import React from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { Typography, InputAdornment, NativeSelect, FormControl, Select, Input, MenuItem } from '@material-ui/core'

import CustomInput from "components/common/CustomInput/CustomInput.js";

import { DataRepositoryTreeTable } from 'components/data/DataRepositoryShare/DataRepositoryTreeTrable/DataRepositoryTreeTable.js'


import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'

import trackEvent from 'trackEvent'

import { useTheme } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import * as convertDataHelper from "components/data/DataRepositoryShare/EntityDataRepository/Helpers/convertDataHelper";
import * as getterDTO from "components/data/DataRepositoryShare/EntityDataRepository/Helpers/getterDTOs";
import TagsConfigurationQuality from 'components/common/TagsConfigurationQuality'

import {
    faUserLock,
} from "@fortawesome/free-solid-svg-icons";

import * as commonStyles from 'style/CommonStyles'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function ProjectCreationDialog(props) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector(store => store.user)
    const projectDataRepo = useSelector((store) => Object.values(store.projectDataRepository));
    const license = useSelector((store) => store.license);


    const deploy_tech = license.isOnPremiseEnable ? ['Cloud', 'On-premise'] : ['Cloud']
    const editable = props.project ? true : false

    const project = props.project
    const [name, setName] = React.useState()
    const [description, setDescription] = React.useState()
    const [environment, setEnvironment] = React.useState('Azure')
    const [tag, setTag] = React.useState()
    const [tags, setTags] = React.useState([])
    const [itemsToShow, setItemsToShow] = React.useState([])


    //error component state
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors, setHasErrors] = React.useState();

    const [selectedItem, setSelectedItem] = React.useState(findRootDto(projectDataRepo)); // selectedItem del private
    const [selectedDtoId, setSelectedDtoId] = React.useState(undefined)
    const [expandedKeys, setExpandedKeys] = React.useState();
    const [userPermissionType, setUserPermissionType] = React.useState("Public")
    const [selectedAllowedUsers, setSelectedAllowedUsers] = React.useState([])

    const [mandatoryTags, setMandatoryTags] = React.useState([])
    const [optionalTags, setOptionalTags] = React.useState([])

    const [mandatoryErrorTags, setMandatoryErrorTags] = React.useState("")
    const [deprecatedErrorTags, setDeprecatedErrorTags] = React.useState("")


    React.useEffect(() => {
        if (user && user.id) {

            dispatch(
                actions.fetchProjectDataRepositoryPrivateWorkspace(
                    user.id
                )
            );
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

   /* React.useEffect(() => {
        setSelectedItem(findRootDto(projectDataRepo))

    }, [projectDataRepo]);// eslint-disable-line react-hooks/exhaustive-deps
    */



    React.useEffect(() => {
        if (editable) {
            setName(project.name)
            setDescription(project.description)
            setEnvironment(project.environment)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setNewItemsToShow()
        // Warning disabled as setNewItemsToShow is not a dependency for this hook.
        // eslint-disable-next-line
    }, [tags])// eslint-disable-line react-hooks/exhaustive-deps



    React.useEffect(() => {
        validate()
        if (selectedItem) {
            setSelectedDtoId(getterDTO.getDTOIdFromFolder(projectDataRepo, selectedItem.itemId))
        }

    }, [selectedItem])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (props.qualityPoint && props.qualityPoint.tagList && props.qualityPoint.tagList.length > 0 && props.configuration && props.configuration.tagConfiguration && props.configuration.tagConfiguration.length > 0) {
            checkIfTagsAreDeprecated(props.qualityPoint.tagList, props.configuration.tagConfiguration)
        }
    }, [props.configuration, props.qualityPoint])// eslint-disable-line react-hooks/exhaustive-deps

    function checkIfTagsAreDeprecated(tagList, tagConfiguration) {
        let error = ""
        for (let i = 0; i < tagList.length; i++) {
            let isTagPresent = tagConfiguration.find(tag => tag.tagName === tagList[i].tagName)
            if (!isTagPresent) {
                error = "tag values are deprecated, please update them."
                setDeprecatedErrorTags(error)
            }
        }
    }


    function findRootDto(dtos) {

        if (dtos && dtos.length > 0) {
            for (var i = 0; i < dtos.length; i++) {
                if (dtos[i].rootFolder.name === 'default')
                    return { itemId: dtos[i]._id, type: "Folder" }
            }

        }
        return { itemId: "", type: "" }
    }

    const createChip = (tag, index) => {
        return (
            <Grid item >
                <Chip
                    key={index}
                    label={tag}
                    style={{ backgroundColor: commonStyles.mainColor, color: '#FFF' }}
                    onDelete={() => deleteTag(index)}
                />
            </Grid>
        );
    }

    const setNewItemsToShow = () => {
        let newItemsToShow = []
        tags.forEach((currentTag, index) => {
            newItemsToShow.push(createChip(currentTag, index))
        })
        setItemsToShow(newItemsToShow)
    }

    const onChangeTextField = (name) => (event) => {

        if (name === 'name') setName(event.target.value)
        else if (name === 'description') setDescription(event.target.value)
        else if (name === 'tag') setTag(event.target.value)
    }

    const setNewTag = () => {
        if (tag !== undefined && tag.length > 2) {
            const isDoublePointInString = tag.search(':')
            if (isDoublePointInString !== -1) {
                let newTagsArray = tags.map((currentTag) => { return currentTag })
                newTagsArray.push(tag)
                setTags(newTagsArray)

            }

        }
    }

    const deleteTag = (index) => {
        const newTagsArray = tags.filter((currentTag, currentIndex) => {
            return currentIndex !== index
        })
        setTags(newTagsArray)
    }

    const saveProject = () => {
        trackEvent('Project', 'Create New Project Dialog Confirm')
        let userPermission = {
            type: userPermissionType,
            allowedUserIds: (userPermissionType === 'Protected' ? selectedAllowedUsers.map(x => x.id) : null)
        }
        let tagsMandatory = mandatoryTags
        let tagsOptional = optionalTags
            ? optionalTags.filter(
                (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
            )
            : [];
        let allTags = tagsMandatory.concat(tagsOptional);

        const project = {
            name: name,
            userId: user.id,
            description: description,
            owner: user.email,
            environment: environment,
            userPermission: userPermission,
            tagList: allTags
        }
        const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = validate()
        //TODO: AVOID WARNINGS
        if (arrayErrorTypes && arrayErrorMsgs) { }
        let hasTagErrors = false
        let emptyMandatoryTags = mandatoryTags.filter(tag => tag.tagValue === undefined || tag.tagValue === '')
        if (emptyMandatoryTags.length > 0) {
            hasTagErrors = true
            setMandatoryErrorTags("Please complete all Mandatory tags.")
        }
        if (!hasTagErrors) {
            if (!hasErrors) {
                props.onClickSaveProject(project)
                let dtoId = selectedDtoId
                let folderId = selectedItem.itemId === selectedDtoId ? 'null' : selectedItem.itemId
                if (((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true'))) {
                    dispatch(actions.createProjectAndRegister(project, dtoId, folderId)).then(response =>
                        dispatch(actions.fetchProjectDataRepositoryPrivateWorkspace(user.id)))
                }
                else {
                    dispatch(actions.createProject(project, dtoId))
                }
            }
        }
    }

    const editProject = () => {
        trackEvent('Project', 'Edit New Project Dialog Confirm')
        let hasErrors = false
        let emptyMandatoryTags = mandatoryTags.filter(tag => tag.tagValue === undefined || tag.tagValue === '')
        if (emptyMandatoryTags.length > 0) {
            hasErrors = true
            setMandatoryErrorTags("Please complete all Mandatory tags.")
        }

        if (!hasErrors) {
            var project2Update = project
            project2Update.name = name
            project2Update.description = description
            project2Update.environment = environment


            let tagsMandatory = mandatoryTags
            let tagsOptional = optionalTags
                ? optionalTags.filter(
                    (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
                )
                : [];
            let allTags = tagsMandatory.concat(tagsOptional);
            project2Update.tagList = allTags

            props.onClickSaveProject(project)
            dispatch(actions.editProject(project._id, project2Update))

        }

    }

    const handleClose = () => {
        trackEvent('Project', 'Create New Project Dialog Cancel')
        props.handleCloseCallback()
    }



    /*
 * ERROR HANDLE FUNCTIONS
 */
    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs[idx]
        }
    }

    function validate() {
        var hasErrors = false
        const arrayErrorTypes = []
        const arrayErrorMsgs = []


        if (!name) {
            hasErrors = true
            arrayErrorTypes.push('name')
            arrayErrorMsgs.push('A name must be provided')
        }


        if (((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true')) &&
            selectedItem.type !== "Folder") {
            hasErrors = true
            arrayErrorTypes.push('folder')
            arrayErrorMsgs.push('A folder needs to be selected in Private Workspace. You have selected a file.')

        }




        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)
        return { hasErrors, arrayErrorTypes, arrayErrorMsgs };

    }

    function validateInputs(states, type) {
        const validations = {
            name: {
                rule: /^[\w_]*$/.test(states.name),
                description: "Incorrect name. The name must only contain letters, numbers and _"
            }
        }
        if (type) {
            return validations[type]
        }
        return Object.values(validations).every((value) => value.rule === true)
    }

    function handleMultipleSelectUsers(event) {
        var values = event.target.value
        setSelectedAllowedUsers(values)
    };

    return (
        <div>
            <Dialog open={true}>
                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <FontAwesomeIcon icon={faPlusCircle} style={theme.dialogs.dialogTitleAwesomeIcon} />
                        </Grid>
                        <Grid item >
                            <Typography style={theme.dialogs.dialogTitleText}>{editable ? 'Edit Project' : ' Create Project'}</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={7}>
                            <div>
                                <CustomInput
                                    labelText="Project Name"
                                    id="ProjectName"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        defaultValue: name,
                                        type: "text",
                                        onChange: onChangeTextField('name'),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className="fas fa-signature" />
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!validateInputs({ name }, "name").rule}
                                    helperText={!validateInputs({ name }, "name").rule ? validateInputs({ name }, "name").description : ""}
                                />
                                <div >
                                    {hasErrors && isError('name') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('name')}</Typography>}
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={7}>
                            <CustomInput
                                labelText="Description"
                                id="Description"
                                formControlProps={{
                                    fullWidth: true
                                }}

                                inputProps={{
                                    defaultValue: description,
                                    type: "text",
                                    onChange: onChangeTextField('description'),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className="fas fa-align-left" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl >
                                <Typography variant='caption'>Deployment Environment</Typography>
                                <NativeSelect
                                    value={environment}
                                    onChange={(e) => setEnvironment(e.target.value)}

                                >
                                    {deploy_tech.map(value =>
                                        <option key={value} value={value}>{value}</option>)}


                                </NativeSelect >
                            </FormControl>
                            <div >
                                {hasErrors && isError('deployment') && <Typography variant='caption' style={theme.errors.errorText}> {getErrorMessage('deployment')}</Typography>}
                            </div>
                        </Grid>


                        {undefined &&
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth='true'
                                    id='Tag'
                                    label='Tag (format id:value)'
                                    inputProps={{
                                        style: { fontSize: 10 }
                                    }}
                                    onChange={onChangeTextField('tag')}
                                />
                            </Grid>}
                        {undefined &&
                            <Grid item xs={4} style={{ marginTop: '8%', marginLeft: '2%' }}>
                                <IconButton onClick={setNewTag} size='small' variant='contained' style={{ backgroundColor: commonStyles.mainColor, color: '#FFF' }}>
                                    <AddIcon />
                                </IconButton>
                            </Grid>}


                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                            < Grid container spacing={1} >
                                <Grid item xs={4}>
                                    <Typography variant='caption'>Metrics Permission Type</Typography>

                                    <Select
                                        native
                                        value={userPermissionType}
                                        onChange={event => setUserPermissionType(event.target.value)}
                                        inputProps={{
                                            name: 'age',
                                            id: 'age-native-simple',
                                        }}
                                    >
                                        <option value={'Public'}>Public</option>
                                        <option value={'Protected'}>Protected</option>
                                        <option value={'Private'}>Private</option>
                                    </Select>
                                </Grid>
                                {userPermissionType === 'Protected' &&
                                    <Grid item xs={7}>


                                        <Typography variant='caption'>Allowed Users</Typography>
                                        <Select
                                            labelId="demo-mutiple-chip-label"
                                            id="demo-mutiple-chip"
                                            multiple
                                            style={{ width: '100%' }}
                                            defaultValue=""
                                            value={selectedAllowedUsers}
                                            onChange={event => handleMultipleSelectUsers(event)}
                                            input={<Input id="select-multiple-chip" />}
                                            renderValue={(selected) => (
                                                <div >
                                                    {selected.map((value) => (
                                                        <Chip key={value.id} label={
                                                            <Typography variant='caption'>{value.name}</Typography>
                                                        } />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {license.users.map((value) => (
                                                <MenuItem key={value.id} value={value} >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                }


                            </ Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                            <TagsConfigurationQuality qualityElement={props.project} elementType="project" setMandatoryTags={setMandatoryTags} setOptionalTags={setOptionalTags} mandatoryErrorTags={mandatoryErrorTags} />
                        </Grid>

                        <Typography variant='caption' style={{ color: commonStyles.errorColor }}>{mandatoryErrorTags}</Typography>
                        <Typography variant='caption' style={{ color: commonStyles.errorColor }}>{deprecatedErrorTags}</Typography>
                        {((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true')) &&
                            <Grid item xs={12} style={{ marginTop: '15px' }}>
                                <div >
                                    {hasErrors && isError('folder') && <Typography variant='caption' style={theme.errors.errorText}> {getErrorMessage('folder')}</Typography>}
                                </div>
                                <div style={{ marginBottom: '20px' }}>
                                    <Typography variant='caption' >Data Repository Folder Selection</Typography>
                                </div>
                                <DataRepositoryTreeTable
                                    enableContextMenu={false}
                                    type="private"
                                    showTitle={false}
                                    dtoData={projectDataRepo}
                                    treeData={convertDataHelper.parseEntityDataRepositoryToTree(projectDataRepo)}
                                    iconTitle={faUserLock}
                                    title={`Select Folder in Private Workspace`}
                                    titleStyle={{ color: "#45b6fe", margin: 0 }}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    setShowDialogs={{ export: undefined, new: undefined }}
                                    showDialogs={{ new: undefined }}
                                    expandedKeys={expandedKeys}
                                    setExpandedKeys={setExpandedKeys}
                                />
                            </Grid>}
                    </Grid>
                    <Grid container style={{ marginTop: '3%', width: '15vw' }} spacing={1}>
                        {itemsToShow}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!editable && <Button disabled={!validateInputs({ name })} id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={saveProject}>Save</Button>}
                    {editable && <Button disabled={!validateInputs({ name })} id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={editProject}>Edit</Button>}
                    <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel} onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
