
import React from 'react'
import {Button, Grid,Popper, Grow,Paper,ClickAwayListener,MenuItem, MenuList} from '@material-ui/core'
import { loadCSS } from 'fg-loadcss';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';


import trackEvent from '../../../../../trackEvent'

import * as commonStyles from 'style/CommonStyles'

export default function AddProjectItemButton(props) {

  const theme = useTheme();


    React.useEffect(() => {
        loadCSS(
          'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
      }, []);

      const [open, setOpen] = React.useState(false);
      const anchorRef = React.useRef(null);

      const handleMenuItemClick = (event, index) => {
        setOpen(false);
        if(index==='QualityPoint')
        {
          props.openQPDialog(true)
        }
      };
    
      const handleToggleButton=()=> {
        trackEvent('DetailProject', 'ToggleButton New Item')
        setOpen((prevOpen) => !prevOpen);

      }

      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };
      
    
    return(
        <div>
          
        
                <Button  onClick = {event => {props.openWIDialog(true); trackEvent('DetailProject', 'New Item Button')}}
                 style={theme.buttons.buttonRegular}
                startIcon={<AddIcon  style={{ fontSize:15}}/>} 
                 >
                   <div style={theme.common.text.buttonTextSmall}>
                   New Item
                   </div>
                   
                        
                </Button>
                 {undefined &&    <Button style={{borderWidth: "0px"}}
                        ref={anchorRef} 
                            size="small"
                            aria-haspopup="true"
                            onClick={handleToggleButton}
                            
                        >
                        <ArrowDropDownIcon />
                        </Button>}
         
                    
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                      <MenuItem
                        key={'QualityPoint'}
                        onClick={(event) => handleMenuItemClick(event,'QualityPoint')}>
                            <Grid container>
                                <Grid item xs={2}>
                                <FontAwesomeIcon icon={faProjectDiagram} style={{color:commonStyles.mainColor, fontSize:14,marginBottom:'10%'}} />
                                </Grid>
                                <Grid item xs={10}>
                                    <div style={{fontSize: '0.7vw', color: commonStyles.mainColor, textTransform: 'none', fontWeight: 'bold'}}>New Project</div>
                                </Grid>
                            </Grid>
                      </MenuItem>
                      <MenuItem
                        key={'QualityDataSource'}
                        onClick={(event) => handleMenuItemClick(event,'QualityDataSource')}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <FontAwesomeIcon icon={faDatabase} style={{color:commonStyles.mainColor, fontSize:14,marginBottom:'10%'}} />
                                </Grid>
                                <Grid item xs={10}>
                                    <div style={{fontSize: '0.7vw', color: commonStyles.mainColor, textTransform: 'none', fontWeight: 'bold'}}>New DataSource</div>
                                </Grid>
                            </Grid>
                      </MenuItem>
                      <MenuItem
                        key={'QualityRule'}
                        onClick={(event) => handleMenuItemClick(event,'QualityRule')}>
                            <Grid container>
                                <Grid item xs={2}>
                                <FontAwesomeIcon icon={faFileAlt} style={{color:commonStyles.mainColor, fontSize:14,marginBottom:'10%'}} />
                                </Grid>
                                <Grid item xs={10}>
                                    <div style={{fontSize: '0.7vw', color: commonStyles.mainColor, textTransform: 'none', fontWeight: 'bold'}}>New Rule</div>
                                </Grid>
                            </Grid>
                      </MenuItem>
                  
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
       </div>
    )

}