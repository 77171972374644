import React from 'react';

import {
        Accordion, AccordionSummary, AccordionDetails, Grid, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { QualityTheme } from '../../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';

import EnvironmentResultStorageTable from './EnvironmentResultStorageTable'

import * as commonStyles from 'style/CommonStyles'


export default function EnvironmentResultStorageConfiguration(props) {



        /*const [cloudProvider, setCloudProvider] = React.useState(props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
                props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure'))
                */
        const cloudProvider = props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
                props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure')
        const [storages, setStorages] = React.useState(props.parentConfiguration && props.parentConfiguration.storages ? props.parentConfiguration.storages : [])
        const [enabledStorages, setEnabledStorages] = React.useState(props.environmentConfiguration && props.environmentConfiguration.resultStorages ? props.environmentConfiguration.resultStorages : [])

        //here we update the environment configuration
        /* React.useEffect(() => {
                props.setStorageResultConfig(enabledStorages)
        }, [enabledStorages]); */

        // here we initialize all possible storages from the main configuration
        React.useEffect(() => {
                if (props.parentConfiguration && props.parentConfiguration.storages) {
                        setStorages(props.parentConfiguration.storages)
                }
        }, [props.parentConfiguration]);

        //here we pass on which storages are already enabled in the selected environment
        React.useEffect(() => {
                if (props.environmentConfiguration && props.environmentConfiguration.enabledResultStorages) {
                        setEnabledStorages(props.environmentConfiguration.enabledResultStorages)
                }
        }, [props.environmentConfiguration.environmentName]);// eslint-disable-line react-hooks/exhaustive-deps


        return (
                <MuiThemeProvider theme={QualityTheme}>
                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Result Storage Configuration</Typography>
                                        </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                        <EnvironmentResultStorageTable environmentConfiguration={props.environmentConfiguration} cloudProvider={cloudProvider} storages={storages} setStorages={setStorages} setEnabledStorages={setEnabledStorages} enabledStorages={enabledStorages} setStorageResultConfig={props.setStorageResultConfig} />
                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}


