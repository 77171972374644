import React, { useState } from 'react';
import DatasourceGrid from './GridAgregations/DatasourceGrid';
import ProjectGrid from './GridAgregations/ProjectGrid';
import ProjectsGrid from './GridAgregations/ProjectsGrid';
import QualityPointGrid from './GridAgregations/QualityPointGrid';
import { useDispatch } from 'react-redux'
import * as actions from 'actions'


export default function ProjectDrillDownComponent(props) {

    const dispatch = useDispatch()
    // projects - project - qualityPoint - datasource

    const [agregationLevel, setAgregationLevel] = useState("projects")
    const [qpExecutions, setQpExecutions] = useState([])
    const [detailQPExecutions, setDetailQPExecution] = useState([])
    const [datasourcesExecution, setDatasourcesExecution] = useState([])
    const [project, setProject] = useState();
    const [qualityPoint, setQualityPoint] = useState();
    const [dataSource, setDataSource] = useState();




    async function handleDrillDownElement(e) {
        if (agregationLevel && agregationLevel === "projects") {

            let projectSelected = props.DQI4Project && props.DQI4Project.filter(p => p.projectId === e.projectId)

            if (projectSelected && projectSelected.length > 0) {

                let projectDetail = await dispatch(actions.getDQIProjectDetail(props.filterObj, e.projectId))
                let executionsQP = await dispatch(actions.getQpDetailExecutions(props.filterObj, e.projectId))
                let executionsQPDetail = await dispatch(actions.getDetailQIExecution(props.filterObj, e.projectId))
                //let executionDetail = await dispatch(actions.getDetailExecutions("1604338697"))
                
                if (projectDetail && projectDetail.data && executionsQP && executionsQP.data && executionsQP.data.qualityPointExecutionHistoryList && executionsQPDetail) {
                    setDetailQPExecution(executionsQPDetail.data)
                    setProject(projectDetail.data)
                    setQpExecutions(executionsQP.data.qualityPointExecutionHistoryList)
                    setAgregationLevel("project")
                }
            }
        }
        if (agregationLevel && agregationLevel === "project") {

            let qpSelected = project.qualityPointDQI.filter(qp => qp.qualityPointId === e.qualityPointId)
            let filter = JSON.parse(JSON.stringify(props.filterObj))
            filter.selectedProjectQPIds.push(e.qualityPointId)

            let executionsDatasources = await dispatch(actions.getDatasourceDetailExecutions(filter, project.projectId))

            if (qpSelected && qpSelected.length > 0 && executionsDatasources && executionsDatasources.data && executionsDatasources.data.datasourceExecutionHistoryList) {
                setQualityPoint(qpSelected[0])
                setDatasourcesExecution(executionsDatasources.data.datasourceExecutionHistoryList)
                setAgregationLevel("qualityPoint")

            }
        }
        if (agregationLevel && agregationLevel === "qualityPoint") {
            setDataSource(e)
            setAgregationLevel("datasource")
        }

    }

    return (
        <>

            {agregationLevel && agregationLevel === "projects" && <ProjectsGrid
                handleDrillDownElement={handleDrillDownElement}
                filterObj={props.filterObj}
                DQI4Project={props.DQI4Project}
                DQI4Project5First={props.DQI4Project5First}
                globalDQI={props.globalDQI}
                DQIByDimension={props.DQIByDimension}
                agregationLevel={agregationLevel}
                setAgregationLevel={setAgregationLevel}
                DQI4ProjectFlag={props.DQI4ProjectFlag}
                DQI4Project5FirstFlag={props.DQI4Project5FirstFlag}
                DQIByDimensionFlag={props.DQIByDimensionFlag}

            />}
            {agregationLevel && agregationLevel === "project" && <ProjectGrid
                handleDrillDownElement={handleDrillDownElement}
                project={project}
                filterObj={props.filterObj}
                qpExecutions={qpExecutions}
                detailQPExecutions={detailQPExecutions}
                agregationLevel={agregationLevel}
                setAgregationLevel={setAgregationLevel}
                DQI4ProjectFlag={props.DQI4ProjectFlag}
                DQI4Project5FirstFlag={props.DQI4Project5FirstFlag}
                DQIByDimensionFlag={props.DQIByDimensionFlag}
            />}
            {agregationLevel && agregationLevel === "qualityPoint" && <QualityPointGrid
                qualityPoint={qualityPoint}
                project={project}
                handleDrillDownElement={handleDrillDownElement}
                qpExecutions={qpExecutions}
                datasourcesExecution={datasourcesExecution}
                filterObj={props.filterObj}
                agregationLevel={agregationLevel}
                setAgregationLevel={setAgregationLevel}
            />}
            {agregationLevel && agregationLevel === "datasource" && <DatasourceGrid
                dataSource={dataSource}
                project={project}
                qualityPoint={qualityPoint}
                filterObj={props.filterObj}
                agregationLevel={agregationLevel}
                setAgregationLevel={setAgregationLevel}
            />}
        </>

    );
}