
//import qualityLocalHost from 'apis/qualityLocalHost';
import quality from '../apis/quality';
import { dispatchError } from '../components/common/axios/axiosHelper';
//import qualityLocalHost from 'apis/qualityLocalHost';





export const getPublicDataformatLastExecution = (idDataformat) => async (dispatch, getState) => {

    var returnValue = { result: 'undefined' }
    await quality.get(`/public/records/last/df/${idDataformat}`).then(function (response) {
      returnValue = { result: 'ok', data: response.data }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
        returnValue = { result: 'ko', error: error }
      });
    return returnValue
  
};
export const getPublicDatasourceLastExecution = (id) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/public/records/last/ds/${id}`).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)
      returnValue = { result: 'ko', error: error }
    });
  return returnValue

};
export const getPublicQualityPointLastExecution = (id) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/public/records/last/qp/${id}`).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)
      returnValue = { result: 'ko', error: error }
    });
  return returnValue

};

export const getPublicLastExecutionDetailForDF = (id) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/public/records/detail/last/df/${id}`).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)
      returnValue = { result: 'ko', error: error }
    });
  return returnValue

};
export const getPublicLastExecutionDetailForDS = (id) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/public/records/detail/last/ds/${id}`).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)
      returnValue = { result: 'ko', error: error }
    });
  return returnValue

};
export const getPublicLastExecutionDetailForQP = (id) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/public/records/detail/last/qp/${id}`).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)
      returnValue = { result: 'ko', error: error }
    });
  return returnValue

};

