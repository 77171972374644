import React from 'react'
import { ICommonRule, IColumn, IAggregationObject } from '../../../interfaces/RuleInterfaces';
import ConditionGenerator from './ConditionGenerator';
import { ILogicColumnOperator } from 'interfaces/LogicCondition';
import { Typography } from '@material-ui/core';
import { ILogicColumnCondition } from '../../../interfaces/LogicCondition';
import * as enrichHelper from 'components/common/EnrichDataformatHelper'


interface ExecutionConditionRuleProps {
    project:any,
    commonRule?: ICommonRule ,
    setConditions: (value: ILogicColumnOperator)=>any,
    columnsEnriched: IColumn[],
    conditions: any,
    aggregatedColumnsFlag? : boolean,
    aggregationPrior2JoinFlag? : boolean,
    aggregationObject? : IAggregationObject
  

}



export default function ExecutionConditionRule(props: ExecutionConditionRuleProps) {


    const [conditionObj, setConditionObj] = React.useState<ILogicColumnOperator>( {} as ILogicColumnOperator)
    const [conditionString, setConditionString] = React.useState('')
    React.useEffect(()=>{
        setConditionObj(props.conditions)
        
    }, [props.conditions])

    function setConditionObjCallback(condition: ILogicColumnOperator){
        setConditionObj(condition)
        props.setConditions(condition)
    }

    function getColumns(){
        var cols: Array<IColumn> = []
        if(props.aggregatedColumnsFlag === true && props.aggregationPrior2JoinFlag === true){
            cols = props.columnsEnriched
            
        }
        else if(props.aggregatedColumnsFlag === true && props.aggregationPrior2JoinFlag !== true){
            cols = enrichHelper.getColumnsFromAggregationObject(props.aggregationObject)
            
        }
        else {
            cols = props.columnsEnriched
            
        }
        return cols

        
        
    }
    


    function getConditionOperatorStr(operator: ILogicColumnOperator){
        var returnStr  = ''
        var conditionArray : string[] = []

        if(operator && operator.columnConditions && operator.columnConditions.length>0){
            for(var i=0;i<operator.columnConditions.length;i++){
               
                    conditionArray.push(getConditionColumnStr(operator.columnConditions[i]))
            }
        }

        if(operator && operator.sqlExpressions && operator.sqlExpressions.length > 0){
            operator.sqlExpressions.forEach(e => {
               if(e) {
                conditionArray.push('(' + e.sqlExpression + ')')
            }
            })
        }

        if(operator && operator.columnOperations && operator.columnOperations.length>0){
            for(var j=0;j<operator.columnOperations.length;j++){
                const tmpStr = getConditionOperatorStr(operator.columnOperations[j])
                if(tmpStr!==""){
                    conditionArray.push(tmpStr)
                }
                    
            }
        }

        if(conditionArray.length>0){
            
                returnStr += conditionArray.join(" "+ operator.operator+" ")
           
        }
        if(returnStr ===''){
            return returnStr
        }
        else{
            return "("+returnStr+")"
        }
       
    }

    function  getConditionColumnStr(col: ILogicColumnCondition){

        if(col.params){
            if(col.params.nullCheck===true){
                return "( "+col.columnName+" is null )"
            }
            else if(col.params.notNullCheck===true){
                return "( "+col.columnName+" is not null )"
            }
            else if(col.params.greaterValue || col.params.lowerValue){
               if(col.params.greaterValue && col.params.lowerValue)
               {
                return  "( "+col.columnName+" between " + col.params.lowerValue +" and "+col.params.greaterValue+" )"
               }
               else if (col.params.greaterValue )
               {
                return  "( "+col.columnName+" < " + col.params.greaterValue +" )"
               }
               else{
                return  "( "+col.columnName+" > " + col.params.lowerValue +" )"
               }
                
            }
            else if(col.params.listValues && col.params.listValues.length>0){
                if(col.columnType === 'string'){
                    return  "( "+col.columnName+" in ( " +col.params.listValues.map(x=>"`"+x+"`").join(",")+" ) )"
                }
                else{
                    return  "( "+col.columnName+" in ( " +col.params.listValues.join(",")+" ) )"
                }
                
            }
            
        }
        return col.columnName +" operation"
    }
   
    return(
        <div style={{height:'100%', width:'100%'}}>
            <Typography variant = 'subtitle2' style={{fontWeight:'bold'}}>Condition Generated:</Typography>
            <Typography variant = 'caption'>{getConditionOperatorStr(conditionObj)}</Typography>
                <ConditionGenerator conditionObj = {conditionObj} columns = {getColumns()} setParentConditions = {setConditionObjCallback}/>
                </div>
    )
}