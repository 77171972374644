import React from 'react'
import MaterialTable from '@material-table/core'
import * as commonStyles from 'style/CommonStyles'
import LollipopChart from '../Charts/LollipopChart'
import BarChartSmall from '../Charts/BarChartSmall'
import RulesScoreChartSmall from '../Charts/RulesScoreChartSmall'
import OpenInBrowserIcon from '@material-ui/icons/SystemUpdateAlt';
import { Tooltip } from '@material-ui/core'
import { LanguageContext } from 'language/LanguageContext';
import {MoneyFormat} from 'commonFunctions/commonFunctions'


export default function TableLlolipopCharts(props) {

  const [data, setData] = React.useState([])

  const chartTableHeight = '60px'
  const chartTableWidth = '120%'
  const chartTableMarginLeft = '-15%'
  const chartTableMarginBottom = '-20px'
  const languageContext = React.useContext(LanguageContext)

  React.useEffect(() => {

    if (props.data) {
      setData(props.data)
    }

  }, [props.data])

  

  function handleDrillDownElement(rowData) {
    props.click(rowData)
  }

  function getZoomInRender(rowData) {
    return (
      <OpenInBrowserIcon style={{ cursor: 'pointer' }}
        onClick={event => {
          handleDrillDownElement(rowData)
        }} />
    )
  }


  return (
    <MaterialTable

      columns={[
        { title: '', width: '2%', render: rowData => getZoomInRender(rowData) },
        { title: 'Name', width: '10%', field: 'name' },
        {
          title: (
            <Tooltip title={languageContext.dictionary['score']}>
              <span>Score</span>
            </Tooltip>
          ), width: '20%',

          render: rowData => {
            let score = Number(((rowData.sumNumOk / (rowData.sumNumIn === 0 ? 1 : rowData.sumNumIn)) * 100).toFixed(0))

            return (
              <RulesScoreChartSmall score={score} chart={'score' + rowData.name} height={chartTableHeight} width={chartTableWidth} marginLeft={chartTableMarginLeft}
                marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        },
        {
          title: (
            <Tooltip title={languageContext.dictionary['scoreTrends']}>
              <span>Score Trends</span>
            </Tooltip>
          ),
          width: '20%',
          render: rowData => {
            return (
              <LollipopChart data={rowData.historyList} value={'score'} category={'fecha'} chart={'chartScore' + rowData.id} height={chartTableHeight}
                width={chartTableWidth} marginLeft={chartTableMarginLeft} marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        },
        {
          title: 'Rows',
          width: '8%',
          render: rowData => {
            return (
              <span>{MoneyFormat(rowData.sumNumIn)}</span>
            )

          }

        },
        {
          title: (
            <Tooltip title={languageContext.dictionary['rowTrends']}>
              <span>Rows Trends</span>
            </Tooltip>
          ),
          width: '20%',
          render: rowData => {
            return (
              <LollipopChart data={rowData.historyList} value={'numIn'} category={'fecha'} chart={'chartRows' + rowData.id} height={chartTableHeight}
                width={chartTableWidth} marginLeft={chartTableMarginLeft} marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        },
        {
          title: (
            <Tooltip title={languageContext.dictionary['passingTrends']}>
              <span>Passing Trends</span>
            </Tooltip>
          ),
          width: '20%',
          render: rowData => {
            return (
              <BarChartSmall data={rowData.historyList} chart={'chartPass' + rowData.id} height={chartTableHeight}
                width={chartTableWidth} marginLeft={chartTableMarginLeft} marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        }
      ]}

      data={data}

      options={{
        title: false,
        search: false,
        paging: false,
        toolbar: false,

        padding: props.fullscreen === true ? 'normal' : 'dense',
        cellStyle: commonStyles.tableCellStyle
      }}


      style={{ width: '100%', margin: '0', height: props.fullscreen === true ? '100%' : '250px' }}
    />
  )
}

