import React from 'react';
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function TableCheckDatabricksEnvironment(props) {


    const theme = useTheme();

    const columns = [
        {title: 'Status',  width: "90%",render: rowData =>  <div >
        {
            rowData.status===true ? 
            <FontAwesomeIcon icon={faCheckCircle} style={{...theme.icons.iconAqtivaTable, color:'#3BA15A'}} />
            : rowData.status===false ?
            <FontAwesomeIcon icon={faTimesCircle} style={{...theme.icons.iconAqtivaTable, color:'#CD312F'}} />
            :
            <CircularProgress style={{ width: '15px', height: '15px' }}/>


        } 
      </div>},
        {title: 'Message', field: 'message', width: "5%" }

        
    ]

  

    return (
        <div style={{width:'600px', height:'400px', overflow:"scroll"}}>

<MaterialTable
                        title = {' '}
                        columns = {columns}
                        data={props.data}
                        options={{
                            filtering: false,
                            exportButton: false,  
                            search: false,
                            paging: false,
                            toolbar: false,
                            grouping: false,
                            cellStyle:{
                                fontSize:'12px',
                                padding:'5px'
                            },
                        }}
                        
                    />
        </div>
    )
}
