import React from 'react';

import {
         Accordion, AccordionSummary, AccordionDetails, Grid, Typography
        
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import {  QualityTheme } from '../../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';


import EnvironmentResultStorageTable from './EnvironmentDataStorageCredentialsTable'


import * as commonStyles from 'style/CommonStyles'


export default function EnvironmentDataStorageCredentialsConfiguration(props) {
      


        const [dataStorageCredentials, setDataStorageCredentials] = React.useState(props.parentConfiguration && props.parentConfiguration.dataStorageCredentials ? props.parentConfiguration.dataStorageCredentials : [])
        const [enabledDataStorageCredentials, setEnabledDataStorageCredentials] = React.useState(props.environmentConfiguration && props.environmentConfiguration.enabledDataStorageCredentials ? props.environmentConfiguration.enabledDataStorageCredentials : [])

        // here we initialize all possible storages from the main configuration
        React.useEffect(() => {
                if (props.parentConfiguration && props.parentConfiguration.dataStorageCredentials) {
                        setDataStorageCredentials(props.parentConfiguration.dataStorageCredentials)
                }
        }, [props.parentConfiguration, props.environmentConfiguration && props.environmentConfiguration.environmentName]);// eslint-disable-line react-hooks/exhaustive-deps

        //here we pass on which storages are already enabled in the selected environment
        React.useEffect(() => {
                if (props.environmentConfiguration && props.environmentConfiguration.enabledDataStorageCredentials) {
                        setEnabledDataStorageCredentials(props.environmentConfiguration.enabledDataStorageCredentials)
                }
                else {
                        setEnabledDataStorageCredentials([])
                }
        }, [props.environmentConfiguration && props.environmentConfiguration.environmentName, props.environmentConfiguration.enabledDataStorageCredentials // eslint-disable-line react-hooks/exhaustive-deps
                && props.environmentConfiguration.enabledDataStorageCredentials.length]);// eslint-disable-line react-hooks/exhaustive-deps


        return (
                <MuiThemeProvider theme={QualityTheme}>
                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Data Storage Credentials Configuration</Typography>
                                        </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                        <EnvironmentResultStorageTable environmentConfiguration={props.environmentConfiguration} dataStorageCredentials={dataStorageCredentials} setDataStorageCredentials={setDataStorageCredentials} setEnabledDataStorageCredentials={setEnabledDataStorageCredentials} enabledDataStorageCredentials={enabledDataStorageCredentials} setDataStorageCredentialsConfig={props.setDataStorageCredentialsConfig} />
                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}


