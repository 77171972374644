
import React from 'react';

import { Grid, Typography } from '@material-ui/core'

import { Accordion, AccordionDetails, AccordionSummary, Chip, Avatar, Tooltip } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';

import { faCog } from '@fortawesome/free-solid-svg-icons'
import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Autocomplete } from '@material-ui/lab';

export default function CategoryLevelError(props) {

    //const languageContext = React.useContext(LanguageContext); 
    const [newError, setNewError] = React.useState(props.rule && props.rule.categoryLevelError ? props.rule.categoryLevelError : '')
    const [errorsList, setErrorList] = React.useState(['INFO', 'WARNING', 'ERROR'])




    const theme = useTheme()

    React.useEffect(() => {
        if (props.selectedDatasource != null && props.selectedDatasource.rules != null) getCategoryErrors(props.selectedDatasource.rules)
    }, [props.selectedDatasource])

    React.useEffect(() => {
        if (newError != null) props.setCategoryError(newError)
    }, [newError])

    function getCategoryErrors(rules) {

        let newErrorList = errorsList

        if (rules && rules.length > 0) {
            rules.forEach(r => {
                if (r.categoryLevelError && !newErrorList.includes(r.categoryLevelError) && r.categoryLevelError != newError) newErrorList.push(r.categoryLevelError)
            })
        }

        setErrorList(newErrorList)
    }


    function onlyLettersAndSpaces(str) {
        if ((Boolean(str?.match(/^[/-_A-Za-z0-9-:\s]*$/))) === true) {
            return undefined
        }
        return 'Category level error can only have number, letters and _'
    }


    function validateError(str) {
        if (onlyLettersAndSpaces(str) === undefined) {
            return undefined;
        }
        else {
            return onlyLettersAndSpaces(str)
        }
    }

    return (
        <Accordion
            style={{ width: '100%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={1}>
                    <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                </Grid>
                <Grid item xs={11}>
                    <Typography variant='subtitle2'>Category Level Error</Typography>



                </Grid>

            </AccordionSummary>
            <AccordionDetails>
                <div style={{width: '100%' }}>
                    <Autocomplete
                        style={{width: '100%'}}
                        autoWidth={true}
                        label="Error"
                        onChange={(event, newValue) => {
                            setNewError(newValue)
                        }}
                        freeSolo
                        autoSelect
                        error={validateError(newError) !== undefined}
                        helperText={validateError(newError)}
                        defaultValue={newError}
                        getOptionLabel={(option) => option}
                        options={errorsList}
                        renderInput={(e) => {
                            return (
                                <TextField {...e} label={'Category Level Error'} />
                            )
                        }}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    )
}