export function checkAllFields(element, allAliases) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if(element !== undefined){
        console.log('element', element)
    
        if (!element.datasourceEnrichName) {
            errorsToCheck.datasourceEnrichName = "Please select a datasource to enrich from."
            errorsToCheck.hasErrors = true
        }
        if (!element.joinType) {
            errorsToCheck.joinType = "Please select a Join type."
            errorsToCheck.hasErrors = true
        }
        if (!element.alias) {
            errorsToCheck.alias = "Please insert an Alias"
            errorsToCheck.hasErrors = true
        }
        if (element.alias) {
            var re = new RegExp("^[a-zA-Z0-9]{1,20}$");
            if (!re.test(element.alias)) {
                errorsToCheck.alias = "Please insert a valid Alias"
                errorsToCheck.hasErrors = true
            }
            if (allAliases.includes(element.alias)) {
                errorsToCheck.alias = "This alias is already in use"
                errorsToCheck.hasErrors = true
            }
        }
        if (element.mappingsList) {
            console.log('mappingList', element.mappingsList)
            for (let i = 0; i < element.mappingsList.length; i++) {
                if (!element.mappingsList[i].originColumn || element.mappingsList[i].originColumn === "") {
                    errorsToCheck[i] = {}
                    errorsToCheck[i].originColumn = "Please select an origin Column."
                    errorsToCheck.hasErrors = true
                }
                if (!element.mappingsList[i].destinationColumn || element.mappingsList[i].destinationColumn === "") {
                    console.log('columna no valida', element.mappingsList[i].destinationColumn)
                    errorsToCheck[i] = {}
                    errorsToCheck[i].destinationColumn = "Please select a destination Column."
                    errorsToCheck.hasErrors = true
                }
            }

        }
    }

    return errorsToCheck

}
export function checkAllComparisons(columnOperations) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false

    if (columnOperations) {
        for (let i = 0; i < columnOperations.length; i++) {
            errorsToCheck[i] = {}
            if (!columnOperations[i].column_or || columnOperations[i].column_or === "") {
                errorsToCheck[i].column_or = "Please select an origin Column."
                errorsToCheck.hasErrors = true
            }
            if (!columnOperations[i].column_dst || columnOperations[i].column_dst === "") {
                errorsToCheck[i].column_dst = "Please select a destination Column."
                errorsToCheck.hasErrors = true
            }
            if (!columnOperations[i].operation || columnOperations[i].operation === "") {
                errorsToCheck[i].operation = "Please select an operation."
                errorsToCheck.hasErrors = true
            }
        }

    }

    return errorsToCheck

}