import React from 'react';


import { useDispatch, useSelector } from 'react-redux'
import * as actions from './../../../actions'
import QualityDatasetProfilingRepository from './../QualityDatasetProfilingRepository';




export default function QualityDatasetProfilingPage(props) {
  const dispatch = useDispatch()
  const license = useSelector((store) => store.license);
  const user = useSelector(store => store.user)
  const qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))



  const [usedDatasourcesByProjects, setUsedDatasourcesByProjects] = React.useState([]);
  const [licenseId, setLicenseId] = React.useState(undefined);
  const [userId, setUserId] = React.useState(undefined);

  

  React.useEffect(() => {
    if (licenseId && userId) {
      ExecutefetchUsedDatasourcesByProject(licenseId, userId)
    }
  }, [userId, licenseId]);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {

    setLicenseId(license._id)
    setUserId(user.id)
  }, [props.match, user, license]);


  function ExecutefetchUsedDatasourcesByProject(licenseId, userId) {
    dispatch(actions.fetchUsedDatasourcesByProject(licenseId, userId)).then(response => {
      setUsedDatasourcesByProjects(response.data)
    })
  }





  return (
     
    
          <QualityDatasetProfilingRepository history={props.history}
            selectDatasetDialog={props.selectDatasetDialog}
            licenseUsers={license.users}
            usedDatasourcesByProjects={usedDatasourcesByProjects}
            configuration={qualityConfigurationSelector}
            user={user}
            handleSelectDatasetForExportationId={props.handleSelectDatasetForExportationId}
            notificationDatasetId = {props.match && props.match.params && props.match.params.dataformatId ? props.match.params.dataformatId : undefined}/>
  

  );
}