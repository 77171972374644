import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  FETCH_ERROR,
  FETCH_INFO
} from "./types";

import AuthService from "./services/UserAuthServices.js";
import qualityLogin from 'apis/qualityLogin';
import { axiosHeader} from 'components/common/axios/axiosHelper';


export const createLocalUser = (userObj) => (dispatch) => {
  return AuthService.register(userObj.name, userObj.email, userObj.password, userObj.role).then(
    (response) => {
      dispatch({
        type: USER_REGISTER_SUCCESS,
      });

      dispatch({
        type: FETCH_INFO,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: USER_REGISTER_FAIL,
      });

      dispatch({
        type: FETCH_ERROR,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password)  => async (dispatch, getState) => {

  const loginRequestObj = {
    "email":username,
    "password":password
}

  var returnValue = {}
  await qualityLogin.post("/public/auth/login", loginRequestObj,{  headers: axiosHeader() })
  .then(function (response) {

    returnValue = response

  })
    .catch(function (error) {
      //dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}



export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: USER_LOGOUT,
  });
};