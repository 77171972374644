import React, { useEffect, useState } from 'react';
import {Divider,Typography, Grid, CardContent, FormControl, Select, MenuItem, TextField, Switch, Checkbox, FormControlLabel, } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';


import ContactsSelector from '../ContactsSelector'
import OutputPathSelector from '../OutputPathSelector'
import SelectEnvironmentComponentSchedule from './SelectEnvironmentComponentSchedule';
import * as dataForRecurrenceHelper from './dataForRecurrence'

import * as parameterHelper from 'commonFunctions/parameterHelper'

import * as commonStyles from 'style/CommonStyles'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '120px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    },
    typography: {
        fontSize: "12px",
        marginTop: "20px",
    },
    recurrence: {
        display: "flex", flexDirection: "row", justifyContent: "left", alignItems: 'center'
    },
    scheduleControlElements: {
        display: "flex", flexDirection: "row", justifyContent: "left",
    },
    buttons: {
        display: "flex", flexDirection: "row", justifyContent: "flex-end", margin: "50px"
    },
    notificationCheck: {
        width: "30%"
    },

    rowTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: '20px'

    },
    rowTitleSmall: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",


    },
    rowTitleWithSpace: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: '10px',
        marginRight: '10px'
    }


}));
export default function SchedulingConfigurationCard(props) {
    const classes = useStyles();

    const [scheduleName, setScheduleName] = React.useState(undefined)
    const [selectedRecurrence, setSelectedRecurrence] = useState(1)
    const [selectedRecurrenceType, setSelectedRecurrenceType] = useState("day")
    const [selectedDayOfTheWeek, setSelectedDayOfTheWeek] = useState(1)
    const [selectedDayOfTheMonth, setSelectedDayOfTheMonth] = useState(1)
    const [selectedHourOfTheDay, setSelectedHourOfTheDay] = useState(0)
    const [selectedMinuteOfTheHour, setSelectedMinuteOfTheHour] = useState(0)
    const [selectedTimezone, setSelectedTimezone] = useState("US/Pacific")
    const [activeCheckStatus, setActiveCheckStatus] = useState(true)
    const [notifyOnSuccess, setNotifyOnSuccess] = useState(false)
    const [notifyOnFailure, setNotifyOnFailure] = useState(false)
    const [notifyOnStart, setNotifyOnStart] = useState(false)
    const [partitionedResult, setPartitionedResult] = useState(false)
    const [appendResult, setAppendResult] = useState(true)
    const [overwriteResult, setOverwriteResult] = useState(false)
    const [cron, setCron] = useState(undefined)
    const [mailsToNotify, setMailsToNotify] = useState(undefined)
    const [selectedEnvironment, setSelectedEnvironment] = React.useState(undefined)
    const [selectedOutputPath, setSelectedOutputPath] = React.useState(undefined)
    const [parameters, setParameters] = React.useState([])

    React.useEffect(()=>{
        if(props.selectedQualityPoint && props.datasource && props.datasource.rules){
            const newParameters=(parameterHelper.getParametersForDS(props.selectedQualityPoint, 
                props.datasource, props.datasource.rules)).map(param=>{
                    if(param.defaultValue===undefined){
                        return param
                    }
                    else{
                        param.value = param.defaultValue
                        return param
                    }
                })
            setParameters(newParameters)
        }
           
        },[props.selectedQualityPoint, props.datasource])

        function updateParameter(index, value){
            var oldParameter = parameters[index]
            oldParameter.value = value
            var newParameterList = JSON.parse(JSON.stringify(parameters))
            newParameterList[index]= oldParameter
            setParameters(newParameterList)
        }

    useEffect(() => {
        let translatedcron = translateRecurrenceToCron(selectedRecurrence, selectedRecurrenceType, selectedDayOfTheWeek, selectedDayOfTheMonth, selectedHourOfTheDay, selectedMinuteOfTheHour)
        setCron(translatedcron)
    }, [selectedRecurrence, selectedRecurrenceType, selectedDayOfTheWeek, selectedDayOfTheMonth, selectedMinuteOfTheHour, selectedHourOfTheDay])

    useEffect(() => {
        if (props.scheduleInfo) {
            let cronObject = {}
            cronObject.cron = cron
            cronObject.timezone = selectedTimezone
            cronObject.pauseStatus = activeCheckStatus ? "UNPAUSED" : "PAUSED"

            let alertConfiguration = {}
            alertConfiguration.no_alert_for_skipped_runs = true
            if (notifyOnSuccess) {
                alertConfiguration.on_success = mailsToNotify
            }
            if (notifyOnFailure) {
                alertConfiguration.on_failure = mailsToNotify
            }
            if (notifyOnStart) {
                alertConfiguration.on_start = mailsToNotify
            }

            let schedule = {}
            schedule.name = scheduleName
            schedule._id = props.scheduleInfo._id
            schedule.jobId = props.scheduleInfo.jobId
            schedule.datasourceId = props.scheduleInfo.datasourceId
            schedule.datasourceName = props.scheduleInfo.datasourceName
            schedule.userId = props.userId
            schedule.projectId = props.scheduleInfo.projectId
            schedule.projectName = props.scheduleInfo.projectName
            schedule.qualityPointId = props.scheduleInfo.qualityPointId
            schedule.qualityPointName = props.scheduleInfo.qualityPointName
            schedule.allowedUsersIds = props.scheduleInfo.allowedUsers //care
            schedule.cron = cronObject
            schedule.alertConfiguration = alertConfiguration
            schedule.environment = selectedEnvironment
            schedule.creationDate = props.scheduleInfo.creationDate
            schedule.updatedFlag = props.scheduleInfo.updatedFlag
            schedule.state = props.scheduleInfo.state
            schedule.storageDTO = selectedOutputPath
            schedule.storageMethod = setStorageValue(overwriteResult, appendResult, partitionedResult)
            schedule.parameters = parameters

            props.setSchedule(schedule)
        }
        else {
            let cronObject = {}
            cronObject.cron = cron
            cronObject.timezone = selectedTimezone
            cronObject.pauseStatus = activeCheckStatus ? "UNPAUSED" : "PAUSED"

            let alertConfiguration = {}
            alertConfiguration.no_alert_for_skipped_runs = true
            if (notifyOnSuccess) {
                alertConfiguration.on_success = mailsToNotify
            }
            if (notifyOnFailure) {
                alertConfiguration.on_failure = mailsToNotify
            }
            if (notifyOnStart) {
                alertConfiguration.on_start = mailsToNotify
            }
            let schedule = {}
            schedule.name = scheduleName
            schedule.datasourceId = props.datasource && props.datasource._id
            schedule.userId = props.userId
            schedule.projectId = props.project._id
            schedule.qualityPointId = props.selectedQualityPoint._id
            schedule.allowedUsersIds = props.project.allowedUsers //care
            schedule.cron = cronObject
            schedule.alertConfiguration = alertConfiguration
            schedule.environment = selectedEnvironment
            schedule.storageDTO = selectedOutputPath
            schedule.storageMethod = setStorageValue(overwriteResult, appendResult, partitionedResult)
            schedule.parameters = parameters

            props.setSchedule(schedule)
        }
    }, [cron, mailsToNotify, activeCheckStatus, notifyOnFailure, notifyOnStart, notifyOnSuccess, selectedEnvironment,// eslint-disable-line react-hooks/exhaustive-deps
         selectedOutputPath, partitionedResult, appendResult, overwriteResult,scheduleName,parameters])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (props.scheduleInfo) {
            let onStart = props.scheduleInfo.alertConfiguration && props.scheduleInfo.alertConfiguration && props.scheduleInfo.alertConfiguration.on_start
            setNotifyOnStart(onStart && onStart.length > 0)
            if (onStart && onStart.length > 0) {
                setMailsToNotify(onStart)
            }
            let onFailure = props.scheduleInfo.alertConfiguration && props.scheduleInfo.alertConfiguration && props.scheduleInfo.alertConfiguration.on_failure
            setNotifyOnFailure(onFailure && onFailure.length > 0)
            if (onFailure && onFailure.length > 0) {
                setMailsToNotify(onFailure)
            }
            let onSuccess = props.scheduleInfo.alertConfiguration && props.scheduleInfo.alertConfiguration && props.scheduleInfo.alertConfiguration.on_success
            setNotifyOnSuccess(onSuccess && onSuccess.length > 0)
            if (onSuccess && onSuccess.length > 0) {
                setMailsToNotify(onSuccess)
            }

            let pauseStatus = props.scheduleInfo.cron && props.scheduleInfo.cron.pauseStatus
            setActiveCheckStatus(pauseStatus === "UNPAUSED" ? true : false)
            let cron = props.scheduleInfo.cron && props.scheduleInfo.cron.cron
            setCron(cron)
            setSelectedEnvironment(props.scheduleInfo.environment)
            setSelectedOutputPath(props.scheduleInfo.outputPath)
            setSelectedTimezone(props.scheduleInfo.cron && props.scheduleInfo.cron.timezone)
            setSelectedOutputPath(props.scheduleInfo.storageDTO)
            translateCronToRecurrence(cron)

        }
    }, [props.scheduleInfo, props.showEditDialog])




    const handleRecurrenceChange = (event) => {
        setSelectedRecurrence(event.target.value);
    };
    const handleRecurrenceTypeChange = (event) => {
        if (event.target.value === "day" || event.target.value === "week" || event.target.value === "month") {
            setSelectedRecurrence(1)
        }
        setSelectedRecurrenceType(event.target.value);
    };
    const handleDayOfTheWeekChange = (event) => {
        setSelectedDayOfTheWeek(event.target.value);
    };
    const handleDayOfTheMonthChange = (event) => {
        setSelectedDayOfTheMonth(event.target.value);
    };
    const handleHourChange = (event) => {
        setSelectedHourOfTheDay(event.target.value);
    };
    const handleminuteChange = (event) => {
        setSelectedMinuteOfTheHour(event.target.value);
    };
    const handleTimezoneChange = (event) => {
        setSelectedTimezone(event.target.value);
    };
    const handleCheckChange = (event) => {
        setActiveCheckStatus(event.target.checked);
    };
    const handleCheckChangeOnStart = (event) => {
        setNotifyOnStart(event.target.checked);
    };
    const handleCheckChangeOnSuccess = (event) => {
        setNotifyOnSuccess(event.target.checked);
    };
    const handleCheckChangeOnFailure = (event) => {
        setNotifyOnFailure(event.target.checked);
    };
    const handleCheckAppend = (event) => {
        setAppendResult(event.target.checked)
        setOverwriteResult(false)
        setPartitionedResult(false)
    }
    const handleCheckOverwrite = (event) => {
        setOverwriteResult(event.target.checked)
        setAppendResult(false)
        setPartitionedResult(false)
    }
    
    /*const handleCheckPartitioned = (event) => {
        setPartitionedResult(event.target.checked)
        setAppendResult(false)
        setOverwriteResult(false)
    }*/

    function setStorageValue(overwrite, append, partitioned) {
        let value
        if (overwrite) {
            value = "OVERWRITE"
        }
        if (append) {
            value = "APPEND"
        }
        if (partitioned) {
            value = "PARTITIONED"
        }
        return value
    }



    function renderMinuteHourSeparator() {
        const type = selectedRecurrenceType !== "minute" && selectedRecurrenceType !== "hour"
        const type2 = selectedRecurrenceType !== "minute"
        const recurrence = selectedRecurrence === 1
        const recurrence2 = selectedRecurrence > 1

        return (type && recurrence) || (type2 && recurrence2)
    }
    function renderHours() {
        const type = selectedRecurrenceType !== "minute" && selectedRecurrenceType !== "hour"
        //const recurrence = selectedRecurrence > 1

        return /*recurrence ||*/ type
    }
    function renderMinutes() {
        const type = selectedRecurrenceType !== "minute"
        //const recurrence = selectedRecurrence > 1

        return /*recurrence ||*/ type
    }

    function renderRecurrence() {
        const type = selectedRecurrenceType !== "minute" && selectedRecurrenceType !== "hour"

        return type
    }

    function translateRecurrenceToCron(recurrence, recurrenceType, dayOfTheWeek, dayOfTheMonth, hourOfTheDay, minuteOfTheHour) {
        let cron
        switch (recurrenceType) {
            case 'minute':
                cron = `0 */${recurrence} * ? * *`
                return cron
            case 'hour':
                cron = `0 ${minuteOfTheHour} */${recurrence} ? * *`
                return cron
            case 'day':
                cron = `0 ${minuteOfTheHour} ${hourOfTheDay} ? * *`
                return cron
            case 'week':
                cron = `0 ${minuteOfTheHour} ${hourOfTheDay} ? * ${dayOfTheWeek}`
                return cron
            case 'month':
                cron = `0 ${minuteOfTheHour} ${hourOfTheDay} ${dayOfTheMonth} * ?`
                return cron
            default:
                return undefined
        }
    }

    //TO DO
    function translateCronToRecurrence(cronExpression) {

        let parts = cronExpression.split(" ");

        if (parts.length === 6) { // enriched cron with seconds
            let recurrence = cronExpression.split("/")
            let cronRecurrence
            let cronRecurrenceType
            if (recurrence.length > 1) {
                cronRecurrence = recurrence[1].split(" ")[0]
                setSelectedRecurrence(cronRecurrence)
            }
            let cronMinuteOfTheHour = parts[1]

            let cronHourOfTheDay = parts[2]


            let cronDayOfTheWeek = parts[5]
            let cronDayOfTheMonth = parts[3]


            if (cronDayOfTheWeek !== "*" && cronDayOfTheWeek !== "?") {
                cronRecurrenceType = "week"
                setSelectedDayOfTheWeek(cronDayOfTheWeek)
                setSelectedMinuteOfTheHour(cronMinuteOfTheHour)
                setSelectedHourOfTheDay(cronHourOfTheDay)
            }
            if (cronDayOfTheMonth !== "*" && cronDayOfTheMonth !== "?") {
                cronRecurrenceType = "month"
                setSelectedDayOfTheMonth(cronDayOfTheMonth)
                setSelectedMinuteOfTheHour(cronMinuteOfTheHour)
                setSelectedHourOfTheDay(cronHourOfTheDay)
            }
            if (cronDayOfTheMonth === "?" && cronDayOfTheWeek === "*") {
                cronRecurrenceType = "day"
                setSelectedMinuteOfTheHour(cronMinuteOfTheHour)
                setSelectedHourOfTheDay(cronHourOfTheDay)
            }
            if (cronHourOfTheDay.includes("*")) {
                cronRecurrenceType = "hour"

                setSelectedHourOfTheDay(cronHourOfTheDay)
            }
            if (cronMinuteOfTheHour.includes("*")) {
                cronRecurrenceType = "minute"
                setSelectedMinuteOfTheHour(cronMinuteOfTheHour)
            }
            setSelectedRecurrenceType(cronRecurrenceType)
        }

    }




    return (
        <>
            <div elevation={12}
            >
                <CardContent >
                    <Grid container spacing={1}>
                    <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'> Name</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.recurrence}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            style={{ fontSize: '2px' }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                            InputProps={{ shrink: true, style: { fontSize: '12px' } }
                                            }
                                            id="standard-required" label="Schedule Name"  value={scheduleName}
                                            onChange={event=> setScheduleName(event.target.value)}
                                        />
                                    </FormControl>
                                </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'> Schedule</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.recurrence}>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        disabled={renderRecurrence()}
                                        value={selectedRecurrence}
                                        onChange={handleRecurrenceChange}
                                        inputProps={{
                                            name: 'selectedRecurrence',
                                            id: 'selectedRecurrence',
                                        }}
                                        style={{ width: '100%', }}
                                    >

                                        {dataForRecurrenceHelper.recurrence.map(element => {
                                            return (
                                                <MenuItem key={element.value} value={element.value}>
                                                    <Typography variant='caption'>{element.label}</Typography>
                                                </MenuItem>

                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={selectedRecurrenceType}
                                        onChange={handleRecurrenceTypeChange}
                                        inputProps={{
                                            name: 'selectedRecurrenceType',
                                            id: 'selectedRecurrenceType',
                                        }}
                                        style={{ width: '100%', }}>
                                        {dataForRecurrenceHelper.recurrenceType.map(element => {
                                            return (
                                                <MenuItem key={element.value} value={element.value}>
                                                    <Typography variant='caption'>{element.label}</Typography>
                                                </MenuItem>

                                            )
                                        })}
                                    </Select>
                                </FormControl>

                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'> Schedule Detail</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.recurrence}>



                                {selectedRecurrenceType && selectedRecurrenceType === "week" && < FormControl className={classes.formControl}>
                                    <Select
                                        value={selectedDayOfTheWeek}
                                        onChange={handleDayOfTheWeekChange}
                                        inputProps={{
                                            name: 'selectedRecurrenceType',
                                            id: 'selectedRecurrenceType',
                                        }}
                                        style={{ width: '100%', }}>
                                        {dataForRecurrenceHelper.daysOfTheWeek.map(element => {
                                            return (
                                                <MenuItem key={element.value} value={element.value}>
                                                    <Typography variant='caption'>{element.label}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>}
                                {selectedRecurrenceType && selectedRecurrenceType === "week" && < Typography className={classes.typography} >at</Typography>}
                                {selectedRecurrenceType && selectedRecurrenceType === "month" && <FormControl className={classes.formControl}>
                                    <Select
                                        value={selectedDayOfTheMonth}
                                        onChange={handleDayOfTheMonthChange}
                                        inputProps={{
                                            name: 'selectedRecurrenceType',
                                            id: 'selectedRecurrenceType',
                                        }}
                                        style={{ width: '100%', }}>
                                        {dataForRecurrenceHelper.daysOfTheMonth.map(element => {
                                            return (
                                                <MenuItem key={element.value} value={element.value}>
                                                    <Typography variant='caption'>{element.label}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>}

                                {renderHours() && <FormControl className={classes.formControl}>
                                    <Select
                                        value={selectedHourOfTheDay}
                                        onChange={handleHourChange}
                                        inputProps={{
                                            name: 'selectedRecurrenceType',
                                            id: 'selectedRecurrenceType',
                                        }}
                                        style={{ width: '100%', }}>
                                        {dataForRecurrenceHelper.hoursOfTheDay.map(element => {
                                            return (
                                                <MenuItem key={element.value} value={element.value}>
                                                    <Typography variant='caption'>{element.label}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>}
                                {renderMinuteHourSeparator() && < Typography className={classes.typography} >: </Typography>}
                                {renderMinutes() && <FormControl className={classes.formControl}>
                                    <Select
                                        value={selectedMinuteOfTheHour}
                                        onChange={handleminuteChange}
                                        inputProps={{
                                            name: 'selectedRecurrenceType',
                                            id: 'selectedRecurrenceType',
                                        }}
                                        style={{ width: '100%', }}>
                                        {dataForRecurrenceHelper.minutesOfTheHour.map(element => {
                                            return (
                                                <MenuItem key={element.value} value={element.value}>
                                                    <Typography variant='caption'>{element.label}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>}
                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={selectedTimezone}
                                        onChange={handleTimezoneChange}
                                        inputProps={{
                                            name: 'selectedRecurrenceType',
                                            id: 'selectedRecurrenceType',
                                        }}
                                        style={{ width: '100%', }}>
                                        {dataForRecurrenceHelper.timezones.map(element => {
                                            return (
                                                <MenuItem key={element.value} value={element.value}>
                                                    <Typography variant='caption'>{element.label}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'> Time(Cron) Expression</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.recurrence}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        style={{ fontSize: '2px' }}
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        InputProps={{ shrink: true, style: { fontSize: '12px' } }
                                        }
                                        id="standard-required" label="Resulting Cron" defaultValue="0 0 0 0 ? *" value={cron}
                                    />
                                </FormControl>
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'> Job Status</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div style={{ marginLeft: '-10px', marginBottom: '-10px' }}>
                                <FormControl className={classes.formControl}>
                                    <div className={classes.recurrence}>


                                        <Switch
                                            checked={activeCheckStatus}
                                            onChange={handleCheckChange}
                                            color="primary"
                                            name="checkedB"

                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        {activeCheckStatus && < Typography className={classes.rowTitleSmall} variant='caption'>schedule is active</Typography>}
                                        {!activeCheckStatus && < Typography className={classes.rowTitleSmall} variant='caption'>schedule is paused</Typography>}


                                    </div>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'> Environment</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.recurrence}>
                                <SelectEnvironmentComponentSchedule setNoEnvironmentError={props.setNoEnvironmentError} environmentOptions={props.environmentOptions} selectedEnvironment={selectedEnvironment} setSelectedEnvironment={setSelectedEnvironment} setSelectedOutputPath={setSelectedOutputPath} currentEnvironmentEdit={props.scheduleInfo && props.scheduleInfo.environment} />
                                {props.noEnvironmentError && <Typography style={{ color: "red" }} variant='body1'>{props.noEnvironmentError}</Typography>}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'>Output Path:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.recurrence}>
                                <OutputPathSelector selectedEnvironment={selectedEnvironment} selectedOutputPath={selectedOutputPath} setSelectedOutputPath={setSelectedOutputPath} setNoOutputPathError={props.setNoOutputPathError} />
                                {props.noOutputPathError && <Typography style={{ color: "red" }} variant='body1'>{props.noOutputPathError}</Typography>}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'>Alert Notification</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.recurrence}>
                                <ContactsSelector mailsToNotify={mailsToNotify} setMailsToNotify={setMailsToNotify} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'>Alert Configuration</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div style={{ marginTop: '10px' }}>
                                <div className={classes.recurrence}>
                                    <FormControlLabel className={classes.notificationCheck}
                                        control={
                                            <Checkbox
                                                checked={notifyOnStart}
                                                onChange={handleCheckChangeOnStart}
                                                name="notify on start"
                                                color="primary"
                                            />
                                        }
                                        label={<Typography variant="caption">
                                            notify on start
                                        </Typography>}
                                    />
                                    <FormControlLabel className={classes.notificationCheck}
                                        control={
                                            <Checkbox
                                                checked={notifyOnSuccess}
                                                onChange={handleCheckChangeOnSuccess}
                                                name="notify on success"
                                                color="primary"
                                            />
                                        }
                                        label={<Typography variant="caption">
                                            notify on success
                                        </Typography>}
                                    />
                                    <FormControlLabel className={classes.notificationCheck}
                                        control={
                                            <Checkbox
                                                checked={notifyOnFailure}
                                                onChange={handleCheckChangeOnFailure}
                                                name="notify on failure"
                                                color="primary"
                                            />
                                        }
                                        label={<Typography variant="caption">
                                            notify on failure
                                        </Typography>}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.rowTitle} variant='subtitle2'>Result Storage</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div style={{ marginTop: '10px' }}>
                                <div className={classes.recurrence}>
                                    <FormControlLabel className={classes.notificationCheck}
                                        control={
                                            <Checkbox
                                                checked={appendResult}
                                                onChange={handleCheckAppend}
                                                name="notify on success"
                                                color="primary"
                                            />
                                        }
                                        label={<Typography variant="caption">
                                            append
                                        </Typography>}
                                    />
                                    <FormControlLabel className={classes.notificationCheck}
                                        control={
                                            <Checkbox
                                                checked={overwriteResult}
                                                onChange={handleCheckOverwrite}
                                                name="notify on start"
                                                color="primary"
                                            />
                                        }
                                        label={<Typography variant="caption">
                                            overwrite
                                        </Typography>}
                                    />
                                    {/*  <FormControlLabel className={classes.notificationCheck}
                                        control={
                                            <Checkbox
                                                checked={partitionedResult}
                                                onChange={handleCheckPartitioned}
                                                name="notify on failure"
                                                color="primary"
                                            />
                                        }
                                        label={<Typography variant="caption">
                                            partitioned
                                        </Typography>}
                                    /> */}
                                </div>
                            </div>
                        </Grid>
                        {parameters && 
                                <Grid item xs={12}>
                                    <Divider/>
                                    <Typography variant="caption" style={{fontWeight:'bold'}}>Parameters to Fill</Typography>
                                    <Grid container spacing={1} >
                                    {parameters && parameters.map((parameter,paramIndex)=>{
                                        return (<Grid item xs={6}>
                                              <div >
                                              <Typography variant="caption" style={{marginRight:'10px'}}>{`@${parameter.name}`}</Typography>
                                              <TextField value={parameter.value}
                                               inputProps={{
                                                style: { fontSize: 14, fontFamily:commonStyles.fontFamily }
                                                }}
                                              error={(parameter.value===undefined  || parameter.value===null || parameter.value ==="") ? true : false} 
                                              helperText={(parameter.value===undefined  || parameter.value===null  || parameter.value.trim() ==="") ?  
                                              <Typography variant="caption" style= {{ fontSize: 12, fontFamily:commonStyles.fontFamily }}>A value must be set</Typography> : 
                                              ""}
                                              onChange={event=>updateParameter(paramIndex, event.target.value)}/>
                                              
                                              </div>
                                           
                                        </Grid>)
                                    })}
                                    </Grid>
                                </Grid>}
                    </Grid>
                </CardContent>
            </div >
        </>
    )
}