import React, { useState } from 'react'
import AqtivaDialog from '../../../AqtivaDialog'
import { Typography, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../../../../actions/index'
import { dispatchInfo, dispatchError } from 'components/common/axios/axiosHelper';
import * as scheduleHelper from 'commonFunctions/scheduleFunctions'



export default function ConfirmUpdateVersionDialog(props) {
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    //const [noEnvironmentError, setNoEnvironmentError] = useState(undefined)
    //const [noOutputPathError, setNoOutputPathError] = useState(undefined)


    async function createConfigurationForDS() {
        let datasource = {
            _id: props.selectedSchedule.datasourceId,
            name: props.selectedSchedule.datasourceName,
        }
        await scheduleHelper.deployQP2Cloud(qualityConfiguration, props.selectedSchedule.environment, props.selectedSchedule.projectId, props.selectedSchedule.qualityPointId, datasource, props.userId, dispatchError, dispatchInfo, dispatch)



        if (props.selectedSchedule && props.selectedSchedule.environment && props.selectedSchedule.storageDTO) {
            setLoading(true)

            await dispatch(actions.updateScheduleNewVersion(props.selectedSchedule._id, props.userId))

        }
        else {
            if (!props.selectedSchedule.environment) {
                //setNoEnvironmentError("Please select an enviroment")
                //TODO: nothing done with this set
            }
            /*if (!props.selectedSchedule.storageDTO) {
                //setNoOutputPathError("Please select an output path")
                //TODO: nothing done with this set
            }*/
        }

    }



    async function updateSchedule() {

        //primer paso: hay que crear la configuración para el datasource
        await createConfigurationForDS()



    }


    return (
        <AqtivaDialog
            visible={props.showConfirmUpdateDialog}
            dialog={props.showConfirmUpdateDialog}
            fullWidth={true}
            maxWidth={'sm'}
            title={"Update Schedule"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setShowConfirmUpdateDialog(false)
            }}
            confirmText={'Confirm'}
            confirmCallback={async () => {
                setLoading(true)
                await updateSchedule()
                await dispatch(actions.fetchProjects(props.userId))
                props.setShowConfirmUpdateDialog(false)
                setLoading(false)
                props.setUpdateCounter(props.updateCounter + 1)

            }}
        >
            {!loading && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                Are you sure you want to update this scheduled job?
            </Typography>}
            {loading && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                updating scheduled job... <CircularProgress style={{ width: '15px', height: '15px' }} />
            </Typography>}
        </AqtivaDialog>
    )
}