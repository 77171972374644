import {
    FETCH_CONTROL_VALUES,
    EDIT_CONTROL_VALUES
} from '../actions/types'
import _ from 'lodash';


export default (state = {}, action) => {

    switch (action.type) {
        case FETCH_CONTROL_VALUES:
            return { ..._.mapKeys(action.payload, '_id') }
            case EDIT_CONTROL_VALUES:
                return { ...state, [action.payload._id]: action.payload };
        default:
            return state;

    }
}