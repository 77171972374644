import React from 'react';

import {
    PieChart, Pie, ResponsiveContainer
} from 'recharts';

import * as colorHelper from './colorHelper'



export default function PaddedPieScore(props) {
    
    const [totalPercent, setTotalPercent] = React.useState(-1)
    const [scoreAngle, setScoreAngle] = React.useState(0)
    //const [datatoget, setDatatoget] = React.useState({ name: 'A1', value: -1 })
  
    React.useEffect(() => {
        if (props.data && props.data.scoreTotalChartDAO) {
            setTotalPercent(props.data.scoreTotalChartDAO.scoreTotal)
            setScoreAngle(props.data.scoreTotalChartDAO.scoreTotal===0 ? 0 : -props.data.scoreTotalChartDAO.scoreTotal* 358 / 100)
            //TODO: CHECK
            //setDatatoget({ name: 'A1', value: props.data.scoreTotalChartDAO.scoreTotal })
        }
    }, [props]);

  
   

   
    if (totalPercent === -1) {
        const data = [{ name: 'ko', value: 1 }];
        return (

            <div style={{height:props.height, position: 'relative'}}>
                <ResponsiveContainer width="100%"  >

                    <PieChart  width={props.smallWindowFlag===true ? 25 : 50} height={props.smallWindowFlag===true ? 25 : 50}>
                        <text fontSize={'1vw'} x={'50%'} y={props.height==='350px'? '57%' : '60%'} textAnchor="middle" dominantBaseline="middle">No Data</text>

                        <Pie
                            data={data}
                            startAngle={-0}
                            endAngle={-358}
                            innerRadius={props.smallWindowFlag===true ? 40 : 65}
                            outerRadius={props.smallWindowFlag===true ? 60 : 100}
                            cx="50%" cy="50%"
                            fill = {totalPercent===0 ? '#F3410DAA' : '#A2A2A2AA'}

                        >

                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                {colorHelper.getGlobalIcon(totalPercent)} 

            </div>
        )
    }
    else {
        const data = [{ name: 'ko', value: 1 }];
        return (
            <div style={{height:props.height, position: 'relative'}}>
                <ResponsiveContainer width="100%"  >

                    <PieChart  width={50} height={50} >
                        <text fontSize={'1.2vw'} x={'50%'} y={props.height==='350px'? '57%' : '60%'}  textAnchor="middle" dominantBaseline="middle">{`${totalPercent}%`}</text>

                        
                        <Pie
                            data={data}
                            startAngle={scoreAngle}
                            endAngle={-358}
                            innerRadius={props.smallWindowFlag===true ? 41 : 66}
                            outerRadius={props.smallWindowFlag===true ? 59 : 99}
                            fill = {totalPercent===0 ? '#F3410DAA' : '#A2A2A2AA'}
                            cx="50%" cy="50%"
                            paddingAngle={0}
                            dataKey="value"
                            

                        >

                        </Pie>
                        <Pie
                            data={data}

                            startAngle={-0}
                            endAngle={scoreAngle}
                            innerRadius={props.smallWindowFlag===true ? 40 : 65}
                            outerRadius={props.smallWindowFlag===true ? 60 : 100}
                            cx="50%" cy="50%"
                            fill={colorHelper.getColorForPercentage(totalPercent)}
                            paddingAngle={5}
                            dataKey="value"
                        >

                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
               {colorHelper.getGlobalIcon(totalPercent)} 

            </div>
        )
    }
}
