import React, {  useState } from 'react';
import AqtivaDialog from '../../../AqtivaDialog'
import { Typography, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import { faEdit } from '@fortawesome/free-solid-svg-icons'

import * as actions from './../../../../../actions/index'
import SchedulingConfigurationCard from '../../SchedulingConfigurationCard';
import * as scheduleHelper from 'commonFunctions/scheduleFunctions'
import { dispatchInfo, dispatchError, } from 'components/common/axios/axiosHelper';

export default function EditScheduleDialog(props) {
    const dispatch = useDispatch();
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))


    const [schedule, setSchedule] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [noOutputPathError, setNoOutputPathError] = useState(undefined)
    const [noEnvironmentError, setNoEnvironmentError] = useState(undefined)

    function validateSchedule(){
        if(schedule.name === undefined || schedule.name === null || schedule.name ===""){
            return false
        }
        return true
    }

    async function editSchedule() {
        if(validateSchedule() === false){
            return
        }
        if (props.scheduleInfo && schedule && schedule.environment && schedule.storageDTO) {
            setLoading(true)
            if ((props.scheduleInfo.environment !== schedule.environment) || (props.scheduleInfo.storageDTO !== schedule.storageDTO)) {
                await scheduleHelper.deployQP2Cloud(qualityConfiguration, schedule.environment, props.project._id, props.selectedQualityPoint._id, props.datasource, props.userId, dispatchError, dispatchInfo, dispatch)

                await dispatch(actions.editScheduleEnvironment(props.scheduleInfo.datasourceId, schedule.environment, schedule, props.userId))
                await dispatch(actions.fetchProjects(props.userId))
                props.setUpdateCounter(props.updateCounter + 1)
                props.setShowEditDialog(false)
                setLoading(false)
            }
            else {
                await dispatch(actions.editSchedule(props.scheduleInfo.datasourceId, props.scheduleInfo.environment, schedule, props.userId))
                await dispatch(actions.fetchProjects(props.userId))
                props.setUpdateCounter(props.updateCounter + 1)
                props.setShowEditDialog(false)
                setLoading(false)
            }
        }
        else {
            if (!schedule.environment) {
                setNoEnvironmentError("Please select an enviroment")
            }
           /* if (!schedule.storageDTO) {
                setNoOutputPathError("Please select an output path")
            }*/
        }
    }



    return (
        <>
            <AqtivaDialog
                visible={props.showEditDialog}
                dialog={props.showEditDialog}
                titleIcon={faEdit}
                fullWidth={false}
                maxWidth={'md'}
                title={"Edit Schedule"}
                editable={false}
                cancelText={'Cancel'}
                cancelCallback={() => {
                    props.setShowEditDialog(false)
                }}
                confirmText={'Edit Schedule'}
                confirmCallback={event => {
                    editSchedule()
                }}
            >
                <SchedulingConfigurationCard scheduleInfo={props.scheduleInfo} loading={loading} userId={props.userId} environmentOptions={props.environmentOptions} setSchedule={setSchedule} noOutputPathError={noOutputPathError} setNoOutputPathError={setNoOutputPathError} noEnvironmentError={noEnvironmentError}
                    setNoEnvironmentError={setNoEnvironmentError} />
                {loading && <Typography variant={'subtitle1'} style={{ textAlign: "center", marginTop: "20px" }}>
                    Updating schedule... <CircularProgress style={{ width: '25px', height: '25px' }} />
                </Typography>}
            </AqtivaDialog>
        </>
    )
}