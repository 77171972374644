import React from 'react'
import { Grid } from '@material-ui/core';
import DonutChartSmall from '../Charts/DonutChartSmall'
import HorizontalPercentChart from '../Charts/HorizontalPercentChart';

export default function DQIDimensionComponent(props) {

    const [formatDqi, setFormatDqi] = React.useState(-1)
    const [integrityDqi, setIntegrityDqi] = React.useState(-1)
    const [unicityDqi, setUnicityDqi] = React.useState(-1)
    const [validityDqi, setValidityDqi] = React.useState(-1)
    const [veracityDqi, setVeracityDqi] = React.useState(-1)

    const intRadiusMax = 95
    const intRadiusMin = 85
    const extRadiusMax= 100
    const extRadiusMin = 80

    function getDataByDimension(data, dimension) {
        if (data && data.length > 0 && data.filter(x => x.dimension === dimension).length > 0) {
            const dat = data.filter(x => x.dimension === dimension)[0]
            
            return (parseFloat(dat.sumNumOk) / parseFloat(dat.sumNumIn) * 100).toFixed(0)
        }
        return -1
    }

    React.useEffect(() => {

        if (props.DQIByDimension && props.DQIByDimension.length > 0) {

            setFormatDqi(getDataByDimension(props.DQIByDimension, "format"))
            setIntegrityDqi(getDataByDimension(props.DQIByDimension, 'integrity'))
            setUnicityDqi(getDataByDimension(props.DQIByDimension, 'unicity'))
            setValidityDqi(getDataByDimension(props.DQIByDimension, 'validity'))
            setVeracityDqi(getDataByDimension(props.DQIByDimension, 'veracity'))
        }

    }, [props.DQIByDimension]);

    return (

        <Grid container spacing={3} style={{ width: "100%", heigh: '250px', margin: '0.5%', marginBottom: '1.5%', marginLeft: '0.5%', marginRight: '1.5%' }}>

            <Grid item xs={4} style={{ height: '50%' }}>
                <Grid container spacing={3} style={{ width: "100%", marginLeft: '1.5%' }}>
                    <Grid item xs={4}>
                        <DonutChartSmall data={formatDqi} showCenterLabel={false} chart={'chartDonutSmall1'} click={false} 
                        height={'100px'}  extRadiusMax={extRadiusMax} extRadiusMin={extRadiusMin} intRadiusMax={intRadiusMax} intRadiusMin={intRadiusMin}/>
                    </Grid>

                    <Grid item xs={8} >
                        <span style={{ marginLeft: '35%', marginBottom: '0%' }}>Format</span>
                        <HorizontalPercentChart data={formatDqi} chart={'chartHorizontal1'} height={'70px'} />
                    </Grid>
                </Grid>
            </Grid >


            <Grid item xs={4} style={{ height: '50%' }}>
                <Grid container spacing={3} style={{ width: "100%", marginLeft: '1.5%' }}>
                    <Grid item xs={4}>
                        <DonutChartSmall data={integrityDqi} showCenterLabel={false} chart={'chartDonutSmall2'} click={false}
                         height={'100px'} extRadiusMax={extRadiusMax} extRadiusMin={extRadiusMin} intRadiusMax={intRadiusMax} intRadiusMin={intRadiusMin}/>
                    </Grid>
                    <Grid item xs={8} >
                        <span style={{ marginLeft: '35%', marginBottom: '0%' }}>Integrity</span>
                        <HorizontalPercentChart data={integrityDqi} chart={'chartHorizontal2'} height={'70px'} />
                    </Grid>
                </Grid>
            </Grid >


            <Grid item xs={4} style={{ height: '50%' }}>
                <Grid container spacing={3} style={{ width: "100%", marginLeft: '1.5%' }}>
                    <Grid item xs={4} >
                        <DonutChartSmall data={unicityDqi} showCenterLabel={false} chart={'chartDonutSmall3'} click={false} height={'100px'} 
                        extRadiusMax={extRadiusMax} extRadiusMin={extRadiusMin} intRadiusMax={intRadiusMax} intRadiusMin={intRadiusMin}/>
                    </Grid>
                    <Grid item xs={8} >
                        <span style={{ marginLeft: '35%', marginBottom: '0%' }}>Unicity</span>
                        <HorizontalPercentChart data={unicityDqi} chart={'chartHorizontal3'} height={'70px'} />
                    </Grid>
                </Grid>
            </Grid >


            <Grid item xs={6} style={{ height: '50%' }}>
                <Grid container spacing={3} style={{ width: "100%", marginLeft: '1.5%' }}>
                    <Grid item xs={4}>
                        <DonutChartSmall data={validityDqi} showCenterLabel={false} chart={'chartDonutSmall4'} click={false} height={'100px'} 
                        extRadiusMax={extRadiusMax} extRadiusMin={extRadiusMin} intRadiusMax={intRadiusMax} intRadiusMin={intRadiusMin}/>
                    </Grid>
                    <Grid item xs={8}>
                        <span style={{ marginLeft: '15%', marginBottom: '0%' }}>Validity</span>
                        <HorizontalPercentChart data={validityDqi} chart={'chartHorizontal4'} height={'70px'} />
                    </Grid>
                </Grid >
            </Grid >

            <Grid item xs={6} style={{ height: '50%' }}>
                <Grid container spacing={3} style={{ width: "100%", marginLeft: '1.5%' }}>
                    <Grid item xs={4}>
                        <DonutChartSmall data={veracityDqi} showCenterLabel={false} chart={'chartDonutSmall5'} click={false} height={'100px'}
                        extRadiusMax={extRadiusMax} extRadiusMin={extRadiusMin} intRadiusMax={intRadiusMax} intRadiusMin={intRadiusMin} />
                    </Grid>
                    <Grid item xs={8}>
                        <span style={{ marginLeft: '15%', marginBottom: '0%' }}>Veracity</span>
                        <HorizontalPercentChart data={veracityDqi} chart={'chartHorizontal5'} height={'70px'} />
                    </Grid>
                </Grid >
            </Grid >
        </Grid>

    )
}