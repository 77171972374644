import React from 'react';
import {
    TextField, Typography, Grid, FormControlLabel
} from '@material-ui/core';


import AqtivaDialogEncryptString from 'components/common/AqtivaDialogEncryptString'




export default function AWSConnectionInfo(props) {


    const [region, setRegion] = React.useState('')
    const [accessKey, setAccessKey] = React.useState('')
    const [bucketName, setBucketName] = React.useState('')
    const [visibleEncrypt, setVisiblevisibleEncrypt] = React.useState(false)
    const [encryptedSecretKey, setEncryptedSecretKey] = React.useState('')

    React.useEffect(() => {
        let connectionObject = {}
        connectionObject.region = region
        connectionObject.accessKey = accessKey
        connectionObject.encryptedSecretKey = encryptedSecretKey
        connectionObject.bucketName = bucketName
        props.setConnectionObjectAWS(connectionObject)
    }, [region, accessKey, encryptedSecretKey, props.displayName])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div style={{ width: '100%' }}>
                <AqtivaDialogEncryptString visible={visibleEncrypt}
                    connectionMethod={props.connectionMethod}
                    setVisible={setVisiblevisibleEncrypt}
                    confirm={setEncryptedSecretKey}
                    sName={props.id && props.id + "localsttkk"}
                />
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display Name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="DisplayName"
                                label="Display name"
                                margin="dense"
                                value={props.displayName}
                                onChange={(event) => { props.setDisplayName(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Region:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="region"
                                label="Region"
                                margin="dense"
                                value={region}
                                onChange={(event) => { setRegion(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Bucket Name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="region"
                                label="Bucket Name"
                                margin="dense"
                                value={bucketName}
                                onChange={(event) => { setBucketName(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Access Key:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="accessKey"
                                id="AccessKey"
                                label="AccessKey"
                                margin="dense"
                                value={accessKey}
                                onChange={(event) => { setAccessKey(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Secret Key:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="secretKey"
                                id="secretKey"
                                label="secretKey"
                                margin="dense"
                                value={encryptedSecretKey}
                                onClick={(event) => { setVisiblevisibleEncrypt(true) }}
                            />} autoComplete="new-password" />
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        autoComplete="new-password"
                        control={
                            <TextField style={{ width: '100%' }}
                                autoComplete="new-password"
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="Password"
                                label="Password"
                                margin="dense"
                                type="password"
                                value={encryptedSecretKey}
                                onClick={(event) => { setVisiblevisibleEncrypt(true) }}
                            />} />
                </Grid> */}

            </div>
        </>
    )
}