import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DimensionKPI from '../commons/DimensionKPI';
import CHartBarsRules from '../commons/ChartBarsRules';
import PieChartRules from '../commons/PieChartRules';
import ChartLineBiaxial from '../commons/ChartLineBiaxial';
import PaddedPieScoreFull from '../commons/PaddedPieScoreFull';

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    selectedPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        cursor:'pointer',
        borderColor: commonStyles.mainColor,
        borderWidth: '10px',
    },
    elementAdjacent: {
        display: 'flex',
        justifyContent: 'left'
    },
}));

export default function QualityDashboardMonitoring(props) {

    const classes = useStyles();
    //const [tiny, setTiny] = React.useState(props.innerWidth <1368 ? true : false)
    const [veryTiny, setVeryTiny] = React.useState(props.innerWidth <1068 ? true : false)
    const [smallWindowFlag, setSmallWindowFlag] = React.useState(getSmallWindow(props.innerWidth <1368 ? true : false))


    const percentPaper = '5%'

    var percentValueIntegrity = '-1'
    var percentValueValidity = '-1'
    var percentValueUnicity = '-1'
    var percentValueVeracity = '-1'
    var percentValueFormat = '-1'

    if(props.simulationData && props.simulationData.dimensionKPIChartDAOList){
          if(props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "integrity")){
            percentValueIntegrity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "integrity").percentOKDimension
          }
          if(props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "validity")){
            percentValueValidity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "validity").percentOKDimension

          }
          if(props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "unicity")){
            percentValueUnicity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "unicity").percentOKDimension

          }
          if(props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "veracity")){
            percentValueVeracity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "veracity").percentOKDimension

          }
          if(props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "format")){
            percentValueFormat = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "format").percentOKDimension

          }

          
    }

    React.useEffect(() => {
        //setTiny(props.innerWidth <1368 ? true : false)
        setVeryTiny(props.innerWidth <1068 ? true : false)
        setSmallWindowFlag(getSmallWindow(props.innerWidth <1368 ? true : false))
    }, [props.innerWidth, props.openFilter]);// eslint-disable-line react-hooks/exhaustive-deps

    function getSmallWindow(tiny){
 
        if((tiny===true && props.openFilter===true)|| veryTiny===true){
            return true
        }
        return false
    }

    function createDimensionArray(){
        var arrayDimension = []
        if(props.selectedDimIntegrity===true){
                arrayDimension.push('integrity')
        }
        if(props.selectedDimUnicity===true){
            arrayDimension.push('unicity')
        }
        if(props.selectedDimValidity===true){
            arrayDimension.push('validity')
        }
        if(props.selectedDimVeracity===true){
            arrayDimension.push('veracity')
            arrayDimension.push('format')
        }
        return arrayDimension
    }

    function filterRuleByDimension(obj, element, dName){
        if(obj===undefined || obj===null){
            return undefined
        }
        const clone = JSON.parse(JSON.stringify(obj));
        let selectedDimensions = createDimensionArray()
        var newValue = clone[element]
           if(selectedDimensions.length>0 && newValue && newValue.length>0){
            clone[element] = newValue.filter(x=>selectedDimensions.includes(x[dName]))
        }
        return clone
    }
    /*
    function filterRawData(obj, element){
        if(obj===undefined){
            return undefined
        }
        const clone = JSON.parse(JSON.stringify(obj));
        let selectedDimensions = createDimensionArray()
        var newValue = clone
        if(selectedDimensions.length>0 && newValue && newValue.length>0){
            return  newValue.filter(x=>selectedDimensions.includes(x.dimension))
        }
        return newValue
    }
*/
    function filterTimeSeriesData(obj, element){
        if(obj===undefined){
            return undefined
        }
        const clone = JSON.parse(JSON.stringify(obj));
        let selectedDimensions = createDimensionArray()
        var newValue = clone[element]
        if(selectedDimensions.length>0 && newValue && newValue.length>0){
            clone[element]=  newValue.filter(x=>selectedDimensions.includes(x.dimension))
        }

        return clone
    }

    function toggleDimValidity(){
        if(props.selectedDimValidity===true && percentValueValidity!=='-1'){
            props.setSelectedDimValidity(false)
        }
        else{
            if(percentValueValidity!=='-1'){
                props.setSelectedDimValidity(true)
            }
           
        }
    }

    function toggleDimVeracity(){
        if(props.selectedDimVeracity===true && (percentValueVeracity!=='-1'|| percentValueFormat!=='-1')){
            props.setSelectedDimVeracity(false)
        }
        else{
            if(percentValueVeracity!=='-1'|| percentValueFormat!=='-1')
            props.setSelectedDimVeracity(true)
        }
    }

    function toggleDimUnicity(){
        if(props.selectedDimUnicity===true && percentValueUnicity!=='-1'){
            props.setSelectedDimUnicity(false)
        }
        else{
            if(percentValueUnicity!=='-1')
            props.setSelectedDimUnicity(true)
        }
    }

    function toggleDimIntegrity(){
        if(props.selectedDimIntegrity===true && percentValueIntegrity!=='-1'){
            props.setSelectedDimIntegrity(false)
        }
        else{
            if(percentValueIntegrity!=='-1')
            props.setSelectedDimIntegrity(true)
        }
    }
    
    
     return (
        <div style={{width: props.openFilter === false ? '100%' : '80%'}}>
            
           
            <Grid container spacing={3}   justify='space-evenly'>
            <Grid item xs={3} >
                    <Paper className={classes.paper} style={{ padding: '0px', width: '100%' ,height:'368px'}}>
                        <div style={{ marginLeft: percentPaper, marginRight: percentPaper }}>
                            <Typography variant='subtitle2'>Total QualityPoints Score</Typography>
                            <PaddedPieScoreFull
                                height='350px'
                                smallWindowFlag={smallWindowFlag}
                                data={props.simulationData} />
                        </div>

                    </Paper>
                </Grid>
                <Grid item xs={9} >
                <Grid container spacing={3}   justify='space-evenly'>
                <Grid item xs={12} >
                <Paper className={classes.paper} style={{padding:'0px', width:'100%',minHeight:'100px'}}>
                <Typography variant='subtitle2'>Dimension Scores</Typography>
                    <Grid container spacing={3} justify='space-evenly'>

                        <Grid item xs={3} >
                            <Paper style={{marginLeft:'5%', padding: '0px', width: '90%',
                            cursor : percentValueValidity!=='-1' ? 'pointer' : 'default' }}
                             className={props.selectedDimValidity===true ? classes.selectedPaper : classes.paper} 
                             elevation={props.selectedDimValidity===true ? 12 : 1}
                            onClick={toggleDimValidity}>
                                <DimensionKPI percentValue={percentValueValidity} dimension={'Validity'} height={'70px'} smallWindowFlag={smallWindowFlag}
                                tiny={true} 
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={3} >
                            <Paper  style={{marginLeft:'5%', padding: '0px', width: '90%',
                             cursor : percentValueUnicity!=='-1' ? 'pointer' : 'default' }}
                            className={props.selectedDimUnicity===true ? classes.selectedPaper : classes.paper} 
                            elevation={props.selectedDimUnicity===true ? 12 : 1}
                            onClick={toggleDimUnicity}>
                                <DimensionKPI percentValue={percentValueUnicity} dimension={'Unicity'} height={'70px'} smallWindowFlag={smallWindowFlag}
                                tiny={true} />
                            </Paper>
                        </Grid>
                        <Grid item xs={3} >
                            <Paper  style={{ marginLeft:'5%',padding: '0px', width: '90%',
                            cursor : percentValueIntegrity!=='-1' ? 'pointer' : 'default' }}
                             className={props.selectedDimIntegrity===true ? classes.selectedPaper : classes.paper} 
                             elevation={props.selectedDimIntegrity===true ? 12 : 1}
                             onClick={toggleDimIntegrity}>
                                <DimensionKPI percentValue={percentValueIntegrity} dimension={'Integrity'} height={'70px'} smallWindowFlag={smallWindowFlag}
                                tiny={true} />
                            </Paper>
                        </Grid>
                        <Grid item xs={3} >
                            <Paper className={classes.paper} style={{marginLeft:'5%', padding: '0px', width: '90%',
                            cursor : (percentValueVeracity!=='-1'|| percentValueFormat!=='-1') ? 'pointer' : 'default' }}
                            //className={props.selectedDimVeracity===true ? classes.selectedPaper : classes.paper} 
                            elevation={props.selectedDimVeracity===true ? 12 : 1}
                            onClick={toggleDimVeracity}>
                                <DimensionKPI percentValue={percentValueFormat} dimension={'Veracity'} height={'70px'} smallWindowFlag={smallWindowFlag}
                                tiny={true} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
                </Grid>
                <Grid item xs={12}>
                <Paper className={classes.paper} style={{ minHeight: '100px' }}>
                    <Typography variant='subtitle2'>EventRules Processed</Typography>
                        <ChartLineBiaxial data={
                            (props.selectedDimIntegrity===false && props.selectedDimUnicity===false && props.selectedDimValidity===false 
                                && props.selectedDimVeracity===false) ? 
                            props.simulationData : filterTimeSeriesData(props.simulationData,'percentTimeSeriesDimensionChartDAO')
                            }
                            dimensionKPI = {!(props.selectedDimIntegrity===false && props.selectedDimUnicity===false && props.selectedDimValidity===false 
                                && props.selectedDimVeracity===false)}
                            selectedDimIntegrity = {props.selectedDimIntegrity}
                            selectedDimUnicity = {props.selectedDimUnicity}
                            selectedDimValidity = {props.selectedDimValidity}
                            selectedDimVeracity = {props.selectedDimVeracity}    />
                    </Paper>
                </Grid>
                </Grid>
                
                

                </Grid>


                
                
                <Grid item xs={6}>
                <Paper className={classes.paper} style={{height: '350px'}}>
                        <div style={{ marginLeft: percentPaper, marginRight: percentPaper }}>
                            <Typography variant='subtitle2'>Rules by dimension</Typography>
                            <PieChartRules piechartrules={'PieRules'}
                                height='300px'
                                smallWindowFlag={smallWindowFlag}
                                data = {props.simulationData && filterRuleByDimension(props.simulationData.rulesByDimensionChartDAO,'rulesByDimensionDAO','dimensionName')}
                                aspect={4}
                            />
                        </div></Paper>
                </Grid>
                
                

              
                <Grid item xs={6}>
                     <Paper className={classes.paper} style={{height: '350px'}}>
                        <div style={{ marginLeft: percentPaper, marginRight: percentPaper }}>
                            <Typography variant='subtitle2'>Rules OK/KO</Typography>
                            <CHartBarsRules barRules={'Rules'}
                                data = { props.simulationData && filterRuleByDimension(props.simulationData.ruleNamePercentsChartDAO,'ruleNamePercentsDAO','dimension')}
                                height= '300px'
                            />
                        </div></Paper>
                </Grid>
                
            </Grid>


        </div >

    );
}