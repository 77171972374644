import React from 'react'
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Typography, FormControl, Input } from '@material-ui/core'
import {  useDispatch } from "react-redux";
import * as actions from 'actions';


export default function DialogCommitProjectVersion(props) {
    const [commitMessage, setCommitMessage] = React.useState('')
    const [errorInputText, setErrorInputText] = React.useState('')
    const dispatch = useDispatch()

    const handleChangeCommitMessage = (event, value) => {
        setCommitMessage(event.target.value)
    }
    

    return (
        <AqtivaDialog
            // Confirm Dialog
            visible={props.showCommitDialog}
            dialog={props.showCommitDialog}
            fullWidth={true}
            maxWidth={'sm'}
            title={'Commit current project version'}
            editable={false}
            titleIcon={faSave}
            cancelText={'Cancel'}
            confirmText={'Commit'}
            cancelCallback={() => {
                props.setShowCommitDialog(false)
                setCommitMessage('')
                setErrorInputText('')
            }}
            confirmCallback={event => {
                let validateMessage = commitMessage.replace(/ /g,'')
                if (validateMessage !== "") {
                    const commitDTO = {
                        commitMessage: commitMessage,
                        qualityProject: props.project
                    }
                    if(props.user && props.user.id){
                        dispatch(actions.addCommitVersion(props.project._id, commitDTO, props.user.id))
                        props.setShowCommitDialog(false)
                        setErrorInputText("")
                        setCommitMessage("")
                    }
                }
                else {
                    setErrorInputText("Commit message can't be empty.")
                }


            }}
        >
            <FormControl>
                <Input value={commitMessage} onChange={handleChangeCommitMessage} placeholder="commit message" />
                {errorInputText && errorInputText !== "" && <Typography variant='standard' style={{ fontSize: "12px", marginTop: "0.8rem", marginLeft: "3rem", color: "#ab2e27" }} >{errorInputText}</Typography>}
            </FormControl>

        </AqtivaDialog>
    )
}