import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { QualityTheme } from 'style/CustomTheme';
import { Button, Typography } from '@material-ui/core';
import DataformatTable from './DataformatTable'
import DataformatDetail from './DataformatDetail'
import DataformatAddProfiling from './DataformatAddProfiling'
import DataformatProfiling from './DataformatProfiling'

import DataformatProjectGraph from './DataformatProjectGraph'
import DialogCreateDataFormat from 'components/dialogs/DialogCreateDataFormat'
import DialogSelectAzureSource from 'components/dialogs/DialogSelectAzureSource';
import DialogViewDataformatVersions from 'components/dialogs/DialogViewDataformatVersions';
import DialogShowIdDataformat from 'components/dialogs/DialogShowIdDataformat';
import * as renderHelper from './utils'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { dispatchError } from "components/common/axios/axiosHelper";


import { useSelector, useDispatch } from 'react-redux'

import * as actions from 'actions'

import { useHistory } from 'react-router-dom'
import DialogSetUserPwd from 'components/dialogs/DialogSelectAzureSource/common/DialogSetUserPwd';
import { LanguageContext } from 'language/LanguageContext'

import { isEmpty } from 'commonFunctions/commonFunctions'

export default function QualityDatasetRepository(props) {
  const languageContext = React.useContext(LanguageContext);

  const history = useHistory();
  const license = useSelector((store) => store.license);
  const user = useSelector(store => store.user)


  const [showDialogDatasetDetail, setShowDialogDatasetDetail] = React.useState(false)
  const [showDialogDatasetProfiling, setShowDialogDatasetProfiling] = React.useState(false)
  const [showProjectUseDetailDialog, setShowProjectUseDetailDialog] = React.useState(false)
  const [selectedDataset, setSelectedDataset] = React.useState(undefined)
  const [selectedDatasetForExportation, setSelectedDatasetForExportation] = React.useState(undefined)
  const [selectedDatasetForExportationId, setSelectedDatasetForExportationId] = React.useState(undefined)
  const [selectedDatasetToViewVersions, setSelectedDatasetToViewVersions] = React.useState(undefined)
  //const [selected, setSelected] = React.useState([])
  //const [projects, setProjects] = React.useState([])

  //TODO: check
  //const [selectedDataformatSource, setSelectedDataformatSource] = React.useState(undefined)
  //const [selectedDataformatUser, setSelectedDataformatUser] = React.useState(undefined)
  const selectedDataformatSource = undefined
  const selectedDataformatUser = undefined

  const [selectedDataformatToAddProfiling, setSelectedDataformatToAddProfiling] = React.useState(undefined)
  const [showAddProfilingDialog, setShowAddProfilingDialog] = React.useState(false)


  const [showDialogCreateDataformatAzure, setShowDialogCreateDataformatAzure] = React.useState(false)
  const [showDialogCreateDataformat, setShowDialogCreateDataformat] = React.useState(false)
  const [showDialogCreateNewDataFormatVersion, setShowDialogCreateNewDataFormatVersion] = React.useState(false)
  const [showDialogCreateDataformatNewVersionFromLocalFile, setShowDialogCreateDataformatNewVersionFromLocalFile] = React.useState(false)
  const [showDialogViewDataformatVersions, setShowDialogViewDataformatVersions] = React.useState(false)
  const [showDialogIdDataformat, setShowDialogIdDataformat] = React.useState(false)
  const [viewAsUser, setViewAsUser] = React.useState(props.selectDatasetDialog === true ? true : false)
  const [viewOnlyProfiling, setViewOnlyProfiling] = React.useState(false)
  const [idDataset, setIdDataset] = React.useState('')
  const [showProjectUserAndPwd, setShowProjectUserAndPwd] = React.useState(false)


  const dataformats = useSelector(store => Object.values(store.dataformats))
  const dataformatsAll = useSelector(store => Object.values(store.dataformatsAll))

  const dispatch = useDispatch()

  /*
    React.useEffect(() => {
      if (props.licenseUsers) {
        setLicenseUsers(props.licenseUsers)
      }
    }, [JSON.stringify(props.licenseUsers)])// eslint-disable-line react-hooks/exhaustive-deps
  */
  React.useEffect(() => {
    if (!isEmpty(user)) {
      //if ((user.role === 'USER' || viewAsUser === true)) {
      dispatch(actions.fetchDataFormats(user))
     
      if (props.notificationDatasetId && dataformats.length !== 0) {
        viewDatasetProfiling(props.notificationDatasetId)
      }
    }
  }, [JSON.stringify(user), JSON.stringify(props.notificationDatasetId), JSON.stringify(dataformats)])// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    dispatch(actions.fetchUser(history))
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(actions.fetchAllDataFormats(user))
     
      
    }
  }, [JSON.stringify(user)])// eslint-disable-line react-hooks/exhaustive-deps



/**
 * Function to translate an userId to its name
 * @param {*} userId 
 * @param {*} licenseUsers_ deprecated, it uses license object now
 * @returns string with user name or not found message
 */
  function translateUserIdToName(userId, licenseUsers_) {
    if (license !== undefined && license.users!==undefined && license.users.length > 0) {
      const licenseUsers = license.users
      for (let i = 0; i < licenseUsers.length; i++) {
        if (licenseUsers[i].id === userId) {
          return licenseUsers[i].name
        }
      }
      return `User with id ${userId} not found`
    }
    return `User with id ${userId} not found`


  }

  function addInternalProfilingNew(rowData) {
    setSelectedDataformatToAddProfiling(rowData)
    setShowAddProfilingDialog(true)
  }

  /*function handleSelectSource(event) {
    setSelectedDataformatSource(event.target.value)
  }
  function handleSelectOwner(event) {

    setSelectedDataformatUser(event.target.value)

  }*/

  function handleSelectDatasetForExportation(id) {
    dispatch(actions.fetchDataformat(id)).then(response => {
      setSelectedDatasetForExportation(response.data)
    })

  }
  function handleSelectDatasetForExportationId(id) {
    setSelectedDatasetForExportationId(id)
    props.handleSelectDatasetForExportationId(id)
  }

  function viewDatasetDetails(id) {
    if ((user.role === 'USER' || viewAsUser === true)) {
      getSelectedDatasource(id)
      setShowDialogDatasetDetail(true)

    }
    else {
      getSelectedDatasource(id)
      setShowDialogDatasetDetail(true)

    }
  }

  function getSelectedDatasource(id) {
    dispatch(actions.fetchDataformat(id)).then(response => {
      setSelectedDataset(response.data)
    })
  }

  function viewDatasetProfiling(id) {
    if ((user.role === 'USER' || viewAsUser === true)) {
      getSelectedDatasource(id)
      setShowDialogDatasetProfiling(true)

    }
    else {
      getSelectedDatasource(id)
      setShowDialogDatasetProfiling(true)

    }

  }
  /*
  function addDataformatVersion(id) {
    if ((user.role === 'USER' || viewAsUser === true)) {
      setSelectedDataset(dataformats ? dataformats.find(x => x._id === id) : undefined)

    }
    else {
      setSelectedDataset(dataformatsAll ? dataformatsAll.find(x => x._id === id) : undefined)

    }
    showDialogDatasetDetail(true)
  }*/

  function viewDatasetUseInProjects(id) {
    getSelectedDatasource(id)
    setShowProjectUseDetailDialog(true)
  }

  function viewUpdateUserAndPwd(id) {


    getSelectedDatasource(id)
    setShowProjectUserAndPwd(true)

  }

  /*
  function viewCreateDataformatAzure() {
    setShowDialogCreateDataformatAzure(true)
  }*/

  function viewCreateDataformat() {
    setShowDialogCreateDataformat(true)
  }
  function viewCreateNewDataformatVersionLocal() {
    setShowDialogCreateDataformatNewVersionFromLocalFile(true)
  }
  function viewCreateNewDataFormatVersionAzure(id) {
    getSelectedDatasource(id)
    setShowDialogCreateNewDataFormatVersion(true)
  }

  function viewCreateNewDataFormatVersion(id) {
    getSelectedDatasource(id)
    setShowDialogCreateNewDataFormatVersion(true)
  }

  function viewDisplayDataFormatVersions(id) {
    dispatch(actions.fetchDataformat(id)).then(response => {
      setSelectedDatasetToViewVersions(response.data)
    })
    setShowDialogViewDataformatVersions(true)
  }

  function viewDisplayShowIdDataformat(id){
    setIdDataset(id)
    setShowDialogIdDataformat(true)
  }

  function onCloseDetailDialog() {
    setShowDialogDatasetDetail(false)
  }
  function onCloseAddProfilingDialog() {
    setShowAddProfilingDialog(false)
  }

  function onCloseProfilingDialog() {
    setShowDialogDatasetProfiling(false)
  }

  function onCloseCreateDataformat() {
    setShowDialogCreateDataformat(false)
  }
  function onCloseCreateNewDataformatVersionLocal() {
    setShowDialogCreateDataformatNewVersionFromLocalFile(false)
  }
  function onCloseCreateNewDataFormatAzureVersion() {
    setShowDialogCreateNewDataFormatVersion(false)
  }
  function onCloseCreateDataformatAzure() {
    setShowDialogCreateDataformatAzure(false)
  }
  function onCloseDisplayDataformatVersions() {
    setShowDialogViewDataformatVersions(false)
  }

  function onCloseProjectUseDetailDialog() {
    setShowProjectUseDetailDialog(false)
  }

  function onCloseShowIdDataformat(){
    setShowDialogIdDataformat(false)
  }

  /*
    function clean() {
      //TODO:VARIABLE NOT USED
      //setSelected([])
  
    }
  */

  function onDeleteUnused() {
    dispatch(actions.deleteUnusedDataformats(user))
  }

  function onAddDataFormat() {
    setShowDialogCreateDataformatAzure(true)
  }

  /*
  function onClose() {
    props.onHide();
    clean();
  }*/






  function handleChangeViewAsUser(event) {
    setViewAsUser(event.target.checked)

    if (!isEmpty(user)) {
      if ((user.role === 'USER' || viewAsUser === true)) {
        dispatch(actions.fetchDataFormats(user))
      }
      else {
        dispatch(actions.fetchAllDataFormats(user))
      }
    }

  }

  function setUserPwd(user, pwd) {
    var newDataformat = JSON.parse(JSON.stringify(selectedDataset))
    if (newDataformat && newDataformat.sqlDatabaseProperties) {
      newDataformat.sqlDatabaseProperties.user = user
      newDataformat.sqlDatabaseProperties.user = pwd
    }
    else {
      if (newDataformat === undefined) {
        dispatchError(dispatch, "No dataformat selected")
      }
      else {
        dispatchError(dispatch, "No SQL properties found, dataset is in a wrong state")
      }
    }

    //selectedDataset has the dataformat

    //update

    dispatch(actions.editDataformat(selectedDataset))
  }

  function storeUserPwd(user, pwd) {
    //selectedDataset has the dataformat
    setUserPwd(user, pwd)

  }


  const { classes } = props;
  return (
    <MuiThemeProvider theme={QualityTheme}>

      <DataformatDetail show={showDialogDatasetDetail}
        dataset={selectedDataset}
        onCloseDetailDialog={onCloseDetailDialog}
      />
      <DataformatAddProfiling show={showAddProfilingDialog}
        dataset={selectedDataformatToAddProfiling}
        onCloseDetailDialog={onCloseAddProfilingDialog}
      />

      <DataformatProfiling show={showDialogDatasetProfiling}
        dataset={selectedDataset}
        onCloseProfilingDialog={onCloseProfilingDialog}
      />

      <DataformatProjectGraph
        userId={props.userId}
        history={props.history}
        show={showProjectUseDetailDialog}
        size={"large"}
        projectsUsedDetail={props.usedDatasourcesByProjects && renderHelper.splitUsedProjectsIntoOwnAndOthers(props.usedDatasourcesByProjects, selectedDataset, props.userId)}
        dataformat={selectedDataset}
        onClose={onCloseProjectUseDetailDialog}
      />

      {showDialogCreateDataformatAzure &&
        <DialogSelectAzureSource visible={showDialogCreateDataformatAzure} setVisible={onCloseCreateDataformatAzure}
          setLocalFileDialogVisible={viewCreateDataformat} />
      }
      {showDialogCreateDataformat &&
        <DialogCreateDataFormat
          configuration={props.configuration}
          open={showDialogCreateDataformat}
          setClose={onCloseCreateDataformat} />}

      {showProjectUserAndPwd &&
        <DialogSetUserPwd
          visible={showProjectUserAndPwd} setVisible={setShowProjectUserAndPwd}
          setUserPwd={setUserPwd}
          storeUserPwd={storeUserPwd} />
      }




      {/*---------------------------------------- dialogos de versiones------------------------------*/}
      {showDialogCreateNewDataFormatVersion &&
        <DialogSelectAzureSource
          createNewVersion={true}
          selectedDataset={selectedDatasetToViewVersions}
          visible={showDialogCreateNewDataFormatVersion} setVisible={onCloseCreateNewDataFormatAzureVersion}
          closeDialogDisplay={onCloseDisplayDataformatVersions}
          setLocalFileDialogVisible={viewCreateNewDataformatVersionLocal} />}

      {showDialogCreateNewDataFormatVersion &&
        <DialogSelectAzureSource
          createNewVersion={true}
          selectedDataset={selectedDatasetToViewVersions}
          visible={showDialogCreateNewDataFormatVersion} setVisible={onCloseCreateNewDataFormatAzureVersion}
          closeDialogDisplay={onCloseDisplayDataformatVersions}
          setLocalFileDialogVisible={viewCreateNewDataformatVersionLocal} />}

      {showDialogCreateDataformatNewVersionFromLocalFile &&
        <DialogCreateDataFormat
          createNewVersion={true}
          selectedDataset={selectedDatasetToViewVersions}
          configuration={props.configuration}
          open={showDialogCreateDataformatNewVersionFromLocalFile}
          closeAzureItemDialog={onCloseCreateNewDataFormatAzureVersion}
          closeDialogDisplay={onCloseDisplayDataformatVersions}
          setClose={onCloseCreateNewDataformatVersionLocal} />}

      {showDialogViewDataformatVersions &&
        <DialogViewDataformatVersions
          viewCreateNewDataFormatVersionAzure={viewCreateNewDataFormatVersionAzure}
          createNewVersion={true}
          selectedDataset={selectedDatasetToViewVersions}
          configuration={props.configuration}
          open={showDialogViewDataformatVersions}
          setClose={onCloseDisplayDataformatVersions}

          classes={classes}
          translateUserIdToName={translateUserIdToName}
          licenseUsers={props.licenseUsers}
          usedDatasourcesByProjects={props.usedDatasourcesByProjects}
          viewDatasetDetails={viewDatasetDetails}
          viewDatasetUseInProjects={viewDatasetUseInProjects}
          viewCreateNewDataFormatVersion={viewCreateNewDataFormatVersion}
          handleSelectDatasetForExportation={handleSelectDatasetForExportation}
          handleSelectDatasetForExportationId={handleSelectDatasetForExportationId}
          selectedDatasetForExportationId={selectedDatasetForExportationId}
          selectDatasetDialog={props.selectDatasetDialog}
        />}

      {showDialogIdDataformat &&
        <DialogShowIdDataformat
          idDataset={idDataset}
          visible={showDialogIdDataformat} setVisible={onCloseShowIdDataformat}
          closeDialogDisplay={onCloseShowIdDataformat}
          />}
      {/*---------------------------------------- fin dialogos de versiones-----------------------------------*/}

      <div style={{
        display: 'flex',
        flexDirection: 'row', marginTop: '-20px'
      }}>


        {user && (user.role !== 'USER') &&
          <div style={{ marginTop: '20px' }}>
            <FormControlLabel control={<Switch color="primary"
              checked={viewAsUser} onChange={handleChangeViewAsUser} />} label={
                <Typography variant='caption'>View as User</Typography>
              } />
          </div>
        }
        <div style={{ marginTop: '20px' }}>
          <FormControlLabel control={<Switch color="primary"
            checked={viewOnlyProfiling} onChange={event => setViewOnlyProfiling(event.target.checked)} />} label={
              <Typography variant='caption'>View only data with profiling</Typography>
            } />
        </div>

        {/* <DataformatFilters dataformats={((user && (user.role === 'USER' || viewAsUser === true))) ? dataformats : dataformatsAll}
          handleSelectSource={handleSelectSource} handleSelectOwner={handleSelectOwner} selectedSource={handleSelectSource} 
        selectedOwner={handleSelectOwner} licenseUsers={props.licenseUsers} /> */}
      </div>

      <DataformatTable selectedDatasetForExportation={selectedDatasetForExportation} classes={classes}
        translateUserIdToName={translateUserIdToName}
        selectedDataformatSource={selectedDataformatSource}
        viewOnlyProfiling={viewOnlyProfiling}
        selectedDataformatUser={selectedDataformatUser}
        dataformats={((user && (user.role === 'USER' || viewAsUser === true))) ? dataformats : dataformatsAll}
        licenseUsers={props.licenseUsers}
        usedDatasourcesByProjects={props.usedDatasourcesByProjects}
        viewDatasetDetails={viewDatasetDetails}
        viewDatasetProfiling={viewDatasetProfiling}
        viewDatasetUseInProjects={viewDatasetUseInProjects}
        viewCreateNewDataFormatVersion={viewDisplayDataFormatVersions}
        viewDisplayShowIdDataformat={viewDisplayShowIdDataformat}
        handleSelectDatasetForExportation={handleSelectDatasetForExportation}
        handleSelectDatasetForExportationId={handleSelectDatasetForExportationId}
        selectedDatasetForExportationId={selectedDatasetForExportationId}
        selectDatasetDialog={props.selectDatasetDialog}
        viewUpdateUserAndPwd={viewUpdateUserAndPwd}
        addInternalProfiling={addInternalProfilingNew} />

      {!props.selectDatasetDialog && <Button variant="contained" style={{ minWidth: '20%', marginTop: "1%" }}
        onClick={onDeleteUnused} color="primary">
        Delete Unused
      </Button>}
      {!props.selectDatasetDialog && <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "1%" }}
        color="primary"
        onClick={event => {
          /* trackEvent('DialogCreateDataSource', 'Add Dataformat Azure') */
          onAddDataFormat()
        }}>
        <i className="icon plus"></i>
        {languageContext.dictionary["addDataformat"]}
      </Button>}


    </MuiThemeProvider>
  );
}

/* QualityDatasetRepository.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    dataformatsAll: Object.values(state.dataformatsAll),
    dataformats: Object.values(state.dataformats),
    currentUser: (state.user)
  };
}


export default withStyles(styles)(withRouter((connect(mapStateToProps, { fetchUser, fetchDataFormats, fetchAllDataFormats, deleteUnusedDataformats, addInternalProfiling, addDataFormatFromProfilingTreeView })(QualityDatasetRepository)))); */
