import _ from 'lodash';
import {FETCH_PROJECTS_DATA_REPOSITORY_SHARED,
        FETCH_PROJECT_DATA_REPOSITORY_SHARED,
        EDIT_PROJECT_DATA_REPOSITORY_SHARED,
        DELETE_PROJECT_DATA_REPOSITORY_SHARED,
        CREATE_PROJECT_DATA_REPOSITORY_SHARED
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_PROJECTS_DATA_REPOSITORY_SHARED:
            return {..._.mapKeys(action.payload,'_id')}
        case FETCH_PROJECT_DATA_REPOSITORY_SHARED:
            return {...state,[action.payload._id]: action.payload};
         case CREATE_PROJECT_DATA_REPOSITORY_SHARED:
            return {...state, [action.payload._id]: action.payload};
        case DELETE_PROJECT_DATA_REPOSITORY_SHARED:
            return _.omit(state, action.payload)
        case EDIT_PROJECT_DATA_REPOSITORY_SHARED:
            return {...state, [action.payload._id]: action.payload};
        default:
            return state;
        
    }
}