import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTrash, faEdit, faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MaterialTable from "@material-table/core";
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import EditScheduleDialog from './EditScheduleDialog'
import ConfirmUpdateVersionDialog from './ConfirmUpdateVersionDialog'
import { Tooltip, Typography } from '@material-ui/core'
import translateCronToRecurrence from './CronTranslator/index'

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
    },
    fabWarning: {
        margin: theme.spacing(1),
        backgroundColor: "yellow"

    }

}));



export default function ScheduleTable(props) {
    const classes = useStyles();

    const [data, setData] = useState([])
    const [selectedSchedule, setSelectedSchedule] = useState(undefined)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showConfirmUpdateDialog, setShowConfirmUpdateDialog] = useState(false)


    useEffect(() => {
        if (props.existingSchedules) {
            setData(props.existingSchedules)
        }
    }, [props.existingSchedules, props.existingSchedules.length])

    function openDeleteDialog(schedule) {
        setSelectedSchedule(schedule)
        setShowConfirmDialog(true)
    }
    function openEditDialog(schedule) {
        setSelectedSchedule(schedule)
        setShowEditDialog(true)
    }
    function openUpdateVersionDialog(schedule) {
        setSelectedSchedule(schedule)
        setShowConfirmUpdateDialog(true)
    }








    return (
        <>
            <ConfirmDeleteDialog showConfirmDialog={showConfirmDialog} setShowConfirmDialog={setShowConfirmDialog} selectedSchedule={selectedSchedule} userId={props.userId} setSelectedSchedule={setSelectedSchedule} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} />
            <EditScheduleDialog showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} scheduleInfo={selectedSchedule} userId={props.userId} setSelectedSchedule={setSelectedSchedule} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} />
            <ConfirmUpdateVersionDialog showConfirmUpdateDialog={showConfirmUpdateDialog} setShowConfirmUpdateDialog={setShowConfirmUpdateDialog} selectedSchedule={selectedSchedule} userId={props.userId} setSelectedSchedule={setSelectedSchedule} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} datasource={props.datasource} project={props.project} selectedQualityPoint={props.selectedQualityPoint}/>

            {data && data.length > 0 && < MaterialTable
                style={{ width: "100%" }}
                title="Schedules"
                data={data}
                columns={
                    [
                        { title: 'Name', field: 'name' },

                        { title: 'Environment', field: 'environment' },
                        {
                            title: 'Cron', field: 'cron', render: (rowData) => {
                                return (
                                    <Tooltip title={translateCronToRecurrence(rowData.cron.cron)}>
                                        <Typography> {rowData.cron && rowData.cron.cron}</Typography>
                                    </Tooltip>
                                )
                            }
                        },
                        { title: 'Pause status', field: 'cron', render: (rowData) => { return rowData.cron && rowData.cron.pauseStatus === "PAUSED" ? "Paused" : "Active" } },
                        { title: 'State', field: 'state' },
                        {
                            title: 'Updated', field: 'updatedFlag', render: (rowData) => {

                                return (
                                    rowData.updatedFlag ? <Tooltip title={"Schedule is up to date with last datasource version"}>
                                        <span>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#3dad5b", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                        </span>
                                    </Tooltip> :
                                        <Tooltip title={"Update Deployed Schedule"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openUpdateVersionDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#FFCC00", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                )
                            }
                        },
                        {
                            title: 'Actions', field: '', filtering: false, render: (rowData) => {
                                return (
                                    <>
                                        <Tooltip title={"Delete Schedule"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openDeleteDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                        <Tooltip title={"Edit Schedule"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openEditDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faEdit} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                    </>
                                )
                            }
                        },
                    ]}
                options={{
                    actionsColumnIndex: -1,
                    filtering: false,
                    search: false,
                    pageSize: 5,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                        backgroundColor: commonStyles.mainColor,
                        color: '#FFF'
                    },
                    cellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                    filterCellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                }}

                components={{
                    Toolbar: props => (
                        <div>

                        </div>
                    ),
                }}
            />}
        </>

    )
}
