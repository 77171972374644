import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import * as colorHelper from './../colorHelper'

export default function TreeViewDimensionsAndRuleTypes(props) {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    function processData(data) {
        if(data === undefined){
            return []
        }
        var treeData = [];
        //obtain all dimensions
        const uniqueDimensions = [...new Set(data.map(x => x.dimension))]
        const dataMapped = data.filter(x => x.ruleType).map(x => {
            const data = {
                ruleType: x.ruleType,
                numIn: parseFloat(x.sumNumIn),
                numOk:  parseFloat(x.sumNumOk),
                dimension: x.dimension,
                executions:parseFloat(x.executions)
            }
            return data
        })

        //calculate maxValue to get minimunValue
        var maxValue = 0
        for (let dimensionIx in uniqueDimensions) {
            const dimension = uniqueDimensions[dimensionIx]
            const dataRawDimension = dataMapped.filter(x => x.dimension === dimension)
            const uniqueRuleTypes = [...new Set(dataRawDimension.map(x => x.ruleType))]
            for (let ruleTypeIx in uniqueRuleTypes) {
                const ruleType = uniqueRuleTypes[ruleTypeIx]
                const dataRawDimensionRuleType = dataRawDimension.filter(x => (x.ruleType) === ruleType)
                const executions = dataRawDimensionRuleType.map(x=>x.executions).reduce((partialSum, a) => partialSum + a, 0);
                const executionsLog = Math.log(executions)
                if(executionsLog> maxValue){
                    maxValue = executionsLog
                }
            }
        }

        
        for (let dimensionIx in uniqueDimensions) {
            const dimension = uniqueDimensions[dimensionIx]
            var dimensionData = { name: dimension, children: [] }
            const dataRawDimension = dataMapped.filter(x => x.dimension === dimension)
            const uniqueRuleTypes = [...new Set(dataRawDimension.map(x => x.ruleType))]
            //var dimensionTotal = dataRawDimension.reduce(function (acc, obj) { return acc + obj.numOk; }, 0) / dataRawDimension.reduce(function (acc, obj) { return acc + obj.numIn; }, 0);

            for (let ruleTypeIx in uniqueRuleTypes) {
                const ruleType = uniqueRuleTypes[ruleTypeIx]
                const dataRawDimensionRuleType = dataRawDimension.filter(x => (x.ruleType) === ruleType)
                const value = (100* dataRawDimensionRuleType.reduce(function (acc, obj) { return acc + obj.numOk; }, 0) / dataRawDimensionRuleType.reduce(function (acc, obj) { return acc + obj.numIn; }, 0)).toFixed(2);
                
                const executions =  dataRawDimensionRuleType.map(x=>x.executions).reduce((partialSum, a) => partialSum + a, 0);
                var executionsLog = Math.log(executions)
                if(executionsLog<0.1*maxValue){
                    executionsLog = 0.1*maxValue
                }
                const definitions = [...new Set(dataRawDimensionRuleType.map(x => x.ruleType))].length

                
                const color = colorHelper.getColorForPercentageSimple(value)
                dimensionData.children.push({ 
                    dimension: capitalizeFirstLetter(dimension),
                    name: ruleType, 
                    count: value+"%" , 
                    color: color,
                    executions: executions,
                    executionsLog: executionsLog,
                    definitions: definitions});
            }
            treeData.push(dimensionData);
            
        }

        return treeData
    }
    React.useLayoutEffect(() => {
        

        // create chart
        var chart = am4core.create(props.fullscreen ===true ? 'chartdivTreeFullscreen' : "chartdivTree", am4charts.TreeMap);
        chart.padding(10, 10, 10, 10);
        chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

        // only one level visible initially
        chart.maxLevels = 2;
        // define data fields
        /* Set color step */
        chart.colors.step = 2;
        chart.dataFields.value = "executionsLog";
        chart.dataFields.name = "name";
        chart.dataFields.children = "children";
        chart.dataFields.color = "color";

        chart.layoutAlgorithm = chart.binaryTree;


        // enable navigation
        chart.navigationBar = new am4charts.NavigationBar();
        chart.zoomable = false;

        // level 0 series template
        var level0SeriesTemplate = chart.seriesTemplates.create("0");
        level0SeriesTemplate.strokeWidth = 2;
        

        // by default only current level series bullets are visible, but as we need brand bullets to be visible all the time, we modify it's hidden state
        level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
        level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = true;
        // create hover state
        var columnTemplate = level0SeriesTemplate.columns.template;
        columnTemplate.column.cornerRadius(10, 10, 10, 10);
        columnTemplate.fillOpacity = 0;
        columnTemplate.strokeWidth = 4;
        columnTemplate.strokeOpacity = 0;
        var hoverState = columnTemplate.states.create("hover");

        // darken
        hoverState.adapter.add("fill", function (fill, target) {
            if (fill instanceof am4core.Color) {
                return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
            }
            return fill;
        })

        
        

        // level1 series template
        var level1SeriesTemplate = chart.seriesTemplates.create("1");
        level1SeriesTemplate.columns.template.fillOpacity = 0.7;
        level1SeriesTemplate.columns.template.strokeOpacity = 0.4;

        level1SeriesTemplate.columns.template.tooltipText = "[bold font-size:12px] {dimension}[/]\n "+
        "[bold font-size:12px]  {name} rule\n"+
        "[bold font-size:12px]DQI: {count}   \n"+
        "[ font-size:12px]Definitions: {definitions}[/]\n"+
        "[ font-size:12px]Executions: {executions}[/]\n";

        var level1ColumnTemplate = level1SeriesTemplate.columns.template;
        level1ColumnTemplate.column.cornerRadius(10, 10, 10, 10)

        var bullet1 = level1SeriesTemplate.bullets.push(new am4charts.LabelBullet());
        bullet1.locationX = 0.5;
        bullet1.locationY = 0.5;
        bullet1.label.text = "[bold font-size:14px] {dimension}[/]\n "+
                            "[ font-size:14px]  {name} rule\n"+
                            "[ font-size:14px]DQI: {count}   \n"
                           
                           
        bullet1.label.fill = am4core.color("#000000");
        bullet1.label.fontSize = 12;
        bullet1.label.fillOpacity = 0.7;
        
        if(props.fullscreen === true){
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [{
                "label": "...",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "json", "label": "JSON" },
                  { "label": "Print", "type": "print" }
                ]
              }];
        }
        

        chart.data = processData(props.data);

    }, [props.data]);// eslint-disable-line react-hooks/exhaustive-deps

    
    return (
        <div id={props.fullscreen ===true ? 'chartdivTreeFullscreen' : "chartdivTree"} style={{ width: "100%", height: "90%" }}></div>
    )
    
}
