import React from 'react';
import SaveIcon from '@material-ui/icons/Save';


export const buttonDefinition = [
    {
        id: 'changeDisplayName',
        labelId: 'changeDisplayNameLabel',
        icon: <SaveIcon />
    },
    {
        id: 'changeQuery',
        labelId: 'changeQueryLabel',
        icon: <SaveIcon />
    },
    {
        id: 'shareDataformat',
        labelId: 'shareDataformatLabel',
        icon: <SaveIcon />
    },
    {
        id: 'addProfiling',
        labelId: 'addProfilingLabel',
        icon: <SaveIcon />
    },
    {
        id: 'createNewVersion',
        labelId: 'createNewVersionLabel',
        icon: <SaveIcon />
    },
    {
        id: 'changeFormat',
        labelId: 'changeFormatLabel',
        icon: <SaveIcon />
    }
 ]


 