export function convertSchema2Datasource(schema, user, selectedItem, fileOutputName, delimiter, format, dataPath) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })
    let abfssDataPath = dataPath.replace("wasbs", "abfss").replace("blob.core.windows.net", "dfs.core.windows.net")

    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: delimiter,
        format: format,
        source: 'azure-storage',
        fileStorageProperties: {
            resourceGroup: selectedItem.resourceGroupName,
            account: selectedItem.storageAccount,
            container: selectedItem.container,
            path: abfssDataPath

        }
    }
    return dataset

}

export function convertSchema2DatasourceCosmos(schema, user, selectedItem, fileOutputName) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })

    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: '',
        format: '',
        source: 'azure-cosmos',
        cosmosProperties: {
            resourceGroup: selectedItem.resourceGroupName,
            account: selectedItem.account,
            database: selectedItem.database,
            collection: selectedItem.collection,
        }


    }
    return dataset

}

export function convertSchema2DatasourceSqlDatabase(schema, user, dto, fileOutputName) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })

    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: '',
        format: '',
        source: 'azure-sqldatabase',
        sqlDatabaseProperties: dto

    }
    return dataset

}
export function convertSchema2DatasourceSqlDatabaseOnprem(schema, user, dto, fileOutputName, query2Preview, query2PreviewName) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })

    let sqlDatabaseProperties = dto.credentials
    sqlDatabaseProperties.table = dto.table
    sqlDatabaseProperties.credentialsId = dto.credentialsId

    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: '',
        format: '',
        source: dto.storageType,
        sqlDatabaseOnpremProperties: sqlDatabaseProperties,
        nickname: query2PreviewName !== undefined && query2PreviewName.length>0 ? query2PreviewName : undefined,
        sqlQuery: query2Preview !==undefined && query2Preview.length>0 ? query2Preview : undefined
    }
    return dataset

}

export function convertSchema2DatasourceBigQueryDatabase(schema, user, dto, fileOutputName) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })
    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: '',
        format: '',
        source: 'bigQuery',
        bigQueryDatabaseProperties: dto
    }
    return dataset

}

export function convertSchema2DatasourceAwsDatabase(schema, user, dto, fileOutputName, separator) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })

    let dataset = {
        userId: user.id,
        separator: separator,
        name: fileOutputName,
        columns: columns,
        format: dto.baseFormat ? dto.baseFormat : "",
        source: 'awsS3',
        awsDatabaseProperties: dto
    }
    return dataset

}

export function convertSchema2DatasourceHiveDatabase(schema, user, dto, fileOutputName) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })
    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: '',
        format: dto.baseFormat ? dto.baseFormat : "",
        source: 'hive',
        hiveDatabaseProperties: dto
    }
    return dataset

}

export function convertSchema2DatasourceRedshiftDatabase(schema, user, dto, fileOutputName) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })
    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: '',
        format: dto.baseFormat ? dto.baseFormat : "",
        source: 'awsRedshift',
        redshiftDatabaseProperties: dto
    }
    return dataset

}



export function convertSchema2DatasourceSqlSynapseDatabase(schema, user, dto, fileOutputName) {
    let columns = schema.fields.map((value, index) => {
        return {
            position: index,
            name: value.name,
            type: parseValueTypeForConvertSchema2Datasource(value.type)
        }
    })

    let dataset = {
        userId: user.id,
        name: fileOutputName,
        columns: columns,
        separator: '',
        format: '',
        source: 'azure-sqlsynapse',
        sqlDatabaseProperties: dto

    }
    return dataset

}

function parseValueTypeForConvertSchema2Datasource(type) {
    if (type === 'integer' || type === 'double') {
        return 'number'
    }
    if (type === 'boolean') {
        return 'boolean'
    }
    if (type === 'date') {
        return 'date'
    }
    return 'string'
}