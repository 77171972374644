import React, { useEffect } from 'react';

import { makeStyles, Select } from '@material-ui/core';

import { styles, QualityTheme } from '../../../../../../../style/CustomTheme'
import { Typography, TextField, FormControlLabel, MuiThemeProvider, FormControl, InputLabel, MenuItem, Chip, Input, Checkbox, ListItemText, Grid, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import { Button } from 'react-rainbow-components';
import * as commonStyles from 'style/CommonStyles'



export default function TagCard(props) {


    const classes = makeStyles(styles(QualityTheme));
    const theme = useTheme()


    const [tagName, setTagName] = React.useState('')
    const [mandatory, setMandatory] = React.useState(false)
    const [inputText, setInputText] = React.useState('')
    //const [errorInputText, setErrorInputText] = React.useState('')
    const [tagValueType, setTagValueType] = React.useState('')
    const [selectedElements, setSelectedElements] = React.useState([])
    const [selectedValues, setSelectedValues] = React.useState([])
    const [id, setId] = React.useState('')


    

    React.useEffect(() => {
        setTagName(props.tagInfo && props.tagInfo.tagName && props.tagInfo.tagName !== null ?
            props.tagInfo.tagName : '')
        setTagValueType(props.tagInfo && props.tagInfo.tagType && props.tagInfo.tagType !== null ?
            props.tagInfo.tagType : 'free')
        setId(props.tagInfo && props.tagInfo.id && props.tagInfo.id !== null ?
            props.tagInfo.id : '')
        setMandatory(props.tagInfo && props.tagInfo.mandatory && props.tagInfo.mandatory !== null ?
            props.tagInfo.mandatory : false)
        setSelectedValues(props.tagInfo && props.tagInfo.allowedValues && props.tagInfo.allowedValues !== null ?
            props.tagInfo.allowedValues : [])
        setSelectedElements(props.tagInfo && props.tagInfo.taggableElementTypes && props.tagInfo.taggableElementTypes !== null ?
            props.tagInfo.taggableElementTypes : ["all"])
        setId(props.tagInfo && props.tagInfo.id && props.tagInfo.id !== null ?
            props.tagInfo.id : "")

    }, [props.tagInfo, props.parentConfiguration]);

    useEffect(() => {
        let tagElement = {}
        tagElement.tagName = tagName
        tagElement.tagType = tagValueType
        tagElement.id = id
        tagElement.allowedValues = selectedValues
        tagElement.taggableElementTypes = selectedElements
        tagElement.mandatory = mandatory
        props.setTag(tagElement)
    }, [tagValueType, tagName, selectedElements, mandatory, selectedValues])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.newTag) {
            let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
            setId(newId)
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedElements(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeInputText = (event, value) => {
        setInputText(event.target.value)
        //setErrorInputText("") //TODO: VARIABLE NOT USED
    }

    const handleInsertNewValue = (event) => {
        let newSelectedvalues = selectedValues
        let newValue = inputText

        let present = false
        if (newSelectedvalues && newSelectedvalues.length > 0) {
            for (let i = 0; i < newSelectedvalues.length; i++) {
                if (newSelectedvalues[i] === newValue) {
                    present = true
                }
            }
        }
        if (!present) {
            newSelectedvalues.push(newValue)
            setSelectedValues(newSelectedvalues)
            setInputText("")
        }
        if (present) {
            //setErrorInputText("This value is already on the list")
            //TODO: VARIABLE NOT USED
        }
    }

    const tagValueTypes = ["free", "list"]
    const elements = ["all", "project", "qualityPoint", "datasource", "rule"]

    const handleChangeMandatory = (event) => {
        setMandatory(event.target.checked);
    };

    const handleDelete = (event, value2delete) => {
        event.preventDefault()
        setSelectedValues((selectedValues) =>
            selectedValues.filter((value) => value !== value2delete)
        )
    };

    return (
        <MuiThemeProvider theme={QualityTheme}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
                <FormControl style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Checkbox
                        checked={mandatory}
                        onChange={handleChangeMandatory}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                    />
                    <Typography variant='caption' style={{ marginTop: "0.7rem" }} >Mandatory Tag</Typography>
                </FormControl>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <Typography variant="subtitle2">Tag Name</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <FormControlLabel
                            control={
                                <TextField style={{ marginLeft: "10px" }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="tagName"
                                    label="Tag Name"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={tagName}
                                    onChange={(event) => { setTagName(event.target.value) }}
                                />} />
                        {props.errors && props.errors.tagName && <div style={{ margin: "10px", color: "red" }}>{props.errors.tagName}</div>}
                        {props.errors && props.errors.repeatedTagName && <div style={{ margin: "10px", color: "red" }}>{props.errors.repeatedTagName}</div>}
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="subtitle2">Taggable Elements</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <FormControl style={{ width: '100%', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <InputLabel style={{ fontSize: '15px' }} id="tag">Select Taggable Element Value</InputLabel>
                            <Select
                                inputProps={{
                                    style: { fontSize: 10 }
                                }}
                                style={{ width: "100%" }}
                                multiple
                                autoWidth={true}
                                labelId="tag-elements"
                                id="tag-list"
                                value={selectedElements}
                                renderValue={(selected) => selected.join(', ')}
                                label="Taggable Element Value"
                                onChange={handleChange}
                            >

                                {elements.map(element => {
                                    return (
                                        <MenuItem key={element} value={element}>
                                            <Checkbox checked={selectedElements.indexOf(element) > -1} />
                                            <ListItemText primary={element} />
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {props.errors && props.errors.taggableElementTypes && <div style={{ color: "red" }}>{props.errors.taggableElementTypes}</div>}
                    <Grid item xs={5}>
                        <Typography variant="subtitle2">Tag value Type</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <FormControl style={{ width: '100%', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <InputLabel id="selectTagValueType">
                                <Typography style={{ fontSize: '15px' }} variant="caption">Select a Tag value type </Typography>
                            </InputLabel>
                            < Select
                                value={tagValueType}
                                onChange={event => {
                                    setTagValueType(event.target.value);
                                }}
                                inputProps={{
                                    name: 'Environment',
                                    id: 'Environment',
                                }}
                                style={{ width: '100%' }}>
                                {tagValueTypes.map(element => {
                                    return (
                                        <MenuItem key={element} value={element}>
                                            <div style={theme.elementAdjacent}>
                                                <Typography variant='body2'>{element}</Typography>
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {props.errors && props.errors.tagType && <div style={{ color: "red" }}>{props.errors.tagType}</div>}

                    {tagValueType && tagValueType === "list" && <Typography variant="subtitle2">List Values</Typography>}
                    {tagValueType && tagValueType === "list" && <FormControl style={{ display: "flex", flexDirection: "column", justifyContent: 'flex-start', marginTop: '20px' }} >
                        <div style={{ margin: "10px", display: "flex", flexWrap: "wrap", maxWidth: "500px" }}>
                            {selectedValues && selectedValues.length > 0 && selectedValues.map(value => {
                                return (
                                    <>
                                        <Tooltip key={value} title={value}>
                                            <Chip label={value} key={value} onDelete={(event) => handleDelete(event, value)} style={{ width: "120px", maxWidth: '120px', backgroundColor: commonStyles.mainColor, margin: "5px" }} />
                                        </Tooltip>
                                    </>
                                )
                            })}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-around', width: "520px" }}>
                            <Input value={inputText} onChange={handleChangeInputText} placeholder="Insert value"
                                style={{ fontSize: "0.8rem", width: '65%', marginRight: '5%' }} />
                            <Button onClick={handleInsertNewValue} variant="contained" style={{ minWidth: '150px', marginLeft: "2%", marginTop: "1%", backgroundColor: "#2596be", color: "white" }}>
                                <Typography variant='subtitle1' >Add new Value</Typography>
                            </Button>
                        </div>
                    </FormControl>}
                    {props.errors && props.errors.allowedValues && <div style={{ margin: "10px", color: "red" }}>{props.errors.allowedValues}</div>}
                </Grid>
            </div>

        </MuiThemeProvider >
    )
}