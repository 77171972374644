import React from 'react';
import * as Sentry from '@sentry/browser';

export class AqtivaErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error:undefined,errorInfo:undefined };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true , error: error};
    }
  
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
      // You can also log the error to an error reporting service
      const componentError = new Error(error.message);
        componentError.name = `React ErrorBoundary ${componentError.name}`;
        componentError.stack = errorInfo.componentStack;

        
        Sentry.captureException(componentError);
  
    }
  
    render() {
        if (this.state.error) {
        // You can render any custom fallback UI
        return (
        <div>
        <h1>Something went wrong.</h1>
        <h2>{this.state.error?.message}</h2>
        <h2>{this.state.errorInfo?.componentStack}</h2>
         </div>
        )
      }
  
      return this.props.children; 
    }
  }