import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Grid,  Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from 'react-redux'
import * as dtoHelper from "../Helpers/getterDTOs";
import { getEntityIdFromFileInRepository } from "../Helpers/getterDTOs";
import { faArrowCircleRight, faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";


export function MigrationButtons(props) {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user);

    const [errorImport, setErrorImport] = useState("");
    const [errorExport, setErrorExport] = useState("");

    const styleDisabled =
    {
        color: "#696969",
        marginRight: "3px",
        width: "40px",
        height: "40px"
    }

    const styleEnabled =
    {
        color: "#45b6fe",
        marginRight: "3px",
        width: "50px",
        height: "50px"

    }

    useEffect(() => {
        validateExport(props.selectedItem, props.selectedItemShared);
        validateImport(props.selectedItem, props.selectedItemShared);
    }, [props.selectedItem, props.selectedItemShared]);

    function validateExport(selectedItem, selectedItemShared) {
        if (!selectedItem || !selectedItem.itemId) {
            setErrorExport(
                "A file needs to be selected in Private Workspace to Import. No element selected."
            );
            return false;
        }
        if (selectedItem.type !== "Files") {
            setErrorExport(
                "A file needs to be selected in Private Workspace to Export. You have selected a folder."
            );
            return false;
        }
        if (!selectedItemShared || !selectedItemShared.itemId) {
            setErrorExport(
                "A folder needs to be selected in Shared Workspace to Import. No element selected."
            );
            return false;
        }
        if (selectedItemShared.type !== "Folder") {
            setErrorExport(
                "A folder needs to be selected in Shared Workspace to Export. You have selected a file."
            );
            return false;
        }
        setErrorExport("");
        return true;
    }

    function validateImport(selectedItem, selectedItemShared) {
        if (!selectedItemShared || !selectedItemShared.itemId) {
            setErrorImport(
                "A file needs to be selected in Shared Workspace to Import. No element selected."
            );
            return false;
        }
        if (selectedItemShared.type !== "Files") {
            setErrorImport(
                "A file needs to be selected in Shared Workspace to Import. You have selected a folder."
            );
            return false;
        }
        if (!selectedItem || !selectedItem.itemId) {
            setErrorImport(
                "A folder needs to be selected in Private Workspace to Import. No element selected."
            );
            return false;
        }
        if (selectedItem.type !== "Folder") {
            setErrorImport(
                "A folder needs to be selected in Private Workspace to Import. You have selected a file."
            );
            return false;
        }

        setErrorImport("");
        return true;
    }
    function exportFile(selectedItem, selectedItemShared) {
        dispatch(
            props.actions.exportFileFromRepository(
                user.id,
                dtoHelper.getDTOIdFromFolder(
                    props.entityDataRepoShared,
                    selectedItemShared.itemId
                ), //dto destino
                selectedItemShared.itemId, //item destino
                dtoHelper.getDTOIdFromFilePrivate(
                    props.entityDataRepo,
                    selectedItem.itemId
                ), //dto origen
                selectedItem.itemId, //item origen
                getEntityIdFromFileInRepository(props.entityDataRepo, selectedItem.itemId)
            )
        );
    }

    function importFile(selectedItem, selectedItemShared) {
        dispatch(
            props.actions.importFileToRepository(
                user.id,
                dtoHelper.getDTOIdFromFileShared(
                    props.entityDataRepoShared,
                    selectedItemShared.itemId
                ), //dto origen
                dtoHelper.getDTOIdFromFolder(props.entityDataRepo, selectedItem.itemId), //dto destino
                selectedItemShared.itemId, //item origen
                selectedItem.itemId, //item destino
                dtoHelper.getEntityIdFromFileInRepositoryShared(
                    props.entityDataRepoShared,
                    selectedItemShared
                )
            )
        );
    }
    return (
        <>
            <Grid xs={12} spacing={1} style={{ marginTop: "7vh" }} >
                <Grid item xs={12}>
                    <Tooltip title={errorExport !== "" ? "To export, select a file from private workspace and a destination folder in shared workspace" : "Export file"}>
                        <div data-toggle="tooltip" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px", }}>
                            <Button disabled={errorExport !==""} onClick={() => exportFile(props.selectedItem, props.selectedItemShared)} >
                                <FontAwesomeIcon
                                    icon={faArrowCircleRight}
                                    style={errorExport !== "" ? styleDisabled : styleEnabled}
                                />
                            </Button>
                        </div>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={errorImport !=="" ? "To import, select a file from shared workspace and a destination folder in private workspace" : "Import file"}>
                        <div data-toggle="tooltip" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px", }}>
                            <Button disabled={errorImport !== ""} onClick={() => importFile(props.selectedItem, props.selectedItemShared)} disableElevation={true} style={{ maxHeight: "60px", maxWidth: "60px", minHeight: "60px", minWidth: "60px" }}>
                                <FontAwesomeIcon
                                    icon={faArrowCircleLeft}
                                    style={errorImport !== "" ? styleDisabled : styleEnabled}
                                />
                            </Button>
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    );
}

