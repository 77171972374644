import React from 'react';


import { Grid, Typography } from '@material-ui/core';



export default function SummaryConnectionAws(props) {

    return (

        <Grid container spacing={3}>
            {props.displayName && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Display name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.displayName}</Typography>
                </Grid>
            </>}
            {props.connectionObjectAWS && props.connectionObjectAWS.name && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectAWS && props.connectionObjectAWS.name}</Typography>
                </Grid>
            </>}
            {props.connectionObjectAWS && props.connectionObjectAWS.accessKey && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Access Key:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectAWS && props.connectionObjectAWS.accessKey}</Typography>
                </Grid>
            </>}
            {props.connectionObjectAWS && props.connectionObjectAWS.secretKey && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Secret Key:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectAWS && props.connectionObjectAWS.secretKey}</Typography>
                </Grid>
            </>}
        </Grid>
    )
}