import React from 'react'
import { Button, LinearProgress, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { encryptString } from 'components/common/encryptHelper';

import { useTheme } from '@material-ui/core/styles';

import trackEvent from 'trackEvent'




export default function BigQueryInputCredentials(props) {

  const theme = useTheme();

  const dispatch = useDispatch()

  var inputFileRef = React.useRef(null);

  const [uploading, setUploading] = React.useState(false)
  const size = 0
  function uploadFiles(files, props) {

    files && files.length > 0 && uploadFile(files[0], props);
  }


  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click()

  }



  async function blobToString(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }


  async function uploadFile(file, props) {
    let credentials = await blobToString(file)
    props.setEncryptedCredentialsFile(await encryptString(props.id + "bigQueryCredentials", dispatch, credentials))
    setUploading(false)
    //props.onUpload(response)
  }



  return (
    <div>
      <input
        style={{ display: 'none' }}
        accept=".json"
        ref={inputFileRef}
        type="file"
        multiple={false}
        onChange={e => uploadFiles(e.target.files, props)}
        onClick={(event) => {
          event.target.value = null
        }}
      />
      {uploading &&
        <div>
          <LinearProgress style={{ width: '65%' }} />
          {size < 20 && <Typography style={{ width: '65%' }} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) / 100} MB`} </Typography>}
          {size >= 20 && <Typography style={{ width: '65%' }} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) / 100} MB. It may take some time depending on your internet connection`} </Typography>}
        </div>
      }
      <Button variant='outlined' style={theme.buttons.buttonRegular} onClick={() => { trackEvent('InputFile', 'Upload File Button'); showFileDialog() }} >
        {props.buttonText}
      </Button>
    </div>
  )
}