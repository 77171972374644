import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


export function infoSnackbarContent(messageObj) {
    var message = ''
    var detail = undefined;
    if(messageObj ===undefined || messageObj.msg ===undefined){
        message = messageObj
    }
    else{
        message = messageObj.msg
    }

    if(messageObj ===undefined || messageObj.detail ===undefined){
        detail = undefined
    }
    else{
        detail = messageObj.detail
    }

    if (detail === undefined) {
        return (
            <Box 
            borderRadius="3px"
            borderColor='#959595'
            

            style={{ backgroundColor: '#FFFFFFEE', minWidth: '300px' ,}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth:'200px',
                   
                }}>
                    <img className="img-fluid" alt=""
                        src={`${process.env.PUBLIC_URL}/logo_omma_O.png`}
                        style={{ width: '30px', margin:'5px', height: '15%' }}
                    />
                    <div style={{minWidth:'270px'}}>
                    <div style={{marginTop:'10px'}}>
                        <Typography variant='body2'>{message}</Typography>
                    </div>
                   
                    </div>
                    
                </div>


            </Box>
        )
    }
    else {
        return (
            <Box 
            borderRadius="3px"
            borderColor='#000000'
            border = {1}

            style={{ backgroundColor: '#FFFFFF', minWidth: '200px' ,}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth:'200px',
                   
                }}>
                    <img className="img-fluid" alt=""
                        src={`${process.env.PUBLIC_URL}/logo_omma_O.png`}
                        style={{ width: '30px', margin:'5px', height: '15%' }}
                    />
                    <div style={{minWidth:'170px'}}>
                    <div>
                        <Typography variant='body2'>{message}</Typography>
                    </div>
                    <div>
                        <Typography variant='caption'>{detail}</Typography>
                    </div>
                    </div>
                    
                </div>


            </Box>
        )
    }
}


export function infoSnackbarContentError(messageObj) {
    var message = ''
    var detail = undefined;
    if(messageObj ===undefined || messageObj.msg ===undefined){
        message = messageObj
    }
    else{
        message = messageObj.msg
    }

    if(messageObj ===undefined || messageObj.detail ===undefined){
        detail = undefined
    }
    else{
        detail = messageObj.detail
    }

    if (detail === undefined) {
        return (
            <Box 
            borderRadius="3px"
            borderColor='#959595'
            

            style={{ backgroundColor: '#B93A3AEE', minWidth: '300px' ,maxWidth: '600px' ,}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth:'200px',
                   
                }}>
                    <img className="img-fluid" alt=""
                        src={`${process.env.PUBLIC_URL}/logo_omma_O_white.png`} 
                        style={{ width: '30px', margin:'5px', height: '15%' }}
                    />
                    <div style={{minWidth:'270px',maxWidth: '570px'}}>
                    <div style={{marginTop:'10px'}}>
                        <Typography variant='body2' style={{color:'#FFFFFF'}}>{message}</Typography>
                    </div>
                   
                    </div>
                    
                </div>


            </Box>
        )
    }
    else {
        return (
            <Box 
            borderRadius="3px"
            borderColor='#000000'
            border = {1}

            style={{ backgroundColor: '#B93A3AEE', minWidth: '300px' ,maxWidth: '600px' ,}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth:'200px',
                   
                }}>
                    <img className="img-fluid" alt=""
                        src={`${process.env.PUBLIC_URL}/omma_logo_white_h.png`} 
                        style={{ width: '30px', margin:'5px', height: '15%' }}
                    />
                    <div style={{minWidth:'270px',maxWidth: '570px'}}>
                    <div>
                        <Typography variant='body2' style={{color:'#FFFFFF'}}>{message}</Typography>
                    </div>
                    <div>
                        <Typography variant='caption' style={{color:'#FFFFFF'}}>{detail}</Typography>
                    </div>
                    </div>
                    
                </div>


            </Box>
        )
    }
}