import React from 'react'
import BarChartSmall from '../Charts/BarChartSmall';
import LollipopChart from '../Charts/LollipopChart'
import TableLlolipopCharts from './TableLollipopCharts'

export default function LollipopChartComponent(props) {

    const valueRow1 = props.valueRow1;
    const valueRow2 = props.valueRow2;

    const categoryRow1 = props.categoryRow1;
    const categoryRow2 = props.categoryRow2;

    const dataTable = [];

    if (!props.disableCharts) {


        props.data.forEach((qp, index) => {

            let tableScore = <LollipopChart data={qp.executionHistory} value={valueRow1} category={categoryRow1} chart={'chart' + index} />

            let tablePassing = <BarChartSmall chart={'chartPass' + index} data={qp.executionHistory} />

            let scores = [];
            let rows = 0;
            qp.executionHistory.map(dt => {
                scores.push(((dt.numOk / (dt.numIn === 0 ? 1 : dt.numIn)) * 100).toFixed(0))
                rows += dt.numIn
                return undefined
            })

            let tableRows = <LollipopChart data={qp.executionHistory} value={valueRow2} category={categoryRow2} chart={'chartRow' + index} />

            dataTable.push(
                {
                    'score': (scores.reduce((a, b) => Number(a) + Number(b)) / scores.length).toFixed(0) + "%",
                    'chartScore': tableScore,
                    'rows': rows,
                    'chartRows': tableRows,
                    'passing': tablePassing
                }
            );

        })
    } else {
        props.data.map(val => {
            dataTable.push({
                'score': val.score,
                'rows': val.sumNumIn
            })
            return undefined
        })
    }

    React.useLayoutEffect(() => {

    }, [])

    return (
        <div style={{ display: 'flex', height:'250px' }}>

            <TableLlolipopCharts
                titleField1={'Score'}
                titleField2={'Score Trend'}
                dataField1={'score'}
                dataField2={'chartScore'}
                titleField3={'Rows'}
                titleField4={'Rows Trend'}
                dataField3={'rows'}
                dataField4={'chartRows'}
                data={dataTable}
                disableCharts={props.disableCharts}
                titleField5={'Passing Trend'}
                dataField5={'passing'}
            />

        </div>
    )
}