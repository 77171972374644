
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import * as actions from 'actions'
import {
    InputLabel, Typography, Select, MenuItem, FormControl, CircularProgress
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCheckCircle,  } from '@fortawesome/free-solid-svg-icons'
import { useInterval } from './useInterval'

import {UPDATE_ENVIRONMENT} from 'actions/types'

import {getUserPreferenceProperty} from 'components/common/userHelper'

import ErrorIcon from '@material-ui/icons/Error';
import StopCircleIcon from '@material-ui/icons/Stop';

import * as commonStyles from 'style/CommonStyles'



const INVALID_TOKEN = 'Invalid Token'

export default function SelectEnvironmentComponent(props) {


    const theme = useTheme()
    const dispatch = useDispatch();

    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))
    const user = useSelector(store => store.user)
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [environmentStates, setEnvironmentStates] = React.useState([])

    React.useEffect(()=>{
        dispatch( {
            type : UPDATE_ENVIRONMENT,
            payload: selectedEnvironment
        });
    }, [selectedEnvironment])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(()=>{
       if(qualityConfiguration === undefined || qualityConfiguration===null || qualityConfiguration._id===undefined){
        if (user && user.id) {
            dispatch(actions.fetchConfigurationByUserId(user.id));
          }
       }
    }, [qualityConfiguration,user])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        let userSelectedEnvironment = getUserPreferenceProperty(user, 'selectedEnvironment')
        if (userSelectedEnvironment !== undefined && qualityConfiguration && qualityConfiguration.environmentConfigurationList && qualityConfiguration.environmentConfigurationList.length>0) {
            setSelectedEnvironmentComplete(qualityConfiguration.environmentConfigurationList.filter(x=>x.environmentName === userSelectedEnvironment)[0], environmentStates)
        }
        else {
            if (qualityConfiguration &&
                qualityConfiguration.environmentConfigurationList) {
                const environmentPreferedSim = filterAllowedEnvironments(
                    qualityConfiguration.environmentConfigurationList, user.id).filter(x => x.prefered4Simulation === true)
                if (environmentPreferedSim && environmentPreferedSim.length > 0) {
                    setSelectedEnvironmentComplete(environmentPreferedSim[0].environmentName, environmentStates)
                }
                else {
                    const filterAllowedEnvironmentList = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)
                    var environmentAllowed = undefined
                    if(filterAllowedEnvironmentList && filterAllowedEnvironmentList.length>0){
                        //hay una lista
                        environmentAllowed = filterAllowedEnvironmentList[0]
                    }
                    else if(filterAllowedEnvironmentList && filterAllowedEnvironmentList.environmentName){
                        //solo hay uno
                        environmentAllowed = filterAllowedEnvironmentList
                    }
                    setSelectedEnvironmentComplete(environmentAllowed ? environmentAllowed : undefined, environmentStates)
                }
            }
        }
    }, [qualityConfiguration, user, environmentStates]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        updateEnvironmentStatus()


    }, [qualityConfiguration.environmentConfigurationList, user])// eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {
        updateEnvironmentStatus()
    },60000)


    function setSelectedEnvironmentComplete(completeValue, environmentStates) {
        var value = completeValue
        if(completeValue && completeValue.environmentName){
            value = completeValue.environmentName
        }
        setSelectedEnvironment(value)
        props.setSelectedEnvironment(value)
        if (props.setSelectedEnvironmentStates && environmentStates!==undefined &&  environmentStates.length > 0) {
            const environments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)
            let environmentStatus = 'PENDING'
            if (environments !== undefined && environments!==null) {
                for (var i = 0; i < environments.length; i++) {
                    if (environments[i].environmentName === value) {
                        environmentStatus = environmentStates[i]
                    }
                }
            }
            props.setSelectedEnvironmentStates(environmentStatus)
        }
    }
    function isValidUser(users, userId) {
        if (users && users.length > 0) {
            if (users.filter(x => x.id === userId) && users.filter(x => x.id === userId).length > 0) {
                return true
            }
        }
        return false
    }

    function filterAllowedEnvironments(environments, userId) {
        if (environments && environments.length > 0) {
            return environments.filter(x => !x.accessType || x.accessType === 'public_access' || (x.accessType === 'protected_access' && isValidUser(x.allowedUsers, userId)))
        }
        return environments
    }

  

    function getEnvironmentComponent(element) {
        const environments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)
        var environmentStatus = 'PENDING'
        if (environments !== undefined && environments!==null) {



            for (var i = 0; i < environments.length; i++) {
                if (environments[i].environmentName === element.environmentName) {
                    environmentStatus = environmentStates[i]
                }
            }
        }
        return (
            <MenuItem key={element.environmentName} value={element.environmentName}>
                <div style={theme.elementAdjacent}>
                    {(environmentStatus === INVALID_TOKEN ) && <ErrorIcon style={{color:commonStyles.errorColor}} />}
                    
                    {(environmentStatus === 'PENDING' || environmentStatus === undefined) && <CircularProgress size={15} style={{ color: commonStyles.mainColor }} />}
                    {environmentStatus === 'RUNNING' && <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#1DA260', fontSize: 15 }} />}
                    {environmentStatus !== undefined && environmentStatus !== INVALID_TOKEN &&environmentStatus !== 'RUNNING' && environmentStatus !== 'PENDING' && 
                    <StopCircleIcon style={{color:commonStyles.warningColor}} />}
                    <Typography variant='subtitle2'>{element.environmentName}</Typography>
                </div>
            </MenuItem>

        )
    }

    async function updateEnvironmentStatus() {
       
        if (process.env.REACT_APP_DATABRICKS_STATE && process.env.REACT_APP_DATABRICKS_STATE === 'true') {
            const environments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)
            if (environments !== undefined && environments!==null) {
                var environmentStatusTmp = []
                for (var i = 0; i < environments.length; i++) {
                    var ret = await getEnvironmentStatus(environments[i].environmentName, user.id)
                    environmentStatusTmp.push(ret)
                }
                setEnvironmentStates(environmentStatusTmp)
            }
        }

    }

    async function getEnvironmentStatus(environment, user) {
        const ret = await dispatch(actions.getClusterStatus(environment, user))
        const ret2 = await ret

        if(ret2.errorMessage){
            if(ret2.errorMessage ===  INVALID_TOKEN)
            {
                return INVALID_TOKEN
            }
        }
        else{
            return ret2.state
        }

        
    }

    function updateUserPreference(dispatch, user, preferenceName, preferenceValue) {
        var newUser = JSON.parse(JSON.stringify(user))
        if (newUser.preferences === undefined || newUser.preferences === null) {
            newUser.preferences = {}
        }
        newUser.preferences[preferenceName] = preferenceValue
        dispatch(actions.updateUserLicense(newUser))
    }

    
    return (

        <FormControl style={{ ...theme.config.formControl, width: '100%', marginRight: '20px', marginTop: '5px' }}>
            <InputLabel id="selectResourceGroup">
                <Typography variant="caption" style={{color:props.white===true ? '#FFFFFF': '#000000'}}>Select an Environment</Typography>
            </InputLabel>
            <Select
                value={selectedEnvironment}
                onChange={event => {
                    setSelectedEnvironmentComplete(event.target.value);
                    updateUserPreference(dispatch, user, "selectedEnvironment", event.target.value)
                    

                }}
                inputProps={{
                    name: 'Environment',
                    id: 'Environment',
                }}
                style={{ width: '100%', marginRight: '20px' ,borderColor: props.white===true ? '#FFFFFF': '#000000', 
                color:props.white===true ? '#FFFFFF': '#000000'}}
            >
                {qualityConfiguration && qualityConfiguration.environmentConfigurationList &&
                    filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id).map(element => {
                        return getEnvironmentComponent(element)
                    })}

            </Select>
        </FormControl>
    )
}