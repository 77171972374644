import { IColumn } from '../../../../interfaces/RuleInterfaces';
import React from 'react';
import { Checkbox, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import SmallBar from 'components/common/ProfilingComponent/SmallBar'

import { IProfilingColumn, IProfiling } from 'interfaces/DataformatInterfaces'

type IProps = {
  column: IColumn,
  profiling?: IProfiling,
  ruleType: string,
  checked: IColumn[],
  handleToggle: (newValue: IColumn) => (any)

};


export default function ColumnSelectorItem(props: IProps) {

  function getProfilingValueForColumn(col: IColumn, profilingColumns: IProfilingColumn[], profilingType: string) {
    for (let i = 0; i < profilingColumns.length; i++) {
      if (profilingColumns[i].columnName === col.name) {
        const profilingItems = profilingColumns[i].profilingColumnItems
        for (let j = 0; j < profilingItems.length; j++) {
          if (profilingItems[j].profilingItemName === profilingType) {
            return profilingItems[j].profilingItemValue
          }

        }


      }
    }
    return '0'
  }

  function createPercentTypeColumn(profilingType: string) {
    try {
      const percentValue = getProfilingValueForColumn(props.column, props.profiling ? props.profiling.profilingColumns : [], profilingType)
      return (
        <ListItem key={props.column.position} role="listitem" button onClick={props.handleToggle(props.column)}>
          <ListItemIcon>
            <Checkbox
              checked={props.checked.indexOf(props.column) !== -1}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <div style={{
            height: '50px', display: 'flex', width: '350px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: "spaceBetween"
          }}>
            <Typography variant='caption' style={{ width: '50%' }}>
              {`#${props.column.position} (${props.column.type}) ${props.column.name} `}
            </Typography>
            {props.profiling && props.profiling.profilingColumns && props.profiling.profilingColumns.length < 100 &&
              <div style={{ width: '50%', height: '50px' }}>
                <SmallBar data={percentValue} id={"smallBar-" + (Math.random() + 1).toString(36).substring(7)} prefix={'NotNulls: '} />
              </div>
            }
          </div>
        </ListItem>
      )
    }
    catch (exception) {
      return createBaseColumn()
    }
  }

  function createRatioTypeColumn(profilingTypeUp: string, profilingTypeDown: string) {
    try {
      const valueUp = parseFloat(getProfilingValueForColumn(props.column, props.profiling ? props.profiling.profilingColumns : [], profilingTypeUp))
      const valueDown = parseFloat(getProfilingValueForColumn(props.column, props.profiling ? props.profiling.profilingColumns : [], profilingTypeDown))
      const value = Math.round((100 - (valueUp / valueDown) * 100) * 100) / 100

      console.log(valueUp, valueDown, value)
      return (
        <ListItem key={props.column.position} role="listitem" button onClick={props.handleToggle(props.column)}>
          <ListItemIcon>
            <Checkbox
              checked={props.checked.indexOf(props.column) !== -1}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <div style={{
            height: '50px', display: 'flex', width: '350px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: "spaceBetween"
          }}>
            <Typography variant='caption' style={{ width: '50%' }}>
              {`#${props.column.position} (${props.column.type}) ${props.column.name} `}
            </Typography>
            {props.profiling && props.profiling.profilingColumns && props.profiling.profilingColumns.length < 100 &&
              <div style={{ width: '50%', height: '50px' }}>
                <SmallBar data={value} id={"smallBar-" + props.column.position} prefix={'Dups: '} inverted={false} />
              </div>
            }
          </div>
        </ListItem>
      )
    }

    catch (exception) {
      return createBaseColumn()
    }
  }

  function createBaseColumn() {
    return (
      <ListItem key={props.column.position} role="listitem" button onClick={props.handleToggle(props.column)}>
        <ListItemIcon>
          <Checkbox
            checked={props.checked.indexOf(props.column) !== -1}
            tabIndex={-1}
            disableRipple

          />
        </ListItemIcon>
        <ListItemText id={props.column.name} primary={`#${props.column.position} (${props.column.type}) ${props.column.name}`} />
      </ListItem>
    )
  }

  switch (props.ruleType) {
    case "null":
      return (createPercentTypeColumn('nullPercent'))
    case "duplicated":
      return (createRatioTypeColumn("distinctValuesCount", 'rowCount'))
    default:
      return (createBaseColumn())
  }


}