import React from 'react';
import { Card, CardContent, Typography, Divider,CardHeader,Grid, Tooltip,FormControl, 
  FormLabel, FormGroup, FormControlLabel,Checkbox} from '@material-ui/core';
import * as commonStyles from 'style/CommonStyles'
import DQIChartAmChartSimple from './components/DQIChartAmChartSimple';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import {  useDispatch } from 'react-redux'
import ProjectDetail from 'components/AqtivaDashboard/components/ProjectDetail'
import QualityPointDetail from 'components/AqtivaDashboard/components/QualityPointDetail'
import * as windowHelper from 'commonFunctions/windowHelper'


export default function DQIProjectComplete(props) {


  const dispatch = useDispatch();


    const { height, width } = windowHelper.useWindowDimensions();
    ///const [graph, setGraph] = React.useState([])

    const [detailedDQIProject, setDetailedDQIProject] = React.useState()
    const [detailedDQIQp, setDetailedDQIQp] = React.useState()
    const [projectViewCheck, setProjectViewCheck]=React.useState(true)
    const [qpViewCheck, setQpViewCheck]=React.useState(false)
    const [detailProjectVisible, setDetailProjectVisible] = React.useState(false)
    const [detailQpVisible, setDetailQpVisible] = React.useState(false)

    const [selectedProject, setSelectedProject] = React.useState(false)
    const [selectedQP, setSelectedQP] = React.useState(false)

    const [hideNodes, setHideNodes] = React.useState([])

    


    
    const handleChangeCheckProjectLevel = (event) => {
      setProjectViewCheck(event.target.checked)
      setQpViewCheck(!event.target.checked)
    };

    const handleChangeCheckQPLevel = (event) => {
      setQpViewCheck(event.target.checked)
      setProjectViewCheck(!event.target.checked)
      
    };


    

      function confirmDialogDetailProject(event){
      setDetailProjectVisible(false)
      }
      

    return (
      <div>
         <AqtivaDialog visible={detailProjectVisible}
            title={`Project ${selectedProject ? selectedProject.projectName : ''} Detail`}
            maxWidth = {width}
            titleIcon={faKey}
            cancelText={'Close'}
            showConfirmButton={false}
            confirmCallback={confirmDialogDetailProject}
            cancelCallback={event=>{setDetailProjectVisible(false)}}
        >
          <div style={{width: width*0.9, height:height*0.9}}>
          <ProjectDetail detailedDQIProject={detailedDQIProject} hideNodes = {hideNodes} filterObj = {props.filterObj} project={selectedProject}/>
          </div>
          
          
          
          </AqtivaDialog> 

          <AqtivaDialog visible={detailQpVisible}
             maxWidth = {width}
            title={`QualityPoint ${selectedQP ? selectedQP.qualityPointName : ''} Detail in Project ${selectedQP.projectName}`}
            titleIcon={faKey}
            cancelText={'Close'}
            showConfirmButton={false}
            confirmCallback={confirmDialogDetailProject}
            cancelCallback={event=>{setDetailQpVisible(false)}}
        >
           <div style={{width: width*0.9, height:height*0.9}}>
            <QualityPointDetail detailedDQIQp={detailedDQIQp} hideNodes = {hideNodes} filterObj = {props.filterObj} selectedQP={selectedQP}/>
          </div>
          
          </AqtivaDialog> 
        <FormControl component="fieldset" style={{marginLeft:'30px'}}>
        <FormLabel component="legend">Select DQI Level</FormLabel>
        <FormGroup style={{display: "flex",
            flexDirection: "row",}} >
          <FormControlLabel
            control={<Checkbox checked={projectViewCheck} onChange={handleChangeCheckProjectLevel} name="gilad" />}
            label="Project"
          />
          <FormControlLabel
            control={<Checkbox checked={qpViewCheck} onChange={handleChangeCheckQPLevel} name="jason" />}
            label="Quality Point"
          />
          
        </FormGroup>
        </FormControl>
      

        <div style={{
            width: width-20+"px", height: '100%',  display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap", marginLeft:'10px', marginRight:'10px', marginTop:'20px'
        }}>

            {projectViewCheck && props.DQI4Project && props.DQI4Project.length > 0 &&
                props.DQI4Project.map(value => {
                    const globalDQI = Math.round(value.sumNumOk / value.sumNumIn * 10000) / 100
                    return (
                        <div style={{ width: '250px', margin:'20px' }}>
                            <Card elevation={3}  styles={{maxWidth: 280,
        borderRadius: 20,
        marginTop: "50px",}}>
                                <CardHeader style={{ backgroundColor: commonStyles.mainColor, height:'30px', width:'100%'}}
                                title={<div style={{width:'100%',display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'}}>
                                        <img alt="" style={{ height: '20px', width: '20px'}}
                                            src={require('resources/images/omma_logo_white_h.png')} />
                                        <Typography variant='subtitle1' style={{ color: '#FFFFFF', fontWeight:'bold' }}>
                                          {value.projectName && value.projectName.length > 20 ? value.projectName.substring(0,17)+"..." : value.projectName}</Typography>
                                        <ZoomInIcon style={{color:'#FFFFFF',cursor:'pointer'}} 
                                        onClick={ event=>{
                                          setSelectedProject(value)
                                          setDetailProjectVisible(true)
                                          dispatch(actions.getDQIProjectDetail(props.filterObj, value.projectId)).then(response=>{
                                            setDetailedDQIProject(response.data)
                                            setHideNodes(response.data.qualityPointDQI === undefined ? [] : 
                                              response.data.qualityPointDQI.flatMap(qpDQI=>qpDQI.datasourceDQI).map(ds=>ds.datasourceId))
                                        }) 
                                        }
                                        }/>
                                    </div>}/>
                                <CardContent >
                                    <div style={{width:'100%', height:'100px'}}>
                                        <DQIChartAmChartSimple data={globalDQI>0 ? globalDQI : -1} id={`${value.projectName}_id`} />
                                    </div>

                                    </CardContent>
                                <Divider/>
                                <CardContent >
                                <Grid container spacing={2} style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                         
                            <Grid item xs={3}>
                              <Tooltip title="Quality points">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                  <img alt="" style={{ height: '20px', width: '25px', marginLeft:'5px', marginRight:'5px',marginTop:'5px' }}
                                  src={require('resources/icons/point_proc1_black.png')}/>
                                   {/* <FontAwesomeIcon
                                      icon={faProjectDiagram}
                                      color=commonStyles.mainColor
                                      style={{ margin: "5px" }}
                                   />*/}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                      {value.qpIds ? value.qpIds.length : 0}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                          
                         
                            <Grid item xs={3}>
                              <Tooltip title="Data sources">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                  <img alt="" style={{ height: '20px', width: '20px', marginLeft:'5px', marginRight:'5px',marginTop:'5px' }}
                                  src={require('resources/icons/data_proc3_black.png')}/>
                                    
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                    {value.dsIds ? value.dsIds.length : 0}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                        
                            <Grid item xs={3}>
                              <Tooltip title="Rules">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                  <img alt="" style={{ height: '20px', width: '20px', marginLeft:'5px', marginRight:'5px',marginTop:'5px' }}
                                  src={require('resources/icons/rule_proc1_black.png')}/>
                                    {/*<FontAwesomeIcon
                                      icon={faFileAlt}
                                      color=commonStyles.mainColor
                                      style={{ margin: "5px" }}
                                    />*/}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                    {value.ruleIds ? value.ruleIds.length : 0}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                         
                        </div>
                      </Grid>
                                </CardContent>
                            </Card>
                        </div>
                    )
                })
            }


{qpViewCheck && props.DQI4QP && props.DQI4QP.length > 0 &&
                props.DQI4QP.map(qp => {
                    const globalDQI = Math.round(qp.sumNumOk / qp.sumNumIn * 10000) / 100
                    return (
                        <div style={{ width: '250px', margin:'20px' }}>
                            <Card elevation={3}  styles={{maxWidth: 280,
        borderRadius: 20,
        marginTop: "50px",}}>
                                <CardHeader style={{ backgroundColor: commonStyles.mainColor, height:'50px', width:'100%'}}
                                title={
                                <div style={{width:'100%'}}>
                                <div style={{width:'100%',display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'}}>
                                        <img alt="" style={{ height: '20px', width: '20px'}}
                                            src={require('resources/images/omma_logo_white_h.png')} />
                                        <Typography variant='subtitle1' style={{ color: '#FFFFFF', fontWeight:'bold' }}>{qp.qualityPointName}</Typography>
                                        <ZoomInIcon style={{color:'#FFFFFF',cursor:'pointer'}} 
                                        onClick={ event=>{
                                          setSelectedQP(qp)
                                          setDetailQpVisible(true)
                                          
                                          dispatch(actions.getDQIQpDetail(props.filterObj, qp.qualityPointId, qp.projectId)).then(response=>{
                                            
                                            setDetailedDQIQp(response.data)
                                            setHideNodes(response.data.datasourceDQI.map(ds=>ds.datasourceId))
                                        }) 
                                        }
                                        }
                                        />
                                    </div>
                                    <div style={{width:'100%', display: 'flex',
                                alignItems: 'center',justifyContent:'center'}}>
                                    <Typography variant='caption' style={{ color: '#FFFFFF',}}>{`(${qp.projectName})`}</Typography>
                                    </div>
                                    </div>   
                                    
                                    }/>
                                <CardContent >
                                    <div style={{width:'100%', height:'100px'}}>
                                        <DQIChartAmChartSimple data={globalDQI>0 ? globalDQI : -1} id={`${qp.qualityPointName}_id`} />
                                    </div>

                                    </CardContent>
                                <Divider/>
                                <CardContent >
                                <Grid container spacing={2} style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                         
                            
                          
                         
                            <Grid item xs={3}>
                              <Tooltip title="Data sources">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                  <img alt="" style={{ height: '20px', width: '20px', marginLeft:'5px', marginRight:'5px',marginTop:'5px' }}
                                  src={require('resources/icons/data_proc3_black.png')}/>
                                    
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                    {qp.dsIds ? qp.dsIds.length : 0}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                        
                            <Grid item xs={3}>
                              <Tooltip title="Rules">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                  <img alt="" style={{ height: '20px', width: '20px', marginLeft:'5px', marginRight:'5px',marginTop:'5px' }}
                                  src={require('resources/icons/rule_proc1_black.png')}/>
                                    {/*<FontAwesomeIcon
                                      icon={faFileAlt}
                                      color=commonStyles.mainColor
                                      style={{ margin: "5px" }}
                                    />*/}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                    {qp.ruleIds ? qp.ruleIds.length : 0}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                         
                        </div>
                      </Grid>
                                </CardContent>
                            </Card>
                        </div>
                    )
                })
            }


        </div>
        </div>



    )
}