import React from 'react';
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import TreeViewSqlServerDatabase from './../treeViews/TreeViewSqlServerDatabase'
import { Grid, FormControlLabel, TextField, Divider, Switch, Typography } from '@material-ui/core';
import { useInterval } from 'components/common/useInterval'
import * as actions from 'actions'
import { dispatchError } from 'components/common/axios/axiosHelper';
import { useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'
import * as helper from './SelectAzureItemDialogHelper'
import ComponentPreviewData from './../ComponentPreviewData'
import ComponentProfilingStatus from './../ComponentProfilingStatus'
import ComponentProfilingBatchStatus from './../ComponentProfilingBatchStatus'
import TreeViewJdbcBasedDatabase from '../treeViews/TreeViewJdbcBasedDatabase';
import AqtivaLoading from 'components/common/AqtivaLoading';

export default function SelectSqlServerDatabaseDialog(props) {

    const user = useSelector(store => store.user)
    const dispatch = useDispatch();
    const theme = useTheme();

    const [runId, setRunId] = React.useState(undefined)

    const [previewDialogVisible, setPreviewDialogVisible] = React.useState(false)
    const [previewDialogParsingData, setPreviewDialogParsingData] = React.useState(false)

    const [schema, setSchema] = React.useState([])

    const [fileOutputName, setFileOutputName] = React.useState('')

    const [stats, setStats] = React.useState([])

    const [delimiter, setDelimiter] = React.useState(undefined)
    const [format, setFormat] = React.useState(undefined)
    const [dataPath, setDataPath] = React.useState(undefined)
    const [dataCount, setDataCount] = React.useState(1)
    const [outputPath, setOutputPath] = React.useState(undefined)

    const [errorBackend, setErrorBackend] = React.useState(undefined)
    const [numberSamples, setNumberSamples] = React.useState(100)
    const [flattenFlag, setFlattenFlag] = React.useState(true)


    const [profilingdataformatId, setProfilingdataformatId] = React.useState(undefined)
    const [profilingBatchId, setProfilingBatchId] = React.useState(undefined)
    const [dataformatWithProfiling, setDataformatWithProfiling] = React.useState(undefined)
    const [profilingBatch, setProfilingBatch] = React.useState(undefined)
    const [profilingState, setProfilingState] = React.useState('')
    const [profilingBatchState, setProfilingBatchState] = React.useState('')
    const [profilingSamples, setProfilingSamples] = React.useState(200000)

    const [sqlError, setSqlError] = React.useState('')
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [selectedSQLDTO, setSelectedSQLDTO] = React.useState({})


    const [selectedEnvironmentStates, setSelectedEnvironmentStates] = React.useState('')
    const [existantDataformats, setExistantDataformats] = React.useState([])
    const [loadingPreview, setLoadingPreview] = React.useState(false)

    const [query2Preview, setQuery2Preview] = React.useState('')
    const [query2PreviewName, setQuery2PreviewName] = React.useState('')


    useInterval(() => {
        if (runId !== undefined) {
            dispatch(actions.getJobRunOutputForUser(runId, user.id, selectedEnvironment, numberSamples, flattenFlag)).then(output => {
                if (output && output.data && output.data.error && output.data.error !== null) {
                    //execution error
                    if (output.data.error === 'No output is available until the task begins.') {
                        setErrorBackend('Starting Big Data server. Depends on the infrastructure it make take some time...')
                    }
                    else {
                        setErrorBackend(output.data.error)
                    }

                }
                if (output && output.data && output.data.metadata && output.data.metadata.state && output.data.metadata.state.result_state === 'FAILED') {
                    //preview failed
                    dispatchError(dispatch, output.data.metadata.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (output && output.data && output.data.state && output.data.state === 'FAILED') {
                    //preview failed
                    dispatchError(dispatch, output.data.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (output && output.data && output.data.result && output.data.result !== null) {
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setSchema(JSON.parse(JSON.parse(output.data.result).schema))
                    setStats(JSON.parse(JSON.parse(output.data.result).statistics))

                    setPreviewDialogVisible(true)
                    setPreviewDialogParsingData(false)


                    setDelimiter(JSON.parse(output.data.result).separator)
                    setFormat(JSON.parse(output.data.result).format)
                    setOutputPath(JSON.parse(output.data.result).outputPath)
                    setDataPath(JSON.parse(output.data.result).dataPath)
                    setDataCount(JSON.parse(output.data.result).count)
                }

            }
            )
        }

    }
        , 3000)

    useInterval(() => {
        if (profilingdataformatId !== undefined) {
            dispatch(actions.fetchDataformat(profilingdataformatId)).then(result => {
                switch (result.data.profilingStatus) {
                    case "starting": {
                        setProfilingState("starting")
                        break
                    }
                    case "in_progress": {
                        setProfilingState("in progress")
                        break
                    }
                    case "ok": {
                        setProfilingState("completed")
                        setDataformatWithProfiling(result.data)
                        break
                    }
                    case "ko": {
                        setProfilingState("some errors were encountered,enable to execute profiling job")
                        break
                    }
                    default:
                        break;
                }
            }
            )
        }
        if (profilingBatchId !== undefined) {
            dispatch(actions.fetchProfilingBatch(profilingBatchId)).then(result => {
                checkStatusProfilingRun()
                switch (result.data.profilingStatus) {
                    case "starting": {
                        setProfilingBatchState("starting")
                        break
                    }
                    case "in_progress": {
                        if (result.data.numberOfProfilings && result.data.dataformatsWithProfiling && result.data.dataformatsWithProfiling.length > 0) {
                            let numberOfFinishedProfilingJobs = checkProfilingsState(result.data)
                            setProfilingBatchState(`in progress: ${numberOfFinishedProfilingJobs} out of ${result.data.numberOfProfilings} profilings finished`)
                        }
                        else {
                            setProfilingBatchState("in progress")
                        }
                        break
                    }
                    case "ok": {
                        setProfilingBatchState("completed")
                        setProfilingBatch(result.data)
                        break
                    }
                    case "ko": {
                        setProfilingBatchState("some errors were encountered,enable to execute profiling job")
                        break
                    }
                    default:
                        break;
                }
            }
            )
        }

    }, 3000)
    function checkStatusProfilingRun() {
        if (runId != undefined) {

            dispatch(actions.getJobRunOutputForUser(runId, user.id, selectedEnvironment)).then(output => {
                if (output && output.data && output.data.state === 'FAILED') {
                    dispatchError(dispatch, output.data.errorMessage)
                    setProfilingState(output.data.errorMessage)
                    setProfilingdataformatId(undefined)
                    setProfilingBatchId(undefined)
                }
            })
        }
    }
    function checkProfilingsState(profilingBatch) {
        let numberOfFinishedProfilingJobs = 0
        for (let i = 0; i < profilingBatch.dataformatsWithProfiling.length; i++) {
            if (profilingBatch.dataformatsWithProfiling[i].profilingStatus && profilingBatch.dataformatsWithProfiling[i].profilingStatus === "ok") {
                numberOfFinishedProfilingJobs += 1
            }
        }
        return numberOfFinishedProfilingJobs
    }



    function setVisiblePreviewCallback(value) {
        setPreviewDialogParsingData(true)
        setPreviewDialogVisible(true)

    }

    function setPreviewDialogVisibleFull(value) {
        setPreviewDialogVisible(value)
        props.setVisible(false)
    }

    //función que convierte un esquema de previsualización en un objecto dataformat, función final del sistema
    function convertSchema2Datasource() {
        if (schema !== undefined) {
            const dataset = helper.convertSchema2DatasourceSqlDatabaseOnprem(schema, user, selectedSQLDTO, fileOutputName)
            if (props.createNewVersion && props.createNewVersion === true && props.selectedDataset) {
                setLoadingPreview(true)
                dispatch(actions.createNewDataformatVersion(dataset, props.selectedDataset._id))
                props.closeDialogDisplay(false)
                setLoadingPreview(false)
            }
            else {
                dispatch(actions.addDataFormatObject(dataset))
            }
            props.setVisible(false)
            props.setVisibleParent(false)
        }


    }


    function createAndContinue() {

        if (schema !== undefined) {
            const dataset = helper.convertSchema2DatasourceSqlDatabaseOnprem(schema, user, selectedSQLDTO, fileOutputName)
            if (props.createNewVersion && props.createNewVersion === true && props.selectedDataset) {
                setLoadingPreview(true)
                dispatch(actions.createNewDataformatVersion(dataset, props.selectedDataset._id))
                props.closeDialogDisplay(false)
                setLoadingPreview(false)
            }
            else {
                dispatch(actions.addDataFormatObject(dataset))
            }

            setVisiblePreviewCallback(true)
            dispatch(actions.fetchAllDataFormats(user))
            setPreviewDialogParsingData(false)
            setPreviewDialogVisible(false)

        }
    }


    function resetProfilingState() {
        setProfilingBatch(undefined)
        setProfilingBatchId(undefined)
        setProfilingBatchState('')
        setProfilingState('')
        setProfilingdataformatId(undefined)
        setDataformatWithProfiling(undefined)
    }

    React.useEffect(() => {
        getSqlOnpremiseDataformats(user)
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps


    async function getSqlOnpremiseDataformats(user) {
        const dataformats = await dispatch(actions.getSqlOnpremiseDataformats(user.id, "sqlServer"))
        setExistantDataformats(dataformats)
    }

    function alreadyExistantDataformat(cred, database, schema, table) {
        var already = false

        if (existantDataformats && existantDataformats.length > 0) {

            existantDataformats.forEach(dataformat => {

                if (dataformat.sqlDatabaseOnpremProperties !== null && dataformat.sqlDatabaseOnpremProperties !== undefined &&
                    dataformat.sqlDatabaseOnpremProperties.server === cred.sqlServerCredentialsDTO.server &&
                    dataformat.sqlDatabaseOnpremProperties.database === database.name &&
                    dataformat.sqlDatabaseOnpremProperties.schema === schema.name &&
                    dataformat.sqlDatabaseOnpremProperties.table === table.tableName) {
                    already = true
                }
            })
        }
        return already

    }

    console.log("selectedEnvironment", selectedEnvironment)

    return (
        <AqtivaDialog visible={props.visible}
            completeScreen={true}
            fullWidth={true}
            title={'Inspect and Select SQL server Database'}
            titleIcon={faWindows}
            confirmText={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? 'Create new version' : 'Create'}
            cancelText={'Close'}
            showConfirmButton={(schema !== undefined && schema.fields !== undefined) ? true : false}
            confirmCallback={() => { convertSchema2Datasource() }}
            cancelCallback={() => { props.setVisible(false) }}
        >
            <div style={{ width: '100%', height: '500px' }}>



                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div style={{ ...theme.elementAdjacent, marginBottom: '-20px', marginLeft: '10px' }}>
                            <div style={{ width: '150px', marginRight: '10px' }}>
                                <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                                    setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                            </div>
                            {undefined && <FormControlLabel style={theme.config.formControl}
                                control={
                                    <TextField
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        id="nSamples"
                                        label={<Typography variant="caption">Samples to Preview</Typography>}
                                        style={theme.config.textField}
                                        margin="dense"
                                        value={numberSamples}
                                        onChange={(event) => setNumberSamples(event.target.value)}
                                    />} />}

                            <FormControlLabel style={theme.config.formControl}
                                control={
                                    <TextField
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        id="nSamples"
                                        label={<Typography variant="caption">Profiling Samples</Typography>}
                                        style={theme.config.textField}
                                        margin="dense"
                                        value={profilingSamples}
                                        onChange={(event) => setProfilingSamples(event.target.value)}
                                    />} />
                            {undefined && <FormControlLabel style={theme.config.formControl}
                                control={
                                    <Switch
                                        checked={flattenFlag}
                                        onChange={event => {
                                            setFlattenFlag(event.target.checked)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={<Typography variant="caption">Flatten Nested Structures</Typography>}
                            />}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {sqlError !== undefined && sqlError !== '' &&
                        <Grid item xs={12}>
                            <Alert severity="error">{sqlError}</Alert>


                        </Grid>

                    }
                    <Grid item xs={4} style={{ marginTop: '-20px' }}>
                        <TreeViewJdbcBasedDatabase
                            setProfilingdataformatId={setProfilingdataformatId}
                            setProfilingBatchId={setProfilingBatchId}
                            setRunId={setRunId}
                            setVisiblePreview={setVisiblePreviewCallback}
                            setFileOutputName={setFileOutputName}
                            numberSamples={numberSamples}
                            profilingSamples={profilingSamples}
                            flattenFlag={flattenFlag}
                            setSqlError={setSqlError}
                            alreadyExistantDataformat={alreadyExistantDataformat}
                            type={'sql_server'}
                            storageType={'sqlServer'}
                            sqlError={sqlError}
                            selectedEnvironment={selectedEnvironment}
                            setSelectedItemDTO={setSelectedSQLDTO}
                            resetProfilingState={resetProfilingState}
                            setPreviewVisible={setPreviewDialogVisible}
                        />
                        {/*<TreeViewSqlServerDatabase
                            setProfilingdataformatId={setProfilingdataformatId}
                            setProfilingBatchId={setProfilingBatchId}
                            setRunId={setRunId}
                            setVisiblePreview={setVisiblePreviewCallback}
                            setFileOutputName={setFileOutputName}
                            numberSamples={numberSamples}
                            profilingSamples={profilingSamples}
                            flattenFlag={flattenFlag}
                            setSqlError={setSqlError}
                            sqlError={sqlError}
                            selectedEnvironment={selectedEnvironment}
                            setSelectedSQLDTO={setSelectedSQLDTO}
                            resetProfilingState={resetProfilingState}
                            setPreviewVisible={setPreviewDialogVisible}
                        />*/}
                    </Grid>
                    <Grid item xs={8}>
                        {loadingPreview &&
                            <AqtivaLoading />
                        }
                        {!loadingPreview &&
                            <>
                                <ComponentPreviewData
                                    selectedEnvironment={selectedEnvironment}
                                    visible={previewDialogVisible}
                                    setVisible={setPreviewDialogVisible}
                                    setVisibleFull={setPreviewDialogVisibleFull}
                                    parsingData={previewDialogParsingData}
                                    fileOutputName={fileOutputName}
                                    schema={schema}
                                    errorBackend={errorBackend}
                                    stats={stats}
                                    delimiter={delimiter}
                                    format={format}
                                    dataPath={dataPath}
                                    outputPath={outputPath}
                                    dataCount={dataCount}
                                    selectedEnvironmentStates={selectedEnvironmentStates}
                                    createAndContinue={createAndContinue}
                                />
                                <ComponentProfilingStatus profilingState={profilingState} visible={true} dataformatWithProfiling={dataformatWithProfiling} />
                                <ComponentProfilingBatchStatus profilingBatchState={profilingBatchState} visible={true} setProfilingBatch={setProfilingBatch} profilingBatch={profilingBatch} />
                            </>
                        }
                    </Grid>


                </Grid>

            </div>

        </AqtivaDialog>
    )
}