import React, { Component } from 'react';

import {Grid, Dialog,DialogTitle,DialogContent,DialogActions,Button,TextField,Typography} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './../../../../style/CustomTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToolbox } from '@fortawesome/free-solid-svg-icons'
import trackEvent from './../../../../trackEvent'
class DialogCreateNewEnvironment extends Component {

    constructor(props) {
        super(props);
        this.state={
            environmentName:'',
            hasError : false,
            error:''
        }
        
    }

    isEmpty = (obj) =>{
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    onHide = ()=>{
        this.props.onHide();
    }
    handleTextFieldChange = name  => event => {
        this.setState({ [name]: event.target.value,hasError:false  });
    };
    onClickAddEnvironment = () =>{
        trackEvent('DialogCreateNewEnvironment','Add Environment');
        var environmentListName = (this.props.configuration && this.props.configuration.environmentConfigurationList) ?
        this.props.configuration.environmentConfigurationList.map(e=>e.environmentName).filter(e=>e.toLowerCase()===this.state.environmentConfigurationList.toLowerCase())
        :
        undefined
        
        if( !/^[\w_]*$/.test(this.state.environmentName)){
            this.setState({hasError:true,error:'Incorrect environment name. The name must only contain letters, numbers and _'})
        }
       
        if(environmentListName || this.isEmpty(environmentListName)){
            this.props.onSubmit(this.state.environmentName)
        }
        else{
            this.setState({hasError:true,error:'This environment is already defined'})
        }
        
    }
    render(){

        
        return(
                <Dialog
                    open={this.props.open}
                    onClose={this.onHide}
                    fullWidth={true}
                    maxWidth = {'xs'}
                    aria-labelledby="form-dialog-create_environment-title"
                >
                    <DialogTitle style={{backgroundColor: '#474b51'}}>
                    <Grid container spacing={3}>
                        <Grid item>
                                <FontAwesomeIcon icon={faToolbox} style={{color:'#D3D0D3', fontSize:30}} />
                            
                        </Grid>
                        <Grid item style={{marginTop: '1%'}}>
                            <Typography style={{'display': 'flex', 'justifyContent': 'flex-start', marginBottom:'1%', color: '#D3D0D3'}}>
                                {'Create Environment'}
                            </Typography>
                        </Grid>
                    </Grid>
                    </DialogTitle>

                    
                    <DialogContent>
                    <Typography variant='subtitle2'>Environment Name</Typography>
                    <TextField type="text"
                                    
                                     value={this.state.environmentName} 
                                    autoComplete='off'
                                        id='environmentName'
                                        style={{ width: '250px', marginTop: '10px' }}
                                        onChange={this.handleTextFieldChange('environmentName')} />
                    {this.state.hasError && <Typography variant='subtitle2' style={{color:'red'}}>{this.state.error}</Typography>}
                    </DialogContent>
                   
                    <DialogActions>
                <Button id='SaveButton' variant='outlined' color="primary"  onClick = {this.onClickAddEnvironment}>Save</Button>
                 
                    <Button id='CancelButton' variant='outlined' color="secondary"  onClick={event=>{
                        this.onHide()
                        
                        }}>Cancel
                    </Button>
                </DialogActions>
                </Dialog>
        )
    }
}

export default withStyles(styles)(DialogCreateNewEnvironment)