import React from 'react';
import {makeStyles,TextField,} from '@material-ui/core';
import classNames from 'classnames';

import { styles, QualityTheme } from 'style/CustomTheme'
import {getDataFromProperties} from './SpecificRuleHelper'



export default function FormColumnAnomalyDetection(props){

    const classes = makeStyles(styles(QualityTheme));


    const [stdDevMultiplier,setStdDevMultiplier] = React.useState(getDataFromProperties(props.editableRule, props.specific,'stdDevMultiplier',3))
    
    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            subtype:'cell_anomaly_columns',
            stdDevMultiplier: stdDevMultiplier
        }
        props.setSpecific(specific)
       
    }

   
    
    React.useEffect(() => {
        createSpecific() 
    }, [stdDevMultiplier]);// eslint-disable-line react-hooks/exhaustive-deps

    


    return(
        <div style={{marginTop:'5px'}}>
            <div style={{maxWidth:'150px'}}>
            <TextField
                    fullWidth={true}
                    id="expTextField"
                    label="Standard Deviation Multiplier"
                    InputLabelProps={{ shrink: true, style:{ fontSize:'12px'} }}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    type='number'
                    value={stdDevMultiplier} 
                    onChange={event =>{setStdDevMultiplier(event.target.value)}}
                />
            </div>
            
            
    </div>
    )
}
