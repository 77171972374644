const images = {
    icons: {
        storage: require('resources/icons/azure/Storage-Accounts.png'),
        container: require('resources/icons/azure/Storage-Container.png'),
        oracle: require('resources/icons/oracle.png'),
        mySQL: require('resources/icons/mySQL.png'),
        sqlServer: require('resources/icons/sqlServer.png'),
        bigQuery: require('resources/icons/bigQuery.png'),
        aws: require('resources/icons/aws/aws-s3-logo.png'),
        hive: require('resources/icons/hive.png'),
        redshift: require('resources/icons/redshift.png'),
    },

};

export default images;