import React from 'react';

import {
    PieChart, Pie, ResponsiveContainer
} from 'recharts';

import * as colorHelper from './../QualityDashboardPagev2/commons/colorHelper'



export default function TotalScoreChart(props) {
    
    const [scoreAngle, setScoreAngle] = React.useState(0)
    //const [datatoget, setDatatoget] = React.useState({ name: 'A1', value: -1 })

    const baseInnerRadius = 38
    const baseExternalRadius = 48
    const baseInnerRadius2 = baseInnerRadius-1
    const baseExternalRadius2 = baseExternalRadius+1

    const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);
  
    React.useEffect(() => {
       
        if (props.data && props.data) {
            setScoreAngle(props.data===-1 ? 0 : -props.data* 358 / 100)
            //setDatatoget({ name: 'A1', value: props.data })
        }
        forceUpdate()
    }, [props.data]);// eslint-disable-line react-hooks/exhaustive-deps

   

  
 
    if (props.data < 0) {
        const data = [{ name: 'ko', value: 1 }];
        return (

            <div style={{height:props.height, position: 'relative'}}>
                <ResponsiveContainer width="100%"  >

                    <PieChart  width={ 15 } height={ 15 }>
                        <text fontSize={'0.7vw'} x={'50%'} y={ '70%'} textAnchor="middle" dominantBaseline="middle">No Data</text>

                        <Pie
                            data={data}
                            startAngle={-0}
                            endAngle={-358}
                            innerRadius={ baseInnerRadius}
                            outerRadius={baseExternalRadius }
                            cx="50%" cy="50%"
                            fill = { '#A2A2A2AA'}
                            
                        >

                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                {colorHelper.getGlobalIconTiny(props.data)} 

            </div>
        )
    }
     else if (props.data === 0) {
        const data = [{ name: 'ko', value: 1 }];
        return (

            <div style={{height:props.height, position: 'relative'}}>
                <ResponsiveContainer width="100%"  >

                    <PieChart  width={ 15 } height={ 15 }>
                        <text fontSize={'0.7vw'} x={'50%'} y={ '70%'} textAnchor="middle" dominantBaseline="middle">{`${props.data}%`}</text>

                        <Pie
                            data={data}
                            startAngle={-0}
                            endAngle={-358}
                            innerRadius={ baseInnerRadius}
                            outerRadius={baseExternalRadius }
                            cx="50%" cy="50%"
                            fill = { '#F3410DAA'}
                            dataKey="value"
                        >

                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                {colorHelper.getGlobalIconTiny(props.data)} 

            </div>
        )
    }
    else {
        const data = [{ name: 'ko', value: 1 }];
        return (
            <div style={{height:props.height, position: 'relative'}}>
                <ResponsiveContainer width="100%"  >

                    <PieChart  width={15} height={15} >
                    <text fontSize={'0.7vw'} x={'50%'} y={ '70%'} textAnchor="middle" dominantBaseline="middle">{`${props.data}%`}</text>


                        
                        <Pie
                            data={data}
                            startAngle={scoreAngle}
                            endAngle={-358}
                            innerRadius={ baseInnerRadius}
                            outerRadius={baseExternalRadius }
                            fill = {props.data<0 ? '#F3410DAA' : '#A2A2A2AA'}
                            cx="50%" cy="50%"
                            paddingAngle={0}
                            dataKey="value"
                            

                        >

                        </Pie>
                        <Pie
                            data={data}

                            startAngle={-0}
                            endAngle={scoreAngle}
                            innerRadius={ baseInnerRadius2}
                            outerRadius={baseExternalRadius2}
                            cx="50%" cy="50%"
                            fill={colorHelper.getColorForPercentage(props.data)}
                            paddingAngle={5}
                            dataKey="value"
                        >

                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
               {colorHelper.getGlobalIconTiny(props.data)} 

            </div>
        )
    }
}
