import React from "react";
import Box from '@material-ui/core/Box';
import AspectRatio from '@material-ui/icons/AspectRatio'
import Tooltip from '@material-ui/core/Tooltip'
import { Info } from "@material-ui/icons";
import { Paper, Typography } from '@material-ui/core';
import * as commonStyles from '../../../../../style/CommonStyles'
import AqtivaDialog from 'components/common/AqtivaDialog';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import AqtivaLoading from 'components/common/AqtivaLoading'
import * as windowHelper from 'commonFunctions/windowHelper'

export default function DashboardChartComponent(props) {


    const handleOpen = () => setOpenAvatarModal(true);
    const handleClose = () => setOpenAvatarModal(false);
    const [openAvatarModal, setOpenAvatarModal] = React.useState(false);
    //const fullScreenHeight = props.fullScreenHeight !== undefined ? (props.fullScreenHeight + '%') : 'auto'
    const fullScreenWidth = props.fullScreenWidth !== undefined ? (props.fullScreenWidth + '%') : '100%'
    const marginLeftBtn = props.marginLeftBtn !== undefined ? (props.marginLeftBtn + 'px') : '2px'
    const { height, width } = windowHelper.useWindowDimensions();

    
    
    

    return (
        <Box
        borderRadius={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                
                width: fullScreenWidth,
                
                marginLeft: props.noMarginLeft ? '' : ''
            }}>
            {!openAvatarModal &&
                <Paper elevation={3} style={{ height: '100%', width: '100%' }}>
                    <div style={commonStyles.DashboardChartComponentTitle}>

                        <Tooltip title={props.description}>
                            <Info style={{ width: '20px', color: '#FFFFFF', cursor: 'pointer', marginLeft: marginLeftBtn }} />
                        </Tooltip>

                        <Typography variant='h6' style={commonStyles.titleText} > 
                            {props.title}
                        </Typography>
                        <AspectRatio style={{ width: '20px', color: '#FFFFFF', cursor: 'pointer', marginRight: '5px' }} onClick={handleOpen} />
                    </div>
                    <div>
                        { props.loading === false ? props.content :  <AqtivaLoading />}
                    </div>
                </Paper>
            }

            <AqtivaDialog visible={openAvatarModal}
                title={props.title}
                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                cancelCallback={handleClose}
                maxWidth={width}
            >
                <div style={{ width: width * 0.9, height: height * 0.7 }}>
                    {props.fullscreenContent ? props.fullscreenContent : props.content}
                </div>

            </AqtivaDialog>

        </Box>
    )


}