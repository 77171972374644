import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as commonStyles from 'style/CommonStyles'

export default function DQIPerObjectChart(props) {
    const [data, setData] = useState([])
    useEffect(() => {
        if (props.data && props.data.length > 0) {
            setData(props.data.map(val => {
                return {
                    ...val,
                    'scoreKo': val.scoreKo && val.scoreKo !== -0 ? val.scoreKo : ''
                }
            }))
        }

    }, [props.data])


    React.useLayoutEffect(() => {


        let root = am5.Root.new(props.chart);

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                layout: root.horizontalLayout,
                arrangeTooltips: false,
                //heigh
            }));


        // Use only absolute numbers
        root.numberFormatter.set("numberFormat", "#.#s'%");

        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: props.categoryField,
            renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
            }),
        }));

        yAxis.data.setAll(data);

        let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                calculateTotals: true,
                min: -100,
                max: 100,
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 50
                }),
            })
        );

        let xRenderer = xAxis.get("renderer");
        let yRenderer = yAxis.get("renderer");

        xRenderer.axisFills.template.setAll({
            fill: am5.color(0x000000),
            fillOpacity: 0.05,
            visible: true
        });

        yRenderer.labels.template.setAll({
            fill: am5.color(0x000000),
            fontSize: "12px"
          });

          xRenderer.labels.template.setAll({
            fill: am5.color(0x000000),
            fontSize: "12px"
          });

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function createSeries(color, value, negative) {

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: value,
                categoryYField: props.categoryField,
                sequencedInterpolation: true,
                stacked: true,
                fill: color,
                stroke: color,
                calculateAggregates: true,
            }));

            series.columns.template.setAll({
                height: data.length === 1 ? am5.percent(50) : am5.p100  ,
            });

            series.columns.template.events.on("click", (e) => {
                props.click(e.target._dataItem.dataContext)
            })

            series.bullets.push(function (_root, _serie, item) {

                return am5.Bullet.new((root), {
                    locationX: negative ? 1 : 0,
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        fill: am5.color(0xffffff),
                        centerY: am5.p50,
                        text: "[#000000R]{valueX}",
                        locationY: 0.5,
                        populateText: true,
                        oversizedBehavior: value === 'score' ?
                            (item.dataContext.score > 10 ? 'show' : 'hide') :
                            (item.dataContext.scoreKo < -10 ? 'show' : 'hide')
                    })
                });
            });

            series.data.setAll(data);

        }

        let positiveColor = commonStyles.colorOkTransp;
        let negativeColor = commonStyles.colorKoTransp;

        createSeries(negativeColor, props.valueKo, true);
        createSeries(positiveColor, props.valueOk, false);


        chart.appear(100);


        return () => {
            chart.dispose()
        }

    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div style={{width: "100%", height: props.small ? "230px" : '274px'}}>
            <div id={props.chart} style={{ marginTop:"20px",width: "100%", height: props.small ? "230px" : '274px', marginRight: '2%' }}></div>
        </div>
    )
}