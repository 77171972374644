import React from 'react'

import {
    makeStyles, Typography, Paper, Grid, Select, Input, MenuItem, Chip, Avatar,
    TextField, Button, FormControl, FormControlLabel, Switch, IconButton, InputLabel, Divider
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { styles, QualityTheme } from 'style/CustomTheme'

import Autocomplete from '@material-ui/lab/Autocomplete';

import * as actions from 'actions'
import { useDispatch } from 'react-redux'



import trackEvent from 'trackEvent'


export default function ExecutionConditionRule(props) {

    const theme = useTheme();
    const classes = makeStyles(styles(QualityTheme));

    const dispatch = useDispatch();

    const [selectedDataformat, setSelectedDataformat] = React.useState(undefined)


    const [selectedColumn, setSelectedColumn] = React.useState("")
    const [selectedCondition, setSelectedCondition] = React.useState('')
    const [textValueRange, setTextValueRange] = React.useState('');
    const [rule_range_array, setRule_range_array] = React.useState([]);
    const [value_range_check, setValue_range_check] = React.useState(false);
    const [rule_max_range, setRule_max_range] = React.useState();
    const [rule_min_range, setRule_min_range] = React.useState();
    const [type_range, setType_range] = React.useState('between');
    const [conditions, setConditions] = React.useState(props.conditions ? props.conditions : [])
    const [selectedJoin, setSelectedJoin] = React.useState('and')

    const [null_check, set_null_check] = React.useState(false)
    const [not_null_check, set_not_null_check] = React.useState(false)
    const [sql_check, set_sql_check] = React.useState(false)
    const [sqlExpression, setSqlExpression] = React.useState('')

    const [value_range_bool, setValue_range_bool] = React.useState([]);

    React.useEffect(() => {
        if (props.commonRule && props.commonRule.common && props.commonRule.common.datasource) {
            dispatch(actions.fetchDataformat(props.commonRule.common.datasource.dataformatId ? props.commonRule.common.datasource.dataformatId :
                props.commonRule.common.datasource.dataset._id)).then(
                    response => {
                        setSelectedDataformat(response.data)
                    }
                )
        }
    }, [props.commonRule])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setConditions(props.conditions ? props.conditions : [])
    }, [props.conditions]);

    function handleNullCheck(event) {
        set_null_check(event.target.checked)
        set_not_null_check(false)
    }

    function handleSqlCheck(event) {
        set_sql_check(event.target.checked)
    }
    function handleNotNullCheck(event) {
        set_not_null_check(event.target.checked)
        set_null_check(false)
    }

    function handleSqlCheck(event) {
        set_sql_check(event.target.checked)
    }

    function cleanConditionValues() {

        setSelectedColumn("")
        setValue_range_bool([])
        setSelectedCondition('')
        setRule_range_array([])
        setValue_range_check(false)
        setTextValueRange('')
        setRule_max_range(undefined)
        setRule_min_range(undefined)
        setType_range('between')
        setSelectedJoin('and')
        set_sql_check(false)
        setSqlExpression('')
    }

    function getConditionString(condition) {

        if (condition.isSqlExpression) {
            return `${condition.sqlExpressions.substring(0, 8)}`
        }
        if (condition.nullCheck === true) {

            return `${condition.column.name} is null`
        }
        else if (condition.notNullCheck === true) {
            return `${condition.column.name} is not null`
        }
        else if (condition.valueRangeBool && condition.valueRangeBool.length > 0) {
            return `${condition.column.name} in ${condition.valueRangeBool ? condition.valueRangeBool.join(',') : 'None'}`
        }
        else if (condition.operator === 'in') {
            return `${condition.column.name} in ${condition.listValues ? condition.listValues.join(',') : 'None'}`
        }
        else if (condition.operator === 'between') {
            return `${condition.column.name} between ${condition.lowerValue} and ${condition.greaterValue}`
        }
        else {
            return `${condition.column.name} ${condition.operator} than ${condition.lowerValue ? condition.lowerValue : condition.greaterValue}`
        }
    }

    function getCompleteConditionString() {
        var conditionIndex;
        var conditionString = ""
        if (conditions.length > 0) {
            for (conditionIndex = 0; conditionIndex < conditions.length - 1; conditionIndex++) {
                // Se ejecuta 5 veces, con valores desde paso desde 0 hasta 4.
                conditionString = conditionString + " ( " + getConditionString(conditions[conditionIndex]) + " ) " + conditions[conditionIndex].selectedJoin
            };
            conditionString = conditionString + " ( " + getConditionString(conditions[conditions.length - 1]) + " ) "
        }
        return conditionString
    }

    function createCondition() {
       
        let condition

        if (sql_check) {
            condition = {
                selectedJoin: selectedJoin,
                isSqlExpression: sql_check,
                sqlExpressions: sqlExpression
            }
        } else {
            let selectedColumnObj = selectedColumn && selectedColumn.name ? selectedColumn : JSON.parse(selectedColumn)
            
            condition = {
                column: selectedColumnObj,
                operator: getConditionOperator(),
                lowerValue: rule_min_range,
                greaterValue: rule_max_range,
                listValues: rule_range_array,
                valueCheck: value_range_check,
                valueRangeBool: value_range_bool,
                selectedJoin: selectedJoin,
                nullCheck: null_check,
                isSqlExpression: sql_check,
                notNullCheck: not_null_check

            }
        }

        const newCond = [...conditions, condition]
        props.setConditions(newCond)
        setConditions(conditions => [...conditions, condition]);
        cleanConditionValues()

    }

    function updateValuesSelectedCondition(selectedCondition_) {

        if (selectedCondition_) {
            let selectedCondition_aux = JSON.parse(selectedCondition_)

            if (selectedCondition_aux.isSqlExpression) {
                set_sql_check(true)
                setSqlExpression(selectedCondition_aux.sqlExpressions)
            } else {

                if (selectedCondition_aux && selectedCondition_aux.column) {
                    set_sql_check(false)
                    setSelectedColumn(selectedCondition_aux.column)
                    setRule_range_array(selectedCondition_aux.listValues ? selectedCondition_aux.listValues : [])
                    setValue_range_check(selectedCondition_aux.valueCheck)
                    setRule_max_range(selectedCondition_aux.greaterValue)
                    setRule_min_range(selectedCondition_aux.lowerValue)
                    setType_range(selectedCondition_aux.operator)
                    setSelectedJoin(selectedCondition_aux.selectedJoin)
                    set_null_check(selectedCondition_aux.nullCheck)
                    set_not_null_check(selectedCondition_aux.notNullCheck)
                    setValue_range_bool(selectedCondition_aux.valueRangeBool)
                }
            }
        }

    }

    function getConditionOperator() {
        if (null_check) {
            return 'is null'
        }
        else if (not_null_check) {
            return 'is not null'
        }
        else if (value_range_check) {
            return type_range
        }
        else {
            return 'in'
        }

    }

    function onClickAddString2List(e) {
        var array = rule_range_array
        array.push(textValueRange)
        setRule_range_array(array)
        setTextValueRange('')


    }

    function onChangeTextValueRange(e) {
        setTextValueRange(e.target.value)
    }
    function onChangeMinRange(e) {
        setRule_min_range(e.target.value)
    }
    function onChangeMaxRange(e) {
        setRule_max_range(e.target.value)
    }

    function onChangeRangeType(event) {
        setType_range(event.target.value)
    }
    function onChangeSQLExpression(event) {
        setSqlExpression(event.target.value)
    }

    function getRenderExpression() {
        return (
            <div style={{ width: '100%' }}>
                <TextField type='string'
                    value={sqlExpression}
                    style={{ width: '100%' }}
                    onChange={onChangeSQLExpression}
                    label={<Typography style={theme.inputLabelBig}>SQL Expression</Typography>}
                    margin="dense"
                />
            </div>
        )
    }

    function onChangeSQLExpression(event) {
        setSqlExpression(event.target.value)
    }

    function getRenderExpression() {
        return (
            <div style={{ width: '100%' }}>
                <TextField type='string'
                    value={sqlExpression}
                    style={{ width: '100%' }}
                    onChange={onChangeSQLExpression}
                    label={<Typography style={theme.inputLabelBig}>SQL Expression</Typography>}
                    margin="dense"
                />
            </div>
        )
    }

    function getRenderConditions() {

        let column = selectedColumn ? (selectedColumn.name ? selectedColumn : JSON.parse(selectedColumn)) : undefined
        if (!column || !column.type) {
            return (
                <div>

                </div>
            )
        }
        if (column.type.toLowerCase() === 'string') {
            return getRenderConditionString()
        }
        else if (column.type.toLowerCase() === 'integer' || column.type.toLowerCase() === 'float' || column.type.toLowerCase() === 'number') {
            return getRenderConditionNumeric()
        }
        else if (column.type.toLowerCase() === 'date') {
            return getRenderConditionDate()
        }
        else if (column.type.toLowerCase() === 'boolean') {
            return getRenderConditionBoolean()
        }
        else {
            return (<div>Error</div>)
        }
    }

    function getRenderConditionDate() {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={null_check}
                                onChange={handleNullCheck}
                                value={null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is null</Typography>}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={not_null_check}
                                onChange={handleNotNullCheck}
                                value={not_null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is not null</Typography>}
                    />
                </div>
            </div>
        )

    }
    function getRenderConditionBoolean() {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={null_check}
                                onChange={handleNullCheck}
                                value={null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is null</Typography>}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={not_null_check}
                                onChange={handleNotNullCheck}
                                value={not_null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is not null</Typography>}
                    />
                    {null_check === false && not_null_check === false &&
                        <Grid container spacing={2} alignItems='flex-end' >
                            <Grid item xs={4} >
                                <FormControl >
                                    <InputLabel id="demo-mutiple-checkbox-label">Select Values</InputLabel>
                                    <Select
                                        multiple
                                        style={{ width: '300px', height: '35px' }}
                                        value={value_range_bool}
                                        input={<Input id="select-multiple-checkbox" />}
                                        onChange={event => { console.log('eee', event.target); setValue_range_bool(event.target.value) }}
                                    >

                                        <MenuItem
                                            key={'true'}
                                            value={true}
                                        >
                                            {'true'}
                                        </MenuItem>
                                        <MenuItem
                                            key={'false'}
                                            value={false}
                                        >
                                            {'false'}
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </div>
            </div>
        )

    }
    function getRenderConditionString() {
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={null_check}
                                onChange={handleNullCheck}
                                value={null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is null</Typography>}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={not_null_check}
                                onChange={handleNotNullCheck}
                                value={not_null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is not null</Typography>}
                    />

                    {null_check === false && not_null_check === false && <Grid container spacing={2} alignItems='flex-end' >

                        <Grid item xs={4} >
                            <div style={theme.elementAdjacent}>
                                <TextField type='string'
                                    value={textValueRange}
                                    style={{ wdith: '100%' }}
                                    autoComplete='off'
                                    onChange={onChangeTextValueRange}
                                    label={<Typography style={theme.inputLabelBig}>Add Value to List</Typography>}
                                    margin="dense"
                                />
                                <IconButton color="primary"
                                    onClick={event => { onClickAddString2List(event); trackEvent('Execution Condition List', 'Add Strings 2 List'); }}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </Grid>

                        <Grid item xs={5}>
                            <Select
                                value={rule_range_array}
                                style={{ width: '100%' }}
                                input={<Input id="select-multiple-checkbox" />}
                                renderValue={selected => {
                                    return <div className={classes.chips}>
                                        {selected && selected.map(value => (
                                            <Chip
                                                avatar={<Avatar>
                                                    <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                        onClick={(e) => { onDeleteSelectedItem(value); trackEvent('Execution Condition List', 'Delete Selected Item'); }} /></Avatar>}
                                                key={value}
                                                label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                }}
                            >

                                {rule_range_array &&
                                    rule_range_array.map(e => (
                                        <MenuItem key={e} value={e} >
                                            <Chip
                                                avatar={<Avatar>
                                                    <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                        onClick={(event) => { onDeleteSelectedItem(e); trackEvent('Execution Condition List', 'Delete Selected Item'); }} /></Avatar>}
                                                key={e}
                                                label={e} className={classes.chip} />
                                        </MenuItem>
                                    ))}

                            </Select>
                        </Grid>
                    </Grid>}
                </div>


            </div>
        )
    }
    function getRenderConditionNumeric() {
        return (
            <div style={{ width: '100%' }}>

                <FormControlLabel
                    control={
                        <Switch
                            checked={null_check}
                            onChange={handleNullCheck}
                            value={null_check}
                            color="primary"
                        />
                    }
                    label={<Typography style={theme.inputLabelBig}>Execute if value is null</Typography>}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={not_null_check}
                            onChange={handleNotNullCheck}
                            value={not_null_check}
                            color="primary"
                        />
                    }
                    label={<Typography style={theme.inputLabelBig}>Execute if value is not null</Typography>}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={value_range_check}
                            onChange={handleChangeValueRangeCheck}
                            value={value_range_check}
                            color="primary"
                        />
                    }
                    label={<Typography style={theme.inputLabelBig}>Value Range Selection</Typography>}
                />

                {null_check === false && not_null_check === false && !value_range_check &&
                    <div style={{ width: '100%' }}>
                        <Grid container spacing={2} alignItems='flex-end' >

                            <Grid item xs={4} >
                                <div style={theme.elementAdjacent}>
                                    <TextField type='number'
                                        value={textValueRange}
                                        style={{ wdith: '100%' }}
                                        autoComplete='off'
                                        onChange={onChangeTextValueRange}
                                        label={<Typography style={theme.inputLabel}>Add Value to List</Typography>}
                                        margin="dense"
                                    />
                                    <IconButton color="primary"
                                        onClick={event => { onClickAddString2List(event); trackEvent('Execution Condition List', 'Add Strings 2 List'); }}>
                                        <AddIcon />
                                    </IconButton>
                                </div>

                            </Grid>

                            <Grid item xs={5}>
                                <Select
                                    value={rule_range_array}
                                    style={{ width: '100%' }}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={selected => {
                                        return <div className={classes.chips}>
                                            {selected && selected.map(value => (
                                                <Chip
                                                    avatar={<Avatar>
                                                        <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                            onClick={(e) => { onDeleteSelectedItem(value); trackEvent('Execution Condition List', 'Delete Selected Item'); }} /></Avatar>}
                                                    key={value}
                                                    label={value} className={classes.chip} />
                                            ))}
                                        </div>
                                    }}
                                >

                                    {rule_range_array &&
                                        rule_range_array.map(e => (
                                            <MenuItem key={e} value={e} >
                                                <Chip
                                                    avatar={<Avatar>
                                                        <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                            onClick={(event) => { onDeleteSelectedItem(e); trackEvent('Execution Condition List', 'Delete Selected Item') }} /></Avatar>}
                                                    key={e}
                                                    label={e} className={classes.chip} />
                                            </MenuItem>
                                        ))}

                                </Select>
                            </Grid>
                        </Grid>
                    </div>
                }
                {null_check === false && not_null_check === false && value_range_check &&
                    <div>
                        <Grid container spacing={2} alignItems='flex-end'>
                            <Grid xs={2}>
                                <FormControl >
                                    <Select
                                        inputProps={{
                                            name: 'range type',
                                            id: 'range type'

                                        }}
                                        style={{ width: '100%', marginBottom: '12%' }}
                                        value={type_range}
                                        onChange={onChangeRangeType}

                                    >
                                        <MenuItem key={'lower'} value={'lower'}>
                                            <Typography>lower</Typography>
                                        </MenuItem>
                                        <MenuItem key={'greater'} value={'greater'}>
                                            <Typography>greater</Typography>
                                        </MenuItem>
                                        <MenuItem key={'between'} value={'between'}>
                                            <Typography>between</Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {type_range !== 'lower' && <Grid item xs={3}>
                                <TextField type='number'
                                    value={rule_min_range}
                                    autoComplete='off'
                                    onChange={onChangeMinRange}
                                    label={<Typography style={theme.inputLabel}>Min Value</Typography>}
                                    style={{ width: '100%' }}

                                    margin="dense"
                                />
                            </Grid>
                            }
                            {type_range !== 'greater' &&
                                <Grid item xs={3}>
                                    <TextField type='number'
                                        value={rule_max_range}
                                        autoComplete='off'
                                        onChange={onChangeMaxRange}
                                        label={<Typography style={theme.inputLabel}>Max Value</Typography>}
                                        style={{ width: '100%' }}
                                        margin="dense"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </div>
        )
    }

    function onDeleteSelectedItem(value) {
        var selectedColTemp = rule_range_array
        for (var i = 0; i < rule_range_array.length; i++) {
            if (value === rule_range_array[i]) {
                selectedColTemp.splice(i, 1);
                i--;
            }

        }
        setRule_range_array(selectedColTemp)
    }

    function handleChangeValueRangeCheck(event) {
        setValue_range_check(event.target.checked)
    }

    function deleteSelectedCondition() {
        //let selectedConditionParsed = JSON.parse(selectedCondition)
        setConditions(conditions.filter(item => JSON.stringify(item) !== selectedCondition));
        props.setConditions(conditions.filter(item => JSON.stringify(item) !== selectedCondition))
        setSelectedCondition('')
        cleanConditionValues()

    }


    return (
        <Paper style={{ width: '100%', marginTop: '-5%' }}>

            <Grid container spacing={2} style={{ margin: '5%' }} >
                <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                        Column condition definition
                    </Typography>

                    <Grid container spacing={2} alignItems='flex-end'>
                        <Grid item xs={2} style={{ marginRight: '3%' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={sql_check}
                                        onChange={handleSqlCheck}
                                        value={sql_check}
                                        color="primary"
                                    />
                                }
                                label={<Typography style={theme.inputLabelBig}>SQL expression</Typography>}
                            />
                        </Grid>
                        {!sql_check && <Grid item xs={4}>
                            <Autocomplete
                                style={{ minWidth: "20%" }}
                                autoWidth={true}
                                labelId="origin-column"
                                id="origin-column"
                                value={selectedColumn}
                                label="origin column"
                                onChange={(event, newValue) => {
                                    setSelectedColumn(newValue)
                                }}

                                options={(props.columnsEnriched) ? props.columnsEnriched : []}
                                getOptionLabel={(data) => (data) ? `#${data.position} (${data.type}) ${data.name}` : ''}
                                renderInput={(e) => <TextField {...e} label="Available Columns" />}
                            />

                        </Grid>}
                        <Grid item xs={2}>
                            <InputLabel htmlFor="typeWarnTh-selection-helper" style={theme.inputLabel}>Join</InputLabel>
                            <Select
                                native
                                value={selectedJoin}
                                style={{
                                    width: '100%',
                                    fontSize: 14,

                                }}
                                onChange={event => { setSelectedJoin((event.target.value)) }}
                                input={<Input id="select-multiple-checkbox" />}
                            >
                                <option value="and">and</option>
                                <option value="or">or</option>
                                <option value="xand">xand</option>
                                <option value="xor">xor</option>

                            </Select>
                        </Grid>
                        {<Grid item xs={12}>
                            {sql_check ? getRenderExpression() : getRenderConditions()}
                        </Grid>}

                        {(selectedColumn || sql_check) &&
                            <Grid item xs={2}>
                                <Button variant='outlined'
                                    style={theme.buttons.buttonRegular}
                                    onClick={event => { createCondition(); trackEvent('Execution Condition List', 'Create Condition') }} >
                                    Create
                                </Button>
                            </Grid>
                        }

                        <Grid item xs={4}>
                            <Button variant='outlined'
                                style={theme.buttons.buttonRegular}
                                onClick={event => { cleanConditionValues(); trackEvent('Execution Condition List', 'Clean Condition') }} >
                                Clear Values
                            </Button>
                        </Grid>

                        {selectedCondition && <Grid item xs={2}>
                            <Button variant='outlined'
                                style={theme.buttons.buttonCancel}
                                onClick={event => { deleteSelectedCondition(); trackEvent('Execution Condition List', 'Delete Selected Condition') }} >
                                Delete
                            </Button>
                        </Grid>}

                    </Grid>




                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                        Generated condition
                    </Typography>

                    <InputLabel htmlFor="typeWarnTh-selection-helper" style={theme.inputLabel}>Conditions</InputLabel>
                    <Select
                        native
                        style={{
                            width: '50%',
                            fontSize: 14,

                        }}
                        value={selectedCondition}
                        onChange={event => { setSelectedCondition(event.target.value); updateValuesSelectedCondition(event.target.value); }}
                    >
                        <option value="">None</option>
                        {conditions.map((condition, i) => (
                            <option key={'condition' + i} value={JSON.stringify(condition)}>
                                {getConditionString(condition)}
                            </option>)
                        )}
                    </Select>
                    <Divider style={{ marginTop: '2%', marginBottom: '1%', width: '50%' }} />
                    <Typography variant='subtitle2'>
                        String Generated Condition
                    </Typography>
                    <Typography variant='caption'>
                        {getCompleteConditionString()}
                    </Typography>
                </Grid>
            </Grid>

        </Paper>
    )
}