import React from 'react';
import ResourceElement from './ResourceElement';
import images from './images'


import { Grid, Typography, Button } from '@material-ui/core';





export default function SelectResource(props) {
    


    function handleClickStorage() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("azure_storage")
            props.setConnectionPhase(props.connectionPhase + 1)
        }
    }
    function handleClickContainer() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("azure_container")
            props.setConnectionPhase(props.connectionPhase + 1)
        }
    }
    function handleClickOracle() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("oracle")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }
    function handleClickSqlServer() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("sqlServer")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }
    function handleClickSynapse() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("azure-sqlsynapse")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }
    function handleClickMySql() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("mySQL")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }

    function handleClickBigQuery() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("bigQuery")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }

    function handleClickAWS() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("aws")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }

    function handleClickHive() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("awsHive")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }

    function handleClickRedshift() {
        if (props.setResourceType && props.setConnectionPhase && props.connectionPhase) {
            props.setResourceType("awsRedshift")
            props.setConnectionPhase(props.connectionPhase + 2)
        }
    }


    return (

        <div style={{ width: '400px' }}>
            <Grid container >
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Select a resource type to connect to</Typography>
                </Grid>
                {/* <Grid item xs={12}>
                <Typography>What kind of Azure resource do you want to connect to?</Typography>
            </Grid> */}
                {props.configuration && props.configuration.enableAzureStorage === true && <Grid item xs={12}>
                    <Button onClick={event => { handleClickStorage() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                        <ResourceElement title={"Storage account"} description={"Attach to a Storage account."} image={images.icons.storage} sizeInPx={30} />
                    </Button>
                </Grid>}
                {props.configuration && props.configuration.enableSynapse === true && <Grid item xs={12}>
                    <Button onClick={event => { handleClickSynapse() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                        <ResourceElement title={"Azure Synapse"} description={"Attach to a Synapse Database."} image={images.icons.synapse} sizeInPx={50} />
                    </Button>
                </Grid>}
                {props.configuration && props.configuration.enableAzureStorage === true && <Grid item xs={12}>
                    <Button onClick={event => { handleClickContainer() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                        <ResourceElement title={"Container"} description={"Attach to a container."} image={images.icons.container} sizeInPx={30} />
                    </Button>
                </Grid>}
                {props.configuration && props.configuration.enableOracle === true &&
                    <Grid item xs={12}>
                        <Button onClick={event => { handleClickOracle() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                            <ResourceElement title={"Oracle Database"} description={"Attach to an Oracle Database."} image={images.icons.oracle} sizeInPx={40} />
                        </Button>
                    </Grid>
                }
                {props.configuration && props.configuration.enableSQLServer === true && <Grid item xs={12}>
                    <Button onClick={event => { handleClickSqlServer() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                        <ResourceElement title={"SQL server Database"} description={"Attach to a SQL server Database."} image={images.icons.sqlServer} sizeInPx={50} />
                    </Button>
                </Grid>}
                {props.configuration && props.configuration.enableMySql === true &&
                    <Grid item xs={12}>
                        <Button onClick={event => { handleClickMySql() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                            <ResourceElement title={"MySQL Database"} description={"Attach to an MySQL Database."} image={images.icons.mySQL} sizeInPx={50} />
                        </Button>
                    </Grid>
                }{props.configuration && props.configuration.enableBigQuery === true &&
                    <Grid item xs={12}>
                        <Button onClick={event => { handleClickBigQuery() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                            <ResourceElement title={"Big Query Database"} description={"Attach to an Big Query Database."} image={images.icons.bigQuery} sizeInPx={50} />
                        </Button>
                    </Grid>
                }
                {props.configuration && props.configuration.enableAws === true &&
                    <Grid item xs={12}>
                        <Button onClick={event => { handleClickAWS() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                            <ResourceElement title={"AWS"} description={"Attach to Amazon Web Services"} image={images.icons.aws} sizeInPx={50} />
                        </Button>
                    </Grid>
                }
                {props.configuration && props.configuration.enableHive === true &&
                    <Grid item xs={12}>
                        <Button onClick={event => { handleClickHive() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                            <ResourceElement title={"Hive"} description={"Attach to Hive"} image={images.icons.hive} sizeInPx={50} />
                        </Button>
                    </Grid>
                }
                {props.configuration && props.configuration.enableRedshift === true &&
                    <Grid item xs={12}>
                        <Button onClick={event => { handleClickRedshift() }} style={{ backgroundColor: "#e1e8f2", margin: "10px", width: "100%" }}>
                            <ResourceElement title={"awsRedshift"} description={"Attach to Redshift"} image={images.icons.redshift} sizeInPx={50} />
                        </Button>
                    </Grid>
            }
            </Grid>



        </div>
    )
}