import React, { useState, useEffect } from 'react'
import MaterialTable from "@material-table/core";
import { Typography } from '@material-ui/core';
import * as actions from 'actions'
import { useDispatch } from "react-redux";


import * as commonStyles from 'style/CommonStyles'


export default function ScheduleTable(props) {
    const dispatch = useDispatch()
    const [data, setData] = useState([])

    useEffect(() => {
        if (props.selectedSchedule) {
            fetchExecutions(props.selectedSchedule)
        }
    }, [props.selectedSchedule])// eslint-disable-line react-hooks/exhaustive-deps

    async function fetchExecutions(selectedSchedule) {
        let result = await dispatch(actions.fetchExecutionsForScheduleId(selectedSchedule._id))
        setData(result.data)
        props.setLoading(false)
    }

    return (
        <>


            {data && data.length > 0 && < MaterialTable
                style={{ width: "100%" }}
                title="Schedules"
                data={data}
                columns={
                    [
                        {
                            title: 'Project', field: 'projectId', render: () => {
                                if (props.selectedSchedule && props.selectedSchedule.projectName) {
                                    return <Typography variant='caption'>{props.selectedSchedule.projectName}</Typography>
                                }
                                else {
                                    return <Typography variant={'caption'} value={""} projectId={data.projectId}></Typography>
                                }
                            }
                        },
                        {
                            title: 'Quality Point Name', field: "qpid", render: () => {
                                if (props.selectedSchedule && props.selectedSchedule.projectName) {
                                    return <Typography variant='caption'>{props.selectedSchedule.qualityPointName}</Typography>
                                }
                                else {
                                    return <Typography variant={'caption'} value={""} projectId={data.projectId}></Typography>
                                }
                            }
                        },
                        {
                            title: 'Datasource  Name', field: 'environment', render: () => {
                                if (props.selectedSchedule && props.selectedSchedule.qualityPointName) {
                                    return <Typography variant='caption'>{props.selectedSchedule.datasourceName}</Typography>
                                }
                                else {
                                    return <Typography variant={'caption'} value={""} projectId={data.projectId}></Typography>
                                }
                            }
                        },
                        { title: 'Environment', field: 'environment' },
                        { title: 'Stage', field: 'stage' },
                        { title: 'Execution Date', field: 'creationDate' },
                        { title: 'Error Message', field: 'errorMessage' },
                    ]}
                options={{
                    actionsColumnIndex: -1,
                    filtering: false,
                    search: false,
                    pageSize: 5,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                        backgroundColor: commonStyles.mainColor,
                        color: '#FFF'
                    },
                    cellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                    filterCellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                }}

                components={{
                    Toolbar: props => (
                        <div>

                        </div>
                    ),
                }}
            />}
        </>

    )
}
