import { configureStore } from '@reduxjs/toolkit';
import promiseMiddleware from 'redux-promise-middleware';
import combineReducers from './index';
import { useDispatch } from 'react-redux';

const store = configureStore({
    reducer: combineReducers,
    middleware: [promiseMiddleware],
});

export type RootState = ReturnType<typeof combineReducers>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;