import * as dataForRecurrenceHelper from './../../SchedulingConfigurationCard/dataForRecurrence'

export default function translateCronToRecurrence(cronExpression) {

    let parts = cronExpression.split(" ");

    if (parts.length === 6) { // enriched cron with seconds
        let recurrence = cronExpression.split("/")
        let cronRecurrence
        let cronRecurrenceType
        if (recurrence.length > 1) {
            cronRecurrence = recurrence[1].split(" ")[0]
        }
        let cronMinuteOfTheHour = (parts[1])

        let cronHourOfTheDay = (parts[2])


        let cronDayOfTheWeek = (parts[5])
        let cronDayOfTheMonth = (parts[3])
        let description = ""


        if (cronDayOfTheWeek !== "*" && cronDayOfTheWeek !== "?") {
            cronRecurrenceType = "week"
            let dayOfTheWeek = dataForRecurrenceHelper.daysOfTheWeek.find(element => element.value === parseInt(cronDayOfTheWeek)).label
            let hourOfTheDay = dataForRecurrenceHelper.hoursOfTheDay.find(element => element.value === parseInt(cronHourOfTheDay)).label
            let minuteOfTheHour = dataForRecurrenceHelper.minutesOfTheHour.find(element => element.value === parseInt(cronMinuteOfTheHour)).label
            description = "Every " + dayOfTheWeek + `, at ${hourOfTheDay}H${minuteOfTheHour}`

        }
        if (cronDayOfTheMonth !== "*" && cronDayOfTheMonth !== "?") {
            cronRecurrenceType = "month"
            let dayOfTheMonth = dataForRecurrenceHelper.daysOfTheMonth.find(element => element.value === cronDayOfTheMonth).label
            let hourOfTheDay = dataForRecurrenceHelper.hoursOfTheDay.find(element => element.value === cronHourOfTheDay).label
            let minuteOfTheHour = dataForRecurrenceHelper.minutesOfTheHour.find(element => element.value === cronMinuteOfTheHour).label
            description = "Every " + cronRecurrenceType + ` on the ${dayOfTheMonth}, at ${hourOfTheDay}H${minuteOfTheHour}`

        }
        if (cronDayOfTheMonth === "?" && (cronDayOfTheWeek === "*" || cronDayOfTheWeek === "?") && (cronDayOfTheMonth === "*" || cronDayOfTheMonth === "?")) {
            let hourOfTheDay = dataForRecurrenceHelper.hoursOfTheDay.find(element => element.value === cronHourOfTheDay) ?
                dataForRecurrenceHelper.hoursOfTheDay.find(element => element.value === cronHourOfTheDay).label : ""
            let minuteOfTheHour = dataForRecurrenceHelper.minutesOfTheHour.find(element => element.value === cronMinuteOfTheHour) ?
                dataForRecurrenceHelper.minutesOfTheHour.find(element => element.value === cronMinuteOfTheHour).label : ""
            cronRecurrenceType = "day"
            description = "Every " + cronRecurrenceType + `, at ${hourOfTheDay}H${minuteOfTheHour}`


        }
        if (cronHourOfTheDay.includes("*") && cronDayOfTheMonth==="?") {
            cronRecurrenceType = "hour"
            let minuteOfTheHour = dataForRecurrenceHelper.minutesOfTheHour.find(element => element.value === cronMinuteOfTheHour) ?
                dataForRecurrenceHelper.minutesOfTheHour.find(element => element.value === cronMinuteOfTheHour).label : ""
            description = "Every " + cronRecurrenceType + `, at the minute ${minuteOfTheHour}`

        }
        if (cronMinuteOfTheHour.includes("*") && cronDayOfTheMonth === "?") {
            cronRecurrenceType = "minute"
            description = `Every ${cronRecurrence} ` + cronRecurrenceType

        }
        return description
    }
}