import React from 'react';

import QPIcon from 'resources/icons/point_proc1_black.png'
import DSIcon from 'resources/icons/data_proc3_black.png'
import RuleIcon from 'resources/icons/rule_proc1_black.png'
import ProjectIcon from 'resources/icons/cloud_proc2_black.png'

import Graph from "react-graph-vis";

import * as commonStyles from 'style/CommonStyles'


//import DQIChartAmChart from './../DQIChartAmChart';
//import DiagramTitle from './../DiagramTitle';
//import AqtivaLoading from 'components/common/AqtivaLoading';
//import DQIDimensionsChartDetail from './../DQIDimensionsChartDetail'
//import LineChartZoomAndScrollProjectStructure from './../LineChartZoomAndScrollProjectStructure'
//import RuleDQIBar from './../RuleDQIBar'




export default function AqtivaDashboard(props) {

    const [detailedDQIProject, setDetailedDQIProject] = React.useState()
    const [hideNodes, setHideNodes] = React.useState()
    const [graph, setGraph] = React.useState()
   /* const [tabValue, setTabValue] = React.useState(0)
    const [project, setProject] = React.useState()
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };*/




   /* React.useEffect(() => {
        if (detailedDQIProject && detailedDQIProject.projectId) {
            dispatch(actions.fetchProjectAndReturn(detailedDQIProject.projectId)).then(response => {
                setProject(response.result)
            })
        }


    }, [detailedDQIProject])// eslint-disable-line react-hooks/exhaustive-deps*/

    const options = {
        layout: {
            hierarchical: {
                direction: "LR",
                levelSeparation: 300
            },
        },
        autoResize: true,
        edges: {
            smooth: {
                type: "vertical",
                roundness: 0.8,
            },
        },
        nodes: {
            shape: "image",
            scaling: {
                label: {
                    min: 6,
                    max: 12,
                },
            },
        },
        physics: {
            enabled: false,
        },
    };

    React.useEffect(() => {
        setDetailedDQIProject(props.detailedDQIProject)
        setHideNodes(props.hideNodes)
    }, [props.detailedDQIProject, props.hideNodes])

    React.useEffect(() => {

        if (detailedDQIProject !== undefined) {
            const DQI = getDQI4Object(detailedDQIProject)
            const mainNode = {
                id: detailedDQIProject.projectId,
                font: { multi: "html", size: "12", color: DQI === 'None' ? '#8f8f8f' : DQI > 90 ? '#000000' : DQI > 65 ? commonStyles.warningColor : commonStyles.errorColor },
                shape: "image",
                image: ProjectIcon,
                margin: 500,
                size: 10,
                label: [
                    "<b>Project:</b> <i>" + detailedDQIProject.name + "</i>",
                    `<b>DQI:</b> <i>${getDQI4Object(detailedDQIProject)}%</i>`,
                    `<b>Data:</b> <i>${100}%</i>`,
                ].join("\n"),
                level: 0,
                fixed: true,
            }

            const qpNodes = detailedDQIProject.qualityPointDQI === undefined ? [] : detailedDQIProject.qualityPointDQI.map(value => {
                const DQI = getDQI4Object(value)
                return (
                    {
                        id: value.qualityPointId,
                        font: { multi: "html", size: "12", color: DQI === 'None' ? '#8f8f8f' : DQI > 90 ? '#000000' : DQI > 65 ? commonStyles.warningColor : commonStyles.errorColor },
                        shape: "image",
                        image: QPIcon,
                        margin: 500,
                        size: 10,
                        label: value.sumNumIn === 0 ?
                            [
                                "<b>QP:</b> <i>" + value.name + "</i>",
                                "<b>No Data</b> "

                            ].join("\n")
                            : [
                                "<b>QP:</b> <i>" + value.name + "</i>",
                                `<b>DQI:</b> <i>${DQI}%</i>`,
                                `<b>Data:</b> <i>${Math.round(value.sumNumIn / detailedDQIProject.sumNumIn * 10000) / 100}%</i>`,

                            ].join("\n"),
                        level: 1,
                        fixed: true,
                    }
                )
            })

            const dsNodes = detailedDQIProject.qualityPointDQI === undefined ? [] : (detailedDQIProject.qualityPointDQI.flatMap(value => value.datasourceDQI)).filter(value => !hideNodes.includes(value.parentId)).map(value => {
                const DQI = getDQI4Object(value)
                return (
                    {
                        id: value.datasourceId,
                        font: { multi: "html", size: "12", color: DQI === 'None' ? '#8f8f8f' : DQI > 90 ? '#000000' : DQI > 65 ? commonStyles.warningColor : commonStyles.errorColor },
                        shape: "image",
                        image: DSIcon,
                        margin: 500,
                        size: 10,
                        label: value.sumNumIn === 0 ?
                            [
                                "<b>QP:</b> <i>" + value.name + "</i>",
                                "<b>No Data</b> "

                            ].join("\n")
                            : [
                                "<b>DS:</b> <i>" + value.name + "</i>",
                                `<b>DQI:</b> <i>${getDQI4Object(value)}%</i>`,
                                `<b>Data:</b> <i>${Math.round(value.sumNumIn / detailedDQIProject.sumNumIn * 10000) / 100}%</i>`,
                            ].join("\n"),
                        level: 2,
                        fixed: true,
                    }
                )
            })

            const rulesNodes = detailedDQIProject.qualityPointDQI === undefined ? [] : (detailedDQIProject.qualityPointDQI.flatMap(value => value.datasourceDQI)
                .filter(value => !hideNodes.includes(value.parentId)).flatMap(value => value.ruleDQI))
                .filter(value => !hideNodes.includes(value.parentId))
                .map(value => {
                    const DQI = getDQI4Object(value)
                    return (
                        {
                            id: value.ruleId,
                            font: { multi: "html", size: "12", color: DQI === 'None' ? '#8f8f8f' : DQI > 90 ? '#000000' : DQI > 65 ? commonStyles.warningColor : commonStyles.errorColor },
                            shape: "image",
                            image: RuleIcon,
                            margin: 500,
                            size: 10,
                            label: value.sumNumIn === 0 ?
                                [
                                    "<b>QP:</b> <i>" + value.name + "</i>",
                                    "<b>No Data</b> "

                                ].join("\n")
                                : [
                                    "<b>Rule:</b> <i>" + value.name + "</i>",
                                    `<b>DQI:</b> <i>${getDQI4Object(value)}%</i>`,
                                    `<b>Data:</b> <i>${Math.round(value.sumNumIn / detailedDQIProject.sumNumIn * 10000) / 100}%</i>`,
                                ].join("\n"),
                            level: 3,
                            fixed: true,
                        }
                    )
                })



            const edgesQP = detailedDQIProject.qualityPointDQI === undefined ? [] : detailedDQIProject.qualityPointDQI.map(value => {
                return (

                    { from: detailedDQIProject.projectId, to: value.qualityPointId }

                )
            })

            const edgesDS = detailedDQIProject.qualityPointDQI === undefined ? [] : (detailedDQIProject.qualityPointDQI.flatMap(value => value.datasourceDQI).filter(value => !hideNodes.includes(value.parentId))).map(value => {
                return (

                    { from: value.parentId, to: value.datasourceId }

                )
            })

            const rulesDS = detailedDQIProject.qualityPointDQI === undefined ? [] : (detailedDQIProject.qualityPointDQI
                .flatMap(value => value.datasourceDQI).filter(value => !hideNodes.includes(value.parentId))
                .flatMap(value => value.ruleDQI))
                .filter(value => !hideNodes.includes(value.parentId))
                .map(value => {
                    return (

                        { from: value.parentId, to: value.ruleId }

                    )
                })

            const graphTmp = {
                edges: [...edgesQP, ...edgesDS, ...rulesDS],
                nodes: [mainNode, ...qpNodes, ...dsNodes, ...rulesNodes]
            }
            setGraph(graphTmp)
        }

    },
        [detailedDQIProject, hideNodes])

    function getDQI4Object(obj) {

        if (obj.sumNumIn === 0) {
            return 'None'
        }
        else {
            return Math.round(obj.sumNumOk / obj.sumNumIn * 10000) / 100
        }
    }


    return (
        <div style={{ width: '100%', height: props.fullscreen === true ? '100%' : '200px' }}>

            { graph && graph.nodes && graph.nodes.length > 0 &&
                <Graph
                    graph={graph}
                    options={options}
                    style={{ height: "100%", width: "100%" }}
                    events={{
                        selectNode: (event) => {
                            var { nodes, edges } = event;
                            if (edges) {
                                //TODO: to avoid compiler warnings
                            }

                            const node = nodes[0]
                            //check if node is in list
                            if (hideNodes.includes(node)) {
                                //remove node

                                const tmpHideNodes = hideNodes.filter(e => e !== node);

                                setHideNodes(tmpHideNodes)
                            }
                            else {
                                //add node
                                setHideNodes([...hideNodes, nodes[0]])
                            }

                        },

                    }}
                />
            }
        </div>
    )
}