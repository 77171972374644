import { FormControl, InputLabel, Select, Tooltip, Typography, MenuItem } from "@material-ui/core";
import React from "react";


export default function ExecutionSelectorDropdown(props) {

    const [executionList, setExecutionList] = React.useState([])
    const [selectedExecution, setSelectedExecution] = React.useState([])

    React.useEffect(() => {
        if (props.executionList) {
            let reversed = props.executionList.reverse()
            setExecutionList(reversed)
        }
    }, [props.executionList])

    function showBasicInformation(e) {
        setSelectedExecution(e)
        props.showBasicInformation(e)
    }


    return (
        <FormControl style={{ width: '90%', margin: '4%', height: '277px' }}>
            <InputLabel id="selectedRecurrence"> <Typography variant='caption'>Select Execution</Typography> </InputLabel>
            <Select
                value={selectedExecution}
                onChange={event => showBasicInformation(event.target.value)}
                style={{ width: '100%', }}

            >

                {executionList.map((element, index) => {
                    let date = element.createTime.split('T')
                    let title = date[0] + ' - ' + date[1].substring(0, 8)
                    let tooltipText =
                        <React.Fragment>
                            <h3>{title}</h3>
                            <ul>
                                <li>DQI: {Number(((element.numOk / (element.numIn === 0 ? 1 : element.numIn)) * 100).toFixed(0))} %</li>
                                <li>NumOk: {element.numOk}</li>
                                <li>NumKo: {element.numKo}</li>
                                <li>NumIn: {element.numIn}</li>
                            </ul>
                        </React.Fragment>

                    return (

                        <MenuItem key={index} value={element}>
                            <div>
                                <Tooltip title={tooltipText}>
                                    <Typography variant='subtitle2'>{title}</Typography>
                                </Tooltip>
                            </div>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}


