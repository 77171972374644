import React from 'react';

import {
    Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { useTheme } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';



/*
 PROPS
    visible: set visible or invisibile the dialog
    fullWidth
    maxWidth
    editable: true or false to activate editable options
    title: string with title
    titleIcon: Icon element to include in title, expected @fortawesome icon
    confirmText: Text in confirmButton. If not set, use Confirm
    cancelText: Text in cancelButton. If not set, use Cancel
    editText: Text in editButton. If not set, use Cancel
    confirmCallback: callback for confirm onClick
    cancelCallback: callback for cancel onClick
    editCallback: callback for edit onClick
*/


export default function AqtivaDialogFlexiblePosition(props) {

    const useStyles = makeStyles((theme) => ({
        dialogCustomizedWidth: {
            'max-width': '95%'
        },
        dialog: {
            display: "flex",
            justifyContent: props.position
        }
    }));

    const theme = useTheme();

    const classes = useStyles();



    return (

        <Dialog open={props.visible}
            fullWidth={props.fullWidth ? props.fullWidth : false}
            fullScreen={props.fullScreen ? props.fullScreen : false}
            maxWidth={props.maxWidth ? props.maxWidth : 'md'}
            classes={props.position &&
                { scrollPaper: classes.dialog }}
        >

            <DialogTitle style={theme.dialogs.dialogTitle}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item >
                        <FontAwesomeIcon icon={props.titleIcon ? props.titleIcon : faCheck} style={theme.dialogs.dialogTitleAwesomeIcon} />

                    </Grid>
                    <Grid item >

                        <Typography style={theme.dialogs.dialogTitleText}>
                            {props.title ? props.title : 'Confirm Dialog'}
                        </Typography>
                    </Grid>
                    {/*<Grid item style={{width:'5%'}}>
                        <img alt="" style={{ height: '20px', width: '20px', marginTop:'-10px' }}
                             src={require('./../../../resources/icons/favicon-32x32.jpg')} />
            </Grid>*/}
                </Grid>
            </DialogTitle>

            <DialogContent>

                {props.children}
            </DialogContent>

            <DialogActions>
                {
                    !props.editable && props.showConfirmButton !== false && <Button id='SaveButton' disabled={props.disabled} variant='outlined' style={theme.buttons.buttonConfirm}
                        onClick={event => {
                            props.confirmCallback && props.confirmCallback(event)
                        }}>
                        {props.confirmText ? props.confirmText : 'Confirm'}</Button>}
                {props.editable && props.showConfirmButton !== false && <Button id='SaveButton' disabled={props.disabled} variant='outlined' style={theme.buttons.buttonConfirm}
                    onClick={event => {
                        props.editCallback && props.editCallback(event)
                    }}>
                    {props.editText ? props.editText : 'Edit'}</Button>}

                <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel} onClick={event => {

                    props.cancelCallback && props.cancelCallback(event)
                }}>{props.cancelText ? props.cancelText : 'Cancel'}
                </Button>
            </DialogActions>



        </Dialog>

    )

}