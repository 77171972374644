import React, { useEffect, useState } from "react";

import { Typography, FormControl, InputLabel, Tooltip, Grid, Select, MenuItem, Fab, FormControlLabel, Checkbox, TextField, Input } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faTrash, faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import DynamicDateComponent from "../DynamicDateComponent";
import AqtivaDialog from "components/common/AqtivaDialog";


export default function DateColumnComparisonComponent(props) {

    const [columnOperation, setColumnOperation] = useState({isDateComparison: true})
    const [destList, setDestList] = useState([])
    const [showDialog, setShowDialog] = useState(false)
    const [specialColumnType, setSpecialColumnType] = useState('')
    const [specialColumn, setSpecialColumn] = useState({})
    const { height, width } = useWindowDimensions();
    const [refresh, doRefresh] = useState(0);
    let comparators = ["==", "<", "<=", ">", ">=", "!="]
    let levels = ["YEAR", "MONTH", "DAY", "HOUR", "MINUTE"]

    useEffect(() => {
        if (props.newRule) {
            let newColumnOperation = {}
            let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
            newColumnOperation.id = newId
            newColumnOperation.operation = "=="
            newColumnOperation.order = 0
            setColumnOperation(newColumnOperation)
        }
    }, [props.ruleInfo])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.columnOperationInfo) {
            setColumnOperation(props.columnOperationInfo)
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.source) {
            let list = [{ name: 'DATEPICKER', type: 'datepicker' }, { name: 'DYNAMIC DATE', type: 'dynamic' }]
            setDestList(list.concat(props.source))
        }
    }, [props.source])

    useEffect(() => {
        if (columnOperation.column_or && columnOperation.column_or.name != undefined && columnOperation.level != undefined && columnOperation.operation != undefined && columnOperation.column_dst && columnOperation.column_dst.type != undefined) {
            props.updateColumnsDate(props.index, columnOperation)
        }
    }, [columnOperation])

    useEffect(() => {
        if (specialColumn) {
            let column = columnOperation
            columnOperation.column_dst = specialColumn
            setColumnOperation(JSON.parse(JSON.stringify(column)))
        }
    }, [specialColumn])

    function handleChangeColumnColumnOperationsOrigin(columnOperations2update, value) {
        columnOperations2update.column_or = value
        setColumnOperation(JSON.parse(JSON.stringify(columnOperations2update)))
    }

    function handleChangeColumnColumnOperationsDestination(columnOperations2update, value) {
        if (value.type && (value.type === 'datepicker' || value.type === 'dynamic')) {
            setSpecialColumnType(value.type)
            setShowDialog(true)
        } else {
            columnOperations2update.column_dst = value
            setColumnOperation(JSON.parse(JSON.stringify(columnOperations2update)))
        }
    }
    function handleChangeOperator(columnOperations2update, value) {
        columnOperations2update.operation = value
        setColumnOperation(JSON.parse(JSON.stringify(columnOperations2update)))
    }
    function handleChangeLevel(columnOperations2update, value) {

        columnOperations2update.level = value
        setColumnOperation(JSON.parse(JSON.stringify(columnOperations2update)))

    }

    function handleDeleteColumnOperations(columnOperation2Delete) {
        /*  let newColumnOperations = columnOperations.filter(function (element) {
              return element.id !== columnOperation2Delete.id;
          });
          setColumnOperations(JSON.parse(JSON.stringify(newColumnOperations)))
  
          let index = columnOperations.findIndex(x => x.id === columnOperation2Delete.id)
          let newChecksDates = treatAsDateCheckbox.splice(index, 1)
          setTreatAsDateCheckbox(newChecksDates)*/
    }

    function handleDynamicColumn() {
        doRefresh(prev => prev + 1)
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        React.useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowDimensions;
    }


    return (

        <>
            <AqtivaDialog visible={showDialog}
                title={`SPECIAL DATE`}
                titleIcon={faCalendarDay}
                cancelText={'Close'}
                showConfirmButton={true}
                confirmCallback={handleDynamicColumn}
                cancelCallback={event => { setShowDialog(false) }}
            >
                <div style={{ width: width * 0.3, height: height * 0.1 }}>
                    <DynamicDateComponent type={specialColumnType} refresh={refresh} setSpecialColumn={setSpecialColumn} close={setShowDialog} />
                </div>

            </AqtivaDialog>


            <Grid item xs={2} style={{ display: 'inline-flex' }}>
                <Typography style={{ marginTop: "20px" }} variant="subtitle2">Date comparison</Typography>
            </Grid>

            <Grid item xs={2}>
                <FormControl style={{ width: '100%', }}>
                    <Autocomplete
                        style={{ minWidth: "20%" }}
                        autoWidth={true}
                        labelId="origin-column"
                        id="origin-column"
                        value={columnOperation.column_or}
                        label="origin column"
                        onChange={(event, newValue) => {
                            handleChangeColumnColumnOperationsOrigin(columnOperation, newValue)
                        }}

                        options={(props.source) ? props.source : []}

                        getOptionLabel={(e) => `(${e.type}) ${e.name}`}
                        renderInput={(e) => <TextField {...e} label="Select first column to compare" />}
                    />

                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl style={{ width: '100%', }}>
                    <InputLabel id="origin-column">Select an operation to compate</InputLabel>
                    <Select
                        renderValue={(val) => val}
                        inputProps={{
                            style: { fontSize: 10 }
                        }}
                        style={{ minWidth: "20%" }}
                        autoWidth={true}
                        labelId="columnOperation"
                        id="columnOperation"
                        value={columnOperation.operation}
                        label="Column Operation"
                        onChange={(event) => {
                            handleChangeOperator(columnOperation, event.target.value)
                        }}
                    >
                        {comparators && comparators.map(value => {
                            return (
                                <MenuItem key={value} value={value}>
                                    <Typography variant='body2'>{value}</Typography>
                                </MenuItem>
                            )
                        })}
                    </Select>


                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl style={{ width: '100%', }}>
                    <InputLabel id="origin-column">level</InputLabel>

                    <Select
                        inputProps={{
                            style: { fontSize: 10 }
                        }}
                        multiple
                        style={{ minWidth: "20%" }}
                        autoWidth={true}
                        labelId="columnLevelOperation"
                        id="columnLevelOperation"
                        value={columnOperation.level ? columnOperation.level : []}
                        label="Level Operation"
                        onChange={(event) => {
                            handleChangeLevel(columnOperation, event.target.value)
                        }}
                    >
                        {levels && levels.map(value => {
                            return (
                                <MenuItem key={value} value={value} >
                                    {value}
                                </MenuItem>
                            )
                        })}
                    </Select>


                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl style={{ width: '100%', }}>

                    <Autocomplete
                        style={{ minWidth: "40%" }}
                        autoWidth={true}
                        labelId="origin-column"
                        id="origin-column"
                        value={columnOperation.column_dst}
                        label="origin column"
                        onChange={(event, newValue) => {
                            handleChangeColumnColumnOperationsDestination(columnOperation, newValue)
                        }}

                        options={destList}
                        getOptionLabel={(e) => `(${e.type}) ${e.name}`}
                        renderInput={(e) => <TextField {...e} label="Select second column to compare" />}
                    />

                </FormControl>
            </Grid>
            <Grid item xs={2}>
                {props.index !== 0 && <Tooltip title={"Delete columnOperations"} style={{ marginTop: "10px" }}>
                    <Fab size="small" color="primary" aria-label="Detail"
                        onClick={() => { handleDeleteColumnOperations(columnOperation) }}>
                        <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                    </Fab>
                </Tooltip>}
            </Grid>
            {/*props.errors && <Grid item xs={12}>
                        <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].column_dst}</Typography>
                        <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].column_or}</Typography>
                        <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].operation}</Typography>
                            </Grid>*/}

        </>

    )
}