import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import ProfilingTable from 'components/common/ProfilingComponent/ProfilingTable';
import DataformatSchemaTable from './../DataformatSchemaTable'
import DataformatUsedInProjectTrace from './../DataformatUsedInProjectTrace'
import DataformatBasicInformation from './DataformatBasicInformation'
import * as actions from 'actions'
import { useDispatch } from 'react-redux'

export default function DataformatResumeCard(props) {

    const dispatch = useDispatch()
    const [tabValue, setTabValue] = React.useState(0)
    const [profilingData, setProfilingData] = React.useState([])
    const [dataformat, setDataformat] = React.useState({})


    React.useEffect(() => {
        if (props.dataformat) {
            getDataformatAndSetProfilings()
        }
        if (props.updateDataformat) {
            getDataformatAndSetProfilings()
            props.setUpdateDataformat(false)
        }

    }, [props.dataformat && props.dataformat.profiling && props.dataformat.profiling.profilingColumnItems, props.updateDataformat])// eslint-disable-line react-hooks/exhaustive-deps


    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    function getDataformatAndSetProfilings() {
        dispatch(actions.getDataformatDetailById(props.dataformat._id)).then(response => {
            if (response.data.sqlQuery != undefined) props.setSqlQuery(response.data.sqlQuery)
            setDataformat(response.data)
            if (response.data && response.data.profiling && response.data.profiling.profilingColumns) {
                let profiling = JSON.parse(JSON.stringify(response.data.profiling.profilingColumns))
                for (let i = 0; i < profiling.length; i++) {
                    for (let e = 0; e < profiling[i].profilingColumnItems.length; e++) {
                        profiling[i][profiling[i].profilingColumnItems[e].profilingItemName] = profiling[i].profilingColumnItems[e].profilingItemValue
                    }
                    delete profiling[i]["profilingColumnItems"]

                }
                setProfilingData(profiling)
            }
        })
    }

    return (
            <div>
                <Tabs value={tabValue} onChange={handleChangeTab}
                    variant="scrollable" scrollButtons="off"
                    style={{ width: '100%', marginTop: '-20px' }}>

                    <Tab label="Basic Information" />
                    <Tab label="Schema Description" />
                    <Tab label="Data Profiling" disabled={dataformat.profiling === undefined || dataformat.profiling === null} />
                    <Tab label="Used by Projects Trace" />
                </Tabs>

                {tabValue === 0 &&
                    <DataformatBasicInformation dataformat={dataformat} />
                }



                {tabValue === 1 &&
                    <DataformatSchemaTable data={dataformat.columns} dataformat={dataformat} />
                }

                {tabValue === 2 && dataformat && dataformat.profiling !== undefined && dataformat.profiling !== null &&
                    <ProfilingTable data={profilingData} />
                }

                {tabValue === 3 &&
                    <DataformatUsedInProjectTrace dataformat={props.dataformat} />
                }
            </div>
        )

    }