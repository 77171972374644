import React, { useEffect } from 'react';

import { makeStyles, Select } from '@material-ui/core';

import { styles, QualityTheme } from '../../../../../../../style/CustomTheme'
import { Typography, TextField, FormControlLabel, MuiThemeProvider, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import StorageCard from '../StorageCard'
import SqlCard from '../SqlCard'
import { useTheme } from '@material-ui/core/styles';
import AWSCard from '../AWSCard';
import BigQueryCard from '../BigQueryCard';


export default function ParentCard(props) {


    const classes = makeStyles(styles(QualityTheme));
    const theme = useTheme()

    const [storageProperties, setStorageProperties] = React.useState('')
    const [storageName, setStorageName] = React.useState('')
    const [storageType, setStorageType] = React.useState('')
    const [id, setId] = React.useState('')

    /*const [cloudProvider, setCloudProvider] = React.useState(props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
        props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
        (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure'))*/
    const cloudProvider = props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
        props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
        (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure')

    React.useEffect(() => {
        setStorageName(props.storageInfo && props.storageInfo.storageName && props.storageInfo.storageName !== null ?
            props.storageInfo.storageName : '')
        setStorageType(props.storageInfo && props.storageInfo.storageType && props.storageInfo.storageType !== null ?
            props.storageInfo.storageType : '')
        setId(props.storageInfo && props.storageInfo.id && props.storageInfo.id !== null ?
            props.storageInfo.id : '')
    }, [props.storageInfo, props.parentConfiguration]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let storageElement = {}
        storageElement.storageName = storageName
        storageElement.storageType = storageType
        storageElement.id = id
        if (storageType === "azure-storage") {
            storageElement.storageProperties = storageProperties
        }
        if (storageType === "azure-sqldatabase") {
            storageElement.sqlProperties = storageProperties
        }
        if (storageType === "AWS") {
            storageElement.awsProperties = storageProperties
        }
        if (storageType === "bigQuery") {
            storageElement.bigQueryProperties = storageProperties
        }
        props.setStorage(storageElement)
    }, [storageProperties, storageType, storageName])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.newStorage) {
            let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
            setId(newId)
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const storageTypes = ["azure-storage", 'AWS', 'bigQuery']
    // here we remove the option azure-sqldatabase as it's not functional yet
    //const storageTypes = ["azure-storage", "azure-sqldatabase"]

    return (
        <MuiThemeProvider theme={QualityTheme}>
            <div >
                {(props.master === true || (props.master !== null && cloudProvider === 'Azure')) &&
                    <div style={{ marginTop: '5%', width: '100%', minWidth: '800px', display: "flex", flexDirection: "column" }}>
                        <Typography variant="subtitle2"> Output Element Configuration</Typography>
                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="StorageName"
                                    label="Storage Display Name"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={storageName}
                                    onChange={(event) => { setStorageName(event.target.value) }}
                                />} />
                        {props.errors && props.errors.storageName && <div style={{ margin: "10px", color: "red" }}>{props.errors.storageName}</div>}
                        <FormControl style={{ width: '150px', marginLeft: '5%' }}>
                            <InputLabel id="selectStorageType">
                                <Typography variant="caption">Select a Storage Type</Typography>
                            </InputLabel>
                            < Select
                                value={storageType}
                                onChange={event => {
                                    setStorageType(event.target.value);
                                    /*  props.setNoOutputPathError(undefined) */
                                }}
                                inputProps={{
                                    name: 'Environment',
                                    id: 'Environment',
                                }}
                                style={{ width: '150px' }}>
                                {storageTypes.map(element => {
                                    return (
                                        <MenuItem key={element} value={element}>
                                            <div style={theme.elementAdjacent}>
                                                {element === "azure-storage" && <img alt="" style={{ height: '25px', width: '25px', marginRight: "10px" }}
                                                    src={require('./../../../../../../../resources/icons/azure/Storage-Accounts.png')} />}
                                                {element === "azure-sqldatabase" && <img alt="" style={{ height: '25px', width: '25px', marginRight: "10px" }}
                                                    src={require('./../../../../../../../resources/icons/azure/sql-database-generic.png')} />}
                                                {element === "AWS" && <img alt="" style={{ height: '25px', width: '25px', marginRight: "10px" }}
                                                    src={require('./../../../../../../../resources/icons/AmazonIcon.png')} />}
                                                {element === "bigQuery" && <img alt="" style={{ height: '25px', width: '25px', marginRight: "10px" }}
                                                    src={require('./../../../../../../../resources/icons/bigQuery.png')} />}
                                                <Typography variant='subtitle2'>{element}</Typography>
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {props.errors && props.errors.storageType && <div style={{ margin: "10px", color: "red" }}>{props.errors.storageType}</div>}
                    </div>
                }
            </div>
            {storageType && storageType === "azure-storage" && <StorageCard master={true} configuration={props.configuration} setStorageProperties={setStorageProperties} storageInfo={props.storageInfo && props.storageInfo.storageProperties} errors={props.errors} id={id} />}
            {storageType && storageType === "bigQuery" && <BigQueryCard master={true} configuration={props.configuration} setStorageProperties={setStorageProperties} storageInfo={props.storageInfo && props.storageInfo.bigQueryProperties} errors={props.errors} id={id} />}
            {storageType && storageType === "AWS" && <AWSCard master={true} configuration={props.configuration} setStorageProperties={setStorageProperties} storageInfo={props.storageInfo && props.storageInfo.awsProperties} errors={props.errors} id={id} />}
            {storageType && storageType === "azure-sqldatabase" && <SqlCard master={true} configuration={props.configuration} setStorageProperties={setStorageProperties} storageInfo={props.storageInfo && props.storageInfo.sqlProperties} errors={props.errors} id={id} />}
        </MuiThemeProvider >
    )
}