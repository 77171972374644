
import quality from '../apis/quality';
//import qualityLocalHost from '../apis/qualityLocalHost';
//import qualityLocalHost2 from './../apis/qualityLocalHost2'
//import qualityLocalHost3 from './../apis/qualityLocalHost3'
import {
    EDIT_PROJECT, COPY_DATASOURCE
    } from './types'
    import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';




export const deleteDatasource = (idProject,qualityPointId,datasourceId)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.delete(`/datasource/${idProject}/${qualityPointId}/${datasourceId}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource with id ${datasourceId} deleted`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}
      });
      return returnValue
}

///datasource/copy/id/{projectId}/idQualityPoint/{idQualityPoint}/userId/{userId}
export const copyDatasource = (projectId, idQualityPoint, userId, datasource) => async dispatch => {
    var returnValue = { result: 'undefined' }
    await quality.post(`/project/datasource/copy/id/${projectId}/idQualityPoint/${idQualityPoint}`, datasource, {
      headers: axiosHeader()
    }).then(function (response) {
  
      dispatch({
        type: COPY_DATASOURCE,
        payload: response.data
      });
      dispatchInfo(dispatch, `datasource copied `)
  
  
      returnValue = { result: 'ok' }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
  
        returnValue = { result: 'ko', error: error }
      });
    return returnValue
  };

export const addDatasourceV1 = (idProject,qualityPointId,datasource)=> async dispatch => {
    var returnValue = {result:'undefined'}    
    await quality.post(`/datasource/${idProject}/${qualityPointId}`, datasource,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource ${datasource.name} created`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}

      });
      return returnValue
}

export const addDatasourceV2 = (idProject,qualityPointId,datasource)=> async dispatch => {
    var returnValue = {result:'undefined'}    
    await quality.post(`/version/2/datasource/${idProject}/${qualityPointId}`, datasource,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource ${datasource.name} created`)
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}

      });
      return returnValue
}


export const editDatasource = (idProject,qualityPointId,ds,userId)=> async dispatch => {
    var returnValue = {result:'undefined'}
    var datasource = ds;
   await quality.put(`/datasource/${idProject}/${qualityPointId}`, datasource,{
    headers: axiosHeader()
  }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource ${datasource.name} modified`)
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}
      });
      return returnValue
}
