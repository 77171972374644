import React from 'react';
import {
    TextField, Typography, Grid, FormControlLabel
} from '@material-ui/core';

import AqtivaDialogEncryptString from 'components/common/AqtivaDialogEncryptString'




export default function RedshiftConnectionInfo(props) {

    const [visibleEncrypt, setVisiblevisibleEncrypt] = React.useState(false)
    const [visibleEncryptS3, setVisiblevisibleEncryptS3] = React.useState(false)
    const [database, setDatabase] = React.useState('')
    const [user, setUser] = React.useState('')
    const [host, setHost] = React.useState('')
    const [bucketName, setBucketName] = React.useState('')
    const [accessKey, setAccessKey] = React.useState('')
    const [s3region, setS3Region] = React.useState('')
    const [port, setPort] = React.useState('')
    const [encryptedPassword, setEncryptedPassword] = React.useState('')
    const [encryptedPasswordS3, setEncryptedPasswordS3] = React.useState('')
    //const [errors, setErrors] = React.useState(undefined)



    React.useEffect(() => {
        let connectionObject = {}
        connectionObject.database = database
        connectionObject.host = host
        connectionObject.username = user
        connectionObject.port = port
        connectionObject.bucketName = bucketName
        connectionObject.accessKey = accessKey
        connectionObject.secretKey = encryptedPasswordS3
        connectionObject.s3region = s3region
        connectionObject.engine = 'mysql'
        connectionObject.dbInstanceIdentifier = 'awsRedshift'
        connectionObject.password = encryptedPassword
        props.setConnectionObjectRedshift(connectionObject)

    }, [host, port, user, encryptedPassword])// eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>
            <div style={{ width: '100%' }}>
                <AqtivaDialogEncryptString visible={visibleEncrypt}
                    connectionMethod={props.connectionMethod}
                    setVisible={setVisiblevisibleEncrypt}
                    confirm={setEncryptedPassword}
                    sName={props.id && props.id + "localsttkk"}
                />

                <AqtivaDialogEncryptString visible={visibleEncryptS3}
                    connectionMethod={props.connectionMethod}
                    setVisible={setVisiblevisibleEncryptS3}
                    confirm={setEncryptedPasswordS3}
                    sName={props.id && props.id + "localsttkkS3"}
                />

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="DisplayName"
                                label="Display name"
                                margin="dense"
                                value={props.displayName}
                                onChange={(event) => { props.setDisplayName(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Database:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Database name"
                                margin="dense"
                                value={database}
                                onChange={(event) => { setDatabase(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Host:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Host name"
                                margin="dense"
                                value={host}
                                onChange={(event) => { setHost(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Port:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Port name"
                                margin="dense"
                                value={port}
                                onChange={(event) => { setPort(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Bucket Name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Bucket name"
                                margin="dense"
                                value={bucketName}
                                onChange={(event) => { setBucketName(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Bucket Access Key:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Access Key"
                                margin="dense"
                                value={accessKey}
                                onChange={(event) => { setAccessKey(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Bucket Secret Key:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Secret Key"
                                margin="dense"
                                type="password"
                                value={encryptedPasswordS3}
                                onClick={(event) => { setVisiblevisibleEncryptS3(true) }}
                            />} autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Bucket Region:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Region"
                                margin="dense"
                                value={s3region}
                                onChange={(event) => { setS3Region(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Username:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="User"
                                label="User"
                                margin="dense"
                                value={user}
                                onChange={(event) => { setUser(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Password:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        autoComplete="new-password"
                        control={
                            <TextField style={{ width: '100%' }}
                                autoComplete="new-password"
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="Password"
                                label="Password"
                                margin="dense"
                                type="password"
                                value={encryptedPassword}
                                onClick={(event) => { setVisiblevisibleEncrypt(true) }}
                            />} />
                </Grid>
            </div>
        </>
    )
}