import { axiosHeader} from 'components/common/axios/axiosHelper';

import qualityLogin from 'apis/qualityLogin';

const getPublicContent = () => {
  return qualityLogin.get("/auth/" + "all");
};

const getUserBoard = () => {
  return qualityLogin.get("/auth/"  + "user", {  headers: axiosHeader() });
};

const getModeratorBoard = () => {
  return qualityLogin.get("/auth/"  + "mod", {  headers: axiosHeader() });
};

const getAdminBoard = () => {
  return qualityLogin.get("/auth/"  + "admin", {  headers: axiosHeader() });
};

const login = (email, password) => {
  const loginRequestObj = {
    "email":email,
    "password":password
}
  return qualityLogin.post("/public/auth/login", loginRequestObj,{  headers: axiosHeader() });
};

const register = (username, email, password, role) => {
  const loginRequestObj = {
    "name": username,
    "email":email,
    "password":password,
    "role": role
}
  return qualityLogin.post("/user/"  + "new/local", loginRequestObj,{  headers: axiosHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  login,
  register
};