import React from 'react';

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {createProjectCard} from './RecentQualityProjectsHelper'
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'

export default function RecentQualityProjects(props) {

    const theme = useTheme(); 
    const history = useHistory();

    const sortProjects = (maxNumberToShow) => {
        if(props && props.projects && props.projects.length>0){
            const sortedProjects = props.projects.sort((a, b) => {
                const date1 = new Date(parseInt(a.updateDate.substring(0, 4)), parseInt(a.updateDate.substring(5, 7)) - 1, parseInt(a.updateDate.substring(8, 10)))
                const date2 = new Date(parseInt(b.updateDate.substring(0, 4)), parseInt(b.updateDate.substring(5, 7)) - 1, parseInt(b.updateDate.substring(8, 10)))
                return date2 - date1
            })
            if(maxNumberToShow !== undefined && sortedProjects !== undefined) {
                let projectsToShow = []
                for(let i = 0; i < ((maxNumberToShow < sortedProjects.length) ? maxNumberToShow :sortedProjects.length ); ++i) {
                    projectsToShow.push(sortedProjects[i])
                }
                return projectsToShow
            }
            return sortedProjects
        }
        return undefined
    }

    const showProjectCards = () => {
        let itemsToShow = []
        let sortedProjects = []
        sortedProjects = sortProjects(5)
        if(sortedProjects){
            sortedProjects.forEach((project, index) => {
                const newId = "card" + index.toString()
                itemsToShow.push(createProjectCard(project, newId, history, theme))
            })
            return itemsToShow
        }
        return undefined 
    }

   

    const title = 'Recent Projects'
    
    return (
        <div style={props.style}>
            <Typography variant='subtitle2' style={{'display': 'flex', 'justifyContent': 'flex-start',marginBottom:'1%'}} m={10}>
                {props.projects && props.projects.length>0 && title}</Typography>
            <Grid container spacing={0} >        
                
                {showProjectCards()}
            </Grid>
        </div>
    );

}