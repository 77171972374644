
export const recurrence = [
    { label: 'Every', value: 1 },
    { label: 'Every 2', value: 2 },
    { label: 'Every 3', value: 3 },
    { label: 'Every 4', value: 4 },
    { label: 'Every 5', value: 5 },
    { label: 'Every 6', value: 6 },
]
export const recurrenceType = [
    { label: 'Minute', value: "minute" },
    { label: 'Hour', value: 'hour' },
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
]
export const minutesOfTheHour = [
    { label: '00', value: 0 },
    { label: '01', value: 1 },
    { label: '02', value: 2 },
    { label: '03', value: 3 },
    { label: '04', value: 4 },
    { label: '05', value: 5 },
    { label: '06', value: 6 },
    { label: '07', value: 7 },
    { label: '08', value: 8 },
    { label: '09', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 },
    { label: '26', value: 26 },
    { label: '27', value: 27 },
    { label: '28', value: 28 },
    { label: '29', value: 29 },
    { label: '30', value: 30 },
    { label: '31', value: 31 },
    { label: '32', value: 32 },
    { label: '33', value: 33 },
    { label: '34', value: 34 },
    { label: '35', value: 35 },
    { label: '36', value: 36 },
    { label: '37', value: 37 },
    { label: '38', value: 38 },
    { label: '39', value: 39 },
    { label: '40', value: 40 },
    { label: '41', value: 41 },
    { label: '42', value: 42 },
    { label: '43', value: 43 },
    { label: '44', value: 44 },
    { label: '45', value: 45 },
    { label: '46', value: 46 },
    { label: '47', value: 47 },
    { label: '48', value: 48 },
    { label: '49', value: 49 },
    { label: '50', value: 50 },
    { label: '51', value: 51 },
    { label: '52', value: 52 },
    { label: '53', value: 53 },
    { label: '54', value: 54 },
    { label: '55', value: 55 },
    { label: '56', value: 56 },
    { label: '57', value: 57 },
    { label: '58', value: 58 },
    { label: '59', value: 59 },
]
export const hoursOfTheDay = [
    { label: '00', value: 0 },
    { label: '01', value: 1 },
    { label: '02', value: 2 },
    { label: '03', value: 3 },
    { label: '04', value: 4 },
    { label: '05', value: 5 },
    { label: '06', value: 6 },
    { label: '07', value: 7 },
    { label: '08', value: 8 },
    { label: '09', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
]
export const daysOfTheWeek = [
    { label: 'Sunday', value: 1 },
    { label: 'Monday', value: 2 },
    { label: 'Tuesday', value: 3 },
    { label: 'Wednesday', value: 4 },
    { label: 'Thursday', value: 5 },
    { label: 'Friday', value: 6 },
    { label: 'Saturday', value: 7 },
];
export const daysOfTheMonth = [
    { label: '1st', value: 1 },
    { label: '2nd', value: 2 },
    { label: '3rd', value: 3 },
    { label: '4th', value: 4 },
    { label: '5th', value: 5 },
    { label: '6th', value: 6 },
    { label: '7th', value: 7 },
    { label: '8th', value: 8 },
    { label: '9th', value: 9 },
    { label: '10th', value: 10 },
    { label: '11th', value: 11 },
    { label: '12th', value: 12 },
    { label: '13th', value: 13 },
    { label: '14th', value: 14 },
    { label: '15th', value: 15 },
    { label: '16th', value: 16 },
    { label: '17th', value: 17 },
    { label: '18th', value: 18 },
    { label: '19th', value: 19 },
    { label: '20th', value: 20 },
    { label: '21st', value: 21 },
    { label: '22nd', value: 22 },
    { label: '23rd', value: 23 },
    { label: '24th', value: 24 },
    { label: '25th', value: 25 },
    { label: '26th', value: 26 },
    { label: '27th', value: 27 },
    { label: '28th', value: 28 },
    { label: '29th', value: 29 },
];
export const timezones = [
    { label: "US/Pacific", value: "US/Pacific" },
    { label: "US/Mountain", value: "US/Mountain" },
    { label: "US/Central", value: "US/Central" },
    { label: "US/Eastern", value: "US/Eastern" },
    { label: "Europe/Amsterdam", value: "Europe/Amsterdam" },
    { label: "Europe/Berlin", value: "Europe/Berlin" },
    { label: "Europe/London", value: "Europe/London" },
    { label: "Europe/Kiev", value: "Europe/Kiev" },
    { label: "Australia/Adelaide", value: "Australia/Adelaide" },
    { label: "Australia/Sydney", value: "Australia/Sydney" },
    { label: "Pacific/Auckland", value: "Pacific/Auckland" },
    { label: "UTC", value: "UTC" },
    { label: "Etc/GMT-11", value: "Etc/GMT-11" },
    { label: "Etc/GMT-10", value: "Etc/GMT-10" },
    { label: "Etc/GMT-9", value: "Etc/GMT-9" },
    { label: "Etc/GMT-8", value: "Etc/GMT-8" },
    { label: "Etc/GMT-7", value: "Etc/GMT-7" },
    { label: "Etc/GMT-6", value: "Etc/GMT-6" },
    { label: "Etc/GMT-5", value: "Etc/GMT-5" },
    { label: "Etc/GMT-4", value: "Etc/GMT-4" },
    { label: "Etc/GMT-3", value: "Etc/GMT-3" },
    { label: "Etc/GMT-2", value: "Etc/GMT-2" },
    { label: "Etc/GMT-1", value: "Etc/GMT-1" },
    { label: "Etc/GMT+1", value: "Etc/GMT+1" },
    { label: "Etc/GMT+2", value: "Etc/GMT+2" },
    { label: "Etc/GMT+3", value: "Etc/GMT+3" },
    { label: "Etc/GMT+4", value: "Etc/GMT+4" },
    { label: "Etc/GMT+5", value: "Etc/GMT+5" },
    { label: "Etc/GMT+6", value: "Etc/GMT+6" },
    { label: "Etc/GMT+7", value: "Etc/GMT+7" },
    { label: "Etc/GMT+8", value: "Etc/GMT+8" },
    { label: "Etc/GMT+9", value: "Etc/GMT+9" },
    { label: "Etc/GMT+10", value: "Etc/GMT+10" },
    { label: "Etc/GMT+11", value: "Etc/GMT+11" },
    { label: "Etc/GMT+12", value: "Etc/GMT+12" },
];