import React, { useState } from 'react'
import AqtivaDialogFlexiblePosition from 'components/common/AqtivaDialogFlexiblePosition'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faCodeBranch } from '@fortawesome/free-solid-svg-icons'
import { Typography, FormControl, Card, Button, Checkbox } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import * as renderHelper from './projectVersionsDisplayUtils'
import { useDispatch } from "react-redux";

import * as actions from 'actions';

import * as commonStyles from 'style/CommonStyles'



export default function DialogDisplayProjectVersions(props) {
    const theme = useTheme()
    const dispatch = useDispatch()

    const [displayAllVersions, setDisplayALlVersions] = useState(false)
    const [displayMyModifications, setDisplayMyModifications] = useState(false)
    const [projectVersions, setProjectVersions] = React.useState([]);


    React.useEffect(() => {
        if (props.project && props.project._id) {
            ExecutefetchProjectVersions(props.project._id)
        }
    }, [props.showDialog]);// eslint-disable-line react-hooks/exhaustive-deps

    function ExecutefetchProjectVersions(projectId) {
        dispatch(actions.fetchProjectVersions(projectId)).then(response => {
            if (response.result === "ok" && response.data && response.data && response.data.length > 0) {

                setProjectVersions(response.data.reverse())
            }
        })
    }

    const handleChangeDisplayAllVersions = (event) => {
        setDisplayALlVersions(event.target.checked);
    };
    const handleChangeDisplayMyModifications = (event) => {
        setDisplayMyModifications(event.target.checked);
    };

    const translateUserIdToName = (userId, licenseUsers) => {
        if (licenseUsers !== undefined && licenseUsers.length > 0) {
            for (let i = 0; i < licenseUsers.length; i++) {
                if (licenseUsers[i].id === userId) {
                    return licenseUsers[i].name
                }
            }
            return userId
        }
        return undefined


    }


    return (
        <AqtivaDialogFlexiblePosition
            // Display project History Dialog
            // we control dialog position by pasing "flex-start" (right), center or "flex-end" as position parameter
            position={"flex-end"}
            showConfirmButton={false}
            rightDialog={true}
            visible={props.showDialog}
            dialog={props.showDialog}
            fullWidth={true}
            maxWidth={'sm'}
            title={'Version History'}
            editable={false}
            titleIcon={faCodeBranch}
            cancelText={'Close'}
            cancelCallback={() => {
                props.setShowDialog(false)
                setDisplayALlVersions(false)
            }}
            confirmCallback={event => {
                props.setShowDialog(false)
            }}
        >

            <FormControl style={{
                    /* maxHeight: "60vh", */ display: "flex", justifyContent: "center", flexDirection: "column"
            }} component="fieldset">
                <FormControl style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Checkbox
                        checked={displayMyModifications}
                        onChange={handleChangeDisplayMyModifications}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                    />
                    <Typography variant='caption' style={{ marginTop: "0.7rem" }} >Display only my modifications</Typography>
                    <Checkbox
                        checked={displayAllVersions}
                        onChange={handleChangeDisplayAllVersions}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                    />
                    <Typography variant='caption' style={{ marginTop: "0.7rem" }} >Display automatic commits</Typography>
                </FormControl>

                {projectVersions && projectVersions.length > 0 &&

                    projectVersions.map(version => {

                        // here we define styles and polish each version Card depending on modification type and type of element
                        const { color, message, display, icon, variant, isCommit } = renderHelper.extractPropertiesNewCommit(version, displayAllVersions, displayMyModifications, props.userId)

                        return (
                            <div key={version.updateDate}>
                                <Card style={{ display: display, justifyContent: "center", flexDirection: "column", marginBottom: "20px" }}>

                                    {isCommit && <Typography variant={variant} style={{ textAlign: "left", }}>

                                        {icon && <FontAwesomeIcon icon={icon} style={{ color: color, fontSize: 'calc(10px + 0.3vw)', marginTop: '2%', marginRight: '2%', marginLeft: '3%' }} />}
                                        {version.updateElementType} {message}
                                    </Typography>}
                                    {!isCommit && <Typography variant={variant} style={{ textAlign: "center", }}>

                                        {icon && <FontAwesomeIcon icon={icon} style={{ color: color, fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />}
                                        {version.updateElementType} {message}
                                    </Typography>}
                                    <Typography variant={'caption'} style={{ textAlign: "center" }}><strong>Project Modification:</strong> {version.projectHistory.updateInfo}</Typography>
                                    <Typography variant={'caption'} style={{ textAlign: "center" }}><strong>UpdateDate:</strong> {version.projectHistory.updateDate}</Typography>
                                    {version.userId && <Typography variant={'caption'} style={{ textAlign: "center" }}><strong>User:</strong> {translateUserIdToName(version.userId, props.license.users)}</Typography>}
                                    <Button
                                        onClick={event => {
                                            props.setSelectedVersionColor(color)
                                            props.setSelectedVersionIcon(icon)
                                            props.setSelectedVersion(version)
                                            props.setShowConfirmDialog(true)
                                            props.setSelectedVersionMessage(message)
                                        }}
                                        style={{ width: "fit-content", margin: "0 auto", color: '#70b3d6' }}
                                        endIcon={<FontAwesomeIcon icon={faUndo} style={{ fontSize: 'calc(7px + 0.3vw)', color: commonStyles.mainColor }} />}
                                    >
                                        <div style={{ ...theme.common.text.buttonTextSmall }}>
                                            revert to this version
                                        </div>
                                    </Button>
                                </Card>
                            </div>
                        )
                    })
                }
            </FormControl>
        </AqtivaDialogFlexiblePosition>
    )
}