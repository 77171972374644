import React, { useEffect, useState } from 'react';
import { Typography, FormControl, InputLabel, Tooltip, Button, Grid, Select, MenuItem, Fab, FormControlLabel, Checkbox, TextField } from '@material-ui/core'
import { faAd, faTrash } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as commonStyles from 'style/CommonStyles'
import CustomInput from 'components/common/CustomInput/CustomInput';



const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


export default function ColumnComparisonParams(props) {

    const classes = useStyles();



    const [columnOperations, setColumnOperations] = useState([])


    //TODO: CONDITION??
    useEffect(() => {
        props.setColumnOperations(columnOperations)
    }, [columnOperations && columnOperations.map(columnOperation => // eslint-disable-line react-hooks/exhaustive-deps  
        Object.keys(columnOperation).map(key => `${key}_${columnOperation[key]}`).join("_"))])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.newRule) {
            let columnOperation = {}
            let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
            columnOperation.id = newId
            columnOperation.operation = "=="
            columnOperation.order = 0
            setColumnOperations([columnOperation])
        }
    }, [props.ruleInfo])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.columnOperationsInfo) {
            setColumnOperations(props.columnOperationsInfo)
        }
    }, [props.columnOperationsInfo])// eslint-disable-line react-hooks/exhaustive-deps

    let comparators = ["==", "<", "<=", ">", ">="]



    function handleAddColumnOperations() {
        let columnOperation = {}
        let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
        columnOperation.id = newId
        columnOperation.id = newId
        columnOperation.operation = "=="
        columnOperation.order = columnOperations.length
        let newColumnOperations = JSON.parse(JSON.stringify(columnOperations))
        newColumnOperations.push(columnOperation)
        setColumnOperations(newColumnOperations)
    }

    function handleDeleteColumnOperations(columnOperation2Delete) {
        let newColumnOperations = columnOperations.filter(function (element) {
            return element.id !== columnOperation2Delete.id;
        });
        setColumnOperations(JSON.parse(JSON.stringify(newColumnOperations)))
    }

    function handleChangeColumnColumnOperationsOrigin(columnOperations2update, value) {
        console.log('entrando en origen', value)
        columnOperations2update.column_or = value

        let index = columnOperations.findIndex(x => x.id === columnOperations2update.id)
        columnOperations[index] = columnOperations2update
        setColumnOperations(JSON.parse(JSON.stringify(columnOperations)))
    }

    function handleChangeColumnColumnOperationsDestination(columnOperations2update, value) {
        console.log('entrando en destination', value)
        columnOperations2update.column_dst = value

        let index = columnOperations.findIndex(x => x.id === columnOperations2update.id)
        columnOperations[index] = columnOperations2update
        setColumnOperations(JSON.parse(JSON.stringify(columnOperations)))
    }
    function handleChangeOperator(columnOperations2update, value) {
        columnOperations2update.operation = value

        let index = columnOperations.findIndex(x => x.id === columnOperations2update.id)
        columnOperations[index] = columnOperations2update
        setColumnOperations(JSON.parse(JSON.stringify(columnOperations)))
    }





    return (
        <>
            {columnOperations && columnOperations.length > 0 && <Grid container spacing={1} style={{ marginTop: "20px" }}>
                {columnOperations && columnOperations.length > 0 && columnOperations.map((columnOperation, index) => {
                    return (
                        <>
                            <Grid item xs={12}>
                                <div style={{ width: '50%' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={props.nullAccepted}
                                                onChange={event => props.setNullAccepted(event.target.checked)}
                                                value="nullValueCheck"
                                                color="primary"
                                            />
                                        }
                                        label="Null values are accepted as correct"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{ marginTop: "20px" }} variant="subtitle2">Column comparison {index + 1}</Typography>
                            </Grid>
                            {/*  <Grid item xs={1}>
                                <FormControl style={{ width: '100%', }}>
                                    <InputLabel style={{ fontSize: '10px' }} id="origin-column">Order</InputLabel>
                                    <Select
                                        defaultValue={''}
                                        renderValue={(val) => val + 1}
                                        inputProps={{
                                            style: { fontSize: 10 }
                                        }}
                                        style={{ minWidth: "20%" }}
                                        autoWidth={true}
                                        labelId="origin-column"
                                        id="origin-column"
                                        value={columnOperation.order}
                                        label="origin column"
                                        onChange={(event) => {
                                            changeOrder(index, event.target.value)
                                        }}
                                    >
                                        {columnOperations.map((columnOperation, index) => {
                                            return (
                                                <MenuItem key={index + 1} value={index}>
                                                    <Typography variant='body2'>{index + 1}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={3}>
                                <FormControl style={{ width: '100%', }}>
                                    <Autocomplete
                                        style={{ minWidth: "20%" }}
                                        autoWidth={true}
                                        labelId="origin-column"
                                        id="origin-column"
                                        value={columnOperation.column_or}
                                        label="origin column"
                                        onChange={(event, newValue) => {
                                            handleChangeColumnColumnOperationsOrigin(columnOperation, newValue)
                                        }}

                                        options={(props.source) ? props.source : []}
                                        getOptionLabel={(e) => `${e.name}`}
                                        renderInput={(e) => <TextField {...e} label="Select first column to compare" />}
                                    />

                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl style={{ width: '100%', }}>
                                    <InputLabel id="origin-column">Select an operation to compate</InputLabel>
                                    <Select
                                        defaultValue={'='}
                                        renderValue={(val) => val}
                                        inputProps={{
                                            style: { fontSize: 10 }
                                        }}
                                        style={{ minWidth: "20%" }}
                                        autoWidth={true}
                                        labelId="columnOperation"
                                        id="columnOperation"
                                        value={columnOperations.operation}
                                        label="Column Operation"
                                        onChange={(event) => {
                                            handleChangeOperator(columnOperation, event.target.value)
                                        }}
                                    >
                                        {comparators && comparators.map(value => {
                                            return (
                                                <MenuItem key={value} value={value}>
                                                    <Typography variant='body2'>{value}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>


                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl style={{ width: '100%', }}>

                                    <Autocomplete
                                        style={{ minWidth: "40%" }}
                                        autoWidth={true}
                                        labelId="origin-column"
                                        id="origin-column"
                                        value={columnOperation.column_dst}
                                        label="origin column"
                                        onChange={(event, newValue) => {
                                            handleChangeColumnColumnOperationsDestination(columnOperation, newValue)
                                        }}

                                        options={(props.source) ? props.source : []}
                                        getOptionLabel={(e) => `${e.name}`}
                                        renderInput={(e) => <TextField {...e} label="Select first column to compare" />}
                                    />

                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                {index !== 0 && <Tooltip title={"Delete columnOperations"} style={{ marginTop: "10px" }}>
                                    <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                        onClick={() => { handleDeleteColumnOperations(columnOperation) }}>
                                        <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                    </Fab>
                                </Tooltip>}
                            </Grid>
                            {props.errors && <Grid item xs={12}>
                                <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].column_dst}</Typography>
                                <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].column_or}</Typography>
                                <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].operation}</Typography>
                            </Grid>}
                        </>
                    )
                })
                }
                <Grid item xs={2}>
                    <CustomInput
                        labelText="Comparison Margin"
                        id="comparison_margin"
                        formControlProps={{
                            fullWidth: false,
                            style: { width: '80%' }
                        }}

                        inputProps={{
                            type: "number",
                            step: '0,5',
                            min: "0",
                            max: "100",
                            onChange: ((event) => {
                                if (parseFloat(event.target.value) > 100) props.setMarginComparison(100)
                                else props.setMarginComparison(parseFloat(event.target.value))
                            }),
                            value: props.marginComparison ? props.marginComparison : 0
                        }}
                    />
                </Grid>
                < Grid item xs={12}>
                    <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "20px" }}
                        color="primary" onClick={event => {
                            handleAddColumnOperations()
                        }}>
                        <FontAwesomeIcon icon={faAd} style={{ marginRight: '5%', marginTop: '10%', color: commonStyles.mainColor, fontSize: 15 }} />
                        add new Column Operations
                    </Button>
                </Grid>
            </Grid>}

        </>
    )
}