import React from 'react';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CircularProgress, } from '@material-ui/core';
import { StyledMenu, StyledMenuItem } from "components/common/CustomizedMenu";
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dispatchInfo } from 'components/common/axios/axiosHelper';
import * as commonStyles from 'style/CommonStyles'
import * as treeViewHelper from './helpers/TreeViewFileStorageHelper'


const useStyles = makeStyles((theme) => ({
    root: {
        height: 240,
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        height: 380,
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialState = {
    mouseX: null,
    mouseY: null,
};



export default function TreeViewFileStorageDatabase(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))

    const [credentials, setCredentials] = React.useState([])


    const [selected, setSelected] = React.useState([])
    const [expanded, setExpanded] = React.useState([]);

    const [state, setState] = React.useState(initialState);


    //const [transactions, setTransactions] = React.useState([])


    const [selectedBucket, setSelectedBucket] = React.useState('')
    const [selectedObject, setSelectedObject] = React.useState('')
    const [selectedContainer, setSelectedContainer] = React.useState('')
    const [selectedCredentials, setSelectedCredentials] = React.useState('')
    // const [existantDataformats, setExistantDataformats] = React.useState([])
    const [typePreview, setTypePreview] = React.useState('')
    //const existantDataformats = []
    //const [notEnvironmentFlag, setNotEnvironmentFlag] = React.useState(false)
    function setNotEnvironmentFlag() { }

    React.useEffect(() => {
        if (props.selectedEnvironment) {
            let credentials = getCredentialsFromEnvironmentConfig(props.selectedEnvironment, configuration)
            if (credentials) {
                setCredentials(credentials)
            }
        }
    }, [user, props.connectToLocalStorages, props.selectedEnvironment && props.selectedEnvironment.environmentName, configuration]);// eslint-disable-line react-hooks/exhaustive-deps


    function getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration) {

        let masterConfig = JSON.parse(JSON.stringify(configuration))

        if (masterConfig.environmentConfigurationList && masterConfig.environmentConfigurationList.length > 0) {
            let selectedEnvironmentConfiguration = masterConfig.environmentConfigurationList.filter(envConf => envConf.environmentName === selectedEnvironment)[0]
            let credentials = []
            if (selectedEnvironmentConfiguration && selectedEnvironmentConfiguration.enabledDataStorageCredentials
                && selectedEnvironmentConfiguration.enabledDataStorageCredentials.length > 0) {
                //console.log('selectedEnvironmentConfiguration.enabledDataStorageCredentials', selectedEnvironmentConfiguration.enabledDataStorageCredentials)

                credentials = selectedEnvironmentConfiguration.enabledDataStorageCredentials
            }
            console.log('credentials1', credentials)
            credentials = checkIfUserCanAccessCredentials(user, credentials)
            console.log('credentials2', credentials)
            credentials = getCredentials(credentials)
            console.log('credentials3', credentials)
            return credentials
        }
    }

    //here we check if credentials are public or protected and if the user is allowed to access them
    function checkIfUserCanAccessCredentials(user, credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].accessType && credentials[i].accessType === "public_access") {
                usableCredentials.push(credentials[i])
            }
            if (credentials[i].accessType && credentials[i].accessType === "protected_access" && credentials[i].allowedUsers) {
                let userIsAllowed = credentials[i].allowedUsers.filter(allowedUser => allowedUser.id === user.id).length > 0
                if (userIsAllowed) {
                    usableCredentials.push(credentials[i])
                }
            }
        }
        if (usableCredentials.length === 0) setNotEnvironmentFlag(true)
        else setNotEnvironmentFlag(false)
        return usableCredentials
    }

    function getCredentials(credentials) {
        console.log('props.storageType', props.storageType)
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].storageType && (credentials[i].storageType === props.storageType || (props.storageType.startsWith('azure') && credentials[i].storageType.startsWith('azure')))) {
                usableCredentials.push(credentials[i])
            }
        }
        if (usableCredentials.length === 0) setNotEnvironmentFlag(true)
        else setNotEnvironmentFlag(false)

        return usableCredentials
    }



    //handles
    const handleClick = (event, credential, bucket, object, parent, type) => {
        setSelectedBucket(bucket)
        setSelectedObject(object)
        setSelectedCredentials(credentials.filter(cr => cr.id === credential.id)[0].id)
        if (credentials.containers && credentials.containers.length > 0 && credentials.containers.find(x => x.name === bucket.name).length > 0) setSelectedContainer(bucket.name)
        setTypePreview(type === 'object' ? 'file' : type)
        event.stopPropagation()
        event.preventDefault();
        let name = type === 'folder' ? bucket.name + '-' + object.name : object.fileName.split('/').at(-1)

        props.setFileOutputName(name)
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    function getBaseFormat(object, typePreview) {

        let baseFormat = ''


        if (typePreview !== 'folder') {
            if (object.fileName.includes('.hoodie')) {
                baseFormat = 'hudi'
            } else {
                baseFormat = object.fileName.split(".").at(-1)
                console.log('baseFormat', baseFormat)
                console.log('baseFormat', object.fileName.split(".").at(-1))
            }

        }
        else {
            if (object.objects && object.objects.length > 0) {
                object.objects.map(obj => {

                    if (obj.fileName.includes('.hoodie')) {
                        baseFormat = 'hudi'
                    } else if (obj.fileName.split('.').length > 1 && obj.fileName.split('.').at(-1).length > 1) {
                        baseFormat = obj.fileName.split('.').at(-1)
                    }
                })
            }else if(object.folders && object.folders.length > 0 ){
                object.folders.map(obj => {

                    if (obj.name.includes('.hoodie')) {
                        baseFormat = 'hudi'
                    } else if (obj.name.split('.').length > 1 && obj.name.split('.').at(-1).length > 1) {
                        baseFormat = obj.name.split('.').at(-1)
                    }
                })
            }
        }

        return baseFormat
    }

    async function handleProfiling(credentialId, bucket, object) {

        let baseFormat
        let creds = credentials.filter(cr => cr.id === credentialId)[0]

        baseFormat = getBaseFormat(object, typePreview)

        if (props.selectedFormat && props.selectedFormat !== 'AUTOMATIC')  baseFormat = props.selectedFormat.toLowerCase()

        const dto = await treeViewHelper.getPreviewDTO(creds, bucket, object, typePreview, baseFormat, props.storageType, dispatch)

       

        handleClose()
        props.setVisiblePreview(true)

        dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")

        switch(props.storageType){
            case 'aws' : {
                await dispatch(actions.addProfilingToDataformatAWS(props.selectedEnvironment, user.id, dto, props.profilingSamples, props.flattenFlag)).then(result => {
                    if (result && result.profilingBatchId) {
                        props.setProfilingBatchId(result.profilingBatchId)
                    }
                    if (result && result.dto?.run_id){
                        props.setRunId(result.dto?.run_idƒ)
                    }
        
                    if (typePreview != 'folder') props.setSelectedItem(dto)
        
                })
            }
            case 'azure_storage' : {

                await dispatch(actions.addProfilingToDataformat(props.selectedEnvironment, user.id, dto, props.profilingSamples, props.flattenFlag)).then(result => {

                    if (result && result.profilingBatchId) {
                        props.setProfilingBatchId(result.profilingBatchId)
                    }
                    if (result && result.dto?.run_id){
                        props.setRunId(result.dto?.run_idƒ)
                    }
                    if (typePreview != 'folder') props.setSelectedItem(dto)
        
                })
            }
        }

        
    }

    async function handlePreview(credentialId, bucket, object) {
        //const cred = credentials.filter(cr => cr.id === credentialId)[0]
        let baseFormat
        let creds = credentials.filter(cr => cr.id === credentialId)[0]

        baseFormat = getBaseFormat(object, typePreview)
        console.log('baseformat', baseFormat)
        
        if (props.selectedFormat && props.selectedFormat !== 'AUTOMATIC')  baseFormat = props.selectedFormat.toLowerCase()

        const dto = await treeViewHelper.getPreviewDTO(creds, bucket, object, typePreview, baseFormat, props.storageType, dispatch)
        

        handleClose()
        props.setVisiblePreview(true)
        props.setSelectedItem(dto)
        dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")
        await dispatch(actions.createPreviewDataJob(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
            if (result !== undefined && result.error === undefined) {
                props.setVisiblePreview(true)
            }
            if (result && result.run_id) {
                props.setRunId(result.run_id)
            }

        })
    }



    //Calls to backend
    async function getContainers(credential, type) {

        console.log('cre', credential)

        if (credential.storageType === 'azure_container') {

            setCredentials(
                credentials.map(c => {
                    if (c.id === credential.id) c = {
                        ...c,
                        'containers': [{ name: credential.storageAccountCredentialsDTO.folder }]
                    }
                    return c
                })
            )
        } else {
            let aqtivaConnector = treeViewHelper.getAqtivaConector(credential, type)

            let result = await dispatch(actions.listStorages(aqtivaConnector, type))
            if (result) {
                console.log('containers', result)
                setCredentials(
                    credentials.map(c => {
                        if (c.id === credential.id) c = {
                            ...c,
                            'containers': result
                        }
                        return c
                    })
                )
            }
        }
    }


    async function handleGetObjects(credential, container) {

        let aqtivaConnector = treeViewHelper.getAqtivaConector(credential, props.type)
        aqtivaConnector.container = container.name

        let result = await dispatch(actions.listObjectsFileStorage(aqtivaConnector, props.type))
        if (result) {
            console.log('objects', result)
            setCredentials(
                credentials.map(c => {
                    if (c.id === credential.id) {
                        c.containers = c.containers.map(cnt => {
                            if (cnt === container) {
                                cnt = {
                                    ...cnt,
                                    'files': result.objects,
                                    'folders': result.prefixes
                                }
                            }
                            return cnt
                        })
                    }
                    return c
                })
            )
        }
    }

    async function handleGetFolderObjects(credential, container, folder) {

        let aqtivaConnector = treeViewHelper.getAqtivaConector(credential, props.type)
        aqtivaConnector.container = container.name

        let result = await dispatch(actions.listFolderObjectsFileStorage(aqtivaConnector, props.type, folder.name.replaceAll('/', '_AQTIVA_SLASH_')))

        var newCredentials = (credentials.map(c => {
            if (c.id === credential.id) {
                c.containers = c.containers.map(cnt => {
                    if (cnt.name === container.name) {
                        cnt.folders = cnt.folders.map(fld => {
                            fld = checkAndUpdateChildsInObject(fld, result, folder)
                            return fld;
                        })
                    }
                    return cnt
                })
            }
            return c
        }))
        console.log('newCredentials', newCredentials)
        setCredentials(newCredentials)


    }

    function checkAndUpdateChildsInObject(childFolder, result, parentFolder) {

        if (childFolder.name === parentFolder.name) {
            childFolder = {
                ...childFolder,
                'files': result.objects,
                'folders': result.prefixes
            }
        }
        else {
            childFolder = {
                ...childFolder,
                'folders': childFolder && childFolder.folders && childFolder.folders !== null ? childFolder.folders.map(fld => {
                    return checkAndUpdateChildsInObject(fld, result, parentFolder)
                }) : []
            }
        }
        return childFolder
    }

    //Render structure

    function createContainer(credential, container) {
        const containertName = container.name
        //let containerComplete = credential.storages.filter(stg => stg.name === storage.name).containers.filter(cnt => cnt.name === containertName)[0]

        return (
            <div>

                <TreeItem key={containertName + '_container'} nodeId={containertName + '_container'}
                    onClick={event => {
                        handleGetObjects(credential, container)
                    }}
                    label={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>
                            {treeViewHelper.getIconsContainers(props.type)}
                            <Typography variant='caption'>{containertName}</Typography>
                        </div>
                    } >
                    {container.folders !== undefined && container.folders.length > 0
                        &&
                        container.folders.map(folder => {

                            return <TreeItem key={container.name + '_' + folder.name} nodeId={container.name + '_' + folder.name}

                                onContextMenu={event => handleClick(event, credential, container, folder, "", 'folder')}
                                label={
                                    <div style={{
                                        marginRight: '5px', display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        {props.alreadyExistantDataformat(credential, container, folder, 'folder') === true &&
                                            <Tooltip title={`Source already registered in Aqtiva`}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    <img alt="" style={{ height: '20px', width: '20px' }}
                                                        src={require('resources/icons/favicon-32x32.jpg')} />
                                                    <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{folder.name}</Typography>

                                                </div>
                                            </Tooltip>
                                        }
                                        {props.alreadyExistantDataformat(credential, container, folder, 'folder') === false &&
                                            <>
                                                {treeViewHelper.getIconsFolders(props.type)}
                                                <Typography variant='subtitle2'>{`${folder.name}`}</Typography>
                                            </>
                                        }

                                    </div>}
                                onClick={event => handleGetFolderObjects(credential, container, folder)}>

                                {createMenuItemDatasetChild(credential, container, folder)}



                            </TreeItem>
                        })
                    }
                    {container.files !== undefined && container.files.length > 0
                        &&
                        container.files.map(file => {
                            if (file.name !== container.name) {
                                return <TreeItem key={file.fileName} nodeId={file.fileName}
                                    onContextMenu={event => handleClick(event, credential, container, file, "", 'object')}
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>

                                            {props.alreadyExistantDataformat(credential, container, file, 'object') === false &&
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>

                                                    {treeViewHelper.getIconsFiles(props.type)}
                                                    <Typography variant='caption' >{file.fileName.split("/").at(-1)}</Typography>
                                                </div>
                                            }
                                            {props.alreadyExistantDataformat(credential, container, file, 'object') === true &&
                                                <Tooltip title={`Source already registered in Aqtiva`}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}>

                                                        <img alt="" style={{ height: '15px', width: '15px' }}
                                                            src={require('resources/icons/favicon-32x32.jpg')} />
                                                        <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{file.fileName.split("/").at(-1)}</Typography>

                                                    </div>
                                                </Tooltip>
                                            }


                                        </div>
                                    } >

                                </TreeItem>
                            }
                        })
                    }
                    {container.files === undefined && (
                        <TreeItem key={`${container.name}_loading`} nodeId={`${container.name}_loading`}
                            label={
                                < div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                    <Typography variant='caption' >loading contents</Typography>

                                </div>
                            } >
                        </TreeItem >
                    )}
                </TreeItem>

                {getStyledMenu()}

            </div>
        )

    }

    function createMenuItemDatasetChild(credential, container, folderParent) {
        //let folderComplete = credential.storages.filter(stg => stg.name === storage.name).containers.filter(cnt => cnt.name === container.name).folders.filter(fld => fld.name === folderName)[0]

        return (
            <div>
                {folderParent.folders && folderParent.folders.map(childFolder => {

                    return <TreeItem key={folderParent.name + '_' + childFolder.name} nodeId={folderParent.name + '_' + childFolder.name}
                        onContextMenu={event => handleClick(event, credential, folderParent, childFolder, folderParent, 'folder')}
                        label={
                            <div style={{
                                marginRight: '5px', display: 'flex',
                                justifyContent: 'left',
                            }}>
                                {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                                {props.alreadyExistantDataformat(credential, container, childFolder, 'folder') === false &&
                                    <>
                                        {treeViewHelper.getIconsFolders(props.type)}
                                        <Typography variant='subtitle2'>{`${childFolder.name}`}</Typography>
                                    </>
                                }
                                {props.alreadyExistantDataformat(credential, container, childFolder, 'folder') === true &&
                                    <Tooltip title={`Source already registered in Aqtiva`}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <img alt="" style={{ height: '20px', width: '20px' }}
                                                src={require('resources/icons/favicon-32x32.jpg')} />
                                            <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{`${childFolder.name}/`}</Typography>

                                        </div>
                                    </Tooltip>
                                }
                            </div>}
                        onClick={event => handleGetFolderObjects(credential, container, childFolder)}>

                        {createMenuItemDatasetChild(credential, container, childFolder)}



                    </TreeItem>

                })
                }
                {folderParent.files !== undefined && folderParent.files !== null && folderParent.files.length > 0
                    &&
                    folderParent.files.map(file => {

                        if (file.key !== folderParent.name) {
                            return <TreeItem key={folderParent.name + '_' + file.fileName} nodeId={file.fileName}
                                onContextMenu={event => handleClick(event, credential, folderParent, file, 'object')}
                                label={
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>

                                        {props.alreadyExistantDataformat(credential, container, file, 'object') === false &&
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                            }}>

                                                {treeViewHelper.getIconsFiles(props.type)}
                                                <Typography variant='caption' >{file.fileName.split("/").at(-1)}</Typography>
                                            </div>
                                        }
                                        {props.alreadyExistantDataformat(credential, container, file, 'object') === true &&
                                            <Tooltip title={`Source already registered in Aqtiva`}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    <img alt="" style={{ height: '15px', width: '15px' }}
                                                        src={require('resources/icons/favicon-32x32.jpg')} />
                                                    <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{file.fileName.split("/").at(-1)}</Typography>

                                                </div>
                                            </Tooltip>
                                        }

                                    </div>
                                } >

                            </TreeItem>
                        }
                    })
                }
                {folderParent.files === undefined && (
                    <TreeItem key={`${folderParent.name}_loading`} nodeId={`${folderParent.name}_loading`}
                        label={
                            < div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>
                                <CircularProgress style={{ width: '15px', height: '15px' }} />
                                <Typography variant='caption' >loading contents</Typography>

                            </div>
                        } >
                    </TreeItem >
                )}
                {getStyledMenu()}
            </div >
        )

    }

    function getStyledMenu() {
        return (
            <StyledMenu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <StyledMenuItem onClick={() => handlePreview(selectedCredentials, selectedBucket, selectedObject)}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    <ListItemText primary="Preview" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleProfiling(selectedCredentials, selectedBucket, selectedObject)}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    <ListItemText primary="Create Profiling" />
                </StyledMenuItem>

            </StyledMenu>
        )
    }



    return (

        <div className={classes.root} >
            {(credentials === undefined || credentials === null || credentials.length === 0) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>

                    {/*!notEnvironmentFlag ?
                        <>
                            <CircularProgress style={{ width: '25px', height: '25px' }} />
                            <Typography variant='subtitle1'>Loading ...</Typography>
                        </>
                        : <Typography variant='subtitle2'>Not enabled buckets in this environment</Typography>
                    */}
                    <Typography variant='subtitle2'>Not enabled buckets in this environment</Typography>
                </div>
            }
            {credentials !== undefined && credentials !== null && credentials.length > 0 &&
                <TreeView
                    className={classes.rootView}
                    style={{ maxHeight: 264, flexGrow: 1, overflowY: 'auto' }}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {credentials.map(credential => {
                        return <TreeItem nodeId={credential.id} key={credential.id} label={
                            <div style={{
                                marginRight: '5px', display: 'flex',
                                justifyContent: 'left',
                            }}>
                                {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                                {treeViewHelper.getIconsCredentials(props.type)}
                                <Typography variant='subtitle2'>{`${credential.displayName}`}</Typography>
                            </div>}
                            onClick={event => getContainers(credential, props.type)}>


                            {credential === undefined || credential === null || credential.containers === undefined ?
                                <TreeItem nodeId={`empty`} key={`empty`} label={
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <CircularProgress style={{ width: '15px', height: '15px' }} />
                                        <Typography variant='subtitle2'>Loading containers, it may take some seconds...</Typography>
                                    </div>
                                } /> :
                                credential.containers && credential.containers.length > 0 && credential.containers.map(container => {
                                    return (
                                        createContainer(credential, container)
                                    )
                                })
                            }
                        </TreeItem>
                    })}

                </TreeView>
            }
        </div>

    )
}