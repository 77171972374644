import React from 'react';
import { Typography, Tabs, Tab } from '@material-ui/core'


import ProjectDrillDownComponent from './ProjectDrillDownComponent';
import GlobalDQIComponent from './GlobalDQIComponent';
import QualityDashboardPagev2 from './QualityDashboardPagev2'
import { useLocation } from "react-router-dom"

export default function DashboardTabComponent(props) {

    const [tabValue, setTabValue] = React.useState(props.tabValue ? props.tabValue : 1)
    const location = useLocation()
    const [initialSimulationId, setInitialSimulationId] = React.useState()
    React.useEffect(() => {
        setTabValue(props.tabValue)
    }, [props.tabValue])

    function loading(loading){
        props.loading(loading)
    }

    function handleTabChange(event, newValue){
        props.handleTabChange(newValue)
    }
    React.useEffect(() => {
        setInitialSimulationId(props.initialSimulationId)
    }, [props.initialSimulationId])

    React.useEffect(() => {
        if (location && location.state && location.state.showSimulation) {
          console.log('simulation before load', location.state.simulationId)
          setInitialSimulationId(location.state.simulationId)
          setTabValue(2)
        }
      }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Tabs value={tabValue} onChange={handleTabChange}
                variant="scrollable" scrollButtons="off"
                style={{ width: '100%', marginTop: '0px' }}>

                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}> <Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Global DQI`}</Typography> </div>}
                />
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}> <Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Project DQI`}</Typography> </div>}
                />
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Simulation DQI`}</Typography> </div>}
                />
            </Tabs>

            {
                tabValue === 0 &&
                <div style={{ width: '99%', margin: "1%" }}>
                    <GlobalDQIComponent
                        filterObj={props.filterObj}
                        DQI4Project={props.DQI4Project}
                        DQIByDimension={props.DQIByDimension}
                        executionsByDimension={props.executionsByDimension}
                        executionsByRuleType={props.executionsByRuleType}
                        definitionsByDimension={props.definitionsByDimension}
                        definitionsByRuleType={props.definitionsByRuleType}
                        globalDQI={props.globalDQI}
                        DQIAndExecutions4RuleTypeAndDimension={props.DQIAndExecutions4RuleTypeAndDimension}
                        DQIByDay={props.DQIByDay}
                        startDate={props.filterObj.startDate}
                        endDate={props.filterObj.endDate}
                    />
                </div>
            }

            {
                tabValue === 1 &&
                <div style={{ width: '99%', margin: "1%" }}>
                    <ProjectDrillDownComponent
                        filterObj={props.filterObj}
                        DQI4Project={props.DQI4Project}
                        DQI4ProjectMostRecent={props.DQI4ProjectMostRecent}
                        globalDQI={props.globalDQI}
                        DQIByDimension={props.DQIByDimension}

                        DQI4ProjectFlag={props.DQI4ProjectFlag}
                        DQI4ProjectMostRecentFlag={props.DQI4ProjectMostRecentFlag}
                        DQIByDimensionFlag={props.DQIByDimensionFlag}
                        loading={loading}
                        initialProjectId={props.initialProjectId}
                        setInitialProjectId={props.setInitialProjectId}
                        initialQpId={props.initialQpId}
                        setInitialQpId={props.setInitialQpId}
                        initialDatasourceId={props.initialDatasourceId}
                        setInitialDatasourceId={props.setInitialDatasourceId}
                        history={props.history}

                    />
                </div>
            }

            {
                tabValue === 2 &&
                <div style={{ width: '99%', margin: "1%" }}>
                    <QualityDashboardPagev2 initialIdSimulation={initialSimulationId}/>
                </div>
            }
        </>
    );
}