import React from 'react';

import LoadingData from './LoadingData'

import { Paper } from '@material-ui/core'

import DQIDimensionsChartSmall from './DQIDimensionsChartSmall';


export default function DQIDimensionsChartDetail(props) {

  const [formatDqi,setFormatDqi] = React.useState(-1)
  const [integrityDqi,setIntegrityDqi] = React.useState(-1)
  const [unicityDqi,setUnicityDqi] = React.useState(-1)
  const [validityDqi,setValidityDqi] = React.useState(-1)
  //const [timelinessDqi,setTimelinessDqi] = React.useState(-1)
  const [veracityDqi,setVeracityDqi] = React.useState(-1)

  function getDataByDimension(data, dimension){
    if(data && data.length>0 && data.filter(x=>x.dimension===dimension).length>0){
      const dat=  data.filter(x=>x.dimension===dimension)[0]

      return parseFloat(dat.sumNumOk)/parseFloat(dat.sumNumIn)*100
    }
     return -1
  }


  React.useEffect(() => {
   
    setFormatDqi(getDataByDimension(props.data,"format"))
    setIntegrityDqi( getDataByDimension(props.data,'integrity'))
    setUnicityDqi( getDataByDimension(props.data,'unicity'))
    setValidityDqi( getDataByDimension(props.data,'validity'))
    //TODO: VARIABLE NOT USED
    //setTimelinessDqi( getDataByDimension(props.data,'timeliness'))
    setVeracityDqi( getDataByDimension(props.data,'veracity'))
  }, [props.data]);


   if (props.data && props.data.length >= 0) {
    return (
      <Paper style={{ width: '100%', height: '90%' }} >
        <div style={{
          width: '100%', height: '100%', display: 'flex',flexWrap: 'wrap',
          justifyContent: 'space-around'
        }}>
         <DQIDimensionsChartSmall  data = {formatDqi} dimension={'Format'}  />
         <DQIDimensionsChartSmall data = {integrityDqi} dimension={'Integrity'}  />
         <DQIDimensionsChartSmall data = {unicityDqi} dimension={'Unicity'}  />
         <DQIDimensionsChartSmall data = {validityDqi} dimension={'Validity'}  />
         <DQIDimensionsChartSmall data = {veracityDqi} dimension={'Veracity'}  />

         
        </div>

      </Paper>

    )
  }
  else {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <LoadingData />
      </div>

    )
  }

}
