import React from 'react';
import { faSearchPlus, faIdBadge } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


import AssignmentIcon from '@material-ui/icons/Assignment';
import { CircularProgress, } from '@material-ui/core';
import { StyledMenu, StyledMenuItem } from "components/common/CustomizedMenu";
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dispatchInfo } from 'components/common/axios/axiosHelper';
import * as commonStyles from 'style/CommonStyles'



const useStyles = makeStyles((theme) => ({
    root: {
        height: 240,
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        height: 380,
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialState = {
    mouseX: null,
    mouseY: null,
};



export default function TreeViewHiveDatabase(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))





    const [credentials, setCredentials] = React.useState([])


    const [selected, setSelected] = React.useState([])
    const [expanded, setExpanded] = React.useState([]);

    const [state, setState] = React.useState(initialState);


    const [selectedDatabase, setSelectedDatabase] = React.useState('')
    const [selectedTable, setSelectedTable] = React.useState('')
    const [selectedCredentials, setSelectedCredentials] = React.useState('')
    const [existantDataformats, setExistantDataformats] = React.useState([])

    const [notEnvironmentFlag, setNotEnvironmentFlag] = React.useState(false)


    React.useEffect(() => {
        if (props.selectedEnvironment) {
            let credentials = getCredentialsFromEnvironmentConfig(props.selectedEnvironment, configuration)
            if (credentials) {
                setCredentials(credentials)
            }

        }
    }, [user, props.connectToLocalStorages, props.selectedEnvironment && props.selectedEnvironment.environmentName, configuration]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getDatabases()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getHiveDataformats(user)
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps


    async function getHiveDataformats(user) {
        const dataformats = await dispatch(actions.getHiveOnpremiseDataformats(user.id, "hive"))
        
        setExistantDataformats(dataformats)
    }

    function getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration) {

        let masterConfig = JSON.parse(JSON.stringify(configuration))

        if (masterConfig.environmentConfigurationList && masterConfig.environmentConfigurationList.length > 0) {
            let selectedEnvironmentConfiguration = masterConfig.environmentConfigurationList.filter(envConf => envConf.environmentName === selectedEnvironment)[0]
            let credentials = []
            if (selectedEnvironmentConfiguration && selectedEnvironmentConfiguration.enabledDataStorageCredentials
                && selectedEnvironmentConfiguration.enabledDataStorageCredentials.length > 0) {
                //console.log('selectedEnvironmentConfiguration.enabledDataStorageCredentials', selectedEnvironmentConfiguration.enabledDataStorageCredentials)
                credentials = selectedEnvironmentConfiguration.enabledDataStorageCredentials
            }
            credentials = checkIfUserCanAccessCredentials(user, credentials)
            credentials = getHiveCredentials(credentials)
            return credentials
        }
    }


    //here we check if credentials are public or protected and if the user is allowed to access them
    function checkIfUserCanAccessCredentials(user, credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].accessType && credentials[i].accessType === "public_access") {
                usableCredentials.push(credentials[i])
            }
            if (credentials[i].accessType && credentials[i].accessType === "protected_access" && credentials[i].allowedUsers) {
                let userIsAllowed = credentials[i].allowedUsers.filter(allowedUser => allowedUser.id === user.id).length > 0
                if (userIsAllowed) {
                    usableCredentials.push(credentials[i])
                }
            }
        }
        if (usableCredentials.length === 0) setNotEnvironmentFlag(true)
        else setNotEnvironmentFlag(false)
        return usableCredentials
    }
    function getHiveCredentials(credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].storageType && (credentials[i].storageType === "awsHive")) {
                usableCredentials.push(credentials[i])
            }
        }
        if (usableCredentials.length === 0) setNotEnvironmentFlag(true)
        else setNotEnvironmentFlag(false)

        return usableCredentials
    }


    function alreadyExistantDataformat(credential, database, table) {
        var already = false
        
        if (existantDataformats && existantDataformats.length > 0) {

            existantDataformats.forEach(dataformat => {

                if (dataformat.hiveDatabaseProperties !== null && dataformat.hiveDatabaseProperties !== undefined &&
                    dataformat.hiveDatabaseProperties.credentialsId === credential.id &&
                    dataformat.hiveDatabaseProperties.database === database.name &&
                    dataformat.hiveDatabaseProperties.table === table.name) {
                    already = true
                }
            })
        }
        return already
    }


    const handleClick = (event, credential, database, table) => {



        setSelectedDatabase(database)
        setSelectedTable(table)
        setSelectedCredentials(credentials.filter(cr => cr.id === credential.id)[0].id)
        console.log('credId', credentials.filter(cr => cr.id === credential.id)[0].id)
        event.preventDefault();
        props.setFileOutputName(database.name + '_' + table.name)
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    async function handleProfiling(credentialId, database, table) {

        props.setPreviewVisible(false)
        props.resetProfilingState()

        const cred = credentials.filter(cr => cr.id === credentialId)[0]

        const dto = {
            storageType: "awsHive",
            database: database.name,
            table: table.name,
            credentials: cred,
            credentialsId: credentialId
        }

        handleClose()
        dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")
        await dispatch(actions.createProfilingDataJobHive(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

            if (result && result.dataformatId) {
                props.setProfilingdataformatId(result.dataformatId)
            }

        })
    }

    async function getDatabases(credential) {
        console.log('cred', credential)
        if (credential) {

            let result = []

            let aqtivaConnector = {
                server: credential.hiveCredentialsDTO.host,
                port: credential.hiveCredentialsDTO.port,
                user: credential.hiveCredentialsDTO.username,
                pwd: credential.hiveCredentialsDTO.password
            }


            result = await dispatch(actions.listHiveDatabases(aqtivaConnector, 'hive'))

            if (result) {
                //cred.databases = result
                setCredentials(
                    credentials.map(c => {
                        if (c.id === credential.id) c = {
                            ...c,
                            'databases': result.map(r => { return { name: r } })
                        }
                        return c
                    })
                )
            }
        }
    }

    async function getTables(credential, database) {

        if (credential) {
            let result = []

            let aqtivaConnector = {
                server: credential.hiveCredentialsDTO.host,
                port: credential.hiveCredentialsDTO.port,
                database: database.name,
                user: credential.hiveCredentialsDTO.username,
                pwd: credential.hiveCredentialsDTO.password
            }

            result = await dispatch(actions.listHiveTables(aqtivaConnector, /* 'hive', */ database.name))

            if (result) {
                //cred.databases = result
                setCredentials(
                    credentials.map(c => {
                        if (c.id === credential.id) {
                            c.databases = c.databases.map(db => {
                                if (db === database) {
                                    db = {
                                        ...db,
                                        'tables': result.map(r => { return { name: r } })
                                    }
                                }
                                return db
                            })
                        }
                        return c
                    })
                )
            }

        }
    }


    async function handlePreview(credentialId, database, table) {

        const cred = credentials.filter(cr => cr.id === credentialId)[0]


        const dto = {
            storageType: "awsHive",
            database: database.name,
            table: table.name,
            credentials: cred.hiveCredentialsDTO,
            credentialsId: credentialId
        }

        props.setSelectedHiveDTO(dto)
        handleClose()
        props.setVisiblePreview(true)

        dispatch(actions.createPreviewDataJobHive(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
            if (result && result.run_id) {

                props.setRunId(result.run_id)
            }

        })
    }
    function createMenuItemDataset(credential, database, tableSelected) {
        const tableName = tableSelected.name
        let table = database.tables.filter(tb => tb.name === tableName)[0]

        return (
            <div>

                <TreeItem nodeId={tableName} key={tableName}
                    onContextMenu={event => handleClick(event, credential, database, table)}
                    label={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>

                            {alreadyExistantDataformat(credential, database, table) === false &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>

                                    <AssignmentIcon />
                                    <Typography variant='caption' >{tableName}</Typography>
                                </div>
                            }
                            {alreadyExistantDataformat(credential, database, table) === true &&
                                <Tooltip title={`Source already registered in Aqtiva`}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <img alt="" style={{ height: '15px', width: '15px' }}
                                            src={require('resources/icons/favicon-32x32.jpg')} />
                                        <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{tableName}</Typography>

                                    </div>
                                </Tooltip>
                            }


                        </div>
                    }>

                </TreeItem>
                <StyledMenu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    <StyledMenuItem onClick={() => handlePreview(selectedCredentials, selectedDatabase, selectedTable)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Preview" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handleProfiling(selectedCredentials, selectedDatabase, selectedTable)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Create Profiling" />
                    </StyledMenuItem>

                </StyledMenu>
            </div>
        )

    }



    return (

        <div className={classes.root}>
            {(credentials === undefined || credentials === null || credentials.length === 0) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>

                    {/*!notEnvironmentFlag ?
                        <>
                            <CircularProgress style={{ width: '25px', height: '25px' }} />
                            <Typography variant='subtitle1'>Loading ...</Typography>
                        </>
                        : <Typography variant='subtitle2'>Not enabled buckets in this environment</Typography>
            */}
                </div>
            }
            {credentials !== undefined && credentials !== null && credentials.length > 0 &&
                <TreeView
                    className={classes.rootView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {credentials.map(credential => {
                        return credential !== undefined || credential !== null ?
                            <TreeItem nodeId={credential.id} key={credential.id} label={
                                <div style={{
                                    marginRight: '5px', display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                                    <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
                                    <Typography variant='subtitle2'>{`${credential.displayName}`}</Typography>
                                </div>}
                                onClick={event => getDatabases(credential)}>


                                {credential === undefined || credential === null || credential.databases === undefined ?
                                    <TreeItem nodeId={`empty`} key={`empty`} label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                                            <Typography variant='subtitle2'>Loading databases, it may take some seconds...</Typography>
                                        </div>
                                    } /> :
                                    credential.databases && credential.databases.length > 0 && credential.databases.map(database => {
                                        return database !== undefined || database !== null ?
                                            <TreeItem nodeId={database.name} key={database.name} label={
                                                <div style={{
                                                    marginRight: '5px', display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                                                    <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
                                                    <Typography variant='subtitle2'>{`${database.name}`}</Typography>
                                                </div>}
                                                onClick={event => getTables(credential, database)}>


                                                {database === undefined || database === null || database.tables === undefined ?
                                                    <TreeItem nodeId={`empty`} key={`empty`} label={
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'left',
                                                        }}>
                                                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                            <Typography variant='subtitle2'>Loading tables, it may take some seconds...</Typography>
                                                        </div>
                                                    } /> :
                                                    database.tables && database.tables.length > 0 && database.tables.map(table => {
                                                        return (
                                                            createMenuItemDataset(credential, database, table)
                                                        )
                                                    })
                                                }
                                            </TreeItem>
                                            :
                                            <TreeItem nodeId={`empty`} key={`empty`} label={
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                    <Typography variant='subtitle2'>Loading, it may take some seconds...</Typography>
                                                </div>
                                            } />
                                    })
                                }
                            </TreeItem>
                            : <TreeItem nodeId={`empty`} key={`empty`} label={
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                    <Typography variant='subtitle2'>Loading, it may take some seconds...</Typography>
                                </div>
                            } />
                    })}

                </TreeView>
            }
        </div >

    )
}