import * as colorHelper from './colorHelper'

import {onlyUnique} from 'commonFunctions/commonFunctions'

export const ruleTypeDict = {
    'anomalyColumns': 'Anomaly Columns',
    'ranges': 'Range of values',
    'cell_range': 'Range of values',
    'cell_null': 'Null check',
    'CELL_NULL': 'Null check',
    'global_based': 'Format',
    'statistics': 'Format',
    'DUPLICATED': 'Duplicated check',
    'dvd': 'Check between loads',
    'checkBetweenLoads': 'Check between loads',
    'masterData': 'Referencial check',
    'CELL_EXP': 'Regular Expression check',
    'adHoc': 'AdHoc queries',
    'time_diff': 'Timeliness',
}


export function getDQIDimensionsPie(formatValue, unicityValue, veracityVaule, integrityValue) {
    const data = [
        { name: 'Format', value: parseFloat(formatValue.toFixed(2)), fill: colorHelper.getColorForPercentage(parseFloat(formatValue.toFixed(2))) },
        { name: 'Unicity', value: parseFloat(unicityValue.toFixed(2)), fill: colorHelper.getColorForPercentage(parseFloat(formatValue.toFixed(2))) },
        { name: 'Veracity', value: parseFloat(veracityVaule.toFixed(2)), fill: colorHelper.getColorForPercentage(parseFloat(formatValue.toFixed(2))) },
        { name: 'Integrity', value: parseFloat(integrityValue.toFixed(2)), fill: colorHelper.getColorForPercentage(parseFloat(formatValue.toFixed(2))) },
    ];
    return data


}


export function createRadarDimensionData(fullMarkValue, formatValue, integrityValue, unicityValue, validityValue, veracityValue) {
    return [
        {
            subject: 'Format',
            value: formatValue,
            fullMark: fullMarkValue,
        },
        {
            subject: 'Integrity',
            value: integrityValue,
            fullMark: fullMarkValue,
        },
        {
            subject: 'Unicity',
            value: unicityValue,
            fullMark: fullMarkValue,
        },
        {
            subject: 'Validity',
            value: validityValue,
            fullMark: fullMarkValue,
        },
        {
            subject: 'Veracity',
            value: veracityValue,
            fullMark: fullMarkValue,
        }
    ]
}

export function getGlobalDQI(rawRuleData) {
    if (rawRuleData && rawRuleData.length > 0) {
        const DQInumIn = rawRuleData.reduce(function (acc, obj) { return acc + obj.sumNumIn; }, 0)
        const DQInumOk = rawRuleData.reduce(function (acc, obj) { return acc + obj.sumNumOk; }, 0)
        const DQInumKo = rawRuleData.reduce(function (acc, obj) { return acc + obj.sumNumKo; }, 0)
        const DQInumOut = rawRuleData.reduce(function (acc, obj) { return acc + obj.sumNumOut; }, 0)

        return {
            numIn: DQInumIn,
            numOut: DQInumOut,
            numOk: DQInumOk,
            numKO: DQInumKo,
            DQIok: DQInumOk / DQInumIn * 100,
            DQIko: DQInumKo / DQInumIn * 100
        }


    }
}

export function getGlobalExecutions(rawRuleData) {
    if (rawRuleData && rawRuleData.length > 0) {
        return rawRuleData.reduce(function (acc, obj) { return acc + obj.executions; }, 0)
        

        
    }
    return 0
}

export function getGlobalDefinitions(rawRuleData) {
    if (rawRuleData && rawRuleData.length > 0) {
        return rawRuleData.reduce(function (acc, obj) { return acc + obj.definitions; }, 0)
        

        
    }
    return 0
}



export function getGlobaRuleExections(rawRuleData) {
    if (rawRuleData && rawRuleData.length > 0) {
        const uniqueExecutions = [...new Set(rawRuleData.map(x => x.executionId))].length
        return uniqueExecutions
    }
    return 0
}
export function getRuleExectionsByDimension(rawRuleData, dimension) {
    if (rawRuleData && rawRuleData.length > 0) {


        const data = rawRuleData.filter(x => x.dimension === dimension)

        if (rawRuleData.length > 0 && data.length > 0) {
            const uniqueExecutions = [...new Set(data.map(x => x.executionId))].length
            return uniqueExecutions
        }
    }
    return 0
}

export function getGlobaRuleDefinitions(rawRuleData) {
    if (rawRuleData && rawRuleData.length > 0) {
        const uniqueExecutions = [...new Set(rawRuleData.map(x => x.ruleId))].length
        return uniqueExecutions
    }
    return 0
}
export function getRuleDefinitionsByDimension(rawRuleData, dimension) {
    if (rawRuleData && rawRuleData.length > 0) {
    const data = rawRuleData.filter(x => x.dimension === dimension)

    if (rawRuleData.length > 0 && data.length > 0) {
        const uniqueExecutions = [...new Set(data.map(x => x.ruleId))].length
        return uniqueExecutions
    }}
    return 0
}


export function createRuleIdMapType(projects, projectsLicense) {


    var myAssociativeArr = [];

    const projectsNew = projects.concat(projectsLicense)

    if (projectsNew && projectsNew.length > 0) {

        for (var i = 0; i < projectsNew.length; i++) {
            const qps = projectsNew[i].qualityPoints

            if (qps && qps.length > 0) {
                for (var j = 0; j < qps.length; j++) {
                    const dss = qps[j].datasources
                    if (dss && dss.length > 0) {
                        for (var k = 0; k < dss.length; k++) {
                            const rules = dss[k].rules
                            if (rules && rules.length > 0) {
                                for (var n = 0; n < rules.length; n++) {
                                    myAssociativeArr[rules[n]._id] = rules[n].subtype
                                }
                            }
                        }
                    }
                }
            }
        }
        return myAssociativeArr

    }

}

export function createDataformat2DatasetMap(projects, projectsLicense) {


    var myAssociativeArr = [];
    const projectsNew = projects.concat(projectsLicense)

    if (projectsNew && projectsNew.length > 0) {

        for (var i = 0; i < projectsNew.length; i++) {


            const qps = projectsNew[i].qualityPoints

            if (qps && qps.length > 0) {
                for (var j = 0; j < qps.length; j++) {
                    const dss = qps[j].datasources
                    if (dss && dss.length > 0) {
                        for (var k = 0; k < dss.length; k++) {

                            const ds = dss[k]

                            if (ds && ds.dataset && ds.dataset._id) {
                                myAssociativeArr[ds._id] = ds.dataset.name
                            }
                        }

                    }
                }
            }
        }
        return myAssociativeArr

    }

}

export function createDataformat2DatasetMapId(projects, projectsLicense) {


    var myAssociativeArr = [];
    const projectsNew = projects.concat(projectsLicense)

    if (projectsNew && projectsNew.length > 0) {

        for (var i = 0; i < projectsNew.length; i++) {


            const qps = projectsNew[i].qualityPoints

            if (qps && qps.length > 0) {
                for (var j = 0; j < qps.length; j++) {
                    const dss = qps[j].datasources
                    if (dss && dss.length > 0) {
                        for (var k = 0; k < dss.length; k++) {

                            const ds = dss[k]

                            if (ds && ds.dataset && ds.dataset._id) {
                                myAssociativeArr[ds._id] = ds.dataset._id
                            }
                        }

                    }
                }
            }
        }
        return myAssociativeArr

    }

}

export function getDQIperDataFormat(rawRuleData, associateDataformat2Dataset,associateDataformat2DatasetId) {
    if (rawRuleData && rawRuleData.length > 0) {
    //first we get all different rule type
    if (associateDataformat2Dataset) {
        const dataFiltered = rawRuleData.filter(x => x.datasourceId && x.datasourceId in associateDataformat2Dataset).map(x => {
            const data = {
                numIn: x.numIn,
                numOk: x.numOk,
                dataformat: associateDataformat2Dataset[x.datasourceId],
                dimension: x.dimension,
                datasourceId: x.datasourceId
            }
            return data

        })
        const dataformat = dataFiltered.map(x => x.dataformat).filter(onlyUnique)
        const fullMarkValue = dataFiltered.length
        const dataArray = []

        for (var i = 0; i < dataformat.length; i++) {
            const dataformatS = dataformat[i]
            const dataDF = dataFiltered.filter(x => x.dataformat === dataformatS)
            const DQInumIn = dataDF.reduce(function (acc, obj) { return acc + obj.numIn; }, 0)
            const DQInumOk = dataDF.reduce(function (acc, obj) { return acc + obj.numOk; }, 0)

            const data = {
                subject: dataformatS,
                dataformatId: associateDataformat2DatasetId[dataDF[0].datasourceId],
                executions: dataDF.length,
                numIn:DQInumIn,
                numOk:DQInumOk,
                DQI: parseFloat((100 * DQInumOk / DQInumIn).toFixed(2)),
                fullMark: fullMarkValue,
            }
            dataArray.push(data)
        }
        const sortDataArray = dataArray.sort(compareDataformatByDQI)
        return sortDataArray
    }
}
    return []


}

export function enrichRawDataDF(data, associateDataformat2Dataset,associateDataformat2DatasetId) {
    if (data && data.length > 0) {
    //first we get all different rule type
    if (associateDataformat2Dataset) {
        const dataFiltered = data.filter(x => x.datasourceId && x.datasourceId in associateDataformat2Dataset).map(x => {
            var newData = JSON.parse(JSON.stringify(x))
            newData.dataformatId = associateDataformat2DatasetId[x.datasourceId]

           
            return newData

        })
        
        return dataFiltered
    }
}
    return []


}

export function enrichRawDataDF2(data, associateDataformat2Dataset,associateDataformat2DatasetId) {
    if (data && data.length > 0) {
    //first we get all different rule type
    if (associateDataformat2Dataset) {
        const dataFiltered = data.filter(x => x.datasourceId && x.datasourceId in associateDataformat2Dataset).map(x => {
            var newData = JSON.parse(JSON.stringify(x))
            newData.dataformatId = associateDataformat2DatasetId[x.datasourceId]

           
            return newData

        })
        return dataFiltered
    }
}
    return []


}

export function compareDataformatByDQI(a, b) {
    if (a.DQI < b.DQI) {
        return -1;
    }
    if (a.DQI > b.DQI) {
        return 1;
    }
    return 0;
}



export function mapRuleType(type) {
    if (type in ruleTypeDict) {
        return ruleTypeDict[type]
    }
    return type
}
export function getRuleExecutionsByRuleType(rawRuleData, associateRuleTypeId) {
    //first we get all different rule type

    if (associateRuleTypeId) {
        const ruleTypes = rawRuleData.filter(x => x.ruleId).map(x => x.ruleId in associateRuleTypeId ? mapRuleType(associateRuleTypeId[x.ruleId]) : mapRuleType(x.ruleType)).filter(onlyUnique)
        const fullMarkValue = rawRuleData.length

        const dataArray = []
        for (var i = 0; i < ruleTypes.length; i++) {
            const ruleType = ruleTypes[i]
            const data = {
                subject: ruleType,
                value: rawRuleData.filter(x => x.ruleId).map(x => x.ruleId in associateRuleTypeId ? 
                    mapRuleType(associateRuleTypeId[x.ruleId]) : mapRuleType(x.ruleType)).filter(x => x === ruleType).length,
                fullMark: fullMarkValue,
            }
            dataArray.push(data)
        }
        return dataArray
    }
    return []


}

export function getRuleDefinitionsByRuleType(rawRuleData, userProjects, licenseProjects) {
    if (rawRuleData && rawRuleData.length > 0) {
    //first we get all different rule type
    const associateRuleTypeId = createRuleIdMapType(userProjects, licenseProjects)
    if (associateRuleTypeId) {
        const ruleTypes = rawRuleData.filter(x => x.ruleId).map(x => x.ruleId in associateRuleTypeId ? mapRuleType(associateRuleTypeId[x.ruleId]) : mapRuleType(x.ruleType)).filter(onlyUnique)
        const fullMarkValue = rawRuleData.length

        const dataArray = []
        //for rule definitions we need to take just one element per ruleId
        for (var i = 0; i < ruleTypes.length; i++) {
            const ruleType = ruleTypes[i]
            const data = {
                subject: ruleType,
                value: rawRuleData.filter(x => x.ruleId).map(x => {
                    const data = {
                        ruleType: x.ruleId in associateRuleTypeId ? mapRuleType(associateRuleTypeId[x.ruleId]) : mapRuleType(x.ruleType),
                        ruleId: x.ruleId
                    }
                    return data
                }).filter(x => x.ruleType === ruleType).map(x => x.ruleId).filter(onlyUnique).length,
                fullMark: fullMarkValue,
            }
            dataArray.push(data)
        }
        return dataArray
    }
}
    return []


}

export function getGlobalDQIDimension(rawRuleData, dimension) {

    if (rawRuleData && rawRuleData.length > 0) {
        const data = rawRuleData.filter(x => x.dimension === dimension)
        if (data.length > 0) {
            const DQInumIn = data.reduce(function (acc, obj) { return acc + obj.numIn; }, 0)
            const DQInumOk = data.reduce(function (acc, obj) { return acc + obj.numOk; }, 0)
            //const DQInumKo = data.reduce(function (acc, obj) { return acc + obj.numKo; }, 0)
            //const DQInumOut = data.reduce(function (acc, obj) { return acc + obj.numOut; }, 0)



            return DQInumOk / DQInumIn * 100
        }
    }
    return -1
}