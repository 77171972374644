import React from 'react';


import { Grid, Typography } from '@material-ui/core';



export default function SummaryConnectionBigQuery(props) {

    return (

        <Grid container spacing={3}>
            {props.displayName && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Display name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.displayName}</Typography>
                </Grid>
            </>}
            {props.connectionObjectBigQuery && props.connectionObjectBigQuery.projectId && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Project ID:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectBigQuery && props.connectionObjectBigQuery.projectId}</Typography>
                </Grid>
            </>}
            {props.connectionObjectBigQuery && props.connectionObjectBigQuery.blobStorage && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>BlobStorage Name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectBigQuery && props.connectionObjectBigQuery.blobStorage}</Typography>
                </Grid>
            </>}
            {props.connectionObjectBigQuery && props.connectionObjectBigQuery.containerName && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Container Name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectBigQuery && props.connectionObjectBigQuery.containerName}</Typography>
                </Grid>
            </>}
            {props.connectionObjectBigQuery && props.connectionObjectBigQuery.serviceAccountPrivateKey && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Credentials:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectBigQuery && props.connectionObjectBigQuery.serviceAccountPrivateKey}</Typography>
                </Grid>
            </>}
        </Grid>
    )
}