import React from 'react';
import { Grid, Card,CardMedia,CardContent,DialogActions,
    Button,Dialog,DialogTitle,Typography,DialogContent} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from 'trackEvent'
import * as commonStyles from 'style/CommonStyles'

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    
      },
    media: {
        textAlign: 'center',
        marginTop:'10%',
   
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
      button: {
        margin: theme.spacing(1),
      },
    card: {
        maxWidth: 250,
        cursor:'pointer',
        minHeight:160
      },
    selectedCard: {
        maxWidth: 250,
        cursor:'pointer',
        borderColor: commonStyles.mainColor,
        borderWidth: '10px',
        minHeight:160
      },
    selectedMedia: {
        textAlign: 'center',
        marginTop:'10%',
      },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
      },
    
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
  }));

export default function QualityDetailProjectToolBar(props) {
    const theme = useTheme();

    const classes = useStyles();
    const [workItemType, setworkItemType] = React.useState(1)



    

    return(
        <div> 
            <Dialog open={props.open} 
            fullWidth={true}
            maxWidth = {'md'}>
                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                                <FontAwesomeIcon icon={faCheckCircle} style={theme.dialogs.dialogTitleAwesomeIcon}/>
                            
                        </Grid>
                        <Grid item >
                            <Typography  style={theme.dialogs.dialogTitleText}>Select Work Item</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container style={{marginTop: '2%'}}>
                        <Grid item xs={4}>
                            <Card className={(workItemType===1) ? classes.selectedCard : classes.card}
                                elevation={(workItemType===1) ? 12 : 1}
                                onClick = {event=>{
                                    trackEvent('DialogSelectWorkItem','Select QualityPoint');
                                    setworkItemType(1)
                                    props.openQPDialog(true)
                                    props.setClose(false)
                                } 
                                }
                                    >
                                <CardMedia className={(workItemType===1) ? classes.selectedMedia : classes.media}>
                                <Grid container spacing={1} style={{marginLeft:'10%'}}>
                                    <Grid item>
                                    <img alt="" style={{ height: '30px', width: '30px', marginLeft:'5px', marginRight:'5px',marginTop:'0px' }}
                                  src={require('resources/icons/point_proc1_black.png')}/>
                                    </Grid>
                                    <Grid item >
                                    <Typography variant = "h6"> Quality Point</Typography>
                                    </Grid>
                                </Grid>
                                    
                                </CardMedia>
                            
                                <CardContent>
                                    <Typography variant="body2"> It contains the definition of the rules to be applied to the desired DataSources </Typography>
                                </CardContent>
                            </Card>
                        
                        </Grid>
                        <Grid item xs={4}>
                            <Card className={(workItemType===2) ? classes.selectedCard : classes.card}
                                elevation={(workItemType===2) ? 12 : 1}
                                onClick = {event=>{
                                    trackEvent('DialogSelectWorkItem','Select Datasource');
                                    setworkItemType(2)
                                    
                                    props.openDSDialog(true)
                                    props.setClose(false)
                                    setworkItemType(1)
                                    }
                                }
                                >
                                <CardMedia className={(workItemType===2) ? classes.selectedMedia : classes.media}>
                                <Grid container spacing={1} style={{marginLeft:'10%'}}>
                                    <Grid item>
                                    <img alt="" style={{ height: '30px', width: '30px', marginLeft:'5px', marginRight:'5px',marginTop:'0px' }}
                                  src={require('resources/icons/data_proc3_black.png')}/>

                                    </Grid>
                                    <Grid item >
                                    <Typography variant = "h6"> DataSource</Typography>
                                    </Grid>
                                </Grid>
                                    
                                </CardMedia>
                            
                                <CardContent>
                                    <Typography variant="body2"> It defines the data to apply rules, either data streams or files</Typography>
                                </CardContent>
                            </Card>
                        
                        </Grid>
                        <Grid item xs={4}>
                            <Card className={(workItemType===3) ? classes.selectedCard : classes.card}
                                elevation={(workItemType===3) ? 12 : 1}
                                onClick = {event=>{
                                    trackEvent('DialogSelectWorkItem','Select Rule');
                                    setworkItemType(3)
                                    
                                    props.openRuleDialog(true)
                                    props.setClose(false)
                                    setworkItemType(1)
                                    }
                                }
                                >
                                <CardMedia className={(workItemType===3) ? classes.selectedMedia : classes.media}>
                                <Grid container spacing={1} style={{marginLeft:'10%'}}>
                                    <Grid item>
                                    <img alt="" style={{ height: '25px', width: '30px', marginLeft:'5px', marginRight:'5px',marginTop:'0px' }}
                                  src={require('resources/icons/rule_proc1_black.png')}/>

                                    </Grid>
                                    <Grid item >
                                    <Typography variant = "h6"> Rules</Typography>
                                    </Grid>
                                </Grid>
                                    
                                </CardMedia>
                            
                                <CardContent>
                                    <Typography variant="body2"> A  rule defines a quality policy to be applied to the Datasource</Typography>
                                </CardContent>
                            </Card>
                        
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={event=>
                        {
                            trackEvent('DialogSelectWorkitem','Cancel Button')
                            props.setClose(false)
                            }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}