
import * as React from 'react';

import { testJsonProfiling } from './ProfileTableTestData'
import Typography from '@material-ui/core/Typography';

import TextFormatIcon from '@material-ui/icons/TextFormat';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SmallBar from './SmallBar'
import HistogramBar from './HistogramBar'

import * as commonStyles from 'style/CommonStyles'


export function prepareData(json) {
    var data = json
    if (json === undefined) {
        data = JSON.parse(testJsonProfiling)
    }

    return data.map((x, index) => (parseDataRow(x, index)))

}


function parseDataRow(data, index) {
    return {
        id: index,
        columnName: data.columnName,
        columnType: data.columnType,
        rowCount: data.rowCount,
        nullCount: data.nullCount,
        nullPercent: data.nullPercent,
        MissingValue: data.MissingValue,
        mean: data.mean,
        stddev: data.stddev,
        maxValue: data.maxValue,
        minValue: data.minValue,
        distinctValuesCount: data.distinctValuesCount,
        quantile25: data.quantile25,
        quantile75: data.quantile75,
        lower_bound: data.lower_bound,
        upper_bound: data.upper_bound,
        //histogram_buckets: data.histogram_buckets,
        //histogram_values: data.histogram_values
    }
}

export function createColumnsFromJson(json) {
    var columns = []
    var data = json
    if (json === undefined) {
        data = prepareData(JSON.parse(testJsonProfiling))
    }

    const obj = data[0]

    for (var prop in obj) {
        if (prop !== 'id' && prop !== "histogram_buckets") {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {

                columns.push(
                    {
                        field: prop,
                        headerClassName: 'super-app-theme--header',
                        headerAlign: 'center',
                        align: getAlign(prop),
                        headerName: getPrintName(prop),
                        description: getDescription(prop),
                        type: getType(prop),
                        minWidth: getMinWidth(prop),

                        editable: false,
                        renderCell: (cellValues) => {
                            return (
                                getRenderCell(cellValues)
                            );
                        }
                    }
                )
            }
        }
    }
    return columns
}

function getAlign(fieldName) {

    switch (fieldName) {
        case 'columnName':
            return 'left'
        case 'columnType':
            return "left"
        default:
            return "right"
    }
}

function getType(fieldName) {
    const stringFields = ['id', 'columnName']
    if (fieldName in stringFields)
        return 'string'

    return 'number'
}

function getPrintName(fieldName) {
    switch (fieldName) {
        case 'columnName':
            return 'Column'
        case 'columnType':
            return "Data Type"
        case 'rowCount':
            return "Row Count"
        case 'nullCount':
            return "Null Count"
        case 'nullPercent':
            return "Null %"
        case 'MissingValue':
            return "Missing Value"
        case 'mean':
            return "Mean"
        case 'stddev':
            return "Standard Deviation"
        case 'maxValue':
            return "Max Value"
        case 'minValue':
            return "Min Value"
        case 'distinctValuesCount':
            return "Distinct Values"
        case 'quantile25':
            return "Quantile 25"
        case 'quantile75':
            return "Quantile 75"
        case 'lower_bound':
            return "Lower Bound"
        case 'upper_bound':
            return "Upper Bound"
        case 'histogram_values':
            return "Histogram Values"
        case 'distinct_values':
            return "Distinct Values"
        default:
            return fieldName
    }
}

function getDescription(fieldName) {
    switch (fieldName) {
        case 'columnName':
            return 'Column Name'
        case 'columnType':
            return "Data Type (string, date,  integer, double,...)"
        case 'rowCount':
            return "Number of rows used for profiling"
        case 'nullCount':
            return "Number of empty or null values"
        case 'nullPercent':
            return "Percent of nulls as nullCount/rowCount"
        case 'MissingValue':
            return "Missing Value found in the column"
        case 'mean':
            return "Mean"
        case 'stddev':
            return "Standard Deviation"
        case 'maxValue':
            return "Max Value"
        case 'minValue':
            return "Min Value"
        case 'distinctValuesCount':
            return "Distinct Values found in the column"
        case 'quantile25':
            return "Quantile 25"
        case 'quantile75':
            return "Quantile 75"
        case 'lower_bound':
            return "Lower Bound, used as lower threshold for anomalous data detection"
        case 'upper_bound':
            return "Upper Bound, used as upper threshold for anomalous data detection"
        case 'histogram_values':
            return "Histogram Values"
        case 'distinct_values':
            return "Distinct Values"
        default:
            return fieldName
    }
}

function getMinWidth(fieldName) {
    switch (fieldName) {
        case 'stddev':
            return 150
        case 'distinctValuesCount':
            return 130
        case 'lower_bound':
            return 130
        case 'upper_bound':
            return 130
            case 'histogram_values':
                return 180
        default:
            return 120
    }
}

function getRenderCell(cellValues) {
    switch (cellValues.field) {
        case 'columnName':
            return getRenderImportantCell(cellValues)
        case 'columnType':
            return getRenderTypeCell(cellValues)
        case 'rowCount':
            return getRenderBaseCell(cellValues)
        case 'nullCount':
            return getRenderBaseCell(cellValues)
        case 'nullPercent':
            return getRenderSmallBar(cellValues)
        case 'MissingValue':    
            return getRenderBaseCellFloat(cellValues)
        case 'Mean':    
            return getRenderBaseCellFloat(cellValues)
        case 'stddev':
            return getRenderBaseCellFloat(cellValues)
        case 'maxValue':
            return getRenderBaseCellFloat(cellValues)
        case 'minValue':
            return getRenderBaseCellFloat(cellValues)
        case 'distinctValuesCount':
            return getRenderBaseCell(cellValues)
        case 'quantile25':
            return getRenderBaseCellFloat(cellValues)
        case 'quantile75':
            return getRenderBaseCellFloat(cellValues)
        case 'lower_bound':
            return getRenderBaseCellFloat(cellValues)
        case 'upper_bound':
            return getRenderBaseCellFloat(cellValues)
        case 'histogram_values':
            return getRenderHistogram(cellValues)
        case 'distinct_values':
            return getRenderDistinct(cellValues)
        default:
            return getRenderBaseCellFloat(cellValues)
    }
}

function getRenderBaseCell(cellValues) {
    return (
        <Typography variant='caption' style={{fontFamily: commonStyles.fontFamily, fontSize: commonStyles.tableCellFontSize}}>
            {cellValues.row[cellValues.field]}
        </Typography>
    )
}

function getRenderBaseCellFloat(cellValues) {
    var value = cellValues.row[cellValues.field]
    try{
        value = Math.round(parseFloat(cellValues.row[cellValues.field])*100)/100
    }
    catch(error){
        console.error(error)
    }
    if(isNaN(value)) {
        value = "NaN"
    }
    return (
        <Typography variant='caption' style={{fontFamily: commonStyles.fontFamily, fontSize: commonStyles.tableCellFontSize}}>
            {value}
        </Typography>
    )
}

function getRenderImportantCell(cellValues) {

    return (
        <Typography variant='subtitle2'>
            {cellValues.row[cellValues.field]}
        </Typography>
    )
}

function getRenderSmallBar(cellValues) {
    const data = parseInt(cellValues.row[cellValues.field])
    let r = (Math.random() + 1).toString(36).substring(7);
    return (
        <div style={{ height: '60px', width:'100px' }}>
            <SmallBar data={data} id={"smallBar-" + r} inverted={false}/>
        </div>

    )


}

function getRenderHistogram(cellValues) {
    //const dataX = cellValues.row['histogram_buckets']
    const dataY = cellValues.row['histogram_values']


    let r = (Math.random() + 1).toString(36).substring(7);

    if (dataY === undefined || dataY === null || dataY.length === 0) {
        return (
            <div>

            </div>
        )
    }
    const data = []

    for (var i = 0; i < dataY.length; i++) {
        data.push({
            bucket: "Bucket "+ i  + "-" + i+1,
            value: dataY[i]
        })
    }
    return (
        <div style={{ height: '100px' }}>
            <HistogramBar data={data} id={"histogramBar-" + r} />
        </div>

    )

}

function getRenderDistinct(cellValues) {
    return cellValues.row["distinct_values"] ? cellValues.row["distinct_values"] : "-"
}

function getRenderTypeCell(cellValues) {

    if (cellValues.row[cellValues.field] === 'string') {
        return (
            <div style={{
                textAlign: "center",
                display: "flex",
                position: "relative"
            }}>
                <TextFormatIcon color="primary" fontSize="small" />
                <Typography variant='caption'>
                    {cellValues.row[cellValues.field]}
                </Typography>
            </div>
        )
    }
    else if (cellValues.row[cellValues.field] === 'date') {
        return (
            <div style={{
                textAlign: "center",
                display: "flex",
                position: "relative"
            }}>
                <DateRangeIcon color="primary" fontSize="small" />
                <Typography variant='caption'>
                    {cellValues.row[cellValues.field]}
                </Typography>
            </div>
        )
    }
    else {
        return (
            <div style={{
                textAlign: "center",
                display: "flex",
                position: "relative"
            }}>
                <FormatListNumberedIcon color="primary" fontSize="small" />
                <Typography variant='caption'>
                    {cellValues.row[cellValues.field]}
                </Typography>
            </div>
        )
    }

}
