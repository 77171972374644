import axios from 'axios';
import {API_MS_URL,ACCESS_TOKEN,ACCESS_TOKEN_AZURE_USER} from './../../../Constants'
import * as actions from 'actions'

import {
    FETCH_INFO,
    FETCH_INFO_LONG,
    FETCH_ERROR
   } from './../../../actions/types'
   import { AuthManager } from "@azure/ms-rest-browserauth";

export default axios.create(
    {
        baseURL: API_MS_URL
    }
);


export const axiosHeader =() =>{
    const headersA = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Cache-Control': 'no-cache'
       
    }
    
    return headersA
}

export const axiosHeaderSendFile =() =>{
    const headersA = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Cache-Control': 'no-cache'
       
    }
    
    return headersA
}


export const axiosHeaderNoAuth =() =>{
    const headersA = {
        'Content-Type': 'application/json',
       
        'Cache-Control': 'no-cache'
       
    }
    
    return headersA
}

export const axiosHeaderAzure =() =>{
    const headersA = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN_AZURE_USER),
        'Cache-Control': 'no-cache'
       
    }
    
    return headersA
}

export const axiosHeaderToken =(accessToken) =>{
    const headersA = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (accessToken),
        
       
    }
    
    return headersA
}



export function dispatchInfo(dispatch, msg, detail){
    var m = {detail: detail,msg: msg, id:Math.floor(Math.random() * 100)}
    
    if(detail===undefined){
     m = {msg: msg, id:Math.floor(Math.random() * 100)}   
    }
    dispatch( {
        type : FETCH_INFO,
        payload: m
    });
   
}

export function dispatchInfoLong(dispatch, msg, detail){
    var m = {detail: detail,msg: msg, id:Math.floor(Math.random() * 100)}
    
    if(detail===undefined){
     m = {msg: msg, id:Math.floor(Math.random() * 100)}   
    }
    dispatch( {
        type : FETCH_INFO_LONG,
        payload: m
    });
   
}

async function callLogout(props, dispatch){
    const appId = await dispatch(actions.getControlValue("AQTIVA_APP_ID"))
    const tenantId =await dispatch(actions.getControlValue("AQTIVA_TENANT_ID"))

    localStorage.clear()
    const authManager = new AuthManager({
        tenantId: tenantId,
        clientId: appId,
        redirectUri: (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
          popUp:true,
      });
      authManager.finalizeLogin().then((result) => {
        if (result.isLoggedIn) {
            authManager.logout()
        } 
      }); 
    if(props && props.history){
        props.history.push('/login')
    }
    

}
export function dispatchErrorSimple(dispatch, error, props){
    dispatch( {
        type : FETCH_ERROR,
        payload:  {msg: error, id:Math.floor(Math.random() * 100)}  
    });
}

export function dispatchError(dispatch, error, props){


    var m = {}
    if(error && error.response && error.response.data){
        dispatch( {
            type : FETCH_ERROR,
            payload: m = {msg: JSON.stringify(error.response.data), id:Math.floor(Math.random() * 100)}  
        });
    }
    else if(error.response && error.response.data && error.response.data.error && error.response.data.error.message){
        if(error.response.data.error.errors && error.response.data.error.errors.length>0){
            let domain = error.response.data.error.errors[0].domain
            let message = `Error in ${domain} element: ${error.response.data.error.message}`
            m = {msg: message, id:Math.floor(Math.random() * 100)}   
            if(m.msg === 'Unauthorized'){
                callLogout(props, dispatch)
            }
            dispatch( {
                type : FETCH_ERROR,
                payload: m
            });
        }
        else{
            m = {msg: error.response.data.error.message, id:Math.floor(Math.random() * 100)}  
            if(m.msg === 'Unauthorized'){
                callLogout(props,dispatch)
            } 
            dispatch( {
                type : FETCH_ERROR,
                payload: m
            });
        }
        
    }

    else if(error.response && error.response.data && error.response.data.error && error.response.data.message){
        m = {msg: error.response.data.message,   id:Math.floor(Math.random() * 100)}  
        if(m.msg === 'Unauthorized'){
            callLogout(props,dispatch)
        } 
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error.response && error.response.data.error){
        m = {msg: error.response.data.error, id:Math.floor(Math.random() * 100)}
        if(m.msg === 'Unauthorized'){
            callLogout(props,dispatch)
        }   
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error.response && error.response.data && error.response.data.message){
        m = {msg: error.response.data.message, id:Math.floor(Math.random() * 100)}
        if(m.msg === 'Unauthorized'){
            callLogout(props,dispatch)
        }
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error && error.message && error.stack){
        m = {msg: error.message, detail:error.stack, id:Math.floor(Math.random() * 100)}  
        if(m.msg === 'Unauthorized'){
            callLogout(props,dispatch)
        } 
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error){
        m = {msg: error, id:Math.floor(Math.random() * 100)}   
        if(m.msg === 'Unauthorized'){
            callLogout(props,dispatch)
        }
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }
    else{
        m = {msg: 'Unexpected error', id:Math.floor(Math.random() * 100)}   
        if(m.msg === 'Unauthorized'){
            callLogout(props,dispatch)
        }
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }
}

