export function checkAllFields(element, existentTags) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    let findSameNameTag = existentTags && existentTags.find(tag => tag.tagName === element.tagName)
    if (!element.tagName) {
        errorsToCheck.tagName = "Please introduce a Tag name."
        errorsToCheck.hasErrors = true
    }
    if (findSameNameTag && !(findSameNameTag.id === element.id)) {
        errorsToCheck.repeatedTagName = "This Tag name already exists."
        errorsToCheck.hasErrors = true
    }
    if (!element.tagType) {
        errorsToCheck.tagType = "Please select a Tag type."
        errorsToCheck.hasErrors = true
    }
    if (!element.taggableElementTypes || !element.taggableElementTypes.length > 0) {
        errorsToCheck.tagType = "Please select which elements should be taggable."
        errorsToCheck.hasErrors = true
    }
    if (element.tagType === "list") {
        if (!element.allowedValues || !element.allowedValues.length > 0) {
            errorsToCheck.allowedValues = "Please introduce at least one allowed value."
            errorsToCheck.hasErrors = true
        }
    }
    return errorsToCheck
}