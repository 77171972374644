import React, { useState, useEffect } from 'react'
import AqtivaDialog from '../../../AqtivaDialog';
import { Typography} from '@material-ui/core'
import { useDispatch } from "react-redux";
import * as actions from '../../../../../actions'
import * as columnHelper from '../utils'



export default function ConfirmDeleteDialog(props) {
    const dispatch = useDispatch()

    const [warningMessage, setWarningMessage] = useState('')
    const [rulesWithIssues, setRulesWithIssues] = useState([])


    useEffect(() => {
        if (props.element && props.element === "datasource") {
            if (props.selectedJoin && props.selectedJoin.columns) {
                let allColumnsUsed = columnHelper.getAllColumnsUsedInRules(props.datasource)
                let rulesWithIssues = columnHelper.checkIfColumnsAreUsed(allColumnsUsed, props.selectedJoin, props.datasource)
                if (rulesWithIssues && rulesWithIssues.length > 0) {
                    setWarningMessage("By deleting this join the next rules won't be able to execute until you fix them:")
                    setRulesWithIssues(rulesWithIssues)
                }
            }
        }
        if (props.element && props.element === "rule") {
            if (props.selectedJoin && props.selectedJoin.columns) {
                let allColumnsUsed = columnHelper.getAllColumnsUsedInSelectedRule(props.datasource, props.rule)
                let rulesWithIssues = columnHelper.checkIfColumnsAreUsedInSelectedRule(allColumnsUsed, props.selectedJoin, props.datasource, props.rule)
                if (rulesWithIssues && rulesWithIssues.length > 0) {
                    setWarningMessage("By deleting this join, this rule won't be able to execute unless you fix the selected columns")
                    setRulesWithIssues(rulesWithIssues)
                }
            }
        }
    }, [props.selectedJoin, props.selectedJoin && props.selectedJoin.columns])// eslint-disable-line react-hooks/exhaustive-deps

    async function arrayRemove(arr, elementToRemove) {

        let newJoins = arr.filter(function (element) {
            return element.id !== elementToRemove.id;
        });


        if (props.element && props.element === "datasource") {
            if (props.selectedJoin && props.selectedJoin.columns) {
                //hacer una llamada update del datasource con el join borrado 
                let newDatasource = JSON.parse(JSON.stringify(props.datasource))
                newDatasource.enrichDataformatObject.joins = newJoins

                await dispatch(actions.editDatasource(props.project._id, props.qualityPoint._id, newDatasource, props.userId))

                let allColumnsUsed = columnHelper.getAllColumnsUsedInRules(props.datasource)
                await columnHelper.checkIfColumnsAreUsedAndUpdateRuleFlagDeleting(allColumnsUsed, elementToRemove, props.project, props.qualityPoint, props.datasource, props.userId, dispatch, actions)

            }
        }


        props.setData(newJoins)
        props.setUpdateCounter(props.updateCounter + 1)
    }



    return (
        <AqtivaDialog
            visible={props.showConfirmDialog}
            dialog={props.showConfirmDialog}
            fullWidth={true}


            maxWidth={'xs'}
            title={"Delete Schedule"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                setRulesWithIssues([])
                props.setShowConfirmDialog(false)
            }}
            confirmText={'Confirm'}
            confirmCallback={async () => {
                arrayRemove(props.data, props.selectedJoin)
                props.setShowConfirmDialog(false)
            }}
        >
            <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                Are you sure you want to delete this join?
            </Typography>
            {rulesWithIssues && rulesWithIssues.length > 0 &&
                <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                    {warningMessage}
                </Typography>}

            {props.element && props.element !== "rule" && rulesWithIssues && rulesWithIssues.length > 0 && rulesWithIssues.map(rule => {
                return (
                    <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                        {rule.name}
                    </Typography>
                )
            })
            }
        </AqtivaDialog>
    )
}