import quality from '../apis/quality';

    import {axiosHeader, dispatchError} from '../components/common/axios/axiosHelper';
//import qualityLocalHost2 from 'apis/qualityLocalHost2';


    
export const getDataRepositoryForUserAndLicense = (userId,licenseId) => async (dispatch,getState) => {
    
    var returnValue = {result:'ko'}
    
    await quality.get(`/data/repository/user/id/${userId}/license/id/${licenseId}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
          });
    
    
   return returnValue
};

export const deleteDataRepository = (_id) => async (dispatch,deleteState) =>{
    var returnValue = {result:'ko'}
    await quality.delete(`/data/repository/id/${_id}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
          });
    
    
   return returnValue
};

export const addDataRepository = (masterDataDTO) => async (dispatch,getState) => {
    
    var returnValue = {result:'ko'}
    
    await quality.post(`/data/repository`,masterDataDTO,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
          });
    
    
   return returnValue
};
export const editDataRepository = (masterDataDTO) => async (dispatch,getState) => {
    
    var returnValue = {result:'ko'}
    
    await quality.put(`/data/repository/id/${masterDataDTO._id}`,masterDataDTO,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
          });
    
    
   return returnValue
};
