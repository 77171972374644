import React, { useEffect, useState } from 'react'
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import ProfilingTable from 'components/common/ProfilingComponent/ProfilingTable';


export default function DataformatProfiling(props) {
  
    const [profiling, setProfiling] = useState(props.dataset && props.dataset.profiling ? props.dataset.profiling.profilingColumns : [])


    useEffect(() => {
        if (props.dataset && props.dataset.profiling && props.dataset.profiling.profilingColumns) {
            let profiling = JSON.parse(JSON.stringify(props.dataset.profiling.profilingColumns))
            for (let i = 0; i < profiling.length; i++) {
                for (let e = 0; e < profiling[i].profilingColumnItems.length; e++) {
                    profiling[i][profiling[i].profilingColumnItems[e].profilingItemName] = profiling[i].profilingColumnItems[e].profilingItemValue
                }
                delete profiling[i]["profilingColumnItems"]

            }

            setProfiling(profiling)
        }
        else{
            setProfiling([])
        }
    }, [props.dataset, props.show])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [profiling])


    return (
        <AqtivaDialog
            visible={props.show}
            dialog={props.show}
            fullWidth={true}
            maxWidth={'xl'}
            title={"Dataformat profiling"}
            editable={false}
            titleIcon={faProjectDiagram}
            confirmText={'Done'}
            confirmCallback={() => { props.onCloseProfilingDialog() }}
            cancelText={'Cancel'}
            cancelCallback={() => { props.onCloseProfilingDialog() }}>
            <div style={{ marginTop: '10px' }}>
                {profiling && <ProfilingTable data={profiling} />}
            </div>

        </AqtivaDialog>
    )
}