import React from "react";
import { useInterval } from '../../common/useInterval'
import {  makeStyles } from '@material-ui/core/styles';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTable from './EnhancedTable';
import LoadingComponent from './LoadingComponent';
import { Paper, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../actions'
import { invertArray } from './utils'
import { useLocation } from "react-router-dom"

import * as recommenderUtils from '../../utils/utilsRecommender'

import {isEmpty} from 'commonFunctions/commonFunctions'

import * as commonStyles from 'style/CommonStyles'


/* This table component is composed by three subcomponents:
-EnhancedTableHead where define columns names and we set display sort values
-EnhancedTableToolbar where the "delete column" and "go back" logic resides
-EnhancedTable, which is the main component where data is displayed and where the "delete rule" and pagination logic resides
*/


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}));

export default function TableRecommendations(props) {
  const dispatch = useDispatch();
  const location = useLocation()

  let ruleRecommendation = useSelector(store => store.fetchJobOutput)

  const { configuration, runId, rules, setRules, recommenderShowNotification } = props;
  //TODO: AVOID WARNINGS
  if(configuration && runId && rules){}
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [histDisplay, setHistDisplay] = React.useState([])
  const [currentDisplay, setCurrentDisplay] = React.useState([])
  const [stopInterval, setStopInterval] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(undefined)

  React.useEffect(() => {
    if (recommenderShowNotification === true) {
      const ruleDTOFromNotification = location.state.rulesDTONotification
      if (!isEmpty(ruleDTOFromNotification) && ruleDTOFromNotification) {

        let res = processResult(ruleDTOFromNotification, true)
        if(res===false){
          setStopInterval(true)
          if(ruleRecommendation && ruleRecommendation.data && ruleRecommendation.data.result){
            setErrorMessage(ruleRecommendation.data.result.toString())
          }
          setErrorMessage('An error occurred while executing the recommendation. Please try again')
          
        }
      }

    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  useInterval( () => {
   
    if (stopInterval===false && recommenderShowNotification !== true && runId !== undefined) {
    dispatch(
      actions.getJobRunOutput(runId, props.environment.databricksInstance, 
        props.environment.databricksToken)).then(ruleRecommendation=>{
      if (!isEmpty(ruleRecommendation) && ruleRecommendation && ruleRecommendation.data && ruleRecommendation.data.result !==null && setStopInterval) {
        
        
        if(ruleRecommendation.data && ruleRecommendation.data.life_cycle_state !== 'PENDING'){
          let res = processResult(ruleRecommendation)
        
          if(res===false){
            setStopInterval(true)
            setErrorMessage(ruleRecommendation.data.result.toString())
  
          }
        }
        

      }
      if (ruleRecommendation && ruleRecommendation.data && ruleRecommendation.data.result !==null
        && ruleRecommendation.data.life_cycle_state !== 'PENDING') {
        setStopInterval(true)
      }

      if (ruleRecommendation && ruleRecommendation.data && ruleRecommendation.data.state ==='FAILED') {
        var errorMessage = ruleRecommendation.data.errorMessage
        if(errorMessage ===""){
          errorMessage = "Uncontrolled error. Please contact your administrator"
        }
        setStopInterval(true)
        setErrorMessage(errorMessage)
      }
    }
      )
      
      
    }
  }
    //How often (ms) we check if rulerecommenderjob is done
    , 3000)

  function processResult(ruleRecommendation, complete) {
    try {
      
      let result = complete && complete ===true ? ruleRecommendation : ruleRecommendation.data.result.toString()
      result = result.replace(new RegExp("reg-exp", "g"), 'reg_exp')
      result = result.replaceAll('inf', '"inf"')
      result = JSON.parse(result)
      result = recommenderUtils.filterRecommenderData(result)
      // set currentDisplay and histdisplay
      let { columns, dataset } = result
      //TODO: AVOID WARNINGS
      if(columns && dataset) {}
      let modifiedData = histDisplay.slice()
      let datasetColumn = {
        column: 'COMPLETE_DATASET',
        rules: [
          { rule: 'schema_rule' }
        ]
      }
      columns.unshift(datasetColumn)

      modifiedData.push(datasetColumn)
      modifiedData.push(columns)
      modifiedData = modifiedData.filter(Boolean);
      setHistDisplay(modifiedData)
      setCurrentDisplay(columns)
      //set  rules array
      if (columns.length > 0) {
        let invertedValue = invertArray(columns)
        setRules(invertedValue)
      }
      return true
    }
    catch (error) {
     console.info("error processing json result")
     console.error(error)
    }
    return false
    
    
  }




  return (
    <div className={classes.root}>
      {errorMessage ===undefined && currentDisplay && currentDisplay.length > 0 && <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} selected={selected} setSelected={setSelected} histDisplay={histDisplay} setHistDisplay={setHistDisplay} currentDisplay={currentDisplay} setCurrentDisplay={setCurrentDisplay} rules={rules} setRules={setRules} />

        <EnhancedTable ruleRecommendation={ruleRecommendation} selected={selected} setSelected={setSelected} histDisplay={histDisplay} setHistDisplay={setHistDisplay} currentDisplay={currentDisplay} setCurrentDisplay={setCurrentDisplay} rules={rules} setRules={setRules} />
      </Paper>}
      {errorMessage ===undefined && currentDisplay && currentDisplay.length < 1 &&
        <LoadingComponent />
      }
      {errorMessage !== undefined && 
        <div>
          <Typography variant='subtitle2'> An error occurred in the recommender execution </Typography>
          <Typography variant='caption' style={{ color: commonStyles.errorColor  }}> {errorMessage}</Typography>
        </div>
      }
    </div >
  );
}


