import React from 'react'
import Grid from '@material-ui/core/Grid'


import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CopyIcon from '@material-ui/icons/FileCopy'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'

import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import ProjectTypography from './../../../common/ProjectTypography'

import * as commonStyles from 'style/CommonStyles'


const getTableRowActions = (languageContext, rowData, editProject, deleteProject, copyProject, anchorEl, setAnchorEl, onCloseShareMenu, onShareForUser, onCopyForUser, license, currentUser) => {


  return (
    <div>

      <Grid>
        <Tooltip title={languageContext.dictionary['actionCopyProject']}>
          <IconButton size='small' onClick={event => onCopyForUser(rowData, event)}>
            <CopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={languageContext.dictionary['actionShareProject']}>
          <IconButton size='small' onClick={event => onShareForUser(rowData, event)} >
            <ShareIcon />
          </IconButton>
        </Tooltip>
        {rowData && rowData.userId === currentUser.id && <Tooltip title={languageContext.dictionary['actionDeleteProject']}>
          <IconButton size='small' onClick={event => deleteProject(rowData)}>

            <DeleteIcon />
          </IconButton>
        </Tooltip>}
        {rowData && rowData.userId === currentUser.id && <Tooltip title={languageContext.dictionary['actionEditProject']}>
          <IconButton size='small' onClick={event => editProject(rowData)}>

            <EditIcon />
          </IconButton>
        </Tooltip>}
      </Grid>
    </div>

  );
}


const getProjectIcon = (rowData) => {


  return (
    <div style={{ display: 'flex', justifyContent: 'left' }}>
      <Tooltip title={(rowData.description && rowData.description !== null) ? rowData.description : ''} >
        <Link href={`/${process.env.REACT_APP_PREFIX}/project/${rowData._id}`} >
          {rowData.name}
        </Link>
      </Tooltip>
    </div>
  );

}

/* CONVERT PROJECT ENVIRONMENT TO VISUALIZATION ENVIRONMENT COMPONENT: TWO TYPES CLOUD OR ON-PREMISE
Azure and AWS are maintenined for legacy. Nowadays those two are converted to Cloud. Cloud type is maintained as environment configuration*/
const getEnvironment = (rowData) => {

  //TODO: legacy, maintain because of previous versions
  if (rowData.environment === "AWS") {
    return (
      <div style={{ display: 'flex', justifyContent: 'left' }}>
         <img alt="" style={{ height: '20px', width: '20px' }}
          src={require('resources/icons/cloud_proc2_black.png')}
        />
        <Typography variant='caption'>Cloud</Typography>
      </div>
    );
  }
  //TODO: legacy, maintain because of previous versions
  else if (rowData.environment === "Azure") {
    return (
      <div style={{ display: 'flex', justifyContent: 'left' }}>
         <img alt="" style={{ height: '20px', width: '20px' }}
          src={require('resources/icons/cloud_proc2_black.png')}
        />
        <Typography variant='caption'>Cloud</Typography>
      </div>
    );
  }
  else if (rowData.environment === "Cloud") {
    return (
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <img alt="" style={{ height: '20px', width: '20px' }}
          src={require('resources/icons/cloud_proc2_black.png')}
        />
        <Typography variant='caption'>Cloud</Typography>
      </div>
    );
  }
  else if (rowData.environment === "On-premise") {
    return (
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <img alt="" style={{ height: '20px', width: '20px', marginRight: '10px', marginLeft: '2px' }}
          src={require('resources/icons/server_proc1_black.png')}
        />
        <Typography variant='caption'>{rowData.environment}</Typography>

      </div>

    );
  }
  else {
    return (
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <FontAwesomeIcon icon={faCloud} style={{ color: commonStyles.mainColor, height: '18px', width: '18px', marginRight: '5px' }} />
        <Typography>
          <ProjectTypography variant={'caption'} value={rowData.name} projectId={rowData._id} />

        </Typography>
      </div>
    );
  }
}

function getUserInfo(project, license) {

  let userId = project.userId
  if (!license || !license.users) {
    return userId
  }
  let user = license.users.filter(data => data.id === userId)
  if (user && user[0]) {
    return user[0].name
  }
  return userId
}

export function getColumns(languageContext,editProject, deleteProject, copyProject, anchorEl, setAnchorEl, license, onCloseShareMenu, onShareForUser, onCopyForUser, currentUser) {
  return ([
    {
      title: 'Name', field: 'name', render: (rowData) => { return getProjectIcon(rowData) },
      cellStyle: {
        fontSize: '13px',
        fontWeight: 'bold',
        borderBottomStyle: 'solid',
        borderBottomColor: commonStyles.mainColor,
        borderBottomWidth: '1px'
      }
    },
    { title: 'Environment', field: 'environment', render: (rowData) => { return getEnvironment(rowData) } },
    { title: 'Owner', field: 'userId', render: (rowData) => { return getUserInfo(rowData, license) } },
    { title: 'Allowed Users', field: 'allowedUsers', render: (rowData) => { return renderAllowedUsers(rowData, license) } },
    {
      title: 'Actions', field: 'no_field_to_match', render: (rowData) => {
        return getTableRowActions(languageContext,rowData, editProject, deleteProject, copyProject, anchorEl, setAnchorEl
          , onCloseShareMenu, onShareForUser, onCopyForUser, license, currentUser)
      }
    }
  ]
  )
}

function renderAllowedUsers(rowData, license) {
  if (rowData.allowedUsers) {
    return (
      <Select
        labelId="select-license-type"
        id="select-license-type"
        value={''}
        style={{ width: "60%" }}
      >
        {rowData.allowedUsers.map(userId =>
          <MenuItem key={userId} value={userId}>{translateUserIdToName(userId, license.users)}</MenuItem>
        )}

      </Select>
    )
  }
}

const translateUserIdToName = (userId, licenseUsers) => {
  if (licenseUsers !== undefined && licenseUsers.length > 0) {
    for (let i = 0; i < licenseUsers.length; i++) {
      if (licenseUsers[i].id === userId) {
        return licenseUsers[i].name
      }
    }
    return userId
  }
  return undefined


}

export const customLocation = {
  body: {
    emptyDataSourceMessage: 'No hay datos que mostrar'
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar'
  },
  pagination: {
    labelRowsSelect: 'registros',
    labelDisplayedRows: '{from}-{to} de {count} regs.',
    firstTooltip: 'Primero',
    previousTooltip: 'Anterior',
    nextTooltip: 'Siguiente',
    lastTooltip: 'Ultimo'
  }

}