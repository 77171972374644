import React from 'react';
import { Typography, AccordionSummary, AccordionDetails, Accordion,  Grid } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EnrichDatasourceTable from './EnrichDatasourceTable'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'


export default function EnrichDatasourceComponent(props) {
    const languageContext = React.useContext(LanguageContext);







    return (

        <Accordion
            style={{ width: '100%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={1}>
                    <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                </Grid>
                <Grid item xs={11}>
                    <Typography variant='subtitle2'>{languageContext.dictionary['enrichedDataset']}</Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: "column", justifyContent: "space-around" }}>
                <div style={{ margin: "10px" }}>
                    {props.datasource === undefined &&
                        <Typography variant='caption' style={{ color: commonStyles.errorColor }}>{languageContext.dictionary['enrichSavePrior']}</Typography>
                    }
                    {props.datasource !== undefined &&
                        <EnrichDatasourceTable element={props.element} joins={props.joins} setJoins={props.setJoins}
                            datasource={props.datasource}
                            dataformat={props.dataformat}
                            userId={props.userId} qualityPoint={props.qualityPoint} project={props.project} rule={props.rule} />
                    }

                </div>
            </AccordionDetails>
        </Accordion >

    )
}