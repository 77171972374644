import React from 'react';

import {
    makeStyles, Typography, Select, Input, MenuItem, Chip,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { faColumns } from '@fortawesome/free-solid-svg-icons'
import * as helper from '../DialogCreateRule/CreateRuleHelper'
import AqtivaDialog from 'components/common/AqtivaDialog'

import * as commonStyles from 'style/CommonStyles'



const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    },

    chipContainerRule: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));


export default function DialogCreateCombinedColumn(props) {


    const classes = useStyles();

    const [selectedColumns, setSelectedColumns] = React.useState([])
    const [source, setSource] = React.useState()
    /*const [hasErrors, setHasErrors] = React.useState()
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
*/
    //TODO CHECK
    const hasErrors = undefined
    const errorTypes = undefined
    const errorMsgs = undefined

    React.useEffect(() => {
        if (props.selectedDataformat && props.source) {
            //TODO: LUKA ESTO ESTÁ BIEN?
            //var source = props.selectedDatasource.dataset.columns.slice();
            setSource(props.source)
        }

    }, [props.selectedDataformat, props.source]);

    React.useEffect(() => {
        if (props.masterData) {
            var source = props.masterData.columns.slice();
            setSource(source)
        }

    }, [props.masterData]);


    function handleSelectColumn(e) {


        for (var i = 0; i < source.length; i++) {
            for (var j = 0; j < e.target.value.length; j++) {
                if (e.target.value[j].position === source[i].position) {
                    source.splice(i, 1);
                    i--;
                }
            }
        }

        setSelectedColumns(e.target.value)
        setSource(source)

    }

    function handleCloseChipCallback(value) {
        var selectedColTemp = selectedColumns.slice()
        for (var i = 0; i < selectedColumns.length; i++) {
            if (value.position === selectedColumns[i].position) {
                selectedColTemp.splice(i, 1);
                break
            }

        }
        var sourceTemp = source;
        sourceTemp.push(value)

        setSelectedColumns(selectedColTemp)
        setSource(sourceTemp)
    }

    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs[idx]
        }
    }


    function createCombinedColumns() {

        var combinedColumn = {
            id: Math.random().toString(36).substr(2, 9),
            cols: selectedColumns.map(x => x.name),
            joinOperation: "concat",

        }
        props.onAddCombinedColumn(combinedColumn)
        props.onClose()
    }

    function closeDialog() {
        props.onClose()
    }


    return (

        <AqtivaDialog visible={props.visible}
            fullWidth={true}
            maxWidth={'md'}
            title={`Create a Combined Column (concatenation)`}
            titleIcon={faPlayCircle}
            confirmText={'Create'}
            cancelText={'Cancel'}
            confirmCallback={createCombinedColumns}
            cancelCallback={closeDialog}
        >

            <div style={commonStyles.adjacentLeft}>
                <FontAwesomeIcon icon={faColumns} style={{ color: commonStyles.mainColor, fontSize: 15 }} />
                <Typography variant='subtitle2' style={{ marginLeft: '2%' }}> Columns Selection</Typography>
            </div>
            <div>
                <Typography variant='body2' style={{ marginTop: '2%' }}> Available Columns</Typography>
                {((props.ruleType !== 'null' && props.ruleType !== 'duplicated') || (props.selectedDataformat && props.selectedDataformat.profiling === null)) &&
                    <Select
                        multiple
                        disabled={helper.isSelectedColumnDisabled(props.ruleType, selectedColumns)}
                        style={{ width: '380px' }}
                        value={selectedColumns}
                        onChange={handleSelectColumn}
                        input={<Input id="select-multiple-checkbox" />}

                    >
                        {source && source.length > 0 &&
                            helper.getSourceForRule(props.ruleType, source).sort(helper.compareColumns).map(e => (
                                <MenuItem key={e.position} value={e} >
                                    <Typography variant='caption'>
                                        {`#${e.position} (${e.type}) ${e.name}`}
                                    </Typography>

                                </MenuItem>
                            ))}
                    </Select>}

                {props.ruleType === 'null' && props.selectedDataformat &&
                    props.selectedDataformat.profiling &&
                    <Select
                        multiple
                        disabled={helper.isSelectedColumnDisabled(props.ruleType, selectedColumns)}
                        style={{ width: '380px' }}
                        value={selectedColumns}
                        onChange={handleSelectColumn}
                        input={<Input id="select-multiple-checkbox" />}

                    >
                        {props.selectedDataformat && props.selectedDataformat.profiling && source &&
                            helper.getNullPercentageForCol(props.selectedDataformat && props.selectedDataformat.profiling && props.selectedDataformat.profiling.profilingColumns, source).sort(helper.compareColumnsbyNullPercentage).map((e, index) => (
                                <MenuItem key={e.columnName} value={e} >
                                    {`#${e.position} (${e.type}) ${e.name}  ${e.nullPercentage}%`}
                                </MenuItem>
                            ))}
                    </Select>}

                {props.ruleType === 'duplicated' && props.selectedDataformat && props.selectedDataformat.profiling &&
                    <Select
                        multiple
                        disabled={helper.isSelectedColumnDisabled(props.ruleType, selectedColumns)}
                        style={{ width: '380px' }}
                        value={selectedColumns}
                        onChange={handleSelectColumn}
                        input={<Input id="select-multiple-checkbox" />}
                    >
                        {props.selectedDatasource && props.selectedDataformat.profiling && source &&
                            helper.getDuplicatedPercentageForCol(props.selectedDataformat && props.selectedDataformat.profiling && props.selectedDataformat.profiling.profilingColumns, source).sort(helper.compareColumnsbyDuplicatedPercentage).map((e, index) => (
                                <MenuItem key={e.columnName} value={e} >
                                    {`#${e.position} (${e.type}) ${e.name}  ${e.duplicatedPercentage}%`}
                                </MenuItem>
                            ))}
                    </Select>}

            </div>

            <div>
                <Typography variant='caption' > {helper.isSelectedColumnDisabledMessage(props.ruleType, source)}</Typography>
            </div>
            <Typography variant='body2' style={{ marginTop: '5%' }}> Selected Columns</Typography>
            <div className={classes.chipContainerRule}>

                {selectedColumns && selectedColumns.map(e => {
                    return (
                        <div>
                            <Chip
                                label={e.name}
                                key={e.position}
                                onDelete={event => handleCloseChipCallback(e)}

                            />
                        </div>
                    )

                }

                )}
            </div>
            <div style={{ marginLeft: '10%' }}>
                {hasErrors && isError('selectedColumns') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedColumns')}</Typography>}
            </div>
        </AqtivaDialog>
    )


}
