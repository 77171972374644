import React, { useState } from 'react';

import QualityDashboardSimulation from './QualityDashboardSimulation';
import QualityDashboardMonitoring from './QualityDashboardMonitoring';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { QualityTheme } from './../../../style/CustomTheme';
import { Grid, Tabs, Tab, Typography, Button, ButtonGroup, Chip, CircularProgress } from '@material-ui/core'
import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartIcon from '@material-ui/icons/TableChart';
import StorageIcon from '@material-ui/icons/Storage';
import FilterMenu from './commons/FilterMenu';
import FilterMenuMonitoring from './commons/FilterMenuMonitoring';

import RssFeedIcon from '@material-ui/icons/RssFeed';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';


import * as mockData from './commons/mockData'

import { useSelector, useDispatch } from 'react-redux'

import * as actions from './../../../actions'

import AppBarTemplate from '../../template/AppBarTemplate'
import * as pathHelper from './../../template/PathHelper'
import QualityRecordPowerTable from './commons/QualityRecordPowerTable'
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { axiosHeader, dispatchError } from '../../../components/common/axios/axiosHelper';
import QualitySimulationDataViewer from './QualitySimulationDataViewer'
import { useHistory } from 'react-router-dom'

import * as commonStyles from 'style/CommonStyles'


let SIMULATION_TAG = 'simulation'
let MONITORING_TAG = 'monitoring'

export default function QualityDashboardPagev2(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const theme = useTheme()
    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const projects = useSelector(store => Object.values(store.projects))

    const [selectedDimValidity, setSelectedDimValidity] = React.useState(false)
    const [selectedDimUnicity, setSelectedDimUnicity] = React.useState(false)
    const [selectedDimIntegrity, setSelectedDimIntegrity] = React.useState(false)
    const [selectedDimVeracity, setSelectedDimVeracity] = React.useState(false)


    const [tabValue, setTabValue] = React.useState(0)
    const [childData, setChildData] = useState("");
    const [rawChildData, setRawChildData] = useState("");

    const [projectsLicense, setProjectsLicense] = React.useState([])

    const [openFilterSimulation, setOpenFilterSimulation] = React.useState(false)
    const [openFilterMonitoring, setOpenFilterMonitoring] = React.useState(false)
    const initialProjectId = (props.match && props.match.params && props.match.params.idProject) ? props.match.params && props.match.params.idProject : undefined
    const initialQualityPointId = (props.match && props.match.params && props.match.params.idQualityPoint) ? props.match.params && props.match.params.idQualityPoint : undefined
    const initialDatasourceId = (props.match && props.match.params && props.match.params.idDatasource) ? props.match.params && props.match.params.idDatasource : undefined
    const initialIdSimulation = (props.match && props.match.params && props.match.params.idSimulation) ? props.match.params.idSimulation : undefined
    const [viewType, setViewType] = React.useState((props.match && props.match.params && props.match.params.idTab) ? props.match.params.idTab : SIMULATION_TAG)
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

    const [pdfUrl, setPdfUrl] = React.useState(undefined)
    const [pdfName, setPdfName] = React.useState(undefined)

    const [pdfUrlSimulation, setPdfUrlSimulation] = React.useState(undefined)
    const [pdfNameSimulation, setPdfNameSimulation] = React.useState(undefined)

    const [fetchingPdf, setFetchingPdf] = React.useState(false)

    const isDirectedUrl = initialProjectId || initialQualityPointId || initialDatasourceId ? true : false

    const [selectedSimulationId, setSelectedSimulationId] = React.useState()

    window.addEventListener('resize', updateWidth);

    function updateWidth() {
        setWindowWidth(window.innerWidth)
    }

    React.useEffect(() => {

        if (props.match && props.match.params && props.match.params.idSimulation) {
            setViewType(props.match.params.idTab)
        }

    }, [props.match])

    React.useEffect(() => {
        if (user && user.id) {
            getProjects()
            if (license && license._id) {
                getLicenseProjects().then(response =>
                    setProjectsLicense(response)
                )

            }
            else {
                if(user!==undefined && user.id!==undefined){
                    dispatch(actions.fetchLicense((user).id))
                }
            }

        }

    }, [user, license._id])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getUser()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    function getProjects() {
        dispatch(actions.fetchProjects(user.id))
    }

    async function getLicenseProjects() {
        var projects = []
        if (license && license._id && license.users && license.users.length > 0) {
            for (var i = 0; i < license.users.length; i++) {
                let licenseUser = license.users[i]
                if (user.id !== licenseUser.id) { //only other users in license
                    var userProjects = await dispatch(actions.fetchProjectsAndReturn(licenseUser.id))
                    projects = projects.concat(userProjects)
                }
            }
        }
        return projects
    }

    function getUser() {
        dispatch(actions.fetchUser(history))
    }


    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }

    function handleViewChangeSimulation(e) {
        setViewType("simulation")
    }
    function handleViewChangeMonitoring(e) {
        setViewType("monitoring")
    }

    function createDimensionArray() {
        var arrayDimension = []
        if (selectedDimIntegrity === true) {
            arrayDimension.push('integrity')
        }
        if (selectedDimUnicity === true) {
            arrayDimension.push('unicity')
        }
        if (selectedDimValidity === true) {
            arrayDimension.push('validity')
        }
        if (selectedDimVeracity === true) {
            arrayDimension.push('veracity')
            arrayDimension.push('format')
        }
        return arrayDimension
    }

    function filterRawData(obj) {
        if (obj === undefined) {
            return undefined
        }
        const clone = JSON.parse(JSON.stringify(obj));
        let selectedDimensions = createDimensionArray()
        var newValue = clone
        if (selectedDimensions.length > 0 && newValue && newValue.length > 0) {
            return newValue.filter(x => selectedDimensions.includes(x.dimension))
        }
        return newValue
    }

    function downloadPdfMonitoring() {
        if (pdfUrl && fetchingPdf === false) {
            setFetchingPdf(true)
            fetch(process.env.REACT_APP_BACK_HOST + pdfUrl, {
                headers: axiosHeader()
            })
                .then(response => {

                    setFetchingPdf(false)
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = pdfName;
                        a.click();
                    });
                })
                .catch(function (error) {

                    dispatchError(dispatch, error)
                    setFetchingPdf(false)

                });;
        }

    }

    function downloadPdfSimulation() {
        if (pdfUrlSimulation && fetchingPdf === false) {
            setFetchingPdf(true)
            fetch(process.env.REACT_APP_BACK_HOST + pdfUrlSimulation, {
                headers: axiosHeader()
            })
                .then(response => {

                    setFetchingPdf(false)
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = pdfNameSimulation;
                        a.click();
                    });
                })
                .catch(function (error) {

                    dispatchError(dispatch, error)
                    setFetchingPdf(false)

                });;
        }

    }

    return (

        <div >
            <AppBarTemplate history={props.history} title={'Dashboard'} arrayPath={pathHelper.getPathArray('Dashboard')} />

            <div style={{ 'padding': '2%' }}>


                <MuiThemeProvider theme={QualityTheme}>
                    <div>
                        <div style={{ ...theme.elementAdjacent, marginTop: '-20px' }}>
                            {(selectedDimIntegrity || selectedDimUnicity || selectedDimValidity || selectedDimVeracity) && <Chip
                                label={'Clear All'}
                                key={'Clear All'}
                                onDelete={event => {
                                    setSelectedDimIntegrity(false)
                                    setSelectedDimUnicity(false)
                                    setSelectedDimValidity(false)
                                    setSelectedDimVeracity(false)
                                }}

                            />}
                            {selectedDimIntegrity && <Chip style={{ marginLeft: '10px' }}
                                label={'Filter Integrity'}
                                key={'Integrity'}
                                onDelete={event => setSelectedDimIntegrity(false)}

                            />}
                            {selectedDimUnicity && <Chip style={{ marginLeft: '10px' }}
                                label={'Filter Unicity'}
                                key={'Unicity'}
                                onDelete={event => setSelectedDimUnicity(false)}

                            />}

                            {selectedDimValidity && <Chip style={{ marginLeft: '10px' }}
                                label={'Filter Validity'}
                                key={'Validity'}
                                onDelete={event => setSelectedDimValidity(false)}

                            />}
                            {selectedDimVeracity && <Chip style={{ marginLeft: '10px' }}
                                label={'Filter Veracity'}
                                key={'Veracity'}
                                onDelete={event => setSelectedDimVeracity(false)}

                            />}
                        </div>
                        <Grid container spacing={0} >
                            <Grid item xs={4} >
                                <Tabs value={tabValue} onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable" scrollButtons="off"
                                    style={{ width: '100%', marginTop: '0px' }}>
                                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                                        <BarChartIcon
                                            style={tabValue === 0 ? { color: commonStyles.mainColor, marginTop: '5px' } : { marginTop: '5px' }} /> <Typography
                                                style={tabValue === 0 ? { color: commonStyles.mainColor, marginTop: '5px' } : { marginTop: '5px' }}>
                                            {`Charts`}</Typography> </div>}
                                    />
                                    {viewType === 'simulation' && <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                                        <TableChartIcon
                                            style={tabValue === 1 ? { color: commonStyles.mainColor, marginTop: '5px' } : { marginTop: '5px' }} /> <Typography
                                                style={tabValue === 1 ? { color: commonStyles.mainColor, marginTop: '5px' } : { marginTop: '5px' }}>
                                            {`Grid `}</Typography> </div>} />}
                                    {viewType === 'simulation' && <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                                        <StorageIcon
                                            style={tabValue === 2 ? { color: commonStyles.mainColor, marginTop: '5px' } : { marginTop: '5px' }} /> <Typography
                                                style={tabValue === 2 ? { color: commonStyles.mainColor, marginTop: '5px' } : { marginTop: '5px' }}>
                                            {`Data `}</Typography> </div>} />}
                                </Tabs>
                            </Grid>
                            <Grid item xs={4}  >
                                {
                                    viewType === SIMULATION_TAG &&
                                    <Typography variant='h6' style={{ marginLeft: '13%' }}>  Simulation View </Typography>
                                }
                                {
                                    viewType === MONITORING_TAG &&
                                    <div>
                                        <Typography variant='h6' style={{ marginLeft: '13%' }}>  Monitor View </Typography>
                                    </div>
                                }
                            </Grid>

                            <Grid item xs={4} >
                                <Grid
                                    style={{ width: '100%' }}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <div style={theme.elementAdjacent}>

                                        <Button
                                            style={theme.buttons.buttonRegularWithRightMargin}
                                            onClick={viewType === 'monitoring' ? downloadPdfMonitoring : downloadPdfSimulation}
                                            startIcon={fetchingPdf === false ?
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    style={{ fontSize: "calc(10px + 0.4vw)" }}
                                                    fontSize="small"
                                                />
                                                :
                                                <CircularProgress
                                                    size={"calc(10px + 0.4vw)"} />
                                            }
                                        ></Button>

                                        <ButtonGroup aria-label="outlined primary button group" style={{ width: isDirectedUrl === true ? '400px' : '300px' }}>
                                            {isDirectedUrl === true &&
                                                <Button
                                                    style={theme.buttons.buttonDisabled}
                                                    startIcon={<FontAwesomeIcon icon={faChartPie} />}
                                                    onClick={event => props.history.goBack()}>Governance</Button>
                                            }
                                            <Button
                                                style={viewType === SIMULATION_TAG ? theme.buttons.buttonGlow : theme.buttons.buttonDisabled}
                                                startIcon={<PlayCircleOutlineIcon />}
                                                onClick={handleViewChangeSimulation}>Simulation</Button>
                                            <Button
                                                style={viewType === SIMULATION_TAG ? theme.buttons.buttonDisabled : theme.buttons.buttonGlow}
                                                startIcon={<RssFeedIcon />}
                                                onClick={handleViewChangeMonitoring}>Monitoring</Button>

                                        </ButtonGroup>
                                        {viewType === 'simulation' &&
                                            <FilterMenu data={mockData.MongoDataExample}
                                                projects={projects && projects.length > 0 ? projects.sort(function (a, b) {
                                                    var dateA = new Date(a.creationDate), dateB = new Date(b.creationDate);
                                                    return dateB - dateA;
                                                }) : []}
                                                viewtype={viewType}
                                                style={{ width: '100px' }}
                                                passChildData={setChildData}
                                                passRawChildData={setRawChildData}
                                                setOpenFilter={setOpenFilterSimulation}
                                                initialIdSimulation={initialIdSimulation}
                                                setPdfUrl={setPdfUrlSimulation}
                                                setSelectedSimulationId={setSelectedSimulationId}
                                                setPdfName={setPdfNameSimulation}></FilterMenu>
                                        }
                                        {viewType === 'monitoring' &&
                                            <FilterMenuMonitoring data={mockData.MongoDataExample}
                                                projects={projects && projects.length > 0 ? projects.sort(function (a, b) {
                                                    var dateA = new Date(a.creationDate), dateB = new Date(b.creationDate);
                                                    return dateA - dateB;
                                                }) : []}
                                                projectsLicense={projectsLicense}
                                                viewtype={viewType}
                                                style={{ width: '100px' }}
                                                passChildData={setChildData}
                                                setOpenFilter={setOpenFilterMonitoring}
                                                passRawChildData={setRawChildData}
                                                initialIdSimulation={initialIdSimulation}
                                                initialProjectId={initialProjectId}
                                                initialQualityPointId={initialQualityPointId}
                                                initialDatasourceId={initialDatasourceId}
                                                setPdfUrl={setPdfUrl}
                                                setPdfName={setPdfName}
                                            ></FilterMenuMonitoring>
                                        }

                                    </div>
                                </Grid>
                            </Grid>


                        </Grid>
                        {
                            tabValue === 2 && viewType === 'simulation' &&
                            <QualitySimulationDataViewer simulationId={(selectedSimulationId === undefined || selectedSimulationId === null) ? initialIdSimulation : selectedSimulationId} />
                        }
                        {
                            tabValue === 0 &&
                            <div style={{ 'padding': '2%', marginTop: '-20px' }}>
                                {
                                    viewType === 'simulation' &&
                                    <QualityDashboardSimulation projects={projects} simulationData={childData}
                                        rawData={rawChildData}
                                        selectedDimValidity={selectedDimValidity}
                                        setSelectedDimValidity={setSelectedDimValidity}
                                        selectedDimUnicity={selectedDimUnicity}
                                        setSelectedDimUnicity={setSelectedDimUnicity}
                                        selectedDimIntegrity={selectedDimIntegrity}
                                        setSelectedDimIntegrity={setSelectedDimIntegrity}
                                        selectedDimVeracity={selectedDimVeracity}
                                        setSelectedDimVeracity={setSelectedDimVeracity}
                                        openFilter={openFilterSimulation}
                                        innerWidth={windowWidth}
                                    />
                                }
                                {
                                    viewType === 'monitoring' &&
                                    <div>
                                        <QualityDashboardMonitoring projects={projects} simulationData={childData}
                                            selectedDimValidity={selectedDimValidity}
                                            setSelectedDimValidity={setSelectedDimValidity}
                                            selectedDimUnicity={selectedDimUnicity}
                                            setSelectedDimUnicity={setSelectedDimUnicity}
                                            selectedDimIntegrity={selectedDimIntegrity}
                                            setSelectedDimIntegrity={setSelectedDimIntegrity}
                                            selectedDimVeracity={selectedDimVeracity}
                                            setSelectedDimVeracity={setSelectedDimVeracity}
                                            openFilter={openFilterMonitoring}
                                            innerWidth={windowWidth}
                                            pdfUrl={pdfUrl}
                                        />

                                    </div>
                                }

                            </div>
                        }

                        {
                            tabValue === 1 &&
                            <div style={{ 'padding': '2%', marginTop: '-20px' }}>
                                {
                                    viewType === 'simulation' &&
                                    <QualityRecordPowerTable data={filterRawData(rawChildData)}
                                        width={'100%'}></QualityRecordPowerTable>
                                }
                                {
                                    viewType === 'monitoring' &&
                                    <div>
                                        QualityGridMonitoring
                                    </div>
                                }

                            </div>
                        }


                    </div>
                </MuiThemeProvider>
            </div>
        </div>

    );
}