
import * as configurationHelper from '../components/common/configurationHelper'

export function calculateAllowedEnvironments(qualityConfiguration, user, setEnvironments, setSelectedEnvironment, dispatch, dispatchError){

    if (qualityConfiguration &&
        qualityConfiguration.environmentConfigurationList) {
        let permitedEnvironments = qualityConfiguration && qualityConfiguration.environmentConfigurationList && configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)
        if (permitedEnvironments && permitedEnvironments.length > 0) {
            setEnvironments(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id).map(x => x.environmentName))
            const environmentPreferedSim = configurationHelper.filterAllowedEnvironments(
                qualityConfiguration.environmentConfigurationList, user.id).filter(x => x.prefered4Simulation === true)
            if (environmentPreferedSim && environmentPreferedSim.length > 0) {
                setSelectedEnvironment(environmentPreferedSim[0].environmentName)
            }
            else {
                setSelectedEnvironment(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)[0] ?
                    configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)[0].environmentName : undefined)
            }
        }
        else {
            dispatchError(dispatch, "No environment was found for your user, please contact your administrator.")
        }

    }
}