import React from 'react';
import {  TextField, Grid, Typography } from '@material-ui/core';

import { Button, Checkbox, FormControlLabel } from '@material-ui/core';


import { getDataFromProperties, getDataFromPropertiesBooleanValue } from './SpecificRuleHelper'


import * as commonStyles from 'style/CommonStyles'
import { ICommonRule, IColumn } from 'interfaces/RuleInterfaces';

interface FormRegularExpProps {
    commonRule: ICommonRule,
    setSpecific: (value: any)=>any,
    editableRule: any,
    specific: any,
    source: IColumn [],

}

export default function FormRegularExp(props: FormRegularExpProps) {

    //const classes = makeStyles(styles(QualityTheme));

    const [expTextField, setExpTextField] = React.useState(getDataFromProperties(props.editableRule, props.specific, 'expRule', ''))
    const [testRegExp, setTestRegExp] = React.useState('')
    const [resultRegExpTest, setResultRegExpTest] = React.useState<string>('')
    const [noMatching, setNoMatching] = React.useState(false)
    const [negateExpression, setNegateExpression] = React.useState(getDataFromPropertiesBooleanValue(props.editableRule, props.specific, 'negateExpression', false))
    const [nullAccepted, setNullAccepted] = React.useState(getDataFromProperties(props.editableRule, props.specific, 'nullAccepted', true))

    function createSpecific() {
        //creamos el objeto de regla especifica
        const specific = {
            subtype: 'CELL_EXP',
            expRule: expTextField,
            negateExpression: negateExpression,
            nullAccepted: nullAccepted
        }
        props.setSpecific(specific)

    }
    React.useEffect(() => {
        createSpecific()
    }, [expTextField, negateExpression, nullAccepted]);// eslint-disable-line react-hooks/exhaustive-deps



    function testRegularExpressionCallback() {
        try {
            const res = testRegExp.match(new RegExp(expTextField))
            
            if (res === null) {
                setNoMatching(true)
            }
            else {
                setResultRegExpTest(res[0])
                setNoMatching(false)
            }
        }
        catch (error) {
            setNoMatching(true)
        }


    }

    return (
        <div style={{ marginTop: '5px' }}>
            <div style={{ width: '50%' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!negateExpression}
                            onClick={event => setNegateExpression(!negateExpression)}
                            value="negateExpression"
                            color="primary"
                        />
                    }
                    label="Detect data not matching regex"
                />
            </div>
            <div style={{ width: '50%' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={nullAccepted}
                            onChange={event => setNullAccepted(event.target.checked)}
                            value="nullValueCheck"
                            color="primary"
                        />
                    }
                    label="Null values are accepted as correct"
                />
            </div>
            <div style={{ width: '50%' }}>
                <TextField
                    fullWidth={true}
                    id="expTextField"
                    label="Regex Definition"
                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                    //className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={expTextField}
                    onChange={event => { setExpTextField(event.target.value); setResultRegExpTest(''); createSpecific() }}
                />
            </div>

            <div style={{ width: '100%' }}>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth={true}
                            id="testRegExpId"
                            label="Test String to Test Reg. Expression"
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            //className={classNames(classes.textField, classes.dense)}
                            margin="dense"
                            value={testRegExp}
                            onChange={event => { setTestRegExp(event.target.value); setResultRegExpTest('') }}
                        />

                    </Grid>
                    <Grid item xs={2} style={{ marginTop: '2%' }}>
                        <Button variant='outlined' style={{ color: commonStyles.mainColor, fontWeight: 'bold', marginBottom: '5%' }}
                            onClick={
                                testRegularExpressionCallback
                            }>
                            <i className="icon plus"></i>
                            Test Reg. Exp
                        </Button>
                    </Grid>
                    {resultRegExpTest && resultRegExpTest !== '' &&
                        <Grid item xs={5} style={{ marginTop: '3%' }}>
                            <Typography variant='body1'>
                                {resultRegExpTest}
                            </Typography>
                        </Grid>
                    }
                    {noMatching === true &&
                        <Grid item xs={5} style={{ marginTop: '3%' }}>
                            <Typography variant='body1'>
                                No matching found
                            </Typography>
                        </Grid>
                    }

                </Grid>
            </div>
        </div>
    )
}
