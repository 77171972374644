import React from 'react';
import {
    PieChart, Pie, ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faBalanceScale, faCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { faSearchengin } from '@fortawesome/free-brands-svg-icons'
import { Typography } from '@material-ui/core';
import * as colorHelper from './colorHelper'

export default function DimensionKPI(props) {
    const [percentValue, setPercentValue] = React.useState(props.percentValue !== undefined ? props.percentValue : -1)

    React.useEffect(() => {
        setPercentValue(props.percentValue !== undefined ? props.percentValue : -1)
    }, [props]);

    const startAngle = 240
    const data = [
        { name: 'Group A', value: 100 }]



    const scoreAngle = startAngle - 300 * ((percentValue !== '-1' ? percentValue : 0) / 100)

    const innerRadius = props.tiny === true ? (props.smallWindowFlag === true ? 15 : 20) : (props.smallWindowFlag === true ? 20 : 32)
    const outerRadius = props.tiny === true ? (props.smallWindowFlag === true ? 20 : 30) : (props.smallWindowFlag === true ? 30 : 45)
    const iconFontSize = props.tiny === true ? (props.smallWindowFlag === true ? 15 : 25) : (props.smallWindowFlag === true ? 25 : 35)
    const bigTitle = props.tiny === true ? (props.smallWindowFlag === true ? 'subtitle1' : 'subtitle2') : (props.smallWindowFlag === true ? 'subtitle2' : 'h5')
    const smallTitle = props.tiny === true ? (props.smallWindowFlag === true ? 'caption' : 'subtitle1') : (props.smallWindowFlag === true ? 'caption' : 'subtitle1')
    return (
        <div style={{
            width: '100%', display: 'flex', height: props.height,
            justifyContent: 'left',
        }}>
            <div style={{ width: '40%', paddinLeft: '5%', height: props.height, position: 'relative' }}>
                <ResponsiveContainer width='100%'>
                    <PieChart >
                        <Pie dataKey="value" isAnimationActive={false} data={data} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle}
                            endAngle={-60} fill={percentValue === 0 ? '#F3410DAA' : '#A2A2A2AA'}
                        />

                        <Pie dataKey="value" data={data} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle}
                            endAngle={scoreAngle}
                            stroke={colorHelper.getColorForPercentage(percentValue)} fill={colorHelper.getColorForPercentage(percentValue)} />

                    </PieChart>

                </ResponsiveContainer>
                <FontAwesomeIcon icon={props.dimension === 'Validity' ? faCheck : props.dimension === 'Veracity' ? faSearchengin :
                    props.dimension === 'Unicity' ? faCrosshairs : faBalanceScale} style={{
                        color: colorHelper.getColorForPercentage(percentValue), fontSize: iconFontSize,
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        webkitTransform: 'translate(-50%, -50%)'
                    }} />



            </div>
            <div style={{ width: '60%' }}>
                <Typography variant={bigTitle} style={{ margin: (props.tiny === true ? '5ox' : '15px') }}> {props.dimension} </Typography>
                <Typography variant={smallTitle} style={{ margin: (props.tiny === true ? '5ox' : '15px') }}> {percentValue === '-1' ? 'No Data Found' : `${percentValue}%`}</Typography>
            </div>

        </div>)
}