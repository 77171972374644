
import React from 'react';

import { FormControl, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles';

import { ILogicColumnCondition, ILogicColumnOperator, ILogicConditionParameters, ILogicSqlExpression } from 'interfaces/LogicCondition';

import NativeSelect from '@material-ui/core/NativeSelect';

import ColumnConditionCreation from './ColumnConditionCreation'

import AqtivaDialog from 'components/common/AqtivaDialog'
import { IColumn } from '../../../../interfaces/RuleInterfaces';


type IProps = {
  open: boolean,
  handleClose: (value: any) => (any),
  columns: IColumn[],
  sqlExpressions: ILogicSqlExpression[] | undefined,
  addOperatorJoin: (value: any) => (any),
  addOperatorCondition: (value: any) => (any),
  addSqlExpression: (value: any) => (any),
  hasColumnConditions: boolean
  hasSqlExpressions: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default function AddOperatorDialog(props: IProps) {

  const classes = useStyles();
  const [operatorType, setOperatorType] = React.useState("columnOperator") //allowed values columnOperator or columnCondition
  const [conditionParameters, setConditionParameters] = React.useState<ILogicConditionParameters>()
  const [columnOperator, setColumnOperator] = React.useState<ILogicColumnOperator>({
    type: 'columnOperator',
    operator: 'and'
  } as ILogicColumnOperator)
  const [columnCondition, setColumnCondition] = React.useState<ILogicColumnCondition>({} as ILogicColumnCondition)
  const [sqlExpresion, setSqlExpression] = React.useState<ILogicSqlExpression>({
    selectedJoin: 'and',
    isSqlExpression: false,
    sqlExpression: ''
  } as ILogicSqlExpression)
  const theme = useTheme();


  React.useEffect(() => {
    if (props.columns) {
      setColumnCondition({
        type: 'columnCondition',
        columnName: props.columns && props.columns[0] ? props.columns[0].name : '',
        columnType: props.columns && props.columns[0] ? props.columns[0].type : ''
      } as ILogicColumnCondition)
    }

  }, [props.columns])


  React.useEffect(() => {
    // if we do not have column conditions, only option is column conditions
    if (props.hasColumnConditions === false) {
      setOperatorType("columnCondition")
    }
    if (props.hasSqlExpressions) {
      setOperatorType('sqlExpresion')
    }

  }, [props.hasColumnConditions, props.hasSqlExpressions])


  function getOperation() {
    if (operatorType === 'columnOperator') {
      console.log('columnOperator', columnOperator)
      props.addOperatorJoin(columnOperator)
    }
    else if (operatorType === 'sqlExpresion') {
      console.log('sqlExpresion', sqlExpresion)
      props.addSqlExpression(sqlExpresion)
    }
    else {
      console.log('columnCondition', columnCondition)
      props.addOperatorCondition(columnCondition)
    }

  }

  function confirmOperation() {
    getOperation()
    props.handleClose(true)
  }

  function setColumnOperatorField(value: any, field: string) {
    var newColumnOperator = JSON.parse(JSON.stringify(columnOperator))
    newColumnOperator[field] = value
    setColumnOperator(newColumnOperator)
  }

  function setSqlExpressionField(value: any, field: string) {
    var newExpression = JSON.parse(JSON.stringify(sqlExpresion))
    newExpression[field] = value
    newExpression.isSqlExpression = true
    setSqlExpression(newExpression)
  }

  function setColumnConditionColumn(value: string) {
    var newColumnCondition = JSON.parse(JSON.stringify(columnCondition))
    newColumnCondition.columnName = value

    if (props.columns) {
      try {
        const column = props.columns.filter(col => col.name === value)[0]
        newColumnCondition.columnType = column.type
      }
      catch (exception) {
        //TODO:
      }

    }
    setColumnCondition(newColumnCondition)
  }

  function createConditionParams(conditionParams: ILogicConditionParameters) {
    setConditionParameters(conditionParams)
    var columnConditionNew = JSON.parse(JSON.stringify(columnCondition))
    columnConditionNew.params = conditionParams
    setColumnCondition(columnConditionNew)
  }

  return (


    <AqtivaDialog visible={props.open}
      title={`Add Operator`}
      maxWidth='lg'
      fullWidth={true}
      titleIcon={faKey}
      cancelText={'Close'}
      showConfirmButton={true}
      confirmCallback={confirmOperation}
      cancelCallback={props.handleClose}
    >


      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-helper">Operator Type</InputLabel>
        <NativeSelect
          value={operatorType}
          onChange={event => setOperatorType(event.target.value)}
          inputProps={{
            name: 'age',
            id: 'age-native-helper',
          }}
        >

          {props.hasColumnConditions === true && <option value={'columnOperator'}>Column Operator</option>}
          <option value={'columnCondition'}>Column Condition</option>
          <option value={'sqlExpresion'}>SQL Expression</option>
        </NativeSelect>
      </FormControl>

      {operatorType === 'columnOperator' &&
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-helper">Select Join Operator Type</InputLabel>
          <NativeSelect
            value={columnOperator && columnOperator.operator ? columnOperator.operator : 'and'}
            onChange={event => setColumnOperatorField(event.target.value, 'operator')}
            inputProps={{
              name: 'age',
              id: 'age-native-helper',
            }}
          >

            <option value={'and'}>and</option>
            <option value={'or'}>or</option>
            <option value={'xor'}>xor</option>
            <option value={'xand'}>xand</option>
          </NativeSelect>
        </FormControl>
      }

      {operatorType === 'columnCondition' &&
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Select a Column</InputLabel>
            <NativeSelect
              value={columnCondition && columnCondition.columnName}
              onChange={event => setColumnConditionColumn(event.target.value)}
              inputProps={{
                name: 'age',
                id: 'age-native-helper',
              }}
            >
              {props.columns &&
                props.columns.map(col => {
                  return <option value={col.name}>{`${col.name} (${col.type})`}</option>
                })}

            </NativeSelect>
          </FormControl>

          <ColumnConditionCreation type={columnCondition.columnType} setConditionParams={createConditionParams} />
        </div>

      }
      {operatorType === 'sqlExpresion' &&
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Join</InputLabel>
            <NativeSelect
              value={sqlExpresion && sqlExpresion.selectedJoin ? sqlExpresion.selectedJoin : 'and'}
              onChange={event => setSqlExpressionField(event.target.value, 'selectedJoin')}
              inputProps={{
                name: 'age',
                id: 'age-native-helper',
              }}
            >

              <option value={'and'}>and</option>
              <option value={'or'}>or</option>
              <option value={'xor'}>xor</option>
              <option value={'xand'}>xand</option>
            </NativeSelect>
          </FormControl>
          <FormControl style={{ width: '70%', marginTop: '3.5px' }}>
            <TextField type='string'
              value={sqlExpresion && sqlExpresion.sqlExpression}
              style={{ width: '70%' }}
              onChange={event => setSqlExpressionField(event.target.value, 'sqlExpression')}
              label={<Typography style={{ fontSize: 13 }}>SQL Expresion</Typography>}
              margin="dense"
            />
          </FormControl>
        </div>
      }
    </AqtivaDialog>


  )

}