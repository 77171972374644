import quality from '../apis/quality';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
//import qualityLocalHost from '../apis/qualityLocalHost';

//import {axiosHeader} from './../util/APIUtils'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';


export const listDatasets = (credentials) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post('/databricks/list/bigquery/datasets', credentials, {
      headers: axiosHeader()
    }).then(function (response) {
  
      returnValue = response.data
  
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
  
        returnValue = { result: 'ko', error: error }
      });
  
  
    return returnValue
  }


  export const listTables = (credentials, datasetId) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/databricks/list/bigquery/tables/${datasetId}`, credentials, {
      headers: axiosHeader()
    }).then(function (response) {
  
      returnValue = response.data
  
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
  
        returnValue = { result: 'ko', error: error }
      });
  
  
    return returnValue
  }

  export const createPreviewDataJobBigQuery = (environment, userId, credentials, nSamples, flatten) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/databricks/bigquery/preview/${userId}/${environment}`, credentials, {
      headers: axiosHeader()
    }).then(function (response) {
  
      returnValue = response.data
      dispatchInfo(dispatch, `Data preview job running`)
  
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
  
        returnValue = { result: 'ko', error: error }
      });
  
  
    return returnValue
  }


  export const createProfilingDataJobBigQuery = (environment, userId, credentials, nSamples, flatten) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/databricks/bigquery/profiling/${userId}/${environment}`, credentials, {
      headers: axiosHeader()
    }).then(function (response) {
  
      returnValue = response.data
      dispatchInfo(dispatch, `Data preview job running`)
  
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
  
        returnValue = { result: 'ko', error: error }
      });
  
  
    return returnValue
  }