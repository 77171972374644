import React from 'react'; 
import { FormControl,makeStyles, InputLabel, NativeSelect } from '@material-ui/core';

import * as ruleUtils from 'components/rule/utilsRuleCreation'

interface RuleTypeSelectorProps {
    ruleType: string,
    setRuleType: ( type: string) => (any)

}




const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    },

    chipContainerRule: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function RuleTypeSelector({ruleType, setRuleType}:  RuleTypeSelectorProps) {

    const classes = useStyles();

    

   
      

    return(
        <div style={{ marginLeft: '10%' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="typeWarnTh-selection-helper">Rule Type</InputLabel>
                                <NativeSelect
                                    style={{
                                        width: '80%',
                                        fontSize: 14,

                                    }}
                                    value={ruleType}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>)  => setRuleType(event.target.value)}
                                >
                                    {ruleUtils.ruleTypesBatch.map(
                                        (event) => (<option key={event.value} value={event.value}>
                                            {event.label}
                                        </option>))
                                    }
                                </NativeSelect>
                            </FormControl>
                           
                        </div>
    )
}
