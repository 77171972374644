import React from 'react';
import LoginPage from 'components/login/LoginPage';
import AzurePage from 'components/login/AzurePage';


import { Route, Switch } from "react-router-dom";
import PrivateRoute from 'components/common/PrivateRoute';

import OAuth2RedirectHandler from 'components/login/OAuth2RedirectHandler'
import { ACCESS_TOKEN } from 'Constants';
import LicensePage from 'components/admin/LicensePage/LicensePage';
import QualityManagementProjectPage from 'pages/HomePage'
import QualityDetailProjectPage from 'pages/QualityProjectPage';
import QualityConfigurationPage from 'components/quality/QualityConfigurationPage';
import QualitySimulationRepositoryPage from 'components/quality/QualitySimulationRepositoryPage';
import QualityShedulePage from 'pages/QualitySchedulePage';
import QualityDatasetRepositoryPage from 'pages/QualityDataPage';
import QualityDatasetProfilingPage from 'pages/QualityDataProfilingPage';
import QualityDashboardPagev2 from 'components/quality/QualityDashboardPagev2'
import SuperAdminLicenseManagementPage from 'pages/SuperAdminPage';

import Snackbar from '@material-ui/core/Snackbar';

import { useSelector } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { QualityTheme } from 'style/CustomTheme'

import * as Sentry from '@sentry/browser';

import { useTracking } from 'useTracking'
import DataRepository from 'components/data/DataRepositoryShare';
import DataRepositoryRefactor from 'components/data/DataRepositoryRefactorShare';
import QualityKpis from 'components/quality/QualityKpis';
import QualityKpisDetail from 'components/quality/QualityKpis/QualityKPIsDetail';

import QualityConsumption from 'components/consumption'
import QualityDataformatDetailPage from 'pages/QualityDataformatDetailPage'
import * as snackbarContentHelper from 'components/common/SnackBarContent/snackbarContentHelper'

import Slide from '@material-ui/core/Slide';
import AqtivaDashboard from 'components/AqtivaDashboard';
import AqtivaDashboardDrillDownPage from 'components/AqtivaDashboardDrillDownPage';

import AqtivaDashboardNewPage from 'components/AqtivaDashboardNewPage';
import DatasetManagementPage from 'pages/DatasetManagementPage'
import {AqtivaErrorBoundary} from 'errors/AqtivaErrorBoundary'

import { BrowserTracing } from "@sentry/tracing";
import AqtivaQualityIntegration from 'pages/AqtivaQualityIntegration';

export default function App(props) {

  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID)



  const [openInfoSnack, setOpenInfoSnack] = React.useState(false);
  const [openInfoLongSnack, setOpenInfoLongSnack] = React.useState(false);

  const [openErrorSnack, setOpenErrorSnack] = React.useState(false);

  const token = localStorage.getItem(ACCESS_TOKEN)


  const [authenticated, setAuthenticated] = React.useState(token ? true : false)

  const errorMessage = useSelector(store => store.errorMessage)
  const infoMessage = useSelector(store => store.infoMessage)
  const infoMessageLong = useSelector(store => store.infoLongMessage)


  /*const [state, setState] = React.useState({
    open: false,
    Transition: Slide,
  });*/
  const state = {
    open: false,
    Transition: Slide,
  }

  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS, 
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_RELEASE,
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,});

  React.useEffect(() => {

    if (errorMessage !== undefined && errorMessage.msg !== undefined) {
      if ((errorMessage.detail !== undefined
        && errorMessage.detail.includes("static") === false)) {
      }
      else {
        setOpenErrorSnack(true)
      }

    }

  }, [errorMessage]);


  React.useEffect(() => {
    if (infoMessage !== undefined && infoMessage.msg !== undefined)
      setOpenInfoSnack(true)
  }, [infoMessage]);

  React.useEffect(() => {
    if (infoMessageLong !== undefined && infoMessageLong.msg !== undefined)
      setOpenInfoLongSnack(true)
  }, [infoMessageLong]);


  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorSnack(false);
  };
  const handleCloseInfo = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInfoSnack(false);
  };

  const handleCloseInfoLong = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInfoLongSnack(false);
  };

  const vertical = 'top'
  const horizontal = 'right'



  try {
    return (
      <div>
        
        <AqtivaErrorBoundary
      >
        <MuiThemeProvider theme={QualityTheme}>

          <div>
            <Snackbar open={openInfoLongSnack} autoHideDuration={30000} onClose={handleCloseInfoLong} onClick={handleCloseInfoLong}
              anchorOrigin={{ vertical, horizontal }} style={{ marginTop: '-15px', marginRight: '-15px' }}
              TransitionComponent={state.Transition}
              TransitionProps={{ direction: 'left' }}>
              {snackbarContentHelper.infoSnackbarContent(infoMessageLong)}
            </Snackbar>
          </div >

          <div>
            <Snackbar open={openInfoSnack} autoHideDuration={3000} onClose={handleCloseInfo} onClick={handleCloseInfo}
              anchorOrigin={{ vertical, horizontal }} style={{ marginTop: '-15px', marginRight: '-15px' }}
              TransitionComponent={state.Transition}
              TransitionProps={{ direction: 'left' }}>
              {snackbarContentHelper.infoSnackbarContent(infoMessage)}
            </Snackbar>
          </div >

          <div>
            <Snackbar open={openErrorSnack} autoHideDuration={10000} onClose={handleCloseError} onClick={handleCloseError}
              anchorOrigin={{ vertical, horizontal }} style={{ marginTop: '-15px', marginRight: '-15px' }}
              TransitionComponent={state.Transition}
              TransitionProps={{ direction: 'left' }}>
              {snackbarContentHelper.infoSnackbarContentError(errorMessage)}


            </Snackbar>
          </div >
          <Switch>
          <Route path="/integration/df/:idDataformat" component={AqtivaQualityIntegration} ></Route>
          <Route path="/integration/ds/:idDatasource" component={AqtivaQualityIntegration} ></Route>
          <Route path="/integration/qp/:idQualityPoint" component={AqtivaQualityIntegration} ></Route>
            
            <Route path="/login"component={() => <LoginPage setAuthenticated={setAuthenticated} />} ></Route>
            <Route path="/oauth2/redirect" component={() => <OAuth2RedirectHandler setAuthenticated={setAuthenticated} store={props.store} />} ></Route>
            <Route path="/azure" component={AzurePage} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/new/dataset`} component={DatasetManagementPage} authenticated={authenticated} />
            
            <PrivateRoute path="/test/new/dashboard" component={AqtivaDashboardDrillDownPage} authenticated={authenticated} />
            <PrivateRoute exact path="/aqtiva/drilldown/dashboard/simulation/:idSimulation" component={AqtivaDashboardNewPage} authenticated={authenticated} />
            <PrivateRoute exact path="/aqtiva/drilldown/dashboard/project/:idProject/qp/:idQp/datasource/:idDatasource" component={AqtivaDashboardNewPage} authenticated={authenticated} />
            <PrivateRoute exact path="/aqtiva/drilldown/dashboard/project/:idProject/qp/:idQp" component={AqtivaDashboardNewPage} authenticated={authenticated} />
            <PrivateRoute exact path="/aqtiva/drilldown/dashboard/project/:idProject" component={AqtivaDashboardNewPage} authenticated={authenticated} />
            <PrivateRoute path="/aqtiva/drilldown/dashboard" component={AqtivaDashboardNewPage} authenticated={authenticated} />
            <PrivateRoute path="/test/dashboard" component={AqtivaDashboard} authenticated={authenticated} />
            <PrivateRoute path="/datarepository" component={DataRepository} authenticated={authenticated} />
            <PrivateRoute path="/datarepositoryrefactor" component={DataRepositoryRefactor} authenticated={authenticated} />
            {/*  Añado estas dos rutas */}
            <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/governance/:aggregationLevel/:entityIds`} forceRefresh={true} component={QualityKpisDetail} authenticated={authenticated} />
            <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/governance`} forceRefresh={true} component={QualityKpis} authenticated={authenticated} />

            {/* Las rutas nuevas son las de arriba */}
            <PrivateRoute path={`/admin`} component={LicensePage} authenticated={authenticated} />
            <PrivateRoute path={`/admin/license`} component={LicensePage} authenticated={authenticated} />
            <PrivateRoute path={`/home`} component={QualityManagementProjectPage} authenticated={authenticated} />
            <PrivateRoute path={`/management`} component={SuperAdminLicenseManagementPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/simulations`} component={QualitySimulationRepositoryPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/datasets/:dataformatId`} component={QualityDataformatDetailPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/datasets`} component={DatasetManagementPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/schedules`} component={QualityShedulePage} authenticated={authenticated} />
            <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/:idSimulation/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated} />
            <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/project/:idProject/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated} />
            <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/qualitypoint/:idQualityPoint/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated} />
            <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/datasource/:idDatasource/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/profiling/:dataformatId`} component={QualityDatasetProfilingPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/profiling`} component={QualityDatasetProfilingPage} authenticated={authenticated} />

            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/dashboard`} component={QualityDashboardPagev2} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/configuration`} component={QualityConfigurationPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/project/:projectId/:qpId/:recommenderShow/:notificationId`} component={QualityDetailProjectPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/project/:projectId`} component={QualityDetailProjectPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/consumption`} component={QualityConsumption} authenticated={authenticated} />
            <PrivateRoute path={`/`} component={QualityManagementProjectPage} authenticated={authenticated} />
            <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}`} component={QualityManagementProjectPage} authenticated={authenticated} />
          </Switch>
        </MuiThemeProvider>
        </AqtivaErrorBoundary>
      </div>
    );
  }
  catch (error) {
      Sentry.captureException(error);

  }
}
