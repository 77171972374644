import React, { useEffect, useState } from 'react'
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@material-ui/core/styles';
import { useInterval } from 'components/common/useInterval'
import * as actions from 'actions'
import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'
import ComponentProfilingStatus from 'components/dialogs/DialogSelectAzureSource/ComponentProfilingStatus'
import {
    Grid, Typography, Button, FormControlLabel, Switch, TextField
} from '@material-ui/core'


export default function DataformatAddProfiling(props) {
    const dispatch = useDispatch()
    const theme = useTheme();
    const configuration = useSelector(store => (store.qualityConfiguration))
    const user = useSelector(store => store.user)

    const [datasetData, setDatasetData] = useState(props.dataset)
    const [flattenFlag, setFlattenFlag] = React.useState(true)
    const [numberSamples, setNumberSamples] = React.useState(100)
    const [profilingSamples, setProfilingSamples] = React.useState(200000)

    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [selectedEnvironmentStates, setSelectedEnvironmentStates] = React.useState('')
    //TODO: CHECK, TO AVOID WARNINGS
    if (selectedEnvironmentStates) { }
    const [profilingdataformatId, setProfilingdataformatId] = useState(undefined)
    const [dataformatWithProfiling, setDataformatWithProfiling] = React.useState(undefined)
    const [profilingState, setProfilingState] = React.useState('')


    useEffect(() => {
        if (props.dataset) {
            setDatasetData(props.dataset)

        }

    }, [props.dataset])


    useInterval(() => {
        if (profilingdataformatId !== undefined) {
            dispatch(actions.fetchDataformat(profilingdataformatId)).then(result => {
                switch (result.data.profilingStatus) {
                    case "starting": {
                        setProfilingState("starting")
                        break
                    }
                    case "in_progress": {
                        setProfilingState("in progress")
                        break
                    }
                    case "ok": {
                        setProfilingState("completed")
                        setDataformatWithProfiling(result.data)
                        break
                    }
                    case "ko": {
                        setProfilingState("some errors were encountered,enable to execute profiling job")
                        break
                    }
                    default:
                        break
                }
            }
            )
        }

    }, 3000)

    async function addInternalProfiling() {
        if (datasetData && numberSamples && flattenFlag) {
            var environment = selectedEnvironment
            var nSamples = numberSamples
            var flatten = flattenFlag
            if (datasetData.source === "azure-sqldatabase") {
                var SQLDto = {
                    server: datasetData.sqlDatabaseProperties.server,
                    database: datasetData.sqlDatabaseProperties.database,
                    schema: datasetData.sqlDatabaseProperties.schema,
                    table: datasetData.sqlDatabaseProperties.table,
                    resourceGroup: datasetData.sqlDatabaseProperties.resourceGroup,
                    user: datasetData.sqlDatabaseProperties.user,
                    pwd: datasetData.sqlDatabaseProperties.pwd
                }
                await dispatch(actions.addProfilingToSQLDataformat(environment, datasetData.userId, SQLDto, nSamples, flatten)).then(result => {
                    /* if (result && result.dto && result.dto.run_id) {
                        props.setRunId(result.dto.run_id)
                    } */
                    if (result && result.dataformatId) {
                        setProfilingdataformatId(result.dataformatId)
                    }

                })
            }
            if (datasetData.source === "azure-storage") {
                var itemDTO = {
                    "type": "file",
                    "name": datasetData.fileStorageProperties.path,
                    "fullName": datasetData.source !== 'azure-storage' ? datasetData.fileStorageProperties.path : null,
                    "resourceGroupName": datasetData.fileStorageProperties.resourceGroup, "storageAccount": datasetData.fileStorageProperties.account, "container": datasetData.fileStorageProperties.container, "children": [],
                    "level": 0, "parent": "",
                    "listChildrenNames": null
                }
                await dispatch(actions.addProfilingToDataformat(environment, datasetData.userId, itemDTO, profilingSamples, flatten)).then(result => {
                    /* if (result && result.dto && result.dto.run_id) {
                        props.setRunId(result.dto.run_id)
                    } */
                    if (result && result.dataformatId) {
                        setProfilingdataformatId(result.dataformatId)
                    }

                })
            }
            if (datasetData.source === "sqlServer") {
                let credentials = getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration, datasetData.source)
                let correctCredentials = credentials.find(element => element.id === datasetData.sqlDatabaseOnpremProperties.credentialsId)
                const itemDTO = {
                    table: datasetData.sqlDatabaseOnpremProperties.table,
                    storageType: correctCredentials.storageType,
                    credentials: correctCredentials.sqlServerCredentialsDTO,
                    credentialsId: correctCredentials.id
                }
                await dispatch(actions.addProfilingToOnpremSQLDataformat(environment, datasetData.userId, itemDTO, nSamples, flatten)).then(result => {
                    /* if (result && result.dto && result.dto.run_id) {
                        props.setRunId(result.dto.run_id)
                    } */
                    if (result && result.dataformatId) {
                        setProfilingdataformatId(result.dataformatId)
                    }

                })
            }
            if (datasetData.source === "mySQL") {
                let credentials = getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration, datasetData.source)
                let correctCredentials = credentials.find(element => element.id === datasetData.sqlDatabaseOnpremProperties.credentialsId)
                const itemDTO = {
                    table: datasetData.sqlDatabaseOnpremProperties.schema,
                    storageType: correctCredentials.storageType,
                    credentials: correctCredentials.mySQLCredentialsDTO,
                    credentialsId: correctCredentials.id
                }
                await dispatch(actions.addProfilingToOnpremSQLDataformat(environment, datasetData.userId, itemDTO, nSamples, flatten)).then(result => {
                    if (result && result.dataformatId) {
                        setProfilingdataformatId(result.dataformatId)
                    }

                })
            }
            if (datasetData.source === "oracle") {
                let credentials = getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration, datasetData.source)
                let correctCredentials = credentials.find(element => element.id === datasetData.sqlDatabaseOnpremProperties.credentialsId)
                const itemDTO = {
                    table: datasetData.sqlDatabaseOnpremProperties.table,
                    storageType: correctCredentials.storageType,
                    credentials: correctCredentials.oracleStorageCredentialsDTO,
                    credentialsId: correctCredentials.id
                }
                await dispatch(actions.addProfilingToOnpremSQLDataformat(environment, datasetData.userId, itemDTO, nSamples, flatten)).then(result => {
                    if (result && result.dataformatId) {
                        setProfilingdataformatId(result.dataformatId)
                    }

                })
            }
            if (datasetData.source === "bigQuery") {
                let credentials = getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration, datasetData.source)
                let correctCredentials = credentials.find(element => element.id === datasetData.bigQueryOnpremDatabaseProperties.credentialsId)
                const itemDTO = {
                    table: datasetData.bigQueryOnpremDatabaseProperties.table,
                    dataset: datasetData.bigQueryOnpremDatabaseProperties.dataset,
                    storageType: correctCredentials.storageType,
                    credentials: correctCredentials.bigQueryCredentialsDTO,
                    credentialsId: correctCredentials.id
                }
                await dispatch(actions.addProfilingToOnpremBigQueryDataformat(environment, datasetData.userId, itemDTO, nSamples, flatten)).then(result => {
                    if (result && result.dataformatId) {
                        setProfilingdataformatId(result.dataformatId)
                    }

                })
            }

            //props.onCloseDetailDialog()
        }
    }


    function getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration, storageType) {
        let masterConfig = JSON.parse(JSON.stringify(configuration))
        if (masterConfig.environmentConfigurationList && masterConfig.environmentConfigurationList.length > 0) {
            let selectedEnvironmentConfiguration = masterConfig.environmentConfigurationList.filter(envConf => envConf.environmentName === selectedEnvironment)[0]
            let credentials = []
            if (selectedEnvironmentConfiguration && selectedEnvironmentConfiguration.enabledDataStorageCredentials
                && selectedEnvironmentConfiguration.enabledDataStorageCredentials.length > 0) {
                credentials = selectedEnvironmentConfiguration.enabledDataStorageCredentials
            }
            credentials = checkIfUserCanAccessCredentials(user, credentials)
            credentials = getServerCredentials(credentials, storageType)
            return credentials
        }

    }

    function checkIfUserCanAccessCredentials(user, credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].accessType && credentials[i].accessType === "public_access") {
                usableCredentials.push(credentials[i])
            }
            if (credentials[i].accessType && credentials[i].accessType === "protected_access" && credentials[i].allowedUsers) {
                let userIsAllowed = credentials[i].allowedUsers.filter(allowedUser => allowedUser.id === user.id).length > 0
                if (userIsAllowed) {
                    usableCredentials.push(credentials[i])
                }
            }
        }
        return usableCredentials
    }
    function getServerCredentials(credentials, storageType) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {

            if (credentials[i].storageType && (credentials[i].storageType === storageType)) {
                usableCredentials.push(credentials[i])
            }

        }
        return usableCredentials
    }

    function resetState() {
        setFlattenFlag(true)
        setNumberSamples(100)
        setProfilingSamples(200000)
        setSelectedEnvironment('')
        setSelectedEnvironmentStates('')
        setProfilingdataformatId(undefined)
        setDataformatWithProfiling(undefined)
        setProfilingState('')

    }

    return (
        <AqtivaDialog
            visible={props.show}
            dialog={props.show}
            fullWidth={true}
            maxWidth={'md'}
            title={"Add Profiling to Dataformat"}
            editable={false}
            titleIcon={faProjectDiagram}
            showConfirmButton={false}
            confirmText={'add profiling'}
            confirmCallback={() => { addInternalProfiling() }}
            cancelText={'Close'}
            cancelCallback={() => {
                dispatch(actions.fetchDataFormats(user))
                dispatch(actions.fetchAllDataFormats(user))
                resetState()
                props.onCloseDetailDialog()
            }}>
            <div style={{ margin: '10px', minHeight: "300px", display: "flex", justifyContent: "space-between" }}>
                <Grid container rowSpacing={7} >
                    <Grid item xs={12} >
                        <div style={{ ...theme.elementAdjacent, marginBottom: '-10px', marginLeft: '10px' }}>
                            <div style={{ width: '150px', marginRight: '20px' }}>
                                <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                                    setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                            </div>
                            {undefined && <FormControlLabel style={theme.config.formControl}
                                control={
                                    <TextField
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        id="nSamples"
                                        label={<Typography variant="caption">Samples to Preview</Typography>}
                                        style={theme.config.textField}
                                        margin="dense"
                                        value={numberSamples}
                                        onChange={(event) => setNumberSamples(event.target.value)}
                                    />} />}

                            <FormControlLabel style={theme.config.formControl}
                                control={
                                    <TextField
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        id="nSamples"
                                        label={<Typography variant="caption">Profiling Samples</Typography>}
                                        style={theme.config.textField}
                                        margin="dense"
                                        value={profilingSamples}
                                        onChange={(event) => setProfilingSamples(event.target.value)}
                                    />} />
                            {undefined && <FormControlLabel style={theme.config.formControl}
                                control={
                                    <Switch
                                        checked={flattenFlag}
                                        onChange={event => {
                                            setFlattenFlag(event.target.checked)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={<Typography variant="caption">Flatten Nested Structures</Typography>}
                            />}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <ComponentProfilingStatus profilingState={profilingState} visible={true} dataformatWithProfiling={dataformatWithProfiling} />
                    </Grid>
                    <Grid item xs={2}></Grid>
                    {profilingdataformatId === undefined &&
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">Please select your desired parameters to execute a profiling job. You can close this dialog as you will receive a notificatin as soon as the execution ends or you can wait and see the profiling results.</Typography>
                        </Grid>
                    }
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        {profilingdataformatId === undefined &&
                            <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}
                                onClick={event => { addInternalProfiling() }}>
                                Add profilinG</Button>
                        }
                    </Grid>
                </Grid>
            </div>

        </AqtivaDialog>
    )
}