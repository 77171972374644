import {FETCH_KPIS_GLOBAL
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_KPIS_GLOBAL:
            return action.payload
        default:
            return state;
        
    }
}