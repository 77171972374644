import { CsvBuilder } from 'filefy';
import { onlyUnique } from 'commonFunctions/commonFunctions'



export function getLookup(data, categoryField) {
  const dataUnique = data.map(x => x[categoryField]).filter(onlyUnique);
  var dataLookUp = {}

  dataUnique.forEach(x => {
    dataLookUp[x] = x
  })


  return dataLookUp
}

export function getLookupObject(data, categoryField) {
  const dataUnique = data.map(x => x[categoryField]).filter(onlyUnique);
  var dataLookUp = []

  dataUnique.forEach(x => {
    if (x !== null) dataLookUp.push(x)
  })


  return dataLookUp
}
export function getLookUpProfiling(data) {
  const dataUnique = data.map(x => x.profiling === undefined ? false : true).filter(onlyUnique);
  var dataLookUp = {}

  dataUnique.forEach(x => {
    dataLookUp[x] = x
  })


  return dataLookUp
}


export function getLookUpColumns(data) {
  const dataUnique = data.map(x => x.columns.length).filter(onlyUnique);
  var dataLookUp = {}

  dataUnique.forEach(x => {
    dataLookUp[x] = x
  })


  return dataLookUp
}


export function getLookUpOwner(data, licenseUsers) {
  const dataUnique = data.map(x => x.userId).filter(onlyUnique);
  var dataLookUp = {}

  dataUnique.forEach(x => {
    dataLookUp[x] = translateUserIdToName(x, licenseUsers)
  })


  return dataLookUp
}

export function getLookUpOwnerList(data, licenseUsers) {
  const dataUnique = data.map(x => x.userId).filter(onlyUnique);
  var dataLookUp = []

  dataUnique.forEach(x => {
    dataLookUp.push(
      {
        id: x,
        name: translateUserIdToName(x, licenseUsers)
      }
    )
  })


  return dataLookUp
}


const translateUserIdToName = (userId, licenseUsers) => {
  if (licenseUsers !== undefined && licenseUsers.length > 0) {
    for (let i = 0; i < licenseUsers.length; i++) {
      if (licenseUsers[i].id === userId) {
        return licenseUsers[i].name
      }
    }
    return userId
  }
  return undefined


}

export function exportCsvDataformats(data, columns, fileName, licenseUsers) {
  const columnTitles = columns
    .map(columnDef => columnDef.title);

  const csvData = data.map(rowData =>
    columns.map(columnDef => {

      if (columnDef.field === 'columns') {
        if (rowData.columns && rowData.columns.length > 0) {
          return rowData.columns.length
        }
        return 0
      }
      else if (columnDef.field === 'userId') {
        return translateUserIdToName(rowData.userId, licenseUsers)

      }
      else if (columnDef.field === 'storageAccount') {
        if (rowData.fileStorageProperties && rowData.fileStorageProperties.resourceGroup) {
          return rowData.fileStorageProperties.resourceGroup
        }
        else {
          return ''
        }


      }
      else if (columnDef.field === 'origin') {
        if (rowData.fileStorageProperties && rowData.fileStorageProperties.path) {
          return rowData.fileStorageProperties.path
        }
        else {
          return ''
        }
      }

      else if (columnDef.field === 'allowedUsers') {
        return rowData && rowData !== null && rowData.allowedUsers && rowData.allowedUsers !== null ?
          rowData.allowedUsers.map(userId =>
            translateUserIdToName(userId, licenseUsers)).filter(onlyUnique).join(" and ") : ""

      }
      else {
        return rowData[columnDef.field]
      }
    })

  );

  const builder = new CsvBuilder(`${fileName}.csv`)
    .setColumns(columnTitles)
    .addRows(csvData)
    .exportFile();

  return builder;
}