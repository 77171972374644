import React, { useEffect, useState } from 'react';


import { useDispatch } from 'react-redux'
import AqtivaDialog from '../../../AqtivaDialog';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@material-ui/core'
import * as helper from '../ErrorUtils'
import EnrichCard from '../EnrichCard';
import * as columnHelper from '../utils'
import * as actions from '../../../../../actions'


export default function EditJoinDialog(props) {
    const dispatch = useDispatch()
    const [join, setJoin] = React.useState('')
    const [errors, setErrors] = useState({})
    const [counter, setCounter] = useState(1)

    const [warningMessage, setWarningMessage] = useState('')
    const [rulesWithIssues, setRulesWithIssues] = useState([])
    const [enrichColumns, setEnrichColumns] = useState([])

    useEffect(() => {

        if (join) {
            if (props.element && props.element === "datasource") {
                let aliasChanged = checkIfAliasHasBeenEdited(join, props.datasource)
                if (aliasChanged) {
                    if (join && join.columns) {
                        let uneditedJoin = props.datasource.enrichDataformatObject.joins.find(j => j.id === join.id)
                        let allColumnsUsed = columnHelper.getAllColumnsUsedInRules(props.datasource)
                        let rulesWithIssues = columnHelper.checkIfColumnsAreUsed(allColumnsUsed, uneditedJoin, props.datasource)
                        if (rulesWithIssues && rulesWithIssues.length > 0) {
                            setWarningMessage("By editing this join's alias the next rules won't be able to execute until you fix them:")
                            setRulesWithIssues(rulesWithIssues)
                        }
                    }
                }
            }
            if (props.element && props.element === "rule") {
                let aliasChanged = checkIfAliasHasBeenEdited(join, props.rule)
                if (aliasChanged) {
                    if (join && join.columns) {
                        let uneditedJoin = props.rule.enrichDataformatObject.joins.find(j => j.id === join.id)
                        let allColumnsUsed = columnHelper.getAllColumnsUsedInSelectedRule(props.datasource, props.rule)
                        let rulesWithIssues = columnHelper.checkIfColumnsAreUsedInSelectedRule(allColumnsUsed, uneditedJoin, props.datasource, props.rule)
                        if (rulesWithIssues && rulesWithIssues.length > 0) {
                            setWarningMessage("By editing this join's alias, this rule won't be able to execute unless you fix the selected columns")
                            setRulesWithIssues(rulesWithIssues)
                        }
                    }
                }
            }
        }
    }, [join, join && join.columns])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (counter > 1) {
            let allAliases = getAllAliasesInDatasourceAndRules(join, props.datasource)
            let errorsToCheck = helper.checkAllFields(join, allAliases)
            setErrors(errorsToCheck)
        }
    }, [counter, counter > 1 && join])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.selectedJoin) updateEnrichColumns(props.selectedJoin)
    }, [props.selectedJoin])
    async function editArrayElement(joins, editedElement) {
        let allAliases = getAllAliasesInDatasourceAndRules(join, props.datasource)
        let errorsToCheck = helper.checkAllFields(editedElement, allAliases)
        setErrors(errorsToCheck)
        if (!errorsToCheck.hasErrors) {


            if (props.element && props.element === "datasource") {

                let aliasChanged = checkIfAliasHasBeenEdited(join, props.datasource)
                if (aliasChanged) {
                    if (props.selectedJoin && props.selectedJoin.columns) {
                        //hacer una llamada update del datasource con el join borrado 
                        let newDatasource = JSON.parse(JSON.stringify(props.datasource))
                        let index = joins.findIndex(x => x.id === props.selectedJoin.id)
                        joins[index] = editedElement
                        newDatasource.enrichDataformatObject.joins = joins

                        await dispatch(actions.editDatasource(props.project._id, props.qualityPoint._id, newDatasource, props.userId))

                        let allColumnsUsed = columnHelper.getAllColumnsUsedInRules(props.datasource)
                        await columnHelper.checkIfColumnsAreUsedAndUpdateRuleFlagEditing(allColumnsUsed, editedElement, props.project, props.qualityPoint, props.datasource, props.userId, dispatch, actions)


                    }
                }
            }
            let index = joins.findIndex(x => x.id === props.selectedJoin.id)
            joins[index] = editedElement
            props.setData(joins)
            props.setUpdateCounter(props.updateCounter + 1)
            props.setShowEditDialog(false)
        }
        else {
            setCounter(2)
        }
    }

    function getAllAliasesInDatasourceAndRules(join, datasource) {
        let allAliases = []
        if (datasource && datasource.enrichDataformatObject && datasource.enrichDataformatObject.joins && datasource.enrichDataformatObject.joins.length > 0) {
            for (let i = 0; i < datasource.enrichDataformatObject.joins.length; i++) {
                if (datasource.enrichDataformatObject.joins[i].id !== join.id) {
                    allAliases.push(datasource.enrichDataformatObject.joins[i].alias ? datasource.enrichDataformatObject.joins[i].alias : "no alias")
                }
            }
        }
        if (datasource && datasource.rules && datasource.rules.length > 0) {
            for (let i = 0; i < datasource.rules.length; i++) {
                if (datasource.rules[i].enrichDataformatObject && datasource.rules[i].enrichDataformatObject.joins && datasource.rules[i].enrichDataformatObject.joins.length > 0) {
                    for (let j = 0; j < datasource.rules[i].enrichDataformatObject.joins.length; j++) {
                        if (datasource.rules[i].enrichDataformatObject.joins[j].id !== join.id) {
                            allAliases.push(datasource.rules[i].enrichDataformatObject.joins[j].alias ? datasource.rules[i].enrichDataformatObject.joins[j].alias : "no alias")
                        }
                    }
                }
            }
        }
        return allAliases
    }

    function checkIfAliasHasBeenEdited(join, qualityElement) {
        if (qualityElement.enrichDataformatObject && qualityElement.enrichDataformatObject.joins && qualityElement.enrichDataformatObject.joins.length > 0) {
            let uneditedJoin = qualityElement.enrichDataformatObject.joins.find(j => j.id === join.id)
            if (join.alias && uneditedJoin.alias && uneditedJoin.alias === join.alias) {
                return false
            }
            else {
                return true
            }
        }

    }

    function updateEnrichColumns(data){

        let cols = []
        
        if(props.enrichColumns && props.enrichColumns.length > 0){
            
            cols = props.enrichColumns.filter(ec => {
                return !data.columns.find( c => {
                    return c === ec
                })
            })

            setEnrichColumns(cols)
        }
    }


    return (
        <AqtivaDialog
            visible={props.showEditDialog}
            dialog={props.showEditDialog}
            titleIcon={faPlusCircle}
            fullWidth={true}
            maxWidth={'md'}
            title={"Edit Join"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setShowEditDialog(false)
            }}
            confirmText={'Edit Join properties'}
            confirmCallback={async () => {
                editArrayElement(props.data, join)
            }}
        >
            <EnrichCard master={true} setJoin={setJoin} configuration={props.configuration} enrichColumns={enrichColumns} joinInfo={props.selectedJoin} errors={errors} datasource={props.datasource} dataformat={props.dataformat} />


            {rulesWithIssues && rulesWithIssues.length > 0 &&
                <Typography variant={'subtitle2'} style={{ textAlign: "center", color: "red" }}>
                    {warningMessage}
                </Typography>}

            {props.element && props.element !=="rule" && rulesWithIssues && rulesWithIssues.length > 0 && rulesWithIssues.map(rule => {
                return (
                    <Typography variant={'subtitle2'} style={{ textAlign: "center", color: "red" }}>
                        {rule.name}
                    </Typography>
                )
            })
            }
        </AqtivaDialog>

    )
}