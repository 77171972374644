import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

import { styles, QualityTheme } from '../../../../../../../style/CustomTheme'
import { Typography, TextField, FormControlLabel, MuiThemeProvider } from '@material-ui/core';
import AqtivaDialogEncryptString from '../../../../../../common/AqtivaDialogEncryptString'
import classNames from 'classnames';


export default function StorageCard(props) {


    const classes = makeStyles(styles(QualityTheme));

    //const [visibleStorageSasToken, setVisibleStorageSasToken] = React.useState(false)
    const [visibleStorageToken, setVisibleStorageToken] = React.useState(false)

    const [resourceGroupName, setResourceGroupName] = React.useState('')
    const [regionName, setRegionName] = React.useState('')
    const [storageAccountName, setStorageAccountName] = React.useState('')
    const [containerName, setContainerName] = React.useState('')
    const [storageKey, setStorageKey] = React.useState('')
    //const [sasToken, setSasToken] = React.useState('')
    //TODO: check
    const sasToken = ''
    const [storageName, setStorageName] = React.useState('')
    const [path, setPath] = React.useState('')




    React.useEffect(() => {
        setStorageName(props.storageInfo && props.storageInfo.storageName && props.storageInfo.storageName !== null ?
            props.storageInfo.storageName : '')
        setResourceGroupName(props.storageInfo && props.storageInfo.resourceGroupName && props.storageInfo.resourceGroupName !== null ?
            props.storageInfo.resourceGroupName : '')
        setRegionName(props.storageInfo && props.storageInfo.regionName && props.storageInfo.regionName !== null ?
            props.storageInfo.regionName : '')
        setStorageAccountName(props.storageInfo && props.storageInfo.storageAccountName && props.storageInfo.storageAccountName !== null ?
            props.storageInfo.storageAccountName : '')
        setContainerName(props.storageInfo && props.storageInfo.containerName && props.storageInfo.containerName !== null ?
            props.storageInfo.containerName : '')
        setStorageKey(props.storageInfo && props.storageInfo.storageKey && props.storageInfo.storageKey !== null ?
            props.storageInfo.path : '')
        setPath(props.storageInfo && props.storageInfo.path && props.storageInfo.path !== null ?
            props.storageInfo.path : '')
    }, [props.storageInfo, props.parentConfiguration]);



    useEffect(() => {
        let storageProperties = {}
        storageProperties.resourceGroupName = resourceGroupName
        storageProperties.storageAccountName = storageAccountName
        storageProperties.containerName = containerName
        storageProperties.storageKey = storageKey
        storageProperties.path = path
        props.setStorageProperties(storageProperties)
    }, [resourceGroupName, regionName, storageAccountName, containerName, storageKey, sasToken, storageName, path])// eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        <MuiThemeProvider theme={QualityTheme}>
            <AqtivaDialogEncryptString visible={visibleStorageToken}
                setVisible={setVisibleStorageToken}
                confirm={setStorageKey}
                sName={props.id && props.id + "sttkk"}
            />
            <div>
                {<div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                    <Typography variant="subtitle2"> BlobStorage Configuration</Typography>
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="accountName"
                                label="BlobStorage Resource Group Name"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={resourceGroupName}
                                onChange={(event) => { setResourceGroupName(event.target.value) }}
                            />} />
                    {props.errors && props.errors.resourceGroupName && <div style={{ margin: "10px", color: "red" }}>{props.errors.resourceGroupName}</div>}
                    {/* <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="accountName"
                                label="BlobStorage Region Name "
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={regionName}
                                onChange={(event) => { setRegionName(event.target.value) }}
                            />} /> */}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="accountName"
                                label="BlobStorage Account Name "
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={storageAccountName}
                                onChange={(event) => { setStorageAccountName(event.target.value) }}
                            />} />
                    {props.errors && props.errors.storageAccountName && <div style={{ margin: "10px", color: "red" }}>{props.errors.storageAccountName}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="containerName"
                                label="BlobStorage Container Name"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={containerName}
                                onChange={(event) => { setContainerName(event.target.value) }}
                            />} />
                    {props.errors && props.errors.containerName && <div style={{ margin: "10px", color: "red" }}>{props.errors.containerName}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="path"
                                label="Path"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={path}
                                onChange={(event) => { setPath(event.target.value) }}
                            />} />
                    {props.errors && props.errors.path && <div style={{ margin: "10px", color: "red" }}>{props.errors.path}</div>}

                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="storageKey"
                                label="BlobStorage key"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                type="password"
                                value={storageKey}
                                onClick={(event) => { setVisibleStorageToken(true) }}
                            />} />
                    {props.errors && props.errors.storageKey && <div style={{ margin: "10px", color: "red" }}>{props.errors.storageKey}</div>}
                </div>
                }
            </div>
        </MuiThemeProvider >
    )
}