import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from 'moment'


export default function DynamicDateComponent(props) {

    const [datePicker, setDatePicker] = useState('')
    const [focus, setFocused] = useState(false);
    const [columnOperation, setColumnOperation] = useState('')
    const [columnTypeUnit, setColumnTypeUnit] = useState('')
    const [columnTimeUnit, setColumnTimeUnit] = useState('')
    const operations = ['+', '-', '=']
    const levels = ["YEAR", "MONTH", "DAY"]
    const currentDate = moment(Date.now()).format('DD-MM-YYYY')
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const [errorObj, setErrorObj] = useState(undefined)


    function checkErrors() {
        let errorsObj = {}
        if (props.type === 'datepicker') {
            if (datePicker === '') {
                errorsObj.errorDatePicker = true;
                setErrorObj(errorsObj)
                return false
            }
            return true

        } else {
            if (columnOperation === '') {
                errorsObj.errorOperation = true;
            }
            if (columnTypeUnit === '') {
                errorsObj.errorTypeUnit = true;
            }
            if (columnTimeUnit === '') {
                errorsObj.errorTimeUnit = true;
            }

            if (!errorsObj.errorTimeUnit && !errorsObj.errorTypeUni && !errorsObj.errorOperation) return true
            else {
                setErrorObj(errorsObj)
                return false
            }

        }

    }

    function makeObject() {
        let obj

        if (props.type === 'datepicker') {
            obj = { date: datePicker, type: props.type }
            return obj
        } else {
            obj = {
                date: currentDate,
                operation: columnOperation,
                typeUnit: columnTypeUnit,
                timeUnit: columnTimeUnit,
                type: props.type
            }
            return obj
        }
    }

    useEffect(() => {
        if (props.setSpecialColumn && props.refresh > 0) {
            if (checkErrors()) {
                props.setSpecialColumn(makeObject())
                props.close(false)
            }
        }
    }, [props.refresh])

    return (
        <Grid container spacing={3}>
            {props.type === 'datepicker' ?
                <Grid xs={12}>
                    <TextField
                        error={errorObj === undefined ? false : errorObj.errorDatePicker !== undefined ? true : false}
                        style={{ width: '80%', marginLeft: '5%', marginRight: '5%', marginTop: '2%' }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        type={datePicker.length > 0 || focus ? "date" : "text"}
                        id="datepickerDate"
                        name="inputDatepickerDate"
                        label="SelectDate"
                        format={'DD/MM/YYYY'}
                        value={datePicker}
                        onChange={(event) => { setDatePicker(event.target.value) }}
                    />
                </Grid>

                :
                <Grid xs={12} style={{ display: 'inline-flex', marginTop: '4%', marginLeft: '5%' }}>
                    <Grid xs={3}>
                        <TextField
                            style={{ width: '100%' }}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            type={"text"}
                            id="datepickerDate"
                            name="inputDatepickerDate"
                            label={currentDate}
                            disabled={true}
                        />
                    </Grid>
                    <Grid xs={2}>
                        <FormControl style={{ width: '90%' }}>
                            <Select
                                error={errorObj === undefined ? false : errorObj.errorOperation !== undefined ? true : false}
                                defaultValue={'='}
                                inputProps={{
                                    style: { fontSize: 10 }
                                }}
                                style={{ width: '80%', marginTop: '25%', marginLeft: '10%' }}
                                autoWidth={true}
                                labelId="columnLevelOperation"
                                id="columnLevelOperation"
                                value={columnOperation}
                                label="Operation"
                                onChange={(event) => {
                                    setColumnOperation(event.target.value)
                                }}
                            >
                                {operations && operations.map(value => {
                                    return (
                                        <MenuItem key={value} value={value} >
                                            {value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={3}>
                        <TextField
                            error={errorObj === undefined ? false : errorObj.errorTypeUnit !== undefined ? true : false}
                            style={{ width: '97%', marginRight: '3%' }}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            type={"number"}
                            id="timeunits"
                            name="timeunits"
                            label="Time units"
                            value={columnTimeUnit}
                            onChange={event => {
                                setColumnTimeUnit(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <FormControl style={{ width: '90%' }}>
                            <InputLabel id="origin-column">Type units</InputLabel>

                            <Select
                                error={errorObj === undefined ? false : errorObj.errorTimeUnit !== undefined ? true : false}
                                inputProps={{
                                    style: { fontSize: 10 }
                                }}
                                style={{ minWidth: "20%" }}
                                autoWidth={true}
                                labelId="typeUnits"
                                id="typeUnits"
                                value={columnTypeUnit}
                                label="Type units"
                                onChange={(event) => {
                                    setColumnTypeUnit(event.target.value)
                                }}
                            >
                                {levels && levels.map(value => {
                                    return (
                                        <MenuItem key={value} value={value} >
                                            {value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>


                        </FormControl>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}