import React, { useEffect, useState } from 'react';
import {
    Typography, FormControl, InputLabel, Tooltip, Button, Grid, Select, MenuItem, Fab, TextField,
    FormControlLabel, Switch
} from '@material-ui/core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faAd } from '@fortawesome/free-solid-svg-icons'
import { AqtivaDialogDataRepository } from 'components/data/DataRepositoryShare/EntityDataRepository/AqtivaDialogDataRepository'
import QualityDatasetRepositoryPage from 'components/data/DataPageForm'
import TagEnvironmentEnrich from 'components/common/TagEnvironmentEnrich';
import * as actions from 'actions'
import trackEvent from 'trackEvent'

import * as commonStyles from 'style/CommonStyles'
import Autocomplete from '@material-ui/lab/Autocomplete';





import ColumnsSelectorAggregated from 'components/rule/RuleCreationComponent/ColumnsSelectorAggregated';


import * as enrichHelper from 'components/common/EnrichDataformatHelper'



const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));
export default function EnrichCard(props) {
    //const dataformats = useSelector(store => Object.values(store.dataformats))
    const user = useSelector(store => store.user)

    const dispatch = useDispatch();
    const classes = useStyles();


    const [selectedJoinType, setSelectedJoinType] = useState(undefined)
    const [openDialogDataRepository, setOpenDialogDataRepository] = useState(false)
    const [selectedDataformatIdFromDialog, setSelectedDataFormatIdFromDialog] = useState(undefined) // si llamamos al dialogo de seleccionar dataformat, el id seleccionado
    const [selectedDataFormat, setSelectedDataFormat] = useState('')
    const [mappings, setMappings] = useState([])
    const [alias, setAlias] = useState('')
    const [join, setJoin] = useState()
    const [enrichColumns, setEnrichColumns] = useState([])
    const [tag, setTag] = useState('')
    const [tagDescription, setTagDescription] = useState('')
    const [useTag, setUseTag] = useState(false)

    //aggregation elements
    const [aggregatedColumnsFlag, setAggregatedColumnsFlag] = React.useState(false)
    const [aggregationObject, setAggregationObject] = React.useState(undefined)


    useEffect(() => {
        let join = {}
        join.aggregatedColumnsFlag = aggregatedColumnsFlag
        join.aggregationObject = aggregationObject
        //CHANGE PROJECT DF join.originDatasourceId = props.datasource && props.datasource.dataset && props.datasource.dataset._id
        join.originDatasourceId = props.datasource.dataformatId
        join.datasourceEnrichName = selectedDataFormat && selectedDataFormat.name
        join.datasourceEnrichId = selectedDataFormat && selectedDataFormat._id
        join.datasourceEnrichType = selectedDataFormat && selectedDataFormat.format
        join.datasourceEnrichSeparator = selectedDataFormat && selectedDataFormat.separator
        join.columns = selectedDataFormat && selectedDataFormat.columns
        join.joinType = selectedJoinType
        join.mappingsList = mappings
        join.alias = alias
        join.useTag = useTag
        if (useTag) {
            join.tag = tag
            join.tagDescription = tagDescription
        }
        join.id = props.joinInfo && props.joinInfo.id ? props.joinInfo.id : Date.now().toString(36) + Math.random().toString(36).substr(2)
        setJoin(join)
        props.setJoin(join)

    }, [tag, tagDescription, useTag, alias, aggregatedColumnsFlag, aggregationObject, selectedJoinType, selectedDataFormat, ...mappings.map(item => item.originColumn && item.originColumn.name), // eslint-disable-line react-hooks/exhaustive-deps
        ...mappings.map(item => item.destinationColumn && item.destinationColumn.name)])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.newJoin) {
            let mapping = {}
            let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
            mapping.id = newId
            setMappings([mapping])
        }
    }, [props.newJoin])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (props.joinInfo) {
            setSelectedJoinType(props.joinInfo && props.joinInfo.joinType && props.joinInfo.tagName !== null ?
                props.joinInfo.joinType : '')

            setSelectedDataFormatIdFromDialog(props.joinInfo && props.joinInfo.datasourceEnrichId)

            obtainAndSelectDataformatFromId()

            setMappings(JSON.parse(JSON.stringify(props.joinInfo.mappingsList)))
            setAlias(props.joinInfo.alias)

            setAggregatedColumnsFlag(props.joinInfo.aggregatedColumnsFlag)
            setAggregationObject(props.joinInfo.aggregationObject)
            setTag(props.joinInfo && props.joinInfo.tag)
            setTagDescription(props.joinInfo && props.joinInfo.tagDescription)
            setUseTag(props.joinInfo && props.joinInfo.useTag)

        }
    }, [props.joinInfo])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.dataformat && props.dataformat.columns) {
            setEnrichColumns(props.dataformat.columns)
        }
    }, [props.dataformat])

    useEffect(() => {
        let cols = []
        if(props.dataformat && props.dataformat.columns) cols = props.dataformat.columns
        if(props.enrichColumns && props.enrichColumns.length > 0) {
            setEnrichColumns(cols.concat(props.enrichColumns))
        }
    }, [props.enrichColumns])


    async function obtainAndSelectDataformat(id) {
        //lo primero obtenemos el dataformat del id


        if (id !== undefined) {

            dispatch(actions.fetchDataformat(id)).then(response => {
                setSelectedDataFormat(response.data)
            })


        }
    }

    async function obtainAndSelectDataformatFromId() {
        //lo primero obtenemos el dataformat del id
        if (props.joinInfo && props.joinInfo.datasourceEnrichId) {
            obtainAndSelectDataformat(props.joinInfo.datasourceEnrichId)
        }
        if (selectedDataformatIdFromDialog !== undefined) {
            obtainAndSelectDataformat(selectedDataformatIdFromDialog)

        }
    }
    function handleSelectDatasetForExportationId(id) {
        setSelectedDataFormatIdFromDialog(id)
    }

    let joinTypes = ["left_join", "inner_join", "left_outer_join", "full_outer_join", "left_anti","cross_join"]

    function onCloseDialogRepository(value) {
        dispatch(actions.fetchDataFormats(user))
        setOpenDialogDataRepository(value)
    }

    function handleAddMapping() {
        let mapping = {}
        let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
        mapping.id = newId
        let newMappings = JSON.parse(JSON.stringify(mappings))
        newMappings.push(mapping)
        setMappings(newMappings)
    }

    function handleDeleteMapping(mapping2Delete) {
        let newMappings = mappings.filter(function (element) {
            return element.id !== mapping2Delete.id;
        });
        setMappings(newMappings)
    }

    function handleChangeColumnMappingOrigin(mapping2update, value) {
        mapping2update.originColumn = value

        let index = mappings.findIndex(x => x.id === mapping2update.id)
        mappings[index] = mapping2update

        setMappings(JSON.parse(JSON.stringify(mappings)))
    }

    function handleChangeColumnMappingDestination(mapping2update, value) {
        mapping2update.destinationColumn = value

        let index = mappings.findIndex(x => x.id === mapping2update.id)
        mappings[index] = mapping2update;

        setMappings(JSON.parse(JSON.stringify(mappings)))
    }


    const toggleAggregateFlag = () => {

        setAggregatedColumnsFlag(!aggregatedColumnsFlag)

    };



    function getEnrichedDatasetColumns() {

        if (aggregatedColumnsFlag === true) {
            if (aggregationObject) {
                return enrichHelper.getColumnsFromAggregationObject(aggregationObject)
            }
        }
        else {
            return (join && join.columns ? join.columns : [])
        }
        return []


    }


    return (
        <>
            {openDialogDataRepository && <AqtivaDialogDataRepository
                dialog={openDialogDataRepository}
                setDialog={onCloseDialogRepository}
                title="Data Repository"
                showConfirmButton={true}
                fullWidth={true}
                confirmText={'Select DataFormat'}
                titleIcon={faDatabase}
                confirmCallback={(event) => { obtainAndSelectDataformatFromId(); setOpenDialogDataRepository(false) }}
            >
                <QualityDatasetRepositoryPage selectDatasetDialog={true} user={user} defaultValue={1}
                    handleSelectDatasetForExportationId={handleSelectDatasetForExportationId} />
            </AqtivaDialogDataRepository>}



            {mappings && mappings.length > 0 && <Grid container spacing={1} style={{ marginTop: "20px" }}>
                <Grid item xs={6}>
                    {!props.joinInfo && <Button style={{ color: commonStyles.mainColor }}
                        variant='outlined'
                        onClick={event => {
                            trackEvent('DialogCreateDataSource', 'Data Repository')
                            setOpenDialogDataRepository(true)
                        }}>
                        Select a dataformat from the repository
                    </Button>}
                    {props.joinInfo && <Typography variant="subtitle1">Selected Dataformat for enrichment:</Typography>}
                </Grid>

                {selectedDataFormat &&
                    <Grid item xs={12} >

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px', marginTop: '5px' }}>
                            <FormControlLabel
                                control={<Switch size="small" checked={aggregatedColumnsFlag} onChange={toggleAggregateFlag} />}
                                label={<Typography variant='subtitle2' > {"Aggregate Dataset"}</Typography>}
                            />
                        </div>

                        {aggregatedColumnsFlag !== false &&
                            <div>


                                <ColumnsSelectorAggregated
                                    originalDF={selectedDataFormat}
                                    baseDataFormat={selectedDataFormat}
                                    aggregationObject={aggregationObject}
                                    aggregatePrior2Join={false}
                                    aggregateFlag={aggregatedColumnsFlag}
                                    setAggregationObject={setAggregationObject}
                                />
                            </div>

                        }

                    </Grid>
                }

                <Grid item xs={6}>
                    <Typography variant="subtitle2">{selectedDataFormat && selectedDataFormat.name}</Typography>
                </Grid>
                <TagEnvironmentEnrich tag={tag} setTag={setTag} tagDescription={tagDescription} setTagDescription={setTagDescription} useTag={useTag} setUseTag={setUseTag} />
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ color: commonStyles.mainColor }}>Make sure enrichment datasets do not contain duplicated values in the join columns or it may introduce duplicate rows in the final dataset</Typography>
                </Grid>
                {props.errors && <Grid item xs={12}>
                    <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors.datasourceEnrichName}</Typography>
                </Grid>}

                <Grid item xs={6}>
                    <Typography style={{ marginTop: "20px" }} variant="subtitle2">Unique Alias</Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: '100%' }}>
                        <TextField style={{ width: '100%' }}
                            InputLabelProps={{ shrink: true, }}
                            id="AliasName"
                            label="Alias"
                            margin="dense"
                            value={alias}
                            onChange={(event) => { setAlias(event.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                {props.errors && <Grid item xs={12}>
                    <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors.alias}</Typography>
                </Grid>}
                <Grid item xs={6}>
                    <Typography style={{ marginTop: "20px" }} variant="subtitle2">Join Type</Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: '100%', }}>
                        <InputLabel id="join">Select Join Type</InputLabel>
                        <Select
                            defaultValue={selectedJoinType}
                            renderValue={(val) => val}
                            inputProps={{
                                style: { fontSize: 10 }
                            }}
                            style={{ minWidth: "20%" }}
                            autoWidth={true}
                            labelId="join-list"
                            id="join-list"
                            value={selectedJoinType}
                            label="Join Types"
                            onChange={(event) => {
                                setSelectedJoinType(event.target.value)
                            }}
                        >
                            {joinTypes.map(value => {
                                return (
                                    <MenuItem key={value} value={value}>
                                        <Typography variant='body2'>{value}</Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                {props.errors && <Grid item xs={12}>
                    <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors.joinType}</Typography>
                </Grid>}

                {mappings && mappings.length > 0 && mappings.map((mapping, index) => {

                    return (
                        <>
                            <Grid item xs={2}>
                                <Typography style={{ marginTop: "20px" }} variant="subtitle2">Column Mapping {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={{ width: '100%', }}>

                                    <Autocomplete
                                        style={{ minWidth: "20%" }}
                                        autoWidth={true}
                                        labelId="origin-column"
                                        id="origin-column"
                                        value={mapping.originColumn}
                                        label="origin column"
                                        onChange={(event, newValue) => {
                                            handleChangeColumnMappingOrigin(mapping, newValue)
                                        }}
                                        disableClearable
                                        options={enrichColumns}
                                        getOptionLabel={(data) => (data) ? `${data.name}` : ''}

                                        renderInput={(e) => <TextField {...e} label={"Select origin datasource column"} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={{ width: '100%', }}>
                                    <Autocomplete
                                        style={{ minWidth: "20%" }}
                                        autoWidth={true}
                                        labelId="Enriched-column"
                                        id="Enriched-columnn"
                                        value={mapping.destinationColumn}
                                        label="Enriched-column"
                                        onChange={(event, newValue) => {
                                            handleChangeColumnMappingDestination(mapping, newValue)
                                        }}
                                        disableClearable
                                        options={getEnrichedDatasetColumns()}
                                        getOptionLabel={(data) => (data) ? `${data.name}` : ''}

                                        renderInput={(e) => <TextField {...e} label={"Select Enriched datasource column"} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                {index !== 0 && <Tooltip title={"Delete mapping"} style={{ marginTop: "10px" }}>
                                    <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                        onClick={() => { handleDeleteMapping(mapping) }}>
                                        <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                    </Fab>
                                </Tooltip>}
                            </Grid>
                            {props.errors && <Grid item xs={12}>
                                <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].destinationColumn}</Typography>
                                <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].originColumn}</Typography>
                            </Grid>}
                        </>
                    )
                })
                }

                < Grid item xs={12}>
                    <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "20px" }}
                        color="primary" onClick={event => {
                            handleAddMapping()
                        }}>
                        <FontAwesomeIcon icon={faAd} style={{ marginRight: '5%', marginTop: '10%', color: commonStyles.mainColor, fontSize: 15 }} />
                        add new Mapping
                    </Button>
                </Grid>
            </Grid>}

        </>
    )
}