import React from 'react';
import { StyledMenu, StyledMenuItem, getStyleItemText } from '../../../../../common/CustomizedMenu'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faRecordVinyl } from '@fortawesome/free-solid-svg-icons'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import { faFileAlt, faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import SelectAllIcon from '@material-ui/icons/SelectAll';
import BlockIcon from '@material-ui/icons/Block';
export function getContextMenuQualityPoint(state, handleClose, handleAddDatasourceAction, handleEditAction,
    handleDeleteAction,handleRuleRecommender) {
    return (
        <div>
            <StyledMenu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <StyledMenuItem onClick={handleAddDatasourceAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Datasource")}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleEditAction}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Edit QualityPoint")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleDeleteAction}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Delete QualityPoint")}
                </StyledMenuItem>

                {process.env.REACT_APP_RULE_RECOMMENDER_ALLOWED === 'true' && <StyledMenuItem onClick={handleRuleRecommender}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faRecordVinyl} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Rule Recommender")}
                </StyledMenuItem>}

            </StyledMenu>
        </div>
    )
}

export function getContextMenuDatasource(state, handleClose, 
    handleAddRuleAction,handleNewDialogAddRuleAction, 
    handleAddCompoundRuleAction, handleAddCompoundRuleActionNewDialog,
    handleAddFolderAction, handleEditAction, handleDeleteAction, handleSelectRules, handleDeselectRules, handleCopyDatasource, flagNewRuleForm) {
    return (
        <div>
            <StyledMenu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <StyledMenuItem onClick={handleAddRuleAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Rule")}
                </StyledMenuItem>
                {flagNewRuleForm && flagNewRuleForm ===true && 
                <StyledMenuItem onClick={handleNewDialogAddRuleAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Rule (New Form Preview)")}
                </StyledMenuItem>
                }
                <StyledMenuItem onClick={handleAddCompoundRuleAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Compound Rule")}
                </StyledMenuItem>

                {flagNewRuleForm && flagNewRuleForm ===true && 
                <StyledMenuItem onClick={handleAddCompoundRuleActionNewDialog}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Compound Rule (New Form Preview)")}
                </StyledMenuItem>
                }
                <StyledMenuItem onClick={handleAddFolderAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFolderOpen} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Folder")}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleEditAction}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Edit Datasource")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelectRules}>
                    <ListItemIcon>
                        <SelectAllIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Select all Rules")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleDeselectRules}>
                    <ListItemIcon>
                        <BlockIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Deselect all Rules")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleCopyDatasource}>
                    <ListItemIcon>
                        <CopyIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Copy Datasource")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleDeleteAction}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Delete Datasource")}
                </StyledMenuItem>

            </StyledMenu>
        </div>
    )
}

export function getContextMenuFolder(state, handleClose, 
    handleAddRuleAction, handleNewDialogAddRuleAction,
    handleAddCompoundRuleAction,handleAddCompoundRuleActionNewDialog,
     handleAddFolderAction, handleEditAction, handleDeleteAction, flagNewRuleForm) {
    return (
        <div>
            <StyledMenu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <StyledMenuItem onClick={handleAddRuleAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Rule")}
                </StyledMenuItem>

                {flagNewRuleForm && flagNewRuleForm ===true && 
                <StyledMenuItem onClick={handleNewDialogAddRuleAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Rule (New Form Preview)")}
                </StyledMenuItem>
                }
                <StyledMenuItem onClick={handleAddCompoundRuleAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Compound Rule")}
                </StyledMenuItem>

                {flagNewRuleForm && flagNewRuleForm ===true && 
                <StyledMenuItem onClick={handleAddCompoundRuleActionNewDialog}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Compound Rule (New Form Preview)")}
                </StyledMenuItem>
                }
                <StyledMenuItem onClick={handleAddFolderAction}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFolderOpen} style={{ fontSize: 14, marginLeft: '15%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Add Folder")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleEditAction}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Edit Folder")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleDeleteAction}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Delete Folder")}
                </StyledMenuItem>

            </StyledMenu>
        </div>
    )
}



export function getContextMenuRule(state, handleClose, handleEditAction, handleEditActionNewDialog, handleDeleteAction, flagNewRuleForm) {
    return (
        <div>
            <StyledMenu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <StyledMenuItem onClick={handleEditAction}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Edit Rule")}
                </StyledMenuItem>
                {flagNewRuleForm && flagNewRuleForm ===true && 
                <StyledMenuItem onClick={handleEditActionNewDialog}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Edit Rule (New Dialog Preview)")}
                </StyledMenuItem>
                }
                <StyledMenuItem onClick={handleDeleteAction}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Delete Rule")}
                </StyledMenuItem>

            </StyledMenu>
        </div>
    )
}


export function getContextMenuRuleComposed(state, handleClose, handleEditAction, handleDeleteAction) {
    return (
        <div>
            <StyledMenu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <StyledMenuItem onClick={handleEditAction}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Edit Composed Rule")}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleDeleteAction}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" style={{ marginLeft: '10%' }} />
                    </ListItemIcon>
                    {getStyleItemText("Delete Rule")}
∑                </StyledMenuItem>

            </StyledMenu>
        </div>
    )
}