import React from 'react';

import {
        makeStyles, TextField, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Grid, Typography,
        FormControl, InputLabel, Select, MenuItem, Checkbox, Tooltip, Button
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHammer } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import { styles, QualityTheme } from '../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import AqtivaDialog from 'components/common/AqtivaDialog'



import * as commonStyles from 'style/CommonStyles'

import {useControlValues} from 'service/controlvalues/useControlValues'

import ControlValueItemConfiguration from './ControlValueItemConfiguration'
import * as windowHelper from 'commonFunctions/windowHelper'
import { useDispatch } from 'react-redux';

import * as actions from 'actions'

export default function ControlValuesConfiguration(props) {
        const dispatch = useDispatch()
        const [ controlValues, getAllControlValues] = useControlValues()
        const [dialogNewItem, setDialogNewItem] = React.useState(false)
        const[tmpName, setTmpName] = React.useState(undefined)
        const[tmpValue, setTmpValue] = React.useState(undefined)
        const { height, width } = windowHelper.useWindowDimensions();
        React.useEffect(()=>{
                getAllControlValues()
        }, [])

        React.useEffect(()=>{
                console.log('controlValues', controlValues)
        }, [controlValues])
        
        function createNewControlValue(){
                setDialogNewItem(true)
        }
        function saveControlValue(){
                dispatch(actions.saveControlValue(tmpName, tmpValue))
                setDialogNewItem(false)
        }

        function validate(){
                if(tmpValue === undefined || tmpValue === '' || tmpName ===undefined || tmpName === ''){
                        return false
                }
                return true
        }
    
        return (
                <MuiThemeProvider theme={QualityTheme}>

                        <AqtivaDialog visible={dialogNewItem}
                                title={`Create New Control Value`}
                                maxWidth = {width}
                                titleIcon={faHammer}
                                cancelText={'Close'}
                                showConfirmButton={validate()}
                                confirmCallback={saveControlValue}
                                cancelCallback={event=>{setDialogNewItem(false)}}
                                >
                                <div style={{width: '300px', height:'130px'}}>
                                <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '6px' } }}
                                                id="nSamples"
                                                label={<Typography variant="caption">Name</Typography>}
                                                style={{width:'100%'}}
                                                margin="dense"
                                                value={tmpName}
                                                onChange= {event=>setTmpName(event.target.value)}
                                />
                                 <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '6px' } }}
                                                id="nSamples"
                                                label={<Typography variant="caption">Value</Typography>}
                                                style={{width:'100%'}}
                                                margin="dense"
                                                value={tmpValue}
                                                onChange= {event=>setTmpValue(event.target.value)}
                                />
                                </div>
                        </AqtivaDialog>
                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Control Values Configuration</Typography>
                                        </Grid>

                                </AccordionSummary>
                                <AccordionDetails>
                                        <div>
                                        <Button variant='outlined' color='primary' style={{marginBottom:'10px'}}
                                                onClick={createNewControlValue}>
                                                        Create New Control Value
                                        </Button>
                                        {controlValues && controlValues.length>0 && 
                                        controlValues.map(item=>{
                                                return <ControlValueItemConfiguration id={item._id} name = {item.name} value = {item.value} />
                                                }
                                                )
                                        }
                                        
                                          
                                        </div>



                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}