
import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export default function AqtivaLoading(props) {

    const logoWidth = '35px'
    const progressWidth = '65px'
    return (
        <div style={{
            width: '100%', height: '100%', display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>

            <Box position="relative" display="inline-flex">
                <CircularProgress style={{ width: progressWidth, height: progressWidth }} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <img alt="" style={{ width: logoWidth, height: logoWidth }}
                        src={require('resources/images/logo_omma_O.png')}
                    />
                </Box>
            </Box>
        </div>
    )
}