import React from 'react';

import QPIcon from 'resources/icons/point_proc1_black.png'
import DSIcon from 'resources/icons/data_proc3_black.png'
import RuleIcon from 'resources/icons/rule_proc1_black.png'

import Graph from "react-graph-vis";

import * as commonStyles from 'style/CommonStyles'

import { Grid, Paper,  Tabs, Tab } from '@material-ui/core'

import DQIChartAmChart from '../DQIChartAmChart';
import DiagramTitle from '../DiagramTitle';
import AqtivaLoading from 'components/common/AqtivaLoading';
import DQIDimensionsChartDetail from '../DQIDimensionsChartDetail'
import LineChartZoomAndScrollProjectStructure from '../LineChartZoomAndScrollProjectStructure'
import RuleDQIBar from '../RuleDQIBar'

import {  useDispatch } from 'react-redux'
import * as actions from 'actions'

import * as textHelper from 'components/common/Texts'


export default function QualityPointDetail(props) {



    const dispatch = useDispatch();

    const [hideNodes, setHideNodes] = React.useState()
    const [graph, setGraph] = React.useState()
    const [tabValue, setTabValue] = React.useState(0)
    const [project,setProject] = React.useState()

    


    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };


   

    React.useEffect(()=>{
        if(props.detailedDQIQp && props.detailedDQIQp.qualityPointId){
            dispatch(actions.fetchProjectAndReturn(props.detailedDQIQp.projectId)).then(response=>{
                setProject(response.result)
            })
        }
        

    },[props.detailedDQIQp])// eslint-disable-line react-hooks/exhaustive-deps

    const options = {
        layout: {
            hierarchical: {
                direction: "LR",
                levelSeparation:300
            },
        },
        autoResize: true,
        edges: {
            smooth: {
                type: "vertical",
                roundness: 0.8,
            },
        },
        nodes: {
            shape: "image",
            scaling: {
                label: {
                    min: 8,
                    max: 20,
                },
            },
        },
        physics: {
            enabled: false,
        },
    };

    React.useEffect(() => {
        setHideNodes(props.hideNodes)
    }, [props.hideNodes])

    React.useEffect(() => {


        if (props.detailedDQIQp !== undefined) {
            const DQI = getDQI4Object(props.detailedDQIQp)
            const mainNode = {
                id: props.detailedDQIQp.qualityPointId,
                font: { multi: "html", size: "12", color:DQI==='None' ? '#8f8f8f' : DQI > 90 ? '#000000' : DQI > 65 ? commonStyles.warningColor : commonStyles.errorColor },
                shape: "image",
                image: QPIcon,
                margin: 500,
                size: 25,
                label: [
                    "<b>Project:</b> <i>" + props.detailedDQIQp.name + "</i>",
                   `<b>DQI:</b> <i>${getDQI4Object(props.detailedDQIQp)}%</i>`,
                    `<b>Data:</b> <i>${100}%</i>`,
                ].join("\n"),
                level: 0,
                fixed: true,
            }

            

            const dsNodes = props.detailedDQIQp.datasourceDQI === undefined ? [] : (props.detailedDQIQp.datasourceDQI).filter(value => hideNodes === undefined ? true : !hideNodes.includes(value.parentId)).map(value => {
                const DQI = getDQI4Object(value)
                return (
                    {
                        id: value.datasourceId,
                        font: { multi: "html", size: "12", color: DQI==='None' ? '#8f8f8f' : DQI > 90 ? '#000000' : DQI > 65 ? commonStyles.warningColor : commonStyles.errorColor },
                        shape: "image",
                        image: DSIcon,
                        margin: 500,
                        size: 25,
                        label:  value.sumNumIn === 0 ?
                        [
                            "<b>QP:</b> <i>" + value.name + "</i>",
                            "<b>No Data</b> "
               
                        ].join("\n")
                        : [
                            "<b>DS:</b> <i>" + value.name + "</i>",
                            `<b>DQI:</b> <i>${getDQI4Object(value)}%</i>`,
                           `<b>Data:</b> <i>${Math.round(value.sumNumIn/props.detailedDQIQp.sumNumIn*10000)/100}%</i>`,
                        ].join("\n"),
                        level: 2,
                        fixed: true,
                    }
                )
            })

            const rulesNodes = props.detailedDQIQq ===undefined || props.detailedDQIQp.datasourceDQI === undefined ? [] : (props.detailedDQIQp.datasourceDQI
                .filter(value => hideNodes === undefined ? true : !hideNodes.includes(value.parentId)).flatMap(value => value.ruleDQI))
                .filter(value => hideNodes === undefined ? true : !hideNodes.includes(value.parentId))
                .map(value => {
                    const DQI = getDQI4Object(value)
                    return (
                        {
                            id: value.ruleId,
                            font: { multi: "html", size: "12", color: DQI==='None' ? '#8f8f8f' : DQI > 90 ? '#000000' : DQI > 65 ? commonStyles.warningColor : commonStyles.errorColor },
                            shape: "image",
                            image: RuleIcon,
                            margin: 500,
                            size: 25,
                            label:  value.sumNumIn === 0 ?
                            [
                                "<b>QP:</b> <i>" + value.name + "</i>",
                                "<b>No Data</b> "
                   
                            ].join("\n")
                            : [
                                "<b>Rule:</b> <i>" + value.name + "</i>",
                                `<b>DQI:</b> <i>${getDQI4Object(value)}%</i>`,
                                `<b>Data:</b> <i>${Math.round(value.sumNumIn/props.detailedDQIQp.sumNumIn*10000)/100}%</i>`,
                            ].join("\n"),
                            level: 3,
                            fixed: true,
                        }
                    )
                })



            

            const edgesDS = (props.detailedDQIQp.datasourceDQI.filter(value => hideNodes === undefined ? true : !hideNodes.includes(value.parentId))).map(value => {
                return (

                    { from: value.parentId, to: value.datasourceId }

                )
            })

            const rulesDS = (props.detailedDQIQp.datasourceDQI.filter(value => hideNodes === undefined ? true : !hideNodes.includes(value.parentId))
                .flatMap(value => value.ruleDQI))
                .filter(value => hideNodes === undefined ? true : !hideNodes.includes(value.parentId))
                .map(value => {
                    return (

                        { from: value.parentId, to: value.ruleId }

                    )
                })

            const graphTmp = {
                edges: [ ...edgesDS, ...rulesDS],
                nodes: [mainNode,  ...dsNodes, ...rulesNodes]
            }
            setGraph(graphTmp)
        }

    },
        [props.detailedDQIQp, hideNodes])// eslint-disable-line react-hooks/exhaustive-deps

    function getDQI4Object(obj) {
       
        var returnValue='None'
        if (obj.sumNumIn === 0) {
            returnValue= 'None'
        }
        else {
            returnValue= Math.round(obj.sumNumOk / obj.sumNumIn * 10000) / 100
        }
        return returnValue;
    }

   

    return (
        <div style={{width:'100%', height:'100%'}}>
            <Tabs value={tabValue} onChange={handleChangeTab}
                variant="scrollable" scrollButtons="off"
                style={{ width: '100%', paddingTop: '-20px' }}>
                {/*<Tab label="Project Info" />*/}
                <Tab label="Project Dashboard" />
                <Tab label="Project Tree DQI" />
            </Tabs>

            {/* tabValue === 0 &&
                <ProjectInfoDetail project={project} />*/}

            {tabValue === 0 &&
                <Grid container spacing={1} style={{ width: '100%' }}>
                    <Grid item xs={4} >
                        <Paper style={{ width: '100%', height: '210px' }} >
                            <DiagramTitle title='Project DQI' tooltip={textHelper.globalDQIText} />
                            {props.dQI4DimensionsFlag === true ? <AqtivaLoading />
                                :
                                <DQIChartAmChart data={props.detailedDQIQp !== undefined ? getDQI4Object(props.detailedDQIQp) : -1} />}
                        </Paper>
                    </Grid>
                    <Grid item xs={8} >
                        <Paper style={{ width: '100%', height: '200px' }} >
                            <DiagramTitle title='Project DQI by Dimension' tooltip={textHelper.globalDQIText} />
                            {props.dQI4DimensionsFlag === true ? <AqtivaLoading />
                                :
                                <DQIDimensionsChartDetail data={props.detailedDQIQp ? props.detailedDQIQp.dimensionDQIList : []} />}


                        </Paper>
                    </Grid>

                    <Grid item xs={12} >
                        <Paper style={{ width: '100%', height: '200px' }} >
                        <DiagramTitle title='DQI Timeline Evolution' 
                    />
                        {props.detailedDQIQp && props.filterObj && 
                        <LineChartZoomAndScrollProjectStructure dataRaw={props.detailedDQIQp} 
                        startDate={props.filterObj.startDate} endDate={props.filterObj.endDate} />
                        }
                        </Paper>
                    </Grid>

                    <Grid item xs={6} >
                        <Paper style={{ width: '100%', height: '400px' }} >
                        <DiagramTitle title='DQI Timeline Evolution' 
                    />
                        {props.detailedDQIQp && props.filterObj && 
                        <RuleDQIBar dataRaw={props.detailedDQIQp}  project = {project}
                       />
                        }
                        </Paper>
                    </Grid>

                    
                     
                </Grid>
            }

            {tabValue === 1 && graph && graph.nodes && graph.nodes.length > 0 &&
                <Graph
                    graph={graph}
                    options={options}
                    style={{ height: "100%", width: "100%" }}
                    events={{
                        selectNode: (event) => {
                            var { nodes, edges } = event;
                            if(edges){} //TODO: AVOID WARNINGS
                            const node = nodes[0]
                            //check if node is in list
                            if (hideNodes.includes(node)) {
                                //remove node

                                const tmpHideNodes = hideNodes.filter(e => e !== node);

                                setHideNodes(tmpHideNodes)
                            }
                            else {
                                //add node
                                setHideNodes([...hideNodes, nodes[0]])
                            }

                        },



                    }}
                />
            }
        </div>
    )
}