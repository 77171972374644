import quality from '../apis/quality';
import {
    FETCH_UNREAD_NOTIFICATIONS,
    DELETE_NOTIFICATIONS,
    ADD_NOTIFICATION,
    FETCH_NOTIFICATIONS
    } from './types'
    import {axiosHeader, dispatchError} from '../components/common/axios/axiosHelper';
//import qualityLocalHost3 from '../apis/qualityLocalHost3';




export const fetchUnreadNotifications = (userId, props) => async (dispatch,getState) => {
    var returnValue= {}
    await quality.get(`/notification/fetch/unread/userId/${userId}`,{
        headers: axiosHeader()
      }).then(function (response) {
           returnValue=response
            dispatch( {
                type : FETCH_UNREAD_NOTIFICATIONS,
                payload: response.data
            });
            
        })
        .catch(function (error) {
           
            dispatchError(dispatch,error, props)
          });
          return returnValue
};
export const fetchNotifications = (userId, props) => async (dispatch,getState) => {
    var returnValue= {}
    //const endpoint = `/notification/mock/fetch/recent/userId/${userId}`
     const endpoint = `/notification/fetch/recent/userId/${userId}`
    await quality.get(endpoint,{
        headers: axiosHeader()
      }).then(function (response) {
        returnValue=response
            dispatch( {
                type : FETCH_NOTIFICATIONS,
                payload: response.data
            });
            
        })
        .catch(function (error) {
            dispatchError(dispatch,error, props)
          });
          return returnValue
};





export const addSimulationNotification = (userId, props) => async (dispatch,getState) => {
    var returnValue= {}
    await quality.post(`/notification/create`,undefined,{
        headers: axiosHeader()
      }).then(function (response) {
        returnValue=response
            dispatch( {
                type : ADD_NOTIFICATION,
                payload: response.data
            });
            
        })
        .catch(function (error) {
            dispatchError(dispatch,error,props)
          });
          return returnValue
};


export const markAsReadNotifications = (notificationId, userId,props) => async (dispatch,getState) => {
    var returnValue= {}
    await quality.post(`/notification/update/read/notificationId/${notificationId}/user/${userId}`,undefined,{
        headers: axiosHeader()
      }).then(function (response) {
        returnValue=response
            dispatch( {
                type : DELETE_NOTIFICATIONS,
                payload: notificationId
            });
            
        })
        .catch(function (error) {
            dispatchError(dispatch,error,props)
          });
          return returnValue
};