import React, { useEffect } from 'react';
import { TextField, Typography, Grid, FormControlLabel } from '@material-ui/core';
import AqtivaDialog from 'components/common/AqtivaDialog'
import * as errorHelper from '../../AddDataCredentialsDialog/Helpers/ErrorHelper'
import * as commonStyles from 'style/CommonStyles'



import BigQueryInputCredentials from '../../AddDataCredentialsDialog/ConnectionInfo/BigQueryConnectionInfo/BigQueryInputCredentials';




export default function EditBigQueryServerDialog(props) {

    const [displayName, setDisplayName] = React.useState('')
    const [encryptedCredentialsFile, setEncryptedCredentialsFile] = React.useState('')
    const [projectId, setProjectId] = React.useState('')
    //const [serviceAccountPrivateKey, setServiceAccountPrivateKey] = React.useState('')
    //const [blobStorage, setBlobStorage] = React.useState('')
    //const [containerName, setContainerName] = React.useState('')
    const [errors, setErrors] = React.useState(undefined)


    useEffect(() => {
        if (props.selectedDataCredentials && props.selectedDataCredentials.displayName && props.selectedDataCredentials.bigQueryCredentialsDTO) {
            setDisplayName(props.selectedDataCredentials.displayName)
            setProjectId(props.selectedDataCredentials.bigQueryCredentialsDTO.projectId ? props.selectedDataCredentials.bigQueryCredentialsDTO.projectId : '')
            //setServiceAccountPrivateKey(props.selectedDataCredentials.bigQueryCredentialsDTO.serviceAccountPrivateKey)

        }
    }, [props.selectedDataCredentials])

    async function editArrayElement(datacredentials) {
        let updatedDataCredentials = JSON.parse(JSON.stringify(props.selectedDataCredentials))
        let connectionObject = {}
        connectionObject.displayName = displayName
        connectionObject.projectId = projectId
        //connectionObject.blobStorage = blobStorage
        //connectionObject.containerName = containerName
        connectionObject.encryptedCredentialsFile = encryptedCredentialsFile

        updatedDataCredentials.bigQueryCredentialsDTO = connectionObject
        updatedDataCredentials.displayName = displayName
        let errorsToCheck = errorHelper.checkForErrorsBigQuery(updatedDataCredentials)

        if (!errorsToCheck.hasErrors) {
            let index = datacredentials.findIndex(x => x.id === updatedDataCredentials.id)
            datacredentials[index] = updatedDataCredentials
            props.setData(datacredentials)
            props.setUpdateCounter(props.updateCounter + 1)
            props.setShowEditDialog(false)
            resetState()
        }
        else {
            setErrors(errorsToCheck)
        }
    }

    function resetState() {
        setProjectId('')
        setDisplayName('')
        //setServiceAccountPrivateKey('')
        setErrors(undefined)
    }

    function onUpload(res) {
        console.log('archivo subido', res)
    }


    return (
        <>
            <AqtivaDialog visible={props.showEditDialog}
                maxWidth={'lg'}
                title={'update BigQuery server Credentials'}
                cancelText={'Close'}
                confirmText={'Store and Use'}
                showConfirmButton={true}
                cancelCallback={() => { props.setShowEditDialog(false) }}
                confirmCallback={async () => { editArrayElement(props.data) }}
            >
                <div style={{ width: '100%' }}>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display name:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="DisplayName"
                                    label="Display name"
                                    margin="dense"
                                    value={displayName}
                                    onChange={(event) => { setDisplayName(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Project Id:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="Server"
                                    label="Project Id"
                                    margin="dense"
                                    value={projectId}
                                    onChange={(event) => { setProjectId(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Crendentials:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BigQueryInputCredentials onUpload={onUpload} setEncryptedCredentialsFile={setEncryptedCredentialsFile} id={props.id}
                            buttonText={'Upload File'} />
                    </Grid>
                    {encryptedCredentialsFile && <Grid item xs={12}>
                        <div><Typography variant='subtitle2'>Credentials stored </Typography></div>
                    </Grid>}
                    <Grid item xs={12}>
                        {errors && errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{errors.bigQueryCredentials}</Typography>}
                    </Grid>
                </div>
            </AqtivaDialog>
        </>
    )
}