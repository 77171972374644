import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import * as commonStyles from 'style/CommonStyles'

export default function DataformatBasicInformationSqlDatabase(props) {


    return(
        <div style={{maxWidth:'500px'}}> 

        {props.dataformat.sqlDatabaseProperties &&
            <Grid container spacing={0} style={{margin:'10px'}}>
                <Grid item xs={12}>
                <Typography variant='subtitle1' style={{fontWeight:'bold'}}>Sql Database Properties </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='subtitle2'>Resource Group: </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='body2'>{props.dataformat.sqlDatabaseProperties.resourceGroup}</Typography>
                </Grid>

                <Grid item xs={4}> 
                    <Typography variant='subtitle2'>Server: </Typography>
                </Grid>
                <Grid item xs={8}>
                        <Typography variant='body2'>{props.dataformat.sqlDatabaseProperties.server}</Typography>
                </Grid>

                <Grid item xs={4}> 
                    <Typography variant='subtitle2'>Database: </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='body2'>{props.dataformat.sqlDatabaseProperties.database}</Typography>
                </Grid>

                <Grid item xs={4}> 
                    <Typography variant='subtitle2'>Schema: </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='body2'>{props.dataformat.sqlDatabaseProperties.schema}</Typography>
                </Grid>
                <Grid item xs={4}> 
                    <Typography variant='subtitle2'>Table: </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='body2'>{props.dataformat.sqlDatabaseProperties.table}</Typography>
                </Grid>
                

                
            </Grid>
             }
        </div>
    )
}