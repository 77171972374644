import * as enrichHelper from 'components/common/EnrichDataformatHelper'

 export async function getDatasetsForEnrichment(joinList, dispatch,fetchDataformat) {
    let datasetsForEnrichment = []
    for (let i = 0; i < joinList.length; i++) {
        const dataset = (await dispatch(fetchDataformat(joinList[i].datasourceEnrichId))).data
        
        datasetsForEnrichment.push(dataset)
    }
    return datasetsForEnrichment
}

export function createEnrichedDataset(originalDataset, joinList, datasetsList, numberOfJoinsInDataset) {
    let enrichedDataset = JSON.parse(JSON.stringify(originalDataset))
    var newColumns = enrichedDataset.columns
    
    for (let i = 0; i < joinList.length; i++) {
        var fitleredDEnrich = datasetsList.find(dataset => dataset!==undefined && dataset._id === joinList[i].datasourceEnrichId)
        let datasetToAddFrom = fitleredDEnrich === undefined ? fitleredDEnrich : JSON.parse(JSON.stringify(fitleredDEnrich))
        if (datasetToAddFrom) {
            var dataset2AddColumns =  datasetToAddFrom.columns
            if(joinList[i].aggregatedColumnsFlag && joinList[i].aggregatedColumnsFlag ===true && joinList[i].aggregationObject){
                dataset2AddColumns = enrichHelper.getColumnsFromAggregationObject(joinList[i].aggregationObject)
            }
            let mappingdestinationColumns = joinList[i].mappingsList.map(columnMapping => columnMapping.destinationColumn.name)
            newColumns = join2DatasetsColumns(newColumns,dataset2AddColumns, mappingdestinationColumns, datasetToAddFrom, joinList[i].alias)
        }
    }
    enrichedDataset.columns = newColumns
    return enrichedDataset
}

export function join2DatasetsColumns(originalColumns, columns2Add, mappingdestinationColumns, dataset, joinAlias) {

    for (let i = 0; i < columns2Add.length; i++) {
        // IF COMMENTED TO SHOW ALL COLUMNS FROM ENRICH DATASET
        //if (!mappingdestinationColumns.includes(columns2Add[i].name)) {
            let newColumn = JSON.parse(JSON.stringify(columns2Add[i]))
            newColumn.name =  newColumn.name.startsWith(joinAlias + '_') ? newColumn.name : joinAlias + "_" + newColumn.name
            newColumn.position = originalColumns.length
            newColumn.datasetName = dataset.name
            newColumn.datasetId = dataset._id
            originalColumns.push(newColumn)
       //}
    }
    return originalColumns

    /*  switch (join.joinType) {
         case "left_join":
 
             break
         case "inner_join":
 
             break
         case "left_outer_join":
 
             break
         case "full_outer_join":
 
             break
         case "left_anti":
 
             break
 
 
     } */
}
