//import qualityLocalHost2 from 'apis/qualityLocalHost2';
import quality from '../apis/quality';
import qualityLocalHost5 from '../apis/qualityLocalHost5';

import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';

export const listHiveDatabases = (credentials) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/databricks/list/hive/databases`, credentials, {
        headers: axiosHeader()
    }).then(function (response) {

        returnValue = response.data

    })
        .catch(function (error) {
            dispatchError(dispatch, error)

            returnValue = { result: 'ko', error: error }
        });


    return returnValue
}

export const listHiveTables = (credentials, databaseName) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/databricks/list/hive/tables/${databaseName}`, credentials, {
        headers: axiosHeader()
    }).then(function (response) {

        returnValue = response.data

    })
        .catch(function (error) {
            dispatchError(dispatch, error)

            returnValue = { result: 'ko', error: error }
        });


    return returnValue
}
//TODO: que es listHiveDatabase2 ? este nombre no se puede permitir
export const listHiveDatabases2 = (credentials, type) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`filestorage/connector/jdbc/databases/${type}`, credentials, {
        headers: axiosHeader()
    }).then(function (response) {

        returnValue = response.data

    }).catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
    });


    return returnValue
}

export const listHiveTables2 = (credentials, type, database, schema) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`filestorage/connector/jdbc/tables/${type}/${database}/${schema}`, credentials, {
        headers: axiosHeader()
    }).then(function (response) {

        returnValue = response.data

    })
        .catch(function (error) {
            dispatchError(dispatch, error)

            returnValue = { result: 'ko', error: error }
        });


    return returnValue
}


export const listHiveSchemas = (credentials, type, database) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`filestorage/connector/jdbc/schemas/${type}/${database}`, credentials, {
        headers: axiosHeader()
    }).then(function (response) {

        returnValue = response.data

    })
        .catch(function (error) {
            dispatchError(dispatch, error)

            returnValue = { result: 'ko', error: error }
        });


    return returnValue
}




export const createPreviewDataJobHive = (environment, userId, credentials, nSamples, flatten) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/databricks/hive/preview/${userId}/${environment}`, credentials, {
        headers: axiosHeader()
    }).then(function (response) {

        returnValue = response.data
        dispatchInfo(dispatch, `Data preview job running`)

    })
        .catch(function (error) {
            dispatchError(dispatch, error)

            returnValue = { result: 'ko', error: error }
        });


    return returnValue
}


export const createProfilingDataJobHive = (environment, userId, credentials, nSamples, flatten) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/databricks/hive/profiling/${userId}/${environment}`, credentials, {
        headers: axiosHeader()
    }).then(function (response) {

        returnValue = response.data
        dispatchInfo(dispatch, `Data preview job running`)

    })
        .catch(function (error) {
            dispatchError(dispatch, error)

            returnValue = { result: 'ko', error: error }
        });


    return returnValue
}