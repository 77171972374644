import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

import { styles, QualityTheme } from '../../../../../../../style/CustomTheme'
import { Typography, TextField, FormControlLabel, MuiThemeProvider } from '@material-ui/core';
import AqtivaDialogEncryptString from '../../../../../../common/AqtivaDialogEncryptString'
import classNames from 'classnames';
import BigQueryInputCredentials from 'components/dataCredentials/DataCredentialsTable/AddDataCredentialsDialog/ConnectionInfo/BigQueryConnectionInfo/BigQueryInputCredentials';


export default function BigQueryCard(props) {


    const classes = makeStyles(styles(QualityTheme));

    //const [visibleStorageSasToken, setVisibleStorageSasToken] = React.useState(false)
    const [visibleStorageToken, setVisibleStorageToken] = React.useState(false)

    //const [resourceGroupName, setResourceGroupName] = React.useState('')
    //const [storageKey, setStorageKey] = React.useState('')
    function setStorageKey(){}

    const [projectId, setProjectId] = React.useState(props.configuration && props.configuration.bigQueryProperties &&
        props.configuration.bigQueryProperties.projectId ?
        props.configuration.bigQueryProperties.projectId : '')
    const [region, setRegion] = React.useState(props.configuration && props.configuration.bigQueryProperties &&
        props.configuration.bigQueryProperties.region ?
        props.configuration.bigQueryProperties.region : '')

    const [bucket, setBucket] = React.useState(props.configuration && props.configuration.bigQueryProperties &&
        props.configuration.bigQueryProperties.bucket ?
        props.configuration.bigQueryProperties.bucket : '')
    const [credentialsFile, setCredentialsFile] = React.useState(props.configuration && props.configuration.bigQueryProperties &&
        props.configuration.bigQueryProperties.credentialsFile ?
        props.configuration.bigQueryProperties.credentialsFile : '')



    React.useEffect(() => {
        setProjectId(props.storageInfo &&
            props.storageInfo.projectId ?
            props.storageInfo.projectId : '')
        setRegion(props.storageInfo &&
            props.storageInfo.region ?
            props.storageInfo.region : '')
        setBucket(props.storageInfo &&
            props.storageInfo.bucket ?
            props.storageInfo.bucket : '')
        setCredentialsFile(props.storageInfo &&
            props.storageInfo.encryptedCredentialsFile ?
            props.storageInfo.encryptedCredentialsFile : '')
    }, [props.storageInfo, props.parentConfiguration]);



    useEffect(() => {
        let storageProperties = {}
        storageProperties.projectId = projectId
        storageProperties.region = region
        storageProperties.bucket = bucket
        storageProperties.encryptedCredentialsFile = credentialsFile
        props.setStorageProperties(storageProperties)
    }, [projectId, region, bucket, credentialsFile])// eslint-disable-line react-hooks/exhaustive-deps

    function onUpload(res) {

        console.log('archivo subido', res)
    }

    return (
        <MuiThemeProvider theme={QualityTheme}>
            <AqtivaDialogEncryptString visible={visibleStorageToken}
                setVisible={setVisibleStorageToken}
                confirm={setStorageKey}
                sName={props.id && props.id + "sttkk"}
            />
            <div>
                <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                    <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                        <Typography variant="subtitle2"> Big Query Configuration</Typography>

                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="projectId"
                                    label="Project Id"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={projectId}
                                    onChange={(event) => { setProjectId(event.target.value) }}
                                />} />

                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="region"
                                    label="Region"
                                    type="test"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={region}
                                    onChange={(event) => { setRegion(event.target.value) }}
                                />} />
                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="bucket"
                                    label="Bucket"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={bucket}
                                    onChange={(event) => { setBucket(event.target.value) }}
                                />} />
                        <Typography variant="subtitle2" style={{ marginLeft: '5%', width: '50%' }} gutterBottom>Crendentials:</Typography>

                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <BigQueryInputCredentials onUpload={onUpload} setEncryptedC
                                    redentialsFile={setCredentialsFile} id={props.id}
                                    buttonText={'Upload File'} />
                            }
                        />
                    </div>
                </div>
            </div>
        </MuiThemeProvider >
    )
}