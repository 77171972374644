// we use this helper in case we want to check for errors before storing the credentials, we check for connection string errors as for displayName errors
export function checkForErrorsStorageAccount(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "A name for this connection is required."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.connectionMethod) {
        errorsToCheck.connectionMethod = "Please select a connection method."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
    if (dataStorageCredentialsDTO.connectionMethod === "connectionString") {
        if (dataStorageCredentialsDTO.sasMethod === "connectionString") {
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.blobEndpoint)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.fileEndpoint)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.tableEndpoint)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.sharedAccessSignature)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.expirationDate)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
        }
        if (dataStorageCredentialsDTO.sasMethod === "sasToken") {
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct Storage account name."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.sharedAccessSignature)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct SAS Token."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.expirationDate)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct SAS Token."
            }

        }

    }

    return errorsToCheck
}

export function checkForErrorsContainer(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "A name for this connection is required."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.connectionMethod) {
        errorsToCheck.connectionMethod = "Please select a connection method."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
    if (dataStorageCredentialsDTO.connectionMethod === "connectionString" && dataStorageCredentialsDTO.storageType === "azure_container") {
        if (dataStorageCredentialsDTO.sasMethod === "connectionString") {


            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.sharedAccessSignature)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.folder)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
        }
        if (dataStorageCredentialsDTO.sasMethod === "sasToken") {
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct Storage account name."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.sharedAccessSignature)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct SAS Token."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.folder)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct container name."
            }

        }
    }
    return errorsToCheck
}


// we use this helper in case we want to check for errors in connection string and display name hasn't been inserted yet
export function checkForErrorsConnectionString(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (dataStorageCredentialsDTO.connectionMethod && dataStorageCredentialsDTO.connectionMethod === "connectionString" && dataStorageCredentialsDTO.storageType && dataStorageCredentialsDTO.storageType === "azure_storage") {
        if (dataStorageCredentialsDTO.sasMethod && dataStorageCredentialsDTO.sasMethod === "connectionString") {
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.blobEndpoint)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.fileEndpoint)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.tableEndpoint)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.sharedAccessSignature)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.expirationDate)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
        }


    }
    if (dataStorageCredentialsDTO.connectionMethod && dataStorageCredentialsDTO.connectionMethod === "connectionString" && dataStorageCredentialsDTO.storageType && dataStorageCredentialsDTO.storageType === "azure_container") {
        if (dataStorageCredentialsDTO.sasMethod && dataStorageCredentialsDTO.sasMethod === "connectionString") {


            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.sharedAccessSignature)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
            if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.folder)) {
                errorsToCheck.hasErrors = true
                errorsToCheck.connectionString = "Please insert a correct connection string."
            }
        }
    }

    return errorsToCheck
}

// we use this helper in case we want to check for errors in connection sas and display name hasn't been inserted yet
export function checkForErrorsSASToken(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (dataStorageCredentialsDTO.connectionMethod === "connectionString") {
        if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.connectionString = "Please insert a correct Storage account name."
        }
        if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.sharedAccessSignature)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.connectionString = "Please insert a correct SAS Token."
        }
        if (!dataStorageCredentialsDTO.storageAccountCredentialsDTO || (dataStorageCredentialsDTO.storageAccountCredentialsDTO && !dataStorageCredentialsDTO.storageAccountCredentialsDTO.expirationDate)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.connectionString = "Please insert a correct SAS Token."
        }
    }

    return errorsToCheck
}

// we use this helper in case we want to check for errors in oracle credentials
export function checkForErrorsOracle(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "A name for this connection is required."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
    if (dataStorageCredentialsDTO.storageType === "oracle") {
        if (!dataStorageCredentialsDTO.oracleStorageCredentialsDTO || (dataStorageCredentialsDTO.oracleStorageCredentialsDTO && !dataStorageCredentialsDTO.oracleStorageCredentialsDTO.server)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.oracleCredentials = "Please insert a correct server name."
        }
        if (!dataStorageCredentialsDTO.oracleStorageCredentialsDTO || (dataStorageCredentialsDTO.oracleStorageCredentialsDTO && !dataStorageCredentialsDTO.oracleStorageCredentialsDTO.user)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.oracleCredentials = "Please insert a correct user name."
        }
        if (!dataStorageCredentialsDTO.oracleStorageCredentialsDTO || (dataStorageCredentialsDTO.oracleStorageCredentialsDTO && !dataStorageCredentialsDTO.oracleStorageCredentialsDTO.pwd)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.oracleCredentials = "Please insert a correct password."
        }
      
    }

    return errorsToCheck
}
// we use this helper in case we want to check for errors in sql server
export function checkForErrorsSqlServer(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "A name for this connection is required."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
    if (dataStorageCredentialsDTO.storageType === "sqlServer") {
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.server)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct server name."
        }
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.user)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct user name."
        }
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.pwd)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct password."
        }
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.database)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct database name."
        }
      
    }

    return errorsToCheck
}

// we use this helper in case we want to check for errors in sql server
export function checkForErrorsSynapse(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "A name for this connection is required."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
    if (dataStorageCredentialsDTO.storageType === "synapse") {
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.server)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct server name."
        }
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.user)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct user name."
        }
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.pwd)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct password."
        }
        if (!dataStorageCredentialsDTO.sqlServerCredentialsDTO || (dataStorageCredentialsDTO.sqlServerCredentialsDTO && !dataStorageCredentialsDTO.sqlServerCredentialsDTO.database)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.sqlServerCredentials = "Please insert a correct database name."
        }
      
    }

    return errorsToCheck
}

// we use this helper in case we want to check for errors in sql server
export function checkForErrorsMySQLServer(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "A name for this connection is required."
        errorsToCheck.hasErrors = true
    }
    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
    if (dataStorageCredentialsDTO.storageType === "mySQL") {
        if (!dataStorageCredentialsDTO.mySQLCredentialsDTO || (dataStorageCredentialsDTO.mySQLCredentialsDTO && !dataStorageCredentialsDTO.mySQLCredentialsDTO.server)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.mySQLCredentials = "Please insert a correct server name."
        }
        if (!dataStorageCredentialsDTO.mySQLCredentialsDTO || (dataStorageCredentialsDTO.mySQLCredentialsDTO && !dataStorageCredentialsDTO.mySQLCredentialsDTO.user)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.mySQLCredentials = "Please insert a correct user name."
        }
        if (!dataStorageCredentialsDTO.mySQLCredentialsDTO || (dataStorageCredentialsDTO.mySQLCredentialsDTO && !dataStorageCredentialsDTO.mySQLCredentialsDTO.pwd)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.mySQLCredentials = "Please insert a correct password."
        }
        if (!dataStorageCredentialsDTO.mySQLCredentialsDTO || (dataStorageCredentialsDTO.mySQLCredentialsDTO && !dataStorageCredentialsDTO.mySQLCredentialsDTO.schema)) {
            errorsToCheck.hasErrors = true
            errorsToCheck.mySQLCredentials = "Please insert a correct schema name."
        }
      
    }

    return errorsToCheck
}


// we use this helper in case we want to check for errors in big query
export function checkForErrorsBigQuery(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false

    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "Display name is required for this connection ."
        errorsToCheck.hasErrors = true
    }
   
    if (!dataStorageCredentialsDTO.bigQueryCredentialsDTO.projectId) {
        errorsToCheck.projectId = "Project Id is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
    if (dataStorageCredentialsDTO.storageType === "bigQuery") {

        if (!dataStorageCredentialsDTO.bigQueryCredentialsDTO || (dataStorageCredentialsDTO.bigQueryCredentialsDTO && !dataStorageCredentialsDTO.bigQueryCredentialsDTO.encryptedCredentialsFile)) {
            errorsToCheck.bigQueryCredentials = "Please insert a correct crendentials."
            errorsToCheck.hasErrors = true
        }
    }

    return errorsToCheck
}

export function checkForErrorsAWS(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false

    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "Display name is required for this connection."
        errorsToCheck.hasErrors = true
    }
   
    if (!dataStorageCredentialsDTO.awsCredentialsDTO.region) {
        errorsToCheck.region = "region is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.awsCredentialsDTO.accessKey) {
        errorsToCheck.accessKey = "Access Key is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.awsCredentialsDTO.encryptedSecretKey) {
        errorsToCheck.encryptedSecretKey = "Secret Key is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
   
    return errorsToCheck
}

export function checkForErrorsHive(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false

    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "Display name is required for this connection."
        errorsToCheck.hasErrors = true
    }
   
    if (!dataStorageCredentialsDTO.hiveCredentialsDTO.host) {
        errorsToCheck.host = "Host is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.hiveCredentialsDTO.username) {
        errorsToCheck.username = "Username is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.hiveCredentialsDTO.password) {
        errorsToCheck.password = "Password is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.hiveCredentialsDTO.engine) {
        errorsToCheck.engine = "Engine is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.hiveCredentialsDTO.port) {
        errorsToCheck.port = "Port is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.hiveCredentialsDTO.dbInstanceIdentifier) {
        errorsToCheck.dbInstanceIdentifier = "Instance is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
   
    return errorsToCheck
}

export function checkForErrorsRedshift(dataStorageCredentialsDTO) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false

    if (!dataStorageCredentialsDTO.displayName) {
        errorsToCheck.displayName = "Display name is required for this connection."
        errorsToCheck.hasErrors = true
    }
   
    if (!dataStorageCredentialsDTO.redshiftCredentialsDTO.host) {
        errorsToCheck.host = "Host is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.redshiftCredentialsDTO.username) {
        errorsToCheck.username = "Username is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.redshiftCredentialsDTO.password) {
        errorsToCheck.password = "Password is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.redshiftCredentialsDTO.engine) {
        errorsToCheck.engine = "Engine is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.redshiftCredentialsDTO.port) {
        errorsToCheck.port = "Port is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.redshiftCredentialsDTO.dbInstanceIdentifier) {
        errorsToCheck.dbInstanceIdentifier = "Instance is required for this connection."
        errorsToCheck.hasErrors = true
    }

    if (!dataStorageCredentialsDTO.storageType) {
        errorsToCheck.storageType = "Please select a resource type."
        errorsToCheck.hasErrors = true
    }
   
    return errorsToCheck
}

