import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import * as commonStyles from 'style/CommonStyles'




export default function ReturnPreviousAgregationButton(props) {

   

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button style={commonStyles.btnBack} onClick={(event) => { props.returnToParent() }} startIcon={<ArrowBack/>}>return</Button>
            </div>
        </>

    );
}