import React from "react";

import { useInterval } from 'components/common/useInterval'
import * as actions from 'actions'
import { useDispatch } from 'react-redux'
import { CircularProgress,Typography,
    TableContainer,Paper,Table,TableHead, TableRow,TableCell,
TableBody} from  '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'


export default function DetailSimulationTable(props) {

    const dispatch = useDispatch()
    const [data,setData] = React.useState([])

    const iconSize = 15
    
    useInterval(async () => {
        if(!props.stopInterval){
            let tmp = await dispatch(actions.getDetailsStreamSimulation(props.userId))
            if(tmp!==undefined && tmp!==null && tmp.length>0){
                const newData = tmp.filter(x=>x.simulationId===props.simulationId)
                setData(newData)
    
                if(newData.map(x=>x.state).includes("SUCCEED")){
                    props.setStopInterval(true)
                }
            }
            
        }
        else{
            //dispatch(actions.getEHubsData())
        }
        
    }
    ,1000)
    return(
        <div>
                <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="left">Step</TableCell>
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                {data.map((row,index) => 
                                           <TableRow key={index}>
                                           <TableCell component="th" scope="row">
                                               {<FontAwesomeIcon icon={faCheckCircle} 
                                               style={{marginTop:'2px',color:'#228247', fontSize:iconSize}} />}
                                               
                                           </TableCell>
                                           <TableCell component="th" scope="row">
                                                   <Typography variant='caption'>
                                                                   {row.state}
                                                   </Typography>
                                           </TableCell>
                                       </TableRow>
                                            
                                        )}
                                {!props.stopInterval && <TableRow key={-1}>
                                                <TableCell component="th" scope="row">
                                                <CircularProgress size={iconSize} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                        <Typography variant='caption'>
                                                                        Processing
                                                        </Typography>
                                                </TableCell>
                                            </TableRow>}
                                </TableBody>
                                </Table>
                                </TableContainer>
        </div>
    )
}
