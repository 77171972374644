import React from 'react';

import {
         TextField, Grid, Typography
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';

import * as actions from 'actions';
import { useDispatch } from 'react-redux';

export default function ControlValueItemConfiguration(props) {
        const dispatch = useDispatch()
        const [tmpValue, setTmpValue] = React.useState(props.value)
        const theme = useTheme()

        function saveItem(){
                dispatch(actions.updateControlValue(props.id,tmpValue))
        }
    
        return (
                <div style={{width:'1200px', marginTop:'5px'}}>
                         <Grid container spacing={3}>
                                <Grid item xs = {1}>
                                <IconButton color="primary" aria-label="save picture" component="span" style={{marginTop:'-10px'}}
                                onClick={event=>saveItem()}>
                                <SaveIcon />
                                </IconButton>
                                </Grid>
                                <Grid item xs={3}>
                                        <Typography variant='subtitle2'>{props.name}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                              
                                                <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '6px' } }}
                                                id="nSamples"
                                                label={<Typography variant="caption">Value</Typography>}
                                                style={{width:'100%', marginTop:'-20px'}}
                                                margin="dense"
                                                value={tmpValue}
                                                onChange= {event=>setTmpValue(event.target.value)}
                                                
                                            />
                                </Grid>
                        </Grid>
                        
                        
                </div>
        )
}