import React from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as commonStyles from 'style/CommonStyles'

export default function BarChartSmall(props) {

  const [dataPos, setDataPos] = React.useState([])
  const [dataNeg, setDataNeg] = React.useState([])

  React.useEffect(() => {
    if (props.data && props.data.length > 0) {
      

      const dataNoReduce= (props.data.map(dt => {
        if (dt.createTime) {
            var date = new Date(dt.createTime)
            date.setHours(0, 0, 0, 0);
            return {
                ...dt,
                "chartDate": date,
                'valuePos': dt.numOk,
                "valueNeg" : -1*dt.numKo
            }
        }
        else {
            return {
                ...dt,
                "chartDate": new Date(),
                'valuePos': dt.numOk,
                "valueNeg" : -1*dt.numKo
            }
        }
    }))
    var dataReduce = []
    dataNoReduce.reduce(function (res, value) {
        if (!res[value.chartDate]) {
            res[value.chartDate] = { chartDate: value.chartDate, valuePos: 0, valueNeg:0, count: 0 };
            dataReduce.push(res[value.chartDate])
        }
        res[value.chartDate].valuePos += value.valuePos;
        res[value.chartDate].valueNeg += value.valueNeg;
        
        res[value.chartDate].count += 1;
        
        return res;
    }, {})

    const auxPos = dataReduce.map(x=>{return {fecha: x.chartDate.getTime(), value: x.valuePos>0 ? x.valuePos/x.count: 0}})
    const auxNeg = dataReduce.map(x=>{return {fecha: x.chartDate.getTime(), value: x.valueNeg<0 ? x.valueNeg/x.count: 0}})

      setDataPos(auxPos)
      setDataNeg(auxNeg)
    }
  }, [props.data])

  React.useLayoutEffect(() => {
    let maxValueTmp = Math.max(...dataPos.map(x=>x.value))*1.2
    let minValueTmp = Math.max(...dataNeg.map(x=>x.value))*1.2

    let maxValue = Math.max(maxValueTmp,minValueTmp*-1)
    let minValue = maxValue*-1


    let root = am5.Root.new(props.chart);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      maxZoomLevel: 1
    }));
    chart.zoomOutButton.set("forceHidden", true);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.5,
      baseInterval: {
          timeUnit: "day",
          count: 1
      },
      renderer: am5xy.AxisRendererX.new(root, {
      }),
      visible: false
  }));
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: minValue,
            max: maxValue,
            strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(root, {}),
      visible: false
    }));



    yAxis.data.setAll(dataPos);
    yAxis.data.setAll(dataNeg);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "fecha",
      fill: commonStyles.colorOk
    }));

    series.data.setAll(dataPos);

    let seriesNeg = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "fecha",
      fill: commonStyles.colorKo
    }));
    seriesNeg.data.setAll(dataNeg);

    return () => {
      chart.dispose()
    }


  }, [dataPos, dataNeg]) // eslint-disable-line react-hooks/exhaustive-deps


  if(props.fullscreen === true){
    return (
      <div id={props.chart} style={{ width: props.width ? props.width : '100%', height:"100px", marginTop:'0px', 
      marginLeft: props.marginLeft ? props.marginLeft : '0px', marginBottom:  '0px' }}></div>
  )
  }
  else{
    return (
      <div id={props.chart} style={{ width: props.width ? props.width : '100%', height: props.height ? props.height :"100px", marginTop:'-15px', 
      marginLeft: props.marginLeft ? props.marginLeft : '0px', marginBottom: props.marginBottom ? props.marginBottom : '0px' }}></div>
  )
  }
  
}