import React from "react";
import AppBarTemplate from "../../template/AppBarTemplate";
import axiosApiHelper from '../../common/axios/axiosApiHelper';
import MaterialTable from "@material-table/core";
import moment from 'moment'
import UsersTable from './UsersTable'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import GroupIcon from '@material-ui/icons/Group'
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import {axiosHeader} from '../../../components/common/axios/axiosHelper';
import trackEvent from './../../../trackEvent'




export default function LicensePage(props) {

    
    const [licenses, setLicenses] = React.useState(undefined)
    const [licenseTypes,setLicenseTypes] = React.useState([])
    //const [oluvionModules,setOluvionModules] = React.useState([])

   
  

      async function fetchArrayData(url,setFunction) {
        await axiosApiHelper.get(url,{
            headers: axiosHeader()
          }).then(response => {
              setFunction (Array.from(response.data))
             

              }
              )
          .catch(error=>console.error(error))
      }

   

    function parseData(licenseList){
        if(licenseList){
            return licenseList.map(x=>parseLicense(x))
        }
        return []
       
    }

    const parseLicense = (license) =>{
        const l = {
            isUserLicense: license.isUserLicense,
            licenseType: license.licenseType,
            initDate: moment(Date.parse(license.initDate)).format('YYYY-MM-DD'),
            endDate : moment(Date.parse(license.endDate)).format('YYYY-MM-DD'),
            numMaxUsers : license.numMaxUsers,
            users: license.users
        }
        return l
    }

    React.useEffect(() => {
      fetchArrayData('/license/all',setLicenses);      
  },[])
    React.useEffect(() => {
      fetchArrayData('/license/types',setLicenseTypes);
      
  },[])
  /*React.useEffect(() => {
    fetchArrayData('/license/modules',setOluvionModules);
    
  },[])*/
    

    return(
        
        <div >
            <AppBarTemplate history={props.history}/>
           
            <div style={{ maxWidth: "100%",
        position: 'absolute', left: '50%',
        transform: 'translate(-50%,0%)' }}>
                <MaterialTable
                columns={[
                    { title: "User License", field: "isUserLicense" ,render: rowData =>{
                      return(
                      <Checkbox
                      color='primary'
                      checked={rowData.isUserLicense}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />)
                    },
                    editComponent: props =>{
                      return(
                      <Checkbox
                      color='primary'
                      checked={props.value}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onChange={e => props.onChange(e.target.value)}
                    />)
                    },
                  },
                    { title: "License Type", field:"licenseType",editComponent: props => (
                      <Select
                          labelId="select-license-type"
                          id="select-license-type"
                          value={props.value}
                          onChange={e => props.onChange(e.target.value)}

                        >
                          {licenseTypes.map(data => 
                              <MenuItem value={data}>{data}</MenuItem>
                          )}
                          
                        </Select>
                    )},
                    { title: "Init Date", field: "initDate" ,
                    editComponent: props=>{
                      return(
                        <TextField
                        style={{fontSize:'2px'}}
                        id="date"
                        type="date"
                        defaultValue={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />)
                    }},
                    
                    { title: "End Date", field: "endDate" ,
                    editComponent: props=>{
                      return(
                        <TextField
                        style={{fontSize:'2px'}}
                        id="date"
                        type="date"
                        defaultValue={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />)
                    }},
                    { title: "Users allowed", field: "numMaxUsers",
                    editComponent: props=>{
                      return(
                        <TextField
                        style={{fontSize:'2px'}}
                        id="date"
                        type="number"
                        defaultValue={props.value}
                        onChange={e => props.onChange(e.target.value)}
                       
                      />)
                    } },
                    
                    
                ]}
                detailPanel={[
                    {
                        icon: props => <GroupIcon />,
                        tooltip: 'Show Surname',
                        render: rowData => {
                            return(
                              <UsersTable data = {rowData.users} />
                            )
                           
                        },
                      },
                ]}
                actions={[
                  {
                    icon: props => <PersonAddIcon/>,
                    tooltip: 'Add User',
                    onClick: (event) => {alert("You want to add a new row"); trackEvent('LicensePage','Person Add Action');
                  }
                  }
                ]}
                editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = licenses;
                            data.push(newData);
                            setLicenses(data)
                            resolve()
                          }
                          resolve()
                        }, 1000)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = licenses;
                            const index = data.indexOf(oldData);
                            data[index] = newData;
                            setLicenses(data)
                            resolve()
                          }
                          resolve()
                        }, 1000)
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            let data = licenses;
                            const index = data.indexOf(oldData);
                            data.splice(index, 1);
                            setLicenses(data)
                            resolve()
                          }
                          resolve()
                        }, 1000)
                      }),
                  }}
                
                data={parseData(licenses)}
                title=""
                />
            </div>
        </div>
    )



}