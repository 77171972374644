import React from 'react';

import { Typography, Grid, Button, TextField, Tooltip, FormControl, Fab, Chip } from '@material-ui/core';
import { IDataformat } from 'interfaces/DataformatInterfaces';
import { IColumn } from 'interfaces/RuleInterfaces';

import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import * as commonStyles from 'style/CommonStyles'


type IProps = {
    baseDataFormat: IDataformat,
    aggregationObject: any,
    setAggregationObject: (newValue: any) => (any),
    originalDF: IDataformat,
    aggregatePrior2Join: boolean,
    aggregateFlag: boolean

};

type IOperationType = {
    id: string,
    column?: IColumn,
    operation: string,
    alias: string
}

/*const useStyles = makeStyles((theme) => ({
  formControl: {
      minWidth: 10,
      marginLeft: '2%'
  },
}));*/


export default function ColumnsSelectorAggregated(props: IProps) {

    //const classes = useStyles();
    const [selectedGroupByColumns, setSelectedGroupByColumns] = React.useState<IColumn[]>([])
    const [operations, setOperations] = React.useState<IOperationType[]>([])



    const [isError, setIsError] = React.useState<any[]>([])
    const operationsOptions = ['COUNT', 'MAX', 'MIN', 'SUM', 'AVG', 'NONE']



    React.useEffect(() => {        //Aqui meter una comprobación para cargar los datos de agrupaciones existentes en operations
        if (props.aggregationObject && props.aggregationObject.aggregationOperations && props.aggregationObject.aggregationOperations
            && props.aggregationObject.aggregationOperations.length > 0) {
            setOperations(props.aggregationObject.aggregationOperations)

        } else {
            let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)

            setOperations([
                {
                    id: newId,
                    column: undefined,
                    operation: '',
                    alias: '',
                }
            ])
        }
        if (props.aggregationObject && props.aggregationObject.groupColumns) {
            setSelectedGroupByColumns(props.aggregationObject.groupColumns)
        }


    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {

        //if (validateOperations()) {
        props.setAggregationObject({
            aggregationOperations: cleanOperations(),
            groupColumns: selectedGroupByColumns
        })
        //}

    }, [selectedGroupByColumns, operations ? JSON.stringify(operations) : []]) // eslint-disable-line react-hooks/exhaustive-deps


    function cleanOperations() {
        if (operations && operations.length > 0) {
            return operations.filter(x => x.alias !== '' && x.column && x.operation !== '')
        }
    }

    function addOperation() {
        let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
        let newOperations = JSON.parse(JSON.stringify(operations));
        newOperations.push({
            id: newId,
            column: '',
            operation: '',
            alias: '',
        })

        setOperations(newOperations)
    }
    function setAliasOperation(el: IOperationType, name: string) {
        el.alias = name
        validateAlias(el)
        let newOperations: IOperationType[] = JSON.parse(JSON.stringify(operations));
        let index = newOperations.findIndex(x => x.id === el.id)
        newOperations[index] = el
        setOperations(newOperations)
    }

    function validateAlias(op: IOperationType) {
        if (op.alias === '' || op.alias === undefined) {
            isError.push(
                {
                    id: op.id,
                    type: 'name_empty'
                }
            )
            setIsError(isError)
        } else {

            if (operations.filter(x => { return x.alias === op.alias }).length > 1) {
                isError.push(
                    {
                        id: op.id,
                        type: 'name'
                    }
                )
                setIsError(isError)
            } else {
                let error = isError.findIndex(x => x.id === op.id && x.type === 'name')
                if (error !== undefined) {
                    isError.splice(error, 1)
                    setIsError(isError)
                }
            }
        }
    }
    function handleDelete(element: IOperationType, index: number) {

        if (index !== 0) {
            let newOperations: IOperationType[] = JSON.parse(JSON.stringify(operations));
            newOperations = newOperations.filter(function (el) {
                return el.id !== element.id;
            });

            setOperations(newOperations)
        } else {
            console.log('operation0', operations[0])
            operations[0].alias = ''
            operations[0].operation = ''
            operations[0].column = undefined
            setOperations(JSON.parse(JSON.stringify(operations)))
        }
    }
    function setColSelected(el: IOperationType, column: IColumn | null) {
        el.column = column && column !== null ? column : undefined
        let newOperations: IOperationType[] = JSON.parse(JSON.stringify(operations));
        let index = newOperations.findIndex(x => x.id === el.id)
        newOperations[index] = el
        setOperations(newOperations)
    }
    function setOperationSOptionselected(el: IOperationType, op: string | null) {
        el.operation = op && op !== null ? op : ''
        let newOperations: IOperationType[] = JSON.parse(JSON.stringify(operations));
        let index = newOperations.findIndex(x => x.id === el.id)
        newOperations[index] = el
        setOperations(newOperations)
    }

    function removeColumn(index: number) {

        let newSelectedGroupByColumns = JSON.parse(JSON.stringify(selectedGroupByColumns));
        newSelectedGroupByColumns.splice(index, 1)

        setSelectedGroupByColumns(newSelectedGroupByColumns)
    }

    function getColumns() {
        var cols: Array<IColumn> = []
        if (props.aggregateFlag === true && props.aggregatePrior2Join === true) {
            if (props.originalDF) {
                cols = props.originalDF.columns
            }
        }
        else {
            if (props.baseDataFormat) {
                cols = props.baseDataFormat.columns
            }
        }
        if(cols === undefined || cols === null){
            return []
        }
        return not(cols, selectedGroupByColumns)
    }
    function not(a: IColumn[], b: IColumn[]) {
        return a.filter((value) => b.map(val => val.position).indexOf(value.position) === -1);
    }

    return (
        <div style={{ height: '100%', width: '100%' }}>


            <Grid container spacing={1} style={{ justifyContent: 'space-between', marginLeft: '2%' }}>
                <Grid item xs={3}  >
                    <Typography variant='subtitle2'> Group By</Typography>

                    <Autocomplete

                        multiple={true}

                        onChange={(event, newValue) => {
                            setSelectedGroupByColumns(newValue)
                        }}
                        renderOption={(option) => <Typography variant='caption' noWrap>{`#${option.position} ${option.name} (${option.type})`}</Typography>}
                        value={selectedGroupByColumns}
                        renderTags={(value, getTagProps) => {
                            return selectedGroupByColumns.map((option, index) => (
                                <div>
                                    <Chip
                                        label={option.name}
                                        key={option.name}
                                        onDelete={event => removeColumn(index)}
                                    />
                                </div>
                            ))
                        }
                        }
                        options={getColumns()}
                        getOptionLabel={(data) => (data) ? `${data.name}` : ''}
                        renderInput={(e) => <TextField {...e} label="Select column" />}
                    />

                </Grid>



                <Grid item xs={9}  >

                    <Typography variant='subtitle2'> Aggregation Columns</Typography>
                    {operations && operations.length > 0 && operations.map((operation, index) => {
                        let error = isError && isError.length > 0 ? isError[isError.findIndex(x => x.id === operation.id)] : false
                        return (
                            <Grid container spacing={0} >
                                <Grid item xs={3} style={{ marginLeft: '15px' }}>

                                    <Autocomplete
                                        style={{ minWidth: "20%" }}
                                        onChange={(event, newValue) => {
                                            setColSelected(operation, newValue)
                                        }}
                                        value={operation.column}
                                        options={getColumns()}
                                        getOptionLabel={(data) => (data) ? `${data.name}` : ''}
                                        renderInput={(e) => {
                                            return (
                                                <TextField {...e} label={operation && operation.column ? operation.column.name : "Select column"} />
                                            )
                                        }}
                                        renderOption={(option) => <Typography variant='caption' noWrap>{`#${option.position} ${option.name} (${option.type})`}</Typography>}
                                    />

                                </Grid>

                                <Grid item xs={3} style={{ marginLeft: '15px' }}>

                                    <Autocomplete
                                        style={{ minWidth: "20%" }}

                                        onChange={(event, newValue) => {
                                            setOperationSOptionselected(operation, newValue)
                                        }}
                                        renderOption={(option) => <Typography variant='caption' noWrap>{option}</Typography>}
                                        options={(operationsOptions && operationsOptions.length > 0) ? operationsOptions : []}
                                        getOptionLabel={(data) => (data) ? `${data}` : ''}
                                        renderInput={(e) => <TextField {...e} label={operation && operation.operation && operation.operation !== '' ? operation.operation : "Select operation"} />}
                                    />

                                </Grid>

                                <Grid item xs={3}>

                                    <TextField style={{ width: '100%', marginTop: '20px', marginLeft: '15px' }}
                                        InputLabelProps={{ shrink: true, style: { fontSize: '10px' } }}
                                        id={`name${operation.id}`}
                                        placeholder="Alias"
                                        margin="dense"
                                        value={operation.alias}
                                        onChange={(event) => {
                                            setAliasOperation(operation, event.target.value)
                                        }}
                                    />

                                    <div style={{ marginLeft: '10%' }}>
                                        {
                                            error !== undefined && error !== false &&
                                            error.type && error.type === 'name' &&
                                            <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {'Name already exists'}</Typography>


                                        }
                                        {
                                            error !== undefined && error !== false &&
                                            error.type && error.type === 'name_empty' &&
                                            <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {'Name can´t be empty'}</Typography>

                                        }
                                    </div>

                                </Grid>
                                <Grid item xs={2}>
                                    {
                                        <FormControl style={{ marginTop: "10px" }}>
                                            <Tooltip title={"Delete"}>

                                                <Fab size="small" color="primary" aria-label="Detail"
                                                    onClick={() => { handleDelete(operation, index) }}>
                                                    <DeleteIcon style={{ color: "white", fontSize: 'calc(8px + 0.3vw)' }} />
                                                </Fab>
                                            </Tooltip>
                                        </FormControl>
                                    }
                                </Grid>
                                {index === (operations.length - 1) && <Grid item xs={4}>
                                    <FormControl style={{ width: '90%' }}>
                                        <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "22%" }} color="primary"
                                            aria-controls="simple-menu"
                                            //startIcon={<FilterListIcon />}
                                            aria-haspopup="true"
                                            onClick={addOperation}
                                        >
                                            Add operation
                                        </Button>
                                    </FormControl>
                                </Grid>}

                            </Grid>
                        )

                    })}

                </Grid>

            </Grid>


        </div>
    )
}
