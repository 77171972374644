
import React from 'react';
import { Card, Typography, CardContent, CardHeader, Avatar, IconButton } from '@material-ui/core';
import { ILogicColumnCondition, ILogicSqlExpression } from 'interfaces/LogicCondition';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as commonStyles from 'style/CommonStyles'

import DeleteIcon from '@material-ui/icons/Delete';
import DialogEditConditionOperator from './DialogEditConditionOperator';
import { IColumn } from 'interfaces/RuleInterfaces';
import DialogEditSqlExpresion from './DialogEditSqlExpresion';

type IProps = {
  sqlExpression: ILogicSqlExpression,
  height: number,
  width: number
  deleteExpression: (value: any) => any,
  editExpression: (value: ILogicSqlExpression, ix: number) => any,
  ixOp: number,
  columns: IColumn[],
  numOperations: number
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: commonStyles.mainColor
    },
  }),
);

function getColumnConditionString(condition: ILogicColumnCondition) {
  if (condition.params?.nullCheck === true) {
    return `${condition.columnName} is null`
  }
  else if (condition.params?.notNullCheck === true) {
    return `${condition.columnName} is not null`
  }
  else if (condition.params?.valueRangeBool && condition.params?.valueRangeBool.length > 0) {
    return `${condition.columnName} in ${condition.params?.valueRangeBool ? condition.params?.valueRangeBool.join(',') : 'None'}`
  }
  else if (condition.params?.operator === 'in') {
    return `${condition.columnName} in ${condition.params?.listValues ? condition.params?.listValues.join(',') : 'None'}`
  }
  else if (condition.params?.operator === 'between') {
    return `${condition.columnName} between ${condition.params?.lowerValue} and ${condition.params?.greaterValue}`
  }
  else {
    return `${condition.columnName} ${condition.params?.operator} than ${condition.params?.lowerValue ? condition.params?.lowerValue : condition.params?.greaterValue}`
  }
}

export default function PaperSqlExpression(props: IProps) {

  const [openEdit, setOpenEdit] = React.useState<boolean>(false)

  function closeDialogEdit() {
    setOpenEdit(false)
  }
  function openDialogEdit() {
    setOpenEdit(true)
  }
  console.log('llegamos')

  const classes = useStyles();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {<DialogEditSqlExpresion edit={openEdit} closeEdit={closeDialogEdit} expresion={props.sqlExpression}
        ixOp={props.ixOp}
        editExpresion={props.editExpression} />}

      <Card elevation={3} style={{ width: `${props.width}px`, minHeight: '100px', height: `${props.height}px` }} >
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              E
            </Avatar>
          }
          action={
            <div style={{ display: 'flex', alignItems: 'right', flexDirection: props.numOperations >= 5 ? 'column' : 'row' }}>
              <IconButton aria-label="settings" onClick={openDialogEdit}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="settings">
                <DeleteIcon onClick={event => props.deleteExpression(props.sqlExpression)} />
              </IconButton>
            </div>

          }
          title={`SQL Expresion`}
          subheader={`Join by ${props.sqlExpression.selectedJoin}`}
        />

        <CardContent style={{ marginTop: '-30px' }}>
          <Typography variant='subtitle1'> {props.sqlExpression.sqlExpression}</Typography>
        </CardContent>

      </Card>
    </div>
  )

}