import React from 'react';
import MaterialTable from 'material-table'
import {Button } from '@material-ui/core'
//import DialogCreateEHubs from './DialogCreateEHubs';
import DialogAddEventHub from './../DialogAddEventHub'


export default function EHUbsTable(props) {


    //const [rowsPerPage, setRowsPerPage] = React.useState(5)
    //const [page, setPage] =React.useState(0)
    const [showDialogCreateEHubs, setShowDialogCreateEHubs] = React.useState(false)
    const [eventHubData, setEventHubData] = React.useState(props.configuration && props.configuration.eventHubs ? props.configuration.eventHubs :[])
    



    const columns = [
        {title: 'EndPoint', field: 'endPoint', defaultGroupOrder: 0, filtering: true},
        {title: 'SAK Name', field: 'sakName',defaultGroupOrder: 1, filtering: false,},
        {title: 'Entity Path (EventHub)', field: 'entityPath',  filtering: true, },
        
    ]

    React.useEffect(() => {
        setEventHubData(props.configuration && props.configuration.eventHubs ? props.configuration.eventHubs :[])  
    },[props.configuration])
    

    function updateEventHubsData(data){
        setEventHubData([...eventHubData, ...data])
        props.updateEventHubProps([...eventHubData, ...data])
    }

    return (
       
            <div>
                    <DialogAddEventHub open = {showDialogCreateEHubs} onClose = {event =>setShowDialogCreateEHubs(false)}
                    data = {eventHubData} updateEventHubsData = {updateEventHubsData}/>
                    <Button onClick = {event =>setShowDialogCreateEHubs(true)}> Add EventHub </Button>
                
                    <MaterialTable
                        title = {' '}
                        columns={columns}
                        data={eventHubData}
                        options={{
                            filtering: false,
                            exportButton: false,  
                            search: false,
                            toolbar: true,
                            grouping: true,
                            cellStyle:{
                                fontSize:'12px',
                                padding:'5px'
                            },
                        }}
                    />

            </div>
      
    )
}