import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import AppBarTemplate from 'components/template/AppBarTemplate'

import * as pathHelper from 'components/template/PathHelper'
import { Toolbar, Button, Divider, FormControlLabel, Switch, Typography } from '@material-ui/core';

import ReactGA from 'react-ga';

import { buttonDefinition } from './butonDefinition'
import { LanguageContext } from 'language/LanguageContext';
import { useTheme } from '@material-ui/core/styles';

import DatasetManagementTable from 'components/data/DatasetManagementTable'
import * as actions from 'actions'
import DataformatProfiling from 'components/data/QualityDatasetRepository/DataformatProfiling'
import DataformatDetail from 'components/data/QualityDatasetRepository/DataformatDetail'
import DataformatProjectGraph from 'components/data/QualityDatasetRepository/DataformatProjectGraph'
import DataformatAddProfiling from 'components/data/QualityDatasetRepository/DataformatAddProfiling'
import DialogSetUserPwd from 'components/dialogs/DialogSelectAzureSource/common/DialogSetUserPwd';
import DialogCreateDataFormat from 'components/dialogs/DialogCreateDataFormat'
import DialogSelectAzureSource from 'components/dialogs/DialogSelectAzureSource';

import * as renderHelper from 'components/data/QualityDatasetRepository/utils'
import { dispatchError } from "components/common/axios/axiosHelper";
import FilterDataset from './FilterDataset';
import DialogViewDataformatVersions from 'components/dialogs/DialogViewDataformatVersions';

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);



export default function DatasetManagementPage(props) {
  const dispatch = useDispatch()
  const languageContext = React.useContext(LanguageContext)
  const theme = useTheme();
  const user = useSelector(store => store.user)
  const license = useSelector(store => store.license)
  var configuration = useSelector(store => (store.qualityConfiguration))

  const [usedDatasourcesByProjects, setUsedDatasourcesByProjects] = React.useState([]);


  const [viewAsUser, setViewAsUser] = React.useState(props.selectDatasetDialog === true ? true : false)
  const [viewOnlyWithProfiling, setViewOnlyWithProfiling] = React.useState(false)

  const [selectedDataset, setSelectedDataset] = React.useState(undefined)

  const [showDialogCreateDataformatAzure, setShowDialogCreateDataformatAzure] = React.useState(false)
  const [showDialogCreateDataformat, setShowDialogCreateDataformat] = React.useState(false)
  const [showDialogDatasetDetail, setShowDialogDatasetDetail] = React.useState(false)
  const [showDialogDatasetProfiling, setShowDialogDatasetProfiling] = React.useState(false)
  const [showProjectUseDetailDialog, setShowProjectUseDetailDialog] = React.useState(false)
  const [showAddProfilingDialog, setShowAddProfilingDialog] = React.useState(false)
  const [showProjectUserAndPwd, setShowProjectUserAndPwd] = React.useState(false)
  const [sourceList, setSourceList] = React.useState([])
  const [ownersList, setOwnersList] = React.useState([])
  const [filterObj, setFilterObj] = React.useState(undefined)
  const [selectedDatasetToViewVersions, setSelectedDatasetToViewVersions] = React.useState(undefined)
  const [showDialogViewDataformatVersions, setShowDialogViewDataformatVersions] = React.useState(false)
  const [showDialogCreateNewDataFormatVersion, setShowDialogCreateNewDataFormatVersion] = React.useState(false)
  const [selectedDatasetForExportation, setSelectedDatasetForExportation] = React.useState(undefined)
  const [selectedDatasetForExportationId, setSelectedDatasetForExportationId] = React.useState(undefined)
  const { classes } = props;
  const [showDialogCreateDataformatNewVersionFromLocalFile, setShowDialogCreateDataformatNewVersionFromLocalFile] = React.useState(false)
  const [updateDataformatList, setUpdateDataformatList] = React.useState(false)

  React.useEffect(() => {
    if (user && license) {
      ExecutefetchUsedDatasourcesByProject(license._id, user.id)
    }
  }, [user, license]);// eslint-disable-line react-hooks/exhaustive-deps

  function ExecutefetchUsedDatasourcesByProject(licenseId, userId) {
    dispatch(actions.fetchUsedDatasourcesByProject(licenseId, userId)).then(response => {
      setUsedDatasourcesByProjects(response.data)
    })
  }

  function getSelectedDatasource(id) {
    dispatch(actions.fetchDataformat(id)).then(response => {
      setSelectedDataset(response.data)
    })
  }

  function viewCreateDataformat() {
    setShowDialogCreateDataformat(true)
  }
  function viewDatasetUseInProjects(id) {
    getSelectedDatasource(id)
    setShowProjectUseDetailDialog(true)
  }
  function viewDatasetProfiling(id) {
    getSelectedDatasource(id)
    setShowDialogDatasetProfiling(true)
  }
  function viewDatasetDetails(id) {
    getSelectedDatasource(id)
    setShowDialogDatasetDetail(true)
  }
  function viewAddProfiling(id) {
    getSelectedDatasource(id)
    setShowAddProfilingDialog(true)
  }
  function viewUpdateUserAndPwd(id) {
    getSelectedDatasource(id)
    setShowProjectUserAndPwd(true)
  }




  function onCloseProjectUseDetailDialog() {
    setShowProjectUseDetailDialog(false)
  }
  function onCloseProfilingDialog() {
    setSelectedDataset(undefined)
    setShowDialogDatasetProfiling(false)
  }
  function onCloseDetailDialog() {
    setShowDialogDatasetDetail(false)
  }
  function onCloseAddProfilingDialog() {
    setShowAddProfilingDialog(false)
  }
  function onCloseCreateDataformat() {
    setShowDialogCreateDataformat(false)
  }
  function onCloseCreateDataformatAzure() {
    setShowDialogCreateDataformatAzure(false)
  }
  function onCloseDisplayDataformatVersions() {
    setUpdateDataformatList(true)
    setShowDialogViewDataformatVersions(false)
  }

  function setUserPwd(user, pwd) {
    var newDataformat = JSON.parse(JSON.stringify(selectedDataset))
    if (newDataformat && newDataformat.sqlDatabaseProperties) {
      newDataformat.sqlDatabaseProperties.user = user
      newDataformat.sqlDatabaseProperties.user = pwd
    }
    else {
      if (newDataformat === undefined) {
        dispatchError(dispatch, "No dataformat selected")
      }
      else {
        dispatchError(dispatch, "No SQL properties found, dataset is in a wrong state")
      }
    }

    //selectedDataset has the dataformat

    //update

    dispatch(actions.editDataformat(selectedDataset))
  }

  function storeUserPwd(user, pwd) {
    //selectedDataset has the dataformat
    setUserPwd(user, pwd)

  }

  function getSelectedDatasource(id) {
    if (id) {
      dispatch(actions.fetchDataformat(id)).then(response => {
        setSelectedDataset(response.data)
      })
    }
  }

  function viewCreateNewDataFormatVersionAzure(id) {
    getSelectedDatasource(selectedDatasetToViewVersions._id)
    setShowDialogCreateNewDataFormatVersion(true)
  }

  function viewCreateNewDataFormatVersion(id) {
    getSelectedDatasource(id)
    setShowDialogCreateNewDataFormatVersion(true)
  }

  function viewDisplayDataFormatVersions(id) {
    dispatch(actions.fetchDataformat(id)).then(response => {
      setSelectedDatasetToViewVersions(response.data)
    })
    setShowDialogViewDataformatVersions(true)
  }
  function onCloseCreateNewDataFormatAzureVersion() {
    setShowDialogCreateNewDataFormatVersion(false)
  }


  function viewCreateNewDataformatVersionLocal() {
    setShowDialogCreateDataformatNewVersionFromLocalFile(true)
  }
  function onCloseCreateNewDataformatVersionLocal() {
    setShowDialogCreateDataformatNewVersionFromLocalFile(false)
  }

  function translateUserIdToName(userId, licenseUsers_) {
    if (license !== undefined && license.users !== undefined && license.users.length > 0) {
      const licenseUsers = license.users
      for (let i = 0; i < licenseUsers.length; i++) {
        if (licenseUsers[i].id === userId) {
          return licenseUsers[i].name
        }
      }
      return `User with id ${userId} not found`
    }
    return `User with id ${userId} not found`


  }

  function handleSelectDatasetForExportation(id) {
    dispatch(actions.fetchDataformat(id)).then(response => {
      setSelectedDatasetForExportation(response.data)
    })

  }
  function handleSelectDatasetForExportationId(id) {
    setSelectedDatasetForExportationId(id)
    props.handleSelectDatasetForExportationId(id)
  }

  return (
    <div >
      {/*
            <AqtivaAppBar history={props.history} title={'Data Profiling'} 
            arrayPath={pathHelper.getPathArray('QualityDatasetRepositoryPage')}
            dataIcon = {true} >

           <DataForm props ={props} />
            </AqtivaAppBar>
            */}
      <AppBarTemplate fecthNotifications={false}
        history={props.history}
        title={"Datasets"}
        arrayPath={pathHelper.getPathArray("Governance")}
      />
      <Toolbar style={{ display: 'flex', justifyContent: 'flex-start' }}>

        <DialogCreateDataFormat
          configuration={configuration}
          open={showDialogCreateDataformat}
          setClose={onCloseCreateDataformat} />

        <DialogSelectAzureSource visible={showDialogCreateDataformatAzure} setVisible={onCloseCreateDataformatAzure}
          setLocalFileDialogVisible={viewCreateDataformat} />


        <DataformatDetail show={showDialogDatasetDetail}
          dataset={selectedDataset}
          onCloseDetailDialog={onCloseDetailDialog}
        />
        <DataformatProfiling show={showDialogDatasetProfiling}
          dataset={selectedDataset}
          onCloseProfilingDialog={onCloseProfilingDialog}
        />
        <DataformatProjectGraph
          userId={props.userId}
          history={props.history}
          show={showProjectUseDetailDialog}
          size={"large"}
          projectsUsedDetail={usedDatasourcesByProjects && renderHelper.splitUsedProjectsIntoOwnAndOthers(usedDatasourcesByProjects, selectedDataset, user.id)}
          dataformat={selectedDataset}
          onClose={onCloseProjectUseDetailDialog}
        />
        <DataformatAddProfiling show={showAddProfilingDialog}
          dataset={selectedDataset}
          onCloseDetailDialog={onCloseAddProfilingDialog}
        />
        <DialogSetUserPwd
          visible={showProjectUserAndPwd} setVisible={setShowProjectUserAndPwd}
          setUserPwd={setUserPwd}
          storeUserPwd={storeUserPwd} />


        {showDialogCreateDataformatNewVersionFromLocalFile &&
          <DialogCreateDataFormat
            createNewVersion={true}
            selectedDataset={selectedDatasetToViewVersions}
            configuration={props.configuration}
            open={showDialogCreateDataformatNewVersionFromLocalFile}
            closeAzureItemDialog={onCloseCreateNewDataFormatAzureVersion}
            closeDialogDisplay={onCloseDisplayDataformatVersions}
            setClose={onCloseCreateNewDataformatVersionLocal} />}

        {showDialogCreateNewDataFormatVersion &&
          <DialogSelectAzureSource
            createNewVersion={true}
            selectedDataset={selectedDatasetToViewVersions}
            visible={showDialogCreateNewDataFormatVersion} setVisible={onCloseCreateNewDataFormatAzureVersion}
            closeDialogDisplay={onCloseDisplayDataformatVersions}
            setLocalFileDialogVisible={viewCreateNewDataformatVersionLocal} />}

        {showDialogViewDataformatVersions &&
          <DialogViewDataformatVersions
            viewCreateNewDataFormatVersionAzure={viewCreateNewDataFormatVersionAzure}
            createNewVersion={true}
            selectedDataset={selectedDatasetToViewVersions}
            configuration={props.configuration}
            open={showDialogViewDataformatVersions}
            setClose={onCloseDisplayDataformatVersions}

            classes={classes}
            translateUserIdToName={translateUserIdToName}
            licenseUsers={props.licenseUsers}
            usedDatasourcesByProjects={props.usedDatasourcesByProjects}
            viewDatasetDetails={viewDatasetDetails}
            viewDatasetUseInProjects={viewDatasetUseInProjects}
            viewCreateNewDataFormatVersion={viewCreateNewDataFormatVersion}
            handleSelectDatasetForExportation={handleSelectDatasetForExportation}
            handleSelectDatasetForExportationId={handleSelectDatasetForExportationId}
            selectedDatasetForExportationId={selectedDatasetForExportationId}
            selectDatasetDialog={props.selectDatasetDialog}
          />}

        {user && (user.role !== 'USER') &&
          <div style={{ marginTop: '0px' }}>
            <FormControlLabel control={<Switch color="primary"
              checked={viewAsUser} onChange={event => setViewAsUser(event.target.checked)} />} label={
                <Typography variant='caption'>View as User</Typography>
              } />
          </div>
        }
        <div style={{ marginTop: '0px' }}>
          <FormControlLabel control={<Switch color="primary"
            checked={viewOnlyWithProfiling} onChange={event => setViewOnlyWithProfiling(event.target.checked)} />} label={
              <Typography variant='caption'>View Datasets with Profiling</Typography>
            } />
        </div>
        {buttonDefinition.map(value => <Button key={value.labelId} startIcon={value.icon}
          onClick={event => value.labelId == 'createDatasetLabel' ? setShowDialogCreateDataformatAzure(true) : console.log(event)}
          style={{ ...theme.buttons.buttonRegular, marginRight: '15px' }}>
          <div style={theme.common.text.buttonTextSmall}>

            {languageContext.dictionary[value.labelId]}
          </div>
        </Button>)}
        <div style={{ marginLeft: 'auto' }}>
          <FilterDataset sourceList={sourceList} ownersList={ownersList} setFilterObj={setFilterObj} />
        </div>

      </Toolbar>

      <Divider style={{ width: '100%', marginTop: '-15px' }} />

      <DatasetManagementTable
        licenseUsers={license.users}
        viewAsUser={viewAsUser} viewOnlyWithProfiling={viewOnlyWithProfiling}
        viewDatasetProfiling={viewDatasetProfiling}
        viewDatasetDetails={viewDatasetDetails}
        viewDisplayDataFormatVersions={viewDisplayDataFormatVersions}
        viewDatasetUseInProjects={viewDatasetUseInProjects}
        viewAddProfiling={viewAddProfiling}
        filterObj={filterObj}
        updateList={updateDataformatList}
        setUpdateList={setUpdateDataformatList}
        viewUpdateUserAndPwd={viewUpdateUserAndPwd}
        setOwnersList={setOwnersList}
        setSourceList={setSourceList}
      />





    </div>

  );
}