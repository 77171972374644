import React from 'react'


import { Tooltip, Select, MenuItem, Typography } from '@material-ui/core';

import { ReactComponent as LocalFileIcon } from 'resources/icons/azure/File.svg';

import * as commonStyles from 'style/CommonStyles'

export function applyFilters(dataformats, selectedDataformatSource, selectedDataformatUser) {
    let filteredDataformats = JSON.parse(JSON.stringify(dataformats))
    for (let i = 0; i < filteredDataformats.length; i++) {
        if (filteredDataformats[i].source === null) {
            filteredDataformats[i].source = "local file"
        }
    }
    if (selectedDataformatSource && selectedDataformatSource !== "") {
        filteredDataformats = filteredDataformats.filter(dataformat => dataformat.source === selectedDataformatSource)
    }
    if (selectedDataformatUser && selectedDataformatUser !== "") {
        filteredDataformats = filteredDataformats.filter(dataformat => (selectedDataformatUser === dataformat.userId))
    }
    return filteredDataformats
}

export function countProjectsUsingDataformat(usedDatasourcesByProjects, dataformat) {
    let count = 0;
    for (let i = 0; i < usedDatasourcesByProjects.length; i++) {
        for (let j = 0; j < usedDatasourcesByProjects[i].dataformatsUsed.length; j++) {
            if (usedDatasourcesByProjects[i].dataformatsUsed[j] === dataformat._id) {
                count += 1
            }
        }
    }
    return count
}

export function parseDateToCorrectFormat(date) {
    let newDate = date.split('.')[0]
    newDate = newDate.replace('T', ' ')
    return newDate

}

export function renderSelectedIcon(dataformat, selectedDataformat) {
    if (dataformat._id === selectedDataformat._id) {
        return true
    }
    else {
        return false
    }

}

export function renderAllowedUsers(rowData, licenseUsers) {
    if (rowData.allowedUsers) {
        return (
            <Select
                labelId="select-license-type"
                id="select-license-type"
                value={''}
                style={{ width: "60%" }}
            >
                {rowData.allowedUsers.map(userId =>
                    <MenuItem value={userId}>{translateUserIdToName(userId, licenseUsers)}</MenuItem>
                )}

            </Select>
        )
    }
}

const translateUserIdToName = (userId, licenseUsers) => {
    if (licenseUsers !== undefined && licenseUsers.length > 0) {
        for (let i = 0; i < licenseUsers.length; i++) {
            if (licenseUsers[i].id === userId) {
                return licenseUsers[i].name
            }
        }
        return userId
    }
    return undefined


}


export function renderAzureLogo(source) {
    switch (source) {
        case 'azure-storage': {
            return (
                <Tooltip title={'Storage Account'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/azure/Storage-Accounts.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'azure-sqlsynapse': {
            return (
                <Tooltip title={'Synapse'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/azure/synapse.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'azure-cosmos': {
            return (
                <Tooltip title={'Cosmos'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/azure/cosmo_db.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'azure-sqldatabase': {
            return (
                <Tooltip title={'SQL Database'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/azure/sql-database-generic.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'sqlServer': {
            return (
                <Tooltip title={'SQL server'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/sqlServer.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'mySQL': {
            return (
                <Tooltip title={'mySQL'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/mySQL.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'oracle': {
            return (
                <Tooltip title={'oracle'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/oracle.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'awsS3': {
            return (
                <Tooltip title={'aws S3'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/aws/awsS3.png')} />
                    </span>
                </Tooltip>
            )
        }

        default: {
            return (
                <Tooltip title={'Local file'}>
                    <span>
                        <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '30px', width: '30px' }} />
                    </span>
                </Tooltip>
            )
        }
    }
}

export function renderAzureLogoInfo(source, element) {
    switch (source) {
        case 'azure-storage': {
            return (
                <>
                    {element.fileStorageProperties && element.fileStorageProperties !== undefined &&
                        <Tooltip title={
                            <React.Fragment>
                                <Typography variant='subtitle2'>Account: <strong style={{ color: "#2fb7e0" }}>{element.fileStorageProperties.account}</strong></Typography>
                                <Typography variant='subtitle2'>Container: <strong style={{ color: "#2fb7e0" }}>{element.fileStorageProperties.container}</strong></Typography>
                                <Typography variant='subtitle2'>Path: <strong style={{ color: "#2fb7e0" }}>{element.fileStorageProperties.path}</strong></Typography>
                            </React.Fragment>
                        }>
                            <span>
                                <img alt="" style={{ height: '30px', width: '30px' }}
                                    src={require('resources/icons/azure/Storage-Accounts.png')} />
                            </span>
                        </Tooltip>
                    }
                </>
            )
        }
        case 'azure-sqlsynapse': {
            return (
                <Tooltip title={'Synapse'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/azure/synapse.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'azure-cosmos': {
            return (
                <Tooltip title={'Cosmos'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/azure/cosmo_db.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'azure-sqldatabase': {
            return (
                <Tooltip title={'SQL Database'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/azure/sql-database-generic.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'sqlServer': {
            return (
                <Tooltip title={'SQL server'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/sqlServer.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'mySQL': {
            return (
                <Tooltip title={'mySQL'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/mySQL.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'oracle': {
            return (
                <Tooltip title={'oracle'}>
                    <span>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/oracle.png')} />
                    </span>
                </Tooltip>
            )
        }
        case 'awsS3': {

            return (
                <>
                    {element.awsDatabaseProperties && element.awsDatabaseProperties !== undefined &&
                        <Tooltip title={
                            <React.Fragment>
                                <Typography variant='subtitle2'>Bucket Name: <strong style={{ color: "#2fb7e0" }}>{element.awsDatabaseProperties.bucket}</strong></Typography>
                                <Typography variant='subtitle2'>Key: <strong style={{ color: "#2fb7e0" }}>{element.awsDatabaseProperties.key}</strong></Typography>
                            </React.Fragment>
                        }>
                            <span>
                                <img alt="" style={{ height: '30px', width: '30px' }}
                                    src={require('resources/icons/aws/awsS3.png')} />
                            </span>
                        </Tooltip>
                    }
                </>
            )
        }

        case 'bigQuery': {
            return (
                <>
                    {element.bigQueryDatabaseProperties && element.bigQueryDatabaseProperties !== undefined &&
                        <Tooltip title={
                            <React.Fragment>
                                <Typography variant='subtitle2'>Project Id: <strong style={{ color: "#2fb7e0" }}>{element.bigQueryDatabaseProperties.projectId}</strong></Typography>
                                <Typography variant='subtitle2'>Dataset: <strong style={{ color: "#2fb7e0" }}>{element.bigQueryDatabaseProperties.dataset}</strong></Typography>
                                <Typography variant='subtitle2'>Table: <strong style={{ color: "#2fb7e0" }}>{element.bigQueryDatabaseProperties.table}</strong></Typography>
                            </React.Fragment>
                        }>
                            <span>
                                <img alt="" style={{ height: '30px', width: '30px' }}
                                    src={require('resources/icons/bigQuery.png')} />
                            </span>
                        </Tooltip>
                    }
                </>
            )
        }

        case 'hive': {
            return (
                <>
                    {element.hiveDatabaseProperties && element.hiveDatabaseProperties !== undefined &&
                        <Tooltip title={
                            <React.Fragment>
                                <Typography variant='subtitle2'>Database: <strong style={{ color: "#2fb7e0" }}>{element.hiveDatabaseProperties.database}</strong></Typography>
                                <Typography variant='subtitle2'>Table: <strong style={{ color: "#2fb7e0" }}>{element.hiveDatabaseProperties.table}</strong></Typography>
                            </React.Fragment>
                        }>
                            <span>
                                <img alt="" style={{ height: '30px', width: '30px' }}
                                    src={require('resources/icons/hive.png')} />
                            </span>
                        </Tooltip>
                    }
                </>
            )
        }

        case 'awsRedshift': {
            return (
                <>
                    {element.redshiftDatabaseProperties && element.redshiftDatabaseProperties !== undefined &&
                        <Tooltip title={
                            <React.Fragment>
                                <Typography variant='subtitle2'>Database: <strong style={{ color: "#2fb7e0" }}>{element.redshiftDatabaseProperties.database}</strong></Typography>
                                <Typography variant='subtitle2'>Table: <strong style={{ color: "#2fb7e0" }}>{element.redshiftDatabaseProperties.table}</strong></Typography>
                            </React.Fragment>
                        }>
                            <span>
                                <img alt="" style={{ height: '30px', width: '30px' }}
                                    src={require('resources/icons/redshift.png')} />
                            </span>
                        </Tooltip>
                    }
                </>
            )
        }

        default: {
            return (
                <Tooltip title={'Local file'}>
                    <span>
                        <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '30px', width: '30px' }} />
                    </span>
                </Tooltip>
            )
        }
    }
}

