import * as actions from "./../../actions";

export function markAsRead(notification, user, dispatch, props) {
    if (notification._id) dispatch(actions.markAsReadNotifications(notification._id, user.id, props));
}

export function clickOnNotification(notification, history, user, dispatch,props) {
  if (notification && notification.params && notification.params.simulationId) {
    if (notification.notificationType === "simulation") {
      history.push({
        pathname: `/${process.env.REACT_APP_PREFIX}/drilldown/dashboard/simulation`,
        state: {simulationId: notification.params.simulationId, showSimulation: true}
      });
    }
    markAsRead(notification, user, dispatch, props);
  }
  
  if (notification && notification.params && notification.params.rulesDTO && notification.params.rulesDTO.notebook_output && notification.params.rulesDTO.notebook_output.result) {
    if (notification.notificationType === "ruleRecommender") {
      history.push(
        {
          pathname: `/${process.env.REACT_APP_PREFIX}/project/${notification.params.projectId}/${notification.params.qpId}/true/${notification._id}`,
          state: { rulesDTONotification: notification.params.rulesDTO.notebook_output.result,
                  dataSet: notification.params.dataSet
           }
      }
      );
    }
  markAsRead(notification, user, dispatch,props);
  }

  if (notification && notification.params && notification.params.dataformatId) {
    if (notification.notificationType === "profiling") {
      if (notification.params.dataformatId === "Folder") { 
        history.push(`/${process.env.REACT_APP_PREFIX}/profiling`);
      } else {
      history.push(`/${process.env.REACT_APP_PREFIX}/profiling/${notification.params.dataformatId}`);
      }
    }
    markAsRead(notification, user, dispatch, props);
  }
}


