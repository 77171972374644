import React from 'react';

import { QualityTheme } from './../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AdvanceConfigurationQuality from '../../../common/AdvanceConfigurationQuality'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'
import StreamEventHubConfiguration from './StreamEventHubConfiguration'
import BackProcessingConfiguration from './BackProcessingConfiguration'
import ControlValuesConfiguration from './ControlValuesConfiguration'
import AzureBackendConfiguration from './AzureBackendConfiguration';
import ResultStorageConfiguration from './ResultStorageConfiguration/index'
import QualityTagsConfiguration from './QualityTagsConfiguration';
import DataCredentials from 'components/dataCredentials';


import { isEmpty } from 'commonFunctions/commonFunctions'
import AzureDatabaseConfiguration from './AnalyticDatabaseConfiguration';
import StorageConfiguration from './StorageConfiguration';
import DefaultSqlConnection from './DefaultSqlConnector';
import ValidateErrorModal from './ValidateErrorModal';



export default function QualityDefaults(props) {

    const dispatch = useDispatch();
    var qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))
    var license = useSelector(store => (store.license))

    //TODO: CHECK
    //const [thError, setThError] = React.useState()
    //const [thWarning, setThWarning] = React.useState()
    //const [wrongRegisterAction, setWrongRegisterAction] = React.useState()
    //const [outputMode, setOutputMode] = React.useState()
    const [qualityConfiguration, setQualityConfiguration] = React.useState({})
    const [storages, setStorages] = React.useState(props.configuration && props.configuration.storages ? props.configuration.storages : [])
    const [dataStorageCredentials, setDataStorageCredentials] = React.useState(props.configuration && props.configuration.dataStorageCredentials ? props.configuration.dataStorageCredentials : [])
    const [tags, setTags] = React.useState(props.configuration && props.configuration.tags ? props.configuration.tags : [])
    const [apiKeyUrl, setApiKeyUrl] = React.useState('')

    const [validationErrors, setValidationErrors] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);



    React.useEffect(() => {
        
        if (qualityConfigurationSelector && (qualityConfigurationSelector.wrongRegisterOutput === null || !qualityConfigurationSelector.wrongRegisterOutput)) {
            qualityConfigurationSelector.wrongRegisterOutput = 'discard_action'
        }
        if (qualityConfigurationSelector && (qualityConfigurationSelector.outputControl === null || !qualityConfigurationSelector.outputControl)) {
            qualityConfigurationSelector.outputControl = 'INFO'
        }
        setQualityConfiguration(qualityConfigurationSelector)
    }, [qualityConfigurationSelector]);

    React.useEffect(() => {
        if (props.currentUser && props.currentUser.id) {
            dispatch(actions.fetchConfigurationByUserId(props.currentUser.id))
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setApiKeyUrl(dispatch(actions.getControlValue("URL_API_VALIDATION")))

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {

        if (qualityConfiguration && qualityConfiguration.storages) {
            setStorages(qualityConfiguration.storages)
        }

    }, [qualityConfiguration]);

    React.useEffect(() => {

        if (qualityConfiguration && qualityConfiguration.dataStorageCredentials) {
            setDataStorageCredentials(qualityConfiguration.dataStorageCredentials)
        }
    }, [qualityConfiguration]);

    React.useEffect(() => {
        

        if (qualityConfiguration && qualityConfiguration.tagConfiguration) {
            setTags(qualityConfiguration.tagConfiguration)
        }

    }, [qualityConfiguration]);

    //function to update control elmenets
    async function setControlObj(data) {
        //setWrongRegisterAction(data.wrongRegisterActionType)
        //setOutputMode(data.controlModeType)

        var qualityConfig = qualityConfiguration
        qualityConfig.outputControl = data.controlModeType
        qualityConfig.wrongRegisterOutput = data.wrongRegisterActionType
        qualityConfig.apiKeyUrl = apiKeyUrl + license.apiKey
        
        setQualityConfiguration(qualityConfig)

    }
    //function to update control elmenets
    async function setThErrorData(data) {

        var qualityConfig = qualityConfiguration
        qualityConfig.thErrorAction = data.action
        qualityConfig.thErrorType = data.type
        qualityConfig.thErrorValue = data.value
        qualityConfig.apiKeyUrl = apiKeyUrl + license.apiKey
        setQualityConfiguration(qualityConfig)
        //setThError(data)

    }
    async function setThWarningData(data) {

        var qualityConfig = qualityConfiguration
        qualityConfig.thWarnAction = data.action
        qualityConfig.thWarnType = data.type
        qualityConfig.thWarnValue = data.value
        qualityConfig.apiKeyUrl = apiKeyUrl + license.apiKey
        setQualityConfiguration(qualityConfig)
        //setThWarning(data)

    }



    function getThError() {
        if (!isEmpty(qualityConfiguration)) {
            return {
                action: qualityConfiguration.thErrorAction,
                value: qualityConfiguration.thErrorValue,
                type: qualityConfiguration.thErrorType
            }
        }
        else {
            return undefined //nunca deberíamos entrar aquí
        }

    }

    function getThWarning() {
        if (!isEmpty(qualityConfiguration)) {
            return {
                action: qualityConfiguration.thWarnAction,
                value: qualityConfiguration.thWarnValue,
                type: qualityConfiguration.thWarnType
            }
        }
        else {
            return undefined //nunca deberíamos entrar aquí
        }

    }

    function getControlObj() {
        if (!isEmpty(qualityConfiguration)) {
            return { wrongRegisterAction: qualityConfiguration.wrongRegisterOutput, outputMode: qualityConfiguration.outputControl }
        }
        else {
            return undefined //nunca deberíamos entrar aquí
        }


    }


    /*
        handleChange = name => event => {
            this.setState({ qualityConfiguration: { ...this.state.qualityConfiguration, [name]: event.target.checked } });
        };
    
        handleTextFieldChange = name => event => {
            this.setState({ qualityConfiguration: { ...this.state.qualityConfiguration, [name]: event.target.value } });
        };*/

    function onClickSave() {
        dispatch(actions.addConfiguration(qualityConfiguration, props.currentUser)).then(result => {
            //notificationMessage(result,this.props.showMessage,'Configuration Updated','Error Updating Configuration')
        }
        )
    }

    async function setBackProcessingConfig(data) {

        const urlApiValidation = await dispatch(actions.getControlValue("URL_API_VALIDATION"))
        var config = qualityConfiguration
        if (config !== undefined && data !== undefined) {
            config.databricksInstance = data.databricksInstance
            config.databricksClusterId = data.databricksClusterId
            config.databricksSimulationNotebookPath = data.databricksSimulationNotebookPath
            config.databricksRuleRecommenderNotebookPath = data.databricksRuleRecommenderNotebookPath
            config.resourceGroupName = data.resourceGroupName
            config.regionName = data.regionName
            config.storageAccountName = data.storageAccountName
            config.containerName = data.containerName
            config.storageKey = data.storageKey
            config.sasToken = data.sasToken
            config.databricksToken = data.databricksToken
            config.databricksDeploymentPath = data.databricksDeploymentPath
            config.apiKeyUrl = urlApiValidation + license.apiKey
            config.amazonConfiguration = data.amazonConfiguration
            config.bigQueryConfiguration = data.bigQueryConfiguration
            config.preferedCloudProvider = data.preferedCloudProvider
            config.environmentSecured = data.environmentSecured
            config.defaultSqlConnector = data.defaultSqlConnector
            setQualityConfiguration(config)
        }
    }
    function setDataStorageCredentialsConfig(dataStorageCredentials) {
        var config = qualityConfiguration
        let cleanCredentials = removeUnrelatedFieldsDataStorageCredentials(dataStorageCredentials)
        config.dataStorageCredentials = cleanCredentials
        config = updateEnvironmentConfigurationEnabledCredentials(config, cleanCredentials)
        setDataStorageCredentials(cleanCredentials)
        setQualityConfiguration(config)
    }


    function setTypesConfiguration(configuration) {
        var config = configuration
        let cleanCredentials = removeUnrelatedFieldsDataStorageCredentials(configuration.dataStorageCredentials)
        config.dataStorageCredentials = cleanCredentials
        config = updateEnvironmentConfigurationEnabledCredentials(config, cleanCredentials)
        setDataStorageCredentials(cleanCredentials)
        setQualityConfiguration(config)
    }



    //here we update enabled credentials in each environment configuration
    function updateEnvironmentConfigurationEnabledCredentials(config, dataStorageCredentials) {
        if (config && config.environmentConfigurationList && config.environmentConfigurationList.length > 0) {
            for (let i = 0; i < config.environmentConfigurationList.length; i++) {
                if (config.environmentConfigurationList[i].enabledDataStorageCredentials && config.environmentConfigurationList[i].enabledDataStorageCredentials.length > 0) {
                    for (let j = 0; j < config.environmentConfigurationList[i].enabledDataStorageCredentials.length; j++) {
                        let enabledExists = false
                        for (let k = 0; k < dataStorageCredentials.length; k++) {
                            if (config.environmentConfigurationList[i].enabledDataStorageCredentials[j].id === dataStorageCredentials[k].id) {
                                config.environmentConfigurationList[i].enabledDataStorageCredentials[j] =
                                    { ...config.environmentConfigurationList[i].enabledDataStorageCredentials[j], ...dataStorageCredentials[k] }
                                enabledExists = true

                            }
                        }
                        if (!enabledExists) {

                            config.environmentConfigurationList[i].enabledDataStorageCredentials = config.environmentConfigurationList[i]
                                .enabledDataStorageCredentials.filter(storageCredentials => storageCredentials.id !== config.environmentConfigurationList[i].enabledDataStorageCredentials[j].id)
                        }
                    }
                }
            }
        }
        if ((!dataStorageCredentials || dataStorageCredentials.length === 0) && config && config.environmentConfigurationList && config.environmentConfigurationList.length > 0) {
            for (let i = 0; i < config.environmentConfigurationList.length; i++) {
                if (config.environmentConfigurationList[i].enabledDataStorageCredentials && config.environmentConfigurationList[i].enabledDataStorageCredentials.length > 0) {
                    config.environmentConfigurationList[i].enabledDataStorageCredentials = []
                }
            }
        }
        return config
    }
    function removeUnrelatedFieldsDataStorageCredentials(dataStorageCredentials) {
        let cleanCredentials = []
        if (dataStorageCredentials && dataStorageCredentials.length > 0) {
            for (let i = 0; i < dataStorageCredentials.length; i++) {
                delete dataStorageCredentials[i].accessType
                delete dataStorageCredentials[i].allowedUsers
                cleanCredentials.push(dataStorageCredentials[i])
            }
        }
        return cleanCredentials
    }

    function setAzureCredentialsConfig(data) {
        var config = qualityConfiguration
        config.azureCredentials = data

        setQualityConfiguration(config)
    }

    function setAnalyticDatabaseConfiguration(data) {
        var config = qualityConfiguration
        config.analyticDatabaseConnection = data

        setQualityConfiguration(config)
    }
    function setDefaultSqlConnector(data) {
        var config = qualityConfiguration
        config.defaultSqlConnector = data

        setQualityConfiguration(config)
    }
    function setResultStorageConfiguration(storages) {
        var config = qualityConfiguration
        config.storages = storages
        setStorages(storages)
        setQualityConfiguration(config)
    }

    function setTagsConfiguration(tags) {
        var config = qualityConfiguration
        config.tagConfiguration = tags
        setTags(tags)
        setQualityConfiguration(config)
    }

   async  function updateEventHubProps(data) {
    const urlApiValidation = await dispatch(actions.getControlValue("URL_API_VALIDATION"))

        var config = qualityConfiguration
        config.eventHubs = data
        config.apiKeyUrl = urlApiValidation + license.apiKey
        setQualityConfiguration(config)
    }
    const handleCloseModal = () => {
        setIsModalOpen(false);
      };

    function onClickValidate( ){
        dispatch(actions.validateConfiguration()).then(result => {
            console.log('result',result)
            if (result && result.length > 0) {
                setValidationErrors(result);
                setIsModalOpen(true);
              }
        }
    )
    }

    return (
        <div>
            {!qualityConfiguration &&
                <div className="ui segment" style={{ height: '100px' }}>
                    <div className="ui active inverted dimmer">
                        <div className="ui text loader">Loading</div>
                    </div>
                    <p></p>
                </div>
            }
            <Button onClick={onClickValidate} variant="contained" color="primary" >
                    Validate Configuration
             </Button>
             <ValidateErrorModal 
                open={isModalOpen} 
                onClose={handleCloseModal} 
                errors={validationErrors} 
            />

            {qualityConfiguration &&
                <MuiThemeProvider theme={QualityTheme}>
                    <div className="dashboard ui segment" style={{ minHeight: '600px' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <ControlValuesConfiguration  />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <AdvanceConfigurationQuality setThError={setThErrorData} setThWarning={setThWarningData} setControlObj={setControlObj}
                                thError={getThError()}
                                thWarning={getThWarning()}
                                controlObj={getControlObj()} />
                        </div>
                      
                        <div style={{ marginBottom: '10px' }}>
                            <BackProcessingConfiguration master={true} configuration={qualityConfiguration} setConfig={setBackProcessingConfig}
                                setStorageAccountName={event => { }} setContainerName={event => { }} setSasToken={event => { }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <AzureDatabaseConfiguration configuration={qualityConfiguration} setConfig={setAnalyticDatabaseConfiguration} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <DefaultSqlConnection configuration={qualityConfiguration} setConfig={setDefaultSqlConnector} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <AzureBackendConfiguration configuration={qualityConfiguration} setConfig={setAzureCredentialsConfig} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <ResultStorageConfiguration configuration={qualityConfiguration} setConfig={setResultStorageConfiguration} storages={storages} setStorages={setResultStorageConfiguration} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <QualityTagsConfiguration configuration={qualityConfiguration} setConfig={undefined}
                                tags={tags}
                                setTagsConfiguration={setTagsConfiguration} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <DataCredentials configuration={qualityConfiguration} setConfig={setDataStorageCredentialsConfig}
                                dataStorageCredentials={dataStorageCredentials} setDataStorageCredentials={setDataStorageCredentialsConfig} />
                        </div>
                        {(license.lambaActive === true || !license.lambdaActive) && <div style={{ marginBottom: '10px' }}>
                            <StreamEventHubConfiguration configuration={qualityConfiguration} updateEventHubProps={updateEventHubProps} />
                        </div>}
                        <div style={{ marginBottom: '10px' }}>
                            <StorageConfiguration configuration={qualityConfiguration} setConfig={setTypesConfiguration} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <Button onClick={onClickSave} variant="contained" color="secondary" >
                                Save
                            </Button>
                        </div>
                    </div>
                </MuiThemeProvider>
            }
        </div>
    )



}