import React, { useEffect, useState } from 'react';
import ConnectionStringNewCredentials from './ConnectionStringNewCredentials';
import SasTokenNewCredentials from './SasTokenNewCredentials'





import { Grid, FormControlLabel, Typography, Checkbox, } from '@material-ui/core';





export default function SasNewCredentialsComponent(props) {

    const [useConnectionString, setUseConnectionString] = useState(true)
    const [useSasToken, setUseSasToken] = useState(false)

    useEffect(() => {
        if (props.sasMethod && props.sasMethod !== "") {
            switch (props.sasMethod) {
                case "connectionString":
                    setUseConnectionString(true)
                    setUseSasToken(false)
                    break
                case "sasToken":
                    setUseConnectionString(false)
                    setUseSasToken(true)
                    break
                 default:
                    break
            }
        }
        if (props.sasMethod === "") {
            setUseConnectionString(true)
            setUseSasToken(false)
            props.setSasMethod("connectionString")
        }
    }, [props.connectionPhase])// eslint-disable-line react-hooks/exhaustive-deps


    const handleCheckConnectionString = (event) => {
        if (props.setSasMethod) {
            props.setSasMethod("connectionString")
            setUseConnectionString(true)
            setUseSasToken(false)
        }
    }

    const handleCheckSasToken = (event) => {
        if (props.setSasMethod) {
            props.setSasMethod("sasToken")
            setUseConnectionString(false)
            setUseSasToken(true)
        }
    }

    return (

        <>
            <div style={{ width: '100%', height: '90%' }}>


                <Grid item xs={12}>
                    <Typography variant="subtitle2" >How will you connect to the storage account?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={useConnectionString}
                                onChange={handleCheckConnectionString}
                                name="notify on success"
                                color="primary"
                            />
                        }
                        label={<Typography variant="caption">use Connection string</Typography>}
                    />
                </Grid>
                {useConnectionString && <Grid item xs={12}>
                    <ConnectionStringNewCredentials storage={props.storage} setSasConnectionString={props.setSasConnectionString} />
                </Grid>}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={useSasToken}
                                onChange={handleCheckSasToken}
                                name="notify on success"
                                color="primary"
                            />
                        }
                        label={<Typography variant="subtitle1">use SAS Token</Typography>}
                    />
                </Grid>
                {useSasToken && <Grid item xs={12}>
                    <SasTokenNewCredentials storage={props.storage} setSasToken={props.setSasToken} />

                </Grid>}

            </div >
        </>
    )
}