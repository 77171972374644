
import quality from '../apis/quality';

import {

  EDIT_USER
} from './types'

import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';
//import qualityLocalHost from 'apis/qualityLocalHost';




export const addStorageCredencialsToUser = (userId, localStorageDTO) => async (dispatch, getState) => {
  var returnValue = []
  await quality.post(`/license/user/store/credentials/${userId}`, localStorageDTO, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: EDIT_USER,
      payload: response.data
    });

    returnValue = response.data
    if (returnValue && returnValue !== null) {
      dispatchInfo(dispatch, `user storage credentials updated`)
    }
    else {
      dispatchError(dispatch, "Error updating user credentials")
    }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}