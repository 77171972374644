
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons'
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ReactComponent as LocalFileIcon } from 'resources/icons/azure/File.svg';
import * as commonStyles from 'style/CommonStyles'
import * as actions from 'actions'

export function getAqtivaConector(credential, type) {

    let aqtivaConnector = {}

    switch (type) {
        case 'aws_s3':
            aqtivaConnector = {
                storageParent: credential.awsCredentialsDTO.bucketName ? credential.awsCredentialsDTO.bucketName : null,
                container: credential.awsCredentialsDTO.bucketName ? credential.awsCredentialsDTO.bucketName : null,
                accessKey: credential.awsCredentialsDTO.accessKey,
                accessSecret: credential.awsCredentialsDTO.encryptedSecretKey,
                region: credential.awsCredentialsDTO.region
            }
            break
        case 'azure_storage':
            aqtivaConnector = {
                storageParent: credential.storageAccountCredentialsDTO.storageAccountName.trim(),
                accessKey: credential.storageAccountCredentialsDTO.accessKey,
                isContainer: false,
                accessSecret: credential.storageAccountCredentialsDTO.sharedAccessSignature,
            }
            break
        case 'azure_container':
            aqtivaConnector = {
                storageParent: credential.storageAccountCredentialsDTO.storageAccountName.trim(),
                isContainer: true,
                accessSecret: credential.storageAccountCredentialsDTO.sharedAccessSignature,
            }
            break
        default:
            break
    }

    return aqtivaConnector;
}

export function getIconsCredentials(type) {

    let icon

    switch (type) {
        case 'aws_s3':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'azure_storage':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/Storage-Accounts.png')} />
            break
        default:
            break
    }
    return icon
}

export function getIconsStorages(type) {

    let icon

    switch (type) {
        case 'aws_s3':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/aws/s3_bucket.png')} />
            break
        case 'azure_storage':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/SQL-Server.png')} />
            break
        default:
            break
    }
    return icon
}

export function getIconsContainers(type) {

    let icon

    switch (type) {
        case 'aws_s3':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/aws/s3_bucket.png')} />
            break
        case 'azure_storage':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/Storage-Container.png')} />
            break
        default:
            break
    }
    return icon
}

export function getIconsFolders(type) {

    let icon

    switch (type) {
        case 'aws_s3':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/aws/aws-s3-logo.png')} />
            break
        case 'azure_storage':
            icon = <img alt="" style={{ height: '12px', width: '12px' }} src={require('resources/icons/azure/Folder.png')} />
            break
        default:
            break
    }
    return icon
}

export function getIconsFiles(type) {

    let icon

    switch (type) {
        case 'aws_s3':
            icon = <AssignmentIcon />
            break
        case 'azure_storage':
            icon = <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '12px', width: '12px' }} />
            break
        default:
            break
    }
    return icon
}

export async function getPreviewDTO(creds, bucket, object, type, baseformat, storageType, dispatch) {

    let dto

    switch (storageType) {
        case 'azure_storage':
            dto = createPreviewAzure(creds, bucket, object, type, storageType, dispatch)
            break;
        case 'aws':
            dto = createPreviewAws(creds, bucket, object, type, baseformat, dispatch)
            break;
    }

    return dto;
}


async function createPreviewAws(creds, bucket, object, type, baseFormat, dispatch) {

    let name = type === 'folder' ? object.name : object.fileName
    if(baseFormat === 'hudi') name = name.replace('.hoodie/', "")
    let dto =  {
        storageType: "awsS3",
        bucket: bucket.bucket,
        key: name,
        baseFormat: baseFormat,
        credentialsId: creds.id
    }

    if (type === 'folder') dto.listChildrenNames = await getChildrenNames(object, creds, "awsS3", creds.awsCredentialsDTO.bucketName, dispatch)

 
    return dto
}

async function createPreviewAzure(creds, bucket, object, type, storageType, dispatch) {

    let dto


    let containerName
    let name = type === 'folder' ? object.name.slice(0, -1) : object.fileName
    if (bucket.name.includes('/')) {
        let folderName = bucket.name.split('/')[0]
        creds.containers.forEach(c => {
            if (c.folders && c.folders.length > 0) {
                c.folders.forEach(f => {
                    if (f.name === folderName + '/') {
                        containerName = c.name
                    }
                })
            }
        })
    } else {
        containerName = bucket.name
    }

    dto = {
        "type": type === 'folder' ? 'Folder' : 'file',
        "name": name,
        "fullName": name,
        "resourceGroupName": "AQTIVA",
        "storageAccount": creds.storageAccountCredentialsDTO.storageAccountName,
        "container": containerName,
        "children": await getObjectChildern(object, creds.storageAccountCredentialsDTO.storageAccountName, type, containerName, creds, storageType, dispatch),
        "level": 0,
        "parent": ""
    }
    if (type === 'folder') dto.listChildrenNames = await getChildrenNames(object, creds, storageType, containerName, dispatch)


    return dto;
}

async function getChildrenNames(object, creds, storageType, containerName, dispatch) {

    let childernNames = []
    let aqtivaConnector = getAqtivaConector(creds, storageType)
    aqtivaConnector.container = containerName

    if (object.folders && object.folders.length > 0) {
        object.folders.map(async (f) => {
            await actions.listFolderObjectsFileStorage(aqtivaConnector, storageType, f.name.replaceAll('/', '_AQTIVA_SLASH_')).then((res => {
                if (res.objects && res.objects.length > 1) res.objects.map(f => childernNames.push(f.fileName))
                if (res.prefixes && res.prefixes.length > 1) res.prefixes.map(f => childernNames.concat(getChildrenNames(f, creds, storageType, containerName)))
            }))

        })
    }

    if (object.files && object.files.length > 0) {
        object.files.map(f => {
            childernNames.push(f.fileName)
        })
    }

    if ((!object.folders || object.folders.length === 0) || (!object.files && object.files.length === 0)) {

        let result = await dispatch(actions.listFolderObjectsFileStorage(aqtivaConnector, 'azure_storage', object.name.replaceAll('/', '_AQTIVA_SLASH_')))

        if (result.objects && result.objects.length > 0) {

            result.objects.map(f => {
                childernNames.push(f.fileName)
            })
        }
        if (result.prefixes && result.prefixes.length > 0) {
            result.prefixes.map(async (f) => {
                await actions.listFolderObjectsFileStorage(aqtivaConnector, storageType, f.name.replaceAll('/', '_AQTIVA_SLASH_')).then((res => {
                    if (res.objects && res.objects.length > 1) res.objects.map(f => childernNames.push(f.fileName))
                    if (res.prefixes && res.prefixes.length > 1) res.prefixes.map(f => childernNames.concat(getChildrenNames(f, creds, storageType, containerName)))
                }))
            })
        }
    }
    return childernNames
}

async function getObjectChildern(object, storageAccount, type, containerName, creds, storageType, dispatch) {

    if (type === 'file') {
        return [{
            "type": "no data",
            "name": "no data",
            "fullName": "no data",
            "resourceGroupName": "AQTIVA",
            "storageAccount": storageAccount,
            "container": containerName,
            "children": [],
            "level": 1,
            "parent": ""
        }]

    } else {
        let childern = []
        let aqtivaConnector = getAqtivaConector(creds, storageType)
        let result = await dispatch(actions.listFolderObjectsFileStorage(aqtivaConnector, 'azure_storage', object.name.replaceAll('/', '_AQTIVA_SLASH_')))

        if (result.objects && result.objects.length > 0) {

            result.objects.map(file => {
                childern.push({
                    "type": 'file',
                    "name": file.fileName,
                    "fullName": file.fileName,
                    "resourceGroupName": "AQTIVA",
                    "storageAccount": storageAccount,
                    "container": containerName,
                    "children": [],
                    "level": 0,
                    "parent": ""
                })
            })
        }

        if (result.prefixes && result.prefixes.length > 0) {
            result.prefixes.map(folder => {
                let name = folder.name.slice(0, -1)

                childern.push({
                    "type": 'Folder',
                    "name": name,
                    "fullName": name,
                    "resourceGroupName": "AQTIVA",
                    "storageAccount": storageAccount,
                    "container": containerName,
                    "children": getObjectChildern(folder, storageAccount, 'Folder', containerName, creds, storageType, dispatch),
                    "level": 0,
                    "parent": ""
                })
            })
        } else {
            childern.push({
                "type": "no data",
                "name": "no data",
                "fullName": "no data",
                "resourceGroupName": "AQTIVA",
                "storageAccount": storageAccount,
                "container": containerName,
                "children": [],
                "level": 1,
                "parent": ""
            })
        }

        return childern
    }

}