import React from 'react'; 
import { InputAdornment, Icon } from '@material-ui/core';
import CustomInput from "components/common/CustomInput/CustomInput.js";

interface RuleNameSelectorProps {
    ruleName: string,
    setRuleName: ( name: string) => (any)

}

interface IValidationElement{
  rule: any,
  description: string
}

interface IValidation {
  [key: string]: IValidationElement
}

export default function RuleNameSelector({ruleName, setRuleName}:  RuleNameSelectorProps) {

   

    

    function validateInputs(states: any, type: string) {
        const validations: IValidation = {
          name: {
            rule: /^[\w_]*$/.test(states.name),
            description: "Incorrect name. The name must only contain letters, numbers and _"
          }
        }
       
          return validations[type]
     
        
      }
      

    return(
        <div style={{ marginLeft: '10%', width:'100%' }}>
                           <CustomInput
                                labelText="Rule Name"
                                id="ProjectName"
                                formControlProps={{
                                    fullWidth: false,

                                    style: { width: '80%' }
                                }}

                                inputProps={{
                                    type: "text",
                                    onChange: ((event: React.ChangeEvent<HTMLInputElement>)  =>setRuleName(event.target.value)),
                                    value: ruleName,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className="fas fa-signature" />
                                        </InputAdornment>
                                    )
                                }}
                                helperText={!validateInputs({ name:ruleName}, "name").rule ? validateInputs({ name:ruleName }, "name").description : ""}
                                error={!validateInputs({ name:ruleName }, "name").rule}
                               
                            />
                        </div>
    )
}
