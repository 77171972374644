import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";

import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import RuleCreationComponent from "../RuleCreationComponent/index";

import {
  ICommonRule,
  IRule,
  IColumn,
  IRuleColumn,
} from "interfaces/RuleInterfaces";
import { IDataformat } from "interfaces/DataformatInterfaces";

import { isEmpty } from "commonFunctions/commonFunctions";

import * as actions from "actions";

import { useAppDispatch } from "reducers/store";
import { ILogicColumnOperator } from "../../../interfaces/LogicCondition";

import * as styleHelper from "style/CommonStyles"
import * as aggregationHelper from 'commonFunctions/aggregationHelper';
import { xhrCallback } from "@sentry/tracing/types/browser/request";
import * as commonStyles from 'style/CommonStyles';

interface DialogCreateRuleProps {
  open: boolean;
  rule: any;
  qualityPoint: any;
  datasource: any;
  project: any;
  setClose: (newValue: any) => any;
  compound?: boolean;
  setChildCompoundRule?: (newValue: any) => any;
}

interface ITag {
  tagValue: string;
}

export default function DialogCreateRule(props: DialogCreateRuleProps) {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState | any) => state.user);

  //const [editable, setEditable] = React.useState(props.rule ? true : false) //check if it is a edit or create based on rule
  const editable = props.rule ? true : false;
  const [activeStep, setActiveStep] = React.useState(0); //step in rule creation
  //const [processingRule, setProcesingRule] = React.useState<IRule>() // common elements of rule

  const steps = [
    "Basic Rule Data",
    "Specific Rule Data",
    "Execution Conditions",
  ]; //get al defined steps

  const [commonRule, setCommonRule] = React.useState<ICommonRule | undefined>(
    props.rule && props.rule.common ? props.rule.common : undefined
  );
  const [specificRule, setSpecificRule] = React.useState(undefined);
  const [mandatoryTags, setMandatoryTags] = React.useState<ITag[]>([]);
  const [optionalTags, setOptionalTags] = React.useState<ITag[]>([]);
  const [mandatoryErrorTags, setMandatoryErrorTags] = React.useState("");
  const [onCloseFlag, setOnCloseFlag] = React.useState(false)
  const [joins, setJoins] = React.useState(props.rule && props.rule.enrichDataformatObject ? props.rule.enrichDataformatObject.joins : []);
  //const [aggregationObject, setAggregationObject] = React.useState()

 

  const [conditions, setConditions] = React.useState<ILogicColumnOperator>(
    props.rule && props.rule.logicOperations
      ? props.rule.logicOperations
      : ({} as ILogicColumnOperator)
  );
  //const [rule, setRule] = React.useState<IRule>({} as IRule)

  const [hasInputErrors, setHasInputErrors] = React.useState(false);
  const [inputErrorTypes, setInputErrorTypes] = React.useState<string[]>([]);
  const [inputErrorMsg, setInputErrorMsg] = React.useState<string[]>([]);

  //set joins if edit
  React.useEffect(() => {
    if (props.rule && props.rule.enrichDataformatObject) {
      setJoins(props.rule.enrichDataformatObject.joins)
    }
    else if(props.rule && props.rule.enrichDataformatObject===undefined){
      setJoins([])
    }
  }, [props])

  

  //effect to update states based on props
  React.useEffect(() => {
    if (props.rule && props.rule.common) {
      setCommonRule(props.rule.common);
      //validateCommonRule(props.rule.common);
    }
    //if props.rule is null set commonrule to null
    if (props.rule === undefined || props.rule === null) {
      setCommonRule(undefined)
    }
  }, [props.rule]);

  //set joins and agg objects
  React.useEffect(() => {
    dispatch(actions.fetchDataFormats(user));

    if (
      props.rule &&
      props.rule.enrichDataformatObject &&
      props.rule.enrichDataformatObject.joins
    ) {
      setJoins(props.rule.enrichDataformatObject.joins);
    }
    else{
      setJoins([])
    }
    if (props.rule && props.rule.logicOperations) {
      setConditions(props.rule.logicOperations);
    }

  }, [props.rule]); // eslint-disable-line react-hooks/exhaustive-deps

  //clean all data after rule creation/edit
  function clearRule() {
    setActiveStep(0);
    setCommonRule(undefined)
    setSpecificRule(undefined)
    setMandatoryTags([])
    setOptionalTags([])
    setMandatoryErrorTags("")
    setJoins([])
    setConditions({} as ILogicColumnOperator)
    setHasInputErrors(false)
    setInputErrorTypes([])
    setInputErrorMsg([])
    setOnCloseFlag(true)
    
  }
  React.useEffect(() => {
    if (inputErrorTypes.find(x => x === 'selectedColumns')) validateCommonRule(commonRule)
  }, [commonRule?.selectedColumns])

  function createRule() {
    let tagsMandatory = mandatoryTags;
    let tagsOptional = optionalTags
      ? optionalTags.filter(
        (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
      )
      : [];
    let allTags =
      tagsMandatory && tagsOptional ? tagsMandatory.concat(tagsOptional) : [];

    const ruleId = props.rule ? props.rule._id : undefined;
    if (commonRule) {
      const rule = createRuleForBackend(
        commonRule,
        specificRule,
        ruleId,
        conditions,
        allTags,
        joins
      );
      if (props.compound) {
        if (props.setChildCompoundRule) props.setChildCompoundRule(rule)
      }
      else {
        dispatch(
          actions.addRule(
            props.project._id,
            props.qualityPoint._id,
            props.datasource._id,
            rule,
            user.id
          )
        );
      }

      return rule;
    }
    return {} as IRule;
  }

  function editRule() {
    let tagsMandatory = mandatoryTags;
    let tagsOptional = optionalTags
      ? optionalTags.filter(
        (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
      )
      : [];
    let allTags =
      tagsMandatory && tagsOptional ? tagsMandatory.concat(tagsOptional) : [];

    const ruleId = props.rule ? props.rule._id : undefined;
    if (commonRule) {
      const rule = createRuleForBackend(
        commonRule,
        specificRule,
        ruleId,
        conditions,
        allTags,
        joins
      );
      if (props.compound) {
        if (props.setChildCompoundRule) props.setChildCompoundRule(rule)
      }
      else {
        dispatch(
          actions.editRule(
            props.project._id,
            props.qualityPoint._id,
            props.datasource._id,
            rule,
            user.id
          )
        );
      }

      return rule;
    }
    return {} as IRule;
  }

  function parseColumns(columns: Array<IColumn>) {
    return columns.map((x) => {
      var newCol = {} as IRuleColumn;
      newCol.cols = [x.name];
      return newCol;
    });
  }
  function setCommonRuleCallback(rule: ICommonRule) {

    if (props.open) {
      setCommonRule(rule)
    }
  }
  function parseWrongRegisterActionType(actionType: string | undefined) {
    if (actionType === undefined) {
      return undefined
    }
    if (actionType === "discard") {
      return "discard_action"
    }
    else if (actionType === "continue") {
      return "continue_action"
    }
    return actionType
  }

  function createRuleForBackend(
    common: ICommonRule,
    specific: any,
    id: string | undefined,
    conditions: ILogicColumnOperator,
    allTags: ITag[],
    joins: any[]
  ) {
    let enrichDataformatObject: IDataformat = {} as IDataformat;
    enrichDataformatObject.joins = joins;

    const weightRuleValue = parseInt(`${common.weight}`) ? common.weight : 1;
    var rule: IRule = {
      name: common.name,
      warningTh: common.thWarning,
      errorTh: common.thError,
      controlModeType: common.output_control,
      wrongRegisterActionType: parseWrongRegisterActionType(common.exclude_wrong_regs_next_rule),
      dimension: common.dimension,
      weight: weightRuleValue,
      categoryLevelError: common.categoryLevelError,
      parameters: common.parameters,
      //subtype: common.subtype,
      columns: common.selectedColumns
        ? parseColumns(common.selectedColumns)
        : [],
      //folder: common.folder !== {} ? common.folder : undefined,
      _id: id,
      // classType: getRuleClassType(common.type),
      active: true,
      logicOperations:
        conditions &&
          conditions.columnConditions &&
          conditions.columnConditions.length > 0
          ? conditions
          : undefined,
      tagList: allTags,
      enrichDataformatObject: enrichDataformatObject,
      ruleHasColumnIssuesFlag: false,
      aggregatedColumnsFlag: common.aggregatedColumnsFlag,
      aggregationObject: common.aggregationObject,
      aggregatePrior2Join: common.aggregatePrior2Join,
      executeByColumn: common.executeByColumn
      //aggregationObject: aggregations
    } as IRule;
    /*
      if (rule.folder && rule.folder.name === 'No Folder') {
          rule.folder = undefined
      }*/

    Object.assign(rule, specific);
    return rule;
  }

  function validateCommonRule(data: ICommonRule | undefined) {
    var hasErrors = false;
    const arrayErrorTypes: string[] = [];
    const arrayErrorMsgs: string[] = [];

    if (data === undefined) {
      return { hasErrors, arrayErrorTypes, arrayErrorMsgs };
    }

    const commonRule = data;
    const errorAggMsg = aggregationHelper.validateAggregationObj(commonRule.aggregationObject, commonRule.aggregatedColumnsFlag)
    if (errorAggMsg !== undefined) {
      hasErrors = true;
      arrayErrorTypes.push("aggregation");
      arrayErrorMsgs.push(errorAggMsg);
    }

    if (!commonRule.name) {
      hasErrors = true;
      arrayErrorTypes.push("name");
      arrayErrorMsgs.push("The name must be provided");
    }

    if (!commonRule.type) {
      hasErrors = true;
      arrayErrorTypes.push("type");
      arrayErrorMsgs.push("A rule type must be selected");
    }
    /* if (!commonRule.subtype) {
        hasErrors = true
        arrayErrorTypes.push('subtype')
        arrayErrorMsgs.push('A rule subtype must be selected')
    }*/
    if (!commonRule.output_control) {
      hasErrors = true;
      arrayErrorTypes.push("output_control");
      arrayErrorMsgs.push("A Output Control mode must be selected");
    }
    if (!commonRule.exclude_wrong_regs_next_rule) {
      hasErrors = true;
      arrayErrorTypes.push("exclude_wrong_regs_next_rule");
      arrayErrorMsgs.push("A Wrong Register Control mode must be selected");
    }

    if (!commonRule.dimension) {
      hasErrors = true;
      arrayErrorTypes.push("dimension");
      arrayErrorMsgs.push("A Dimension must be selected");
    }
    if (!commonRule.qualityPoint) {
      hasErrors = true;
      arrayErrorTypes.push("selectedQualityPoint");
      arrayErrorMsgs.push("A Quality Point must be selected");
    }
    if (!commonRule.datasource) {
      hasErrors = true;
      arrayErrorTypes.push("selectedDatasource");
      arrayErrorMsgs.push("A Datasource must be selected");
    }

    if (
      commonRule.type !== "adHoc" &&
      commonRule.type !== "checkBetweenLoads" &&
      commonRule.type !== "masterData" &&
      commonRule.type !== "comparison"
    ) {
      if (!commonRule.selectedColumns || isEmpty(commonRule.selectedColumns)) {
        hasErrors = true;
        arrayErrorTypes.push("selectedColumns");
        arrayErrorMsgs.push("At least one column must be selected");
      }
    }

    /*if ((commonRule.type === 'dup' || commonRule.type === 'duplicated') && commonRule.selected_columns && commonRule.selected_columns.length > 1) {

        hasErrors = true
        arrayErrorTypes.push('selectedColumns')
        arrayErrorMsgs.push('Duplicated check only accepts one column (simple or combined)')

    }*/
    if (commonRule.selectedColumns && commonRule.selectedColumns.length > 0) {
      for (let i = 0; i < commonRule.selectedColumns.length; i++) {
        if (commonRule.selectedColumns[i].notAvailable) {
          hasErrors = true;
          arrayErrorTypes.push("selectedColumns");
          arrayErrorMsgs.push("Some selected columns are invalid");
        }
      }
    }

    setHasInputErrors(hasErrors);
    setInputErrorTypes(arrayErrorTypes);
    setInputErrorMsg(arrayErrorMsgs);

    return { hasErrors, arrayErrorTypes, arrayErrorMsgs };
  }

  /*
    function validateInputs(states: any) {
        const validations = {
          name: {
            rule: /^[\w_]*$/.test(states.name),
            description: "Incorrect name. The name must only contain letters, numbers and _"
          }
        }
        
        return Object.values(validations).every((value) => value.rule === true)
      }*/

  function validateBasicRuleInfo() {
    //Here we check for rule errors
    const { hasErrors, arrayErrorTypes, arrayErrorMsgs } =
      validateCommonRule(commonRule);
    //TODO: avoid warnings
    if (arrayErrorTypes && arrayErrorMsgs) {
    }
    let hasTagErrors = false;
    let emptyMandatoryTags = mandatoryTags.filter(
      (tag) => tag.tagValue === undefined || tag.tagValue === ""
    );
    //here we check for tag errors
    if (emptyMandatoryTags.length > 0) {
      hasTagErrors = true;
      setMandatoryErrorTags("Please complete all Mandatory tags.");
    }
    if (hasTagErrors) {
      return false;
    }
    if (hasErrors) {
      return false;
    }
    return true;
  }
  //take one step Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      const validated = validateBasicRuleInfo();
      if (validated === false) {
        return;
      }
    }
    if (activeStep === steps.length - 1) {
      handleFinish();
    }
    if(!hasInputErrors) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function handleFinish() {
    createRule();
    setActiveStep(0);
    clearRule();
  }

  function handleFinishEdit() {
    editRule();
    setActiveStep(0);
    clearRule();
  }

  

  //wrapper to save rule
  const saveItem = () => {
    handleFinish();
    props.setClose(false);
  };

  const editItem = () => {
    handleFinishEdit();
    props.setClose(false);
  };

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={"xl"}>
      <DialogTitle style={{ backgroundColor: commonStyles.darkColor }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <img
              alt=""
              style={{
                height: "25px",
                width: "28px",
                marginLeft: "5px",
                marginRight: "5px",
                marginTop: "5px",
              }}
              src={require("resources/icons/rule_proc1_white.png")}
            />
          </Grid>
          <Grid item>
            <Typography style={{ color: "#D3D0D3" }}>
              {editable ? "Edit Rule" : "Create Rule"}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <RuleCreationComponent
          step={activeStep}
          setStep={setActiveStep}
          rule={props.rule}
          qualityPoint={props.qualityPoint}
          project={props.project}
          datasource={props.datasource}
          userId={user.id}
          commonRule={commonRule}
          joins={joins}
          specificRule={specificRule}
          conditions={conditions}
          mandatoryErrorTags={mandatoryErrorTags}
          setConditions={setConditions}
          setJoins={setJoins}
          setOptionalTags={setOptionalTags}
          setMandatoryTags={setMandatoryTags}
          setCommonRule={setCommonRuleCallback}
          setSpecificRule={setSpecificRule}
          setMandatoryErrorTags={setMandatoryErrorTags}
          hasErrors={hasInputErrors}
          arrayErrorTypes={inputErrorTypes}
          arrayErrorMsgs={inputErrorMsg}
          onCloseFlag={onCloseFlag}
          setHasErrors={setHasInputErrors}
        />
      </DialogContent>

      <DialogActions>
        <Button
          id="CancelButton"
          variant="outlined"
          style={{ color: styleHelper.errorColor, fontWeight: 'bold' }}
          onClick={(event) => {
            clearRule();
            props.setClose(false);
          }}
        >
          Cancel
        </Button>
        {activeStep !== 0 && (
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
          >
            Back
          </Button>
        )}

        {activeStep !== steps.length - 1 && (
          <Button
            //disabled={processingRule ? !validateInputs({ name: processingRule.common ? processingRule.common.name : '' }) : false}
            disabled={false}
            variant="outlined"
            onClick={handleNext}
            style={{ color: styleHelper.mainColor, fontWeight: 'bold' }}
          >
            Next
          </Button>
        )}
        {!editable && activeStep === steps.length - 1 && (
          <Button
            id="SaveButton"
            variant="outlined"
            style={{ color: styleHelper.mainColor, fontWeight: 'bold' }}
            onClick={(event) => {
              saveItem();
            }}
          >
            Save
          </Button>
        )}

        {editable && activeStep === steps.length - 1 && (
          <Button
            style={{ color: styleHelper.mainColor, fontWeight: 'bold' }}
            id="SaveButton"
            variant="outlined"
            onClick={(event) => {
              editItem();
            }}
          >
            Edit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
