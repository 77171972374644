import React from "react";
import MaterialTable from "@material-table/core";
import * as commonStyles from 'style/CommonStyles'
import {  Tooltip } from "@material-ui/core";
import AqtivaDialog from "components/common/AqtivaDialog";
import { faKey } from '@fortawesome/free-solid-svg-icons'
import RulesScoreChartSmall from "../Charts/RulesScoreChartSmall";
import * as windowHelper from 'commonFunctions/windowHelper'

export default function ExecutionRulesTable(props) {

    const [rules, setRules] = React.useState([])
    const [showDialog, setShowDialog] = React.useState(false)
    const { height, width } = windowHelper.useWindowDimensions();
    const chartTableHeight = '60px'
    const chartTableWidth = '120%'
    const chartTableMarginLeft = '-15%'
    const chartTableMarginBottom = '-20px'

    React.useEffect(() => {
        if (props.rules && props.rules.length > 0) {
            setRules(props.rules.map((rule, index) => {
                let score = Number(rule.dqiWeight*100).toFixed(0);
                let scoreKo = -Number(100 - rule.dqiWeight*100).toFixed(0)
                
                return {
                    'index': index,
                    'rule': capitalizeFirstLetterAndTruncate(rule.ruleName,35),
                    'ruleType': rule.ruleType,
                    'desc': rule.msg,
                    'columns': '',
                    'avgScore': score,
                    'scoreKo': scoreKo,
                    'ruleWeight': rule.priority === 0 ? 1 : rule.priority,
                    'numOk': rule.numOk,
                    'numKo': rule.numKo,
                    'numIn': rule.numIn,
                    'dqi': rule.dqiWeight,
                    'priority': rule.weight
                }
            }))
        }
    }, [props.rules])


    /*function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }*/
    function capitalizeFirstLetterAndTruncate(string, lengthString) {
        if(string!==null && string !==undefined){
            if(string.length> lengthString){
                return (string.charAt(0).toUpperCase() + string.slice(1)).slice(0,lengthString-3)+'...'
            }
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    return (
        <>
            <MaterialTable

                columns={[
                    {
                        title: 'Rule', field: 'rule', width: "10%",
                       
                    },
                    {
                        title: 'Score', width: '20%', cellStyle: { paddingLeft: '5%' }, headerStyle: { paddingLeft: '5%' },

                        render: (rowData) => {
                            return (
                                <RulesScoreChartSmall score={rowData.avgScore} scoreKo={rowData.scoreKo} chart={'score' + rowData.index} height={chartTableHeight} width={chartTableWidth} marginLeft={chartTableMarginLeft}
                                    marginBottom={chartTableMarginBottom} fullscreen={false} />
                            )

                        }

                    },
                    { title: 'Rule Weight', field: 'ruleWeight', width: "10%", marginRight: '5%' },
                    { title: 'Rule Type', field: 'ruleType', width: "10%", marginRight: '5%' },
                    {
                        title: 'Description', field: 'desc', width: "10%", render: (rowData) => {
                            let desc = rowData.desc.length > 0 ? rowData.desc.substring(0, 12) + '...' : ''
                            return (
                                <Tooltip title={rowData.desc}>
                                    <span>{desc}</span>
                                </Tooltip>
                            )
                        }
                    },
                   /* { title: 'Columns', field: 'columns', width: "10%", headerStyle: { align: 'center' } },*/
                    { title: 'Avg Score', field: 'avgScore', width: "10%", headerStyle: { align: 'center' } },
                    { title: 'Num Ok', field: 'numOk', width: "10%", headerStyle: { align: 'center' } },
                    { title: 'Num Ko', field: 'numKo', width: "10%", headerStyle: { align: 'center' } },
                    { title: 'Num In', field: 'numIn', width: "10%", headerStyle: { align: 'center' } },
                   /*  { title: 'Rule Weight', field: 'weight', width: "10%", headerStyle: { align: 'center' } }, 
                    {
                        title: 'Details', field: 'details', align: 'center', width: "10%", headerStyle: { align: 'center' }, render: (rowData) => {

                            return (
                                <Tooltip title={'Project Detail'}>
                                    <IconButton size='small' onClick={event => {
                                        setShowDialog(true)
                                    }} >

                                        <TuneIcon />
                                    </IconButton>
                                </Tooltip>
                            )

                        }

                    },*/

                ]}
                data={rules}

                options={{
                    title: false,
                    search: false,
                    paging: false,
                    toolbar: false,
                    tableLayout: 'fixed',
                    cellStyle: commonStyles.tableCellStyle
                }}

                headerStyle={{
                    align: 'right'
                }}

                style={{ margin: '0', marginRight: '10%' }}
            />
            <AqtivaDialog visible={showDialog}
                title={`Rule Detail`}
                maxWidth={width}
                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                cancelCallback={event => { setShowDialog(false) }}
            >
                <div style={{ width: width * 0.9, height: height * 0.9 }}>

                </div>



            </AqtivaDialog>
        </>
    )
}