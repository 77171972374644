import React from 'react';

import { makeStyles, TextField, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Grid, Typography, MenuItem, InputLabel, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { styles, QualityTheme } from '../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
import AqtivaDialogEncryptString from '../../../common/AqtivaDialogEncryptString'

import * as commonStyles from 'style/CommonStyles'


export default function AzureDatabaseConfiguration(props) {


        const classes = makeStyles(styles(QualityTheme));

      

        const [analyticDatabaseConnection, setAnalyticDatabaseConnection] = React.useState(props.configuration && props.configuration.analyticDatabaseConnection ?
                props.configuration.analyticDatabaseConnection : {})

        const [visibleAzureSecretEncrypt, setVisibleAzureSecretEncrypt] = React.useState(false)



        React.useEffect(() => {


             
                setAnalyticDatabaseConnection(props.configuration && props.configuration.analyticDatabaseConnection ?
                        props.configuration.analyticDatabaseConnection : {})


        }, [props.configuration, props.parentConfiguration]);

        React.useEffect(() => {
                props.setConfig(analyticDatabaseConnection)
        }, [analyticDatabaseConnection]);// eslint-disable-line react-hooks/exhaustive-deps

        function setValue(param, value) {
               
                var oldDatabaseConn =  setDefaultValues()
                oldDatabaseConn[param] = value
                setAnalyticDatabaseConnection(oldDatabaseConn)
        }

        function setDefaultValues() {
                var oldDatabaseConn = Object.assign({}, analyticDatabaseConnection);
                oldDatabaseConn['schema'] = "aqtiva"
                oldDatabaseConn['cronValue'] = "15 25 14 * * *"
                return oldDatabaseConn
        }

        function getDatabaseEngineValues(){
                const databaseEngines = [
                    {value: "SQL_DATABASE", name: "Azure SQL Database"},
                    {value: "SQL_SERVER", name: "SQL Server"},
                    {value: "SQL_MYSQL", name: "MySQL"},
                    {value: "SQL_POSTGRESQL", name: "PostgreSQL"}
                ];
    
                return databaseEngines.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                ))
            }

        return (
                <MuiThemeProvider theme={QualityTheme}>
                        <AqtivaDialogEncryptString visible={visibleAzureSecretEncrypt}
                                setVisible={setVisibleAzureSecretEncrypt}
                                confirm={(event) => { setValue('pwd', event) }}
                                sName={"sqlanalyticpwd" + props.configuration._id}
                        />

                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Sql Analytic Database Configuration</Typography>
                                        </Grid>

                                </AccordionSummary>
                                <AccordionDetails>
                                        <div>
                                                <div style={{ width: '100%', minWidth: '800px' }}>
                                                <FormControlLabel
                                                            style={{ marginLeft: '5%', width: '50%' }}
                                                            control={
                                                                <div style={{
                                                                    display: "flex",
                                                                    width: "100%",
                                                                    marginTop: "8px",
                                                                    marginBottom: "4px",
                                                                    flexDirection: "column",
                                                                    transform: "translate(0, 1.5px) scale(0.75)",
                                                                    transformOrigin: "top left"
                                                                }} >
                                                                    <InputLabel
                                                                        style={{
                                                                            color: "rgba(0, 0, 0, 0.54)",
                                                                            padding: "0",
                                                                            fontSize: "12px",
                                                                            fontFamily: "Robot;",
                                                                            fontWeight: "400",
                                                                            lineHeight: "1"
                                                                        }}
                                                                        id={"serverEngineLabel"}>
                                                                        Server Engine
                                                                    </InputLabel>
                                                                    <Select
                                                                        style={{width: '100%'}}
                                                                        labelId={"serverEngine"}
                                                                        id={"serverEngine"}
                                                                        label={"Server Engine"}
                                                                        value={(analyticDatabaseConnection && analyticDatabaseConnection.connectionType) ? analyticDatabaseConnection.connectionType : ''}
                                                                        onChange={(event) => { setValue('connectionType', event.target.value) }}
                                                                    >
                                                                        <MenuItem disabled={true}>Select database engine</MenuItem>
                                                                        {getDatabaseEngineValues()}
                                                                    </Select>
                                                                </div>
                                                            } />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="serverNameId"
                                                                                label="Server Name"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={(analyticDatabaseConnection && analyticDatabaseConnection.server) ? analyticDatabaseConnection.server : ''}
                                                                                onChange={(event) => { setValue('server', event.target.value) }}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databaseNameId"
                                                                                label="Database Name"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={(analyticDatabaseConnection && analyticDatabaseConnection.database) ? analyticDatabaseConnection.database : ''}
                                                                                onChange={(event) => { setValue('database', event.target.value) }}
                                                                        />} />

                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="schemaId"
                                                                                label="Schema Name"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={(analyticDatabaseConnection && analyticDatabaseConnection.schema) ? analyticDatabaseConnection.schema : 'aqtiva'}
                                                                                onChange={(event) => { setValue('schema', event.target.value) }}
                                                                        />} />

                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="userId"
                                                                                label="Database User"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={(analyticDatabaseConnection && analyticDatabaseConnection.user) ? analyticDatabaseConnection.user : ''}
                                                                                onChange={(event) => { setValue('user', event.target.value) }}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="pwdId"
                                                                                label="Database Password"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                type="password"
                                                                                value={(analyticDatabaseConnection && analyticDatabaseConnection.pwd) ? analyticDatabaseConnection.pwd : ''}
                                                                                onClick={event => setVisibleAzureSecretEncrypt(true)}
                                                                                onChange={(event) => { setValue('secret', event.target.value) }}
                                                                        />} />
                                                         <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="schemaId"
                                                                                label="Cron Scheduler Expression"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={(analyticDatabaseConnection && analyticDatabaseConnection.cronValue) ? analyticDatabaseConnection.cronValue : '15 25 14 * * *'}
                                                                                onChange={(event) => { setValue('cronValue', event.target.value) }}
                                                                        />} />


                                                </div>
                                        </div>



                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}