import _ from 'lodash';
import {
    FETCH_PROJECTS,
    FETCH_PROJECT,
    EDIT_PROJECT,
    DELETE_PROJECT,
    CREATE_PROJECT,
    COPY_DATASOURCE
} from '../actions/types'


export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_PROJECTS:
            return { ..._.mapKeys(action.payload, '_id') }
        case FETCH_PROJECT:
            return { ...state, [action.payload._id]: action.payload };
        case CREATE_PROJECT:
            return { ...state, [action.payload._id]: action.payload };
        case DELETE_PROJECT:
            return _.omit(state, action.payload)
        case EDIT_PROJECT:
            return { ...state, [action.payload._id]: action.payload };
        case COPY_DATASOURCE:
            return { ...state, [action.payload._id]: action.payload };
        default:
            return state;

    }
}