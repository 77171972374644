//----------------------------- functions used when we delete a join from a RULE ------------------------------//

// here we get all columns used in the SELECTED RULE
export function getAllColumnsUsedInSelectedRule(datasource, rule) {
    let allColumnValues = []
    if (rule && rule.columns && rule.columns.length > 0) {
        for (let i = 0; i < rule.columns.length; i++) {
            if (rule.columns[i].cols) {
                let columnInfo = {}
                columnInfo.name = rule.columns[i].cols[0]
                columnInfo.ruleId = rule._id
                columnInfo.ruleName = rule.name
                allColumnValues.push(columnInfo)
            }
        }
    }
    return allColumnValues
}
// here we check if some of the columns from this enrich dataset are used in the SELECTED RULE
export function checkIfColumnsAreUsedInSelectedRule(allColumnsUsed, element2Remove, datasource, rule) {

    let columnsInElement2Remove = element2Remove.columns.map(column => `${element2Remove.alias}_${column.name}`)
    let rulesWithIssues = []
    for (let i = 0; i < allColumnsUsed.length; i++) {
        if (columnsInElement2Remove.includes(allColumnsUsed[i].name)) {
            let rule2UpdateFlag = datasource.rules.find(rule => rule._id === allColumnsUsed[i].ruleId)
            rulesWithIssues.push(rule2UpdateFlag)
        }
    }
    return rulesWithIssues
}
//----------------------------------------------end------------------------------------------------------------------//

//----------------------------- functions used when we delete a join from a DATASOURCE ------------------------------//

// here we get all columns used in all the rules inside the datasourcee
export function getAllColumnsUsedInRules(datasource) {
    let datasource2Examine = JSON.parse(JSON.stringify(datasource))
    let allColumnValues = []
    if (datasource2Examine && datasource2Examine.rules && datasource2Examine.rules.length > 0) {
        for (let i = 0; i < datasource2Examine.rules.length; i++) {
            if (datasource2Examine.rules[i].columns && datasource2Examine.rules[i].columns.length > 0) {
                for (let j = 0; j < datasource2Examine.rules[i].columns.length; j++) {
                    if (datasource2Examine.rules[i].columns[j].cols) {
                        let columnInfo = {}
                        columnInfo.name = datasource2Examine.rules[i].columns[j].cols[0]
                        columnInfo.ruleId = datasource2Examine.rules[i]._id
                        columnInfo.ruleName = datasource2Examine.rules[i].name
                        allColumnValues.push(columnInfo)
                    }
                }
            }
        }
    }
    return allColumnValues
}
// here we check if some of the columns from this enrich dataset are used in some rules
export function checkIfColumnsAreUsed(allColumnsUsed, element2Remove, datasource) {

    let columnsInElement2Remove = element2Remove.columns.map(column => `${element2Remove.alias}_${column.name}`)
    let rulesWithIssues = []
    for (let i = 0; i < allColumnsUsed.length; i++) {
        if (columnsInElement2Remove.includes(allColumnsUsed[i].name)) {
            let rule2UpdateFlag = datasource.rules.find(rule => rule._id === allColumnsUsed[i].ruleId)
            rulesWithIssues.push(rule2UpdateFlag)
        }
    }
    let rulesWithIssuesUnique = [...new Set(rulesWithIssues)]
    return rulesWithIssuesUnique
}

// here we check if some of the columns from this enrich dataset are used in some rules AND we edit the rule flag 
export async function checkIfColumnsAreUsedAndUpdateRuleFlagDeleting(allColumnsUsed, element2Remove, project, qualityPoint, datasource, userId, dispatch, actions) {

    let columnsInElement2Remove = element2Remove.columns.map(column => `${element2Remove.alias}_${column.name}`)
    for (let i = 0; i < allColumnsUsed.length; i++) {
        const colUsed = allColumnsUsed[i]
        if (columnsInElement2Remove.includes(colUsed.name)) {
            let rule2UpdateFlag = JSON.parse(JSON.stringify(datasource.rules.find(rule => rule._id === colUsed.ruleId)))
            rule2UpdateFlag.ruleHasColumnIssuesFlag = true
            await dispatch(actions.editRule(project._id, qualityPoint._id, datasource._id, rule2UpdateFlag, userId))
            allColumnsUsed = allColumnsUsed.filter(column => column.ruleId !==colUsed.ruleId)

        }
    }
}
export async function checkIfColumnsAreUsedAndUpdateRuleFlagEditing(allColumnsUsed, element2Remove, project, qualityPoint, datasource, userId, dispatch, actions) {

    let columnsInElement2Remove = element2Remove.columns.map(column => `${element2Remove.alias}_${column.name}`)
    for (let i = 0; i < allColumnsUsed.length; i++) {
        const colUsed = allColumnsUsed[i]
        if (!columnsInElement2Remove.includes(colUsed.name)) {
            let rule2UpdateFlag = JSON.parse(JSON.stringify(datasource.rules.find(rule => rule._id === colUsed.ruleId)))
            rule2UpdateFlag.ruleHasColumnIssuesFlag = true
            await dispatch(actions.editRule(project._id, qualityPoint._id, datasource._id, rule2UpdateFlag, userId))
            allColumnsUsed = allColumnsUsed.filter(column => column.ruleId !==colUsed.ruleId)

        }
    }
}
//---------------------------------------------- end--------------------------------------------------------------//





//--------------------------------------------- modifying a datasource's dataset-----------------------------------//

export function checkIfDataformatColumnsAreUsed(allColumnsUsed, element2Remove, datasource) {

    let columnsInElement2Remove = element2Remove.columns.map(column => column.name)
    let rulesWithIssues = []
    for (let i = 0; i < allColumnsUsed.length; i++) {
        if (columnsInElement2Remove.includes(allColumnsUsed[i].name)) {
            let rule2UpdateFlag = datasource.rules.find(rule => rule._id === allColumnsUsed[i].ruleId)
            rulesWithIssues.push(rule2UpdateFlag)
        }
    }
    let rulesWithIssuesUnique = [...new Set(rulesWithIssues)]
    return rulesWithIssuesUnique
}

export async function checkIfColumnsAreUsedAndUpdateRuleFlagEditingDatasource(allColumnsUsed, newDatasource, project, qualityPoint, datasource, userId, dispatch, actions) {
    if (newDatasource.dataset && newDatasource.dataset.columns) {

        let columnsInNewDatasource = newDatasource.dataset.columns.map(column => column.name)
        for (let i = 0; i < allColumnsUsed.length; i++) {
            const colUsed = allColumnsUsed[i]
            if (columnsInNewDatasource.includes(colUsed.name)) {
                let rule2UpdateFlag = JSON.parse(JSON.stringify(datasource.rules.find(rule => rule._id === colUsed.ruleId)))
                rule2UpdateFlag.ruleHasColumnIssuesFlag = true
                await dispatch(actions.editRule(project._id, qualityPoint._id, datasource._id, rule2UpdateFlag, userId))
                allColumnsUsed = allColumnsUsed.filter(column => column.ruleId !==colUsed.ruleId)

            }
        }
    }
}