import React from 'react';
import { Grid, } from '@material-ui/core';

import ColumnComparisonParams from './ColumnComparisonParams'
import { getDataFromProperties } from '../SpecificRuleHelper'




export default function FormColumnComparison(props) {

    const [columnOperations, setColumnOperations] = React.useState(undefined)
    const [nullAccepted, setNullAccepted] = React.useState(getDataFromProperties(props.editableRule, props.specific, 'nullAccepted', true))
    const [marginComparison, setMarginComparison] =  React.useState(getDataFromProperties(props.editableRule, props.specific, 'marginComparison', 0))

    function createSpecific() {
        //creamos el objeto de regla especifica
        const specific = {
            subtype: 'cell_column_comparison',
            columnOperations: columnOperations,
            nullAccepted: nullAccepted,
            marginComparison: marginComparison
        }
        props.setSpecific(specific)

    }

    /*function setDefaultSpecific(data) {

        //creamos el objeto de regla especifica
        const specific = {
            subtype: 'cell_column_comparison',
            columnOperations: columnOperations
        }
        props.setSpecific(specific)

    }*/


    React.useEffect(() => {
        createSpecific()
    }, [columnOperations, nullAccepted, marginComparison]);// eslint-disable-line react-hooks/exhaustive-deps









    return (
        <div >



            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <ColumnComparisonParams master={true} configuration={props.configuration} setColumnOperations={setColumnOperations} datasource={props.datasource} columnOperationsInfo={props.editableRule && props.editableRule.columnOperations ? props.editableRule.columnOperations : false} newRule={props.editableRule && props.editableRule.columnOperations ? false : true} errors={props.errorsComparison} source={props.source} nullAccepted={nullAccepted} setNullAccepted={setNullAccepted} marginComparison={marginComparison} setMarginComparison={setMarginComparison} />
                </Grid>

            </Grid>
        </div>
    )
}
