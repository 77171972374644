
import React from 'react';

import { Typography,Card,CardContent,FormControl,InputLabel} from '@material-ui/core'
    
import NativeSelect from '@material-ui/core/NativeSelect';
    
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width:'100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    resize:{
        fontSize:14
      }
  }));

export default function OutputControlCard(props) {

    const classes = useStyles();
    const theme = useTheme()

    const title = props.title

    const controlModeTypes = [
        { label: 'Info', value: 'INFO' },
        { label: 'Warning', value: 'WARNING' },
        { label: 'Error', value: 'ERROR' }
    ];

    const wrongRegisterActionTypes= [
        { label: 'Include', value: 'include_action' },
        { label: 'Discard', value: 'discard_action' }
    ];

    const [controlMode,setControlMode] = React.useState(props.obj ? props.obj.outputMode: 'INFO')
    const [wrongRegister,setWrongRegister] =React.useState(props.obj ? props.obj.wrongRegisterAction : 'include_action')

    React.useEffect(() => {   
        setControlMode(props.obj ? props.obj.outputMode: 'INFO')
        setWrongRegister(props.obj ? props.obj.wrongRegisterAction : 'include_action')
      }, [props]);

    const handleControlModeChange = (event) => {setControlMode(event.target.value); };
    const handleWrongRegisterAction = (event) => {setWrongRegister(event.target.value); };

    

    const update = () =>{
        const obj = {
            controlModeType: controlMode,
            wrongRegisterActionType: wrongRegister,
        }
        props.setObj(obj)
    }

    React.useEffect(() => {
        update()

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //calls parent callback when any element is changed
    React.useEffect(() => {
        update()
    }, [controlMode,wrongRegister]);// eslint-disable-line react-hooks/exhaustive-deps
   

    return(
        <Card elevation={12}
        style={{minHeight:'265px'}}>
                                    <CardContent>
                                        <Typography variant='subtitle2'> {title}</Typography>
                                    </CardContent>
                                    <CardContent style={{marginRight:'5%'}}>
                                    <FormControl className={classes.formControl}>
                                     <InputLabel htmlFor="typeWarnTh-selection-helper">Control Mode</InputLabel>
                                     {(!props.obj || !props.obj.outputMode) && 
                                                 <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >Control Mode must be set</div>
                                                </div>
                                        }
                                        <NativeSelect 
                                        style={{
                                            width:'100%',
                                            fontSize: 14,
                                            
                                        }}
                                        value={controlMode}
                                        onChange={event => handleControlModeChange(event)}
                                        >
                                            {controlModeTypes.map((event) => (<option key={event.value} value={event.value}>
                                               {event.label}
                                            </option>))}
                                        </NativeSelect>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                     <InputLabel htmlFor="typeWarnTh-selection-helper">Wrong Data Action</InputLabel>
                                     {(!props.obj || !props.obj.wrongRegisterAction) && 
                                                 <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >Wrong Data Action must be set</div>
                                                </div>
                                        }
                                        <NativeSelect 
                                        style={{
                                            width:'100%',
                                            fontSize: 14,
                                            
                                        }}
                                        value={wrongRegister}
                                        onChange={event => handleWrongRegisterAction(event)}
                                        >
                                           {wrongRegisterActionTypes.map((event) => (<option key={event.value} value={event.value}>
                                               {event.label}
                                            </option>))}
                                        </NativeSelect>
                                    </FormControl>

                                   
                                   
                                    </CardContent>
                                </Card>
    )
}