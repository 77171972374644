import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StyledMenu } from "../common/StyleMenu";
import { MenuItem } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSearch, faBell } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import { AqtivaDialogDataRepository } from "components/data/DataRepositoryShare/EntityDataRepository/AqtivaDialogDataRepository";
import { AllNotifications } from "./AllNotifications";
//import * as actions from "./../../actions";
import {clickOnNotification, markAsRead} from "./NotificationsHelper"
import AqtivaDialog from '../common/AqtivaDialog';

//Name of html notifications: simulationUserNotification (name for the db)

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function NotificationsMenu(props) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const user = useSelector((store) => store.user);
  const unreadNotifications = useSelector((store) =>
    Object.values(store.notifications)
  );

  const [dialog, setDialog] = React.useState(false);
  const [dialogHtml, setDialogHtml] = React.useState(false);
  const [htmlStringNotification, setHtmlStringNotification] = React.useState("");

  function truncate(strShort,strLong, n, useWordBoundary) {
    if((strShort===undefined || strShort===null) && (strLong===undefined || strLong===null)){
      //TODO: CHECK
      return strLong
    }
    let str = (strShort===undefined || strShort===null)? strLong : strShort
    if (str.length <= n) {
      return str;
    }
    const subString = str.substr(0, n - 1);
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "..."
    );
  }
  //DELETE THIS MOCK OBJECT WHEN WE RECEIVE REAL INFO
/*    const htmlNotification = {
    longDescription: 'There is a new release in Aqtiva',
    shortDescription: 'Aqtiva new release',
    notificationType: 'simulationUserNotification',
    params: {
      message: `<h1>There is a new release in Aqtiva</h1>
      <p>Check the new features
      <ul>
      <li>New user accounts</li>
      <li>More storage</li>
      <li>Improved user interface</li>
      </ul>
      </p>`
    }
  }

  unreadNotifications.push(htmlNotification)  */

//

  const notificationsItems = unreadNotifications.filter(notification=>notification!==undefined && notification!==null).map((notification, key) => {
    
    return (
      <StyledMenuItem key={key}>
        <Grid container spacing={1} style={{ width: "100%" }}>
        <Grid item xs={3}>
              <Typography style={theme.notifications.dateItem}>
                {notification!==undefined && notification!==null && notification.creationDate ? notification.creationDate.substring(0,19).replace('T',' ') : ''}
                </Typography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title = {notification.longDescription}>
              <Typography style={theme.notifications.mainItem}>
                {truncate(notification.shortDescription, notification.longDescription, 60)}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
          <Tooltip title="Mark as read">
            <Button
              style={{
                color: "white",
                width: "5px",
                marginRight:"10px"
              }}
              onClick={() => markAsRead(notification, user, dispatch, props)}
            >
              <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
            </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
          <Tooltip title="Go to detail">
            <Button
              style={{
                color: "white",
                width: "5px",
                marginRight: "-10px",
                marginLeft: "15px",
              }}
              onClick={() => { 
                if (notification && notification.notificationType === "simulationUserNotification") {
                  setDialogHtml(true) //to open the dialog
                  setHtmlStringNotification(notification.params.message) // to show the html chain
                } else {
                  clickOnNotification(notification, props.history, user, dispatch)
                }
              }}
            >
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </StyledMenuItem>
    );
  });

  const showNotificationsOrDefaultMessage = () => {
    if (
      unreadNotifications &&
      unreadNotifications.length > 0 &&
      notificationsItems
    ) {
      return (
          <div>
            {unreadNotifications &&
              unreadNotifications.length > 0 &&
              notificationsItems}
          </div>
      );
    } else {
      return (
          <Typography
            variant="subtitle2"
            style={theme.notifications.notificationDefaultText}
          >
            You don't have new notifications
          </Typography>
      );
    }
  };
 

  return (
    <>
      <div>
        <StyledMenu
          id="menu-appbar"
          anchorEl={props.anchorElNotification}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={props.openNotifications}
          onClose={props.handleCloseNotifications}
          style={{ minWidth: "500px" }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography variant="subtitle2" style={theme.notifications.title}>
              Notifications
            </Typography>
          </div>
          <Divider
            variant="inset"
            component="li"
            style={{ backgroundColor: "#FFFFFF", margin: "1%" }}
          />
          {showNotificationsOrDefaultMessage()}
          <Divider
            variant="inset"
            component="li"
            style={{ backgroundColor: "#FFFFFF", margin: "1%" }}
          />

          <Grid container spacing={1} style={{ width: "100%" }}>
            <Grid item xs={12}>
              <Button
                style={theme.notifications.notificationsButton}
                onClick={() => setDialog(true)}
              >
                <Typography
                  variant="subtitle2"
                  style={theme.notifications.notificationsButton}
                >
                  View all notifications
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </StyledMenu>
      </div>
      <AqtivaDialogDataRepository
        dialog={dialog}
        setDialog={setDialog}
        title="All notifications"
        titleIcon={faBell}
        showConfirmButton={false}
        confirmCallback={(event) => console.log("I am clicking here")}
      >
        <AllNotifications history={props.history} />
      </AqtivaDialogDataRepository>
      
      <AqtivaDialog visible={dialogHtml} //HTML DIALOG FUNCTION
         maxWidth = {'lg'}
         title={'New release'}
         titleIcon={faBell}
         showConfirmButton={false}
         cancelText={'Close'}
         cancelCallback={() => { setDialogHtml(false) }}
         >
           <div dangerouslySetInnerHTML={{ __html: htmlStringNotification, }} />
         </AqtivaDialog>
    </>
  );
}
