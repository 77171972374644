import { getColumns } from "components/quality/QualityManagementProjects/QualityProjectsTable/QualityProjectsTableHelper";


export const ruleTypesBatch = [
    { label: 'Schema Validation', value: 'statistics' },
    { label: 'Null Detection', value: 'null' },
    { label: 'Regular Expression', value: 'exp' },
    { label: 'Value Ranges', value: 'ranges' },
    { label: 'Timeliness', value: 'time' },
    { label: 'Duplicated Check', value: 'duplicated' },
    { label: 'Check Data between loads', value: 'checkBetweenLoads' },
    { label: 'Referencial Integrity', value: 'masterData' },
    { label: 'Ad-Hoc Expression', value: 'adHoc' },
    { label: 'Column Comparison', value: 'comparison' },
    { label: '(Preview)Column Anomaly Detection', value: 'cell_anomaly_columns' },
    { label: '(Preview)Row Anomaly Detection', value: 'cell_anomaly_rows' },
   
    //{ label: 'Column Anomaly Detection', value: 'anomalyColumns' }
 

];

export const ruleTypesLambda = [
    { label: 'Schema Validation', value: 'statistics' },
    { label: 'Null Detection', value: 'null' },
    { label: 'Regular Expression', value: 'exp' },
    { label: 'Value Ranges', value: 'ranges' },
    { label: 'Timeliness', value: 'time' },
    { label: 'Ad-Hoc Expression', value: 'adHoc' },
    { label: 'Column Comparison', value: 'comparison' },


];

export const ruleDimensions = [
    'Integrity','Veracity','Unicity','Timeliness','Format'
];
/*
export function getIconFromDimension(dimension: string){
    switch (dimension) {
        case 'Integrity':
            return <CompareArrowsIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
        case 'Veracity':
            return <CheckCircleOutlineIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
        case 'Unicity':
            return <AllOutIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
        case 'Timeliness':
                return <AvTimerIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
                case 'Format':
                    return <AvTimerIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
        default:
            return <CompareArrowsIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />

    }
}*/

export function getDimensionFromRuleType(ruleType: string) {
    if(ruleType === undefined || ruleType === null){
        return 'integrity'
    }
    switch (ruleType.toUpperCase()) {
        case 'STATISTICS':
            return 'format'
        case 'TIME':
            return 'timeliness'
        case 'DUPLICATED':
            return 'unicity'
        default:
            return 'integrity'

    }
}

export function getDatasetColumns(aggregatedObject: any, datasource: any){
    console.log('getColumns', aggregatedObject,datasource)
}