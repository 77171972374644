import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import * as commonStyles from 'style/CommonStyles'




export default function ReturnPreviousAgregationButton(props) {

    function returnToParent() {
        if (props.agregationLevel && props.agregationLevel === "project") {
            props.setAgregationLevel("projects")
        }
        if (props.agregationLevel && props.agregationLevel === "qualityPoint") {
            props.setAgregationLevel("project")
        }
        if (props.agregationLevel && props.agregationLevel === "datasource") {
            props.setAgregationLevel("qualityPoint")
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button style={commonStyles.btnBack} onClick={(event) => { returnToParent() }} startIcon={<ArrowBack/>}>return</Button>
            </div>
        </>

    );
}