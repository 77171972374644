import React from 'react';
import SaveIcon from '@material-ui/icons/Save';


export const buttonDefinition = [
    {
        id: 'createDataset',
        labelId: 'createDatasetLabel',
        icon: <SaveIcon />
    }
 ]


 