import React from 'react';
import {StyledMenu} from './../common/StyleMenu'
import {MenuItem} from "@material-ui/core";
import {IconButton} from '@material-ui/core';
import AccountCircle from "@material-ui/icons/AccountCircle";
import {Grid,Typography} from '@material-ui/core';
import { ACCESS_TOKEN} from './../../Constants';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import BuildIcon from '@material-ui/icons/Build';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { Redirect } from 'react-router-dom'
import { AuthManager } from "@azure/ms-rest-browserauth";

import trackEvent from './../../trackEvent';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';

import * as actions from 'actions'



const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);



export default function ProfileMenu(props){
      const user = useSelector(store => store.user)
      const dispatch = useDispatch();

      
    var token = localStorage.getItem(ACCESS_TOKEN)
    
    async function logout (){
      const appId = await dispatch(actions.getControlValue("AQTIVA_APP_ID"))
      const tenantId =await dispatch(actions.getControlValue("AQTIVA_TENANT_ID"))
      
      trackEvent('Login', 'Logout')
      localStorage.clear()
      token = false
      console.log('logout', process.env.REACT_APP_SSO_AZURE, user )
      if((process.env.REACT_APP_SSO_AZURE === true || process.env.REACT_APP_SSO_AZURE === 'Azure') && ( user!==undefined && user.provider === 'azure')){
        const authManager = new AuthManager({
        tenantId: tenantId,
      clientId: appId,
      redirectUri:process.env.REACT_APP_FRONT_HOST+"azure",
        popUp:true,
      });
      
        
      authManager.logout()
    }
    
      props.history.push('/login')
    }
    
    if(token){

      return(
          <div>
              <StyledMenu 
                  id="menu-appbar"
                  anchorEl={props.anchorElProfile}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  open={props.openProfile}
                  onClose={props.handleCloseProfile}
                >
                    <div style={{width:'350px'}}>
                    <Grid container spacing={6}>
                          <Grid item xs={3}>
                              <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              color="inherit"
                              >
                                  <AccountCircle style={{fontSize:"64px"}} />
                              </IconButton>
                          </Grid>
                          <Grid item xs={9}>
                              <div >
                                  <Typography variant='subtitle2'> {user ? user.name : ''}</Typography>
                                  <Typography variant='subtitle2'> {user ? `Provider: ${(user).provider}`: ''}</Typography>
                                  <Typography variant='subtitle2'> {user ? `Role: ${(user).role}`: ''}</Typography>
                              </div>
                          </Grid>
                  </Grid>
                      
                    
                    </div>
                    <Divider variant="inset" component="li" style={{backgroundColor:'#FFFFFF',margin:'1%'}}/>
                    {(user!==null && user &&(user.role === 'SUPER_ADMIN' || user.role==='ADMIN')) && 
                    <StyledMenuItem onClick={event=>props.history.push(`/${process.env.REACT_APP_PREFIX}/consumption`)}>
                      <ListItemIcon>
                        <SendIcon fontSize="small" style={{color:'#FFFFFF'}} />
                      </ListItemIcon>
                      <ListItemText primary="License Consumption" />
                    </StyledMenuItem>}
                    {undefined && <StyledMenuItem>
                      <ListItemIcon>
                        <AccountCircle fontSize="small" style={{color:'#FFFFFF'}} />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </StyledMenuItem>} 
                    {(user!==null && user &&(user.role === 'SUPER_ADMIN' || user.role==='ADMIN')) &&  <StyledMenuItem onClick={event=>props.history.push(`/${process.env.REACT_APP_PREFIX}/configuration`)}>
                      <ListItemIcon>
                        <BuildIcon fontSize="small" style={{color:'#FFFFFF'}} />
                      </ListItemIcon>
                      <ListItemText primary="Configuration" />
                    </StyledMenuItem>}
                
                    <Divider variant="inset" component="li" style={{backgroundColor:'#FFFFFF',margin:'1%'}}/>
                    <StyledMenuItem onClick= {logout}>
                      <ListItemIcon>
                        <PowerSettingsNewIcon fontSize="small" style={{color:'#FFFFFF'}} />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </StyledMenuItem>
              </StyledMenu>
          </div>
      )
    }
    else{
      return <Redirect to={{
        pathname: "/login",
        
    }}/>; 
    }

}
