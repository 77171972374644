import React from 'react';
//import AqtivaAppBar from 'components/template/AqtivaAppBar'
import AppBarTemplate from 'components/template/AppBarTemplate'

import * as pathHelper from 'components/template/PathHelper'


import ReactGA from 'react-ga';

import DataForm from './../components/data/DataPageForm'


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/simulation/repository '); 

export default function QualityDataPage(props) {

    

    return (
        <div >
            {/*
            <AqtivaAppBar history={props.history} title={'Data Profiling'} 
            arrayPath={pathHelper.getPathArray('QualityDatasetRepositoryPage')}
            dataIcon = {true} >

           <DataForm props ={props} />
            </AqtivaAppBar>
            */}
             <AppBarTemplate fecthNotifications = {false}
        history={props.history}
        title={"Datasets"}
        arrayPath={pathHelper.getPathArray("Governance")}
      />
      <DataForm props ={props} />
        </div>

    );
}