import React from 'react';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectOneValueWithError from 'components/common/SelectOneValueWithError'

import {getDataFromProperties} from './SpecificRuleHelper'

import {isEmpty} from 'commonFunctions/commonFunctions'

import * as actions from 'actions'
import { ICommonRule, IColumn } from 'interfaces/RuleInterfaces';
import { useAppDispatch } from 'reducers/store';
import { IDataformat } from '../../../interfaces/DataformatInterfaces';


const AggMethodValues = [
  {id:1,
  text:'Count'},
  {id:2,
    text:'Sum'},
  {id:3,
    text:'Avg'},
  {id:4,
    text:'CountDistinct'},
  {id:5,
    text:'CountNulls'},
  {id:6,
    text:'CountNullsPercent'},
  {id:7,
    text:'CountNotNulls'},
  {id:8,
    text:'CountNotNullsPercent'},
]

const CompareWithMethodValues = [
  {id:1,
  text:'Last Load'}
  /*,
  {id:2,
    text:'Last Load in the same:'},*/
]

export const thTypes = [
  { label: 'Number', value: 'number' },
  { label: 'Percent', value: 'percent' }
];


interface FormRuleCheckBetweenLoadsProps {
  commonRule: ICommonRule,
  setSpecific: (value: any)=>any,
  editableRule: any,
  specific: any,
  source: IColumn [],

}

interface ILocalError {
  aggMethod?: boolean,
  compareWithMethod?: boolean,
  columnAgg?: boolean, 
  selectedGroupByColumn?:boolean

}

export default function FormRuleCheckBetweenLoads (props: FormRuleCheckBetweenLoadsProps) {

  console.log('commonRule',props.commonRule)

  const dispatch = useAppDispatch();

  const [selectedDataformat, setSelectedDataformat] = React.useState(undefined)

    const [selectedAggMethod, setSelectedAggMethod] = React.useState(getDataFromProperties(props.editableRule, props.specific,'selectedAggMethod','Count') )
    const [compareWithMethod, setCompareWithMethod] = React.useState( getDataFromProperties(props.editableRule, props.specific,'compareWithMethod','Last Load') )
    const [selectedColumnAgg, setSelectedColumnAgg] = React.useState<IColumn>( getDataFromProperties(props.editableRule, props.specific,'selectedColumnAgg',{}))
    const [selectedGroupByColumn, setSelectedGroupByColumn] = React.useState<IColumn>( getDataFromProperties(props.editableRule, props.specific,'selectedGroupByColumn','All'))
    const [thWarningComparisonType, setThWarningComparisonType] = React.useState( getDataFromProperties(props.editableRule, props.specific,'thWarningComparisonType','percent'))
    const [thWarningComparisonValue, setThWarningComparisonValue] = React.useState(getDataFromProperties(props.editableRule, props.specific,'thWarningComparisonValue',1))
    const [thErrorComparisonType, setThErrorComparisonType] = React.useState(getDataFromProperties(props.editableRule, props.specific,'thErrorComparisonType','percent'))
    const [thErrorComparisonValue, setThErrorComparisonValue] = React.useState(getDataFromProperties(props.editableRule, props.specific,'thErrorComparisonValue',10))
    const [error, setError] = React.useState<ILocalError>({})
    const [visualizeSelectedColumnAgg, setVisualizeSelectedColumnAgg] = React.useState(false)


    React.useEffect(()=>{
      if(props.commonRule && props.commonRule && props.commonRule.datasource){
        
          dispatch(actions.fetchDataformat(props.commonRule.datasource.dataformatId ? props.commonRule.datasource.dataformatId :  // @ts-ignore
              props.commonRule.datasource.dataset._id)).then( 
                (                  response: { data: React.SetStateAction<undefined>; })=>{
                      setSelectedDataformat(response.data)
                  }
              )
      }
  }, [props.commonRule])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      createSpecific() 
  }, [selectedAggMethod,compareWithMethod,selectedColumnAgg,selectedGroupByColumn,thWarningComparisonType,thWarningComparisonValue,// eslint-disable-line react-hooks/exhaustive-deps
    thErrorComparisonType,thErrorComparisonValue]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setVisualizeSelectedColumnAggFromSelected(selectedAggMethod)
  }, [selectedAggMethod]);

  

  function createSpecific(){
      //creamos el objeto de regla especifica
      const specific = {
        subtype:'checkBetweenLoads',
        selectedAggMethod: selectedAggMethod,
        compareWithMethod: compareWithMethod,
        selectedColumnAgg: isEmpty(selectedColumnAgg) ? "": selectedColumnAgg,
        selectedGroupByColumn: selectedGroupByColumn,
        thWarningComparisonType: thWarningComparisonType,
        thWarningComparisonValue: thWarningComparisonValue,
        thErrorComparisonType: thErrorComparisonType,
        thErrorComparisonValue: thErrorComparisonValue
      }


      props.setSpecific(specific)
     
  }

  function setVisualizeSelectedColumnAggFromSelected(value: string){

    if(value==='Sum' || value==='Avg' || value ==='CountDistinct' || value ==='CountNulls' || value ==='CountNullsPercent' || value ==='CountNotNulls' || value ==='CountNotNullsPercent') {
      setVisualizeSelectedColumnAgg(true)
    }
    else{
      setVisualizeSelectedColumnAgg(false)
    }
  }

    
   function validate () {
  
      var error: ILocalError ={}
      if(selectedAggMethod==='' || selectedAggMethod===null){
        error.aggMethod=true
      }
      if(compareWithMethod===''|| compareWithMethod===null){
        error.compareWithMethod=true
      }
      if(selectedColumnAgg===undefined || selectedColumnAgg.position === undefined|| selectedColumnAgg===null){
        error.columnAgg=true
      }
      if(selectedGroupByColumn===undefined || selectedGroupByColumn.position === undefined|| selectedGroupByColumn===null){
        error.selectedGroupByColumn=true
      }
      
    
      return error
    } 



function getAggregatedColumnsAll(aggregationObject: any){
  var data: IColumn[] = []
    if(aggregationObject && aggregationObject.aggregationOperations){
      for(var i = 0;i<aggregationObject.aggregationOperations.length;i++){
        var aggColumn = aggregationObject.aggregationOperations[i].column
        aggColumn.name = aggregationObject.aggregationOperations[i].alias
        data=data.concat(aggColumn)
      }
    }
    if(aggregationObject && aggregationObject.groupColumns){
      data=data.concat(aggregationObject.groupColumns)
    }
    return data
}

function calculateDataColumnsWithAll (df: any[], aggregationObject: any){
  if(aggregationObject !==undefined && (aggregationObject.groupColumns !==undefined && aggregationObject.aggregationOptions!==undefined)){
    var data = [{position:0, name:'All', type:''}]
    data = data.concat(getAggregatedColumnsAll(aggregationObject))
    return data
  }
  else{
    var data = [{position:0, name:'All', type:''}]
    if(df && df.length > 0) {
      data=data.concat(df)
    }
    return data
  }
 
}


function getColumnsNumber (df: IDataformat, aggregationObject: any) {
  
  if(selectedDataformat){
    if(aggregationObject !==undefined && (aggregationObject.groupColumns !==undefined && aggregationObject.aggregationOptions!==undefined)){
      const aggregatedData: IColumn[] = getAggregatedColumnsAll(aggregationObject)
      const ret = aggregatedData.filter(e=>e.type.toLowerCase() === 'integer' || e.type.toLowerCase() ==='float' || e.type.toLowerCase() ==='double'  || e.type.toLowerCase() ==='number')
      return ret
    }
    else{
      const ret = df.columns.filter(e=>e.type.toLowerCase() === 'integer' || e.type.toLowerCase() ==='float' || e.type.toLowerCase() ==='double'  || e.type.toLowerCase() ==='number')
      return ret
    }
    
  }
  return []
  
}
function getColumnsAll (df: IDataformat, aggregationObject: any) {
  if(aggregationObject !==undefined && (aggregationObject.groupColumns !==undefined && aggregationObject.aggregationOptions!==undefined)){
    return getAggregatedColumnsAll(aggregationObject)
  }

  else if(df){
  return df.columns
}
return []
}
       
       return (
        <Grid container spacing={3}>
          <Grid item xs={3}>
              
              <SelectOneValueWithError
                  value={ selectedGroupByColumn}
                  onChange={(e: { target: { value: any; }; })=>{  setSelectedGroupByColumn(e.target.value); setError(validate()) }}
                  data={ calculateDataColumnsWithAll(props.source, props.commonRule.aggregationObject)}
                  error= { error.selectedGroupByColumn}
                  errorLabel='A column must be set to Group By'
                  caption = 'Group By Column'
                  idField='position'
                  textField='name'
                  fontSizeError='10px'
                  width='240px'
                  />
           </Grid>

           <Grid item xs={3}>
              <SelectOneValueWithError
                  value={ selectedAggMethod}
                  onChange={(e: { target: { value: string; }; })=>{  setSelectedAggMethod(e.target.value); setVisualizeSelectedColumnAggFromSelected(e.target.value); setError(validate()) }}
                  data={AggMethodValues}
                  error= { error.aggMethod}
                  errorLabel='An Aggregation Method must be selected'
                  caption = 'Aggregation Method'
                  idField='id'
                  textField='text'
                  fontSizeError='10px'
                  width='240px'
                  />
                </Grid>
                { visualizeSelectedColumnAgg && (selectedAggMethod !== 'CountNulls'  && selectedAggMethod !== 'CountNotNulls' &&
                selectedAggMethod !== 'CountNullsPercent' && selectedAggMethod !== 'CountNotNullsPercent') &&
              <Grid item xs={3}>
                  
                  <SelectOneValueWithError
                  value={ selectedColumnAgg}
                  onChange={(e: { target: { value: any; }; })=>{ setSelectedColumnAgg(e.target.value);  setError(validate())}}
                  data={( selectedDataformat ) ?
                     getColumnsNumber(selectedDataformat, props.commonRule.aggregationObject): []}
                  error= { error.columnAgg}
                  errorLabel='A column must be set for the aggregation'
                  caption = 'Aggregation Method Column'
                  idField='position'
                  textField='name'
                  fontSizeError='10px'
                  width='240px'
                  />
              </Grid>   } 

              { visualizeSelectedColumnAgg && (selectedAggMethod === 'CountNulls'  || selectedAggMethod === 'CountNotNulls' ||
                selectedAggMethod === 'CountNullsPercent' || selectedAggMethod === 'CountNotNullsPercent') &&
              <Grid item xs={3}>
                  
                  <SelectOneValueWithError
                  value={ selectedColumnAgg}
                  onChange={(e: { target: { value: any; }; })=>{ setSelectedColumnAgg(e.target.value);  setError(validate())}}
                  data={selectedDataformat ?
                     getColumnsAll(selectedDataformat, props.commonRule.aggregationObject): []}
                  error= { error.columnAgg}
                  errorLabel='A column must be set for the aggregation'
                  caption = 'Aggregation Method Column'
                  idField='position'
                  textField='name'
                  fontSizeError='10px'
                  width='240px'
                  />
              </Grid>   } 
          <Grid item xs={3}>
          
              <SelectOneValueWithError
                  value={ compareWithMethod}
                  onChange={(e: { target: { value: any; }; })=>{ setCompareWithMethod(e.target.value);  setError(validate())}}
                  data={CompareWithMethodValues}
                  error= { error.compareWithMethod}
                  errorLabel='A Comparison option must be selected'
                  caption = 'Compare with'
                  idField='id' 
                  textField='text'
                  fontSizeError='10px'
                  width='240px'
                  />
         
              </Grid>
              <Grid item xs={12}>
                        <Grid container spacing={3} >
                          <Grid item xs={6}>
                              <div>
                                <Typography variant='subtitle2'>Warning Comparison Th.</Typography>
                              </div>
                              <div>
                                <Typography variant='caption'>Type</Typography>
                              </div>
                              <div>
                                <Select
                                    style={{ width: '45%',marginRight:'5%'}}
                                    value={ thWarningComparisonType}
                                    onChange={(e) => {  setThWarningComparisonType(e.target.value)   }}
                                    inputProps={{
                                        name: 'Select a Type',
                                        id: 'typeWarnTh-selection'

                                    }}>


                                    {thTypes.map((event) => (<MenuItem key={event.value} value={event.value}>
                                        <Typography>{event.label}</Typography>
                                    </MenuItem>))}
                                </Select>
                            
                                <TextField type="number" 
                                 InputProps={{ inputProps: { min: 1, max: 100 },
                                 endAdornment: <InputAdornment position="start">
                                   { thWarningComparisonType && 
                                     thWarningComparisonType.toLowerCase()==='percent'? '%' : ''}
                                   </InputAdornment>, }} 
                                 value={ thWarningComparisonValue} autoComplete='off'
                                    id='thWarningValue'
                                    style={{ width: '30%',marginRight:'5%',marginTop:'-2%'}}
                                    onChange={  e=>{setThWarningComparisonValue(e.target.value)} }
                                    label="Value"
                                    fullWidth
                                    margin="dense"
                                />
                            </div>
                            </Grid>
                            
                        
                        <Grid item xs={6}>
                            <div>
                                <Typography variant='subtitle2'>Error Comparison Th.</Typography>
                            </div>
                            <div>
                                <Typography variant='caption'>Type</Typography>
                            </div>
                            <div>
                                <Select
                                    style={{ width: '45%',marginRight:'5%'}}
                                    value={ thErrorComparisonType}
                                    onChange={(e) => {  setThErrorComparisonType(e.target.value)  }}
                                    inputProps={{
                                        name: 'Select a Type',
                                        id: 'typeWarnTh-selection'

                                    }}>


                                    {thTypes.map((event) => (<MenuItem key={event.value} value={event.value}>
                                        <Typography>{event.label}</Typography>
                                    </MenuItem>))}
                                </Select>

                                <TextField type="number" 
                                 InputProps={{ inputProps: { min: 1, max: 100 },
                                 endAdornment: <InputAdornment position="start">
                                   { thErrorComparisonType && 
                                     thErrorComparisonType.toLowerCase()==='percent'? '%' : ''}
                                   </InputAdornment>, }}
                                 value={ thErrorComparisonValue} autoComplete='off'
                                    id='thErrorValue' 
                                    style={{ width: '30%',marginRight:'5%',marginTop:'-2%'}}
                                    onChange={ e=>{setThErrorComparisonValue(e.target.value)} }
                                    label="Value"
                                    fullWidth
                                    margin="dense"
                                   
                                     />
                              </div>
                            </Grid>
                            
                            
                        </Grid>

           
              
            </Grid>
              
              </Grid>
        );
    
}


