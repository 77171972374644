import React from 'react';

import {
         Accordion, AccordionSummary, AccordionDetails, Grid, Typography
        
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import {  QualityTheme } from '../../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';

import ResultStorageTable from './ResultStorageTable'


import * as commonStyles from 'style/CommonStyles'


export default function ResultStorageConfiguration(props) {
       
        /*const [cloudProvider, setCloudProvider] = React.useState(props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
                props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure'))
        */
                const cloudProvider = props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
                props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure')



        return (
                <MuiThemeProvider theme={QualityTheme}>
                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Result Storage Configuration</Typography>
                                        </Grid>
                                </AccordionSummary>
                                <AccordionDetails >
                                        <ResultStorageTable configuration={props.configuration} cloudProvider={cloudProvider} storages={props.storages} setStorages={props.setStorages} />
                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}


