import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons'
import MaterialTable from "@material-table/core";

import { useDispatch, useSelector } from 'react-redux'




import AqtivaDialog from 'components/common/AqtivaDialog'
import AdvanceConfigurationQuality from 'components/common/AdvanceConfigurationQuality'
import CustomInput from "components/common/CustomInput/CustomInput.js";

import DialogCreateRule from '../DialogCreateRule'

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from '@material-ui/core/Fab';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles';

import AllOutIcon from '@material-ui/icons/AllOut';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { v4 as uuidv4 } from 'uuid';

import * as actions from 'actions'

import * as commonStyles from 'style/CommonStyles'


import {
  Tooltip, FormControl,  Typography, Select,  InputAdornment, Icon, makeStyles, MenuItem,
   Button
} from '@material-ui/core'
import { dispatchError } from 'components/common/axios/axiosHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resize: {
    fontSize: 14
  },
 
  chipContainerRule: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function DialogCreateCompoundRule(props) {

  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
    const user = useSelector(store => store.user)

  
  const [editable] = React.useState(props.rule ? true : false)
  const [selectedItem, setSelectedItem] = React.useState(undefined)
  const [name, setName] =  React.useState(editable ? props.rule.name   : undefined)

  const [selectedDimension, setSelectedDimension] = React.useState(editable ? props.rule.dimension   : undefined)
  const [selectedComposeMode, setSelectedComposeMode] = React.useState(editable ? props.rule.compoundMode   : 'and')

  const [thError, setThError] = React.useState(editable ? props.rule.errorTh : (props.commonRule && props.commonRule.common ? props.commonRule.common.thError : (props.datasource ? props.datasource.errorTh : {})))
  const [thWarning, setThWarning] = React.useState(editable ? props.rule.warningTh : (props.commonRule && props.commonRule.common ? props.commonRule.common.thWarning : (props.datasource ? props.datasource.warningTh : {})))
  const [wrongRegisterAction, setWrongRegisterAction] = React.useState(editable ? props.rule.wrongRegisterActionType : props.datasource.wrongRegisterActionType)
  const [outputMode, setOutputMode] = React.useState(editable ? props.rule.controlModeType : props.datasource.controlModeType)


    const [openCreateRuleDialog, setOpenCreateRuleDialog] = React.useState(false)
    const [childRules, setChildRules] = React.useState(editable ? props.rule.childRules   : [])

  function getControlObj() {
    return { wrongRegisterAction: wrongRegisterAction, outputMode: outputMode }


  }
  //function to update control elmenets
  function setControlObj(data) {
    setWrongRegisterAction(data.wrongRegisterActionType)
    setOutputMode(data.controlModeType)
  }

  
  function getThFromObject(obj) {
    return (obj !== null && obj !== undefined) ? obj.action.substring(0, 8) + ' ' + obj.value + (obj.type === 'number' ? '' : '%') : ''

  }

  function setSelectedItemAndOpen(rule){
    setSelectedItem(rule)
    setOpenCreateRuleDialog(true)
  }

  function deleteItem(item){
    setChildRules( childRules.filter(x=>x._id !== item._id) )
  }

  function validateCompoundRule(){
    if(name === undefined){
      dispatchError(dispatch, "A Name needs to be set for the rule")
      return false
    }
    else if(selectedDimension === undefined){
      dispatchError(dispatch, "A Dimension needs to be selected")
      return false
    }
    else if(thWarning === undefined){
      dispatchError(dispatch, "Warning Threshold needs to be selected")
      return false
    }
    else if(thError === undefined){
      dispatchError(dispatch, "Error Threshold needs to be selected")
      return false
    }
    else if(outputMode === undefined){
      dispatchError(dispatch, "Output mode needs to be selected")
      return false
    }
    else if(wrongRegisterAction === undefined){
      dispatchError(dispatch, "Wrong Register Action needs to be selected")
      return false
    }
    else if(childRules === undefined || childRules.length ===0){
      dispatchError(dispatch, "At least one child rule needs to be created")
      return false
    }
    return true
  }

  function saveRule(){

    if(validateCompoundRule() === false){
      return;
    }

    const ruleId= props.rule ? props.rule._id : undefined
    const rule = {
        name: name,
        warningTh : thWarning,
        errorTh: thError,
        controlModeType: outputMode,
        wrongRegisterActionType: wrongRegisterAction,
        dimension: selectedDimension,
        folder: props.folder!=={} ? props.folder : undefined,
        _id : ruleId,
        type: "compound",
        subtype: "compound",
        active: true,
        compoundMode: selectedComposeMode,
        childRules : childRules
    }

    if(ruleId){
      dispatch(actions.editRule(props.project._id,props.qualityPoint._id,props.datasource._id,rule,user.id))
     
     }
     else{
       
        dispatch(actions.addRule(props.project._id,props.qualityPoint._id,props.datasource._id,rule,user.id))
       
      
     
     }

     props.setVisible(false)
  }



  function MultipleActions() {
    return (
      <MaterialTable
        title="Multiple Actions Preview"
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Conditional', field: 'conditional' },
          {
            title: 'Error Th.', field: 'errorTh',
            render: rowData => { return getThFromObject(rowData.errorTh) }
          },
          {
            title: 'Warning Th.', field: 'warningTh',
            render: rowData => { return getThFromObject(rowData.warningTh) }
          },

          { title: 'Dimension', field: 'dimension' },
          {
            title: 'Edit', field: 'edit', render: (rowData) => {
              return (
                <>
                  <Fab size="small" color="primary" aria-label="Detail" 
                    onClick={() => { setSelectedItemAndOpen(rowData) }}
                  >
                    <Tooltip title={"dataformat versions"}>
                      <span>
                        <FontAwesomeIcon icon={faEdit} style={{ color: "white", fontSize: 'calc(9px + 0.4vw)', marginTop: '2%', marginRight: '2%' }} />
                      </span>
                    </Tooltip>
                  </Fab>
                </>
              )
            }
          },

          {
            title: 'Delete', field: 'delete', render: (rowData) => {
              return (
                <>
                  <Fab size="small" color="primary" aria-label="Detail" 
                    onClick={() => { deleteItem(rowData) }}
                  >
                    <Tooltip title={"dataformat versions"}>
                      <span>
                        <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(9px + 0.4vw)', marginTop: '2%', marginRight: '2%' }} />
                      </span>
                    </Tooltip>
                  </Fab>
                </>
              )
            }
          },
        ]}
        data={ childRules}
        icons={{
          Edit: () => <EditIcon style={{ color: commonStyles.mainColor }} />,
          Delete: () => <DeleteIcon style={{ color: commonStyles.mainColor }} />,
          Save: () => <EditIcon style={{ color: commonStyles.mainColor }} />

        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Rule',
            isFreeAction: true,
            onClick: (event) => console.log("You want to add a new row")
          },


        ]}

        options={{
          actionsColumnIndex: -1,
          tableLayout: "Auto",
          filtering: false,
          search: false,
          pageSize: 5,
          maxBodyHeight: '90%',
          minBodyHeight: 250,
          headerStyle: {
            fontSize: 'calc(8px + 0.2vw)',
            backgroundColor: commonStyles.mainColor,
            color: '#FFF'
          },
          cellStyle: {
            fontSize: 'calc(8px + 0.2vw)',
          },
          filterCellStyle: {
            fontSize: 'calc(8px + 0.2vw)',
          },
        }}
        components={{
          Toolbar: props => (
            <div>

            </div>
          ),
        }}
      />
    )
  }
  const onChangeTextField = (event) => {

    setName(event.target.value)
}

const onChangeDimensionCallback = (event) => {

  setSelectedDimension(event.target.value)
}

  function setChildCompoundRule(rule){
    
    var ruleWithId = JSON.parse(JSON.stringify(rule))
    if(ruleWithId._id===undefined){
      ruleWithId._id = uuidv4();
    }
    //find rule and overwrite
    var newChildRules = []
    var found = false
    if(childRules && childRules.length>0){
      for(var i = 0;i<childRules.length;i++){
        var childRule = childRules[i]
        if(rule._id === childRule._id){
          newChildRules.push(rule)
          found=true
        }
        else{
          newChildRules.push(childRule)
        }
      }
      if(found === false){
          newChildRules.push(rule)

      }
      setChildRules(newChildRules)
    }
    else{
      setChildRules([...childRules,ruleWithId])
    }
    
  }

  return (
    <AqtivaDialog visible={props.visible}
      maxWidth={'md'}
      title={'Create Compound Rule'}
      titleIcon={faObjectGroup}
      confirmText={'Save'}
      cancelText={'Close'}
      confirmCallback={event => saveRule()}
      cancelCallback={event => { props.setVisible(false) }}
    >
      <div>
      {openCreateRuleDialog/*  && !tableByRulesOrColumns */ && <DialogCreateRule rule={selectedItem} project={props.project}
                    open={openCreateRuleDialog} setClose={setOpenCreateRuleDialog}
                    datasource={props.datasource}
                    qualityPoint={props.qualityPoint}
                    folder={props.folder}
                    compound = {true}
                    setChildCompoundRule = {setChildCompoundRule}
                />}

<div style={{ width: '50%' }}>
                            <CustomInput
                                labelText="Rule Name"
                                id="ProjectName"
                                formControlProps={{
                                    fullWidth: false,

                                    style: { width: '80%' }
                                }}

                                inputProps={{
                                    type: "text",
                                    onChange: onChangeTextField,
                                    value: name,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className="fas fa-signature" />
                                        </InputAdornment>
                                    )
                                }}
                                error={name!==undefined ? false : true}
                                helperText={name!==undefined ? ""  : "A name must be provided"}
                            />
                        </div>
                        <div style={{ width: '50%' }}>
                            <FormControl className={classes.formControl}>

                                <Typography variant='caption'>Quality Dimension</Typography>
                                <Select
                                    style={{ width: '80%' }}
                                    value={selectedDimension}
                                    onChange={onChangeDimensionCallback}
                                    inputProps={{
                                        name: 'Rule Dimension',
                                        id: 'dimension-selection',

                                    }}
                                    
                                >
                                    <MenuItem key='integrity' value='integrity'><div style={commonStyles.adjacentLeft}>
                                        <CompareArrowsIcon style={{  color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Integrity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='veracity' value='veracity'><div style={commonStyles.adjacentLeft}>
                                        <CheckCircleOutlineIcon style={{  color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Veracity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='unicity' value='unicity'><div style={commonStyles.adjacentLeft}>
                                        <AllOutIcon style={{  color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Unicity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='timeliness' value='timeliness'><div style={commonStyles.adjacentLeft}>
                                        <AvTimerIcon style={{  color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Timeliness</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='format' value='format'><div style={commonStyles.adjacentLeft}>
                                        <AvTimerIcon style={{ color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Format</Typography></div>
                                    </MenuItem>

                                </Select>
                            </FormControl>
                        </div>
        <div style={{ width: '50%' }}>
          <FormControl className={classes.formControl}>

            <Typography variant='caption'>Composition Mode</Typography>
            <Select
              style={{ width: '80%' }}
              value={selectedComposeMode}
              onChange={event => { setSelectedComposeMode(event.target.value) }}
              inputProps={{
                name: 'Componsition Mode',
                id: 'composition-mode-selection',

              }}

            >
              <MenuItem key='and' value='and'><div style={commonStyles.adjacentLeft}>

                <Typography variant='body2'>And</Typography></div>
              </MenuItem>
              <MenuItem key='or' value='or'><div style={commonStyles.adjacentLeft}>

                <Typography variant='body2'>Or</Typography></div>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <Typography variant='subtitle2'>Rules</Typography>
          <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} 
                    onClick={event =>{setSelectedItem(undefined); setOpenCreateRuleDialog(true)}}>Create Child Rule</Button>
          {MultipleActions()}
        </div>
        <div style={{ marginTop: '1%', width: '95%' }}>
          <AdvanceConfigurationQuality
            setThError={setThError} setThWarning={setThWarning} setControlObj={setControlObj}
            thError={thError}
            thWarning={thWarning}
            controlObj={getControlObj()} />
        </div>

      </div>
    </AqtivaDialog>
  )
}