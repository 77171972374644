import React, { useState } from 'react'
import { Card, CardContent, Typography, Divider, CardHeader, Grid, Tooltip } from '@material-ui/core';
import ProjectDetail from 'components/AqtivaDashboard/components/ProjectDetail'

import OpenInBrowserIcon from '@material-ui/icons/SystemUpdateAlt';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import AqtivaDialog from 'components/common/AqtivaDialog';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import * as commonStyles from 'style/CommonStyles'
import DonutChartSmall from '../Charts/DonutChartSmall';
import { useDispatch } from 'react-redux'
import * as actions from 'actions'
import { LanguageContext } from 'language/LanguageContext';
import * as windowHelper from 'commonFunctions/windowHelper'


export default function CardComponent(props) {

    const [value, setValue] = React.useState([])
    const [selectedProject, setSelectedProject] = React.useState(false)
    const [detailProjectVisible, setDetailProjectVisible] = React.useState(false)
    const [detailedDQIProject, setDetailedDQIProject] = React.useState()
    const [hideNodes, setHideNodes] = React.useState([])
    const { height, width } = windowHelper.useWindowDimensions();
    const dispatch = useDispatch();
    const languageContext = React.useContext(LanguageContext)


   

    React.useEffect(() => {
        if (props.data) {
            setValue(props.data)
        }
    }, [props.data, props.filterObj])

    function handleDrillDownElement() {
        props.click(value)
    }

    function confirmDialogDetailProject(event) {
        setDetailProjectVisible(false)
    }

    return (
        <div style={{ width: '250px', margin: '20px' }}>
            <Card elevation={3} styles={{
                maxWidth: 280,
                borderRadius: 20,
                marginTop: "50px",
            }}>
                <CardHeader style={{ backgroundColor: commonStyles.mainColor, height: '30px', width: '100%' }}
                    title={<div style={{
                        width: '100%', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        
                            <img alt="" style={{ height: '20px', width: '20px' }}
                                src={require('resources/images/omma_logo_white_h.png')}  />

                        <Tooltip title={<Typography style={{ fontSize: '12px' }} >{ languageContext.dictionary['cardDetail']} </Typography>}>
                            <ZoomInIcon style={{ color: '#FFFFFF', cursor: 'pointer' }}
                                onClick={event => {
                                    setSelectedProject(value)
                                    setDetailProjectVisible(true)

                                    dispatch(actions.getDQIProjectDetail(props.filterObj, value.projectId)).then(response => {
                                        setDetailedDQIProject(response.data)
                                        setHideNodes(response.data.qualityPointDQI === undefined ? [] :
                                            response.data.qualityPointDQI.flatMap(qpDQI => qpDQI.datasourceDQI).map(ds => ds.datasourceId))
                                    })
                                }}

                            />
                        </Tooltip>

                        <Typography variant='subtitle1' style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                            {value.projectName && value.projectName.length > 20 ? value.projectName.substring(0, 17) + "..." : value.projectName}</Typography>

                        
                        <Tooltip title={<Typography style={{ fontSize: '12px' }} >{ languageContext.dictionary['cardDrilldown']} </Typography>}>
                            <OpenInBrowserIcon style={{ color: '#FFFFFF', cursor: 'pointer' }}
                                onClick={handleDrillDownElement}

                            />
                        </Tooltip>

                    </div>} />
                <CardContent >
                    <DonutChartSmall data={value.globalDQI} showCenterLabel={true} chart={'chartDonut' + props.index} click={false} height={'100px'} />
                </CardContent>
                <Divider />
                <CardContent >
                    <Grid container spacing={2} style={{ width: "100%" }}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                            }}
                        >

                            <Grid item xs={3}>
                                <Tooltip title="Quality points">
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <img alt="" style={{ height: '20px', width: '25px', marginLeft: '5px', marginRight: '5px', marginTop: '5px' }}
                                                src={require('resources/icons/point_proc1_black.png')} />
                                            {/* <FontAwesomeIcon
                                      icon={faProjectDiagram}
                                      color=commonStyles.mainColor
                                      style={{ margin: "5px" }}
                                   />*/}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ marginTop: "5px" }}>
                                                {value.qpIds ? value.qpIds.length : 0}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Tooltip>
                            </Grid>


                            <Grid item xs={3}>
                                <Tooltip title="Data sources">
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <img alt="" style={{ height: '20px', width: '20px', marginLeft: '5px', marginRight: '5px', marginTop: '5px' }}
                                                src={require('resources/icons/data_proc3_black.png')} />

                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ marginTop: "5px" }}>
                                                {value.dsIds ? value.dsIds.length : 0}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Tooltip>
                            </Grid>

                            <Grid item xs={3}>
                                <Tooltip title="Rules">
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <img alt="" style={{ height: '20px', width: '20px', marginLeft: '5px', marginRight: '5px', marginTop: '5px' }}
                                                src={require('resources/icons/rule_proc1_black.png')} />
                                            {/*<FontAwesomeIcon
                                      icon={faFileAlt}
                                      color=commonStyles.mainColor
                                      style={{ margin: "5px" }}
                                    />*/}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ marginTop: "5px" }}>
                                                {value.ruleIds ? value.ruleIds.length : 0}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Tooltip>
                            </Grid>

                        </div>
                    </Grid>
                </CardContent>
            </Card>

            <AqtivaDialog visible={detailProjectVisible}
                title={`Project ${selectedProject ? selectedProject.projectName : ''} Detail`}
                maxWidth={width}
                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                confirmCallback={confirmDialogDetailProject}
                cancelCallback={event => { setDetailProjectVisible(false) }}
            >
                <div style={{ width: width * 0.9, height: height * 0.9 }}>
                    <ProjectDetail detailedDQIProject={detailedDQIProject} hideNodes={hideNodes} filterObj={props.filterObj} project={selectedProject} />
                </div>



            </AqtivaDialog>
        </div>
    )
}