import React from 'react'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import * as commonStyles from './../../../style/CommonStyles'

export default function ProjectTypography(props) {
    const history = useHistory(); 

    return(
    <Typography variant={props.variant} style={{cursor:'pointer', fontWeight:'bold', color:commonStyles.mainColor}}
    onClick={event=>history.push(`/${process.env.REACT_APP_PREFIX}/project/${props.projectId}`)}
    >{props.value}</Typography>
    )
}
