export function splitUsedProjectsIntoOwnAndOthers(usedDatasourcesByProjects, dataformat, userId) {
    let projectsUsedDetail = []
    for (let i = 0; i < usedDatasourcesByProjects.length; i++) {
        for (let j = 0; j < usedDatasourcesByProjects[i].dataformatsUsed.length; j++) {
            if (dataformat && usedDatasourcesByProjects[i].dataformatsUsed[j] === dataformat._id) {
                if (usedDatasourcesByProjects[i].userOwnProject || checkIfSharedProject(userId, usedDatasourcesByProjects[i].allowedProjectUsers)) {
                    let project = {
                        projectId: usedDatasourcesByProjects[i].projectId,
                        projectName: usedDatasourcesByProjects[i].projectName,
                        userId: usedDatasourcesByProjects[i].userId,
                        ownProject: true,
                    }
                    projectsUsedDetail.push(project)
                }
                else {
                    let project = {
                        projectId: usedDatasourcesByProjects[i].projectId,
                        projectName: usedDatasourcesByProjects[i].projectName,
                        userId: usedDatasourcesByProjects[i].userId,
                        ownProject: false
                    }
                    projectsUsedDetail.push(project)
                }
            }
        }
    }
    return projectsUsedDetail
}

export function checkIfSharedProject(userId, allowedUsers) {
    if (userId && allowedUsers && allowedUsers.length > 0) {
        return allowedUsers.includes(userId)
    }
}