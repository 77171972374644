import React from 'react';

import './QualityProjectTreeTable.css'
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";

import * as helper from './QualityProjectTableHelper'
import * as renderHelper from './QualityProjectTableRenderHelper'
import * as projectHelper from '../QualityProjectHelper'
import {
    Typography, FormControl, FormLabel, InputLabel, Select, MenuItem
} from '@material-ui/core'


import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import trackEvent from '../../../../../../trackEvent'
import { NameCellTemplate } from './NameCellTemplate'
import { DragDropTemplate } from './DragDropTemplate'
import AqtivaDialog from '../../../../../common/AqtivaDialog/index.js';


import * as actions from '../../../../../../actions';


import SelectEnvironmentComponent from '../../../../../common/SelectEnvironmentComponent'


import * as consolidateHelper from './QualityProjectConsolidableHelper'
import * as contextHelper from './QualityProjectContextMenuHelper'
import { dispatchError } from '../../../../../common/axios/axiosHelper';

import * as environmentHelper from './../../../../../../commonFunctions/environmentFunctions'

const initialState = {
    mouseX: null,
    mouseY: null,
};


export default function QualityDetailProjectTable(props) {

    const theme = useTheme();
    const dispatch = useDispatch();
    //const allVersions = useSelector(store => (store.projectVersions))

    var project = (props.project)

    const user = useSelector((store) => store.user);
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))


    const [selectedItem, setSelectedItem] = React.useState()
    const [itemType, setItemType] = React.useState()
    const [state, setState] = React.useState(initialState);

    //state for restar rule dialog
    const [restartRuleDialogVisible, setRestartRuleDialogVisible] = React.useState(false)
    const [ruleId, setRuleId] = React.useState('')
    const [environments, setEnvironments] = React.useState([])
    //TODO: AVOID WARNINGS
    if (environments) {

    }
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [modifiedProject, setModifiedProject] = React.useState([])
    //const [disableRuleConsolidationButton, setDisableRuleConsolidationButton] = React.useState(true)
    const [consolidationResult, setConsolidationResult] = React.useState({})
    const [dialog, setDialog] = React.useState(false);
    const [schedules, setSchedules] = React.useState([]);

    const [copyDatasourceDialog, setCopyDatasourceDialog] = React.useState(false)
    const [selectedProject, setSelectedProject] = React.useState()
    const [selectedQP, setSelectedQP] = React.useState()
    var projects = useSelector(store => Object.values(store.projects))


    /*  const [projectVersions, setProjectVersions] = React.useState([]); */


    //get allowed environments for user
    React.useEffect(() => {
        environmentHelper.calculateAllowedEnvironments(qualityConfiguration, user, setEnvironments, setSelectedEnvironment, dispatch, dispatchError)
    }, [qualityConfiguration]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        setModifiedProject(helper.convertProjectToNodes(filterProject(project), props.tableByRulesOrColumns))
    }, [project, props.tableByRulesOrColumns]);// eslint-disable-line react-hooks/exhaustive-deps


    //TODO: este effect qué es??
    React.useEffect(() => {
        fetchSchedules(project)


    }, [project && project._id, Object.keys(project).map(key => `${key}_${project[key]}`).join("_"), props.scheduleHasBeenModified]);// eslint-disable-line react-hooks/exhaustive-deps

    async function fetchSchedules(project) {
        try {
            let newSchedules = []
            if (project && project.qualityPoints && project.qualityPoints.length > 0) {
                for (let i = 0; i < project.qualityPoints.length; i++) {
                    for (let j = 0; project.qualityPoints[i].datasources && j < project.qualityPoints[i].datasources.length; j++) {
                        if (project.qualityPoints[i].datasources[j].hasScheduleFlag) {
                            let datasourceSchedules = await dispatch(actions.getSchedulesFromDatasource(project.qualityPoints[i].datasources[j] && project.qualityPoints[i].datasources[j]._id, props.userId))
                            newSchedules = [...newSchedules, ...datasourceSchedules]
                        }
                    }
                }
                setSchedules(newSchedules)
            }
        } catch (e) {
            dispatchError(dispatch, "Error fetching schedules: " + JSON.stringify(e))
        }

    }


    function restartRule(ruleId_) {
        setRuleId(ruleId_)
        setRestartRuleDialogVisible(true)
    }


    function filterProject(project) {

        if (props.searchText && props.searchText !== "") {
            return projectHelper.searchProject(props.searchText, project)
        }
        else {
            return project
        }
    }


    function consolidateRulesFromDatasource(datasourceId, project) {
        let newProject = JSON.parse(JSON.stringify(project))
        let datasource
        let qualityPointId
        for (let i = 0; i < newProject.qualityPoints.length; i++) {
            for (let j = 0; newProject.qualityPoints[i].datasources && j < newProject.qualityPoints[i].datasources.length; j++) {
                if (newProject.qualityPoints[i].datasources[j]._id === datasourceId) {
                    datasource = JSON.parse(JSON.stringify(newProject.qualityPoints[i].datasources[j]))
                    qualityPointId = newProject.qualityPoints[i]._id
                }
            }
        }
        if (qualityPointId && datasource && datasource.rules && datasource.rules.length > 0) {
            let consolidationResult = consolidateHelper.consolidateDatasource(datasource)
            consolidationResult.qpId = qualityPointId
            setConsolidationResult(consolidationResult)
            setDialog(true)
        }
    }


    /**  HANDLE EVENT CALLBACKS**/
    const handleClickContextMenuTable = (event) => {

        const { item, itemType, qualitypointId, datasourceId } = helper.obtainEditableItem(event.value, filterProject(props.project), props.tableByRulesOrColumns)
        //TODO: avoid warnings
        if (qualitypointId && datasourceId) {

        }
        setItemType(itemType)
        setSelectedItem(item)
        event.originalEvent.preventDefault();
        setState({
            mouseX: event.originalEvent.clientX - 2,
            mouseY: event.originalEvent.clientY - 4,
        });
    };

    const handleCloseContextMenu = () => {
        setState(initialState);
    };

    const handleEditAction = () => {
        trackEvent('DetailProjectTable context menu', 'Edit');
        props.setSelectedItem(selectedItem, itemType)
        setState(initialState);


    }

    const handleEditActionNewDialog = () => {
        trackEvent('DetailProjectTable context menu', 'Edit');
        props.setSelectedItemNewDialog(selectedItem, itemType)
        setState(initialState);


    }

    const handleDeleteAction = () => {
        trackEvent('DetailProjectTable context menu', 'Delete');
        props.deleteItem(selectedItem, itemType)
        setState(initialState);

    }

    const handleRuleRecommender = () => {
        trackEvent('DetailProjectTable context menu', 'Rule Recommender');
        props.handleRuleRecommender(selectedItem, itemType)
        setState(initialState);

    }

    const handleAddDatasourceAction = () => {
        trackEvent('DetailProjectTable context menu', 'Add Datasource');
        if (itemType === "Qualitypoint") {
            props.addDatasource(selectedItem, itemType)
        }
        setState(initialState);

    }

    const handleAddFolderAction = () => {
        trackEvent('DetailProjectTable context menu', 'Add Folder');
        props.addFolder(selectedItem, itemType)
        setState(initialState);

    }
    const handleAddRuleAction = () => {
        trackEvent('DetailProjectTable context menu', 'Add Rule');
        props.addRule(selectedItem, itemType)
        setState(initialState);

    }
    const handleNewDialogAddRuleAction = () => {
        trackEvent('DetailProjectTable context menu', 'Add Rule');
        props.addRuleNewDialog(selectedItem, itemType)
        setState(initialState);

    }

    const handleAddCompoundRuleAction = () => {
        trackEvent('DetailProjectTable context menu', 'Add Compound Rule');
        props.addCompoundRule(selectedItem, itemType)
        setState(initialState);

    }

    const handleAddCompoundRuleActionNewDialog = () => {
        trackEvent('DetailProjectTable context menu', 'Add Compound Rule');
        props.addCompoundRuleNewDialog(selectedItem, itemType)
        setState(initialState);

    }


    const handleSelectRules = () => {
        trackEvent('DetailProjectTable context menu', 'Select all Rules');


        selectedItem.rules = selectedItem.rules.map(rule => {
            return ({
                ...rule,
                'active': true
            })
        })

        let selectedProjectId = undefined
        modifiedProject.filter(x => {
            return x.data.datasources.filter(y => {
                if (y._id === selectedItem._id) {
                   selectedProjectId = x.data._id

                }
                return undefined
            })
        })

        props.editDatasource(project._id, selectedProjectId, selectedItem, selectedItem._id, props.userId)

    }

    const handleDeselectRules = () => {
        trackEvent('DetailProjectTable context menu', 'Deselect all Rules');


        selectedItem.rules = selectedItem.rules.map(rule => {
            return ({
                ...rule,
                'active': false
            })
        })

        let selectedProjectId = undefined
        modifiedProject.filter(x => {
            x.data.datasources.filter(y => {
                if (y._id === selectedItem._id) {
                   selectedProjectId = x.data._id

                }
                return undefined
            })
            return undefined
        })

        props.editDatasource(project._id, selectedProjectId, selectedItem, selectedItem._id, props.userId)

    }

    const handleCopyDatasource = () => {
        setCopyDatasourceDialog(true)
    }

    /*
    function changeSelectedItem(item, select) {

        if (select) {
            item.rules = item.rules.map(rule => {
                return ({
                    ...rule,
                    'active': true
                })
            })
        } else {
            item.rules = item.rules.map(rule => {
                return ({
                    ...rule,
                    'active': false
                })
            })
        }
        props.editDatasource(project._id, modifiedProject[0].data._id, item, item._id, props.userId)

    }
*/



    return (
        <div style={{ width: '100%', zIndex: 3 }}>
            {(itemType === "Qualitypoint") && contextHelper.getContextMenuQualityPoint(
                state, handleCloseContextMenu, handleAddDatasourceAction, handleEditAction,
                handleDeleteAction, handleRuleRecommender)}
            {(itemType === "Datasource") && contextHelper.getContextMenuDatasource(
                state, handleCloseContextMenu, handleAddRuleAction,handleNewDialogAddRuleAction, 
                handleAddCompoundRuleAction,handleAddCompoundRuleActionNewDialog,
                handleAddFolderAction, handleEditAction, handleDeleteAction, handleSelectRules, handleDeselectRules, handleCopyDatasource,props.newRuleFormFlag
            )}
            {(itemType === "Folder") && contextHelper.getContextMenuFolder(
                state, handleCloseContextMenu, handleAddRuleAction, handleNewDialogAddRuleAction,
                handleAddCompoundRuleAction,handleAddCompoundRuleActionNewDialog, handleAddFolderAction, handleEditAction, handleDeleteAction,props.newRuleFormFlag
            )}
            {(itemType === "Rule" && selectedItem !== undefined) && contextHelper.getContextMenuRule( 
                state, handleCloseContextMenu, handleEditAction, handleEditActionNewDialog,  handleDeleteAction,props.newRuleFormFlag
            )}

            <AqtivaDialog visible={restartRuleDialogVisible}
                fullWidth={true}
                maxWidth={'sm'}
                title={'Clean rule KPIs'}
                editable={false}
                titleIcon={faDatabase}
                confirmText={'Clean'}
                cancelText={'Cancel'}
                confirmCallback={event => {
                    dispatch(actions.resetDvdKpiRule(project._id, ruleId, selectedEnvironment));
                    setRestartRuleDialogVisible(false)
                }}
                cancelCallback={event => {
                    setRestartRuleDialogVisible(false)
                }}
            >
                <div>
                    <Typography variant='subtitle2' style={{ fontFamily: 'Future' }}>Confirm Environment</Typography>
                    <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                    />
                </div>


            </AqtivaDialog>
            <TreeTable
                value={modifiedProject}

                //contextMenuSelectionKey={this.state.selectedNodeKey}
                onContextMenuSelectionChange={event => {
                    handleClickContextMenuTable(event)
                }
                }
            >
                {process.env.REACT_APP_LINKS_TO_DEPLOY_ALLOWED === 'true'}
                <Column style={renderHelper.tableHeaderStyle('2%')}
                    field="none" header=" " body={data => renderHelper.go2DeployTemplate(data, props.qualityConfiguration, user.id, project, props.selectedEnvironment)}></Column>

                <Column style={renderHelper.tableHeaderStyle('2%')}
                    field="more" header=" " body={data => renderHelper.simulateCellTemplate(data, props.onClickOpenDialogSimulation)}></Column>
                <Column style={renderHelper.tableHeaderStyle('3%')}
                    field="hasScheduleFlag" header=" " body={data => renderHelper.hasScheduleTemplate(data, schedules)}></Column>
                <Column style={renderHelper.tableHeaderStyle('3%')}
                    field="hasColumnIssuesFlag" header=" " body={data => renderHelper.hasIssuesWithColumns(data)}></Column>
                <Column style={renderHelper.tableHeaderStyle('3%')}
                    field="consolidate" header=" " body={data => renderHelper.consolidateRulesTemplate(data, project, consolidateRulesFromDatasource, consolidateHelper.checkIfDatasourceHasConsolidableRules)}></Column>
                <Column style={renderHelper.tableHeaderStyle('7%')}
                    field="type" header="Type" body={data =>
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.typeTemplate(data, props.tableByRulesOrColumns)}
                        </div>
                    }>

                </Column>

                {
                    <Column style={renderHelper.tableHeaderStyle('7%')}
                        field="enabled" header="Enabled" body={data => renderHelper.enableTemplate(data, filterProject(project), props.editQualityPipe, props.editDatasource, props.editRule, props.tableByRulesOrColumns, props.userId)}>

                    </Column>
                }

                <Column style={renderHelper.tableHeaderStyleLeft('25%')}
                    field="name" header="Name" expander body={data => {
                        if (props.tableByRulesOrColumns === true && (data.data.nodeType === 'Rule' || data.data.nodeType === 'Column')) {
                            return (
                                DragDropTemplate(renderHelper.ruleTemplate(data, filterProject(project), props.setSelectedItem, restartRule, undefined, props.tableByRulesOrColumns), data, modifiedProject, project, props.tableByRulesOrColumns)
                            )
                        }
                        else {
                            return (
                                DragDropTemplate(NameCellTemplate(data, filterProject(project), props.setSelectedItem, restartRule, props.tableByRulesOrColumns), data, modifiedProject, project, props.tableByRulesOrColumns)
                            )
                        }
                    }
                    }
                ></Column>





                <Column style={renderHelper.tableHeaderStyleCenter('8%')}
                    field="conditional" header="Conditional" body={data => renderHelper.conditionalTemplate(data, theme)}></Column>




                <Column style={renderHelper.tableHeaderStyleLeft('15%')}
                    field="thError" header="Error Th." body={data =>
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.thErrorTemplate(data)}
                        </div>
                    }></Column>
                <Column style={renderHelper.tableHeaderStyleLeft('10%')}
                    field="thWarning" header="Warning Th." body={data =>
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.thWarningTemplate(data)}
                        </div>
                    }></Column>
                <Column style={renderHelper.tableHeaderStyleCenter('10%')}
                    field="controlModeType" header="Output Control"
                    body={data =>
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.outputControllCellTemplate(data)}
                        </div>
                    }
                ></Column>
                <Column style={renderHelper.tableHeaderStyleLeft('10%')}
                    field="wrongRegisterActionType" header="Bad Regs Action"
                    body={data =>
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.wrongActionCellTemplate(data)}
                        </div>
                    }
                >
                </Column>
                <Column style={renderHelper.tableHeaderStyleLeft('10%')}
                    field="dimension" header="Dimension"
                    body={data =>
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.dimensionCellTemplate(data)}
                        </div>
                    }
                >
                </Column>

                <Column style={renderHelper.tableHeaderStyleLeft('10%')}
                    field="ruleSpecificType" header="Rule Type"
                    body={data =>
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.ruleTypeTemplate(data)}
                        </div>
                    }
                >
                </Column>

            </TreeTable>
            <AqtivaDialog
                visible={dialog}
                dialog={dialog}
                setDialog={setDialog}
                fullWidth={true}
                maxWidth={'sm'}
                title={'Consolidate rules'}
                editable={false}
                titleIcon={faDatabase}
                confirmText={'Consolidate'}
                cancelText={'Cancel'}
                cancelCallback={() => { setDialog(false) }}
                confirmCallback={event => {
                    dispatch(actions.editDatasource(project._id, consolidationResult.qpId, consolidationResult.datasource, user.id))
                    setDialog(false)
                }}
            >
                <FormLabel component="legend">Do you want to consolidate the rules as such:</FormLabel>
                <FormControl style={{
                    maxHeight: "40vh"
                }} component="fieldset">
                    {consolidationResult && consolidationResult.oldRules && consolidationResult.oldRules.map(element => {
                        if (element.names && element.names.length > 0) {
                            return (
                                <>
                                    <br></br>
                                    {element.names.length > 1 && <Typography>The following rules consolidate into rule <strong>{element.into}</strong>: </Typography>}
                                    {element.names.length === 1 && <Typography>The following rule consolidates into rule <strong>{element.into}</strong>: </Typography>}
                                    <ul styles={{ listStyleType: "none" }}>
                                        {element.names.map(element => {
                                            return (
                                                <li>{element}</li>
                                            )
                                        })}
                                    </ul>
                                </>
                            )
                        }
                        else {
                            return (
                                <>
                                    <br></br>
                                </>
                            )
                        }
                    })}

                </FormControl>

            </AqtivaDialog>

            <AqtivaDialog
                visible={copyDatasourceDialog}
                dialog={copyDatasourceDialog}
                setDialog={setDialog}
                fullWidth={true}
                maxWidth={'sm'}
                title={'Copy Datasource'}
                editable={false}
                titleIcon={faDatabase}
                confirmText={'Copy'}
                cancelText={'Cancel'}
                showConfirmButton = {selectedQP !== undefined}
                cancelCallback={() => { setCopyDatasourceDialog(false) }}
                confirmCallback={event => {
                    dispatch(actions.copyDatasource(selectedProject._id, selectedQP._id, user.id, selectedItem)).then(() => {
                        setCopyDatasourceDialog(false)
                    })
                }}
            >

                <FormControl style={{ width: '100%', }}>
                    <InputLabel > <Typography variant='caption'> Select Project</Typography> </InputLabel>
                    <Select
                        value={selectedProject}
                        onChange={event => {
                            setSelectedProject(event.target.value)
                            setSelectedQP(undefined)
                        }}
                        inputProps={{
                            name: 'selectedRecurrence',
                            id: 'selectedRecurrence',
                        }}
                        style={{ width: '100%', }}
                    >

                        {projects.filter(pr => pr._id !== project._id).map(element => {
                            return (
                                <MenuItem key={element._id} value={element}>
                                    <Typography variant='caption'>{`${element.name}`}</Typography>
                                </MenuItem>

                            )
                        })}
                    </Select>
                </FormControl>


                {<FormControl disabled={selectedProject ? false : true} style={{ width: '100%', }}>
                    <InputLabel >
                        <Typography variant='caption'> Select Quality Point</Typography>
                    </InputLabel>
                    <Select
                        value={selectedQP}
                        onChange={event => setSelectedQP(event.target.value)}
                        inputProps={{
                            name: 'selectedRecurrence',
                            id: 'selectedRecurrence',
                        }}
                        style={{ width: '100%', }}
                    >

                        {selectedProject && selectedProject.qualityPoints &&  selectedProject.qualityPoints.map(element => {
                            return (
                                <MenuItem key={element._id} value={element}>
                                    <Typography variant='caption'>{`${element.name}`}</Typography>
                                </MenuItem>

                            )
                        })}
                    </Select>
                </FormControl>}

            </AqtivaDialog>
        </div >

    );
}
