import React from 'react';
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, FormControlLabel, TextField, Divider, Switch, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useInterval } from 'components/common/useInterval'
import * as actions from 'actions'
import { dispatchError } from 'components/common/axios/axiosHelper';
import { useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'
import FilterByRGComponentAutocomplete from './../common/FilterByRGComponentAutocomplete'
import * as helper from './SelectAzureItemDialogHelper'
import ComponentPreviewData from './../ComponentPreviewData'
import TreeViewSynapse from './../treeViews/TreeViewSynapse';
import AqtivaLoading from 'components/common/AqtivaLoading';

export default function SelectSynapseDialog(props) {

    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))
    const dispatch = useDispatch();
    const theme = useTheme();

    const [selectedItem, setSelectedItem] = React.useState(undefined)
    const [runId, setRunId] = React.useState(undefined)

    const [previewDialogVisible, setPreviewDialogVisible] = React.useState(false)
    const [previewDialogParsingData, setPreviewDialogParsingData] = React.useState(false)

    const [schema, setSchema] = React.useState([])

    const [fileOutputName, setFileOutputName] = React.useState('')

    const [stats, setStats] = React.useState([])

    const [delimiter, setDelimiter] = React.useState(undefined)
    const [format, setFormat] = React.useState(undefined)
    const [dataPath, setDataPath] = React.useState(undefined)
    const [dataCount, setDataCount] = React.useState(1)
    const [outputPath, setOutputPath] = React.useState(undefined)

    const [errorBackend, setErrorBackend] = React.useState(undefined)
    const [numberSamples, setNumberSamples] = React.useState(100)
    const [flattenFlag, setFlattenFlag] = React.useState(true)

    const [resourceGroups, setResourceGroups] = React.useState([])
    const [selectedResourceGroup, setSelectedResourceGroup] = React.useState('')

    const [subscriptions, setSubscriptions] = React.useState([])
    const [selectedSubscription, setSelectedSubscription] = React.useState('')


    const [sqlError, setSqlError] = React.useState('')
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [selectedSQLDTO, setSelectedSQLDTO] = React.useState({})

    const [selectedEnvironmentStates, setSelectedEnvironmentStates] = React.useState('')
    const [loadingPreview, setLoadingPreview] = React.useState(false)

    function setResourceGroupsAndSelect(rgroups) {
        setResourceGroups(rgroups)
        if (rgroups !== undefined && rgroups.length > 0) {
            setSelectedResourceGroup(rgroups[0])
        }
    }

    React.useEffect(() => {

        (async () => {
            const subscriptions = await dispatch(actions.getSubscriptions())
            setSubscriptions(subscriptions)
            if (configuration && configuration.azureCredentials && configuration.azureCredentials.subscription) {
                setSelectedSubscription(configuration.azureCredentials.subscription)
            }
            else {
                setSelectedSubscription(subscriptions[0].subscriptionId)
            }

        })();

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {
        if (runId !== undefined) {
            dispatch(actions.getJobRunOutputForUser(runId, user.id, selectedEnvironment, numberSamples, flattenFlag)).then(output => {
                if (output && output.data && output.data.error && output.data.error !== null) {
                    //execution error
                    if (output.data.error === 'No output is available until the task begins.') {
                        setErrorBackend('Starting Big Data server. Depends on the infrastructure it make take some time...')
                    }
                    else {
                        setErrorBackend(output.data.error)
                    }



                }
                if (output && output.data && output.data.metadata && output.data.metadata.state && output.data.metadata.state.result_state === 'FAILED') {
                    //preview failed
                    dispatchError(dispatch, output.data.metadata.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (output && output.data && output.data.state && output.data.state === 'FAILED') {
                    //preview failed
                    dispatchError(dispatch, output.data.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (output && output.data && output.data.result && output.data.result !== null) {
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setSchema(JSON.parse(JSON.parse(output.data.result).schema))
                    setStats(JSON.parse(JSON.parse(output.data.result).statistics))

                    setPreviewDialogVisible(true)
                    setPreviewDialogParsingData(false)


                    setDelimiter(JSON.parse(output.data.result).separator)
                    setFormat(JSON.parse(output.data.result).format)
                    setOutputPath(JSON.parse(output.data.result).outputPath)
                    setDataPath(JSON.parse(output.data.result).dataPath)
                    setDataCount(JSON.parse(output.data.result).count)
                }

            }
            )
        }

    }
        , 3000)


    function setVisiblePreviewCallback(value) {
        setPreviewDialogParsingData(true)
        setPreviewDialogVisible(true)

    }

    function setPreviewDialogVisibleFull(value) {
        setPreviewDialogVisible(value)
        props.setVisible(false)
    }

    //función que convierte un esquema de previsualización en un objecto dataformat, función final del sistema
    function convertSchema2Datasource() {
        if (schema !== undefined && selectedItem !== undefined) {
            const dataset = helper.convertSchema2DatasourceSqlSynapseDatabase(schema, user, selectedSQLDTO, fileOutputName)
            if (props.createNewVersion && props.createNewVersion === true && props.selectedDataset) {
                setLoadingPreview(true)
                dispatch(actions.createNewDataformatVersion(dataset, props.selectedDataset._id))
                props.closeDialogDisplay(false)
                setLoadingPreview(false)
            }
            else {
                dispatch(actions.addDataFormatObject(dataset))
            }
            props.setVisible(false)
            props.setVisibleParent(false)
        }


    }

    function createAndContinue() {

        if (schema !== undefined && selectedItem !== undefined) {

            const dataset = helper.convertSchema2DatasourceSqlSynapseDatabase(schema, user, selectedSQLDTO, fileOutputName)
            if (props.createNewVersion && props.createNewVersion === true && props.selectedDataset) {
                setLoadingPreview(true)
                dispatch(actions.createNewDataformatVersion(dataset, props.selectedDataset._id))
                props.closeDialogDisplay(false)
                setLoadingPreview(false)
            }
            else {
                dispatch(actions.addDataFormatObject(dataset))
            }

            setVisiblePreviewCallback(true)
            dispatch(actions.fetchAllDataFormats(user))
            setPreviewDialogParsingData(false)
            setPreviewDialogVisible(false)
        
        }
    }

    return (
        <AqtivaDialog visible={props.visible}
            maxWidth={'lg'}
            title={'Inspect and Select Azure Synapse SQL Workspaces'}
            titleIcon={faWindows}
            confirmText={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? 'Create new version' : 'Create'}
            cancelText={'Close'}
            confirmCallback={() => { convertSchema2Datasource() }}
            cancelCallback={() => { props.setVisible(false) }}
        >
            <div style={{ width: '100%', height: '500px' }}>



                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div style={{ ...theme.elementAdjacent, marginBottom: '-20px', marginLeft: '10px' }}>
                            <div style={{ width: '150px', marginRight: '10px' }}>
                                <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                                    setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                            </div>

                            <FormControl style={{ ...theme.config.formControl, minWidth: '300px', marginRight: '20px', marginTop: '5px' }}>
                                <InputLabel id="selectSubscription">Select a Subscription</InputLabel>
                                <Select
                                    labelId="dselectSubscription"
                                    id="selectSubscription"
                                    value={selectedSubscription}
                                    onChange={event => setSelectedSubscription(event.target.value)}
                                >
                                    {subscriptions !== undefined && subscriptions.length > 0 && subscriptions.map(x => {
                                        return <MenuItem value={x.subscriptionId}>
                                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                <img alt="" style={{ height: '20px', width: '20px' }}
                                                    src={require('resources/icons/azure/azureSubscriptions.jpg')} />
                                                <Typography variant='subtitle2'>{x.displayName}</Typography>
                                            </div>
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <div style={{ width: '200px', marginRight: '30px' }}>
                                <FilterByRGComponentAutocomplete
                                    selectedResourceGroup={selectedResourceGroup}
                                    setSelectedResourceGroup={setSelectedResourceGroup}
                                    resourceGroups={resourceGroups} />
                            </div>
                            {undefined && <FormControlLabel style={theme.config.formControl}
                                control={
                                    <TextField
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        id="nSamples"
                                        label={<Typography variant="caption">Samples to Preview</Typography>}
                                        style={theme.config.textField}
                                        margin="dense"
                                        value={numberSamples}
                                        onChange={(event) => setNumberSamples(event.target.value)}
                                    />} />}
                              {undefined &&<FormControlLabel style={theme.config.formControl}
                                control={
                                    <Switch
                                        checked={flattenFlag}
                                        onChange={event => {
                                            setFlattenFlag(event.target.checked)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={<Typography variant="caption">Flatten Nested Structures</Typography>}
                            />}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {sqlError !== undefined && sqlError !== '' &&
                        <Grid item xs={12}>
                            <Alert severity="error">{sqlError}</Alert>


                        </Grid>

                    }
                    <Grid item xs={4} style={{ marginTop: '-20px' }}>
                        <TreeViewSynapse
                            selectedSubscription={selectedSubscription}
                            setSelectedItem={setSelectedItem}
                            selectedItem={selectedItem}
                            setRunId={setRunId}
                            setVisiblePreview={setVisiblePreviewCallback}
                            setFileOutputName={setFileOutputName}
                            numberSamples={numberSamples}
                            flattenFlag={flattenFlag}
                            setResourceGroups={setResourceGroupsAndSelect}
                            selectedResourceGroup={selectedResourceGroup}
                            setSqlError={setSqlError}
                            sqlError={sqlError}
                            selectedEnvironment={selectedEnvironment}
                            setSelectedSQLDTO={setSelectedSQLDTO}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        {loadingPreview &&
                            <AqtivaLoading />
                        }
                        {!loadingPreview &&
                            <>
                                <ComponentPreviewData
                                    selectedEnvironment={selectedEnvironment}
                                    selectedItem={selectedItem}
                                    visible={previewDialogVisible}
                                    setVisible={setPreviewDialogVisible}
                                    setVisibleFull={setPreviewDialogVisibleFull}
                                    parsingData={previewDialogParsingData}
                                    fileOutputName={fileOutputName}
                                    schema={schema}
                                    errorBackend={errorBackend}
                                    stats={stats}
                                    delimiter={delimiter}
                                    format={format}
                                    dataPath={dataPath}
                                    outputPath={outputPath}
                                    dataCount={dataCount}
                                    selectedEnvironmentStates={selectedEnvironmentStates} 
                                    createAndContinue={createAndContinue}
                                    />
                            </>
                        }
                    </Grid>

                </Grid>

            </div>

        </AqtivaDialog>
    )
}