import React from 'react'
import MaterialTable from "@material-table/core";
import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';


export default function UsersTable(props) {

    React.useEffect(() => {
        loadCSS(
          'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
      }, []);

    const data = props.data
    if(!props.data){
        return(
            <div></div>
        )
    }

    return(
        <MaterialTable
                columns={[
                    { title: "Name", field: "name" ,},
                    { title: "Email", field:"email"},
                    { title: "Provider", field: "provider" ,render: rowData =>{ 
                        if(rowData.provider==="google") return <Icon className="fab fa-google" style={{color:'#db3236'}} fontSize="small"/>
                    if(rowData.provider==="azure") return <Icon className="fab fa-windows" color='primary' fontSize="small" />                    }},
                    { title: "role", field: "role" },
                    
                    
                ]}
                data={data}
               
                options={{
                    filtering: false,
                    search:false,
                    maxBodyHeight : 250, 
                    minBodyHeight : 250
                  }}
                  components={{
                    Toolbar: props => (
                      <div>
                        
                      </div>
                    ),
                  }}
                title=""
    />
        
    )
}