import React from "react";
import { useDrag } from "react-dnd";
import { useDrop } from "react-dnd";
import {  useDispatch } from 'react-redux'
import * as actions from '../../../../../../../../actions'


//Limitamos los archivos para que puedan moverse con dnd aplicando useDrag (al contrario que las carpetas)
export function Drag(props) {
  const dispatch = useDispatch();

  const [{ isDragging }, dragRef] = useDrag({
    item: {
      type: "card",
      itemId: "1",
      index: props.data.index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: "card",
    drop: (item, monitor) => {
      if (item.itemId) {
        changeIndex(item.index, props.data.index, props.data, props.modifiedProject, props.setModifiedProject)
      }

    },
    canDrop: () => true,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  });

  if(isOver && canDrop){
    //TODO: to avoid compiler warnings
  }


  function changeIndex(oldPosition, newPosition, ruleData, modifiedProject, setModifiedProject) {
    const rulePositionString = ruleData.key.split('-')
    let rulePositionInt = []
    if (rulePositionString.length === 3) {
      for (let i = 0; i < rulePositionString.length; i++) {
        rulePositionInt.push(parseInt(rulePositionString[i], 10))
      }
      let newRules = modifiedProject[rulePositionInt[0]].children[rulePositionInt[1]].children
      let idProject = props.project._id
      let idQualitypoint = modifiedProject[rulePositionInt[0]].data._id
      let idDatasource = modifiedProject[rulePositionInt[0]].children[rulePositionInt[1]].data._id
      if (oldPosition > newPosition) {
        newRules.splice(newPosition, 0, newRules[oldPosition])
        newRules.splice(oldPosition + 1, 1)
      }
      if (oldPosition < newPosition) {
        newRules.splice(newPosition + 1, 0, newRules[oldPosition])
        newRules.splice(oldPosition, 1)
        modifiedProject[rulePositionInt[0]].children[rulePositionInt[1]].children = newRules
      }
      modifiedProject[rulePositionInt[0]].children[rulePositionInt[1]].children = newRules
      let rulesToBeshipped = []
      for (let i = 0; i < newRules.length; i++) {
        newRules[i].data.priority = i
        rulesToBeshipped.push(newRules[i].data)
      }
      dispatch(actions.editRulesPriority(idProject, idQualitypoint, idDatasource, rulesToBeshipped))

    }
    
  }

  return (
    <div ref={dropRef} >
      <div style={{ backgroundColor: isDragging ? "#fbb" : "transparent", marginLeft: `${props.data && props.data && props.data.key && props.data.key.split("-").length * 13}px`, width: "100%", display: "inline", paddingTop: "1px" }} ref={dragRef} >
        <span style={{ display: "inline-block", width: "60%", height: "100%" }}>
          {props.children}
        </span>
      </div>
    </div>

  );
}
export default Drag