import React from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { AppBar, Toolbar, ClickAwayListener, Badge ,Tooltip} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

import Sidebar from "./Sidebar";
import ProfileMenu from "./ProfileMenu";
import NotificationsMenu from "./NotificationsMenu";
import FeedbackMenu from "./FeedbackMenu";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions";
import PathNavigation from "./PathNavigation";

import SentryGenerateAlert from "./SentryGenerateAlert";

import { useInterval } from "./../common/useInterval";

import { ReactSlackChat } from 'react-slack-chat/dist/react-slack-chat-with-default-hooks';

import { useHistory } from "react-router-dom";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import * as commonStyles from 'style/CommonStyles'

import { LanguageContext } from 'language/LanguageContext'

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: commonStyles.mainColor+" !important",
  },
  customBadge:{
    backgroundColor: commonStyles.redColor
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));



function AppBarTemplate(props) { 
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const languageContext = React.useContext(LanguageContext); 

  const qualityConfiguration = useSelector(store => (store.qualityConfiguration))


  const unreadNotifications = useSelector((store) =>
    Object.values(store.notifications) !==undefined && Object.values(store.notifications).length>0 ? Object.values(store.notifications).filter(x=>x!==null) : Object.values(store.notifications)
  );

  const [open, setOpen] = React.useState(false);

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);
  const [anchorElFeedback, setAnchorElFeedback] = React.useState(null);

  const [configErrors, setConfigErrors] = React.useState([])

  const openProfile = Boolean(anchorElProfile);
  const openNotifications = Boolean(anchorElNotification);
  const openFeedback = Boolean(anchorElFeedback);

  const user = useSelector(store => store.user)

  const [slackSupportChannel, setSlackSupportChannel] = React.useState("")

  async function getSlackSupportChannel(){
    setSlackSupportChannel( await dispatch(actions.getControlValue("SLACK_SUPPORT_CHANNEL")))
  }

  React.useEffect(()=>{
    getSlackSupportChannel()
  },[])

  //función de obtención de configuración
  React.useEffect(()=>{
    if(qualityConfiguration === undefined || qualityConfiguration===null || JSON.stringify(qualityConfiguration)==="{}"){
     if (user && user.id) {
         dispatch(actions.fetchConfigurationByUserId(user.id));
       }
    }
 }, [JSON.stringify(user)])// eslint-disable-line react-hooks/exhaustive-deps


 //funcion de validación de la configuracion
 React.useEffect(()=>{
  if(qualityConfiguration !== undefined && qualityConfiguration!==null && JSON.stringify(qualityConfiguration)!=="{}"){
   //validate configuration
   validateConfiguration(qualityConfiguration)
  }
}, [JSON.stringify(qualityConfiguration)])// eslint-disable-line react-hooks/exhaustive-deps


//TODO: sacar a general

function  validateConfiguration(configuration){
  var errors = []

  validateField(configuration, "thWarnType",languageContext.dictionary['warningThType'], errors)
  validateField(configuration, "thWarnValue",languageContext.dictionary['warningTh'], errors)
  validateField(configuration, "thWarnAction",languageContext.dictionary['warningThAction'], errors)

  validateField(configuration, "thErrorType",languageContext.dictionary['errorThType'], errors)
  validateField(configuration, "thErrorValue",languageContext.dictionary['errorTh'], errors)
  validateField(configuration, "thErrorAction",languageContext.dictionary['errorThAction'], errors)


  //check environment

  if(configuration.environmentConfigurationList === null || configuration.environmentConfigurationList === undefined ||
    configuration.environmentConfigurationList.length ===0){
      errors.push("No environment has been configured. At least one environment needs to be configured")
    }
    else{
      //validate all environments
    }

  

  console.log(errors)

  setConfigErrors(errors)
  //comprobación de umbrales
}

function validateField(obj, fieldName, fieldPrettyName, errorList){
  if(obj[fieldName]===null || obj[fieldName]===undefined){
    errorList.push(fieldPrettyName+" mandatory parameter should be defined")
  }
  
}


  useInterval(() => {
    if(props.intervalNotifications!==false){
      if(props.fecthNotifications!==false){
        if(user && process.env.REACT_APP_NOTIFICATIONS_ALLOWED ==='true'){
          //do not call fetch notifications if user id is not loaded
          if(user !==undefined && user.id!==undefined){
            dispatch(actions.fetchUnreadNotifications(user.id, props))
            dispatch(actions.fetchNotifications(user.id, props))
          }
          
          }
      }
    }
   
    

}
,120000)


React.useEffect(()=>{
  if(user && process.env.REACT_APP_NOTIFICATIONS_ALLOWED ==='true'){
    //do not call fetch notifications if user id is not loaded
    if(user !==undefined && user.id!==undefined){
      dispatch(actions.fetchUnreadNotifications(user.id, props))
      dispatch(actions.fetchNotifications(user.id, props))
    }
    }
  
    if(user!==undefined && user.id!==undefined){
      dispatch(actions.fetchLicense((user).id))
      
  }
},[user])// eslint-disable-line react-hooks/exhaustive-deps



  const handleClickAway = () => {
    setOpen(false);
  };

  //eventSource.onerror = (event: any) => console.log('error', event);

  React.useEffect(() => {
    if(user!==undefined && user.id!==undefined){
      dispatch(actions.fetchLicense((user).id))
  }
    if(user && process.env.REACT_APP_NOTIFICATIONS_ALLOWED ==='true'){
       //do not call fetch notifications if user id is not loaded
       if(user !==undefined && user.id!==undefined){
        dispatch(actions.fetchUnreadNotifications(user.id, props))
        dispatch(actions.fetchNotifications(user.id, props))
      }
      }
      if(user!==undefined && user.id!==undefined){
        dispatch(actions.fetchLicense((user).id))
    }
  }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleMenuFeedback = (event) => {
    setAnchorElFeedback(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const handleCloseFeedback = () => {
    setAnchorElFeedback(null);
  };

  const handleMenuNotifications = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotifications = () => {
    setAnchorElNotification(null);
  };


  return ( 
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        
      {process.env.REACT_APP_REALTIME_CHAT===true && 
      <ReactSlackChat
          botName={user.name} // VisitorID, CorpID, Email, IP address etc.
          apiToken='eG94Yi0yOTU3NzAzODQ2NzgtMTgzNjc0MDk1ODM3NS1lQUJMNTZxakUxWFA5NG1yRllGSVVMVHY='
          
          channels={[
          {
            name: slackSupportChannel,
            id: 'C48SAX4',
            icon: 'https://cdn.worldvectorlogo.com/logos/slack-1.svg'
          },
          ]}
          helpText='Aqtiva Online Help Desk'
          themeColor={commonStyles.mainColor}
          userImage='http://cdn.onlinewebfonts.com/svg/img_184513.png'
          singleUserMode={true}
          closeChatButton={true}
          defaultMessage= "Welcome! Someone will help shortly."
        />
      }

        {undefined && <SentryGenerateAlert />}
        <AppBar position="static" className={classes.appbar}>
          <Toolbar style={{ marginBottom: "-5px", marginTop: "-5px" }}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <img
              src="/logo_omma_O_white.png"
              alt="logo"
              style={{ width: "35px", marginLeft: "10px", marginRight: "10px" }}
            />

            <ArrowBackIcon style={{cursor:'pointer', marginLeft:'10px' }} onClick={event=> history.goBack()} />
            <ArrowForwardIcon style={{cursor:'pointer',marginRight:'10px' }} onClick={event=> history.goForward()}  />

            <PathNavigation
              title={props.title}
              history={props.history}
              arrayPath={props.arrayPath}
              user={user}
            />
            {process.env.REACT_APP_NOTIFICATIONS_ALLOWED==='true' && 
             
            <div>
              { (configErrors !== undefined && configErrors.length >0) &&
              <IconButton
                aria-label="notifications"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                style={{color: (configErrors && configErrors.length > 0 ? "inherit" : "#6B6B6B") }}
              >
                <Badge
                  badgeContent={
                    configErrors ? configErrors.length : 0
                  }
                  overlap="rectangular"
                  color="primary"
                >
                 {configErrors && configErrors.length>0 &&  
                 <img alt="" style={{ height: '25px', width: '25px' }}
                src={require('resources/icons/error_icon_red.png')}
                />}

              
                </Badge>
              </IconButton>

            }
              <IconButton
                aria-label="notifications"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuNotifications}
                style={{color: (unreadNotifications && unreadNotifications.length > 0 ? "inherit" : "#6B6B6B") }}
              >
                <Badge
                  badgeContent={
                    unreadNotifications ? unreadNotifications.length : 0
                  }
                  overlap="rectangular"
                  classes={{ badge: classes.customBadge }}
                  
                >
                 {unreadNotifications && unreadNotifications.length>0 &&  
                 <img alt="" style={{ height: '25px', width: '25px' }}
                src={require('resources/icons/notifications_proc1_white_2.png')}
                />}

              {(unreadNotifications === undefined || unreadNotifications.length ===0) &&
              <img alt="" style={{ height: '25px', width: '25px' }}
                src={require('resources/icons/notifications_proc1_black.png')}
                />
              }
                </Badge>
              </IconButton>
              <NotificationsMenu
                id="menu-appbar"
                openNotifications={openNotifications}
                handleCloseNotifications={handleCloseNotifications}
                history={props.history}
                anchorElNotification={anchorElNotification}
              />
            </div> }
            <div>
              <Tooltip title='Send feedback to Aqtiva'>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuFeedback}
                color="inherit"
              >
                 <img alt="" style={{ height: '30px', width: '30px' }}
                src={require('resources/icons/comment_proc1_white.png')}
                />
                 </IconButton>
                 </Tooltip>
                 <FeedbackMenu
                id="menu-appbar"
                open={openFeedback}
                handleCloseNotifications={handleCloseFeedback}
                history={props.history}
                anchorEl={anchorElFeedback}
              />
            </div>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuProfile}
                color="inherit"
              >
                <img alt="" style={{ height: '30px', width: '30px' }}
                src={require('resources/icons/user_proc1_white.png')}
                />
              </IconButton>

              
              <ProfileMenu
                id="menu-appbar"
                openProfile={openProfile}
                handleCloseProfile={handleCloseProfile}
                anchorElProfile={anchorElProfile}
                history={props.history}
              />
            </div>
          </Toolbar>
        </AppBar>

        <Sidebar
          open={open}
          handleDrawerClose={handleDrawerClose}
          className={classes.appbar}
          localPath={props && props.history && props.history.location ? props.history.location.pathname: ''}
        />
      </div>
    </ClickAwayListener>
  );
}

export default AppBarTemplate;
