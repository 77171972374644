import React, { useEffect, useState } from 'react'
import AqtivaDialog from '../../../common/AqtivaDialog'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import {  useDispatch } from "react-redux";
import MaterialTable from "@material-table/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import * as actions from './../../../../actions'

import * as commonStyles from 'style/CommonStyles'

export default function DataformatDetail(props) {
    const dispatch = useDispatch()
    const [data, setData] = useState(props.dataset && props.dataset.columns)


    useEffect(() => {
        if (props.dataset && props.dataset.columns) {
            let columns = JSON.parse(JSON.stringify(props.dataset.columns))
            for (let i = 0; i < columns.length; i++) {
                columns[i].position = i
            }
            setData(columns)
        }

    }, [props.dataset && props.dataset.columns && props.dataset.columns.length, props.show])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [data])

    async function confirm(columns, dataset, props) {
        if (columns && dataset && props.onCloseDetailDialog) {
            let newDataset = dataset
            newDataset.columns = columns
            await dispatch(actions.editDataformat(newDataset))
            props.onCloseDetailDialog()
        }
    }

    return (
        <AqtivaDialog
            visible={props.show}
            dialog={props.show}
            fullWidth={true}
            maxWidth={'md'}
            title={"Dataformat detail"}
            editable={false}
            titleIcon={faProjectDiagram}
            confirmText={'Save changes'}
            confirmCallback={() => { confirm(data, props.dataset, props) }}
            cancelText={'Cancel'}
            cancelCallback={() => {props.onCloseDetailDialog()}}>
            <div style={{ marginTop: '10px' }}>
                {data && <MaterialTable
                    style={{ width: "100%" }}
                    title="Datasets"
                    data={data}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    setData([...data, newData]);

                                    resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setData([...dataUpdate]);

                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    let columns = JSON.parse(JSON.stringify(dataDelete))
                                    for (let i = 0; i < columns.length; i++) {
                                        columns[i].position = i
                                    }
                                    setData([...columns]);

                                    resolve()
                                }, 1000)
                            }),
                    }}
                    columns={
                        [
                            { title: 'Position', field: 'position', editable: 'never' },
                            { title: 'Name', field: 'name' },
                            { title: 'Format', field: 'type', lookup: { Date: 'Date', Float: 'Float', Integer: 'Integer', String: 'String' }, },
                        ]}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: false,
                        search: false,
                        pageSize: 5,
                        maxBodyHeight: '90%',
                        minBodyHeight: 250,
                        headerStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                            backgroundColor: commonStyles.mainColor,
                            color: '#FFF'
                        },
                        cellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                        filterCellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                    }}
                    icons={{
                        Edit: () => <EditIcon style={{ color: commonStyles.mainColor }} />,
                        Delete: () => <DeleteIcon style={{ color: commonStyles.mainColor }} />,
                        Save: () => <EditIcon style={{ color: commonStyles.mainColor }} />

                    }}
                    

                    components={{
                        Toolbar: props => (
                            <div>

                            </div>
                        ),
                    }}
                />}
            </div>

        </AqtivaDialog>
    )
}