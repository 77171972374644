import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { DataRepositoryHeaderButtons } from "../DataRepositoryHeaderButtons";
//import * as actions from "../../../../actions"; ya no hace falta pasarlas porque ahora vienen por props import { OptionExport } from "./Options/OptionExport";
import { OptionNew } from "./Options/OptionNew";
import { OptionGraph } from "./Options/OptionGraph";
import { MigrationButtons } from "./MigrationButtons"
import { DataRepositoryTreeTable } from "components/data/DataRepositoryShare/DataRepositoryTreeTrable/DataRepositoryTreeTable.js";
import { faShareSquare, faUserLock } from "@fortawesome/free-solid-svg-icons";
import * as convertDataHelper from "./Helpers/convertDataHelper";

//Este componente es el general que se reutiliza para Project, Rule y Datasets y el que incluimos en el index de cada uno de ellos.

export function EntityDataRepository(props) {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState({ itemId: "", type: "" }); // selectedItem del private
  const [selectedItemShared, setSelectedItemShared] = useState({ itemId: "", type: "" });
  const [expandedKeys, setExpandedKeys] = useState();
  const [expandedKeysShared, setExpandedKeysShared] = useState();
  const [showImportDialog, setShowImportDialog] = React.useState(false);
  const [showExportDialog, setShowExportDialog] = React.useState(false);

  if(showImportDialog && showExportDialog){
    //TODO: avoid compiler warnings
  }

  const [showNewSharedDialog, setShowNewSharedDialog] = React.useState(false);
  const [showNewDialog, setShowNewDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showDeleteSharedDialog, setShowDeleteSharedDialog] = React.useState(false);
  const [showNewDialogParent, setShowNewDialogParent] = React.useState(false);
  const [showGraphDialog, setShowGraphDialog] = React.useState(false);
  const [defaultFolderId, setDefaultFolderId] = React.useState(" ");

  const entityDataRepo = useSelector((store) =>
    Object.values(store[props.entityName])
  );
  const entityDataRepoShared = useSelector((store) =>
    Object.values(store[props.entityName + "Shared"])
  );
  function findEntityInDTOs(selectedIds, dtos) {
    for (var i = 0; i < dtos.length; i++) {
      if (dtos[i].qualityEntityRepoVirtualDTOS && dtos[i].qualityEntityRepoVirtualDTOS.length > 0) {

        for (var j = 0; j < dtos[i].qualityEntityRepoVirtualDTOS.length; j++) {
          if (dtos[i].qualityEntityRepoVirtualDTOS[j].entityId === selectedIds) {
            setSelectedItem({ itemId: dtos[i].qualityEntityRepoVirtualDTOS[j]._id, type: "Files" })
            break;
          }
        }
      }
    }
  }
  //if a default selected item needs to be shown
  React.useEffect(() => {

    if (props.selectedPrivateEntityId !== undefined && entityDataRepo !== undefined && entityDataRepo.length > 0 && selectedItem.itemId === "") {
      findEntityInDTOs(props.selectedPrivateEntityId, entityDataRepo)
    }

  }, [props.selectedPrivateEntityId, entityDataRepo])// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let defaultFolderIdNumber = entityDataRepo[0] && entityDataRepo[0]._id
    setDefaultFolderId(defaultFolderIdNumber)
  }, [entityDataRepo])

  React.useEffect(() => {
    if (props.user && props.user.id) {
      dispatch(
        props.actions.fetchProjectDataRepositorySharedWorkspace(
          /* "5d829501ee51b877f859a29c" */ props.user.id
        )
      );
      dispatch(
        props.actions.fetchProjectDataRepositoryPrivateWorkspace(
          /* "5d829501ee51b877f859a29c" */ props.user.id
        )
      );
    }
  }, [props.user]);// eslint-disable-line react-hooks/exhaustive-deps


  //Treetables de private, shared y migration buttons con las props necesarias
  return (
    <div>
      <DataRepositoryHeaderButtons
        /*         setShowImportDialog={setShowImportDialog}
                setShowExportDialog={setShowExportDialog} */
        setShowNewDialogParent={setShowNewDialogParent}
      />
      <Divider />
      <div style={{ width: "100%", display: "flex", direction: "row", alignItems: "center" }}>
        <div item style={{ minWidth: "43vw" }}>
          <DataRepositoryTreeTable
            actions={props.actions}
            enableContextMenu={true}
            type="private"
            dtoData={entityDataRepo}
            defaultFolderId={defaultFolderId}
            treeData={convertDataHelper.parseEntityDataRepositoryToTree(
              entityDataRepo
            )}
            iconTitle={faUserLock}
            title={`Private Workspace User ${props.user.name}`}
            titleStyle={{ color: "#45b6fe", margin: 0 }}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setShowDialogs={{
              import: setShowImportDialog,
              export: setShowExportDialog,
              new: setShowNewDialog,
              delete: setShowDeleteDialog,
            }}
            showDialogs={{ new: showNewDialog, delete: showDeleteDialog }}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
            iconType1={props.iconType1}
            iconType2={props.iconType2}
            user={props.user}
            entityName={props.entityName}
          />


        </div>
        <div item style={{ minWidth: "5vw", marginTop: "-6vh" }} >
          <MigrationButtons selectedItem={selectedItem} selectedItemShared={selectedItemShared} entityDataRepo={entityDataRepo} entityDataRepoShared={entityDataRepoShared} actions={props.actions} />
        </div>
        <div item style={{ minWidth: "43vw" }}>

          <DataRepositoryTreeTable
            actions={props.actions}
            enableContextMenu={true}
            type="shared"
            dtoData={entityDataRepoShared}
            defaultFolderId={defaultFolderId}
            treeData={convertDataHelper.filterSharedRepository(
              convertDataHelper.parseEntityDataRepositoryToTree(
                entityDataRepoShared
              ),
              props.user.id
            )} //Hemos añadido filterSharedRepository para que no aparezcan las carpetas privadas del user
            iconTitle={faShareSquare}
            title={"Shared projects area"}
            titleStyle={{ color: "#45b6fe", margin: 0 }}
            selectedItem={selectedItemShared}
            setSelectedItem={setSelectedItemShared}
            setShowDialogs={{
              import: setShowImportDialog,
              export: setShowExportDialog,
              new: setShowNewSharedDialog,
              graph: setShowGraphDialog,
              delete: setShowDeleteSharedDialog,
            }}
            showDialogs={{ new: showNewSharedDialog, delete: showDeleteSharedDialog }}
            expandedKeys={expandedKeysShared}
            setExpandedKeys={setExpandedKeysShared}
            iconType1={props.iconType1}
            iconType2={props.iconType2}
            user={props.user}
            entityName={props.entityName}
          />
        </div>
      </div>
      <OptionGraph
        showDialog={showGraphDialog}
        setShowDialog={setShowGraphDialog}
        selectedItemShared={selectedItemShared}
        entityName={props.entityName}
      />
      {/*  Add new root folder */}
      <OptionNew
        actions={props.actions}
        showDialog={showNewDialogParent}
        setShowDialog={setShowNewDialogParent}
        //type="private"
        parent={true}
      />
    </div>
  );
}
