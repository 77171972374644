import { BlobServiceClient } from '@azure/storage-blob';


    export function uploadToBlobStorage(file,folder, storageAccountName,containerName,sasToken, request) {
       return uploadFileFromBrowser(file,folder, storageAccountName,containerName,sasToken);
  }

  export function downloadFileFromBlobStorage(file, storageAccountName,containerName,sasToken) {
    return downloadFile(file, storageAccountName,containerName,sasToken);
}


  


  async function uploadFileFromBrowser( file, folder, storageAccountName,containerName,sasToken){
    const STORAGE_ACCOUNT_NAME = storageAccountName
    const CONTAINER_NAME = containerName
    // for browser, SAS_TOKEN is get from API?
    const SAS_TOKEN = sasToken
    const sasURL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${SAS_TOKEN}`
  
    const blobServiceClient = new BlobServiceClient(sasURL)
    const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)
  
    var filename = folder ? folder+'/'+file.name.substring(0, file.name.lastIndexOf('.')) : file.name.substring(0, file.name.lastIndexOf('.')) 
    filename =filename.split(' ').join('_').replaceAll("(","").replaceAll(")","")
    const ext = file.name.substring(file.name.lastIndexOf('.'))
    const blobName = filename + ext
    const blockBlobClient = containerClient.getBlockBlobClient(blobName)
    await blockBlobClient.uploadBrowserData(file)
    return blobName
   
  }


  async function downloadFile( file, storageAccountName,containerName,sasToken){
    const STORAGE_ACCOUNT_NAME = storageAccountName
    const CONTAINER_NAME = containerName
    // for browser, SAS_TOKEN is get from API?
    const SAS_TOKEN = sasToken
    const sasURL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${SAS_TOKEN}`
  
    const blobServiceClient = new BlobServiceClient(sasURL)
    const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)
  
    

    
    const blobName = file
    const blockBlobClient = containerClient.getBlockBlobClient(blobName)
    return   blockBlobClient.download()
   
  }


  