import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core';

import DashboardChartComponent from '../../Common/Components/DashboardChartComponent';
import TableLlolipopCharts from '../../Common/Components/TableLollipopCharts'
import DonutChart from '../../Common/Charts/DonutChart';

import DQIDimensionComponent from '../../Common/Components/DQIDimensionComponent';
import ExecutionSelectorDropdown from '../../Common/Components/ExecutionSelectorDropdown';
import ChartTripleTemporal from '../../Common/Charts/ChartTripleTemporal';
import ExecutionDetail from '../../Common/Components/ExecutionDetail';
import AqtivaDialog from 'components/common/AqtivaDialog';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { LanguageContext } from 'language/LanguageContext';
import * as windowHelper from 'commonFunctions/windowHelper'

export default function ProjectGrid(props) {

    const languageContext = React.useContext(LanguageContext)
    //const [qpList, setQpList] = useState([])
    const [dimension, setDimension] = useState([])
    //const [project, setProject] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const { height, width } = windowHelper.useWindowDimensions();
    const [donutChart, setDonutChart] = React.useState(<div></div>)
    const [chartTriple, setChartTriple] = useState(<div></div>)
    const [chartTripleFullscreen, setChartTripleFullscreen] = useState(<div></div>)
    const [executionSelector, setExecutionSelector] = useState(<div></div>)
    const [executionDetailsChart, setExecutionDetailsChart] = useState(<div></div>)
    const [executionDetailsChartFullscreen, setExecutionDetailsChartFullscreen] = useState(<div></div>)
    const [tablesLollipop, setTablesLollipop] = useState(<div></div>)
    const [tablesLollipopFullscreen, setTablesLollipopFullscreen] = useState(<div></div>)
    const dispatch = useDispatch()


    useEffect(() => {


        if (props.project && props.qpExecutions && props.qpExecutions.length > 0) {

            //setProject(props.project)

            let dqi = Number((props.project.sumNumOk / props.project.sumNumIn) * 100).toFixed(2)

            setDonutChart(<DonutChart data={dqi} showCenterLabel={true} chart={'chartDonutDataSource'} openDimensionModal={openModal} click={true} />)

            setDimension(props.project.dimensionDQIList)

            let listOfQps = mapQualityPoints(props.project.qualityPointDQI)

            setTablesLollipop(<TableLlolipopCharts data={listOfQps} click={handleDrillDownElement} showIcons={true} />)
            setTablesLollipopFullscreen(<TableLlolipopCharts fullscreen={true} data={listOfQps} click={handleDrillDownElement} showIcons={true} />)

           // setQpList(listOfQps)

        }

        if (props.detailQPExecutions && props.detailQPExecutions) {
            setChartTriple(<ChartTripleTemporal data={props.detailQPExecutions} showBasicInformation={showBasicInformation} />)
            setChartTripleFullscreen(<ChartTripleTemporal fullscreen={true} data={props.detailQPExecutions} showBasicInformation={showBasicInformation} />)
            //setExecutionSelector(<ExecutionSelector executionList={props.detailQPExecutions} showBasicInformation={showBasicInformation}/>)
            setExecutionSelector(<ExecutionSelectorDropdown executionList={props.detailQPExecutions} showBasicInformation={showBasicInformation}/>)
        }

        //setProject(props.project)

    }, [props.project, props.qpExecutions, props.detailQPExecutions]) // eslint-disable-line react-hooks/exhaustive-deps


   /* useEffect(() => {
        if (props.project) {

            let listOfQps = props.project.qualityPointDQI

            setQpList(listOfQps)
        }

    }, [props.project])*/

    function handleDrillDownElement(e) {
        props.handleDrillDownElement(e)
    }

    /**
     * This function map qualityPoints list to format the object and get the data for the page components
     * 
     * @param  {List}  qualityPointList list of qualityPoints form database
     */
    function mapQualityPoints(qualityPointList) {
        return qualityPointList.map(qp => {

            return {
                ...qp,
                'id': qp.qualityPointId,
                'historyList': props.qpExecutions.filter(ex => ex.qpId === qp.qualityPointId)[0] ? props.qpExecutions.filter(ex => ex.qpId === qp.qualityPointId)[0].executionHistory : [],
                'score': Number(((qp.sumNumOk / (qp.sumNumOk + qp.sumNumKo)) * 100).toFixed(0)),
                'scoreKo': -Number(((qp.sumNumKo /  (qp.sumNumOk + qp.sumNumKo)) * 100).toFixed(0)),
                "fecha": qp.dayQpDQIList.length > 0 ? new Date(qp.dayQpDQIList[0].year, qp.dayQpDQIList[0].month, qp.dayQpDQIList[0].day).getTime() : new Date().getTime(),
            }
        })
    }

    const [openDimensionModal, setOpenDimensionModal] = React.useState(false)
    const handleClose = () => setOpenDimensionModal(false);

    function openModal(data) {
        setOpenDimensionModal(true)
    }

    

    async function showBasicInformation(e) {

        let executionDetail = await dispatch(actions.getDetailExecutions(e.executionId))
        if (executionDetail) {
            let qp = mapQualityPoints(props.project.qualityPointDQI).filter(qp => { return executionDetail.data[0].qualityPointId === qp.qualityPointId })
            let dataSourceId = executionDetail.data.filter(ex => ex.type === 'DATA_SOURCE')[0].datasourceId

            let dataSource = qp[0].datasourceDQI.filter(dt => dt.datasourceId === dataSourceId)[0]

            setExecutionDetailsChart(<ExecutionDetail project={props.project} execution={executionDetail.data} qualityPoint={qp[0]} dataSource={dataSource} />)
            setExecutionDetailsChartFullscreen(<ExecutionDetail fullscreen={true} project={props.project} execution={executionDetail.data} qualityPoint={qp[0]} dataSource={dataSource}/>)
            setShowDetails(true)
        }
    }





    return (
        <>


            <Grid item xs={4}>

                <DashboardChartComponent loading={props.loading} content={donutChart} title={'Project DQI'} description={languageContext.dictionary['globalDQIProject']} />

            </Grid >

            <Grid item xs={8}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={tablesLollipopFullscreen} content={tablesLollipop} title={'Trends'} description={languageContext.dictionary['qualityPointDQILollipop']} />

            </Grid >


            <Grid item xs={10}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={chartTripleFullscreen} content={chartTriple} title={'Project DQI Time Evolution'} showTooltip={true} description={languageContext.dictionary['qualityPointChartTriple']} />

            </Grid >

            <Grid item xs={2}>
                <DashboardChartComponent loading={false} content={executionSelector} showTooltip={false} title={'Execution Selector'} hideFullScreen={true} description={languageContext.dictionary['exectuionSelector']} />
            </Grid >

            <Grid item xs={12}>
                {showDetails && <DashboardChartComponent loading={props.loading} fullscreenContent={executionDetailsChartFullscreen} content={executionDetailsChart} title={'Details'} description={languageContext.dictionary['detaillExecution']} />}
            </Grid >



            <AqtivaDialog visible={openDimensionModal}
                title={`DQI Dimension`}

                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                cancelCallback={handleClose}
                maxWidth={width}
            >
                <div style={{ width: width * 0.9, height: height * 0.7 }}>
                    <DQIDimensionComponent DQIByDimension={dimension} />
                </div>

            </AqtivaDialog>
        </>
    )
}

