export function checkAllFields(element) {
    let errorsToCheck = {}
    errorsToCheck.hasErrors = false
    if (!element.storageName) {
        errorsToCheck.storageName = "Please introduce a Storage name."
        errorsToCheck.hasErrors = true
    }
    if (!element.storageType) {
        errorsToCheck.storageType = "Please select a Storage type."
        errorsToCheck.hasErrors = true
    }
    if (element.storageType === "azure-storage") {

        if (element.storageProperties && !element.storageProperties.resourceGroupName) {
            errorsToCheck.resourceGroupName = "Please introduce a resource group name."
            errorsToCheck.hasErrors = true
        }
        if (element.storageProperties && !element.storageProperties.storageAccountName) {
            errorsToCheck.storageAccountName = "Please introduce a storage account name."
            errorsToCheck.hasErrors = true
        }
        if (element.storageProperties && !element.storageProperties.containerName) {
            errorsToCheck.containerName = "Please introduce a container name."
            errorsToCheck.hasErrors = true
        }
        if (element.storageProperties && !element.storageProperties.storageKey) {
            errorsToCheck.storageKey = "Please introduce a storage key."
            errorsToCheck.hasErrors = true
        }
        if (element.storageProperties && !element.storageProperties.path) {
            errorsToCheck.path = "Please introduce a path."
            errorsToCheck.hasErrors = true
        }


    }
    if (element.storageType === "azure-sqldatabase") {
        if (element.sqlProperties && !element.sqlProperties.resourceGroupName) {
            errorsToCheck.resourceGroupName = "Please introduce a resource group name."
            errorsToCheck.hasErrors = true
        }
        if (element.sqlProperties && !element.sqlProperties.server) {
            errorsToCheck.server = "Please introduce a server name."
            errorsToCheck.hasErrors = true
        }
        if (element.sqlProperties && !element.sqlProperties.database) {
            errorsToCheck.database = "Please introduce a database name."
            errorsToCheck.hasErrors = true
        }
        if (element.sqlProperties && !element.sqlProperties.schema) {
            errorsToCheck.schema = "Please introduce a schema."
            errorsToCheck.hasErrors = true
        }
        if (element.sqlProperties && !element.sqlProperties.table) {
            errorsToCheck.table = "Please introduce a table name."
            errorsToCheck.hasErrors = true
        }
        if (element.sqlProperties && !element.sqlProperties.user) {
            errorsToCheck.user = "Please introduce a user name."
            errorsToCheck.hasErrors = true
        }
        if (element.sqlProperties && !element.sqlProperties.pwd) {
            errorsToCheck.password = "Please introduce a password."
            errorsToCheck.hasErrors = true
        }

    }

    if (element.storageType === "AWS") {
        if (element.awsProperties && !element.awsProperties.s3BucketName) {
            errorsToCheck.s3BucketName = "Please introduce a bucket name."
            errorsToCheck.hasErrors = true
        }
        if (element.awsProperties && !element.awsProperties.s3Region) {
            errorsToCheck.s3Region = "Please introduce a region."
            errorsToCheck.hasErrors = true
        }
        if (element.awsProperties && !element.awsProperties.s3KeySecret) {
            errorsToCheck.s3KeySecret = "Please introduce a Key Secret."
            errorsToCheck.hasErrors = true
        }
        if (element.awsProperties && !element.awsProperties.s3Keyid) {
            errorsToCheck.s3Keyid = "Please introduce a Key ID."
            errorsToCheck.hasErrors = true
        }
    }
    if (element.storageType === "bigQuery") {
        if (element.bigQueryProperties && !element.bigQueryProperties.projectId) {
            errorsToCheck.projectId = "Please introduce a project Id."
            errorsToCheck.hasErrors = true
        }
        if (element.bigQueryProperties && !element.bigQueryProperties.region) {
            errorsToCheck.region = "Please introduce a region."
            errorsToCheck.hasErrors = true
        }
        if (element.bigQueryProperties && !element.bigQueryProperties.bucket) {
            errorsToCheck.bucket = "Please introduce a bucket name."
            errorsToCheck.hasErrors = true
        }
        if (element.bigQueryProperties && !element.bigQueryProperties.credentialsFile) {
            errorsToCheck.credentialsFile = "Please introduce a credentials file."
            errorsToCheck.hasErrors = true
        }
    }

    return errorsToCheck
}