import React from 'react';
import {Grid,CircularProgress,Typography, List,ListItem} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import * as commonStyles from 'style/CommonStyles'



export default function RunningParseData(props){

    
    const iconSize = 15
    const margin='-3%'

    
 
    return(
        <div>
            <Grid container spacing={2}
                style={{marginTop:'5%', marginRight:'10%'}}>
                
                <Grid item xs={12}>
                <List>
                
                {props.parseMessages.length>1 && props.parseMessages.slice(0, -1).map(m => 
                    <ListItem style={{marginTop:margin, marginBottom:margin}}>
                        <div style={commonStyles.adjacentSpaceBetween}>
                            <FontAwesomeIcon icon={faCheckCircle} style={{marginTop:'2px',color:'#228247', fontSize:iconSize}} />
                            <Typography variant='caption'>
                                            {m}
                            </Typography>
                            
                        </div>
                    </ListItem>
                )}
                {props.parseMessages.length>1 && props.parseMessages.slice(props.parseMessages.length-1, props.parseMessages.length).map(m => <ListItem style={{marginTop:margin, marginBottom:margin}}>
                        <div style={commonStyles.adjacentSpaceBetween}>
                            <CircularProgress size={iconSize} />
                            <Typography variant='caption'>
                                            {m}
                            </Typography>
                            
                        </div>
                    </ListItem>)}

                    {props.parseMessages.length<2 && props.parseMessages.map(m => <ListItem style={{marginTop:margin, marginBottom:margin}}>
                        <div style={commonStyles.adjacentSpaceBetween}>
                            <CircularProgress size={iconSize} />
                            <Typography variant='caption'>
                                            {m}
                            </Typography>
                            
                        </div>
                        
                    </ListItem>)}
                </List>
                <div>
                    
                </div>
                           
                </Grid>  
                

            </Grid>
            
        </div>
    )

}