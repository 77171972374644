import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'
import { Drawer, FormControl, InputLabel, Select, Divider, Typography, Paper, Button, MenuItem, InputBase } from "@material-ui/core";
import { DateTimePicker } from "react-rainbow-components";
import FilterListIcon from "@material-ui/icons/FilterList";


const withFilterVW = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: 'center',
    },
    BackdropProps: {
        background: "transparent",

    },
    drawerPaper: {
        height: "auto",
        margin: "20px",
        marginTop: "100px",
        borderRadius: "10px",
        border: "0px",
        top: "auto",
        backgroundColor: "transparent",
        boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        overflow: "hidden"
    },
    content: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
}));

export default function FilterDataset(props) {
    const theme = useTheme();

    const classes = useStyles();

    const [open, setOpen] = React.useState(false)

    const [displayName, setDisplayName] = React.useState('')
    const [creationDate, setCreationDate] = React.useState(moment(new Date()).subtract(1, 'months').toDate())
    const [updateDate, setUpdateDate] = React.useState(moment(new Date()))
    const [activeCreationDate, setActiveCreationDate] = React.useState(false)
    const [activeUpdateDate, setActiveUpdateDate] = React.useState(false)
    const [source, setSource] = React.useState('')
    const [owner, setOwner] = React.useState('')

    const [ownersList, setOwnersList] = React.useState([])
    const [sourceList, setSourceList] = React.useState([])


    function createFilter() {

        let cDate = creationDate && activeCreationDate ? moment(creationDate) : undefined
        let uDate = updateDate && activeUpdateDate ? moment(updateDate) : undefined

        const filterObj = {
            name: displayName,
            creationDate: cDate ? cDate.add(1, 'days').toISOString() : '',
            updateDate: uDate ? uDate.add(1, 'days').toISOString() : '',
            source: source,
            owner: owner
        }

        if (props.setFilterObj) {
            props.setFilterObj(filterObj)
        }

    }

    React.useEffect(() => {
        if (props.sourceList) setSourceList(props.sourceList)
        if (props.ownersList) setOwnersList(props.ownersList)
    }, [props.sourceList, props.ownersList])// eslint-disable-line react-hooks/exhaustive-deps


    function clearFilters() {
        setDisplayName('')
        setCreationDate(moment(new Date()).subtract(1, 'months').toDate())
        setUpdateDate(moment(new Date()))
        setOwner('')
        setSource('')
        setActiveCreationDate(false)
        setActiveUpdateDate(false)

        if (props.setFilterObj) {
            props.setFilterObj(undefined)
        }
    }

    return (
        <>
            <div style={{ textAlign: "end" }}>
                <Button
                    aria-controls="simple-menu"
                    style={{ marginTop: props.up && props.up === true ? '-60px' : '0px' }}
                    startIcon={<FilterListIcon />}
                    aria-haspopup="true"
                    onClick={() => (open ? setOpen(false) : setOpen(true))}
                >
                    Filter
                </Button>
            </div>
            <div className={classes.root}>
                <main
                    className={classes.content}
                    style={open ? { marginRight: `${withFilterVW + 2}px` } : { marginRight: "0" }}
                >
                    {props.children}
                </main>
                <Drawer
                    ModalProps={{
                        BackdropProps: {
                            classes: {
                                root: classes.BackdropProps,
                            },
                        },
                    }}
                    variant="temporary"
                    disableScrollLock={true}
                    onClose={(event,reason) =>{if(reason === 'backdropClick'){setOpen(false)}
          if(reason === 'escapeKeyDown'){setOpen(false)}}}
                    className={classes.drawer}
                    anchor="right"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Divider />
                    <Paper style={{ width: `${withFilterVW}px`, height: `100%` }}>
                        <div style={{ ...theme.dashboard.filter.mainTitleDiv, textAlign: "center" }}>
                            <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                                Dataset filters
                            </Typography>
                        </div>

                        <div style={{
                            width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap',
                            justifyContent: 'flex-start', marginLeft: '5%', marginRight: '5%', marginTop: '2%'
                        }}>
                            <InputBase
                                placeholder="Name"
                                value={displayName}
                                style={{ maxWidth: "90%" }}
                                onChange={(event) => setDisplayName(event.target.value)}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'name' }}
                            />
                        </div>
                        <Divider />


                        <div style={{
                            width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap',
                            justifyContent: 'flex-start', marginLeft: '5%', marginRight: '5%', marginBottom: '2%'
                        }}>
                            <DateTimePicker
                                //disabled={}
                                value={creationDate}
                                onChange={(date) => { setActiveCreationDate(true); setCreationDate(date) }}
                                formatStyle="small"
                                style={{ maxWidth: "90%" }}
                                label={<Typography variant="caption"> Creation Date </Typography>}
                            />

                            <DateTimePicker
                                //disabled={}
                                value={updateDate}
                                onChange={(date) => {setActiveUpdateDate(true); setUpdateDate(date)}}
                                formatStyle="small"
                                style={{ maxWidth: "90%", }}
                                label={<Typography variant="caption"> Update Date </Typography>}
                            />

                        </div>
                        <Divider />

                        <div style={{
                            width: '90%', display: 'flex', flexWrap: 'wrap', marginTop: '1%',
                            justifyContent: 'flex-start', marginLeft: '5%', marginRight: '5%', marginBottom: '2%'
                        }}>

                            <FormControl style={{ width: '100%', }}>
                                <InputLabel > <Typography variant='caption'> Source Type</Typography> </InputLabel>
                                <Select
                                    value={source}
                                    onChange={event => setSource(event.target.value)}
                                    style={{ width: '100%', }}
                                    renderValue={(selected) => <Typography variant='caption'> {selected}</Typography>}
                                >

                                    {sourceList.length > 0 && sourceList.map((source, i) => {
                                        return (
                                            <MenuItem key={source} value={source}>
                                                <Typography variant='caption'>{`${source.charAt(0).toUpperCase() + source.slice(1)}`}</Typography>
                                            </MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormControl>


                            <FormControl style={{ width: '100%', }}>
                                <InputLabel >
                                    <Typography variant='caption'> Owner</Typography>
                                </InputLabel>
                                <Select
                                
                                    value={owner}
                                    onChange={event => setOwner(event.target.value)}
                                    style={{ width: '100%', }}
                                    renderValue={(selected) => <Typography variant='caption'> {selected.name}</Typography>}
                                >

                                    {ownersList.length > 0 && ownersList.map(owner => {
                                        return (
                                            <MenuItem key={owner.id} value={owner}>
                                                <Typography variant='caption'>{`${owner.name}`}</Typography>
                                            </MenuItem>

                                        )
                                    })

                                    }
                                </Select>
                            </FormControl>

                        </div>

                        <Divider />

                        <div style={{
                            width: '90%', display: 'flex', flexWrap: 'wrap',
                            justifyContent: 'center', margin: '5px', marginTop: '2%', marginBottom: '2%'
                        }}>
                            <Button variant='outlined' color='primary'
                                onClick={createFilter}
                            >Filter</Button>
                            <Button variant='outlined' color='primary' style={{ marginLeft: '5px' }}
                                onClick={clearFilters}
                            >Clear</Button>
                        </div>

                    </Paper>

                </Drawer>
            </div>
        </>
    );
}
