import React from 'react';
import AqtivaDialog from './../../../../components/common/AqtivaDialog'
import { faClipboardCheck, faTools } from '@fortawesome/free-solid-svg-icons'
import TableCheckDatabricksEnvironment from './TableCheckDatabricksEnvironment';
import { useInterval } from './../../../../components/common/useInterval'


export default function DialogCheckEnvironment(props) {


    //const [installing, setInstalling] = React.useState(false)

    
    //const [containers, setContainers] = React.useState(undefined)
    //const [containerFiles, setContainerFiles] = React.useState(undefined)
    //TODO: check states commented

    

    
    const data = ([
        {
            "status": undefined,
            "message": "Getting data..."
        }
    ])


    
    //TODO: CHECK
    //const [clusterState, setClusterState] = React.useState('')


    //TODO: INTRODUCIR LOS ESTADOS

    /*
        FUNCIONAMIENTO: 
            cuando se llama al boton de comprobar o comprobar y corregir:
            1) comprobamos si el cluster está activo: clusterState
                1.1) si el cluster no está levantado esperamos al cluster levantado
                1.2) si el cluster está levantado pasamos al punto 2
            2) se llama a check o checkand correct, flag checking a true y pedimos el estado de actualización
                2.1) si el estado de corrección no es finalizado seguimos pidiendo estado de actualización
                2.2) si el estado de corrección está finalizado finalizamos
    */

    React.useEffect(() => {
       // updateData()

    }, [props.environment])// eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {


        //updateData()

    }, 3000)


   /* async function download(blobServiceClient, containerName, blobName) {
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);

        // Get blob content from position 0 to the end
        // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
        const exists = await blobClient.exists();
        if (exists === true) {
            const downloadBlockBlobResponse = await blobClient.download();
            const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
            const data = JSON.parse(downloaded)
            return data
        }
        else {
            dispatchInfo(dispatch, "No data was found")
        }



        // [Browsers only] A helper method used to convert a browser Blob into string.
        async function blobToString(blob) {
            //var file = new File([blob], "file_name", { lastModified: 1534584790000 });
            const fileReader = new FileReader();
            return new Promise((resolve, reject) => {
                fileReader.onloadend = (ev) => {
                    resolve(ev.target.result);
                };
                fileReader.onerror = reject;
                fileReader.readAsText(blob);
            });
        }
    }
    */
   /* async function tryGetBlob(account, sas, blobName, containerName) {
        try {
            const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
            const data = await download(blobServiceClient, containerName, blobName)
            return data
        } catch (error) {
            dispatchError(dispatch, error)
        }
    }*/

    /*
    async function getConfigContainersAndFilesAndSetThem() {
        const account = 'aqtivamasterconfig';
                const sas = process.env.REACT_APP_ENV_PREPARTION_MASTER_SAS
                const containerName = 'configfiles'
        const blobName = "aqtivaContainersAndFiles.json"
        const response = await tryGetBlob(account, sas, blobName, containerName)
        return {containers: response.containers, files: response.files}
           
        
    }*/
/*
    async function getConfigFilesAndSetThem(account, sas, containerName, result) {
        let blobNameLibs
        if (result.spark_version.split('.')[0] > 6) {
            blobNameLibs = 'aqtivaLibsConfig.json'
        }
        else {
            blobNameLibs = 'aqtivaLibsConfig6andPrevious.json'
        }
        const blobNameFiles = 'aqtivaNotebookFilesConfig.json'
        const responseFiles = await tryGetBlob(account, sas, blobNameFiles, containerName)
           
       
        const responseLibs = await tryGetBlob(account, sas, blobNameLibs, containerName)
           
    
        let libsAndFilesDTO
        if (responseFiles.files && responseLibs.libs) {
            libsAndFilesDTO = {
                libs: responseLibs.libs,
                files: responseFiles.files
            }
        }
        return libsAndFilesDTO
    }
*/
    //STEPS:
    //  0: cluster not checked or not running
    //  1: cluster is running
    /*
    async function updateData() {

        if(runningGetData === true){
            return
        }
        setRunningGetData(true)
        var dataTmp = JSON.parse(JSON.stringify(data))
        var runningStep = checkStep
        //MODULE TO CHECK CLUSTER STATE

        var clusterStatusResult =undefined
        if(checkStep<1){
             clusterStatusResult = await dispatch(actions.getClusterStatus(props.environment, user.id))
            setStateClusterResult(clusterStatusResult)
            if (clusterStatusResult && clusterStatusResult.state && clusterStatusResult.state === 'RUNNING') {
                runningStep = 1
                setCheckStep(1)
                //TODO: CHECK
                //setClusterState(clusterStatusResult.state)

                const dataRunning = [
                    {
                        "status": true,
                        "message": "Cluster  running"
                    },
                    
                ]
                setData(dataRunning)
                setDataNoDatabricks(dataTmp)

                await dispatch(actions.setClusterPreparationStatus(props.environment, user.id))
                
            }
            else{
                dispatch(actions.startCluster(props.environment, user.id))
                
                const dataNotRunning = [
                    {
                        "status": false,
                        "message": "Cluster not running, in state " + clusterStatusResult.state
                    },
                    {
                        "status": undefined,
                        "message": "Request cluster starting.... wait for cluster to be up"
                    },
                ]
                setData(dataNotRunning)
                setDataNoDatabricks(dataTmp)
            }
        }
        if(runningStep ===1){
            
            const resultConfigContainers = await getConfigContainersAndFilesAndSetThem()
            const resContainers = resultConfigContainers.containers
            const resContainerFiles = resultConfigContainers.files

            if (resContainers && props.environmentConfiguration && props.environmentConfiguration.storageAccountName && props.environmentConfiguration.sasToken) {
                for (let i = 0; i < resContainers.length; i++) {
                   const result = await storageHelper.checkContainersAndUpdateCheckList(props.environmentConfiguration.storageAccountName, resContainers[i].name, props.environmentConfiguration.sasToken, undefined, dispatch, dataTmp)
                   dataTmp.push(result)
                   setData(dataTmp)
                   setDataNoDatabricks(dataTmp)
                    
                }
            }
            if (!resContainers) {
                const checkingContainers = [
                    {
                        "status": undefined,
                        "message": " Checking containers..."
                    }
                ]

                dataTmp.push(checkingContainers)
                setData(dataTmp)
                setDataNoDatabricks(dataTmp)
            }
            if (resContainerFiles && props.environmentConfiguration && props.environmentConfiguration.storageAccountName && props.environmentConfiguration.sasToken) {
                for (let i = 0; i < resContainerFiles.length; i++) {
                    const result = await storageHelper.checkContainersAndUpdateCheckList(props.environmentConfiguration.storageAccountName, resContainerFiles[i].container, props.environmentConfiguration.sasToken, resContainerFiles[i].path, dispatch, dataTmp)
                    dataTmp.push(result)
                    setData(dataTmp)
                    setDataNoDatabricks(dataTmp)

                }
            }
            if (!resContainerFiles) {
                const checkingFilesContainers = [
                    {
                        "status": undefined,
                        "message": " Checking files in containers..."
                    }
                ]

                dataTmp.push(checkingFilesContainers)
                setData(dataTmp)
                setDataNoDatabricks(dataTmp)

            }
           
            runningStep=2
            setCheckStep(runningStep)
        }
        if(runningStep ===2){
            //INSTALL (OPTIONAL)
            var result = stateClusterResult === undefined ? clusterStatusResult :stateClusterResult
            if (checking === false) {
                //setInstalling(true)
             

                const account = process.env.REACT_APP_ENV_PREPARATION_MASTER_ACCOUNT;
                const sas = process.env.REACT_APP_ENV_PREPARTION_MASTER_SAS
                const containerName = process.env.REACT_APP_ENV_PREPARATION_CONTAINER_NAME
                if (props.correct === true) {
                    getConfigFilesAndSetThem(account, sas, containerName, result)

                    let libsAndFilesDTO = await getConfigFilesAndSetThem(account, sas, containerName, result)
                    if (libsAndFilesDTO) {
                        dispatch(actions.checkAndCorrectClusterEnvironmentAllFromConfig(props.environment, user.id, libsAndFilesDTO))
                    }
                }
                else {
                    getConfigFilesAndSetThem(account, sas, containerName, result)

                    let libsAndFilesDTO = await getConfigFilesAndSetThem(account, sas, containerName, result)
                    if (libsAndFilesDTO) {
                         dispatch(actions.checkClusterEnvironmentAllFromConfig(props.environment, user.id, libsAndFilesDTO))
                    }
                }
                setChecking(true)
            }
            runningStep = 3
            setCheckStep(runningStep)
            
        }

        if(runningStep === 3){
            dispatch(actions.getClusterPreparationStatus(props.environment, user.id)).then(response => {
                if(response!==undefined && response.responses!==undefined){
                    setData((dataNoDatabricks).concat(response.responses))
                    runningStep = 4
                }
        })
    }
        
        
        setRunningGetData(false)

    }
    */
    /*

    async function updateDataOld() {

        var dataTmp = []
        if (props.environment) {
            getConfigContainersAndFilesAndSetThem()
            if (containers && props.environmentConfiguration && props.environmentConfiguration.storageAccountName && props.environmentConfiguration.sasToken) {
                for (let i = 0; i < containers.length; i++) {
                   const result = await storageHelper.checkContainersAndUpdateCheckList(props.environmentConfiguration.storageAccountName, containers[i].name, props.environmentConfiguration.sasToken, undefined, dispatch, dataTmp)
                   dataTmp.push(result)
                    
                }
            }
            if (!containers) {
                const checkingContainers = [
                    {
                        "status": undefined,
                        "message": " Checking containers..."
                    }
                ]

                dataTmp.push(checkingContainers)
            }
            if (containerFiles && props.environmentConfiguration && props.environmentConfiguration.storageAccountName && props.environmentConfiguration.sasToken) {
                for (let i = 0; i < containerFiles.length; i++) {
                    const result = storageHelper.checkContainersAndUpdateCheckList(props.environmentConfiguration.storageAccountName, containerFiles[i].container, props.environmentConfiguration.sasToken, containerFiles[i].path, dispatch, dataTmp)
                    dataTmp.push(result)
                    
                }
            }
            if (!containerFiles) {
                const checkingFilesContainers = [
                    {
                        "status": undefined,
                        "message": " Checking files in containers..."
                    }
                ]

                dataTmp.push(checkingFilesContainers)
            }
            dispatch(actions.getClusterStatus(props.environment, user.id)).then(result => {
                if (result && result.state) {

                    setClusterState(result.state)
                    if (result.state !== 'RUNNING') {


                        dispatch(actions.startCluster(props.environment, user.id))
                        const dataNotRunning = [
                            {
                                "status": false,
                                "message": "Cluster not running, in state " + result.state
                            },
                            {
                                "status": undefined,
                                "message": "Request cluster starting.... wait for cluster to be up"
                            },
                        ]
                        setData(dataNotRunning)
                    }
                    else {
                        if (checking === false) {
                            //setInstalling(true)
                            const account = process.env.REACT_APP_ENV_PREPARATION_MASTER_ACCOUNT;
                            const sas = process.env.REACT_APP_ENV_PREPARTION_MASTER_SAS
                            const containerName = process.env.REACT_APP_ENV_PREPARATION_CONTAINER_NAME
                            if (props.correct === true) {
                                getConfigFilesAndSetThem(account, sas, containerName, result)

                                let libsAndFilesDTO = getConfigFilesAndSetThem(account, sas, containerName, result)
                                if (libsAndFilesDTO) {
                                    dispatch(actions.checkAndCorrectClusterEnvironmentAllFromConfig(props.environment, user.id, libsAndFilesDTO))
                                }
                            }
                            else {
                                getConfigFilesAndSetThem(account, sas, containerName, result)

                                let libsAndFilesDTO = getConfigFilesAndSetThem(account, sas, containerName, result)
                                if (libsAndFilesDTO) {
                                    dispatch(actions.checkClusterEnvironmentAllFromConfig(props.environment, user.id, libsAndFilesDTO))
                                }
                            }
                            setChecking(true)
                        }
                        else {
                            dispatch(actions.getClusterPreparationStatus(props.environment, user.id)).then(response => {
                                if(response!==undefined && response.responses!==undefined){
                                    dataTmp = dataTmp.concat(response.responses)
                                }
                                
                               
                                if (response.state === 'FINISHED') {
                                    setChecking(false)
                                    //setInstalling(false)
                                }
                                else {
                                    const checkingLibraries = [
                                        {
                                            "status": undefined,
                                            "message": " Waiting for data for cluster checking and preparation..."
                                        }
                                    ]

                                    dataTmp = dataTmp.concat(checkingLibraries)

                                }

                                setData(dataTmp)
                            })
                        }
                    }
                }
            })
        }
    }
    */

    return (
        <AqtivaDialog visible={props.visible}
            maxWidth={'lg'}
            title={props.correct === true ? 'Check And Prepare Environment' : 'Check Environment'}
            titleIcon={props.correct === true ? faTools : faClipboardCheck}
            cancelText={'Close'}
            showConfirmButton={false}
            cancelCallback={event => { props.onClose(false) }}
        >

            <TableCheckDatabricksEnvironment data={data} />

        </AqtivaDialog>
    )
}