import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faTrash, faEdit, faList } from '@fortawesome/free-solid-svg-icons'
import { Button, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MaterialTable from "@material-table/core";
import DeleteJoinDialog from './DeleteJoinDialog'
import EditJoinDialog from './EditJoinDialog'
import CreateNewJoinDialog from './CreateNewJoinDialog'
import { Tooltip } from '@material-ui/core'

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
    },
    fabWarning: {
        margin: theme.spacing(1),
        backgroundColor: "yellow"

    }

}));



export default function EnrichDatasourceTable(props) {
    const classes = useStyles();

    const [data, setData] = useState([])
    const [selectedJoin, setSelectedJoin] = useState(undefined)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [enrichColumns, setEnrichColumns] = useState([])
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [updateCounter, setUpdateCounter] = useState(1)

    useEffect(() => {
        if (props.joins && props.joins.length > 0) {
            setData(props.joins)
            updateEnrichColumns(props.joins)
        }else{
            updateEnrichColumns([])
        }

    }, [props.joins, props.joins && props.joins.length, showConfirmDialog, showCreateDialog, showEditDialog])// eslint-disable-line react-hooks/exhaustive-deps


    function updateEnrichColumns(data) {

        if (data.length > 0) {

            let columns = []

            data.map(el => {

                columns = columns.concat(el.columns.map(c => {
                   if( !c.name.startsWith(el.alias + '_')) c.name = el.alias + '_' + c.name
                    return c
                }))
            })

            setEnrichColumns(columns)
        }else{
            setEnrichColumns([])
        }
    }

    function openDeleteDialog(join) {
        setSelectedJoin(join)
        setShowConfirmDialog(true)
    }
    function openEditDialog(join) {
        setSelectedJoin(join)
        setShowEditDialog(true)
    }



    function setDataAndPropagate(value) {
        setData(value)
        props.setJoins(value)
    }


    return (
        <div>
            <DeleteJoinDialog
                showConfirmDialog={showConfirmDialog}
                setShowConfirmDialog={setShowConfirmDialog}
                selectedJoin={selectedJoin}
                setSelectedJoin={setSelectedJoin}
                data={data}
                setData={setDataAndPropagate}
                updateCounter={updateCounter}
                setUpdateCounter={setUpdateCounter}
                configuration={props.configuration}
                datasource={props.datasource}
                dataformat={props.dataformat}
                qualityPoint={props.qualityPoint} project={props.project} element={props.element} rule={props.rule} />
            <EditJoinDialog data={data}
                setData={setDataAndPropagate}
                showEditDialog={showEditDialog}
                setShowEditDialog={setShowEditDialog}
                selectedJoin={selectedJoin}
                userId={props.userId}
                setSelectedJoin={setSelectedJoin}
                updateCounter={updateCounter}
                enrichColumns={enrichColumns}
                setUpdateCounter={setUpdateCounter}
                environmentOptions={props.environmentOptions}
                configuration={props.configuration}
                datasource={props.datasource} dataformat={props.dataformat} qualityPoint={props.qualityPoint} project={props.project}
                element={props.element} rule={props.rule} />
            <CreateNewJoinDialog showCreateDialog={showCreateDialog} setShowCreateDialog={setShowCreateDialog} userId={props.userId}
                updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} data={data} setData={setDataAndPropagate} enrichColumns={enrichColumns}
                configuration={props.configuration} datasource={props.datasource} dataformat={props.dataformat} />
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                < MaterialTable
                    style={{ width: "100%" }}
                    title="Joins"
                    data={data}
                    columns={[
                        { title: 'Dataset Enrich name', field: 'datasourceEnrichName' },
                        { title: 'Join type', field: 'joinType' },
                        {
                            title: 'Mappings list', field: 'mappingsList', render: (rowData) => {

                                return (
                                    <>
                                        {rowData.mappingsList && rowData.mappingsList.length > 0 && <>
                                            <Tooltip title={
                                                <React.Fragment>
                                                    {rowData.mappingsList && rowData.mappingsList.map(maping => {
                                                        return (
                                                            <h3 key={maping.originColumn.name + maping.destinationColumn.name}>{"column: " + maping.originColumn.name + " => column: " + maping.destinationColumn.name}</h3>
                                                        )
                                                    })}
                                                </React.Fragment>} >
                                                <span>
                                                    {rowData.mappingsList && rowData.mappingsList.length > 0 ? <FontAwesomeIcon icon={faList} style={{ color: "#2596be", fontSize: 'calc(14px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} /> : <FontAwesomeIcon icon={faCircle} style={{ color: "red", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />}
                                                </span>
                                            </Tooltip>
                                        </>}
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Actions', field: '', filtering: false, render: (rowData) => {
                                return (
                                    <>
                                        <Tooltip title={"Delete Join"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openDeleteDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                        {!(props.element === 'rule' && props.rule === undefined) &&
                                            <Tooltip title={"Edit Join"}>


                                                <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                    onClick={() => { openEditDialog(rowData) }}>
                                                    <FontAwesomeIcon icon={faEdit} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                                </Fab>


                                            </Tooltip>
                                        }
                                    </>
                                )
                            }
                        },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: false,
                        search: false,
                        pageSize: 5,
                        maxBodyHeight: '90%',
                        minBodyHeight: 250,
                        headerStyle: commonStyles.tableHeaderStyle,
                        cellStyle: commonStyles.tableCellStyle,
                        filterCellStyle: commonStyles.tableFilterCellStyle
                    }}

                    components={{
                        Toolbar: props => (
                            <div>

                            </div>
                        ),
                    }}
                />
                <div className={classes.buttonContainer}>
                    <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "1%" }}
                        color="primary"
                        onClick={event => {
                            setShowCreateDialog(true)
                        }}>
                        Add Join
                    </Button>

                </div>
            </div>
        </div>

    )
}
