import React from 'react';
import AqtivaDialog from '../AqtivaDialog'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import {
    makeStyles, TextField, FormControlLabel
} from '@material-ui/core';
import { styles, QualityTheme } from '../../../style/CustomTheme'
import classNames from 'classnames';
import { encryptString } from '../encryptHelper'
import { useDispatch } from 'react-redux';
import * as parserHelper from 'components/dialogs/DialogSelectAzureSource/common/LocalConnectionDialog/Helpers/ParserHelper'


export default function AqtivaDialogParseAndEncryptString(props) {
    const classes = makeStyles(styles(QualityTheme));

    const [value, setValue] = React.useState('')

    const dispatch = useDispatch();

    async function confirm() {
        if (props.connectionMethod && props.connectionMethod === "connectionString") {
            if (props.resourceType === "azure_storage") {

                let connectionObject = parserHelper.parseConnectionString(value)
                let encryptedSas = await encryptString(props.sName, dispatch, connectionObject.sharedAccessSignature)
                connectionObject.sharedAccessSignature = encryptedSas
                props.confirm(connectionObject)
                props.setVisible(false)
                return
            }
            if (props.resourceType === "azure_container"){
                let connectionObject = parserHelper.parseContainerSasUrl(value)
                let encryptedSas = await encryptString(props.sName, dispatch, connectionObject.sharedAccessSignature)
                connectionObject.sharedAccessSignature = encryptedSas
                props.confirm(connectionObject)
                props.setVisible(false)
                return
            }
        }
        props.confirm(await encryptString(props.sName, dispatch, value))
        props.setVisible(false)
    }
    return (
        <AqtivaDialog visible={props.visible}
            maxWidth={'md'}
            title={'Encrypt Value'}
            titleIcon={faKey}
            confirmText={'Encrypt and Save'}
            cancelText={'Close'}
            showConfirmButton={props.sName ? true : false}
            confirmCallback={confirm}
            cancelCallback={event => { props.setVisible(false) }}
        >
            <FormControlLabel style={{ marginLeft: '5%', width: '300px' }}
                control={
                    <TextField style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                        id="databricksToken"
                        type="password"
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        value={value}
                        onChange={(event) => { setValue((event.target.value)) }}
                    />} />
        </AqtivaDialog>
    )

}