import React from 'react';
import * as textHelper from 'components/common/Texts'

import { Grid, Paper,  Modal, Box } from '@material-ui/core'
import DiagramTitle from './components/DiagramTitle';
import DataformatDQIBar from './components/DataformatDQIBar'
import DataformatDimensionDQIBar from './components/DataformatDimensionDQIBar'
import DataformatDQIHistogram from './components/DataformatDQIHistogram'

import DataformatPaperResume from './components/DataformatPaperResume'

export default function DQIDataformatComplete(props) {

   

    //filtros de pagina
    const [filterLocal, setFilterLocal] = React.useState(false)
    const [filterStorage, setFilterStorage] = React.useState(false)
    const [filterSqlDatabase, setFilterSqlDatabase] = React.useState(false)
    const [filterSynapse, setFilterSynapse] = React.useState(false)

    const [filterDF, setFilterDF] = React.useState(undefined)
    //TODO: AVOID WARNINGS
    if(filterDF){}
    
    //const [rawDataFiltered, setRawDataFiltered] = React.useState([])
    //const [rawDataQPFiltered, setRawDataQPFiltered] = React.useState([])

    //const [ruleDefinitionByRuleType, setRuleDefinitionByRuleType] = React.useState([])
    //const [ruleExecutionByRuleType, setRuleExecutionByRuleType] = React.useState([])

    const [dqiBucket, setDqiBucket] = React.useState(undefined)

    const [ openModal, setOpenModal] = React.useState(false)
    const [modalComponent, setModalComponent] =React.useState('')
    function handleCloseModal(){
        setOpenModal(false)
    }

    function callbackOpenModal(value){
        setOpenModal(true)
        setModalComponent(value)
    }
    /*
    function filterRawData(data) {
        if (data && props.projects && props.projects.length > 0) {
            var dataFilteredDF = data
            if (filterDF !== undefined) {
                dataFilteredDF = data.filter(x => x.dataformatId === filterDF.dataformat._id)
            }
            return dataFilteredDF
        }
        return []

    }*/

    function filterDQIDataformatAndDimensionBySource(d) {
        if (filterLocal === true || filterStorage === true || filterSqlDatabase === true || filterSynapse === true) {
            const dataStorage = d.filter(x => x.dataformatType === 'azure-storage')
            const dataSql = d.filter(x => x.dataformatType === 'azure-sqldatabase')
            const dataSynapse = d.filter(x => x.dataformatType === 'azure-sqlsynapse')
            const dataLocal = d.filter(x => x.dataformatType !== 'azure-storage' && x.dataformatType !== 'azure-sqldatabase' && x.dataformatType !== 'azure-sqlsynapse')

            var dataFiltered = []
            if (filterLocal === true) {
                dataFiltered = dataFiltered.concat(dataLocal)
            }
            if (filterSynapse === true) {
                dataFiltered = dataFiltered.concat(dataSynapse)
            }
            if (filterSqlDatabase === true) {
                dataFiltered = dataFiltered.concat(dataSql)
            }
            if (filterStorage === true) {
                dataFiltered = dataFiltered.concat(dataStorage)
            }

            return dataFiltered

        }
        return d
    }

    function filterDQIDataformatBySource(data) {

        if (data) {



            var d = data.map(x => {

                return {
                    subject: x.dataformatName,
                    DQI: parseFloat(x.sumNumOk) / (parseFloat(x.sumNumIn)) * 100,
                    numIn: x.sumNumIn,
                    numOk: x.sumNumOk,
                    dataformatId: x.dataformatId,
                    dataformatType: x.dataformatType
                }
            })



            if (dqiBucket !== undefined) {
                const categories = dqiBucket.split("-")
                const minCat = categories[0]
                const maxCat = categories[1]
                d = d.filter(x => x.DQI > minCat && x.DQI <= maxCat)
            }


            if (filterLocal === true || filterStorage === true || filterSqlDatabase === true || filterSynapse === true) {


                const dataStorage = d.filter(x => x.dataformatType === 'azure-storage')
                const dataSql = d.filter(x => x.dataformatType === 'azure-sqldatabase')
                const dataSynapse = d.filter(x => x.dataformatType === 'azure-sqlsynapse')
                const dataLocal = d.filter(x => x.dataformatType !== 'azure-storage' && x.dataformatType !== 'azure-sqldatabase' && x.dataformatType !== 'azure-sqlsynapse'
                )
                var dataFiltered = []
                if (filterLocal === true) {
                    dataFiltered = dataFiltered.concat(dataLocal)
                }
                if (filterSynapse === true) {
                    dataFiltered = dataFiltered.concat(dataSynapse)
                }
                if (filterSqlDatabase === true) {
                    dataFiltered = dataFiltered.concat(dataSql)
                }
                if (filterStorage === true) {
                    dataFiltered = dataFiltered.concat(dataStorage)
                }
                const sortDataArray = dataFiltered.sort(compareDataformatByDQI)
                return sortDataArray
            }
            return d



        }

        return data
    }

    function compareDataformatByDQI(a, b) {
        if (a.DQI < b.DQI) {
            return -1;
        }
        if (a.DQI > b.DQI) {
            return 1;
        }
        return 0;
    }

    function getModalContent(){
        if(modalComponent ==='DataformatDQIBar'){
            return (
            <div style={{ width: '100%', height: '750px' }}  fullscreenAllowed = {false}>
                 <DiagramTitle title='DQI Per Dataformat' />
                            <DataformatDQIBar data={filterDQIDataformatBySource(props.DQI4Dataformat)} tooltip={textHelper.dataformatsDQIText}
                                setSelectedDF={setFilterDF}
                                fullscreen = {true} 
                            />

            </div>
            )
        }

        else if(modalComponent ==='DataformatDQIHistogram'){
            return (
            <div style={{ width: '100%', height: '750px' }}  fullscreenAllowed = {false}>
                <DiagramTitle title='Dataformat Histogram per DQI ranges' />
                            <DataformatDQIHistogram data={filterDQIDataformatBySource(props.DQI4Dataformat)} tooltip={textHelper.dataformatsDQIText}
                                setCategoryBucket={setDqiBucket}
                                fullscreen = {true} 
                            />

            </div>
            )
        }

        else if(modalComponent ==='DataformatDimensionDQIBar'){
            return (
            <div style={{ width: '100%', height: '750px' }}  fullscreenAllowed = {false}>
                <DiagramTitle title='DQI Per Dataformat And Dimension' />
                    <DataformatDimensionDQIBar data={filterDQIDataformatAndDimensionBySource(props.DQI4DataformatAndDimension)} tooltip={textHelper.dataformatsDQIText}
                        setSelectedDF={setFilterDF}
                        fullscreen = {true} 
                    />

            </div>
            )
        }
        
        else{
           return ( <div>
                Component Not Found
            </div>)
        }
    }

    /*React.useEffect(() => {
        setRuleDefinitionByRuleType(helper.getRuleDefinitionsByRuleType(
            rawDataFiltered, props.projects, []))
        setRuleExecutionByRuleType(helper.getRuleExecutionsByRuleType(
            rawDataFiltered, props.associateRuleTypeId))
    }, [rawDataFiltered])

    React.useEffect(() => {
        setRawDataFiltered(filterRawData(props.rawRuleData))
       setRawDataQPFiltered(filterRawData(props.rawQPData))

    }, [props.DQIDataformat, filterDF, filterLocal, filterSqlDatabase, filterStorage, filterSynapse])// eslint-disable-line react-hooks/exhaustive-deps
    */
    return (
        <div>

        <Grid container spacing={1} style={{ width: '99.5%', marginLeft: '0.5%' }}>

            <Grid item xs={12}>
                <DataformatPaperResume data={props.DQI4Dataformat} projects={props.projects} type='complete'
                    filterLocal={filterLocal} setFilterLocal={setFilterLocal}
                    filterStorage={filterStorage} setFilterStorage={setFilterStorage}
                    filterSqlDatabase={filterSqlDatabase} setFilterSqlDatabase={setFilterSqlDatabase}
                    filterSynapse={filterSynapse} setFilterSynapse={setFilterSynapse}

                />
            </Grid>

            <Grid item xs={8}>
                <Grid container spacing={1} >

                    <Grid item xs={6}>
                        <Paper style={{ width: '100%', height: '345px', }} >

                            <DiagramTitle title='DQI Per Dataformat' fullscreenAllowed={true} onClickModal={event=>callbackOpenModal('DataformatDQIBar')}/>
                            <DataformatDQIBar data={filterDQIDataformatBySource(props.DQI4Dataformat)} tooltip={textHelper.dataformatsDQIText}
                            loading ={props.dQI4DataformatsFlag}
                                setSelectedDF={setFilterDF}
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper style={{ width: '100%', height: '345px' }} >

                            <DiagramTitle title='Dataformat Histogram per DQI ranges' fullscreenAllowed={true} onClickModal={event=>callbackOpenModal('DataformatDQIHistogram')}/>
                            <DataformatDQIHistogram data={filterDQIDataformatBySource(props.DQI4Dataformat)} tooltip={textHelper.dataformatsDQIText}
                                setCategoryBucket={setDqiBucket}
                                loading ={props.dQI4DataformatsFlag}
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper style={{ width: '100%', height: '345px' }} >

                            <DiagramTitle title='pending' />
                           
                        </Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper style={{ width: '100%', height: '345px' }} >

                            <DiagramTitle title='pending' /> 
                            
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Paper style={{ width: '100%', height: '700px', marginBottom: '0px' }} >

                    <DiagramTitle title='DQI Per Dataformat And Dimension' fullscreenAllowed={true} onClickModal={event=>callbackOpenModal('DataformatDimensionDQIBar')}/>
                    <DataformatDimensionDQIBar data={filterDQIDataformatAndDimensionBySource(props.DQI4DataformatAndDimension)} tooltip={textHelper.dataformatsDQIText}
                        setSelectedDF={setFilterDF}
                        loading ={props.dQI4DataformatsAndDimensionsFlag}
                    />
                </Paper>
            </Grid>


        </Grid>


<Modal
open={openModal}
onClose={handleCloseModal}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Box sx={{ position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  height: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,}}>
      
          {getModalContent()}
    </Box>
    </Modal>
    </div>




    )
}