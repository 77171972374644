import React, { useEffect, useState } from 'react';
import { Grid, Typography, AccordionSummary, AccordionDetails, Accordion, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import SchedulesTable from './SchedulesTable'
import CreateScheduleDialog from './CreateScheduleDialog'
import * as actions from '../../../actions/index'

import * as commonStyles from 'style/CommonStyles'

export default function SchedulingConfigurationQuality(props) {
    const dispatch = useDispatch();
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))

    const [existingSchedules, setExistingSchedules] = useState(undefined)
    const [environmentOptions, setEnvironmentOptions] = useState([])
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [updateCounter, setUpdateCounter] = useState(0)



    useEffect(() => {
       
        dispatch(actions.getSchedulesFromDatasource(props.datasource && props.datasource._id, props.userId)).then(response => {
            setExistingSchedules(response)
            if (response && response.length > 0) {
                let usedEnvironments = response.map(schedule => schedule.environment)
                let filteredAllowedEnvironments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, props.userId, usedEnvironments)
                setEnvironmentOptions(qualityConfiguration.environmentConfigurationList)
            }
            else {
                let filteredAllowedEnvironments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, props.userId, [])
                setEnvironmentOptions(qualityConfiguration.environmentConfigurationList)
            }
        })
    }, [updateCounter])// eslint-disable-line react-hooks/exhaustive-deps

    function isValidUser(users, userId) {
        if (users && users.length > 0) {
            if (users.filter(x => x.id === userId) && users.filter(x => x.id === userId).length > 0) {
                return true
            }
        }
        return false
    }

    function filterAllowedEnvironments(environments, userId, usedEnvironments) {
        if (environments && environments.length > 0) {
            let environmentsAvailable = environments.filter(env => !usedEnvironments.includes(env.environmentName))
            return environmentsAvailable.filter(x => !x.accessType || x.accessType === 'public_access' || (x.accessType === 'protected_access' && isValidUser(x.allowedUsers, userId)))
        }
        return environments
    }

    return (
        <>

            <CreateScheduleDialog showCreateDialog={showCreateDialog} setShowCreateDialog={setShowCreateDialog} userId={props.userId} environmentOptions={environmentOptions} datasource={props.datasource} project={props.project} selectedQualityPoint={props.selectedQualityPoint} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} />
            <Accordion
                style={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid item xs={1}>
                        <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='subtitle2'>Schedule Configuration</Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: "column", justifyContent: "space-around" }}>
                    {existingSchedules && existingSchedules.length > 0 && <SchedulesTable updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} userId={props.userId} existingSchedules={existingSchedules} environmentOptions={environmentOptions} datasource={props.datasource} project={props.project} selectedQualityPoint={props.selectedQualityPoint}/>}
                    {environmentOptions && environmentOptions.length > 0 &&
                        < Button onClick={event => { setShowCreateDialog(true) }} style={{ backgroundColor: commonStyles.mainColor, color: "white", marginTop: "5px", display: "flex", textAlign: "center", justifyContent: "flex-start", width: "20%" }}>Add New Schedulling</Button>
                    }
                </AccordionDetails>
            </Accordion >
        </>
    )
}