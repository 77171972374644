import React, { useState, useEffect } from 'react';

import { Typography, Card, CardContent, FormControl, Checkbox, Grid, } from '@material-ui/core'


import ContactsSelector from './../../common/SchedulingConfigurationQuality/ContactsSelector'
import * as actions from 'actions'
import { useSelector, useDispatch } from 'react-redux'


export default function EmailConfigCard(props) {
    const dispatch = useDispatch();


    var license = useSelector(store => store.license)

   /* const recurrenceTypes = [
        { label: 'hour', value: 'hour' },
        { label: 'day', value: 'day' },
        { label: 'week', value: 'week' },
    ]*/

    const [notifyDetailExecution, setNotifyDetailExecution] = useState(false)
    const [notifyPeriodicallyDetailExecution, setNotifyPeriodicallyDetailExecution] = useState(false)
    const [recurrenceTypeDetailExecution, setRecurrenceTypeDetailExecution] = useState("")
    const [notifyOnExecutionDetailExecution, setNotifyOnExecutionDetailExecution] = useState(false)

    const [notifyErrors, setNotifyErrors] = useState(false)
    const [notifyPeriodicallyErrors, setNotifyPeriodicallyErrors] = useState(false)
    const [recurrenceTypeErrors, setRecurrenceTypeErrors] = useState("")
    const [notifyOnExecutionErrors, setNotifyOnExecutionErrors] = useState(false)


    //const [receiversError, setReceiversError] = useState([])
    //const [receiversDetailExecution, setReceiversDetailExecution] = useState([])
    const [selectedReceiversError, setSelectedReceiversError] = useState([])
    const [selectedReceiversDetail, setSelectedReceiversDetail] = useState([])

    //const [inputText1, setInputText1] = useState("")
    //const [errorInputText1, setErrorInputText1] = useState("")
    //const [errorInputText2, setErrorInputText2] = useState("")
    //const [inputText2, setInputText2] = useState("")

    useEffect(() => {
        const users = license.users
        let mails = []
        for (let i = 0; i < users.length; i++) {
            mails.push(users[i].email)
        }
        //setReceiversError(mails)
        //setReceiversDetailExecution(mails)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.qualityPoint && props.qualityPoint.qualityPointNotificationConfiguration) {
            setNotifyDetailExecution(props.qualityPoint.qualityPointNotificationConfiguration.notifyDetailExecution)
            setNotifyPeriodicallyDetailExecution(props.qualityPoint.qualityPointNotificationConfiguration.notifyPeriodicallyDetailExecution)
            setRecurrenceTypeDetailExecution(props.qualityPoint.qualityPointNotificationConfiguration.recurrenceTypeDetailExecution)
            setNotifyOnExecutionDetailExecution(props.qualityPoint.qualityPointNotificationConfiguration.notifyDetailExecution)
            setSelectedReceiversDetail(props.qualityPoint.qualityPointNotificationConfiguration.selectedReceiversDetail)
            setNotifyErrors(props.qualityPoint.qualityPointNotificationConfiguration.notifyErrors)
            setNotifyPeriodicallyErrors(props.qualityPoint.qualityPointNotificationConfiguration.notifyPeriodicallyErrors)
            setRecurrenceTypeErrors(props.qualityPoint.qualityPointNotificationConfiguration.recurrenceTypeErrors)
            setNotifyOnExecutionErrors(props.qualityPoint.qualityPointNotificationConfiguration.notifyErrors)
            setSelectedReceiversError(props.qualityPoint.qualityPointNotificationConfiguration.selectedReceiversError)
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        updateNotificationConfig()
    }, [notifyDetailExecution, notifyPeriodicallyDetailExecution, recurrenceTypeDetailExecution,// eslint-disable-line react-hooks/exhaustive-deps
         notifyOnExecutionDetailExecution, selectedReceiversDetail, notifyErrors, notifyPeriodicallyErrors,// eslint-disable-line react-hooks/exhaustive-deps
          recurrenceTypeErrors, notifyOnExecutionErrors, selectedReceiversError]); // eslint-disable-line react-hooks/exhaustive-deps

    async function updateNotificationConfig () {

        const emailUrl = await dispatch(actions.getControlValue("EMAIL_URL"))
        const emailErrorUrl = await dispatch(actions.getControlValue("EMAIL_URL_ERROR"))

        if(emailUrl && emailErrorUrl){
            const notificationConfiguration = {
                notifyDetailExecution: notifyDetailExecution,
                notifyPeriodicallyDetailExecution: notifyPeriodicallyDetailExecution,
                recurrenceTypeDetailExecution: recurrenceTypeDetailExecution,
                notifyOnExecutionDetailExecution: notifyDetailExecution,
                selectedReceiversDetail: selectedReceiversDetail,
    
    
                notifyErrors: notifyErrors,
                notifyPeriodicallyErrors: notifyPeriodicallyErrors,
                recurrenceTypeErrors: recurrenceTypeErrors,
                notifyOnExecutionErrors: notifyErrors,
                selectedReceiversError: selectedReceiversError,
    
                mailEndPointExecutionDetail: emailUrl,
                mailEndPointExecutionError:emailErrorUrl,
                mailEndPointRecurrentMail: emailUrl,
            }
            props.setNotificationConfiguration(notificationConfiguration)
        }
       
    }
    const handleChangeNotifyErrors = (event) => {
        setNotifyErrors(event.target.checked);
    };
    const handleChangeNotifyDetailExecution = (event) => {
        setNotifyDetailExecution(event.target.checked);
    };
   /* const handleChangeNotifyPeriodicallyError = (event) => {
        setNotifyPeriodicallyErrors(event.target.checked);
        setRecurrenceTypeErrors(event.target.checked ? recurrenceTypes[0].value : "")
    };
    const handleChangeNotifyOnExecutionError = (event) => {
        setNotifyOnExecutionErrors(event.target.checked);
    };
    const handleTypeChangeError = (event) => {
        setRecurrenceTypeErrors(event.target.value);
    };


    
    
    const handleChangeNotifyPeriodicallyDetailExecution = (event) => {
        setNotifyPeriodicallyDetailExecution(event.target.checked);

        setRecurrenceTypeDetailExecution(event.target.checked ? recurrenceTypes[0].value : "")

    };
    const handleChangeNotifyOnExecutionDetailExecution = (event) => {
        setNotifyOnExecutionDetailExecution(event.target.checked);
    };
    const handleTypeChangeDetailExecution = (event) => {
        setRecurrenceTypeDetailExecution(event.target.value);
    };
    const handleInsertNewMailError = (event, value) => {
        let newSelectedReceivers = selectedReceiversError
        let newMail = inputText1
        let mailCorrecto = false
        mailCorrecto = validateInputs(newMail)
        let present = false

        for (let i = 0; i < newSelectedReceivers.length; i++) {
            if (newSelectedReceivers[i] === newMail) {
                present = true
            }
        }
        if (!mailCorrecto) {
            setErrorInputText1("You must enter a valid email address")
        }
        if (!present && mailCorrecto) {
            newSelectedReceivers.push(newMail)
            setSelectedReceiversError(newSelectedReceivers)
            setInputText1("")
        }
        if (present) {
            setErrorInputText1("This email address is already on the list")
        }
    }
    const handleInsertNewMailDetailExecution = () => {
        let newSelectedReceivers = selectedReceiversDetail
        let newMail = inputText2
        let mailCorrecto = false
        mailCorrecto = validateInputs(newMail)
        let present = false
        for (let i = 0; i < newSelectedReceivers.length; i++) {
            if (newSelectedReceivers[i] === newMail) {
                present = true
            }
        }

        if (!mailCorrecto) {
            setErrorInputText2("You must enter a valid email address")
        }
        if (!present && mailCorrecto) {
            newSelectedReceivers.push(newMail)
            setSelectedReceiversDetail(newSelectedReceivers)
            setInputText2("")
        }
        if (present) {
            setErrorInputText2("This email address is already on the list")
        }
    }

    function validateInputs(mail) {
        const validation = {
            name: {
                rule: /^[^\s@]+@[^\s@]+$/.test(mail),
            }
        }
        return validation.name.rule
    }



    const handleChangeInputText1 = (event, value) => {
        setInputText1(event.target.value)
        setErrorInputText1("")
    }
    const handleChangeInputText2 = (event, value) => {
        setInputText2(event.target.value)
        setErrorInputText2("")
    }
*/



    return (
        <>
            <Card elevation={12}
                style={{ minHeight: '265px' }}>
                <CardContent>
                    <Typography variant='subtitle2'> {props.title}</Typography>
                </CardContent>


                <CardContent style={{ marginRight: '5%' }}>
                    <FormControl style={{ display: "flex", flexDirection: "row" }}>
                        <Checkbox
                            checked={notifyErrors}
                            onChange={handleChangeNotifyErrors}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            color="primary"
                        />
                        <Typography variant='caption' style={{ marginTop: "0.7rem" }} >Enable Error notifications</Typography>
                    </FormControl>
                </CardContent>
                
                {notifyErrors && <Grid item container spacing={3} style={{ marginLeft: '5%' }} >
                    <Grid item xs={10}>
                    <ContactsSelector mailsToNotify={selectedReceiversError} setMailsToNotify={setSelectedReceiversError} />
                                     </Grid>
                </Grid>}


                <CardContent style={{ marginRight: '5%' }}>
                    <FormControl style={{ display: "flex", flexDirection: "row" }}>
                        <Checkbox
                            checked={notifyDetailExecution}
                            onChange={handleChangeNotifyDetailExecution}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            color="primary"
                        />
                        <Typography variant='caption' style={{ marginTop: "0.7rem" }} >Enable Execution Detail Notifications</Typography>
                    </FormControl>
                </CardContent>

                {notifyDetailExecution && <Grid item container spacing={3} style={{ marginLeft: '5%' }} >
                    <Grid item xs={10}>
                    <ContactsSelector mailsToNotify={selectedReceiversDetail} setMailsToNotify={setSelectedReceiversDetail} />
                       </Grid>
                </Grid>}
            </Card>
        </>
    )
}

/*<Grid xs={6}>
                        <CardContent style={{ marginRight: '5%' }}>
                             <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                <Checkbox
                                    checked={notifyPeriodicallyErrors}
                                    onChange={handleChangeNotifyPeriodicallyError}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    color="primary"
                                    style={{ marginLeft: "30px" }}
                                />
                                <Typography variant='standard' style={{ marginTop: "0.7rem" }} >Enable Periodic Notifications</Typography>
                            </FormControl>
                            {notifyPeriodicallyErrors && <>
                                <Typography variant='standard' style={{ marginTop: "0.7rem", marginLeft: "3rem" }} >Recurrence:</Typography>
                                <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                    <Typography variant='standard' style={{ marginTop: "0.7rem", marginLeft: "3rem" }} >Every </Typography>
                                    <NativeSelect
                                        style={{
                                            width: '50%',
                                            fontSize: 14,
                                            marginLeft: '3rem',
                                            marginTop: '0.5rem'
                                        }}
                                        value={recurrenceTypeErrors}
                                        onChange={event => handleTypeChangeError(event)}
                                    >
                                        {recurrenceTypes.map((event) => (<option key={event.value} value={event.value}>
                                            {event.label}
                                        </option>))}
                                    </NativeSelect>
                                </FormControl>
                            </>}
 */
/* <FormControl style={{ display: "flex", flexDirection: "row" }} >
                                <Checkbox
                                    checked={notifyOnExecutionErrors}
                                    onChange={handleChangeNotifyOnExecutionError}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    color="primary"
                                    style={{ marginLeft: "30px" }}
                                />
                                <Typography variant='standard' style={{ marginTop: "0.7rem" }} >Enable On Execution Notifications</Typography>
                            </FormControl> 
                        </CardContent>
                    </Grid>*/



/* <Grid xs={6}>
                        <CardContent style={{ marginRight: '5%' }}>
                             <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                <Checkbox
                                    checked={notifyPeriodicallyDetailExecution}
                                    onChange={handleChangeNotifyPeriodicallyDetailExecution}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    color="primary"
                                    style={{ marginLeft: "30px" }}
                                />
                                <Typography variant='standard' style={{ marginTop: "0.7rem" }} >Enable Periodic Notifications</Typography>
                            </FormControl>
                            {notifyPeriodicallyDetailExecution && <>
                                <Typography variant='standard' style={{ marginTop: "0.7rem", marginLeft: "3rem" }} >Recurrence:</Typography>
                                <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                    <Typography variant='standard' style={{ marginTop: "0.7rem", marginLeft: "3rem" }} >Every </Typography>
                                    <NativeSelect
                                        style={{
                                            width: '50%',
                                            fontSize: 14,
                                            marginLeft: '3rem',
                                            marginTop: '0.5rem'
                                        }}
                                        value={recurrenceTypeDetailExecution}
                                        onChange={event => handleTypeChangeDetailExecution(event)}
                                    >
                                        {recurrenceTypes.map((event) => (<option key={event.value} value={event.value}>
                                            {event.label}
                                        </option>))}
                                    </NativeSelect>
                                </FormControl>
                            </>} 

                             <FormControl style={{ display: "flex", flexDirection: "row" }} >
                                <Checkbox
                                    checked={notifyOnExecutionDetailExecution}
                                    onChange={handleChangeNotifyOnExecutionDetailExecution}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    color="primary"
                                    style={{ marginLeft: "30px" }}
                                />
                                <Typography variant='standard' style={{ marginTop: "0.7rem" }} >Enable On Execution Notifications</Typography>
                            </FormControl> 
                        </CardContent>
                    </Grid>  */