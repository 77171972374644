import React from 'react';
import {Typography,TextField} from '@material-ui/core';

import {getDataFromProperties} from './SpecificRuleHelper'
import { IColumn, ICommonRule } from 'interfaces/RuleInterfaces';



interface FormRuleDuplicatedProps {
    commonRule: ICommonRule,
    setSpecific: (value: any)=>any,
    editableRule: any,
    specific: any,
    source: IColumn [],
  
  }

export default function FormRuleDuplicated(props: FormRuleDuplicatedProps){

    //const classes = makeStyles(styles(QualityTheme));
    const [maxDuplicatedCount, setMaxDuplicatedCount] = React.useState(parseInt(getDataFromProperties(props.editableRule, props.specific,'maxDuplicatedCount',1))+1 )

    React.useEffect(() => {
        createSpecific()
    }, [maxDuplicatedCount]);// eslint-disable-line react-hooks/exhaustive-deps

    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            subtype:'DUPLICATED',
            maxDuplicatedCount: maxDuplicatedCount-1
        }
        props.setSpecific(specific)
       
    }




    function handleTextFieldChange (value: number) {  
        setMaxDuplicatedCount(value)
        createSpecific()
    };
   
    
    return(

            <div 
            //className={classes.actionsContainer}
            >
                <Typography variant='caption'> Num of Rows to consider a duplicated</Typography>
                <div>
                    <TextField type="number" value={maxDuplicatedCount} autoComplete='off'
                        style={{ width: '15%' }}
                        margin="dense"
                        InputProps={{ inputProps: { min: 2, max: 100 } }}
                       id='timelinessValue' 
                        onChange={event =>{// @ts-ignore
                            handleTextFieldChange(event.target.value)  
                            } }
                        />
                    
                    </div>
            
            </div>          

    )
}
