import React from 'react';
import TextField from '@material-ui/core/TextField';

import {
    makeStyles, Button, Typography, Select, Input, Chip, Avatar, Grid,
    MenuItem, Switch, FormControlLabel, FormControl, Checkbox, InputLabel
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { styles, QualityTheme } from 'style/CustomTheme'
import * as helper from '../CreateRuleHelper'

import { getDataFromProperties } from './SpecificRuleHelper'
import trackEvent from 'trackEvent'
import * as actions from 'actions'
import { useDispatch } from 'react-redux'

import * as utilsRuleCreation from './../utilsRuleCreation'


export default function FormRuleValueRange(props) {

    const ruleRangeListSeparator = ","

    const classes = makeStyles(styles(QualityTheme));
    const dispatch = useDispatch();


    const [selectedDataformat, setSelectedDataformat] = React.useState(undefined)
    const [rule_max_range, setRule_max_range] = React.useState(getDataFromProperties(props.editableRule, props.specific, 'rule_max_range', undefined));
    const [rule_min_range, setRule_min_range] = React.useState(getDataFromProperties(props.editableRule, props.specific, 'rule_min_range', undefined));
    const [textValueRange, setTextValueRange] = React.useState('');
    const [rule_range_array, setRule_range_array] = React.useState(getDataFromProperties(props.editableRule, props.specific, 'rule_range_list', []).length > 0 ?
    splitRange(getDataFromProperties(props.editableRule, props.specific, 'rule_range_list', [])): []);
    const [value_range_check, setValue_range_check] = React.useState(props.specific && (props.specific.rule_max_range || props.specific.rule_min_range) ? true : false);
    const [type_range, setType_range] = React.useState('between');
    const [commonSelectedColumn, setCommonSelectedColumn] = React.useState((props.commonRule && props.commonRule.selectedColumns) ? props.commonRule.selectedColumns : undefined)
    const [columnType, setColumnType] = React.useState(getColumType())
    const [nullAccepted, setNullAccepted] = React.useState(getDataFromProperties(props.editableRule, props.specific, 'nullAccepted', true))
    const [viewRecommendedValues, setViewRecommendedValues] = React.useState(viewRecommendation(props.commonRule))
    const [viewMaxValue, setViewMaxValue] = React.useState(viewMaxMinRecommendation(props.commonRule, 0))
    const [viewMinValue, setViewMinValue] = React.useState(viewMaxMinRecommendation(props.commonRule, 1))

    function splitRange(data){
        if(data.includes("ruleRangeListSeparator")){
            return data.split("ruleRangeListSeparator")
        }
        else{
            return data.split(",")
        }
    }
   
    // const [viewMaxAndMinValue, setViewMaxAndMinValue] = React.useState(viewMaxMinRecommendation(props.commonRule, 2))

    React.useEffect(()=>{
        if(props.commonRule  && props.commonRule.datasource){
            dispatch(actions.fetchDataformat(props.commonRule.datasource.dataformatId ? props.commonRule.datasource.dataformatId : 
                props.commonRule.datasource.dataset._id)).then(
                    response=>{
                        setSelectedDataformat(response.data)
                    }
                )
        }
        console.log('props.commonRule', props.commonRule)
    }, [props.commonRule])// eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {

        setRule_max_range(getDataFromProperties(props.editableRule, props.specific, 'rule_max_range', undefined))
        setRule_min_range(getDataFromProperties(props.editableRule, props.specific, 'rule_min_range', undefined))
        setRule_range_array(getDataFromProperties(props.editableRule, props.specific, 'rule_range_list', []).length > 0 ?
        splitRange(getDataFromProperties(props.editableRule, props.specific, 'rule_range_list', [])) : [])
        setValue_range_check(props.specific && (props.specific.rule_max_range || props.specific.rule_min_range) ? true : false)
        setCommonSelectedColumn((props.commonRule && props.commonRule.selectedColumns) ? props.commonRule.selectedColumns: undefined)
        setColumnType(getColumType())
        console.log('columnType', getColumType())
        setNullAccepted(getDataFromProperties(props.editableRule, props.specific, 'nullAccepted', true))
        setViewRecommendedValues(viewRecommendation(props.commonRule))
        setViewMaxValue(viewMaxMinRecommendation(props.commonRule, 0))
        setViewMinValue(viewMaxMinRecommendation(props.commonRule, 1))

        
    }, [props.editableRule, props.commonRule, selectedDataformat])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        setValue_range_check(props.specific ? (props.specific.value_range_check ? value_range_check : (props.specific.rule_max_range || props.specific.rule_min_range) ? true : false) : true)
    }, [props.specific]);// eslint-disable-line react-hooks/exhaustive-deps

    function viewRecommendation(commonRule) {
        if (commonRule.selectedColumns && commonRule.selectedColumns.length === 1) {
            if (selectedDataformat && selectedDataformat.profiling &&
                selectedDataformat.profiling.profilingColumns[props.commonRule.selectedColumns[0].positionTmp] && 
                selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp].profilingColumnItems[15].profilingItemValue !== "null") {
                    var distinct_val = (selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp].profilingColumnItems[15].profilingItemValue)
                    var array_val = splitRange(distinct_val.replace('[', '').replace(']', ''))
                // here we trim and remove '' characters from the start and end the of the value
                array_val = array_val.map(e => {
                    e = e.trim()
                    if (e[0] === "'" && e[e.length - 1] === "'") {
                        e = e.slice(1, -1);
                        return e
                    }
                    else {
                        return e
                    }
                })



                return array_val
            }
        }
    }

    function viewMaxMinRecommendation(commonRule, maxOrMin) {
        if (commonRule.selectedColumns && commonRule.selectedColumns.length === 1) {
            if (maxOrMin === 0 && selectedDataformat && selectedDataformat.profiling &&
                selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp] && 
                selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp].profilingColumnItems[5].profilingItemValue !== "null") {
                var max = (selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp].profilingColumnItems[5].profilingItemValue)
                return max
            } else if (maxOrMin === 1 && selectedDataformat && selectedDataformat.profiling &&
                selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp] && 
                selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp].profilingColumnItems[6].profilingItemValue !== "null") {
                var min = (selectedDataformat.profiling.profilingColumns[commonRule.selectedColumns[0].positionTmp].profilingColumnItems[6].profilingItemValue)
                return min
            } else if (maxOrMin === 2 && max && min) {
                return [max, min]
            }
        }
    }

    function getColumType() {

        //si todas las columnas son del mismo tipo se devuelve ese tipo
        //si son diferentes, se considera string
        //si hay columnas concatenadas, se considera string
        if(commonSelectedColumn===undefined){
            console.log('1')
            return 'string'
        }
        for (let i = 0; i < commonSelectedColumn.length; i++) {
            if (commonSelectedColumn[i].cols && commonSelectedColumn[i].cols.length > 1) {
                console.log('2')
                return 'string'
            }
        }


        var lastType = getColumnType(0)
        for (let i = 0; i < commonSelectedColumn.length; i++) {

            var type = getColumnType(i)
            console.log('type', type)
            if (type !== lastType) {
                console.log('3')
                console.log(type, lastType)
                return 'string'
            }

        }
        console.log('4')
        return lastType
    }

    function getColumnType(colIndex) {
        return commonSelectedColumn[colIndex].type
       
    }



    function createSpecific() {
        //creamos el objeto de regla especifica

        const specific = {
            subtype: 'cell_range',
            rule_range_type: columnType,
            rule_range_list: rule_range_array.join("ruleRangeListSeparator"),
            rule_max_range: rule_max_range,
            rule_min_range: rule_min_range,
            value_range_check: value_range_check,
            nullAccepted: nullAccepted
        }
        props.setSpecific(specific)

    }

    React.useEffect(() => {
        getColumType()
    }, [commonSelectedColumn]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        createSpecific()
    }, [rule_range_array, rule_max_range, rule_min_range, value_range_check, nullAccepted]);// eslint-disable-line react-hooks/exhaustive-deps




    function onClickAddString2List(e) {
        var array = rule_range_array.slice()
        array.push(textValueRange)
        setRule_range_array(array)
        setTextValueRange('')


    }

    function onClickAddRecommendation2List(e, text) {
        var array = rule_range_array.slice()
        array.push(text)
        setRule_range_array(array)
    }

    function onChangeMinRangeRecommendation(value) {
        setRule_min_range(value)
        document.getElementById("minTextField").focus();

    }
    function onChangeMaxRangeRecommendation(value) {
        setRule_max_range(value)
        document.getElementById("maxTextField").focus();

    }

    function onChangeTextValueRange(e) {
        setTextValueRange(e.target.value)
    }
    function onChangeMinRange(e) {
        setRule_min_range(e.target.value)
    }
    function onChangeMaxRange(e) {
        setRule_max_range(e.target.value)
    }

    function onChangeRangeType(event) {
        setType_range(event.target.value)
    }

    function handleChangeValueRangeCheck(event) {
        setValue_range_check(event.target.checked)
    }


    function onDeleteSelectedItem(value) {

        var selectedColTemp = rule_range_array.slice()
        for (var i = 0; i < rule_range_array.length; i++) {
            if (value === rule_range_array[i]) {
                selectedColTemp.splice(i, 1);
                break
            }

        }
        setRule_range_array(selectedColTemp)

    }

    return (
        <div>
            <div style={{ width: '50%' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={nullAccepted}
                            onChange={event => {
                                setNullAccepted(event.target.checked)
                            }
                            }
                            value="nullValueCheck"
                            color="primary"
                        />
                    }
                    label="Null values are accepted as correct"
                />
            </div>
            <div>
                {columnType === 'boolean' &&
                    <div>

                        <FormControl >
                            <InputLabel id="demo-mutiple-checkbox-label">Select Values</InputLabel>
                            <Select
                                multiple
                                style={{ width: '300px', height: '35px' }}
                                value={(rule_range_array) ? rule_range_array.map(e => (e === 'true' || e === true) ? true : false) : []}
                                input={<Input id="select-multiple-checkbox" />}
                                onChange={event => { console.log('eee', event.target); setRule_range_array(event.target.value) }}
                            >

                                <MenuItem
                                    key={'true'}
                                    value={true}
                                >
                                    {'true'}
                                </MenuItem>
                                <MenuItem
                                    key={'false'}
                                    value={false}
                                >
                                    {'false'}
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </div>
                }
                {columnType === 'string' &&
                    <div>
                        <Typography variant='button'> Value List</Typography>
                        <Grid container className={classes.gridForm} spacing={0} >

                            <Grid key={'1'} item style={{ width: '210px' }}>
                                <TextField
                                    value={textValueRange}
                                    autoComplete='off'
                                    onChange={onChangeTextValueRange}
                                    label={'Add Value to List'}
                                    style={{ marginRight: '5px', width: '200px' }}

                                    margin="dense"
                                />
                            </Grid>
                            <Grid key={'2'} item style={{ width: '80px' }}>
                                <Button variant="outlined" color="primary" size="small" aria-label="add"
                                    style={{
                                        marginTop: '20px', marginBottom: '20px', padding: '5px',
                                        marginRight: '5px'
                                    }}
                                    onClick={event => {
                                        trackEvent('CreateRuleSpecific', 'ValueRange Null Rule Add Custom Value  Button')
                                        onClickAddString2List(event)
                                    }}>
                                    <AddIcon />
                                </Button>
                            </Grid>
                            <Grid key={'3'} item style={{ width: '300px', marginTop: '18px' }}>
                                <Select

                                    style={{ width: '300px', height: '35px' }}
                                    value={rule_range_array}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={selected => {
                                        return <div className={classes.chips}>
                                            {selected && selected.map(value => (
                                                <Chip
                                                    avatar={<Avatar>
                                                        <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                            onClick={(e) => {
                                                                onDeleteSelectedItem(value);
                                                                trackEvent('CreateRuleSpecific', 'ValueRange Delete Selected Item')
                                                            }} /></Avatar>}
                                                    key={value}
                                                    label={value} className={classes.chip} />
                                            ))}
                                        </div>
                                    }}
                                >

                                    {rule_range_array &&
                                        rule_range_array.map(e => (
                                            <MenuItem key={e} value={e} >
                                                <Chip
                                                    avatar={<Avatar>
                                                        <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                            onClick={(event) => {
                                                                onDeleteSelectedItem(e);
                                                                trackEvent('CreateRuleSpecific', 'ValueRange Delete Selected Item');
                                                            }} /></Avatar>}
                                                    key={e}
                                                    label={e} className={classes.chip} />
                                            </MenuItem>
                                        ))}

                                </Select>

                            </Grid>
                        </Grid>
                        {viewRecommendedValues && viewRecommendedValues.map((e) => (
                            <Button variant="outlined" color="primary" size="small" aria-label="add"
                                style={{
                                    marginTop: '20px', marginBottom: '20px', padding: '5px',
                                    marginRight: '5px'
                                }}
                                onClick={event => {
                                    trackEvent('CreateRuleSpecific', 'ValueRange Null Rule Add Custom Value  Button')
                                    onClickAddRecommendation2List(event, e)
                                }}>
                                {e}
                            </Button>))}
                    </div>
                }

                {(columnType === 'number' || columnType ==='double' || columnType ==='integer') &&
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={value_range_check}
                                    onChange={handleChangeValueRangeCheck}
                                    value={value_range_check}
                                    color="primary"
                                />
                            }
                            label="Value Range Selection"
                        />

                        {( value_range_check === false) &&
                            <div>
                                <Typography variant='button'> Value List</Typography>
                                <Grid container className={classes.gridForm} spacing={0} >

                                    <Grid key={'1'} item style={{ width: '210px' }}>
                                        <TextField type='number'
                                            value={textValueRange}
                                            autoComplete='off'
                                            onChange={onChangeTextValueRange}
                                            label={'Add Value to List'}
                                            style={{ marginRight: '5px', width: '200px' }}

                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid key={'2'} item style={{ width: '80px' }}>
                                        <Button variant="outlined" color="primary" size="small" aria-label="add"
                                            style={{
                                                marginTop: '20px', marginBottom: '20px', padding: '5px',
                                                marginRight: '5px'
                                            }}
                                            onClick={event => {
                                                trackEvent('CreateRuleSpecific', 'ValueRange Add Strings 2 List')
                                                onClickAddString2List(event)
                                            }}>
                                            <AddIcon />
                                        </Button>
                                    </Grid>
                                    <Grid key={'3'} item style={{ width: '300px', marginTop: '18px' }}>
                                        <Select

                                            style={{ width: '300px', height: '35px' }}
                                            value={rule_range_array}
                                            input={<Input id="select-multiple-checkbox" />}
                                            renderValue={selected => {
                                                return <div className={classes.chips}>
                                                    {selected && selected.map(value => (
                                                        <Chip
                                                            avatar={<Avatar>
                                                                <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                                    onClick={(e) => {
                                                                        onDeleteSelectedItem(value);
                                                                        trackEvent('CreateRuleSpecific', 'ValueRange Delete Selected Item');
                                                                    }} /></Avatar>}
                                                            key={value}
                                                            label={value} className={classes.chip} />
                                                    ))}
                                                </div>
                                            }}
                                        >

                                            {rule_range_array &&
                                                rule_range_array.map(e => (
                                                    <MenuItem key={e} value={e} >
                                                        <Chip
                                                            avatar={<Avatar>
                                                                <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                                    onClick={(event) => {
                                                                        onDeleteSelectedItem(e);
                                                                        trackEvent('CreateRuleSpecific', 'ValueRange Delete Selected Item');
                                                                    }} /></Avatar>}
                                                            key={e}
                                                            label={e} className={classes.chip} />
                                                    </MenuItem>
                                                ))}

                                        </Select>
                                    </Grid>
                                </Grid>
                                {viewRecommendedValues && viewRecommendedValues.sort(helper.compareNumbers).map((e) => (
                                    <Button variant="outlined" color="primary" size="small" aria-label="add"
                                        style={{
                                            marginTop: '20px', marginBottom: '20px', padding: '5px',
                                            marginRight: '5px'
                                        }}
                                        onClick={event => {
                                            trackEvent('CreateRuleSpecific', 'ValueRange Null Rule Add Custom Value  Button')
                                            onClickAddRecommendation2List(event, e)
                                        }}>
                                        {e}
                                    </Button>))}
                            </div>
                        }
                        { value_range_check === true && 
                            <div>
                                <Typography variant='button'> Value Range </Typography>
                                <Grid container className={classes.gridForm} spacing={0} >
                                    <Grid key={'0'} item style={{ width: '160px' }}>
                                        <FormControl className={classes.formControl}>
                                            <Typography variant='subtitle2'>Range Type</Typography>
                                            <Select
                                                inputProps={{
                                                    name: 'range type',
                                                    id: 'range type'

                                                }}
                                                style={{ width: '150px' }}
                                                value={type_range}
                                                onChange={onChangeRangeType}

                                            >
                                                <MenuItem key={'max'} value={'max'}>
                                                    <Typography>max value</Typography>
                                                </MenuItem>
                                                <MenuItem key={'min'} value={'min'}>
                                                    <Typography>min value</Typography>
                                                </MenuItem>
                                                <MenuItem key={'between'} value={'between'}>
                                                    <Typography>between</Typography>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {type_range !== 'max' && <div><Grid key={'1'} item style={{ width: '210px' }}>
                                        <TextField type='number'
                                            id="minTextField"
                                            value={rule_min_range}
                                            InputLabelProps={{ shrink: true }}
                                            autoComplete='off'
                                            onChange={onChangeMinRange}
                                            label={'Min. Value Range'}
                                            style={{ marginRight: '5px', width: '200px' }}

                                            margin="dense"
                                        />
                                    </Grid>
                                        {viewMinValue &&
                                            <Button variant="outlined" color="primary" size="small" aria-label="add"
                                                style={{
                                                    marginTop: '20px', marginBottom: '20px', padding: '5px',
                                                    marginRight: '5px'
                                                }}
                                                onClick={event => {
                                                    onChangeMinRangeRecommendation(viewMinValue)
                                                }}>
                                                {viewMinValue}
                                            </Button>}
                                    </div>
                                    }
                                    {type_range !== 'min' &&
                                        <div><Grid key={'2'} item style={{ width: '210px' }}>
                                            <TextField type='number'
                                                id="maxTextField"
                                                value={rule_max_range}
                                                autoComplete='off'
                                                onChange={onChangeMaxRange}
                                                InputLabelProps={{ shrink: true }}
                                                label={'Max. Value Range'}
                                                style={{ marginRight: '5px', width: '200px' }}
                                                margin="dense"
                                            />
                                        </Grid>
                                            {viewMaxValue &&
                                                <Button variant="outlined" color="primary" size="small" aria-label="add"
                                                    style={{
                                                        marginTop: '20px', marginBottom: '20px', padding: '5px',
                                                        marginRight: '5px'
                                                    }}
                                                    onClick={event => {
                                                        onChangeMaxRangeRecommendation(viewMaxValue)
                                                    }}>
                                                    {viewMaxValue}
                                                </Button>}
                                        </div>
                                    }
                                </Grid>
                            </div>
                        }
                    </div>
                }
            </div>


        </div>
    );

}

