import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Link from "@material-ui/core/Link";
import { Typography, Tooltip } from '@material-ui/core';
import { faProjectDiagram, faColumns } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { faHubspot } from '@fortawesome/free-brands-svg-icons'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import * as helper from '../QualityProjectTableHelper'
import * as commonStyles from 'style/CommonStyles'



function isNodeSqlDatabase(node) {
    const source = node.data && node.data.dataset && node.data.dataset.source ? node.data.dataset.source : node.data.dataformatSource
    if (node.data.type === 'Datasource' && node.data.datasourceType !== 'REAL_TIME_LAMBDA' && source === 'azure-sqldatabase') {
        return true;
    }
    else {
        return false;
    }
}
function isNodeStorage(node) {
    const source = node.data && node.data.dataset && node.data.dataset.source ? node.data.dataset.source : node.data.dataformatSource
    if (node.data.type === 'Datasource' && node.data.datasourceType !== 'REAL_TIME_LAMBDA' && source === 'azure-storage') {
        return true;
    }
    else {
        return false;
    }
}

function isNodeAwsS3(node) {
    const source = node.data && node.data.dataset && node.data.dataset.source ? node.data.dataset.source : node.data.dataformatSource
    if (node.data.type === 'Datasource' && node.data.datasourceType !== 'REAL_TIME_LAMBDA' && source === 'awsS3') {
        return true;
    }
    else {
        return false;
    }
}

function isNodeHive(node) {
    const source = node.data && node.data.dataset && node.data.dataset.source ? node.data.dataset.source : node.data.dataformatSource
    if (node.data.type === 'Datasource' && node.data.datasourceType !== 'REAL_TIME_LAMBDA' && (source === 'awsHive' || source === 'hive')) {
        return true;
    }
    else {
        return false;
    }
}

function isNodeRedshift(node) {
    const source = node.data && node.data.dataset && node.data.dataset.source ? node.data.dataset.source : node.data.dataformatSource
    if (node.data.type === 'Datasource' && node.data.datasourceType !== 'REAL_TIME_LAMBDA' && (source === 'awsRedshift' || source === 'awsRedshift')) {
        return true;
    }
    else {
        return false;
    }
}

function isNodeBigQuery(node) {
    const source = node.data && node.data.dataset && node.data.dataset.source ? node.data.dataset.source : node.data.dataformatSource
    if (node.data.type === 'Datasource' && node.data.datasourceType !== 'REAL_TIME_LAMBDA' && source === 'bigQuery') {
        return true;
    }
    else {
        return false;
    }
}
function isNodeLocal(node) {

    if (node.data.type === 'Datasource' && node.data.datasourceType !== 'REAL_TIME_LAMBDA' &&
        isNodeSqlDatabase(node) === false && isNodeStorage(node) === false
        && isNodeAwsS3(node) === false
        && isNodeHive(node) === false
        && isNodeBigQuery(node) === false) {
        return true;
    }
    else {
        return false;
    }
}
export function NameCellTemplate(node, project, setSelectedItem, restartRule, tableByRulesOrColumns) {

    // const name = node.data.nodeType === 'Rule' && node.data.type === 'Column' ? node.data.nameColumn : node.data.name

    if (node.data.type === 'dvd') {
    }
    var nameDS = ""
    if (node.data.type === 'Datasource') {
        if (node.data.dataset) {
            nameDS = `${node.data.name} (${node.data.dataset.name})`
        }
        else if (node.data.dataset || node.data.dataformatName) {
            nameDS = `${node.data.name} (${node.data.dataset ? node.data.dataset.name : node.data.dataformatName})`
        }
        else {
            nameDS = node.data.name

        }
        if (nameDS.length > 50) {
            nameDS = nameDS.substring(0, 47) + "..."
        }
    }
    return (
        <div style={{ display: 'inline-flex', width: '70%' }}>
            {(node.data.type === 'Quality Point') && <FontAwesomeIcon icon={faProjectDiagram} style={{ color: commonStyles.darkColor, fontSize: commonStyles.projectTableIconSize, marginRight: '10%' }} />}
            {isNodeSqlDatabase(node) === true &&
                <img alt="" style={{ height: commonStyles.projectTableIconSize, width: commonStyles.projectTableIconSize, marginRight: '10%' }}
                    src={require('resources/icons/azure/sql-database-generic.png')} />}
            {isNodeStorage(node) === true &&
                <img alt="" style={{ height: commonStyles.projectTableIconSize, width: commonStyles.projectTableIconSize, marginRight: '10%' }}
                    src={require('resources/icons/azure/Storage-Container.png')} />}
            {isNodeAwsS3(node) === true &&
                <img alt="" style={{ height: commonStyles.projectTableIconSize, width: commonStyles.projectTableIconSize, marginRight: '10%' }}
                    src={require('resources/icons/aws/aws-s3-logo.png')} />}
            {isNodeHive(node) === true &&
                <img alt="" style={{ height: commonStyles.projectTableIconSize, width: commonStyles.projectTableIconSize, marginRight: '10%' }}
                    src={require('resources/icons/hive.png')} />}
            {isNodeRedshift(node) === true &&
                <img alt="" style={{ height: commonStyles.projectTableIconSize, width: commonStyles.projectTableIconSize, marginRight: '10%' }}
                    src={require('resources/icons/redshift.png')} />}
            {isNodeBigQuery(node) === true &&
                <img alt="" style={{ height: commonStyles.projectTableIconHiveRoleSize, width: commonStyles.projectTableIconHiveRoleSize, marginRight: '10%' }}
                    src={require('resources/icons/bigQuery.png')} />}
            {isNodeLocal(node) === true && <FontAwesomeIcon icon={faDatabase} style={{ color: commonStyles.darkColor, fontSize: commonStyles.projectTableIconSize, marginRight: '10%' }} />}
            {(node.data.type === 'Datasource' && node.data.datasourceType === 'REAL_TIME_LAMBDA') && <FontAwesomeIcon icon={faHubspot} style={{ color: commonStyles.mainColor, fontSize: commonStyles.projectTableIconSize, marginRight: '10%' }} />}
            {(node.data.nodeType === 'Rule' && !tableByRulesOrColumns) &&
                <img alt="" style={{ height: commonStyles.projectTableIconRoleSize, width: commonStyles.projectTableIconRoleSize, marginLeft: '5px', marginRight: '5px' }}
                    src={require('resources/icons/rule_proc1_black.png')}
                />
            }
            {/* This one is for columns (inside Rules) */}
            {(node.data.nodeType === 'Rule' && tableByRulesOrColumns && node.data.name === '') && <FontAwesomeIcon icon={faColumns} style={{ color: commonStyles.mainColor, marginRight: '5px', marginLeft: '5px' }} />}
            {(node.data.type === 'Folder') && <FontAwesomeIcon icon={faFolderOpen} style={{ color: commonStyles.folderColor, fontSize: commonStyles.projectTableIconSize, marginRight: '10%' }} />}
            {(node.data.type === 'Quality Point') &&
                <Typography style={{ cursor: 'pointer', width: '100%', color: commonStyles.darkColor, fontWeight: 'bold', fontFamily: commonStyles.fontFamily, fontSize: commonStyles.projectTableQPTextSize }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project, tableByRulesOrColumns)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }
            {(node.data.type === 'Datasource') &&


                <div>
                    <Typography style={{ cursor: 'pointer', width: '100%', color: commonStyles.darkColor, fontWeight: 'bold', fontFamily: commonStyles.fontFamily, fontSize: commonStyles.projectTableDSTextSize }} variant='subtitle2'
                        onDoubleClick={e => {
                            const { item, itemType } = helper.obtainEditableItem(node.key, project, tableByRulesOrColumns)
                            setSelectedItem(item, itemType)
                        }}
                    >
                        {nameDS}  </Typography>
                </div>
            }

            {(node.data.type === 'Folder') &&
                <Typography style={{ cursor: 'pointer', width: '100%', color: commonStyles.folderColor, fontFamily: commonStyles.fontFamily, fontSize: commonStyles.projectTableDSTextSize }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project, tableByRulesOrColumns)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }

            {(node.data.nodeType === 'Rule') && (node.data.type === 'dvd') &&
                <div style={{ display: 'inline-flex' }}/*  ref={dragRef} */>

                    <Typography style={{ cursor: 'pointer', width: '100%', color: commonStyles.secondaryColor, fontFamily: commonStyles.fontFamily, fontSize: commonStyles.projectTableRuleTextSize }} variant='subtitle2'
                        onDoubleClick={e => {
                            if (node.data.type !== 'Column') {
                                const { item, itemType } = helper.obtainEditableItem(node.key, project, tableByRulesOrColumns)
                                setSelectedItem(item, itemType)
                            }
                        }}
                    >
                        {/* Rule name or column names */}
                        {!tableByRulesOrColumns
                            ? node.data.name
                            : node.data.nameColumn
                        }
                    </Typography>
                    <Tooltip title={'Reset KPIs to restart rule'} >
                        <div>
                            <FontAwesomeIcon icon={faSyncAlt} style={{ marginLeft: '10px', color: commonStyles.mainColor, fontSize: 16, }}
                                onClick={event => restartRule(node.data._id)} />
                        </div>

                    </Tooltip>
                </div>

            }

            {(node.data.nodeType === 'Rule') &&
                (node.data.type !== 'dvd') &&
                <Typography style={{ cursor: node.data.type !== 'Column' ? 'pointer' : 'auto', width: '100%', color: commonStyles.secondaryColor, fontFamily: commonStyles.fontFamily, fontSize: commonStyles.projectTableRuleTextSize }} variant='subtitle2'
                    onDoubleClick={e => {
                        if (node.data.type !== 'Column') {
                            const { item, itemType } = helper.obtainEditableItem(node.key, project, tableByRulesOrColumns)
                            setSelectedItem(item, itemType)
                        }
                    }}
                >
                    {/* Rule name or column names */}
                    {!tableByRulesOrColumns
                        ? node.data.name
                        : node.data.nameColumn
                    }
                </Typography>
            }

        </div>
    );
}