
import qualityLocalHost from 'apis/qualityLocalHost';
import quality from '../apis/quality';
import qualityLocalHost2 from '../apis/qualityLocalHost2';
//import qualityLocalHost from '../apis/qualityLocalHost';

//import {axiosHeader} from './../util/APIUtils'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';


export const getClusterStatus = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.get(`/databricks/cluster/get/${environment}/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      //no mostramos error ya que puede haber clusters mal configurados y aparecería el error constantemente
      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const checkClusterEnvironment = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  //TODO: cambiar notebook-base por parametrización
  await quality.get(`/databricks/check/${environment}/${userId}/notebook-base`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const checkAndCorrectClusterEnvironment = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  //TODO: cambiar notebook-base por parametrización
  await quality.get(`/databricks/checkcorrect/${environment}/${userId}/notebook-base`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const checkClusterEnvironmentLibs = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  //TODO: cambiar notebook-base por parametrización
  await quality.get(`/databricks/check/libs/${environment}/${userId}/downloadable`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}





export const checkAndCorrectClusterEnvironmentAll = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  //TODO: cambiar notebook-base por parametrización
  await quality.get(`/databricks/checkcorrect/all/${environment}/${userId}/notebook-base/downloadable`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

//this 1
export const checkAndCorrectClusterEnvironmentAllFromConfig = (environment, userId, config) => async (dispatch, getState) => {


  var returnValue = {}
  //TODO: cambiar notebook-base por parametrización
  await quality.post(`/databricks/checkcorrect/all/config/file/${environment}/${userId}/notebook-base/downloadable`, config, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const getClusterPreparationStatus = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  //TODO: cambiar notebook-base por parametrización
  await quality.get(`/configuration/clusterpreparation/${userId}/${environment}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const setClusterPreparationStatus = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  var clusterPreparationResponse = {
    state: '',
    responses: []
  }
  //TODO: cambiar notebook-base por parametrización
  await quality.put(`/configuration/clusterpreparation/${userId}/${environment}`, clusterPreparationResponse, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}



// this 2
export const checkClusterEnvironmentAllFromConfig = (environment, userId, config) => async (dispatch, getState) => {

  var returnValue = {}
  //TODO: cambiar notebook-base por parametrización
  await quality.post(`/databricks/check/all/config/file/${environment}/${userId}/notebook-base/downloadable`, config, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const startCluster = (environment, userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.get(`/databricks/cluster/start/${environment}/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}



export const checkStatus = (environment, userId, containerName) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.get(`/databricks/check/${environment}/${userId}/${containerName}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}





export const deployNotebook2DatabricksAzure = (environment, userId, projectName, qpName) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/version/1/publish/notebook/version/1/azure/${environment}/${userId}/${qpName}/${projectName}`, null, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Quality Point ${qpName} deployed to Databricks`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue 
}



export const createPreviewDataJob = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
  const url = rootItem && rootItem.storageType === 'awsS3' ?
  `/databricks/createPreviewDataJob/aws/${environment}/${userId}/${nSamples}/${flatten}`
  :
  `/databricks/createPreviewDataJob/${environment}/${userId}/${nSamples}/${flatten}`
  
  await quality.post(url, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      const errorString = JSON.stringify(error)
      if(errorString.includes("403") || errorString.includes("401")){
        dispatchError(dispatch, "Aqtiva is not authorized to read that file. Please contact your Azure administrator to provide access to Aqtiva")
      }
      else{
        dispatchError(dispatch, error)
      }
      

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}



export const createPreviewDataJobCosmos = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/createPreviewDataJob/cosmos/${environment}/${userId}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const createPreviewDataJobSQLDatabase = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/createPreviewDataJob/sqldatabase/${environment}/${userId}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}
export const createPreviewDataJobSQLOnpremDatabase = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/createPreviewDataJob/onpremise/sqldatabase/${environment}/${userId}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const createPreviewDataJobBigQueryOnpremDatabase = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/createPreviewDataJob/bigQuery/${environment}/${userId}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const createPreviewDataJobRedshift = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/redshift/preview/${environment}/${userId}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const getDataAsJson = (simulationId, environment, userId, nSamples, okData) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/parse2json/${simulationId}/${environment}/${userId}/${nSamples}/${okData}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Get Data to Visualize`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue
}


export const addProfilingToDataformat = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/databricks/createProfilingDataJob2/user/${userId}/${environment}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)
    returnValue = response.data

    return returnValue;
  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const addProfilingToDataformatAWS = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
 

  await quality.post(`/databricks/createProfilingDataJob/onprem/aws/${userId}/${environment}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)
    returnValue = response.data

    return returnValue;
  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const addProfilingToSQLDataformat = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/databricks/createProfilingDataJob/sqldatabase/${userId}/${environment}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)
    returnValue = response.data

    return returnValue;
  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}
export const addProfilingToOnpremSQLDataformat = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/databricks/createProfilingDataJob/onprem/sqldatabase/${userId}/${environment}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)
    returnValue = response.data

    return returnValue;
  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const addProfilingToOnpremBigQueryDataformat = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/databricks/createProfilingDataJob/onprem/bigQuery/${userId}/${environment}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)
    returnValue = response.data

    return returnValue;
  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const createProfilingDataJobRedshift = (environment, userId, credentials, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/redshift/profiling/${environment}/${userId}/${nSamples}/${flatten}`, credentials, {
      headers: axiosHeader()
  }).then(function (response) {

      returnValue = response.data
      dispatchInfo(dispatch, `Data preview job running`)

  })
      .catch(function (error) {
          dispatchError(dispatch, error)

          returnValue = { result: 'ko', error: error }
      });


  return returnValue
}
 
export const executeProfilingBatchJob = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/databricks/createProfilingDataJob/batch/user/${userId}/${environment}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)
    returnValue = response.data

    return returnValue;
  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const createProfilingDataJob = (environment, userId, rootItem, nSamples, flatten) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/databricks/createProfilingDataJob/${environment}/${userId}/${nSamples}/${flatten}`, rootItem, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)

  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const fetchProfilingBatch = (profilingBatchId) => async (dispatch, getState) => {
  var returnValue = { result: 'undefined' }
  await quality.get(`/databricks/profiling/batch/${profilingBatchId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });
  return returnValue

};

export const fetchExecutionsForScheduleId = (scheduleId) => async (dispatch, getState) => {
  var returnValue = { result: 'undefined' }
  await quality.get(`/databricks/executions/schedule/${scheduleId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });
  return returnValue

};

