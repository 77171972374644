import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import * as commonStyles from 'style/CommonStyles'
import DashboardChartComponent from '../../Common/Components/DashboardChartComponent';
import ChartTripleTemporal from '../../Common/Charts/ChartTripleTemporal';
import DonutChart from '../../Common/Charts/DonutChart';

import * as actions from 'actions'
import { useDispatch } from 'react-redux'

import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DQIDimensionComponent from '../../Common/Components/DQIDimensionComponent';
import ExecutionDetail from '../../Common/Components/ExecutionDetail';
import ReturnPreviousAgregationButton from '../ReturnPreviousAgregationButton';
import { LanguageContext } from 'language/LanguageContext';

export default function DatasourceGrid(props) {

    const languageContext = React.useContext(LanguageContext)
    const [dataSource, setDataSource] = useState([])
    //const [project, setProject] = useState([])
    //const [qualityPoint, setQualityPoint] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [openDimensionModal, setOpenDimensionModal] = React.useState(false)
    const handleClose = () => setOpenDimensionModal(false);
    const dispatch = useDispatch()
    const [chartTripleTemporal, setChartTripleTemporal] = useState(<div></div>)
    const [donutChart, setDonutChart] = useState(<div></div>)
    const [executionsDetail, setExecutionDetail] = useState(<div></div>)
    //const [spiderChart, setSpiderChart] = useState(<div></div>)
    const [dimensionChart, setDimensionChart] = useState(<div></div>)

    useEffect(() => {

        if (props.dataSource) {

            setDataSource(props.dataSource)


            /*let dimension = [
                { 'dimension': 'integrity', 'executions': getDimension('integrity') }, 
                { 'dimension': 'format', 'executions': getDimension('format') }, 
                { 'dimension': 'unicity', 'executions': getDimension('unicity') },
                { 'dimension': 'validity', 'executions': getDimension('validity') },
                { 'dimension': 'veracity', 'executions': getDimension('veracity') }
            ]*/


            setChartTripleTemporal(<ChartTripleTemporal data={props.dataSource.historyList} showBasicInformation={showBasicInformation} />)
            setDonutChart(<DonutChart data={props.dataSource.score} showCenterLabel={true} chart={'chartDonutDataSource0'} click={false} />)
            //setSpiderChart(<SpiderChart chart={"spiderDataSource"} data={dimension} click={false} />)
            setDimensionChart(<DQIDimensionComponent DQIByDimension={props.dataSource.dimensionDQIList} />)
        }

        //if (props.project) setProject(props.project)
        //if (props.qualityPoint) setQualityPoint(props.qualityPoint)

    }, [props.project, props.qualityPoint, props.dataSource])// eslint-disable-line react-hooks/exhaustive-deps

    //const chartTriple = <ChartTripleTemporal data={executionHistory} showBasicInformation={showBasicInformation}/>
    //const donutChart = <DonutChart data={dataSource.score} showCenterLabel={true} chart={'chartDonutDataSource0'} click={false} />
    //const executionDetails = <ExecutionDetail project={project} execution={execution} qualityPoint={qualityPoint}/>
    //const spiderChart = <SpiderChart chart={"spiderDataSource"} data={dimension} click={false} />



    async function showBasicInformation(e) {
        let executionDetail = await dispatch(actions.getDetailExecutions(e.executionId))
        if (executionDetail) {
            setExecutionDetail(<ExecutionDetail project={props.project} execution={executionDetail.data} qualityPoint={props.qualityPoint} />)
            setShowDetails(true)
        }


    }

    /*
    function getDimension(name){
        let num = 0
        props.dataSource.dimensionDQIList.filter(val => {
            if(val.dimension === name) {
                num = Number((val.sumNumOk / (val.sumNumIn === 0 ? 1 : val.sumNumIn)) * 100)
            }
            return undefined
        })
        return num
    }*/

    function setAgregationLevel(level) {
        props.setAgregationLevel(level)
    }

    return (
        <>
            <Grid container spacing={3} style={commonStyles.titleBox}>
                <Grid item xs={12} style={commonStyles.DashboardChartComponentTitle}>
                    <Typography variant='h6' style={commonStyles.titleText}>Datasource - {props.dataSource.name} - QualityPoint - {props.qualityPoint.name} - Project - {props.project.name}  </Typography>
                    <ReturnPreviousAgregationButton agregationLevel={props.agregationLevel} setAgregationLevel={setAgregationLevel} />
                </Grid >

                <Grid item xs={4}>
                    <DashboardChartComponent loading={false} content={donutChart} title={'Datasource DQI'} description={languageContext.dictionary['dataSourceDQI']} />

                </Grid >
                
                <Grid item xs={8}>
                    <DashboardChartComponent loading={false} content={dimensionChart} title={'DQI per Dimension'} description={languageContext.dictionary['dimensionDQI']} />

                </Grid >

                <Grid item xs={12}>
                    <DashboardChartComponent loading={false} content={chartTripleTemporal} title={'Global DQI'} description={languageContext.dictionary['executionsChartTriple']} />
                </Grid >

                <Grid item xs={12}>
                    {showDetails && <DashboardChartComponent loading={false} content={executionsDetail} title={'Details'} description={languageContext.dictionary['detaillExecution']} />}

                </Grid >

            </Grid>

            <Dialog
                fullScreen={true}
                open={openDimensionModal}
                onClose={handleClose}
            >
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <div style={{ marginTop: '25px' }}>
                    <DQIDimensionComponent data={dataSource.dimensionDQIList} />
                </div>
            </Dialog>
        </>

    );
}

