import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import DashboardChartComponent from '../../Common/Components/DashboardChartComponent';
import ChartTripleTemporal from '../../Common/Charts/ChartTripleTemporal';
import DonutChart from '../../Common/Charts/DonutChart';
import * as actions from 'actions'
import { useDispatch } from 'react-redux'

import DQIDimensionComponent from '../../Common/Components/DQIDimensionComponent';
import ExecutionDetail from '../../Common/Components/ExecutionDetail';
import { LanguageContext } from 'language/LanguageContext';
import TableLlolipopCharts from '../../Common/Components/TableLollipopCharts';
import AqtivaDialog from 'components/common/AqtivaDialog';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import ExecutionSelectorDropdown from '../../Common/Components/ExecutionSelectorDropdown';
import * as windowHelper from 'commonFunctions/windowHelper'

export default function DatasourceGrid(props) {

    const languageContext = React.useContext(LanguageContext)
    //const [dataSource, setDataSource] = useState([])
    //const [project, setProject] = useState([])
    //const [qualityPoint, setQualityPoint] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [openDimensionModal, setOpenDimensionModal] = React.useState(false)
    const handleClose = () => setOpenDimensionModal(false);
    const dispatch = useDispatch()
    const [chartTripleTemporal, setChartTripleTemporal] = useState(<div></div>)
    const [chartTripleTemporalFullScreen, setChartTripleTemporalFullScreen] = useState(<div></div>)
    const [donutChart, setDonutChart] = useState(<div></div>)
    const [donutChartFullScreen, setDonutChartFullScreen] = useState(<div></div>)
    const [executionsDetail, setExecutionDetail] = useState(<div></div>)
    const [executionsDetailFullScreen, setExecutionDetailFullScreen] = useState(<div></div>)
    //const [spiderChart, setSpiderChart] = useState(<div></div>)
    const [tablesLollipop, setTablesLollipop] = useState(<div></div>)
    const [tablesLollipopFullScreen, setTablesLollipopFullScreen] = useState(<div></div>)
    //const [dimension, setDimension] = useState([])
    const { height, width } = windowHelper.useWindowDimensions();
    const [executionSelector, setExecutionSelector] = useState(<div></div>)

    useEffect(() => {

        if (props.dataSource) {

            //setDataSource(props.dataSource)



            /*let dimension = [
                { 'dimension': 'integrity', 'executions': getDimension('integrity') },
                { 'dimension': 'format', 'executions': getDimension('format') },
                { 'dimension': 'unicity', 'executions': getDimension('unicity') },
                { 'dimension': 'validity', 'executions': getDimension('validity') },
                { 'dimension': 'veracity', 'executions': getDimension('veracity') }
            ]


            setDimension(dimension)*/

            setChartTripleTemporal(<ChartTripleTemporal data={props.dataSource.historyList} showBasicInformation={showBasicInformation} />)
            setChartTripleTemporalFullScreen(<ChartTripleTemporal fullscreen={true} data={props.dataSource.historyList} showBasicInformation={showBasicInformation} />)
            setDonutChart(<DonutChart data={props.dataSource.score} showCenterLabel={true} chart={'chartDonutDataSource0'} openDimensionModal={openModal} click={true} />)
            setDonutChartFullScreen(<DonutChart fullscreen={true} data={props.dataSource.score} showCenterLabel={true} chart={'chartDonutDataSource0'} openDimensionModal={openModal} click={true} />)
            //setSpiderChart(<SpiderChart chart={"spiderDataSource"} data={dimension} click={false} />)
            setExecutionSelector(<ExecutionSelectorDropdown executionList={props.dataSource.historyList} showBasicInformation={showBasicInformation}/>)

            let rules = props.dataSource && props.dataSource.ruleDQI && props.dataSource.ruleDQI.map(rule => {
                return {
                    ...rule,
                    'id': rule.ruleId,
                    'score': Number(((rule.sumNumOk / (rule.sumNumOk + rule.sumNumKo)) * 100).toFixed(0)),
                    'historyList': rule.dayRuleDQIList.map(ex => {
                        return {
                            "fecha": new Date(ex.year, ex.month, ex.day).getTime(),
                            'numOk': ex.sumNumOk,
                            'numKo': ex.sumNumKo,
                            'numIn': ex.sumNumIn,
                            "createTime": ex.year + "/" + ex.month + "/" + ex.day
                        }
                    })
                }
            })

            //console.log('rules', rules)
            setTablesLollipop(<TableLlolipopCharts data={rules} showIcons={false} />)
            setTablesLollipopFullScreen(<TableLlolipopCharts fullscreen={true} data={rules} showIcons={false} />)
        }

        //if (props.project) setProject(props.project)
        //if (props.qualityPoint) setQualityPoint(props.qualityPoint)

    }, [props.project, props.qualityPoint, props.dataSource])// eslint-disable-line react-hooks/exhaustive-deps


    /**
     * This function is obtain details of a datasource execution and load the detail component
     * 
     * @param  {Execution} execution Object with all the information about the execution
     */
    async function showBasicInformation(execution) {
        let executionDetail = await dispatch(actions.getDetailExecutions(execution.executionId))

        if (executionDetail) {
            let dataSourceId = executionDetail.data.filter(ex => ex.type === 'DATA_SOURCE')[0].datasourceId

            let dataSource = props.qualityPoint.datasourceDQI.filter(dt => dt.datasourceId === dataSourceId)[0]
            setExecutionDetail(<ExecutionDetail project={props.project} execution={executionDetail.data} qualityPoint={props.qualityPoint} dataSource={dataSource}/>)
            setExecutionDetailFullScreen(<ExecutionDetail fullscreen={true} project={props.project} execution={executionDetail.data} qualityPoint={props.qualityPoint} dataSource={dataSource} />)
            setShowDetails(true)
        }


    }

    /**
     * This function search inside the datasource dimension list and format the data to be loaded in dimension component
     * 
     * @param  {String} name Name of the property that we want format
     */
    /*function getDimension(name) {
        let num = 1
        props.dataSource && props.dataSource.dimensionDQIList && props.dataSource.dimensionDQIList.filter(val => {
            if (val.dimension === name) {
                num = Number((val.sumNumOk / (val.sumNumIn === 0 ? 1 : val.sumNumIn)) * 100)
            }
            return undefined
        })
        return num
    }*/


    function openModal(data) {
        setOpenDimensionModal(true)
    }

   


    return (
        <>
            <Grid item xs={4}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={donutChartFullScreen} content={donutChart} title={'Datasource DQI'} description={languageContext.dictionary['dataSourceDQI']} />

            </Grid >

            <Grid item xs={8}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={tablesLollipopFullScreen} content={tablesLollipop} title={'Trends'} description={languageContext.dictionary['datasourcesDQILollipop']} />

            </Grid >

            <Grid item xs={10}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={chartTripleTemporalFullScreen} content={chartTripleTemporal} showTooltip={true} title={'Datasource DQI Time Evolution'} description={languageContext.dictionary['qualityPointChartTriple']} />
            </Grid >

            <Grid item xs={2}>
                <DashboardChartComponent loading={false} content={executionSelector} showTooltip={false} hideFullScreen={true} title={'Execution Selector'} description={languageContext.dictionary['exectuionSelector']} />
            </Grid >

            <Grid item xs={12}>
                {showDetails && <DashboardChartComponent loading={props.loading} fullscreenContent={executionsDetailFullScreen} content={executionsDetail} title={'Details'} description={languageContext.dictionary['detaillExecution']} />}
            </Grid >


            <AqtivaDialog visible={openDimensionModal}
                title={`DQI Dimension`}

                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                cancelCallback={handleClose}
                maxWidth={width}
            >
                <div style={{ width: width * 0.9, height: height * 0.7 }}>
                    {!props.loading && <DQIDimensionComponent data={props.dataSource.dimensionDQIList} DQIByDimension={props.dataSource.dimensionDQIList} />
                    }
                </div>

            </AqtivaDialog>
        </>

    );
}

