import React, { useEffect, useState } from 'react';
import { Typography, FormControl, Tooltip, Button, Grid, Fab, TextField } from '@material-ui/core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faAd } from '@fortawesome/free-solid-svg-icons'
import { AqtivaDialogDataRepository } from 'components/data/DataRepositoryShare/EntityDataRepository/AqtivaDialogDataRepository'
import QualityDatasetRepositoryPage from 'components/data/DataPageForm'
import TagEnvironmentEnrich from 'components/common/TagEnvironmentEnrich';
import * as actions from 'actions'
import trackEvent from 'trackEvent'

import * as commonStyles from 'style/CommonStyles'
import { Autocomplete } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));
export default function SourceOfTruth(props) {
    const dataformats = useSelector(store => Object.values(store.dataformats))
    const user = useSelector(store => store.user)

    const dispatch = useDispatch();
    const classes = useStyles();



    const [selectedJoinType, setSelectedJoinType] = useState("inner_join")
    const [openDialogDataRepository, setOpenDialogDataRepository] = useState(false)
    const [selectedDataformatIdFromDialog, setSelectedDataFormatIdFromDialog] = useState(undefined) // si llamamos al dialogo de seleccionar dataformat, el id seleccionado
    const [selectedDataFormat, setSelectedDataFormat] = useState('')
    const [mappings, setMappings] = useState([])
    const [alias, setAlias] = useState('')
    const [tag, setTag] = useState('')
    const [useTag, setUseTag]= useState(false)
    const [tagDescription, setTagDescription] = useState('')

  
    useEffect(() => {
        let join = {}
        join.originDatasourceId = props.datasource && props.datasource.dataset && props.datasource.dataset._id
        join.datasourceEnrichName = selectedDataFormat && selectedDataFormat.name
        join.datasourceEnrichId = selectedDataFormat && selectedDataFormat._id
        join.datasourceEnrichType = selectedDataFormat && selectedDataFormat.format
        join.datasourceEnrichSeparator = selectedDataFormat && selectedDataFormat.separator
        join.columns = selectedDataFormat && selectedDataFormat.columns
        join.joinType = "inner_join"
        join.mappingsList = mappings
        join.alias = alias
        join.useTag = useTag
        if (useTag) {
            join.tag = tag
            join.tagDescription = tagDescription
        }
        join.id = props.joinInfo && props.joinInfo.id ? props.joinInfo.id : Date.now().toString(36) + Math.random().toString(36).substr(2)

        props.setJoin(join)
    }, [alias,tag, tagDescription, useTag, selectedJoinType, selectedDataFormat, mappings])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.newJoin) {
            let mapping = {}
            let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
            mapping.id = newId
            setMappings([mapping])
        }
    }, [props.newJoin])

    useEffect(() => {
        if (props.joinInfo) {
            setSelectedJoinType(props.joinInfo && props.joinInfo.joinType && props.joinInfo.tagName !== null ?
                props.joinInfo.joinType : '')

            setSelectedDataFormatIdFromDialog(props.joinInfo && props.joinInfo.datasourceEnrichId)

            obtainAndSelectDataformatFromId()
            setMappings(props.joinInfo && props.joinInfo.mappingsList)
            setAlias(props.joinInfo && props.joinInfo.alias)
            setTag(props.joinInfo && props.joinInfo.tag)
            setTagDescription(props.joinInfo && props.joinInfo.tagDescription)
            setUseTag(props.joinInfo && props.joinInfo.useTag)

        }
    }, [props.joinInfo])// eslint-disable-line react-hooks/exhaustive-deps


    async function obtainAndSelectDataformatFromId() {
        //lo primero obtenemos el dataformat del id
        setSelectedDataFormat(undefined)
        var dataformatFiltered = undefined
        if (selectedDataformatIdFromDialog !== undefined) {
             dataformatFiltered = dataformats.filter(x => x._id === selectedDataformatIdFromDialog) 
        }
        if (props.joinInfo && props.joinInfo.datasourceEnrichId) {
             dataformatFiltered = dataformats.filter(x => x._id === props.joinInfo.datasourceEnrichId)  
        }
        if (dataformatFiltered !== undefined && dataformatFiltered.length > 0) {
            dispatch(actions.fetchDataformat(dataformatFiltered[0]._id)).then(response => {
                            //añadir este dataframe a la lista de dataframes del selector y marcarlo como seleccionado (selectedDataFormat, dataformats)
                setSelectedDataFormat(response.data)
            })
        }
    }

    function handleSelectDatasetForExportationId(id) {
        setSelectedDataFormatIdFromDialog(id)
    }


    function onCloseDialogRepository(value) {
        dispatch(actions.fetchDataFormats(user))
        setOpenDialogDataRepository(value)
    }

    function handleAddMapping() {
        let mapping = {}
        let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
        mapping.id = newId
        let newMappings = JSON.parse(JSON.stringify(mappings))
        newMappings.push(mapping)
        setMappings(newMappings)
    }

    function handleDeleteMapping(mapping2Delete) {
        let newMappings = mappings.filter(function (element) {
            return element.id !== mapping2Delete.id;
        });
        setMappings(newMappings)
    }

    function handleChangeColumnMappingOrigin(mapping2update, value) {
        mapping2update.originColumn = value
        var newMappings = JSON.parse(JSON.stringify(mappings))

        let index = newMappings.findIndex(x => x.id === mapping2update.id)
        newMappings[index] = mapping2update
        setMappings(newMappings)
    }

    function handleChangeColumnMappingDestination(mapping2update, value) {
        mapping2update.destinationColumn = value
        var newMappings = JSON.parse(JSON.stringify(mappings))
        let index = newMappings.findIndex(x => x.id === mapping2update.id)
       
        newMappings[index] = mapping2update
        setMappings(newMappings)
    }


    return (
        <>
            {openDialogDataRepository && <AqtivaDialogDataRepository
                dialog={openDialogDataRepository}
                setDialog={onCloseDialogRepository}
                title="Data Repository"
                showConfirmButton={true}
                fullWidth={true}
                confirmText={'Select DataFormat'}
                titleIcon={faDatabase}
                confirmCallback={(event) => { obtainAndSelectDataformatFromId(); setOpenDialogDataRepository(false) }}
            >
                <QualityDatasetRepositoryPage selectDatasetDialog={true} user={user} defaultValue={1}
                    handleSelectDatasetForExportationId={handleSelectDatasetForExportationId} />
            </AqtivaDialogDataRepository>}


            {mappings && mappings.length > 0 && <Grid container spacing={1} style={{ marginTop: "20px" }}>
                <Grid item xs={6}>
                    <Button style={{ color: commonStyles.mainColor }}
                        onClick={event => {
                            trackEvent('DialogCreateDataSource', 'Data Repository')
                            setOpenDialogDataRepository(true)
                        }}>
                        Select a dataformat from the repository
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">{selectedDataFormat && selectedDataFormat.name}</Typography>
                </Grid>
                <TagEnvironmentEnrich tag={tag} setTag={setTag} tagDescription={tagDescription} setTagDescription={setTagDescription} />
                {props.errors && <Grid item xs={12}>
                    <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors.datasourceEnrichName}</Typography>
                </Grid>}
                {props.errors && <Grid item xs={12}>
                    <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors.joinType}</Typography>
                </Grid>}

                <Grid item xs={6}>
                    <Typography style={{ marginTop: "20px" }} variant="subtitle2">Unique Alias</Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: '100%' }}>
                        <TextField style={{ width: '100%' }}
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            id="AliasName"
                            label="Alias"
                            margin="dense"
                            value={alias}
                            onChange={(event) => { setAlias(event.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                {props.errors && <Grid item xs={12}>
                    <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors.alias}</Typography>
                </Grid>}

                {mappings && mappings.length > 0 && mappings.map((mapping, index) => {
                    return (
                        <>
                            <Grid item xs={2}>
                                <Typography style={{ marginTop: "20px" }} variant="subtitle2">Column Mapping {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={{ width: '100%', }}>


                                    <Autocomplete
                                        style={{ minWidth: "20%" }}
                                        autoWidth={true}
                                        labelId="origin-column"
                                        id="origin-column"
                                        value={mapping.originColumn ? mapping.originColumn : undefined}
                                        label="origin column"
                                        onChange={(event, newValue) => {
                                            handleChangeColumnMappingOrigin(mapping, newValue)
                                        }}

                                        options={(props.source && props.source.length > 0) ? props.source : []}
                                        getOptionLabel={(data) => (data) ? `${data.name}` : ''}
                                        renderInput={(e) => <TextField {...e} label="Select origin datasource column" />}
                                    />

                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={{ width: '100%', }}>

                                    <Autocomplete
                                        style={{ minWidth: "20%" }}
                                        autoWidth={true}
                                        labelId="Enriched-column"
                                        id="Enriched-column"
                                        value={mapping.destinationColumn? mapping.destinationColumn : undefined}
                                        label="Enriched column"
                                        onChange={(event, newValue) => {
                                            handleChangeColumnMappingDestination(mapping, newValue)
                                        }}

                                        options={(selectedDataFormat) ? selectedDataFormat.columns : []}
                                        getOptionLabel={(data) => (data) ? `${data.name}` : ''}
                                        renderInput={(e) => <TextField {...e} label="Select Enriched datasource column" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                {index !== 0 && <Tooltip title={"Delete mapping"} style={{ marginTop: "10px" }}>
                                    <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                        onClick={() => { handleDeleteMapping(mapping) }}>
                                        <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                    </Fab>
                                </Tooltip>}
                            </Grid>
                            {props.errors && <Grid item xs={12}>
                                <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].destinationColumn}</Typography>
                                <Typography style={{ color: "red" }} variant='subtitle2'>{props.errors && props.errors[index] && props.errors[index].originColumn}</Typography>
                            </Grid>}
                        </>
                    )
                })
                }

                < Grid item xs={12}>
                    <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "20px" }}
                        color="primary" onClick={event => {
                            handleAddMapping()
                        }}>
                        <FontAwesomeIcon icon={faAd} style={{ marginRight: '5%', marginTop: '10%', color: commonStyles.mainColor, fontSize: 15 }} />
                        add new Mapping
                    </Button>
                </Grid>
            </Grid>}

        </>
    )
}