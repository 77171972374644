
import quality from '../apis/quality';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
//import qualityLocalHost from '../apis/qualityLocalHost';

//import {axiosHeader} from './../util/APIUtils'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';


export const createSchedule = (datasourceId, environment, scheduleDTO, userId) => async (dispatch, getState) => {


  var returnValue = {}
  await quality.post(`/databricks/create/schedule/${datasourceId}/${environment}/${userId}`, scheduleDTO, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Schedule Created`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const editSchedule = (datasourceId, environment, scheduleDTO, userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/update/schedule/${datasourceId}/${environment}/${userId}`, scheduleDTO, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const editScheduleEnvironment = (datasourceId, environment, scheduleDTO, userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/update/environment/schedule/${datasourceId}/${environment}/${userId}`, scheduleDTO, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const deleteSchedule = (scheduleId, userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.delete(`/databricks/delete/schedule/${scheduleId}/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
    dispatchInfo(dispatch, `Schedule Deleted`)
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const updateScheduleNewVersion = (scheduleId, userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/update/version/schedule/${scheduleId}/${userId}`, null, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
    dispatchInfo(dispatch, `Schedule updated`)
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}


export const getSchedulesFromDatasource = (datasourceId, userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.get(`/databricks/get/schedules/${datasourceId}/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue
}

export const getSchedulesFromUserId = (userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.get(`/databricks/get/schedules/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue
}

export const getAllSchedulesFromUserId = (userId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.get(`/databricks/get/all/schedules/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue
}

