import React from 'react';
import { Typography } from '@material-ui/core';
import { LanguageContext } from 'language/LanguageContext'
import * as commonStyles from 'style/CommonStyles'

export default function DataformatDetailForm(props) {

    const languageContext = React.useContext(LanguageContext); 

    const [dataformat, setDataFormat] = React.useState({})

    React.useEffect(()=>{
        if(props.dataformat !==undefined){
            setDataFormat(props.dataformat)
        }
        
    }, [props.dataformat])

    return(
        <div>
            <div style={commonStyles.adjacentLeft}>
        <Typography variant='subtitle2'>{languageContext.dictionary['dataformatName']}</Typography>
        <Typography variant='caption'>{dataformat.name}</Typography>
        </div>

        <div style={commonStyles.adjacentLeft}>
        <Typography variant='subtitle1'>{languageContext.dictionary['dataformatFormat']}</Typography>
        <Typography variant='caption'>{dataformat.format}</Typography>
        </div>

        <div style={commonStyles.adjacentLeft}>
        <Typography variant='body2'>{languageContext.dictionary['dataformatSeparator']}</Typography>
        <Typography variant='caption'>{dataformat.separator}</Typography>
        </div>

        <div style={commonStyles.adjacentLeft}>
        <Typography variant='body2'>{languageContext.dictionary['dataformatSource']}</Typography>
        <Typography variant='caption'>{dataformat.source}</Typography>
        </div>
        </div>

    )
}