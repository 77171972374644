import React, { useState, useEffect } from 'react'
import {
     FormControlLabel, Switch, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "@material-table/core";
import { Tooltip } from '@material-ui/core'
import * as renderHelper from '../../ResultStorageConfiguration/ResultStorageTable/RenderUtils'

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
    },
    fabWarning: {
        margin: theme.spacing(1),
        backgroundColor: "yellow"

    }

}));



export default function EnvironmentResultStorageTable(props) {
    const classes = useStyles();

    const [data, setData] = useState([])
    const [selectedStoragesIds, setSelectedStoragesIds] = useState([])

    // here we initialize all possible storages from the main configuration
    useEffect(() => {

        if (props.storages && props.storages.length > 0) {
            setData(props.storages)
        }

    }, [props.storages, props.storages && props.storages.length])// eslint-disable-line react-hooks/exhaustive-deps


    //here we pass on which storages are already enabled in the selected environment
    useEffect(() => {
        if (props.enabledStorages) {
            let enabledStoragesIds = []
            for (let i = 0; i < props.enabledStorages.length; i++) {
                enabledStoragesIds.push(props.enabledStorages[i].id)
            }
            setSelectedStoragesIds(enabledStoragesIds)
        }
    }, [props.enabledStorages, props.environmentConfiguration && props.environmentConfiguration.name])// eslint-disable-line react-hooks/exhaustive-deps


    //here we update environment configuration with the enabled storages
    //we complete the storage information based on the selectedStoragesIds
    useEffect(() => {
        let storagesComplete = []
        for (let i = 0; i < selectedStoragesIds.length; i++) {
            let storage = data.find(element => element.id === selectedStoragesIds[i])
            storagesComplete.push(storage)
        }
        /* props.setEnabledStorages(storagesComplete) */
        props.setStorageResultConfig(storagesComplete)

    }, [selectedStoragesIds])// eslint-disable-line react-hooks/exhaustive-deps

    const handleToggle = (value) => () => {
        const currentIndex = selectedStoragesIds.indexOf(value);
        const newChecked = [...selectedStoragesIds];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedStoragesIds(newChecked);
    };



    return (
        <>
            {data && data.length > 0 && < MaterialTable
                style={{ width: "100%" }}
                title="Storages"
                data={data}
                columns={
                    [
                        { title: 'Storage name', field: 'storageName' },
                        {
                            title: 'Storage Type', field: 'storageType', render: (rowData) => {
                                if (rowData.storageType === "azure-storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>azure-storage</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/azure/Storage-Accounts.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure-sqldatabase") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>azure-sqldatabase</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/azure/sql-database-generic.png')} />
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "AWS") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>AWS</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/AmazonIcon.png')} />
                                        </Tooltip>
                                    )
                                }

                            }
                        },
                        {
                            title: 'Description', field: 'description', render: (rowData) => {
                                if (rowData.storageType === "azure-storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Resource Group Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.resourceGroupName}</strong></h3>
                                                <h3>Storage Account Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.storageAccountName}</strong></h3>
                                                <h3>Container  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.containerName}</strong></h3>
                                                <h3>Path: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.path}</strong></h3>
                                            </React.Fragment>}>
                                            <Typography variant='subtitle2'>{renderHelper.renderDescription(rowData)}</Typography>
                                        </Tooltip>
                                    )

                                }
                                if (rowData.storageType === "azure-sqldatabase") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Resource Group Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.resourceGroup}</strong></h3>
                                                <h3>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.server}</strong></h3>
                                                <h3>Database Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.database}</strong></h3>
                                                <h3>Schema  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.schema}</strong></h3>
                                                <h3>Table  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.table}</strong></h3>
                                            </React.Fragment>}>
                                            <Typography variant='subtitle2'>{renderHelper.renderDescription(rowData)}</Typography>
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "AWS") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Key Id: <strong style={{ color: "#2fb7e0" }}>{rowData.awsProperties.s3Keyid}</strong></h3>
                                                <h3>Bucket Name: <strong style={{ color: "#2fb7e0" }}>{rowData.awsProperties.s3BucketName}</strong></h3>
                                                <h3>Region: <strong style={{ color: "#2fb7e0" }}>{rowData.awsProperties.s3Region}</strong></h3>
                                            </React.Fragment>}>
                                            <Typography variant='subtitle2'>{renderHelper.renderDescription(rowData)}</Typography>
                                        </Tooltip>
                                    )
                                }

                            }
                        },
                        {
                            title: 'Actions', field: 'enabled', filtering: false, render: (rowData) => {
                                return (
                                    <>
                                        <FormControlLabel className={classes.notificationCheck}
                                            control={
                                                <Tooltip title="Enable Storage for selected Environment">
                                                    <Switch
                                                        edge="end"
                                                        color="primary"
                                                        onChange={handleToggle(rowData.id)}
                                                        checked={!!selectedStoragesIds.find(element => element === rowData.id)}
                                                    />
                                                </Tooltip>
                                            }
                                        />
                                    </>
                                )
                            }
                        },
                    ]}
                options={{
                    actionsColumnIndex: -1,
                    filtering: false,
                    search: false,
                    pageSize: 5,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                        backgroundColor: commonStyles.mainColor,
                        color: '#FFF'
                    },
                    cellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                    filterCellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                }}

                components={{
                    Toolbar: props => (
                        <div>

                        </div>
                    ),
                }}
            />}
        </>

    )
}
