import React from 'react'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'


export const getProjectIcon = (source) => {
        if(source === 'AWS'){
            return(
                <img alt="" style={{ height: '40px', width: '40px' }}
                src={require('resources/icons/cloud_proc2_black.png')}
                />
                
            )
        }
        else if(source === 'Azure'){
            return(
                <img alt="" style={{ height: '40px', width: '40px' }}
                src={require('resources/icons/cloud_proc2_black.png')}
                />
              
            )
        }
        else{
            return(
                <img alt="" style={{ height: '40px', width: '40px' }}
                src={require('resources/icons/server_proc1_black.png')}
                />
            )
        }
}


export const createProjectCard = (project, cardId, history, theme) => {
        let projectIcon = getProjectIcon(project.environment)
        return(
            <Grid item id={cardId} key={cardId}>
                <Card style={{width: '11vw', height: '100px', marginRight:'2.5vw'}}
                onClick = {event => history.push(`/${process.env.REACT_APP_PREFIX}/project/${project._id}`)}>
                    <Button style={{width: '100%', height: '100%'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                {projectIcon}
                            </Grid>
                            <Grid item xs={12}>
                            <div style={theme.common.text.boldRegularText}>
                            {project.name}
                             </div>
                                
                            </Grid>
                        </Grid>
                    </Button>
                </Card>
            </Grid>
        );
    }