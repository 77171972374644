import React from 'react';

import {
         Accordion, AccordionSummary, AccordionDetails, Grid, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import {  QualityTheme } from 'style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import DataCredentialsTable from './DataCredentialsTable'
import * as commonStyles from 'style/CommonStyles'


export default function DataCredentials(props) {


        return (
                <MuiThemeProvider theme={QualityTheme}>

                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Data Credentials</Typography>
                                        </Grid>
                                </AccordionSummary>
                                <AccordionDetails >
                                        <DataCredentialsTable configuration={props.configuration} dataStorageCredentials={props.dataStorageCredentials} setDataStorageCredentials={props.setDataStorageCredentials} />
                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}


