import quality from '../apis/quality';
//import qualityLocalHost from '../apis/qualityLocalHost';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';

import {axiosHeader, dispatchError} from '../components/common/axios/axiosHelper';
import {
    FETCH_STREAM_EVENTHUB_CONFIG

    } from './types'

export const fetchEventHubTree = (clientId,domain,secret,subscription) =>{
    return async function(dispatch, getState){


    await quality.get(`/ehubs/management/eventhubs/list/tree/credentials/${clientId}/${domain}/${secret}/${subscription}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : FETCH_STREAM_EVENTHUB_CONFIG,
            payload: response.data
        });
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
      });
    }
    
};

export const getStreamEventHubDetails = (clientId,domain,secret,subscription, resourcegroup,namespace,name) => async (dispatch,getState) =>{
    

    var returnValue = {result:'undefined'}

    await quality.get(`/ehubs/management/eventhub/detail/credentials/${clientId}/${domain}/${secret}/${subscription}/resourcegroup/${resourcegroup}/namespace/${namespace}/name/${name}`,{
        headers: axiosHeader()
      }).then(function (response) {
        returnValue = {result:'ok', data:response.data}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
      });
    
      return returnValue
};

export const getStatisticsFromStream = (userid,endPoint,entityPath,sakName,sak) => async (dispatch,getState) => {
    
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/ehubs/statistics/beginning/${userid}/nada/${endPoint}/${entityPath}/${sakName}/${sak}/2`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
          returnValue = {result:'ko', data:'No data found'}
            
          });
    
    
   return returnValue
};


export const createEventHub = (clientId,domain,secret,subscription, configurationDTO) => async (dispatch,getState) => {
    
  var returnValue = {result:'undefined'}
  
       
  await quality.post(`/ehubs/management/eventhub/create/credentials/${clientId}/${domain}/${secret}/${subscription}`,
    configurationDTO,{
      headers: axiosHeader()
    }).then(function (response) {
          
          returnValue = {result:'ok', data:response.data}
      })
      .catch(function (error) {
          dispatchError(dispatch,error)
          
        });
  
  
 return returnValue
};



export const getEHubsData = (endPoint, entityPath, sakName, sak, n_messages) => async (dispatch,getState) => {
 

  var returnValue = {result:'undefined'}

 await quality.get(`/ehubs/data/beginning/${endPoint}/${entityPath}/${sakName}/${sak}/${n_messages}`,{
  headers: axiosHeader()
}).then(function (response) {
      returnValue = response.data
      
  })
  .catch(function (error) {
      returnValue = {result:'ko',error:error}
    });
    return returnValue
};