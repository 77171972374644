import React, { useEffect } from 'react';
import { TextField, Typography, Grid, FormControlLabel } from '@material-ui/core';
import AqtivaDialog from 'components/common/AqtivaDialog'
import * as errorHelper from '../../AddDataCredentialsDialog/Helpers/ErrorHelper'
import * as commonStyles from 'style/CommonStyles'
import AqtivaDialogEncryptString from 'components/common/AqtivaDialogEncryptString'




export default function EditAWSServerDialog(props) {

    const [displayName, setDisplayName] = React.useState('')
    const [region, setRegion] = React.useState('')
    const [accessKey, setAccessKey] = React.useState('')
    const [secretKey, setSecretKey] = React.useState('')
    const [bucketName, setBucketName] = React.useState('')
    const [errors, setErrors] = React.useState(undefined)
    const [visibleEncrypt, setVisiblevisibleEncrypt] = React.useState(false)


    useEffect(() => {

        if (props.selectedDataCredentials && props.selectedDataCredentials.displayName && props.selectedDataCredentials.awsCredentialsDTO) {
            setDisplayName(props.selectedDataCredentials.displayName)
            setRegion(props.selectedDataCredentials.awsCredentialsDTO.region ? props.selectedDataCredentials.awsCredentialsDTO.region : '')
            setAccessKey(props.selectedDataCredentials.awsCredentialsDTO.accessKey ? props.selectedDataCredentials.awsCredentialsDTO.accessKey : '')
            setSecretKey(props.selectedDataCredentials.awsCredentialsDTO.encryptedSecretKey ? props.selectedDataCredentials.awsCredentialsDTO.encryptedSecretKey : '')
            setBucketName(props.selectedDataCredentials.awsCredentialsDTO.bucketName ? props.selectedDataCredentials.awsCredentialsDTO.bucketName : '')

        }
    }, [props.selectedDataCredentials])

    async function editArrayElement(datacredentials) {
        let updatedDataCredentials = JSON.parse(JSON.stringify(props.selectedDataCredentials))
        let connectionObject = {}
        connectionObject.region = region
        connectionObject.accessKey = accessKey
        connectionObject.encryptedSecretKey = secretKey
        connectionObject.bucketName = bucketName

        updatedDataCredentials.awsCredentialsDTO = connectionObject
        updatedDataCredentials.displayName = displayName
        let errorsToCheck = errorHelper.checkForErrorsAWS(updatedDataCredentials)

        if (!errorsToCheck.hasErrors) {
            let index = datacredentials.findIndex(x => x.id === updatedDataCredentials.id)
            datacredentials[index] = updatedDataCredentials
            props.setData(datacredentials)
            props.setUpdateCounter(props.updateCounter + 1)
            props.setShowEditDialog(false)
            resetState()
        }
        else {
            setErrors(errorsToCheck)
        }
    }

    function resetState() {
        setRegion('')
        setDisplayName('')
        setSecretKey('')
        setAccessKey('')
        setErrors(undefined)
    }


    return (
        <>
            <AqtivaDialog visible={props.showEditDialog}
                maxWidth={'lg'}
                title={'update AWS server Credentials'}
                cancelText={'Close'}
                confirmText={'Store and Use'}
                showConfirmButton={true}
                cancelCallback={() => { props.setShowEditDialog(false) }}
                confirmCallback={async () => { editArrayElement(props.data) }}
            >
                 <AqtivaDialogEncryptString visible={visibleEncrypt}
                        connectionMethod={props.connectionMethod}
                        setVisible={setVisiblevisibleEncrypt}
                        confirm={setSecretKey}
                        sName={props.id && props.id + "localsttkk"}
                    />
                <div style={{ width: '100%' }}>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display name:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="DisplayName"
                                    label="Display name"
                                    margin="dense"
                                    value={displayName}
                                    onChange={(event) => { setDisplayName(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Region:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="region"
                                    label="Region"
                                    margin="dense"
                                    value={region}
                                    onChange={(event) => { setRegion(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Bucket Name:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="region"
                                    label="Bucket Name"
                                    margin="dense"
                                    value={bucketName}
                                    onChange={(event) => { setBucketName(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Access Key:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="accessKey"
                                    id="AccessKey"
                                    label="AccessKey"
                                    margin="dense"
                                    value={accessKey}
                                    onChange={(event) => { setAccessKey(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Secret Key:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="secretKey"
                                    id="secretKey"
                                    label="secretKey"
                                    margin="dense"
                                    type="password"
                                    value={secretKey}
                                    onClick={(event) => { setVisiblevisibleEncrypt(true) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        {errors && errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{errors.bigQueryCredentials}</Typography>}
                    </Grid>
                </div>
            </AqtivaDialog>
        </>
    )
}