import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {Card,CardContent,CardHeader,Typography,Grid} from "@material-ui/core";
import chartIcon3 from "./chartIcon3.png";

import * as commonStyles from 'style/CommonStyles'

//En este componente usamos las props para indicar el número total de event rules ==> {props.dataTotalRecords.total}
export default function CardTotalEventRules(props) {
  const theme = useTheme();
  //const [totalValue, setTotalValue] = React.useState([]);
/*
  React.useEffect(() => {
    setTotalValue(props.dataTotalRecords.total);
  }, [props.dataTotalRecords]);*/
  return (
    <div>
      <Card
        style={{...theme.QualityKPIS.mainContainer,marginTop: "0px",height: "320px"}}
        variant="outlined"
      >
        <CardHeader
          style={{
            backgroundColor: commonStyles.mainColor,
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.1), 0px 4px 5px 1px rgba(0,0,0,0.1), 0px 3px 7px 2px rgba(0,0,0,0.1)",
            height: "50px",
          }}
          title={
            <Typography
              style={{
                color: "white",
                marginTop: "-10px",
                marginBottom: "-10px",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "18px",
              }}
              variant="subtitle1"
            >
              {" "}
              Total UCs
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                src={chartIcon3}
                alt="element"
                height="100px"
                width="100px"
                style={{ marginTop: "30px" }}
              ></img>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography
                style={{ ...theme.QualityKPIS.text }}
                variant="h6"
                fontWeight="bold"
              >
                {Math.round(props.dataTotalRecords*1000)/1000}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
