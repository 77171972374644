import React from 'react';
import {
    TextField, Typography, Grid, FormControlLabel
} from '@material-ui/core';


import BigQueryInputCredentials from './BigQueryInputCredentials';
import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent';




export default function BigQueryConnectionInfo(props) {

    const [projectId, setProjectId] = React.useState('')
    const [encryptedCredentialsFile, setEncryptedCredentialsFile] = React.useState('')
    //const [serviceAccountPrivateKey, setServiceAccountPrivateKey] = React.useState('')
    const serviceAccountPrivateKey=''
   

    React.useEffect(() => {
        let connectionObject = {}
        connectionObject.projectId = projectId
        connectionObject.encryptedCredentialsFile = encryptedCredentialsFile
        props.setConnectionObjectBigQuery(connectionObject)
    }, [projectId, serviceAccountPrivateKey, encryptedCredentialsFile])// eslint-disable-line react-hooks/exhaustive-deps


    function onUpload(res) {

        console.log('archivo subido', res)
    }
    function setSelectedEnvironment(){

    }
    function setSelectedEnvironmentStates(){
        
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                <div style={{ width: '150px', marginRight: '20px' }}>
                    <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                        setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                </div>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display Name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="DisplayName"
                                label="Display name"
                                margin="dense"
                                value={props.displayName}
                                onChange={(event) => { props.setDisplayName(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Project ID:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="ProjectId"
                                label="Project"
                                margin="dense"
                                value={props.projectId}
                                onChange={(event) => { setProjectId(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Crendentials:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <BigQueryInputCredentials onUpload={onUpload} setEncryptedCredentialsFile={setEncryptedCredentialsFile} id={props.id}
                        buttonText={'Upload File'} />
                </Grid>
                {encryptedCredentialsFile && <Grid item xs={12}>
                    <div style={{marginTop: '2%'}}> <Typography variant='subtitle2'>Credentials stored </Typography></div>
                </Grid>}

            </div>
        </>
    )
}