
import quality from '../apis/quality';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
import qualityLocalHost from '../apis/qualityLocalHost';

//import {axiosHeader} from './../util/APIUtils'
import { axiosHeader, dispatchError } from '../components/common/axios/axiosHelper';


export const setSecret = (name, value) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/keyvault/set/${name}`, value, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue



}



export const getSecret = (name) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/keyvault/get`, name, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data
  })
    .catch(function (error) {
      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

