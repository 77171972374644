import React from 'react';
import {   Grid } from '@material-ui/core';

import SourceOfTruth from './SourceOfTruth'




export default function FormMasterDataNew(props) {



    const [join, setJoin] = React.useState(undefined)
    //const [errors, setErrors] = React.useState(undefined) //TODO:
    


/*
    function createRuleColumn(column) {
        return {
            cols: [column.name],
            joinOperation: 'concat'
        }
    }
*/
    function createSpecific() {
        //creamos el objeto de regla especifica
        const specific = {
            subtype: 'masterData',
            masterDataJoin: join
        }
        props.setSpecific(specific)

    }
/*
    function setDefaultSpecific(data) {

        //creamos el objeto de regla especifica
        const specific = {
            subtype: 'masterData',
            masterDataJoin: join
        }
        props.setSpecific(specific)

    }
    */


    React.useEffect(() => {
        createSpecific()
    }, [join]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div >



            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <SourceOfTruth master={true} configuration={props.configuration} 
                    setJoin={setJoin} 
                    //errors={errors} //TODO: CHECK
                    //newJoin={true} 
                    datasource={props.datasource} 
                    joinInfo={props.editableRule && props.editableRule.masterDataJoin ? props.editableRule.masterDataJoin : false} 
                    newJoin={props.editableRule && props.editableRule.masterDataJoin ? false : true} 
                    errors={props.errorsMasterData}
                    source={props.source}/>
                </Grid>

            </Grid>
        </div>
    )
}
