import React from 'react';

export default function FormRuleFormat(props) {
    //const dataformats = useSelector(store => Object.values(store.dataformats))
    
    /* React.useEffect(() => {
        let dataset
        if (dataformats && dataformats.length > 0) {
            dataset = dataformats.find(dataformat => dataformat._id === props.dataset._id)
        }
        setUpdatedDataset(dataset)
    }, [dataformats && dataformats.length]) */

    
    function createSpecific(ruleColumnNames) {
        //creamos el objeto de regla especifica
        const specific = {
            subtype: 'statistics'
            
        }
        props.setSpecific(specific)

    }

    React.useEffect(() => {
        if (props.commonRule.common.selected_columns && props.commonRule.common.selected_columns.length > 0) {
            createSpecific(props.commonRule.common.selected_columns.map(e => e.cols[0]))
        }
    }, [props.source]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div></div>
    )
}

