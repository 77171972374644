import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as commonStyles from 'style/CommonStyles'


export default function ChartTripleTemporal(props) {

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (props.data && props.data.length > 0) {

            setData(props.data.map(qp => {
                return {
                    ...qp,

                    'score': Number(Number((qp.numOk / qp.numIn) * 100).toFixed(0)),
                    'fecha': new Date(qp.createTime).getTime(),
                    "bulletSettings": { fill: Number(((qp.numOk / qp.numIn) * 100).toFixed(2)) > 70 ? am5.color(commonStyles.colorOk) : am5.color(commonStyles.colorKo) },
                    "numKo": -qp.numKo,
                    "numKoTmp": qp.numKo
                }
            }))


        }
    }, [props.data])


    function showBasicInformation(e) {
        props.showBasicInformation(e)
    }

    React.useLayoutEffect(() => {

        var root = am5.Root.new("chart");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                wheelY: "zoomX",
                layout: root.verticalLayout
            })
        );

        // Craete Y-axes
        var yAxis1 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                x: am5.percent(100),
                centerX: am5.percent(100),
                renderer: am5xy.AxisRendererY.new(root, {}),
                max: 100,
                min: 1

            })
        );

        var label1 = am5.Label.new(root, {
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50
            //x: am5.p0,
            //centerY: am5.p0
        })

        yAxis1.children.unshift(
            label1
        );


        var yAxis2 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                x: am5.percent(100),
                centerX: am5.percent(100),
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        var label2 = am5.Label.new(root, {
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50
            //x: am5.p0,
            //centerY: am5.p0
        })
        yAxis2.children.unshift(
            label2
        );

        yAxis2.axisHeader.set("paddingTop", 20);

        yAxis2.axisHeader.children.push(am5.Label.new(root, {
            fontSize: commonStyles.tableCellFontSize,
            fontFamily: commonStyles.fontFamily
        }));

        var yAxis3 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                x: am5.percent(100),
                centerX: am5.percent(100),
                renderer: am5xy.AxisRendererY.new(root, {}),
                min: 0,
            })
        );

        var label3 = am5.Label.new(root, {
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50
            //x: am5.p0,
            //centerY: am5.p0
        })
        yAxis3.children.unshift(
            label3
        );

        yAxis3.axisHeader.set("paddingTop", 20);

        yAxis3.axisHeader.children.push(am5.Label.new(root, {
            fontWeight: "500"
        }));



        yAxis1.ghostLabel.events.on("boundschanged", function () {
            syncTitles()
        })

        yAxis2.ghostLabel.events.on("boundschanged", function () {
            syncTitles()
        })

        yAxis3.ghostLabel.events.on("boundschanged", function () {
            syncTitles()
        })


        function syncTitles() {
            var g1w = yAxis1.ghostLabel.width();
            var g2w = yAxis2.ghostLabel.width();
            var g3w = yAxis3.ghostLabel.width();

            let max = Math.max(g1w, g2w, g3w);

            yAxis1.ghostLabel.set("minWidth", max)
            yAxis2.ghostLabel.set("minWidth", max)
            yAxis3.ghostLabel.set("minWidth", max)
        }


        // Stack Y-axes
        chart.leftAxesContainer.set("layout", root.verticalLayout);

        var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({
            rotation: -40,
            centerY: am5.p50,
            centerX: am5.p50,
            paddingRight: 15
        });
        // Create X-Axis
        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "day", count: 1 },
                renderer: xRenderer
            })
        );




        // Create series
        function createSeriesOkKo(yAxis) {
            // //
           

            let seriesKo = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "num_ko",
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,

                    valueYField: "numKoTmp",
                    valueXField: "fecha",
                    categoryXField: "fecha",
                    fill: am5.Color.fromString(commonStyles.colorKo),

                    tooltip: am5.Tooltip.new(root, {
                        tooltipText: "{valueY}",
                        pointerOrientation: 'up'
                    }),
                })
            );

            seriesKo.get("tooltip").label.set("text", "Num Ko: {valueY}");
            seriesKo.data.setAll(data);

            let seriesOk = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "num_ok",
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "numOk",
                    valueXField: "fecha",
                    categoryXField: "fecha",
                    tooltip: am5.Tooltip.new(root, {
                        tooltipText: "{valueY}"
                    }),

                    fill: am5.Color.fromString(commonStyles.colorOk),

                })
            );


            seriesOk.get("tooltip").label.set("text", "Num Ok: {valueY}");



            seriesOk.data.setAll(data);
        }


        function createSerieScore(yAxis) {
            let series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: "score",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "score",
                    valueXField: "fecha",
                    locationX: 0.5
                }));

            series.bullets.push(function () {
                let circleTemplate = am5.Template.new({
                    radius: 6,
                    templateField: "bulletSettings",
                    fill: series.get("fill"),
                    strokeWidth: 2,
                    text: "DQI: {valueY}",
                    tooltipText: "DQI: {valueY}",
                    showTooltipOn: "always",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "down",
                        paddingTop: 3,
                        paddingBottom: 2,
                        paddingLeft: 4,
                        paddingRight: 4,
                    }),
                    populateText: true,
                    stroke: root.interfaceColors.get("background")
                })

                let circle = am5.Circle.new(root, {}, circleTemplate);

                circle.events.on("click", (e) => {
                    showBasicInformation(e.target._dataItem.dataContext)
                })

                return am5.Bullet.new(root, {
                    sprite: circle,
                    locationX: 0.5
                });
            });

            series.data.setAll(data);
        }

        function createSerieIn(yAxis) {
            let series = chart.series.push(am5xy.LineSeries.new(root, {
                name: 'Num In',
                stacked: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'numIn',
                valueXField: "fecha",
                tooltip: am5.Tooltip.new(root, {
                    tooltipText: "{valueY}",
                    pointerOrientation: "up",
                }),
                categoryXField: "fecha",


            }));


            series.fills.template.setAll({
                fillOpacity: 0.5,
                visible: true
            });

           
            series.get("tooltip").label.set("text", "Rows: {valueY}");

            series.data.setAll(data);
        }


        createSerieScore(yAxis1)
        createSeriesOkKo(yAxis2)
        createSerieIn(yAxis3)

        let yRenderer1 = yAxis1.get("renderer");
        let yRenderer2 = yAxis2.get("renderer");
        let yRenderer3 = yAxis3.get("renderer");

        yRenderer1.labels.template.setAll({
            fill: am5.color(0x000000),
            fontSize: "12px",
            fontFamily: commonStyles.fontFamily
        });
        yRenderer2.labels.template.setAll({
            fill: am5.color(0x000000),
            fontSize: "12px",
            fontFamily: commonStyles.fontFamily
        });
        yRenderer3.labels.template.setAll({
            fill: am5.color(0x000000),
            fontSize: "12px",
            fontFamily: commonStyles.fontFamily
        });

        xRenderer.labels.template.setAll({
            fill: am5.color(0x000000),
            fontSize: "12px",
            fontFamily: commonStyles.fontFamily
        });

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);

       /* if(props.fullscreen === true){
            var exporting = am5plugins_exporting.Exporting.new(root, {
                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                filePrefix: "QualityEvolutionTripleChart"
              });
        }*/
        

        return () => {
            chart.dispose()
        }

    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
            <div id="chart" style={{ width: "100%", height: props.fullscreen === true ? '100%' : "300px" }}></div>
    )
}