import React from 'react';
import {Checkbox, FormControlLabel, TextField} from '@material-ui/core';
import {getDataFromProperties} from './SpecificRuleHelper'
import { ICommonRule, IColumn } from 'interfaces/RuleInterfaces';

interface FormAdHocProps {
    commonRule: ICommonRule,
    setSpecific: (value: any)=>any,
    editableRule: any,
    specific: any,
    source: IColumn [],

}
export default function FormAdHoc(props: FormAdHocProps){

    //const classes = makeStyles(styles(QualityTheme));
    console.log('props.editab', props.editableRule, props.specific)
    const [query,setQuery] = React.useState(getDataFromProperties(props.editableRule, props.specific,'query',''))
    const [querySQL, setQuerySQL] =  React.useState(getDataFromProperties(props.editableRule, props.specific,'querySQL',''))
    const [isSparkSQL, setIsSparkSQL] =React.useState(getDataFromProperties(props.editableRule, props.specific,'isSparkSQL',false))

    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            query: query,
            querySQL: querySQL,
            isSparkSQL: isSparkSQL,
            subtype:'adHoc'
        }
        props.setSpecific(specific)
       
    }
    
    React.useEffect(() => {
        createSpecific() 
    }, [query,querySQL,isSparkSQL]);// eslint-disable-line react-hooks/exhaustive-deps


    return(
        <div style={{marginTop:'5px'}}>
            <div style={{ width: '50%' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isSparkSQL}
                            onChange={event => setIsSparkSQL(event.target.checked)}
                            value="isSparkSQL"
                            color="primary"
                        />
                    }
                    label="Spark SQL where expression"
                />
            </div>
            
           {isSparkSQL === false &&  <div style={{width:'50%'}}>
                <TextField
                    fullWidth={true}
                    id="queryTextField"
                    label="Spark Filter Expression"
                    InputLabelProps={{ shrink: true, style:{ fontSize:'12px'} }}
                    //className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={query} 
                    onChange={event =>{setQuery(event.target.value); }}
                />
            </div>}

            {isSparkSQL === true &&  <div style={{width:'50%'}}>
                <TextField
                    fullWidth={true}
                    id="queryTextFieldSql"
                    label="SQL Expression"
                    InputLabelProps={{ shrink: true, style:{ fontSize:'12px'} }}
                    //className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={querySQL} 
                    onChange={event =>{setQuerySQL(event.target.value); }}
                />
            </div>}
            
          </div>
    )
}
