import React from 'react';
import { Typography, Tabs, Tab } from '@material-ui/core'



//import * as actions from 'actions'

import DatasetDrillDownComponent from './DatasetDrillDownComponent';
import ProjectDrillDownComponent from './ProjectDrillDownComponent';
import QpDrillDownComponent from './QpDrillDownComponent';
import GlobalDQIComponent from './GlobalDQIComponent';
//import { onlyUnique } from 'commonFunctions/commonFunctions'

export default function DashboardTabComponent(props) {
    /*const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const projects = useSelector(store => Object.values(store.projects))

    const languageContext = React.useContext(LanguageContext)
    const [filterObj, setFilterObj] = React.useState(undefined)
    const [projectIdExecutionsEnrich, setProjectIdExecutionsEnrich] = React.useState([])
    const [projectsLicense, setProjectsLicense] = React.useState([])
    const [projectIdExecutions, setProjectIdExecutions] = React.useState([])*/
    const [tabValue, setTabValue] = React.useState(1)


    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }


    return (
        <>
            <Tabs value={tabValue} onChange={handleTabChange}
                variant="scrollable" scrollButtons="off"
                style={{ width: '100%', marginTop: '0px' }}>

                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}> <Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Global DQI`}</Typography> </div>}
                />
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}> <Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Project DQI`}</Typography> </div>}
                />
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Quality points DQI`}</Typography> </div>}
                />
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Datasource DQI`}</Typography> </div>}
                />
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Dataset DQI`}</Typography> </div>}
                />
            </Tabs>

            {
                tabValue === 0 &&
                <div style={{ width: '99%', margin: "1%" }}>
                    <GlobalDQIComponent
                        filterObj={props.filterObj}
                        DQI4Project={props.DQI4Project}
                        DQIByDimension={props.DQIByDimension}
                        executionsByDimension={props.executionsByDimension}
                        executionsByRuleType={props.executionsByRuleType}
                        definitionsByDimension={props.definitionsByDimension}
                        definitionsByRuleType={props.definitionsByRuleType}
                        globalDQI={props.globalDQI}
                        DQIAndExecutions4RuleTypeAndDimension={props.DQIAndExecutions4RuleTypeAndDimension}
                    />
                </div>
            }

            {
                tabValue === 1 &&
                <div style={{ width: '99%', margin: "1%" }}>
                    <ProjectDrillDownComponent
                        filterObj={props.filterObj}
                        DQI4Project={props.DQI4Project}
                        DQI4Project5First={props.DQI4Project5First}
                        globalDQI={props.globalDQI}
                        DQIByDimension={props.DQIByDimension}

                        DQI4ProjectFlag={props.DQI4ProjectFlag}
                        DQI4Project5FirstFlag={props.DQI4Project5FirstFlag}
                        DQIByDimensionFlag={props.DQIByDimensionFlag}


                    />
                </div>
            }

            {
                tabValue === 2 &&
                <div style={{ width: '99%', margin: "1%" }}>
                    <QpDrillDownComponent 
                        filterObj={props.filterObj} 
                        DQI4QP={props.DQI4QP}
                    />
                </div>
            }

            {
                tabValue === 3 &&
                <div style={{ width: '99%', margin: "1%" }}>
                    <DatasetDrillDownComponent filterObj={props.filterObj} />
                </div>
            }
            {
                tabValue === 4 &&
                <div style={{ width: '98%', margin: "2%" }}>
                    <DatasetDrillDownComponent filterObj={props.filterObj} />
                </div>
            }
        </>
    );
}