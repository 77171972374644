import React from 'react';

import SummaryConnectionString from './SummaryConnectionString'
import SummaryConnectionBigQuery from './SummaryConnectionBigQuery'
import SummaryOnPremiseSQL from './SummaryOnPremiseSQL'
import * as commonStyles from 'style/CommonStyles'



import { Typography, Button } from '@material-ui/core';
import SummaryConnectionAws from './SummaryConnectionAws';





export default function SelectResource(props) {

   

    function handleClickBack() {
        if (props.setConnectionPhase && props.connectionPhase) {
            props.setConnectionPhase(props.connectionPhase - 1)
        }
    }
  


    return (

        <div style={{ width: '100%', height: '95%', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Typography variant="h5" gutterBottom>The following settings will be used to connect to your resource:</Typography>

            {props.connectionMethod && props.connectionMethod === "connectionString" ? <SummaryConnectionString displayName={props.displayName} connectionMethod={props.connectionMethod} connectionObjectAccessKey={props.connectionObjectAccessKey} connectionObjectSas={props.connectionObjectSas} /> : <></>}
            {props.resourceType && props.resourceType === "azure-sqlsynapse" ? <SummaryOnPremiseSQL displayName={props.displayName} connectionObjectOnPremise={props.connectionObjectSqlServer} /> : <></>}
            {props.resourceType && props.resourceType === "oracle" ? <SummaryOnPremiseSQL displayName={props.displayName} connectionObjectOnPremise={props.connectionObjectOracle} /> : <></>}
            {props.resourceType && props.resourceType === "mySQL" ? <SummaryOnPremiseSQL displayName={props.displayName} connectionObjectOnPremise={props.connectionObjectMySQL} /> : <></>}
            {props.resourceType && props.resourceType === "sqlServer" ? <SummaryOnPremiseSQL displayName={props.displayName} connectionObjectOnPremise={props.connectionObjectSqlServer} /> : <></>}
            {props.resourceType && props.resourceType === "bigQuery" ? <SummaryConnectionBigQuery displayName={props.displayName} connectionObjectBigQuery={props.connectionObjectBigQuery} /> : <></>}
            {props.resourceType && props.resourceType === "aws" ? <SummaryConnectionAws displayName={props.displayName} connectionObjectAWS={props.connectionObjectAWS} /> : <></>}


            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                <Button onClick={event => { handleClickBack() }} style={{ color: "white", backgroundColor: commonStyles.secondaryColor, marginRight: "30px", marginBottom: "-20px" }}>Back</Button>
            </div>
        </div>
    )
}