import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { Grid, Typography } from '@material-ui/core';
import * as commonStyles from 'style/CommonStyles'

import DashboardChartComponent from '../../Common/Components/DashboardChartComponent';
import TableLlolipopCharts from '../../Common/Components/TableLollipopCharts'
import DonutChart from '../../Common/Charts/DonutChart';

import DQIDimensionComponent from '../../Common/Components/DQIDimensionComponent';
import ChartTripleTemporal from '../../Common/Charts/ChartTripleTemporal';
import ExecutionDetail from '../../Common/Components/ExecutionDetail';
import AqtivaDialog from 'components/common/AqtivaDialog';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import ReturnPreviousAgregationButton from '../ReturnPreviousAgregationButton';
import { LanguageContext } from 'language/LanguageContext';
import * as windowHelper from 'commonFunctions/windowHelper'

export default function ProjectGrid(props) {

    const languageContext = React.useContext(LanguageContext)
    //const [qpList, setQpList] = useState([])
    const [dimension, setDimension] = useState([])
    //const [project, setProject] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const { height, width } = windowHelper.useWindowDimensions();
    const [donutChart, setDonutChart] = React.useState(<div></div>)
    const [chartTriple, setChartTriple] = useState(<div></div>)
    const [chartTripleFullscreen, setChartTripleFullscreen] = useState(<div></div>)

    const [executionDetailsChart, setExecutionDetailsChart] = useState(<div></div>)
    const [executionDetailsChartFullscreen, setExecutionDetailsChartFullscreen] = useState(<div></div>)
    const [tablesLollipop, setTablesLollipop] = useState(<div></div>)
    const [tablesLollipopFullscreen, setTablesLollipopFullscreen] = useState(<div></div>)
    const dispatch = useDispatch()


    useEffect(() => {


        if (props.project && props.qpExecutions && props.qpExecutions.length > 0) {

            //setProject(props.project)

            let dqi = Number((props.project.sumNumOk / props.project.sumNumIn) * 100).toFixed(2)

            setDonutChart(<DonutChart data={dqi} showCenterLabel={true} chart={'chartDonutDataSource'} openDimensionModal={openModal} click={true} />)

            setDimension(props.project.dimensionDQIList)

            let listOfQps = mapQualityPoints(props.project.qualityPointDQI)


            setTablesLollipop(<TableLlolipopCharts data={listOfQps} click={handleDrillDownElement} showIcons={true}/>)
            setTablesLollipopFullscreen(<TableLlolipopCharts fullscreen={true} data={listOfQps} click={handleDrillDownElement} showIcons={true}/>)

            //setQpList(listOfQps)

        }

        if(props.detailQPExecutions && props.detailQPExecutions){
            setChartTriple(<ChartTripleTemporal data={props.detailQPExecutions} showBasicInformation={showBasicInformation} />)
            setChartTripleFullscreen(<ChartTripleTemporal fullscreen = {true} data={props.detailQPExecutions} showBasicInformation={showBasicInformation} />)
        }  

        //setProject(props.project)

    }, [props.project, props.qpExecutions, props.detailQPExecutions])// eslint-disable-line react-hooks/exhaustive-deps


   /* useEffect(() => {

        let listOfQps = props.project.qualityPointDQI

        setQpList(listOfQps)

    }, [props.project])// eslint-disable-line react-hooks/exhaustive-deps
    */
   
    function handleDrillDownElement(e) {
        props.handleDrillDownElement(e)
    }

    function mapQualityPoints(qualityPointList){
        return qualityPointList.map(qp => {

            return {
                ...qp,
                'id': qp.qualityPointId,
                'historyList': props.qpExecutions.filter(ex => ex.qpId === qp.qualityPointId)[0].executionHistory,
                'score': Number(((qp.sumNumOk / (qp.sumNumOk + qp.sumNumKo)) * 100).toFixed(0)),
                'scoreKo': -Number(((qp.sumNumKo / (qp.sumNumOk + qp.sumNumKo)) * 100).toFixed(0)),
                "fecha": qp.dayQpDQIList.length > 0 ? new Date(qp.dayQpDQIList[0].year, qp.dayQpDQIList[0].month, qp.dayQpDQIList[0].day).getTime() : new Date().getTime(),
            }
        })
    }

    const [openDimensionModal, setOpenDimensionModal] = React.useState(false)
    const handleClose = () => setOpenDimensionModal(false);

    function openModal(data) {
        setOpenDimensionModal(true)
    }

    

    async function showBasicInformation(e) {
        let executionDetail = await dispatch(actions.getDetailExecutions(e.executionId))
        if (executionDetail) {
            let qp = mapQualityPoints(props.project.qualityPointDQI).filter(qp => { return executionDetail.data[0].qualityPointId === qp.qualityPointId})
            setExecutionDetailsChart(<ExecutionDetail  project={props.project} execution={executionDetail.data.filter(ex => ex.type === 'QUALITY_RULE')} qualityPoint={qp[0]} />)
            setExecutionDetailsChartFullscreen(<ExecutionDetail fullscreen = {true}  project={props.project} execution={executionDetail.data.filter(ex => ex.type === 'QUALITY_RULE')} qualityPoint={qp[0]} />)
            setShowDetails(true)
        }
    }


    function setAgregationLevel(level) {
        props.setAgregationLevel(level)
    }



    return (
        <>
            <Grid container spacing={1} style={commonStyles.titleBox}>
                <Grid item xs={12} style={commonStyles.DashboardChartComponentTitle}>
                    <Typography variant='h6' style={commonStyles.titleText}> QualityPoints for Project - {props.project.name}  </Typography>
                    <ReturnPreviousAgregationButton agregationLevel={props.agregationLevel} setAgregationLevel={setAgregationLevel} />
                </Grid >

                <Grid item xs={4}>

                    <DashboardChartComponent loading={props.DQI4ProjectFlag} content={donutChart} title={'Project DQI'} description={languageContext.dictionary['globalDQIProject']} />

                </Grid >

                <Grid item xs={8}>
                    <DashboardChartComponent loading={props.DQI4ProjectFlag} fullscreenContent={tablesLollipopFullscreen} content={tablesLollipop} title={'Trends'} description={languageContext.dictionary['qualityPointDQILollipop']} />

                </Grid >


                <Grid item xs={12}>
                    <DashboardChartComponent loading = {props.DQI4ProjectFlag} fullscreenContent = {chartTripleFullscreen} content={chartTriple} title={'Project DQI Time Evolution'} description={languageContext.dictionary['qualityPointChartTriple']} />

                </Grid >

                <Grid item xs={12}>
                    {showDetails && <DashboardChartComponent loading = {props.DQI4ProjectFlag} fullscreenContent ={executionDetailsChartFullscreen} content={executionDetailsChart} title={'Details'} description={languageContext.dictionary['detaillExecution']} />}

                </Grid >

            </Grid>

            <AqtivaDialog visible={openDimensionModal}
                title={`DQI Dimension`}
                
                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                cancelCallback={handleClose}
                maxWidth={width}
            >
                <div style={{ width: width * 0.9, height: height * 0.7 }}>
                    <DQIDimensionComponent DQIByDimension={dimension} />


                </div>

            </AqtivaDialog>
        </>
    )
}

