import React, { useEffect, useState } from 'react'
import AqtivaDialog from '../../../common/AqtivaDialog'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import ProfilingTable from '../../../common/ProfilingComponent/ProfilingTable';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CsvBuilder } from 'filefy';
import { createColumnsFromJson, prepareData } from 'components/common/ProfilingComponent/ProfilingTableHelper';


export default function DataformatProfiling(props) {
    const [profiling, setProfiling] = useState(props.dataset && props.dataset.profiling && props.dataset.profiling.profilingColumns)
    const theme = useTheme();


    useEffect(() => {
        if (props.dataset && props.dataset.profiling && props.dataset.profiling.profilingColumns) {

            let profiling = JSON.parse(JSON.stringify(props.dataset.profiling.profilingColumns))
            for (let i = 0; i < profiling.length; i++) {
                for (let e = 0; e < profiling[i].profilingColumnItems.length; e++) {
                    profiling[i][profiling[i].profilingColumnItems[e].profilingItemName] = profiling[i].profilingColumnItems[e].profilingItemValue
                }
                delete profiling[i]["profilingColumnItems"]

            }

            setProfiling(profiling)
        }
    }, [props.dataset && props.dataset.profiling && props.dataset.profiling.profilingColumnItems, props.show])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [profiling])// eslint-disable-line react-hooks/exhaustive-deps


    function exportToCSV() {
        
        let columns = createColumnsFromJson(profiling).map(col => { return col.field })
        let rows = prepareData(profiling)
        const builder = new CsvBuilder(`dataProfiling.csv`).setColumns(columns)

        rows.map(r => {
            builder.addRow([
                columns.map(cl => {
                    return r[cl]
                })
            ])
            return undefined
        } )
    
        builder.exportFile()

        return builder;
    }

    return (
        <AqtivaDialog
            visible={props.show}
            dialog={props.show}
            fullWidth={true}
            maxWidth={'xl'}
            title={"Dataformat profiling"}
            editable={false}
            titleIcon={faProjectDiagram}
            confirmText={'Done'}
            confirmCallback={() => { props.onCloseProfilingDialog() }}
            cancelText={'Cancel'}
            cancelCallback={() => { props.onCloseProfilingDialog() }}>
            <div style={{ marginTop: '10px' }}>
                {console.log('profiling', profiling)}
                {profiling && <ProfilingTable data={profiling} />}
                <div style={{ marginTop: '1%' }}>
                    <Button id='exportButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={event => { exportToCSV() }}>Download</Button>
                </div>
            </div>

        </AqtivaDialog>
    )
}