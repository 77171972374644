import React from 'react';
import {
    Grid, Typography, Button, InputAdornment, Icon,
    Tabs, Tab, FormControlLabel, Switch, Tooltip, Checkbox, TextField, Chip, Accordion, AccordionDetails, AccordionSummary
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faCheckDouble, faCog } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faSearch } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "components/common/CustomInput/CustomInput.js";
import PreviewDataformat from './PreviewDataformat'
import AdvanceConfigurationQuality from 'components/common/AdvanceConfigurationQuality'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'

import QualityItemSelector from 'components/common/QualityItemSelector'
import DialogCreateDataFormat from '../DialogCreateDataFormat'

import { faHubspot } from '@fortawesome/free-brands-svg-icons'
import { faFileUpload, faFileImport } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from 'trackEvent'
import SelectHierarchyEhubs from 'components/common/SelectHierarchyEhubs/index.js';
import { LoadingComponent } from './LoadingComponent.js';
import AqtivaDialog from 'components/common/AqtivaDialog/index.js';
import DialogViewDetailTopic from '../DialogViewDetailTopic'
import { AqtivaDialogDataRepository } from 'components/data/DataRepositoryShare/EntityDataRepository/AqtivaDialogDataRepository'
import QualityDatasetRepositoryPage from 'components/data/DataPageForm'
import * as sseApi from 'apis/sseApi';

import * as datasourceHelper from './CreaterDataSourceHelper'
import DialogSelectAzureSource from 'components/dialogs/DialogSelectAzureSource';
import SchedulingConfigurationQuality from 'components/common/SchedulingConfigurationQuality'
import TagsConfigurationQuality from 'components/common/TagsConfigurationQuality'
import EnrichDatasourceComponent from 'components/common/EnrichDatasourceComponent'
import DialogInsertAnjanaId from 'components/dialogs/DialogInsertAnjanaId'
import * as columnHelper from 'components/common/EnrichDatasourceComponent/EnrichDatasourceTable/utils'

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'
import ParameterConfiguration from 'components/common/ParameterConfiguration';

import ColumnsSelectorAggregated from 'components/rule/RuleCreationComponent/ColumnsSelectorAggregated';
import * as enrichHelper from 'components/common/EnrichDataformatHelper'


export default function DialogCreateDataSource(props) {
    const languageContext = React.useContext(LanguageContext);

    const theme = useTheme();
    const dispatch = useDispatch();

    /******* STORE OBJECTS ********/
    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const dataformats = useSelector(store => Object.values(store.dataformats))


    var qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))

    /******* CONTROL VARIABLES ********/
    const activateStream = (license && (license.lambdaActivate === true || !license.lambdaActivate))//variable to activate stream capabilities
    const [tabValue, setTabValue] = React.useState(props.datasource && props.datasource.inputConnector ? 1 : 0)
    const [editable, setEditable] = React.useState(props.datasource ? true : false)
    const [loadingPreviewData, setLoadingPreviewData] = React.useState(false)
    const [noDataStreamFound, setNoDataStreamFound] = React.useState(false)
    const [datasourceType, setDatasourceType] = React.useState(props.datasource && props.datasource.inputConnector ? 'REAL_TIME_LAMBDA' : 'BATCH')

    /******* DIALOG CONTROL VARIABLES ********/
    const [showDialogCreateDataformat, setShowDialogCreateDataformat] = React.useState(false)
    const [showDialogCreateDataformatAzure, setShowDialogCreateDataformatAzure] = React.useState(false)

    const [openDialogDataRepository, setOpenDialogDataRepository] = React.useState(false)

    /******* FORM VARIABLES  ********/
    const [name, setName] = React.useState(props.datasource ? props.datasource.name : '')
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState('')
    const [selectedDataFormat, setSelectedDataFormatState] = React.useState('')
    const [thError, setThError] = React.useState(props.datasource ? props.datasource.errorTh : (props.qualityPoint ? props.qualityPoint.errorTh : {}))


    const [thWarning, setThWarning] = React.useState(props.datasource ? props.datasource.warningTh : (props.qualityPoint ? props.qualityPoint.warningTh : {}))
    const [wrongRegisterAction, setWrongRegisterAction] = React.useState(props.datasource ? props.datasource.wrongRegisterActionType : (props.qualityPoint ? props.qualityPoint.wrongRegisterActionType : ''))
    const [outputMode, setOutputMode] = React.useState(props.datasource ? props.datasource.controlModeType : (props.qualityPoint ? props.qualityPoint.controlModeType : ''))

    const [selectedStreamDataFormat, setSelectedStreamDataFormat] = React.useState(props.datasource && props.datasource.inputConnector ? props.datasource.inputConnector : undefined)
    const [selectedOutputStreamDataFormat, setSelectedOutputStreamDataFormat] = React.useState(props.datasource && props.datasource.inputConnector ? props.datasource.outputConnector : undefined)
    const [selectedOutputOKStreamDataFormat, setSelectedOutputOKStreamDataFormat] = React.useState(props.datasource && props.datasource.outputOKConnector ? props.datasource.outputOKConnector : undefined)
    const [selectedOutputKOStreamDataFormat, setSelectedOutputKOStreamDataFormat] = React.useState(props.datasource && props.datasource.outputKOConnector ? props.datasource.outputKOConnector : undefined)

    const [filterDatasourceSQL, setFilterDatasourceSQL] = React.useState(props.datasource && props.datasource.filterSql ? props.datasource.filterSql : undefined)
    const [sqlQuery, setSqlQuery] = React.useState(props.datasource && props.datasource.sqlQuery ? props.datasource.sqlQuery : undefined)

    const [selectedStreamDataFormatConnStr, setSelectedStreamDataFormatConnStr] = React.useState()
    const [selectedOutputStreamDataFormatConnStr, setSelectedOutputStreamDataFormatConnStr] = React.useState()
    const [selectedOutputOKStreamDataFormatConnStr, setSelectedOutputOKStreamDataFormatConnStr] = React.useState()
    const [selectedOutputKOStreamDataFormatConnStr, setSelectedOutputKOStreamDataFormatConnStr] = React.useState()

    const [streamEndPoint, setStreamEndPoint] = React.useState()
    const [streamEntityPath, setStreamEntityPath] = React.useState()
    const [streamSak, setStreamSak] = React.useState()
    const [streamSakName, setStreamSakName] = React.useState()

    const [outputPaths, setOutputPaths] = React.useState(React.useState(props.datasource && props.datasource.outputPath ? props.datasource.outputPath : {}))
    const [enableOutputPath, setEnableOutputPath] = React.useState(React.useState(props.datasource && props.datasource.enableOutputPath ? props.datasource.enableOutputPath : false))

    /******* ERROR VARIABLES ********/
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors, setHasErrors] = React.useState()

    const [checkSplitOutput, setCheckSplitOutput] = React.useState(true)
    const [visibleDetailStream, setVisibleDetailStream] = React.useState(false)

    /******* EVENT DATA VARIABLES ********/
    const [rawConsumers, setRawConsumers] = React.useState([]) // info obtain from ehubs to be parsed to tree structured
    const [streams, setStreams] = React.useState([]) // info obtain from ehubs to be parsed to tree structured

    const [selectedDataformatIdFromDialog, setSelectedDataFormatIdFromDialog] = React.useState(undefined) // si llamamos al dialogo de seleccionar dataformat, el id seleccionado

    const [mandatoryTags, setMandatoryTags] = React.useState([])
    const [optionalTags, setOptionalTags] = React.useState([])

    const [mandatoryErrorTags, setMandatoryErrorTags] = React.useState("")
    const [deprecatedErrorTags, setDeprecatedErrorTags] = React.useState("")
    const [joins, setJoins] = React.useState([]);

    const [warningMessage, setWarningMessage] = React.useState('')
    const [rulesWithIssues, setRulesWithIssues] = React.useState([])

    const [visibleAnjanaId, setVisibleAnjanaId] = React.useState(false)
    const [anjanaIntegrable, setAnjanaIntegrable] = React.useState(false)
    const [anjanaId, setAnjanaId] = React.useState("")

    const [selectedColumns, setSelectedColumns] = React.useState(React.useState(props.datasource && props.datasource.filterColumns ? props.datasource.filterColumns : []))

    const [openAutocomplete, setOpenAutocomplete] = React.useState(false)

    const [cacheDatasetOnStart, setCacheDatasetOnStart] = React.useState(props.datasource && props.datasource.cacheDatasetOnStart ? props.datasource.cacheDatasetOnStart : true)
    const [filterBeforeEnrichment, setFilterBeforeEnrichment] = React.useState(props.datasource && props.datasource.filterBeforeEnrichment ? props.datasource.filterBeforeEnrichment === true : false)

    const [parameters, setParameters] = React.useState(props.datasource && props.datasource.parameters ? props.datasource.parameters : [])
    const [parentParameters, setParentParameters] = React.useState(props.qualityPoint && props.qualityPoint.parameters ? props.qualityPoint.parameters : [])

    const [aggregatePrior2Join, setAggregatePrior2Join] = React.useState(props.datasource && props.datasource.aggregatePrior2Join ? props.datasource.aggregatePrior2Join : false)

    const [aggregationObject, setAggregationObject] = React.useState(props.datasource && props.datasource.aggregationObject ? props.datasource.aggregationObject : {})
    const [enrichedDataset, setEnrichedDataset] = React.useState(undefined)
    const [aggregationFlag, setAggregationFlag] = React.useState(props.datasource && props.datasource.aggregationFlag ? props.datasource.aggregationFlag : {})

    function setSelectedDataFormat(value) {
        if (value && selectedDataFormat && value._id === selectedDataFormat._id) {
            return
        }
        if (value !== undefined) {
            setSelectedDataFormatState(value)
        }


    }

    React.useEffect(() => {

        if (dataformats === undefined || dataformats.length === 0) {
            //fetch recent dataformats
            dispatch(actions.fetchDataFormats(user))
        }
    }, [dataformats])// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * When a dataformat is selected, if it´s a short projection (no columns) we get for that dataformat the complete object
     */
    React.useEffect(() => {
        if (selectedDataFormat !== undefined && selectedDataFormat.columns === undefined) {
            dispatch(actions.fetchDataformat(selectedDataFormat._id)).then(response => {
                console.log('setDF1', response.data)
                setSelectedDataFormatState(response.data)
            })


        }
    }, [selectedDataFormat])



    function handleSelectDatasetForExportationId(id) {
        setSelectedDataFormatIdFromDialog(id)
    }

    async function obtainAndSelectDataformatFromId() {
        //lo primero obtenemos el dataformat del id
        if (selectedDataformatIdFromDialog !== undefined) {
            dispatch(actions.fetchDataformat(selectedDataformatIdFromDialog)).then(response => {
                console.log('setDF3', response.data)
                setSelectedDataFormat(response.data)
            })


        }
    }

    async function obtainAndSelectDataformat(id) {
        //lo primero obtenemos el dataformat del id


        if (id !== undefined) {

            dispatch(actions.fetchDataformat(id)).then(response => {
                console.log('setDF4', response.data)
                setSelectedDataFormat(response.data)
            })


        }
    }


    /******* INIT FUNCTION ********/
    React.useEffect(() => {
        sseApi.sseReceiver(process.env.REACT_APP_BACK_HOST + '/azure/eventhub/list/simple', onOpen, onMessage, onClose)
        //setSelectedDataFormat(props.datasource && dataformats && dataformats.length > 0 ? dataformats.filter(x => x.name === props.datasource.dataset.name)[0] : '')
        //TOOD: NO SE SI NECESARIO PARA STREAM
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /******* UPDATE EFFECTS ********/

    React.useEffect(() => {
        datasourceHelper.createConsumerTreeView(rawConsumers, setStreams)

    }, [rawConsumers])



    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchConfigurationByUserId(user.id))
            //dispatch(actions.fetchDataFormats(user))
        }

    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (props.datasource && props.datasource.dataformatId) {
            obtainAndSelectDataformat(props.datasource.dataformatId)
        }
        else if (props.datasource && props.datasource.dataset && props.datasource.dataset._id) {
            obtainAndSelectDataformat(props.datasource.dataset._id)
        }
    }, [props.datasource]);// eslint-disable-line react-hooks/exhaustive-deps




    //set editable elements if qualityPoint is found in props
    React.useEffect(() => {

        setEditable(props.datasource ? true : false)
        setName(props.datasource ? props.datasource.name : '')
        setThError(props.datasource ? props.datasource.errorTh : (props.qualityPoint ? props.qualityPoint.errorTh : {}))
        setThWarning(props.datasource ? props.datasource.warningTh : (props.qualityPoint ? props.qualityPoint.warningTh : {}))
        setWrongRegisterAction(props.datasource ? props.datasource.wrongRegisterActionType : (props.qualityPoint ? props.qualityPoint.wrongRegisterActionType : ''))
        setOutputMode(props.datasource ? props.datasource.controlModeType : (props.qualityPoint ? props.qualityPoint.controlModeType : ''))
        setOutputPaths(props.datasource && props.datasource.outputPath ? props.datasource.outputPath : {})
        setEnableOutputPath(props.datasource && props.datasource.enableOutputPath ? props.datasource.enableOutputPath : false)
        setFilterDatasourceSQL(props.datasource && props.datasource.filterSql ? props.datasource.filterSql : undefined)
        setSqlQuery(props.datasource && props.datasource.sqlQuery ? props.datasource.sqlQuery : undefined)
        setAggregatePrior2Join(props.datasource && props.datasource.aggregatePrior2Join ? props.datasource.aggregatePrior2Join : undefined)
        setAggregationObject(props.datasource && props.datasource.aggregationObject ? props.datasource.aggregationObject : undefined)
        setAggregationFlag(props.datasource && props.datasource.aggregationFlag ? props.datasource.aggregationFlag : undefined)
        setSelectedColumns(props.datasource && props.datasource.filterColumns ? props.datasource.filterColumns : [])
        setCacheDatasetOnStart(props.datasource && props.datasource.cacheDatasetOnStart ? props.datasource.cacheDatasetOnStart : true)
        setFilterBeforeEnrichment(props.datasource && props.datasource.filterBeforeEnrichment ? props.datasource.filterBeforeEnrichment === true : false)
        setParameters(props.datasource && props.datasource.parameters ? props.datasource.parameters : [])

        /*if (props.datasource && props.datasource.dataset) {
            if (props.datasource) {
                if (dataformats.filter(x => x.name === props.datasource.dataset.name)[0]) {
                    setSelectedDataFormat(dataformats.filter(x => x.name === props.datasource.dataset.name)[0])
                }

            }
        }*/

        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true') {
            if (props.datasource && props.datasource.anjanaIntegrable) {
                setAnjanaIntegrable(props.datasource.anjanaIntegrable)
            }
            if (props.datasource && props.datasource.anjanaId) {
                setAnjanaId(props.datasource.anjanaId)
            }
        }


    }, [props.datasource]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => { validate() }, [selectedDataFormat, selectedQualityPoint]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setSelectedQualityPoint(props.qualityPoint)
        setThError(props.datasource ? props.datasource.errorTh : (props.qualityPoint ? props.qualityPoint.errorTh : undefined))
        setThWarning(props.datasource ? props.datasource.warningTh : (props.qualityPoint ? props.qualityPoint.warningTh : undefined))
        setWrongRegisterAction(props.datasource ? props.datasource.wrongRegisterActionType : (props.qualityPoint ? props.qualityPoint.wrongRegisterActionType : undefined))
        setOutputMode(props.datasource ? props.datasource.controlModeType : (props.qualityPoint ? props.qualityPoint.controlModeType : undefined))
    }, [props.qualityPoint]);// eslint-disable-line react-hooks/exhaustive-deps




    React.useEffect(() => {
        if (props.datasource && props.datasource.enrichDataformatObject && props.datasource && props.datasource.enrichDataformatObject.joins && props.datasource.enrichDataformatObject.joins.length > 0) {
            setJoins(props.datasource.enrichDataformatObject.joins)

        }
    }, [props.datasource && props.datasource.enrichDataformatObject && props.datasource && props.datasource.enrichDataformatObject.joins])// eslint-disable-line react-hooks/exhaustive-deps




    React.useEffect(() => {
        if (props.qualityPoint && props.qualityPoint.tagList && props.qualityPoint.tagList.length > 0 && props.configuration && props.configuration.tagConfiguration && props.configuration.tagConfiguration.length > 0) {
            checkIfTagsAreDeprecated(props.qualityPoint.tagList, props.configuration.tagConfiguration)
        }
    }, [props.configuration, props.qualityPoint])

    function checkIfTagsAreDeprecated(tagList, tagConfiguration) {
        let error = ""
        for (let i = 0; i < tagList.length; i++) {
            let isTagPresent = tagConfiguration.find(tag => tag.tagName === tagList[i].tagName)
            if (!isTagPresent) {
                error = "tag values are deprecated, please update them."
                setDeprecatedErrorTags(error)
            }
        }
    }

    React.useEffect(() => {
        if (selectedDataFormat) {
            let datasetChanged = checkIfDatasetHasBeenEdited(selectedDataFormat, props.datasource)
            if (datasetChanged) {
                if (selectedDataFormat && selectedDataFormat.columns) {
                    let uneditedDataset = props.datasource.dataset
                    let allColumnsUsed = columnHelper.getAllColumnsUsedInRules(props.datasource)
                    let rulesWithIssues = columnHelper.checkIfDataformatColumnsAreUsed(allColumnsUsed, uneditedDataset, props.datasource)
                    if (rulesWithIssues && rulesWithIssues.length > 0) {
                        setWarningMessage("By editing this datasource's dataset the next rules won't be able to execute until you fix them:")
                        setRulesWithIssues(rulesWithIssues)
                    }
                }
            }
        }
    }, [selectedDataFormat])// eslint-disable-line react-hooks/exhaustive-deps

    function checkIfDatasetHasBeenEdited(selectedDataFormat, datasource) {
        if (datasource && datasource.dataset) {
            if (selectedDataFormat.name === datasource.dataset.name) {
                return false
            }
            else {
                return true
            }
        }
    }



    //helper functions
    function onMessage(event) {
        const eventData = JSON.parse(event.data);
        setRawConsumers(rawConsumers => [...rawConsumers, eventData]);
    }
    function onOpen(event) {
        console.log('open', event)
    }
    function onClose(event) {

        setSelectedStreamDataFormat(props.datasource && props.datasource.inputConnector ?
            {
                type: 'eventhub',
                name: props.datasource.inputConnector.name,
                namespace: props.datasource.inputConnector.namespace,
                resourcegroup: props.datasource.inputConnector.resourcegroup
            } : undefined)
    }





    /******* UPDATE CALLBACKS ********/
    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }

    //function to update control elmenets
    function setControlObj(data) {
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)
    }

    const onChangeTextField = (event) => {
        setName(event.target.value)
        validate()
    }

    //callback to save Datasource
    const createDatasourceV1 = () => {
        let tagsMandatory = mandatoryTags
        let tagsOptional = optionalTags
            ? optionalTags.filter(
                (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
            )
            : [];
        let allTags = tagsMandatory.concat(tagsOptional);
        let enrichDataformatObject = {}
        enrichDataformatObject.joins = joins


        const datasource = {
            name: name,
            active: true,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType: wrongRegisterAction,
            dataformatId: selectedDataFormat._id,
            dataformatName: selectedDataFormat.name,
            dataformatSource: selectedDataFormat.source,
            type: datasourceType,
            outputPath: outputPaths,
            enableOutputPath: enableOutputPath,
            tagList: allTags,
            filterSql: filterDatasourceSQL,
            sqlQuery: sqlQuery,
            aggregatePrior2Join: aggregatePrior2Join,
            aggregationObject: aggregationObject,
            aggregationFlag: aggregationFlag,
            filterColumns: selectedColumns.length > 0 ? selectedColumns : undefined,
            cacheDatasetOnStart: cacheDatasetOnStart,
            enrichDataformatObject: enrichDataformatObject,
            filterBeforeEnrichment: filterBeforeEnrichment,
            parameters: parameters

        }
        dispatch(actions.addDatasourceV1(props.project._id, selectedQualityPoint._id, datasource))

    }

    //callback to save QualityPoint
    async function createDatasourceV2() {
        let tagsMandatory = mandatoryTags
        let tagsOptional = optionalTags
            ? optionalTags.filter(
                (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
            )
            : [];
        let allTags = tagsMandatory.concat(tagsOptional);

        var dataformatStream = Object.assign({}, selectedDataFormat)
        dataformatStream.format = 'json'
        dataformatStream.name = selectedStreamDataFormat.name
        const azureCredentials = qualityConfigurationSelector.azureCredentials
        let enrichDataformatObject = {}
        enrichDataformatObject.joins = joins




        const datasource = {
            name: name,
            active: true,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType: wrongRegisterAction,
            dataformatId: dataformatStream.id,
            dataformatName: dataformatStream.name,
            dataformatSource: dataformatStream.source,
            datasourceType: datasourceType,
            enableOutputPath: enableOutputPath,
            inputConnector: await createConnector(selectedStreamDataFormat, selectedStreamDataFormatConnStr, azureCredentials),
            outputDefaultConnector: await createConnector(selectedOutputStreamDataFormat, selectedOutputStreamDataFormatConnStr, azureCredentials),
            outputOKConnector: await createConnector(selectedOutputOKStreamDataFormat, selectedOutputOKStreamDataFormatConnStr, azureCredentials),
            outputKOConnector: await createConnector(selectedOutputKOStreamDataFormat, selectedOutputKOStreamDataFormatConnStr, azureCredentials)
            , outputPath: outputPaths,
            filterSql: filterDatasourceSQL,
            sqlQuery: sqlQuery,
            aggregatePrior2Join: aggregatePrior2Join,
            aggregationObject: aggregationObject,
            aggregationFlag: aggregationFlag,
            cacheDatasetOnStart: cacheDatasetOnStart,
            tagList: allTags,
            enrichDataformatObject: enrichDataformatObject,
            filterBeforeEnrichment: filterBeforeEnrichment,
            parameters: parameters

        }

        dispatch(actions.addDatasourceV1(props.project._id, selectedQualityPoint._id, datasource))

    }

    async function createConnection(stream, azureCredentials) {
        var result = undefined
        await dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            stream.resourcegroup, stream.namespace, stream.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    result = response.data.connectionStrings[0]
                }

            }).catch(error => {
                result = undefined
            })

        return result
    }

    async function createConnector(stream, connStr, azureCredentials) {

        if (!stream) {
            return undefined
        }

        const connector = {
            type: 'event_hubs',
            name: stream.name,
            namespace: stream.namespace,
            resourcegroup: stream.resourcegroup,
            conn_str: connStr ? connStr : await createConnection(stream, azureCredentials)

        }
        return connector
    }

    //callback to save QualityPoint
    const modifyDatasource = async () => {
        let tagsMandatory = mandatoryTags
        let tagsOptional = optionalTags
            ? optionalTags.filter(
                (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
            )
            : [];
        let allTags = tagsMandatory.concat(tagsOptional);
        var newDatasource = Object.assign(props.datasource)
        let uneditedDatasource = JSON.parse(JSON.stringify(props.datasource))
        let enrichDataformatObject = {}
        enrichDataformatObject.joins = joins



        newDatasource.name = name
        newDatasource.active = true
        newDatasource.userId = user.id
        newDatasource.errorTh = thError
        newDatasource.warningTh = thWarning
        newDatasource.controlModeType = outputMode
        newDatasource.wrongRegisterActionType = wrongRegisterAction
        newDatasource.outputPath = outputPaths
        newDatasource.enableOutputPath = enableOutputPath
        newDatasource.dataformatId = selectedDataFormat._id
        newDatasource.dataformatName = selectedDataFormat.name
        newDatasource.dataformatSource = selectedDataFormat.source
        newDatasource.tagList = allTags
        newDatasource.filterSql = filterDatasourceSQL
        newDatasource.sqlQuery = sqlQuery
        newDatasource.aggregatePrior2Join = aggregatePrior2Join
        newDatasource.aggregationObject = aggregationObject
        newDatasource.aggregationFlag = aggregationFlag
        newDatasource.filterColumns = selectedColumns.length > 0 ? selectedColumns : undefined
        newDatasource.enrichDataformatObject = enrichDataformatObject
        newDatasource.filterBeforeEnrichment = filterBeforeEnrichment
        newDatasource.parameters = parameters

        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true') {
            if (anjanaId) {
                newDatasource.anjanaId = anjanaId ? anjanaId : ""
            }
            newDatasource.anjanaIntegrable = anjanaIntegrable ? anjanaIntegrable : false
        }

        await dispatch(actions.editDatasource(props.project._id, selectedQualityPoint._id, newDatasource, user.id))


        let datasetChanged = checkIfDatasetHasBeenEdited(selectedDataFormat, uneditedDatasource)
        if (datasetChanged) {
            if (selectedDataFormat && selectedDataFormat.columns) {

                let allColumnsUsed = columnHelper.getAllColumnsUsedInRules(uneditedDatasource)
                await columnHelper.checkIfColumnsAreUsedAndUpdateRuleFlagEditingDatasource(allColumnsUsed, uneditedDatasource, props.project, props.qualityPoint, props.datasource, props.userId, dispatch, actions)
            }
        }



    }

    function reset() {
        setName('')
        setThError({})
        setThWarning({})
        setWrongRegisterAction('')
        setOutputMode('')
        setShowDialogCreateDataformat(false)
        setErrorTypes(undefined)
        setErrorMsgs(undefined)
        setDatasourceType('BATCH')
        setCheckSplitOutput(true)
        setParameters([])
    }

    //wrapper to save QualityPoint
    const saveItem = () => {
        const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = validate()
        //TODO: CHECK
        if (arrayErrorTypes && arrayErrorMsgs) { }
        if (!hasErrors) {


            if (tabValue === 1) {
                //es stream, uso v2
                createDatasourceV2()
                props.setClose(false)
                props.clearSelectedItem()

            }
            else {
                //es batch, uso v1
                createDatasourceV1()
                props.setClose(false)
                props.clearSelectedItem()

            }

            reset()


        }
        props.setScheduleHasBeenModified(!props.scheduleHasBeenModified)
    }

    //wrapper to edit QualityPoint
    const editItem = async () => {
        const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = validate()
        //TODO: CHECK
        if (arrayErrorTypes && arrayErrorMsgs) { }
        if (!hasErrors) {
            await modifyDatasource()
            props.setClose(false)
            props.clearSelectedItem()
        }
    }

    function onChangeQPCallback(event) {

        setSelectedQualityPoint(event.target.value)
        validate()
    }

    function onChangeDFCallback(event) {
        setDatasourceType('BATCH')
        console.log('setDF4', event.target.value)
        setSelectedDataFormat(event.target.value)
        validate()
    }
    function onChangeStreamDFCallback(event) {
        setSelectedStreamDataFormat(event.target.value)
        setLoadingPreviewData(true)
        const data = event.target.value
        const azureCredentials = qualityConfigurationSelector.azureCredentials

        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedStreamDataFormatConnStr(response.data.connectionStrings[0])
                    listenEventHubs(response.data.connectionStrings[0])
                    validate()
                }

            }
            )


    }
    function onChangeOutputStreamDFCallback(event) {
        setSelectedOutputStreamDataFormat(event.target.value)
        validate()
        const azureCredentials = qualityConfigurationSelector.azureCredentials
        const data = event.target.value
        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedOutputStreamDataFormatConnStr(response.data.connectionStrings[0])

                }

            })
    }
    function onChangeOutputOKStreamDFCallback(event) {
        setSelectedOutputOKStreamDataFormat(event.target.value)
        const azureCredentials = qualityConfigurationSelector.azureCredentials
        const data = event.target.value
        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedOutputOKStreamDataFormatConnStr(response.data.connectionStrings[0])

                }

            })
        validate()
    }
    function onChangeOutputKOStreamDFCallback(event) {
        setSelectedOutputKOStreamDataFormat(event.target.value)
        const azureCredentials = qualityConfigurationSelector.azureCredentials
        const data = event.target.value
        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedOutputKOStreamDataFormatConnStr(response.data.connectionStrings[0])

                }

            })
        validate()
    }



    function getControlObj() { return { wrongRegisterAction: wrongRegisterAction, outputMode: outputMode } }

    /*
    * ERROR FORM HANDLER FUNCTIONS
    */
    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs[idx]
        }
    }

    function validate() {
        var hasErrors = false
        const arrayErrorTypes = []
        const arrayErrorMsgs = []


        if (!name) {
            hasErrors = true
            arrayErrorTypes.push('name')
            arrayErrorMsgs.push(languageContext.dictionary['nameMissingError'])
        }
        if (!selectedQualityPoint) {
            hasErrors = true
            arrayErrorTypes.push('selectedQualityPoint')
            arrayErrorMsgs.push(languageContext.dictionary['qualityPointMissingError'])
        }
        if (tabValue === 0 && !selectedDataFormat) {
            hasErrors = true
            arrayErrorTypes.push('selectedDataFormat')
            arrayErrorMsgs.push(languageContext.dictionary['dataformatMissingError'])
        }
        let emptyMandatoryTags = mandatoryTags.filter(tag => tag.tagValue === undefined || tag.tagValue === '')
        if (emptyMandatoryTags.length > 0) {
            hasErrors = true
            setMandatoryErrorTags(languageContext.dictionary['mandatoryTagsMissingError'])
        }


        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)

        return { hasErrors, arrayErrorTypes, arrayErrorMsgs };

    }



    function listenEventHubs(stream) {
        setDatasourceType('REAL_TIME_LAMBDA')
        let endPoint = stream.split(';')[0].split('Endpoint=')[1].split("/").join("_()_")
        let entityPath = stream.split(';')[3].split('EntityPath=')[1].split("/").join("_()_")
        let sak = stream.split(';')[2].split('SharedAccessKey=')[1].split("/").join("_()_")
        let sakName = stream.split(';')[1].split('SharedAccessKeyName=')[1].split("/").join("_()_")

        setStreamEndPoint(endPoint)
        setStreamEntityPath(entityPath)
        setStreamSak(sak)
        setStreamSakName(sakName)
        setSelectedDataFormat(undefined)



        dispatch(actions.getStatisticsFromStream(user.id, endPoint, entityPath, sakName, sak)).then(response => {
            if (response && response.data) {
                if (response.result === 'ok') {
                    console.log("setDF5", response.data)
                    setSelectedDataFormat(response.data)
                    setLoadingPreviewData(false)
                    setNoDataStreamFound(false)
                }
                else {
                    setLoadingPreviewData(false)
                    setNoDataStreamFound(true)
                }

            }

        }
        )


    }

    function onCloseDialogRepository(value) {
        //dispatch(actions.fetchDataFormats(user))
        setOpenDialogDataRepository(value)
    }

    function sourceCanHaveSchedule(datasource) {
        switch (datasource && datasource.dataformatSource) {
            case ("azure-storage"):
                return true
            case ("azure-sqldatabase"):
                return true
            case ("azure-sqlsynapse"):
                return true
            case ("sqlServer"):
                return true
            case ("mySQL"):
                return true
            case ("oracle"):
                return true
            case ("bigQuery"):
                return true
            case ("awsS3"):
                return true
            case ("hive"):
                return true
            default:
                return false
        }
    }

    function validateInputs(states, type) {
        const validations = {
            name: {
                rule: /^[\w_]*$/.test(states.name),
                description: languageContext.dictionary['incorrectNameError']
            }
        }
        if (type) {
            return validations[type]
        }
        return Object.values(validations).every((value) => value.rule === true)
    }

    /*
    function setThErrorTest(value){
        console.log('aadsadf',value)
    }*/

    function handleAnjanaSwitch() {
        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && props.qualityPoint && props.qualityPoint._id && props.datasource && props.datasource._id) {
            setAnjanaIntegrable(!anjanaIntegrable)
            dispatch(actions.editAnjanaFlagDatasource(props.project._id, props.qualityPoint._id, props.datasource._id, user.id, !anjanaIntegrable, props.project.name))

        }
    }

    function calculateColumnsFromRules() {




        if (props.datasource && props.datasource.rules && props.datasource.rules.length > 0 && selectedDataFormat) {
            //get columns used in rules
            const ruleColumns = props.datasource.rules.flatMap(x => x.columns).filter(x => x !== undefined && x !== null && x.cols !== undefined && x.cols !== null).flatMap(x => x.cols)
            //get columns used in execution conditions
            const executionConditionsRule = props.datasource.rules.filter(x => x.conditions !== undefined && x.conditions !== null).flatMap(x => x.conditions).filter(x => x.column !== undefined && x.column !== null).map(x => x.column.name)

            //now we get columns used in special rules
            const allColumns = selectedDataFormat.columns.map(x => x.name.toUpperCase())
            const ruleColumnsAdHoc = props.datasource.rules.filter(x => x.subtype === 'adHoc')
                .filter(x => x.query !== null && x.query !== undefined)
                .flatMap(r => allColumns.filter(x => r.query.toUpperCase().includes(x)))


            const ruleColumnsCheckBetweenLoads1 = props.datasource.rules.filter(x => x.subtype === 'checkBetweenLoads').filter(x => x.selectedColumnAgg !== null && x.selectedColumnAgg !== undefined).flatMap(x => x.selectedColumnAgg)
            const ruleColumnsCheckBetweenLoads2 = props.datasource.rules.filter(x => x.subtype === 'checkBetweenLoads').filter(x => x.selectedGroupByColumn !== null && x.selectedGroupByColumn !== undefined).flatMap(x => x.selectedGroupByColumn)

            const columns = ruleColumns.concat(executionConditionsRule).concat(ruleColumnsAdHoc).concat(ruleColumnsCheckBetweenLoads1).concat(ruleColumnsCheckBetweenLoads2)

            let uniqueColumns = columns.filter((c, index) => {
                return columns.indexOf(c) === index;
            }).filter(x => x !== undefined && x != null).map(x => x.toUpperCase());


            const newSelectedColumns = selectedDataFormat.columns.filter(x => uniqueColumns.includes(x.name.toUpperCase()))
            setSelectedColumns(newSelectedColumns)



        }
    }

    const toggleAggregatePrior2Join = () => {

        setAggregatePrior2Join(!aggregatePrior2Join)

    };

    //create enrich dataset 
    React.useEffect(() => {

        enrichHelper.createEnrichDatasetFromDataformatAndJoin(selectedDataFormat, aggregatePrior2Join, aggregationFlag,
            aggregationObject, props.datasource, joins, dispatch).then(
                resultDataset => {
                    setEnrichedDataset(resultDataset)
                })
    }, [selectedDataFormat, aggregatePrior2Join, aggregationObject, joins])// eslint-disable-line react-hooks/exhaustive-deps

    //create enrich dataset 
    React.useEffect(() => {
        var aggregatedFlag = false
        if (aggregationObject && aggregationObject.aggregationOperations &&
            aggregationObject.aggregationOperations.length > 0) {
            aggregatedFlag = true
        }
        setAggregationFlag(aggregatedFlag)

    }, [aggregationObject])// eslint-disable-line react-hooks/exhaustive-deps

    function validateSqlQueryStart(query) {
        if (query === undefined || query === "") {
            return true
        }
        if (query.toUpperCase().startsWith("select * from".toUpperCase())) {
            return true
        }
        else {
            return false
        }
    }

    function validateSqlQueryEnd(query) {
        if (query === undefined || query === "") {
            return true
        }
        if (query.endsWith(";") || query.endsWith(",")) {
            return false
        }
        else {
            return true
        }
    }

    return (
        <AqtivaDialog visible={props.open}
            fullWidth={true}
            maxWidth={'lg'}
            title={editable ? languageContext.dictionary['editDatasourceTitle'] : languageContext.dictionary['createDatasourceTitle']}
            editable={editable}
            iconSourceFlag={true}
            iconSource={require('resources/icons/data_proc1_white.png')}

            confirmText={'Save'}
            cancelText={'Cancel'}
            editText={'Edit'}
            confirmCallback={saveItem}
            cancelCallback={event => {
                reset()
                props.setScheduleHasBeenModified(!props.scheduleHasBeenModified)
                props.setClose(false)
            }}
            editCallback={editItem}
            disabled={!validateInputs({ name: name })}
        >
            {showDialogCreateDataformatAzure &&
                <DialogSelectAzureSource visible={showDialogCreateDataformatAzure} setVisible={setShowDialogCreateDataformatAzure} configuration={props.configuration}
                    setLocalFileDialogVisible={setShowDialogCreateDataformat} />
            }

            {openDialogDataRepository && <AqtivaDialogDataRepository
                dialog={openDialogDataRepository}
                setDialog={onCloseDialogRepository}
                title={languageContext.dictionary['dataRepositoryTitle']}
                showConfirmButton={true}
                maxWidth={'xl'}
                confirmText={'Select DataFormat'}
                titleIcon={faDatabase}
                confirmCallback={(event) => { obtainAndSelectDataformatFromId(); setOpenDialogDataRepository(false) }}
            >
                <QualityDatasetRepositoryPage selectDatasetDialog={true} user={user} defaultValue={1}
                    handleSelectDatasetForExportationId={handleSelectDatasetForExportationId} />
            </AqtivaDialogDataRepository>}

            {process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && <DialogInsertAnjanaId visible={visibleAnjanaId} setVisibleAnjanaId={setVisibleAnjanaId} dialog={visibleAnjanaId} setAnjanaId={setAnjanaId} anjanaId={anjanaId} setAnjanaIntegrable={setAnjanaIntegrable} user={props.user} />}



            <DialogViewDetailTopic visible={visibleDetailStream} close={setVisibleDetailStream}
                endPoint={streamEndPoint}
                entityPath={streamEntityPath}
                sak={streamSak}
                sakName={streamSakName}
            />

            {activateStream && process.env.REACT_APP_AFUNCTION_ALLOWED === 'true' &&
                <Tabs value={tabValue} onChange={handleTabChange}
                    variant="scrollable" scrollButtons="off"
                    style={{ width: '100%', marginTop: '-10px' }}>
                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                        <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: '5%', marginTop: '10%', color: commonStyles.mainColor, fontSize: 15 }} />
                        <Typography variant='subtitle2' style={{ marginTop: '5px' }}>
                            {`Batch`}</Typography> </div>}
                    />
                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                        <FontAwesomeIcon icon={faHubspot} style={{ marginRight: '%', marginTop: '10%', color: commonStyles.mainColor, fontSize: 15 }} />
                        <Typography variant='subtitle2' style={{ marginTop: '5px' }}>
                            {`Stream `}</Typography> </div>} />
                </Tabs>
            }

            {showDialogCreateDataformat &&
                <DialogCreateDataFormat
                    configuration={props.configuration}
                    open={showDialogCreateDataformat}
                    setClose={setShowDialogCreateDataformat} />
            }
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Grid item xs={6}>
                        <div>
                            <CustomInput
                                labelText={languageContext.dictionary['datasourceName']}
                                id="ProjectName"
                                formControlProps={{
                                    fullWidth: false,
                                    style: { width: '80%' }
                                }}

                                inputProps={{
                                    type: "text",
                                    onChange: onChangeTextField,
                                    value: name,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className="fas fa-signature" />
                                        </InputAdornment>
                                    )
                                }}
                                error={!validateInputs({ name: name }, "name").rule}
                                helperText={!validateInputs({ name: name }, "name").rule ? validateInputs({ name: name }, "name").description : ""}
                            />
                            <div >
                                {hasErrors && isError('name') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('name')}</Typography>}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        {process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && <div style={{ ...theme.elementAdjacent, minWidth: '10vw', }}>
                            <Checkbox
                                checked={anjanaIntegrable}
                                color='primary'
                                onClick={e => {
                                    handleAnjanaSwitch()
                                }}
                            />
                            <Typography variant='subtitle2' style={{ ...theme.common.text.regularText, marginTop: '8px' }}>
                                {languageContext.dictionary['anjanaIntegrable']}
                            </Typography>
                        </div>}
                    </Grid>


                    {editable && <Grid item xs={12}>
                        <Typography variant='subtitle2'>{`Id: ${props.datasource ? props.datasource._id : ""}`}</Typography>
                    </Grid>
                    }
                    {editable && <Grid item xs={12}>
                        <Typography variant='subtitle2'>{`Id Dataformat: ${props.datasource ? props.datasource.dataformatId : ""}`}</Typography>
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <div>


                            <QualityItemSelector
                                title={languageContext.dictionary['selectQualitypoint']} type='QualityPoint' value={selectedQualityPoint} onChange={onChangeQPCallback}
                                itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints : [])}
                                itemId={'_id'} itemValue={'name'}
                            />
                            <div >
                                {hasErrors && isError('selectedQualityPoint') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedQualityPoint')}</Typography>}
                            </div>
                        </div>
                    </Grid>
                    {tabValue === 0 &&
                        <div>
                            <Grid item xs={12}>

                                <div>
                                    <div style={theme.elementAdjacent}>

                                        <div style={{ width: '90%' }}>
                                            <QualityItemSelector
                                                title={languageContext.dictionary['selectDataformat']} type='DataFormat' value={selectedDataFormat} onChange={onChangeDFCallback}
                                                itemsArray={dataformats}
                                                itemId={'_id'} itemValue={'name'}
                                            />
                                        </div>
                                        <Tooltip title={languageContext.dictionary['importDataformatFromRepository']}>
                                            <div>
                                                <FontAwesomeIcon icon={faFileImport}
                                                    style={{ color: commonStyles.mainColor, fontSize: '20px', marginTop: '30px', marginLeft: '-60px', cursor: 'pointer' }}
                                                    onClick={event => {
                                                        trackEvent('DialogCreateDataSource', 'Data Repository')
                                                        setOpenDialogDataRepository(true)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div >
                                        {hasErrors && isError('selectedDataFormat') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedDataFormat')}</Typography>}
                                    </div>
                                </div>
                            </Grid>


                            <Grid item xs={10}>
                                <Grid container spacing={2}>

                                    <Grid item xs={6}>
                                        <Button variant='outlined' style={theme.buttons.buttonConfirm}
                                            onClick={event => {
                                                trackEvent('DialogCreateDataSource', 'Add Dataformat Azure')
                                                if (process.env.REACT_APP_AZURE_SOURCES && process.env.REACT_APP_AZURE_SOURCES === 'true') {
                                                    setShowDialogCreateDataformatAzure(true)
                                                }
                                                else {
                                                    setShowDialogCreateDataformat(true)
                                                }

                                            }}>
                                            <i className="icon plus"></i>
                                            {languageContext.dictionary["addDataformat"]}
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>


                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <TextField id="standard-basic"
                                        style={{ width: '80%', marginTop: '10px', marginBottom: '10px' }}
                                        value={filterDatasourceSQL}
                                        onChange={e => setFilterDatasourceSQL(e.target.value)}
                                        label="Add Filter to Datasource (Spark SQL where)" />

                                </Grid>

                                {selectedDataFormat && selectedDataFormat.sqlDatabaseOnpremProperties && <Grid item xs={8}>
                                    <TextField id="standard-basic"
                                        style={{ width: '80%', marginTop: '10px', marginBottom: '10px' }}
                                        value={sqlQuery}
                                        onChange={e => setSqlQuery(e.target.value)}
                                        label="Query from JDBC Query (must be select * from )" />

                                    <div >
                                        {!validateSqlQueryStart(sqlQuery) &&
                                            <Typography variant='caption' style={{ color: commonStyles.errorColor }}>
                                                JDBC Query must start with select * from</Typography>}
                                        {!validateSqlQueryEnd(sqlQuery) &&
                                            <Typography variant='caption' style={{ color: commonStyles.errorColor }}>
                                                JDBC Query should not finish with ; or , </Typography>}
                                    </div>

                                </Grid>
                                }



                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={filterBeforeEnrichment}
                                                onChange={event => {
                                                    setFilterBeforeEnrichment(event.target.checked);
                                                }}
                                                name="checkSplitOutput"
                                                color="primary"
                                            />
                                        }
                                        style={{ marginTop: "15%" }}
                                        label={<Typography variant='body2'>{filterBeforeEnrichment ? "Filter before enrichment" : "Filter after enrichment"}</Typography>}
                                    />
                                </Grid>
                            </Grid>



                            <Grid item xs={10}>


                                <div style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                                    <Typography variant='body2' style={{ marginTop: '2%' }}> Filter columns to use in the Datasource</Typography>

                                    <Autocomplete
                                        id="combo-box-demo"
                                        multiple
                                        limitTags={4}
                                        open={openAutocomplete}
                                        onClose={(event, reason) => { if (reason === 'select-option') { setOpenAutocomplete(true); } else { setOpenAutocomplete(false); } }}
                                        onOpen={event => setOpenAutocomplete(true)}
                                        value={selectedColumns}
                                        onChange={(event, newValue) => {


                                            setSelectedColumns(newValue);
                                        }}
                                        renderTags={(value, getTagProps) => {

                                            return value.map((option, index) => (
                                                <Chip label={option.name} {...getTagProps({ index })} />

                                            ))
                                        }
                                        }
                                        options={(selectedDataFormat && selectedDataFormat.columns ? selectedDataFormat.columns.filter(x => !selectedColumns.map(x => x.name).includes(x.name)) : [])}
                                        getOptionLabel={(e) => `# ${e.position} (${e.type}) ${e.name}`}
                                        style={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Select columns" />}
                                    />


                                </div>
                                <Grid item xs={10}>


                                    <div style={{ width: '60%', marginTop: '10px', marginBottom: '10px' }}>
                                        <Button variant='outlined' style={theme.buttons.buttonConfirm}
                                            onClick={event => {
                                                calculateColumnsFromRules()

                                            }}>
                                            Columns used in rules
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={cacheDatasetOnStart}
                                            onChange={event => {
                                                setCacheDatasetOnStart(event.target.checked);
                                            }}
                                            name="checkSplitOutput"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant='body2'>Cache Dataset On Start</Typography>}
                                />
                            </Grid>

                        </div>
                    }
                    {tabValue === 1 &&
                        <div>
                            <Grid item xs={12}>
                                <div style={theme.elementAdjacent}>
                                    <div style={{ width: '90%' }}>
                                        <SelectHierarchyEhubs title={languageContext.dictionary['selectStreamSource']}
                                            streams={streams}
                                            value={selectedStreamDataFormat} onChange={onChangeStreamDFCallback} />
                                        <div  >
                                            {hasErrors && isError('selectedDataFormat') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedDataFormat')}</Typography>}
                                        </div>
                                    </div>
                                    <div style={{ width: '20px' }}>
                                        <FontAwesomeIcon icon={faSearch} onClick={event => setVisibleDetailStream(true)}
                                            style={{ color: commonStyles.mainColor, fontSize: '15px', marginTop: '40px', marginLeft: '-60px', cursor: 'pointer' }} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkSplitOutput}
                                            onChange={event => {
                                                setCheckSplitOutput(event.target.checked);
                                            }}
                                            name="checkSplitOutput"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant='body2'>{languageContext.dictionary['automaticSeparatorEstimation']}</Typography>}
                                />
                            </Grid>
                            {checkSplitOutput &&
                                <div>
                                    <Grid item xs={12}>
                                        <SelectHierarchyEhubs title={languageContext.dictionary['selectOutputStreamOkData']}
                                            streams={streams}
                                            value={selectedOutputOKStreamDataFormat}
                                            onChange={onChangeOutputOKStreamDFCallback}
                                            enableCreation={true} />
                                        <div  >
                                            {hasErrors && isError('selectedOutputOKDataFormat') &&
                                                <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedOutputOKDataFormat')}</Typography>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectHierarchyEhubs title={languageContext.dictionary['selectOutputStreamKoData']}
                                            streams={streams}
                                            value={selectedOutputKOStreamDataFormat}
                                            onChange={onChangeOutputKOStreamDFCallback}
                                            enableCreation={true} />

                                        <div  >
                                            {hasErrors && isError('selectedOutputKODataFormat') &&
                                                <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedOutputKODataFormat')}</Typography>}
                                        </div>
                                    </Grid>
                                </div>

                            }
                            {!checkSplitOutput &&
                                <Grid item xs={12}>
                                    <QualityItemSelector
                                        title={languageContext.dictionary['selectOutputStream']} type='StreamDataformat' value={selectedOutputStreamDataFormat}
                                        onChange={onChangeOutputStreamDFCallback}
                                        itemsArray={qualityConfigurationSelector && qualityConfigurationSelector.eventHubs ? qualityConfigurationSelector.eventHubs : []}
                                        itemId={'entityPath'} itemValue={'entityPath'}
                                    />
                                    <div  >
                                        {hasErrors && isError('selectedOutputDataFormat') &&
                                            <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedOutputDataFormat')}</Typography>}
                                    </div>
                                </Grid>
                            }
                        </div>
                    }


                </Grid>

                {loadingPreviewData && <Grid item xs={5} >
                    <div style={{ marginTop: '10%' }}>
                        <LoadingComponent />
                    </div>
                </Grid>}

                {noDataStreamFound && <Grid item xs={5} >
                    <div style={{ marginTop: '10%' }}>
                        <LoadingComponent noData={true} />
                    </div>
                </Grid>}

                {!loadingPreviewData && <Grid item xs={5} >
                    <div style={{ marginTop: '0%' }}>
                        {selectedDataFormat && <PreviewDataformat dataformat={enrichedDataset ? enrichedDataset : selectedDataFormat} />}
                    </div>
                    {selectedDataFormat && selectedDataFormat.sqlQuery && selectedDataFormat.sqlQuery.length > 0 &&
                        <div style={{marginTop: '2%'}}>
                            <TextField id="standard-basic"
                                style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                                value={selectedDataFormat.sqlQuery}
                                label={'Dataset Query'}
                                disabled={true}
                            />
                        </div>}
                </Grid>}




            </Grid>

            <Accordion
                style={{ width: '100%', marginTop: '10px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid item xs={1}>
                        <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='subtitle2'>Aggregation Operations</Typography>
                    </Grid>

                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: "column", justifyContent: "space-around" }}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px', marginTop: '5px' }}>
                            <FormControlLabel
                                control={<Switch size="small" checked={aggregatePrior2Join} onChange={toggleAggregatePrior2Join} />}
                                label={<Typography variant='subtitle2' > {"Aggregate Before Join"}</Typography>}
                            />
                        </div>

                        <ColumnsSelectorAggregated
                            originalDF={selectedDataFormat}
                            baseDataFormat={enrichedDataset ? enrichedDataset : selectedDataFormat}
                            aggregationObject={aggregationObject}
                            aggregatePrior2Join={aggregatePrior2Join}
                            aggregateFlag={true}
                            setAggregationObject={setAggregationObject}
                        />
                    </div>
                </AccordionDetails>
            </Accordion >

            <ParameterConfiguration parameters={parameters} setParameters={setParameters} parentParameters={parentParameters} />
            <AdvanceConfigurationQuality setThError={setThError} setThWarning={setThWarning} setControlObj={setControlObj}
                thError={thError}
                thWarning={thWarning}
                controlObj={getControlObj()} />
            {editable && sourceCanHaveSchedule(props.datasource) && <SchedulingConfigurationQuality userId={user.id} datasource={props.datasource} project={props.project} selectedQualityPoint={selectedQualityPoint} />}
            <EnrichDatasourceComponent element={"datasource"}
                datasource={props.datasource}
                dataformat={enrichedDataset ? enrichedDataset : selectedDataFormat}
                joins={joins} setJoins={setJoins} userId={user.id} qualityPoint={selectedQualityPoint} project={props.project} />
            <TagsConfigurationQuality parent={selectedQualityPoint} qualityElement={props.datasource} elementType="datasource" setMandatoryTags={setMandatoryTags} setOptionalTags={setOptionalTags} mandatoryErrorTags={mandatoryErrorTags} mainParent={props.project} />
            <Typography variant={'caption'} style={{ color: commonStyles.errorColor }}>{mandatoryErrorTags}</Typography>
            <Typography variant={'caption'} style={{ color: commonStyles.errorColor }}>{deprecatedErrorTags}</Typography>
            {selectedDataFormat !== undefined && (selectedDataFormat.source === undefined || selectedDataFormat.source === null ||
                selectedDataFormat.source === 'azure-storage') &&
                <div></div>

                /*<SelectOutputPath setOutputPaths={setOutputPaths} dataformat={selectedDataFormat}
                outputPaths={outputPaths}
                enableOutputPath={enableOutputPath}
                setEnableOutputPath={setEnableOutputPath} />*/
            }
            {rulesWithIssues && rulesWithIssues.length > 0 &&
                <Typography variant={'subtitle2'} style={{ textAlign: "center", color: "red" }}>
                    {warningMessage}
                </Typography>}

            {rulesWithIssues && rulesWithIssues.length > 0 && rulesWithIssues.map(rule => {
                return (
                    <Typography variant={'subtitle2'} style={{ textAlign: "center", color: "red" }}>
                        {rule.name}
                    </Typography>
                )
            })
            }


        </AqtivaDialog>
    )
}