import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default function TreeViewDimensionsAndRuleTypes(props) {

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (props.data && props.data.length > 0 && props.data[0] !== undefined) {
            setData(props.data)
        }
    }, [props.data])

    React.useLayoutEffect(() => {

        if (data && data.length > 0) {
            let root = am5.Root.new(props.chart);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create wrapper container
            let container = root.container.children.push(
                am5.Container.new(root, {
                    width: am5.percent(100),
                    height: am5.percent(100),
                    layout: root.verticalLayout,


                })
            );

            // Create series
            // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
            let series = container.children.push(
                am5hierarchy.Treemap.new(root, {
                    singleBranchOnly: false,
                    downDepth: 1,
                    upDepth: -1,
                    initialDepth: 2,
                    valueField: "score",
                    categoryField: "dimension",
                    childDataField: "children",
                    nodePaddingOuter: 0,
                    nodePaddingInner: 0,

                })
            );


            series.labels.template.setAll({
                text: "[bold]{dimension}\n{ruleType} rule \nDQI: {score}%",
                fontSize: 14
            });



            series.rectangles.template.set("tooltipText",
                "[bold font-size:12px] {dimension}[/]\n " +
                "[bold font-size:12px]  {name} rule\n" +
                "[bold font-size:12px]DQI: {score}   \n" +
                "[font-size:12px]Executions: {executions}[/]\n"
            );


            let datas = {
                name: "Root",
                children: [
                    data[0]
                ]
            };
            series.data.setAll([datas]);
            series.set("selectedDataItem", series.dataItems[0]);

            return () => {
                container.dispose()
            }
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps


    return (

        <div>
            <div id={props.chart} style={{ width: "100%", height: "550px", }}></div>
        </div>
    )
}