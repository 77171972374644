import React from 'react';
import {
    TextField, Typography, Grid, FormControlLabel
} from '@material-ui/core';
import AqtivaDialog from 'components/common/AqtivaDialog'
import * as errorHelper from '../../AddDataCredentialsDialog/Helpers/ErrorHelper'
import * as commonStyles from 'style/CommonStyles'

import AqtivaDialogEncryptString from 'components/common/AqtivaDialogEncryptString'




export default function EditSqlServerDialog(props) {



    const [visibleEncrypt, setVisiblevisibleEncrypt] = React.useState(false)
    const [server, setServer] = React.useState('')
    const [displayName, setDisplayName] = React.useState('')
    const [user, setUser] = React.useState('')
    const [port, setPort] = React.useState('')
    const [database, setDatabase] = React.useState('')
    const [schema, setSchema] = React.useState('')
    const [encryptedPassword, setEncryptedPassword] = React.useState('')
    const [errors, setErrors] = React.useState(undefined)


    React.useEffect(() => {
        if (props.selectedDataCredentials && props.selectedDataCredentials.displayName && props.selectedDataCredentials.sqlServerCredentialsDTO) {
            setDisplayName(props.selectedDataCredentials.displayName)
            setServer(props.selectedDataCredentials.sqlServerCredentialsDTO.server)
            setPort(props.selectedDataCredentials.sqlServerCredentialsDTO.port ? props.selectedDataCredentials.sqlServerCredentialsDTO.port : '')
            setUser(props.selectedDataCredentials.sqlServerCredentialsDTO.user)
            setEncryptedPassword(props.selectedDataCredentials.sqlServerCredentialsDTO.pwd)
            setDatabase(props.selectedDataCredentials.sqlServerCredentialsDTO.database)
            setSchema(props.selectedDataCredentials.sqlServerCredentialsDTO.schema)
        }
    }, [props.selectedDataCredentials])

    async function editArrayElement(datacredentials) {
        let updatedDataCredentials = JSON.parse(JSON.stringify(props.selectedDataCredentials))
        let connectionObject = {}
        connectionObject.server = server
        connectionObject.user = user
        connectionObject.port = port
        connectionObject.database = database
        connectionObject.schema = schema
        connectionObject.pwd = encryptedPassword

        connectionObject.url = buildUrlConnectionSqlServer()

        updatedDataCredentials.sqlServerCredentialsDTO = connectionObject
        updatedDataCredentials.displayName = displayName
        let errorsToCheck = errorHelper.checkForErrorsSqlServer(updatedDataCredentials)
        if (!errorsToCheck.hasErrors) {
            let index = datacredentials.findIndex(x => x.id === updatedDataCredentials.id)
            datacredentials[index] = updatedDataCredentials
            props.setData(datacredentials)
            props.setUpdateCounter(props.updateCounter + 1)
            props.setShowEditDialog(false)
            resetState()
        }
        else {
            setErrors(errorsToCheck)
        }
    }

    function buildUrlConnectionSqlServer() {
        let url = "jdbc:sqlserver://"
        if (server) {
            url += server
            if (port) {
                url += ":" + port
            }
        }
        return url
    }
    function resetState() {
        setServer('')
        setUser('')
        setPort('')
        setSchema('')
        setDatabase('')
        setEncryptedPassword('')
        setDisplayName('')
        setErrors(undefined)
    }



    return (
        <>
            <AqtivaDialog visible={props.showEditDialog}
                maxWidth={'lg'}
                title={'update SQL server Credentials'}
                //iconSource={require('icons/data_proc1_white.png')}
                cancelText={'Close'}
                confirmText={'Store and Use'}
                showConfirmButton={true}
                cancelCallback={() => { props.setShowEditDialog(false) }}
                confirmCallback={async () => { editArrayElement(props.data)}}
            >
                <div style={{ width: '100%' }}>
                    <AqtivaDialogEncryptString visible={visibleEncrypt}
                        connectionMethod={props.connectionMethod}
                        setVisible={setVisiblevisibleEncrypt}
                        confirm={setEncryptedPassword}
                        sName={props.selectedDataCredentials && props.selectedDataCredentials.id + "localsttkk"}
                    />

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display name:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="DisplayName"
                                    label="Display name"
                                    margin="dense"
                                    value={displayName}
                                    onChange={(event) => { setDisplayName(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Server Name:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="Server"
                                    label="Server name"
                                    margin="dense"
                                    value={server}
                                    onChange={(event) => { setServer(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Port (optional):</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="port"
                                    label="port name"
                                    margin="dense"
                                    value={port}
                                    onChange={(event) => { setPort(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Database name:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="database"
                                    label="database name"
                                    margin="dense"
                                    value={database}
                                    onChange={(event) => { setDatabase(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Schema name:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="schema"
                                    label="schema name"
                                    margin="dense"
                                    value={schema}
                                    onChange={(event) => { setSchema(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>User:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    readonly
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    autoComplete="new-password"
                                    id="User"
                                    label="User"
                                    margin="dense"
                                    value={user}
                                    onChange={(event) => { setUser(event.target.value) }}
                                />} autoComplete="new-password" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Password:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                            autoComplete="new-password"
                            control={
                                <TextField style={{ width: '100%' }}
                                    autoComplete="new-password"
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="Password"
                                    label="Password"
                                    margin="dense"
                                    type="password"
                                    value={encryptedPassword}
                                    onClick={(event) => { setVisiblevisibleEncrypt(true) }}
                                />} />
                    </Grid>
                    <Grid item xs={12}>
                        {errors && errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{errors.sqlServerCredentials}</Typography>}
                    </Grid>
                </div>
            </AqtivaDialog>
        </>
    )
}