import React from 'react';
import {
    Typography, Switch, FormGroup, FormControlLabel, TextField,
    Accordion, AccordionSummary, AccordionDetails, Grid, Tooltip
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'


export default function RuleRecomenderAdvanceConfiguration(props) {
    const languageContext = React.useContext(LanguageContext); 

    const theme = useTheme()

    const [nullRuleActivated, setNullRuleActivated] = React.useState(true)
    const [schemaRuleActivated, setSchemaRuleActivated] = React.useState(true)
    const [duplicatedRuleActivated, setDuplicatedRuleActivated] = React.useState(true)
    const [numericRangeRuleActivated, setNumericRangeRuleActivated] = React.useState(true)
    const [valueRangeRuleActivated, setValueRangeRuleActivated] = React.useState(true)
    const [regexRuleActivated, setRegexRuleActivated] = React.useState(true)

    const [nullRuleMinScore, setNullRuleMinScore] = React.useState(990)
    const [duplicatedRuleMaxScore, setDuplicatedRuleMaxScore] = React.useState(990)
    const [duplicatedRuleMaxScoreDataset, setDuplicatedRuleMaxScoreDataset] = React.useState(990)
    const [numericRangeMinScore, setNumericRangeMinScore] = React.useState(500)
    const [numericRangeAllowOpenRange, setNumericRangeAllowOpenRange] = React.useState(true)
    const [valueRangeMinScore, setValueRangeMinScore] = React.useState(200)
    const [valueRangeHardLimit, setValueRangeHardLimit] = React.useState(20)

    const [regexMinScore, setRegexMinScore] = React.useState(750)
    const [regexPatternRatio, setRegexPatternRatio] = React.useState(0.75)
    const [regexPatternExpressions, setRegexPatternExpressions] = React.useState(10)

    React.useEffect(()=>{
        if(props.setJsonConfig){
            props.setJsonConfig(generateJson())
        }
    },[nullRuleActivated,schemaRuleActivated,duplicatedRuleActivated,numericRangeRuleActivated,valueRangeRuleActivated,// eslint-disable-line react-hooks/exhaustive-deps
        regexRuleActivated,nullRuleMinScore,duplicatedRuleMaxScore,duplicatedRuleMaxScoreDataset,numericRangeMinScore,// eslint-disable-line react-hooks/exhaustive-deps
        numericRangeAllowOpenRange,valueRangeMinScore,valueRangeHardLimit,regexMinScore,regexPatternRatio,regexPatternExpressions// eslint-disable-line react-hooks/exhaustive-deps
    ])// eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(()=>{
        if(props.setJsonConfig){
            props.setJsonConfig(generateJson())
        }
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    function generateJson(){
        var jsonConfig = {
            "null_rule": {
                "active": nullRuleActivated,
                "min_score": parseInt(nullRuleMinScore)
            },
            "schema_rule": {
                "active": schemaRuleActivated,
                "min_score": -1
            },
            "duplicates_rule": {
                "active": duplicatedRuleActivated,
                "max_score": parseInt(duplicatedRuleMaxScore),
                "max_score_dataset": parseInt(duplicatedRuleMaxScoreDataset)
            },
            "numeric_range_rule": {
                "active": numericRangeRuleActivated,
                "min_score": parseInt(numericRangeMinScore),
                "allow_open_range": parseInt(numericRangeAllowOpenRange)
            },
            "values_rule": {
                "active": valueRangeRuleActivated,
                "max_score": parseInt(valueRangeMinScore),
                "hard_limit": parseInt(valueRangeHardLimit)
            },
            "row_count_rule": {
                "active": false,
                "max_disp": 2
            },
            "missed_row_rule": {
                "active": true
            },
            "reg_exp_rule": {
                "active": regexRuleActivated,
                "min_score": parseInt(regexMinScore),
                "pattern_ratio": parseFloat(regexPatternRatio),
                "pattern_expressions": parseInt(regexPatternExpressions)
            },
            "outlier_rule": {
                "active": true,
                "max_score": 10,
                "numeric_interval": 2,
                "non_numeric_outlier": 10
            },
            "distribution_frequency_rule": {
                "active": true,
                "distance_th": 20,
                "max_unique_categorical": 10,
                "numerical_bins": 5
            },
            "row_anomaly_rule": {
                "active": true,
                "max_unique_categorical": 10,
                "min_relevant_columns": 600
            }
        }
        return jsonConfig
    }

    return (

        <Accordion
            style={{ width: '100%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={1}>
                    <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                </Grid>
                <Grid item xs={11}>
                    <Typography variant='subtitle2'>{languageContext.dictionary['thBlockTitle']}</Typography>


                </Grid>

            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <div id="null_rule" style={{ marginTop: '5px' }}>
                        <Typography variant='subtitle2'>
                            Null Rule
            </Typography>
                        <div style={theme.dataRepository.elementAdjacent}>
                            <FormGroup row style={{ marginTop: '15px' ,width:'150px'}}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            
                                            color={'primary'}
                                            checked={nullRuleActivated}
                                            onChange={event => setNullRuleActivated(event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant='caption'>{nullRuleActivated === true ?
                                         languageContext.dictionary['activatedLabel'] : languageContext.dictionary['deactivatedLabel']}</Typography>}
                                />

                            </FormGroup>
                            <div style={theme.dataRepository.elementAdjacent}>
                            <TextField id="standard-basic" label={languageContext.dictionary['minScore']}
                                value={nullRuleMinScore}
                                style={{ width: '130px', marginRight: '15px' }}
                                onChange={event => setNullRuleMinScore(event.target.value)}
                                type="number"
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title={languageContext.dictionary['minScoreTooltip']}>
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                 />
                                 </div>

                        </div>
                    </div>

                    <div id="schema_rule" style={{ marginTop: '5px' }}>
                        <Typography variant='subtitle2'>
                            Schema Rule
            </Typography>
                        <div style={theme.dataRepository.elementAdjacent}>
                            <FormGroup row style={{ marginTop: '15px',width:'150px' }}>
                                <FormControlLabel
                                    control={
                                        <Switch

                                            color={'primary'}
                                            checked={schemaRuleActivated}
                                            onChange={event => setSchemaRuleActivated(event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant='caption'>{schemaRuleActivated === true ? "Activated" : "Deactivated"}</Typography>}
                                />

                            </FormGroup>


                        </div>
                    </div>

                    <div id="duplicated_rule" style={{ marginTop: '5px' }}>
                        <Typography variant='subtitle2'>
                            Duplicated Rule
            </Typography>
                        <div style={theme.dataRepository.elementAdjacent}>
                            <FormGroup row style={{ marginTop: '15px',width:'150px' }}>
                                <FormControlLabel
                                    control={
                                        <Switch

                                            color={'primary'}
                                            checked={duplicatedRuleActivated}
                                            onChange={event => setDuplicatedRuleActivated(event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant='caption'>{duplicatedRuleActivated === true ? "Activated" : "Deactivated"}</Typography>}
                                />

                            </FormGroup>
                            <TextField id="standard-basic" label="Max. Score"
                                value={duplicatedRuleMaxScore}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Maximum proportion (as per thousand) of repeated values ​​in the
                                    column to recommend the rule">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setDuplicatedRuleMaxScore(event.target.value)}
                                type="number" />

                            <TextField id="standard-basic" label="Max Score Data"
                                value={duplicatedRuleMaxScoreDataset}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Maximum proportion (as per thousand) of repeated values ​​in the
                                    column to recommend the rule">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setDuplicatedRuleMaxScoreDataset(event.target.value)}
                                type="number" />

                        </div>
                    </div>

                    <div id="duplicated_rule" style={{ marginTop: '5px' }}>
                        <Typography variant='subtitle2'>
                            Numeric Range Rule
            </Typography>
                        <div style={theme.dataRepository.elementAdjacent}>
                            <FormGroup row style={{ marginTop: '15px' ,width:'150px'}}>
                                <FormControlLabel
                                    control={
                                        <Switch

                                            color={'primary'}
                                            checked={numericRangeRuleActivated}
                                            onChange={event => setNumericRangeRuleActivated(event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant='caption'>{numericRangeRuleActivated === true ? "Activated" : "Deactivated"}</Typography>}
                                />

                            </FormGroup>

                            <TextField id="standard-basic" label="Min. Score"
                                value={numericRangeMinScore}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Configure under which conditions the rule is recommended. Their
                                    Possible values ​​are positive numbers greater than 0 in the range (0, 1000]. ">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setNumericRangeMinScore(event.target.value)}
                                type="number" />

                            <FormGroup row style={{ marginTop: '15px', width: '300px', marginRight: '15px' }}>
                                <FormControlLabel
                                    control={
                                        <Switch

                                            color={'primary'}
                                            checked={numericRangeAllowOpenRange}
                                            onChange={event => setNumericRangeAllowOpenRange(event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant='caption'>Allow Open Range</Typography>}
                                />

                            </FormGroup>

                        </div>
                    </div>

                    <div id="duplicated_rule" style={{ marginTop: '5px' }}>
                        <Typography variant='subtitle2'>
                            Value Range Rule
            </Typography>
                        <div style={theme.dataRepository.elementAdjacent}>
                            <FormGroup row style={{ marginTop: '15px' ,width:'150px'}}>
                                <FormControlLabel
                                    control={
                                        <Switch

                                            color={'primary'}
                                            checked={valueRangeRuleActivated}
                                            onChange={event => setValueRangeRuleActivated(event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant='caption'>{valueRangeRuleActivated === true ? "Activated" : "Deactivated"}</Typography>}
                                />

                            </FormGroup>
                            <TextField id="standard-basic" label="Min. Score"
                                value={valueRangeMinScore}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Per thousand maximum of distinct values ​​to admit column
                                    as a categorical variable.">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setValueRangeMinScore(event.target.value)}
                                type="number" />

                            <TextField id="standard-basic" label="Hard Limit"
                                value={valueRangeHardLimit}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Absolute value of the maximum number of distinct values ​​for
                                    admit column as categorical variable.">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setValueRangeHardLimit(event.target.value)}
                                type="number" />

                        </div>
                    </div>

                    <div id="duplicated_rule" style={{ marginTop: '5px' }}>
                        <Typography variant='subtitle2'>
                            Regular Expression Rule
            </Typography>
                        <div style={theme.dataRepository.elementAdjacent}>
                            <FormGroup row style={{ marginTop: '15px',width:'150px' }}>
                                <FormControlLabel
                                    control={
                                        <Switch

                                            color={'primary'}
                                            checked={regexRuleActivated}
                                            onChange={event => setRegexRuleActivated(event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant='caption'>{regexRuleActivated === true ? "Activated" : "Deactivated"}</Typography>}
                                />

                            </FormGroup>
                            <TextField id="standard-basic" label="Min. Score"
                                value={regexMinScore}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Minimum proportion threshold (as per thousand) of matches of
                                    a regular expression to be recommended.">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setRegexMinScore(event.target.value)}
                                type="number" />

                            <TextField id="standard-basic" label="Pattern Ratio"
                                value={regexPatternRatio}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Proportion (as per one) of minimum coincidence between
                                    pairs of values ​​for pattern extraction.">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setRegexPatternRatio(event.target.value)}
                                type="number" />

                            <TextField id="standard-basic" label="N. Pattern Expr."
                                value={regexPatternExpressions}
                                style={{ width: '130px', marginRight: '15px' }}
                                InputProps={{
                                    style: { fontSize: 12 },
                                    endAdornment: 
                                    <Tooltip title="Number of pairs of values ​​to analyze to find
                                    a common pattern between them.">
                                        <div>
                                        <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ color:commonStyles.mainColor,fontSize: "calc(8px + 0.4vw)" }}
                                        fontSize="small"
                                    />
                                    </div>
                                  </Tooltip>
                                  }}
                                onChange={event => setRegexPatternExpressions(event.target.value)}
                                type="number" />

                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>)
}  