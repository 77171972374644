
import quality from './../apis/quality';
import {
  CREATE_DATAFORMAT,
  FETCH_DATAFORMATS,
  FETCH_ALL_DATAFORMATS,
  EDIT_DATAFORMAT, CREATE_DATAFORMAT_VERSION
} from './types'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';
import qualityLocalHost from 'apis/qualityLocalHost';
import qualityLocalHost2 from 'apis/qualityLocalHost2';
//import qualityLocalHost from 'apis/qualityLocalHost';
//import qualityLocalHost4 from 'apis/qualityLocalHost4';
//import qualityLocalHost from '../apis/qualityLocalHost';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
//import qualityLocalHost3 from 'apis/qualityLocalHost3';


export const addDataFormat = (columns, name, userId, fileStorageProperties) => async (dispatch, getState) => {
  var dataformat2Submit = {};
  dataformat2Submit.columns = columns
  dataformat2Submit.name = name
  dataformat2Submit.userId = userId;
  dataformat2Submit.fileStorageProperties = fileStorageProperties
  await quality.post('/dataformat/', dataformat2Submit, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: CREATE_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${name} created`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};


export const shareDataformat = (dataformatId, name, userId) => async (dispatch, getState) => {
  
  await quality.get(`/dataformat/share/id/${dataformatId}/user/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: EDIT_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${name} shared`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};

export const addDataFormatObject = (dataformat) => async (dispatch, getState) => {

  await quality.post('/dataformat/', dataformat, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: CREATE_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${dataformat.name} created`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};

export const createNewDataformatVersion = (dataformat, dataformatId) => async (dispatch, getState) => {

  await quality.post(`/dataformat/new/version/${dataformatId}`, dataformat, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: CREATE_DATAFORMAT_VERSION,
      payload: response.data
    });
    dispatchInfo(dispatch, `New Dataformat version ${dataformat.name} created`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};

export const editDataformat = (dataformat) => async (dispatch, getState) => {

  await quality.put(`/dataformat/${dataformat._id}`, dataformat, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: EDIT_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${dataformat.name} edited`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};


export const addDataFormatAndRegister = (columns, separator, name, userId, dtoId, folderId, fileStorageProperties) => async (dispatch, getState) => {
  var dataformat2Submit = {};
  dataformat2Submit.columns = columns
  dataformat2Submit.separator = separator
  dataformat2Submit.name = name
  dataformat2Submit.userId = userId;
  dataformat2Submit.fileStorageProperties = fileStorageProperties
  await quality.post(`/dataformat/register/dto/${dtoId}/folder/${folderId}`, dataformat2Submit, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: CREATE_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${name} created`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};


export const addDataFormatNew = (columns, separator, name, userId, fileStorageProperties) => async (dispatch, getState) => {
  var dataformat2Submit = {};
  dataformat2Submit.columns = columns
  dataformat2Submit.separator = separator
  dataformat2Submit.name = name
  dataformat2Submit.userId = userId;
  dataformat2Submit.fileStorageProperties = fileStorageProperties
  await quality.post(`/dataformat/`, dataformat2Submit, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: CREATE_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${name} created`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};


export const parseDataFormatBlob = (separator, blobFileName, userId, container, environment) => async (dispatch, getState) => {

  let finalContainer = container ? container : "null"
  const response = await quality.post(`/dataformat/parse/azure/${separator}/${userId}/${finalContainer}/${environment}`, blobFileName, {
    headers: axiosHeader()
  })
  return response

};

export const parseDataFormatS3 = (separator, blobFileName, userId, environment) => async (dispatch, getState) => {

  const response = await quality.post(`/dataformat/parse/aws/${separator}/${userId}/${environment}`, blobFileName, {
    headers: axiosHeader()
  })
  return response

};
export const fetchDataformat = (dataformatId) => async (dispatch, getState) => {
  var returnValue = { result: 'undefined', data:{} }
  await quality.get(`/dataformat/detail/${dataformatId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
    /* dispatch({
      type: EDIT_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${dataformat.name} edited`) */
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });
  return returnValue

};

export const getDataformatDetailById = (dataformatId) => async (dispatch, getState) => {
  var returnValue = { result: 'undefined', data:undefined }
  await quality.get(`/dataformat/detail/${dataformatId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
    /* dispatch({
      type: EDIT_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${dataformat.name} edited`) */
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });
  return returnValue

};

export const fetchDataFormats = (user) => {
  var id = user.id
  if (id) {
    return async function (dispatch, getState) {
      await quality.get(`/dataformats/short/${id}`, {
        headers: axiosHeader()
      }).then(function (response) {
        dispatch({
          type: FETCH_DATAFORMATS,
          payload: response.data
        });
      })
        .catch(function (error) {
          dispatchError(dispatch, error)

        });

    };
  }
  else {
    return async function (dispatch, getState) {
    }
  }


};

export const fetchAllDataFormats = (user) => {

  var id = user.id
  if (id) {
    return async function (dispatch, getState) {
      await quality.get(`/dataformats/short/license/${id}`, {
        headers: axiosHeader()
      }).then(function (response) {
        dispatch({
          type: FETCH_ALL_DATAFORMATS,
          payload: response.data
        });
      })
        .catch(function (error) {
          dispatchError(dispatch, error)

        });

    };
  }
  else {
    return async function (dispatch, getState) {
    }
  }


};

export const fetchDataformatVersions = (dataformatId) => async (dispatch, getState) => {
  var returnValue = { result: 'undefined' }
  await quality.get(`/dataformat/get/versions/${dataformatId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
    /* dispatch({
      type: EDIT_DATAFORMAT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Dataformat ${dataformat.name} edited`) */

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });
  return returnValue

};




export const deleteUnusedDataformats = (user) => {
  return async function (dispatch, getState) {
    const userName = user.id
    await quality.delete(`/dataformat/unused/${userName}`, {
      headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: FETCH_DATAFORMATS,
        payload: response.data
      });
      dispatchInfo(dispatch, `Deleted unused datasets`)
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
      });

  };
}



/* export const startProfilingJob = (environment, nSamples, flatten, dataset) => async (dispatch, getState) => {
  var returnValue = {}
  await quality.post(`/databricks/createProfilingDataJob2/${environment}/${nSamples}/${flatten}`, dataset, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)

  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
} */



export const addInternalProfiling = (rowData) => async (dispatch, getState) => {
  var returnValue
  dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")
  var environment = "demo_environment"
  var itemDTO = { "type": "file", "name": rowData.fileStorageProperties.path, "fullName": rowData.source !== 'azure-storage' ? rowData.fileStorageProperties.path : null, "resourceGroupName": rowData.fileStorageProperties.resourceGroup, "storageAccount": rowData.fileStorageProperties.account, "container": rowData.fileStorageProperties.container, "children": [], "level": 0, "parent": "", "listChildrenNames": null }
  var nSamples = 200
  var flatten = false
  await quality.post(`/databricks/createProfilingDataJob/${environment}/${rowData.userId}/${nSamples}/${flatten}`, itemDTO, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

    dispatchInfo(dispatch, `Data profiling job running, will notify you when finished`)

  })
    .catch(function (error) {

      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const getAzureStorageDataformats = (userId) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/azure/storage/user/${userId}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data


  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const getSqlOnpremiseDataformats = (userId, source) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/onpremise/user/${userId}/${source}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const getBigQueryOnpremiseDataformats = (userId, source) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/onpremise/user/${userId}/${source}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const getAwsOnpremiseDataformats = (userId, source) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/onpremise/user/${userId}/${source}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const getHiveOnpremiseDataformats = (userId, source) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/onpremise/user/${userId}/${source}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const getAzureSqlDatabaseDataformats = (userId) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/azure/sqldatabase/user/${userId}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data


  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const getAzureSqlSynapseDataformats = (userId) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/azure/sqlsynapse/user/${userId}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data


  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const getRedshiftOnpremiseDataformats = (userId, source) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/onpremise/user/${userId}/${source}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
}

export const getAzureCosmosDataformats = (userId) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/dataformat/get/azure/cosmos/user/${userId}`, undefined, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data


  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


