

import {isEmpty} from 'commonFunctions/commonFunctions'


export function createQualityPoint(name, thWarning, thError, advance){
    /*const qp = {
        _id: undefined,
        name: name,
        thWarn: thWarning,
        thError: thError,
        advanced: advance
    }*/

}

export function addQualityPoint2Project(project,qualityPoint){

    const qps = project.qualityPoints
    if(qps===undefined){
        
    }
}

export function findQualityPointFromDatasourceId(project, datasourceId){
    //vamos a buscar en todos los qualityPoint aquel que incluye el datasource
    //FIX SENTRY ERROR ID 9a98357298c94fa19aab59d5ef9868ae
    for(var i = 0;i<project.qualityPoints.length ; i++){
        const qp = project.qualityPoints[i]
        if(qp && qp.datasources!==undefined && qp.datasources!==null){
            const tmpDatasources = qp.datasources.filter(x=>x._id===datasourceId)
            if(tmpDatasources && tmpDatasources.length>0){
                return qp
            }
        }
        
    }
    return null
}

export function findDatasourceFromDatasourceId(project, datasourceId){
    //vamos a buscar en todos los qualityPoint aquel que incluye el datasource
    for(var i = 0;i<project.qualityPoints.length ; i++){
        const qp = project.qualityPoints[i]
        const tmpDatasources = qp.datasources.filter(x=>x._id===datasourceId)
        if(tmpDatasources && tmpDatasources.length>0){
            return tmpDatasources[0]
        }
    }
    return null
}


export function findQualityPointFromRuleId(project, ruleId){
    //vamos a buscar en todos los qualityPoint aquel que incluye el datasource
    for(var i = 0;i<project.qualityPoints.length ; i++){
        const qp = project.qualityPoints[i]
        const tmpDatasources = qp.datasources
        if(tmpDatasources!==undefined && tmpDatasources!==null && tmpDatasources.length>0){
            for (var j=0;j<qp.datasources.length;j++){
                const ds = qp.datasources[j]
                if( ds && ds.rules && ds.rules!==null && ds.rules.length>0){
                    const rules = ds.rules.filter(x=>x._id === ruleId)
                    if(rules && rules.length>0){
                        return qp
                    }
                }
                
            }
        }
        
    }
    return null
}

export function findDatasourceFromRuleId(project, ruleId){
    //vamos a buscar en todos los qualityPoint aquel que incluye el datasource
    for(var i = 0;i<project.qualityPoints.length ; i++){
        const qp = project.qualityPoints[i]
        const tmpDatasources = qp.datasources
        if(tmpDatasources!==undefined && tmpDatasources!==null && tmpDatasources.length>0){
            for (var j=0;j<qp.datasources.length;j++){
                const ds = qp.datasources[j]
                if( ds && ds.rules && ds.rules!==null && ds.rules.length>0){
                    const rules = ds.rules.filter(x=>x._id === ruleId)
                    if(rules && rules.length>0){
                        return ds
                    }
                }
                
                
            }
        }
        
    }
    return null
}



export function searchProject(searchText, project){
    var qualityPoints = []
    if(!project.qualityPoints || project.qualityPoints===null){
        return project
    }
    for(var i = 0;i<project.qualityPoints.length ; i++){
        
        
            const qp = searchQualityPoint(searchText,project.qualityPoints[i])
            if(!isEmpty(qp.datasources) || qp.name.toLowerCase().includes(searchText.toLowerCase())){
                qualityPoints.push(qp)
            }
        
        
    }
    var filteredProject = Object.assign({}, project);
    filteredProject.qualityPoints = qualityPoints
    return filteredProject
}

function searchQualityPoint(searchText, qualityPoint){
    if(!qualityPoint.datasources || qualityPoint.datasources===null){
        return qualityPoint
    }
    if(qualityPoint.name.toLowerCase().includes(searchText.toLowerCase())){
        return qualityPoint
    }
    var datasources = []
    for(var i = 0;i<qualityPoint.datasources.length ; i++){
        
            const ds = searchDatasource(searchText,qualityPoint.datasources[i])
            if(!isEmpty(ds.rules) || ds.name.toLowerCase().includes(searchText.toLowerCase())){
                datasources.push(ds)
            }
        
        
    }
    var filteredQP = Object.assign({}, qualityPoint);
    filteredQP.datasources = datasources
    return filteredQP
}

function searchDatasource(searchText, datasource){
    if(!datasource.rules || datasource.rules===null){
        return datasource
    }
    if(datasource.name.toLowerCase().includes(searchText.toLowerCase())){
        return datasource
    }
    var rules = []
    for(var i = 0;i<datasource.rules.length ; i++){
        const rule = searchRule(searchText,datasource.rules[i])
        if(!isEmpty(rule)){
            rules.push(rule)
        }
        
    }
    var filteredRule = Object.assign({}, datasource);
    filteredRule.rules = rules
    
    return filteredRule
}

function searchRule(searchText, rule){
    if(rule.name.toLowerCase().includes(searchText.toLowerCase())){
        return Object.assign({}, rule);
    }
    return {}
}