import React from 'react';
//import AqtivaAppBar from './../components/template/AqtivaAppBar'
import * as pathHelper from './../components/template/PathHelper'
import AppBarTemplate from 'components/template/AppBarTemplate'


import ReactGA from 'react-ga';

import QualitySchedulePageForm from './../components/schedule/QualitySchedulePageForm'


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/simulation/repository '); 

export default function QualityShedulePage(props) {

    

    return (
        <div >
      <AppBarTemplate
        history={props.history}
        title={"Scheduling"}
        arrayPath={pathHelper.getPathArray("Governance")}
      />
      <QualitySchedulePageForm props ={props} />
        </div>

    );
}