import React from "react";
import AqtivaDialog from "components/common/AqtivaDialog";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import { Grid, TextField, Typography,  } from "@material-ui/core"
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import QualityItemSelector from 'components/common/QualityItemSelector'
import * as simulationHelper from './StreamSimulationHelper'
import * as actions from 'actions'
import { axiosHeader } from 'components/common/axios/axiosApiHelper'
import { BlobServiceClient } from '@azure/storage-blob';

import { API_MS_URL } from 'Constants'
import { FETCH_INFO } from 'actions/types'

import DetailStreamSimulationTable from './DetailStreamSimulationTable'
import trackEvent from 'trackEvent'

import { useHistory } from 'react-router-dom'

import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'


export default function DialogCreateStreamSimulation(props) {
    const theme = useTheme();
    const dispatch = useDispatch()
    const history = useHistory();


    const configuration = useSelector(store => (store.qualityConfiguration))
    const user = useSelector((store) => store.user);
    const license = useSelector((store)=>store.license)

    const [minNumberRegisters, setMinNumberRegisters] = React.useState(10)
    const [selectedDatasource, setSelectedDatasource] = React.useState('')
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState(props.selectedQualityPoint ? props.selectedQualityPoint : '')
    const [error, setError] = React.useState({})
    const [showDetails, setShowDetails] = React.useState(false)
    const [simulationId, setSimulationId] = React.useState(undefined)
    const [stopInterval, setStopInterval] = React.useState(false)

    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
//const [selectedEnvironmentStates, setSelectedEnvironmentStates] = React.useState('')
    function setSelectedEnvironmentStates(){}

    function reset(){
        setMinNumberRegisters(10)
        setShowDetails(false)
        setSimulationId(false)
    }

    function resetState(){
        
        setMinNumberRegisters(10)
        setSelectedDatasource('')
        setSelectedQualityPoint(props.selectedQualityPoint ? props.selectedQualityPoint : '')
        setError({})
        setShowDetails(false)
        setSimulationId(undefined)
        setStopInterval(false)
        
    }

    function go2Dashboard(){
        let simId = simulationId
        resetState()
        history.push(`/${process.env.REACT_APP_PREFIX}/dashboard/${simId}/simulation`);
    }

    function createSimulation() {
        trackEvent('DialogCreateStreamSimulation','Create Simulation');
        let simId =simulationHelper.create_UUID()
        setSimulationId(simId)
        let dto = simulationHelper.createStreamSimulationDTO(license,props.project,selectedQualityPoint, minNumberRegisters, configuration, user.id, simId,
            configuration.environmentConfigurationList[0].environmentName)
        onClickDeploy(configuration.environmentConfigurationList[0].environmentName,simId).then(response =>
            {
            dispatch(actions.createStreamSimulation(dto))
            setShowDetails(true)
            }

        )
    }

    async function asyncForEach(array, callback) {
        if (array) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        }
    }

    const asyncDeployForEach = async (data, environment, containerClient, projectId) => {
        await asyncForEach(data, async (x) => {
            var filename = "deploy/stream/simulation/" + user.id + '/simulation.json'
            filename = filename.split(' ').join('_')


            const blockBlobClient = containerClient.getBlockBlobClient(filename)
            blockBlobClient.uploadBrowserData(x).then(
                response => {
                    dispatch({
                        type: FETCH_INFO,
                        payload: `QualityPoint ${JSON.parse(x).quality_point.name} updated to BlobStorage`
                    });
                }
            )

        });

    }


    async function onClickDeploy(environment, simulationId) {

        
        if (environment) {


            if (props.project.environment === 'On-premise') {
                //if(!props.project.qualityPoints[0].datasources[0].type){
                fetch(API_MS_URL + `/project/datafile/${props.project._id}/${environment}`, {
                    headers: axiosHeader()
                })
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = props.project._id + "_" + new Date().getTime() + ".zip";
                            a.click();
                        });
                    });

            }
            else {
                let storageAccountName = selectedEnvironment ? selectedEnvironment.storageAccountName : 'sainputdemoquality'
                let containerName = selectedEnvironment ? selectedEnvironment.containerName : 'unit-test'
                let sasToken = selectedEnvironment ? selectedEnvironment.sasToken :
                    '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'

                const response = await dispatch(actions.getQualityConfigurationDeployment(props.project._id, environment,simulationId))
                const STORAGE_ACCOUNT_NAME = storageAccountName
                const CONTAINER_NAME = containerName
                // for browser, SAS_TOKEN is get from API?
                const SAS_TOKEN_TMP = await  dispatch(actions.getSecret(sasToken))
                let SAS_TOKEN = SAS_TOKEN_TMP.startsWith("?") ? SAS_TOKEN_TMP : "?"+SAS_TOKEN_TMP
                const sasURL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${SAS_TOKEN}`


                const blobServiceClient = new BlobServiceClient(sasURL)
                const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)

                const configs = response.configuration
                asyncDeployForEach(configs, environment, containerClient, props.project._id)



            }
        }

    }


    return (
        <AqtivaDialog
            visible={props.visible}
            maxWidth={"lg"}
            title={"Create Streaming Processing Simulation"}
            titleIcon={faHubspot}
            confirmText={stopInterval===true || simulationId !==undefined ? "Go to Dashboard" : "Simulate"}
            cancelText={"Close"}
            confirmCallback={(event) => stopInterval===true || simulationId !==undefined ? go2Dashboard() : createSimulation()}
            cancelCallback={(event) => {reset(); props.setVisible(false)}}
        >
            <Grid container spacing={3}>
            <div style={{ width: '150px', marginRight: '20px' }}>
                                <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                                    setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                            </div>
                <Grid item xs={12}>
                    <TextField
                        style={{ fontSize: '2px' }}
                        id="number_regs_simulate"
                        type="number"
                        label={<Typography style={theme.inputLabel}>Number of registers to simulate</Typography>}
                        defaultValue={minNumberRegisters}
                        onChange={event => setMinNumberRegisters(event.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                {!props.selectedQualityPoint &&
                    <Grid item xs={12}>
                        <QualityItemSelector
                            title={'Select a Quality Point'} type='QualityPoint'
                            value={selectedQualityPoint}
                            onChange={event => setSelectedQualityPoint(event.target.value)}
                            itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints.filter(x => x.active === true) : [])}
                            itemId={'_id'} itemValue={'name'}
                        />


                    </Grid>}
                {selectedQualityPoint && selectedQualityPoint.datasources &&
                    selectedQualityPoint.datasources.length > 1 &&
                    <Grid item xs={12}>


                        <QualityItemSelector
                            title={'Select a Data Source'} type='Datasource'
                            value={selectedDatasource}
                            onChange={event => setSelectedDatasource(event.target.value)}
                            itemsArray={selectedQualityPoint.datasources.filter(x => x.active === true)}
                            itemId={'_id'} itemValue={'name'}
                        />


                    </Grid>}
                {error && error.selectedQualityPoint &&
                    <div className="ui error message" style={theme.errors.errorText}>
                        <div className="header" >{error.selectedQualityPoint}</div>
                    </div>}
                {showDetails && <Grid item xs={12}>
                    <DetailStreamSimulationTable
                    userId = {user.id}
                    simulationId = {simulationId}
                    stopInterval = {stopInterval}
                    setStopInterval = {setStopInterval}
                    />
                </Grid>}
            </Grid>
        </AqtivaDialog>
    );
}
