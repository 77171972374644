import React from 'react';
import AqtivaDialog from '../AqtivaDialog'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import {
    makeStyles, TextField, FormControlLabel
} from '@material-ui/core';
import { styles, QualityTheme } from '../../../style/CustomTheme'
import classNames from 'classnames';
import { encryptString } from '../encryptHelper'
import { useDispatch } from 'react-redux';


export default function AqtivaDialogParseAndEncryptSasToken(props) {
    const classes = makeStyles(styles(QualityTheme));

    const [value, setValue] = React.useState('')

    const dispatch = useDispatch();

    async function confirm() {
        if (props.connectionMethod && props.connectionMethod === "connectionString") {
            let expirationDate
            let connectionObject = {}
            let treatedValue
            let encryptedSas

            if (value && value.split("&st=").length > 1) {
                expirationDate = value && value.split("&st=")[1].split("&")[0]
            }
            if (props.resourceType && props.resourceType === "azure_storage") {
                treatedValue = value[0] === "?" ? value.substring(1) : value
                encryptedSas = await encryptString(props.sName, dispatch, treatedValue)
            }
            if (props.resourceType && props.resourceType === "azure_container") {
                treatedValue = value[0] === "?" ? value.substring(1) :  value
                encryptedSas = await encryptString(props.sName, dispatch, treatedValue)
            }
            connectionObject.sharedAccessSignature = encryptedSas
            connectionObject.expirationDate = expirationDate
            props.confirm(connectionObject)
            props.setVisible(false)
            return
        }
        if (props.connectionMethod && props.connectionMethod === "accountKey") {
            props.confirm(await encryptString(props.sName, dispatch, value))
            return
        }
        props.confirm(await encryptString(props.sName, dispatch, value))
        props.setVisible(false)
    }
    return (
        <AqtivaDialog visible={props.visible}
            maxWidth={'md'}
            title={'Encrypt Value'}
            titleIcon={faKey}
            confirmText={'Encrypt and Save'}
            cancelText={'Close'}
            showConfirmButton={props.sName ? true : false}
            confirmCallback={confirm}
            cancelCallback={event => { props.setVisible(false) }}
        >
            <FormControlLabel style={{ marginLeft: '5%', width: '300px' }}
                control={
                    <TextField style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                        id="databricksToken"
                        type="password"
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        value={value}
                        onChange={(event) => { setValue((event.target.value)) }}
                    />} />
        </AqtivaDialog>
    )

}