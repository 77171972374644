import React from "react";

import Drag from "./Drag/index";
import { Tooltip} from '@material-ui/core';
//Este componente es una especie de wrapper que sirve para englobar las zonas de drag and drop.
//Cuando queramos aplicarlo lo haremos en DataRepositoryTreeTable y le pasaremos por {component} la zona que nos interesa.
export function DragDropTemplate(
  component,
  data,
  modifiedProject,
  project,
  dragIfRule
) {


  if ((data.data.nodeType === 'Rule' && !dragIfRule)) {
    return (
      <Drag data={data} modifiedProject={modifiedProject} project={project} /* style={{ marginLeft: "10%" }} */>
        <span>
          {component}
        </span>
      </Drag>
    )
  } if (data.data.type === 'Datasource') {
    return (
      <Tooltip title={data.data.dataformatName}>
        <span> {component} </span>
      </Tooltip>
    )
  } else {
    return (<span> {component} </span>)
  }
}