import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCircle,  faTrash, faEdit,  faList } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MaterialTable from "@material-table/core";
import DeleteTag from './DeleteTagDialog'
import EditTagDialog from './EditTagDialog'
import CreateNewtagDialog from './CreateNewTagDialog'
import { Tooltip } from '@material-ui/core'


import * as commonStyles from 'style/CommonStyles'



const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
    },
    fabWarning: {
        margin: theme.spacing(1),
        backgroundColor: "yellow"

    }

}));



export default function TagsTable(props) {
    const classes = useStyles();

    const [data, setData] = useState([])
    const [selectedTag, setSelectedTag] = useState(undefined)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [updateCounter, setUpdateCounter] = useState(1)
    

    useEffect(() => {
        if (props.tags && props.tags.length > 0) {
            setData(props.tags)
        }
    }, [props.tags, props.tags && props.tags.length, showConfirmDialog, showCreateDialog])// eslint-disable-line react-hooks/exhaustive-deps

    /* useEffect(() => {
        if (data) {
            props.setTagsConfiguration(data)
        }
    }, [updateCounter]) */

    function openDeleteDialog(tag) {
        setSelectedTag(tag)
        setShowConfirmDialog(true)
    }
    function openEditDialog(tag) {
        setSelectedTag(tag)
        setShowEditDialog(true)
    }

    function setDataAndPropagate(value) {
        setData(value)
        props.setTagsConfiguration(value)
    }




    return (
        <>
            <DeleteTag showConfirmDialog={showConfirmDialog} setShowConfirmDialog={setShowConfirmDialog} selectedTag={selectedTag} userId={props.userId} setSelectedTag={setSelectedTag} data={data} setData={setDataAndPropagate} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} configuration={props.configuration} />
            <EditTagDialog data={data} setData={setDataAndPropagate} showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} selectedTag={selectedTag} userId={props.userId} setSelectedTag={setSelectedTag} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />
            <CreateNewtagDialog showCreateDialog={showCreateDialog} setShowCreateDialog={setShowCreateDialog} userId={props.userId} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} data={data} setData={setDataAndPropagate} configuration={props.configuration} />
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                {data && data.length > 0 && < MaterialTable
                    style={{ width: "100%" }}
                    title="tags"
                    data={data}
                    columns={[
                        { title: 'Tag name', field: 'tagName' },
                        { title: 'Mandatory Tag', field: 'mandatory' },
                        { title: 'Tag Type', field: 'tagType' },
                        {
                            title: 'Allowed Values', field: 'allowedValues', render: (rowData) => {

                                return (
                                    <>
                                        {rowData.tagType === "list" && rowData.allowedValues && rowData.allowedValues.length > 0 && <>
                                            <Tooltip title={
                                                <React.Fragment>
                                                    {rowData.allowedValues && rowData.allowedValues.map(element => {
                                                        return (
                                                            <h3>{element}</h3>
                                                        )
                                                    })}
                                                </React.Fragment>} >
                                                <span>
                                                    {rowData.allowedValues && rowData.allowedValues.length > 0 ? <FontAwesomeIcon icon={faList} style={{ color: "#2596be", fontSize: 'calc(14px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} /> : <FontAwesomeIcon icon={faCircle} style={{ color: "red", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />}
                                                </span>
                                            </Tooltip>
                                        </>}
                                        {!rowData.allowedValues && < FontAwesomeIcon icon={faCircle} style={{ color: "red", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />}
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Actions', field: '', filtering: false, render: (rowData) => {
                                return (
                                    <>
                                        <Tooltip title={"Delete Tag"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openDeleteDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                        <Tooltip title={"Edit Tag"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openEditDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faEdit} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                    </>
                                )
                            }
                        },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: false,
                        search: false,
                        pageSize: 5,
                        maxBodyHeight: '90%',
                        minBodyHeight: 250,
                        headerStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                            backgroundColor: commonStyles.mainColor,
                            color: '#FFF'
                        },
                        cellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                        filterCellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                    }}

                    components={{
                        Toolbar: props => (
                            <div>

                            </div>
                        ),
                    }}
                />}
                <div className={classes.buttonContainer}>
                    <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "1%" }}
                        color="primary"
                        onClick={event => {
                            setShowCreateDialog(true)
                        }}>
                        <i className="icon plus"></i>
                        Add Tag
                    </Button>

                </div>
            </div>
        </>

    )
}
