
import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import MaterialTable from "@material-table/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import * as commonStyles from 'style/CommonStyles'


export default function TablePrevisualization(props) {

    const [data, setData] = useState(undefined)

    useEffect(() => {
        if (props.columnFormats && props.columnFormats.length > 0) {
            setData(props.columnFormats)
        }
    }, [props.columnFormats])


    if (props.parsingData === true) {
        return (
            <>
                <section style={{ textAlign: 'center', border: '0px solid ', borderRadius: '20px', width: '300px', paddingTop: '20px', paddingBottom: '20px', paddingRight: '10px', paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto' }}>
                    <p style={{ paddingBottom: '10px', fontWeight: 'bold', color: '#606060' }}><span style={{ display: 'block' }}>Loading your data,</span> <span>please wait...</span></p>
                    <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <FontAwesomeIcon icon={faTable} style={{ marginRight: '8', alignSelf: 'center', color: '#66B2FF' }} />
                        <CircularProgress size='1em' style={{ alignSelf: 'center' }} />
                    </section>
                </section>
            </>
        )
    }
    else {
        return (
            <div>
                {data && <MaterialTable
                    style={{ width: "100%" }}
                    title="Datasets"
                    data={data}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    setData([...data, newData]);

                                    resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    let columns = JSON.parse(JSON.stringify(dataUpdate))
                                    setData([...columns]);
                                    props.setColumnFormats([...columns])

                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    let columns = JSON.parse(JSON.stringify(dataDelete))
                                    for (let i = 0; i < columns.length; i++) {
                                        columns[i].position = i
                                    }
                                    setData([...columns]);
                                    props.setColumnFormats(columns)

                                    resolve()
                                }, 1000)
                            }),
                    }}
                    columns={
                        [
                            { title: 'Position', field: 'position', editable: 'never',width: "10%" },
                            { title: 'Name', field: 'name',width: "60%" },
                            { title: 'Format', field: 'type', lookup: { Date: 'Date', Float: 'Float', Integer: 'Integer', String: 'String' },width: "20%" },
                        ]}
                    options={{
                        actionsColumnIndex: -1,
                        tableLayout: "Auto",
                        filtering: false,
                        search: false,
                        pageSize: 5,
                        maxBodyHeight: '90%',
                        minBodyHeight: 250,
                        headerStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                            backgroundColor: commonStyles.mainColor,
                            color: '#FFF'
                        },
                        cellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                        filterCellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                    }}
                    icons={{
                        Edit: () => <EditIcon style={{ color: commonStyles.mainColor }} />,
                        Delete: () => <DeleteIcon style={{ color: commonStyles.mainColor }} />,
                        Save: () => <EditIcon style={{ color: commonStyles.mainColor }} />

                    }}


                    components={{
                        Toolbar: props => (
                            <div>

                            </div>
                        ),
                    }}
                />}
            </div>
        )
    }
}