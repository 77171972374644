import React from 'react'
import AqtivaDialog from '../../../../common/AqtivaDialog'
import {Typography} from '@material-ui/core'
import { faHubspot } from '@fortawesome/free-brands-svg-icons'

export default function DeployDialogStreamConfirmation(props) {

    function closeCallback(){
        props.setVisible(false)
    }

return (
    <AqtivaDialog visible={props.visible}
    maxWidth = {'lg'}
    title={'Deploying Lambda Function..'}
    titleIcon={faHubspot}
    confirmText={'Create'}
    cancelText={'Close'}
    showConfirmButton={false}
    cancelCallback={closeCallback}
    >
        <div>
            <Typography variant='subtitle1'>
                Creating Azure Functions for deployment...
            </Typography>
        </div>
        <div>
            <Typography variant='caption'>
                It may take some time, depending on Cloud provider response time.
            </Typography>
        </div>
        <div>
            <Typography variant='caption'>
                You can close this dialog. Once the infrastructure is deployed we will notify you.
            </Typography>
        </div>
        
        </AqtivaDialog>
)
}