import React from 'react';
//import AqtivaAppBar from './../components/template/AqtivaAppBar'
import AppBarTemplate from 'components/template/AppBarTemplate'

import * as pathHelper from './../components/template/PathHelper'
import HomePageForm from './../components/home/HomePageForm'

import ReactGA from 'react-ga';
import { LanguageContext } from 'language/LanguageContext'



ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

export default function HomePage(props) {
    const languageContext = React.useContext(LanguageContext); 

    //const [searchText, setSearchText] = React.useState(undefined)
    const searchText = undefined //TODO: change by state
    return (
        <div >
            {/*<AqtivaAppBar history={props.history} title={'Home'} 
            arrayPath={pathHelper.getPathArray('ProjectDetail')}
            homeIcon = {true} 
            setSearchText={setSearchText}
            withEnvironment={false}>

                <HomePageForm props={props}  searchText = {searchText}/>

           
    </AqtivaAppBar>*/}
    
    <AppBarTemplate
        history={props.history}
        title={languageContext.dictionary['home']}
        intervalNotifications = {false}
        arrayPath={pathHelper.getPathArray(languageContext.dictionary['home'])}
      />
      <HomePageForm props={props}  searchText = {searchText}/> 
        </div>

    );
}