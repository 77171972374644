import React from 'react';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledMenu, StyledMenuItem } from "components/common/CustomizedMenu";
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogSetUserPwd from 'components/dialogs/DialogSelectAzureSource/common/DialogSetUserPwd';
//import AES from 'crypto-js/aes';

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    root: {
        height: 240,
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        height: 380,
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialState = {
    mouseX: null,
    mouseY: null,
};



export default function TreeViewSynapse(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const user = useSelector(store => store.user)

    const [storageAccounts, setStorageAccounts] = React.useState([])


    const [selected, setSelected] = React.useState([])
    const [expanded, setExpanded] = React.useState([]);

    const [state, setState] = React.useState(initialState);

    const [selectedItemForMenu, setSelectedItemForMenu] = React.useState({})
    const [transactions, setTransactions] = React.useState([])

    const [dialogUserPwdVisible, setDialogUserPwdVisible] = React.useState(false)

    const [selectedServer, setSelectedServer] = React.useState(undefined)

    const [azureStorageDataformat, setAzureStorageDataformat] = React.useState([])

    //const [sqlError, setSqlError] = React.useState('')

    const [selectedSchema, setSelectedSchema] = React.useState({})
    const [selectedTable, setSelectedTable] = React.useState('')
    const [selectedDatabase, setSelectedDatabase] = React.useState({})

    const [userPwdSql, setUserPwdSql] = React.useState({})


    React.useEffect(() => { getResourceGroups(); getAzureStorageAccount(user) }, [user, props.selectedSubscription]);// eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => { updateStorageAccounts(props.selectedResourceGroup) }, [props.selectedResourceGroup, props.selectedSubscription])// eslint-disable-line react-hooks/exhaustive-deps

    async function getAzureStorageAccount(user) {
        const dataformats = await dispatch(actions.getAzureSqlSynapseDataformats(user.id))
        setAzureStorageDataformat(dataformats)
    }

    function alreadyAzureDataformat(server, database, schema, table) {
        var already = false

        if(azureStorageDataformat === null || azureStorageDataformat===undefined ){
            return already
        }
        azureStorageDataformat.forEach(dataformat => {

            if (dataformat.sqlDatabaseProperties !== null && dataformat.sqlDatabaseProperties !== undefined &&
                dataformat.sqlDatabaseProperties.server === server.name &&
                dataformat.sqlDatabaseProperties.database === database.name &&
                dataformat.sqlDatabaseProperties.schema === schema.name &&
                dataformat.sqlDatabaseProperties.table === table) {
                already = true
            }
        })
        return already
    }


    /** Actualiza todos los resource groups disponibles */
    async function getResourceGroups() {
        var returnValue = []
        //const result = await props.azureManager.finalizeLogin()
        //if (result.isLoggedIn) {
            if(props.selectedSubscription && props.selectedSubscription!=="")
            returnValue = await actions.onListResourceGroups(props.selectedSubscription , dispatch)

        /*}
        else {
            props.azureManager.login()
        }*/
        if (returnValue && returnValue.length > 0) {
            props.setResourceGroups(returnValue.sort((a, b) => (a > b) ? 1 : -1));
        }
        else {
            props.setResourceGroups([])
        }
    }


    React.useEffect(() => {

        if (transactions && transactions.length > 0) {
            let storageAccountItem = transactions[0]
            getDetailStorage(storageAccountItem)
            setTransactions(transactions.slice(1))
        }

    }, [transactions])// eslint-disable-line react-hooks/exhaustive-deps




    const handleClick = (event, schema, table, database, server) => {


        setSelectedSchema(schema)
        setSelectedTable(table)
        setSelectedServer(server)
        setSelectedDatabase(database)

        event.preventDefault();
        setSelectedItemForMenu(schema)
        props.setFileOutputName(server.name + "_" + database.name + "_" + schema.name + "_" + table)
        setSelectedItemForMenu(schema)
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };



    function getDetailStorageTransaction(storageAccount) {
        setTransactions([...transactions, storageAccount])
    }

    function getDetailStorage(storageAccount) {
        getDetail(user, props.selectedEnvironment, storageAccount).then(object => {
            var tempResult = JSON.parse(JSON.stringify(storageAccounts))
            let newList = [];
            tempResult.forEach(function (item) {
                if (item.resourceGroupName === object.resourceGroupName && item.name === object.name) {
                    newList.push(object);
                } else {
                    newList.push(item);
                }
            });
            tempResult = newList
            setStorageAccounts(newList);
        })
    }





    async function updateStorageAccounts(resourceGroup) {
        if (resourceGroup && resourceGroup !== "" && resourceGroup !== "none" && resourceGroup !== "None") {
            setStorageAccounts(await listStorageAccountForRG(resourceGroup))
        }
        else {
            setStorageAccounts(await listStorageAccountAll())
        }
    }

    /** obtiene un listado de los storage accounts para un resourcegroup */
    async function listStorageAccountForRG(resourceGroup) {
        //const result = await props.azureManager.finalizeLogin()
       // if (result.isLoggedIn) {
           if(props.selectedSubscription && props.selectedSubscription!=="")
            return await actions.onListSynapseDatabaseForRG(props.selectedSubscription  , resourceGroup, dispatch)
       /* }
        else {
            props.azureManager.login()
        }*/
        return []
    }

    /** obtiene un listado de los storage accounts para un resourcegroup */
    async function listStorageAccountAll() {
       // const result = await props.azureManager.finalizeLogin()
      //  if (result.isLoggedIn) {
        if(props.selectedSubscription && props.selectedSubscription!=="")
            return await actions.onListSynapseDatabaseAll( props.selectedSubscription  , dispatch)
       /* }
        else {
            props.azureManager.login()
        }*/
        return []
    }

    async function getDetail(user, environment, dto) {
        var returnValue = []
      //  var result = await props.azureManager.finalizeLogin()

       // if (result.isLoggedIn) {
            returnValue = actions.onListDatabasesFromWorkspace( props.selectedSubscription , dto.resourceGroupName, dto.name, dto, dispatch)


      /*  }
        else {
            props.azureManager.login()
        }*/

        return returnValue
    }

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };



    async function handlePreview(event) {

        props.setSelectedItem(selectedItemForMenu)

        const dto = {
            server: selectedServer.name,
            database: selectedDatabase.name,
            schema: selectedSchema.name,
            table: selectedTable,
            resourceGroup: selectedDatabase.resourceGroupName,
            user: userPwdSql.user,
            pwd: userPwdSql.pwd
        }
        props.setSelectedSQLDTO(dto) //para cuando se cree el datasource, tenga toda la información necesaria
        /*
        AES.encrypt(userPwdSql.pwd, "Secret Passphrase").ciphertext.toString()
        */


        handleClose(event)
        props.setVisiblePreview(true)
        dispatch(actions.createPreviewDataJobSQLDatabase(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
            if (result && result.run_id) {

                props.setRunId(result.run_id)
            }
           
        })

    }

    function resetDbCredencials(storageAcountName, selectedDTO) {
        visualizeUserPwdDialog(storageAcountName, selectedDTO)
        setDialogUserPwdVisible(true)
    }

    function createMenuItemCosmoContainer(storageAccount, container, rootItem) {
        const finalName = rootItem.name
        return (
            <div>

                <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_" + finalName}

                    label={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>
                            <img alt="" style={{ height: '12px', width: '12px' }}
                                src={require('resources/icons/azure/Folder.png')} />
                            <Typography variant='caption'>{finalName}</Typography>
                        </div>
                    } >
                    {rootItem.tables !== undefined && rootItem.tables.length > 0
                        &&
                        rootItem.tables.sort((a, b) => (a > b) ? 1 : -1).map((table, containerIndex) => {


                            return <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + table}
                                onContextMenu={event => handleClick(event, rootItem, table, container, storageAccount)}
                                label={
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>

                                        {alreadyAzureDataformat(storageAccount, container, rootItem, table) === false &&
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                            }}>

                                                <img alt="" style={{ height: '12px', width: '12px' }}
                                                    src={require('resources/icons/azure/Table.png')} />
                                                <Typography variant='caption' >{table}</Typography>

                                            </div>}
                                        {alreadyAzureDataformat(storageAccount, container, rootItem, table) === true &&
                                            <Tooltip title={`Source already registered in Aqtiva`}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    <img alt="" style={{ height: '15px', width: '15px' }}
                                                        src={require('resources/icons/favicon-32x32.jpg')} />
                                                    <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{table}</Typography>

                                                </div>
                                            </Tooltip>
                                        }


                                    </div>
                                } >




                            </TreeItem>
                        })
                    }
                </TreeItem>
                <StyledMenu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    <StyledMenuItem onClick={() => handlePreview(rootItem)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Preview" />
                    </StyledMenuItem>

                </StyledMenu>
            </div>
        )

    }

    async function storeUserPwd(userSql, pwdSql) {
        //se almacena para el futuro
        const keyUser = user.id + selectedServer + "u"
        const keyPd = user.id + selectedServer + "p"
        dispatch(actions.setSecret(keyUser, userSql))
        dispatch(actions.setSecret(keyPd, pwdSql))
        //se ejecuta la logica normal
        setUserPwd(userSql, pwdSql, selectedServer)
    }
    async function visualizeUserPwdDialog(server, selectedItem) {

        //primero comprobamos si existe ya un user y pwd almacenado
        const hasKeyUser = await dispatch(actions.getSecret(user.id + server + "u"))
        const hasKeyPwd = await dispatch(actions.getSecret(user.id + server + "p"))
        if ((hasKeyUser.error !== undefined && hasKeyUser.error !== null) || (hasKeyPwd.error !== undefined && hasKeyPwd.error !== null)) {
            //no hemos encontrado el valor
            setDialogUserPwdVisible(true)
            setSelectedServer(server)
        }
        else {
            setSelectedServer(server)
            setUserPwd(hasKeyUser, hasKeyPwd, server, selectedItem)
        }

    }

    async function setUserPwd(user, pwd, server, selectedItem) {
        if (selectedItem === undefined) {
            selectedItem = props.selectedItem
        }
        if (server === undefined) {
            server = selectedServer
        }
        setUserPwdSql({ user: user, pwd: pwd })
        props.setSqlError('')
        if (selectedItem) {
            var object = await dispatch(actions.getSQLSynapseDetail(user, pwd, server, selectedItem))
            if (object.error !== undefined && object.error !== '' && object.error !== 'null' && object.error !== null) {
                props.setSqlError(JSON.stringify(object.error))
            }
        }


        let newList = [];
        storageAccounts.forEach(function (item) {
            if (item.resourceGroupName === object.resourceGroupName && item.name === server) {
                // buscamos ahora el sqlDatabaseDTOs
                var databases = item.sqlDatabaseDTOS
                let newDatabases = []
                databases.forEach(function (database) {
                    if (database.name === object.name) {

                        newDatabases.push(object)
                    }
                    else {
                        newDatabases.push(database)
                    }
                })

                var newItem = item
                newItem.sqlDatabaseDTOS = newDatabases

                newList.push(newItem);
            } else {
                newList.push(item);
            }
        });
        setStorageAccounts(newList);





    }

    return (

        <div className={classes.root}>
            <DialogSetUserPwd visible={dialogUserPwdVisible} setVisible={setDialogUserPwdVisible}
                setUserPwd={setUserPwd} 
                storeUserPwd={storeUserPwd} />

            {(storageAccounts === undefined || storageAccounts === null || storageAccounts.length === 0) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <CircularProgress style={{ width: '25px', height: '25px' }} />
                    <Typography variant='subtitle1'>Loading ...</Typography>
                </div>
            }

            {storageAccounts !== undefined && storageAccounts !== null && storageAccounts.length > 0 &&
                <TreeView
                    className={classes.rootView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {(props.selectedResourceGroup === '' ? storageAccounts : storageAccounts.filter(x => x.resourceGroupName === props.selectedResourceGroup))
                        .sort((a, b) => (a.name > b.name) ? 1 : -1).map(storageAccount => {
                            return <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name} label={
                                <div style={{
                                    marginRight: '5px', display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    <img alt="" style={{ height: '20px', width: '20px' }}
                                        src={require('resources/icons/azure/SQL-Server.png')} />
                                    <Typography variant='subtitle2'>{storageAccount.name}</Typography>
                                </div>}
                                onClick={event => getDetailStorageTransaction(storageAccount)}>
                                {storageAccount.sqlDatabaseDTOS === undefined || storageAccount.sqlDatabaseDTOS === null || storageAccount.sqlDatabaseDTOS.length === 0 ?
                                    <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_empty"} label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                                            <Typography variant='subtitle2'>Loading storage data, it may take some seconds...</Typography>
                                        </div>
                                    } /> :
                                    storageAccount.sqlDatabaseDTOS.sort((a, b) => (a.name > b.name) ? 1 : -1).map(container => {
                                        return <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name}
                                            onClick={event => {
                                                props.setSelectedItem(container);
                                                if (container.schemas === undefined || container.schemas.length === 0) visualizeUserPwdDialog(storageAccount.name, container)
                                            }}
                                            label={
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    {storageAccount && container && <Tooltip title="Refresh Credencials">
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={faSyncAlt}
                                                                style={{ margin: "5px", color: commonStyles.mainColor }}
                                                                onClick={event => {
                                                                    event.stopPropagation();
                                                                    resetDbCredencials(storageAccount.name, container)
                                                                }} />
                                                        </span>
                                                    </Tooltip>}
                                                    <img alt="" style={{ height: '15px', width: '15px' }}
                                                        src={require('resources/icons/azure/sql-database-generic.png')} />
                                                    <Typography variant='subtitle2'>{container.name}</Typography>
                                                </div>
                                            } >
                                            {
                                                container.schemas === undefined || container.schemas === null || container.schemas.length === 0 ?
                                                    <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_empty"} label={
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'left',
                                                        }}>
                                                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                            <Typography variant='subtitle2'>Loading storage data, it may take some seconds...</Typography>
                                                        </div>
                                                    } />
                                                    :
                                                    container.schemas.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {

                                                        return createMenuItemCosmoContainer(storageAccount, container, item)
                                                    })
                                            }

                                        </TreeItem>
                                    })
                                }
                            </TreeItem>
                        })}

                </TreeView>
            }
        </div>

    )
}