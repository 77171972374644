import React from 'react';
import QualityDetailProjectTable from './QualityDetailProjectTable';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function QualityDetailProject(props) {


   

    return (
        <div >

            <DndProvider backend={HTML5Backend}>
                <QualityDetailProjectTable project={props.project}
                    userId={props.userId}
                    editQualityPipe={props.editQualityPipe}
                    editDatasource={props.editDatasource}
                    editRule={props.editRule}
                    setSelectedItem={props.setSelectedItem}
                    setSelectedItemNewDialog={props.setSelectedItemNewDialog}
                    deleteItem={props.deleteItem}
                    addDatasource={props.addDatasource}
                    addFolder={props.addFolder}
                    addRule={props.addRule}
                    addRuleNewDialog={props.addRuleNewDialog}
                    addCompoundRule={props.addCompoundRule}
                    addCompoundRuleNewDialog={props.addCompoundRuleNewDialog}
                    onClickOpenDialogSimulation={props.onClickOpenDialogSimulation}
                    qualityConfiguration={props.qualityConfiguration}
                    searchText={props.searchText}
                    handleRuleRecommender={props.handleRuleRecommender}
                    selectedEnvironment={props.selectedEnvironment}
                    tableByRulesOrColumns={props.tableByRulesOrColumns}
                    scheduleHasBeenModified={props.scheduleHasBeenModified}
                    newRuleFormFlag = {props.newRuleFormFlag}
                    
                />
            </DndProvider>
        </div>

    );
}