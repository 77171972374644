import React from 'react';

import AqtivaDialogParseAndEncryptString from 'components/common/AqtivaDialogParseAndEncryptString'

import { Grid, FormControlLabel, TextField,  Typography,  } from '@material-ui/core';





export default function ConnectionStringConnectionInfo(props) {

  

    const [visibleConnectionString, setVisibleConnectionString] = React.useState('')


    return (
        <div style={{ width: '100%' }}>
            <AqtivaDialogParseAndEncryptString visible={visibleConnectionString}
                connectionMethod={props.connectionMethod}
                setVisible={setVisibleConnectionString}
                confirm={props.setConnectionObjectSas}
                sName={props.id && props.id + "localsttkk"}
                resourceType={props.resourceType}
            />

            <Grid item xs={12}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display name:</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                    control={
                        <TextField style={{ width: '100%' }}
                            readonly
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            autoComplete="new-password"
                            id="DisplayName"
                            label="Display name"
                            margin="dense"
                            value={props.displayName}
                            onChange={(event) => { props.setDisplayName(event.target.value) }}
                        />} autoComplete="new-password" />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Connection string:</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                    control={
                        <TextField style={{ width: '100%' }}
                            autoComplete="new-password"
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            id="storageKey"
                            label="Connection string"
                            margin="dense"
                            type="password"
                            value={props.connectionObjectSas && props.connectionObjectSas.sharedAccessSignature ? props.connectionObjectSas.sharedAccessSignature : ""}
                            onClick={(event) => { setVisibleConnectionString(true) }}
                        />} autoComplete="new-password" />
            </Grid>
        </div>

    )
}