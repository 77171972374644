import React, { useState, useEffect } from 'react';
import DatasourceGrid from './GridAgregations/DatasourceGrid';
import ProjectGrid from './GridAgregations/ProjectGrid';
import ProjectsGrid from './GridAgregations/ProjectsGrid';
import QualityPointGrid from './GridAgregations/QualityPointGrid';
import ReturnPreviousAgregationButton from './ReturnPreviousAgregationButton';
import { useDispatch } from 'react-redux'
import { Grid, Typography } from '@material-ui/core';

import * as commonStyles from 'style/CommonStyles'
import * as actions from 'actions'


export default function ProjectDrillDownComponent(props) {

    const dispatch = useDispatch()

    const [agregationLevel, setAgregationLevel] = useState("projects")
    const [qpExecutions, setQpExecutions] = useState([])
    const [detailQPExecutions, setDetailQPExecution] = useState([])
    const [datasourcesExecution, setDatasourcesExecution] = useState([])
    const [project, setProject] = useState();
    const [qualityPoint, setQualityPoint] = useState();
    const [dataSource, setDataSource] = useState();
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        detectAgregationByUrlParameters()
    }, [props.filterObj])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
       if(!loading) props.loading(loading)
    }, [loading])// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Main function where we handle drill down throught quality elements
     * @param  {String} agregationLevel Indicates the level of agregation we're currently in the different values are "projects", "project", "qualityPoint", "datasource" 
     * when drilling down,3 main actions are executed:
     *      -depending on the element we execute queries to obtain more detailed information regarding executions
     *      -we set the state with information about the selected element to browse in detail
     *      -we update the url path
     */
    async function handleDrillDownElement(e) {
        if (agregationLevel && agregationLevel === "projects") {

            let projectSelected = props.DQI4Project && props.DQI4Project.filter(p => p.projectId === e.projectId)

            if (projectSelected && projectSelected.length > 0) {

                //Call to obtain details of project, params filterObject with all the parameters for the filter and the project ID
                let projectDetail = await dispatch(actions.getDQIProjectDetail(props.filterObj, e.projectId))

                //Call to obtain all executions from each qualityPoint individually, params filterObject with all the parameters for the filter and the project ID
                let executionsQP = await dispatch(actions.getQpDetailExecutions(props.filterObj, e.projectId))

                //Call to obtain all executions from all qualityPoints in the same list, params filterObject with all the parameters for the filter and the project ID
                let executionsQPDetail = await dispatch(actions.getDetailQIExecution(props.filterObj, e.projectId))

                if (projectDetail && projectDetail.data && executionsQP && executionsQP.data && executionsQP.data.qualityPointExecutionHistoryList && executionsQPDetail) {
                    setDetailQPExecution(executionsQPDetail.data)
                    setProject(projectDetail.data)
                    setQpExecutions(executionsQP.data.qualityPointExecutionHistoryList)
                    setAgregationLevel("project")
                    setLoading(false)
                    props.history.push(`/${process.env.REACT_APP_PREFIX}/drilldown/dashboard/project/${e.projectId}`)
                }
            }
        }
        if (agregationLevel && agregationLevel === "project") {
            

            
            let filter = JSON.parse(JSON.stringify(props.filterObj))
            filter.selectedProjectQPIds.push(e.qualityPointId)

           
            dispatch(actions.getDatasourceDetailExecutions(filter, project.projectId)).then(executionsDatasources=>{
                let qpSelected = project.qualityPointDQI.filter(qp => qp.qualityPointId === e.qualityPointId)
                if (qpSelected && qpSelected.length > 0 && executionsDatasources && executionsDatasources.data && executionsDatasources.data.datasourceExecutionHistoryList) {
                    setQualityPoint(qpSelected[0])
                    setDatasourcesExecution(executionsDatasources.data.datasourceExecutionHistoryList)
                    setAgregationLevel("qualityPoint")
                    setLoading(false)
                    
                }
            })

            props.history.push(`/${process.env.REACT_APP_PREFIX}/drilldown/dashboard/project/${project.projectId}/qp/${e.qualityPointId}`)
        }
        if (agregationLevel && agregationLevel === "qualityPoint") {
            setDataSource(e)
            setAgregationLevel("datasource")
            setLoading(false)
            props.history.push(`/${process.env.REACT_APP_PREFIX}/drilldown/dashboard/project/${project.projectId}/qp/${e.parentId}/datasource/${e.datasourceId}`)
        }

    }

    /**
     * This function is used to execute the apropiate queries and state settings depending on the agregation level read from the url
     * depending on the parameters found the agregation level will be set acordingly and the apropiate queries executed
     * @param  {String} agregationLevel Indicates the level of agregation we're currently in the different values are "projects", "project", "qualityPoint", "datasource" 
     * @param  {String} props.initialProjectId Indicates the project Id received from the url
     * @param  {String} rops.initialQpId Indicates the qualityPoint Id received from the url
     * @param  {String} props.initialDatasourceId Indicates the datasource Id received from the url 
     * 
     */
    async function detectAgregationByUrlParameters() {
        setLoading(true)

        //we redirect to datasource agregation
        if (props.initialDatasourceId && props.initialQpId && props.initialProjectId) {
            setAgregationLevel("datasource")

            //Call to obtain details of project, params filterObject with all the parameters for the filter and the project ID
            let projectDetail = await dispatch(actions.getDQIProjectDetail(props.filterObj, props.initialProjectId))

            //Call to obtain all executions of qualityPoint, params filterObject with all the parameters for the filter and the project ID
            let executionsQP = await dispatch(actions.getQpDetailExecutions(props.filterObj, props.initialProjectId))

            //Call to obtain details of qualityPoints executions params filterObject with all the parameters for the filter and the project ID
            let executionsQPDetail = await dispatch(actions.getDetailQIExecution(props.filterObj, props.initialProjectId))

            if (projectDetail && projectDetail.data && executionsQP && executionsQP.data && executionsQP.data.qualityPointExecutionHistoryList && executionsQPDetail) {
                setDetailQPExecution(executionsQPDetail.data)
                setProject(projectDetail.data)
                setQpExecutions(executionsQP.data.qualityPointExecutionHistoryList)
            }
            let qpSelected = projectDetail.data.qualityPointDQI.filter(qp => qp.qualityPointId === props.initialQpId)
            let filter = JSON.parse(JSON.stringify(props.filterObj))
            filter.selectedProjectQPIds.push(props.initialQpId)

            let executionsDatasources = await dispatch(actions.getDatasourceDetailExecutions(filter, props.initialProjectId))
            let execs = []


            if (qpSelected && qpSelected.length > 0 && executionsDatasources && executionsDatasources.data && executionsDatasources.data.datasourceExecutionHistoryList) {
                setQualityPoint(qpSelected[0])
                setDatasourcesExecution(executionsDatasources.data.datasourceExecutionHistoryList)
                execs = executionsDatasources.data.datasourceExecutionHistoryList.filter(dt => dt.datasourceId === props.initialDatasourceId)[0].executionHistory

            }

            let datasources = qpSelected[0].datasourceDQI.filter(dt => dt.datasourceId === props.initialDatasourceId)
            let historyList = execs

            let dataSourceInfo = {
                ...createDatasourceList(datasources, historyList, props.initialDatasourceId),
                'execution': execs.filter(dt => dt.datasourceId === props.initialDatasourceId)[0]
            }
            setDataSource(dataSourceInfo)
            setLoading(false)


            return
        }
        //we redirect to qualityPoint agregation
        if (props.initialQpId && props.initialProjectId) {
            setAgregationLevel("qualityPoint")
            let projectDetail = await dispatch(actions.getDQIProjectDetail(props.filterObj, props.initialProjectId))
            let executionsQP = await dispatch(actions.getQpDetailExecutions(props.filterObj, props.initialProjectId))
            let executionsQPDetail = await dispatch(actions.getDetailQIExecution(props.filterObj, props.initialProjectId))


            if (projectDetail && projectDetail.data && executionsQP && executionsQP.data && executionsQP.data.qualityPointExecutionHistoryList && executionsQPDetail) {
                setDetailQPExecution(executionsQPDetail.data)
                setProject(projectDetail.data)
                setQpExecutions(executionsQP.data.qualityPointExecutionHistoryList)
            }

            let qpSelected = projectDetail.data.qualityPointDQI.filter(qp => qp.qualityPointId === props.initialQpId)
            let filter = JSON.parse(JSON.stringify(props.filterObj))
            filter.selectedProjectQPIds.push(props.initialQpId)

            let executionsDatasources = await dispatch(actions.getDatasourceDetailExecutions(filter, props.initialProjectId))

            if (qpSelected && qpSelected.length > 0 && executionsDatasources && executionsDatasources.data && executionsDatasources.data.datasourceExecutionHistoryList) {
                setQualityPoint(qpSelected[0])
                setDatasourcesExecution(executionsDatasources.data.datasourceExecutionHistoryList)

                setLoading(false)

            }
            return
        }
        //we redirect to project agregation
        if (props.initialProjectId) {

            setAgregationLevel("project")

            let projectDetail = await dispatch(actions.getDQIProjectDetail(props.filterObj, props.initialProjectId))
            let executionsQP = await dispatch(actions.getQpDetailExecutions(props.filterObj, props.initialProjectId))
            let executionsQPDetail = await dispatch(actions.getDetailQIExecution(props.filterObj, props.initialProjectId))
            if (projectDetail && projectDetail.data && executionsQP && executionsQP.data && executionsQP.data.qualityPointExecutionHistoryList && executionsQPDetail) {
                setDetailQPExecution(executionsQPDetail.data)
                setProject(projectDetail.data)
                setQpExecutions(executionsQP.data.qualityPointExecutionHistoryList)

                setLoading(false)
            }
            return
        }
    }


    function renderTextTitle() {
        if (agregationLevel === "project") {
            return `Project - ${project && project.name}`
        }
        if (agregationLevel === "qualityPoint") {
            return `QualityPoint - ${qualityPoint && qualityPoint.name} - Project - ${project && project.name}`
        }
        if (agregationLevel === "datasource") {
            return `Datasource - ${dataSource && dataSource.name} - QualityPoint - ${qualityPoint && qualityPoint.name} - Project - ${project && project.name}`
        }
    }

    /**
    * This function is used to return to return to the previous level of agregation
    *
    * @param  {String} agregationLevel Indicates the level of agregation we're currently in the different values are "projects", "project", "qualityPoint", "datasource"
    * * when "drilling up",3 main actions are executed:
    *      -we change the agregationLevel
    *      -we update the url path
    * 
    */
    function returnToParent() {
        if (agregationLevel && agregationLevel === "project") {
            props.history.push(`/${process.env.REACT_APP_PREFIX}/drilldown/dashboard`)
            setAgregationLevel("projects")
            props.setInitialProjectId(undefined)
            props.setInitialQpId(undefined)
            props.setInitialDatasourceId(undefined)
        }
        if (agregationLevel && agregationLevel === "qualityPoint") {
            props.history.push(`/${process.env.REACT_APP_PREFIX}/drilldown/dashboard/project/${project.projectId}`)
            setAgregationLevel("project")
            props.setInitialProjectId(project.projectId)
            props.setInitialQpId(undefined)
            props.setInitialDatasourceId(undefined)
        }
        if (agregationLevel && agregationLevel === "datasource") {
            props.history.push(`/${process.env.REACT_APP_PREFIX}/drilldown/dashboard/project/${project.projectId}/qp/${qualityPoint.qualityPointId}`)
            setAgregationLevel("qualityPoint")
            props.setInitialProjectId(project.projectId)
            props.setInitialQpId(undefined)
            props.setInitialDatasourceId(undefined)
        }
    }


    /**
   * This function is used to map the previous datasources
   * 
   * @param  {List} dtList List of all datasource in the qualityPoint
   * @param  {List} historyList List of all executions of the datasource
   * @param  {List} datasourceId Datasource id
   */
    function createDatasourceList(dtList, historyList, datasourceId) {
        let dataSources = dtList.map(dt => {
            if (dt.dayDsDQIList && dt.dayDsDQIList.length > 0) {
                return {
                    ...dt,
                    'id': dt.datasourceId,
                    'historyList': historyList,
                    'score': Number(((dt.sumNumOk / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                    'scoreKo': -Number(((dt.sumNumKo / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                    "fecha": new Date(dt.dayDsDQIList[0].year, dt.dayDsDQIList[0].month, dt.dayDsDQIList[0].day).getTime(),
                    "timestamp": dt.dayDsDQIList[0].year + "/" + dt.dayDsDQIList[0].month + "/" + dt.dayDsDQIList[0].day

                }
            } else {
                return {
                    ...dt,
                    'score': Number(((dt.sumNumOk / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                    'scoreKo': -Number(((dt.sumNumKo / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                    "fecha": new Date().getTime(),
                    "timestamp": 2022 + "/" + 10 + "/" + 15

                }
            }
        })

        return dataSources.filter(dt => dt.id === datasourceId)[0]
    }

    return (
        <>
            <Grid container spacing={3} style={commonStyles.titleBox}>
                {agregationLevel && agregationLevel !== "projects" && <Grid item xs={12} style={commonStyles.DashboardChartComponentTitleDark}>
                    <Typography variant='h6' style={commonStyles.titleText}>{renderTextTitle()}</Typography>
                    <ReturnPreviousAgregationButton returnToParent={returnToParent} />
                </Grid >}

                {agregationLevel && agregationLevel === "projects" && <ProjectsGrid
                    handleDrillDownElement={handleDrillDownElement}
                    filterObj={props.filterObj}
                    DQI4Project={props.DQI4Project}
                    DQI4ProjectMostRecent={props.DQI4ProjectMostRecent}
                    globalDQI={props.globalDQI}
                    DQIByDimension={props.DQIByDimension}
                    agregationLevel={agregationLevel}
                    setAgregationLevel={setAgregationLevel}
                    DQI4ProjectFlag={props.DQI4ProjectFlag}
                    DQI4ProjectMostRecentFlag={props.DQI4ProjectMostRecentFlag}
                    DQIByDimensionFlag={props.DQIByDimensionFlag}

                />}
                {agregationLevel && agregationLevel === "project" && <ProjectGrid
                    handleDrillDownElement={handleDrillDownElement}
                    project={project}
                    filterObj={props.filterObj}
                    qpExecutions={qpExecutions}
                    detailQPExecutions={detailQPExecutions}
                    agregationLevel={agregationLevel}
                    setAgregationLevel={setAgregationLevel}
                    loading={loading}

                />}
                {agregationLevel && agregationLevel === "qualityPoint" && <QualityPointGrid
                    qualityPoint={qualityPoint}
                    project={project}
                    handleDrillDownElement={handleDrillDownElement}
                    qpExecutions={qpExecutions}
                    datasourcesExecution={datasourcesExecution}
                    filterObj={props.filterObj}
                    agregationLevel={agregationLevel}
                    setAgregationLevel={setAgregationLevel}
                    loading={loading}
                />}
                {agregationLevel && agregationLevel === "datasource" && <DatasourceGrid
                    dataSource={dataSource}
                    project={project}
                    qualityPoint={qualityPoint}
                    filterObj={props.filterObj}
                    agregationLevel={agregationLevel}
                    setAgregationLevel={setAgregationLevel}
                    loading={loading}
                />}
            </Grid>
        </>

    );
}