
import React, { useEffect, useState } from "react";

import { Grid,AppBar ,Toolbar, Typography} from '@material-ui/core';

import DashboardChartComponent from 'components/AqtivaDashboardDrillDownPage/DashboardTabComponent/Common/Components/DashboardChartComponent';
import DonutChart from 'components/AqtivaDashboardDrillDownPage/DashboardTabComponent/Common/Charts/DonutChart'
import  * as helper from 'components/AqtivaDashboardDrillDownPage/helper'
import DQIDimensionsChartDetail from 'components/AqtivaDashboard/components/DQIDimensionsChartDetail'
import ExecutionDetail from 'components/AqtivaDashboardNewPage/DashboardTabComponent/Common/Components/ExecutionDetail';

import * as actions from 'actions'
import {  useDispatch } from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
    appbar: {
      backgroundColor: "#212121 !important",
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));


export default function AqtivaQualityIntegration(props) {

    const classes = useStyles();
    const dispatch = useDispatch();


    const [donutData, setDonutData] = useState([])

    const [globalDQI, setGlobalDQI] = React.useState(undefined)
    const [DQIByDimension, setDQIByDimension] = React.useState([])
    const [dQI4DimensionsFlag, setDQI4DimensionsFlag] = React.useState(true)
    const [executionDetail, setExecutionDetail] = React.useState([])

    const [loadingAgg, setLoadingAgg] = React.useState(true)
    const [loadingDetail, setLoadingDetail] = React.useState(true)

    const donutChart = <DonutChart data={donutData.DQIok} showCenterLabel={true} chart={'chartDonutGlobalDQI'} click={false} />
    const dimensionChart = <DQIDimensionsChartDetail data={DQIByDimension}  />
    const executionDetailChart = <ExecutionDetail  execution={executionDetail}  projectTreeViewFlag={false}/>
    //get data
    useEffect(() => {
        //if we have idDataformat
        if(props.match && props.match.params && props.match.params.idDataformat){
            const idDf = props.match.params.idDataformat
            dispatch(actions.getPublicDataformatLastExecution(idDf)).then(response => {
                //get global DQI and 4 dimensions
                const value = helper.getGlobalDQI(response.data)
                
                setGlobalDQI(value)
                setDQIByDimension(response.data)
                setDQI4DimensionsFlag(false)
                setLoadingAgg(false)

            })

            dispatch(actions.getPublicLastExecutionDetailForDF(idDf)).then(response=>{
                setExecutionDetail(response.data)
                setLoadingDetail(false)
            })
           
        }
        else if(props.match && props.match.params && props.match.params.idDatasource){
          const idDS = props.match.params.idDatasource
          dispatch(actions.getPublicDatasourceLastExecution(idDS)).then(response => {
              //get global DQI and 4 dimensions
              const value = helper.getGlobalDQI(response.data)
              
              setGlobalDQI(value)
              setDQIByDimension(response.data)
              setDQI4DimensionsFlag(false)
              setLoadingAgg(false)

          })

          dispatch(actions.getPublicLastExecutionDetailForDS(idDS)).then(response=>{
              setExecutionDetail(response.data)
              setLoadingDetail(false)
          })
        }
        else if(props.match && props.match.params && props.match.params.idQualityPoint){
          const idQP = props.match.params.idQualityPoint
          dispatch(actions.getPublicQualityPointLastExecution(idQP)).then(response => {
              //get global DQI and 4 dimensions
              const value = helper.getGlobalDQI(response.data)
              
              setGlobalDQI(value)
              setDQIByDimension(response.data)
              setDQI4DimensionsFlag(false)
              setLoadingAgg(false)

          })

          dispatch(actions.getPublicLastExecutionDetailForQP(idQP)).then(response=>{
              setExecutionDetail(response.data)
              setLoadingDetail(false)
          })
        }
    }, [])


    //donut data dqi
    useEffect(() => {
            if(globalDQI){
                setDonutData(globalDQI)
            }
        
    },[globalDQI])


    return(
        <div>
         <AppBar position="static" className={classes.appbar}>
         <Toolbar style={{ marginBottom: "-5px", marginTop: "-5px" }}>
         <img
              src="/omma_logo_white_h.png"
              alt="logo"
              style={{ width: "140px", marginLeft: "10px", marginRight: "10px" }}
            />
            <Typography variant='h6' style={{color:'#FFFFFF', marginLeft:'50px'}}>Data Quality Results Integration</Typography>
            </Toolbar>
         </AppBar>

            <Grid container spacing={1} style={{ width: '100%', marginTop:'10px' }}>
                <Grid item xs={4} >
                <DashboardChartComponent loading = {loadingAgg} content={donutChart} title={'Global DQI'} description={'Global DQI'} />
                </Grid>

                <Grid item xs={8} >
                <DashboardChartComponent loading = {loadingAgg} content={dimensionChart} title={' DQI per dimension'} description={' DQI per dimension'} />
                </Grid>
                  
                <Grid item xs={12}>
                    <DashboardChartComponent loading={loadingDetail} content={executionDetailChart} title={'Details'} description={'Detail'}  />}

                </Grid >
                    
            </Grid>
           

        </div>
    )
}