import React, { useState, useEffect } from 'react'

import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from "@material-table/core";
import { Tooltip } from '@material-ui/core'



import * as renderHelper from './utils'

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'


export default function DataformatVersionsTable(props) {
    const languageContext = React.useContext(LanguageContext); 

    const [data, setData] = useState([])
    const [selected, setSelected] = useState(false)
    


    useEffect(() => {
        if(selected){
            //TODO: do nothing, avoid warning
        }
        if (props.dataformats && props.dataformats.length > 0) {
            const filteredData = renderHelper.applyFilters(props.dataformats, props.selectedDataformatSource, props.selectedDataformatUser)
            setData(filteredData)
        }
    }, [props.dataformats && props.dataformats.length])// eslint-disable-line react-hooks/exhaustive-deps








    return (

        <MaterialTable
            style={{ width: "100%" }}
            title="Datasets"
            data={data}
            onRowClick={(event, rowData) => {
                if (props.selectDatasetDialog) {
                    props.handleSelectDatasetForExportation(rowData._id)
                    if (rowData.tableData.id === props.selectedDatasetForExportationId) {
                        setSelected(false)
                        props.handleSelectDatasetForExportationId(rowData._id)
                    } else {
                        setSelected(true)
                        props.handleSelectDatasetForExportationId(rowData._id)
                    }
                }

            }}
            columns={
                [
                    { title: languageContext.dictionary['name'], field: 'name' },
                    { title: languageContext.dictionary['creationDate'], field: 'creationDate', filtering: false, render: rowData => { return rowData.creationDate && renderHelper.parseDateToCorrectFormat(rowData.creationDate) } },
                    { title: languageContext.dictionary['updateDate'], field: 'updateDate', filtering: false, render: rowData => { return rowData.updateDate && renderHelper.parseDateToCorrectFormat(rowData.updateDate) } },
                    { title: languageContext.dictionary['numberOfColumns'], field: 'columns', filtering: false, render: rowData => { return rowData.columns ? rowData.columns.length : 0 } },
                    { title: languageContext.dictionary['sourceType'], field: 'source', filtering: false, render: rowData => { return renderHelper.renderAzureLogo(rowData.source) } },

                    { title: languageContext.dictionary['owner'], field: 'userId', filtering: false, render: (rowData) => { return rowData.ownerUserId ? props.translateUserIdToName(rowData.ownerUserId, props.licenseUsers) : rowData.userId && props.translateUserIdToName(rowData.userId, props.licenseUsers) } },
                    { title: languageContext.dictionary['allowedUsers'], field: 'allowedUsers', render: (rowData) => { return renderHelper.renderAllowedUsers(rowData, props.licenseUsers) } },
                    {
                        title: languageContext.dictionary['usedByProjects'], field: 'createTime', filtering: false, render: (rowData) => {
                            return props.usedDatasourcesByProjects ? renderHelper.countProjectsUsingDataformat(props.usedDatasourcesByProjects, rowData) : '0'
                        }
                    },
                    {
                        title: languageContext.dictionary['version'], field: 'version', filtering: false, render: rowData => { return rowData.version }
                    },
                    {
                        title: languageContext.dictionary['detail'], field: '', filtering: false, render: (rowData) => {
                            return <Tooltip title={languageContext.dictionary['dataformatDetail']}>
                                <Fab size="small" color="primary" aria-label="Detail" 
                                    onClick={() => { props.viewDatasetDetails(rowData) }}
                                >
                                    <SearchIcon />
                                </Fab >
                            </Tooltip>
                        }
                    },
                ]}
            options={{
                rowStyle: rowData => ({
                    backgroundColor:
                        props.selectedDatasetForExportationId &&
                            rowData._id === props.selectedDatasetForExportationId
                            ? commonStyles.mainColor
                            : "#fff"
                }),
                actionsColumnIndex: -1,
                filtering: false,
                search: false,
                pageSize: 5,
                maxBodyHeight: '90%',
                minBodyHeight: 250,
                headerStyle: {
                    fontSize: 'calc(8px + 0.2vw)',
                    backgroundColor: commonStyles.mainColor,
                    color: '#FFF'
                },
                cellStyle: {
                    fontSize: 'calc(8px + 0.2vw)',
                },
                filterCellStyle: {
                    fontSize: 'calc(8px + 0.2vw)',
                },
            }}

            components={{
                Toolbar: props => (
                    <div>

                    </div>
                ),
            }}
        />

    )
}
