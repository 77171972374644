import React from 'react';


import {  useDispatch } from 'react-redux'
import AqtivaDialog from 'components/common/AqtivaDialog';
import { Grid,   TextField, FormControlLabel } from '@material-ui/core'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'






export default function InsertAnjanaIdDialog(props) {
    const dispatch = useDispatch();

    // here we pass a parameter element to indicate if the element to update is a project, in case it is, we update the project directly otherwise we modify 
    //the qp or the datasource when we click on edit.
    function editAnjanaFields() {
        if (props.project && props.project._id && props.project.name && props.anjanaId && props.element) {
            if (props.element === "project") {
                let project = JSON.parse(JSON.stringify(props.project));
                project.anjanaId = props.anjanaId ? props.anjanaId : ""
                project.anjanaIntegrable = true
                dispatch(actions.editProject(props.project._id, project))
                props.setAnjanaId(props.anjanaId)
                props.setAnjanaIntegrable(true)
                props.setVisibleAnjanaId(false)
            }
        }
        if (!props.element) {
            props.setAnjanaId(props.anjanaId)
            props.setAnjanaIntegrable(true)
            props.setVisibleAnjanaId(false)
        }
    }

    return (
        <AqtivaDialog
            visible={props.visible}
            dialog={props.visible}
            titleIcon={faPlusCircle}
            fullWidth={true}
            maxWidth={'sm'}
            title={"Insert Anjana Id"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setVisibleAnjanaId(false)

            }}
            confirmText={'confirm Id'}
            confirmCallback={async () => {
                editAnjanaFields()
            }}
        >
            < Grid container spacing={1} >
                <Grid item xs={10}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '85%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Anjana Id"
                                label="Anjana Id"
                                margin="dense"
                                value={props.anjanaId ? props.anjanaId : ""}
                                onChange={(event) => { props.setAnjanaId(event.target.value) }}
                            />} autoComplete="new-password" />

                </ Grid>
            </ Grid>


        </AqtivaDialog>

    )
}