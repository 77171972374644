import React, { Component, } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { QualityTheme, styles } from '../../../style/CustomTheme';
import PropTypes from 'prop-types';
import {  Typography } from '@material-ui/core';
import DataformatTable from './DataformatTable'
import DataformatFilters from './DataformatFilters';
import DataformatDetail from './DataformatDetail'
import DataformatProfiling from './DataformatProfiling'

import DataformatProjectGraph from './DataformatProjectGraph'
import DialogCreateDataFormat from '../../dialogs/DialogCreateDataFormat'
import DialogSelectAzureSource from '../../dialogs/DialogSelectAzureSource';
import DialogViewDataformatVersions from '../../dialogs/DialogViewDataformatVersions';
import * as renderHelper from './utils'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


import { connect } from 'react-redux';
import { fetchDataFormats, fetchAllDataFormats, deleteUnusedDataformats, fetchUser } from '../../../actions';
import {
  withRouter
} from 'react-router-dom'



class QualityDatasetProfilingRepository extends Component {

  constructor(props) {
    super(props);


    this.state = {
      showDialogDatasetDetail: false,
      showDialogDatasetProfiling: false,
      showProjectUseDetailDialog: false,
      selectedDataset: undefined,
      selectedDatasetForExportation: undefined,
      selectedDatasetForExportationId: undefined,
      selectedDatasetToViewVersions: undefined,
      selected: [],
      licenseUsers: [],
      projects: [],
      selectedDataformatSource: undefined,
      selectedDataformatUser: undefined,
      showDialogCreateDataformatAzure: false,
      showDialogCreateDataformat: false,
      showDialogCreateNewDataFormatVersion: false,
      showDialogCreateDataformatNewVersionFromLocalFile: false,
      showDialogViewDataformatVersions: false,
      viewAsUser: false
    }


  }
  async componentDidMount() {

    this.state.licenseUsers = this.props.licenseUsers
    this.props.fetchUser()

    if (!this.isEmpty(this.props.currentUser)) {
      if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
        await this.props.fetchDataFormats(this.props.currentUser)
      }
      else {
        await this.props.fetchAllDataFormats(this.props.currentUser)
      }

      if (this.props.notificationDatasetId &&  this.props.dataformats.length !==0) {

        this.viewDatasetProfiling(this.props.notificationDatasetId)
      }
    }


  }



  componentDidUpdate(oldProps) {
    if (this.isEmpty(oldProps.currentUser) && !this.isEmpty(this.props.currentUser)) {
      if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
        this.props.fetchDataFormats(this.props.currentUser)
      }
      else {
        this.props.fetchAllDataFormats(this.props.currentUser)
      }
    }
    if (oldProps.dataformats.length !== this.props.dataformats.length) {
      if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
        this.props.fetchDataFormats(this.props.currentUser)
        if (this.props.notificationDatasetId) {
          this.viewDatasetProfiling(this.props.notificationDatasetId)
        }
      }
      else {
        this.props.fetchAllDataFormats(this.props.currentUser)
      }

    }

  }



  translateUserIdToName = (userId, licenseUsers) => {
    if (licenseUsers !== undefined && licenseUsers.length > 0) {
      for (let i = 0; i < licenseUsers.length; i++) {
        if (licenseUsers[i].id === userId) {
          return licenseUsers[i].name
        }
      }
      return userId
    }
    return undefined


  }


  handleSelectSource = event => {
    let value = event.target.value
    this.setState({ selectedDataformatSource: value })
  }
  handleSelectOwner = event => {
    let value = event.target.value
    this.setState({ selectedDataformatUser: value })
  }

  handleSelectDatasetForExportation = (id) => {
    if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
      this.setState({ selectedDatasetForExportation: (this.props.dataformats ? this.props.dataformats.find(x => x._id === id) : undefined) })

    }
    else {
      this.setState({ selectedDatasetForExportation: (this.props.dataformatsAll ? this.props.dataformatsAll.find(x => x._id === id) : undefined) })

    }
  }
  handleSelectDatasetForExportationId = (id) => {
    this.setState({ selectedDatasetForExportationId: id })
    this.props.handleSelectDatasetForExportationId(id)
  }

  viewDatasetDetails = (id) => {
    if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
      this.setState({ selectedDataset: (this.props.dataformats ? this.props.dataformats.find(x => x._id === id) : undefined), showDialogDatasetDetail: true })

    }
    else {
      this.setState({ selectedDataset: (this.props.dataformatsAll ? this.props.dataformatsAll.find(x => x._id === id) : undefined), showDialogDatasetDetail: true })

    }
  }
  viewDatasetProfiling = (id) => {
    if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
      this.setState({ selectedDataset: (this.props.dataformats ? this.props.dataformats.find(x => x._id === id) : undefined), showDialogDatasetProfiling: true })

    }
    else {
      this.setState({ selectedDataset: (this.props.dataformatsAll ? this.props.dataformatsAll.find(x => x._id === id) : undefined), showDialogDatasetProfiling: true })

    }
  }
  addDataformatVersion = (id) => {
    if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
      this.setState({ selectedDataset: (this.props.dataformats ? this.props.dataformats.find(x => x._id === id) : undefined), showDialogDatasetDetail: true })
    }
    else {
      this.setState({ selectedDataset: (this.props.dataformatsAll ? this.props.dataformatsAll.find(x => x._id === id) : undefined), showDialogDatasetDetail: true })
    }
  }
  viewDatasetUseInProjects = (id) => {

    if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
      this.setState({ selectedDataset: (this.props.dataformats ? this.props.dataformats.find(x => x._id === id) : undefined), showProjectUseDetailDialog: true })

    }
    else {
      this.setState({ selectedDataset: (this.props.dataformatsAll ? this.props.dataformatsAll.find(x => x._id === id) : undefined), showProjectUseDetailDialog: true })

    }

  }
  viewCreateDataformatAzure = () => {

    this.setState({ showDialogCreateDataformatAzure: true })
  }
  viewCreateDataformat = () => {

    this.setState({ showDialogCreateDataformat: true })
  }
  viewCreateNewDataformatVersionLocal = () => {

    this.setState({ showDialogCreateDataformatNewVersionFromLocalFile: true })
  }
  viewCreateNewDataFormatVersionAzure = (id) => {
    this.setState({ selectedDataset: (this.props.dataformats ? this.props.dataformats.find(x => x._id === id) : undefined), showDialogCreateNewDataFormatVersion: true })
  }
  viewDisplayDataFormatVersions = (id) => {
    this.setState({ selectedDatasetToViewVersions: (this.props.dataformats ? this.props.dataformats.find(x => x._id === id) : undefined), showDialogViewDataformatVersions: true })
  }

  onCloseDetailDialog = () => {
    this.setState({ showDialogDatasetDetail: false })
  }

  onCloseProfilingDialog = () => {
    this.setState({ showDialogDatasetProfiling: false })
  }

  onCloseCreateDataformat = () => {
    this.setState({ showDialogCreateDataformat: false })
  }
  onCloseCreateNewDataformatVersionLocal = () => {
    this.setState({ showDialogCreateDataformatNewVersionFromLocalFile: false })
  }
  onCloseCreateNewDataFormatAzureVersion = () => {

    this.setState({ showDialogCreateNewDataFormatVersion: false })
  }
  onCloseCreateDataformatAzure = () => {
    this.setState({ showDialogCreateDataformatAzure: false })
  }
  onCloseDisplayDataformatVersions = () => {
    this.setState({ showDialogViewDataformatVersions: false })
  }

  onCloseProjectUseDetailDialog = () => {
    this.setState({ showProjectUseDetailDialog: false })
  }



  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  clean() {
    this.setState({
      selected: []
    })
  }

  pad(n) {
    return n < 10 ? '0' + n : n
  }


  onDeleteUnused = () => {
    this.props.deleteUnusedDataformats(this.props.currentUser)
  }

  onAddDataFormat = () => {
    this.setState({ showDialogCreateDataformatAzure: true })
  }

  onClose = () => {
    this.props.onHide();
    this.clean();
  }




  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      var newSelecteds = undefined
      if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
        newSelecteds = this.props.dataformats.map(n => n._id);
      }
      else {
        newSelecteds = this.props.dataformatsAll.map(n => n._id);
      }
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleClick(event, id) {
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected })
  }

  compareDatasets = (a, b) => {
    if (a.creationDate > b.creationDate) {
      return -1;
    }
    if (a.creationDate < b.creationDate) {
      return 1;
    }
    return 0;
  }

  isItemSelected = (id) => {
    return (this.state.selected.indexOf(id) !== -1)
  };

  handleChangeViewAsUser = (event) => {
    this.setState({ ...this.state, viewAsUser: event.target.checked }, function () {
      if (!this.isEmpty(this.props.currentUser)) {
        if ((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) {
          this.props.fetchDataFormats(this.props.currentUser)
        }
        else {
          this.props.fetchAllDataFormats(this.props.currentUser)
        }
      }
    });
  }


  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={QualityTheme}>

        <DataformatDetail show={this.state.showDialogDatasetDetail}
          dataset={this.state.selectedDataset}
          onCloseDetailDialog={this.onCloseDetailDialog}
        />

        <DataformatProfiling show={this.state.showDialogDatasetProfiling}
          dataset={this.state.selectedDataset}
          onCloseProfilingDialog={this.onCloseProfilingDialog}
        />

        <DataformatProjectGraph
          userId={this.props.userId}
          history={this.props.history}
          show={this.state.showProjectUseDetailDialog}
          size={"large"}
          projectsUsedDetail={this.props.usedDatasourcesByProjects && renderHelper.splitUsedProjectsIntoOwnAndOthers(this.props.usedDatasourcesByProjects, this.state.selectedDataset, this.props.userId)}
          dataformat={this.state.selectedDataset}
          onClose={this.onCloseProjectUseDetailDialog}
        />

        {this.state.showDialogCreateDataformatAzure &&
          <DialogSelectAzureSource visible={this.state.showDialogCreateDataformatAzure} setVisible={this.onCloseCreateDataformatAzure} configuration={this.props.configuration}
            setLocalFileDialogVisible={this.viewCreateDataformat} />
        }
        {this.state.showDialogCreateDataformat &&
          <DialogCreateDataFormat
            configuration={this.props.configuration}
            open={this.state.showDialogCreateDataformat}
            setClose={this.onCloseCreateDataformat} />}


        {/*---------------------------------------- dialogos de versiones------------------------------*/}
        {this.state.showDialogCreateNewDataFormatVersion &&
          <DialogSelectAzureSource
            createNewVersion={true}
            selectedDataset={this.state.selectedDatasetToViewVersions}
            visible={this.state.showDialogCreateNewDataFormatVersion} setVisible={this.onCloseCreateNewDataFormatAzureVersion}
            closeDialogDisplay={this.onCloseDisplayDataformatVersions}
            setLocalFileDialogVisible={this.viewCreateNewDataformatVersionLocal} />}

        {this.state.showDialogCreateDataformatNewVersionFromLocalFile &&
          <DialogCreateDataFormat
            createNewVersion={true}
            selectedDataset={this.state.selectedDatasetToViewVersions}
            configuration={this.props.configuration}
            open={this.state.showDialogCreateDataformatNewVersionFromLocalFile}
            closeAzureItemDialog={this.onCloseCreateNewDataFormatAzureVersion}
            closeDialogDisplay={this.onCloseDisplayDataformatVersions}
            setClose={this.onCloseCreateNewDataformatVersionLocal} />}

        {this.state.showDialogViewDataformatVersions &&
          <DialogViewDataformatVersions
            viewCreateNewDataFormatVersionAzure={this.viewCreateNewDataFormatVersionAzure}
            createNewVersion={true}
            selectedDataset={this.state.selectedDatasetToViewVersions}
            configuration={this.props.configuration}
            open={this.state.showDialogViewDataformatVersions}
            setClose={this.onCloseDisplayDataformatVersions}

            classes={classes}
            translateUserIdToName={this.translateUserIdToName}
            licenseUsers={this.props.licenseUsers}
            usedDatasourcesByProjects={this.props.usedDatasourcesByProjects}
            viewDatasetDetails={this.viewDatasetDetails}
            viewDatasetUseInProjects={this.viewDatasetUseInProjects}
            viewCreateNewDataFormatVersion={this.viewCreateNewDataFormatVersion}
            handleSelectDatasetForExportation={this.handleSelectDatasetForExportation}
            handleSelectDatasetForExportationId={this.handleSelectDatasetForExportationId}
            selectedDatasetForExportationId={this.state.selectedDatasetForExportationId}
            selectDatasetDialog={this.props.selectDatasetDialog}
          />}

        {/*---------------------------------------- fin dialogos de versiones-----------------------------------*/}

        <div style={{display: 'flex',
  flexDirection: 'row'}}>
        {this.props.currentUser && (this.props.currentUser.role !== 'USER') &&
          <div>
            <FormControlLabel control={<Switch color="primary"
              checked={this.state.viewAsUser} onChange={this.handleChangeViewAsUser} />} label={
                <Typography variant = 'caption'>View as User</Typography>
              } />
          </div>
        }
        <div style={{marginTop:'-15px'}}>
        <DataformatFilters dataformats={((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) ? this.props.dataformats : this.props.dataformatsAll} handleSelectSource={this.handleSelectSource} handleSelectOwner={this.handleSelectOwner} selectedSource={this.state.handleSelectSource} selectedOwner={this.state.handleSelectOwner} licenseUsers={this.props.licenseUsers} />
        </div>
        </div>
        <DataformatTable selectedDatasetForExportation={this.state.selectedDatasetForExportation} classes={classes}
          translateUserIdToName={this.translateUserIdToName}
          selectedDataformatSource={this.state.selectedDataformatSource}
          selectedDataformatUser={this.state.selectedDataformatUser}
          dataformats={((this.props.currentUser.role === 'USER' || this.state.viewAsUser === true)) ? this.props.dataformats : this.props.dataformatsAll}
          licenseUsers={this.props.licenseUsers}
          usedDatasourcesByProjects={this.props.usedDatasourcesByProjects}
          viewDatasetDetails={this.viewDatasetDetails}
          viewDatasetProfiling={this.viewDatasetProfiling}
          viewDatasetUseInProjects={this.viewDatasetUseInProjects}
          viewCreateNewDataFormatVersion={this.viewDisplayDataFormatVersions}
          handleSelectDatasetForExportation={this.handleSelectDatasetForExportation}
          handleSelectDatasetForExportationId={this.handleSelectDatasetForExportationId}
          selectedDatasetForExportationId={this.state.selectedDatasetForExportationId}
          selectDatasetDialog={this.props.selectDatasetDialog} />

    


      </MuiThemeProvider>
    );
  }
}
QualityDatasetProfilingRepository.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    dataformatsAll: Object.values(state.dataformatsAll),
    dataformats: Object.values(state.dataformats),
    currentUser: (state.user)
  };
}


export default withStyles(styles)(withRouter((connect(mapStateToProps, { fetchUser, fetchDataFormats, fetchAllDataFormats, deleteUnusedDataformats })(QualityDatasetProfilingRepository))));
