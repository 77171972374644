
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
    InputLabel, Typography, Select, MenuItem, FormControl, Tooltip
} from '@material-ui/core'
import { useSelector } from 'react-redux'

export default function OutputPathSelector(props) {
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))

    const [outputPathsOptions, setOutputPathsOptions] = useState(undefined)


    useEffect(() => {
        if (props.selectedEnvironment) {
            let environment = qualityConfiguration && qualityConfiguration.environmentConfigurationList && qualityConfiguration.environmentConfigurationList.filter(element => element.environmentName === props.selectedEnvironment)[0]
            if (environment) {
                setOutputPathsOptions(environment.enabledResultStorages)
            }
            else {
                setOutputPathsOptions(undefined)
            }
        }
    }, [props.selectedEnvironment])// eslint-disable-line react-hooks/exhaustive-deps


    const theme = useTheme()

    return (

        <FormControl style={{ width: '150px' }}>
            <InputLabel id="selectResourceGroup">
                <Typography variant="caption">Select an Ouput Path</Typography>
            </InputLabel>
            {outputPathsOptions && outputPathsOptions.length > 0 && < Select
                value={props.selectedOutputPath}
                renderValue={value => value.storageName}
                onChange={event => {
                    props.setSelectedOutputPath(event.target.value);
                    props.setNoOutputPathError(undefined)
                }}
                inputProps={{
                    name: 'Environment',
                    id: 'Environment',
                }}
                style={{ width: '150px' }}
            >
                {outputPathsOptions.map(element => {
                    if (element.storageType === "azure-storage") {
                        return (
                            <MenuItem key={element.storageName} value={element}>
                                <div style={theme.elementAdjacent}>
                                    <Tooltip title={
                                        <React.Fragment>
                                            <h3>Resource Group Name: <strong style={{ color: "#2fb7e0" }}>{element.storageProperties.resourceGroupName}</strong></h3>
                                            <h3>Storage Account Name: <strong style={{ color: "#2fb7e0" }}>{element.storageProperties.storageAccountName}</strong></h3>
                                            <h3>Container  Name: <strong style={{ color: "#2fb7e0" }}>{element.storageProperties.containerName}</strong></h3>
                                            <h3>Path: <strong style={{ color: "#2fb7e0" }}>{element.storageProperties.path}</strong></h3>
                                        </React.Fragment>}>
                                        <Typography variant='subtitle2'>{element.storageName}</Typography>
                                    </Tooltip>
                                </div>
                            </MenuItem>

                        )

                    }
                    if (element.storageType === "azure-sqldatabase") {
                        return (
                            <MenuItem key={element.storageName} value={element}>
                                <div style={theme.elementAdjacent}>
                                    <Tooltip title={
                                        <React.Fragment>
                                            <h3>Resource Group Name: <strong style={{ color: "#2fb7e0" }}>{element.sqlProperties.resourceGroup}</strong></h3>
                                            <h3>Server Name: <strong style={{ color: "#2fb7e0" }}>{element.sqlProperties.server}</strong></h3>
                                            <h3>Database Name: <strong style={{ color: "#2fb7e0" }}>{element.sqlProperties.database}</strong></h3>
                                            <h3>Schema  Name: <strong style={{ color: "#2fb7e0" }}>{element.sqlProperties.schema}</strong></h3>
                                            <h3>Table  Name: <strong style={{ color: "#2fb7e0" }}>{element.sqlProperties.table}</strong></h3>
                                        </React.Fragment>}>
                                        <Typography variant='subtitle2'>{element.storageName}</Typography>
                                    </Tooltip>
                                </div>
                            </MenuItem>
                        )
                    }
                    if (element.storageType === "AWS") {
                        return (
                            <MenuItem key={element.storageName} value={element}>
                                <div style={theme.elementAdjacent}>
                                    <Tooltip title={
                                        <React.Fragment>
                                            <h3>Bucket Name: <strong style={{ color: "#2fb7e0" }}>{element.awsProperties.s3BucketName}</strong></h3>
                                            <h3>Key Id: <strong style={{ color: "#2fb7e0" }}>{element.awsProperties.s3Keyid}</strong></h3>
                                            <h3>Region: <strong style={{ color: "#2fb7e0" }}>{element.awsProperties.s3Region}</strong></h3>
                                        </React.Fragment>}>
                                        <Typography variant='subtitle2'>{element.storageName}</Typography>
                                    </Tooltip>
                                </div>
                            </MenuItem>
                        )
                    }
                    return(
                        <div></div>
                    )
                })}
            </Select>}
        </FormControl >
    )
}



