import React from 'react';
import { Typography, Grid, TextField, Switch, FormControlLabel } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';


export default function TagEnvironmentEnrich(props) {
    const theme = useTheme()

    // In this component we can modify 3 values: useTag, tag, tagDescription
    // Depending on the value of useTag, the other two values will be introduced in the join object.

    return (
        <Grid item xs={12}>
            <div style={theme.elementAdjacent}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={props.useTag}
                            onChange={event => {
                                props.setUseTag(event.target.checked);
                            }}
                            name="checkSplitOutput"
                            color="primary"
                        />
                    }
                    label={<Typography variant='body2'>Tag dataset</Typography>}
                />
            {props.useTag && 
                <TextField style={{ width: '30%', marginRight:'5px' }}
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            id="tag"
                            label="Tag"
                            margin="dense"
                            value={props.tag}
                    onChange={(event) => { props.setTag(event.target.value) }}
                />
            }
              {props.useTag && 
                <TextField style={{ width: '50%' }}
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            id="TagDescription"
                            label="Tag Description"
                            margin="dense"
                            value={props.tagDescription}
                    onChange={(event) => { props.setTagDescription(event.target.value) }}
                />
              }
            </div>
        </Grid>
       
    )
}