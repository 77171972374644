import React from "react";

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { Typography, CircularProgress } from "@material-ui/core";

import * as commonStyles from 'style/CommonStyles'


//Nos llegan las props de la info de la gráfica
export default function ChartLineBiaxialTotalEventRules(props) {

  if (!props.chartData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress size={50} style={{ margin: "40px" }} />
        <Typography variant="subtitle2" style={{ marginLeft: "10px" }}>
          Fetching data
        </Typography>
        <Typography variant="caption" style={{ marginTop: "10px" }}>
          Please wait...{" "}
        </Typography>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        width={1150}
        height={250}
        data={props.chartData ? props.chartData : []}
      >
        <XAxis dataKey="date" angle={-10} tick={{ fontSize: 12 }} />
        <YAxis tick={{ fontSize: 12 }} />
        <Area
          type="monotone"
          dataKey="ucs"
          stroke={commonStyles.mainColor}
          fill={commonStyles.mainColor}
        />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        {/* <Legend /> */}
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="ucs"
          stroke={commonStyles.mainColor}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
