import React from 'react';
import { Button } from '@material-ui/core';



export default function ReturnPreviousAgregationButton(props) {

    function returnToParent() {
        if (props.agregationLevel && props.agregationLevel === "datasource") {
            props.setAgregationLevel("qualityPoint")
        }
        if (props.agregationLevel && props.agregationLevel === "execution") {
            props.setAgregationLevel("datasource")
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={(event) => { returnToParent() }}>return to parent</Button>
            </div>
        </>

    );
}