import { Grid, Typography } from "@material-ui/core";
import React from "react";
import DashboardChartComponent from "../../Common/Components/DashboardChartComponent";
import ReturnPreviousAgregationButton from "../ReturnPreviousAgregationButton";
import * as commonStyles from 'style/CommonStyles'


export default function ExecutionGrid(props) {


    function setAgregationLevel(level) {
        props.setAgregationLevel(level)
    }

    return (
        <>
            <Grid container spacing={3} style={commonStyles.titleBox}>
                <Grid item xs={12} style={commonStyles.DashboardChartComponentTitle}>
                    <Typography variant='h6' style={commonStyles.titleText}>{props.name} -  DataSources</Typography>
                    <ReturnPreviousAgregationButton agregationLevel={props.agregationLevel} setAgregationLevel={setAgregationLevel} />
                </Grid >


                <Grid item xs={7}>

                    <DashboardChartComponent content={[]} title={''} description={'Puntuación de todas las ejecuciones por proyecto'} />


                </Grid >
                <Grid item xs={5}>
                    <DashboardChartComponent content={[]} title={'Trends'} description={'Global DQI'} />

                </Grid >

            </Grid>
        </>
    )
}