
import React from 'react';

import { Grid, Typography } from '@material-ui/core'

import EmailConfigCard from './EmailConfigCard'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';

import { faCog } from '@fortawesome/free-solid-svg-icons'

import * as commonStyles from 'style/CommonStyles'

export default function NotificationConfigurationQuality(props) {


    const theme = useTheme()

    function isValid() {
        if (props.notificationConfiguration) {
            return true
        }
        return false
    }

    return (
        <Accordion
            style={{ width: '100%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={1}>
                    <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                </Grid>
                <Grid item xs={11}>
                    <Typography variant='subtitle2'>Notification Configuration</Typography>
                    {!isValid() &&
                        <div className="ui error message" style={theme.errors.errorText}>
                            <div className="header" >Error in Notification Configuration</div>
                        </div>

                    }

                </Grid>

            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <EmailConfigCard title='Email notification parameters' setNotificationConfiguration={props.setNotificationConfiguration} notificationConfiguration={props.notificationConfiguration} qualityPoint={props.qualityPoint} />
                    </Grid>
                </Grid>

            </AccordionDetails>
        </Accordion>
    )
}