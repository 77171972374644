export function validateAggregationObj(aggregationObject : any , aggregatedColumnsFlag: boolean){
    if(aggregatedColumnsFlag===true){
        //aggregation selected
        if(aggregationObject === undefined || aggregationObject===null){
            //aggregationObject must be set
            return "Aggregation configuration must be set"
        }
        else{
            
            if(aggregationObject.aggregationOperations ===undefined || aggregationObject.aggregationOperations===null || aggregationObject.aggregationOperations.length===0){
                //group columns must have at leat one value
                return "At least one aggregation must be set"
            }
        }
    }
    return undefined
}