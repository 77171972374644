import React, { useState } from 'react'
import { Button } from '@material-ui/core'

import MaterialTable from "@material-table/core";
import { Tooltip } from '@material-ui/core'
import * as commonStyles from 'style/CommonStyles'

import VpnKeyIcon from '@material-ui/icons/VpnKey';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';


import { TextField, FormControlLabel, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox } from '@material-ui/core';

import * as renderHelper from './utils'
import * as renderHelperInfo from '../DataformatVersionsTable/utils'

import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import BarChartIcon from '@material-ui/icons/BarChart';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { LanguageContext } from 'language/LanguageContext'

import * as tableFunctions from 'commonFunctions/tableFunctions'
import { dispatchError } from 'components/common/axios/axiosHelper';
import { useDispatch } from 'react-redux'
import SpeedDialActionsTable from 'components/common/SpeedDialActionsTable';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid'

import { onlyUnique } from 'commonFunctions/commonFunctions'


export default function DataformatTable(props) {
 
    //props.selectDatasetDialog indicates if it´s the main page or a dialog for selecting a datasource
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    //const [selected, setSelected] = useState(false)

    const languageContext = React.useContext(LanguageContext);

    const [sourceTypeLP, setSourceTypeLp] = React.useState([])
    const [ownerLP, setOwnerLP] = React.useState([])
    const [profilingLp, setProfilingLp] = React.useState([])

    const [licenseUsers, setLicenseUsers] = React.useState([])

    const [searchText, setSearchText] = React.useState('')

    const [filterName, setFilterName] = React.useState('')
    const [filterStorageAccount, setFilterStorageAccount] = React.useState('')
    const [filterOrigin, setFilterOrigin] = React.useState('')
    const [sourceTypes, setSourceTypes] = React.useState([])

    function filterData(data) {
        var newData = data
        if (searchText !== undefined && searchText !== '') {
            newData = newData.filter(x => JSON.stringify(x).includes(searchText))
        }
        if (filterName !== undefined && filterName !== '') {
            newData = newData.filter(x => x.name !== undefined && x.name !== null && x.name.includes(filterName))
        }
        if (filterStorageAccount !== undefined && filterStorageAccount !== '') {
            newData = newData.filter(x => x.fileStorageProperties !== undefined && x.fileStorageProperties !== null && x.fileStorageProperties.account.includes(filterStorageAccount))
        }

        if (filterOrigin !== undefined && filterOrigin !== '') {
            newData = newData.filter(x => x.fileStorageProperties !== undefined && x.fileStorageProperties !== null && x.fileStorageProperties.path.includes(filterOrigin))
        }

        if (sourceTypes !== undefined && sourceTypes.length > 0) {
            if (sourceTypes.includes("local-file")) {
                const dataLocal = newData.filter(x => x.source === undefined || x.source === null || x.source === '')
                const filterData = newData.filter(x => sourceTypes.includes(x.source))
                newData = dataLocal.concat(filterData)
            }
            else {
                newData = newData.filter(x => sourceTypes.includes(x.source))
            }

        }
        return newData
    }


    React.useEffect(() => {
        var filteredData = renderHelper.applyFilters(props.dataformats, props.selectedDataformatSource, props.selectedDataformatUser)
        if (props.viewOnlyProfiling === true) {
            filteredData = filteredData.filter(x => (x.profiling !== undefined && x.profiling !== null) || x.hasProfiling === true)
        }

        filteredData = filterData(filteredData)

        setData(filteredData)
    }, [JSON.stringify(props.dataformats), JSON.stringify(props.selectedDataformatSource),// eslint-disable-line react-hooks/exhaustive-deps
    JSON.stringify(props.selectedDataformatUser), JSON.stringify(props.viewOnlyProfiling), // eslint-disable-line react-hooks/exhaustive-deps
        searchText, filterName, filterStorageAccount, filterOrigin, sourceTypes])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        setLicenseUsers(props.licenseUsers)
    }, [JSON.stringify(props.licenseUsers)])// eslint-disable-line react-hooks/exhaustive-deps






    React.useEffect(() => {
        if (data !== undefined && data.length > 0) {
            setSourceTypeLp(tableFunctions.getLookup(data, 'source'))
            setOwnerLP(tableFunctions.getLookUpOwner(data, props.licenseUsers))
            //TODO: VARIABLE NOT USED
            setProfilingLp(tableFunctions.getLookUpProfiling(data, 'profiling'))
        }


    }, [JSON.stringify(data)])// eslint-disable-line react-hooks/exhaustive-deps


    function getColumns() {
        var baseColumns =
            [
                {
                    title: languageContext.dictionary['name'], width: props.selectDatasetDialog === true ? '30%' : '15%', field: 'name', render: rowData => {
                        return (
                            <>
                                {rowData.name && rowData.name.length >= 20 &&
                                    <Tooltip title={rowData.name}>
                                        <span> {rowData.name.substring(0, 20)}...</span>
                                    </Tooltip>
                                }
                                {rowData.name && rowData.name.length < 20 &&
                                    <span> {rowData.name}</span>

                                }
                                {rowData.name === undefined &&
                                    <span></span>

                                }
                            </>
                        )
                    }
                },
                { title: languageContext.dictionary['creationDate'], width: '7%', field: 'creationDate', filtering: false, render: rowData => { return rowData.creationDate && renderHelper.parseDateToCorrectFormat(rowData.creationDate) } },
                { title: languageContext.dictionary['updateDate'], width: '7%', field: 'updateDate', filtering: false, render: rowData => { return rowData.updateDate && renderHelper.parseDateToCorrectFormat(rowData.updateDate) } },
                { title: languageContext.dictionary['numberOfColumns'], filtering: false, width: '7%', field: 'columns', render: rowData => { return rowData.columns ? rowData.columns.length : 0 } },
                { title: languageContext.dictionary['sourceType'], width: '7%', field: 'source', render: rowData => { return renderHelperInfo.renderAzureLogoInfo(rowData.source, rowData) } },

                { title: languageContext.dictionary['storageAccount'], width: '7%', field: 'storageAccount', render: rowData => { return renderHelper.getAzureStorageAccount(rowData) } },
                { title: languageContext.dictionary['origin'], width: '15%', field: 'origin', render: rowData => { return renderHelper.getDataformatOrigin(rowData) } },

                { title: languageContext.dictionary['owner'], width: '7%', field: 'userId', render: (rowData) => { return rowData.ownerUserId ? props.translateUserIdToName(rowData.ownerUserId, props.licenseUsers) : rowData.userId && props.translateUserIdToName(rowData.userId, props.licenseUsers) } },
                { title: languageContext.dictionary['allowedUsers'], width: '7%', field: 'allowedUsers', render: (rowData) => { return renderHelper.renderAllowedUsers(rowData, props.licenseUsers) } },

                {
                    title: languageContext.dictionary['usedByProjects'], width: '7%', field: 'usedByProjects', filtering: false, render: (rowData) => {
                        return props.usedDatasourcesByProjects ? renderHelper.countProjectsUsingDataformat(props.usedDatasourcesByProjects, rowData) : '0'
                    }
                },
                {
                    title: languageContext.dictionary['version'], width: '7%', field: 'version', filtering: false, render: rowData => { return rowData.version }
                }
            ]
        if (props.selectDatasetDialog !== true) {
            baseColumns.push({
                title: languageContext.dictionary['actions'], width: '15%', field: '', filtering: false, render: rowData => { return getActionsModel(rowData) }
            }
            )

        }
        return baseColumns



    }

    function generateActionsArray(rowData) {
        var actions = []
        actions.push({
            name: languageContext.dictionary['tooltipDetail'],
            icon: <IconButton size='small' onClick={(event) => props.viewDatasetDetails(rowData._id)}>
                <ViewWeekIcon />
            </IconButton>
        })
        actions.push({
            name: languageContext.dictionary['tooltipSeeTrace'],
            icon: <IconButton size='small' onClick={(event) => props.viewDatasetUseInProjects(rowData._id)}>
                <AccountTreeIcon />
            </IconButton>
        })

        if (rowData.source === 'azure-sqldatabase') {
            actions.push({
                name: languageContext.dictionary['updateUserAndPwd'],
                icon: <IconButton size='small' onClick={(event) => { props.viewUpdateUserAndPwd(rowData._id); event.preventDefault() }}>
                    <VpnKeyIcon />
                </IconButton>
            })
        }

        if (rowData.version > 0) {
            actions.push({
                name: languageContext.dictionary['tooltipSeeVersion'],
                icon: <IconButton size='small' onClick={(event) => { props.viewCreateNewDataFormatVersion(rowData._id) }}>
                    <FormatListNumberedIcon />
                </IconButton>
            })
        }

        actions.push({
            name: languageContext.dictionary['tooltipAddVersion'],
            icon: <IconButton size='small' onClick={(event) => props.viewCreateNewDataFormatVersion(rowData._id)}>
                <span style={{ display: 'inline-block', position: 'relative' }}>
                    <FormatListNumberedIcon textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: '.9em' }} />
                    <AddIcon
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        style={{ position: 'absolute', left: '.3em', bottom: '.4em' }}
                    />
                </span>
            </IconButton>
        })

        if (rowData.hasProfiling === true) {
            actions.push({
                name: languageContext.dictionary['tooltipProfiling'],
                icon: <IconButton size='small' onClick={(event) => { props.viewDatasetProfiling(rowData._id) }}>
                    <BarChartIcon />
                </IconButton>
            })
        }

        actions.push({
            name: languageContext.dictionary['tooltipAddProfiling'],
            icon: <IconButton size='small' onClick={(event) => props.addInternalProfiling(rowData)}>
                <span style={{ display: 'inline-block', position: 'relative' }}>
                    <BarChartIcon textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: '.9em' }} />
                    <AddIcon
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        style={{ position: 'absolute', left: '.3em', bottom: '.4em' }}
                    />
                </span>
            </IconButton>
        })

        actions.push({
            name: languageContext.dictionary['tooltipShowIdDataset'],
            icon: <IconButton size='small' onClick={(event) => props.viewDisplayShowIdDataformat(rowData._id)}>
                <FileCopyIcon textAnchor="middle" alignmentBaseline="middle"  />
            </IconButton>
        })

        return actions
    }

    function getActionsModel(rowData) {
        if (process.env.REACT_APP_SPEEDDIAL_TABLES === true) {
            return getActionsSpeedDial(rowData)
        }
        else {
            return getActionsBase(rowData)
        }
    }
    function getActionsBase(rowData) {
        const actionList = generateActionsArray(rowData)

        return (
            <div style={{ width: '100%' }}>
                <Grid>
                    {actionList.map(action => {
                        return (
                            <Tooltip title={action.name} style={{ marginLeft: '5px' }}>
                                {action.icon}
                            </Tooltip>
                        )
                    })}
                </Grid>
            </div>

        )
    }

    function getActionsSpeedDial(rowData) {
        const actionList = generateActionsArray(rowData)

        return (
            <div style={{ width: '100%' }}>
                <SpeedDialActionsTable actions={actionList} />
            </div>

        )
    }


    return (
        <div>

            <div style={commonStyles.adjacentLeft}>
                <Button variant="outlined"
                    color='primary'
                    disabled={data && data.length > 0 && licenseUsers && licenseUsers.length > 0 ? false : true}
                    onClick={event => tableFunctions.exportCsvDataformats(data, getColumns(), 'test', licenseUsers)}>Export</Button>
                <div style={{ marginLeft: '10px', minWidth: '200px' }}>
                    <TextField id="search-datarepository-textfield" label="Search" variant="standard"
                        defaultValue={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                </div>
                <div style={{ marginLeft: '10px', minWidth: '200px' }}>
                    <FormControlLabel
                        control={
                            <TextField
                                InputLabelProps={{ shrink: true, style: { fontSize: '8px' } }}
                                id="nSamples"
                                label={<Typography variant="caption">Filter by name</Typography>}
                                margin="dense"
                                value={filterName}
                                onChange={(event) => setFilterName(event.target.value)}
                            />} />
                </div>

                <div style={{ marginLeft: '10px', minWidth: '200px' }}>
                    <FormControlLabel
                        control={
                            <TextField
                                InputLabelProps={{ shrink: true, style: { fontSize: '8px' } }}
                                id="nSamples"
                                label={<Typography variant="caption">Filter by Storage Account</Typography>}
                                margin="dense"
                                value={filterStorageAccount}
                                onChange={(event) => setFilterStorageAccount(event.target.value)}
                            />} />
                </div>

                <div style={{ marginLeft: '10px', minWidth: '200px' }}>
                    <FormControlLabel
                        control={
                            <TextField
                                InputLabelProps={{ shrink: true, style: { fontSize: '8px' } }}
                                id="nSamples"
                                label={<Typography variant="caption">Filter by Origin</Typography>}
                                margin="dense"
                                value={filterOrigin}
                                onChange={(event) => setFilterOrigin(event.target.value)}
                            />} />
                </div>

                <div style={{ marginLeft: '10px', minWidth: '200px' }}>
                    <FormControl style={{ width: '100%', }}>
                        <InputLabel >
                            <Typography variant='caption'> Filter by Source Type</Typography>
                        </InputLabel>
                        <Select
                            multiple
                            value={sourceTypes}
                            onChange={event => setSourceTypes(event.target.value)}
                            inputProps={{
                                name: 'sourceType',
                                id: 'sourceType',
                            }}
                            style={{ width: '100%', }}
                            renderValue={(selected) => <Typography variant='caption'> {selected.map(x => x.name).join(', ')}</Typography>}
                        >

                            {props.dataformats && props.dataformats.map(x => x['source']).filter(x => x !== undefined && x !== null && x !== '').filter(onlyUnique).map(element => {
                                return (
                                    <MenuItem key={element} value={element}>
                                        <Checkbox checked={sourceTypes.indexOf(element) > -1} />
                                        <Typography variant='caption'>{element}</Typography>
                                    </MenuItem>

                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <MaterialTable
                style={{ minWidth: props.selectDatasetDialog === true ? '1500px' : '800px', width: "100%" }}
                title="Datasets"
                data={data}
                onSelectionChange={(rows) => {
                    if (rows.length > 1) {
                        dispatchError(dispatch, "More than one dataset selected. Please select just one")
                    }
                    else if (rows.length > 0) {
                        const rowData = rows[0]
                        if (props.selectDatasetDialog) {
                            props.handleSelectDatasetForExportation(rowData._id)
                            if (rowData.tableData.id === props.selectedDatasetForExportationId) {
                                //TODO: VARIABLE NOT USED
                                //setSelected(false)
                                props.handleSelectDatasetForExportationId(rowData._id)
                            } else {
                                //TODO: VARIABLE NOT USED
                                //setSelected(true)
                                props.handleSelectDatasetForExportationId(rowData._id)
                            }
                        }
                    }
                    else {
                        props.handleSelectDatasetForExportation(undefined)
                    }

                }}
                columns={getColumns()}

                options={{
                    rowStyle: rowData => ({
                        backgroundColor:
                            props.selectedDatasetForExportationId &&
                                rowData._id === props.selectedDatasetForExportationId
                                ? commonStyles.mainColor
                                : "#fff"
                    }),
                    actionsColumnIndex: -1,
                    selection: props.selectDatasetDialog ? true : false,
                    filtering: props.selectDatasetDialog ? false : true,
                    tableLayout: "fixed",
                    search: false,
                    pageSize: 5,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: commonStyles.tableHeaderStyle,
                    cellStyle: commonStyles.tableCellStyle,
                    filterCellStyle: commonStyles.tableFilterCellStyle
                }}

                components={{
                    Toolbar: props => (
                        <div>
                        </div>
                    ),
                }}
            />
        </div>

    )
}
