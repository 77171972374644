import _ from 'lodash';
import {FETCH_PROJECT_VERSIONS,
        
    } from '../actions/types'
 

export default (state={},action) => {
    switch(action.type){
        case FETCH_PROJECT_VERSIONS:
            return {..._.mapKeys(action.payload,'_id')}
        default:
            return state;
        
    }
}