import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import { faFrown } from '@fortawesome/free-regular-svg-icons'
import { faMeh } from '@fortawesome/free-regular-svg-icons'

  
  //percent between 0 and 1
  const minPercentError=50
 const minPerentWarning=90
 const iconFontSize=35
 const iconFontSizeTiny=20

  export  function getColorForPercentage (percent){

    
    if(percent<=0){
      return '#A2A2A2AA'
    }
    if(percent <= minPercentError){
        return '#F3410DAA'
    }
    else if(percent < minPerentWarning){
      return '#ffad1fAA'
    }
    else{
        return '#108521AA'
    }
  
}

export  function getColorForPercentageSimple (percent){

    
  if(percent<0){
    return '#A2A2A2AA'
  }
  if(percent <= minPercentError){
      return '#F3410DAA'
  }
  else if(percent < minPerentWarning){
    return '#ffad1fAA'
  }
  else{
      return '#108521AA'
  }

}




export  function getGlobalIcon (percent){
    
  if(percent<0){
    return (<FontAwesomeIcon icon={faMeh} style={{
       color: getColorForPercentage(percent), fontSize: iconFontSize,
        position: 'absolute',
        left: '50%',  
        top: '45%',
        transform: 'translate(-50%, -50%)',
        webkitTransform: 'translate(-50%, -50%)'
    }}/>)
  }
  if(percent <= minPercentError){
      return (<FontAwesomeIcon icon={faFrown} style={{
        color: getColorForPercentage(percent), fontSize: iconFontSize,
         position: 'absolute',
         left: '50%',  
         top: '45%',
         transform: 'translate(-50%, -50%)',
         webkitTransform: 'translate(-50%, -50%)'
     }}/>)
  }
  else if(percent < minPerentWarning){
    return (<FontAwesomeIcon icon={faMeh} style={{
      color: getColorForPercentage(percent), fontSize: iconFontSize,
       position: 'absolute',
       left: '50%',  
       top: '45%',
       transform: 'translate(-50%, -50%)',
       webkitTransform: 'translate(-50%, -50%)'
   }}/>)
  }
  else{
      return (<FontAwesomeIcon icon={faSmile} style={{
        color: getColorForPercentage(percent), fontSize: iconFontSize,
         position: 'absolute',
         left: '50%',  
         top: '45%',
         transform: 'translate(-50%, -50%)',
         webkitTransform: 'translate(-50%, -50%)'
     }}/>)
  }
}

export  function getGlobalIconTiny (percent){
    
  if(percent<0){
    return (<FontAwesomeIcon icon={faMeh} style={{
       color: getColorForPercentage(percent), fontSize: iconFontSizeTiny,
        position: 'absolute',
        left: '50%',  
        top: '48%',
        transform: 'translate(-50%, -50%)',
        webkitTransform: 'translate(-50%, -50%)'
    }}/>)
  }
  if(percent <= minPercentError){
      return (<FontAwesomeIcon icon={faFrown} style={{
        color: getColorForPercentage(percent), fontSize: iconFontSizeTiny,
         position: 'absolute',
         left: '50%',  
         top: '48%',
         transform: 'translate(-50%, -50%)',
         webkitTransform: 'translate(-50%, -50%)'
     }}/>)
  }
  else if(percent < minPerentWarning){
    return (<FontAwesomeIcon icon={faMeh} style={{
      color: getColorForPercentage(percent), fontSize: iconFontSizeTiny,
       position: 'absolute',
       left: '50%',  
       top: '48%',
       transform: 'translate(-50%, -50%)',
       webkitTransform: 'translate(-50%, -50%)'
   }}/>)
  }
  else{
      return (<FontAwesomeIcon icon={faSmile} style={{
        color: getColorForPercentage(percent), fontSize: iconFontSizeTiny,
         position: 'absolute',
         left: '50%',  
         top: '48%',
         transform: 'translate(-50%, -50%)',
         webkitTransform: 'translate(-50%, -50%)'
     }}/>)
  }

}