import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Box';
export default function LoadingData(props) {

    return (
       
           
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems:'center',
            height:'100%',
            width:'100%'
        }}>
            <CircularProgress  style={{width:'25px', height:'25px'}}/>
            <Typography variant='subtitle1'>Loading ...</Typography>
        </div>
         

    )
}