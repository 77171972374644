import React from 'react';
import { Typography, FormControl,  TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab'

import { useTheme } from '@material-ui/core/styles';


export default function FilterByRGComponentAutocomplete(props) {

    const theme = useTheme();


    return (
        <FormControl style={{ ...theme.config.formControl, width: '100%', marginRight: '20px', marginTop: '6px' }}>
            {/* <InputLabel id="selectResourceGroup">
                <Typography variant="caption">Filter by Resource Group</Typography>
            </InputLabel> */}
            <Autocomplete value={props.selectedResourceGroup} options={props.resourceGroups}
                onChange={(event, newValue) => {
                    props.setSelectedResourceGroup(newValue);
                }}
                getOptionSelected={option => {
                    return (

                        <img alt="" style={{ height: '20px', width: '20px' }}
                            src={require('resources/icons/azure/Resource-Groups.png')} />

                    )
                }}
                renderTags={options => {
                    return (
                        options.map(option =>
                            <React.Fragment>
                                <div style={{ display: 'flex', justifyContent: 'left' }}>
                                    <img alt="" style={{ height: '20px', width: '20px' }}
                                        src={require('resources/icons/azure/Resource-Groups.png')} />
                                    <Typography variant='subtitle2'>{option}</Typography>
                                </div>
                            </React.Fragment>))
                }}
                renderOption={option => {
                    return (
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <img alt="" style={{ height: '20px', width: '20px' }}
                                    src={require('resources/icons/azure/Resource-Groups.png')} />
                                <Typography variant='subtitle2'>{option}</Typography>
                            </div>
                        </React.Fragment>
                    );
                }}
                renderInput={(params, data) => (
                    <div style={{ position: "relative" }}>
                        {params.inputProps.value && (
                            <span
                                style={{
                                    position: "absolute",
                                    transform: "translateY(90%)",
                                    marginLeft: "5px",
                                    marginRight: "50px",
                                }}
                            >
                                <img alt="" style={{ height: '20px', width: '20px' }}
                                    src={require('resources/icons/azure/Resource-Groups.png')} />
                            </span>
                        )}
                        <TextField
                            {...params}
                            label="Filter by Resource Group"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                                style: { paddingLeft: "20px",marginLeft:"5px" } 
                            }}
                        />
                    </div>
                )} />
        </FormControl>
    )
}