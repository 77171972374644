
import * as actions from '../../actions'


var CryptoJS = require("crypto-js");



export async function encryptString(kvName_, dispatch,plaintext){
    var kvName = kvName_.replaceAll("_","").replaceAll("-","")
    var passPhrase= await dispatch(actions.getControlValue("APP_PF"))
   

    var iv = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
        var salt = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);

        var aesUtil = new AesUtil(128, 1000);
        var ciphertext = aesUtil.encrypt(salt, iv, passPhrase, plaintext);

        var aesPassword = (iv + "::" + salt + "::" + ciphertext);

        await dispatch(actions.setSecret(kvName+"ecry",btoa(aesPassword)))
        let value = await dispatch(actions.setSecret(kvName,plaintext))
        return value    
}

var AesUtil = function(keySize, iterationCount) {
    this.keySize = keySize / 32;
    this.iterationCount = iterationCount;
  };
  
  AesUtil.prototype.generateKey = function(salt, passPhrase) {
    var key = CryptoJS.PBKDF2(
        passPhrase, 
        CryptoJS.enc.Hex.parse(salt),
        { keySize: this.keySize, iterations: this.iterationCount });
    return key;
  }
  
  AesUtil.prototype.encrypt = function(salt, iv, passPhrase, plainText) {
    var key = this.generateKey(salt, passPhrase);
    var encrypted = CryptoJS.AES.encrypt(
        plainText,
        key,
        { iv: CryptoJS.enc.Hex.parse(iv) });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }
  
  AesUtil.prototype.decrypt = function(salt, iv, passPhrase, cipherText) {
    var key = this.generateKey(salt, passPhrase);
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText)
    });
    var decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        key,
        { iv: CryptoJS.enc.Hex.parse(iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }