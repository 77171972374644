
import React from 'react';
import { Grid, Typography, InputAdornment, Icon, Checkbox } from '@material-ui/core'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "components/common/CustomInput/CustomInput.js";

import AdvanceConfigurationQuality from 'components/common/AdvanceConfigurationQuality'
import NotificationConfigurationQuality from 'components/common/NotificationConfigurationQuality'
import AqtivaDialog from 'components/common/AqtivaDialog'
import TagsConfigurationQuality from 'components/common/TagsConfigurationQuality'
import DialogInsertAnjanaId from 'components/dialogs/DialogInsertAnjanaId'
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'actions';

import { useTheme } from '@material-ui/core/styles';

import * as commonStyles from 'style/CommonStyles'
import ParameterConfiguration from 'components/common/ParameterConfiguration';


export default function DialogCreateQualityPoint(props) {

    const user = useSelector(store => store.user)
    const dispatch = useDispatch();

    const [editable, setEditable] = React.useState(props.qualityPoint ? true : false)
    const [name, setName] = React.useState(props.qualityPoint ? props.qualityPoint.name : '')
    //child components state
    const [thError, setThError] = React.useState()
    const [thWarning, setThWarning] = React.useState()
    const [notificationConfiguration, setNotificationConfiguration] = React.useState()
    const [wrongRegisterAction, setWrongRegisterAction] = React.useState()
    const [outputMode, setOutputMode] = React.useState()
    const [active, setActive] = React.useState()

    //error component state
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors, setHasErrors] = React.useState()

    const [mandatoryTags, setMandatoryTags] = React.useState([])
    const [optionalTags, setOptionalTags] = React.useState([])

    const [mandatoryErrorTags, setMandatoryErrorTags] = React.useState("")
    const [deprecatedErrorTags, setDeprecatedErrorTags] = React.useState("")

    const [visibleAnjanaId, setVisibleAnjanaId] = React.useState(false)
    const [anjanaIntegrable, setAnjanaIntegrable] = React.useState(false)
    const [anjanaId, setAnjanaId] = React.useState("")
    const [parameters, setParameters] = React.useState([])


    const theme = useTheme();

    function reset() {
        setName('')

    }


    //set editable elements if qualityPoint is found in props
    React.useEffect(() => {

        setEditable(props.qualityPoint ? true : false)
        setName(props.qualityPoint ? props.qualityPoint.name : '')
        setActive(props.qualityPoint ? props.qualityPoint.active : true)
        setThError(props.qualityPoint ? props.qualityPoint.errorTh : {})
        setThWarning(props.qualityPoint ? props.qualityPoint.warningTh : {})
        setParameters(props.qualityPoint ? props.qualityPoint.parameters: [])
        setNotificationConfiguration(props.qualityPoint ? props.qualityPoint.notificationConfiguration : {})
        if (props.qualityPoint) {
            setControlObj(props.qualityPoint)
        }
        else {
            setWrongRegisterAction('')
            setOutputMode('') //nunca deberíamos entrar aquí
        }


    }, [props.qualityPoint]);

    React.useEffect(() => {
        if (!editable) {
            const thError = {
                type: props.configuration.thErrorType,
                value: props.configuration.thErrorValue,
                action: props.configuration.thErrorAction
            }
            const thWarning = {
                type: props.configuration.thWarnType,
                value: props.configuration.thWarnValue,
                action: props.configuration.thWarnAction
            }
            setThError(thError)
            setThWarning(thWarning)
            setWrongRegisterAction(props.configuration.wrongRegisterOutput)
            setOutputMode(props.configuration.outputControl)
        }
    }, [props.configuration, editable])

    React.useEffect(() => {
        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true') {
            if (props.qualityPoint && props.qualityPoint.anjanaIntegrable) {
                setAnjanaIntegrable(props.qualityPoint.anjanaIntegrable)
            }
        }
    }, [props.qualityPoint])

    React.useEffect(() => {
        if (props.qualityPoint && props.qualityPoint.tagList && props.qualityPoint.tagList.length > 0 && props.configuration && props.configuration.tagConfiguration && props.configuration.tagConfiguration.length > 0) {
            checkIfTagsAreDeprecated(props.qualityPoint.tagList, props.configuration.tagConfiguration)
        }
    }, [props.configuration, props.qualityPoint])

    function checkIfTagsAreDeprecated(tagList, tagConfiguration) {
        let error = ""
        for (let i = 0; i < tagList.length; i++) {
            let isTagPresent = tagConfiguration.find(tag => tag.tagName === tagList[i].tagName)
            if (!isTagPresent) {
                error = "tag values are deprecated, please update them."
                setDeprecatedErrorTags(error)
            }
        }
    }

    /*
    * CALLBACK SETTERS
    */
    function setControlObj(data) {
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)
    }


    function getControlObj() {
        return { wrongRegisterAction: wrongRegisterAction, outputMode: outputMode }


    }

    const onChangeTextField = (event) => {

        setName(event.target.value)
    }


    //**********/

    /*
    * ACTION CALLBACKS
    */

    //callback to save QualityPoint
    const createQualityPoint = () => {
        let tagsMandatory = mandatoryTags
        let tagsOptional = optionalTags
            ? optionalTags.filter(
                (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
            )
            : [];
        let allTags = tagsMandatory.concat(tagsOptional);
        const qualityPoint = {
            name: name,
            active: true,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType: wrongRegisterAction,
            qualityPointNotificationConfiguration: notificationConfiguration,
            tagList: allTags,
            parameters: parameters
        }
        dispatch(actions.addQualityPipe(props.idProject, qualityPoint, user.id))


    }

    //callback to save QualityPoint
    const modifyQualityPoint = () => {
        let tagsMandatory = mandatoryTags
        let tagsOptional = optionalTags
            ? optionalTags.filter(
                (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
            )
            : [];
        let allTags = tagsMandatory.concat(tagsOptional);
        const qualityPoint = {
            _id: props.qualityPoint._id,
            name: name,
            active: active,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType: wrongRegisterAction,
            qualityPointNotificationConfiguration: notificationConfiguration,
            tagList: allTags,
            parameters: parameters
        }
        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true') {
            if (anjanaId) {
                qualityPoint.anjanaId = anjanaId ? anjanaId : ""
            }
            qualityPoint.anjanaIntegrable = anjanaIntegrable ? anjanaIntegrable : false
        }
        dispatch(actions.editQualityPipe(props.idProject, qualityPoint, user.id))
    }

    //wrapper to save QualityPoint
    const saveQualityPoint = () => {

        const { hasErrors } = validate() //{hasErrors, arrayErrorTypes, arrayErrorMsgs}
        if (!hasErrors) {
            createQualityPoint()
            props.setClose(false)
            props.clearSelectedItem()
        }


    }

    //wrapper to edit QualityPoint
    const editQualityPoint = () => {
        const { hasErrors } = validate() //{hasErrors, arrayErrorTypes, arrayErrorMsgs}

        if (!hasErrors) {
            modifyQualityPoint()
            props.setScheduleHasBeenModified(!props.scheduleHasBeenModified)
            props.setClose(false)
            props.clearSelectedItem()
        }
    }


    /*
    * ERROR FORM HANDLER FUNCTIONS
    */

    /*
    * ERROR HANDLE FUNCTIONS
    */
    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs[idx]
        }
    }

    function validate() {
        var hasErrors = false
        const arrayErrorTypes = []
        const arrayErrorMsgs = []


        if (!name) {
            hasErrors = true
            arrayErrorTypes.push('name')
            arrayErrorMsgs.push('A name must be provided')
        }

        let emptyMandatoryTags = mandatoryTags.filter(tag => tag.tagValue === undefined || tag.tagValue === '')
        if (emptyMandatoryTags.length > 0) {
            hasErrors = true
            setMandatoryErrorTags("Please complete all Mandatory tags.")
        }

        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)

        return { hasErrors, arrayErrorTypes, arrayErrorMsgs };

    }

    function validateInputs(states, type) {
        const validations = {
            name: {
                rule: /^[\w_]*$/.test(states.name),
                description: "Incorrect name. The name must only contain letters, numbers and _"
            }
        }
        if (type) {
            return validations[type]
        }
        return Object.values(validations).every((value) => value.rule === true)
    }

    function handleAnjanaSwitch() {
        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && props.qualityPoint && props.qualityPoint._id) {
            setAnjanaIntegrable(!anjanaIntegrable)
            dispatch(actions.editAnjanaFlagQualityPoint(props.idProject, props.qualityPoint._id, user.id, !anjanaIntegrable, props.project.name))

        }
    }

    return (
        <AqtivaDialog visible={props.open}
            maxWidth={'md'}
            editable={editable}
            title={editable ? 'Edit Quality Point' : 'Create Quality Point'}
            iconSourceFlag={true}
            iconSource={require('resources/icons/point_proc1_white.png')}
            titleIcon={faProjectDiagram}
            confirmText={'Create'}
            cancelText={'Close'}
            editText={'Edit'}
            confirmCallback={event => { saveQualityPoint(event); reset() }}
            cancelCallback={event => {
                props.setClose(false)
                props.clearSelectedItem()
                reset()
            }}
            editCallback={event => { editQualityPoint(event); reset() }}
            disabled={!validateInputs({ name })}
        >
            {/* {process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && <DialogInsertAnjanaId visible={visibleAnjanaId} setVisibleAnjanaId={setVisibleAnjanaId} dialog={visibleAnjanaId} setAnjanaId={setAnjanaId} anjanaId={anjanaId} setAnjanaIntegrable={setAnjanaIntegrable} user={props.user} />} */}
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <div>

                        <CustomInput
                            labelText="Quality Point Name"
                            id="ProjectName"
                            formControlProps={{
                                fullWidth: false,
                                style: { width: '60%' }
                            }}

                            inputProps={{
                                type: "text",
                                onChange: onChangeTextField,
                                value: name,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                )
                            }}
                            error={!validateInputs({ name }, "name").rule}
                            helperText={!validateInputs({ name }, "name").rule ? validateInputs({ name }, "name").description : ""}
                        />
                        <div >
                            {hasErrors && isError('name') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('name')}</Typography>}
                        </div>
                    </div>

                </Grid>
                <Grid item xs={4}>
                    {process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && <div style={{ ...theme.elementAdjacent, minWidth: '10vw', }}>
                        <Checkbox
                            checked={anjanaIntegrable}
                            color='primary'
                            onClick={e => {
                                handleAnjanaSwitch()
                            }}
                        />
                        <Typography variant='subtitle2' style={{ ...theme.common.text.regularText, marginTop: '8px' }}>
                            Anjana Integrable
                        </Typography>
                    </div>}
                </Grid>
                {editable &&    <Grid item xs={12}>
                    <Typography variant='subtitle2'>{`Id: ${props.qualityPoint._id}`}</Typography>
                </Grid>
                }
            </Grid>
            <ParameterConfiguration parameters = {parameters} setParameters={setParameters} />
            <AdvanceConfigurationQuality setThError={setThError} setThWarning={setThWarning} setControlObj={setControlObj}
                thError={thError}
                thWarning={thWarning}
                setNotificationConfiguration={setNotificationConfiguration}
                notificationConfiguration={notificationConfiguration}
                controlObj={getControlObj()} />
            <NotificationConfigurationQuality setNotificationConfiguration={setNotificationConfiguration}
                notificationConfiguration={notificationConfiguration} qualityPoint={props.qualityPoint} />
            <TagsConfigurationQuality qualityElement={props.qualityPoint}
                elementType="qualityPoint" setMandatoryTags={setMandatoryTags} setOptionalTags={setOptionalTags} mandatoryErrorTags={mandatoryErrorTags}
                parent={props.project} />
            <Typography variant={'caption'} style={{ color: commonStyles.errorColor }}>{mandatoryErrorTags}</Typography>
            <Typography variant={'caption'} style={{ color: commonStyles.errorColor }}>{deprecatedErrorTags}</Typography>





        </AqtivaDialog >
    )
}