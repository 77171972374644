import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import * as commonStyles from 'style/CommonStyles'

export default function DataformatBasicInformationAzureStorage(props) {


    return(
        <div style={{maxWidth:'500px'}}> 

{props.dataformat.fileStorageProperties &&
            <Grid container spacing={0} style={{margin:'10px'}}>
                <Grid item xs={12}>
                <Typography variant='subtitle1' style={{fontWeight:'bold'}}>Azure Storage Properties </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='subtitle2'>Resource Group: </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='body2'>{props.dataformat.fileStorageProperties.resourceGroup}</Typography>
                </Grid>

                <Grid item xs={4}> 
                    <Typography variant='subtitle2'>Account: </Typography>
                </Grid>
                <Grid item xs={8}>
                        <Typography variant='body2'>{props.dataformat.fileStorageProperties.account}</Typography>
                </Grid>

                <Grid item xs={4}> 
                    <Typography variant='subtitle2'>Container: </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='body2'>{props.dataformat.fileStorageProperties.container}</Typography>
                </Grid>

                <Grid item xs={4}> 
                    <Typography variant='subtitle2'>Path: </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='body2'>{props.dataformat.fileStorageProperties.path}</Typography>
                </Grid>

                
            </Grid>
}
        </div>
    )
}