
import React from 'react'
import { Button } from '@material-ui/core'
import {  faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import trackEvent from 'trackEvent'
import { useTheme } from '@material-ui/core/styles';
import DialogCommitProjectVersion from 'components/dialogs/DialogProjectVersions/DialogCommitProjectVersion'






export default function CommitButton(props) {
    
    const theme = useTheme();
    const [showCommitDialog, setShowCommitDialog] = React.useState(false)



    return (
        <div>
            <DialogCommitProjectVersion
                showCommitDialog={showCommitDialog}
                setShowCommitDialog={setShowCommitDialog}
                project={props.project}
                user={props.user}
            />

            <Button onClick={event => {
                setShowCommitDialog(true)
                trackEvent('go back version', 'go back version Button')
            }}
                style={theme.buttons.buttonRegular}
                startIcon={<FontAwesomeIcon icon={faSave} style={{ fontSize: 15 }} />}
            >
                <div style={theme.common.text.buttonTextSmall}>
                    Commit
                </div>
            </Button>
        </div >
    )

}