import React, { useEffect } from 'react';
import {
    Typography
} from '@material-ui/core'
import {  useDispatch } from 'react-redux';
import LinearProgressBar from './LinearProgressBar';


import ProfilingTable from 'components/common/ProfilingComponent/ProfilingTable'

import {

    EDIT_DATAFORMAT, 
  } from 'actions/types'


export default function ComponentProfilingStatus(props) {

    const dispatch = useDispatch()

    const [progressComplete, setProgressComplete] = React.useState(false)
    //TODO: avoid warnings
    if(progressComplete){
        
    }
    const [profilingParsed, setProfilingParsed] = React.useState(undefined)

    useEffect(() => {
        if (props.dataformatWithProfiling && props.dataformatWithProfiling.profiling && props.dataformatWithProfiling.profiling.profilingColumns) {
            let profiling = JSON.parse(JSON.stringify(props.dataformatWithProfiling.profiling.profilingColumns))
            for (let i = 0; i < profiling.length; i++) {
                for (let e = 0; e < profiling[i].profilingColumnItems.length; e++) {
                    profiling[i][profiling[i].profilingColumnItems[e].profilingItemName] = profiling[i].profilingColumnItems[e].profilingItemValue
                }
                delete profiling[i]["profilingColumnItems"]

            }
            setProfilingParsed(profiling)


            dispatch({
                type: EDIT_DATAFORMAT,
                payload: props.dataformatWithProfiling
              });

        }
        if (!props.dataformatWithProfiling) {
            setProfilingParsed(undefined)
        }

    }, [props.dataformatWithProfiling])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div style={{ width: '100%' }}>
                {/* convertSchema2DatasourceBase  */}
                {props.visible === true &&
                    <div>
                        {props.profilingState && (props.profilingState === "starting" || props.profilingState === "in progress") && <LinearProgressBar timeToComplete={90} style={{ width: '100%' }} setProgressComplete={setProgressComplete} />}
                        {props.profilingState && <Typography style={{ width: '100%' }} variant={'subtitle2'}> <strong>Profiling job Status:</strong> {props.profilingState}</Typography>}
                        {props.dataformatWithProfiling && profilingParsed && <Typography style={{ width: '100%' }} variant={'subtitle2'}>Profiling has successfully been created and added to the dataset</Typography>}
                        {props.dataformatWithProfiling && profilingParsed && <ProfilingTable data={profilingParsed} />}
                    </div>}
            </div>
        </>
    )
}