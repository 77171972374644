import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

import { styles, QualityTheme } from '../../../../../../../style/CustomTheme'
import { Typography, TextField, FormControlLabel, MuiThemeProvider } from '@material-ui/core';
import AqtivaDialogEncryptString from '../../../../../../common/AqtivaDialogEncryptString'
import classNames from 'classnames';


export default function AWSCard(props) {


    const classes = makeStyles(styles(QualityTheme));

    //const [visibleStorageSasToken, setVisibleStorageSasToken] = React.useState(false)
    const [visibleStorageToken, setVisibleStorageToken] = React.useState(false)

    const [s3Keyid, setS3KeyId] = React.useState(props.configuration && props.configuration.awsProperties &&
        props.configuration.awsProperties.s3Keyid ?
        props.configuration.awsProperties.s3Keyid : '')
    const [s3KeySecret, setS3KeySecret] = React.useState(props.configuration && props.configuration.awsProperties &&
        props.configuration.awsProperties.s3KeySecret ?
        props.configuration.awsProperties.s3KeySecret : '')

    const [s3Region, setS3Region] = React.useState(props.configuration && props.configuration.awsProperties &&
        props.configuration.awsProperties.s3Region ?
        props.configuration.awsProperties.s3Region : '')
    const [s3BucketName, setS3BucketName] = React.useState(props.configuration && props.configuration.awsProperties &&
        props.configuration.awsProperties.s3BucketName ?
        props.configuration.awsProperties.s3BucketName : '')
    const [s3Path, setS3Path] = React.useState(props.configuration && props.configuration.awsProperties &&
        props.configuration.awsProperties.s3Path ?
        props.configuration.awsProperties.s3Path : '')



    React.useEffect(() => {
        setS3KeyId(props.storageInfo &&
            props.storageInfo.s3Keyid ?
            props.storageInfo.s3Keyid : '')
        setS3KeySecret(props.storageInfo &&
            props.storageInfo.s3KeySecret ?
            props.storageInfo.s3KeySecret : '')
        setS3Region(props.storageInfo &&
            props.storageInfo.s3Region ?
            props.storageInfo.s3Region : '')
        setS3BucketName(props.storageInfo &&
            props.storageInfo.s3BucketName ?
            props.storageInfo.s3BucketName : '')
        setS3Path(props.storageInfo &&
            props.storageInfo.s3Path ?
            props.storageInfo.s3Path : '')
    }, [props.storageInfo, props.parentConfiguration]);



    useEffect(() => {
        let storageProperties = {}
        storageProperties.s3Keyid = s3Keyid
        storageProperties.s3KeySecret = s3KeySecret
        storageProperties.s3Region = s3Region
        storageProperties.s3BucketName = s3BucketName
        storageProperties.s3Path = s3Path
        props.setStorageProperties(storageProperties)
    }, [s3Keyid, s3KeySecret, s3Region, s3BucketName, s3Path])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <MuiThemeProvider theme={QualityTheme}>
            <AqtivaDialogEncryptString visible={visibleStorageToken}
                setVisible={setVisibleStorageToken}
                confirm={setS3KeySecret}
                sName={props.id && props.id + "sttkk"}
            />
            <div>
                <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                    <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                        <Typography variant="subtitle2"> Amazon S3 Configuration</Typography>

                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="s3Keyid"
                                    label="Key Id"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={s3Keyid}
                                    onChange={(event) => { setS3KeyId(event.target.value) }}
                                />} />

                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="s3KeySecret"
                                    label="Key Secret"
                                    type="password"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={s3KeySecret}
                                    onClick={(event) => { setVisibleStorageToken(true) }}
                                />} />
                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="s3Region"
                                    label="Region"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={s3Region}
                                    onChange={(event) => { setS3Region(event.target.value) }}
                                />} />
                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="s3BucketName"
                                    label="Bucket Name"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={s3BucketName}
                                    onChange={(event) => { setS3BucketName(event.target.value) }}
                                />} />
                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                            control={
                                <TextField style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                    id="s3BucketName"
                                    label="Path"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    value={s3Path}
                                    onChange={(event) => { setS3Path(event.target.value) }}
                                />} />
                    </div>
                </div>
            </div>
        </MuiThemeProvider >
    )
}