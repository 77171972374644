import quality from '../apis/quality';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
import qualityLocalHost from '../apis/qualityLocalHost';
//import qualityLocalHost5 from '../apis/qualityLocalHost5';

import { axiosHeader, dispatchError, dispatchInfo } from '../components/common/axios/axiosHelper';
import {
  FETCH_CONTROL_VALUES,
  EDIT_CONTROL_VALUES
} from './types'


export const fetchAllControlValues = () => {
    return async function (dispatch, getState) {
      await quality.get(`control/all/values`, {
        headers: axiosHeader()
      }).then(function (response) {

        dispatch({
          type: FETCH_CONTROL_VALUES,
          payload: response.data
        });
      })
        .catch(function (error) {
           dispatchError(dispatch,error)

        })


    };


};

export const updateControlValue = (id, value) => {
  return async function (dispatch, getState) {
    await quality.put(`control/value/${id}`,value, {
      headers: axiosHeader()
    }).then(function (response) {

      dispatch({
        type: EDIT_CONTROL_VALUES,
        payload: response.data
      });
      dispatchInfo(dispatch, "Cotrol value updated")
    })
      .catch(function (error) {
         dispatchError(dispatch,error)

      })


  }
}


export const saveControlValue = (name, value) => {
  return async function (dispatch, getState) {
    await quality.post(`control/value`,
    {
      name:name,
      value:value
    }, {
      headers: axiosHeader()
    }).then(function (response) {

      dispatch({
        type: EDIT_CONTROL_VALUES,
        payload: response.data
      });
      dispatchInfo(dispatch,"Control value updated")
    })
      .catch(function (error) {
         dispatchError(dispatch,error)

      })


  };


};


export const getControlValue = (key) => async (dispatch, getState) => { 

  var returnValue = undefined
  await quality.get('/control/values/'+key, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      let errorMessage = "Key "+key+" not found. Please contact your administrator to update Aqtiva configuration"
      dispatchError(dispatch, errorMessage)

      returnValue = undefined
    });


  return returnValue
}
export const getControlValueNoSnackbar = (key) => async (dispatch, getState) => { 

  var returnValue = undefined
  await quality.get('/control/values/'+key, {
    headers: axiosHeader()
  }).then(function (response) {
    
    returnValue = response.data

  })
    .catch(function (error) {
     
      returnValue = undefined
    });


  return returnValue
}



export async function getControlValueAsync (key)  {

  var returnValue = undefined
  await quality.get('/control/values/'+key, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      let errorMessage = "Key "+key+" not found. Please contact your administrator to update Aqtiva configuration"

      returnValue = undefined
    });


  return returnValue
}
