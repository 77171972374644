import React from 'react'
import { Typography, Grid, Tooltip } from '@material-ui/core'


import * as commonStyles from 'style/CommonStyles'

import { useTheme } from '@material-ui/core/styles';


function routeChange(props, route) {
    let path = route;
    props.history.push(path);
}

export default function PathNavigation(props) {

    const theme = useTheme();

    const variantText = 'subtitle1'

    const arrayPath = props.arrayPath ? props.arrayPath : []


    return (
        <div style={theme.title}>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={4}>
                    <div style={theme.elementAdjacent}>
                        <img alt="" style={{ height: '15px', width: '15px' }}
                            src={require('resources/icons/home_proc1_white.png')}
                            onClick={event => routeChange(props, '/home')} />

                        {/*   <FontAwesomeIcon icon={faHome} style={{ color: '#FFFFFF', fontSize: 18, marginTop: '5px', marginRight: '0.5%', cursor: 'pointer' }}
                            onClick={event => routeChange(props, '/home')} />*/}

                        <Typography variant={variantText} style={{ fontFamily: commonStyles.fontFamily }} >
                            /
                        </Typography>

                        {arrayPath.map(path => (
                             <div style={theme.elementAdjacent} key={path.text}>
                                <Typography variant={variantText} style={{ cursor: 'pointer', fontFamily:commonStyles.fontFamily }}
                                    onClick={event => routeChange(props, path.route)}> {path.text}</Typography>
                                <Typography variant={variantText} >/</Typography>
                                </div>
                           
                        ))}
                        <Typography variant={variantText} style={{ fontFamily: commonStyles.fontFamily }} >
                            {props.title}
                        </Typography>
                    </div>
                </Grid>
                {(props.onlyText === undefined || props.onlyText === false) && <Tooltip title='Home'>
                    <div style={{ marginLeft: '1.6vw' }}>
                        <img alt="" style={{ height: '30px', width: '30px' }}
                            src={require('resources/icons/home_proc1_white.png')}
                            onClick={event => routeChange(props, '/home')} />

                        {/*}   <FontAwesomeIcon icon={faHome} style={theme.titleIcon}
                            onClick={event => routeChange(props, '/home')} />*/}
                    </div>

                </Tooltip>
                }
                {(props.onlyText === undefined || props.onlyText === false) &&
                    <Tooltip title='KPI Governance'>
                        <div style={{ marginLeft: '1.6vw' }}>
                            <img alt="" style={{ height: '30px', width: '30px' }}
                                src={require('resources/icons/kpis_proc1_white.png')}
                                onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/drilldown/dashboard`)} />

                            {/*   <FontAwesomeIcon icon={faChartPie} style={theme.titleIcon}
                            onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/governance`)} /> */}
                        </div>
                    </Tooltip>
                }
                {/*(props.onlyText === undefined || props.onlyText === false) &&
                    <Tooltip title='Monitoring'>
                        <div style={{ marginLeft: '1.6vw' }}>
                            <img alt="" style={{ height: '30px', width: '30px' }}
                                src={require('resources/icons/monitoring_proc1.png')}
                                onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/dashboard`)} />
                            {/*  <FontAwesomeIcon icon={faChartBar} style={theme.titleIcon}
                            onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/dashboard`)} />}
                        </div>
                    </Tooltip>*/
                }

                {/*<Tooltip title='Simulations'>
                    <div style={{marginLeft:'1.6vw'}}>
                     
                    <FontAwesomeIcon icon={faPlayCircle} style={theme.titleIcon} 
                    onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/simulations`)}/>       
                    </div>
                        </Tooltip>*/}
                {(props.user && (props.user.role === 'ADMIN' || props.user.role === 'SUPER_ADMIN')) &&
                    (props.onlyText === undefined || props.onlyText === false) &&
                    <Tooltip title='Configuration'>
                        <div style={{ marginLeft: '1.6vw' }}>
                            <img alt="" style={{ height: '30px', width: '30px' }}
                                src={require('resources/icons/configuration_proc1_white.png')}
                                onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/configuration`)} />
                            {/* <FontAwesomeIcon icon={faTools} style={theme.titleIcon}
                                onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/configuration`)} />*/}
                        </div>
                    </Tooltip>
                }
                {(props.onlyText === undefined || props.onlyText === false) &&
                    <Tooltip title='Datasets'>
                        <div style={{ marginLeft: '1.6vw' }}>
                            <img alt="" style={{ height: '30px', width: '30px' }}
                                src={require('resources/icons/data_proc1_white.png')}
                                onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/datasets`)} />
                            {/*<FontAwesomeIcon icon={faDatabase} style={theme.titleIcon}
                            onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/datasets`)} />*/}
                        </div>
                    </Tooltip>
                }
                {(props.onlyText === undefined || props.onlyText === false) &&
                    <Tooltip title='Scheduled Jobs'>
                        <div style={{ marginLeft: '1.6vw' }}>
                            <img alt="" style={{ height: '30px', width: '30px' }}
                                src={require('resources/icons/scheduling_proc1_white.png')}
                                onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/schedules`)} />
                            {/* <FontAwesomeIcon icon={faClock} style={theme.titleIcon}
                            onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/schedules`)} />*/}
                        </div>
                    </Tooltip>
                }
                {(props.onlyText === undefined || props.onlyText === false) &&
                     false ===true && <Tooltip title='Profiling'>
                        <div style={{ marginLeft: '1.6vw' }}>
                            <img alt="" style={{ height: '30px', width: '30px' }}
                                src={require('resources/icons/data_profiling.png')}
                                onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/profiling`)} />
                            {/* <FontAwesomeIcon icon={faClock} style={theme.titleIcon}
                            onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/schedules`)} />*/}
                        </div>
                    </Tooltip>
                }
            </Grid>
        </div>


    )
}