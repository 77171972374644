import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import ScedulesTable from './../SchedulesTable'
import { Typography, CircularProgress } from '@material-ui/core'

import * as actions from '../../../actions'
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom'
import {isEmpty} from 'commonFunctions/commonFunctions'


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/simulation/repository '); 

export default function QualityShedulePageForm(props) {
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))
    const user = useSelector(store => store.user)
    const history = useHistory(); 

    const [existingSchedules, setExistingSchedules] = useState(undefined)
    const [environmentOptions, setEnvironmentOptions] = useState([])
    const [updateCounter, setUpdateCounter] = useState(0)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    function getSchedulesFromUserRole(){
        if(user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'){
            getAdminSchedules()
        }
        else{
            getUserSchedules()
        }
    }

    function getAdminSchedules(){
        dispatch(actions.getAllSchedulesFromUserId(user.id)).then(response => {
            setExistingSchedules(response)
            if (response && response.length > 0) {

                let usedEnvironments = response.map(schedule => schedule.environment)
                let filteredAllowedEnvironments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id, usedEnvironments)
                setEnvironmentOptions(filteredAllowedEnvironments)
                setLoading(false)
            }
            else {
                let filteredAllowedEnvironments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id, [])
                setEnvironmentOptions(filteredAllowedEnvironments)
                setLoading(false)
            }
        })
    }

    function getUserSchedules(){
        dispatch(actions.getSchedulesFromUserId(user.id)).then(response => {
            setExistingSchedules(response)
            if (response && response.length > 0) {

                let usedEnvironments = response.map(schedule => schedule.environment)
                let filteredAllowedEnvironments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id, usedEnvironments)
                setEnvironmentOptions(filteredAllowedEnvironments)
                setLoading(false)
            }
            else {
                let filteredAllowedEnvironments = filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id, [])
                setEnvironmentOptions(filteredAllowedEnvironments)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        if(isEmpty(user)){
            dispatch(actions.fetchUser(history))
        }
        getSchedulesFromUserRole()
       
    }, [updateCounter, user])// eslint-disable-line react-hooks/exhaustive-deps

    function isValidUser(users, userId) {
        if (users && users.length > 0) {
            if (users.filter(x => x.id === userId) && users.filter(x => x.id === userId).length > 0) {
                return true
            }
        }
        return false
    }

    function filterAllowedEnvironments(environments, userId, usedEnvironments) {
        if (environments && environments.length > 0) {
            let environmentsAvailable = environments.filter(env => !usedEnvironments.includes(env.environmentName))
            return environmentsAvailable.filter(x => !x.accessType || x.accessType === 'public_access' || (x.accessType === 'protected_access' && isValidUser(x.allowedUsers, userId)))
        }
        return environments
    }

  



    return (
       
            <div >
                {existingSchedules && existingSchedules.length > 0 && <ScedulesTable updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} userId={user.id} existingSchedules={existingSchedules} environmentOptions={environmentOptions} />}
                {loading && <Typography variant={'subtitle1'} style={{ textAlign: "center", marginTop: "20px" }}>
                    Loading table <CircularProgress style={{ width: '25px', height: '25px' }} />
                </Typography>}
            </div>
           
       

    );
}