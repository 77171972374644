import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


import { DataGrid } from '@material-ui/data-grid';


import { createColumnsFromJson, prepareData } from './ProfilingTableHelper'

import * as commonStyles from 'style/CommonStyles'

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: commonStyles.mainColor,
      color: '#FFFFFF'
    },
  },
});


export default function ProfilingTable(props) {
  const [pageSize, setPageSize] = React.useState(10);
  const classes = useStyles();
 
 /* React.useEffect(() => {
    const cols = createColumnsFromJson(props.data)
  }, [props.data])*/


  return (
    <div style={{ display: 'flex', height: '70vh' }}>
      <div style={{ flexGrow: 1 }} className={classes.root}>
        <DataGrid
          rows={prepareData(props.data)}
          columns={createColumnsFromJson(props.data)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10]}
          pagination
        />
      </div>
    </div>
  )
}