
import quality from '../apis/quality';
import { axiosHeader, dispatchError } from '../components/common/axios/axiosHelper';
//import qualityLocalHost from 'apis/qualityLocalHost';





export const getRecordsForSimulation = (idSimulation) => async (dispatch, getState) => {

  if (idSimulation) {
    var returnValue = { result: 'undefined' }


    await quality.get(`/records/simulation/id/${idSimulation}`, {
      headers: axiosHeader()
    }).then(function (response) {

      returnValue = { result: 'ok', data: response.data }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
      });


    return returnValue
  }
  return { result: 'ok', data: [] }
};


export const getQualityPointRawRecords = (startDate, endDate) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.get(`/dashboard/global/QP/${startDate}/${endDate}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getRuleRawRecords = (startDate, endDate) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.get(`/dashboard/global/RULE/${startDate}/${endDate}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getDSRawRecords = (startDate, endDate) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.get(`/dashboard/global/DS/${startDate}/${endDate}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getSimulationDAOForSimulationId = (simulationId) => async (dispatch, getState) => {

  if (simulationId) {
    var returnValue = { result: 'undefined' }


    await quality.get(`/dashboard/simulation/${simulationId}`, {
      headers: axiosHeader()
    }).then(function (response) {

      returnValue = { result: 'ok', data: response.data }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
      });


    return returnValue
  }
  return { result: 'ok', data: [] }
};


export const getDashboardGlobalMock = () => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }

  await quality.get(`/dashboard/global`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue


};




export const getDQI4Dimensions = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/dimensions`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};



export const getDQI4Dataformats = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/dataformat`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDQI4DataformatsAndDimensions = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/dataformat/dimension`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDQI4Projects = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }



  await quality.post(`/dashboard/2.0/dqi/project`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};
export const getDQI4ProjectsByUser = (filterObj, userId) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }



  await quality.post(`/dashboard/2.0/dqi/project/userId/${userId}`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getDQI4ProjectsMostRecent = (object) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/projects/recents`, object, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDQI4QualityPoints = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/qualitypoint`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getExecutions4Dimensions = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/executions/dimensions`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDefinitions4Dimensions = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/definitions/dimensions`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};



export const getExecutions4RuleType = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/executions/ruletype`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDefinitions4RuleType = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/definitions/ruletype`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getExecutionsAndDQI4RuleTypeAndDimension = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/ruletype/dimension`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getDQIByDay = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/day`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDQIByHour = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/hour`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};



export const getProjectIdExecutions = (filterObj) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/executions/projectid`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDQIProjectDetail = (filterObj, projectId) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/detail/project/${projectId}`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};


export const getDQIQpDetail = (filterObj, qpId, projectId) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/detail/qp/${qpId}/project/${projectId}`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getQpDetailExecutions = (filterObj, projectId) => async (dispatch, getState,) => {


  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/detail/qp/executions/${projectId}`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {
    //console.log('response.data', response.data)
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};
export const getDatasourceDetailExecutions = (filterObj, projectId) => async (dispatch, getState,) => {

  var returnValue = { result: 'undefined' }


  await quality.post(`/dashboard/2.0/dqi/detail/datasource/executions/${projectId}`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {
    //console.log('response.data', response.data)
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue

};

export const getDetailExecutions = (executionId) => async (dispatch, getState,) => {

  var returnValue = { result: 'undefined' }


  await quality.get(`/dashboard/2.0/dqi/detail/execution/${executionId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue


};

export const getDetailQIExecution = (filterObj, projectId) => async (dispatch, getState,) => {

  var returnValue = { result: 'undefined' }

  await quality.post(`/dashboard/2.0/dqi/detail/execution/${projectId}`, filterObj, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });

  return returnValue

}
