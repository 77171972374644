import React from 'react';
import CustomInput from "../../../common/CustomInput/CustomInput";

interface RulePrioritySelectorProps{
    rulePriority: number,
    setRulePriority: (priority: number) => (any)
}

interface IValidationElement{
    rule: any,
    description: string
}

interface IValidation {
    [key: string]: IValidationElement
}

export default function  RulePrioritySelector({rulePriority, setRulePriority}: RulePrioritySelectorProps){
    function validateInputs(states: any, type: string) {
        const priority = states.priority
        const validations: IValidation = {
            priority: {
                rule:  1 <= priority && priority <= 10,
                description: "Incorrect priority, must be from 1 to 10"
            }
        }
        return validations[type]
    }
    return <div style={{marginLeft: '10%', width: '100%'}}>
        <CustomInput
            labelText="Rule priority"
            id="rulePriority"
            formControlProps={{
                fullWidth: false,
                style: { width: '80%'}
            }}

            inputProps={{
                type: 'number',
                onChange: ((event: React.ChangeEvent<HTMLInputElement>)  => {
                    setRulePriority(parseInt(event.target.value))
                }),
                step: "1",
                min: "1",
                max: "10",
                value: rulePriority
            }}
            helperText={!validateInputs(
                { priority:rulePriority},
                "priority"
            ).rule ? validateInputs(
                { priority:rulePriority },
                "priority"
            ).description : ""}
            error={!validateInputs(
                { priority:rulePriority },
                "priority"
            ).rule}
        />
    </div>
}