import React from 'react';
import QualityDetailProjectToolBar from './QualityDetailProjectToolBar';
import QualityDetailProject from './QualityDetailProject';
import DialogSelectWorkItem from 'components/dialogs/DialogSelectWorkItem'
import DialogCreateQualityPoint from 'components/dialogs/DialogCreateQualityPoint'
import DialogCreateDatasource from 'components/dialogs/DialogCreateDataSource'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'

import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog'
import DialogCreateFolder from 'components/dialogs/DialogCreateFolder';

import DialogCreateRule from 'components/dialogs/DialogCreateRule'
import DialogRuleCreation from 'components/rule/DialogRuleCreation'

import DialogCreateCompoundRule from 'components/dialogs/DialogCreateCompoundRule'
import DialogCreateCompoundRuleNewDialog from 'components/rule/DialogCreateCompoundRuleNewDialog'

import DialogSimulation from 'components/dialogs/DialogSimulation'
import DialogCreateStreamSimulation from 'components/dialogs/DialogCreateStreamSimulation'


import * as projectHelper from './QualityDetailProject/QualityProjectHelper'
import { FETCH_ERROR } from 'actions/types'

import DialogRecommendedRules from 'components/dialogs/DialogRecommendedRules'

import ReactGA from 'react-ga';

import trackEvent from 'trackEvent'

import { AqtivaDialogDataRepository } from 'components/data/DataRepositoryShare/EntityDataRepository/AqtivaDialogDataRepository'
import { AqtivaDialogDataRepositoryRefactor } from 'components/data/DataRepositoryRefactorShare/EntityDataRepository/AqtivaDialogDataRepository'
import { TabsBar } from '../../../data/DataRepositoryShare/Tabs/index'
import { TabsBarRefactor } from 'components/data/DataRepositoryRefactorShare/Tabs/index'
import { faDatabase } from "@fortawesome/free-solid-svg-icons";

import * as helper from './QualityDetailProjectPageHelper'
import { useHistory } from 'react-router-dom'


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/project/');

export default function QualityProjectPage(props) {



    const dispatch = useDispatch();
    const history = useHistory();

    var projects = useSelector(store => Object.values(store.projects))
    var qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))
    const allNotifications = useSelector(store => (store.allNotifications))
    const [qualityConfiguration, setQualityConfiguration] = React.useState({})

    const user = useSelector(store => store.user)



    const [openSelectWI, setOpenSelectWI] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState()
    const [selectedTypeItem, setSelectedTypeItem] = React.useState()
    const [openQualityPointDialog, setOpenQualityPointDialog] = React.useState(false)
    const [openDatasourceDialog, setOpenDatasourceDialog] = React.useState(false)
    const [openCreateFolderDialog, setOpenCreateFolderDialog] = React.useState(false)
    const [openCreateRuleDialog, setOpenCreateRuleDialog] = React.useState(false)
    const [openCreateRuleDialogNewDialog, setOpenCreateRuleDialogNewDialog] = React.useState(false)
    const [openCreateCompoundRuleDialog, setOpenCreateCompoundRuleDialog] = React.useState(false)
    const [openCreateCompoundRuleNewDialog, setOpenCreateCompoundRuleNewDialog] = React.useState(false) //TODO: borrar cuando pase periodo de convivencia

    const [openSimulationDialog, setOpenSimulationDialog] = React.useState(false)
    const [openSimulationDialogStream, setOpenSimulationDialogStream] = React.useState(false)
    const [openEditRecommendedRulesDialog, setOpenEditRecommendedRulesDialog] = React.useState(false)
    const [runId, setRunId] = React.useState()
    //TODO: CHECK, to avoid warnings
    if (runId && openEditRecommendedRulesDialog) { }

    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState()
    const [selectedDatasource, setSelectedDatasource] = React.useState()
    const [selectedFolder, setSelectedFolder] = React.useState();


    //const [title, setTitle] = React.useState('')
    const [openConfirmDialogDeleteQP, setConfirmDialogDeleteQP] = React.useState(false)
    const [openConfirmDialogDeleteDS, setConfirmDialogDeleteDS] = React.useState(false)
    const [openConfirmDialogDeleteFolder, setConfirmDialogDeleteFolder] = React.useState(false)
    const [openConfirmDeleteRule, setConfirmDeleteRule] = React.useState(false)
    const [showDialogRuleRecommender, setShowDialogRuleRecommender] = React.useState(false)

    const [searchText, setSearchText] = React.useState("")

    const [ruleRecommenderQPId, setRuleREcommenderQPId] = React.useState()

    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')

    const [openExportDialog, setOpenExportDialog] = React.useState(false)

    //const [ruleDTOId, setRuleDTOId] = React.useState()

    // we extract params from the path in case we're redirected from a notification
    const [qpId, setQpId] = React.useState((props.qpId) ? props.qpId : undefined)

    //const paramRuleDTOId = (props.match && props.match.params && props.match.params.ruleDTOId) ? props.match.params.ruleDTOId : undefined
    const [paramRecommenderShowNotification, setParamRecommenderShowNotification] =
        React.useState((props.recommenderShow) ? props.recommenderShow : undefined)
    const [recommenderShowNotification, setRecommenderShowNotification] = React.useState(paramRecommenderShowNotification === 'true')
    const [datasetFromParams, setDatasetFromParams] = React.useState((props.dataformat) ? props.dataformat : undefined)

    const [anjanaIntegrable, setAnjanaIntegrable] = React.useState(false)
    const [anjanaId, setAnjanaId] = React.useState("")
    const [tableByRulesOrColumns, setTableByRulesOrColumns] = React.useState(false)
    const [scheduleHasBeenModified, setScheduleHasBeenModified] = React.useState(false);
    const [editable, setEditable] = React.useState(false);

    const [workingProject, setWorkingProject] = React.useState({})

    React.useEffect(() => {
        setWorkingProject(helper.getProject(searchText, projects, props.projectId))
    }, [props.projectId, searchText, JSON.stringify(projects)])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setSearchText(props.searchText)
    }, [props.searchText])// eslint-disable-line react-hooks/exhaustive-deps




    React.useEffect(() => {
        setQpId((props.qpId) ? props.qpId : undefined)
        setParamRecommenderShowNotification((props.recommenderShow) ? props.recommenderShow : undefined)
        setRecommenderShowNotification(((props.recommenderShow) ? props.recommenderShow : undefined) === 'true')
        const notificationId = (props.notificationId) ? props.notificationId : undefined
        if (notificationId) {
            setDatasetFromParams(allNotifications[notificationId] && allNotifications[notificationId].params ?
                allNotifications[notificationId].params.dataformat : undefined)
        }

    }, [props.match, allNotifications, props.qpId, props.recommenderShow, props.notificationId]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchProjects(user.id))
            dispatch(actions.fetchConfigurationByUserId(user.id))
        }

    }, [user])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true') {
            if (workingProject) {
                setAnjanaIntegrable(workingProject.anjanaIntegrable)
            }
        }
    }, [workingProject.name, workingProject.anjanaIntegrable])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        dispatch(actions.fetchUser(history))
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    /*React.useEffect(() => {

        if (projects && workingProject) {
            if (workingProject) {
                setTitle(workingProject.name)
            }
        }

    }, [JSON.stringify(projects), workingProject]);*/ // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        setQualityConfiguration(qualityConfigurationSelector)
    }, [qualityConfigurationSelector]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (qpId && recommenderShowNotification) {
            setProjectValuesFromNotification(qpId, recommenderShowNotification)
        }
    }, [qpId, recommenderShowNotification]);// eslint-disable-line react-hooks/exhaustive-deps

   

    const confirmDialogDeleteQP = () => {
        dispatch(actions.deleteQualityPipe(workingProject._id, selectedItem._id, user.id))
        setConfirmDialogDeleteQP(false)

    }
    const cancelDialogDeleteQP = () => {
        setConfirmDialogDeleteQP(false)
    }

    const confirmDialogDeleteDatasource = () => {
        const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, selectedItem._id)

        dispatch(actions.deleteDatasource(workingProject._id, qualityPoint._id, selectedItem._id))
        setConfirmDialogDeleteDS(false)
        setSelectedItem(undefined)

    }
    const cancelDialogDeleteDatasource = () => {
        setConfirmDialogDeleteDS(false)
    }

    const confirmDialogDeleteFolder = () => {
        let parentName = selectedItem.parentName ? selectedItem.parentName : selectedItem.name

        dispatch(actions.deleteFolder(workingProject._id, selectedItem.datasourceId, selectedItem.level, selectedItem.name, parentName, user.id))
        setConfirmDialogDeleteFolder(false)

    }
    const cancelDialogDeleteFolder = () => {
        setConfirmDialogDeleteFolder(false)
    }

    const confirmDialogDeleteRule = () => {
        let ret = helper.getDSAndQPFromSearchTextAndSelectdItem(searchText, selectedItem, projects, props.projectId)
        if (ret.ds !== undefined && ret.qp !== undefined) {
            dispatch(actions.deleteRule(ret.project._id, ret.qp._id, ret.ds._id, selectedItem._id, user.id))
        }

        setConfirmDeleteRule(false)
    }
    const cancelDialogDeleteRule = () => {
        setConfirmDeleteRule(false)
    }




    /**
     * TODO: TO DELETE TEMPORAL METHOD WHILE RULE DIALOG AND NEW DIALOG LIVE TOGETHER
     * @param {*} value 
     * @param {*} type 
     * @returns 
     */
    function setSelectedItemAndOpenNewDialog(value, type) {
        // Aquí va la lógica para mostar una ventana o otra
        setSelectedItem(value)
        setSelectedTypeItem(type)
        var selectedQualityPoint = undefined;
        var selectedDatasource = undefined;
        switch (type) {
            case 'Qualitypoint':
                setOpenQualityPointDialog(true);
                break;
            case 'Datasource':
                selectedQualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value._id)
                setSelectedQualityPoint(selectedQualityPoint)
                setOpenDatasourceDialog(true)
                break;
            case 'Rule':
                //abrimos dialogo de quality point en modo edicion
                selectedQualityPoint = projectHelper.findQualityPointFromRuleId(workingProject, value._id)
                selectedDatasource = projectHelper.findDatasourceFromRuleId(workingProject, value._id)
                setSelectedQualityPoint(selectedQualityPoint)
                setSelectedDatasource(selectedDatasource)
                if (value.type === "compound") {
                    setOpenCreateCompoundRuleNewDialog(true)

                }
                else {
                    setOpenCreateRuleDialogNewDialog(true)

                }

                break;
            case 'Folder':
                selectedQualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value.datasourceId)
                selectedDatasource = projectHelper.findDatasourceFromDatasourceId(workingProject, value.datasourceId)
                setSelectedQualityPoint(selectedQualityPoint)
                setSelectedDatasource(selectedDatasource)
                setSelectedItem(value)
                setSelectedTypeItem(type)
                setEditable(true)
                setOpenCreateFolderDialog(true)
                break
            default:
                dispatch({
                    type: FETCH_ERROR,
                    payload: `No Item found for type ${type}`
                });
                break;
        }


    }

    function setSelectedItemAndOpen(value, type) {
        // Aquí va la lógica para mostar una ventana o otra
        setSelectedItem(value)
        setSelectedTypeItem(type)
        var selectedQualityPoint = undefined;
        var selectedDatasource = undefined;
        switch (type) {
            case 'Qualitypoint':
                setOpenQualityPointDialog(true);
                break;
            case 'Datasource':
                selectedQualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value._id)
                setSelectedQualityPoint(selectedQualityPoint)
                setOpenDatasourceDialog(true)
                break;
            case 'Rule':
                //abrimos dialogo de quality point en modo edicion
                selectedQualityPoint = projectHelper.findQualityPointFromRuleId(workingProject, value._id)
                selectedDatasource = projectHelper.findDatasourceFromRuleId(workingProject, value._id)
                setSelectedQualityPoint(selectedQualityPoint)
                setSelectedDatasource(selectedDatasource)
                if (value.type === "compound") {
                    setOpenCreateCompoundRuleDialog(true)

                }
                else {
                    setOpenCreateRuleDialog(true)

                }

                break;
            case 'Folder':
                selectedQualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value.datasourceId)
                selectedDatasource = projectHelper.findDatasourceFromDatasourceId(workingProject, value.datasourceId)
                setSelectedQualityPoint(selectedQualityPoint)
                setSelectedDatasource(selectedDatasource)
                setSelectedItem(value)
                setSelectedTypeItem(type)
                setEditable(true)
                setOpenCreateFolderDialog(true)
                break
            default:
                dispatch({
                    type: FETCH_ERROR,
                    payload: `No Item found for type ${type}`
                });
                break;
        }


    }

    function deleteItem(value, type) {

        setSelectedItem(value)
        setSelectedTypeItem(type)
        switch (type) {
            case 'Qualitypoint':
                setConfirmDialogDeleteQP(true);
                break;
            case 'Datasource':
                setConfirmDialogDeleteDS(true);
                break;
            case 'Folder':
                setConfirmDialogDeleteFolder(true)
                break
            case 'Rule':
                setConfirmDeleteRule(true)
                break
            default:
                break;
        }

    }



    function clearSelectedItem() {
        //setSelectedItem(undefined)
        //setSelectedTypeItem(undefined)
    }

    function editQualityPipe(projectId, qualityPoint, userId) {
        dispatch(actions.editQualityPipe(projectId, qualityPoint, userId))
    }

    function editDatasource(projectId, qualitypointId, datasource, datasourceId, userId) {
        dispatch(actions.editDatasource(projectId, qualitypointId, datasource, datasourceId, userId))
    }

    function editRule(projectId, qualitypointId, datasourceId, rule, userId) {
        dispatch(actions.editRule(projectId, qualitypointId, datasourceId, rule, userId))
    }

    const updateSearchText = (tx) => {
        setSearchText(tx)
    }
    function addDatasource(value, type) {
        setSelectedQualityPoint(value)
        setSelectedItem(undefined)
        setSelectedTypeItem(type)

        if (type === 'Qualitypoint') {
            //abrimos dialogo de quality point en modo edicion
            setOpenDatasourceDialog(true)
        }
    }

    function handleRuleRecommender(value, type) {
        setRuleREcommenderQPId(value._id)
        setShowDialogRuleRecommender(true)
    }

    function addFolder(value, type) {

        if (type === "Folder") {
            const datasource = projectHelper.findDatasourceFromDatasourceId(workingProject, value.datasourceId)
            setSelectedDatasource(datasource)
        }
        if (type === "Datasource") {
            setSelectedDatasource(value)
        }

        setSelectedItem(value)
        setSelectedTypeItem(type)
        setOpenCreateFolderDialog(true)
    }

    function addCompoundRule(value, type) {
        //TODO:

        if (type === 'Datasource') {
            //abrimos dialogo de quality point en modo edicion
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value._id)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateCompoundRuleDialog(true)
        }

        if (type === 'Folder') {
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value.datasourceId)
            const datasource = projectHelper.findDatasourceFromDatasourceId(workingProject, value.datasourceId)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(datasource)
            setSelectedFolder(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateCompoundRuleDialog(true)
        }
    }

    function addCompoundRuleNewDialog(value, type) {
        //TODO: unificar cuando pase el periodo de convivencia con modelo antiguo

        if (type === 'Datasource') {
            //abrimos dialogo de quality point en modo edicion
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value._id)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateCompoundRuleNewDialog(true)
        }

        if (type === 'Folder') {
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value.datasourceId)
            const datasource = projectHelper.findDatasourceFromDatasourceId(workingProject, value.datasourceId)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(datasource)
            setSelectedFolder(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateCompoundRuleNewDialog(true)
        }
    }

    function addRule(value, type) {

        if (type === 'Datasource') {
            //abrimos dialogo de quality point en modo edicion
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value._id)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateRuleDialog(true)
        }

        if (type === 'Folder') {
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value.datasourceId)
            const datasource = projectHelper.findDatasourceFromDatasourceId(workingProject, value.datasourceId)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(datasource)
            setSelectedFolder(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateRuleDialog(true)
        }
    }

    function addRuleNewDialog(value, type) {

        if (type === 'Datasource') {
            //abrimos dialogo de quality point en modo edicion
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value._id)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateRuleDialogNewDialog(true)
        }

        if (type === 'Folder') {
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(workingProject, value.datasourceId)
            const datasource = projectHelper.findDatasourceFromDatasourceId(workingProject, value.datasourceId)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(datasource)
            setSelectedFolder(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateRuleDialogNewDialog(true)
        }
    }

    function onClickOpenDialogSimulation(data) {
        trackEvent('Detail Project Page', 'Open Dialog Simulation');
        setSelectedQualityPoint(data)
        if (data && data.runningType !== 'streaming_lambda') {
            trackEvent('Detail Project Page', 'Open Dialog Simulation Batch');
            setOpenSimulationDialog(true)
        }
        else {
            trackEvent('Detail Project Page', 'Open Dialog Simulation Stream');
            setOpenSimulationDialogStream(true)
        }


    }

    function onClickSimulate() {

    }

    function onClickExport() {
        setOpenExportDialog(true)
    }
    function onClickCloseSimulation() {
        setOpenSimulationDialog(false)
    }

    function onClickCloseSimulationStream() {
        setOpenSimulationDialogStream(false)
    }


    function setOpenCreateRuleDialogCallback(event) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenCreateRuleDialog(event)
    }




    function setOpenCreateFolderDialogCallback(open) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenCreateFolderDialog(open)
    }

    function setOpenDatasourceDialogCallback(open) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenDatasourceDialog(open)
    }

    function setOpenQualityPointDialogCallback(open) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenQualityPointDialog(open)
    }

    // here we set the values extracted from the path to their corresponding fields.
    //this function is called in the third useEffect.
    function setProjectValuesFromNotification(qpId, recommenderShowNotification, paramRuleDTOId) {
        setShowDialogRuleRecommender(recommenderShowNotification)
        setRuleREcommenderQPId(qpId)
        //setRuleDTOId(paramRuleDTOId)
    }






    function addDialogs() {
        return (
            <div>
                {openExportDialog === true && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' &&
                    <AqtivaDialogDataRepository
                        dialog={openExportDialog}
                        setDialog={setOpenExportDialog}
                        title="Data Repository"
                        titleIcon={faDatabase}
                        confirmCallback={(event) => console.log("I am clicking here")}

                    >
                        <TabsBar user={user} selectedProjectId={workingProject._id} />
                    </AqtivaDialogDataRepository>}

                {openExportDialog === true && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true' && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'false' &&
                    <AqtivaDialogDataRepositoryRefactor
                        dialog={openExportDialog}
                        setDialog={setOpenExportDialog}
                        title="Data Repository"
                        titleIcon={faDatabase}
                        confirmCallback={(event) => console.log("I am clicking here")}

                    >
                        <TabsBarRefactor user={user} selectedProjectId={workingProject._id} />
                    </AqtivaDialogDataRepositoryRefactor>}

                {showDialogRuleRecommender &&
                    <DialogRecommendedRules
                        open={showDialogRuleRecommender} setClose={setShowDialogRuleRecommender}
                        openEditRecommendedRulesDialog={setOpenEditRecommendedRulesDialog}
                        userId={user.id}
                        configuration={qualityConfiguration}
                        setRunId={setRunId}
                        projectId={workingProject ? workingProject._id : -1}
                        qpId={ruleRecommenderQPId}
                        project={workingProject}
                        recommenderShowNotification={recommenderShowNotification}
                        datasetFromParams={datasetFromParams}
                        history={props.history}
                    //ruleDTOId={ruleDTOId}
                    />}

                {/* {openConfirmDialogDeleteQP && <ConfirmDialog
                    id='confirmDialogDelete'
                    confirmAction={confirmDialogDeleteQP}
                    cancelAction={cancelDialogDeleteQP}
                    title={`Confirm Delete Quality point ${selectedItem.name}`}
                    open={openConfirmDialogDeleteQP}
                />} */}
                {openConfirmDialogDeleteQP && <ConfirmDeleteDialog
                    element={selectedItem}
                    elementType={"quality point"}
                    id='confirmDialogDelete'
                    confirmAction={confirmDialogDeleteQP}
                    cancelAction={cancelDialogDeleteQP}
                    title={`Confirm Delete Quality point ${selectedItem.name}`}
                    open={openConfirmDialogDeleteQP}
                />}

                {openConfirmDialogDeleteFolder && helper.generateDialog('confirmDialogDeleteFolder',
                    confirmDialogDeleteFolder, cancelDialogDeleteFolder,
                    `Confirm Delete Folder ${selectedItem.name}`, openConfirmDialogDeleteFolder)}

                {openConfirmDialogDeleteDS && helper.generateDialog('confirmDialogDeleteFDS',
                    confirmDialogDeleteDatasource, cancelDialogDeleteDatasource,
                    `Confirm Delete Datasource ${selectedItem.name}`, openConfirmDialogDeleteDS)}

                {openConfirmDeleteRule && helper.generateDialog('confirmDialogDeleteRule',
                    confirmDialogDeleteRule, cancelDialogDeleteRule,
                    `Confirm Delete Rule ${selectedItem.name}`, openConfirmDeleteRule)}

                {/* {openConfirmDialogDeleteDS && <ConfirmDialog
                    id='confirmDialogDeleteFDS'
                    confirmAction={confirmDialogDeleteDatasource}
                    cancelAction={cancelDialogDeleteDatasource}
                    title={`Confirm Delete Datasource ${selectedItem.name}`}
                    open={openConfirmDialogDeleteDS}
                />} */}
                {openConfirmDialogDeleteDS && <ConfirmDeleteDialog
                    element={selectedItem}
                    elementType={"datasource"}
                    id='confirmDialogDeleteFDS'
                    confirmAction={confirmDialogDeleteDatasource}
                    cancelAction={cancelDialogDeleteDatasource}
                    title={`Confirm Delete Datasource ${selectedItem.name}`}
                    open={openConfirmDialogDeleteDS}
                />}

                <DialogSelectWorkItem open={openSelectWI} setClose={setOpenSelectWI}
                    openQPDialog={setOpenQualityPointDialog}
                    openDSDialog={setOpenDatasourceDialogCallback}
                    openRuleDialog={setOpenCreateRuleDialogCallback}
                />
                {openQualityPointDialog && <DialogCreateQualityPoint
                    configuration={qualityConfigurationSelector}
                    open={openQualityPointDialog} setClose={setOpenQualityPointDialogCallback} idProject={props.projectId}
                    qualityPoint={selectedItem} clearSelectedItem={clearSelectedItem}
                    project={workingProject} scheduleHasBeenModified={scheduleHasBeenModified}
                    setScheduleHasBeenModified={setScheduleHasBeenModified} />}
                {openDatasourceDialog && <DialogCreateDatasource open={openDatasourceDialog}
                    setClose={setOpenDatasourceDialogCallback}
                    project={workingProject}
                    configuration={qualityConfigurationSelector}
                    datasource={selectedItem} qualityPoint={selectedQualityPoint} clearSelectedItem={clearSelectedItem} scheduleHasBeenModified={scheduleHasBeenModified}
                    setScheduleHasBeenModified={setScheduleHasBeenModified} />}
                {openCreateFolderDialog && <DialogCreateFolder userId={user.id} parent={selectedItem} parentType={selectedTypeItem} project={workingProject} datasource={selectedDatasource}
                    open={openCreateFolderDialog} setClose={setOpenCreateFolderDialogCallback} editable={editable} setEditable={setEditable} folder={selectedTypeItem === 'Folder' ? selectedItem : undefined}
                />}

                {/* Conditional render based on tableByRulesOrColumns */}

                
                <DialogRuleCreation rule={selectedItem} project={workingProject}
                    open={openCreateRuleDialogNewDialog} setClose={setOpenCreateRuleDialogNewDialog}
                    datasource={selectedDatasource}
                    qualityPoint={selectedQualityPoint}
                    folder={selectedFolder}
                />

                
                <DialogCreateRule rule={selectedItem} project={workingProject}
                    open={openCreateRuleDialog} setClose={setOpenCreateRuleDialog}
                    datasource={selectedDatasource}
                    qualityPoint={selectedQualityPoint}
                    folder={selectedFolder}
                />

                {openCreateCompoundRuleDialog/*  && !tableByRulesOrColumns */ &&
                    <DialogCreateCompoundRule rule={selectedItem} project={workingProject}
                        open={openCreateRuleDialog} setClose={setOpenCreateRuleDialog}
                        datasource={selectedDatasource}
                        qualityPoint={selectedQualityPoint}
                        folder={selectedFolder}
                        visible={openCreateCompoundRuleDialog}
                        setVisible={setOpenCreateCompoundRuleDialog}
                    />}

                {openCreateCompoundRuleNewDialog/*  && !tableByRulesOrColumns */ &&
                    <DialogCreateCompoundRuleNewDialog rule={selectedItem} project={workingProject}
                        open={openCreateRuleDialogNewDialog} setClose={setOpenCreateRuleDialogNewDialog}
                        datasource={selectedDatasource}
                        qualityPoint={selectedQualityPoint}
                        folder={selectedFolder}
                        visible={openCreateCompoundRuleNewDialog}
                        setVisible={setOpenCreateCompoundRuleNewDialog}
                    />}

                {openSimulationDialogStream && <DialogCreateStreamSimulation
                    userId={user.id}
                    configuration={qualityConfigurationSelector}
                    history={props.history}
                    visible={openSimulationDialogStream}
                    setVisible={setOpenSimulationDialogStream}
                    onClickSimulate={onClickSimulate}
                    onClose={onClickCloseSimulationStream}
                    onHide={onClickCloseSimulationStream}
                    selectedQualityPoint={selectedQualityPoint}
                    project={workingProject}
                    currentUser={user}
                />}
                {openSimulationDialog && <DialogSimulation
                    userId={user.id}
                    configuration={qualityConfigurationSelector}
                    history={props.history}
                    visible={openSimulationDialog}
                    onClickSimulate={onClickSimulate}
                    onClose={onClickCloseSimulation}
                    onHide={onClickCloseSimulation}
                    selectedQualityPoint={selectedQualityPoint}
                    project={workingProject}
                    currentUser={user} />}
            </div>
        )
    }
    return (
        <div  >

            <div style={{ 'padding': '10px' }}>
                {addDialogs()}
                <div style={{ marginTop: '0px' }}>


                    <QualityDetailProjectToolBar openWIDialog={setOpenSelectWI}
                        openRuleRecommenderDialog={setShowDialogRuleRecommender}
                        updateText={updateSearchText}
                        qualityConfiguration={qualityConfiguration}
                        project={workingProject === undefined ? undefined : workingProject}
                        history={props.history}
                        setSelectedEnvironment={setSelectedEnvironment}
                        user={user}
                        onClickExport={onClickExport}
                        tableByRulesOrColumns={tableByRulesOrColumns}
                        setTableByRulesOrColumns={setTableByRulesOrColumns}
                        anjanaIntegrable={anjanaIntegrable}
                        setAnjanaIntegrable={setAnjanaIntegrable}
                        anjanaId={anjanaId}
                        setAnjanaId={setAnjanaId}
                    />
                </div>
                <QualityDetailProject project={workingProject === undefined ? undefined : workingProject}
                    userId={user.id}
                    setSelectedItem={setSelectedItemAndOpen}
                    setSelectedItemNewDialog={setSelectedItemAndOpenNewDialog}
                    editQualityPipe={editQualityPipe}
                    editDatasource={editDatasource}
                    editRule={editRule}
                    addDatasource={addDatasource}
                    addFolder={addFolder}
                    addRule={addRule}
                    addRuleNewDialog={addRuleNewDialog}
                    addCompoundRule={addCompoundRule}
                    addCompoundRuleNewDialog={addCompoundRuleNewDialog}
                    deleteItem={deleteItem}
                    onClickOpenDialogSimulation={onClickOpenDialogSimulation}
                    qualityConfiguration={qualityConfiguration}
                    handleRuleRecommender={handleRuleRecommender}
                    searchText={searchText}
                    selectedEnvironment={selectedEnvironment}
                    tableByRulesOrColumns={tableByRulesOrColumns}
                    scheduleHasBeenModified={scheduleHasBeenModified}
                    newRuleFormFlag = {props.newRuleFormFlag}
                />
            </div>
        </div>

    );
}