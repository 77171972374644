import React from 'react'

import AqtivaDialog from '../common/AqtivaDialog'
import {  Typography,  CircularProgress } from '@material-ui/core'

import trackEvent from '../../trackEvent'

export default function ConfirmDeleteDialog(props) {
    
    //const [loading, setLoading] = useState(false)
    const loading = false



    return (
        <AqtivaDialog
            visible={props.open}
            dialog={props.open}
            fullWidth={true}
            maxWidth={'sm'}
            title={props.title}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={event => {
                trackEvent('Confirm', 'Cancel Button'); props.cancelAction(event)
            }}
            confirmText={'Confirm'}
            confirmCallback={event => {
                trackEvent('Confirm', 'Confirm Button'); props.confirmAction(event)
            }}
        >
            {!loading && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                {`Are you sure you want to delete this ${props.elementType}?`}
            </Typography>}
            {!loading && props.element.hasScheduleFlag && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                {`This ${props.elementType} has some scheduled jobs asociated to it, if you delete it, all asociated jobs will be terminated.`}
            </Typography>}
            {loading && props.element.hasScheduleFlag && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                {` Deleting ${props.elementType} and all asociated scheduled jobs...`} <CircularProgress style={{ width: '15px', height: '15px' }} />
            </Typography>}
        </AqtivaDialog>
    )
}
