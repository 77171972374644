import React from 'react';
import {makeStyles,TextField} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { styles, QualityTheme } from 'style/CustomTheme'


export default function FormTimeliness (props) {

  const classes = makeStyles(styles(QualityTheme));


  const [timelinessValue, setTimelinessValue] = React.useState('')
  const [timeUnits, setTimeUnits] = React.useState('')

  React.useEffect(() => {
    createSpecific() 
}, [timelinessValue,timeUnits]);// eslint-disable-line react-hooks/exhaustive-deps

function createSpecific(){
    //creamos el objeto de regla especifica
    const specific = {
        subtype:'time_diff',
        timeValue: timelinessValue,
        timeUnits: timeUnits
    }


    props.setSpecific(specific)
   
}

        
        return (
            <div>
                   <Typography variant='caption'> Time Difference</Typography>
                <div>
                    <TextField type="number" value={timelinessValue} autoComplete='off'
                        style={{ width: '15%', marginRight:'5%' }}
                        margin="dense"
                        label="Time value"
                       id='timelinessValue' 
                        onChange={e=>setTimelinessValue(e.target.value)} />
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-simple"> Unit</InputLabel>
                <Select
                      value={timeUnits}
                      onChange={(e)=>setTimeUnits(e.target.value)}
                      style={{ width: '300%',marginTop:'50%' }}
                       >
                      
                      <MenuItem value={'seconds'}>Secs</MenuItem>
                      <MenuItem value={'minutes'}>Mins</MenuItem>
                      <MenuItem value={'hours'}>Hours</MenuItem>
                      <MenuItem value={'days'}>Days</MenuItem>
          </Select>
        </FormControl>                       
                </div>
                        
            </div>
        );
    
}
