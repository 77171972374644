
import React from 'react';
import { createStyles, FormControl, InputLabel, makeStyles, NativeSelect, TextField, Theme, Typography } from '@material-ui/core';
import { ILogicColumnCondition, ILogicSqlExpression } from 'interfaces/LogicCondition';
import { IColumn } from '../../../../interfaces/RuleInterfaces';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import AqtivaDialog from 'components/common/AqtivaDialog';
import ColumnConditionCreation from './ColumnConditionCreation';
import { ILogicConditionParameters } from '../../../../interfaces/LogicCondition';


type IProps = {
  edit: boolean,
  closeEdit: () => (void),
  expresion: ILogicSqlExpression,
  editExpresion: (value: ILogicSqlExpression, ix: number) => any,
  ixOp: number,
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);


export default function DialogEditSqlExpresion(props: IProps) {
  const classes = useStyles();

  const [tmpExpresion, setTmpExpresion] = React.useState<ILogicSqlExpression>(props.expresion)


  React.useEffect(() => {
    setTmpExpresion(props.expresion)
  }, [props.expresion])

  function setSelectedJoin(join: string) {
    var newExpresion: ILogicSqlExpression = JSON.parse(JSON.stringify(tmpExpresion))
    newExpresion.selectedJoin = join
    setTmpExpresion(newExpresion)
  }

  function editExpresion(expresion: string) {
    var newExpresion: ILogicSqlExpression = JSON.parse(JSON.stringify(tmpExpresion))
    newExpresion.sqlExpression = expresion
    setTmpExpresion(newExpresion)
  }

  function editCallback() {
    props.editExpresion(tmpExpresion, props.ixOp)
    props.closeEdit()
  }


  return (
    <AqtivaDialog visible={props.edit}
      title={`Add sql expresion`}
      maxWidth='lg'
      fullWidth={true}
      titleIcon={faKey}
      cancelText={'Close'}
      showConfirmButton={true}
      confirmCallback={editCallback}
      cancelCallback={props.closeEdit}
    >
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-helper">Join</InputLabel>
        <NativeSelect
          value={tmpExpresion && tmpExpresion.selectedJoin ? tmpExpresion.selectedJoin : 'and'}
          onChange={event => setSelectedJoin(event.target.value)}
          inputProps={{
            name: 'age',
            id: 'age-native-helper',
          }}
        >

          <option value={'and'}>and</option>
          <option value={'or'}>or</option>
          <option value={'xor'}>xor</option>
          <option value={'xand'}>xand</option>
        </NativeSelect>
      </FormControl>
      <FormControl style={{ width: '70%', marginTop: '3.5px' }}>
        <TextField type='string'
          value={tmpExpresion && tmpExpresion.sqlExpression}
          style={{ width: '70%' }}
          onChange={event => editExpresion(event.target.value)}
          label={<Typography style={{ fontSize: 13 }}>SQL Expresion</Typography>}
          margin="dense"
        />
      </FormControl>

    </AqtivaDialog>

  )

}