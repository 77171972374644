import React from 'react';


import { Grid, Typography } from '@material-ui/core';





export default function SummaryOnPremiseSQL(props) {

    return (

        <Grid container spacing={3}>
            {props.displayName && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Display name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.displayName}</Typography>
                </Grid>
            </>}
            {props.connectionObjectOnPremise && props.connectionObjectOnPremise.server && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Server:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectOnPremise && props.connectionObjectOnPremise.server}</Typography>
                </Grid>
            </>}
            {props.connectionObjectOnPremise && props.connectionObjectOnPremise.port && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Port:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectOnPremise && props.connectionObjectOnPremise.port}</Typography>
                </Grid>
            </>}
            {props.connectionObjectOnPremise && props.connectionObjectOnPremise.database && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Database:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectOnPremise && props.connectionObjectOnPremise.database}</Typography>
                </Grid>
            </>}
            {props.connectionObjectOnPremise && props.connectionObjectOnPremise.schema && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Schema:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectOnPremise && props.connectionObjectOnPremise.schema}</Typography>
                </Grid>
            </>}
            {props.connectionObjectOnPremise && props.connectionObjectOnPremise.user && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>User:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectOnPremise && props.connectionObjectOnPremise.user}</Typography>
                </Grid>
            </>}
            {props.connectionObjectOnPremise && props.connectionObjectOnPremise.pwd && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Encrypted Password:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectOnPremise && props.connectionObjectOnPremise.pwd}</Typography>
                </Grid>
            </>}
        </Grid>
    )
}