import React from 'react'
import MaterialTable from '@material-table/core'
import * as commonStyles from 'style/CommonStyles'
import LollipopChart from '../Charts/LollipopChart'
import BarChartSmall from '../Charts/BarChartSmall'
import RulesScoreChartSmall from '../Charts/RulesScoreChartSmall'
import OpenInBrowserIcon from '@material-ui/icons/SystemUpdateAlt';
import { Tooltip, Typography } from '@material-ui/core'
import { LanguageContext } from 'language/LanguageContext';
import {MoneyFormat} from 'commonFunctions/commonFunctions'

export default function TableLlolipopCharts(props) {

  const [data, setData] = React.useState([])

  const chartTableHeight = '60px'
  const chartTableWidth = '120%'
  const chartTableMarginLeft = '-15%'
  const chartTableMarginBottom = '-20px'
  const languageContext = React.useContext(LanguageContext)

  React.useEffect(() => {

    if (props.data) {
      setData(props.data)
    }

  }, [props.data]) 

  
  function handleDrillDownElement(rowData) {
    props.click(rowData)
  }

  function getZoomInRender(rowData) {
    return (
      <Tooltip title={<Typography style={{ fontSize: '12px' }} >{languageContext.dictionary['drillDown']}</Typography>}>
        <OpenInBrowserIcon style={{ cursor: 'pointer' }}
          onClick={event => {
            handleDrillDownElement(rowData)
          }} />
      </Tooltip>
    )
  }


  return (
    <MaterialTable

      columns={[
        { title: '', width: '2%', hidden: !props.showIcons ? true : false, render: rowData => getZoomInRender(rowData) },
        {
          title: 'Name', width: '4%', render: rowData => {
            if (rowData.name.length > 16) {
              return (
                <Tooltip title={<Typography style={{ fontSize: '12px' }} >{rowData.name}</Typography>}>
                  <span>{rowData.name.substring(0, 16)}</span>
                </Tooltip>
              )
            } else {
              return (<span>{rowData.name}</span>)
            }
          }
        },
        {
          title: (
            <Tooltip title={<Typography style={{ fontSize: '12px' }} >{languageContext.dictionary['score']} </Typography>}>
              <span>Score</span>
            </Tooltip>
          ), width: '20%',

          render: rowData => {
            let score = Number(((rowData.sumNumOk / (rowData.sumNumOk + rowData.sumNumKo)) * 100).toFixed(0))
            let scoreKo = -Number(((rowData.sumNumKo / (rowData.sumNumOk + rowData.sumNumKo)) * 100).toFixed(0))

            return (
              <RulesScoreChartSmall score={score} scoreKo={scoreKo} chart={'score' + rowData.name} height={chartTableHeight} width={chartTableWidth} marginLeft={chartTableMarginLeft}
                marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        },
        {
          title: (
            <Tooltip title={<Typography style={{ fontSize: '12px' }} >{languageContext.dictionary['scoreTrends']}</Typography>}>
              <span>Score Trends</span>
            </Tooltip>
          ),
          width: '20%',
          render: rowData => {
            return (
              <LollipopChart data={rowData.historyList} value={'score'} category={'fecha'} chart={'chartScore' + rowData.id} height={chartTableHeight}
                width={chartTableWidth} marginLeft={chartTableMarginLeft} marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        },
        {
          title: 'nºExecs',
          width: '1%',
          render: rowData => {
            return (
              <span>{rowData.historyList && rowData.historyList.length > 0 ? rowData.historyList.length : 0}</span>
            )

          }

        },
        {
          title: 'Rows',
          width: '8%',
          render: rowData => {
            return (
              <span>{MoneyFormat(rowData.sumNumIn)}</span>
            )

          }

        },
        {
          title: (
            <Tooltip title={<Typography style={{ fontSize: '12px' }} >{languageContext.dictionary['rowTrends']} </Typography>}>
              <span>Rows Trends</span>
            </Tooltip>
          ),
          width: '20%',
          render: rowData => {
            return (
              <LollipopChart data={rowData.historyList} value={'numIn'} category={'fecha'} chart={'chartRows' + rowData.id} height={chartTableHeight}
                width={chartTableWidth} marginLeft={chartTableMarginLeft} marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        },
        {
          title: (
            <Tooltip title={<Typography style={{ fontSize: '12px' }} >{languageContext.dictionary['passingTrends']}</Typography>}>
              <span>Passing Trends</span>
            </Tooltip>
          ),
          width: '20%',
          render: rowData => {
            return (
              <BarChartSmall data={rowData.historyList} chart={'chartPass' + rowData.id} height={chartTableHeight}
                width={chartTableWidth} marginLeft={chartTableMarginLeft} marginBottom={chartTableMarginBottom} fullscreen={props.fullscreen} />
            )

          }

        }
      ]}

      data={data}

      options={{
        title: false,
        search: false,
        paging: true,
        toolbar: false,
        pageSize: 5,
        pageSizeOptions: [5],
        padding: props.fullscreen === true ? 'normal' : 'dense',
        cellStyle: commonStyles.tableCellStyle
      }}


      style={{ width: '100%', margin: '0', height: props.fullscreen === true ? '100%' : '300px' }}
    />
  )
}

