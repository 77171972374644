import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';


import DashboardChartComponent from '../../Common/Components/DashboardChartComponent';
import TableLlolipopCharts from '../../Common/Components/TableLollipopCharts'
import DQIDimensionComponent from '../../Common/Components/DQIDimensionComponent';


import DonutChart from '../../Common/Charts/DonutChart';
import CardsComponent from '../../Common/Components/CardsComponent';
import AqtivaDialog from 'components/common/AqtivaDialog';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { LanguageContext } from 'language/LanguageContext';

import * as windowHelper from 'commonFunctions/windowHelper'


export default function ProjectsGrid(props) {

    const languageContext = React.useContext(LanguageContext)
    const [projectList, setProjectList] = useState([])
    const [project5FirstList, setProject5FirstList] = useState([])
    const [globalDQI, setGlobalDQI] = useState([])
    const [dimension, setDimension] = useState([])
    const { height, width } = windowHelper.useWindowDimensions();
    //const [loading, setLoading] = useState(true)

    //const [chartDrillDown, setChartDrillDown] = React.useState(<div></div>)
    //const [tablesLollipop, setTablesLollipop] = React.useState(<div></div>)
    const [tablesLollipopWithGraphs, setTablesLollipopWithGraphs] = React.useState(<div></div>)

    //const [chartDrillDownFullscreen, setChartDrillDownFullscreen] = React.useState(<div></div>)
    //const [tablesLollipopFullscreen, setTablesLollipopFullscreen] = React.useState(<div></div>)
    const [tablesLollipopWithGraphsFullscreen, setTablesLollipopWithGraphsFullscreen] = React.useState(<div></div>)
    const [donutChart, setDonutChart] = React.useState(<div></div>)

    const [openDimensionModal, setOpenDimensionModal] = React.useState(false)


    const handleClose = () => setOpenDimensionModal(false);

    React.useEffect(() => {
       //setChartDrillDown(<DQIPerObjectChart chart={'drillDownChartProjects1'} data={project5FirstList} click={handleDrillDownElement} categoryField={'projectName'} valueOk={'score'} valueKo={'scoreKo'} small={true} loading={loadingDQI} />)
        //setTablesLollipop(<LollipopChartsComponentComplete click={handleDrillDownElement} data={project5FirstList} valueRow1={'score'} valueRow2={'numIn'} categoryRow1={'fecha'} categoryRow2={'fecha'} disableCharts={false} dqi={false} />)
        setTablesLollipopWithGraphs(<TableLlolipopCharts data={project5FirstList} click={handleDrillDownElement} showIcons={true} />)

        //setChartDrillDownFullscreen(<DQIPerObjectChart fullscreen={true} chart={'drillDownChartProjects1'} data={project5FirstList} click={handleDrillDownElement} categoryField={'projectName'} valueOk={'score'} valueKo={'scoreKo'} small={true} loading={loadingDQI} />)
        //setTablesLollipopFullscreen(<LollipopChartsComponentComplete fullscreen={true} click={handleDrillDownElement} data={project5FirstList} valueRow1={'score'} valueRow2={'numIn'} categoryRow1={'fecha'} categoryRow2={'fecha'} disableCharts={false} dqi={false} />)
        setTablesLollipopWithGraphsFullscreen(<TableLlolipopCharts fullscreen={true} data={project5FirstList} click={handleDrillDownElement} showIcons={true} />)
    }, [project5FirstList])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        setDonutChart(<DonutChart data={globalDQI.DQIok} showCenterLabel={true} chart={'chartDonutDataSource'} openDimensionModal={openModal} click={true} />)
    }, [JSON.stringify(globalDQI), JSON.stringify(openModal)])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (props.DQI4Project) {

            //Map list of projects to obtain the data that we need for the components of the page
            let listOfProjects = props.DQI4Project.map((val, index) => {
                return {
                    ...val,
                    'score': Number((val.sumNumOk / (val.sumNumOk+val.sumNumKo) * 100).toFixed(0)),
                    'scoreKo': Number((val.sumNumKo / (val.sumNumOk+val.sumNumKo) * 100).toFixed(0)),
                    'globalDQI': Number((val.sumNumOk / (val.sumNumOk+val.sumNumKo) * 100).toFixed(0)),
                }
            })

            setProjectList(listOfProjects)
        }

        if (props.DQI4ProjectMostRecent) {

            //Map list of most recents projects to obtain the data that we need for the components of the page
            let list5FirstProject = props.DQI4ProjectMostRecent.map(val => {
                return {
                    ...val,
                    'id': val.projectId,
                    'name': val.projectName,
                    'historyList': val.qualityPointExecutionHistoryList,
                    'score': Number((val.sumNumOk / (val.sumNumOk+val.sumNumKo) * 100).toFixed(0)),
                    'scoreKo': Number((val.sumNumKo / (val.sumNumOk+val.sumNumKo) * 100).toFixed(0)),
                    'globalDQI': Number((val.sumNumOk / (val.sumNumOk+val.sumNumKo) * 100).toFixed(0)),
                }
            })

            setProject5FirstList(list5FirstProject)
        }
        if (props.globalDQI) {
            setGlobalDQI(props.globalDQI)
        }

        if (props.DQIByDimension && props.DQIByDimension.length > 0) {
            setDimension(props.DQIByDimension);
        }
    }, [props.DQI4Project, props.filterObj, props.DQIByDimension, props.globalDQI, props.DQI4ProjectMostRecent])


    function handleDrillDownElement(e) {
        props.handleDrillDownElement(e)
    }

    function openModal(data) {
        setOpenDimensionModal(true)
    }

    


    /*function loadingDQI(status) {
        setLoading(status)
    }*/



    return (
        <>
            <Grid item xs={4}>
                <DashboardChartComponent loading={props.DQI4ProjectFlag} content={donutChart} title={'Global DQI'} description={languageContext.dictionary['globalDQIProjects']} />
            </Grid >



            <Grid item xs={8}>
                <DashboardChartComponent loading={props.DQI4ProjectMostRecentFlag} content={tablesLollipopWithGraphs} fullscreenContent={tablesLollipopWithGraphsFullscreen} title={'DQI 5 Most Recent Project Executions'} description={languageContext.dictionary['projectDQITooltip']} />
            </Grid >

            <Grid item xs={12}>
                <CardsComponent loading={props.DQI4ProjectFlag} filterObj={props.filterObj} projects={projectList} click={handleDrillDownElement} />
            </Grid >






            <AqtivaDialog visible={openDimensionModal}
                title={`DQI Dimension`}

                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                cancelCallback={handleClose}
                maxWidth={width}
            >
                <div style={{ width: width * 0.9, height: height * 0.7 }}>
                    <DQIDimensionComponent DQIByDimension={dimension} />
                </div>

            </AqtivaDialog>

        </>

    );
}