import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import { Typography, FormControl, Checkbox, TextField,  } from '@material-ui/core'

import AqtivaLoading from 'components/common/AqtivaLoading'

am4core.useTheme(am4themes_frozen);
export default function DataformatDimensionDQIBar(props) {

    const [enableZeroDQI, setEnabledZeroDQI] = React.useState(false)
    const [numberDataformats, setNumberDataformats] = React.useState(5)


    const [data, setData] = React.useState([])

    React.useEffect(() => {
        setData(convertData(props.data).slice(0, numberDataformats))
    }, [JSON.stringify(props.data), numberDataformats, enableZeroDQI])// eslint-disable-line react-hooks/exhaustive-deps

    function isEmptyDataObject(data) {
        for (var j = 0; j < data.dimensionDQIs.length; j++) {
            const dim = data.dimensionDQIs[j]
            if (parseFloat(dim.sumNumOk) > 0) {
                return false
            }
        }
        return true
    }

    function convertData(data) {
        if (data === undefined || data.length === 0) {
            return []
        }
        var values = []
        //const dimensions = ['integrity', 'unicity', 'veracity', 'format']
        for (var i = 0; i < data.length; i++) {
            if (enableZeroDQI === true || isEmptyDataObject(data[i]) === false) {



                var dataDim = []
                var newValue = {}
                newValue.name = `${data[i].dataformatName}`
                for (var j = 0; j < data[i].dimensionDQIs.length; j++) {

                    newValue[data[i].dimensionDQIs[j].dimension] = Math.round(data[i].dimensionDQIs[j].sumNumOk / data[i].dimensionDQIs[j].sumNumIn * 10000) / 100


                    dataDim.push(data[i].dimensionDQIs[j].dimension)

                }




                values.push(newValue)
            }

        }

        return values
    }


    React.useEffect(() => {
        if (data === undefined || data.length === 0) {
            return
        }
        var chart = am4core.create(props.fullscreen === true ? "chartdivDataformatDimensionBarFullscreen" : "chartdivDataformatDimensionBar", am4charts.XYChart);

        chart.colors.step = 2;

        chart.legend = new am4charts.Legend()
        chart.legend.position = 'bottom'
        chart.legend.paddingBottom = 0
        chart.legend.labels.template.maxWidth = 95
        chart.legend.labels.template.fontSize = 11

        var yAxis = chart.yAxes.push(new am4charts.CategoryAxis())
        yAxis.dataFields.category = 'name'

        yAxis.renderer.grid.template.location = 0;
        yAxis.renderer.minGridDistance = 0;
        yAxis.renderer.labels.template.fontSize = 10;
        yAxis.renderer.cellStartLocation = 0.1;
        yAxis.renderer.cellEndLocation = 0.9;
        yAxis.renderer.inversed = true;


        var xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.min = 0;
        xAxis.max = 100;
        xAxis.renderer.labels.template.fontSize = 10;

        function createSeries(value, name) {
            var series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueX = value
            series.dataFields.categoryY = 'name'
            series.name = name
            series.columns.template.width = am4core.percent(100);

            var labelBullet = series.bullets.push(new am4charts.LabelBullet())
            labelBullet.label.horizontalCenter = "left";
            labelBullet.label.dx = 10;
            labelBullet.label.text = `[font-size:12px]${name} {values.valueX.workingValue.formatNumber('#.00as')}%`;
            labelBullet.locationX = 1;
            labelBullet.label.fill = am4core.color("#FFFFFF");




            return series;
        }

        chart.data = data
        const dimensions = ['integrity', 'unicity', 'veracity', 'format']
        for (var i = 0; i < dimensions.length; i++) {
            createSeries(dimensions[i], dimensions[i]);
        }




    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps



    if (props.loading === true) {
        return <AqtivaLoading />
    }
    else {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ display: "flex", justifyContent: 'space-around', height: "10%", marginBottom: '-20px' }}>
                    <FormControl style={{ display: "flex", flexDirection: "row", alignItems: 'center', marginRight: '20px' }}>
                        <Checkbox
                            checked={enableZeroDQI}
                            onChange={event => { setEnabledZeroDQI(event.target.checked) }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            color="primary"
                        />
                        <Typography variant='caption'  >View Zero DQIs</Typography>

                    </FormControl>
                    <TextField
                        id="outlined-number"
                        label="Items to view"
                        type="number"
                        style={{ fontSize: '2px' }}
                        defaultValue={numberDataformats}
                        onChange={e => setNumberDataformats(e.target.value)}
                    /></div>
                <div id={props.fullscreen === true ? "chartdivDataformatDimensionBarFullscreen" : "chartdivDataformatDimensionBar"} style={{ width: "100%", height: "88%" }}></div>
            </div>

        )
    }
}
