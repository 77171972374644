import { createTheme } from '@material-ui/core/styles'

const mainColor='#302BA9'
const disabledColor='#959595'
const darkColor='#000000'
const darkOppositeColor='#D3D0D3'
const errorColor='#ab2e27'

export const QualityTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#302BA9',
    },
    secondary: {
      main: '#292828',
    },
    text: {
      primary: 'rgba(16,16,16,0.87)',
    },
    error: {
      main: '#ab261d',
    },
    warning: {
      main: '#9e7523',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 14,
    caption: {
      fontSize: '0.9rem',
    }
  },
    icons:{
      iconAqtivaTable:{
        fontSize:14
      },
      iconErrorBig:{
        fontSize:20,
        color:errorColor,
      },
      iconInfoBig:{
        fontSize:20,
        color:mainColor,
      },
      iconAqtivaActionBig:{
        fontSize:16,
        color:mainColor
      },
      iconAqtivaTablePrime:{
        backgroundColor:'transparent', 
        borderColor:'transparent',
         color:'#000000'
      }
    },
    inputLabel:{
      fontSize:11
    } ,
    inputLabelBig:{
      fontSize:13
    } ,
    title: {
        flexGrow: 1,
    },
    titleIcon:{
      color:'#FFFFFF',
       fontSize:20,
       marginLeft:'2%',
       cursor: 'pointer'
    },
    elementAdjacent: {
          display: 'flex',
          justifyContent: 'left',
          alignItems: "center",
      },
    pageToolbar:{
      marginLeft: '10%',marginRight:'10%', marginBottom:'1%'
    },
    recentQualtiyProjects:{
      marginBottom:'2%', marginLeft: '10%'
    },
    errors:{
      errorText:{
        color:errorColor,
        fontSize:'12px'
      }
    },
    info:{
      infoText:{
        color:mainColor,
        fontSize:'12px'
      }
    },
    buttons:{
      buttonConfirm:{
        color: mainColor, fontWeight: 'bold'
      },
      buttonInMenuItem:{
        color: mainColor, fontWeight: 'bold',
        fontSize:'9px',
        width:'100%'
      },
      buttonRegular:{
        color: mainColor, fontWeight: 'bold'
      },
      buttonRegularDisabled:{
        color: disabledColor
      },
      buttonGroupRegular : {
        borderWidth: "1px",width:'100%',
                        fontSize: '0.6vw',textTransform: 'none', fontWeight: 'bold'
      },
      buttonGroupRegularDisabled : {
        color: disabledColor,
        borderWidth: "1px",width:'100%',
                        fontSize: '0.6vw',textTransform: 'none', fontWeight: 'bold'
      },
      buttonRegularWithRightMargin:{
        color: mainColor, fontWeight: 'bold', marginRight:'1vw'
      },
      buttonDisabled:{
        color: darkColor, fontWeight: 'bold',
        fontSize: 'calc(8px + 0.3vw)'
      },
      buttonCancel:{
        color: errorColor, fontWeight: 'bold'
      },
      buttonDark:{
        color: darkColor, fontWeight: 'bold'
      },
      buttonGlow: {
          animation: 'glow .5s infinite alternate',
          color: mainColor, fontWeight: 'bold',
          fontSize: 'calc(8px + 0.3vw)'

      
      }
    },
    dialogs:{
      dialogTitleAwesomeIcon:{
        color: '#D3D0D3', fontSize: 25
      },
      tinyIcon:{
        color: '#D3D0D3', fontSize: 12
      },
      smallIcon:{
        color: mainColor, fontSize: 15
      },
      dialogTitleIcon:{
        backgroundColor: darkOppositeColor, marginLeft: '-2%'
      },
      dialogTitleIconInner:{
        color: darkColor
      },
      dialogTitle:{
        backgroundColor: darkColor,
        height:'60px'
      },
      
      dialogTitleText:{
        color: darkOppositeColor
      }
    },
    config:{
      formControlShort: {
        width: '10%',
      },
      formControl: {
        width: '15%',
      }, 
      formControlLong: {
        width: '50%',
      }, 
      formControlSelect: {
        width:'85%',marginLeft:'-5%'
      }, 
    },
    dataRepository:{
      default:{
        marginBottom:'10px',
        border:'none',
        color: '#696969',
        textTransform:'capitalize',
        backgroundColor:'white',
        marginLeft:'40px'
      },
      defaultNoMargin:{
        border:'none',
        color: '#696969',
        textTransform:'capitalize',
        backgroundColor:'white',
      },
      errorMessage:{
        fontWeight:"bold", 
        color:errorColor
      },
      treeTableTitle:{
        color:darkColor,
        fontSize: 'calc(8px + 0.4vw)',
        textTransform: 'none', 
        fontWeight: 'bold'
      },
      elementAdjacent: {
        display: 'flex',
        justifyContent: 'left' ,
        alignItems: "center",
      },
      iconPublic:{
        fontSize: "calc(8px + 0.4vw)",
            marginLeft: "5px",
            marginRight: "5px",
            color: mainColor
      },
      iconPrivate:{
        fontSize: "calc(8px + 0.4vw)",
              marginLeft: "5px",
              marginRight: "5px",
              color: errorColor,
      },
      backgroundColumnsAndText:{
        background: "transparent",
        borderColor: "transparent",
        fontSize:  "calc(10px + 0.14vw)"
      }
    },
    notifications:{
      badge:{
        fontSize: "20px",
      },
      mainItem:{
        fontSize: "calc(12px + 0.1vw)", 
      },
      dateItem:{
        fontSize: "calc(12px + 0.1vw)", 
      },
      title:{
        textAlign: "center", fontSize: "calc(12px + 0.2vw)", width: "100%"
      },
      subtitle:{
        textAlign: "center", fontSize:  "calc(12px + 0.12vw)",  width: "100%"
      },
      notificationsButton:{
        textAlign: "center", fontSize:  "calc(12px + 0.12vw)",  width: "100%", textTransform:"capitalize", color:"white",
      },
      allNotificationsTableContainer: {
        textAlign:"right", width:"100%", minWidth:"1000px"
  },
      allNotificationsTable: {
          textAlign:"left", width:"100%", minWidth:"700px"
    },
      allNotificationsTableTitle:{
        textAlign:"center", fontWeight:"bold", fontSize:"calc(14px + 0.2vw)"
      },
      notificationDefaultText: {
        textAlign: "center", fontSize:  "calc(12px + 0.12vw)", color:"white", marginRight:"20px", marginLeft:"20px", marginBottom:"20px", marginTop:"20px"
      },
      showUnreadCheck:{
        textAlign:"right",  fontSize:"200px"
      }
    },
    dashboard:{
      filter:{
        mainTitleDiv:{
          width:'100%', backgroundColor: darkColor,
          padding:'10px',
          display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
        },
        form:{
          width:'90%', marginLeft:'20px'
        }
      }
    },
    QualityKPIS:{
      mainContainer: {
        maxWidth: 280,
        borderRadius: 20,
        marginTop: "50px",
      },
      bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
      },
      title: {
        fontSize: 16,
        margin: "auto",
        textAlign: "center",
        marginTop: "15px",
      },
      text: {
        margin: "auto",
        textAlign: "center",
        marginTop: "5px",
      },
      pos: {
        marginBottom: 12,
      },
      card: {
        backgroundColor: "lightBlue",
        width: "100%",
        borderRadius: 10,
      },
      button: {
        //border:'1px solid lightGrey',
        marginTop: "5px",
        marginRight: "10px",
        marginLeft: "0px",
        marginBottom: "0px",
        cursor: 'pointer',
        fontSize:'20px',
        color:'#FFFFFF'
      },
      divider: {
        borderTop: "1px solid grey",
      },
      firstDivider: {
        borderBottom: "1px solid grey",
       
        backgroundColor: mainColor,
        width: "100%",
        borderRadius: 0,
        fontSize: '12px'
      },
      firstDividerNoData: {
        borderBottom: "1px solid grey",
       
        backgroundColor: 'grey',
        width: "100%",
        borderRadius: 10,
        fontSize: '12px'
      },

      container: {
        margin: '20px',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
      },

      container2: {
        width:'135%',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      },

      },
    common:{
      text:{
        searchText:{
          fontSize: 'calc(7px + 0.3vw)',
        },
        regularText:{
          fontSize: 'calc(8px + 0.3vw)',
        },
        boldRegularText:{
          fontSize: 'calc(8px + 0.3vw)',
          textTransform: 'none', 
          fontWeight: 'bold',
        },
        buttonText:{
          fontSize: 'calc(8px + 0.3vw)',
        },
        buttonTextDisabled:{
          color:disabledColor,
          fontSize: 'calc(8px + 0.3vw)',
        },
        buttonTextSmall:{
          fontSize: 'calc(8px + 0.15vw)',
        },
        tableProjectText:{
          fontSize: 'calc(8px + 0.3vw)',
        },
        tableProjectCellText:{
          fontSize: 'calc(6px + 0.3vw)',
        }
      }
      
    }
  });

  export const styles = theme => ({
    toolbar: {
      background: 'linear-gradient(to right, #474b51,#474b51,#474b51, #4697c4)'
    },
    appBar: {
      position: 'relative',
    },
    flex: {
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
      background:mainColor,
      color:'white'
    },
    textFieldSearch: {
      marginTop: 0
    },
    input: {
      display: 'none',
    },
    card: {
      width: '460px',
       height: '180px',
        margin: '10px', 
   },
   chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '-10px',
  },
  chip: {
    margin: theme.spacing(1)
  },

media: {
  height: 140,
},
fab: {
    margin: theme.spacing(1),
    width:'35px',
    height:'35px'
  },
  dividers : {
    width: '100%',
    maxWidth: '360px',
    backgroundColor: theme.palette.background.paper,
  },
  gridForm: {
    flexGrow: 1,
    width: '980px'
},
subGridForm: {
  flexGrow: 1,
  width: '350px',
},
ruleFormCard :{
  minWidth: 200,
},
ruleFormPaper: {
  height: 140,
  width: 100,
},
ruleFormControl: {
  padding: theme.spacing(2),
},
pos: {
  marginBottom: 30,
  fontSize: 14
},
cardRuleColumn: {
  height: 35
},
cardRuleColumnContent: {
  marginTop: -5
},
cardRuleColumnButtonDelete: {
  
},
icon: {
  margin: theme.spacing(2),
},
cardHeader : {
  backgroundColor:'#f2f2f2'
},
cardHeaderNoOwner : {
  backgroundColor:'#e8e5b9'
},
dense: {
  marginTop: 16,
},
formCreateWorkItem: {
  position:'relative',
   left: 0,
   marginBottom:'0px'
},
formControl: {
  margin: theme.spacing(1),
  minWidth: 140,
},  
baseForm: {
  display: 'flex',
  flexWrap: 'wrap',
},
elementAdjacent: {
  display: 'flex',
  justifyContent: 'space-between' ,
  alignItems:"center"
},
elementAdjacentBottom: {
  display: 'flex',
  justifyContent: 'space-between' ,
  alignItems: "center",
},
searchIcon:{
  color:darkColor,
  marginTop:'25px'
},
formRule:{
  display: 'flex',
  width: '1024px',
  flexDirection: 'column',
  margin: 'auto',
  minHeight:'80px',
},
column: {
  flexBasis: '33.33%',
},
heading: {
  fontSize: theme.typography.pxToRem(17),
  fontWeight: 'bold',
  color:mainColor
},
chips: {
  display: 'flex',
  flexWrap: 'wrap',
},
closeButton: {
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: 'white',
},
closeButton2: {
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
},
buttonRegular:{
  color: mainColor, 
  fontWeight: 'bold'
},
textLink: {
  cursor:'pointer',
  fontStyle: 'italic',
  textDecoration:'underline'
},
formControlMultiSelect: {
  margin: theme.spacing(1),
  maxHeight: '120px',
  select: {
    '&:before': {
        borderColor: 'red',
    },
    '&:after': {
        borderColor: 'red',
    }
},
},
  })

  