import React from 'react'; 
import { makeStyles, FormControl, Typography, Select, MenuItem } from '@material-ui/core';

import * as commonStyles from 'style/CommonStyles'

import * as ruleUtils from 'components/rule/utilsRuleCreation'



interface RuleDimensionSelectorProps {
    ruleDimension: string,
    setRuleDimension: ( newValue: string) => (any)

}


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    },

    chipContainerRule: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function RuleDimensionSelector({ruleDimension, setRuleDimension}:  RuleDimensionSelectorProps) {

   
    const classes = useStyles();


    return(
        <div style={{ marginLeft: '10%' }}>
                            <FormControl className={classes.formControl}>

                                <Typography variant='caption'>Quality Dimension</Typography>
                                <Select
                                    style={{ width: '80%' }}
                                    value={ruleDimension}
                                    onChange={(event: React.ChangeEvent<{ name?: string | undefined; value: any; }>, child: React.ReactNode)  => setRuleDimension(event.target.value)}
                                    inputProps={{
                                        name: 'Rule Dimension',
                                        id: 'dimension-selection',

                                    }}

                                >
                                    {ruleUtils.ruleDimensions.map(
                                        (value) => (
                                            <MenuItem key={value} value={value.toLowerCase()}>
                                                <div style={commonStyles.adjacentLeft}>
                                            <Typography variant='body2'>{value}</Typography></div>
                                        </MenuItem>))}
                                    

                                </Select>
                            </FormControl>
                        </div>
    )
}
