import React from 'react';
import MaterialTable from 'material-table'
import { FormControlLabel, Switch, Tooltip, Typography } from '@material-ui/core'
import * as commonStyles from 'style/CommonStyles'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '120px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    },
    typography: {
        fontSize: "12px",
        marginTop: "20px",
    },
    recurrence: {
        display: "flex", flexDirection: "row", justifyContent: "left", alignItems: 'center'
    },
    scheduleControlElements: {
        display: "flex", flexDirection: "row", justifyContent: "left",
    },
    buttons: {
        display: "flex", flexDirection: "row", justifyContent: "flex-end", margin: "50px"
    },
    notificationCheck: {
        width: "30%",
    },

    rowTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: '20px'

    },
    rowTitleSmall: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",


    },
    rowTitleWithSpace: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: '10px',
        marginRight: '10px'
    }


}));

export default function StorageConfigurationTable(props) {

    const classes = useStyles();

    //const [rowsPerPage, setRowsPerPage] = React.useState(5)
    //const [page, setPage] =React.useState(0)

    const [configuration, setConfiguration] = React.useState()

    const data = [
        {
            id: 0,
            name: 'Azure Storage',
            img: 'azure/Storage-Accounts.png',
            enable: configuration && configuration.enableAzureStorage ? configuration.enableAzureStorage : false
        },
        {
            id: 1,
            name: 'SQL Database',
            img: 'azure/sql-database-generic.png',
            enable: configuration && configuration.enableSqlDatabase ? configuration.enableSqlDatabase : false
        },
        {
            id: 2,
            name: 'MySql',
            img: 'mySQL.png',
            enable: configuration && configuration.enableMySql ? configuration.enableMySql : false
        },
        {
            id: 3,
            name: 'Oracle',
            img: 'oracle.png',
            enable: configuration && configuration.enableOracle ? configuration.enableOracle : false
        },
        {
            id: 4,
            name: 'Synapse',
            img: 'azure/synapse.png',
            enable: configuration && configuration.enableSynapse ? configuration.enableSynapse : false
        },
        {
            id: 5,
            name: 'Big Query',
            img: 'bigQuery.png',
            enable: configuration && configuration.enableBigQuery ? configuration.enableBigQuery : false
        }, {
            id: 6,
            name: 'SQL Server',
            img: 'sqlServer.png',
            enable: configuration && configuration.enableSQLServer ? configuration.enableSQLServer : false
        },
        {
            id: 7,
            name: 'AWS',
            img: 'aws/aws-s3-logo.png',
            enable: configuration && configuration.enableAws ? configuration.enableAws : false
        },
        {
            id: 8,
            name: 'Hive',
            img: 'hive.png',
            enable: configuration && configuration.enableHive ? configuration.enableHive : false
        },
        {
            id: 9,
            name: 'awsRedshift',
            img: 'redshift.png',
            enable: configuration && configuration.enableRedshift ? configuration.enableRedshift : false
        }
    ]

    const columns = [
        { title: 'Name', field: 'name' },
        {
            title: 'Storage', filtering: false, render: (rowData) => {
                return (
                    <>
                        <Tooltip title={
                            <React.Fragment>
                                <Typography variant='subtitle2'>{rowData.name}</Typography>
                            </React.Fragment>}>
                            <img alt="" style={{ height: '25px', width: '25px' }}
                                src={require(`./../../../../resources/icons/${rowData.img}`)} />
                        </Tooltip>

                    </>
                )
            }
        },
        {
            title: '', render: (rowData) => {
                return (
                    <>
                        <FormControlLabel className={classes.notificationCheck}
                            control={
                                <Tooltip title="Enabled Storage Types">
                                    <Switch
                                        edge="end"
                                        color="primary"
                                        onClick={handleToggle(rowData)}
                                        checked={rowData.enable}
                                    />
                                </Tooltip>
                            }
                        />
                    </>
                )
            }
        }
    ]

    React.useEffect(() => {
        if (props.configuration) setConfiguration(JSON.parse(JSON.stringify(props.configuration)))
    }, [props.configuration])


    const handleToggle = (rowData) => () => {

        switch (rowData.id) {
            case (0):
                configuration.enableAzureStorage = !rowData.enable
                break;
            case (1):
                configuration.enableSqlDatabase = !rowData.enable
                break;
            case (2):
                configuration.enableMySql = !rowData.enable
                break;
            case (3):
                configuration.enableOracle = !rowData.enable
                break;
            case (4):
                configuration.enableSynapse = !rowData.enable
                break;
            case (5):
                configuration.enableBigQuery = !rowData.enable
                break;
            case (6):
                configuration.enableSQLServer = !rowData.enable
                break;
            case (7):
                configuration.enableAws = !rowData.enable
                break;
            case (8):
                configuration.enableHive = !rowData.enable
                break;
            case (9):
                configuration.enableRedshift = !rowData.enable
                break;
            default:
                break;
        }

        rowData.enable = !rowData.enable

        setConfiguration(JSON.parse(JSON.stringify(configuration)))
        props.setConfig(JSON.parse(JSON.stringify(configuration)))
    }

    return (

        <div>

            <MaterialTable
                title={'Enable Storages'}
                columns={columns}
                data={data}
                options={{
                    filtering: false,
                    exportButton: false,
                    search: false,
                    toolbar: false,
                    pageSize: 6,
                    grouping: false,
                    paging: false,
                    headerStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                        backgroundColor: commonStyles.mainColor,
                        color: '#FFF'
                    },
                    cellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                    filterCellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                }}
            />

        </div>

    )
}