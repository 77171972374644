import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import DataformatBasicInformationAzureStorage from './DataformatBasicInformationAzureStorage'
import DataformatBasicInformationSqlDatabase from './DataformatBasicInformationSqlDatabase'
import DataformatBasicInformationJDBCDatabase from './DataformatBasicInformationJdbcDatabase'

export default function DataformatBasicInformation(props) {


    return (
        <div style={{ maxWidth: '400px' }}>


            <Grid container spacing={0} style={{ margin: '10px' }}>

                <Grid item xs={6}>
                    <Typography variant='subtitle2'>Dataformat Name: </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>{props.dataformat.nickname}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='subtitle2'>Dataformat Id: </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>{props.dataformat._id}</Typography>
                </Grid>


                <Grid item xs={6}>
                    <Typography variant='subtitle2'>Dataformat Base Name: </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>{props.dataformat.name}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='subtitle2'>Dataformat Type: </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>{props.dataformat.source}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='subtitle2'>Dataformat Format: </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>{props.dataformat.format ? props.dataformat.format : 'NA'}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='subtitle2'>Profiling Status: </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>{props.dataformat.profilingStatus ? props.dataformat.profilingStatus : 'No Profiling'}</Typography>
                </Grid>

                {props.dataformat.source === 'azure-storage' &&
                    <DataformatBasicInformationAzureStorage dataformat={props.dataformat} />
                }

                {props.dataformat.source === 'azure-sqldatabase' &&
                    <DataformatBasicInformationSqlDatabase dataformat={props.dataformat} />
                }

                { (props.dataformat.source === 'oracle' || props.dataformat.source === 'mySQL' || props.dataformat.source === 'sqlServer') &&
                    <DataformatBasicInformationJDBCDatabase properties={props.dataformat.sqlDatabaseOnpremProperties} query={props.dataformat.sqlQuery ? props.dataformat.sqlQuery : 'N/A'}/>
                }
            </Grid>
        </div>
    )
}