import React from "react";
import {
  Grid,
} from "@material-ui/core";
import AppBarTemplate from "components/template/AppBarTemplate";
import * as pathHelper from "components/template/PathHelper";
import * as actions from "actions";
import FilterMenuTotalEventRules from "./FilterMenuTotalEventRules";
import ChartTotalEventRules from "./ChartTotalEventRules";
import CardTotalEventRules from "./CardTotalEventRules";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom'
import TableTotalUCs from "./TableTotalUCs";

export default function QualityConsumption(props) {
  const dispatch = useDispatch();
  const history = useHistory(); 

  const theme = useTheme();
  const user = useSelector((store) => store.user);
  const license = useSelector((store) => store.license);
  const projects = useSelector((store) => Object.values(store.projects));
  //const dataTotalRecords = useSelector((store) =>store.totalRecords);
  const tableTotalEventRulesData = useSelector((store) =>store.totalRecordsTable);

  const [projectsLicense, setProjectsLicense] = React.useState([]);

  //Pasamos por props la info de dataTotalRecords a los componentes "hijos": el filter, la card y el chart


  React.useEffect(() => {
    if (user && user.id) {
      dispatch(actions.fetchProjects(user.id));
      if (license && license._id) {
        getLicenseProjects().then((response) => setProjectsLicense(response));
      } else {
        if(user!==undefined && user.id!==undefined){
          dispatch(actions.fetchLicense((user).id))
      }
      }
    }
  }, [user, license._id]);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    dispatch(actions.fetchUser(history));
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  async function getLicenseProjects() {
    var projects = [];
    if (license && license._id && license.users && license.users.length > 0) {
      for (var i = 0; i < license.users.length; i++) {
        let licenseUser = license.users[i];
        if (user.id !== licenseUser.id) {
          var userProjects = await dispatch(
            actions.fetchProjectsAndReturn(licenseUser.id)
          );
          projects = projects.concat(userProjects);
        }
      }
    }
    return projects;
  }

  return (
    <>
      <AppBarTemplate
        history={props.history}
        title={"Consumption"}
        arrayPath={pathHelper.getPathArray("Consumption")}
      />

      <FilterMenuTotalEventRules
        projects={projects}
        projectsLicense={projectsLicense}
      >
        <div style={theme.QualityKPIS.container}>
          <Grid container justify="center" spacing={1}>
            <Grid item xs={2}>
              <CardTotalEventRules dataTotalRecords={tableTotalEventRulesData ? tableTotalEventRulesData.totalUCs: 0}/>
            </Grid>
            <Grid item xs={9}>
              <ChartTotalEventRules dataTotalRecords={tableTotalEventRulesData ?tableTotalEventRulesData.dayDto : []} />
            </Grid>
            <Grid item xs={12}>
            <TableTotalUCs data={tableTotalEventRulesData ? tableTotalEventRulesData.dayEnvironmentDto : []}/>
            </Grid>
            
          </Grid>
        </div>
     
      </FilterMenuTotalEventRules>
    </>
  );
}
