import React from 'react';
import { Grid,  Typography } from '@material-ui/core';





export default function ResourceElement(props) {

   

    return (
        <>
            <Grid item xs={2}>
                <img alt="" style={{ height: `${props.sizeInPx}px`, width: `${props.sizeInPx}px` }} src={props.image} />
            </Grid>
            <Grid item xs={10}>
                <div style={{ minWidth: '100%' }}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{props.title}</Typography>
                    <Typography variant="caption" >{props.description}</Typography>
                </div>
            </Grid>
        </>
    )
}