import * as actions from 'actions'
import { BlobServiceClient } from '@azure/storage-blob';
import { FETCH_INFO } from 'actions/types'


export function deployProject2Cloud(configuration, envName, projectId, user, dispatch) {
  //get environment configuration
  let envConfig = configuration.environmentConfigurationList.filter(x => x.environmentName === envName)[0]

  if (envConfig.backendType === 'Azure') {
    deployProject2Azure(envConfig, projectId, envName, user, dispatch)
  }
  else if (envConfig.backendType === 'AWS') {
    deployProject2AWS(envConfig, projectId, envName, user, dispatch)
  }
  else {
    //TODO: throw error
  }
}

async function deployProject2AWS(envConfiguration, projectId, environment, user, dispatch) {
  const response = await dispatch(actions.getQualityConfigurationDeployment(projectId, environment))
  const configs = response.configuration
  //get need the elements
  asyncDeployForEachAWS(configs, envConfiguration, environment, projectId, user, dispatch)


}

async function deployProject2Azure(envConfiguration, projectId, environment, user, dispatch) {
  const response = await dispatch(actions.getQualityConfigurationDeployment(projectId, environment))
  const configs = response.configuration
  //get need the elements
  asyncDeployForEachAzure(configs, envConfiguration, environment, projectId, user, dispatch)


}

const asyncDeployForEachAWS = async (data, envConfiguration, environment, projectId, user, dispatch) => {

  let userId = user.id
  let container = envConfiguration.amazonConfiguration.bucketName
  let account = envConfiguration.amazonConfiguration.accessKey
  let key = envConfiguration.amazonConfiguration.privateKey
  let region = envConfiguration.amazonConfiguration.awsServiceRegion


  await asyncForEach(data, async (x) => {
    const qualitypointName = JSON.parse(x).quality_point.name ? JSON.parse(x).quality_point.name : JSON.parse(x).quality_point.quality_point_name
    var folder = "deploy/" + environment + '/' + projectId + '/'
    var filename = qualitypointName + ".json"
    filename = filename.split(' ').join('_')
    // dispatch to path /upload/file/s3
    //creamos metodo para poder pasar un string, no solamente un byte[]

    //let byteArray = Buffer.from(x + "\0")

    const object = {
      userId: userId,
      container: container,
      account: account,
      key: key,
      region: region,
      folder: folder,
      fileName: filename,
      fileAsString: JSON.stringify(JSON.parse(x)),
      //byteArray: byteArray.data
    }
    dispatch(actions.uploadObjectStringToS3(object))
    await dispatch(actions.deployNotebook2DatabricksAzure(environment, user.id, projectId, qualitypointName))
    dispatch(actions.addAudit(environment, projectId))
  });
}

const asyncDeployForEachAzure = async (data, envConfiguration, environment, projectId, user, dispatch) => {

  let userId = user.id
  let container = envConfiguration.containerName
  let account = envConfiguration.storageAccountName
  let servicePrincipalId = envConfiguration.servicePrincipalId
  var servicePrincipalSecret = await dispatch(actions.getSecret(envConfiguration.servicePrincipalSecret)) 
  if (servicePrincipalSecret.error){
    servicePrincipalSecret= undefined
  }
  let tenantId =  envConfiguration.tenantId
  let key =   await dispatch(actions.getSecret(envConfiguration.storageKey)) 
  
  await asyncForEach(data, async (x) => {
    const qualitypointName = JSON.parse(x).quality_point.name ? JSON.parse(x).quality_point.name : JSON.parse(x).quality_point.quality_point_name
    var folder = "deploy/" + environment + '/' + projectId + '/'
    var filename = qualitypointName + ".json"
    filename = filename.split(' ').join('_')
    // dispatch to path /upload/file/s3
    //creamos metodo para poder pasar un string, no solamente un byte[]

    //let byteArray = Buffer.from(x + "\0")

    const object = {
      userId: userId,
      container: container,
      account: account,
      key: key,
      servicePrincipalId: servicePrincipalId,
      servicePrincipalSecret:servicePrincipalSecret,
      tenantId: tenantId,
      folder: folder,
      fileName: filename,
      fileAsString: JSON.stringify(JSON.parse(x)),
      //byteArray: byteArray.data
    }
    dispatch(actions.uploadObjectStringToAzure(object))
    await dispatch(actions.deployNotebook2DatabricksAzure(environment, user.id, projectId, qualitypointName))
    dispatch(actions.addAudit(environment, projectId))
  });
}



function getSasElements(envConfig) {

  return {
    'sasToken': envConfig.sasToken,
    'storageAccountName': envConfig.storageAccountName,
    "containerName": envConfig.containerName
  };


}

async function asyncForEach(array, callback) {
  if (array) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
}
