import React  from "react";
import {  FormControl, Checkbox, InputLabel, Select, Typography, MenuItem} from "@material-ui/core";
import {onlyUnique} from 'commonFunctions/commonFunctions'


export default function Filter(props) {
 

  const [tagValues, setTagValues] = React.useState([])

  const [selectedColumns, setSelectedColumns] = React.useState([])

  React.useState(() => {
    setTagValues(getTagValues(props.tagName))
  }, [props.tagName])


  function getTagValues(name) {
    if (props.tagObjects && props.tagObjects.length > 0) {
      let filterObjects = props.tagObjects.filter(x => x.tagName === name)
      if (filterObjects && filterObjects.length > 0) {
        return filterObjects.map(x => x.tagValue).filter(onlyUnique)
      }
    }
    return []

  }

  function handleSelectColumn(e) {

    
    setSelectedColumns(e.target.value)

    props.updateFilter(props.tagName,e.target.value)

}

  return (
    <FormControl style={{width:'100%'}}>
      <InputLabel htmlFor="typeWarnTh-selection-helper" >{props.tagName}</InputLabel>
      <Select
        multiple
        style={{ width: '100%' }}
        value={selectedColumns}
        onChange={handleSelectColumn}
        
        renderValue={(selectedColumns) => selectedColumns.join(', ')}

      >
      

        {tagValues &&tagValues.length>0 && tagValues.map(e => (
          <MenuItem key={e} value={e}>
          <Checkbox size = {'small'} checked={selectedColumns.indexOf(e) > -1} />
          <Typography variant = "caption">{e}</Typography> 
        </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
