import React, { useEffect } from 'react';


import { useSelector } from 'react-redux'
import AqtivaDialog from '../../../../../../common/AqtivaDialog';
import { Grid, InputLabel, Select, Chip, Typography, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {  faPlusCircle } from '@fortawesome/free-solid-svg-icons'



export default function EditCredentialsPermissionsDialog(props) {
    var license = useSelector(store => store.license)

    const [accessType, setAccessType] = React.useState('')
    const [selectedUsers, setSelectedUsers] = React.useState([])


    useEffect(() => {
        if (props.selectedDataCredentials && props.selectedDataCredentials.accessType) {
            setAccessType(props.selectedDataCredentials.accessType)
        }
        if (props.selectedDataCredentials && props.selectedDataCredentials.allowedUsers) {
            handleMultipleSelect(props.selectedDataCredentials.allowedUsers)
        }
    }, [props.selectedDataCredentials, props.selectedDataCredentials && props.selectedDataCredentials.id])// eslint-disable-line react-hooks/exhaustive-deps


    function editArrayElement(datacredentials, editedElement) {
        let updatedDataCredentials = JSON.parse(JSON.stringify(editedElement))
        updatedDataCredentials.accessType = accessType
        updatedDataCredentials.allowedUsers = selectedUsers

        let index = datacredentials.findIndex(x => x.id === props.selectedDataCredentials.id)
        datacredentials[index] = updatedDataCredentials
        props.setData(datacredentials)
        props.setUpdateCounter(props.updateCounter + 1)
        props.setShowEditDialog(false)
    }

    function handleMultipleSelectUsers(values) {
        console.log(values)
        handleMultipleSelect(values)
    };

    function handleMultipleSelect(values) {
        if (values && values.length > 0) {
            values = values.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
        }
        setSelectedUsers(values)
    };

    const MyChip = (props) => {

        return (
            <Chip
                style={{ maxWidth: '120px', backgroundColor: '#b9e4eb44' }}
                {...props}
            />
        );
    };
    function resetState() {
        setAccessType('')
        setSelectedUsers([])
    }

    return (
        <AqtivaDialog
            visible={props.showEditDialog}
            dialog={props.showEditDialog}
            titleIcon={faPlusCircle}
            fullWidth={true}
            maxWidth={'lg'}
            title={"Edit Permissions"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setShowEditDialog(false)
                resetState()
            }}
            confirmText={'Edit Storage properties'}
            confirmCallback={async () => {
                editArrayElement(props.data, props.selectedDataCredentials)
                resetState()
            }}
        >
            < Grid container spacing={1} >
                <Grid item xs={3}>
                    <InputLabel id="demo-mutiple-chip-label">Select Access Policy</InputLabel>
                    <Select
                        native
                        value={accessType ? accessType : 'public_access'}
                        onChange={event => setAccessType(event.target.value)}
                        inputProps={{
                            name: 'age',
                            id: 'age-native-simple',
                        }}
                    >
                        <option value={'public_access'}>Public</option>
                        <option value={'protected_access'}>Protected</option>
                    </Select>
                </Grid>
                {accessType && accessType === 'protected_access' &&
                    <Grid item xs={8}>
                        <InputLabel id="demo-mutiple-chip-label">Allowed Users</InputLabel>
                        {/* <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            style={{ width: '100%' }}
                            value={selectedUsers ? selectedUsers : []}
                            onChange={event => handleMultipleSelectUsers(event.target.value)}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div >
                                    {selected.map((value) => (
                                        <Chip key={value.id} label={value.name} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {license.users.map((value) => (
                                <MenuItem key={value.id} value={value} >
                                    {value.name}
                                </MenuItem>
                            ))}
                        </Select> */}
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={license.users}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions={true}
                            value={selectedUsers}
                            getOptionDisabled={(option) => !!selectedUsers.find(element => element.id === option.id)}
                            onChange={(event, value) => {
                                handleMultipleSelectUsers(value)
                            }}
                            renderTags={(selectedUsers, getTagProps) => {
                                return selectedUsers.map((option, index) => (
                                    <MyChip {...getTagProps({ index })} label={<Typography variant='caption'>{option.name}</Typography>} />
                                ));
                            }}
                            renderOption={(option) => (
                                <Typography variant='caption'>{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ fontSize: '4px', maxWidth: "100%" }}

                                    variant="standard"
                                    label={<Typography variant='caption'>Allowed users</Typography>}

                                />
                            )}
                        />
                    </Grid>
                }

            </ Grid>


        </AqtivaDialog>

    )
}