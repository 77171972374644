import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

import { styles, QualityTheme } from '../../../../../../../style/CustomTheme'
import { Typography, TextField, FormControlLabel, MuiThemeProvider } from '@material-ui/core';
import AqtivaDialogEncryptString from '../../../../../../common/AqtivaDialogEncryptString'
import classNames from 'classnames';


export default function SqlCard(props) {


    const classes = makeStyles(styles(QualityTheme));

    const [visibleSQLPassword, setVisibleSQLPassword] = React.useState(false)

    const [resourceGroupName, setResourceGroupName] = React.useState('')
    const [server, setServer] = React.useState('')
    const [database, setDatabase] = React.useState('')
    const [schema, setSchema] = React.useState('')
    const [table, setTable] = React.useState('')
    const [user, setUser] = React.useState('')
    const [pwd, setPwd] = React.useState('')


    React.useEffect(() => {
        setResourceGroupName(props.storageInfo && props.storageInfo.resourceGroupName && props.storageInfo.resourceGroupName !== null ?
            props.storageInfo.resourceGroupName : '')
        setServer(props.storageInfo && props.storageInfo.server && props.storageInfo.server !== null ?
            props.storageInfo.server : '')
        setDatabase(props.storageInfo && props.storageInfo.database && props.storageInfo.database !== null ?
            props.storageInfo.database : '')
        setSchema(props.storageInfo && props.storageInfo.schema && props.storageInfo.schema !== null ?
            props.storageInfo.schema : '')
        setTable(props.storageInfo && props.storageInfo.table && props.storageInfo.table !== null ?
            props.storageInfo.table : '')
        setUser(props.storageInfo && props.storageInfo.user && props.storageInfo.user !== null ?
            props.storageInfo.user : '')
        setPwd(props.storageInfo && props.storageInfo.pwd && props.storageInfo.pwd !== null ?
            props.storageInfo.pwd : '')
    }, [props.storageInfo, props.parentConfiguration]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let storageProperties = {}
        storageProperties.resourceGroupName = resourceGroupName
        storageProperties.server = server
        storageProperties.database = database
        storageProperties.schema = schema
        storageProperties.table = table
        storageProperties.user = user
        storageProperties.pwd = pwd
        props.setStorageProperties(storageProperties)
    }, [resourceGroupName, server, database, schema, table, user, pwd])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MuiThemeProvider theme={QualityTheme}>
            <AqtivaDialogEncryptString visible={visibleSQLPassword}
                setVisible={setVisibleSQLPassword}
                confirm={setPwd}
                sName={props.master === true ? props.configuration._id + "stsastk" : props.configuration._id + "stsastk"}
            />
            <div>
                {<div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                    <Typography variant="subtitle2"> SQL Configuration</Typography>
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="accountName"
                                label="Resource Group Name"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={resourceGroupName}
                                onChange={(event) => { setResourceGroupName(event.target.value) }}
                            />} />
                    {props.errors && props.errors.resourceGroupName && <div style={{ margin: "10px", color: "red" }}>{props.errors.resourceGroupName}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="serverName"
                                label="Server Name "
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={server}
                                onChange={(event) => { setServer(event.target.value) }}
                            />} />
                    {props.errors && props.errors.server && <div style={{ margin: "10px", color: "red" }}>{props.errors.server}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="database"
                                label="Database "
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={database}
                                onChange={(event) => { setDatabase(event.target.value) }}
                            />} />
                    {props.errors && props.errors.database && <div style={{ margin: "10px", color: "red" }}>{props.errors.database}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="schema"
                                label="Schema"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={schema}
                                onChange={(event) => { setSchema(event.target.value) }}
                            />} />
                    {props.errors && props.errors.schema && <div style={{ margin: "10px", color: "red" }}>{props.errors.schema}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="table"
                                label="Table Name"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={table}
                                onChange={(event) => { setTable(event.target.value) }}
                            />} />
                    {props.errors && props.errors.table && <div style={{ margin: "10px", color: "red" }}>{props.errors.table}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="user"
                                label="User"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                value={user}
                                onChange={(event) => { setUser(event.target.value) }}
                            />} />
                    {props.errors && props.errors.user && <div style={{ margin: "10px", color: "red" }}>{props.errors.user}</div>}
                    <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="password"
                                label="Password"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                type="password"
                                value={pwd}
                                onClick={(event) => { setVisibleSQLPassword(true) }}
                            />} />
                    {props.errors && props.errors.password && <div style={{ margin: "10px", color: "red" }}>{props.errors.password}</div>}
                </div>
                }
            </div>
        </MuiThemeProvider >
    )
}