import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core'
import { useSelector, } from 'react-redux'
import TagConfigurator from './TagConfigurator';

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'

export default function TagComponent(props) {
    const languageContext = React.useContext(LanguageContext);

    const configuration = useSelector(store => (store.qualityConfiguration))


    const [mandatoryTagsData, setMandatoryTagsData] = useState(undefined)
    const [mandatoryTags, setMandatoryTags] = useState(undefined)
    const [optionalTagsData, setOptionalTagsData] = useState(undefined)
    const [optionalTags, setOptionalTags] = useState(undefined)

    function getValueFromParent(tagName) {
        if (props.parent && props.parent.tagList && props.parent.tagList.length > 0) {
            //buscamos si existe
            const foundValue = props.parent.tagList.filter(x => x.tagName === tagName)
            if (foundValue && foundValue.length > 0) {
                return foundValue[0].tagValue
            }
            if (props.intermediateParent && props.intermediateParent.tagList && props.intermediateParent.tagList.length > 0) {
                let mainParentValue = props.intermediateParent.tagList.filter(x => x.tagName === tagName)
                if (mainParentValue && mainParentValue.length > 0) {
                    return mainParentValue[0].tagValue
                }
            }
            if (props.mainParent && props.mainParent.tagList && props.mainParent.tagList.length > 0) {
                let mainParentValue = props.mainParent.tagList.filter(x => x.tagName === tagName)
                if (mainParentValue && mainParentValue.length > 0) {
                    return mainParentValue[0].tagValue
                }
            }
            else {
                return undefined
            }
        }
        else {
            return undefined
        }
    }

    //here we initialize mandatory and optional tags arrays and set their values if they already have one
    useEffect(() => {
        let mandatoryTagsData = configuration.tagConfiguration ? configuration.tagConfiguration.filter(tag => tag.mandatory) : []
        let optionalTagsData = configuration.tagConfiguration ? configuration.tagConfiguration.filter(tag => !tag.mandatory) : []
        let mandatoryTags = []
        let optionalTags = []
        for (let i = 0; i < mandatoryTagsData.length; i++) {
            if (mandatoryTagsData[i].taggableElementTypes.includes("all") || mandatoryTagsData[i].taggableElementTypes.includes(props.elementType)) {
                let tag = {}
                let originalValue = props.qualityElement && props.qualityElement.tagList && props.qualityElement.tagList.find((tag) => tag.tagName === mandatoryTagsData[i].tagName)
                tag.tagName = mandatoryTagsData[i].tagName
                tag.tagValue = originalValue && originalValue.tagValue ? originalValue.tagValue : getValueFromParent(tag.tagName)
                mandatoryTags.push(tag)
            }
        }
        for (let i = 0; i < optionalTagsData.length; i++) {
            if (optionalTagsData[i].taggableElementTypes.includes("all") || optionalTagsData[i].taggableElementTypes.includes(props.elementType)) {
                let tag = {}
                let originalValue = props.qualityElement && props.qualityElement.tagList && props.qualityElement.tagList.find((tag) => tag.tagName === optionalTagsData[i].tagName)
                tag.tagName = optionalTagsData[i].tagName
                tag.tagValue = originalValue && originalValue.tagValue ? originalValue.tagValue : getValueFromParent(tag.tagName)
                optionalTags.push(tag)
            }
        }
        setMandatoryTags(mandatoryTags)
        setOptionalTags(optionalTags)
        setMandatoryTagsData(mandatoryTagsData)
        setOptionalTagsData(optionalTagsData)

    }, [props.qualityElement, props.parent, configuration])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.setMandatoryTags(mandatoryTags)
        props.setOptionalTags(optionalTags)
    }, [mandatoryTags, optionalTags])// eslint-disable-line react-hooks/exhaustive-deps


    // these next two functions are past down to sub element tagConfigurator to manage change in values
    function handleMandatoryChangeValue(value, name) {
        let newMandatoryTags = mandatoryTags
        let index = newMandatoryTags.findIndex(element => element.tagName === name)
        newMandatoryTags[index].tagValue = value
        setMandatoryTags(newMandatoryTags)
    }
    function handleOptionalChangeValue(value, name) {
        let newOptionalTags = optionalTags
        let index = newOptionalTags.findIndex(element => element.tagName === name)
        newOptionalTags[index].tagValue = value
        setOptionalTags(newOptionalTags)
    }


    return (
        <>
            <div elevation={12}
                style={{ minHeight: '365px', width: "100%" }}>
                <Typography variant='subtitle2' style={{ width: '100%', backgroundColor: commonStyles.mainColor, color: '#FFFFFF' }}>{languageContext.dictionary['mandatoryTags']}</Typography>
                <div style={{ marginRight: '5%', }}>
                    {mandatoryTagsData && mandatoryTagsData.map(element => {
                        if (element.taggableElementTypes.includes("all") || element.taggableElementTypes.includes(props.elementType)) {
                            let presetValue = mandatoryTags && mandatoryTags.find(tag => tag.tagName === element.tagName)
                            return (
                                <TagConfigurator key={"mandatory-" + JSON.stringify(element)} tag={element} presetValue={presetValue} handleChangeValue={handleMandatoryChangeValue} />
                            )
                        }
                        return <div />

                    })
                    }
                    {props.mandatoryErrorTags && <Typography style={{ color: commonStyles.errorColor }} variant='caption'>{props.mandatoryErrorTags}</Typography>}
                </div>
                <Typography variant='subtitle2' style={{ marginTop: '5px', width: '100%', backgroundColor: commonStyles.mainColor, color: '#FFFFFF' }}>{languageContext.dictionary['optionalTags']}</Typography>
                <div style={{ marginRight: '5%', }}>
                    {optionalTagsData && optionalTagsData.map(element => {
                        if (element.taggableElementTypes.includes("all") || element.taggableElementTypes.includes(props.elementType)) {
                            let presetValue = optionalTags && optionalTags.find(tag => tag.tagName === element.tagName)
                            return (
                                <TagConfigurator key={"optional-" + JSON.stringify(element)} tag={element} presetValue={presetValue} handleChangeValue={handleOptionalChangeValue} />
                            )
                        }
                        return <div />

                    })
                    }
                </div>
            </div >
        </>
    )
}