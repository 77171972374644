
import qualityLogin from '../apis/qualityLogin';
import quality from '../apis/quality';

//import qualityLocalHost3 from 'apis/qualityLocalHost3';
import {
  FETCH_ERROR,
  FETCH_USER,
} from './types'
//import {axiosHeader} from './../util/APIUtils'
import { axiosHeader, dispatchInfo, dispatchError, dispatchErrorSimple } from '../components/common/axios/axiosHelper';



export const fetchUser = (history, user) => {
 
  return async function (dispatch, getState) {

    console.log('user', user)
    if(user !== undefined){
      return 
    }
    await qualityLogin.get(`/user/me/`, {
      headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: FETCH_USER,
        payload: response.data
      });
      
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
        dispatch({
          type: FETCH_ERROR,
          payload: 'Error obtaining user information'
        });
        if(history){
          history.push("/login")
        }
        
      });
  }

};



export const dispatchErrorConnect = (message) => async (dispatch, getState) => {

  dispatchErrorSimple(dispatch, message)


}

export const createUser = (user) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/user/new/`, user, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    if (returnValue && returnValue !== null) {
      dispatchInfo(dispatch, `User created`)
    }
    else {
      dispatchError(dispatch, "Error creating user, email already exists")
    }


  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const updateUser = (user) => async (dispatch, getState) => {

  var returnValue = []
  await quality.post(`/user/update/`, user, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    if (returnValue && returnValue !== null) {
      dispatchInfo(dispatch, `User updated`)
    }
    else {
      dispatchError(dispatch, "Error creating user, email already exists")
    }



  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

