import React from "react";
import { loadCSS } from "fg-loadcss";
import {Button} from "@material-ui/core";
import { AqtivaDialogDataRepositoryRefactor } from './EntityDataRepository/AqtivaDialogDataRepository'
import { TabsBarRefactor } from './Tabs'
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import AppBarTemplate from "../../template/AppBarTemplate";
import * as pathHelper from "../../template/PathHelper";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions";
import { useHistory } from 'react-router-dom'

export default function DataRepositoryRefactor (props) {
  const [dialog, setDialog] = React.useState(true);
  const dispatch = useDispatch();
  const history = useHistory(); 

  const user = useSelector((store) => store.user);

  React.useEffect(() => {
    dispatch(actions.fetchUser(history));
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  return (
    <div>
      <AppBarTemplate
        history={props.history}
        title={"Data Repository"}
        arrayPath={pathHelper.getPathArray("Dashboard")}
      />
      <Button onClick={() => setDialog(true)}>CLICK TO OPEN DIALOG</Button>
      <AqtivaDialogDataRepositoryRefactor 
      maxWidth={"xlg"}
      fullWidth={true}
        dialog={dialog} 
        setDialog={setDialog} 
        title="Data Repository"
        titleIcon={faDatabase}
        confirmCallback={(event) => console.log("I am clicking here")}
        showConfirmButton={false}
      >
        <TabsBarRefactor user={user}/> {/* Este componente contiene las pestañas Project, Rule y DataSets */}
      </AqtivaDialogDataRepositoryRefactor>
    </div>
  );
}
