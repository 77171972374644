export function getDTOIdFromFolder(dtoData, folderId) {
  if (!folderId || !dtoData || dtoData.length < 1) {
    return undefined;
  }
  for (var i = 0; i < dtoData.length; i++) {
    if (dtoData[i]._id === folderId) {
      return dtoData[i]._id;
    }
    let dto = dtoData[i];
    if (dto.entityDataFolders && dto.entityDataFolders.length > 0) {
      for (var j = 0; j < dto.entityDataFolders.length; j++) {
        let entityDataFolder = dto.entityDataFolders[j];
        if (entityDataFolder._id === folderId) {
          return dto._id;
        }
      }
    }
  }
  return undefined;
}

export function getDTOIdFromFilePrivate(dtoData, fileId) {
  if (!fileId || !dtoData || dtoData.length < 1) {
    return undefined;
  }
  for (var i = 0; i < dtoData.length; i++) {
    if (dtoData[i]._id === fileId) {
      return dtoData[i]._id;
    }
    let dto = dtoData[i];
    if (
      dto.qualityEntityRepoVirtualDTOS &&
      dto.qualityEntityRepoVirtualDTOS.length > 0
    ) {
      for (var j = 0; j < dto.qualityEntityRepoVirtualDTOS.length; j++) {
        let p = dto.qualityEntityRepoVirtualDTOS[j];
        if (p._id === fileId) {
          return dto._id;
        }
      }
    }
  }
  return undefined;
}

export function getDTOIdFromFileShared(dtoData, fileId) {
  if (!fileId || !dtoData || dtoData.length < 1) {
    return undefined;
  }
  for (var i = 0; i < dtoData.length; i++) {
    if (dtoData[i]._id === fileId) {
      return dtoData[i]._id;
    }
    let dto = dtoData[i];
    if (dto.qualityEntityRepoDTOS && dto.qualityEntityRepoDTOS.length > 0) {
      for (var j = 0; j < dto.qualityEntityRepoDTOS.length; j++) {
        let p = dto.qualityEntityRepoDTOS[j];
        if (p._id === fileId) {
          return dto._id;
        }
      }
    }
  }
  return undefined;
}

export function getEntityIdFromFileInRepository(dtoData, selectedItem) {
  if (!selectedItem || !dtoData || dtoData.length < 1) {
    return undefined;
  }

  for (var i = 0; i < dtoData.length; i++) {
    let dto = dtoData[i];
    const qualityEntityRepo =
      dto.qualityEntityRepoVirtualDTOS || dto.qualityEntityRepoDTOS;
    if (qualityEntityRepo && qualityEntityRepo.length > 0) {
      for (var j = 0; j < qualityEntityRepo.length; j++) {
        let entity = qualityEntityRepo[j];
        if (entity._id === selectedItem) {
          return entity.entityId;
        }
      }
    }
  }
  return undefined;
}

export function getEntityIdFromFileInRepositoryShared(dtoData, selectedItem) {
  if (!selectedItem || !dtoData || dtoData.length < 1) {
    return undefined;
  }

  for (var i = 0; i < dtoData.length; i++) {
    let dto = dtoData[i];
    const qualityEntityRepo = dto.qualityEntityRepoDTOS;
    if (qualityEntityRepo && qualityEntityRepo.length > 0) {
      for (var j = 0; j < qualityEntityRepo.length; j++) {
        let entity = qualityEntityRepo[j];
        if (entity._id === selectedItem.itemId) {
          return entity._id;
        }
      }
    }
  }
  return undefined;
}

export function getFileShared(dtoData, fileId) {
  if (!fileId || !dtoData || dtoData.length < 1) {
    return undefined;
  }
  for (var i = 0; i < dtoData.length; i++) {
    if (dtoData[i]._id === fileId) {
      return dtoData[i];
    }
    let dto = dtoData[i];
    if (dto.qualityEntityRepoDTOS && dto.qualityEntityRepoDTOS.length > 0) {
      for (var j = 0; j < dto.qualityEntityRepoDTOS.length; j++) {
        let p = dto.qualityEntityRepoDTOS[j];
        if (p._id === fileId) {
          return dto.qualityEntityRepoDTOS[j];
        }
      }
    }
  }
  return undefined;
}

export function findAuthorNameFromId(license, userId) {
  if (license && license.users && license.users.length > 0 && userId) {
    let userFound = license.users.filter((x) => x.id === userId);

    if (userFound && userFound.length > 0) {
      return userFound[0].name;
    }
  }
  return "";
}
