
import React from 'react';
import { Card, Typography, IconButton, FormControl, InputLabel, NativeSelect, createStyles, makeStyles, Theme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import * as commonStyles from 'style/CommonStyles'
import AqtivaDialog from 'components/common/AqtivaDialog';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import DeleteIcon from '@material-ui/icons/Delete';


type IProps = {
  operator: string,
  opIndex?: number,
  setOperator: (value: string, index: number) => (void),
  enableDelete: boolean,
  deleteOperator: (index: number) => (void)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default function JoinOperator(props: IProps) {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = React.useState(false)
  const [operatorType, setOperatorType] = React.useState("columnOperator") //allowed values columnOperator or columnCondition

  //set state variable with init value
  React.useEffect(() => {
    setOperatorType(props.operator)
  }, [props.operator])

  function setOperatorTypeParent() {
    console.log(operatorType, props.opIndex !== undefined ? props.opIndex : -1)
    props.setOperator(operatorType, props.opIndex !== undefined ? props.opIndex : -1)
    closeDialogEdit()
  }

  function closeDialogEdit() {
    setOpenEdit(false)
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AqtivaDialog visible={openEdit}
        title={`Add Operator`}
        maxWidth='lg'
        fullWidth={true}
        titleIcon={faKey}
        cancelText={'Close'}
        showConfirmButton={true}
        confirmCallback={setOperatorTypeParent}
        cancelCallback={closeDialogEdit}
      >
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-helper">Select Join Operator Type</InputLabel>
          <NativeSelect
            value={operatorType ? operatorType : 'and'}
            onChange={event => setOperatorType(event.target.value)}
            inputProps={{
              name: 'age',
              id: 'age-native-helper',
            }}
          >

            <option value={'and'}>and</option>
            <option value={'or'}>or</option>
            <option value={'xor'}>xor</option>
            <option value={'xand'}>xand</option>
          </NativeSelect>
        </FormControl>

      </AqtivaDialog>
      <Card elevation={10} style={{ minHeight: '100px', height: '100%', width: '100%', backgroundColor: commonStyles.mainColor, border: '1px solid #FFFFFF' }} >
        <IconButton aria-label="settings" style={{ color: '#FFFFFF' }} onClick={event => setOpenEdit(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
        {props.enableDelete && <IconButton aria-label="settings" style={{ color: '#FFFFFF' }} onClick={event => props.deleteOperator(props.opIndex ? props.opIndex : 0)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        }
        <div style={{ display: 'flex', alignItems: 'center', height: '60%' }}>
          <Typography variant='caption' style={{ marginLeft: '5px', color: '#FFFFFF', fontWeight: 'bold' }} >{props.operator}</Typography>
        </div>

      </Card>
    </div>



  )

}