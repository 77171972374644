import React from 'react'
import MaterialTable from "@material-table/core";
import * as commonStyles from 'style/CommonStyles'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import * as actions from 'actions'
import { useDispatch } from 'react-redux'



export default function DataformatSchemaTable(props) {
    

    const dispatch = useDispatch()
    const [ data, setData] = React.useState(props.data)
    const [dataformat, setDataformat] = React.useState(props.dataformat)


    React.useEffect(()=>{
        setData(props.data)
        setDataformat(props.dataformat)
    }, [props.data, props.dataformat])

    function saveChanges(newColumns){
        dataformat.columns = newColumns
        dispatch(actions.editDataformat(dataformat))
    }

    return(
        <div style={{ marginTop: '10px' }}>
        {data && <MaterialTable
            style={{ width: "100%" }}
            title="Datasets"
            data={data}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            setData([...data, newData]);
                            resolve();
                        }, 1000)
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);
                            saveChanges(dataUpdate)
                            resolve();
                        }, 1000)
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataDelete = [...data];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            let columns = JSON.parse(JSON.stringify(dataDelete))
                            for (let i = 0; i < columns.length; i++) {
                                columns[i].position = i
                            }
                            setData([...columns]);
                            saveChanges(columns)
                            resolve()

                        }, 1000)
                    }),
            }}
            columns={
                [
                    { title: 'Position', field: 'position', editable: 'never' },
                    { title: 'Name', field: 'name' },
                    { title: 'Format', field: 'type', lookup: { Date: 'Date', Float: 'Float', Integer: 'Integer', String: 'String',
                    string: 'String', integer: 'Integer', float: 'Float', double: 'Double', date: 'Date', boolean: 'Boolean', number:'Number'
                 }, },
                ]}
            options={{
                actionsColumnIndex: -1,
                filtering: false,
                search: false,
                pageSize: 5,
                maxBodyHeight: '90%',
                minBodyHeight: 250,
                headerStyle: {
                    fontSize: 'calc(8px + 0.2vw)',
                    backgroundColor: commonStyles.mainColor,
                    color: '#FFF'
                },
                cellStyle: { 
                    fontSize: 'calc(8px + 0.2vw)',
                },
                filterCellStyle: {
                    fontSize: 'calc(8px + 0.2vw)',
                },
            }}
            icons={{
                Edit: () => <EditIcon style={{ color: commonStyles.mainColor }} />,
                Delete: () => <DeleteIcon style={{ color: commonStyles.mainColor }} />,
                Save: () => <EditIcon style={{ color: commonStyles.mainColor }} />

            }}
            

            components={{
                Toolbar: props => (
                    <div>

                    </div>
                ),
            }}
        />}
    </div>
    )
}