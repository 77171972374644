import quality from '../apis/quality';
import {
  FETCH_RULES_DATA_REPOSITORY,
  FETCH_RULES_DATA_REPOSITORY_SHARED,
  EDIT_RULES_DATA_REPOSITORY,
  EDIT_RULES_DATA_REPOSITORY_SHARED,
  DELETE_RULES_DATA_REPOSITORY,
  DELETE_RULES_DATA_REPOSITORY_SHARED
} from './types'
import { axiosHeader, dispatchError, dispatchInfo } from '../components/common/axios/axiosHelper';
//import qualityLocalHost from '../apis/qualityLocalHost';



export const fetchRulestDataRepositoryPrivateWorkspace = (userId) => async (dispatch, getState) => {


  await quality.get(`/data/repository/rule/privateworkspace/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: FETCH_RULES_DATA_REPOSITORY,
      payload: response.data
    });

  })
    .catch(function (error) {
      dispatchError(dispatch, error)
    });



};

export const fetchRulesDataRepositorySharedWorkspace = (userId) => async (dispatch, getState) => {


  await quality.get(`/data/repository/rule/sharedworkspace/${userId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: FETCH_RULES_DATA_REPOSITORY_SHARED,
      payload: response.data
    });

  })
    .catch(function (error) {
      dispatchError(dispatch, error)
    });



};


export const addFolderToRepositoryRules = (type, name, dtoId, parentId,visibility) => async (dispatch, getState) => {
    await quality
      .post(
        `/data/repository/rule/folder/${type}/add/${name}/${dtoId}/${parentId}/${visibility}`,null,
        {
          headers: axiosHeader(),
        }
      )
      .then(function (response) {
          const actionsType = 
              type === 'private' 
                  ? EDIT_RULES_DATA_REPOSITORY 
                  : EDIT_RULES_DATA_REPOSITORY_SHARED
              dispatch({
                  type: actionsType,
                  payload: response.data,
              });
              dispatchInfo(dispatch, `Folder ${name} was succesfully created`);
      })
      .catch(function (error) {
        dispatchError(dispatch, error);
      });
  };
  
  
  export const addRootFolderToRepositoryRules = (type, name, userId, visibility) => async (dispatch,getState) => {
      await quality
        .post(
          `/data/repository/rule/folder/${type}/add/root/${name}/${userId}/${visibility}`,undefined,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
            const actionsType = 
                type === 'private' 
                    ? EDIT_RULES_DATA_REPOSITORY 
                    : EDIT_RULES_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: response.data,
                });
                dispatchInfo(dispatch, `Folder ${name} succesfully created in root`);
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };
  
  
    export const deleteParentFolderFromRepositoryRules = (type, dtoId, folderId) => async (dispatch,getState) => {
      await quality
        .delete(
          `/data/repository/rule/folder/delete/nobackup/${type}/${dtoId}/${folderId}`,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
          if(dtoId===folderId){
            const actionsType = 
                type === 'private' 
                    ? DELETE_RULES_DATA_REPOSITORY 
                    : DELETE_RULES_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: dtoId,
                });
                dispatchInfo(dispatch, 'Root folder deleted');
          }
          else{
            const actionsType = 
            type === 'private' 
                ? EDIT_RULES_DATA_REPOSITORY 
                : EDIT_RULES_DATA_REPOSITORY_SHARED
            dispatch({
                type: actionsType,
                payload: response.data,
            });
            dispatchInfo(dispatch, 'Folder deleted');
          }
            
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };


  //Nuevo action para enviar archivos a default

     export const deleteFolderFromRepositoryAndSaveRules = (type, dtoId, folderId, defaultId) => async (dispatch,getState) => {
      await quality
        .delete(
          `/data/repository/rule/folder/delete/backup/${type}/dtoId/${dtoId}/folderId/${folderId}/defaultId/${defaultId}`,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
          if(dtoId===folderId){
            const actionsType = 
                type === 'private' 
                    ? DELETE_RULES_DATA_REPOSITORY 
                    : DELETE_RULES_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: dtoId,
                });
                dispatchInfo(dispatch, 'Root folder deleted');
          }
          else{
            const actionsType = 
            type === 'private' 
                ? EDIT_RULES_DATA_REPOSITORY 
                : EDIT_RULES_DATA_REPOSITORY_SHARED
            dispatch({
                type: actionsType,
                payload: response.data,
            });
            dispatchInfo(dispatch, 'Folder deleted');
          }
            
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    }; 
  

    export const deleteFileFromRepositoryRules = (type, ruleId, userId) => async (dispatch,getState) => {
      await quality
        .delete(
          `/data/repository/rule/delete/${type}/${ruleId}/user/${userId}`,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
          const actionsType = 
          type === 'private' 
              ? EDIT_RULES_DATA_REPOSITORY 
              : EDIT_RULES_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: response.data,
                });
                dispatchInfo(dispatch, 'File deleted');
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };



    export const exportFileFromRepositoryRules = (userId, dtoId, folderId, dtoIdOr,fileIdOr, ruleId) => async (dispatch,getState) => {
      await quality
        .post(
          `/data/repository/rule/export/user/${userId}/dto/${dtoId}/folder/${folderId}/rule/${ruleId}/or/dto/${dtoIdOr}/entity/${fileIdOr}`, undefined,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
                dispatch({
                    type: EDIT_RULES_DATA_REPOSITORY_SHARED,
                    payload: response.data,
                });
                dispatchInfo(dispatch, `File succesfully exported`);
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };
  
  
  
    export const importFileToRepositoryRules = (userId, dtoIdOr, dtoIdDst,entityIdOr, entityIdDst, ruleId) => async (dispatch,getState) => {
      await quality
        .post(
          `/data/repository/rule/import/user/${userId}/dto/or/dto/${dtoIdOr}/entity/${entityIdOr}/dst/dto/${dtoIdDst}/entity/${entityIdDst}/rule/${ruleId}`, undefined,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
                dispatch({
                    type: EDIT_RULES_DATA_REPOSITORY,
                    payload: response.data,
                });
                dispatchInfo(dispatch, `File succesfully imported`);
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
        dispatchInfo(dispatch, 'Folder deleted');
      }

    



