import React, { useEffect, useState } from 'react';
import AqtivaDialogParseAndEncryptSasToken from 'components/common/AqtivaDialogParseAndEncryptSasToken'
import { Grid, FormControlLabel, TextField,  Typography } from '@material-ui/core';

export default function SasTokenConnectionInfo(props) {

  
    const [visibleConnectionString, setVisibleConnectionString] = useState('')
    const [storageAccountName, setStorageAccountName] = useState('')
    const [containerName, setContainername] = useState('')
    const [encryptedSasTokenInfo, setEncryptedSasTokenInfo] = useState(undefined)

    useEffect(() => {
        let connectionObject = {}
        connectionObject.sharedAccessSignature = encryptedSasTokenInfo && encryptedSasTokenInfo.sharedAccessSignature ? encryptedSasTokenInfo.sharedAccessSignature : ''
        connectionObject.expirationDate = encryptedSasTokenInfo && encryptedSasTokenInfo.expirationDate ? encryptedSasTokenInfo.expirationDate : ''
        connectionObject.storageAccountName = storageAccountName
        if (props.resourceType && props.resourceType === "azure_container") {
            connectionObject.folder = containerName
        }

        props.setConnectionObjectSas(connectionObject)

    }, [encryptedSasTokenInfo, storageAccountName])// eslint-disable-line react-hooks/exhaustive-deps








    return (
        <div style={{ width: '100%' }}>
            <AqtivaDialogParseAndEncryptSasToken visible={visibleConnectionString}
                connectionMethod={props.connectionMethod}
                setVisible={setVisibleConnectionString}
                confirm={setEncryptedSasTokenInfo}
                sName={props.id && props.id + "localsttkk"}
                resourceType={props.resourceType}
            />

            <Grid item xs={12}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display name:</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                    control={
                        <TextField style={{ width: '100%' }}
                            readonly
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            autoComplete="new-password"
                            id="DisplayName"
                            label="Display name"
                            margin="dense"
                            value={props.displayName}
                            onChange={(event) => { props.setDisplayName(event.target.value) }}
                        />} autoComplete="new-password" />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Storage account name:</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                    control={
                        <TextField style={{ width: '100%' }}
                            readonly
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            autoComplete="new-password"
                            id="Storage account name"
                            label="Storage account name"
                            margin="dense"
                            value={props.connectionObjectSas && props.connectionObjectSas.storageAccountName ? props.connectionObjectSas.storageAccountName : storageAccountName}
                            onChange={(event) => { setStorageAccountName(event.target.value) }}
                        />} autoComplete="new-password" />
            </Grid>
            {props.resourceType && props.resourceType === "azure_container" && <>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Container name name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Container name"
                                label="Container name"
                                margin="dense"
                                value={props.connectionObjectSas && props.connectionObjectSas.folder ? props.connectionObjectSas.folder : containerName}
                                onChange={(event) => { setContainername(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
            </>}

            <Grid item xs={12}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>SAS Token:</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                    autoComplete="new-password"
                    control={
                        <TextField style={{ width: '100%' }}
                            autoComplete="new-password"
                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                            id="storageKey"
                            label="SAS Token"
                            margin="dense"
                            type="password"
                            value={props.connectionObjectSas && props.connectionObjectSas.sharedAccessSignature ? props.connectionObjectSas.sharedAccessSignature : encryptedSasTokenInfo && encryptedSasTokenInfo.sharedAccessSignature ? encryptedSasTokenInfo.sharedAccessSignature : ''}
                            onClick={(event) => { setVisibleConnectionString(true) }}
                        />} />
            </Grid>
        </div>

    )
}