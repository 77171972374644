import React from 'react';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import * as commonStyles from 'style/CommonStyles'


export default function RadarDimensionsAmChart(props) {
    const fontFamily = commonStyles.fontFamily
    const [ fontSizeSmall , setFontSizeSmall] = React.useState(props.fullscreen=== true ? 15: 12)
    const [ fontSize , setFontSize ]= React.useState(props.fullscreen=== true ? 20: 17)

        React.useEffect(()=>{
            setFontSizeSmall(props.fullscreen=== true ? 15: 12)
            setFontSize(props.fullscreen=== true ? 20: 17)
        },[props.fullscreen])

    
    

    function parseData(data) {
        if (data === null || data === undefined) {
            return []
        }
        else {
            return reduceBasedOnSubject(data.map(value => {
                var newValue = JSON.parse(JSON.stringify(value))
                newValue.subject = parseDataLabels(value)
                return newValue
            }))
        }

    }

    function reduceBasedOnSubject(data) {
        var result = [];
        data.reduce(function (res, value) {
            if (!res[value.subject]) {
                res[value.subject] = value
                result.push(res[value.subject])
            }
            res[value.subject].value += value.value;

            return res;
        }, {})

        return result
    }
    function parseDataLabels(data) {
        if (data.subject === 'CELL_NULL') return 'Null'
        if (data.subject === 'cell_range') return 'Value Range'
        if (data.subject === 'DUPLICATED') return 'Duplicated'
        if (data.subject === 'cell_column_comparison') return 'Column Comparison'
        if (data.subject === 'statistics') return 'Format'
        if (data.subject === 'CELL_EXP') return 'Regex'
        if (data.subject === 'time_diff') return 'Time Check'
        if (data.subject === 'cell_anomaly_rows') return 'Anomaly Rows'
        if (data.subject === 'cell_anomaly_columns') return 'Anomaly Columns'
        return capitalizeFirstLetter(data.subject)
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    React.useLayoutEffect(() => {
        const data = parseData(props.data)

        const fullMark = data.length>0 ? data[0].fullMark : 0


        var chart = am4core.create(props.fullscreen === true ? "radarChartFullScreen"+props.id : "radarChart"+props.id, am4charts.RadarChart);
        // Add data
        chart.data = data
        chart.cursor = new am4charts.RadarCursor();
        chart.cursor.behavior = 'none';
        chart.cursor.lineY.disabled = true;
        chart.cursor.fullWidthLineX = true;
        chart.cursor.lineX.strokeWidth = 0;
        chart.cursor.lineX.fillOpacity = 0.1;
        chart.zoomOutButton.disabled = true;

        /* Create axes */
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "subject";
        categoryAxis.renderer.labels.template.fontSize = fontSizeSmall;
        categoryAxis.renderer.labels.template.fontFamily = fontFamily;
        categoryAxis.renderer.ticks.disabled = false;
        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
        categoryAxis.renderer.ticks.template.strokeWidth = 1;
        categoryAxis.renderer.ticks.template.length = 5;
        categoryAxis.renderer.tooltipLocation = 0.001;
        categoryAxis.tooltip.fontSize = fontSize;
        categoryAxis.tooltip.fontFamily = fontFamily;
        categoryAxis.tooltip.getFillFromObject = false;
        categoryAxis.tooltip.autoTextColor = false;
        categoryAxis.tooltip.background.strokeWidth = 0;
        categoryAxis.tooltip.background.cornerRadius = 3;
        categoryAxis.tooltip.background.paddingTop = 3;
        categoryAxis.tooltip.background.paddingBottom = 2;
        categoryAxis.tooltip.zIndex = 1;


        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.axisFills.template.fill = chart.colors.getIndex(2);
        valueAxis.renderer.axisFills.template.fillOpacity = 0.05;
        valueAxis.renderer.labels.template.fontSize = fontSizeSmall;
        valueAxis.renderer.labels.template.fontFamily = fontFamily;
        valueAxis.min=0
        valueAxis.max=fullMark
        
        
        /* Create and configure series */
        var series = chart.series.push(new am4charts.RadarSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "subject";
        series.name = "Sales";
        series.strokeWidth = 1;
        series.fillOpacity = 0.2;
        series.tooltipText = "{categoryX} : [bold]{valueY}[/]"
  
        series.tooltip.fontSize = fontSize;
        series.tooltip.fontFamily = fontFamily;
        series.tooltip.zIndex = 2;
        series.tooltip.label.wrap = true;
        series.stroke = props.strokeColor
        series.fill = props.fillColor

        if(props.fullscreen === true){
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [{
                "label": "...",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "json", "label": "JSON" },
                  { "type": "csv", "label": "CSV" },
                  { "label": "Print", "type": "print" }
                ]
              }];
        }

        return () => {
            chart.dispose();
        };

    }, [props.data,fontSizeSmall, fontSize])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div id={props.fullscreen === true ? "radarChartFullScreen"+props.id : "radarChart"+props.id} style={{ width: '90%', height: '90%' }} ></div>
    )

}