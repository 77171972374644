import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import * as colorHelper from './../colorHelper'



export default function DQIDimensionsChartDetail(props) {


    React.useLayoutEffect(() => {
        if(props.data ){
        var chart = am4core.create(props.id, am4charts.XYChart);
        var labelBullet
        chart.data = 
            [{
                "country": "",
                "value": props.data >=0 ? props.data : 0,
                "base": 100
            },
            ]

            chart.titles.template.isMeasured = false;
            // Create axes
            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "country";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.renderer.grid.template.disabled = true;
            categoryAxis.renderer.labels.template.disabled = true;
            categoryAxis.cursorTooltipEnabled = false;
            
            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.max = 100; 
            valueAxis.renderer.grid.template.disabled = true;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.cursorTooltipEnabled = false;
            
            var series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.valueX = "base";
            series2.dataFields.categoryY = "country";
            series2.clustered = false;
            series2.columns.template.width = am4core.percent(50);
            series2.fill = (props.data ===0 ) ? 
            am4core.color(colorHelper.getColorForPercentage(1)) : am4core.color(colorHelper.getColorForPercentage(0));
            series2.stroke = am4core.color('white');
            if(props.data<0){

            
            labelBullet = series2.bullets.push(new am4charts.LabelBullet())
            labelBullet.label.horizontalCenter = "left";
            labelBullet.label.dx = 10;
            labelBullet.label.text ="[font-size:12px]No data"
            
            labelBullet.label.fill = am4core.color('white');
            labelBullet.locationX = 1;
        }
            // Create series
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = "value";
            series.dataFields.categoryY = "country";
            series.clustered = false;
            series.fill = am4core.color(colorHelper.getColorForPercentage(props.data));
            series.stroke = am4core.color('white');
            if(props.data>=0){
            labelBullet = series.bullets.push(new am4charts.LabelBullet())
            labelBullet.label.horizontalCenter = "left";
            labelBullet.label.dx = 10;
            labelBullet.label.text =  "[font-size:12px]{values.valueX.workingValue.formatNumber('#.00as')}%";
            labelBullet.label.fill = am4core.color('white');
            labelBullet.locationX = 1;
            }

            chart.cursor = new am4charts.XYCursor();
            chart.cursor.lineY.disabled = true;
            chart.cursor.lineX.disabled = true;

        return () => {
            chart.dispose();
        };
    }
    }, [props.data]);// eslint-disable-line react-hooks/exhaustive-deps


   
        return (
                <div id={props.id} style={{marginTop:'-5%', width: "100%", height: "65%" }}></div>
               
           
        )
      
   
}
