export const mock = [

  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/03 15:56:48',
    'execution_id': ' 1656950202aDvBtm0hO1',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 999,
    'num_out': 211,
    'num_ok': 211,
    'num_ko': 788,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-03T15:56:48.450Z',
    '_modifiedTS': '2022-07-03T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/04 15:56:48',
    'execution_id': ' 1656950202aDvBtm0hO2',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 993,
    'num_out': 200,
    'num_ok': 150,
    'num_ko': 843,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-04T15:56:48.450Z',
    '_modifiedTS': '2022-07-04T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/02 15:56:48',
    'execution_id': ' 1656950202aDvBtm0hO3',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 894,
    'num_out': 788,
    'num_ok': 788,
    'num_ko': 211,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-02T15:56:48.450Z',
    '_modifiedTS': '2022-07-02T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/01 15:56:48',
    'execution_id': ' 1656950202aDvBtm0hO4',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': 'af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': '4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 999,
    'num_out': 211,
    'num_ok': 211,
    'num_ko': 788,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-01T15:56:48.450Z',
    '_modifiedTS': '2022-07-01T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/05 15:56:48',
    'execution_id': ' 1656950202aDvBtm0hO9',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': 'af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': '4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 999,
    'num_out': 211,
    'num_ok': 211,
    'num_ko': 788,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-01T15:56:48.450Z',
    '_modifiedTS': '2022-07-01T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/06 15:56:48',
    'execution_id': ' 1656950202a2DvBtm0hO5',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 700,
    'num_out': 211,
    'num_ok': 211,
    'num_ko': 489,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-04T15:56:48.450Z',
    '_modifiedTS': '2022-07-04T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/07 15:56:48',
    'execution_id': ' 16569530202aDvBtm0hO5',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 500,
    'num_out': 489,
    'num_ok': 489,
    'num_ko': 11,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-04T15:56:48.450Z',
    '_modifiedTS': '2022-07-04T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/08 15:56:48',
    'execution_id': ' 13656950202aDvBtm0hO5',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 900,
    'num_out': 211,
    'num_ok': 489,
    'num_ko': 211,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-04T15:56:48.450Z',
    '_modifiedTS': '2022-07-04T15:56:48.450Z'
  }
]

export const mockDrill = [

  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/03 15:56:48',
    'execution_id': ' 1656950202aDvBtm0hO1',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 999,
    'num_out': 211,
    'num_ok': 211,
    'num_ko': 788,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-03T15:56:48.450Z',
    '_modifiedTS': '2022-07-03T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/04 15:56:48',
    'execution_id': ' 1656950202aDvBtm0hO2',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 993,
    'num_out': 200,
    'num_ok': 150,
    'num_ko': 843,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-04T15:56:48.450Z',
    '_modifiedTS': '2022-07-04T15:56:48.450Z'
  },
  {
    '_id': '62c30dc083c66e029259eaed',
    'environment': 'demo_sas_env',
    'timestamp': '2022/07/08 15:56:48',
    'execution_id': ' 13656950202aDvBtm0hO5',
    'simulation_id': ' f404ebc7-66a3-46d3-bd96-f296ba019363',
    'user_id': ' 5f855b3d9ce8c446555da24b',
    'type': ' DATA_SOURCE',
    'version': ' 0.1',
    'level': ' WARNING',
    'dimension': ' ',
    'quality_point_id': ' af577e46-c34a-4b49-b44c-c6a9981655f2',
    'data_source_id': ' 4f9578eb-2ceb-4466-b932-454278907caa',
    'rule_id': ' ',
    'rule_name': ' ',
    'rule_type': ' ',
    'rule_subtype': ' ',
    'num_in': 900,
    'num_out': 211,
    'num_ok': 489,
    'num_ko': 211,
    'num_warn': 0,
    'ts_window': ' null',
    'msg': ' ',
    '_insertedTS': '2022-07-04T15:56:48.450Z',
    '_modifiedTS': '2022-07-04T15:56:48.450Z'
  }
]

export const mockProyectos = [
  {
    'projectName': 'testAzureSchedule',
    'projectId': '615480a0977b322397f74d37',
    'qpIds': [
      {
        'id': 'd927bce2-3818-46e7-998a-a73cc3749378',
        'name': 'testQP'
      }
    ],
    'dsIds': [
      {
        'id': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
        'name': 'Innotest',
        'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378'
      }
    ],
    'ruleIds': [
      {
        'id': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
        'name': 'schema',
        'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5'
      }
    ],
    'ownerId': null,
    'sumNumIn': 350,
    'sumNumOut': 350,
    'sumNumOk': 300,
    'sumNumKo': 50
  },
  {
    'projectName': 'testDuplicated',
    'projectId': '62a09fafcc246b78fc48c069',
    'qpIds': [
      {
        'id': 'd38b6321-6873-42a3-8dff-a0d3f2652933',
        'name': 'DuplicatedQP'
      }
    ],
    'dsIds': [
      {
        'id': '1b975e60-98ee-4427-acff-8b27b8ab3492',
        'name': 'renfeDatasource',
        'parentId': 'd38b6321-6873-42a3-8dff-a0d3f2652933'
      },
      {
        'id': '86158319-0801-4716-9ec5-67d9fc6bccae',
        'name': 'pepsicoDup',
        'parentId': 'd38b6321-6873-42a3-8dff-a0d3f2652933'
      },
      {
        'id': '2b6ae39f-7f80-4822-b850-d27281694ccc',
        'name': 'localPep',
        'parentId': 'd38b6321-6873-42a3-8dff-a0d3f2652933'
      },
      {
        'id': '6128b2b1-3252-4468-8913-486e81f182f6',
        'name': 'part1pepsico',
        'parentId': 'd38b6321-6873-42a3-8dff-a0d3f2652933'
      }
    ],
    'ruleIds': [
      {
        'id': 'e55b8c7e-297b-46de-9391-1fb4bd2a6226',
        'name': 'schemaRule',
        'parentId': '1b975e60-98ee-4427-acff-8b27b8ab3492'
      },
      {
        'id': '55c2ef56-05f7-43fb-b458-b182b5b0490d',
        'name': 'duplicateRule',
        'parentId': '1b975e60-98ee-4427-acff-8b27b8ab3492'
      },
      {
        'id': '07224033-aa86-4ee2-b9bd-25e3677e308f',
        'name': 'testrule',
        'parentId': '1b975e60-98ee-4427-acff-8b27b8ab3492'
      },
      {
        'id': '685c778d-f480-41fe-9606-62609390f5ce',
        'name': 'schema',
        'parentId': '86158319-0801-4716-9ec5-67d9fc6bccae'
      },
      {
        'id': 'f4265e9a-cc98-4cf4-98ad-acd228082883',
        'name': 'duplicateRule',
        'parentId': '86158319-0801-4716-9ec5-67d9fc6bccae'
      },
      {
        'id': '0e967050-fdf9-4712-8b1c-951cf0756529',
        'name': 'schema',
        'parentId': '2b6ae39f-7f80-4822-b850-d27281694ccc'
      },
      {
        'id': '77b32185-d08f-4f87-83b9-4fa24da6fce0',
        'name': 'duplicateRule',
        'parentId': '2b6ae39f-7f80-4822-b850-d27281694ccc'
      },
      {
        'id': 'b1bb7a88-dac3-4f1a-9492-9b3a50ddb125',
        'name': 'schema',
        'parentId': '6128b2b1-3252-4468-8913-486e81f182f6'
      },
      {
        'id': 'fcc486d5-fe4f-468c-aadb-2a8a7c6953f7',
        'name': 'duplicateRule',
        'parentId': '6128b2b1-3252-4468-8913-486e81f182f6'
      }
    ],
    'ownerId': null,
    'sumNumIn': 9229,
    'sumNumOut': 9229,
    'sumNumOk': 9029,
    'sumNumKo': 200
  }
]


export const mockDetalleProyectos = [
  {
    'projectId': '615480a0977b322397f74d37',
    'name': 'testAzureSchedule',
    'sumNumIn': 350,
    'sumNumOut': 350,
    'sumNumOk': 350,
    'sumNumKo': 0,
    'dimensionDQIList': [
      {
        'dimension': 'format',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0
      }
    ],
    'qualityPointDQI': [
      {
        'qualityPointId': 'd927bce2-3818-46e7-998a-a73cc3749344',
        'name': 'testQP',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0,
        'dimensionDQIList': [
          {
            'dimension': 'format',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'datasourceDQI': [
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b1',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest1',
            'sumNumIn': 450,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 100,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          },
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b2',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest2',
            'sumNumIn': 550,
            'sumNumOut': 450,
            'sumNumOk': 450,
            'sumNumKo': 50,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '13',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '13',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          },
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b3',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest3',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '14',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          },
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b4',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest4',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '15',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }
        ],
        'dayQpDQIList': [
          {
            'qpId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'year': '2022',
            'month': '7',
            'day': '12',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'ruleDQIList': [
          {
            'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ]
      },
      {
        'qualityPointId': 'd927bce2-3818-46e7-998a-a73cc3749356',
        'name': 'testQP2',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0,
        'dimensionDQIList': [
          {
            'dimension': 'format',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'datasourceDQI': [
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }, {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b1',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }, {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b2',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }, {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b3',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          },
        ],
        'dayQpDQIList': [
          {
            'qpId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'year': '2022',
            'month': '7',
            'day': '13',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'ruleDQIList': [
          {
            'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ]
      },
      {
        'qualityPointId': 'd927bce2-3818-46e7-998a-a73cc3749367',
        'name': 'testQP3',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0,
        'dimensionDQIList': [
          {
            'dimension': 'format',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'datasourceDQI': [
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b1',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }, {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b2',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }, {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b3',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }, {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b54',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          },
        ],
        'dayQpDQIList': [
          {
            'qpId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'year': '2022',
            'month': '7',
            'day': '14',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'ruleDQIList': [
          {
            'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ]
      }
    ],
    'dayDQIList': [
      {
        'year': '2022',
        'month': '7',
        'day': '12',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0
      }
    ],
    'ruleDQIList': [
      {
        'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0
      }
    ]
  },
  {
    'projectId': '62a09fafcc246b78fc48c069',
    'name': 'testDuplicated',
    'sumNumIn': 350,
    'sumNumOut': 350,
    'sumNumOk': 350,
    'sumNumKo': 0,
    'dimensionDQIList': [
      {
        'dimension': 'format',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0
      }
    ],
    'qualityPointDQI': [
      {
        'qualityPointId': 'd927bce2-3818-46e7-998a-a73cc3749378',
        'name': 'testQPP2',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0,
        'dimensionDQIList': [
          {
            'dimension': 'format',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'datasourceDQI': [
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              },
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f1856',
                'name': 'schema1',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          },
          {
            'datasourceId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b6',
            'parentId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'name': 'Innotest1',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0,
            'dimensionDQIList': [
              {
                'dimension': 'format',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQI': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'name': 'schema',
                'parentId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0,
                'dimensionDQIList': [
                  {
                    'dimension': 'format',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ],
                'dayRuleDQIList': [
                  {
                    'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                    'year': '2022',
                    'month': '7',
                    'day': '12',
                    'sumNumIn': 350,
                    'sumNumOut': 350,
                    'sumNumOk': 350,
                    'sumNumKo': 0
                  }
                ]
              }
            ],
            'dayDsDQIList': [
              {
                'dsId': '2b5043f6-15ce-4c83-9e2d-dc0d6d84c3b5',
                'year': '2022',
                'month': '7',
                'day': '12',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ],
            'ruleDQIList': [
              {
                'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
                'sumNumIn': 350,
                'sumNumOut': 350,
                'sumNumOk': 350,
                'sumNumKo': 0
              }
            ]
          }
        ],
        'dayQpDQIList': [
          {
            'qpId': 'd927bce2-3818-46e7-998a-a73cc3749378',
            'year': '2022',
            'month': '7',
            'day': '12',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ],
        'ruleDQIList': [
          {
            'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
            'sumNumIn': 350,
            'sumNumOut': 350,
            'sumNumOk': 350,
            'sumNumKo': 0
          }
        ]
      }
    ],
    'dayDQIList': [
      {
        'year': '2022',
        'month': '7',
        'day': '12',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0
      }
    ],
    'ruleDQIList': [
      {
        'ruleId': '681a20f7-1a5e-40ee-ade0-6a4afa0f185e',
        'sumNumIn': 350,
        'sumNumOut': 350,
        'sumNumOk': 350,
        'sumNumKo': 0
      }
    ]
  },
]

export const mockExecution = [
  {
    "_id" : "62e7c79de51938656b7f10c1",
    "environment" : "demo_sas_env",
    "timestamp" : "2022/08/01 12:31:25",
    "execution_id" : "1659357081QeuzhokPJb",
    "simulation_id" : "8558b1e3-d6c1-48ae-bbd6-1c3d310a5ac2",
    "user_id" : "",
    "type" : "QUALITY_RULE",
    "version" : "0.1",
    "level" : "WARNING",
    "dimension" : "integrity",
    "quality_point_id" : "1b3f8c5b-9b0d-4b34-a230-398081b68b12",
    "data_source_id" : "4e2760b3-8e8b-4f7f-bf23-fde80db6e79f",
    "dataset_id" : "605da85ab48d5a01a3ff8861",
    "rule_id" : "4af2e73b-9178-4ec5-b36d-7de8112565c9",
    "rule_name" : "nulls",
    "rule_type" : "cell_based",
    "rule_subtype" : "cell_null",
    "num_in" : 200000,
    "num_out" : 199252,
    "num_ok" : 199252,
    "num_ko" : 748,
    "num_warn" : 0,
    "ts_window" : "null",
    "processingTime" : 2,
    "msg" : "Filter query:   (`vehicle_class` is null or cast(`vehicle_class` as string) == ‘’) or  `vehicle_class` == ‘’ ",
    "_insertedTS" : "2022-08-01T12:31:25.669Z",
    "_modifiedTS" : "2022-08-01T12:31:25.669Z"
},
{
    "_id" : "62e7c7a1e51938656b7f10c2",
    "environment" : "demo_sas_env",
    "timestamp" : "2022/08/01 12:31:29",
    "execution_id" : "1659357081QeuzhokPJb",
    "simulation_id" : "8558b1e3-d6c1-48ae-bbd6-1c3d310a5ac2",
    "user_id" : "",
    "type" : "QUALITY_RULE",
    "version" : "0.1",
    "level" : "INFO",
    "dimension" : "format",
    "quality_point_id" : "1b3f8c5b-9b0d-4b34-a230-398081b68b12",
    "data_source_id" : "4e2760b3-8e8b-4f7f-bf23-fde80db6e79f",
    "dataset_id" : "605da85ab48d5a01a3ff8861",
    "rule_id" : "04a0d184-583d-4868-83b4-10ca28e3749c",
    "rule_name" : "schema",
    "rule_type" : "statistics",
    "rule_subtype" : "statistics",
    "num_in" : 199252,
    "num_out" : 199252,
    "num_ok" : 199252,
    "num_ko" : 0,
    "num_warn" : 0,
    "ts_window" : "null",
    "processingTime" : 0,
    "msg" : "",
    "_insertedTS" : "2022-08-01T12:31:29.230Z",
    "_modifiedTS" : "2022-08-01T12:31:29.230Z"
},
{
    "_id" : "62e7c7a1e51938656b7f10c3",
    "environment" : "demo_sas_env",
    "timestamp" : "2022/08/01 12:31:29",
    "execution_id" : "1659357081QeuzhokPJb",
    "simulation_id" : "8558b1e3-d6c1-48ae-bbd6-1c3d310a5ac2",
    "user_id" : "5d829501ee51b877f859a29c",
    "type" : "DATA_SOURCE",
    "version" : "0.1",
    "level" : "WARNING",
    "dimension" : "",
    "quality_point_id" : "1b3f8c5b-9b0d-4b34-a230-398081b68b12",
    "data_source_id" : "4e2760b3-8e8b-4f7f-bf23-fde80db6e79f",
    "dataset_id" : "605da85ab48d5a01a3ff8861",
    "rule_id" : "",
    "rule_name" : "",
    "rule_type" : "",
    "rule_subtype" : "",
    "num_in" : 200000,
    "num_out" : 199252,
    "num_ok" : 199252,
    "num_ko" : 748,
    "num_warn" : 0,
    "ts_window" : "null",
    "processingTime" : 7,
    "msg" : "",
    "_insertedTS" : "2022-08-01T12:31:29.456Z",
    "_modifiedTS" : "2022-08-01T12:31:29.456Z"
},

{
    "_id" : "62e7c7a1e51938656b7f10c4",
    "environment" : "demo_sas_env",
    "timestamp" : "2022/08/01 12:31:29",
    "execution_id" : "1659357081QeuzhokPJb",
    "simulation_id" : "8558b1e3-d6c1-48ae-bbd6-1c3d310a5ac2",
    "user_id" : "5d829501ee51b877f859a29c",
    "type" : "QUALITY_POINT",
    "version" : "0.1",
    "level" : "INFO",
    "dimension" : "",
    "quality_point_id" : "1b3f8c5b-9b0d-4b34-a230-398081b68b12",
    "data_source_id" : "",
    "dataset_id" : "",
    "rule_id" : "",
    "rule_name" : "",
    "rule_type" : "",
    "rule_subtype" : "",
    "num_in" : 200000,
    "num_out" : 199252,
    "num_ok" : 199252,
    "num_ko" : 748,
    "num_warn" : 0,
    "ts_window" : "null",
    "processingTime" : 7,
    "msg" : "",
    "_insertedTS" : "2022-08-01T12:31:29.465Z",
    "_modifiedTS" : "2022-08-01T12:31:29.465Z"
}
]