

import {  axiosHeader } from "../components/common/axios/axiosHelper";
import quality from "apis/quality";
//import qualityLocalHost from "apis/qualityLocalHost";
import {Buffer} from 'buffer';
import qualityLocalHost from "apis/qualityLocalHost";

const {
  DataLakeServiceClient,
} = require("@azure/storage-file-datalake");
const { BlobServiceClient } = require("@azure/storage-blob")






export async function onGetContainerListSaS(dto, dispatch, decriptedSas) {

  if (dto !== undefined && dto.storageAccountCredentialsDTO !== undefined && dto.storageAccountCredentialsDTO.folder !== undefined &&
    dto.storageAccountCredentialsDTO.folder !== null) {
    //its a folder sas, we return first element

    const containersMap = []
    containersMap.push({
      name: dto.storageAccountCredentialsDTO.folder,
      exploded: false,
      storageAccountItemDTOSRoot: []
    })
    const clone = JSON.parse(JSON.stringify(dto));
    clone.containers = []
    clone.containers = containersMap
    return clone
  }

  const clone = JSON.parse(JSON.stringify(dto));
  clone.containers = []
  try {

    const account = dto.storageAccountCredentialsDTO.storageAccountName;


    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${decriptedSas}`);

    let containers = blobServiceClient.listContainers()
    const containersMap = []
    //TODO: CONTAINER CHANGE CONTAINERS
    for await (const container of containers) {
      containersMap.push({
        name: container.name,
        exploded: false,
        storageAccountItemDTOSRoot: []
      })
    }

  }
  catch (error) {
    return clone
  }
}


export async function onGetContainerListADLv2SaS(dto, dispatch, decriptedSas) {


  if (dto !== undefined && dto.storageAccountCredentialsDTO !== undefined && dto.storageAccountCredentialsDTO.folder !== undefined &&
    dto.storageAccountCredentialsDTO.folder !== null) {
    //its a folder sas, we return first element

    const containersMap = []
    containersMap.push({
      name: dto.storageAccountCredentialsDTO.folder,
      exploded: false,
      storageAccountItemDTOSRoot: []
    })
    const clone = JSON.parse(JSON.stringify(dto));
    clone.containers = []
    clone.containers = containersMap
    return clone
  }

  const clone = JSON.parse(JSON.stringify(dto));
  clone.containers = []
  try {

    const account = dto.storageAccountCredentialsDTO.storageAccountName;


    const datalakeServiceClient = new DataLakeServiceClient(`https://${account}.dfs.core.windows.net?${decriptedSas}`);

    let fileSystems = datalakeServiceClient.listFileSystems();
    const containersMap = []
    for await (const fileSystem of fileSystems) {
      containersMap.push({
        name: fileSystem.name,
        exploded: false,
        storageAccountItemDTOSRoot: []
      })
    }
    clone.containers = containersMap
    return clone



  }
  catch (error) {

    return clone

  }
}



export async function getItemsFromContainerADLv2SaS(folder, storageAccountName, containerName, decriptedSas) {

  try {
    const datalakeServiceClient = new DataLakeServiceClient(`https://${storageAccountName}.dfs.core.windows.net?${decriptedSas}`);
    // Get a reference to a container
    const fileSystemClient = datalakeServiceClient.getFileSystemClient(containerName.name);
    const blobs = []
    let iter = await fileSystemClient.listPaths({ path: folder, recursive: false });


    for await (const path of iter) {
      blobs.push(path)
    }


    return blobs


  }
  catch (error) {
    if (decriptedSas) {
      //we try as blob
      try {
        const values = getItemsFromContainerBlobSaS(folder, storageAccountName, containerName, decriptedSas)
        return values
      }
      catch (errorBlob) {
        return []
      }

    }

  }
}
export async function getItemsFromContainerBlobSaS(folder, storageAccountName, container, decriptedSas) {
  const url = `https://${storageAccountName}.blob.core.windows.net/${container.name}/${folder}?${decriptedSas}`
  const path = folder
  /* const urlB64 = Buffer.from(url, 'base64')
  const pathB64 = Buffer.from(path, 'base64') */
  let buff1 = new Buffer(url);
  let buff2 = new Buffer(path);
  let urlB64 = buff1.toString('base64');
  let pathB64 = buff2.toString('base64');
  const sasObject = { url: urlB64, path: pathB64 }


  /* url: 'https://pepedapdevlakesukeu01.blob.core.windows.net/datahub/?sv=2020-02-10&st=2022-03-22T05%3A04%3A15Z&se=2022-03-23T05%3A04%3A15Z&sr=c&sp=rle&sig=WOJniMPSGPd6DrZxvwf9dxhl6LE7oyPKdnxOHOcT5x8%3D' */

  var results = await quality.post('/public/filestorage/azure/sas/list', sasObject, {
    headers: axiosHeader()
  })
  const data = []
  for (var i = 0; i < results.data.length; i++) {
    var tmpData = JSON.parse(JSON.stringify(results.data[i]))
    tmpData.isDirectory = tmpData.directory
    data.push(tmpData)
  }

  return data

}
/*
export async function getItemsFromContainerBlobSaS(folder, storageAccountName, container, decriptedSas) {
console.log('folder', folder,container.name,`https://${storageAccountName}.blob.core.windows.net/${folder}?${decriptedSas}`)
  try {
    const blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net?${decriptedSas}`);

    // Get a reference to a container
    const containerClient = blobServiceClient.getContainerClient(container.name);


    const blobs = []
    // List the blob(s) in the container.
    for await (var blob of folder === "/" ? containerClient.listBlobsByHierarchy("/") :
      containerClient.listBlobsByHierarchy("/", { prefix: folder })) {
      if(blob.kind === 'prefix'){
        blob.isDirectory = true
      }
      blobs.push(blob)

    }
    return blobs


  }
  catch (error) {
    if (decriptedSas) {
      return []
    }

  }
}*/

export async function getItemsFromContainerSaS(folder, storageAccountName, container, decriptedSas) {
  try {

    const url = `https://${storageAccountName}.blob.core.windows.net/${container.name}/${folder}?${decriptedSas}`
    const path = folder
    /* const urlB64 = Buffer.from(url, 'base64')
   const pathB64 = Buffer.from(path, 'base64') */
    let buff1 = new Buffer(url);
    let buff2 = new Buffer(path);
    let urlB64 = buff1.toString('base64');
    let pathB64 = buff2.toString('base64');
    const sasObject = { url: urlB64, path: pathB64 }

    var results = await quality.post('/public/filestorage/azure/sas/list', sasObject, {
      headers: axiosHeader()
    })



    const data = []
    for (var i = 0; i < results.data.length; i++) {
      var tmpData = JSON.parse(JSON.stringify(results.data[i]))
      tmpData.isDirectory = tmpData.directory
      data.push(tmpData)
    }

    return data


  }
  catch (error) {

    return []
  }
}




/** STORAGE ACCOUNT FUNCTIONS */
export async function getNameListChildrenStorageObjectSaS(object, decriptedSas) {

  const containerName = object.container
  const folder = object.fullName
  const storageAccountName = object.storageAccount

  const url = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${folder}?${decriptedSas}`
    const path = folder
    /* const urlB64 = Buffer.from(url, 'base64')
   const pathB64 = Buffer.from(path, 'base64') */
    let buff1 = new Buffer(url);
    let buff2 = new Buffer(path);
    let urlB64 = buff1.toString('base64');
    let pathB64 = buff2.toString('base64');
    const sasObject = { url: urlB64, path: pathB64 }

  var results = await quality.post('/public/filestorage/azure/sas/list/full', sasObject, {
    headers: axiosHeader()
  })


  const data = []
  const dataName = []
    for (var i = 0; i < results.data.length; i++) {
      var tmpData = JSON.parse(JSON.stringify(results.data[i]))
      tmpData.isDirectory = tmpData.directory
      if (tmpData.isDirectory!==true) {
        data.push(tmpData)
        dataName.push(tmpData.name)
      }
      
      
    }

    object.listChildrenNames = dataName
    return object
  

/*
    // List the blob(s) in the container.
    for await (const blob of
      containerClient.listBlobsFlat({ prefix: object.fullName })) {
      if (blob.name.startsWith(object.fullName)) {
        blobs.push(blob.name)
      }



    }
    object.listChildrenNames = blobs
    return object
    */



  
}