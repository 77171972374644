import React, { useEffect, useState } from 'react';
import * as commonStyles from 'style/CommonStyles'
import { Grid, FormControlLabel, Typography, Checkbox, Button } from '@material-ui/core';


export default function ConnectionMethod(props) {


    const [connectionStringMethod, setConnectionStringMethod] = useState(true)
    //TODO: CHECK
    //const [sasUrlMethod, setSasUrlMethod] = useState(false)
    //const [accountKeyMethod, setAccountKeyMethod] = useState(false)

    useEffect(() => {
        if (props.connectionMethod && props.connectionMethod !== "") {
            switch (props.connectionMethod) {
                case "connectionString":
                    setConnectionStringMethod(true)
                    //setSasUrlMethod(false)
                    //setAccountKeyMethod(false)
                    break
                case "sas":
                    setConnectionStringMethod(false)
                //setSasUrlMethod(true)
                //setAccountKeyMethod(false)
                break;
                case "accountKey":
                    setConnectionStringMethod(false)
                    //setSasUrlMethod(false)
                    //setAccountKeyMethod(true)
                    break
                default:
                    break
            }

        }
        if (props.connectionMethod === "") {
            setConnectionStringMethod(true)
            //setSasUrlMethod(false)
            //setAccountKeyMethod(false)
            props.setConnectionMethod("connectionString")
        }
    }, [props.connectionPhase])// eslint-disable-line react-hooks/exhaustive-deps


    const handleCheckConnectionString = (event) => {
        if (props.setConnectionMethod) {
            props.setConnectionMethod("connectionString")
            setConnectionStringMethod(true)
            //setSasUrlMethod(false)
            //setAccountKeyMethod(false)
        }
    }
    /*
    const handleCheckSas = (event) => {
        if (props.setConnectionMethod) {
            props.setConnectionMethod("sas")
            setConnectionStringMethod(false)
            //setSasUrlMethod(true)
            //setAccountKeyMethod(false)
        }
    }

    const handleCheckAccountKey = (event) => {
        if (props.setConnectionMethod) {
            props.setConnectionMethod("accountKey")
            setConnectionStringMethod(false)
            setSasUrlMethod(false)
            setAccountKeyMethod(true)
        }
    }*/

    function handleClickBack() {
        if (props.setConnectionPhase && props.connectionPhase) {
            props.setConnectionPhase(props.connectionPhase - 1)
        }
    }
    function handleClickNext() {
        if (props.setConnectionPhase && props.connectionPhase) {
            props.setConnectionPhase(props.connectionPhase + 1)
        }
    }



    return (

        <>
            <div style={{ width: '100%', height: '90%' }}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" >How will you connect to the storage account?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={connectionStringMethod}
                                    onChange={handleCheckConnectionString}
                                    name="notify on success"
                                    color="primary"
                                />
                            }
                            label={<Typography variant="caption">SAS token or SAS connection string</Typography>}
                        />
                    </Grid>
                </Grid>
            </div >
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Button onClick={event => { handleClickBack() }} style={{ color: "white", backgroundColor: commonStyles.secondaryColor, marginRight: "30px", marginBottom: "-20px" }}>Back</Button>
                <Button onClick={event => { handleClickNext() }} style={{ color: "white", backgroundColor: commonStyles.mainColor, marginBottom: "-20px" }}>Next</Button>
            </div>
        </>
    )
}