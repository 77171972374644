import React from 'react';


import { Grid, Typography } from '@material-ui/core';





export default function SummaryConnectionString(props) {



    return (

        <Grid container spacing={3}>
            {props.displayName && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Display name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.displayName}</Typography>
                </Grid>
            </>}
            {props.connectionObjectSas && props.connectionObjectSas.storageAccountName && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Account name:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectSas && props.connectionObjectSas.storageAccountName}</Typography>
                </Grid>
            </>}
            {props.connectionObjectSas && props.connectionObjectSas.blobEndpoint && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Blob endpoint:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectSas && props.connectionObjectSas.blobEndpoint}</Typography>
                </Grid>
            </>}
            {props.connectionObjectSas && props.connectionObjectSas.fileEndpoint && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>File endpoint:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectSas && props.connectionObjectSas.fileEndpoint}</Typography>
                </Grid>
            </>}
            {props.connectionObjectSas && props.connectionObjectSas.queueEndpoint && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Queue endpoint:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectSas && props.connectionObjectSas.queueEndpoint}</Typography>
                </Grid>
            </>}
            {props.connectionObjectSas && props.connectionObjectSas.tableEndpoint && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Table endpoint:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectSas && props.connectionObjectSas.tableEndpoint}</Typography>
                </Grid>
            </>}
            {props.connectionObjectSas && props.connectionObjectSas.sharedAccessSignature && <>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>Encrypted SAS:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" gutterBottom>{props.connectionObjectSas && props.connectionObjectSas.sharedAccessSignature}</Typography>
                </Grid>
            </>}
        </Grid>
    )
}