export const testJsonProfiling = `
[
    {
      "index": 0,
      "colName": "id",
      "type": "int",
      "rowCount": 200000,
      "nullCount": 0,
      "nullPercent": 0.0,
      "mean": 10857334.82578,
      "stddev": 6689930.101637174,
      "maxValue": 21335120.0,
      "minValue": 108.0,
      "MissingValue": 0,
      "distinctValuesCount": 197914,
      "quantile25": 108.0,
      "quantile75": 21335120.0,
      "lower_bound": -32002410.0,
      "upper_bound": 53337638.0,
      
      "distinct_values": null
    },
    
  ]
`;