import React  from 'react'
import {  Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';


import * as renderHelper from './utils'



export default function DataformatFilters(props) {
    const theme = useTheme()

    return (
        <>
            <FormControl style={{ ...theme.config.formControl, width: '200px', marginRight: '20px' }}>
                <InputLabel 
                    variant='standard' htmlFor="zipCode-selection">
                        <Typography variant='caption'>Filter by owner</Typography>
                        </InputLabel>
                <Select
                    style={{ width: '100%', marginRight: '20px' }}
                    value={props.selectedDataformatUser}
                    onChange={props.handleSelectOwner}
                    defaultValue=""
                    SelectDisplayProps={{ style: { fontSize: '10px' } }}
                >
                    {props.dataformats && props.licenseUsers && renderHelper.getUserIdValuesForFilter(props.dataformats, props.licenseUsers).map(user => {
                        return (
                            <MenuItem key={user.userId} value={user.userId}>
                                <div style={theme.elementAdjacent}>
                                    <Typography variant='subtitle2'>{user.userName}</Typography>
                                </div>
                            </MenuItem>
                        )
                    })}
                    <MenuItem key={''} value={''}>
                        <div style={theme.elementAdjacent}>
                            <Typography variant='subtitle2'>All values</Typography>
                        </div>
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}