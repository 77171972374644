import React from 'react';

import {
        makeStyles, TextField, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Grid, Typography,
        FormControl, InputLabel, Select, MenuItem, Checkbox, Tooltip, Switch
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { styles, QualityTheme } from './../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';

import AqtivaDialogEncryptString from './../../../common/AqtivaDialogEncryptString'

import * as commonStyles from 'style/CommonStyles'
import BigQueryInputCredentials from 'components/dataCredentials/DataCredentialsTable/AddDataCredentialsDialog/ConnectionInfo/BigQueryConnectionInfo/BigQueryInputCredentials';


export default function BackProcessingConfiguration(props) {
        const classes = makeStyles(styles(QualityTheme));

        const [visibleDatabricksInstanceEncrypt, setVisibleDatabricksInstanceEncrypt] = React.useState(false)
        const [visibleStorageSasToken, setVisibleStorageSasToken] = React.useState(false)
        const [visibleStorageToken, setVisibleStorageToken] = React.useState(false)
        const [visibleStorageAwsToken, setVisibleStorageAwsToken] = React.useState(false)
        const [visibleServicPrincipalSecret, setVisibileServicePrincipalSecret] = React.useState(false)

        const [databricksInstance, setDatabricksInstance] = React.useState(props.configuration && props.configuration.databricksInstance ?
                props.configuration.databricksInstance : '')
        const [databricksClusterId, setDatabricksClusterId] = React.useState(props.configuration && props.configuration.databricksClusterId ?
                props.configuration.databricksClusterId : '')
        const [databricksSimulationNotebookPath, setDatabricksSimulationNotebookPath] = React.useState(props.configuration && props.configuration.databricksSimulationNotebookPath ?
                props.configuration.databricksSimulationNotebookPath : '')
        const [databricksRuleRecommenderNotebookPath, setDatabricksRuleRecommenderPath] = React.useState(props.configuration && props.configuration.databricksRuleRecommenderNotebookPath ?
                props.configuration.databricksRuleRecommenderNotebookPath : '')
        const [databricksBaseNotebookPath, setDatabricksBaseNotebookPath] = React.useState(props.configuration && props.configuration.databricksBaseNotebookPath ?
                props.configuration.databricksBaseNotebookPath : '')
        const [databricksToken, setDatabricksToken] = React.useState(props.configuration && props.configuration.databricksToken ?
                props.configuration.databricksToken : '')

        const [resourceGroupName, setResourceGroupName] = React.useState(props.configuration && props.configuration.resourceGroupName ?
                props.configuration.resourceGroupName : '')
        const [regionName, setRegionName] = React.useState(props.configuration && props.configuration.regionName ?
                props.configuration.regionName : '')
        const [storageAccountName, setStorageAccountName] = React.useState(props.configuration && props.configuration.storageAccountName ?
                props.configuration.storageAccountName : '')
        const [containerName, setContainerName] = React.useState(props.configuration && props.configuration.containerName ?
                props.configuration.containerName : '')
        const [storageKey, setStorageKey] = React.useState(props.configuration && props.configuration.storageKey ?
                props.configuration.storageKey : '')
        const [sasToken, setSasToken] = React.useState(props.configuration && props.configuration.sasToken ?
                props.configuration.sasToken : '')
        const [databricksDeploymentPath, setDatabricksDeploymentPath] = React.useState(props.configuration && props.configuration.databricksDeploymentPath ?
                props.configuration.databricksDeploymentPath : '')

        const [cloudProvider, setCloudProvider] = React.useState(props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
                props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure'))

        const [s3Keyid, setS3KeyId] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.accessKey ?
                props.configuration.amazonConfiguration.accessKey : '')
        const [s3KeySecret, setS3KeySecret] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.privateKey ?
                props.configuration.amazonConfiguration.privateKey : '')

        const [s3Region, setS3Region] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.awsServiceRegion ?
                props.configuration.amazonConfiguration.awsServiceRegion : '')
        const [s3BucketName, setS3BucketName] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.bucketName ?
                props.configuration.amazonConfiguration.bucketName : '')


        const [projectId, setProjectId] = React.useState(props.configuration && props.configuration.bigQueryConfiguration &&
                props.configuration.bigQueryConfiguration.projectId ?
                props.configuration.bigQueryConfiguration.projectId : '')
        const [region, setRegion] = React.useState(props.configuration && props.configuration.bigQueryConfiguration &&
                props.configuration.bigQueryConfiguration.region ?
                props.configuration.bigQueryConfiguration.region : '')

        const [bucket, setBucket] = React.useState(props.configuration && props.configuration.bigQueryConfiguration &&
                props.configuration.bigQueryConfiguration.bucket ?
                props.configuration.bigQueryConfiguration.bucket : '')
        const [encryptedCredentialsFile, setEncryptedCredentialsFile] = React.useState(props.configuration && props.configuration.bigQueryConfiguration &&
                props.configuration.bigQueryConfiguration.encryptedCredentialsFile ?
                props.configuration.bigQueryConfiguration.encryptedCredentialsFile : '')

        const [servicePrincipalStorageFlag, setServicePrincipalStorageFlag] = React.useState(props.configuration && props.configuration.servicePrincipalStorageFlag ?
                props.configuration.servicePrincipalStorageFlag : false )
        const [servicePrincipalId, setServicePrincipalId] = React.useState(props.configuration && props.configuration.servicePrincipalId ?
                props.configuration.servicePrincipalId : 'servicePrincipalId' )
        const [servicePrincipalSecret, setServicePrincipalSecret] = React.useState(props.configuration && props.configuration.servicePrincipalSecret ?
                props.configuration.servicePrincipalSecret : 'servicePrincipalSecret' )
        const [tenantId, setTenantId] = React.useState(props.configuration && props.configuration.tenantId ?
                props.configuration.tenantId : 'tenantId' )

        const id = Math.random().toString(36).substr(2, 9)

        const [environmentSecured, setEnvironmentSecured] = React.useState(props.configuration && props.configuration.environmentSecured ?
                props.configuration.environmentSecured : false)

        function onUpload(res) {
                console.log('archivo subido', res)
        }

        React.useEffect(() => {
                setTenantId(props.configuration && props.configuration.tenantId ?
                        props.configuration.tenantId  : (props.parentConfiguration && props.parentConfiguration.tenantId ?
                                props.parentConfiguration.tenantId : 'tenantId'))

                setServicePrincipalStorageFlag(props.configuration && props.configuration.servicePrincipalStorageFlag ?
                        props.configuration.servicePrincipalStorageFlag  : (props.parentConfiguration && props.parentConfiguration.servicePrincipalStorageFlag ?
                                props.parentConfiguration.servicePrincipalStorageFlag : false))
                setServicePrincipalId(props.configuration && props.configuration.servicePrincipalId ?
                        props.configuration.servicePrincipalId  : (props.parentConfiguration && props.parentConfiguration.servicePrincipalId ?
                        props.parentConfiguration.servicePrincipalId : 'servicePrincipalId'))
                setServicePrincipalSecret(props.configuration && props.configuration.servicePrincipalSecret ?
                        props.configuration.servicePrincipalSecret  : (props.parentConfiguration && props.parentConfiguration.servicePrincipalSecret ?
                        props.parentConfiguration.servicePrincipalStorageFlag : 'servicePrincipalSecret'))
                setDatabricksInstance(props.configuration && props.configuration.databricksInstance && props.configuration.databricksInstance !== null ?
                        props.configuration.databricksInstance : (props.parentConfiguration && props.parentConfiguration.databricksInstance ?
                                props.parentConfiguration.databricksInstance : ''))
                setDatabricksClusterId(props.configuration && props.configuration.databricksClusterId && props.configuration.databricksClusterId !== null ?
                        props.configuration.databricksClusterId : (props.parentConfiguration && props.parentConfiguration.databricksClusterId ?
                                props.parentConfiguration.databricksClusterId : ''))
                setDatabricksSimulationNotebookPath(props.configuration && props.configuration.databricksSimulationNotebookPath && props.configuration.databricksSimulationNotebookPath !== null ?
                        props.configuration.databricksSimulationNotebookPath : (props.parentConfiguration && props.parentConfiguration.databricksSimulationNotebookPath ?
                                props.parentConfiguration.databricksSimulationNotebookPath : ''))
                setDatabricksBaseNotebookPath(props.configuration && props.configuration.databricksBaseNotebookPath && props.configuration.databricksBaseNotebookPath !== null ?
                        props.configuration.databricksBaseNotebookPath : (props.parentConfiguration && props.parentConfiguration.databricksBaseNotebookPath ?
                                props.parentConfiguration.databricksBaseNotebookPath : ''))
                setDatabricksRuleRecommenderPath(props.configuration && props.configuration.databricksRuleRecommenderNotebookPath && props.configuration.databricksRuleRecommenderNotebookPath !== null ?
                        props.configuration.databricksRuleRecommenderNotebookPath : (props.parentConfiguration && props.parentConfiguration.databricksRuleRecommenderNotebookPath ?
                                props.parentConfiguration.databricksRuleRecommenderNotebookPath : ''))
                setResourceGroupName(props.configuration && props.configuration.resourceGroupName && props.configuration.resourceGroupName !== null ?
                        props.configuration.resourceGroupName : (props.parentConfiguration && props.parentConfiguration.resourceGroupName ?
                                props.parentConfiguration.resourceGroupName : ''))
                setRegionName(props.configuration && props.configuration.regionName && props.configuration.regionName !== null ?
                        props.configuration.regionName : (props.parentConfiguration && props.parentConfiguration.regionName ?
                                props.parentConfiguration.regionName : ''))
                setStorageAccountName(props.configuration && props.configuration.storageAccountName && props.configuration.storageAccountName !== null ?
                        props.configuration.storageAccountName : (props.parentConfiguration && props.parentConfiguration.storageAccountName ?
                                props.parentConfiguration.storageAccountName : ''))
                setContainerName(props.configuration && props.configuration.containerName && props.configuration.containerName !== null ?
                        props.configuration.containerName : (props.parentConfiguration && props.parentConfiguration.containerName ?
                                props.parentConfiguration.containerName : ''))

                setStorageKey(props.configuration && props.configuration.storageKey && props.configuration.storageKey !== null ?
                        props.configuration.storageKey : (props.parentConfiguration && props.parentConfiguration.storageKey ?
                                props.parentConfiguration.storageKey : ''))
                setSasToken(props.configuration && props.configuration.sasToken && props.configuration.sasToken !== null ?
                        props.configuration.sasToken : (props.parentConfiguration && props.parentConfiguration.sasToken ?
                                props.parentConfiguration.sasToken : ''))
                setDatabricksToken(props.configuration && props.configuration.databricksToken && props.configuration.databricksToken !== null ?
                        props.configuration.databricksToken : (props.parentConfiguration && props.parentConfiguration.databricksToken ?
                                props.parentConfiguration.databricksToken : ''))
                setDatabricksDeploymentPath((props.configuration && props.configuration.databricksDeploymentPath ?
                        props.configuration.databricksDeploymentPath : ''))
                setS3KeyId(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.accessKey ?
                        props.configuration.amazonConfiguration.accessKey : '')
                setS3KeySecret(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.privateKey ?
                        props.configuration.amazonConfiguration.privateKey : '')
                setS3Region(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.awsServiceRegion ?
                        props.configuration.amazonConfiguration.awsServiceRegion : '')
                setS3BucketName(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.bucketName ?
                        props.configuration.amazonConfiguration.bucketName : '')


                setProjectId(props.configuration && props.configuration.bigQueryConfiguration &&
                        props.configuration.bigQueryConfiguration.projectId ?
                        props.configuration.bigQueryConfiguration.projectId : '')

                setRegion(props.configuration && props.configuration.bigQueryConfiguration &&
                        props.configuration.bigQueryConfiguration.region ?
                        props.configuration.bigQueryConfiguration.region : '')


                setBucket(props.configuration && props.configuration.bigQueryConfiguration &&
                        props.configuration.bigQueryConfiguration.bucket ?
                        props.configuration.bigQueryConfiguration.bucket : '')


                setEncryptedCredentialsFile(props.configuration && props.configuration.bigQueryConfiguration &&
                        props.configuration.bigQueryConfiguration.encryptedCredentialsFile ?
                        props.configuration.bigQueryConfiguration.encryptedCredentialsFile : '')

                setCloudProvider(props.master === true ? (props.configuration.preferedCloudProvider !== undefined) ?
                        props.configuration.preferedCloudProvider : process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                        (props.configuration && props.configuration.backendType ? props.configuration.backendType : 'Azure'))

                setEnvironmentSecured((props.configuration && props.configuration.environmentSecured ?
                        props.configuration.environmentSecured : ''))


        }, [props.configuration, props.parentConfiguration]);// eslint-disable-line react-hooks/exhaustive-deps

        React.useEffect(() => {
                props.setConfig(createBackProcessingObj())
        }, [databricksClusterId, databricksInstance, databricksSimulationNotebookPath, storageAccountName, containerName, storageKey, databricksToken,// eslint-disable-line react-hooks/exhaustive-deps
                databricksDeploymentPath, databricksBaseNotebookPath, sasToken, regionName,// eslint-disable-line react-hooks/exhaustive-deps
                resourceGroupName, databricksRuleRecommenderNotebookPath, s3KeySecret, s3Keyid, // eslint-disable-line react-hooks/exhaustive-deps
                s3BucketName, s3Region, cloudProvider, environmentSecured, projectId, region, bucket, encryptedCredentialsFile, // eslint-disable-line react-hooks/exhaustive-deps
                servicePrincipalStorageFlag, servicePrincipalId, servicePrincipalSecret, tenantId]);// eslint-disable-line react-hooks/exhaustive-deps


        function createBackProcessingObj() {
                var obj
                //remove https:// from databricksInstance if exists
                var databricksInstanceClean = databricksInstance
                if (databricksInstanceClean.includes('https://')) {
                        databricksInstanceClean = databricksInstanceClean.replace('https://', '')
                }
                if (props.master === true && props.configuration !== undefined && props.configuration._id !== undefined) {

                        obj = {
                                databricksInstance: databricksInstanceClean,
                                databricksClusterId: databricksClusterId,
                                databricksBaseNotebookPath: databricksBaseNotebookPath.endsWith('/') ? databricksBaseNotebookPath : databricksBaseNotebookPath + '/',
                                databricksSimulationNotebookPath: databricksSimulationNotebookPath,
                                databricksRuleRecommenderNotebookPath: databricksRuleRecommenderNotebookPath,
                                resourceGroupName: resourceGroupName,
                                regionName: regionName,
                                storageAccountName: storageAccountName,
                                containerName: containerName,
                                storageKey: storageKey,
                                servicePrincipalStorageFlag: servicePrincipalStorageFlag,
                                servicePrincipalId: servicePrincipalId,
                                servicePrincipalSecret: servicePrincipalSecret,
                                tenantId: tenantId,
                                sasToken: sasToken,
                                databricksToken: databricksToken,
                                databricksDeploymentPath: databricksDeploymentPath,
                                preferedCloudProvider: cloudProvider,
                                environmentSecured: environmentSecured,
                                amazonConfiguration: {
                                        accessKey: s3Keyid,
                                        privateKey: s3KeySecret,
                                        awsServiceRegion: s3Region,
                                        bucketName: s3BucketName
                                },
                                bigQueryConfiguration: {
                                        projectId: projectId,
                                        region: region,
                                        bucket: bucket,
                                        encryptedCredentialsFile: encryptedCredentialsFile
                                }

                        }
                        return obj
                }
                else if (props.master === false && props.configuration !== undefined && props.parentConfiguration._id !== undefined) {
                        
                        obj = {
                                
                                databricksInstance: databricksInstanceClean,
                                databricksClusterId: databricksClusterId,
                                databricksBaseNotebookPath: databricksBaseNotebookPath.endsWith('/') ? databricksBaseNotebookPath : databricksBaseNotebookPath + '/',
                                databricksSimulationNotebookPath: databricksSimulationNotebookPath,
                                databricksRuleRecommenderNotebookPath: databricksRuleRecommenderNotebookPath,
                                resourceGroupName: resourceGroupName,
                                regionName: regionName,
                                storageAccountName: storageAccountName,
                                containerName: containerName,
                                storageKey: storageKey,
                                servicePrincipalStorageFlag: servicePrincipalStorageFlag,
                                servicePrincipalId: servicePrincipalId,
                                servicePrincipalSecret: servicePrincipalSecret,
                                tenantId: tenantId,
                                sasToken: sasToken,
                                databricksToken: databricksToken,
                                databricksDeploymentPath: databricksDeploymentPath,
                                backendType: cloudProvider,
                                environmentSecured: environmentSecured,
                                amazonConfiguration: {
                                        accessKey: s3Keyid,
                                        privateKey: s3KeySecret,
                                        awsServiceRegion: s3Region,
                                        bucketName: s3BucketName
                                },
                                bigQueryConfiguration: {
                                        projectId: projectId,
                                        region: region,
                                        bucket: bucket,
                                        encryptedCredentialsFile: encryptedCredentialsFile
                                }

                        }
                        return obj
                }

        }

        return (
                <MuiThemeProvider theme={QualityTheme}>
                        <AqtivaDialogEncryptString visible={visibleDatabricksInstanceEncrypt}
                                setVisible={setVisibleDatabricksInstanceEncrypt}
                                confirm={setDatabricksToken}
                                sName={props.master === true ? props.configuration._id + "databkt" : props.parentConfiguration._id + props.configuration.environmentName + "databkt"}
                        />
                        <AqtivaDialogEncryptString visible={visibleStorageSasToken}
                                setVisible={setVisibleStorageSasToken}
                                confirm={setSasToken}
                                sName={props.master === true ? props.configuration._id + "stsastk" : props.parentConfiguration._id + props.configuration.environmentName + "stsastk"}
                        />
                        <AqtivaDialogEncryptString visible={visibleStorageToken}
                                setVisible={setVisibleStorageToken}
                                confirm={setStorageKey}
                                sName={props.master === true ? props.configuration._id + "sttkk" : props.parentConfiguration._id + props.configuration.environmentName + "sttkk"}
                        />
                        <AqtivaDialogEncryptString visible={visibleStorageAwsToken}
                                setVisible={setVisibleStorageAwsToken}
                                confirm={setS3KeySecret}
                                sName={props.master === true ? props.configuration._id + "sttaws" : props.parentConfiguration._id + props.configuration.environmentName + "sttaws"}
                        />
                        <AqtivaDialogEncryptString visible={visibleServicPrincipalSecret}
                                setVisible={setVisibileServicePrincipalSecret}
                                confirm={setServicePrincipalSecret}
                                sName={props.master === true ? props.configuration._id + "stsspid" : props.parentConfiguration._id + props.configuration.environmentName + "stsspid"}
                        />
                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Backend Processing Configuration</Typography>
                                        </Grid>

                                </AccordionSummary>
                                <AccordionDetails>
                                        <div>

                                                {props.master === true &&
                                                        <div style={{ marginTop: '-0%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> Cloud Preferred Provider</Typography>
                                                                <FormControl style={{ width: '200px' }}>
                                                                        <InputLabel id="cloud-provider-select-label">Cloud Provider</InputLabel>
                                                                        <Select
                                                                                labelId="cloud-provider-select"
                                                                                id="cloud-provider-select"
                                                                                value={cloudProvider}
                                                                                onChange={event => setCloudProvider(event.target.value)}
                                                                        >

                                                                                <MenuItem value={'Azure'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('resources/icons/AzureIcon2.png')} />
                                                                                                Azure
                                                                                        </div>
                                                                                </MenuItem>
                                                                                <MenuItem value={'AWS'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('resources/icons/AmazonIcon.png')} />
                                                                                                AWS
                                                                                        </div>
                                                                                </MenuItem>
                                                                                <MenuItem value={'BigQuery'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('resources/icons/bigQuery.png')} />
                                                                                                Big Query
                                                                                        </div>
                                                                                </MenuItem>
                                                                        </Select>

                                                                </FormControl>
                                                                {cloudProvider === 'Azure' &&
                                                                        <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                                                                <Checkbox
                                                                                        checked={environmentSecured}
                                                                                        onChange={event => setEnvironmentSecured(event.target.checked)}
                                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                        color="primary"
                                                                                />
                                                                                <Tooltip title={'Secure Databricks cluster with a KeyVault to avoid use keys directly in notebooks. Aqtiva highly recommend using secure environments'}>
                                                                                        <Typography variant='caption' style={{ marginTop: "0.7rem" }} >Environment Secured by KeyVault</Typography>
                                                                                </Tooltip>
                                                                        </FormControl>
                                                                }
                                                        </div>
                                                }

                                                {props.master !== true &&
                                                        <div style={{ marginTop: '-0%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> Cloud Provider</Typography>
                                                                <FormControl style={{ width: '200px' }}>
                                                                        <InputLabel id="cloud-provider-select-label">Cloud Provider</InputLabel>
                                                                        <Select
                                                                                labelId="cloud-provider-select"
                                                                                id="cloud-provider-select"
                                                                                value={cloudProvider}
                                                                                onChange={event => setCloudProvider(event.target.value)}
                                                                        >

                                                                                <MenuItem value={'Azure'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('resources/icons/AzureIcon2.png')} />
                                                                                                Azure
                                                                                        </div>
                                                                                </MenuItem>
                                                                                <MenuItem value={'AWS'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('resources/icons/AmazonIcon.png')} />
                                                                                                AWS
                                                                                        </div>
                                                                                </MenuItem>
                                                                                <MenuItem value={'BigQuery'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('resources/icons/bigQuery.png')} />
                                                                                                Big Query
                                                                                        </div>
                                                                                </MenuItem>
                                                                        </Select>

                                                                </FormControl>
                                                                {cloudProvider === 'Azure' &&
                                                                        <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                                                                <Checkbox
                                                                                        checked={environmentSecured}
                                                                                        onChange={event => setEnvironmentSecured(event.target.checked)}
                                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                        color="primary"
                                                                                />
                                                                                <Tooltip title={'Secure Databricks cluster with a KeyVault to avoid use keys directly in notebooks. Aqtiva highly recommend using secure environments'}>
                                                                                        <Typography variant='caption' style={{ marginTop: "0.7rem" }} >Environment Secured by KeyVault</Typography>
                                                                                </Tooltip>
                                                                        </FormControl>
                                                                }
                                                        </div>
                                                }

                                                <div style={{ marginTop: '2%', width: '100%', minWidth: '800px' }}>
                                                        <Typography variant="subtitle2"> Databricks Cluster Configuration</Typography>
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksInstance"
                                                                                label="Databricks Instance"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksInstance}
                                                                                onChange={(event) => { setDatabricksInstance(event.target.value) }}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksClusterId"
                                                                                label="Databricks Cluster Id"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksClusterId}
                                                                                onChange={(event) => { setDatabricksClusterId(event.target.value) }}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksToken"
                                                                                type="password"
                                                                                label="Databricks Token "
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksToken}
                                                                                onClick={event => setVisibleDatabricksInstanceEncrypt(true)}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksBaseNotebookPath"
                                                                                label="Databricks Base Notebook Path"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksBaseNotebookPath}
                                                                                onChange={(event) => {
                                                                                        setDatabricksBaseNotebookPath(event.target.value);
                                                                                        props.setConfig(createBackProcessingObj())
                                                                                }}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksSimulationNotebookPath"
                                                                                label="Databricks Simulation Notebook Path"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksSimulationNotebookPath}
                                                                                onChange={(event) => {
                                                                                        setDatabricksSimulationNotebookPath(event.target.value);
                                                                                        props.setConfig(createBackProcessingObj())
                                                                                }}
                                                                        />} />

                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksSimulationNotebookPath"
                                                                                label="Databricks Rule Recommender Notebook Path"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksRuleRecommenderNotebookPath}
                                                                                onChange={(event) => {
                                                                                        setDatabricksRuleRecommenderPath(event.target.value);
                                                                                        props.setConfig(createBackProcessingObj())
                                                                                }}
                                                                        />} />

                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksDeployNotebookPath"
                                                                                label="Databricks Deployment Notebook Path"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksDeploymentPath}
                                                                                onChange={(event) => {
                                                                                        setDatabricksDeploymentPath(event.target.value);
                                                                                        props.setConfig(createBackProcessingObj())
                                                                                }}
                                                                        />} />

                                                </div>
                                                {(props.master === true || (props.master !== null && cloudProvider === 'AWS')) &&
                                                        <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> Amazon S3 Configuration</Typography>

                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3Keyid"
                                                                                        label="Key Id"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3Keyid}
                                                                                        onChange={(event) => { setS3KeyId(event.target.value) }}
                                                                                />} />

                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3KeySecret"
                                                                                        label="Key Secret"
                                                                                        type="password"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3KeySecret}
                                                                                        onClick={(event) => { setVisibleStorageAwsToken(true) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3Region"
                                                                                        label="Region"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3Region}
                                                                                        onChange={(event) => { setS3Region(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3BucketName"
                                                                                        label="Bucket Name"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3BucketName}
                                                                                        onChange={(event) => { setS3BucketName(event.target.value) }}
                                                                                />} />
                                                        </div>
                                                }
                                                {(props.master === true || (props.master !== null && cloudProvider === 'Azure')) &&
                                                        <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> BlobStorage Configuration</Typography>
                                                                <div style={{ marginTop: '20px' }}>
                                                                        <FormControlLabel control={<Switch color="primary"
                                                                        checked={servicePrincipalStorageFlag} onChange={event =>setServicePrincipalStorageFlag(event.target.checked)} />} label={
                                                                                 <Typography variant='caption'>{servicePrincipalStorageFlag === true ? 'Service Principal Credentials' : ' Storage Key Credentials' }</Typography>}
                                                                         />
                                                                        </div>
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="Storage Resource Group Name"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={resourceGroupName}
                                                                                        onChange={(event) => { setResourceGroupName(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="Storage Region Name "
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={regionName}
                                                                                        onChange={(event) => { setRegionName(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="Storage Account Name "
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={storageAccountName}
                                                                                        onChange={(event) => { setStorageAccountName(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="containerName"
                                                                                        label="Storage Container Name"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={containerName}
                                                                                        onChange={(event) => { setContainerName(event.target.value) }}
                                                                                />} />

                                                                {servicePrincipalStorageFlag !==true && <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="storageKey"
                                                                                        label="BlobStorage key"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        type="password"
                                                                                        value={storageKey}
                                                                                        onClick={(event) => { setVisibleStorageToken(true) }}
                                                                                />} />
                                                                        }
                                                                        {servicePrincipalStorageFlag ===true && <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="tenantId"
                                                                                        label="Tenant Id"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={tenantId}
                                                                                       onChange = {event => setTenantId(event.target.value)}
                                                                                />} />
                                                                        }
                                                                        {servicePrincipalStorageFlag ===true && <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="servicePrincipalId"
                                                                                        label="Service Principal Id"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={servicePrincipalId}
                                                                                       onChange = {event => setServicePrincipalId(event.target.value)}
                                                                                />} />
                                                                        }
                                                                        {servicePrincipalStorageFlag ===true && <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="storageKey"
                                                                                        label="Service Principal Secret"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        type="password"
                                                                                        value={servicePrincipalSecret}
                                                                                        onClick={(event) => { setVisibileServicePrincipalSecret(true) }}
                                                                                />} />
                                                                        }
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="sasToken"
                                                                                        label="Storage SAS Token"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        type="password"
                                                                                        value={sasToken}
                                                                                        onClick={(event) => { setVisibleStorageSasToken(true) }}
                                                                                />} />
                                                        </div>
                                                }
                                                {(props.master === true || (props.master !== null && cloudProvider === 'BigQuery')) &&
                                                        <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> Big Query Configuration</Typography>
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="Project ID"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={projectId}
                                                                                        onChange={(event) => { setProjectId(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="Region Name "
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={region}
                                                                                        onChange={(event) => { setRegion(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="Bucket Name"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={bucket}
                                                                                        onChange={(event) => { setBucket(event.target.value) }}
                                                                                />} />

                                                                <Typography variant="subtitle2" style={{ marginLeft: '5%', width: '50%' }} gutterBottom>Crendentials:</Typography>

                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <BigQueryInputCredentials onUpload={onUpload} setEncryptedCredentialsFile={setEncryptedCredentialsFile} id={id}
                                                                                        buttonText={'Upload File'} />
                                                                        }
                                                                />
                                                        </div>
                                                }
                                        </div>



                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}