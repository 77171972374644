import React from 'react';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { CircularProgress, } from '@material-ui/core';
import { StyledMenu, StyledMenuItem } from "components/common/CustomizedMenu";
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dispatchInfo } from 'components/common/axios/axiosHelper';

import * as commonStyles from 'style/CommonStyles'



const useStyles = makeStyles((theme) => ({
    root: {
        height: 240,
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        height: 380,
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialState = {
    mouseX: null,
    mouseY: null,
};



export default function TreeViewMySqlDatabase(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))

    const [sqlServers, setSqlServers] = React.useState([])
    const [credentials, setcredentials] = React.useState([])


    const [selected, setSelected] = React.useState([])
    const [expanded, setExpanded] = React.useState([]);

    const [state, setState] = React.useState(initialState);


    const [transactions, setTransactions] = React.useState([])


    const [selectedServer, setSelectedServer] = React.useState(undefined)

    const [selectedSchema, setSelectedSchema] = React.useState({})
    const [selectedTable, setSelectedTable] = React.useState('')
    const [existantDataformats, setExistantDataformats] = React.useState([])


    React.useEffect(() => {
        if (props.selectedEnvironment) {
            let credentials = getCredentialsFromEnvironmentConfig(props.selectedEnvironment, configuration)
            if (credentials && credentials.length > 0) {
                // todo llamada en bucle a las credenciales que tocan para y ordenar.
                let organizedServers = organizeCredentialsByHierarchy(credentials)
                console.log("organizedServers", organizedServers)
                setcredentials(credentials)
                setSqlServers(organizedServers)
            }

        }
        else {
            setSqlServers([])
        }
    }, [user, props.connectToLocalStorages, props.selectedEnvironment && props.selectedEnvironment.environmentName, configuration]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getMySQLDataformats(user)
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps


    async function getMySQLDataformats(user) {
        const dataformats = await dispatch(actions.getSqlOnpremiseDataformats(user.id, "mySQL"))
        setExistantDataformats(dataformats)
    }

    async function getTables(credentials, type) {
        let tables = await dispatch(actions.getSQLTables(type, credentials))
        return tables
    }


    function getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration) {
        let masterConfig = JSON.parse(JSON.stringify(configuration))
        if (masterConfig.environmentConfigurationList && masterConfig.environmentConfigurationList.length > 0) {
            let selectedEnvironmentConfiguration = masterConfig.environmentConfigurationList.filter(envConf => envConf.environmentName === selectedEnvironment)[0]
            let credentials = []
            if (selectedEnvironmentConfiguration && selectedEnvironmentConfiguration.enabledDataStorageCredentials
                && selectedEnvironmentConfiguration.enabledDataStorageCredentials.length > 0) {
                credentials = selectedEnvironmentConfiguration.enabledDataStorageCredentials
            }
            credentials = checkIfUserCanAccessCredentials(user, credentials)
            credentials = getStoragesAndContainersCredentials(credentials)
            return credentials
        }

    }

    //here we check if credentials are public or protected and if the user is allowed to access them
    function checkIfUserCanAccessCredentials(user, credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].accessType && credentials[i].accessType === "public_access") {
                usableCredentials.push(credentials[i])
            }
            if (credentials[i].accessType && credentials[i].accessType === "protected_access" && credentials[i].allowedUsers) {
                let userIsAllowed = credentials[i].allowedUsers.filter(allowedUser => allowedUser.id === user.id).length > 0
                if (userIsAllowed) {
                    usableCredentials.push(credentials[i])
                }
            }
        }
        return usableCredentials
    }
    function getStoragesAndContainersCredentials(credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].storageType && (credentials[i].storageType === "mySQL")) {
                usableCredentials.push(credentials[i])
            }
        }
        return usableCredentials
    }

    function organizeCredentialsByHierarchy(credentials) {
        let organizedServers = []
        let serversFound = []
        let schemasFound = []
        for (let i = 0; i < credentials.length; i++) {
            if (!serversFound.includes(credentials[i].mySQLCredentialsDTO.server)) {
                serversFound.push(credentials[i].mySQLCredentialsDTO.server)
                let newServer = {
                    server: credentials[i].mySQLCredentialsDTO.server,
                    port: credentials[i].mySQLCredentialsDTO.port,
                    user: credentials[i].mySQLCredentialsDTO.user,
                    pwd: credentials[i].mySQLCredentialsDTO.pwd
                }
                let schema = {
                    name: credentials[i].mySQLCredentialsDTO.schema
                }

                schemasFound.push(credentials[i].mySQLCredentialsDTO.schema)
                newServer.schemas = []
                newServer.schemas.push(schema)
                organizedServers.push(newServer)
            }
            else {
                let existingServer = organizedServers.find(organizedCredential => organizedCredential.server === credentials[i].mySQLCredentialsDTO.server)
                if (!schemasFound.includes(credentials[i].mySQLCredentialsDTO.schema)) {
                    let schema = {
                        name: credentials[i].mySQLCredentialsDTO.schema
                    }
                    existingServer.schemas.push(schema)
                    schemasFound.push(credentials[i].mySQLCredentialsDTO.schema)
                }
            }
        }
        console.log("organizedServers", organizedServers)
        return organizedServers
    }

    function alreadyExistantDataformat(server, schema, table) {
        var already = false

        if (existantDataformats && existantDataformats.length > 0) {

            existantDataformats.forEach(dataformat => {

                if (dataformat.sqlDatabaseOnpremProperties !== null && dataformat.sqlDatabaseOnpremProperties !== undefined &&
                    dataformat.sqlDatabaseOnpremProperties.server === server.server &&
                    dataformat.sqlDatabaseOnpremProperties.schema === schema.name &&
                    dataformat.sqlDatabaseOnpremProperties.table === table.tableName) {
                    already = true
                }
            })
        }
        return already
    }


    function handleGetTables(server, schemaName) {
        if (credentials && credentials.length > 0) {
            let enrichedServer = JSON.parse(JSON.stringify(server));
            let correctCredentials = credentials.find(credential => credential.mySQLCredentialsDTO.server === enrichedServer.server && credential.mySQLCredentialsDTO.schema === schemaName)
            getTables(correctCredentials.mySQLCredentialsDTO, correctCredentials.storageType).then(result => {
                console.log("result", result)
                if (result && result.length > 0 && result[0] && result[0].tableName) {
                    //TODO: LUKA ESTO funciona?
                    enrichedServer.schemas.forEach(schema => {
                        if (schema.name === schemaName) {
                            schema.tables = result

                        }
                    })
                    let newSqlServers = JSON.parse(JSON.stringify(sqlServers))
                    let index = newSqlServers.findIndex(element => element.server === enrichedServer.server && element.port === enrichedServer.port)
                    newSqlServers[index] = enrichedServer
                    console.log("newSqlServers", newSqlServers)
                    setSqlServers(newSqlServers)
                }

            })
        }
    }

    const handleClick = (event, schema, table, server) => {


        setSelectedTable(table)
        setSelectedServer(server)
        setSelectedSchema(schema && schema.name)

        event.preventDefault();
        props.setFileOutputName(server.server + "_" + schema.name + "_" + table.tableName)
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };



    function getDetailStorageTransaction(sqlServer) {
        setTransactions([...transactions, sqlServer])
    }


    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    async function handleProfiling(event) {

        props.setPreviewVisible(false)
        props.resetProfilingState()
        let correctCredentials = credentials.find(element => element.mySQLCredentialsDTO.server === selectedServer.server && element.mySQLCredentialsDTO.schema === selectedSchema)
        const dto = {
            table: selectedTable.tableName,
            storageType: correctCredentials.storageType,
            credentials: correctCredentials.mySQLCredentialsDTO,
            credentialsId: correctCredentials.id
        }
        handleClose(event)
        dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")

        await dispatch(actions.addProfilingToOnpremSQLDataformat(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

            if (result && result.dataformatId) {
                props.setProfilingdataformatId(result.dataformatId)
            }

        })
    }


    async function handlePreview(event) {


        let correctCredentials = credentials.find(element => element.mySQLCredentialsDTO.server === selectedServer.server && element.mySQLCredentialsDTO.schema === selectedSchema)
        const dto = {
            table: selectedTable.tableName,
            storageType: correctCredentials.storageType,
            credentials: correctCredentials.mySQLCredentialsDTO,
            credentialsId: correctCredentials.id
        }
        props.setSelectedSQLDTO(dto) //para cuando se cree el datasource, tenga toda la información necesaria
        /*
        AES.encrypt(userPwdSql.pwd, "Secret Passphrase").ciphertext.toString()
        */


        handleClose(event)
        props.setVisiblePreview(true)
        dispatch(actions.createPreviewDataJobSQLOnpremDatabase(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
            if (result && result.run_id) {

                props.setRunId(result.run_id)
            }

        })
    }
    function createMenuItemDatabase(sqlServer, rootItem) {
        const schemaName = rootItem.name
        return (
            <div>

                <TreeItem nodeId={sqlServer.server + "_" + schemaName} key={sqlServer.server + "_" + schemaName}
                    onClick={event => {
                        handleGetTables(sqlServer, schemaName)
                    }}
                    label={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>
                            <img alt="" style={{ height: '15px', width: '15px', marginTop: "4px" }}
                                src={require('resources/icons/azure/Folder.png')} />
                            <Typography variant='caption'>{schemaName}</Typography>
                        </div>
                    } >
                    {rootItem.tables !== undefined && rootItem.tables.length > 0
                        &&
                        rootItem.tables.sort((a, b) => (a.tableName > b.tableName) ? 1 : -1).map((table, containerIndex) => {


                            return <TreeItem key={sqlServer.server + "_" + schemaName + "_" + table.tableName}
                                            nodeId={sqlServer.server + "_" + schemaName + "_" + table.tableName}
                                onContextMenu={event => handleClick(event, rootItem, table, sqlServer)}
                                label={
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>

                                        {alreadyExistantDataformat(sqlServer, rootItem, table) === false &&
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                            }}>

                                                <img alt="" style={{ height: '12px', width: '12px', marginTop: "4px" }}
                                                    src={require('resources/icons/azure/Table.png')} />
                                                <Typography variant='caption' >{table.tableName}</Typography>

                                            </div>}
                                        {alreadyExistantDataformat(sqlServer, rootItem, table) === true &&
                                            <Tooltip title={`Source already registered in Aqtiva`}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    <img alt="" style={{ height: '15px', width: '15px' }}
                                                        src={require('resources/icons/favicon-32x32.jpg')} />
                                                    <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{table.tableName}</Typography>

                                                </div>
                                            </Tooltip>
                                        }


                                    </div>
                                } >




                            </TreeItem>
                        })
                    }
                    {rootItem.tables === undefined && (
                        <TreeItem nodeId={`${sqlServer.server}_${schemaName}_loading`}
                                    key={`${sqlServer.server}_${schemaName}_loading`}
                            label={
                                < div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                    <Typography variant='caption' >loading tables</Typography>

                                </div>
                            } >
                        </TreeItem >
                    )}
                </TreeItem>
                <StyledMenu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    <StyledMenuItem onClick={() => handlePreview(rootItem)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Preview" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handleProfiling(rootItem)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Create Profiling" />
                    </StyledMenuItem>

                </StyledMenu>
            </div>
        )

    }



    return (

        <div className={classes.root}>

            {sqlServers !== undefined && sqlServers !== null && sqlServers.length > 0 &&
                <TreeView
                    className={classes.rootView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {sqlServers.sort((a, b) => (a.server > b.server) ? 1 : -1).map(sqlServer => {
                        return <TreeItem nodeId={sqlServer.server} key={sqlServer.server}  label={
                            <div style={{
                                margin: '7px', display: 'flex',
                                justifyContent: 'left',
                            }}>
                                {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                                <img alt="" style={{ height: '20px', width: '20px' }}
                                    src={require('resources/icons/azure/SQL-Server.png')} />
                                <Typography variant='subtitle2'>{sqlServer.server}</Typography>
                            </div>}
                            onClick={event => getDetailStorageTransaction(sqlServer)}>


                            {sqlServer.schemas === undefined || sqlServer.scehmas === null || sqlServer.schemas.length === 0 ?
                                <TreeItem nodeId={`${sqlServer.server}_empty`} key={`${sqlServer.server}_empty`} label={
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <CircularProgress style={{ width: '15px', height: '15px' }} />
                                        <Typography variant='subtitle2'>Loading storage data, it may take some seconds...</Typography>
                                    </div>
                                } /> :
                                sqlServer.schemas.sort((a, b) => (a.name > b.name) ? 1 : -1).map(schema => {
                                    return (
                                        createMenuItemDatabase(sqlServer, schema)
                                    )
                                })
                            }
                        </TreeItem>
                    })}

                </TreeView>
            }
        </div>

    )
}