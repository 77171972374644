import React, { useEffect, useState } from 'react';

import AqtivaDialog from '../../../../../../common/AqtivaDialog';

import ParentCard from '../ParentCard'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import * as helper from '../ErrorUtils'


export default function ResultStorageConfiguration(props) {
    const [storage, setStorage] = React.useState('')
    const [errors, seterrors] = useState({})
    const [counter, setCounter] = useState(1)

    useEffect(() => {
        if (counter > 1) {
            let errorsToCheck = helper.checkAllFields(storage)
            seterrors(errorsToCheck)
        }
    }, [counter, counter > 1 && storage])// eslint-disable-line react-hooks/exhaustive-deps


    function editArrayElement(storages, editedElement) {
        let errorsToCheck = helper.checkAllFields(editedElement)
        seterrors(errorsToCheck)
        if (!errorsToCheck.hasErrors) {
            let index = storages.findIndex(x => x.id === props.selectedStorage.id)
            storages[index] = editedElement
            props.setData(storages)
            props.setUpdateCounter(props.updateCounter + 1)
            props.setShowEditDialog(false)
        }
        else {
            setCounter(2)
        }
    }

    return (
        <AqtivaDialog
            visible={props.showEditDialog}
            dialog={props.showEditDialog}
            titleIcon={faPlusCircle}
            fullWidth={true}
            maxWidth={'lg'}
            title={"Edit Storage"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setShowEditDialog(false)
            }}
            confirmText={'Edit Storage properties'}
            confirmCallback={async () => {
                editArrayElement(props.data, storage)
            }}
        >
            <ParentCard master={true} setStorage={setStorage} configuration={props.configuration} storageInfo={props.selectedStorage} errors={errors} />
        </AqtivaDialog>

    )
}