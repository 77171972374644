import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import AqtivaLoading from 'components/common/AqtivaLoading'


export default function DataformatDQIBar(props) {


   
    //const [createdChar, setCreatedChar] = React.useState(undefined)

    const [data, setData] = React.useState([])
    const [maxValue, setMaxValue] = React.useState(0)



    function prepareData(data) {
        if (props.data && props.data.length > 0) {


            var histogram = []
            for (var i = 100; i > 0; i -= 10) {
                histogram.push({
                    subject: `${i - 10}-${i}`,
                    minRange: i,
                    maxRange: i - 10,
                    value: 0
                })
            }

            for ( i = 0; i < data.length; i++) {
                var number = Math.trunc(data[i].DQI / 10 * Math.pow(10, 0)) / Math.pow(10, 0)
                var index = 10 - parseInt(number) - 1

                if (index < 0) {
                    index = 0
                }
                if (histogram !== undefined && histogram[index] !== undefined) {
                    histogram[index].value += 1
                }


            }

            setMaxValue(Math.max(histogram.map(x => x.value)))

        }
        return histogram
    }

    React.useEffect(() => {

        var chart = am4core.create(props.fullscreen === true ? "chartdivDataformatHistFullscreen" : "chartdivDataformatHist", am4charts.XYChart);

        chart.padding(40, 40, 40, 40);



        chart.data = data


        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "subject";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.fontSize = 10;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = maxValue;
        valueAxis.renderer.labels.template.fontSize = 10;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "subject";
        series.dataFields.valueY = "value";
        series.tooltipText = "{valueX}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;

        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";

        labelBullet.label.text = "[font-size:12px]{values.valueY.workingValue.formatNumber('#as')}";
        labelBullet.label.dy = 10;
        labelBullet.label.fill = am4core.color("#FFFFFF");

        // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        series.columns.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });

        series.columns.template.togglable = true;

        var activeState = series.columns.template.states.create("active");
        activeState.properties.fill = am4core.color("#E94F37");


        series.columns.template.events.on("hit", function (event) {
            series.columns.each(function (column) {
                if (column === event.target) {
                    if (column.isActive === false) {

                        const categoryBucket = column._dataItem.categories.categoryX
                        props.setCategoryBucket(categoryBucket)

                    }
                    else {
                        props.setCategoryBucket(undefined)
                    }


                }
                if (column !== event.target) {
                    column.setState("default");
                    column.isActive = false;
                }
            })
        });

        //setCreatedChar(chart)

    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        const dataStr = JSON.stringify(data)
        //const propsDataStr = JSON.stringify(props.data)
        const preparedData = prepareData(props.data)
        if (dataStr !== JSON.stringify(preparedData)) {
            setData(preparedData)
        }

    }, [props.data])// eslint-disable-line react-hooks/exhaustive-deps

    if (props.loading === true) {
        return <AqtivaLoading />
    }
    else {
        return (
            <div style={{ width: "100%", height: "100%" }}>

                <div id={props.fullscreen === true ? "chartdivDataformatHistFullscreen" : "chartdivDataformatHist"} style={{ width: "100%", height: "90%" }}></div>
            </div>

        )
    }
}
