
import quality from '../apis/quality';
import {
  FETCH_LICENSE, FETCH_USER,
  UPDATE_LICENSE
} from './types'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';
//import qualityLocalHost from '../apis/qualityLocalHost';


export const fetchQualityLicense = (id) => {
  return async function (dispatch, getState) {

    await quality.get(`/license/id/${id}`, {
      headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: FETCH_LICENSE,
        payload: response.data
      });
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

      });
  }

};


export const sendNewUserEmail = (frontUrl, userEmail) => {
  return async function (dispatch, getState) {

    await quality.post(`/license/email/newuser/fronturl/${userEmail}`, frontUrl, {
      headers: axiosHeader()
    }).then(function (response) {

    })
      .catch(function (error) {
        dispatchError(dispatch, error)

      });
  }

};

export const editQualityLicense = formValues => async (dispatch, getState) => {

  await quality.post('/license/user/', { ...formValues }, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: UPDATE_LICENSE,
      payload: response.data
    });
    dispatchInfo(dispatch, `License modified`)
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};

export const updateUserLicense = formValues => async (dispatch, getState) => {

  await quality.post('/license/user/update', { ...formValues }, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: FETCH_USER,
      payload: formValues
    });
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};

export const editUserLicense = formValues => async (dispatch, getState) => {

  await quality.post('/license/user/edit', { ...formValues }, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: UPDATE_LICENSE,
      payload: formValues
    });
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};

export const updateQualityLicense = license => async (dispatch, getState) => {

  await quality.put('/license/update', license, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: UPDATE_LICENSE,
      payload: response.data
    });
    dispatchInfo(dispatch, `License updated`)
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};

export const dropUserFromQualityLicense = (_id, licenseId) => async (dispatch, getState) => {

  await quality.delete(`/license/user/${_id}/${licenseId}`, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: UPDATE_LICENSE,
      payload: response.data
    });
    dispatchInfo(dispatch, `User deleted from license`)
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

    });

};




