import React from 'react';
import { useSelector } from 'react-redux'

import { MuiThemeProvider } from '@material-ui/core/styles';
import { QualityTheme } from 'style/CustomTheme';
import {
    Grid, Dialog, DialogTitle, Typography, DialogContent,
    DialogActions, Button, InputAdornment, LinearProgress, FormControlLabel, Switch, Select, MenuItem, Tooltip,Divider
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { faMousePointer } from '@fortawesome/free-solid-svg-icons'
import TextField from '@material-ui/core/TextField';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useDispatch } from 'react-redux'
import * as actions from 'actions'
import QualityItemSelector from 'components/common/QualityItemSelector'
import RunningSimulation from './RunningSimulation'
import { useInterval } from 'components/common/useInterval'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import RunningParseData from './RunningParseData';
import InputFile from 'components/common/azure-storage/components/InputFile'
import trackEvent from 'trackEvent';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Dropdown } from 'primereact/dropdown';


import { useTheme } from '@material-ui/core/styles';

import * as configurationHelper from 'components/common/configurationHelper'
import * as userHelper from 'components/common/userHelper'
import { dispatchError } from 'components/common/axios/axiosHelper';

import UploadS3 from 'components/common/UploadS3'

import * as listBlobHelper from 'components/common/azure-storage/helpers/ListBlobsHelper'


import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'

import { isEmpty } from 'commonFunctions/commonFunctions'
import { getUserPreferenceProperty } from 'components/common/userHelper'

import { useHistory } from 'react-router-dom'

import * as parameterHelper from 'commonFunctions/parameterHelper'

import * as commonStyles from 'style/CommonStyles'


export default function DialogSimulation(props) {

    const theme = useTheme()
    const history = useHistory();



    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))
    const user = useSelector(store => store.user)
    const env = useSelector(store => store.environment)


    const [simulationName, setSimulationName] = React.useState(null)
    const [fileUploadName, setFileUploadName] = React.useState('')
    const [separator, setSeparator] = React.useState('automatic')
    const [separatorClean, setSeparatorClean] = React.useState('automatic')
    const [error, setError] = React.useState({})
    const [selectedDatasource, setSelectedDatasource] = React.useState('')
    const [selectedDataformat, setSelectedDataformat] = React.useState()
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState(props.selectedQualityPoint ? props.selectedQualityPoint : '')

    const [simulation, setSimulation] = React.useState(null)
    const [simulationId, setSimulationId] = React.useState(null)
    const [isRunningSimulation, setIsRunningSimulation] = React.useState(false)
    const [isUploadingData, setIsUploadingData] = React.useState(false)
    const [nReceivedRules, setNReceivedRules] = React.useState(false)
    const [isSimulanionFinished, setIsSimulationFinished] = React.useState(false)
    const [colFormats, setColFormats] = React.useState([])
    const [isParsing, setIsParsing] = React.useState(false)
    const [checkAutomaticSeparator, setCheckAutomaticSeparator] = React.useState(true)

    const [nSamples, setNSamples] = React.useState(200000)

    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')

    const [openInfoSnack, setOpenInfoSnack] = React.useState(false);
    const [openErrorSnack, setOpenErrorSnack] = React.useState(false);

    const [environments, setEnvironments] = React.useState([])
    const [environmentStates, setEnvironmentStates] = React.useState([])
    //TODO: CHECK AVOID WARNINGS
    if (environments && environmentStates && openInfoSnack) { }

    const [errorMessage, setErrorMessage] = React.useState('')

    const [recentlyUsedFiles, setRecentlyUsedFiles] = React.useState([])
    const [recentlyUsedFilesInSimulation, setRecentlyUsedFilesInSimulation] = React.useState([])
    const [selectRecentFile, setSelectRecentFile] = React.useState('')

    const dispatch = useDispatch();

    const [parseMessages, setParseMessages] = React.useState(['Sending data to server..'])
    const [simulationMessages, setSimulationMessages] = React.useState([{
        type: '',
        state: '',
        subState: '',
        message: 'Sending data to server..'
    }])

    const classNameName = `field ${error && error.simulationName ? 'error' : ''}`

    const [parameters, setParameters] = React.useState([])

    React.useEffect(()=>{
        if(selectedQualityPoint && selectedDatasource && selectedDatasource.rules){
            const newParameters=(parameterHelper.getParametersForDS(selectedQualityPoint, 
                selectedDatasource, selectedDatasource.rules)).map(param=>{
                    if(param.defaultValue===undefined){
                        return param
                    }
                    else{
                        param.value = param.defaultValue
                        return param
                    }
                })
            setParameters(newParameters)
        }
           
        },[selectedQualityPoint, selectedDatasource])

    React.useEffect(() => {
        if (selectedDatasource && selectedDatasource !== '') {
            dispatch(actions.fetchDataformat(selectedDatasource.dataformatId ? selectedDatasource.dataformatId : selectedDatasource.dataset._id)).then(
                response => {
                    setSelectedDataformat(response.data)
                }
            )
        }

    }, [selectedDatasource])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        //updateEnvironmentStatus()


    }, [qualityConfiguration.environmentConfigurationList, user])// eslint-disable-line react-hooks/exhaustive-deps

    /*
    async function updateEnvironmentStatus() {
        if (process.env.REACT_APP_DATABRICKS_STATE && process.env.REACT_APP_DATABRICKS_STATE === 'true') {
            const environments = configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)
            if (environments !== undefined && environments !== null) {
                var environmentStatusTmp = []
                for (var i = 0; i < environments.length; i++) {
                    var ret = await getEnvironmentStatus(environments[i].environmentName, user.id)
                    environmentStatusTmp.push(ret)
                }
                setEnvironmentStates(environmentStatusTmp)
            }
        }
    }*/

    /*async function getEnvironmentStatus(environment, user) {
        const ret = await dispatch(actions.getClusterStatus(environment, user))
        const ret2 = await ret
        return ret2.state
    }*/

    function updateParameter(index, value){
        var oldParameter = parameters[index]
        oldParameter.value = value
        var newParameterList = JSON.parse(JSON.stringify(parameters))
        newParameterList[index]= oldParameter
        setParameters(newParameterList)
    }

    async function getSasToken(env) {
        const sasTokenTmp = await dispatch(actions.getSecret(env.sasToken))
        if (sasTokenTmp && sasTokenTmp.result === undefined) {
            let sasToken = sasTokenTmp.startsWith("?") ? sasTokenTmp : "?" + sasTokenTmp
            return sasToken
        }
        return undefined


    }

    React.useEffect(() => {

        if (user && user.id && selectedEnvironment && qualityConfiguration && qualityConfiguration.environmentConfigurationList &&
            qualityConfiguration.environmentConfigurationList.length > 0) {
            //console.log('env',env)
            let env = qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]
            const storageAccountName = env.storageAccountName
            const sasToken = getSasToken(env)
            const containerName = env.containerName
            const folder = user.id + "/stagging/"
            listBlobHelper.listBlobs(storageAccountName, sasToken, containerName, folder).then(response => {
                setRecentlyUsedFiles(response)
            }
            )
        }



    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    /*function getEnvironmentComponent(element) {
        const environments = configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)
        var environmentStatus = 'PENDING'
        if (environments !== undefined) {



            for (var i = 0; i < environments.length; i++) {
                if (environments[i].environmentName === element.environmentName) {
                    environmentStatus = environmentStates[i]
                }
            }
        }
        return (
            <MenuItem key={element.environmentName} value={element.environmentName}>
                <div style={theme.elementAdjacent}>
                    {(environmentStatus === 'PENDING' || environmentStatus === undefined) && <FontAwesomeIcon icon={faCircle} style={{ color: '#AAAAAA', fontSize: 15 }} />}
                    {environmentStatus === 'RUNNING' && <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#1DA260', fontSize: 15 }} />}
                    {environmentStatus !== undefined && environmentStatus !== 'RUNNING' && environmentStatus !== 'PENDING' && <FontAwesomeIcon icon={faStopCircle} style={{ color: '#CF4418', fontSize: 15 }} />}
                    <Typography variant='subtitle2'>{element.environmentName}</Typography>
                </div>
            </MenuItem>

        )
    }*/



    React.useEffect(() => {

        if (user && user.id && selectedQualityPoint && qualityConfiguration && qualityConfiguration.environmentConfigurationList &&
            qualityConfiguration.environmentConfigurationList.length && selectedEnvironment) {

            dispatch(actions.getLastSimulationsForQpOrderByDate(user.id, selectedQualityPoint, 100)).then(result => {
                //get only fileNames

                if (result && result.result && result.result.length > 0) {

                    let dataFilter = result.result.filter(x => x.dataFileName !== undefined && x.dataFileName !== null).map(x => listBlobHelper.getOnlyNameFromSimulation(x))

                    setRecentlyUsedFilesInSimulation(dataFilter)
                    let env = qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]
                    const storageAccountName = env.storageAccountName
                    const sasToken = getSasToken(env)
                    const containerName = env.containerName

                    const folder = user.id + "/stagging/"
                    listBlobHelper.listBlobsFilter(storageAccountName, sasToken, containerName, folder, dataFilter).then(response => {
                        setRecentlyUsedFiles(response)
                    }
                    )

                }
            }

            )
        }


    }, [qualityConfiguration, user, selectedQualityPoint, selectedEnvironment]);// eslint-disable-line react-hooks/exhaustive-deps



    function onEditorValueChange(propsValue, value) {
        let updatedColumnFormats = [...propsValue.value];
        updatedColumnFormats[propsValue.rowIndex][propsValue.field] = value;
        setColFormats(updatedColumnFormats)
    }

    function formatEditor(props) {
        let formats = [
            { label: 'String', value: 'String' },
            { label: 'Integer', value: 'Integer' },
            { label: 'Float', value: 'Float' },
            { label: 'Date', value: 'Date' }
        ];

        return (
            <Dropdown value={props.value[props.rowIndex].brand} options={formats} style={{ fontSize: '10px' }}
                onChange={(e) => onEditorValueChange(props, e.value)} placeholder="Select a Format" />
        );
    }

    React.useEffect(() => {
        validate()
    }, [simulationName, fileUploadName, separator, separatorClean, selectedQualityPoint, selectedDatasource, selectedEnvironment]);// eslint-disable-line react-hooks/exhaustive-deps



    function resetState() {

        setFileUploadName(undefined)
        setSeparator('automatic')
        setSeparatorClean('automatic')
        setError({})
        setSimulation(null)
        setSimulationId(null)
        setIsRunningSimulation(false)
        setIsUploadingData(false)
        setNReceivedRules(false)
        setIsSimulationFinished(false)
        setColFormats([])
        setIsParsing(false)
        setCheckAutomaticSeparator(true)
        setOpenInfoSnack(false)
        setOpenErrorSnack(false)
        setErrorMessage('')
        setParseMessages(['Sending data to server..'])
        setSimulationMessages([{
            type: '',
            state: '',
            subState: '',
            message: 'Sending data to server..'
        }])
        setSelectRecentFile('')
    }



    /*
    * Functions to hangle error messages Snackbar
    */

    function validate() {
        var errors = {};

        if(parameters && parameters.length>0){
            for(var i=0;i< parameters.length;i++){
                const value = parameters[i].value
                if(value ===undefined || value === null || value ===""){
                    errors.parameters ="You must fill all parameter values"
                }
            }
        }
        if (!simulationName || simulationName === null) {
            errors.simulationName = 'You must set a Simulation name';

        }
        if (!selectedQualityPoint || selectedQualityPoint === null) {
            errors.selectedQualityPoint = 'You must select a Quality Point';
        }
        if (!fileUploadName || fileUploadName === null) {
            errors.colFormats = 'You must upload a sample File';
        }
        if (!selectedEnvironment || selectedEnvironment === null) {
            errors.selectedEnvironment = 'You must selected an environment';
        }
        if ((selectedQualityPoint && selectedQualityPoint.datasources && selectedQualityPoint.datasources.length > 1) &&
            (!selectedDatasource || selectedDatasource === null)) {
            errors.selectedDatasource = 'You must select a Datasource to simulate';

        }
        setError(errors)

        return errors;
    }

    function validateAzureStorage() {
        var errors = {};

        if(parameters && parameters.length>0){
            for(var i=0;i< parameters.length;i++){
                const value = parameters[i].value
                if(value ===undefined || value === null || value ===""){
                    errors.parameters ="You must fill all parameter values"
                }
            }
        }

        if (!simulationName || simulationName === null) {
            errors.simulationName = 'You must set a Simulation name';

        }
        if (!selectedQualityPoint || selectedQualityPoint === null) {
            errors.selectedQualityPoint = 'You must select a Quality Point';
        }

        if (!selectedEnvironment || selectedEnvironment === null) {
            errors.selectedEnvironment = 'You must selected an environment';
        }
        if ((selectedQualityPoint && selectedQualityPoint.datasources && selectedQualityPoint.datasources.length > 1) &&
            (!selectedDatasource || selectedDatasource === null)) {
            errors.selectedDatasource = 'You must select a Datasource to simulate';

        }
        setError(errors)

        return errors;
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleCloseError = (event, reason) => {
        //seteamos el mensaje a null para poder volver a llamar al mismo mensaje repetidas veces

        if (reason === 'clickaway') {
            setErrorMessage('')
            return;
        }
        setOpenErrorSnack(false);
        setErrorMessage('')
    };
    /*const handleCloseInfo = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenInfoSnack(false);
    };*/
    React.useEffect(() => {
        if ((typeof errorMessage) === 'string' && errorMessage !== '')
            setOpenErrorSnack(true)
    }, [errorMessage]);

    const vertical = 'top'
    const horizontal = 'right'


    /*
    * Use Effecs 
    */
    React.useEffect(() => {

        let userSelectedEnvironment = userHelper.getUserPreferenceProperty(user, 'selectedEnvironment')
        if (userSelectedEnvironment !== undefined) {
            setSelectedEnvironment(userSelectedEnvironment)
        }
        else {
            if (qualityConfiguration &&
                qualityConfiguration.environmentConfigurationList) {
                setEnvironments(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id).map(x => x.environmentName))
                const environmentPreferedSim = configurationHelper.filterAllowedEnvironments(
                    qualityConfiguration.environmentConfigurationList, user.id).filter(x => x.prefered4Simulation === true)
                if (environmentPreferedSim && environmentPreferedSim.length > 0) {
                    setSelectedEnvironment(environmentPreferedSim[0].environmentName)
                }
                else {
                    setSelectedEnvironment(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)[0] ?
                        configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)[0].environmentName : undefined)
                }
            }
        }



    }, [qualityConfiguration, user]);


    var es = null
    var keepAliveTimer = null;

    React.useEffect(() => {

        var separator_ = separator
        if (separator_ === ";") {
            separator_ = "semicolon"
        }
        else if (separator_ === "|") {
            separator_ = "pipe"
        }
        setSeparatorClean(separator_)



    }, [separator]);

    React.useEffect(() => {
        if (props.selectedQualityPoint) {
            setSelectedQualityPoint(props.selectedQualityPoint)
            if (props.selectedQualityPoint.datasources && props.selectedQualityPoint.datasources.length > 0) {
                setSelectedDatasource(props.selectedQualityPoint.datasources[0])
            }

        }
    }, [props.selectedQualityPoint]);

    React.useEffect(() => {
        if (selectedQualityPoint) {
            if (selectedQualityPoint.datasources && selectedQualityPoint.datasources.length > 0) {
                setSelectedDatasource(selectedQualityPoint.datasources[0])
            }

        }
    }, [selectedQualityPoint]);


    useInterval(async () => {
        if (simulation && simulation._id) {
            dispatch(actions.getRecordsForSimulation(simulation._id)).then(response => {

                if (response.result === 'ok') {
                    setNReceivedRules(response.data.length)
                }

            })
        }
        const stopRunning = stopSimulationRunning()
        if (stopRunning) {

            if (simulation) {
                dispatch(actions.getSimulationFromId(simulation._id)).then(response => {

                    if (response.data.state === "Running") {
                        setTimeout(function () {
                            dispatch(actions.getSimulationFromId(simulation._id)).then(response => {
                            })
                            if (response.data && response.data.errorMessage && response.data.errorMessage !== null) {
                                setSimulationMessages([...simulationMessages, {
                                    type: '',
                                    state: '',
                                    subState: '',
                                    message: "Simulation with errors:" + response.data.errorMessage
                                }])
                            }

                            stopSimulation()
                            setIsSimulationFinished(true)

                        }, 2000);
                    }
                    else {
                        if (response.data && response.data.errorMessage && response.data.errorMessage !== null) {
                            setSimulationMessages([...simulationMessages, {
                                type: '',
                                state: '',
                                subState: '',
                                message: "Simulation with errors:" + response.data.errorMessage
                            }])
                        }
                        stopSimulation()
                        setIsSimulationFinished(true)
                    }
                })
            }
        }


    }, 1000)

    useInterval(async () => {
        if (simulation && simulation._id && !isSimulanionFinished) {
            dispatch(actions.getSimulationFromId(simulation._id)).then(response => {
                const messageData = response.data ? response.data.records : undefined
                if (response.result === 'ok') {
                    setSimulationMessages(messageData)

                }
                if (messageData && messageData !== null) {
                    stopSimulationRunning(messageData[messageData.length - 1])
                }
                //si obtenemos un mensaje de fail paramos la ejecución
                if (messageData) {
                    for (var i = 0; i < messageData.length; i++) {
                        if (messageData[i].subState === 'TERMINATED' &&
                            messageData[i].message.includes('FAILED')) {

                            dispatch(actions.getSimulationFromId(simulation._id)).then(response => {
                                if (response.data.state === "Running") {
                                    setTimeout(function () {
                                        dispatch(actions.getSimulationFromId(simulation._id)).then(response => {
                                        })
                                        if (response.data && response.data.errorMessage && response.data.errorMessage !== null) {
                                            setSimulationMessages([...simulationMessages, {
                                                type: '',
                                                state: '',
                                                subState: '',
                                                message: "Simulation with errors:" + response.data.errorMessage
                                            }])
                                        }
                                        setSimulation(undefined)
                                        setIsSimulationFinished(true)

                                    }, 2000);
                                }
                                else {
                                    if (response.data && response.data.errorMessage && response.data.errorMessage !== null) {
                                        setSimulationMessages([...simulationMessages, {
                                            type: '',
                                            state: '',
                                            subState: '',
                                            message: "Simulation with errors:" + response.data.errorMessage
                                        }])
                                    }
                                    setSimulation(undefined)
                                    setIsSimulationFinished(true)
                                }
                            })





                        }
                        else if (messageData[i].subState === 'TERMINATED' &&
                            messageData[i].message.includes('SUCCESS')) {

                            dispatch(actions.getSimulationFromId(simulation._id)).then(response => {

                                if (response.data.state === "Running") {
                                    setTimeout(function () {
                                        dispatch(actions.getSimulationFromId(simulation._id)).then(response => {
                                        })
                                        if (response.data && response.data.errorMessage && response.data.errorMessage !== null) {
                                            setSimulationMessages([...simulationMessages, {
                                                type: '',
                                                state: '',
                                                subState: '',
                                                message: "Simulation with errors:" + response.data.errorMessage
                                            }])
                                        }
                                        setSimulation(undefined)
                                        setIsSimulationFinished(true)

                                    }, 2000);
                                }
                                else {
                                    if (response.data && response.data.errorMessage && response.data.errorMessage !== null) {
                                        setSimulationMessages([...simulationMessages, {
                                            type: '',
                                            state: '',
                                            subState: '',
                                            message: "Simulation with errors:" + response.data.errorMessage
                                        }])
                                    }
                                    setSimulation(undefined)
                                    setIsSimulationFinished(true)
                                }
                            })
                        }
                    }
                }

            })
        }


    }, 1000)




    function stopActivity() {
        if (keepAliveTimer !== null) clearTimeout(keepAliveTimer);
        if (es !== null) es.close()
        setParseMessages(['Sending data to server..'])
        setSimulationMessages(['Sending data to server..'])
    }

    function stopSimulation() {
        if (keepAliveTimer !== null) clearTimeout(keepAliveTimer);
        if (es !== null) es.close()

    }


    /* function gotActivityRunning(){
      if(keepAliveTimer !==null) clearTimeout(keepAliveTimer);
      keepAliveTimer = setTimeout(connectRunning, 30 * 1000);
    }*/

    /*function getNumberRulesFromProjectAndDatasource(project, qpId, dsId) {
        return project.qualityPoints.find(x => x._id === qpId)[0].datasources.find(x => x._id === dsId).rules.length
    }*/
    function getNumberRulesFromProjectAndQP(project, qualityPoint, datasource) {
        if (!qualityPoint) {
            return 0
        }
        if (!project) {
            return 0
        }
        const qpId = qualityPoint._id
        var count = 0
        const qp = project.qualityPoints.find(x => x._id === qpId)
        var i = 0
        var j = 0
        if (qp.datasources && qp.datasources !== null && !datasource) {
            for (i = 0; i < qp.datasources.length; i++) {
                const ds = qp.datasources[i]
                if (ds.rules && ds.rules !== null) {
                    for (j = 0; j < ds.rules.length; j++) {
                        if (ds.rules[j].active === true) {
                            if(ds.rules[j].executeByColumn === true && ds.rules[j].columns &&
                                ds.rules[j].columns.length>0){
                                count = count + ds.rules[j].columns.length
                            }
                            else{
                                count = count + 1
                            }
                            
                        }

                    }

                }

            }
        }
        if (qp.datasources && qp.datasources !== null && datasource) {
            for (i = 0; i < qp.datasources.length; i++) {
                const ds = qp.datasources[i]
                if (ds._id === datasource._id && ds.rules && ds.rules !== null) {
                    for (j = 0; j < ds.rules.length; j++) {
                        if (ds.rules[j].active === true) {
                            
                            if(ds.rules[j].executeByColumn === true && ds.rules[j].columns &&
                                ds.rules[j].columns.length>0){
                                count = count + ds.rules[j].columns.length
                            }
                            else{
                                count = count + 1
                            }
                            
                        }

                    }

                }

            }
        }

        return count

    }







    React.useEffect(() => {
        if (props.selectedQualityPoint) {
            const d = new Date()
            const simName = [
                d.getFullYear(),
                d.getMonth() + 1,
                d.getDate(),].join('_') + '_' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join('_')
                + "_" +
                props.selectedQualityPoint.name

            setSimulationName(simName)

        }
    }, [props]);

    React.useEffect(() => {
        if (selectedQualityPoint) {
            const d = new Date()
            const simName = [
                d.getFullYear(),
                d.getMonth() + 1,
                d.getDate(),].join('_') + '_' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join('_')
                + "_" +
                selectedQualityPoint.name

            setSimulationName(simName)

        }
    }, [selectedQualityPoint]);





    function onChangeSimulationName(event) {
        /*var format = {
            simulationName: event.target.value,
            fileUploadName: fileUploadName
        }*/
        setSimulationName(event.target.value)


    }

    async function addParmeters2Simulation(sim) { 
        if(parameters){
            const newSim = await actions.addParameter2Simulation(sim._id, parameters)
            return newSim.data
        }
        else{
            return sim
        }
     }


    async function onSubmit(e) {

        trackEvent('Simulation', 'Simulate')

        var results = validate()
        if (selectedDatasource && selectedDataformat && (
            selectedDataformat.source === 'azure-storage' || selectedDataformat.source === 'azure-cosmos'
            || selectedDataformat.source === 'azure-sqldatabase'
            || selectedDataformat.source === 'azure-sqlsynapse'
            || selectedDataformat.source === 'sqlServer'
            || selectedDataformat.source === 'mySQL'
            || selectedDataformat.source === 'oracle'
            || selectedDataformat.source === 'bigQuery'
            || selectedDataformat.source === 'awsS3'
            || selectedDataformat.source === 'hive'
            || selectedDataformat.source === 'awsRedshift'
        )) {
            results = validateAzureStorage()
        }

        if (isEmpty(results)) {

            var datasourceId = undefined
            if (selectedDatasource) {
                datasourceId = selectedDatasource.name
            }


            setIsRunningSimulation(true)
            actions.createSimulation(props.currentUser.id, separatorClean, props.project._id, selectedQualityPoint._id, datasourceId, fileUploadName ? fileUploadName : selectedDataformat.name, simulationName, selectedEnvironment)
                .then(async function (sim_) {
                    
                    if (!sim_) {
                        setErrorMessage('Error Creating Simulation: Unexpected error')
                        setIsRunningSimulation(false)
                        return
                    }
                    setSimulation(sim_.data)
                    setSimulationId(sim_.data._id)
                    // connectRunning(sim.data)
                    var sim = sim_.data
                    
                    if(parameters){
                        //set parameters
                        await actions.addParameter2Simulation(sim._id, parameters).then(
                            result=>{
                                sim = result.data
                                setSimulation(sim)
                                setSimulationId(sim._id)
                                runSimulation(datasourceId, sim)
                            }
                        ).catch(e=>{
                            setErrorMessage('Parameters cannot be set. Error '+e)
                            setIsRunningSimulation(false)
                        })
                        if(!sim){
                            setErrorMessage('Parameters cannot be set. Error '+e)
                            setIsRunningSimulation(false)
                        }
                        
                    }
                    else{
                        runSimulation(datasourceId, sim)
                    }

                })
                .catch(function (error) {
                    setErrorMessage('Unexpected error')
                    console.log(error)
                    setIsRunningSimulation(false)
                });

            //const simulation = createSimulation();
            //props.onClickSimulate(simulation, selectedDatasource);


        }
    }

    function runSimulation(datasourceId, sim){
        dispatch(actions.runSimulation(props.currentUser.id, separatorClean, props.project._id, selectedQualityPoint._id,
            datasourceId, fileUploadName, simulationName, selectedEnvironment, sim._id, nSamples))
            .then(simulationOutput => {
                if (simulationOutput !== undefined && simulationOutput.result !== undefined && simulationOutput.result.errorMessage !== undefined &&
                    simulationOutput.result.errorMessage !== null) {
                    var isRuleError = false
                    try {
                        if (simulationOutput.result.errorMessage.includes('status:"') && simulationOutput.result.errorMessage.includes('msg:"')) {
                            isRuleError = true
                        }

                    } catch (e) {
                        isRuleError = false;
                    }

                    if (isRuleError === true) {


                    }
                    else {
                        //simulation with error
                        setIsRunningSimulation(false)
                        dispatchError(dispatch, simulationOutput.result.errorMessage)
                        resetState()
                    }



                }
            })
    }

    function stopSimulationRunning(data) {

        if (data) {
            if (data.substate === 'FINISHED' || data.substate === 'TERMINATED') {
                setSimulation(undefined)
                return true
            }
            /*
            if (nReceivedRules === getNumberRulesFromProjectAndQP(props.project, selectedQualityPoint, selectedDatasource)) {
                setSimulation(undefined)
                return true
            }*/

        }
        /* else {
             if (nReceivedRules === getNumberRulesFromProjectAndQP(props.project, selectedQualityPoint, selectedDatasource)) {
                 setSimulation(undefined)
                 return true
             }
         }*/




        return false


    }



    function onClose() {
        trackEvent('Simulation', 'Close Simulation Dialog')

        if (isRunningSimulation === true && isSimulanionFinished === false) {
            setFlagNotificateSimulationFinish()
        }

        resetState()
        props.onClose();
    }

    function setFlagNotificateSimulationFinish() {
        dispatch(actions.setSimulationNotificable(simulationId))
    }



    async function onUpload(fileName, type) {
        trackEvent('Simulation', 'Upload Data File')

        //si está en automático el separador es automatic y volvemos a sobreescribir

        var separator2Api = separatorClean
        if (checkAutomaticSeparator) {
            separator2Api = 'automatic'
            setSeparator('automatic')
            setSeparatorClean('automatic')
        }

        setIsUploadingData(false)
        setColFormats([])
        setIsParsing(true)

        /* var format = {
             simulationName: simulationName,
             fileUploadName: fileName
         }*/

        //Obtenemos la simulacion del campo de respuesta

        setFileUploadName(fileName)
        validate()

        stopActivity()

        var env2Send = env
        if (env === null || isEmpty(env)) {
            env2Send = getUserPreferenceProperty(user, 'selectedEnvironment')
        }
        const response = type === 'Azure' ? await dispatch(actions.parseDataFormatBlob(separator2Api, fileName, props.userId, undefined, env2Send)) :
            await dispatch(actions.parseDataFormatS3(separator2Api, fileName, props.userId, selectedEnvironment))

        setIsParsing(false)
        setColFormats(response.data.columns)
        setSeparator(response.data.separator)
        setSeparatorClean(response.data.separator)

    }

    function onClickGoConfigPage() {
        resetState()
        history.push('/configuration');
    }
    /*
    function onClickGoDashboardPage() {
        resetState()
        history.push(`/${process.env.REACT_APP_PREFIX}/dashboard/${simulationId}/simulation`);
    }*/

    function onClickGoSimulationPage() {
        resetState()
        history.push(`/${process.env.REACT_APP_PREFIX}/drilldown/dashboard/simulation/${simulationId}`);
    }
    function handleSeparatorChange(event) {

        /*var format = {
            simulationName: simulationName,
            fileUploadName: fileUploadName
        }*/
        setSeparator(event.target.value)



    };


    return (

        <MuiThemeProvider theme={QualityTheme}>
            <Dialog
                aria-labelledby="simulate-dialog-title"
                open={props.visible}
                fullWidth={true}
                maxWidth={'md'}

            >
                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <FontAwesomeIcon icon={faPlayCircle} style={theme.dialogs.dialogTitleAwesomeIcon} />

                        </Grid>
                        <Grid item >
                            <Typography style={theme.dialogs.dialogTitleText}>
                                {`Create Simulation for QualityPoint: ${selectedQualityPoint && selectedQualityPoint.name}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <div>
                    <Snackbar open={openErrorSnack} autoHideDuration={6000} onClose={handleCloseError}
                        anchorOrigin={{ vertical, horizontal }}>
                        <Alert onClose={handleCloseError} severity="error">
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </div >
                {isRunningSimulation &&
                    <div>
                        <DialogContent>
                            <RunningSimulation simulationMessages={(simulationMessages && simulationMessages !== null) ? simulationMessages.map(x => x.message) : []}
                                nExpectedRules={getNumberRulesFromProjectAndQP(props.project, selectedQualityPoint, selectedDatasource)}
                                nReceivedRules={nReceivedRules}
                                isSimulanionFinished={isSimulanionFinished}
                            />
                        </DialogContent>

                        <DialogActions>
                            {isSimulanionFinished && <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={event => {
                                trackEvent('DialogSimulation', 'View Results Button')
                                onClickGoSimulationPage(event)
                            }}>View Results</Button>}
                            <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel} onClick={event => {
                                trackEvent('DialogSimulation', 'Close Button')
                                onClose(event)
                            }}>
                                Close
                            </Button>
                        </DialogActions>
                    </div>
                }
                {!isRunningSimulation && <div>
                    <DialogContent>
                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SelectEnvironmentComponent
                                            setSelectedEnvironment={setSelectedEnvironment} />
                                        {error && error.selectedEnvironment &&
                                            <div className="ui error message" style={theme.errors.errorText}>
                                                <div className="header" >{error.selectedEnvironment}</div>
                                            </div>}
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField className={classNameName} type="text" id='simulationName'
                                            value={simulationName} autoComplete='off'
                                            onChange={onChangeSimulationName}
                                            fullWidth={true}
                                            label="Name"
                                            margin="dense"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FontDownloadIcon style={{ color: '#595959' }} />
                                                    </InputAdornment>)
                                            }} />
                                        {error && error.simulationName &&
                                            <div className="ui error message" style={theme.errors.errorText}>
                                                <div className="header" >{error.simulationName}</div>
                                            </div>}
                                        {(!error || !error.simulationName) &&
                                            <div className="ui error message" style={theme.info.infoText}>
                                                <div className="header" >
                                                    Simulation Name automatically set when Quality Point is selected
                                                </div>
                                            </div>}

                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={checkAutomaticSeparator}
                                                    onChange={event => {
                                                        setCheckAutomaticSeparator(event.target.checked);
                                                        setSeparator('automatic')
                                                    }}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label={<Typography variant='body2'> Automatic Separator Estimation</Typography>}

                                        />
                                    </Grid>
                                    {!checkAutomaticSeparator &&
                                        <Grid item xs={12}>
                                            <TextField type="text" value={separator} autoComplete='off'
                                                id="separator"
                                                label='Separator'
                                                style={{ marginTop: '5px' }}
                                                onChange={handleSeparatorChange}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                {selectedDatasource && selectedDataformat && (selectedDataformat.source === 'azure-storage' ||
                                    selectedDataformat.source === 'azure-cosmos' || selectedDataformat.source === 'azure-sqldatabase'
                                    || selectedDataformat.source === 'azure-sqlsynapse' || selectedDataformat.source === 'sqlServer' || selectedDataformat.source === 'mySQL' ||
                                    selectedDataformat.source === 'oracle' || selectedDataformat.source === 'bigQuery' || selectedDataformat.source === 'awsS3' || selectedDataformat.source === 'hive' || selectedDataformat.source === 'awsRedshift') &&
                                    <Grid item xs={12}>
                                        <div >
                                            <Typography variant='subtitle2'>Select max number of rows to simulate</Typography>
                                            <TextField type='number'
                                                value={nSamples}
                                                onChange={event => setNSamples(event.target.value)}
                                                style={{ wdith: '100%' }}
                                                autoComplete='off'
                                                margin="dense"
                                            />
                                        </div>
                                    </Grid>
                                }
                                {props.configuration && props.configuration.environmentConfigurationList &&
                                    props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0] &&
                                    props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].backendType === 'Azure' &&
                                    (selectedDatasource && selectedDataformat && selectedDataformat.source !== 'azure-storage'
                                        && selectedDataformat.source !== 'azure-cosmos'
                                        && selectedDataformat.source !== 'azure-sqldatabase'
                                        && selectedDataformat.source !== 'azure-sqlsynapse'
                                        && selectedDataformat.source !== 'sqlServer'
                                        && selectedDataformat.source !== 'mySQL'
                                        && selectedDataformat.source !== 'oracle'
                                        && selectedDataformat.source !== 'aws'
                                        && selectedDataformat.source !== 'awsS3'
                                        && selectedDataformat.source !== 'bigQuery'
                                        && selectedDataformat.source !== 'hive'
                                        && selectedDataformat.source !== 'awsRedshift') &&


                                    <Grid item xs={12}>
                                        <div >
                                            <Typography variant='subtitle2'>Select a File to use as Simulation Input</Typography>
                                            <div style={{ marginTop: '5%' }}>
                                                <InputFile onUpload={event => onUpload(event, 'Azure')} folder={`${props.userId}/stagging`}
                                                    storageAccountName={(props.configuration && props.configuration.environmentConfigurationList && props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]) ?
                                                        props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].storageAccountName : 'sainputdemoquality'}
                                                    containerName={(props.configuration && props.configuration.environmentConfigurationList && props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]) ?
                                                        props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].containerName : 'unit-test'}
                                                    sasToken={(props.configuration && props.configuration.environmentConfigurationList && props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]) ?
                                                        props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].sasToken : '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'}
                                                    buttonText={'Upload File'} />
                                            </div>
                                            <div>
                                                <Typography variant='subtitle2'>or select a recently used file</Typography>
                                            </div>
                                            <div>
                                                <Select
                                                    value={selectRecentFile}
                                                    onChange={event => {
                                                        setSelectRecentFile(event.target.value)
                                                        if (event.target.value !== '') {
                                                            setFileUploadName(event.target.value)
                                                            onUpload(event.target.value, 'Azure')
                                                        }
                                                        else
                                                            setFileUploadName(undefined)
                                                        setColFormats([])

                                                    }}
                                                    inputProps={{
                                                        name: 'Recent Files',
                                                        id: 'Recent Files',
                                                    }}
                                                    style={{ width: '55%', marginRight: '20px' }}
                                                >
                                                    <MenuItem key={''} value={''}>
                                                        <Typography variant='caption'>
                                                            None
                                                        </Typography></MenuItem>
                                                    {recentlyUsedFiles && recentlyUsedFiles.length > 0 && recentlyUsedFiles.map(element => {
                                                        if (recentlyUsedFilesInSimulation && recentlyUsedFiles.includes(listBlobHelper.getOnlyNameFromBlob(element))) {


                                                            return <MenuItem key={element.name} value={element.name}>
                                                                <div style={theme.elementAdjacent}>
                                                                    <Tooltip title={"File used in this QualityPoint"}>
                                                                        <FontAwesomeIcon icon={faMousePointer} style={theme.dialogs.tinyIcon} />
                                                                    </Tooltip>

                                                                    <Typography variant='caption'>
                                                                        {listBlobHelper.getOnlyNameFromBlob(element)}
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>

                                                        }
                                                        else {
                                                            return <MenuItem key={element.name} value={element.name}>
                                                                <Typography variant='caption'>
                                                                    {listBlobHelper.getOnlyNameFromBlob(element)}
                                                                </Typography>
                                                            </MenuItem>
                                                        }
                                                    })}

                                                </Select>
                                            </div>
                                            {error && error.colFormats &&
                                                <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >{error.colFormats}</div>
                                                </div>}
                                        </div>
                                    </Grid>
                                }
                                {props.configuration && props.configuration.environmentConfigurationList &&
                                    props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0] &&
                                    props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].backendType === 'AWS' &&
                                    (selectedDatasource && selectedDataformat && selectedDataformat.source !== 'azure-storage'
                                        && selectedDataformat.source !== 'azure-cosmos'
                                        && selectedDataformat.source !== 'azure-sqldatabase'
                                        && selectedDataformat.source !== 'azure-sqlsynapse'
                                        && selectedDataformat.source !== 'sqlServer'
                                        && selectedDataformat.source !== 'mySQL'
                                        && selectedDataformat.source !== 'oracle'
                                        && selectedDataformat.source !== 'aws'
                                        && selectedDataformat.source !== 'awsS3'
                                        && selectedDataformat.source !== 'hive'
                                        && selectedDataformat.source !== 'awsRedshift'
                                        && selectedDataformat.source !== 'bigQuery') &&
                                    <Grid item xs={12}>
                                        <div >
                                            <Typography variant='subtitle2'>Select a File to use as Simulation Input S3</Typography>
                                            <div style={{ marginTop: '5%' }}>
                                                <UploadS3 onUpload={event => onUpload(event, 'AWS')}
                                                    folder={`${props.userId}/stagging`}
                                                    amazonConfiguration={(props.configuration && props.configuration.environmentConfigurationList && props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]) ?
                                                        props.configuration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].amazonConfiguration : {}} />
                                            </div>
                                            {error && error.colFormats &&
                                                <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >{error.colFormats}</div>
                                                </div>}
                                        </div>
                                    </Grid>
                                }
                               

                                <Grid item xs={12}>
                                    {isUploadingData &&
                                        <RunningParseData parseMessages={parseMessages} />}
                                </Grid>
                                {!props.selectedQualityPoint &&
                                    <Grid item xs={8}>
                                        <QualityItemSelector
                                            title={'Select a Quality Point'} type='QualityPoint'
                                            value={selectedQualityPoint}
                                            onChange={event => setSelectedQualityPoint(event.target.value)}
                                            itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints.filter(x => x.active === true) : [])}
                                            itemId={'_id'} itemValue={'name'}
                                        />


                                    </Grid>}
                                {selectedQualityPoint && selectedQualityPoint.datasources &&
                                    selectedQualityPoint.datasources.length > 1 &&
                                    <Grid item xs={8}>


                                        <QualityItemSelector
                                            title={'Select a Data Source'} type='Datasource'
                                            value={selectedDatasource}
                                            onChange={event => setSelectedDatasource(event.target.value)}
                                            itemsArray={selectedQualityPoint.datasources.filter(x => x.active === true)}
                                            itemId={'_id'} itemValue={'name'}
                                        />


                                    </Grid>}
                                {parameters && 
                                <Grid item xs={12}>
                                    <Divider/>
                                    <Typography variant="caption" style={{fontWeight:'bold'}}>Parameters to Fill</Typography>
                                    <Grid container spacing={1} >
                                    {parameters && parameters.map((parameter,paramIndex)=>{
                                        return (<Grid item xs={6}>
                                              <div style={theme.elementAdjacent}>
                                              <Typography variant="caption" style={{marginRight:'10px'}}>{`@${parameter.name}`}</Typography>
                                              <TextField value={parameter.value}
                                               inputProps={{
                                                style: { fontSize: 14, fontFamily:commonStyles.fontFamily }
                                                }}
                                              error={(parameter.value===undefined  || parameter.value===null || parameter.value ==="") ? true : false} 
                                              helperText={(parameter.value===undefined  || parameter.value===null  || parameter.value.trim() ==="") ?  
                                              <Typography variant="caption" style= {{ fontSize: 12, fontFamily:commonStyles.fontFamily }}>A value must be set</Typography> : 
                                              ""}
                                              onChange={event=>updateParameter(paramIndex, event.target.value)}/>
                                              
                                              </div>
                                           
                                        </Grid>)
                                    })}
                                    </Grid>
                                </Grid>}
                                {error && error.selectedQualityPoint &&
                                    <div className="ui error message" style={theme.errors.errorText}>
                                        <div className="header" >{error.selectedQualityPoint}</div>
                                    </div>}

                            </Grid>
                            {selectedDatasource && selectedDataformat &&
                                <Grid item xs={6}>
                                    <div style={{ marginTop: '10px' }}>
                                        <label style={{ fontWeight: 'bold' }}> Columns Obtained from Sample Data </label>
                                        <DataTable value={colFormats ? colFormats : []}
                                            editable={true}
                                            style={{ algin: 'center', width: '100%' }}
                                            scrollable={true} scrollHeight="200px">
                                            <Column field="position" header="Position"
                                                style={{
                                                    background: 'transparent',
                                                    borderColor: 'transparent',
                                                    fontSize: '12px',
                                                    textAlign: 'right',
                                                    width: '20%'
                                                }} />
                                            <Column field="name" header="Name"
                                                style={{
                                                    background: 'transparent',
                                                    borderColor: 'transparent',
                                                    fontSize: '12px',
                                                    textAlign: 'right',
                                                    width: '40%'
                                                }} />
                                            <Column field="type" header="Format"
                                                editor={formatEditor}
                                                style={{
                                                    background: 'transparent',
                                                    borderColor: 'transparent',
                                                    fontSize: '12px',
                                                    textAlign: 'right',
                                                    width: '40%'
                                                }} />

                                        </DataTable>
                                        {
                                            isParsing &&
                                            <div>
                                                <LinearProgress />
                                                <Typography variant={'subtitle2'}> Parsing File. Depending on file's size it may take some time </Typography>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            }
                        </Grid>





                    </DialogContent>
                    <DialogActions>
                        <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={event => {
                            trackEvent('DialogSimulation', 'Simulate')
                            onSubmit(event);
                        }}>Simulate</Button>
                        <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel} onClick={event => {
                            trackEvent('DialogSimulation', 'Cancel')
                            onClose(event)
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
                }

                {props.qualityConfiguration === {} &&
                    <div className="ui error message" style={{
                        display: 'flex',
                        alignItems: 'center', width: '350px', fontSize: '10px'
                    }}>
                        <div className="header" >No Configuration defined. Go to Configuration Page</div>
                        <NavigateNextIcon style={{ cursor: 'pointer' }}
                            key="close"
                            aria-label="Close"
                            color="inherit"

                            onClick={onClickGoConfigPage}
                        />

                    </div>}

            </Dialog>
        </MuiThemeProvider>

    )


}
