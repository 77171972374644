import React from 'react';

import MaterialTable from '@material-table/core'

import { getColumns } from './QualityProjectsTableHelper'
import { Paper, TextField,Typography,Input,InputAdornment, FormControl, InputLabel } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { useSelector } from 'react-redux'
import { StyledMenu, StyledMenuItem } from './../../../common/CustomizedMenu'
import ListItemText from '@material-ui/core/ListItemText';

import * as commonStyle from './../../../../style/CommonStyles'

import { LanguageContext } from './../../../../language/LanguageContext'


const initialState = {
    mouseX: null,
    mouseY: null,
};

export default function QualityProjectsTable(props) {

    const languageContext = React.useContext(LanguageContext);


    var license = useSelector(store => store.license)
    const user = useSelector(store => store.user)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const [state, setState] = React.useState(initialState);
    const [stateCopy, setStateCopy] = React.useState(initialState);

    const [selectedProject2Share, setSelectedProject2Share] = React.useState('')

    const [userFilterText, setUserFilterText] = React.useState("")



    const onCloseShareMenu = () => {
        setState(initialState)
    }

    const onShareForUser = (project, event) => {
        setSelectedProject2Share(project)
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    }
    const onCloseCopyMenu = () => {
        setStateCopy(initialState)
    }

    const onCopyForUser = (project, event) => {
        setSelectedProject2Share(project)
        setStateCopy({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    }

    const editProject = (project) => {
        props.editProject(project)
    }


    const deleteProject = (rowData) => {
        props.deleteProject(rowData)
    }
    const copyProject = (project, userId2Copy) => {
        props.copyProject(project, userId2Copy)
        setStateCopy(initialState)
    }

    const shareProject = (project, userId2Share) => {

        props.shareProject(project, userId2Share)
        setState(initialState)
    }


    function getContextShare() {
        return (
            <div>
                <StyledMenu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={onCloseShareMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    {user && license && license.users && license.users.filter(e => e.email !== user.email).sort((a, b) => (a.name > b.name) ? 1 : -1).map((user, index) =>
                        <StyledMenuItem key={index}>

                            <ListItemText primary={ <Typography variant = "caption">{`${user.name} (${user.email})`}</Typography>} key={index}
                                onClick={event => shareProject(selectedProject2Share, user.id)} />
                        </StyledMenuItem>
                    )}

                </StyledMenu>
            </div>
        )
    }
    function getContextCopy() {
        return (
            <div>
                <StyledMenu
                    keepMounted
                    open={stateCopy.mouseY !== null}
                    onClose={onCloseCopyMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        stateCopy.mouseY !== null && stateCopy.mouseX !== null
                            ? { top: stateCopy.mouseY, left: stateCopy.mouseX }
                            : undefined
                    }
                >
                   
                    
                    <StyledMenuItem key={user.name}>

                        <ListItemText primary={"(You) " + user.name} key={user.name}
                            onClick={event => copyProject(selectedProject2Share, user.id)} />
                    </StyledMenuItem>
                    {user && license && license.users && 
                    license.users.filter(e => e.email !== user.email).sort((a, b) => (a.name > b.name) ? 1 : -1).map((user, index) =>
                        <StyledMenuItem key={index}>

                            <ListItemText primary={
                                <Typography variant = "caption">{`${user.name} (${user.email})`}</Typography>} key={index}
                                onClick={event => copyProject(selectedProject2Share, user.id)} />
                        </StyledMenuItem>
                    )}

                </StyledMenu>
            </div>
        )
    }

    function addId2Rows(array){
        if(array === undefined){
            return array
        }
       return  array.map(x=>{
            x.id=x._id
            return x
        })
    }


    return (

        <div >
            {getContextShare()}
            {getContextCopy()}
            <MaterialTable
                components={{
                    Container: props => <Paper {...props} elevation={0} />
                }}
                data={addId2Rows(props.rows)}
                columns={getColumns(languageContext,editProject, deleteProject, copyProject, anchorEl, setAnchorEl, license, onCloseShareMenu,
                     onShareForUser, onCopyForUser, user)}
                style={{
                    width: '80%',
                    marginLeft: '10%',
                }}
                options={{
                    search: false,
                    paging: (props.rows && props.rows.length > 5) ? true : false,
                    toolbar: false,
                    headerStyle: commonStyle.homeProjectTableHeaderStyle,
                    cellStyle: commonStyle.tableCellStyle
                }}
            />
        </div>
    );

}