import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { Button, MenuItem, Typography, Divider, Popover, Paper } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import {  Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

//import DatePicker from "react-datepicker";
import {  DateTimePicker } from 'react-rainbow-components';
import * as actions from '../../../../actions'
import * as configurationHelper from '../../../common/configurationHelper'
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as helper from './FilterHelper'
import { useInterval } from './../../../common/useInterval'
import ls from 'local-storage'

import * as commonStyles from 'style/CommonStyles'


export default function  FilterMenu(props) {
    
    const theme = useTheme()

    const dispatch = useDispatch();

    const [fetchLevel,setFetchLevel]= useState(-1) //0: project, 1:qualitypoint, 2:datasource 


    const user = useSelector(store => store.user)
    const license = useSelector(store=>store.license)
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))


    const [environment, setEnvironment] = React.useState("")
    const [selectedEnvironment, setSelectedEnvironment] = React.useState("null")

    const [projects, setProjects] = React.useState(props.projects)
    const [projectsLicense , setProjectsLicense] = React.useState(props.projectsLicense)
    const [selectedQualityProject, setselectedQualityProject] = React.useState()

    const [qualityPoints, setQualityPoints] = React.useState([])
    const [selectedQualityPointsId, setSelectedQualityPointsId] = React.useState([])

    const [datasources, setDatasources] = React.useState([])
    const [selectedDatasourcesId, setSelectedDatasourcesId] = React.useState([])

    const [checkedAcc, setcheckedAcc] = React.useState(true)

    const [simulations, setSimulations] = React.useState([])

    //const [filteredSimulations, setFilteredSimulations] = React.useState([])
    //TODO: CHECK
    const filteredSimulations = []
    const [selectedSimulationId, setSelectedSimulationId] = React.useState()


    const [checkedMin, setCheckedMin] = React.useState(false)
    const [checkedHour, setCheckedHour] = React.useState(false)
    const [checkedDay, setCheckedDay] = React.useState(false)

    const [checkedRealTime, setCheckedRealTime] = React.useState(false)


    const [startDate, setStartDate] = useState(ls.get('ds-startDate') ? moment(Date.parse(ls.get('ds-startDate'))).subtract(2, 'months').add(2, 'months').toDate() : moment(new Date()).subtract(2, 'months').toDate());
    const [endDate, setEndDate] = useState(ls.get('ds-endDate') ? moment(Date.parse(ls.get('ds-endDate'))).subtract(2, 'months').add(2, 'months').toDate() : moment(new Date()).subtract(2, 'months').add(2, 'months').toDate());
    const [anchorEl, setAnchorEl] = React.useState(null);


    const [checkedLastExecution, setCheckedLastExecution] = React.useState(false)
    const [lastNexecutions, setLastNexecutions] = React.useState(10)


    function setStartDateAndSave(value){
        ls.set('ds-startDate',value)
        setStartDate(value)
      }
    
      function setEndDateAndSave(value){
        ls.set('ds-endDate',value)
        setEndDate(value)
      }

    useInterval( () => {
        if (checkedRealTime===true ) {
            refreshData()
        }
      }
        //How often (ms) we check if rulerecommenderjob is done
        , 10000)

    React.useEffect(() => { getInitData() }, [props.initialProjectId, props.projects, props.projectsLicense]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(startDate & endDate){
            let value ={
                startDate: startDate,
                endDate: endDate
              }
          dispatch( {
            type : 'SET_DASHBOARD_DATES',
            payload: value
            
        });
        }
        
      }, [startDate,endDate]);// eslint-disable-line react-hooks/exhaustive-deps


    

    function getInitData() {
       if(selectedQualityProject===undefined && props.initialProjectId && (props.projects || props.projectsLicense)){
         if(props.projects.concat(props.projectsLicense).find(x => x._id === props.initialProjectId)){
            setselectedQualityProject(props.projects.concat(props.projectsLicense).find(x => x._id === props.initialProjectId)._id)
            var qps = props.projects.concat(props.projectsLicense).find(x => x._id === props.initialProjectId).qualityPoints
            setQualityPoints(qps && qps.length>0 ? qps : [])
        }
    }

        else if(selectedQualityPointsId.length===0 && props.initialQualityPointId && (props.projects || props.projectsLicense)){
            var project = helper.findProjectByQualityPoint(props.projects.concat(props.projectsLicense), props.initialQualityPointId)
            if(project){
                setselectedQualityProject(project._id)
                setQualityPoints(project.qualityPoints)
                setSelectedQualityPointsId(props.initialQualityPointId)
            }
            
        }

        else if(selectedDatasourcesId.length===0     && props.initialDatasourceId && (props.projects || props.projectsLicense)){
            var {proj,qp} = helper.findProjectAndQualityPointByDatasource(props.projects.concat(props.projectsLicense), props.initialDatasourceId)
            if(proj && qp){

                
                setQualityPoints(proj.qualityPoints)
               
                setDatasources(qp.datasources)
                setSelectedDatasourcesId(props.initialDatasourceId)
                setSelectedQualityPointsId(qp._id)
                setselectedQualityProject(proj._id)
            }
        }
           
       
    }

    React.useEffect(() => {

        if (checkedMin) {
            var startDate_ = new Date()
            var endDate_ = new Date()
            startDate_.setMinutes(startDate_.getMinutes()-1)
            setStartDateAndSave(startDate_);
            setEndDateAndSave(endDate_)
        }
        else if(!checkedDay && !checkedHour && !checkedMin){
            var last2MonthDate = new Date()
            last2MonthDate.setMonth(last2MonthDate.getMonth()-6)
            setStartDateAndSave(last2MonthDate);
            setEndDateAndSave(new Date());

        }

    }, [checkedMin]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {

        if (checkedHour) {
            var startDate_ = new Date()
            var endDate_ = new Date()
            startDate_.setHours(startDate_.getHours()-1)
            setStartDateAndSave(startDate_);
            setEndDateAndSave(endDate_)
        }
        else if(!checkedDay && !checkedHour && !checkedMin){
            var last2MonthDate = new Date()
            last2MonthDate.setMonth(last2MonthDate.getMonth()-6)
            setStartDateAndSave(last2MonthDate);
            setEndDateAndSave(new Date());        
        }

    }, [checkedHour]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {

        if (checkedDay) {
            var startDate_ = new Date()
            var endDate_ = new Date()
            startDate_.setHours(startDate_.getHours()-72)
            setStartDateAndSave(startDate_);
            setEndDateAndSave(endDate_)
        }
        else if(!checkedDay && !checkedHour && !checkedMin) {
            var last2MonthDate = new Date()
            last2MonthDate.setMonth(last2MonthDate.getMonth()-6)
            setStartDateAndSave(last2MonthDate);
            setEndDateAndSave(new Date());
        }

    }, [checkedDay]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (checkedLastExecution && simulations) {
            //first we sort by date
            simulations.sort(function (a, b) {
                var dateA = new Date(a.creationDate), dateB = new Date(b.creationDate);
                return dateA - dateB;
            })

            //once sorted by date, we will take N last simulations
            // setFilteredSimulations(last(sorted_simulations, lastNexecutions))
        }
        else if (!checkedLastExecution && simulations) {
            // setFilteredSimulations((simulations.filter(x => selectedQualityPointsId.includes(x.idQualitypoint))))
        }
    }, [lastNexecutions, checkedLastExecution]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {if (user && user.id) {  dispatch(actions.fetchConfigurationByUserId(user.id))}
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps



    React.useEffect(() => {
        if (qualityConfiguration && configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList,user.id)) {
            setEnvironment(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList,user.id))
        }
    }, [qualityConfiguration]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (props.projects) {
            setProjects(props.projects)
            setProjectsLicense(props.projectsLicense)
        }
    }, [props.projects, props.projectsLicense]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getDataProjectLevel()
    }, [selectedQualityProject, selectedEnvironment]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getDataQualityPointLevel()
    }, [selectedQualityPointsId,startDate,endDate,selectedEnvironment,selectedDatasourcesId]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {

        getDataDatasourceLevel()
    
 }, [selectedDatasourcesId,startDate,endDate,selectedEnvironment]);// eslint-disable-line react-hooks/exhaustive-deps


    function getDataProjectLevel(){
        setFetchLevel(0)
        let date = new Date()

        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hour = date.getHours()
        let min = date.getMinutes()
        let secs = date.getSeconds()

        if (selectedQualityProject && (!selectedDatasourcesId || selectedDatasourcesId===-1 || selectedDatasourcesId.length===0)) {
            setQualityPoints(props.projects.concat(props.projectsLicense).find(x => x._id === selectedQualityProject).qualityPoints)

            let qps = props.projects.concat(props.projectsLicense).find(x => x._id === selectedQualityProject).qualityPoints
            setQualityPoints(qps)

            if(startDate && endDate){
                dispatch(actions.getMonitoringDAOForProjectBetweenDatesEnvironment(selectedQualityProject,startDate,endDate,selectedEnvironment)).then(response => {
                    setSimulations(response.data)
                    functionHandler(response.data)
                })
                let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
                props.setPdfUrl(`/dashboard/project/pdf/${selectedQualityProject}/${startDate}/${endDate}/1000/${selectedEnvironment}/${projectName}`)
                props.setPdfName(projectName.replace(/\s+/g,"_")+"_report_"+year+"-"+month+"-"+day+"-"+hour+"-"+min+"-"+secs+".pdf")
            }
               else{
                dispatch(actions.getMonitoringDAOForProjectEnvironment(selectedQualityProject,selectedEnvironment)).then(response => {
                    setSimulations(response.data)
                    functionHandler(response.data)
                })
                let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
                props.setPdfUrl(`/dashboard/project/pdf/${selectedQualityProject}/1000/${selectedEnvironment}/${projectName}`)
                props.setPdfName(projectName.replace(/\s+/g,"_")+"_report_"+year+"_"+month+"_"+day+"-"+hour+"-"+min+"-"+secs+".pdf")
               }
        }
    }
    
    function getDataQualityPointLevel(){
        setFetchLevel(1)
        let date = new Date()

        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hour = date.getHours()
        let min = date.getMinutes()
        let secs = date.getSeconds()
        if(selectedDatasourcesId===-1){

        
           if (selectedQualityPointsId && selectedQualityPointsId.length>0 && selectedQualityPointsId!==-1) {
           if(startDate && endDate){
            
               
            dispatch(actions.getMonitoringDAOForQualityPointBetweenDatesEnvironment(selectedQualityPointsId,startDate,endDate,selectedEnvironment)).then(response => {

                setSimulations(response.data)
                functionHandler(response.data)
            })
            let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
            let qpName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).qualityPoints.find(x=>x._id===selectedQualityPointsId).name
            props.setPdfUrl(`/dashboard/qualitypoint/pdf/${selectedQualityPointsId}/${startDate}/${endDate}/1000/${selectedEnvironment}/${projectName}/${qpName}`)
            props.setPdfName(projectName.replace(/\s+/g,"_")+"_"+qpName.replace(/\s+/g,"_")+"_report_"+year+"_"+month+"_"+day+"-"+hour+"-"+min+"-"+secs+".pdf")
        }
           else{
            dispatch(actions.getMonitoringDAOForQualityPointEnvironment(selectedQualityPointsId,selectedEnvironment)).then(response => {

                setSimulations(response.data)
                functionHandler(response.data)
            })
            let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
            let qpName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).qualityPoints.find(x=>x._id===selectedQualityPointsId).name
            
            props.setPdfUrl(`/dashboard/qualitypoint/pdf/${selectedQualityPointsId}/1000/${selectedEnvironment}/${projectName}/${qpName}`)
            props.setPdfName(projectName.replace(/\s+/g,"_")+"_"+qpName.replace(/\s+/g,"_")+"_report_"+year+"_"+month+"_"+day+"-"+hour+"-"+min+"-"+secs+".pdf")

           }
            

            
        }
        else{
            if (selectedQualityProject) {
                setQualityPoints(props.projects.concat(props.projectsLicense).find(x => x._id === selectedQualityProject).qualityPoints)
    
                let qps = props.projects.concat(props.projectsLicense).find(x => x._id === selectedQualityProject).qualityPoints
                setQualityPoints(qps)
    
                if(startDate && endDate){
                    dispatch(actions.getMonitoringDAOForProjectBetweenDatesEnvironment(selectedQualityProject,startDate,endDate,selectedEnvironment)).then(response => {
                        setSimulations(response.data)
                        functionHandler(response.data)
                    })
                    let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
                    props.setPdfUrl(`/dashboard/project/pdf/${selectedQualityProject}/${startDate}/${endDate}/1000/${selectedEnvironment}/${projectName}`)
                    props.setPdfName(projectName.replace(/\s+/g,"_")+"_report_"+year+"_"+month+"_"+day+"-"+hour+"-"+min+"-"+secs+".pdf")

                }
                   else{
                    dispatch(actions.getMonitoringDAOForProjectEnvironment(selectedQualityProject,selectedEnvironment)).then(response => {
                        setSimulations(response.data)
                        functionHandler(response.data)
                    })
                    let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
                    props.setPdfUrl(`/dashboard/project/pdf/${selectedQualityProject}/1000/${selectedEnvironment}/${projectName}`)
                    props.setPdfName(projectName.replace(/\s+/g,"_")+"_report_"+year+"_"+month+"_"+day+"-"+hour+"-"+min+"-"+secs+".pdf")
                   }
            }
        }
    }
    }
    
    function getDataDatasourceLevel(){
        setFetchLevel(1)
        let date = new Date()

        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hour = date.getHours()
        let min = date.getMinutes()
        let secs = date.getSeconds()

        if (selectedDatasourcesId && selectedDatasourcesId.length>0 && selectedDatasourcesId!==-1) {
        if(startDate && endDate){
         
             
         dispatch(actions.getMonitoringDAOForDatasourcesEnvironment(selectedDatasourcesId,selectedEnvironment)).then(response => {

             setSimulations(response.data)
             functionHandler(response.data)
         })
         let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
         let dsName = datasources.find(x=>x._id===selectedDatasourcesId) ? datasources.find(x=>x._id===selectedDatasourcesId).name : 'ds'
         props.setPdfUrl(`/dashboard/datasource/pdf/${selectedDatasourcesId}/${startDate}/${endDate}/1000/${selectedEnvironment}/${projectName}/${dsName}`)
         props.setPdfName(projectName.replace(/\s+/g,"_")+"_"+dsName.replace(/\s+/g,"_")+"_report_"+year+"_"+month+"_"+day+"-"+hour+"-"+min+"-"+secs+".pdf")

        }
        else{
         dispatch(actions.getMonitoringDAOForDatasourcesEnvironment(selectedDatasourcesId,selectedEnvironment)).then(response => {

             setSimulations(response.data)
             functionHandler(response.data)
         })
         let projectName = props.projects.concat(props.projectsLicense).find(x=>x._id===selectedQualityProject).name
         let dsName = datasources.find(x=>x._id===selectedDatasourcesId).name
         props.setPdfUrl(`/dashboard/datasource/pdf/${selectedDatasourcesId}/1000/${selectedEnvironment}/${projectName}/${dsName}`)
         props.setPdfName(projectName.replace(/\s+/g,"_")+"_"+dsName.replace(/\s+/g,"_")+"_report_"+year+"_"+month+"_"+day+"-"+hour+"-"+min+"-"+secs+".pdf")
      
        }
         

         
     }
    }

   



   
    
    


    React.useEffect(() => {
        if (qualityPoints && selectedQualityPointsId) {

            setDatasources(compare(qualityPoints, selectedQualityPointsId))

            
        }

    }, [selectedQualityPointsId]);// eslint-disable-line react-hooks/exhaustive-deps

    
    function findUserNameFromIdInLicense(license,userId){
     
        if(license && license._id && license.users && license.users.length>0){
            for(var i=0;i<license.users.length;i++){
                if(license.users[i].id===userId){
                    return license.users[i].name
                }
            }
        }
        return userId
    }


   
    const functionHandler = (data) => {

        props.passChildData(data);

    }

    /*
    const functionHandlerRaw = (data) => {

        props.passRawChildData(data);

    }*/

    

    function compare(arr1, arrTmp) {

        const arr2 = [arrTmp]

        const objMap = [];
        if(arr1 !== undefined){
        if(arr1 && arr2){
            arr1.forEach((e1) => arr2.forEach((e2) => {
                if (e1._id === e2) {
                    if(e1.datasources){
                        e1.datasources.forEach((ds) => { objMap.push(ds) })
                    }

                }
            }
            ));
            return objMap;
        }
        else{
            return objMap;
        }
    }
        else{
            return objMap;
        }

    }


    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        props.setOpenFilter(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        props.setOpenFilter(false)
    };
    
    const getMenuItems = (itemsArray) => {
        let itemsToShow = []
        itemsArray.map(m =>
            itemsToShow.push(<MenuItem key={m._id} value={m._id}>
                <Typography variant = 'caption' style={{fontWeight:'bold'}}>
                    {`(own) ${m.name}`}</Typography></MenuItem>)
        )
        return itemsToShow
    }

    const getMenuItemsLicense = (itemsArray) => {
        let itemsToShow = []
        if(itemsArray && itemsArray.length>0){
            itemsArray.filter(m=>(!m.userPermission || m.userPermission.type==='Public')).map(m =>
                itemsToShow.push(<MenuItem key={m._id} value={m._id}>
                    <Typography variant = 'caption'>
                    {`(${findUserNameFromIdInLicense(license,m.userId)}) ${m.name}`}
                    </Typography></MenuItem>)
            )
        }
        
        return itemsToShow
    }

    var handleChangeMin = (ev) => {
        setCheckedDay(false)
        setCheckedHour(false)
        setCheckedMin(ev)
        //poner al ultimo minuto las variables de fechas
    };
    var handleChangeRealTime = (ev) => {
        
        setCheckedRealTime(ev)
    };
    var handleChangeHour = (ev) => {
        setCheckedDay(false)
        setCheckedHour(ev)
        setCheckedMin(false)
        //poner a la ultima hora las variables de fechas
    };
    var handleChangeDay = (ev) => {
        setCheckedDay(ev)
        setCheckedHour(false)
        setCheckedMin(false)
        //poner al ultimo dia las variables de fechas

    };

    //takes the last N elements of an array
    var first = function first(array, n) {
        if (array === null) return void 0;

        return array.slice(0, n);
    };

    function setQualityProjectCallback(event) {
        let selectedProject = event.target.value
        setSelectedDatasourcesId(-1)
        setSelectedQualityPointsId(-1)
        setselectedQualityProject(selectedProject)


    }
    var filterByDateRange = function filterByDateRange(array, startDate_, endDate_) {
        return array.filter(a => {
            var date = new Date(a.creationDate);
            return (date >= startDate_ && date <= endDate_);
        })
    };

    function refreshData(){
        setEndDate(new Date())
        if(fetchLevel===0){
            getDataProjectLevel()
        }
        else if(fetchLevel === 1){
            getDataQualityPointLevel()
        }
        else if (fetchLevel === 2){
            getDataDatasourceLevel()
        }
    }

  
    

    return (
        <div >

            <Button aria-controls="simple-menu" startIcon={<FilterListIcon />} aria-haspopup="true" onClick={handleClick}>
                Filter
            </Button>

            <Popover
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginTop:'30px'}}
            >

                <Paper style={{ width: "21vw", height: "100%" }}  >
                <div  >
               
                        <div style={theme.dashboard.filter.mainTitleDiv}>
                            <Typography variant='subtitle2' style={{ color: '#FFFFFF' }} > Source Selection</Typography>
                        </div>


                        <div style={{marginLeft:'15px'}}>
                        <Button  
                             style={{...theme.buttons.buttonRegular, color:'#474b51', marginRight:'15px'}}
                             startIcon={<FontAwesomeIcon icon={faSync} 
                             style={{ fontSize:15, color:commonStyles.mainColor}}
                             onClick={event=>refreshData()}
                              />} 
                             >
                             <div style={theme.common.text.buttonTextSmall}>
                             Refresh Data
                             </div>
                             
                                     
                             </Button>

                        <FormControlLabel
                                        control={<Checkbox checked={checkedRealTime} onChange={event => handleChangeRealTime(!checkedRealTime)} name="checkedRealTime" />}
                                        label={<Typography variant='caption' > RealTime Update</Typography>}
                                        labelPlacement="left"
                                    />
                                    </div>
                        <FormControl style={theme.dashboard.filter.form}>

                            <InputLabel htmlFor="age-native-simple">
                                <Typography variant='caption' > Quality Project</Typography>
                            </InputLabel>
                            <Select
                                onChange={setQualityProjectCallback}
                            >
                                {getMenuItems(projects)}
                                {getMenuItemsLicense(projectsLicense)}
                            </Select>
                        </FormControl>

                        <FormControl style={theme.dashboard.filter.form}>

                            <InputLabel htmlFor="age-native-simple">
                                <Typography variant='caption' > Quality Point</Typography>
                            </InputLabel>
                            <Select
                                
                                renderValue={(selected) => (' ')}
                                value={(selectedQualityPointsId)}
                                onChange={event => setSelectedQualityPointsId(event.target.value)}
                            >
                                 <MenuItem key={-1} value={-1}>
                                            <Typography variant='caption' style={{marginLeft:'10px'}}> All</Typography>
                                        </MenuItem>
                                {qualityPoints && qualityPoints.map(m =>{
                                    
                                    return(
                                    <MenuItem key={m._id} value={m._id}>
                                        <Checkbox checked={selectedQualityPointsId===m._id} />
                                        <Typography variant='caption' > {m.name}</Typography>
                                    </MenuItem>
                                    )
                                }
                                )}
                            </Select>
                        </FormControl>


                        <FormControl style={theme.dashboard.filter.form} >

                            <InputLabel htmlFor="age-native-simple">
                                <Typography variant='caption' > DataSource</Typography>
                            </InputLabel>
                            <Select
                                
                                value={selectedDatasourcesId}
                                renderValue={(selected) => (' ')}
                                onChange={event => {setSelectedDatasourcesId(event.target.value)
                                }
                            }
                            >
                                 <MenuItem key={-1} value={-1}>
                                            <Typography variant='caption' style={{marginLeft:'10px'}}> All</Typography>
                                        </MenuItem>
                                {datasources && datasources.map(m =>
                                    <MenuItem key={m._id} value={m._id}>
                                        <Checkbox checked={selectedDatasourcesId===m._id} />
                                        <Typography variant='caption' > {m.name}</Typography>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>



                        {props.viewtype === "simulation" && <div style={{ minWidth: '100%' }}>
                            <FormControl style={theme.dashboard.filter.form} >

                                <InputLabel htmlFor="age-native-simple">
                                    <Typography variant='caption' > Simulation</Typography>
                                </InputLabel>
                                <Select
                                    value={selectedSimulationId}
                                    onChange={event => setSelectedSimulationId(event.target.value)}
                                >
                                   
                                    {filteredSimulations && checkedLastExecution && filterByDateRange(first(filteredSimulations.filter(function (el) {
                                        return el.state === "Running" || el.state >= "Succeed" || el.state >= "Finished";
                                    }), lastNexecutions), startDate, endDate).map(m =>
                                        <MenuItem key={m._id} value={m._id}>
                                            <Typography variant='caption' > {m.name}</Typography>
                                        </MenuItem>
                                    )}
                                    {filteredSimulations && !checkedLastExecution && filterByDateRange(filteredSimulations.filter(function (el) {
                                        return el.state === "Running" || el.state >= "Succeed" || el.state >= "Finished";
                                    }), startDate, endDate).map(m =>
                                        <MenuItem key={m._id} value={m._id}>
                                            <Typography variant='caption' > {m.name}</Typography>
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                        </div>}
                        <div style={{ marginTop: '6%' }}>
                            <Divider light />
                        </div>
                        <div style={theme.dashboard.filter.mainTitleDiv}>
                            <Typography variant='subtitle2' style={{ color: '#FFFFFF' }} > Environment Selection</Typography>
                        </div>

                        <FormControl style={theme.dashboard.filter.form}  >
                            <InputLabel size={"medium"} htmlFor="Environment-form">
                                <Typography variant='caption' > Environment</Typography>

                            </InputLabel>
                            <Select
                                onChange={event => setSelectedEnvironment(event.target.value)}
                            >
                                <MenuItem key={'null'} value={'null'} >
                                        <Typography variant='caption' >  All</Typography>
                                    </MenuItem>
                                {environment && environment.map(e =>
                                    <MenuItem key={e.environmentName} value={e.environmentName} >
                                        <Typography variant='caption' >  {e.environmentName}</Typography>
                                    </MenuItem>)}
                                {!environment &&
                                    <MenuItem key={environment._id} value={environment._id} >
                                        <Typography variant='caption' >  {selectedEnvironment}</Typography>
                                        {selectedEnvironment}</MenuItem>}

                            </Select>
                        </FormControl>



                        {undefined && <div >
                            <div style={{ marginTop: '6%' }}>
                                <Divider light />
                            </div>
                            <Grid container >
                                <Grid item xs={6} >
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedAcc} onChange={event => setcheckedAcc(!checkedAcc)} name="checkedA" />}
                                        label="Accumulative"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={!checkedAcc} defaultChecked={false} onChange={event => setcheckedAcc(!checkedAcc)} name="checkedA" />}
                                        label="Split Panel"
                                    />
                                </Grid>
                            </Grid>
                            <Divider light />
                        </div>}
                        <div style={{ marginTop: '6%' }}>
                            <Divider light />
                        </div>

                        <div style={theme.dashboard.filter.mainTitleDiv}>
                            <Typography variant='subtitle2' style={{ color: '#FFFFFF' }} > Time Period Selection</Typography>
                        </div>
                        <div >
                            <div style={{ marginTop: '2%' }}>
                            </div>
                            <Grid container >

                                <Grid item xs={4} >
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedMin} onChange={event => handleChangeMin(!checkedMin)} name="checkedA" />}
                                        label={<Typography variant='caption' > Last min</Typography>}
                                        labelPlacement="top"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedHour} defaultChecked={false} onChange={event => handleChangeHour(!checkedHour)} name="checkedA" />}
                                        label={<Typography variant='caption' > Last hour</Typography>}
                                        labelPlacement="top"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedDay} defaultChecked={false} onChange={event => handleChangeDay(!checkedDay)} name="checkedA" />}
                                        label={<Typography variant='caption' > Last day</Typography>}
                                        labelPlacement="top"
                                    />
                                </Grid>

                                {
                                    <div style={{ marginLeft:'20px' }}>
                                    < Grid container >

                                        <Grid item xs={6}>

                                            <DateTimePicker
                                                disabled={checkedDay || checkedHour || checkedMin}
                                                value={startDate}
                                                onChange={date => setStartDateAndSave(date)}
                                                formatStyle="small"
                                                style={{
                                                    maxWidth: "85%",
                                                    marginRight: "3%"
                                                }}
                                                label={<Typography variant='caption' > From </Typography>}

                                            />

                                        </Grid>

                                        <Grid item xs={6}>
                                            <DateTimePicker
                                                disabled={checkedDay || checkedHour || checkedMin}

                                                value={endDate}
                                                onChange={date => setEndDateAndSave(date)}
                                                formatStyle="small"
                                                style={{
                                                    
                                                    maxWidth: "85%",
                                                    marginRight: "5%"
                                                }}
                                                label={<Typography variant='caption' > To </Typography>}
                                            />

                                        </Grid>

                                    </Grid>
                                    </div>}

                            </Grid>
                            <div style={{ marginTop: '6%' }}>
                            <Divider light />
                        </div>
                        </div>
                        <div style={{ marginLeft:'20px' }}>
                        <Grid container >
                            <Grid item xs={6}>
                            {undefined &&   <FormControlLabel
                                    control={<Checkbox checked={checkedLastExecution} defaultChecked={false} onChange={event => setCheckedLastExecution(!checkedLastExecution)} name="checkedLastM" />}
                                    label={<Typography variant='caption' > Last Executions </Typography>}
                                />}

                            </Grid>
                            {checkedLastExecution &&
                                <Grid item xs={6}>
                                   {undefined && <FormControl size={"medium"}  >

                                        <InputLabel htmlFor="age-native-simple">
                                        <Typography variant='caption' > Last Executions </Typography>
                                        </InputLabel>
                                        <Select
                                            value={lastNexecutions}
                                            onChange={event => setLastNexecutions(event.target.value)}
                                        >
                                            <MenuItem key={1} value={1}>1</MenuItem>
                                            <MenuItem key={2} value={2}>2</MenuItem>
                                            <MenuItem key={3} value={3}>3</MenuItem>
                                            <MenuItem key={4} value={4}>4</MenuItem>
                                            <MenuItem key={5} value={5}>5</MenuItem>
                                            <MenuItem key={6} value={6}>6</MenuItem>
                                            <MenuItem key={7} value={7}>7</MenuItem>
                                            <MenuItem key={8} value={8}>8</MenuItem>
                                            <MenuItem key={9} value={9}>9</MenuItem>
                                            <MenuItem key={10} value={10}>10</MenuItem>
                                        </Select>
                                    </FormControl>}
                                </Grid>
                            }
                        </Grid>
                        </div>
                    </div>
                
                </Paper>
            </Popover>
        </div >
    );
}