import React from 'react';

import {
    Accordion, AccordionSummary, AccordionDetails, Grid, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import {  QualityTheme } from '../../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';

import TagsTable from './TagsTable'

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'


export default function QualityTagsConfiguration(props) {
    const languageContext = React.useContext(LanguageContext); 

    return (
        <MuiThemeProvider theme={QualityTheme}>
            <Accordion
                style={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid item xs={1}>
                        <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='subtitle2'>{languageContext.dictionary['tagsConfiguration']}</Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    <TagsTable configuration={props.configuration} tags={props.tags} setTagsConfiguration = {props.setTagsConfiguration} />
                </AccordionDetails>
            </Accordion>
        </MuiThemeProvider>
    )
}


