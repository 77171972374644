import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { QualityTheme } from './../../../style/CustomTheme';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from  '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import LaunchIcon from '@material-ui/icons/Launch';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import SearchIcon from  '@material-ui/icons/Search';

import {useDispatch,useSelector} from 'react-redux'


import * as actions from '../../../actions';


import { makeStyles } from "@material-ui/core/styles";

import {isEmpty} from 'commonFunctions/commonFunctions'


import * as commonStyles from 'style/CommonStyles'
import { useHistory } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
   
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width:'80%'
    }
  }));


export default function QualitySimulationRepository(props) {

    const classes = useStyles();
    const history = useHistory(); 


    const dispatch = useDispatch();
    const projects = useSelector(store => Object.values(store.projects))
    const user = useSelector(store => store.user)

    var simulations = useSelector(store => Object.values(store.simulations))

    //const [qualityPoints, setQualityPoints] = React.useState([])

    const [selectedProject, setSelectedProject] = React.useState()
    const [initDate, setInitDate] = React.useState()
    const [endDate, setEndDate] = React.useState()
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState()
    const [simulationState, setSimulationState] = React.useState(null)
    const [selected, setSelected] = React.useState()

    function getProjects(){
        dispatch(actions.fetchProjects(user.id))
    }
    /*
    function getsimuationsByUserIdAndDates(){
        dispatch(actions.fetchSimulationsByUserIdByDates(user.id,initDate,endDate,simulationState))
    }*/

    React.useEffect(() => {
        if(user && user.id){
            getProjects(user)
        }
        if(user && !isEmpty(user)){
            fetchData() 
        }
       
    },[user])// eslint-disable-line react-hooks/exhaustive-deps




    //constructor method
    React.useEffect(() => {
        dispatch(actions.fetchUser(history)  )
        var today = new Date();
        
        var now = today.getFullYear()+'-'+pad(today.getMonth()+1)+'-'+pad(today.getDate())+"T"+pad(today.getHours()) + ":" + pad(today.getMinutes()) ;
        today.setDate(today.getDate()-7)
        var yesterday = today.getFullYear()+'-'+pad(today.getMonth()+1)+'-'+pad(today.getDate())+"T"+pad(today.getHours()) + ":" + pad(today.getMinutes()) ;
        
        setInitDate(yesterday)
        setEndDate(now)
        if(user && !isEmpty(user)){
            dispatch(actions.fetchSimulationsByUserIdByDates(user.id,yesterday,now,simulationState))
        }
        

        
        
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //constructor method
    React.useEffect(() => {
        fetchData() 
        
    }, [selectedProject,selectedQualityPoint,simulationState]);// eslint-disable-line react-hooks/exhaustive-deps

    function fetchData () {

        var today = new Date();
        
        var now = today.getFullYear()+'-'+pad(today.getMonth()+1)+'-'+pad(today.getDate())+"T"+pad(today.getHours()) + ":" + pad(today.getMinutes()) ;
        today.setDate(today.getDate()-7)
        var yesterday = today.getFullYear()+'-'+pad(today.getMonth()+1)+'-'+pad(today.getDate())+"T"+pad(today.getHours()) + ":" + pad(today.getMinutes()) ;
        
       
        
        if(!initDate){
            setInitDate(yesterday)
        }
        if(!endDate){
            setEndDate(now)
        }


        if(!selectedProject && !selectedQualityPoint){
            dispatch(actions.fetchSimulationsByUserIdByDates(user.id,initDate,endDate,simulationState))
        }
        else if(selectedProject && !selectedQualityPoint){
            dispatch(actions.fetchSimulationsByProjectByDates(selectedProject._id,initDate,endDate,simulationState))
        }
        else if(selectedProject && selectedQualityPoint){
            dispatch(actions.fetchSimulationsByProjectAndQPByDates(selectedProject._id,selectedQualityPoint._id,initDate,endDate,simulationState))
        }
        /*
        if(state.selectedQualityPoint){
            props.fetchSimulationsByProjectAndQPByDates(state.selectedProject._id,state.selectedQualityPoint._id,state.initDate,state.endDate, state.simulationState)
        }
        else {
            props.fetchSimulationsByProjectByDates(state.selectedProject._id,state.initDate,state.endDate, state.simulationState)
        }
        setState({selected: []})*/
        
    }

    
    function setQualityPointsCallback(){
        if(selectedProject !==null){
            //TODO: CHECK
            //setQualityPoints(selectedProject.qualityPoints)
        }
    }


    
/*
    function clean() {
        setSelectedQualityPoint(null)
        setSimulationState(null)
        setSelected([])
        
    }
    */

    function pad(n) {
        return n<10 ? '0'+n : n
    }

    function onDeleteSimulations () {
        
        dispatch(actions.deleteSimulations(selected.filter(x=>x)))
    }

    /*
    function onClose (){
        clean();
    }*/

    function onSelectedQP (e) {
        setSelectedQualityPoint(e.target.value)
        
    }
    function onSelectedSimulationState (e) {
        setSimulationState(e.target.value)
        
    }
    function changeInitTime (event) {
        setInitDate(event.target.value)
        fetchData()
        
    }
    function changeEndTime (event) {
        setEndDate(event.target.value)
        fetchData()

    }
    
    function handleSelectAllClick (event)  {
        
        if (event.target.checked) {
          const newSelecteds = simulations.map(n => n._id);
          setSelected(newSelecteds)
          return;
        }
        setSelected([])
      }
 /*
    function desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

     
    function getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
      }*/

     function handleClick(event, id) {
            
                var selectedIndex = 0
            if(selected){
                selectedIndex = selected.indexOf(id);
            }
            else{
                selectedIndex = [].indexOf(id);
            }
            
            let newSelected = [];
        
            if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            }
            setSelected(newSelected)
        
        
      }

      function compareSimulations( a, b ) {
        if ( a.creationDate > b.creationDate ){
          return -1;
        }
        if ( a.creationDate < b.creationDate ){
          return 1;
        }
        return 0;
      }

     function isItemSelected(id) {
         if(selected){
            return (selected.indexOf(id) !== -1)
         }
         return false
        
        };

    function viewSimDetails(id){
        
            props.history.push(`/${process.env.REACT_APP_PREFIX}/dashboard/${id}/simulation`)
            
        }
        
    function onClickExportDataFile (row) {
           /* fetch(basePath+`/simulation/datafile/${row._id}`)
        .then(response => {
          
         response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = row.dataFileName
            a.click();
        });
     });*/
        }

        function onClickExportConfigFile (row) {
           /* fetch(basePath+`/simulation/configFile/${row._id}`)
        .then(response => {
         
         response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = row.dataFileName
            a.click();
        });
     });*/
        }

        return (
            <MuiThemeProvider theme={QualityTheme}>
              
              
                        <Grid container spacing={2} alignItems = 'flex-end'>
                            <Grid item xs={2} >
                                <TextField
                                    id="datetime-local"
                                    style={{marginBottom:'10px'}}
                                    label="From Time"
                                    type="datetime-local"
                                    value={initDate}
                                    
                                    onChange={changeInitTime}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <TextField 
                                id="datetime-local"
                                style={{marginBottom:'10px'}}
                                label="To Time"
                                type="datetime-local"
                                value={endDate}
                                
                                onChange={changeEndTime}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} >
                                <FormControl className={classes.formControl} >
                                    <Typography htmlFor="age-simple">Project</Typography>
                                        <Select

                                            value={selectedProject}
                                            onChange={event => {setSelectedProject(event.target.value); setQualityPointsCallback();}}
                                            inputProps={{
                                                name: 'Select a Project',
                                                id: 'project-selection',

                                            }}
                                        >
                                            <MenuItem key={-1} value={null}><div style={commonStyles.adjacentSpaceBetween}>
                                                    <AllInclusiveIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>All</Typography></div>
                                                </MenuItem>
                                            {projects && projects.map((value) =>
                                                <MenuItem key={value._id} value={value}><div style={commonStyles.adjacentSpaceBetween}>
                                                    <LaunchIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>{value.name}</Typography></div>
                                                </MenuItem>
                                            )}
                                        </Select>  
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} >
                                <FormControl className={classes.formControl} >
                                    <Typography htmlFor="age-simple">QualityPoint</Typography>
                                        <Select

                                            value={selectedQualityPoint}
                                            onChange={onSelectedQP}
                                            inputProps={{
                                                name: 'Select a QualityPoint',
                                                id: 'project-selection',

                                            }}
                                        >
                                            <MenuItem key={-1} value={null}><div style={commonStyles.adjacentSpaceBetween}>
                                                    <AllInclusiveIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>All</Typography></div>
                                                </MenuItem>
                                            {selectedProject && selectedProject.qualityPoints && selectedProject.qualityPoints.map((value) =>
                                                <MenuItem key={value._id} value={value}><div style={commonStyles.adjacentSpaceBetween}>
                                                    <LaunchIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>{value.name}</Typography></div>
                                                </MenuItem>
                                            )}
                                        </Select>  
                                </FormControl>
                            </Grid>
                       

                            <Grid item xs={2} >
                                <FormControl className={classes.formControl} >
                                    <Typography htmlFor="age-simple">Simulation State</Typography>
                                    <Select

                                        value={simulationState}
                                        onChange={onSelectedSimulationState}
                                        inputProps={{
                                            name: 'Select a Simulation State',
                                            id: 'project-selection',

                                        }}
                                    >
                                        <MenuItem key={-1} value={null}><div style={commonStyles.adjacentSpaceBetween}>
                                                <AllInclusiveIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>All</Typography></div>
                                            </MenuItem>
                                            <MenuItem key={-1} value='stagging'><div style={commonStyles.adjacentSpaceBetween}>
                                                <AllInclusiveIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>Stagging</Typography></div>
                                            </MenuItem>
                                            <MenuItem key={-1} value='running'><div style={commonStyles.adjacentSpaceBetween}>
                                                <AllInclusiveIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>Running</Typography></div>
                                            </MenuItem>
                                            <MenuItem key={-1} value='finished'><div style={commonStyles.adjacentSpaceBetween}>
                                                <AllInclusiveIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>Finished</Typography></div>
                                            </MenuItem>
                                            <MenuItem key={-1} value='error'><div style={commonStyles.adjacentSpaceBetween}>
                                                <AllInclusiveIcon fontSize="small" style={{ color: '#5292f9' }} /> <Typography>Error</Typography></div>
                                            </MenuItem>
                                       
                                    </Select>  </FormControl>
                            </Grid>
                        </Grid>
                        
                    
                        <Table style={{ width: "auto", tableLayout: "auto" }}>
                            <TableHead>
                                <TableRow>
                                <TableCell width="10%" padding="checkbox"> 
                                <Checkbox onClick={handleSelectAllClick} color='primary' />
                                </TableCell>   
                                    <TableCell width="10%" align="left">Timestamp</TableCell>
                                    <TableCell width="10%" align="left">Name</TableCell>
                                    <TableCell width="10%" align="left">Project</TableCell>
                                    <TableCell width="10%" align="left">QualityPoint</TableCell>
                                    <TableCell width="30%" align="left">Data Filename</TableCell>
                                    <TableCell width="10%" align="left">State</TableCell>
                                    <TableCell width="10%" align="left"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {simulations && !isEmpty(simulations) &&
                             simulations.sort(compareSimulations).map(row => (
                                <TableRow key={row._id}
                                hover
                                 onClick={event => handleClick(event, row._id)}>
                                <TableCell width="10%" padding="checkbox">
                                    <Checkbox checked={isItemSelected(row._id)} color='primary' />
                                 </TableCell>
                                    <TableCell width="10%" component="th" scope="row" align="left">
                                    {row.creationDate ? row.creationDate.substring(0,16) : ''}
                                    </TableCell>
                                    <TableCell width="10%" align="left">{row.name}</TableCell>
                                    <TableCell width="10%" align="left">{projects && projects.length>0 && projects.find(x=>x._id===row.idProject) && projects.find(x=>x._id===row.idProject).name}</TableCell>
                                    <TableCell width="10%" align="left">{projects && projects.length>0 && projects.find(x=>x._id===row.idProject) && 
                                    projects.find(x=>x._id===row.idProject).qualityPoints.find(x=>x._id===row.idQualitypoint).name}</TableCell>
                                    <TableCell width="30%" align="left"><div> 
                                        <div>
                                            <Typography className={classes.textLink}   color='primary' variant='caption'
                                                onClick = {e=>onClickExportDataFile(row)}
                                                                                            >{row.dataFileName}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography className={classes.textLink}   color='primary' variant='caption'
                                                onClick = {e=>onClickExportConfigFile(row)}
                                                                                            >{row.configurationFile}
                                            </Typography>
                                        </div>
                                    </div>
                                    </TableCell>
                                    <TableCell width="10%" align="left">{row.state}</TableCell>
                                    <TableCell width="10%" padding="checkbox">
                                    <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                    onClick={() => { viewSimDetails(row._id) }}
                                    >
                                        <SearchIcon    />
                                    </Fab>
                                 </TableCell>
                                
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    
                        <Button   variant="contained" style={{minWidth:'20%'}} 
                        onClick={onDeleteSimulations} color="primary">
                            Delete
                        </Button>
                       
                    
            </MuiThemeProvider>
        );
    
}
