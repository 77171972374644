import React from 'react';
import { Modal, Box, Typography, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = (theme) => ({
  modalBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
    maxWidth: 500,
    borderRadius: 8,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[900],
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: '8px 8px 0 0',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
  },
  content: {
    maxHeight: '400px', // Ajusta la altura máxima según tus necesidades
    overflowY: 'auto',
  },
  moduleContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 0,
    },
  },
  moduleTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  list: {
    margin: 0,
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(8),
  },
  secondary: {
    color: theme.palette.text.secondary,
  }
});

const ValidationErrorModal = ({ open, onClose, errors, classes }) => {
  const groupedErrors = errors.reduce((acc, error) => {
    if (!acc[error.errorModule]) {
      acc[error.errorModule] = [];
    }
    acc[error.errorModule].push(error);
    return acc;
  }, {});

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalBox}>
        <Box className={classes.header}>
          <CheckCircleIcon className={classes.icon} />
          <Typography variant="h6" component="h2">
            Validation Errors
          </Typography>
        </Box>
        <Box className={classes.content}>
          {Object.keys(groupedErrors).map((module) => (
            <Box key={module} className={classes.moduleContainer}>
              <Box className={classes.moduleTitle}>
                <ErrorIcon className={classes.icon} />
                <Typography variant="subtitle1">{module}</Typography>
              </Box>
              <List className={classes.list}>
                {groupedErrors[module].map((error, index) => (
                  <ListItem key={index} className={classes.listItem}>
                    <ListItemText 
                      primary={error.errorMessage} 
                      secondary={<span className={classes.secondary}>{error.errorType}</span>} 
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(ValidationErrorModal);
