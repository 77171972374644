import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MaterialTable from "@material-table/core";
import DeleteStorage from './DeleteStorageDialog'
import EditStorageDialog from './EditStorageDialog'
import CreateNewStorageDialog from './CreateNewStorageDialog'
import { Tooltip } from '@material-ui/core'
import * as renderHelper from '../ResultStorageTable/RenderUtils'

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
    },
    fabWarning: {
        margin: theme.spacing(1),
        backgroundColor: "yellow"

    }

}));



export default function ResultStorageTable(props) {
    const classes = useStyles();

    const [data, setData] = useState([])
    const [selectedStorage, setSelectedStorage] = useState(undefined)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [updateCounter, setUpdateCounter] = useState(1)
    

    useEffect(() => {
        if (props.storages && props.storages.length > 0) {
            setData(props.storages)
        }
    }, [props.storages, props.storages && props.storages.length, showConfirmDialog, showCreateDialog])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            props.setStorages(data)
        }
    }, [updateCounter])// eslint-disable-line react-hooks/exhaustive-deps

    function openDeleteDialog(storage) {
        setSelectedStorage(storage)
        setShowConfirmDialog(true)
    }
    function openEditDialog(storage) {
        setSelectedStorage(storage)
        setShowEditDialog(true)
    }


    return (
        <>
            <DeleteStorage showConfirmDialog={showConfirmDialog} setShowConfirmDialog={setShowConfirmDialog} selectedStorage={selectedStorage} userId={props.userId} setSelectedStorage={setSelectedStorage} data={data} setData={setData} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} configuration={props.configuration} />
            <EditStorageDialog data={data} setData={setData} showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} selectedStorage={selectedStorage} userId={props.userId} setSelectedStorage={setSelectedStorage} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />
            <CreateNewStorageDialog showCreateDialog={showCreateDialog} setShowCreateDialog={setShowCreateDialog} userId={props.userId} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} data={data} setData={setData} configuration={props.configuration} />
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>


                {data && data.length > 0 && < MaterialTable
                    style={{ width: "100%" }}
                    title="Storages"
                    data={data}
                    columns={[
                        { title: 'Storage name', field: 'storageName' },
                        {
                            title: 'Storage Type', field: 'storageType', filtering: false, render: (rowData) => {
                                if (rowData.storageType === "azure-storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>azure-storage</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/azure/Storage-Accounts.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure-sqldatabase") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>azure-sqldatabase</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/azure/sql-database-generic.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "AWS") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>AWS S3</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/aws/aws-s3-logo.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "bigQuery") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>AWS S3</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/bigQuery.png')} />
                                        </Tooltip>
                                    )
                                }
                            }
                        },
                        {
                            title: 'Description', field: 'description', render: (rowData) => {
                                if (rowData.storageType === "azure-storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Resource Group Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.resourceGroupName}</strong></h3>
                                                <h3>Storage Account Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.storageAccountName}</strong></h3>
                                                <h3>Container  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.containerName}</strong></h3>
                                                <h3>Path: <strong style={{ color: "#2fb7e0" }}>{rowData.storageProperties.path}</strong></h3>
                                            </React.Fragment>}>
                                            <Typography variant='subtitle2'>{renderHelper.renderDescription(rowData)}</Typography>
                                        </Tooltip>
                                    )

                                }
                                if (rowData.storageType === "azure-sqldatabase") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Resource Group Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.resourceGroup}</strong></h3>
                                                <h3>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.server}</strong></h3>
                                                <h3>Database Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.database}</strong></h3>
                                                <h3>Schema  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.schema}</strong></h3>
                                                <h3>Table  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlProperties.table}</strong></h3>
                                            </React.Fragment>}>
                                            <Typography variant='subtitle2'>{renderHelper.renderDescription(rowData)}</Typography>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "AWS" && rowData.awsProperties) {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Key id: <strong style={{ color: "#2fb7e0" }}>{rowData.awsProperties.s3Keyid}</strong></h3>
                                                <h3>Region: <strong style={{ color: "#2fb7e0" }}>{rowData.awsProperties.s3Region}</strong></h3>
                                                <h3>Bucket Name: <strong style={{ color: "#2fb7e0" }}>{rowData.awsProperties.s3BucketName}</strong></h3>
                                            </React.Fragment>}>
                                            <Typography variant='subtitle2'>{renderHelper.renderDescription(rowData)}</Typography>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "bigQuery" && rowData.bigQueryProperties) {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Project ID: <strong style={{ color: "#2fb7e0" }}>{rowData.bigQueryProperties.projectId}</strong></h3>
                                                <h3>Region: <strong style={{ color: "#2fb7e0" }}>{rowData.bigQueryProperties.region}</strong></h3>
                                                <h3>Bucket: <strong style={{ color: "#2fb7e0" }}>{rowData.bigQueryProperties.bucket}</strong></h3>
                                            </React.Fragment>}>
                                            <Typography variant='subtitle2'>{renderHelper.renderDescription(rowData)}</Typography>
                                        </Tooltip>
                                    )
                                }

                            }
                        },
                        {
                            title: 'Actions', field: '', filtering: false, render: (rowData) => {
                                return (
                                    <>
                                        <Tooltip title={"Delete Storage"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openDeleteDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                        <Tooltip title={"Edit Storage"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openEditDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faEdit} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                    </>
                                )
                            }
                        },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: false,
                        search: false,
                        pageSize: 5,
                        maxBodyHeight: '90%',
                        minBodyHeight: 250,
                        headerStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                            backgroundColor: commonStyles.mainColor,
                            color: '#FFF'
                        },
                        cellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                        filterCellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                    }}

                    components={{
                        Toolbar: props => (
                            <div>

                            </div>
                        ),
                    }}
                />}
                <div className={classes.buttonContainer}>
                    <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "1%" }}
                        color="primary"
                        onClick={event => {
                            setShowCreateDialog(true)
                        }}>
                        <i className="icon plus"></i>
                        Add Storage
                    </Button>

                </div>
            </div>
        </>

    )
}
