export function findProjectByQualityPoint(projects, qpId){
    for(var i =0;i<projects.length;i++){
        var project = projects[i]
        if(project.qualityPoints && project.qualityPoints.length>0){
            for(var j=0;j<project.qualityPoints.length;j++){
                if(project.qualityPoints[j]._id===qpId){
                    return project
                }
            }
        }
    }
    return undefined
}

export function findProjectAndQualityPointByDatasource(projects, dsId){
    for(var i =0;i<projects.length;i++){
        var project = projects[i]
        if(project.qualityPoints && project.qualityPoints.length>0){
            for(var j=0;j<project.qualityPoints.length;j++){
                var qp = project.qualityPoints[j]
                if(qp.datasources && qp.datasources.length>0){
                    for(var k=0;k<qp.datasources.length;k++){
                        if(qp.datasources[k]._id===dsId){
                            return {project,qp}
                        }
                    }
                }
            }
        }
    }
    const projectUndefined = undefined
    const qpUndefined= undefined
    return {projectUndefined,qpUndefined}
}
