import React  from 'react';
import {
     TextField, Typography
} from '@material-ui/core';






export default function SasTokenNewCredentials(props) {



    return (
        <>
            <div style={{ width: '100%' }}>
                {props.storage && props.storage.storageAccountCredentialsDTO && props.storage.storageAccountCredentialsDTO.storageAccountName && < Typography variant="h6" gutterBottom><strong>Storage Account Name: </strong> {`${props.storage.storageAccountCredentialsDTO.storageAccountName}`} </Typography>}
            </div>
            <div>
                <TextField label="SAS Token" type="password" variant="filled" onChange={event => props.setSasToken(event.target.value)}
                    inputProps={{
                        autocomplete: 'new-password',
                        form: {
                            autocomplete: 'off',
                        },
                    }} />
            </div>
        </>
    )
}