import {useEffect} from 'react';
import {  useSelector } from "react-redux";
import { fetchAllControlValues } from 'actions/ControlValueActions';
import { IControlValue } from 'interfaces/controlvalues/ControlValues';
import {useAppDispatch} from 'reducers/store'

export const useControlValues = () => {
  const dispatch = useAppDispatch()

    var controlValues : IControlValue[] = useSelector((store:any) => Object.values(store.controlValues))

   
  useEffect(()=>{
    getControlValues()
  }, [])



    function getControlValues(){
         dispatch(fetchAllControlValues())
       
    }

    return [controlValues, () =>getControlValues()]
}