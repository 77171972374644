import React, { useEffect, useState } from 'react'
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Typography, } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from "react-redux";
import * as actions from 'actions';
import DataformatDetail from 'components/data/QualityDatasetRepository/DataformatDetail';
import DataformatVersionsTable from 'components/data/QualityDatasetRepository/DataformatVersionsTable'



export default function DialogViewDataformatVersions(props) {
    const [dataformatVersions, setDataformatVersions] = useState([])
    const [showDialogDatasetDetail, setShowDialogDatasetDetail] = useState(false)
    const [selectedDataset, setSelectedDataset] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()


    useEffect(() => {
        if (props.selectedDataset && props.selectedDataset._id) {
            setLoading(true)

            dispatch(actions.fetchDataformatVersions(props.selectedDataset._id)).then(response => {
                if (response && response.data && response.data.length > 0) {
                    setDataformatVersions(response.data.reverse())
                }
                else {
                    setDataformatVersions(response.data)
                }
                setLoading(false)
            })
        }
    }, [props.selectedDataset]);// eslint-disable-line react-hooks/exhaustive-deps


    function onCloseDialogDatasetDetail(event) {
        setShowDialogDatasetDetail(false)
    }

    function viewDetailDialog(dataset) {
        setSelectedDataset(dataset)
        setShowDialogDatasetDetail(true)
    }


    return (
        <AqtivaDialog
            // Confirm Dialog
            visible={props.open}
            dialog={props.open}
            fullWidth={true}
            maxWidth={'xl'}
            title={'Dataformat versions'}
            editable={false}
            titleIcon={faSave}
            confirmText={'Create new version'}
            confirmCallback={props.viewCreateNewDataFormatVersionAzure}
            cancelText={'Close'}
            cancelCallback={() => {
                props.setClose(false)
            }}
        >
            <DataformatDetail show={showDialogDatasetDetail}
                dataset={selectedDataset}
                onCloseDetailDialog={onCloseDialogDatasetDetail}
            />
            <DataformatVersionsTable

                dataformats={dataformatVersions}
                classes={props.classes}
                translateUserIdToName={props.translateUserIdToName}
                licenseUsers={props.licenseUsers}
                usedDatasourcesByProjects={props.usedDatasourcesByProjects}
                viewDatasetDetails={viewDetailDialog}
                viewDatasetUseInProjects={props.viewDatasetUseInProjects}
                viewCreateNewDataFormatVersion={props.viewCreateNewDataFormatVersion}
                selectDatasetDialog={props.selectDatasetDialog} />
            {loading === true && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", margin: "50px", }}>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <CircularProgress style={{ width: '50px', height: '50px', textAlign: "center" }} />
                </div>
                <Typography style={{ textAlign: "center" }} variant='subtitle1'>Loading ...</Typography>
            </div>}
        </AqtivaDialog>
    )
}