import {FETCH_TOTAL_RECORDS_TABLE, FETCH_TOTAL_UCS_TABLE
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_TOTAL_RECORDS_TABLE:
            return action.payload
         case FETCH_TOTAL_RECORDS_TABLE:
            return action.payload
        default:
            return state;
        
    }
}