import React from 'react'
import MaterialTable from "@material-table/core";

import * as commonStyles from 'style/CommonStyles'

export default function DialogCreateDataSource(props) {
    return (
        <div>
            {props.dataformat && props.dataformat.columns && <MaterialTable
                style={{ width: "100%" }}
                title="Datasets"
                data={props.dataformat.columns}
                columns={
                    [
                        { title: 'Position', field: 'position', editable: 'never',width: "10%" },
                        { title: 'Name', field: 'name' },
                        { title: 'Format', field: 'type' },
                    ]}
                options={{
                    actionsColumnIndex: -1,
                    filtering: false,
                    search: false,
                    pageSize: 5,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: commonStyles.tableHeaderStyle,
                    cellStyle: commonStyles.tableCellStyle,
                    filterCellStyle: commonStyles.tableFilterCellStyle
                }}

                components={{
                    Toolbar: props => (
                        <div>

                        </div>
                    ),
                }}
            />}
        </div>
    )
}
