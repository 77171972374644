import React from 'react';
import AppBarTemplate from 'components/template/AppBarTemplate'
import * as pathHelper from 'components/template/PathHelper'
import LicenseTable from 'components/superadmin/LicenseTable'
import DialogCreateLicense from 'components/superadmin/DialogCreateLicense';
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { useTheme } from '@material-ui/core/styles';


import trackEvent from 'trackEvent'



export default function SuperAdminLicenseManagementPage(props) {

    
    const theme = useTheme();


    const [visibleDialogCreateLicense, setVisibleDialogCreateLicense] = React.useState(false)
    const [update,setUpdate] = React.useState(0)
   

    
    return (
        <div >
            <AppBarTemplate history={props.history} title={'Management'}  arrayPath ={pathHelper.getPathArray('Management')} />
            <Button  onClick = {event => {setVisibleDialogCreateLicense(true); trackEvent('Management', 'Create License Dialog')}} style={theme.buttons.buttonRegular}
                startIcon={<AddIcon />} 
                 >
                    New License
                        
                </Button>
            <LicenseTable update = {update}/>
            <DialogCreateLicense visible={visibleDialogCreateLicense} 
            onClose={event =>{setVisibleDialogCreateLicense(false); setUpdate(update+1)}}/>
           
        </div>

    );
}