//sorting logic
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function invertArray(currentDisplay) {
  let rules = []
  let newRule
  let notIn
  let paramsNotIn
  for (let i = 0; i < currentDisplay.length; i++) {
    for (let j = 0; j < currentDisplay[i].rules.length; j++) {
      //check if rule already in
      notIn = rules.filter(element => (element.rule === currentDisplay[i].rules[j].rule))
      notIn = notIn.length === 0
      //check if params from the rule are already in
      //value params
      if (currentDisplay[i].rules[j].params && currentDisplay[i].rules[j].params.values) {
        paramsNotIn = rules.filter(element => (element.params && element.params.values && JSON.stringify(element.params.values) === JSON.stringify(currentDisplay[i].rules[j].params.values)))
        paramsNotIn = paramsNotIn.length === 0
        if (!paramsNotIn) {
        }
      }
      //regex params
      else if (currentDisplay[i].rules[j].params && currentDisplay[i].rules[j].params.reg_exp) {
        paramsNotIn = rules.filter(element => (element.params && element.params.reg_exp && element.params.reg_exp === currentDisplay[i].rules[j].params.reg_exp))
        paramsNotIn = paramsNotIn.length === 0
      }
      //numeric range params
      else if (currentDisplay[i].rules[j].params && currentDisplay[i].rules[j].params.max && currentDisplay[i].rules[j].params.min.toString()) {
        paramsNotIn = rules.filter(element => (element.params && element.params.max && element.params.max === currentDisplay[i].rules[j].params.max && element.params.min.toString() && element.params.min === currentDisplay[i].rules[j].params.min))
        paramsNotIn = paramsNotIn.length === 0
      }
      else {
        paramsNotIn = false
      }
      // if rule with params is not yet in 
      if (notIn && paramsNotIn && currentDisplay[i].rules[j].params) {
        newRule = {
          rule: currentDisplay[i].rules[j].rule,
          columns: [currentDisplay[i].column],
          params: currentDisplay[i].rules[j].params
        }
        rules.push(newRule)
      }
      //if rule with params is in with different params
      else if (!notIn && paramsNotIn && currentDisplay[i].rules[j].params) {
        newRule = {
          rule: currentDisplay[i].rules[j].rule,
          columns: [currentDisplay[i].column],
          params: currentDisplay[i].rules[j].params
        }
        rules.push(newRule)
      }
      // if rule without params is not yet in
      else if (notIn && !currentDisplay[i].rules[j].params) {
        newRule = {
          rule: currentDisplay[i].rules[j].rule,
          columns: [currentDisplay[i].column],
        }
        rules.push(newRule)
      }

      // if rule with params is already in with params already in
      else if (!notIn && !paramsNotIn && currentDisplay[i].rules[j].params) {
        rules.map(rule => {
          if ((rule.rule === currentDisplay[i].rules[j].rule && rule.params === currentDisplay[i].rules[j].params) || JSON.stringify(rule.params) === JSON.stringify(currentDisplay[i].rules[j].params)) {
            rule.columns.push(currentDisplay[i].column)
          }
          return ''
        })
      }
      // if rule without params is already 
      else {
        rules.map(rule => {
          if (rule.rule === currentDisplay[i].rules[j].rule && rule.columns[0] !==currentDisplay[i].column) {
            rule.columns.push(currentDisplay[i].column)
          }
          return ''
        })
      }
    }
  }
  return rules
}

/*
function count7(inverted, value) {
  let counter = 0
  for (let i = 0; i < inverted.length; i++) {
    if (inverted[i].params && inverted[i].params.values && inverted[i].params.values[0] === value) {
      counter += 1
    }
  }
  return counter
}
*/



