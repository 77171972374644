import React, { Component } from 'react';
import { ACCESS_TOKEN, ACCESS_TOKEN_AZURE_USER, USER } from './../../Constants';
import { Redirect } from 'react-router-dom'
import quality from '../../apis/qualityLogin';
import { axiosHeaderToken, dispatchError } from './../common/axios/axiosHelper';
import {
    withRouter
} from 'react-router-dom'

import {  PublicClientApplication } from "@azure/msal-browser";
import { connect } from 'react-redux';

import { dispatchErrorConnect, getControlValue,getControlValueAsync } from 'actions';



class OAuth2RedirectHandler extends Component {


    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');// eslint-disable-line react-hooks/exhaustive-deps
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

     popup()
    {
        var chk=false;
        var win1=window.open();
        if(win1==null){
            return chk
        }
        win1.onbeforeunload=()=>{
            
                chk=true;
           
        };
        win1.close();
        return chk;
    }

    async getTokenFromAzureUser() {
        const appId = await getControlValueAsync("AQTIVA_APP_ID")
        const tenantId =await getControlValueAsync("AQTIVA_TENANT_ID")

        //Check appId and tenantId are informed

        if(appId === "" || appId === undefined){
            dispatchErrorConnect("AppId is not informed. Please check database for entity AQTIVA_APP_ID")
        }
        if(tenantId === "" || tenantId === undefined){
            dispatchErrorConnect("tenantId is not informed. Please check database for entity AQTIVA_TENANT_ID")
        }

        const msalConfig = {
            auth: {
                clientId: appId,
                redirectUri:  (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
                prompt: 'select_account',
                authority:
                    'https://login.microsoftonline.com/'+tenantId,
            },
            cache: {
                cacheLocation: 'localStorage',
            },
        }

        console.log('msalConfig', msalConfig)

        var token = undefined
        let request = {
            scopes: ['https://management.core.windows.net/user_impersonation'],
            prompt: 'select_account',
        };
        const msalInstance = new PublicClientApplication(
            msalConfig,
        );
        try {
            let cacheToken = localStorage.setItem('ACCESS_TOKEN_AZURE_IDENTITY', token);
            if (cacheToken) {
                token = cacheToken
            }
            else {
                let tokenResponse = await msalInstance.acquireTokenSilent(request);
                token = tokenResponse.accessToken
                console.log('tok response', tokenResponse)
            }

        } catch (error) {
            console.error('Silent token acquisition failed. Using interactive mode');
            try{
            let tokenResponse = await msalInstance.acquireTokenPopup(request);
            token = tokenResponse.accessToken
            }
            catch(e){
                console.error("Error in acquireTokenPopup")
                console.error(3)
            }

        }

        localStorage.setItem(ACCESS_TOKEN_AZURE_USER, token);

        
    }



    render() {
        const popupAllowed = this.popup()
        if(popupAllowed===false){
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: this.props.location,
                    error: "No popups are allowed. Please allow popup in Aqtiva page in order to be able to completely login in Azure"
                }
            }} />;
        }
        
        
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
      

        if (token) {
            localStorage.setItem(ACCESS_TOKEN, token);
            


            //obtenemos la información de usuario

            quality.get('/user/me', {
                headers: axiosHeaderToken(token)
            }).then(response => {
                const user = response.data
                if((process.env.REACT_APP_SSO_AZURE === true || process.env.REACT_APP_SSO_AZURE === 'Azure') && ( user!==undefined && user.provider === 'azure')){

                    //this.getTokenFromAzureUser()
                }

                localStorage.setItem(USER, JSON.stringify(response.data))
                this.props.setAuthenticated(true)
                this.props.history.push('/home')
            }
            )
                .catch(error => { console.log(error) })


            return <Redirect to={{
                pathname: "/home",
                state: { from: this.props.location }
            }} />;;

        } else {
            localStorage.setItem(ACCESS_TOKEN, undefined);
            return <Redirect to={{
                pathname: "/login",
                c: {
                    from: this.props.location,
                    error: error
                }
            }} />;
        }
    }
}

export default (withRouter((connect(null, { getControlValue,dispatchErrorConnect})(OAuth2RedirectHandler))));
