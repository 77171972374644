import React from 'react';
import AppBarTemplate from 'components/template/AppBarTemplate';
import FilterComponent from './FilterComponent'
import { LanguageContext } from 'language/LanguageContext';
import { useSelector, useDispatch } from 'react-redux'

//import * as actions from 'actions'
import * as pathHelper from 'components/template/PathHelper'
import DashboardTabComponent from './DashboardTabComponent'
import { useHistory } from 'react-router-dom'
import * as actions from 'actions'
import * as helper from './helper'

import { onlyUnique } from 'commonFunctions/commonFunctions'

export default function AqtivaDashboardDrillDownPage(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const projects = useSelector(store => Object.values(store.projects))

    const languageContext = React.useContext(LanguageContext)
    const [filterObj, setFilterObj] = React.useState(undefined)
    const [projectIdExecutionsEnrich, setProjectIdExecutionsEnrich] = React.useState([])
    const [projectsLicense, setProjectsLicense] = React.useState([])
    const [projectIdExecutions, setProjectIdExecutions] = React.useState([])
    const [tabValue, setTabValue] = React.useState(1)



    const [initialProjectId, setInitialProjectId] = React.useState(undefined)
    const [initialQpId, setInitialQpId] = React.useState(undefined)
    const [initialDatasourceId, setInitialDatasourceId] = React.useState(undefined)
    const [initialSimulationId, setInitialSimulationId] = React.useState(undefined)

    const urlProjectId = (props.match && props.match.params && props.match.params.idProject) ? props.match.params && props.match.params.idProject : undefined
    const urlQualityPointId = (props.match && props.match.params && props.match.params.idQp) ? props.match.params && props.match.params.idQp : undefined
    const urlDatasourceId = (props.match && props.match.params && props.match.params.idDatasource) ? props.match.params && props.match.params.idDatasource : undefined
    const urlSimulationId = (props.match && props.match.params && props.match.params.idSimulation) ? props.match.params && props.match.params.idSimulation : undefined


    //new states
    const [DQIByDimension, setDQIByDimension] = React.useState([])
    const [globalDQI, setGlobalDQI] = React.useState(undefined)
    //const [globalExecutions, setGlobalExecutions] = React.useState(0)
    //const [globalDefinitions, setGlobalDefinitions] = React.useState(0)

    const [executionsByDimension, setExecutionsByDimension] = React.useState([])
    const [executionsByRuleType, setExecutionsByRuleType] = React.useState([])

    const [definitionsByDimension, setDefinitionsByDimension] = React.useState([])
    const [definitionsByRuleType, setDefinitionsByRuleType] = React.useState([])

    const [DQIAndExecutions4RuleTypeAndDimension, setDQIAndExecutions4RuleTypeAndDimension] = React.useState([])
    const [DQIByDay, setDQIByDay] = React.useState([])

    //const [DQI4Dataformat, setDQI4Dataformat] = React.useState([])
    //const [DQI4DataformatAndDimension, setDQI4DataformatAndDimension] = React.useState([])



    const [DQI4Project, setDQI4Project] = React.useState([])
    const [DQI4ProjectMostRecent, setDQI4ProjectMostRecent] = React.useState([])
    const [DQI4QP, setDQI4QP] = React.useState([])


    const [DQI4ProjectFlag, setDQI4ProjectFlag] = React.useState(true)
    const [DQI4ProjectMostRecentFlag, setDQI4ProjectMostRecentFlag] = React.useState(true)
    const [DQI4QPFlag, setDQI4QPFlag] = React.useState(true)

    //flags of data loading
    const [dQI4DimensionsFlag, setDQI4DimensionsFlag] = React.useState(true)
    //const [dQI4DataformatsFlag, setDQI4DataformatsFlag] = React.useState(true)
    //const [dQI4DataformatsAndDimensionsFlag, setDQI4DataformatsAndDimensionsFlag] = React.useState(true)
    const [executions4DimensionsFlag, setExecutions4DimensionsFlag] = React.useState(true)
    const [definitions4DimensionsFlag, setDefinitions4DimensionsFlag] = React.useState(true)
    const [executions4RuleTypeFlag, setExecutions4RuleTypeFlag] = React.useState(true)
    const [definitions4RuleTypeFlag, setDefinitions4RuleTypeFlag] = React.useState(true)
    const [executionsAndDQI4RuleTypeAndDimensionFlag, setExecutionsAndDQI4RuleTypeAndDimensionFlag] = React.useState(true)
    //const [dQIByDayFlag, setDQIByDayFlag] = React.useState(true)


    const [isSimulationTab, setIsSimulationTab] = React.useState(false)

    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setInitialProjectId(urlProjectId)
        setInitialQpId(urlQualityPointId)
        setInitialDatasourceId(urlDatasourceId)
        setInitialSimulationId(urlSimulationId)
        if (urlSimulationId !== undefined) {
            setIsSimulationTab(true)
            setTabValue(2)
        }
    }, [urlProjectId, urlQualityPointId, urlDatasourceId, urlSimulationId])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {

        if (!loading) {
            // getUser()
            // fetchData()
            dispatch(actions.getDQI4Dimensions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                const value = helper.getGlobalDQI(response.data)
                setGlobalDQI(value)
                setDQIByDimension(response.data)
                setDQI4DimensionsFlag(false)

            })

            /*dispatch(actions.getDQI4Dataformats(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setDQI4Dataformat(response.data)
                setDQI4DataformatsFlag(false)

            })*/

            /* dispatch(actions.getDQI4DataformatsAndDimensions(filterObj)).then(response => {
                 //get global DQI and 4 dimensions
                 setDQI4DataformatAndDimension(response.data)
                 setDQI4DataformatsAndDimensionsFlag(false)
 
             })*/

            dispatch(actions.getExecutions4Dimensions(filterObj)).then(response => {
                //get global DQI
                //const value = helper.getGlobalExecutions(response.data)
                //setGlobalExecutions(value)
                setExecutionsByDimension(response.data)
                setExecutions4DimensionsFlag(false)

            })

            dispatch(actions.getDefinitions4Dimensions(filterObj)).then(response => {
                //const value = helper.getGlobalDefinitions(response.data)
                //setGlobalDefinitions(value)
                setDefinitionsByDimension(response.data)
                setDefinitions4DimensionsFlag(false)
            })

            dispatch(actions.getExecutions4RuleType(filterObj)).then(response => {
                //get global DQI
                setExecutionsByRuleType(getRuleTypesData(response.data, 'executions'))
                setExecutions4RuleTypeFlag(false)
            })

            dispatch(actions.getDefinitions4RuleType(filterObj)).then(response => {
                //get global DQI
                setDefinitionsByRuleType(getRuleTypesData(response.data, 'definitions'))
                setDefinitions4RuleTypeFlag(false)
            })

            dispatch(actions.getExecutionsAndDQI4RuleTypeAndDimension(filterObj)).then(response => {
                //get global DQI
                setDQIAndExecutions4RuleTypeAndDimension(response.data)
                setExecutionsAndDQI4RuleTypeAndDimensionFlag(false)

            })

            /* dispatch(actions.getDQIByDay(filterObj)).then(response => {
                 //get global DQI
                 setDQIByDay(response.data)
                 setDQIByDayFlag(false)
 
             })*/



        }

    }, [loading])



    React.useEffect(() => {
        if (!areThereParamsFromUrl()) {


            getUser()
            fetchData()
        }
    }, [JSON.stringify(filterObj), user && user.id, areThereParamsFromUrl()])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (!areThereParamsFromUrl()) {

            if (DQI4Project && DQI4Project.length > 0) {

                let object = {
                    recentProjectsDQI: DQI4Project.slice(0, 5),
                    filter: filterObj
                }
                dispatch(actions.getDQI4ProjectsMostRecent(object)).then(response => {
                    setDQI4ProjectMostRecent(response.data)
                    setDQI4ProjectMostRecentFlag(false)
                })
            }
            else {
                setDQI4ProjectMostRecent([])
                setDQI4ProjectMostRecentFlag(false)
            }
        }

    }, [DQI4Project, areThereParamsFromUrl()])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (!areThereParamsFromUrl()) {
            if (user && user.id) {
                dispatch(actions.fetchProjects(user.id))
                dispatch(actions.fetchAllDataFormats(user))
                if (license && license._id) {


                }
                else {
                    if(user!==undefined && user.id!==undefined){
                        dispatch(actions.fetchLicense((user).id))
                    }
                }

            }
        }

    }, [user && user.id])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (!areThereParamsFromUrl()) {

            if (projectIdExecutions !== undefined) {
                var projectsEnriched = []
                const allProjects = projects.concat(projectsLicense)
                for (var i = 0; i < projectIdExecutions.length; i++) {
                    var p = JSON.parse(JSON.stringify(projectIdExecutions[i]))
                    p.project = getProjectFromId(p.projectId, allProjects)
                    projectsEnriched.push(p)
                }
                setProjectIdExecutionsEnrich(projectsEnriched)
            }
        }

    }, [projectIdExecutions, projectsLicense, areThereParamsFromUrl()])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (!areThereParamsFromUrl()) {

            fetchProjectData()
        }
    }, [filterObj, areThereParamsFromUrl()])


    async function fetchProjectData() {
        if (filterObj && filterObj.startDate && filterObj.endDate) {
            dispatch(actions.getProjectIdExecutions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setProjectIdExecutions(response.data)

            })
        }
    }




    function loadingFlag(loading) {
        setLoading(loading)
    }


    function getProjectFromId(projectId, projects) {
        const project = projects.filter(x => x._id === projectId)
        if (project && project.length > 0) {
            return project[0]
        }
        return undefined
    }

    function getUser() {
        dispatch(actions.fetchUser(history))
    }

    function getRuleTypesData(rawRuleData, field) {
        if (rawRuleData !== undefined && rawRuleData.length > 0) {
            const ruleTypes = rawRuleData.filter(x => x.ruleType).map(x => x.ruleType).filter(onlyUnique)
            const sum = rawRuleData.map(x => x[field]).reduce((partialSum, a) => partialSum + a, 0);
            const fullMarkValue = sum

            const dataArray = []
            for (var i = 0; i < ruleTypes.length; i++) {
                const ruleType = ruleTypes[i]
                const data = {
                    subject: ruleType,
                    value: rawRuleData.filter(x => x.ruleType === ruleType)[0][field],
                    fullMark: fullMarkValue,
                }
                dataArray.push(data)
            }
            return dataArray
        }

        return []

    }




    async function fetchData() {
        if (filterObj && filterObj.startDate && filterObj.endDate) {

            setDQI4DimensionsFlag(true)
            //setDQI4DataformatsFlag(true)
            //setDQI4DataformatsAndDimensionsFlag(true)
            setExecutions4DimensionsFlag(true)
            setDefinitions4DimensionsFlag(true)
            setExecutions4RuleTypeFlag(true)
            setDefinitions4RuleTypeFlag(true)
            setExecutionsAndDQI4RuleTypeAndDimensionFlag(true)
            //setDQIByDayFlag(true)
            setDQI4ProjectFlag(true)
            setDQI4ProjectMostRecentFlag(true)
            dispatch(actions.getDQI4Dimensions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                const value = helper.getGlobalDQI(response.data)
                setGlobalDQI(value)
                setDQIByDimension(response.data)
                setDQI4DimensionsFlag(false)

            })

            /* dispatch(actions.getDQI4Dataformats(filterObj)).then(response => {
                 //get global DQI and 4 dimensions
                 setDQI4Dataformat(response.data)
                 setDQI4DataformatsFlag(false)
 
             })*/

            /*dispatch(actions.getDQI4DataformatsAndDimensions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setDQI4DataformatAndDimension(response.data)
                setDQI4DataformatsAndDimensionsFlag(false)

            })*/

            dispatch(actions.getExecutions4Dimensions(filterObj)).then(response => {
                //get global DQI
                //const value = helper.getGlobalExecutions(response.data)
                // setGlobalExecutions(value)
                setExecutionsByDimension(response.data)
                setExecutions4DimensionsFlag(false)

            })

            dispatch(actions.getDefinitions4Dimensions(filterObj)).then(response => {
                //const value = helper.getGlobalDefinitions(response.data)
                // setGlobalDefinitions(value)
                setDefinitionsByDimension(response.data)
                setDefinitions4DimensionsFlag(false)
            })

            dispatch(actions.getExecutions4RuleType(filterObj)).then(response => {
                //get global DQI
                setExecutionsByRuleType(getRuleTypesData(response.data, 'executions'))
                setExecutions4RuleTypeFlag(false)
            })

            dispatch(actions.getDefinitions4RuleType(filterObj)).then(response => {
                //get global DQI
                setDefinitionsByRuleType(getRuleTypesData(response.data, 'definitions'))
                setDefinitions4RuleTypeFlag(false)
            })

            dispatch(actions.getExecutionsAndDQI4RuleTypeAndDimension(filterObj)).then(response => {
                //get global DQI
                setDQIAndExecutions4RuleTypeAndDimension(response.data)
                setExecutionsAndDQI4RuleTypeAndDimensionFlag(false)

            })

            /* dispatch(actions.getDQIByDay(filterObj)).then(response => {
                 //get global DQI
                 setDQIByDay(response.data)
                 setDQIByDayFlag(false)
 
             })*/
            if (user && user.id) {
                dispatch(actions.getDQI4ProjectsByUser(filterObj, user.id)).then(response => {
                    setDQI4Project(response.data)

                    setDQI4ProjectFlag(false)
                })
            }




            dispatch(actions.getDQI4QualityPoints(filterObj)).then(response => {
                setDQI4QP(response.data)
                setDQI4QPFlag(false)
            })


        }
    }

    function areThereParamsFromUrl() {
        if (initialProjectId || initialQpId || initialDatasourceId) {
            return true
        }
        else {
            return false
        }
    }

    function handleTabChange(newValue) {
        setTabValue(newValue)

        if (newValue === 2) {
            setIsSimulationTab(true)
        } else {
            setIsSimulationTab(false)
        }
    }



    return (
        <div >
            <AppBarTemplate
                history={props.history}
                title={languageContext.dictionary['home']}
                intervalNotifications={false}
                arrayPath={pathHelper.getPathArray(languageContext.dictionary['home'])}
            />
            <FilterComponent setFilterObj={setFilterObj}
                projects={projectIdExecutionsEnrich}
                isSimulationTab={isSimulationTab}
                user={user}>
                <DashboardTabComponent
                    setIsSimulationTab={setIsSimulationTab}
                    filterObj={filterObj}
                    DQI4Project={DQI4Project}
                    DQI4ProjectMostRecent={DQI4ProjectMostRecent}
                    DQIByDimension={DQIByDimension}
                    executionsByDimension={executionsByDimension}

                    executionsByRuleType={executionsByRuleType}
                    definitionsByDimension={definitionsByDimension}
                    definitionsByRuleType={definitionsByRuleType}
                    DQIAndExecutions4RuleTypeAndDimension={DQIAndExecutions4RuleTypeAndDimension}
                    globalDQI={globalDQI}
                    DQI4QP={DQI4QP}
                    loading={loadingFlag}
                    DQI4ProjectFlag={DQI4ProjectFlag}
                    DQI4ProjectMostRecentFlag={DQI4ProjectMostRecentFlag}
                    DQIByDimensionFlag={dQI4DimensionsFlag}
                    executionsByDimensionFlag={executions4DimensionsFlag}
                    executionsByRuleTypeFlag={executions4RuleTypeFlag}
                    definitionsByDimensionFlag={definitions4DimensionsFlag}
                    definitionsByRuleTypeFlag={definitions4RuleTypeFlag}
                    DQIAndExecutions4RuleTypeAndDimensionFlag={executionsAndDQI4RuleTypeAndDimensionFlag}
                    DQI4QPFlag={DQI4QPFlag}

                    initialProjectId={initialProjectId}
                    setInitialProjectId={setInitialProjectId}
                    initialQpId={initialQpId}
                    setInitialQpId={setInitialQpId}
                    initialDatasourceId={initialDatasourceId}
                    setInitialDatasourceId={setInitialDatasourceId}
                    initialSimulationId={initialSimulationId}
                    DQIByDay={DQIByDay}

                    history={history}
                    tabValue={tabValue}
                    handleTabChange={handleTabChange}

                />
            </FilterComponent>

        </div>

    );
}





