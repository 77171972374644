
import React from 'react';

import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ILogicColumnCondition, ILogicColumnOperator, ILogicSqlExpression } from 'interfaces/LogicCondition';
import AddOperatorDialog from './AddOperatorDialog';
import { IColumn } from 'interfaces/RuleInterfaces';


type IProps = {
  columnConditions: ILogicColumnCondition[] | undefined,
  columnOperations: ILogicColumnOperator[] | undefined,
  sqlExpressions: ILogicSqlExpression[] | undefined,
  baseHeight: number,
  baseWidth: number,
  columns: IColumn[],
  addOperatorJoin: (value: ILogicColumnOperator) => (void),
  addOperatorCondition: (value: ILogicColumnCondition) => (void),
  addSqlExpression: (value: any) => (any),
  hasColumnConditions: boolean,
  hasSqlExpressions: boolean
}

/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      cursor:'pointer',
      width:`250px`, margin:'5px', height:`75px`,
      transition: "transform 0.15s ease-in-out",
      "&:hover": { transform: "scale3d(1.05, 1.05, 1)", boxShadow: 20, backgroundColor:'#edf6f7' },
    },
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: commonStyles.mainColor
    },
  }),
);
*/
export default function AddOperatorCard(props: IProps) {


  const [open, setOpen] = React.useState(false)

  function addOperatorJoinFunction(operator: ILogicColumnOperator) {
    props.addOperatorJoin(operator)
  }

  function addOperatorConditionFunction(condition: ILogicColumnCondition) {
    props.addOperatorCondition(condition)
  }

  function addSqlExpressionFunction(condition: ILogicSqlExpression) {
    console.log('condition', condition)
    props.addSqlExpression(condition)
  }

  function handleClose() {
    setOpen(false)
  }
  return (
    <div>
      <AddOperatorDialog open={open} handleClose={handleClose} columns={props.columns} sqlExpressions={props.sqlExpressions}
        addOperatorJoin={addOperatorJoinFunction}
        addOperatorCondition={addOperatorConditionFunction}
        addSqlExpression={addSqlExpressionFunction}
        hasColumnConditions={props.hasColumnConditions} 
        hasSqlExpressions={props.hasSqlExpressions} />
      <Fab color="primary" aria-label="add" size="small" onClick={event => setOpen(true)} style={{ margin: '5px' }}>
        <AddIcon />
      </Fab>
    </div>


  )

}