import {FETCH_INFO_LONG
    } from '../actions/types'




export default (state={},action) => {
    switch(action.type){
        case FETCH_INFO_LONG:
        return action.payload;
        default:
            return state;
        
    }
}