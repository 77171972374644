import React from 'react';
import { faSearchPlus, faDatabase } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledMenu, StyledMenuItem } from "components/common/CustomizedMenu";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogPreviewDataformat from './../common/DialogPreviewDataformat'

import { dispatchInfo } from 'components/common/axios/axiosHelper';

import { ReactComponent as LocalFileIcon } from 'resources/icons/azure/File.svg';

import * as commonStyles from 'style/CommonStyles'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 240,
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        height: 380,
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialState = {
    mouseX: null,
    mouseY: null,
};



export default function TreeViewStorageRest(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))



    const [storageAccounts, setStorageAccounts] = React.useState([])
    const [selected, setSelected] = React.useState([])
    const [expanded, setExpanded] = React.useState([]);
    const [state, setState] = React.useState(initialState);
    const [selectedItemForMenu, setSelectedItemForMenu] = React.useState({})
    const [storageType, setStorageType] = React.useState("adlv2")
    const [transactions, setTransactions] = React.useState([])
    let allowedFormats = ['.csv', '.json', '.parquet', '.txt']

    const [allowUpdate, setAllowUpdate] = React.useState(true)

    const [azureStorageDataformat, setAzureStorageDataformat] = React.useState([])

    const [showAlreadyDatasource, setShowAlreadyDatasource] = React.useState(false)


    React.useEffect(() => { updateStorageAccounts(props.selectedResourceGroup) }, [props.selectedResourceGroup, props.selectedSubscription])// eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => { setAllowUpdate(true) }, [storageAccounts])
    React.useEffect(() => { getResourceGroups(); getAzureStorageDataFormats(user) }, [user, props.selectedSubscription]);// eslint-disable-line react-hooks/exhaustive-deps


    async function getAzureStorageDataFormats(user) {
        const dataformats = await dispatch(actions.getAzureStorageDataformats(user.id))
        if (dataformats) {
            setAzureStorageDataformat(dataformats)
        }
    }

    function alreadyAzureDataformatOwn(storageAccount, container, item) {
        var already = false
        if (azureStorageDataformat === null || azureStorageDataformat === undefined) {
            return already
        }
        azureStorageDataformat.forEach(dataformat => {

            if (
                dataformat.fileStorageProperties.account === storageAccount.name &&
                dataformat.fileStorageProperties.container === container.name &&
                dataformat.fileStorageProperties.path.endsWith(item.name)) {
                if (
                    (dataformat.userId === user.id ||
                        (dataformat.allowedUsers && dataformat.allowedUsers.length > 0 && dataformat.allowedUsers.includes(user.id)))) {
                    already = true
                }
            }
        })
        return already
    }

    function alreadyAzureDataformatOther(storageAccount, container, item) {
        var already = false
        if (azureStorageDataformat === null || azureStorageDataformat === undefined) {
            return already
        }
        azureStorageDataformat.forEach(dataformat => {

            if (
                dataformat.fileStorageProperties.account === storageAccount.name &&
                dataformat.fileStorageProperties.container === container.name &&
                dataformat.fileStorageProperties.path.endsWith(item.name)) {
                if (
                    alreadyAzureDataformatOwn(storageAccount, container, item) === false) {
                    already = true
                }
            }
        })
        return already
    }

    function getDataformat(resourceGroup, storageAccount, container, name) {
        var selectedDF = undefined
        if (azureStorageDataformat === null || azureStorageDataformat === undefined) {
            return selectedDF
        }
        azureStorageDataformat.forEach(dataformat => {

            if (
                dataformat.fileStorageProperties.account === storageAccount &&
                dataformat.fileStorageProperties.container === container &&
                dataformat.fileStorageProperties.path.endsWith(name)) {
                selectedDF = dataformat
            }
        })
        return selectedDF
    }

    /** Funciones de gestión del menu contextual **/

    const handleClick = (event, rootItem, finalName) => {
        const finalRootName = rootItem.resourceGroupName + "_" + rootItem.storageAccount + "_" + rootItem.container + "_" + rootItem.name
        if (finalRootName === selected) {

            const expandedSplit = selected.split("_")
            if (expandedSplit.length === 4) {
                //const storageAcc = expandedSplit[0]
                //const rg = expandedSplit[1]
                //const container = expandedSplit[2]
                const blobName = expandedSplit[3]
                if (rootItem.name === blobName) {
                    event.preventDefault();
                    props.setFileOutputName(finalName)
                    setSelectedItemForMenu(rootItem)
                    setState({
                        mouseX: event.clientX - 2,
                        mouseY: event.clientY - 4,
                    });
                }
            }
            else {
                event.preventDefault();
                props.setFileOutputName(finalName)
                setSelectedItemForMenu(rootItem)
                setState({
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                });
            }

        }


    };

    const handleClose = () => {
        setState(initialState);
    };

    /** FUNCIONES DE ACTUALIZACIÓN DE DATOS*/

    async function updateStorageAccounts(resourceGroup) {
        if (resourceGroup && resourceGroup !== "" && resourceGroup !== "None") {
            setStorageAccounts(await listStorageAccountForRG(resourceGroup))
        }
        else if (resourceGroup === "None") {
            setStorageAccounts(await listStorageAccountForAll())

        }
    }

    /** obtiene un listado de los storage accounts para un resourcegroup */
    async function listStorageAccountForRG(resourceGroup) {
        return await actions.listStorageAccountForRG(props.selectedSubscription, resourceGroup, dispatch)

    }

    /** obtiene un listado de los storage accounts para todos los resource groups */
    async function listStorageAccountForAll() {
        return await actions.listStorageAccountsAll(props.selectedSubscription, dispatch)
    }



    /** Actualiza todos los resource groups disponibles */
    async function getResourceGroups() {
        var returnValue = []
        // const result = await props.azureManager.finalizeLogin()
        if (props.selectedSubscription && props.selectedSubscription !== "") {
            returnValue = await actions.onListResourceGroups(props.selectedSubscription, dispatch)
            if (returnValue.length === 0) {
                props.setResourceGroups(['None'])
            }
        }

        props.setResourceGroups(returnValue.sort((a, b) => (a > b) ? 1 : -1));


    }




    function getDetailStorageTransaction(storageAccount) {
        if (allowUpdate === true) {
            getDetailStorage(storageAccount)
        }
    }



    function getDetailStorage(storageAccount) {
        setAllowUpdate(false)
        getDetail(storageAccount).then(object => {

            var tempResult = JSON.parse(JSON.stringify(storageAccounts))
            if (object.containers === undefined || object.containers.length === 0) {
                //creamos container que nos marque que está vacío
                object.containers = [
                    {
                        resourceGroupName: 'no-container-found',
                        name: 'no-container-found',
                        region: 'no-container-found'
                    }
                ]
            }

            let newList = [];
            tempResult.forEach(function (item) {
                if (item.resourceGroupName === object.resourceGroupName && item.name === object.name) {
                    object.kind = item.kind
                    newList.push(object);
                } else {
                    newList.push(item);
                }
            });
            tempResult = newList
            setStorageAccounts(newList);
            const newTransactions = transactions.slice(1)
            setTransactions(newTransactions)



        })
    }









    async function getDetail(dto) {
        var ADLv2Results = actions.onGetContainerListADLv2(configuration, props.selectedSubscription, dto.resourceGroupName, dto.name, dto.region, dto, dispatch)

        setStorageType("adlv2")

        return ADLv2Results

    }


    /**FUNCIONES DE GESTIÓN DE EVENTOS UI */

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };


    async function handlePreview(event, isFolder) {

        if (isFolder === true) {
            event = await actions.getNameListChildrenStorageObject(configuration, props.selectedSubscription, event, dispatch)

        }
        if (props.selectedFormat) selectedItemForMenu.selectedFormat = props.selectedFormat.toLowerCase()

        props.setSelectedItem(selectedItemForMenu)

        handleClose(event)
        dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")
        await dispatch(actions.createPreviewDataJob(props.selectedEnvironment, user.id, selectedItemForMenu, props.numberSamples, props.flattenFlag)).then(result => {
            if (result !== undefined && result.error === undefined) {
                props.setVisiblePreview(true)
            }
            if (result && result.run_id) {
                props.setRunId(result.run_id)
            }

        })


    }

    async function handleProfilingNew(event, isFolder) {
        props.resetProfilingState()
        console.log('handleProfilingNew', isFolder)

        if (isFolder === true) {
            //obtenemos un listado del nombre de todos los hijos del elemento, para poder inferir el formato de los ficheros
            //  const result = await props.azureManager.finalizeLogin()
            //  if (result.isLoggedIn) {
            if (props.selectedFormat) selectedItemForMenu.selectedFormat = props.selectedFormat.toLowerCase()
            
            handleClose(event)
            await dispatch(actions.addProfilingToDataformat(props.selectedEnvironment, user.id, selectedItemForMenu, props.profilingSamples, props.flattenFlag)).then(result => {
                /* if (result && result.dto && result.dto.run_id) {
                    props.setRunId(result.dto.run_id)
                } */
                if (result !== null) {

                    if (result.dto && result.dto.run_id) {
                        props.profilingRunId(result.dto.run_id)
                    }
                    if (result.profilingBatchId) {
                        props.setProfilingBatchId(result.profilingBatchId)
                    }
                    if (result.dataformatId) {
                        props.setProfilingdataformatId(result.dataformatId)
                    }

                    props.setProfilingVisible(true)
                }

            })
            /*   }
               else {
                   props.azureManager.login()
               }*/

        }
        if (isFolder === false) {
            props.setSelectedItem(selectedItemForMenu)

            handleClose(event)
            dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")

            await dispatch(actions.addProfilingToDataformat(props.selectedEnvironment, user.id, selectedItemForMenu, props.profilingSamples, props.flattenFlag)).then(result => {
                /* if (result && result.dto && result.dto.run_id) {
                    props.setRunId(result.dto.run_id)
                } */
                console.log('result', result)

                if (result && result.dto?.run_id) {
                    props.profilingRunId(result.dto.run_id)
                }
                if (result && result.profilingBatchId && result.isProfilingBatch) {
                    props.setProfilingVisible(true)
                    props.setProfilingBatchId(result.profilingBatchId)
                }
                if (result && result.dataformatId) {
                    props.setProfilingVisible(true)
                    props.setProfilingdataformatId(result.dataformatId)
                }



            })
        }
    }

    async function handleImport(event, isFolder) {

        var dataformat = getDataformat(event.resourceGroupName, event.storageAccount, event.container, event.fullName)
        dataformat.userId = user.id
        if (dataformat !== undefined) {
            dispatch(actions.addDataFormatObject(dataformat))
        }
    }


    async function getRootItemsForContainer(storageAccount, container, containerIndex, storageAccountIndex) {
        //obtenemos la key del storageAccount

        /*var key = undefined
        const dtoRest = {
            key: undefined,
            accountName: storageAccount.name,
            folder: undefined,
            container: container
        }*/

        actions.getItemsFromContainerADLv2(configuration, "/", storageAccount.name, container, dispatch).then(childrens => {
            if (childrens === undefined || childrens.length === 0) {
                //try with blobStorage
                setStorageType("blob")
                actions.getItemsFromContainer(configuration, "/", storageAccount.name, container, dispatch).then(childrens => {
                    const childrensMap = (childrens === undefined || childrens.length === 0) ? [] : childrens.map(x => {
                        return {
                            type: (x.properties === null || x.properties === undefined) ? 'Folder ' : x.properties['Content-Type'],
                            name: x.name,
                            fullName: x.name,
                            resourceGroupName: storageAccount.resourceGroupName,
                            storageAccount: storageAccount.name,
                            container: container.name,
                            children: (x.properties === null || x.properties === undefined) ?
                                [{
                                    type: 'no data',
                                    name: 'no data',
                                    fullName: 'no data',
                                    resourceGroupName: storageAccount.resourceGroupName,
                                    storageAccount: storageAccount.name,
                                    container: container.name,
                                    children: [],
                                    level: 1,
                                    parent: x.name
                                }] : [],
                            level: 0,
                            parent: ""
                        }
                    })
                    var tempResult = JSON.parse(JSON.stringify(storageAccount))
                    var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                    tempResult.containers[containerIndex].storageAccountItemDTOSRoot = childrensMap

                    newStorages[storageAccountIndex] = tempResult
                    setStorageAccounts(newStorages)
                })
            }
            else {
                const childrensMap = (childrens === undefined || childrens.length === 0) ? [] : childrens.map(x => {
                    return {
                        type: (x.isDirectory === true) ? 'Folder ' : 'file',
                        name: x.name,
                        fullName: x.name,
                        resourceGroupName: storageAccount.resourceGroupName,
                        storageAccount: storageAccount.name,
                        container: container.name,
                        children: (x.properties === null || x.properties === undefined) ?
                            [{
                                type: 'no data',
                                name: 'no data',
                                fullName: 'no data',
                                resourceGroupName: storageAccount.resourceGroupName,
                                storageAccount: storageAccount.name,
                                container: container.name,
                                children: [],
                                level: 1,
                                parent: x.name
                            }] : [],
                        level: 0,
                        parent: ""
                    }
                })
                var tempResult = JSON.parse(JSON.stringify(storageAccount))
                var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                tempResult.containers[containerIndex].storageAccountItemDTOSRoot = childrensMap

                newStorages[storageAccountIndex] = tempResult
                setStorageAccounts(newStorages)
            }
        })



    }

    function checkAlreadyCreatedDataformat(rootItem, elements) {
        if (elements === undefined || elements.length === 0) {
            return false
        }
        else {
            let rootItemPath = `wasbs://${rootItem.container}@${rootItem.storageAccount}.blob.core.windows.net/${rootItem.name}`

            let values = elements.filter(x => x.fileStorageProperties !== undefined).map(x => x.fileStorageProperties).filter(x => x.account === rootItem.storageAccount && x.container === rootItem.container && x.path === rootItemPath)
            if (values.length > 0) {

                return true
            }
        }
        return false
    }

    function getNameAlreadyCreatedDataformat(rootItem, elements) {
        if (elements === undefined || elements.length === 0) {
            return ''
        }
        else {
            let rootItemPath = `wasbs://${rootItem.container}@${rootItem.storageAccount}.blob.core.windows.net/${rootItem.name}`

            let values = elements.filter(x => x.fileStorageProperties !== undefined).filter(x => x.fileStorageProperties.account === rootItem.storageAccount && x.fileStorageProperties.container === rootItem.container
                && x.fileStorageProperties.path === rootItemPath)
            if (values.length > 0) {
                return values[0].name
            }
        }
        return ''
    }


    async function getDetailBlobItemList(storageAccount, container, parent, indexList,
        allowUpdate, azureManager, actions, dispatch, storageAccounts, setStorageAccounts) {
        if (parent && parent.children &&
            parent.children.length > 0 &&
            (parent.children.length > 1 ||
                parent.children[0].type !== 'no data')

        ) {

            return
        }

        if (allowUpdate === false) {
            return
        }


        // var key = undefined
        // var result = await azureManager.finalizeLogin()
        // if (result.isLoggedIn) {
        /*   const dtoRest = {
               key: undefined,
               accountName: storageAccount.name,
               folder: parent.name,
               container: container
           }*/
        if (storageType === "adlv2") {
            actions.getItemsFromContainerADLv2(configuration, parent.name, storageAccount.name, container, dispatch).then(childrens => {

                // ahora hay que actualizar recursivamente
                //trabajamos con una copia ya que el original es constante
                var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                var firstDTO = storageAccount.containers[indexList[1]].storageAccountItemDTOSRoot[indexList[2]]
                const values = childrens.map(x => parseContainerDataADLv2(x, storageAccount, container))

                if (indexList.length <= 3) {
                    firstDTO.children = values
                }
                else {
                    firstDTO = updateStorageAccountDTORecursively(firstDTO, indexList, 3, values)
                }
                newStorages[indexList[0]] = storageAccount
                setStorageAccounts(newStorages)

            })
        }
        else {
            actions.getItemsFromContainer(configuration, parent.name, storageAccount.name, container, dispatch).then(childrens => {

                // ahora hay que actualizar recursivamente
                //trabajamos con una copia ya que el original es constante
                var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                var firstDTO = storageAccount.containers[indexList[1]].storageAccountItemDTOSRoot[indexList[2]]
                const values = childrens.map(x => parseContainerData(x, storageAccount, container))

                if (indexList.length <= 3) {
                    firstDTO.children = values
                }
                else {
                    firstDTO = updateStorageAccountDTORecursively(firstDTO, indexList, 3, values)
                }
                newStorages[indexList[0]] = storageAccount
                setStorageAccounts(newStorages)

            })
        }

        //  }


    }

    function parseContainerData(x, storageAccount, container) {
        return {
            type: (x.properties === null || x.properties === undefined) ? 'Folder ' : x.properties['Content-Type'],
            name: x.name,
            fullName: x.name,
            resourceGroupName: storageAccount.resourceGroupName,
            storageAccount: storageAccount.name,
            container: container.name,
            children: (x.properties === null || x.properties === undefined) ?
                [{
                    type: 'no data',
                    name: 'no data',
                    fullName: 'no data',
                    resourceGroupName: storageAccount.resourceGroupName,
                    storageAccount: storageAccount.name,
                    container: container.name,
                    children: [],
                    level: 1,
                    parent: x.name
                }] : [],
            level: 0,
            parent: ""
        }
    }

    function parseContainerDataADLv2(x, storageAccount, container) {
        return {
            type: (x.isDirectory === true) ? 'Folder ' : 'file',
            name: x.name,
            fullName: x.name,
            resourceGroupName: storageAccount.resourceGroupName,
            storageAccount: storageAccount.name,
            container: container.name,
            children: (x.properties === null || x.properties === undefined) ?
                [{
                    type: 'no data',
                    name: 'no data',
                    fullName: 'no data',
                    resourceGroupName: storageAccount.resourceGroupName,
                    storageAccount: storageAccount.name,
                    container: container.name,
                    children: [],
                    level: 1,
                    parent: x.name
                }] : [],
            level: 0,
            parent: ""
        }
    }




    function createStorageAccTreeItemFolder(content, storageAccount, container, rootItem,
        imagePath, treeItemName, indexVector) {
        return (
            <div>
                <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_" + rootItem.fullName}
                    onClick={event => {
                        getDetailBlobItemList(storageAccount, container, rootItem, indexVector,
                            allowUpdate, props.azureManager, actions, dispatch, storageAccounts, setStorageAccounts)
                    }}
                    onContextMenu={event => {
                        handleClick(event, rootItem, rootItem.name)
                    }
                    }
                    onDoubleClick={event => {
                        handleClick(event, rootItem, rootItem.name)
                    }
                    }

                    label={
                        <div>
                            {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === false &&
                                alreadyAzureDataformatOther(storageAccount, container, rootItem) === false &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    {imagePath === 'Folder-Blank' &&
                                        <img alt="" style={{ height: '12px', width: '12px' }}
                                            src={require('resources/icons/azure/Folder.png')} />}

                                    <Typography variant='caption'>{treeItemName}</Typography>


                                </div>
                            }



                            {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === true &&
                                <Tooltip title={`Source already registered in Aqtiva`}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <img alt="" style={{ height: '15px', width: '15px' }}
                                            src={require('resources/icons/favicon-32x32.jpg')} />
                                        <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{treeItemName}</Typography>

                                    </div>
                                </Tooltip>
                            }

                            {alreadyAzureDataformatOther(storageAccount, container, rootItem) === true &&
                                <Tooltip title={`Source already registered in Aqtiva by other user`}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <img alt="" style={{ height: '15px', width: '15px' }}
                                            src={require('resources/icons/favicon-32x32.jpg')} />
                                        <Typography variant='caption' style={({ color: '#a1a1a1' })}>{treeItemName}</Typography>

                                    </div>
                                </Tooltip>
                            }
                        </div>



                    } >
                    {content}
                </TreeItem>
                {createPreviewContextMenu(storageAccount, container, rootItem, true)}
            </div>
        )
    }



    function updateStorageAccountDTORecursively(dto, indexList, secondIndex, values) {
        // se empieza siempre por el hijo
        if (secondIndex < indexList.length) {
            dto.children[indexList[secondIndex]] = updateStorageAccountDTORecursively(dto.children[indexList[secondIndex]], indexList, secondIndex + 1, values)
            return dto
        }
        else {
            dto.children = values
            return dto
        }

    }



    function createRootItemChildren(storageAccount, container, rootItem, indexVector) {

        return (
            <div>
                {rootItem.children !== undefined && rootItem.children.length === 1 && rootItem.children[0].name === 'no data' &&
                    <div>
                        <TreeItem
                            key={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_" + rootItem.parent + "_empty"}
                            nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_" + rootItem.parent + "_empty"} label={
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>

                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                    {allowUpdate === true && <Typography variant='caption'>Loading storage data, it may take some seconds...</Typography>}
                                    {allowUpdate === false && <Typography variant='caption'>Wait for previous data to be loading and try again</Typography>}
                                </div>
                            } />
                    </div>


                }


                {(rootItem.children !== undefined && rootItem.children !== null && rootItem.children.length > 0 && rootItem.children[0].name !== 'no data') &&
                    rootItem.children.sort((a, b) => (a.name > b.name) ? 1 : -1).map((children, childrenIndex) => {
                        return createMenuItemBlobItem(storageAccount, container, children, [...indexVector, childrenIndex])
                    })
                }
            </div>
        )
    }


    function createPreviewContextMenu(storageAccount, container, rootItem, isFolder) {

        if (JSON.stringify(rootItem) === JSON.stringify(selectedItemForMenu)) {


            if (alreadyAzureDataformatOwn(storageAccount, container, rootItem) === true) {
                return (
                    <div>
                        <StyledMenu
                            keepMounted
                            open={state.mouseY !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                state.mouseY !== null && state.mouseX !== null
                                    ? { top: state.mouseY, left: state.mouseX }
                                    : undefined
                            }
                        >
                            <StyledMenuItem onClick={() => setShowAlreadyDatasource(true)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="View Dataformat" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handlePreview(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Preview" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleProfilingNew(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Update Profiling" />
                            </StyledMenuItem>


                        </StyledMenu>
                    </div>
                )
            }
            else if (alreadyAzureDataformatOther(storageAccount, container, rootItem) === true) {
                return (
                    <div>
                        <StyledMenu
                            keepMounted
                            open={state.mouseY !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                state.mouseY !== null && state.mouseX !== null
                                    ? { top: state.mouseY, left: state.mouseX }
                                    : undefined
                            }
                        >
                            <StyledMenuItem onClick={() => setShowAlreadyDatasource(true)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="View Dataformat" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handlePreview(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Preview" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleImport(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Import" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleProfilingNew(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Update Profiling" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </div>
                )
            }
            else {

                return (
                    <div>
                        <StyledMenu
                            keepMounted
                            open={state.mouseY !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                state.mouseY !== null && state.mouseX !== null
                                    ? { top: state.mouseY, left: state.mouseX }
                                    : undefined
                            }
                        >
                            <StyledMenuItem onClick={() => handlePreview(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Preview" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleProfilingNew(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Create Profiling" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </div>
                )
            }

        }
        else {
            return <div></div>
        }




    }

    function isStorageAvailable(storage) {
        if (storage.cors === undefined || storage.cors.length === 0 ||
            storage.cors[0].cors.corsRules === undefined || storage.cors[0].cors.corsRules.length === 0) {
            return false
        }
        return true
    }

    function getStorageCorsErrorMessage(storage) {
        if (storage.cors === undefined || storage.cors.length === 0 ||
            storage.cors[0].cors.corsRules === undefined || storage.cors[0].cors.corsRules.length === 0) {
            return "No CORS has been configured. Please contact your administrator to allow CORS for this account in order to be accesible by Aqtiva"

        }
        return ""
    }



    function createMenuItemBlobItem(storageAccount, container, rootItem, indexVector) {

        let splitName = rootItem.name.split('/')
        let finalName = splitName.length > 1 ? splitName[splitName.length - 1] !== "" ? splitName[splitName.length - 1] : splitName[splitName.length - 2] : rootItem.name

        if (rootItem.type === 'Folder ') {
            if (rootItem.children !== undefined && rootItem.children !== null && rootItem.children.length > 0) {
                return <div>
                    {createStorageAccTreeItemFolder([createRootItemChildren(storageAccount, container, rootItem, indexVector)],
                        storageAccount, container, rootItem,
                        'Folder-Blank', finalName, indexVector)}


                </div>
            }
            else {
                return <div>
                    <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_" + rootItem.fullName}
                        onContextMenu={event => {
                            handleClick(event, rootItem, finalName)
                        }
                        }
                        onDoubleClick={event => handleClick(event, rootItem, finalName)}
                        label={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>
                                <div style={{ width: '25px' }} />
                                <img alt="" style={{ height: '12px', width: '12px' }}
                                    src={require('resources/icons/azure/Folder.png')} />
                                <Typography variant='caption'>{finalName}</Typography>

                            </div>
                        } />
                    {createPreviewContextMenu(storageAccount, container, rootItem, true)}

                </div>
            }

        }
        else {
            return <div>
                <TreeItem nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_" + rootItem.fullName}
                    onContextMenu={event => {
                        if (
                            (allowedFormats.some(substring => rootItem.name.includes(substring)) === true)) {
                            handleClick(event, rootItem, finalName)
                        }
                    }}
                    onDoubleClick={event => {
                        if (
                            (allowedFormats.some(substring => rootItem.name.includes(substring)) === true)) {
                            handleClick(event, rootItem, finalName)
                        }
                    }}
                    label={checkAlreadyCreatedDataformat(rootItem, props.alreadyDefinedDataformats) === true ?

                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>
                            <div />
                            <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '12px', width: '12px' }} />
                            <Tooltip title={`Source already created: ${getNameAlreadyCreatedDataformat(rootItem, props.alreadyDefinedDataformats)}`}>
                                <Typography variant='caption' style={{ fontWeight: 'bold', color: commonStyles.mainColor }}>{finalName}</Typography>
                            </Tooltip>
                        </div>
                        : (allowedFormats.some(substring => rootItem.name.includes(substring)) === true) ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>
                                <div />
                                {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === false &&
                                    alreadyAzureDataformatOther(storageAccount, container, rootItem) === false &&
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>

                                        <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '12px', width: '12px' }} />
                                        <Typography variant='caption' >{finalName}</Typography>

                                    </div>}
                                {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === true &&
                                    <Tooltip title={`Source already registered in Aqtiva`}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <img alt="" style={{ height: '15px', width: '15px' }}
                                                src={require('resources/icons/favicon-32x32.jpg')} />
                                            <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{finalName}</Typography>

                                        </div>
                                    </Tooltip>
                                }


                                {alreadyAzureDataformatOther(storageAccount, container, rootItem) === true &&
                                    <Tooltip title={`Source already registered in Aqtiva by other user`}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <img alt="" style={{ height: '15px', width: '15px' }}
                                                src={require('resources/icons/favicon-32x32.jpg')} />
                                            <Typography variant='caption' style={({ color: '#a1a1a1' })}>{finalName}</Typography>

                                        </div>
                                    </Tooltip>
                                }


                            </div>
                            :
                            <div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>
                                <div />
                                <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '12px', width: '12px' }} />
                                <Tooltip title={`Format not allowed`}>
                                    <Typography variant='caption' style={{ color: '#bfbfbf' }}>{finalName}</Typography>
                                </Tooltip>

                            </div>
                    } />


                {createPreviewContextMenu(storageAccount, container, rootItem, (rootItem.type === 'Folder ') ? true : false)}
            </div>

        }

    }

    function getDataFormatFromItemSelected(itemSelected) {
        var dataformat2Return = {}
        if (azureStorageDataformat !== undefined && azureStorageDataformat !== null && azureStorageDataformat.length > 0) {
            azureStorageDataformat.forEach(dataformat => {
                if (
                    dataformat.fileStorageProperties.account === itemSelected.storageAccount &&
                    dataformat.fileStorageProperties.container === itemSelected.container &&
                    dataformat.fileStorageProperties.path.endsWith(itemSelected.name)) {
                    dataformat2Return = dataformat
                }
            })
        }
        return dataformat2Return
    }


    return (

        <div className={classes.root}>

            <DialogPreviewDataformat
                setVisible={setShowAlreadyDatasource}
                visible={showAlreadyDatasource}
                selectedDataFormat={getDataFormatFromItemSelected(selectedItemForMenu)}
            />
            {(storageAccounts === undefined || storageAccounts === null || storageAccounts.length === 0) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <CircularProgress style={{ width: '25px', height: '25px' }} />
                    <Typography variant='subtitle1'>Loading ...</Typography>
                </div>
            }
            {storageAccounts !== undefined && storageAccounts !== null && storageAccounts.length > 0 &&
                <TreeView
                    className={classes.rootView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {storageAccounts
                        .sort((a, b) => (a.name > b.name) ? 1 : -1).map((storageAccount, storageAccountIndex) => {
                            if (isStorageAvailable(storageAccount) === false) {
                                return <Tooltip key={storageAccount.resourceGroupName + "_" + storageAccount.name} title={getStorageCorsErrorMessage(storageAccount)}>
                                    <TreeItem
                                        key={storageAccount.resourceGroupName + "_" + storageAccount.name}
                                        nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name} label={
                                            <div style={{
                                                marginRight: '5px', display: 'flex',
                                                justifyContent: 'left',
                                            }}>
                                                <img alt="" style={{ height: '20px', width: '20px' }}
                                                    src={require('resources/icons/azure/Storage-Accounts.png')} />


                                                <Typography variant='subtitle2' style={({ color: '#a1a1a1' })}>{storageAccount.name}</Typography>
                                            </div>}
                                    />
                                </Tooltip>
                            }
                            else {
                                return <TreeItem key={storageAccount.resourceGroupName + "_" + storageAccount.name}
                                    nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name} label={
                                        <div style={{
                                            marginRight: '5px', display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <img alt="" style={{ height: '20px', width: '20px' }}
                                                src={require('resources/icons/azure/Storage-Accounts.png')} />


                                            <Typography variant='subtitle2'>{storageAccount.name}</Typography>
                                        </div>}
                                    onClick={event => getDetailStorageTransaction(storageAccount)}>
                                    {(storageAccount.containers.length === 0) ?
                                        <TreeItem
                                            key={storageAccount.resourceGroupName + "_" + storageAccount.name + "_empty"}
                                            nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_empty"} label={
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                    {allowUpdate === true && <Typography variant='caption'>Loading storage data, it may take some seconds...</Typography>}
                                                    {allowUpdate === false && <Typography variant='caption'>Wait for previous data to be loading and try again</Typography>}
                                                </div>
                                            } />

                                        :
                                        (storageAccount.containers !== undefined && storageAccount.containers.length === 1 && storageAccount.containers[0].name === 'no-container-found') ?

                                            <TreeItem
                                                key={storageAccount.resourceGroupName + "_" + storageAccount.name + "_empty"}
                                                nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_empty"} label={
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}>

                                                        <Typography variant='subtitle2'>No containers found</Typography>
                                                    </div>
                                                } />
                                            :
                                            storageAccount.containers
                                                .sort((a, b) => (a.name > b.name) ? 1 : -1).map((container, containerIndex) => {

                                                    return <TreeItem
                                                        key={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name}
                                                        nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name}
                                                        onClick={event => getRootItemsForContainer(storageAccount, container, containerIndex, storageAccountIndex)}
                                                        label={
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'left',
                                                            }}>

                                                                {storageAccount.kind !== 'ADLV2' &&
                                                                    <img alt="" style={{ height: '15px', width: '15px' }}
                                                                        src={require('resources/icons/azure/Storage-Container.png')} />
                                                                }
                                                                {storageAccount.kind === 'ADLV2' &&
                                                                    <img alt="" style={{ height: '25px', width: '25px' }}
                                                                        src={require('resources/icons/azure/adlv2.png')} />
                                                                }

                                                                <Typography variant='subtitle2'>{container.name}</Typography>
                                                            </div>
                                                        } >

                                                        {
                                                            container.storageAccountItemDTOSRoot === undefined || container.storageAccountItemDTOSRoot === null ? [] :
                                                                container.storageAccountItemDTOSRoot
                                                                    .sort((a, b) => (a.name > b.name) ? 1 : -1).map((rootItem, rootItemIndex) => {

                                                                        return createMenuItemBlobItem(storageAccount, container, rootItem, [storageAccountIndex, containerIndex, rootItemIndex])
                                                                    })
                                                        }
                                                        {(container.storageAccountItemDTOSRoot === undefined || container.storageAccountItemDTOSRoot.length === 0) &&
                                                            <TreeItem
                                                                key={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_empty"}
                                                                nodeId={storageAccount.resourceGroupName + "_" + storageAccount.name + "_" + container.name + "_empty"} label={
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'left',
                                                                    }}>
                                                                        <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                                        <Typography variant='subtitle2'>Loading storage data, it may take some seconds...</Typography>
                                                                    </div>
                                                                } />
                                                        }
                                                    </TreeItem>


                                                })
                                    }
                                </TreeItem>
                            }
                        })}

                </TreeView>
            }
        </div>

    )
}
