import React from 'react';
import {TextField} from '@material-ui/core';
import {getDataFromProperties} from './SpecificRuleHelper'
import { ICommonRule, IColumn } from 'interfaces/RuleInterfaces';


interface FormRowAnomalyDetectionProps {
    commonRule: ICommonRule,
    setSpecific: (value: any)=>any,
    editableRule: any,
    specific: any,
    source: IColumn [],

}
export default function FormRowAnomalyDetectionProps(props:FormRowAnomalyDetectionProps){

    //const classes = makeStyles(styles(QualityTheme));

    const [outlierThreshold,setOutlierThreshold] = React.useState(getDataFromProperties(props.editableRule, props.specific,'outlierThreshold',10))
    
    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            subtype:'cell_anomaly_rows',
            outlierThreshold: outlierThreshold
        }
        props.setSpecific(specific)
       
    }
    
    React.useEffect(() => {
        createSpecific() 
    }, [outlierThreshold]);// eslint-disable-line react-hooks/exhaustive-deps

    


    return(
        <div style={{marginTop:'5px'}}>
            <div style={{maxWidth:'100px'}}>
            <TextField
                    fullWidth={true}
                    id="expTextField"
                    label="Outlier Threshold"
                    InputLabelProps={{ shrink: true, style:{ fontSize:'12px'} }}
                    //className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    type='number'
                    value={outlierThreshold} 
                    onChange={event =>{setOutlierThreshold(event.target.value)}}
                />
            </div>
            
            
    </div>
    )
}
