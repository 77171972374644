import React, { useState } from 'react';
import QualityPointGrid from '../QpDrillDownComponent/GridAgregations/QualityPointGrid'
import DatasourceGrid from '../QpDrillDownComponent/GridAgregations/DataSourceGrid'
import ExecutionGrid from '../QpDrillDownComponent/GridAgregations/ExecutionGrid'
import { mockDetalleProyectos } from '../Common/Charts/Mock';

export default function QpDrillDownComponent(props) {

    const [agregationLevel, setAgregationLevel] = useState("qualityPoint")

    //const [qualityPoint, setQualityPoint] = useState();
    const [dataSource, setDataSource] = useState();
    const [name, setName] = useState();

    const data = []
    mockDetalleProyectos.map(pr => {
        return pr.qualityPointDQI.map(qp => {
            data.push(qp)
            return undefined
        })
    })


    async function handleDrillDownElement(e) {
        if (agregationLevel && agregationLevel === "qualityPoint") {
            setAgregationLevel("qualityPoint")
        }
        if (agregationLevel && agregationLevel === "qualityPoint") {
            setDataSource(e.datasourceDQI)
            setName(e.name)
            //setAgregationLevel("datasource")
        }
        //TODO: this is gonna break
        /*if (agregationLevel && agregationLevel === "execution") {
            setDataSource(qualityPoint[0].datasourceDQI.filter(ds => ds.datasourceId === e.datasourceId))
            setAgregationLevel("execution")
        }*/

    }

    return (
        <>
            {agregationLevel && agregationLevel === "qualityPoint" && <QualityPointGrid
                DQI4QP={props.DQI4QP}
                filterObj={props.filterObj}
                handleDrillDownElement={handleDrillDownElement}
            />}
            {agregationLevel && agregationLevel === "datasource" && <DatasourceGrid
                data={dataSource}
                name={name}
                handleDrillDownElement={handleDrillDownElement}
                setAgregationLevel={setAgregationLevel}

            />}
            {agregationLevel && agregationLevel === "execution" && <ExecutionGrid
                handleDrillDownElement={handleDrillDownElement}
                setAgregationLevel={setAgregationLevel}
            />}
        </>

    );
}