import React, { useState } from "react";
import {  makeStyles } from '@material-ui/core/styles';
import EnhancedTableHead from '../EnhancedTableHead';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Checkbox, Tooltip, Chip } from '@material-ui/core';
import { getComparator, stableSort, invertArray } from '../utils'


/* This table component is composed by three subcomponents:
-EnhancedTableHead where define columns names and we set display sort values
-EnhancedTableToolbar where the "delete column" and "go back" logic resides
-EnhancedTable, which is the main component where data is displayed and where the "delete rule" and pagination logic resides
*/

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


export default function EnhancedTable(props) {
  const { ruleRecommendation, selected, setSelected, histDisplay, setHistDisplay, currentDisplay, setCurrentDisplay, rules, setRules } = props;
  if(ruleRecommendation && rules){
    //TODO: to avoid warnings compiler
  }
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  // get data from the store, set currentDisplay and set HistDisplay filtering all empty values from it, preventing errors when we go back without any modifications done.
  /*  useEffect(() => {
     let { columns } = ruleRecommendation
     let modifiedData = histDisplay.slice()
     modifiedData.push(columns)
     modifiedData = modifiedData.filter(Boolean);
     setHistDisplay(modifiedData)
     setCurrentDisplay(columns)
   }, [ruleRecommendation]) */


  const handleDelete = (name, ruleToDelete) => () => {

    const newState = JSON.parse(JSON.stringify(currentDisplay))
    for (let i = 0; i < newState.length; i++) {
      if (newState[i].column === name) {
        for (let j = 0; j < newState[i].rules.length; j++) {
          if (newState[i].rules[j].rule === ruleToDelete) {
            newState[i].rules.splice(j, 1)
          }
        }
      }
    }
    let newData = JSON.parse(JSON.stringify(histDisplay))
    newData.push(newState)
    setCurrentDisplay(newState)
    setHistDisplay(newData)
    // updated new rules Array
    let inverted = invertArray(newState)
    setRules(inverted)
  }


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = currentDisplay.map((n) => n.column);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, currentDisplay && currentDisplay.length - page * rowsPerPage);


  return (
    <div >
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={currentDisplay.length}
          />
          <TableBody>
            {stableSort(currentDisplay, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.column);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.column)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.column}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell align="left">{row.column}</TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="right">
                      {row.rules && row.rules.map(ruleInfo => {
                        return (<>
                          {ruleInfo.params && <Tooltip
                            title={
                              <React.Fragment>
                                <h3>Acepted values:</h3>
                                {ruleInfo.params.values && ruleInfo.params.values.map((value, index) => {
                                  if (index < ruleInfo.params.values.length - 1 && index < 10) {
                                    return <h4>{value + ', '}</h4>
                                  }
                                  if (index === 10) {
                                    return <h4>{'...'}</h4>
                                  }
                                  else if (index < 10 && index === ruleInfo.params.values.length - 1) {
                                    return <h4>{value}</h4>
                                  }
                                return <h4>{''}</h4>
                                }
                                )}
                                {ruleInfo.params.reg_exp && <h4>{ruleInfo.params.reg_exp}</h4>}
                                {(ruleInfo.params.min || ruleInfo.params.min === 0) && <h4>{'min: ' + ruleInfo.params.min.toString()}</h4>}
                                {ruleInfo.params.max && <h4>{'max: ' + ruleInfo.params.max.toString()}</h4>}
                              </React.Fragment>
                            }>
                            <span>
                              <Chip label={ruleInfo.rule} onDelete={handleDelete(row.column, ruleInfo.rule)} color="primary" />
                            </span>
                          </Tooltip>}
                          {!ruleInfo.params &&
                            <Chip label={ruleInfo.rule} onDelete={handleDelete(row.column, ruleInfo.rule)} color="primary" />
                          }
                        </>
                        )
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={currentDisplay.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
