import { faUndo, faDatabase, faProjectDiagram, faFileAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'

import * as commonStyles from 'style/CommonStyles'


export function extractProperties(version, displayAll, displayMyModifications, userId) {
    let color
    switch (version.updateType) {
        case "create":
            color = '#6BAA75'
            break
        case "edit":
            color = commonStyles.mainColor
            break
        case "delete":
            color = '#E95232'//'#ff9800'
            break;
        case "consolidate":
            color = commonStyles.mainColor
            break;
        case "ruleRecommender":
            color = '#F7FF58'
            break;
        case "commit":
            color = commonStyles.mainColor
            break;

        default:
            color = commonStyles.mainColor
    }
    let icon
    switch (version.updateElementType) {
        case "qualityPoint":
            icon = faProjectDiagram
            break
        case "datasource":
            icon = faDatabase
            break
        case "rule":
            icon = faFileAlt
            break
        case "project":
            if (version.updateType === 'commit') {
                icon = faUserCircle
            }
            if (version.updateType === 'revert') {
                icon = faUndo
            }
            break
        default:
            icon = false
    }
    let message
    let variant = 'subtitle2'
    let isCommit = false
    switch (version.updateType) {
        case "delete":
            message = "deleted"
            break
        case "create":
            message = "created"
            break
        case "edit":
            message = "edited"
            break
        case "consolidate":
            message = "consolidated"
            break
        case "revert":
            message = "reverted"
            break
        case "commit":
            message = "commit"
            isCommit = true
            variant = 'subtitle1'
            break
        default:
            message = ''
    }


    let display

    //let displayed = displayMyModifications && version.userId === userId

    if (version.updateType === "commit" || displayAll) {
        if (displayMyModifications) {
            if (version.userId === userId) {
                display = "flex"

            }
            else {
                display = "none"

            }
        }
        else {
            display = "flex"
        }
    }
    else {
        display = "none"
    }

    let stylePropeties = {
        color: color,
        icon: icon,
        message: message,
        display: display,
        variant: variant,
        isCommit: isCommit
    }

    return stylePropeties
}




export function extractPropertiesNewCommit(version, displayAll, displayMyModifications, userId) {
    let color
    switch (version.projectHistory && version.projectHistory.updateType) {
        case "create":
            color = '#6BAA75'
            break
        case "edit":
            color = commonStyles.mainColor
            break
        case "delete":
            color = '#E95232'//'#ff9800'
            break;
        case "consolidate":
            color = commonStyles.mainColor
            break;
        case "ruleRecommender":
            color = '#F7FF58'
            break;
        case "commit":
            color = commonStyles.mainColor
            break;

        default:
            color = commonStyles.mainColor
    }
    let icon
    switch (version.projectHistory && version.projectHistory.updateElementType) {
        case "qualityPoint":
            icon = faProjectDiagram
            break
        case "datasource":
            icon = faDatabase
            break
        case "rule":
            icon = faFileAlt
            break
        case "project":
            if (version.projectHistory.updateType === 'commit') {
                icon = faUserCircle
            }
            if (version.projectHistory.updateType === 'revert') {
                icon = faUndo
            }
            break
        default:
            icon = false
    }
    let message
    let variant = 'subtitle2'
    let isCommit = false
    switch (version.projectHistory.updateType) {
        case "delete":
            message = "deleted"
            break
        case "create":
            message = "created"
            break
        case "edit":
            message = "edited"
            break
        case "consolidate":
            message = "consolidated"
            break
        case "revert":
            message = "reverted"
            break
        case "commit":
            message = "commit"
            isCommit = true
            variant = 'subtitle1'
            break
        default:
            message = ''
    }


    let display

    //let displayed = displayMyModifications && version.userId === userId

    if (version.projectHistory && version.projectHistory.updateType === "commit" || displayAll) {
        if (displayMyModifications) {
            if (version.userId === userId) {
                display = "flex"

            }
            else {
                display = "none"

            }
        }
        else {
            display = "flex"
        }
    }
    else {
        display = "none"
    }

    let stylePropeties = {
        color: color,
        icon: icon,
        message: message,
        display: display,
        variant: variant,
        isCommit: isCommit
    }

    return stylePropeties
}