import React from 'react';

import {
    Button,
    Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlobServiceClient } from '@azure/storage-blob';
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from 'react-redux';
import LinearProgressBar from './LinearProgressBar';


import MaterialTable from "@material-table/core";

import { faSpellCheck } from '@fortawesome/free-solid-svg-icons'
import { faSortNumericUp } from '@fortawesome/free-solid-svg-icons'

import * as actions from 'actions'
import { dispatchError, dispatchInfo } from 'components/common/axios/axiosHelper';

import * as commonStyles from 'style/CommonStyles'



export default function ComponentPreviewData(props) {
    const theme = useTheme();

    const configuration = useSelector(store => (store.qualityConfiguration))
    const env = useSelector(store => store.environment)

    const [columns, setColumns] = React.useState([])
    const [rawData, setRawData] = React.useState([])
    const [data, setData] = React.useState([])
    const [progressComplete, setProgressComplete] = React.useState(false)

    const dispatch = useDispatch();


    async function processData() {
        if (props.schema && props.schema.fields && props.schema.fields.length > 0) {

            let col = (props.schema.fields.map(x => {
                let value = {
                    title: x.name, render: rowData => {
                        if (rowData.type !== 'data') {
                            getCellNumberSummary(x)
                        }
                        return <div >

                            {rowData.type === 'data' &&
                                <Typography variant='caption'>{rowData[x.name]}</Typography>}
                            {rowData.type !== 'data' &&
                                getCellNumberSummary(x)
                            }


                        </div>
                    }
                }
                return value
            }))

            setColumns(col)


            const selectedEnvironment = configuration.environmentConfigurationList.filter(x => x.environmentName === env)[0]
            if (selectedEnvironment.backendType === 'Azure') {
                getPreviewDataAzure(selectedEnvironment)
            }
            else if (selectedEnvironment.backendType === 'AWS') {
                getPreviewDataAws(selectedEnvironment)
            }

        }
    }
    React.useEffect(() => {

        processData()
    }, [props.schema, props.stats, props.dataCount, props.outputPath, props.selectedEnvironmentStates])// eslint-disable-line react-hooks/exhaustive-deps



    async function getPreviewDataAzure(selectedEnvironment) {
        const account = selectedEnvironment.storageAccountName
        const sasTmp = await dispatch(actions.getSecret(selectedEnvironment.sasToken))
        
        let sas = sasTmp.startsWith("?") ? sasTmp : "?" + sasTmp
        const containerName = selectedEnvironment.containerName
        //var blobName = "5d829501ee51b877f859a29c/stagging/sample/"+props.fileOutputName;

        if (props.outputPath !== undefined) {
            const outputPathBlob = props.outputPath.replaceAll("dfs.core.windows.net","blob.core.windows.net").replaceAll("abfs","wasb")
            var blobName = outputPathBlob.split(".blob.core.windows.net/")[1]
            if(blobName!==undefined){
                blobName = blobName.split('.').slice(0, -1).join('.')+".csv"
                try {
                    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
                    downloadAzure(blobServiceClient, containerName, blobName)
                } catch (error) {
                    console.error(error);
                    dispatchError(dispatch, error)
                }
            }
           
        }
    }


    async function getPreviewDataAws(selectedEnvironment) {

        let awsCredentials = {
            bucketName: selectedEnvironment.amazonConfiguration.bucketName,
            region: selectedEnvironment.amazonConfiguration.awsServiceRegion,
            accessKey: selectedEnvironment.amazonConfiguration.accessKey,
            encryptedSecretKey: selectedEnvironment.amazonConfiguration.privateKey
        }
        if (props.outputPath != undefined) {
            let folder = props.outputPath.replace('s3a://' + awsCredentials.bucketName + '/', '')
            downloadAWS(folder, awsCredentials)
            /*let result = await dispatch(actions.listPrefixObjects(awsCredentials, folder.replaceAll("/", "__AQTIVA_SLASH__")))

            if (result && result.objects && result.objects.length > 0) {
                let filename = result.objects.filter((x) => x.key.startsWith(folder + 'part'))[0].key
                downloadAWS(filename, awsCredentials)
            }*/

        }


    }

    function getCellNumberSummary(data) {
        if (props.stats && data) {
            let type = data.type
            if (type === 'string') {
                let statData = props.stats[data.name]
                let nullPercent = Math.round((props.dataCount - parseInt(statData[0])) / props.dataCount * 100) / 100

                return (
                    <div style={{ minWidth: '150px' }}>
                        <div style={theme.elementAdjacent}>
                            <FontAwesomeIcon icon={faSpellCheck} style={theme.dialogs.smallIcon} />
                            <Typography variant='subtitle2'>{data.type}</Typography>
                        </div>

                        <Typography variant='caption'>{`Null percent: ${nullPercent * 100}%`}</Typography>
                    </div>
                )
            }
            else if (type === 'integer' || type === 'double' || type === 'long') {
                let statData = props.stats[data.name]
                let nullPercent = Math.round((props.dataCount - parseInt(statData[0])) / props.dataCount * 100) / 100

                return (
                    <div style={{ minWidth: '150px' }}>
                        <div style={theme.elementAdjacent}>
                            <FontAwesomeIcon icon={faSortNumericUp} style={theme.dialogs.smallIcon} />
                            <Typography variant='subtitle2'>{data.type}</Typography>
                        </div>
                        <div>
                            <Typography variant='caption'>{`Null percent: ${nullPercent * 100}%`}</Typography>
                        </div>
                        <div>
                            <Typography variant='caption'>{`Range: ${Math.round(parseFloat(statData[4]) * 100) / 100} - ${Math.round(parseFloat(statData[3]) * 100) / 100}`}</Typography>
                        </div>




                    </div>
                )
            }
            else {
                return <Typography variant='subtitle2'>{data.type}</Typography>
            }
        }

    }

    React.useEffect(() => {

        setData(parseDataForSchema(rawData, columns))
    }, [columns, rawData])



    function parseDataForSchema(data, schema) {
        const schema_length = schema.length

        let d = (data !== undefined && data.length > 0) ? data.map(row => {
            let rowSplit = row.split("|")
            if (rowSplit.length < schema.length) {
                return {}
            }
            else {
                var objectRow = {}
                for (var i = 0; i < schema_length; i++) {
                    objectRow[schema[i].title] = rowSplit[i]
                }
                objectRow.type = 'data'
                return objectRow
            }
        }) : []
        var summaryRow = []
        for (var i = 0; i < schema.length; i++) {
            summaryRow[schema[i].title] = 'summary'
        }
        summaryRow.type = 'summary'
        d.unshift(summaryRow)

        return d



    }




    async function downloadAzure(blobServiceClient, containerName, blobName) {
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);

        // Get blob content from position 0 to the end
        // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
        const exists = await blobClient.exists();
        if (exists === true) {
            const downloadBlockBlobResponse = await blobClient.download();
            const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

            const data = downloaded.split('\n').filter(x => x !== '')

            setRawData(data)
        }
        else {
            dispatchInfo(dispatch, "No data was found")
        }



        // [Browsers only] A helper method used to convert a browser Blob into string.
        async function blobToString(blob) {
            const fileReader = new FileReader();
            return new Promise((resolve, reject) => {
                fileReader.onloadend = (ev) => {
                    resolve(ev.target.result);
                };
                fileReader.onerror = reject;
                fileReader.readAsText(blob);
            });
        }
    }

    async function downloadAWS(path, credentials) {

        let fileContent = [await dispatch(actions.getS3Object(credentials, path.replaceAll("/", "__AQTIVA_SLASH__")))]
        if (fileContent != undefined) {

            var oneLineFile = fileContent[0][0]

            const data = oneLineFile.split('\n').filter(x => x !== '')

            setRawData(data)

        } else {
            dispatchInfo(dispatch, "No data was found")
        }

    }

   

    function createAndContinue() {
        props.createAndContinue()
    }

    return (



        <div style={{ width: '100%', margin: "30px" }}>
            {/* convertSchema2DatasourceBase  */}
            {props.visible === true && props.parsingData === true &&
                <div>
                    {props.selectedEnvironmentStates === "RUNNING" && <LinearProgressBar timeToComplete={90} style={{ width: '100%' }} setProgressComplete={setProgressComplete} />}
                    {props.selectedEnvironmentStates !== "RUNNING" && <Typography style={{ width: '100%' }} variant={'subtitle2'}> Starting Big Data Cluster. It may take some minutes. Please wait...</Typography>}
                    {props.selectedEnvironmentStates === "RUNNING" && !progressComplete && <Typography style={{ width: '100%' }} variant={'subtitle2'}> Parsing Data in Big Data Backend. It may take around 1 minute. Please wait... </Typography>}
                    {progressComplete && <Typography style={{ width: '100%' }} variant={'subtitle2'}> The process is taking longer than expected. Your content will be ready shortly... </Typography>}
                    {(props.errorBackend !== undefined && props.errorBackend !== null) &&
                        <Typography style={{ width: '100%' }} variant={'caption'}> {props.errorBackend} </Typography>
                    }
                </div>}

            {props.visible === true && props.parsingData === false &&
                <div>
                    <MaterialTable
                        title="Simple Action Preview"
                        columns={columns.map((c) => ({ ...c, tableData: undefined }))}
                        data={data}
                        options={{
                            filtering: false,
                            search: false,
                            sorting: false,
                            maxBodyHeight: 650,
                            minBodyHeight: 250,
                            headerStyle: {
                                fontSize: 'calc(8px + 0.2vw)',
                                backgroundColor: commonStyles.mainColor,
                                color: '#FFF'
                            },
                        }}
                        components={{
                            Toolbar: props => (
                                <div>

                                </div>
                            ),
                        }}


                    />
                    <div style={{ marginTop: '1%' }}>
                        <Button id='importBtn' variant='outlined' style={theme.buttons.buttonConfirm} onClick={event => { createAndContinue() }}>Create and continue </Button>
                    </div>
                </div>
            }


        </div>
    )
}