import React, { useState, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from "@material-table/core";


import * as commonStyles from   './../../../../style/CommonStyles'

import * as renderHelper from './utils'

import { LanguageContext } from './../../../../language/LanguageContext'


export default function DataformatTable(props) {

    const [data, setData] = useState([])
    //const [selected, setSelected] = useState(false)
    const languageContext = React.useContext(LanguageContext); 

   


    useEffect(() => {
        let filteredDataformats = JSON.parse(JSON.stringify(props.dataformats))
        filteredDataformats = filteredDataformats.filter(dataformat => dataformat.profiling !==null)

        if(props.selectedDataformatUser){
            filteredDataformats = filteredDataformats.filter(dataformat=>dataformat.userId === props.selectedDataformatUser)
        }
        setData(filteredDataformats)
    }, [props.dataformats && props.dataformats.length, // eslint-disable-line react-hooks/exhaustive-deps  
        props.selectedDataformatSource && props.selectedDataformatSource.length, props.selectedDataformatUser]) // eslint-disable-line react-hooks/exhaustive-deps  








    return (
        <MaterialTable
            style={{ width: "100%" }}
            title=""
            data={data}
            onRowClick={(event, rowData) => {
                if (props.selectDatasetDialog) {
                    props.handleSelectDatasetForExportation(rowData._id)
                    if (rowData.tableData.id === props.selectedDatasetForExportationId) {
                        //setSelected(false)
                        props.handleSelectDatasetForExportationId(rowData._id)
                    } else {
                       // setSelected(true)
                        props.handleSelectDatasetForExportationId(rowData._id)
                    }
                }

            }}
            columns={
                [
                    { title: languageContext.dictionary['name'],width:'15%', field: 'name' },
                    { title: languageContext.dictionary['creationDate'],width:'5%', field: 'creationDate', filtering: false, render: rowData => { return rowData.creationDate && renderHelper.parseDateToCorrectFormat(rowData.creationDate) } },
                    { title: languageContext.dictionary['numberOfColumns'],width:'5%', field: 'columns', filtering: false, render: rowData => { return rowData.columns ? rowData.columns.length : 0 } },
                    { title: languageContext.dictionary['sourceType'], width:'20%',field: 'source', filtering: false, render: rowData => { return renderHelper.renderAzureLogo(rowData.source) } },
                    { title: languageContext.dictionary['storageAccount'], width:'10%',field: 'name', render: rowData=>{return renderHelper.getAzureRG(rowData)} },
                    { title: languageContext.dictionary['origin'],width:'30%', field: 'name', render: rowData=>{return renderHelper.getDataformatOrigin(rowData)} },
                    
                    { title: languageContext.dictionary['owner'], width:'10%',field: 'userId', filtering: false, render: (rowData) => { return rowData.ownerUserId ? props.translateUserIdToName(rowData.ownerUserId, props.licenseUsers) : rowData.userId && props.translateUserIdToName(rowData.userId, props.licenseUsers) } },
                    {
                        title: languageContext.dictionary['profilingVersion'],width:'5%', field: 'version', filtering: false, render: rowData => { return rowData.profiling ? rowData.profiling.profilingVersion: "-" }
                    },
                   
                ]}
            options={
                {
                rowStyle: rowData => ({
                    backgroundColor:
                        props.selectedDatasetForExportationId &&
                            rowData._id === props.selectedDatasetForExportationId
                            ? commonStyles.mainColor
                            : "#fff"
                }),
                actionsColumnIndex: -1,
                filtering: false,
                search: false,
                pageSize: 5,
                maxBodyHeight: '90%',
                minBodyHeight: 250,
                headerStyle: commonStyles.tableHeaderStyle,
                    cellStyle: commonStyles.tableCellStyle,
                    filterCellStyle: commonStyles.tableFilterCellStyle
            }}
            actions={[
                {
                    icon: () => 
                     <SearchIcon style={{color:commonStyles.mainColor}}/>
               ,
                    tooltip: languageContext.dictionary['tooltipSeeProfile'],
                    onClick: (event,rowData) => props.viewDatasetProfiling(rowData._id)
                  },
            ]}
            components={{
                Toolbar: props => (
                    <div>

                    </div>
                ),
            }}
        />

    )
}
