
import React from 'react';

import { Grid,  Typography,  InputAdornment, FormControlLabel, Switch } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSignature } from '@fortawesome/free-solid-svg-icons'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "components/common/CustomInput/CustomInput.js";
import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'


import TablePrevisualization from './TablePrevisualization'

import 'style/layout.css'

import InputFile from 'components/common/azure-storage/components/InputFile'
import AqtivaDialog from 'components/common/AqtivaDialog'

import { useTheme } from '@material-ui/core/styles';

import { DataRepositoryTreeTable } from 'components/data/DataRepositoryShare/DataRepositoryTreeTrable/DataRepositoryTreeTable'
import * as convertDataHelper from 'components/data/DataRepositoryShare/EntityDataRepository/Helpers/convertDataHelper'
import * as getterDTO from "components/data/DataRepositoryShare/EntityDataRepository/Helpers/getterDTOs";

import {
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";

import {isEmpty} from 'commonFunctions/commonFunctions'
import { getUserPreferenceProperty } from 'components/common/userHelper'
import { LanguageContext } from 'language/LanguageContext'

import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'


export default function DialogCreateDataSource(props) {

  const languageContext = React.useContext(LanguageContext);

  const theme = useTheme();

  const user = useSelector(store => store.user)
  const env = useSelector(store => store.environment)

  const dispatch = useDispatch();

  //variables de estado
  const [error, setError] = React.useState({})
  const [selectedFormatName, setSelectedFormatName] = React.useState(props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? props.selectedDataset.name : null)
  const [fileUploadName, setFileUploadName] = React.useState(null)
  const [separator, setSeparator] = React.useState('automatic')
  const [colFormats, setColFormats] = React.useState([])
  const [checkAutomaticSeparator, setCheckAutomaticSeparator] = React.useState(true)

  const [defaultFolderId, setDefaultFolderId] = React.useState(" ");
  const [expandedKeys, setExpandedKeys] = React.useState();
  const [selectedDtoId, setSelectedDtoId] = React.useState(undefined)

  const [selectedItem, setSelectedItem] = React.useState({ itemId: "", type: "" }); // selectedItem del private
  

  const [parsedSeparator, setParsedSeparator] = React.useState("")


  const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
  //const [selectedEnvironmentStates, setSelectedEnvironmentStates] = React.useState('')
  function setSelectedEnvironmentStates(){}

  var qualityConfiguration = useSelector(store => (store.qualityConfiguration))

  
  const entityDataRepo = useSelector((store) =>
    Object.values(store["datasetBatchDataRepository"])
  );


  React.useEffect(() => {
    validate()
    if (selectedItem) {
      setSelectedDtoId(getterDTO.getDTOIdFromFolder(entityDataRepo, selectedItem.itemId))
    }

  }, [selectedItem])// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (user && user.id) {

      dispatch(
        actions.fetchDataSetBatchPrivateWorkspace(
          user.id
        )
      );
    }
  }, [user]);// eslint-disable-line react-hooks/exhaustive-deps


  React.useEffect(() => {
    let defaultFolderIdNumber = entityDataRepo[0] && entityDataRepo[0]._id
    setDefaultFolderId(defaultFolderIdNumber)
  }, [entityDataRepo])

  const actionsByType = {
    projectDataRepository: {
      fetchProjectDataRepositorySharedWorkspace:
        actions.fetchProjectDataRepositorySharedWorkspace,
      fetchProjectDataRepositoryPrivateWorkspace:
        actions.fetchProjectDataRepositoryPrivateWorkspace,
      deleteParentFolderFromRepository: actions.deleteParentFolderFromRepository,
      deleteFolderFromRepositoryAndSave: actions.deleteFolderFromRepositoryAndSave,
      deleteFileFromRepository: actions.deleteFileFromRepository,
      addFolderToRepository: actions.addFolderToRepository,
      addRootFolderToRepository: actions.addRootFolderToRepository,
      exportFileFromRepository: actions.exportFileFromRepository,
      importFileToRepository: actions.importFileToRepository,
    },
    datasets: {
      fetchProjectDataRepositorySharedWorkspace:
        actions.fetchDataSetBatchSharedWorkspace,
      fetchProjectDataRepositoryPrivateWorkspace:
        actions.fetchDataSetBatchPrivateWorkspace,
      deleteParentFolderFromRepository:
        actions.deleteParentFolderFromRepositoryDataSetBatch,
      deleteFolderFromRepositoryAndSave: actions.deleteParentFolderFromRepositoryAndSaveyDataSetBatch,
      deleteFileFromRepository: actions.deleteFileFromRepositoryDataSetBatch,
      addFolderToRepository: actions.addFolderToRepositoryDataSetBatch,
      addRootFolderToRepository: actions.addRootFolderToRepositoryDataSetBatch,
      exportFileFromRepository: actions.exportFileFromRepositoryDataSetBatch,
      importFileToRepository: actions.importFileToRepositoryDataSetBatch,
    },
    rules: {
      fetchProjectDataRepositorySharedWorkspace:
        actions.fetchRulesDataRepositorySharedWorkspace,
      fetchProjectDataRepositoryPrivateWorkspace:
        actions.fetchRulestDataRepositoryPrivateWorkspace,
      deleteParentFolderFromRepository:
        actions.deleteParentFolderFromRepositoryRules,
      deleteFileFromRepository: actions.deleteFileFromRepositoryRules,

      addFolderToRepository: actions.addFolderToRepositoryRules,
      deleteFolderFromRepositoryAndSave: actions.deleteFolderFromRepositoryAndSaveRules,
      addRootFolderToRepository: actions.addRootFolderToRepositoryRules,
      exportFileFromRepository: actions.exportFileFromRepositoryRules,
      importFileToRepository: actions.importFileToRepositoryRules,
    },
  };




  function onChangeName(event) {
    setSelectedFormatName(event.target.value)
  }
  function onChangeSeparator(event) {
    setSeparator(event.target.value)
  }


  async function onUpload(fileName) {
    setFileUploadName(fileName)

    var sep = separator
    if (sep === ";") {
      sep = "semicolon"
    }
    else if (sep === "|") {
      sep = "pipe"
    }


    const errors = validate()
    setError(errors)
    var env2Send = env
    if (env === null || isEmpty(env)) {
      env2Send = getUserPreferenceProperty(user, 'selectedEnvironment')
    }
    if(env2Send && env2Send.environmentName){
      env2Send = env2Send.environmentName
    }
    const response = await dispatch(actions.parseDataFormatBlob(sep, fileName, user.id, undefined, env2Send))

    setColFormats(response.data.columns)
    setParsedSeparator(response.data.separator)



  }


  function saveDataformatCallback(event) {
    const validateResult = validate()
    setError(validateResult)

    let dtoId = selectedDtoId
    let folderId = selectedItem.itemId === selectedDtoId ? 'null' : selectedItem.itemId

    if ((Object.keys(validateResult).length === 0)) {

      //create an object to store where is dataformat file stored
      let fileStorageProperties = {
        resourceGroup: '',
        account: selectedEnvironment ? selectedEnvironment.storageAccountName : 'sainputdemoquality',
        container: selectedEnvironment ? selectedEnvironment.containerName : 'unit-test',
        path: fileUploadName
      }



      if (((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true'))) {
        dispatch(actions.addDataFormatAndRegister(colFormats, parsedSeparator, selectedFormatName, user.id, dtoId, folderId, fileStorageProperties)).then(response =>
          dispatch(actions.fetchDataSetBatchPrivateWorkspace(user.id)))
      }
      else {
        dispatch(actions.addDataFormatNew(colFormats, parsedSeparator, selectedFormatName, user.id, fileStorageProperties))

      }

      props.setClose(false)
    }
  }
  async function createNewVersion(event) {
    var dataformat2Submit = {};
    dataformat2Submit.columns = colFormats
    dataformat2Submit.name = selectedFormatName
    dataformat2Submit.userId = user.id
    dataformat2Submit.separator = separator

    let fileStorageProperties = {
      resourceGroup: '',
      account: selectedEnvironment ? selectedEnvironment.storageAccountName : 'sainputdemoquality',
      container: selectedEnvironment ? selectedEnvironment.containerName : 'unit-test',
      path: fileUploadName
    }
    dataformat2Submit.fileStorageProperties = fileStorageProperties
    await dispatch(actions.createNewDataformatVersion(dataformat2Submit, props.selectedDataset._id))
    props.setClose(false)
    props.closeAzureItemDialog(false)
    props.closeDialogDisplay(false)

  }

  function validate() {
    var errors = {};


    if (!selectedFormatName || selectedFormatName === null) {
      errors.selectedFormatName = languageContext.dictionary['provideFilenameError'];

    }
    if (!separator || separator === null) {
      errors.separator = languageContext.dictionary['selectSeparatorError'];

    }

    if (((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true')) &&
      selectedItem.type !== "Folder") {
      errors.folder = languageContext.dictionary['folderSelectedError'];

    }
    setError(errors)

    return errors;
  }
    
  function setColFormatsCallback(newFormat) {
    setColFormats(newFormat)
  }

  function validateInputs(states, type) {
    const validations = {
      name: {
        rule: /^[\w_]*$/.test(states.name),
        description: languageContext.dictionary['incorrectNameError']
      }
    }
    if (type) {
      return validations[type]
    }
    return Object.values(validations).every((value) => value.rule === true)
  }

  return (
    <AqtivaDialog visible={props.open}
      fullWidth={true}
      maxWidth={'lg'}
      title={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? 
        `${languageContext.dictionary['createNewDataformatVersion']} ${props.selectedDataset.name}` : languageContext.dictionary['createDataformat']}
      titleIcon={faTable}
      confirmText={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? languageContext.dictionary['createNewVersion'] : languageContext.dictionary['create']}
      cancelText={'Close'}
      confirmCallback={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? createNewVersion : saveDataformatCallback}
      cancelCallback={event => props.setClose(false)}
      disabled={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? false : !validateInputs({ name: selectedFormatName })}
    >



      <Grid container spacing={2}>
        <Grid item xs={6}>
        <div style={{ width: '150px', marginRight: '20px' }}>
                                <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                                    setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                            </div>
          <Grid item xs={12}>
            {!props.createNewVersion && !props.selectedDataset && <CustomInput
              labelText={languageContext.dictionary['dataformatName']}
              id="dataformat_name_id"
              formControlProps={{
                fullWidth: false,
                style: { width: '60%' }
              }}

              inputProps={{
                type: "text",
                onChange: onChangeName,
                value: props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? props.selectedDataset.name : selectedFormatName,
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faSignature} style={{ fontSize: 20 }} />

                  </InputAdornment>
                )
              }}
              error={!validateInputs({ name: selectedFormatName }, "name").rule}
              helperText={!validateInputs({ name: selectedFormatName }, "name").rule ? validateInputs({ name: selectedFormatName }, "name").description : ""}
            />}
            {error && error.selectedFormatName && !props.createNewVersion && !props.selectedDataset &&
              <div className="ui error message" style={theme.errors.errorText}>
                <div className="header" >{error.selectedFormatName}</div>
              </div>}
            {/* --------------------------------locked input for versions----------------------------------------- */}
            {props.createNewVersion && props.createNewVersion === true && props.selectedDataset && <CustomInput
              labelText={languageContext.dictionary['dataformatName']}
              id="dataformat_name_id"
              formControlProps={{
                fullWidth: false,
                style: { width: '60%' }
              }}

              inputProps={{
                type: "text",
                onChange: onChangeName,
                value: props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? props.selectedDataset.name : selectedFormatName,
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faSignature} style={{ fontSize: 20 }} />

                  </InputAdornment>
                )
              }}
            />}
            {/* -------------------------------- end locked input for versions----------------------------------------- */}

          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkAutomaticSeparator}
                  onChange={event => {
                    setCheckAutomaticSeparator(event.target.checked);
                    setSeparator('automatic')
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<Typography variant='body2'> Automatic Separator Estimation</Typography>}
            />
          </Grid>

          {!checkAutomaticSeparator &&
            <Grid item xs={12}>
              <CustomInput
                labelText={languageContext.dictionary['separator']}
                id="separator_id"
                formControlProps={{
                  fullWidth: false,
                  style: { width: '30%' }
                }}

                inputProps={{
                  type: "text",
                  onChange: onChangeSeparator,
                  value: separator,
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faCode} style={{ fontSize: 20 }} />
                    </InputAdornment>
                  )
                }}
              />
              {error && error.separator &&
                <div className="ui error message" style={{ width: '300px', fontSize: '10px' }}>
                  <div className="header" >{error.separator}</div>
                </div>}
            </Grid>
          }


          <Grid item xs={12}>
            <div style={{ marginTop: '5%' }}>
              <InputFile onUpload={onUpload} uploadInBack={true} folder={`dataformatTmp/${user.id}`} userId={user.id}
                storageKey={selectedEnvironment ? qualityConfiguration.environmentConfigurationList.filter(x=>x.environmentName === selectedEnvironment)[0].storageKey : ""}
                storageAccountName={selectedEnvironment ? qualityConfiguration.environmentConfigurationList.filter(x=>x.environmentName === selectedEnvironment)[0].storageAccountName : 'sainputdemoquality'}
                containerName={selectedEnvironment? qualityConfiguration.environmentConfigurationList.filter(x=>x.environmentName === selectedEnvironment)[0].containerName : 'unit-test'}
                sasToken={selectedEnvironment ? qualityConfiguration.environmentConfigurationList.filter(x=>x.environmentName === selectedEnvironment)[0].sasToken : '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'}
                buttonText={'Upload File'} />
            </div>


          </Grid>
          {((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true')) && !props.createNewVersion && !props.selectedDataset &&
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <div >
                {error && error.folder &&
                  <div className="ui error message" style={theme.errors.errorText}>
                    <div className="header" >{error.folder}</div>
                  </div>} </div>
              <div style={{ marginBottom: '20px' }}>
                <Typography variant='caption' >{languageContext.dictionary['dataRepositoryFolderSelection']}</Typography>
              </div>
              <DataRepositoryTreeTable
                actions={actionsByType.datasets}
                enableContextMenu={false}
                showTitle={false}
                type="private"
                dtoData={entityDataRepo}
                defaultFolderId={defaultFolderId}
                treeData={convertDataHelper.parseEntityDataRepositoryToTree(
                  entityDataRepo
                )}
                iconTitle={faUserLock}
                title={`Select Folder in Private Workspace`}
                titleStyle={{ color: "#45b6fe", margin: 0 }}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                setShowDialogs={{ export: undefined, new: undefined }}
                showDialogs={{ new: undefined }}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
                user={user}
              />
            </Grid>
          }
        </Grid>
        <Grid item xs={6}>
          <TablePrevisualization
            columnFormats={colFormats}
            setColumnFormats={setColFormatsCallback}
          />
        </Grid>
      </Grid>
    </AqtivaDialog>

  )

}