import React from 'react'

export const sourceTechs = [
    'azure-storage',
    'azure-sqldatabase',
    'azure-sqlsynapse',
    'azure-cosmos',
    'awsS3',
    'awsHive',
    "hive",
    'awsRedshift',
    "bigQuery",
    'sqlServer',
    'oracle',
    'mySQL'
]

export function getLabelFromSource(source){
    if(source ==='azure-storage'){
       return 'Azure Storage'
    }
    else  if(source ==='azure-sqldatabase'){
        return 'Azure SQL Database'
    }
    else  if(source ==='azure-sqlsynapse'){
        return 'Azure Synapse'
    }
    else  if(source ==='azure-cosmos'){
        return 'Azure Cosmos'
    }
    else  if(source ==='awsS3'){
        return 'AWS S3'
    }
    else  if(source ==='awsHive' || source ==='hive'){
        return 'Hive'
    }
    else  if(source ==='bigQuery'){
        return 'Big Query'
    }
    else  if(source ==='sqlServer'){
        return 'SQL Server'
    }
    else  if(source ==='oracle'){
        return 'Oracle Database'
    }
    else  if(source ==='mySQL'){
        return 'MySQL'
    }
    else  if(source ==='awsRedshift'){
        return 'awsRedshift'
    }
    else  {
        return 'Local File'
    }
}

export function getIconFromSource(source, size_){
    const size = (size_ === undefined) ? '15px': size_
    if(source ==='azure-storage'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require('./../resources/icons/azure/Storage-Accounts.png')} />)
    }
    else  if(source ==='azure-sqldatabase'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/azure/sql-database-generic.png')} />)
    }
    else  if(source ==='azure-sqlsynapse'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/azure/synapse.png')} />)
    }
    else  if(source ==='azure-cosmos'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/azure/cosmo_db.png')} />)
    }
    else  if(source ==='awsS3'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/aws/aws-s3-logo.png')} />)
    }
    else  if(source ==='awsHive' || source ==='hive'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/hive.png')} />)
    }
    else  if(source ==='bigQuery'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/bigQuery.png')} />)
    }
    else  if(source ==='sqlServer'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/azure/sql-database-generic.png')} />)
    }
    else  if(source ==='oracle'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( 'resources/icons/oracle.png')} />)
    }
    else  if(source ==='mySQL'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/mySQL.png')} />)
    }
    else  if(source ==='awsRedshift'){
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/redshift.png')} />)
    }
    else  {
        
        return( <img alt="" style={{ height: size, width: size }}
               src={require( './../resources/icons/azure/File.png')} />)
    }
}

