
//const baseUrl = 'http://62-210-202-96.rev.poneytelecom.eu'


export const baseUrlLogin = process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_LOGIN_HOST : process.env.REACT_APP_LOGIN_HOST;
export const baseUrlFront= process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST;
export const API_MS_URL = process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_BACK_HOST : process.env.REACT_APP_BACK_HOST;


export const ACCESS_TOKEN = 'current_token';
export const ACCESS_TOKEN_AZURE_USER = 'current_token_azure_user';

export const AUTHENTICATION = 'authentication';
export const USER='current_user'


export const OAUTH2_REDIRECT_URI = baseUrlFront+'oauth2/redirect'

export const GOOGLE_AUTH_URL = baseUrlLogin + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = baseUrlLogin + '/oauth2/authorize/azure?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = baseUrlLogin + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const AUTH0_AUTH_URL = baseUrlLogin + '/oauth2/authorize/auth0?redirect_uri=' + OAUTH2_REDIRECT_URI;
