import {  EventSourcePolyfill } from 'event-source-polyfill';


export function sseReceiver(url, onOpenCallback, onMessageCallback, onCloseCallback, continuous){
    let eventSource = undefined;
    eventSource = new EventSourcePolyfill(url,{
        headers: {
        'Authorization': 'my secret jwt token' // or localStorage.getItem("myToken")
        }}); 
        eventSource.onopen = (event) => onOpenCallback(event); 
        eventSource.onmessage = (event) => onMessageCallback(event)
        eventSource.onerror = (event) => {onCloseCallback(event)
            if(!continuous || continuous===false) eventSource.close();}
}