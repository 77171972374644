import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Typography, FormControl, Checkbox, TextField } from '@material-ui/core'
import AqtivaLoading from 'components/common/AqtivaLoading'

export default function DataformatDQIBar(props) {

    const [enableZeroDQI, setEnabledZeroDQI] = React.useState(false)
    const [numberDataformats, setNumberDataformats] = React.useState(10)

    const [selectedDF, setSelectedDF] = React.useState(undefined)
    

    //const [createdChar, setCreatedChar] = React.useState(undefined)

    const [data, setData] = React.useState([])

    function findDFByName(dfName){
        const  dfNameTruncated= dfName.replace('...','')
        
        for(var i=0;i<data.length;i++){
            if(data[i].subject.indexOf(dfNameTruncated)!==-1 ||
            dfNameTruncated.indexOf(data[i].subject)!==-1 ||
             data[i].subject === dfNameTruncated){
                //si es el que tenemos guardado, se quita
               
                    setSelectedDFAndUpdateParent(data[i])
               
                break;
                
            }
            
        }
    }

    function setSelectedDFAndUpdateParent(data){
        setSelectedDF(data)
        props.setSelectedDF(data)
    }

    React.useEffect(() => {
        var chart = am4core.create(props.fullscreen === true ? "chartdivDataformatBarFullscreen" : "chartdivDataformatBar", am4charts.XYChart);

        chart.padding(40, 40, 40, 40);

        var dataTmp = data
        if (enableZeroDQI === false) {
            dataTmp = dataTmp.filter(x => x.DQI > 0)
        }
        const dataTruncated = dataTmp.map(x => {
            return {
                subject: x.subject.length > 40 ?  x.subject.substring(0, 37)+"..."  : x.subject,
                DQI: x.DQI
            }
        }).slice(0, numberDataformats)


        chart.data = dataTruncated


        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "subject";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.fontSize = 10;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.renderer.labels.template.fontSize = 10;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "subject";
        series.dataFields.valueX = "DQI";
        series.tooltipText = "{valueX}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;

        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 10;
        labelBullet.label.text = "[font-size:12px]{values.valueX.workingValue.formatNumber('#.00as')}%";
        labelBullet.locationX = 1;
        labelBullet.label.fill=am4core.color("#FFFFFF");


        // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        series.columns.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });

        series.columns.template.togglable = true;

        var activeState = series.columns.template.states.create("active");
        activeState.properties.fill = am4core.color("#E94F37");

        

        series.columns.template.events.on("hit", function (event) {
            series.columns.each(function (column) {
                if(column===event.target){
                    if(column.isActive === false){
                        findDFByName(column._dataItem.categories.categoryY)
                    }
                    else{
                        setSelectedDFAndUpdateParent(undefined)
                    }
                   
                }
                if (column !== event.target) {
                    column.setState("default");
                    column.isActive = false;
                }
            })
        });

        categoryAxis.sortBySeries = series;

       
        series.columns.each(function (column) {
           
            const category1=column._dataItem.categories.categoryYreplace('...','')
            const category2= selectedDF.subject



            if(category2.indexOf(category1)!==-1 ||
            category1.replace('...','').indexOf(category2)!==-1 ||
            category2.subject === category1){
                column.setState("default");
                column.isActive = true;
            }
            })

            //TODO: VARIABLE NOT USED
            //setCreatedChar(chart)


       
    }, [data, enableZeroDQI, numberDataformats]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(()=>{
        const dataStr = JSON.stringify(data)
        const propsDataStr = JSON.stringify(props.data)
        if(dataStr!==propsDataStr){
            setData(props.data)
        }
    },[props.data, enableZeroDQI, numberDataformats])// eslint-disable-line react-hooks/exhaustive-deps

    if (props.loading === true) {
        return <AqtivaLoading />
    }
    else {
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ display: "flex", justifyContent: 'space-around', height: "10%" ,}}>
                <FormControl style={{ display: "flex", flexDirection: "row", alignItems:'center', marginRight: '20px' }}>
                    <Checkbox
                        checked={enableZeroDQI}
                        onChange={event => { setEnabledZeroDQI(event.target.checked) }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                    />
                    <Typography variant='caption'  >View Zero DQIs</Typography>

                </FormControl>
                <TextField
                    id="outlined-number"
                    label="Items to view"
                    type="number"
                    style={{ fontSize: '2px' }}
                    defaultValue={numberDataformats}
                    onChange={e => setNumberDataformats(e.target.value)}
                /></div>
            <div id={props.fullscreen === true ? "chartdivDataformatBarFullscreen" : "chartdivDataformatBar"} style={{ width: "100%", height: "80%" }}></div>
        </div>

    )
    }
}
