import React from 'react';
import {
     TextField, Typography, Grid, FormControlLabel
} from '@material-ui/core';

import AqtivaDialogEncryptString from 'components/common/AqtivaDialogEncryptString'




export default function MySqlConnectionInfo(props) {



    const [visibleEncrypt, setVisiblevisibleEncrypt] = React.useState(false)
    const [server, setServer] = React.useState('')
    const [user, setUser] = React.useState('')
    const [port, setPort] = React.useState('')
    const [schema, setSchema] = React.useState('')
    const [encryptedPassword, setEncryptedPassword] = React.useState('')
    //const [errors, setErrors] = React.useState(undefined)



    React.useEffect(() => {
        let connectionObject = {}
        connectionObject.server = server
        connectionObject.user = user
        connectionObject.port = port
        connectionObject.schema = schema
        connectionObject.pwd = encryptedPassword
        props.setConnectionObjectMySQL(connectionObject)
    }, [server, user, encryptedPassword])// eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>
            <div style={{ width: '100%' }}>
                <AqtivaDialogEncryptString visible={visibleEncrypt}
                    connectionMethod={props.connectionMethod}
                    setVisible={setVisiblevisibleEncrypt}
                    confirm={setEncryptedPassword}
                    sName={props.id && props.id + "localsttkk"}
                />

                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Display name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="DisplayName"
                                label="Display name"
                                margin="dense"
                                value={props.displayName}
                                onChange={(event) => { props.setDisplayName(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Server Name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Server"
                                label="Server name"
                                margin="dense"
                                value={server}
                                onChange={(event) => { setServer(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Port (optional):</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Port"
                                label="Port"
                                margin="dense"
                                value={port}
                                onChange={(event) => { setPort(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Schema name:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="Schema"
                                label="Schema name"
                                margin="dense"
                                value={schema}
                                onChange={(event) => { setSchema(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>User:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        control={
                            <TextField style={{ width: '100%' }}
                                readonly
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                autoComplete="new-password"
                                id="User"
                                label="User"
                                margin="dense"
                                value={user}
                                onChange={(event) => { setUser(event.target.value) }}
                            />} autoComplete="new-password" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>Password:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                        autoComplete="new-password"
                        control={
                            <TextField style={{ width: '100%' }}
                                autoComplete="new-password"
                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                id="Password"
                                label="Password"
                                margin="dense"
                                type="password"
                                value={encryptedPassword}
                                onClick={(event) => { setVisiblevisibleEncrypt(true) }}
                            />} />
                </Grid>
            </div>
        </>
    )
}