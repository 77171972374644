
import React from 'react';

import { Grid, Typography } from '@material-ui/core'

import { Accordion, AccordionDetails, AccordionSummary, Chip, Avatar, Tooltip } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';

import { faCog } from '@fortawesome/free-solid-svg-icons'
import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export default function ParameterConfiguration(props) {

    //const languageContext = React.useContext(LanguageContext); 
    const [newParameter, setNewParameter] = React.useState('')
    const [parameters, setParameters] = React.useState(props.parameters && props.parameters !== {} ? props.parameters : [])
    const [parentParameters, setParentParameters] = React.useState(props.parentParameters ? props.parentParameters : [])


    
    const theme = useTheme()

    React.useEffect(() => {
        if (props.parentParameters) {
            setParentParameters(props.parentParameters)
        }
        if (props.parameters) {
            setParameters(props.parameters)
        }
    }, [props.parentParameters, props.parameters])

    function addParameter() {
        if (validateTag(newParameter) === undefined) {


            var oldParameters = JSON.parse(JSON.stringify(parameters))
            var newParameterObj = {
                name: undefined,
                defaultValue: undefined
            }
            if (newParameter.split(":").length > 1) {
                newParameterObj.name = newParameter.split(":")[0]
                newParameterObj.defaultValue = newParameter.split(":")[1]
            }
            else {
                newParameterObj.name = newParameter
            }
            oldParameters.push(newParameterObj)
            setParameters(oldParameters)
            setNewParameter('')
            if (props.setParameters) {
                props.setParameters(oldParameters)
            }
        }
    }

    function keyPressAddElement(e) {
        if (e.keyCode == 13) {
            addParameter()
        }
    }

    function validateParameter() {
        if (newParameter.indexOf(' ') > -1) {
            return true
        }
        return false
    }

    function onlyLettersAndSpaces(str) {
        if ((Boolean(str?.match(/^[/-_A-Za-z0-9-:\s]*$/))) === true) {
            return undefined
        }
        return 'Parameter can only have number, letters and _'
    }

    function validateTagStructure(str) {
        const splitStr = str.split(":")
        if (splitStr.length !== 2) {
            return "Tag must have structure name:value"
        }
        else {
            return undefined
        }
    }


    function validateTag(str) {
        if (validateTagStructure(str) === undefined) {
            if (onlyLettersAndSpaces(str) === undefined) {
                return undefined;
            }
            else {
                return onlyLettersAndSpaces(str)
            }
        }
        else {
            return validateTagStructure(str)
        }


    }
    const handleDeleteParameter = (index) => {

        var newParameterList = JSON.parse(JSON.stringify(parameters))
        newParameterList.splice(index, 1)
        setParameters(newParameterList)
        if (props.setParameters) {
            props.setParameters(newParameterList)
        }
    };


    return (
        <Accordion
            style={{ width: '100%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={1}>
                    <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                </Grid>
                <Grid item xs={11}>
                    <Typography variant='subtitle2'>Parameter Configuration</Typography>



                </Grid>

            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: "flex", justifyContent: 'space-around' }}>
                    <TextField
                        style={{ fontSize: '2px', marginLeft: '10px' }}
                        id="date"
                        value={(newParameter)}
                        onKeyDown={keyPressAddElement}
                        onChange={e => setNewParameter(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={validateTag(newParameter) !== undefined}
                        helperText={validateTag(newParameter)}
                    />
                    <IconButton color="primary" aria-label="upload picture" component="span" style={{ marginTop: '-20px' }}
                        onClick={addParameter}>
                        <AddCircleOutlineIcon />
                    </IconButton>

                    <div style={{ display: "flex", justifyContent: 'space-around' }}>
                        {parentParameters && parentParameters.map((parameter, index) =>
                            <Chip
                                key = {index}
                                style={{ backgroundColor: commonStyles.lightGrey }}
                                avatar={<Tooltip title={`Default Value: ${parameter.defaultValue}`}><Avatar >@</Avatar></Tooltip>}
                                label={parameter.name}
                                variant="outlined"
                            />
                        )}
                        {parameters && parameters.length>0 &&  parameters.map((parameter, index) =>
                            <Chip

                                avatar={<Tooltip title={`Default Value: ${parameter.defaultValue}`}>
                                    <Avatar style={{ backgroundColor: commonStyles.mainColor, color: "#FFFFFF" }}>@</Avatar></Tooltip>}

                                label={parameter.name}
                                onDelete={event => handleDeleteParameter(index)}
                                variant="outlined"
                            />
                        )}
                    </div>
                </div>



            </AccordionDetails>
        </Accordion>
    )
}