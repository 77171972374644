import React from "react";
import CardComponent from "./CardComponent";
import AqtivaLoading from "components/common/AqtivaLoading";


export default function CardsComponent(props) {

    const [projects, setProjects] = React.useState([])


    React.useEffect(() => {

        if (props.projects && props.projects.length > 0) {
            setProjects(props.projects)
        }
    }, [props.projects])

    function handleDrillDownElement(e){
        props.click(e)
    }

    return (
        <div style={{
            height: '100%', display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap", marginLeft: '10px', marginRight: '10px', marginTop: '20px'
        }}>
            {props.loading === false &&
            props.projects && props.projects.length > 0 &&
                projects.map((project, index) => {
                    return <CardComponent data={project} filterObj={props.filterObj} index={index} click={handleDrillDownElement} />
                })
            }
            {props.loading !== false && <AqtivaLoading />}
        </div>
    )
}