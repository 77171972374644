import React from 'react';
import TextField from '@material-ui/core/TextField';
import AqtivaDialog2SaveButton from 'components/common/AqtivaDialog2SaveButton';



export default function DialogSetUserPwd(props) {


    const [user, setUser] = React.useState('')
    const [pwd, setPwd] = React.useState('')

    

    

    return (
        <AqtivaDialog2SaveButton visible={props.visible}
            maxWidth={'lg'}
            title={'Set user and Password'}
            iconSourceFlag={true}
            iconSource = {require('resources/icons/data_proc1_white.png')}
            cancelText={'Close'}
            confirmText={'Store and Use'}
            confirmTextSecond={'Use One Time'}
            showConfirmButton={(user !=='' && pwd!=='' )? true : false}
            showConfirmButtonSecond={(user !=='' && pwd!=='' )? true : false}
            cancelCallback={() => { props.setVisible(false) }}
            confirmCallback={() => { props.storeUserPwd(user,pwd) ; props.setVisible(false)}}
            confirmCallbackSecond={() => { props.setUserPwd(user,pwd) ; props.setVisible(false)}}
        >

            <div style={{idth:'100%'}}>
                <div style={{idth:'100%'}}>
                    <TextField   style={{idth:'100%'}} label="User" variant="filled" onChange={event => setUser(event.target.value)}/>
                </div>
                <div>
                    <TextField  label="Password" type="password" variant="filled"  onChange={event => setPwd(event.target.value)}
                    inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}/>
                </div>
            
            
            </div>


        </AqtivaDialog2SaveButton>
    )
}