import React from 'react';
import { Grid, Divider, Button, Switch, Typography, Checkbox } from '@material-ui/core'
import AddProjectItemButton from './AddProjectItemButton'
import SimulateButton from './SimulateButton'
import VersionsButton from './VersionsButton'
import DeployButtonMenu from './DeployButtonMenu'
import DeployExternalButtonMenu from './DeployExternalButtonMenu'
import SearchButton from './SearchButton'
import DeployAuditComponent from './DeployAuditComponent'
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecordVinyl, faFileExport } from '@fortawesome/free-solid-svg-icons'
import trackEvent from '../../../../../trackEvent'

import DeployButtonLambda from './DeployButtonLambda'
import * as helper from './detailProjectToolBarHelper'

import * as projectHelper from '../../../../common/projectHelper'
import { useSelector, useDispatch } from 'react-redux'

import * as actions from '../../../../../actions'
import CommitButton from './CommitButton';


export default function QualityDetailProjectToolBar(props) {

    const dispatch = useDispatch();


    const theme = useTheme();
    const license = useSelector(store => store.license)

    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [deploymentAudit, setDeploymentAudit] = React.useState(undefined)
    //const [visibleAnjanaId, setVisibleAnjanaId] = React.useState(false)


    React.useEffect(() => {
        if (license.apiKey && selectedEnvironment !== undefined && selectedEnvironment !== "No Environment" && props.project._id !== undefined) {
            dispatch(actions.getDeploymentForProjectAndEnvironment(license.apiKey, props.project._id, selectedEnvironment)).then(response => {
                if (response.data && response.data.length > 0) {
                    setDeploymentAudit(true)
                }
                else {
                    setDeploymentAudit(false)
                }
            }
            )
        }

    }, [props.project._id, selectedEnvironment])// eslint-disable-line react-hooks/exhaustive-deps

    function setSelectedEnvironmentAndParent(value) {
        setSelectedEnvironment(value)
        if (props.setSelectedEnvironment) {
            props.setSelectedEnvironment(value)
        }
    }

    // if anjana flag would be set to true, we open a dialog to insert anjanaId, otherwise we change it to false directly
    function handleAnjanaSwitch() {
        if (process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true'
         && props.project && props.project._id && props.project.name) {
            props.setAnjanaIntegrable(!props.anjanaIntegrable)
            dispatch(actions.editAnjanaFlagProject(props.project._id, props.user.id, !props.anjanaIntegrable, props.project.name))

        }
    }


    return (
        <div>
            {/*  {process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && <DialogInsertAnjanaId visible={visibleAnjanaId} setVisibleAnjanaId={setVisibleAnjanaId} dialog={visibleAnjanaId} setAnjanaId={props.setAnjanaId} anjanaId={props.anjanaId} setAnjanaIntegrable={props.setAnjanaIntegrable} project={props.project} user={props.user} element={"project"} />} */}
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <div style={theme.elementAdjacent}>
                        <div style={{ minWidth: '7vw' }}>
                            <AddProjectItemButton openWIDialog={props.openWIDialog} />
                        </div>


                        <div style={{ minWidth: '7vw' }}>
                            <SimulateButton project={props.project}
                                user={props.user}
                                history={props.history}
                                visible={projectHelper.isProjectSimulable(props.project) === true}
                                qualityConfiguration={props.qualityConfiguration}
                            />
                        </div>

                        {process.env.REACT_APP_RULE_RECOMMENDER_ALLOWED === 'true' &&
                            <div style={{ minWidth: '10vw' }}>
                                <Button onClick={event => {
                                    if (projectHelper.isProjectRecommendable(props.project) === true) {
                                        trackEvent('DetailProjectToolbar', 'Open Rule Recommender Dialog');
                                        props.openRuleRecommenderDialog(true)
                                    }
                                }}
                                    style={projectHelper.isProjectRecommendable(props.project) === true ? theme.buttons.buttonRegular : theme.buttons.buttonRegularDisabled}
                                    startIcon={<FontAwesomeIcon icon={faRecordVinyl} style={{ fontSize: 15 }} />}
                                >
                                    <div style={theme.common.text.buttonTextSmall}>
                                        Rule Recommender
                                    </div>
                                </Button>
                            </div>}
                        {!helper.isStreamProject(props.project) &&
                            <div style={{ minWidth: '7vw' }}>
                                <DeployButtonMenu qualityConfiguration={props.qualityConfiguration}
                                    project={props.project}
                                    visible={projectHelper.isProjectExportable(props.project)}
                                    setSelectedEnvironment={setSelectedEnvironmentAndParent} />
                            </div>
                        }

                        {process.env.REACT_APP_DEPLOY_EXTERNAL && process.env.REACT_APP_DEPLOY_EXTERNAL === 'true' && !helper.isStreamProject(props.project) &&

                            <div style={{ minWidth: '12vw' }}>
                                <DeployExternalButtonMenu qualityConfiguration={props.qualityConfiguration}
                                    visible={projectHelper.isProjectExportable(props.project)}
                                    project={props.project} />
                            </div>
                        }

                        {(process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' || process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true') &&
                            <div style={{ minWidth: '10vw' }}>
                                <Button onClick={event => {
                                    if (projectHelper.isProjectExportable(props.project) === true) {
                                        props.onClickExport(event);
                                        trackEvent('DetailProject', 'Export Project Button')
                                    }
                                }}
                                    style={projectHelper.isProjectExportable(props.project) === true ? theme.buttons.buttonRegular : theme.buttons.buttonRegularDisabled}
                                    startIcon={<FontAwesomeIcon icon={faFileExport}
                                        style={{ fontSize: 15 }}

                                    />}
                                >
                                    <div style={theme.common.text.buttonTextSmall}>
                                        Export Project
                                    </div>


                                </Button>
                            </div>
                        }
                        {process.env.REACT_APP_COMMIT && process.env.REACT_APP_COMMIT === 'true' &&
                            <div style={{ width: '10vw' }}>
                                <VersionsButton project={props.project} user={props.user} license={license} />
                            </div>
                        }
                        {process.env.REACT_APP_COMMIT && process.env.REACT_APP_COMMIT === 'true' &&
                            <div style={{ width: '10vw' }}>
                                <CommitButton project={props.project} user={props.user} />
                            </div>
                        }

                        <div style={{ ...theme.elementAdjacent, minWidth: '10vw', }}>

                            <Switch
                                checked={props.tableByRulesOrColumns}
                                color='primary'
                                onClick={e => {
                                    props.setTableByRulesOrColumns(!props.tableByRulesOrColumns)
                                }}
                            />
                            <Typography variant='subtitle2' style={{ ...theme.common.text.regularText, marginTop: '8px' }}>
                                {props.tableByRulesOrColumns === true ? 'View by columns' : 'View by rules'}
                            </Typography>
                        </div>
                        {process.env.REACT_APP_ANJANA_INTEGRATION && process.env.REACT_APP_ANJANA_INTEGRATION === 'true' && 
                        <div style={{ ...theme.elementAdjacent, minWidth: '10vw', }}>
                            <Checkbox
                                checked={props.anjanaIntegrable ? props.anjanaIntegrable : false}
                                color='primary'
                                onClick={e => {
                                    handleAnjanaSwitch()
                                }}
                            />
                            <Typography variant='subtitle2' style={{ ...theme.common.text.regularText, marginTop: '8px' }}>
                                Anjana Integrable
                            </Typography>
                        </div>}
                    </div>
                </Grid>
                <Grid item xs={10} style={{ display: "flex", flexDirection: "row" }}>

                    <div style={theme.elementAdjacent}>
                        {helper.isStreamProject(props.project) &&
                            <div style={{ width: '350px' }}>
                                <DeployButtonLambda qualityConfiguration={props.qualityConfiguration}
                                    project={props.project}
                                    visible={projectHelper.isProjectExportable(props.project)}
                                    setSelectedEnvironment={setSelectedEnvironmentAndParent} />
                            </div>
                        }




                        {projectHelper.isProjectExportable(props.project) === true &&
                            <div style={{ width: '400px', marginTop: '10px' }}>
                                <DeployAuditComponent project={props.project} environment={selectedEnvironment}
                                    deploymentAudit={deploymentAudit} />
                            </div>
                        }
                    </div>

                </Grid>


                <Grid item xs={2}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <SearchButton updateText={props.updateText} />

                    </div>

                </Grid>


            </Grid>
            <Divider />
        </div>
    )
}