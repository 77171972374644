
import React  from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
    InputLabel, Typography, Select, MenuItem, FormControl, 
} from '@material-ui/core'

export default function SelectEnvironmentComponentSchedule(props) {



    const theme = useTheme()

    return (

        <FormControl style={{ width: '150px' }}>
            <InputLabel id="selectResourceGroup">
                <Typography variant="caption">Select an Environment</Typography>
            </InputLabel>
            {props.selectedEnvironment && props.currentEnvironmentEdit && <Select
                value={props.selectedEnvironment}
                onChange={event => {
                    props.setSelectedEnvironment(event.target.value);
                    props.setSelectedOutputPath(undefined)
                    props.setNoEnvironmentError(undefined)
                }}
                inputProps={{
                    name: 'Environment',
                    id: 'Environment',
                }}
                style={{ width: '150px' }}
            >
                {props.currentEnvironmentEdit && <MenuItem key={props.currentEnvironmentEdit} value={props.currentEnvironmentEdit}>
                    <div style={theme.elementAdjacent}>
                        <Typography variant='subtitle2'>{props.currentEnvironmentEdit}</Typography>
                    </div>
                </MenuItem>}
                {props.environmentOptions && props.environmentOptions.length > 0 && props.environmentOptions.map(element => {
                    return (
                        <MenuItem key={element.environmentName} value={element.environmentName}>
                            <div style={theme.elementAdjacent}>
                                <Typography variant='subtitle2'>{element.environmentName}</Typography>
                            </div>
                        </MenuItem>
                    )
                })}
            </Select>}
            {!props.currentEnvironmentEdit && <Select
                value={props.selectedEnvironment}
                onChange={event => {
                    props.setSelectedEnvironment(event.target.value);
                    props.setNoEnvironmentError(undefined)
                }}
                inputProps={{
                    name: 'Environment',
                    id: 'Environment',
                }}
                style={{ width: '150px' }}
            >
                {props.environmentOptions && props.environmentOptions.length > 0 && props.environmentOptions.map(element => {
                    return (
                        <MenuItem key={element.environmentName} value={element.environmentName}>
                            <div style={theme.elementAdjacent}>
                                <Typography variant='subtitle2'>{element.environmentName}</Typography>
                            </div>
                        </MenuItem>
                    )
                })}
            </Select>}
        </FormControl>
    )
}