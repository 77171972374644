import { IconButton, makeStyles, Popover, Typography } from "@material-ui/core";
import AqtivaDialog from "components/common/AqtivaDialog";
import React from "react";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(theme => ({
    popoverRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default function QualityDatasetRepository(props) {
    
    const classes = useStyles();
    const [openPop, setOpenPop] = React.useState(false)

    const handleClose = () => {
        setOpenPop(false)
    };


    return (
        <AqtivaDialog visible={props.visible}
            maxWidth={'md'}
            fullWidth={false}
            title={'Show ID Dataset'}
            iconSourceFlag={true}
            iconSource={require('resources/icons/data_proc1_white.png')}
            cancelText={'Close'}
            showConfirmButton={false}
            cancelCallback={() => { props.setVisible(false) }}
        >

            <Typography>
                {`ID Datasource: ${props.idDataset}`}

                <IconButton size='small'
                    onClick={() => {
                        navigator.clipboard.writeText(props.idDataset)
                        setOpenPop(true)

                        setTimeout(() => {
                            setOpenPop(false)
                        }, 2000)
                    }}>
                    <span style={{ display: 'inline-block', position: 'relative' }}>
                        <FileCopyIcon textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: '.9em' }} />

                    </span>
                </IconButton>
            </Typography>

            <Popover
                id={'test'}
                open={openPop}
                onClose={handleClose}
                anchorReference={"none"}
                classes={{
                    root: classes.popoverRoot,
                }}
            >
                <Typography>Id copied to clipboard.</Typography>

            </Popover>


        </AqtivaDialog >
    )
}