
import React from 'react';

import { Typography,Card,CardContent,TextField,FormControl,InputLabel} from '@material-ui/core'
    
import NativeSelect from '@material-ui/core/NativeSelect';
    
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { LanguageContext } from 'language/LanguageContext'

const useStyles = makeStyles((theme) => ({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
          width:'100%'
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
        resize:{
            fontSize:14
          }
      }));

export default function ThresoldCard(props) {

    const languageContext = React.useContext(LanguageContext); 

    const theme = useTheme()

 
    const classes = useStyles();

    const title = props.title

    const thTypes = [
        { label: 'Number', value: 'number' },
        { label: 'Percent', value: 'percent' }
    ];

    const thAction= [
        { label: 'Interrupt', value: 'interrupt_action' },
        { label: 'Continue', value: 'continue_action' }
    ];

    const [type,setType] = React.useState(props.obj ? props.obj.type : 'number')
    const [value,setValue] =React.useState(props.obj ? props.obj.value : 1 )
    const [action,setAction] = React.useState(props.obj ? props.obj.action : 'continue_action')

    React.useEffect(() => {
        setType(props.obj ? props.obj.type : 'number')
        setValue(props.obj ? props.obj.value : 1 )
        setAction(props.obj ? props.obj.action : 'continue_action')
      }, [props]);

  

    const handleTypeChange = (event) => {setType(event.target.value); };
    const handleValueChange = (event) => {setValue(event.target.value); };
    const handleActionChange = (event) => {setAction(event.target.value); };

    const updateTh = () =>{
        const th = {
            type: type,
            value: value,
            action: action
        }
        props.setTh(th)
    }

    

    React.useEffect(() => {
        updateTh()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        updateTh()
    }, [type,value,action]);// eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Card elevation={12}
        style={{minHeight:'265px'}}>
                                    <CardContent>
                                        <Typography variant='subtitle2'> {title}</Typography>
                                    </CardContent>
                                    <CardContent style={{marginRight:'5%'}}>
                                    <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="typeWarnTh-selection-helper">{languageContext.dictionary['thType']}</InputLabel>
                                        {(!props.obj || !props.obj.type) && 
                                                 <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >{languageContext.dictionary['thTypeMustBeSet']}</div>
                                                </div>
                                        }
                                        <NativeSelect 
                                        style={{
                                            width:'100%',
                                            fontSize: 14,
                                        }}
                                        value={type}
                                        onChange={event => handleTypeChange(event)}
                                        >
                                            {thTypes.map((event) => (<option key={event.value} value={event.value}>
                                               {event.label}
                                            </option>))}
                                        </NativeSelect>
                                    </FormControl>

                                    <TextField type="number" 
                                    label={languageContext.dictionary['thValue']}
                                    style={{marginLeft:'3%',width:'100%'}}
                                    value={value}
                                    onChange={event=>handleValueChange(event)}
                                    InputProps={{
                                        classes: {
                                          input: classes.resize,
                                        },
                                      }}
                                    autoComplete='off'
                                        id='thErrorValue' 
                                        />
                                     {(!props.obj || !props.obj.value) && 
                                                 <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >{languageContext.dictionary['thValueMustBeSet']}</div>
                                                </div>
                                        }
                                    <FormControl className={classes.formControl}>
                                     <InputLabel htmlFor="typeWarnTh-action-helper">{languageContext.dictionary['thAction']}</InputLabel>
                                     {(!props.obj || !props.obj.action) && 
                                                 <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >{languageContext.dictionary['thActionMustBeSet']}</div>
                                                </div>
                                        }
                                        <NativeSelect
                                        style={{
                                            width:'100%',
                                            fontSize: 14,
                                        }}
                                        value={action}
                                        onChange={event => handleActionChange(event)}
                                        >
                                          {thAction.map((event) => (<option key={event.value} value={event.value}>
                                               {event.label}
                                            </option>))}
                                        </NativeSelect>
                                    </FormControl>
                                   
                                    </CardContent>
                                </Card>
    )
}