
import React from 'react';
import { Typography,Box,Tooltip} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle,faChartLine } from '@fortawesome/free-solid-svg-icons'

import * as commonStyles from 'style/CommonStyles'

export default function DiagramTitle(props) {

    return(
        <Box
        
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  borderRadius={2}
  style={{backgroundColor:commonStyles.mainColor, paddingLeft:'2%', paddingRight:'2%'}}
>
  <Tooltip title={props.tooltip ? props.tooltip : props.title}>
    <div>
<FontAwesomeIcon icon={faInfoCircle} style={{cursor: "pointer",color:'white'}} />
</div>
</Tooltip>
  <Typography variant='subtitle1' style={{color:'#FFFFFF'}}>{props.title} </Typography>
  {props.fullscreenAllowed === true && 
  <FontAwesomeIcon icon={faChartLine} style={{cursor: "pointer",color:'white'}} 
  onClick = {props.onClickModal}
  />
}
{props.fullscreenAllowed!==true &&
  <div>

  </div>
}
</Box>
    )
}