import React from 'react';
//import AppBarTemplate from 'components/template/AppBarTemplate'


import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'actions'
import QualityDatasetRepository from 'components/data/QualityDatasetRepository';
import CustomTabs from 'components/common/CustomTabs/CustomTabs'

import Storage from "@material-ui/icons/Storage";
import BarChart from "@material-ui/icons/BarChart";



import { useHistory } from 'react-router-dom'


import DataProfilingForm from 'components/profiling/DataProfilingForm'


export default function QualityDatasetRepositoryPage(props) {
  const dispatch = useDispatch()
  const license = useSelector((store) => store.license);
  const user = useSelector(store => store.user)
  const qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))
  const history = useHistory();


  const [usedDatasourcesByProjects, setUsedDatasourcesByProjects] = React.useState([]);
  const [licenseId, setLicenseId] = React.useState(undefined);
  const [userId, setUserId] = React.useState(undefined);
  const tabSelected = 0

  

  React.useEffect(() => {
    if (licenseId && userId) {
      ExecutefetchUsedDatasourcesByProject(licenseId, userId)
    }
  }, [userId, licenseId]);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {

    setLicenseId(license._id)
    setUserId(user.id)
  }, [props.match, user, license]);


  function ExecutefetchUsedDatasourcesByProject(licenseId, userId) {
    dispatch(actions.fetchUsedDatasourcesByProject(licenseId, userId)).then(response => {
      setUsedDatasourcesByProjects(response.data)
    })
  }

  function renderTabs() {

    return [
      {
        tabName: "Dataset Repository",
        tabIcon: Storage,
        tabContent: (
          <QualityDatasetRepository history={history}
            licenseUsers={license.users}
            usedDatasourcesByProjects={usedDatasourcesByProjects}
            configuration={qualityConfigurationSelector}
            userId={userId}
            handleSelectDatasetForExportationId={props.handleSelectDatasetForExportationId} 
            notificationDatasetId = {props.match && props.match.params && props.match.params.dataformatId ? props.match.params.dataformatId : undefined}/>
        )
      },

      {
        tabName: "Data Profiling",
        tabIcon: BarChart,
        tabContent: (
          <DataProfilingForm props ={props} />
          )
      }
      
    ]

  }




  return (
      <div>
        {props.selectDatasetDialog &&
          <QualityDatasetRepository history={history}
            selectDatasetDialog={props.selectDatasetDialog}
            licenseUsers={license.users}
            usedDatasourcesByProjects={usedDatasourcesByProjects}
            configuration={qualityConfigurationSelector}
            user={user}
            handleSelectDatasetForExportationId={props.handleSelectDatasetForExportationId} />}
        {!props.selectDatasetDialog &&
          <CustomTabs
            headerColor={"primary"}
            value={tabSelected}
            tabs={renderTabs()} />

        }
      </div>



  );
}