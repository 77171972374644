import { IQualityItemParametrizable, IQualityParameter } from "interfaces/Parameters";


export function getParametersForDS(
   
    qualityPoint: IQualityItemParametrizable,
    datasource: IQualityItemParametrizable,
    rules: IQualityItemParametrizable[]){


        const ruleParameters: IQualityParameter[] = rules && rules.length>0 ? getDistinctParameters(rules.flatMap(r=>r.parameters).filter(param=>param!==null && param!==undefined)) : []
        const dsParameters: IQualityParameter[] = datasource.parameters ? datasource.parameters : []
        const qpParameters: IQualityParameter[] = qualityPoint.parameters ? qualityPoint.parameters : []
        for(var i =0;i< dsParameters.length; i++){
            const dsParameter : IQualityParameter = dsParameters[i]
            if(containsParameter(ruleParameters, dsParameter)===false){
                ruleParameters.push(dsParameter)
            }
            
        }
        for(var i =0;i< qpParameters.length; i++){
            const qpParameter : IQualityParameter = qpParameters[i]
            if(containsParameter(ruleParameters, qpParameter)===false){
                ruleParameters.push(qpParameter)
            }
            
        }
        return ruleParameters
    }



    
function containsParameter(list : IQualityParameter[], value: IQualityParameter){
    const listName = list.filter(param=>param!==null && param!==undefined).map(param=> param.name)
    if(listName.includes(value.name)){
        return true
    }
    return false
}

function getDistinctParameters(list : IQualityParameter[]){
    var distinctList: IQualityParameter[] = []
    for(var i=0;i<list.length;i++){
        if(!distinctList.map(x=>x.name).includes(list[i].name)){
            distinctList.push(list[i])
        }
    }
    return distinctList;
}