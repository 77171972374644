import React from 'react'
import * as commonStyles from 'style/CommonStyles'

import * as actions from 'actions'

import { useDispatch, useSelector } from 'react-redux'

import Graph from "react-graph-vis";
import CloudIcon from "./graph/cloudFull.png";
import CloudIconGrey from "./graph/cloudFullGrey.png";
import DatabaseIcon from "./graph/database.png";

import { useHistory } from 'react-router-dom'


export default function DataformatUsedInProjectTrace(props) {

    const dispatch = useDispatch()
    const history = useHistory(); 


    const license = useSelector((store) => store.license);
    const user = useSelector(store => store.user)

    const [graph, setGraph] = React.useState(null);
    const [usedDatasourcesByProjects, setUsedDatasourcesByProjects] = React.useState([]);
    const [projectsUsedDetail, setProjectsUsedDetail] = React.useState(undefined)

    function ExecutefetchUsedDatasourcesByProject(licenseId, userId) {
        dispatch(actions.fetchUsedDatasourcesByProject(licenseId, userId)).then(response => {
          setUsedDatasourcesByProjects(response.data)
        })
      }
  
      React.useEffect(() => {
        if (license._id && user.id) {
          ExecutefetchUsedDatasourcesByProject(license._id, user.id)
        }
      }, [user, license]);// eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(()=>{
        if(usedDatasourcesByProjects){
            setProjectsUsedDetail(splitUsedProjectsIntoOwnAndOthers(usedDatasourcesByProjects, props.dataformat, user.id))
        }
         

        
      }, [usedDatasourcesByProjects])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (props.dataformat && projectsUsedDetail && projectsUsedDetail.length > 0) {
        setGraph(
          parseProjectsUsedDetailToGraph(projectsUsedDetail, props.dataformat)
        )
      }
    }, [projectsUsedDetail, props.dataformat]);// eslint-disable-line react-hooks/exhaustive-deps

 function splitUsedProjectsIntoOwnAndOthers(usedDatasourcesByProjects, dataformat, userId) {
        let projectsUsedDetail = []
        for (let i = 0; i < usedDatasourcesByProjects.length; i++) {
            for (let j = 0; j < usedDatasourcesByProjects[i].dataformatsUsed.length; j++) {
                if (dataformat && usedDatasourcesByProjects[i].dataformatsUsed[j] === dataformat._id) {
                    if (usedDatasourcesByProjects[i].userOwnProject || checkIfSharedProject(userId, usedDatasourcesByProjects[i].allowedProjectUsers)) {
                        let project = {
                            projectId: usedDatasourcesByProjects[i].projectId,
                            projectName: usedDatasourcesByProjects[i].projectName,
                            userId: usedDatasourcesByProjects[i].userId,
                            ownProject: true,
                        }
                        projectsUsedDetail.push(project)
                    }
                    else {
                        let project = {
                            projectId: usedDatasourcesByProjects[i].projectId,
                            projectName: usedDatasourcesByProjects[i].projectName,
                            userId: usedDatasourcesByProjects[i].userId,
                            ownProject: false
                        }
                        projectsUsedDetail.push(project)
                    }
                }
            }
        }
        return projectsUsedDetail
    }
    
     function checkIfSharedProject(userId, allowedUsers) {
        if (userId && allowedUsers && allowedUsers.length > 0) {
            return allowedUsers.includes(userId)
        }
    }
  
    function findAuthorNameFromId(license, userId) {
      if (license && license.users && license.users.length > 0 && userId) {
        let userFound = license.users.filter((x) => x.id === userId);
  
        if (userFound && userFound.length > 0) {
          return (userFound[0].name.length > 18 ? userFound[0].name.substr(0, 15) + "..." : userFound[0].name);
        }
      }
      return "";
    }
  
    function printNodeData(entity, user, type) {
      return [
        "<b>Project:</b> <i>" + entity + "</i>",
        "<b>User:</b> <i>" + findAuthorNameFromId(license, user) + "</i>",
        "<b>Type:</b> <i>" + type + "</i>",
      ].join("\n")
    }
    function createNodes(projectsUsedDetail, dataformat) {
      let nodes = []
      let dataformatNode = {
        id: dataformat._id,
        font: { multi: "html", size: "12" },
        shape: "image",
        image: DatabaseIcon,
        margin: 500,
        size: 25,
        label: printNodeData(dataformat.name, dataformat.userId, "dataformat"),
        color: commonStyles.mainColor,
        level: 0,
        fixed: true,
      }
      nodes.push(dataformatNode)
      for (let i = 0; i < projectsUsedDetail.length; i++) {
        let projectNode = {
          id: projectsUsedDetail[i].projectId + "-" + projectsUsedDetail[i].ownProject,
          font: { multi: "html", size: "12" },
          shape: "image",
          image: projectsUsedDetail[i].ownProject ? CloudIcon : CloudIconGrey,
          margin: 1000,
          size: 25,
          label: printNodeData(projectsUsedDetail[i].projectName, projectsUsedDetail[i].userId, "project"),
          color: commonStyles.mainColor,
          level: 2,
          fixed: true,
        }
        nodes.push(projectNode)
      }
      return nodes
    }
  
    function createEdges(projectsUsedDetail, dataformat) {
      let edges = []
      for (let i = 0; i < projectsUsedDetail.length; i++) {
        let projectEdge = {
          from: dataformat._id,
          to: projectsUsedDetail[i].projectId + "-" + projectsUsedDetail[i].ownProject,
          length: 200,
          color: "#EF8354",
          width: 1,
          label: "<b>used in</b>",
          font: { align: "horizontal", multi: "html", color: '#474b51', size: "12" }
        }
        edges.push(projectEdge)
      }
      return edges
    }
  
    const seen = new Set();
  
  
    function parseProjectsUsedDetailToGraph(projectsUsedDetail, dataformat) {
      if (projectsUsedDetail === undefined || !projectsUsedDetail.length > 0) {
        props.onClose()
      } else {
        const filteredProjects = projectsUsedDetail.filter(project => {
          const duplicate = seen.has(project.projectId);
          seen.add(project.projectId);
          return !duplicate;
        });
        const graph = {
          nodes: createNodes(filteredProjects, dataformat),
          edges: createEdges(filteredProjects, dataformat)
        }
        return graph
  
      }
    }
    const events = {
      select: function (event) {
        var { nodes } = event;
        if (props.dataformat._id !== nodes[nodes.length - 1]) {
          const projectId = nodes[nodes.length - 1].split("-")[0]
          const allowed = nodes[nodes.length - 1].split("-")[1]
          if (allowed === "true") {
            history.push(`/${process.env.REACT_APP_PREFIX}/project/${projectId}`)
          }
        }
      }
    };
    const options = {
      interaction: { hover: true },
      manipulation: {
        enabled: true,
      },
      layout: {
        hierarchical: {
          direction: "LR",
        },
      },
      autoResize: true,
      edges: {
        smooth: {
          type: "cubicBezier",
          roundness: 0.8,
        },
      },
      nodes: {
        shape: "image",
        scaling: {
          label: {
            min: 8,
            max: 20,
          },
        },
      },
      physics: {
        enabled: false,
      },
    };
    
    
    return(
        <div>
            {graph && <Graph
                graph={graph}
                options={options}
                events={events}
                style={{ height: "500px", width: "1000px" }}
            /> }
        </div>
    )

    
        
           
          
    
}