import { Grid, Typography } from "@material-ui/core";
import React from "react";
import DQIPerObjectChart from "../../Common/Charts/DQIPerObjectChart";
import DashboardChartComponent from "../../Common/Components/DashboardChartComponent";
import LollipopChartComponent from "../../Common/Components/LollipopChartsComponent";
import * as commonStyles from 'style/CommonStyles'
import ReturnPreviousAgregationButton from "../ReturnPreviousAgregationButton";


export default function DataSourceGrid(props){

    const data = props.data.map(dt => {
        
        return {
            ...dt,
            'score': Number(((dt.sumNumOk / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
            'scoreKo': -Number(((dt.sumNumKo / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
            "fecha": new Date(dt.dayDsDQIList[0].year, dt.dayDsDQIList[0].month, dt.dayDsDQIList[0].day).getTime(),
            "timestamp": dt.dayDsDQIList[0].year + "/" + dt.dayDsDQIList[0].month + "/" + dt.dayDsDQIList[0].day

        }
    })



    function handleDrillDownElement(e) {
        props.handleDrillDownElement(e)
    }

    function setAgregationLevel(level) {
        props.setAgregationLevel(level)
    }

   const drillDown = <DQIPerObjectChart chart={'drillDownChartQualityPointSec'} data={data} click={handleDrillDownElement} categoryField={'name'} valueOk={'score'} valueKo={'scoreKo'} />
   const tablesLollipop = <LollipopChartComponent  data={[]} valueRow1={'score'} valueRow2={'sumNumIn'} categoryRow1={'fecha'} categoryRow2={'fecha'} disableCharts={false}/>


    return (
        <Grid container spacing={3} style={commonStyles.titleBox}>
            <Grid item xs={12} style={commonStyles.DashboardChartComponentTitle}>
                <Typography variant='h6' style={commonStyles.titleText}>{props.name} -  DataSources</Typography>
                <ReturnPreviousAgregationButton agregationLevel={props.agregationLevel} setAgregationLevel={setAgregationLevel} />
            </Grid >


            <Grid item xs={7}>

                <DashboardChartComponent content={drillDown} title={''} description={'Puntuación de todas las ejecuciones por proyecto'} />


            </Grid >
            <Grid item xs={5}>
                <DashboardChartComponent content={tablesLollipop} title={'Trends'} description={'Global DQI'} />

            </Grid >

        </Grid>
    )
}