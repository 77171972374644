import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons'
import AssignmentIcon from '@material-ui/icons/Assignment';
import StorageIcon from '@material-ui/icons/Storage';
import AppsIcon from '@material-ui/icons/Apps';


export function getAqtivaConector(credential, type) {

    let aqtivaConnector = {}

    switch (type) {
        case 'awsHive':
            aqtivaConnector = {
                server: credential.hiveCredentialsDTO.host,
                port: credential.hiveCredentialsDTO.port,
                user: credential.hiveCredentialsDTO.username,
                pwd: credential.hiveCredentialsDTO.password
            }
            break
        case 'mySQL':
            aqtivaConnector = {
                server: credential.mySQLCredentialsDTO.server,
                port: credential.mySQLCredentialsDTO.port,
                user: credential.mySQLCredentialsDTO.user,
                pwd: credential.mySQLCredentialsDTO.pwd
            }
            break
        case 'sqlServer':
            aqtivaConnector = {
                server: credential.sqlServerCredentialsDTO.server,
                port: credential.sqlServerCredentialsDTO.port,
                user: credential.sqlServerCredentialsDTO.user,
                pwd: credential.sqlServerCredentialsDTO.pwd
            }
            break
            case 'azure-sqlsynapse':
                aqtivaConnector = {
                    server: credential.sqlServerCredentialsDTO.server,
                    database: credential.sqlServerCredentialsDTO.database,
                    port: credential.sqlServerCredentialsDTO.port,
                    user: credential.sqlServerCredentialsDTO.user,
                    pwd: credential.sqlServerCredentialsDTO.pwd
                }
                break
        case 'oracle':
            aqtivaConnector = {
                server: credential.oracleStorageCredentialsDTO.server,
                port: credential.oracleStorageCredentialsDTO.port,
                user: credential.oracleStorageCredentialsDTO.user,
                pwd: credential.oracleStorageCredentialsDTO.pwd,
                database: credential.oracleStorageCredentialsDTO.database,
                schema: credential.oracleStorageCredentialsDTO.schema
            }
            break
        case 'bigQuery':
            aqtivaConnector = {
                server: credential.bigQueryCredentialsDTO.projectId,
                pwd: credential.bigQueryCredentialsDTO.encryptedCredentialsFile
            }
            break
        case 'awsRedshift':
            aqtivaConnector = {
                server: credential.redshiftCredentialsDTO.host,
                port: credential.redshiftCredentialsDTO.port,
                s3Bucket: credential.redshiftCredentialsDTO.s3Bucket,
                user: credential.redshiftCredentialsDTO.username,
                pwd: credential.redshiftCredentialsDTO.password,
                database: credential.redshiftCredentialsDTO.database
            }
            break
        default:
            break
    }

    return aqtivaConnector;
}

export function getIconsCredentials(type) {

    let icon

    switch (type) {
        case 'awsHive':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'mySQL':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'sqlServer':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'oracle':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'bigQuery':
            icon = <StorageIcon />
            break
        case 'awsRedshift':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        default:
            break
    }
    return icon
}

export function getIconsDatabases(type) {

    let icon

    switch (type) {
        case 'awsHive':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'mySQL':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/SQL-Server.png')} />
            break
        case 'sqlServer':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/SQL-Server.png')} />
            break
        case 'oracle':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/SQL-Server.png')} />
            break
        case 'bigQuery':
            icon = <AppsIcon />
            break
        case 'awsRedshift':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        default:
            break
    }
    return icon
}

export function getIconsSchema(type) {

    let icon

    switch (type) {
        case 'awsHive':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'mySQL':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/SQL-Server.png')} />
            break
        case 'sqlServer':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/SQL-Server.png')} />
            break
        case 'oracle':
            icon = <img alt="" style={{ height: '20px', width: '20px' }} src={require('resources/icons/azure/SQL-Server.png')} />
            break
        case 'bigQuery':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        case 'awsRedshift':
            icon = <FontAwesomeIcon icon={faIdBadge} style={{ fontSize: 20, marginLeft: '1%' }} />
            break
        default:
            break
    }
    return icon
}

export function getIconsTables(type) {

    let icon

    switch (type) {
        case 'awsHive':
            icon = <AssignmentIcon />
            break
        case 'mySQL':
            icon = <img alt="" style={{ height: '12px', width: '12px', marginTop: "4px" }} src={require('resources/icons/azure/Table.png')} />
            break
        case 'sqlServer':
            icon = <img alt="" style={{ height: '12px', width: '12px', marginTop: "4px" }} src={require('resources/icons/azure/Table.png')} />
            break
        case 'oracle':
            icon = <img alt="" style={{ height: '12px', width: '12px', marginTop: "4px" }} src={require('resources/icons/azure/Table.png')} />
            break
            case 'azure-sqlsynapse':
                icon = <img alt="" style={{ height: '12px', width: '12px', marginTop: "4px" }} src={require('resources/icons/azure/Table.png')} />
                break
        case 'bigQuery':
            icon = <AssignmentIcon />
            break
        case 'awsRedshift':
            icon = <AssignmentIcon />
            break
        default:
            break
    }
    return icon
}


export function getPreviewDTO(creds, database, table, storageType, query2Preview, query2PreviewName) {

    let dto
    switch (storageType) {
        case 'awsHive':
            dto = {
                storageType: "awsHive",
                database: database.name,
                table: table.name,
                credentials: creds.hiveCredentialsDTO,
                credentialsId: creds.id,
                query2Preview: query2Preview,
                query2PreviewName: query2PreviewName
            }
            break;
        case 'mySQL':
            //Databricks no usa el atributo database, así que tenemos que meterlo en schema
            creds.mySQLCredentialsDTO.schema = database.name
            dto = {
                table: table.name,
                storageType: 'mySQL',
                credentials: creds.mySQLCredentialsDTO,
                credentialsId: creds.id,
                query2Preview: query2Preview,
                query2PreviewName: query2PreviewName
            }
            break;
        case 'sqlServer':
            dto = {
                table: table.name,
                storageType: 'sqlServer',
                credentials: creds.sqlServerCredentialsDTO,
                credentialsId: creds.id,
                query2Preview: query2Preview,
                query2PreviewName: query2PreviewName
            }
            break;
        
        case 'azure-sqlsynapse':
            dto = {
                table: table.name,
                storageType: 'sqlServer',
                credentials: creds.sqlServerCredentialsDTO,
                credentialsId: creds.id,
                query2Preview: query2Preview,
                query2PreviewName: query2PreviewName
            }
            break;
        case 'oracle':
            dto = {
                table: table.name,
                storageType: 'oracle',
                credentials: creds.oracleStorageCredentialsDTO,
                credentialsId: creds.id,
                query2Preview: query2Preview,
                query2PreviewName: query2PreviewName
            }
            break;
        case 'bigQuery':
            dto = {
                table: table.name,
                dataset: database.name,
                projectId: creds.bigQueryCredentialsDTO.projectId,
                credentialsId: creds.id,
                query2Preview: query2Preview,
                query2PreviewName: query2PreviewName
            }
            break;
        case 'awsRedshift':
            dto = {
                storageType: "awsRedshift",
                database: database.name,
                table: table.name,
                credentials: creds.redshiftCredentialsDTO,
                credentialsId: creds.id,
                query2Preview: query2Preview,
                query2PreviewName: query2PreviewName
            }
            break;
        default:
            break
    }

    return dto;
}