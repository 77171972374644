import React from 'react'
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuItem, MenuList } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { API_MS_URL } from '../../../../../Constants'
import { axiosHeader } from '../../../../common/axios/axiosApiHelper'
import { useSelector, useDispatch } from 'react-redux'
import * as configurationHelper from '../../../../common/configurationHelper'
import { useTheme } from '@material-ui/core/styles';
import * as userHelper from '../../../../common/userHelper'
import * as commonStyles from 'style/CommonStyles'
import * as deployHelper from 'commonFunctions/deployFunctions'

export default function DeployButton(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(store => store.user)
  const [options, setOptions] = React.useState((props.qualityConfiguration && configurationHelper.filterAllowedEnvironments(
    props.qualityConfiguration.environmentConfigurationList, user.id))
    ? configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList, user.id).map(x => x.environmentName)
    : ['No Environment Found']);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  React.useEffect(() => {
    setOptions((props.qualityConfiguration && configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList, user.id))
      ? configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList, user.id).map(x => x.environmentName) : ['No Environment'])
  }, [props.qualityConfiguration]);// eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (props.setSelectedEnvironment) {
      props.setSelectedEnvironment(options[selectedIndex])
    }
  }, [selectedIndex, options])// eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    let userSelectedEnvironment = userHelper.getUserPreferenceProperty(user, 'selectedEnvironment')
    if (userSelectedEnvironment !== undefined) {
      if (props.qualityConfiguration && configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList, user.id)) {
        var selIndex = configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList, user.id).map(x => x.environmentName).indexOf(userSelectedEnvironment)
        if (selIndex >= 0) {
          setSelectedIndex(selIndex)
        }
      }
    }
  }, [user, props.qualityConfiguration])// eslint-disable-line react-hooks/exhaustive-deps
  const handleToggleButton = (event) => {
    setOpen((prevOpen) => !prevOpen);
  }
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  /*  const handleMenuItemClickButtonExport=(event, index)=> {
        setSelectedIndex(index)
        userHelper.updateUserPreference(dispatch,user,"selectedEnvironment", options[index])
        setOpen(false)
  }*/
 
 
  /* function returnIconDeploymentEnvironment(){
     if(props.project.environment === 'On-premise'){
       return <FontAwesomeIcon icon={faDownload} style={props.visible===true ? {fontSize:14,marginBottom:'5%'}: {fontSize:14,marginBottom:'5%',color:"#959595"}} />
     }
     else{
       if(configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id) &&
       configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id)[selectedIndex]){
         if(configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id)[selectedIndex].backendType==='Azure'){
           return <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
           src={require('./../../../../../resources/icons/AzureIcon2.png')} />
         }
         else{
           return <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
           src={require('./../../../../../resources/icons/AmazonIcon.png')} />
         }
       }
     }
     return <FontAwesomeIcon icon={faDownload} style={props.visible===true ? {fontSize:14,marginBottom:'5%'}: {fontSize:14,marginBottom:'5%',color:"#959595"}} />
   }*/
  async function onClickDeploy(e, environment) {
    console.log('click deploy',e,environment)
    if (environment) {
      if (props.project.environment === 'On-premise') {
        //if(!props.project.qualityPoints[0].datasources[0].type){
        fetch(API_MS_URL + `/project/datafile/${props.project._id}/${environment}`, {
          headers: axiosHeader()
        })
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = props.project._id + "_" + new Date().getTime() + ".zip";
              a.click();
            });
          });
        /* }
         else{
           fetch(API_MS_URL+`/version/2/project/datafile/${props.project._id}/${environment}`,{
             headers: axiosHeader()
           })
           .then(response => {
             response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = props.project._id+"_"+ new Date().getTime()+".zip";
               a.click();
                });
           });
         }*/
      }
      else {
        try {
          deployHelper.deployProject2Cloud(props.qualityConfiguration, environment,props.project._id, user, dispatch)
          
         
        }
        catch (error) {
          console.error(error)
        }
      }
    }
  }

  return (
    <div  >
      <Button ref={anchorRef}
        onClick={event => { handleToggleButton(event) }} style={props.visible === true ? theme.buttons.buttonRegular : theme.buttons.buttonRegularDisabled}
        startIcon={<ArrowDropDownIcon />}
      >
        <div style={theme.common.text.buttonTextSmall}>
          Deploy to
        </div>
      </Button>
      <div style={{ zIndex: 24 }}>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper style={{ zIndex: "10000", }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" style={{ zIndex: "10000", }}>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={event => { onClickDeploy(event, options[index]); handleToggleButton(event) }}
                      >
                        <div style={{ fontSize: '0.7vw', color: commonStyles.mainColor, textTransform: 'none', fontWeight: 'bold' }}>{`Env: ${option}`}</div>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}