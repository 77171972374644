import React, { useEffect, useState } from 'react';

import AqtivaDialog from '../../../../../../common/AqtivaDialog';
import TagCard from '../TagCard'
import {  faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import * as helper from '../ErrorUtils'


export default function EditTagDialog(props) {
    const [tag, setTag] = useState('')
    const [errors, setErrors] = useState('')
    const [counter, setCounter] = useState(1)

    useEffect(() => {
        if (counter > 1) {
            let errorsToCheck = helper.checkAllFields(tag, props.data)
            setErrors(errorsToCheck)
        }
    }, [counter, counter > 1 && tag])// eslint-disable-line react-hooks/exhaustive-deps


    function editArrayElement(tags, editedElement) {
        let errorsToCheck = {}
        errorsToCheck = helper.checkAllFields(editedElement, props.data)

        setErrors(errorsToCheck)
        if (!errorsToCheck.hasErrors) {
            let index = tags.findIndex(x => x.id === props.selectedTag.id)
            tags[index] = editedElement
            props.setData(tags)
            props.setUpdateCounter(props.updateCounter + 1)
            props.setShowEditDialog(false)
        }
        else {
            setCounter(2)
        }
    }

    return (
        <AqtivaDialog
            visible={props.showEditDialog}
            dialog={props.showEditDialog}
            titleIcon={faPlusCircle}
            fullWidth={true}
            maxWidth={'sm'}
            title={"Edit Tag"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                setCounter(1)
                setErrors(undefined)
                props.setShowEditDialog(false)
            }}
            confirmText={'Edit Tag properties'}
            confirmCallback={async () => {
                setCounter(1)
                editArrayElement(props.data, tag)
            }}
        >
            <TagCard master={true} setTag={setTag} configuration={props.configuration} tagInfo={props.selectedTag} errors={errors} />
        </AqtivaDialog>

    )
}