import React from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import StorageConfigurationTable from './StorageConfigurationTable'

import * as commonStyles from 'style/CommonStyles'

export default function StorageConfiguration(props) {

        return (
                <Accordion
                        style={{ width: '100%' }}>
                        <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                        >
                                <Grid item xs={1}>
                                        <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                </Grid>
                                <Grid item xs={11}>
                                        <Typography variant='subtitle2'>Enable Storage Types</Typography>
                                </Grid>

                        </AccordionSummary>
                        <AccordionDetails>

                                <div style={{ width: '10%', minWidth: '800px' }}>
                                        <StorageConfigurationTable configuration={props.configuration} setConfig={props.setConfig} />
                                </div>


                        </AccordionDetails>
                </Accordion>
        )
}