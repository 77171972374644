import React, { Component } from 'react';

import { styles, QualityTheme } from './../../../../style/CustomTheme'
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { sendNewUserEmail, fetchQualityLicense, editQualityLicense, dropUserFromQualityLicense, updateQualityLicense,createLocalUser } from '../../../../actions'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import DialogConfirmUserEdition from './DialogConfirmUserEdition';

import AddIcon from '@material-ui/icons/Add';
import DialogCreateGroup from './DialogCreateGroup'
import AqtivaDialog from '../../../common/AqtivaDialog';
import UserGroupTable from './UserGroupTable'
import { Typography, Select, MenuItem, CircularProgress } from '@material-ui/core'

import trackEvent from './../../../../trackEvent'

import * as commonStyles from 'style/CommonStyles'


class AccountSettings extends Component {




    constructor(props) {
        super(props);

        this.state = {
            license: null,
            showDialogCreateUser: false,
            userName: '',
            provider: null,
            role: null,
            errors: {},
            visibleConfirmDelete: false,
            visibleConfirmEdit: false,
            selectedUser: null,
            visibleDialogCreateGroup: false
        }



    }
    componentDidMount() {
        if (this.props.license) {
            this.setState({ license: this.props.license })
        }


    }

    componentDidUpdate(oldProps) {
        if (this.props.license !== oldProps.license) {
            this.setState({ license: this.props.license })
        }
        else if (this.state.license === null && this.props.license) {
            this.setState({ license: this.props.license })
        }


    }





    setVisibleDialogCreateGroup = (value) => {
        this.setState({ visibleDialogCreateGroup: value })
    }

    handleCloseDialogUser = () => {
        this.setState({ showDialogCreateUser: false })
    }
    handleCloseDialogUserEdition = () => {
        this.setState({ visibleConfirmEdit: false })
    }
    handleOpenDialogUser = () => {
        console.log(this.props.license && this.props.license.users)
        this.setState({ showDialogCreateUser: true })
    }

    onHandleRoleChange = (event) => {
        this.setState({ role: event.target.value })

    }
    onHandleProviderChange = (event) => {
        this.setState({ provider: event.target.value })

    }
    onHandleUserNameChange = (event) => {
        this.setState({ userName: event.target.value })
    }
    onHandlePasswordChange= (event) => {
        this.setState({ password: event.target.value })
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    onClickAddUserLocal = () => {
        var user = {
            "name":this.state.userName,
            "email":this.state.userName,
            "password":this.state.password,
            "role": this.state.role
        }
        this.props.createLocalUser(user)
        this.props.fetchQualityLicense(this.state.license._id)
        this.handleCloseDialogUser();

    }

    onClickAddUserCloud = () => {
        trackEvent('AccountSettings', 'Add User');

        const errors = this.validate();
        this.setState({ errors: errors })
        if (this.isEmpty(errors)) {
            var user = {
                name: this.state.userName.toLowerCase(),
                email: this.state.userName.toLowerCase(),
                domain: this.state.userName.toLowerCase().split('@')[1],
                provider: this.state.provider,
                licenseId: this.props.license._id,
                role: this.state.role
            }
            this.props.editQualityLicense(user)
            this.props.sendNewUserEmail( (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST), this.state.userName.toLowerCase())
            this.handleCloseDialogUser();
        }
    }

    onClickAddUser = () => {
        if(this.state.provider === 'local'){
            this.onClickAddUserLocal()
        }
        else{
            this.onClickAddUserCloud()
        }
    }
    hideConfirmDialog = () => {
        this.setState({ visibleConfirmDelete: false })
    }
    createConfirmDialog = () => {
        return (

            <div>
                <AqtivaDialog
                    visible={this.state.visibleConfirmDelete}
                    confirmCallback={this.deleteUser}
                    cancelCallback={this.hideConfirmDialog}
                    onHide={this.hideConfirmDialog}
                    title={`Confirm Delete User ${this.state.selectedUser.name}`} >

                    <Typography style={{ margin: "20px" }} variant='subtitle2'>{`Are you sure you want to delete user ${this.state.selectedUser.name}?`}</Typography>
                </AqtivaDialog>

            </div>
        );
    }

    onClickDeleteUser = (user) => {
        trackEvent('AccountSettings', 'Delete User');
        this.setState({ visibleConfirmDelete: true, selectedUser: user })
    }
    onClickEditUser = (user) => {
        trackEvent('AccountSettings', 'Edit User');
        this.setState({ visibleConfirmEdit: true, selectedUser: user })
    }

    deleteUser = () => {
        this.props.dropUserFromQualityLicense(this.state.selectedUser.id, this.state.license._id);
        this.setState({ visibleConfirmDelete: false })

    }
    validate = () => {
        var errors = {};
        if (!this.state.userName || this.state.userName === null) {
            errors.userName = 'You must provide a User Name';
        }
        if (!this.state.provider || this.state.provider === null) {
            errors.provider = 'You must select a User Provider';
        }
        if (!this.state.role || this.state.role === null) {
            errors.role = 'You must provide a User Role';
        }
        if (this.state.userName.toLowerCase() && this.state.userName !== null && !this.validateEmail(this.state.userName.toLowerCase())) {
            errors.userName = 'You must provide a valid UserName in email format';
        }
        if (this.state.userName.toLowerCase() && this.state.license.users && this.state.license.users.find(x => x.name === this.state.userName.toLowerCase())) {
            errors.userName = 'Username already in use'
        }
        if (this.state.userName.toLowerCase() && this.state.license.users && this.state.license.users.find(x => x.email === this.state.userName.toLowerCase())) {
            errors.userName = 'Username already in use'
        }

        return errors
    }
    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    renderError = (error) => {

        return (
            <div className="ui error message" style={{ width: '250px', fontSize: '10px' }}>
                <div className="header" >{error}</div>
            </div>
        );

    }

    addGroup = (group) => {

        var newLicense = Object.assign({}, this.state.license);
        var groups = newLicense.groups
        if (this.isEmpty(groups)) {
            groups = [group]
        }
        else {
            groups.push(group)
        }
        newLicense.groups = groups
        this.setState({ license: newLicense })

        this.props.updateQualityLicense(newLicense)
    }

    editGroup = (group) => {

        var newLicense = Object.assign({}, this.state.license);
        var groups = newLicense.groups
        if (this.isEmpty(groups)) {
            return
        }
        else {
            for (var i = 0; i < groups.length; i++) {
                if (groups[i].id === group.id) {
                    groups[i] = group
                    break;
                }
            }
        }
        newLicense.groups = groups
        this.setState({ license: newLicense })

        this.props.updateQualityLicense(newLicense)
    }
    deleteGroup = (group) => {

        var newLicense = Object.assign({}, this.state.license);
        var groups = newLicense.groups
        if (this.isEmpty(groups)) {
            return
        }
        else {
            for (var i = 0; i < groups.length; i++) {
                if (groups[i].id === group.id) {
                    groups.splice(i, 1);
                    break;
                }
            }
        }
        newLicense.groups = groups
        this.setState({ license: newLicense })

        this.props.updateQualityLicense(newLicense)
    }


    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={QualityTheme}>
                <DialogCreateGroup visible={this.state.visibleDialogCreateGroup} users={this.isEmpty(this.state.license) ? [] : this.state.license.users}
                    onClose={this.setVisibleDialogCreateGroup}
                    onAddGroup={this.addGroup} />
                {this.state.visibleConfirmDelete && this.createConfirmDialog()}
                {
                    <Dialog open={this.state.showDialogCreateUser}
                        onClose={this.handleCloseDialogUser} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-createUser-title">
                            <div style={{ display: 'inline-flex' }}>
                                <Typography variant='h6'>Create User</Typography>
                                <Fab size="small" color="primary" aria-label="Close" className={classes.closeButton}
                                    onClick={this.handleCloseDialogUser}>
                                    <CloseIcon />
                                </Fab>
                            </div>
                        </DialogTitle>
                        <DialogContent style={{ width: '350px' }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="User Name"
                                type="email"
                                style={{ width: '300px' }}
                                value={this.state.userName}
                                onChange={this.onHandleUserNameChange}
                            />
                            {this.state.errors && this.state.errors.userName &&
                                this.renderError(this.state.errors.userName)}
                            {this.state.provider === 'local' && 
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="password"
                                    label="Password"
                                    type="password"
                                    style={{ width: '300px' }}
                                    value={this.state.password}
                                    onChange={this.onHandlePasswordChange}
                                />
                            }
                            <InputLabel htmlFor="age-auto-width">Provider</InputLabel>
                            <Select
                                style={{ width: '300px' }}
                                value={this.state.provider}
                                onChange={this.onHandleProviderChange}
                                input={<Input name="age" id="age-auto-width" />}
                                fullWidth
                            >


                                <MenuItem value={'google'}>google</MenuItem>
                                <MenuItem value={'azure'}>azure</MenuItem>
                                <MenuItem value={'local'}>local</MenuItem>
                            </Select>
                            {this.state.errors && this.state.errors.provider &&
                                this.renderError(this.state.errors.provider)}

                            <InputLabel htmlFor="age-auto-width">Role</InputLabel>
                            <Select
                                style={{ width: '300px' }}
                                value={this.state.role}
                                onChange={this.onHandleRoleChange}
                                input={<Input name="age" id="age-auto-width" />}
                                fullWidth
                            >
                                {this.props.license.role === 'SUPER_ADMIN' &&
                                    <MenuItem value={'SUPER_ADMIN'}>Super Admin</MenuItem>}
                                <MenuItem value={'ADMIN'}>Admin</MenuItem>
                                <MenuItem value={'USER'}>User</MenuItem>
                            </Select>
                            {this.state.errors && this.state.errors.role &&
                                this.renderError(this.state.errors.role)}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onClickAddUser}
                                variant="contained" color="secondary" className={classes.button}
                            >
                                <i className="icon plus"></i>
                                Save
                            </Button>

                        </DialogActions>

                    </Dialog>
                }
                {<DialogConfirmUserEdition user={this.state.selectedUser} visibleConfirmEdit={this.state.visibleConfirmEdit} handleCloseDialogUserEdition={this.handleCloseDialogUserEdition} renderError={this.renderError} license={this.props.license} />}
                {this.state.license && <div className="dashboard ui segment" style={{ minHeight: '600px' }}>
                    <Grid container spacing={0} >
                        <Grid key={'providerId'}>
                            <div style={{ margin: '20px' }}>
                                <Typography variant='subtitle2'>
                                    License Key
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {this.state.license.apiKey}</Typography></div>
                        </Grid>



                        <Grid key={'initDate'} >
                            <div style={{ margin: '20px' }}>
                                <Typography variant='subtitle2'>
                                    Init Date
                                </Typography>
                                <Typography variant='body1'>
                                    {this.state.license.initDate && this.state.license.initDate.substring(0, 10)}</Typography></div>
                        </Grid>
                        <Grid key={'endDate'} >
                            <div style={{ margin: '20px' }}>
                                <Typography variant='subtitle2'>
                                    End Date
                                </Typography>
                                <Typography variant='body1'>
                                    {this.state.license.endDate && this.state.license.endDate.substring(0, 10)}</Typography></div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} >
                        <Grid key={'numMaxUsers'} >
                            <div style={{ margin: '20px' }}>
                                <Typography variant='subtitle2'>
                                    Max Number of Users
                                </Typography>
                                <Typography variant='body1'>
                                    {this.state.license.numMaxUsers}</Typography></div>
                        </Grid>
                        <Grid key={'users'} >
                            <div style={{ margin: '10px' }}>
                                {this.state.license.users && <List style={{ maxHeight: '200px', overflow: 'auto' }} dense={true}>
                                    {this.state.license.users && this.state.license.users.map(user => {
                                        return (
                                            <ListItem key={user.name + user.provider}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        {user.provider === 'google' &&
                                                            <i className=" google icon red"></i>}
                                                        {user.provider === 'microsoft' &&
                                                            <i className=" microsoft icon blue"></i>}
                                                        {user.provider === 'userpwd' && <AccountCircleIcon />}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText style={{ cursor: 'pointer' }}
                                                    primary={user.name}
                                                    secondary={user.role + (user.providerId !== null ? '' : '(pending accept)')}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton aria-label="Edit" onClick={e => this.onClickEditUser(user)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="Delete" onClick={e => this.onClickDeleteUser(user)}>
                                                        <DeleteIcon />
                                                    </IconButton>

                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    })}
                                </List>}
                                {this.state && this.state.license && !this.state.license.users && <Grid item={true} xs={12} key={'loading component'} style={{ margin: "50px" }}>

                                    <CircularProgress size={60} style={{ color: commonStyles.mainColor }} />
                                    <Typography variant='subtitle2'>Loading Users</Typography>
                                </Grid>
                                }
                            </div>
                        </Grid>
                        <Grid key={'addUserButton'}>
                            <div style={{ margin: '20px' }}>
                                <Fab disabled={this.props.license.users && this.props.license.users.length === this.props.license.numMaxUsers}
                                    size="small" color='primary' aria-label="Filter" onClick={this.handleOpenDialogUser}  >
                                    <AddIcon />
                                </Fab>
                            </div>
                        </Grid>
                    </Grid>
                    {undefined && <Button style={{ color: commonStyles.mainColor, fontWeight: 'bold' }}
                        onClick={event => { this.setVisibleDialogCreateGroup(true); }}

                        startIcon={<AddIcon />}
                    >
                        New Group

                    </Button>}
                    {undefined &&
                        <UserGroupTable
                            groups={this.isEmpty(this.state.license) || this.isEmpty(this.state.license.groups) ? [] : this.state.license.groups}
                            editGroup={this.editGroup}
                            deleteGroup={this.deleteGroup}
                        />
                    }


                </div>}
            </MuiThemeProvider>
        );
    };

}


const mapStateToProps = (state) => {
    return { license: state.license };
}

export default withStyles(styles(QualityTheme))(connect(mapStateToProps, { createLocalUser, sendNewUserEmail, editQualityLicense, updateQualityLicense, fetchQualityLicense, dropUserFromQualityLicense })(AccountSettings));
