import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";


export default function DQIDimensionsChartDetail(props) {

   
    function generateData(chart){
        chart.titles.template.isMeasured = false;
        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "bucket";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.disabled = true;
        categoryAxis.cursorTooltipEnabled = false;
        
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.cursorTooltipEnabled = false;
        
        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "value";
        series2.dataFields.categoryX = "bucket";
        series2.clustered = false;
        series2.fill = am4core.color('#448563');
        
        series2.stroke = am4core.color('white');
       
        
    
        series2.columns.template.tooltipText = "[font-size:12px] {categoryX}: \n {valueY}";

        chart.svgContainer.autoResize = false;
        chart.svgContainer.measure();
        
    }
    React.useLayoutEffect(() => {

        am4core.array.each(am4core.registry.rootElements, function(root) {
            if (root && root.dom.id === props.id) {
              root.dispose();
            }
          });
        var chart = am4core.create(props.id, am4charts.XYChart);

        chart.data = props.data
            
        generateData(chart)
            
       
         
    
    
    }, [props.data]);// eslint-disable-line react-hooks/exhaustive-deps


   
        return (
                <div id={props.id} style={{ width: "100%", height: "80%" }}></div>
               
           
        )
      
   
}
