import React from 'react';
import AppBarTemplate from './../../template/AppBarTemplate'
import * as pathHelper from './../../template/PathHelper'
import { useHistory } from 'react-router-dom'


import {  useDispatch } from 'react-redux'
import * as actions  from './../../../actions'
import QualitySimulationRepository from './QualitySimulationRepository';
import ReactGA from 'react-ga';


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/simulation/repository '); 

export default function QualitySimulationRepositoryPage(props) {

    const dispatch = useDispatch();
    const history = useHistory(); 

    dispatch(actions.fetchUser(history))

   
    return (
        <div >
            <AppBarTemplate history={props.history} title={'Configuration'} arrayPath ={pathHelper.getPathArray('QualitySimulationRepositoryPage')} />
            
            <div style={{'padding':'2%'}}>
            <QualitySimulationRepository 
                history = {props.history}
             />
            
            </div>
        </div>

    );
}