import React from 'react';
import AqtivaDialog from 'components/common/AqtivaDialog'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid';

import SelectMySqlDatabaseDialog from './selectDialogs/SelectMySqlDatabaseDialog';
import SelectSqlServerDatabaseDialog from './selectDialogs/SelectSqlServerDatabaseDialog';
import SelectSqlDatabaseDialog from './selectDialogs/SelectSqlDatabaseDialog';
import SelectAzureItemDialog from './selectDialogs/SelectAzureItemDialog'
import SelectSynapseDialog from './selectDialogs/SelectSynapseDialog';
import SelectOracleDatabaseDialog from './selectDialogs/SelectOracleDatabaseDialog';
import SelectBigQueryDatabaseDialog from './selectDialogs/SelectBigQueryDatabaseDialog';
import SelectAwsDatabaseDialog from './selectDialogs/SelectAwsS3DatabaseDialog';
import SelectHiveDatabaseDialog from './selectDialogs/SelectHiveDatabaseDialog';

import {
    Typography
} from '@material-ui/core'


import * as actions from 'actions'

import { ReactComponent as LocalFileIcon } from 'resources/icons/azure/File.svg';

import * as commonStyles from 'style/CommonStyles'
import SelectRedshiftDatabaseDialog from './selectDialogs/SelectRedshiftDatabaseDialog';
import SelectSynapseJdbcDialog from './selectDialogs/SelectSynapseJdbcDialog';



export default function DialogSelectAzureSource(props) {
    const dispatch = useDispatch();


    const [showDialogStorageAccount, setShowDialogStorageAccount] = React.useState(false)
    //const [showDialogCosmoAccount, setShowDialogCosmoAccount] = React.useState(false)
    const [showDialogSqlDatabaseAccount, setShowDialogSqlDatabaseAccount] = React.useState(false)
    const [showDialogSynapseAccount, setShowDialogSynapseAccount] = React.useState(false)
    const [showDialogOracle, setShowDialogOracle] = React.useState(false)
    const [showDialogMySQL, setShowDialogMySQL] = React.useState(false)
    const [showDialogSqlServer, setShowDialogSqlServer] = React.useState(false)
    const [showDialogSynapseJdbc, setShowDialogSynapseJdbc] = React.useState(false)

    const [showDialogBigQuery, setShowDialogBigQuery] = React.useState(false)
    const [showDialogAws, setShowDialogAws] = React.useState(false)
    const [showDialogHive, setShowDialogHive] = React.useState(false)
    const [showDialogRedshift, setShowDialogRedshift] = React.useState(false)



    const user = useSelector(store => store.user)
    var qualityConfiguration = useSelector(store => store.qualityConfiguration)



    React.useEffect(() => {
        if (user && user.id && (qualityConfiguration === undefined || qualityConfiguration === null)) {

            dispatch(actions.fetchConfigurationByUserId(user.id))
        }
    }, [JSON.stringify(user), qualityConfiguration]);// eslint-disable-line react-hooks/exhaustive-deps



    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchConfigurationByUserId(user.id))

        }

    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps








    return (
        <AqtivaDialog visible={props.visible}
            maxWidth={'lg'}
            title={'Select Datasource type'}
            iconSourceFlag={true}
            iconSource={require('resources/icons/data_proc1_white.png')}

            cancelText={'Close'}
            showConfirmButton={false}
            cancelCallback={() => { props.setVisible(false) }}
        >

            {showDialogStorageAccount &&
                <SelectAzureItemDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogStorageAccount} setVisible={setShowDialogStorageAccount} setVisibleParent={props.setVisible}
                />
            }
            {/*
{showDialogCosmoAccount && 
                <SelectCosmoDbDialog  visible ={showDialogCosmoAccount} setVisible={setShowDialogCosmoAccount} setVisibleParent ={props.setVisible}
                azureManager = {aManager}
               />

              
            }
        */}

            {showDialogSqlDatabaseAccount &&
                <SelectSqlDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogSqlDatabaseAccount} setVisible={setShowDialogSqlDatabaseAccount}
                    setVisibleParent={props.setVisible}
                />

            }
            {showDialogSynapseJdbc &&
                <SelectSynapseJdbcDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogSynapseJdbc} setVisible={setShowDialogSynapseJdbc}
                    setVisibleParent={props.setVisible}
                />
            }

            {showDialogSynapseAccount &&
                <SelectSynapseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogSynapseAccount} setVisible={setShowDialogSynapseAccount}
                    setVisibleParent={props.setVisible}
                />
            }
            {showDialogOracle &&
                <SelectOracleDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogOracle} setVisible={setShowDialogOracle}
                    setVisibleParent={props.setVisible}
                />

            }
            {showDialogMySQL &&
                <SelectMySqlDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogMySQL} setVisible={setShowDialogMySQL}
                    setVisibleParent={props.setVisible}
                />

            }
            {showDialogSqlServer &&
                <SelectSqlServerDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogSqlServer} setVisible={setShowDialogSqlServer}
                    setVisibleParent={props.setVisible}
                />

            }


            {showDialogSynapseAccount &&
                <SelectSynapseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogSynapseAccount} setVisible={setShowDialogSynapseAccount}
                    setVisibleParent={props.setVisible}
                />
            }

            {showDialogBigQuery &&
                <SelectBigQueryDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogBigQuery} setVisible={setShowDialogBigQuery}
                    setVisibleParent={props.setVisible}
                />
            }

            {showDialogAws &&
                <SelectAwsDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogAws} setVisible={setShowDialogAws}
                    setVisibleParent={props.setVisible}
                />
            }

            {showDialogHive &&
                <SelectHiveDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogHive} setVisible={setShowDialogHive}
                    setVisibleParent={props.setVisible}
                />
            }

            {showDialogRedshift &&
                <SelectRedshiftDatabaseDialog
                    closeDialogDisplay={props.closeDialogDisplay}
                    createNewVersion={props.createNewVersion}
                    selectedDataset={props.selectedDataset} visible={showDialogRedshift} setVisible={setShowDialogRedshift}
                    setVisibleParent={props.setVisible}
                />
            }

            <Grid container spacing={3} style={{marginTop:'20px'}}>
                {qualityConfiguration && qualityConfiguration.enableAzureStorage === true && <Grid item xs={4}>
                    <div style={{ textAlign: 'center', cursor: "pointer" }}
                        onClick={event => { setShowDialogStorageAccount(true) }} >
                        <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                            src={require('resources/icons/azure/Storage-Accounts.png')} />
                        <Typography variant='subtitle2'>StorageAccount</Typography>
                    </div>
                </Grid>}
                {undefined && <Grid item xs={4}>
                    <div style={{ textAlign: 'center', cursor: "pointer" }}
                    //onClick={event => { setShowDialogCosmoAccount(true) }} 
                    >
                        <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                            src={require('resources/icons/azure/cosmo_db.png')} />
                        <Typography variant='subtitle2'>Cosmos DB</Typography>
                    </div>
                </Grid>}
                {qualityConfiguration && qualityConfiguration.enableSqlDatabase === true && < Grid item xs={4}>
                    <div style={{ textAlign: 'center', cursor: "pointer" }}
                        onClick={event => { setShowDialogSqlDatabaseAccount(true) }} >
                        <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                            src={require('resources/icons/azure/sql-database-generic.png')} />
                        <Typography variant='subtitle2'>SQL Database</Typography>
                    </div>
                </Grid>}
                {qualityConfiguration && qualityConfiguration.enableSynapse === true && <Grid item xs={4}>
                    <div style={{ textAlign: 'center', cursor: "pointer" }}
                        onClick={event => { setShowDialogSynapseAccount(true) }} >
                        <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                            src={require('resources/icons/azure/synapse.png')} />
                        <Typography variant='subtitle2'>Synapse</Typography>
                    </div>
                </Grid>}
                {qualityConfiguration && qualityConfiguration.enableMySql === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogMySQL(true) }}>
                            <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                                src={require('resources/icons/mySQL.png')} />
                            <Typography variant='subtitle2'>MySQL Database</Typography>
                        </div>

                    </Grid>
                }
                {qualityConfiguration && qualityConfiguration.enableSQLServer === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogSqlServer(true) }}>
                            <img alt="" style={{ height: '70px', width: '70px' }}
                                src={require('resources/icons/sqlServer.png')} />
                            <Typography variant='subtitle2'>Sql Server Database</Typography>
                        </div>

                    </Grid>
                }

                {qualityConfiguration && qualityConfiguration.enableSynapse === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogSynapseJdbc(true) }}>
                            <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                            src={require('resources/icons/azure/synapse.png')} />
                            <Typography variant='subtitle2'>Synapse JDBC Database</Typography>
                        </div>

                    </Grid>
                }
                {qualityConfiguration && qualityConfiguration.enableOracle === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogOracle(true) }}>
                            <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                                src={require('resources/icons/oracle.png')} />
                            <Typography variant='subtitle2'>Oracle Database</Typography>
                        </div>
                    </Grid>
                }
                {qualityConfiguration && qualityConfiguration.enableBigQuery === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogBigQuery(true) }}>
                            <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                                src={require('resources/icons/bigQuery.png')} />
                            <Typography variant='subtitle2'>Big Query</Typography>
                        </div>
                    </Grid>
                }
                {//qualityConfiguration && qualityConfiguration.enableAws === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogAws(true) }}>
                            <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                                src={require('resources/icons/aws/aws-s3-logo.png')} />
                            <Typography variant='subtitle2'>AWS</Typography>
                        </div>
                    </Grid>
                }

                {qualityConfiguration && qualityConfiguration.enableHive === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogHive(true) }}>
                            <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                                src={require('resources/icons/hive.png')} />
                            <Typography variant='subtitle2'>Hive</Typography>
                        </div>
                    </Grid>
                }

                {qualityConfiguration && qualityConfiguration.enableRedshift === true &&
                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center', cursor: "pointer" }}
                            onClick={event => { setShowDialogRedshift(true) }}>
                            <img alt="" style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }}
                                src={require('resources/icons/redshift.png')} />
                            <Typography variant='subtitle2'>Redshift</Typography>
                        </div>
                    </Grid>
                }
                <Grid item xs={4}>
                    <div style={{ textAlign: 'center', cursor: "pointer" }}
                        onClick={event => { props.setLocalFileDialogVisible(true) }}>
                        <LocalFileIcon fill={commonStyles.mainColor} style={{ height: commonStyles.selectDatasourceIconSize, width: commonStyles.selectDatasourceIconSize }} />

                        <Typography variant='subtitle2'>Local File</Typography>
                    </div>

                </Grid>
            </Grid>

        </AqtivaDialog >
    )
}