import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';
import { useInterval } from 'components/common/useInterval'
import { dispatchError } from 'components/common/axios/axiosHelper';
import { ACCESS_TOKEN_AZURE_USER } from 'components/../Constants';

import * as actions from 'actions'


import { Grid, FormControlLabel, TextField, Switch, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ComponentPreviewData from './../ComponentPreviewData'
import ComponentProfilingStatus from './../ComponentProfilingStatus'
import ComponentProfilingBatchStatus from './../ComponentProfilingBatchStatus'



import AqtivaDialog from 'components/common/AqtivaDialog'
import TreeViewStorageRest from './../treeViews/TreeViewStorageRest'

import * as helper from './SelectAzureItemDialogHelper'

import { faWindows, } from '@fortawesome/free-brands-svg-icons'

import FilterByRGComponentAutocomplete from 'components/dialogs/DialogSelectAzureSource/common/FilterByRGComponentAutocomplete'
import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'
import SelectAzureItemDialogLocal from './SelectAzureItemDialogLocal'
import { PublicClientApplication } from "@azure/msal-browser";
import AqtivaLoading from 'components/common/AqtivaLoading';


export default function SelectAzureItemDialog(props) {

    const user = useSelector(store => store.user)
    var configuration = useSelector(store => (store.qualityConfiguration))
    const dispatch = useDispatch();
    const theme = useTheme();


    const [schema, setSchema] = React.useState([])
    const [selectedItem, setSelectedItem] = React.useState(undefined)
    const [fileOutputName, setFileOutputName] = React.useState('')
    const [delimiter, setDelimiter] = React.useState(undefined)
    const [format, setFormat] = React.useState(undefined)
    const [dataPath, setDataPath] = React.useState(undefined)
    const [outputPath, setOutputPath] = React.useState(undefined)

    const [selectedResourceGroup, setSelectedResourceGroup] = React.useState('')
    const [resourceGroups, setResourceGroups] = React.useState([])

    const [flattenFlag, setFlattenFlag] = React.useState(true)
    const [numberSamples, setNumberSamples] = React.useState(100)
    const [profilingSamples, setProfilingSamples] = React.useState(200000)
    //const [alreadyDefinedDataformats, setAlreadyDefinedDataformats] = React.useState([])
    //TODO: CHECK
    const alreadyDefinedDataformats = []

    const [previewDialogVisible, setPreviewDialogVisible] = React.useState(false)
    const [previewDialogParsingData, setPreviewDialogParsingData] = React.useState(false)
    const [profilingVisible, setProfilingVisible] = React.useState(false)
    const [errorBackend, setErrorBackend] = React.useState(undefined)
    const [stats, setStats] = React.useState([])
    const [dataCount, setDataCount] = React.useState(1)


    const [runId, setRunId] = React.useState(undefined)
    const [profilingdataformatId, setProfilingdataformatId] = React.useState(undefined)
    const [profilingBatchId, setProfilingBatchId] = React.useState(undefined)
    const [dataformatWithProfiling, setDataformatWithProfiling] = React.useState(undefined)
    const [profilingBatch, setProfilingBatch] = React.useState(undefined)
    const [profilingState, setProfilingState] = React.useState('')
    const [profilingBatchState, setProfilingBatchState] = React.useState('')
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [selectedEnvironmentStates, setSelectedEnvironmentStates] = React.useState('')

    const [subscriptions, setSubscriptions] = React.useState([])
    const [selectedSubscription, setSelectedSubscription] = React.useState('')
    const [connectToLocalStorages, setConnectToLocalStorages] = React.useState(false)

    const [loadingPreview, setLoadingPreview] = React.useState(false)
    const [profilingRunId, setProfilingRunId] = React.useState(undefined)

    const datasetFormats = ['AUTOMATIC', 'CSV', 'TXT', 'PARQUET', 'DELTA', 'HOODIE']
    const [selectedFormat, setSelectedFormat] = React.useState('AUTOMATIC')

    //Función que actualiza los resource groups y selecciona el primero de ellos
    function setResourceGroupsAndSelect(rgroups) {
        setResourceGroups(rgroups)
        if (rgroups !== undefined && rgroups.length > 0) {
            setSelectedResourceGroup(rgroups[0])
        }
    }

    function isAzureUser() {
        return user && user.providerId && user.providerId.toUpperCase()==='AZURE'
    }


    //función que convierte un esquema de previsualización en un objecto dataformat, función final del sistema
    async function convertSchema2Datasource() {
        if (schema !== undefined && selectedItem !== undefined) {
            const dataset = helper.convertSchema2Datasource(schema, user, selectedItem, fileOutputName, delimiter, format, dataPath)
            if (props.createNewVersion && props.createNewVersion === true && props.selectedDataset) {
                setLoadingPreview(true)
                await dispatch(actions.createNewDataformatVersion(dataset, props.selectedDataset._id))
                setLoadingPreview(false)
                props.closeDialogDisplay(false)
            }
            else {
                dispatch(actions.addDataFormatObject(dataset))
            }
            props.setVisible(false)
            props.setVisibleParent(false)
        }


    }


    function setPreviewDialogVisibleFull(value) {
        setPreviewDialogVisible(value)
        props.setVisible(false)
    }

    function setRunIdAndRestart(runId) {
        setRunId(runId)
        setSchema([])
        setStats([])



        setDelimiter(undefined)
        setFormat(undefined)
        setDataPath(undefined)
        setOutputPath(undefined)
        setDataCount(1)

    }
    function resetProfilingState() {
        setProfilingBatch(undefined)
        setProfilingBatchId(undefined)
        setProfilingBatchState('')
        setProfilingState('')
        setProfilingdataformatId(undefined)
        setDataformatWithProfiling(undefined)
        setProfilingVisible(false)
    }

    async function getTokenFromAzureUser() {
        if(isAzureUser()===true){
            const appId = await actions.getControlValueAsync("AQTIVA_APP_ID")
            const tenantId = await actions.getControlValueAsync("AQTIVA_TENANT_ID")



            const msalConfig = {
                auth: {
                    clientId: appId,
                    redirectUri: (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST) + "azure",
                    prompt: 'select_account',
                    authority:
                        'https://login.microsoftonline.com/' + tenantId,
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            }

            console.log('msalConfig', msalConfig)

            var token = undefined
            let request = {
                scopes: ['https://management.core.windows.net/user_impersonation'],
                prompt: 'select_account',
            };
            const msalInstance = new PublicClientApplication(
                msalConfig,
            );
            try {
                let cacheToken = localStorage.setItem('ACCESS_TOKEN_AZURE_IDENTITY', token);
                if (cacheToken) {
                    token = cacheToken
                }
                else {
                    let tokenResponse = await msalInstance.acquireTokenSilent(request);
                    token = tokenResponse.accessToken
                    console.log('tok response', tokenResponse)
                }

            } catch (error) {
                console.error('Silent token acquisition failed. Using interactive mode');
                try {
                    let tokenResponse = await msalInstance.acquireTokenPopup(request);
                    token = tokenResponse.accessToken
                }
                catch (e) {
                    console.error("Error in acquireTokenPopup")
                    console.error(3)
                }

            }

            localStorage.setItem(ACCESS_TOKEN_AZURE_USER, token);
        }


    }


    React.useEffect(() => {
        (async () => {
            if(isAzureUser()===true){
            //first get storage ids
            let cacheToken = localStorage.getItem('ACCESS_TOKEN_AZURE_IDENTITY')
            console.log('cacheToken', cacheToken)
            if (cacheToken === undefined || cacheToken === null) {
                await getTokenFromAzureUser()
            }

            const subs = await dispatch(actions.getSubscriptions())
            //FIX SENTRY ISSUE ea305d00f39445ab85b249742b35ad68
            if ((subs === undefined || subs === null || subs.length === 0) && subs[0]) {
                dispatchError(dispatch, "No Subscription has been found")
                return
            }
            setSubscriptions(subs)
            if (configuration && configuration.azureCredentials && configuration.azureCredentials.subscription) {
                setSelectedSubscription(configuration.azureCredentials.subscription)
            }
            else {
                //FIX SENTRY ISSUE ea305d00f39445ab85b249742b35ad68
                if (subs !== undefined && subs !== null && subs.length > 0 && subs[0]) {
                    setSelectedSubscription(subs[0].subscriptionId)
                }
                else {
                    console.error("No subscriptions has been found")
                }

            }
        }

        })();

    }, [localStorage.getItem(ACCESS_TOKEN_AZURE_USER)])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (profilingBatchId !== undefined || profilingdataformatId !== undefined) {
            setPreviewDialogParsingData(false)
            setVisiblePreviewCallback(false)
            setPreviewDialogVisible(false)
            setProfilingVisible(true)
        }

    }, [profilingBatchId, profilingdataformatId])

    function getJobOutputNotStarted(output) {
        if (output && output.data && output.data.error && output.data.error !== null) {
            return true
        }
        return false
    }

    function getJobOutputPreviewFailed(output) {
        if (output && output.data && output.data.metadata && output.data.metadata.state && output.data.metadata.state.result_state === 'FAILED') {
            return true
        }
        if (output && output.data && output.data.state && output.data.state === 'FAILED') {
            return true
        }
        return false
    }

    function getJobOutputUncontrolledException(output) {
        if (output && output.errorMessage) {
            return true
        }
        return false
    }

    function getJobOutputControlledException(output) {
        if (output && output.data && output.data.result
        ) {
            if (output.data.result.includes('status:"ko"')) {
                return true
            }
        }
        return false
    }

    function getJobOutputErrorNotebookAccess(output) {
        if (output && output.data && output.data.result && output.data.result.includes('status:"ko"') &&
            (output.data.result.includes('HTTP Error 401') || output.data.result.includes('HTTP Error 403'))) {
            return true
        }
        return false
    }

    function getJobOutputNoError(output) {
        if (output && output.data && output.data.result && output.data.result !== null) {
            return true
        }
        return false
    }

    useInterval(() => {
        if (runId !== undefined) {
            dispatch(actions.getJobRunOutputForUser(runId, user.id, selectedEnvironment, numberSamples, flattenFlag)).then(output => {

                if (getJobOutputUncontrolledException(output)) {
                    dispatchError(dispatch, output.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (getJobOutputControlledException(output)) {
                    //control 403 error
                    if (output && output.data && output.data.result &&
                        (output.data.result.includes('This request is not authorized to perform this operation using this permission')
                            || output.data.result.includes('403') || output.data.result.includes('401'))) {
                        dispatchError(dispatch, "OMMA is not authorized to read that file. Please contact your Azure administrator to provide access to Aqtiva")
                    }
                    else {
                        var errorMessage = "File cannot be parsed to preview"
                        if (output && output.data && output.data.result) {
                            var s1 = output.data.result.split("msg:")
                            if (s1.length > 1) {
                                errorMessage = errorMessage + ": " + s1[1]
                            }
                        }
                        dispatchError(dispatch, errorMessage)
                    }
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (getJobOutputNotStarted(output)) {
                    //execution error
                    if (output.data.error === 'No output is available until the task begins.') {
                        setErrorBackend('Starting Big Data server. Depends on the infrastructure it make take some time...')
                    }
                    else {
                        setErrorBackend(output.data.error)
                    }



                }
                if (getJobOutputPreviewFailed(output)) {
                    //preview failed
                    dispatchError(dispatch, output.data.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }


                if (getJobOutputErrorNotebookAccess(output)) {
                    dispatchError(dispatch, output.data.errorMessage)

                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)





                }

                else if (getJobOutputNoError(output)) {
                    /*if (output && output.data && output.data.result &&
                        (output.data.result.includes('Server failed to authenticate the request')
                            || output.data.result.includes('403'))) {
                        dispatchError(dispatch, "OMMA is not authorized to read that file. Please contact your Azure administrator to provide access to Aqtiva")
                    }
                    else {*/
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setSchema(JSON.parse(JSON.parse(output.data.result).schema))
                    setStats(JSON.parse(JSON.parse(output.data.result).statistics))

                    setPreviewDialogVisible(true)
                    setPreviewDialogParsingData(false)


                    setDelimiter(JSON.parse(output.data.result).separator)
                    setFormat(JSON.parse(output.data.result).format)
                    setOutputPath(JSON.parse(output.data.result).outputPath)
                    setDataPath(JSON.parse(output.data.result).dataPath)
                    setDataCount(JSON.parse(output.data.result).count)

                    //}
                }

            }
            )
        }

    }
        , 3000)

    useInterval(() => {
        if (profilingdataformatId !== undefined) {
            dispatch(actions.fetchDataformat(profilingdataformatId)).then(result => {
                checkStatusProfilingRun()
                switch (result.data.profilingStatus) {
                    case "starting": {
                        setProfilingState("starting")
                        break
                    }
                    case "in_progress": {
                        setProfilingState("in progress")
                        break
                    }
                    case "ok": {
                        setProfilingState("completed")
                        setDataformatWithProfiling(result.data)
                        break
                    }
                    case "ko": {
                        setProfilingState("some errors were encountered,enable to execute profiling job")
                        break
                    }
                    default:
                        break
                }
            }
            )
        }
        if (profilingBatchId !== undefined) {
            dispatch(actions.fetchProfilingBatch(profilingBatchId)).then(result => {
                switch (result.data.profilingStatus) {
                    case "starting": {
                        setProfilingBatchState("starting")
                        break
                    }
                    case "in_progress": {
                        if (result.data.numberOfProfilings && result.data.dataformatsWithProfiling && result.data.dataformatsWithProfiling.length > 0) {
                            let numberOfFinishedProfilingJobs = checkProfilingsState(result.data)
                            setProfilingBatchState(`in progress: ${numberOfFinishedProfilingJobs} out of ${result.data.numberOfProfilings} profilings finished`)
                        }
                        else {
                            setProfilingBatchState("in progress")
                        }
                        break
                    }
                    case "ok": {
                        setProfilingBatchState("completed")
                        setProfilingBatch(result.data)
                        break
                    }
                    case "ko": {
                        setProfilingBatchState("some errors were encountered,enable to execute profiling job")
                        break
                    }
                    default:
                        break
                }
            }
            )
        }

    }, 3000)

    function checkStatusProfilingRun() {
        if (profilingRunId != undefined) {

            dispatch(actions.getJobRunOutputForUser(profilingRunId, user.id, selectedEnvironment)).then(output => {
                if (output && output.data && output.data.state === 'FAILED') {
                    dispatchError(dispatch, output.data.errorMessage)
                    setProfilingState(output.data.errorMessage)
                    setProfilingdataformatId(undefined)
                    setProfilingBatchId(undefined)
                    setProfilingVisible(false)
                }
            })
        }
    }

    function checkProfilingsState(profilingBatch) {
        let numberOfFinishedProfilingJobs = 0
        for (let i = 0; i < profilingBatch.dataformatsWithProfiling.length; i++) {
            if (profilingBatch.dataformatsWithProfiling[i].profilingStatus && profilingBatch.dataformatsWithProfiling[i].profilingStatus === "ok") {
                numberOfFinishedProfilingJobs += 1
            }
        }
        return numberOfFinishedProfilingJobs
    }

    function createAndContinue() {

        if (schema !== undefined && selectedItem !== undefined) {

            resetProfilingState()
            const dataset = helper.convertSchema2Datasource(schema, user, selectedItem, fileOutputName, delimiter, format, dataPath)
            if (props.createNewVersion && props.createNewVersion === true && props.selectedDataset) {
                dispatch(actions.createNewDataformatVersion(dataset, props.selectedDataset._id))
            }
            else {
                dispatch(actions.addDataFormatObject(dataset))
            }

            setVisiblePreviewCallback(true)
            dispatch(actions.fetchAllDataFormats(user))
            setPreviewDialogParsingData(false)
            setPreviewDialogVisible(false)
        
        }
    }

    function setVisiblePreviewCallback(value) {
        setProfilingVisible(false)
        setPreviewDialogParsingData(true)
        setPreviewDialogVisible(true)

    }

    return (
        <>
            {/* <LocalConnectionDialog visible={showConnectionDialog} setVisible={setShowConnectionDialog} /> */}

            <AqtivaDialog visible={props.visible}
                completeScreen={true}
                fullWidth={true}
                title={'Inspect and Select Azure Datasource'}
                titleIcon={faWindows}
                confirmText={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? 'Create new version' : 'Create'}
                cancelText={'Close'}
                showConfirmButton={(schema !== undefined && schema.fields !== undefined) ? true : false}
                confirmCallback={() => { convertSchema2Datasource() }}
                cancelCallback={() => {
                    dispatch(actions.fetchDataFormats(user))
                    dispatch(actions.fetchAllDataFormats(user))
                    props.setVisible(false)
                }}
            >

                <div style={{ width: '100%', height: '500px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div style={{ ...theme.elementAdjacent, marginBottom: '-20px', marginLeft: '10px' }}>
                                <div style={{ width: '150px', marginRight: '10px' }}>
                                    <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                                        setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                                </div>
                                {isAzureUser()===true && 
                                <FormControl style={{ ...theme.config.formControl, minWidth: '300px', marginRight: '20px', marginTop: '5px' }}>
                                    <InputLabel id="selectSubscription">Select a Subscription</InputLabel>
                                    <Select
                                        labelId="dselectSubscription"
                                        id="selectSubscription"
                                        value={selectedSubscription}
                                        onChange={event => setSelectedSubscription(event.target.value)}
                                    >
                                        {subscriptions && subscriptions.length > 0 && subscriptions.map(x => {
                                            return <MenuItem value={x.subscriptionId} key={x.subscriptionId}>
                                                <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                    <img alt="" style={{ height: '20px', width: '20px' }}
                                                        src={require('resources/icons/azure/azureSubscriptions.jpg')} />
                                                    <Typography variant='subtitle2'>{x.displayName}</Typography>
                                                </div>
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                }
                                {isAzureUser()===true && 
                                <div style={{ width: '200px', marginRight: '30px' }}>
                                    <FilterByRGComponentAutocomplete
                                        selectedResourceGroup={selectedResourceGroup}
                                        setSelectedResourceGroup={setSelectedResourceGroup}
                                        resourceGroups={resourceGroups} />
                                </div>
                                }
                                {undefined && <FormControlLabel style={theme.config.formControl}
                                    control={
                                        <TextField
                                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                            id="nSamples"
                                            label={<Typography variant="caption">Samples to Preview</Typography>}
                                            style={theme.config.textField}
                                            margin="dense"
                                            value={numberSamples}
                                            onChange={(event) => setNumberSamples(event.target.value)}
                                        />} />}
                                <FormControlLabel style={theme.config.formControl}
                                    control={
                                        <TextField
                                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                            id="nSamples"
                                            label={<Typography variant="caption">Profiling Samples</Typography>}
                                            style={theme.config.textField}
                                            margin="dense"
                                            value={profilingSamples}
                                            onChange={(event) => setProfilingSamples(event.target.value)}
                                        />} />

                                <FormControl style={{ ...theme.config.formControl, minWidth: '300px', marginRight: '20px', marginTop: '5px' }}>
                                    <InputLabel id="selectSubscription">Select Format</InputLabel>
                                    <Select
                                        labelId="dselectSubscription"
                                        id="selectSubscription"
                                        value={selectedFormat}
                                        onChange={event => setSelectedFormat(event.target.value)}
                                    >
                                        {datasetFormats.map(x => {
                                            return <MenuItem value={x} key={x}>
                                                <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                    <Typography variant='subtitle2'>{x}</Typography>
                                                </div>
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                {undefined &&  <FormControlLabel style={theme.config.formControl}
                                    control={
                                        <Switch
                                            checked={flattenFlag}
                                            onChange={event => {
                                                setFlattenFlag(event.target.checked)
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant="caption">Flatten Nested Structures</Typography>}
                                />}

                            </div>
                        </Grid>




                        <Grid item xs={4}>

                            {/* <Button onClick={event => { setShowConnectionDialog(true); trackEvent('connect with local storage', 'connect with local storage Button') }}
                                style={{ marginTop: '20px' }}
                                startIcon={<FontAwesomeIcon icon={faPlug} style={{ fontSize: 15 }} />}
                            >
                                <div style={theme.common.text.buttonTextSmall}>
                                    connect to an Azure resource
                                </div>
                            </Button> */}
                            
                            <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginTop: "20px" }}>Local Storages</Typography>
                            <SelectAzureItemDialogLocal connectToLocalStorages={connectToLocalStorages} setConnectToLocalStorages={setConnectToLocalStorages}
                                selectedSubscription={selectedSubscription} setSelectedItem={setSelectedItem}
                                setProfilingdataformatId={setProfilingdataformatId}
                                setProfilingBatchId={setProfilingBatchId}
                                setRunId={setRunIdAndRestart}
                                setVisiblePreview={setVisiblePreviewCallback}
                                setFileOutputName={setFileOutputName}
                                numberSamples={numberSamples}
                                profilingSamples={profilingSamples}
                                flattenFlag={flattenFlag}
                                setResourceGroups={setResourceGroupsAndSelect}
                                selectedResourceGroup={selectedResourceGroup}
                                alreadyDefinedDataformats={alreadyDefinedDataformats}
                                selectedEnvironment={selectedEnvironment}
                                azureManager={props.azureManager}
                                selectedFormat={selectedFormat}
                                resetProfilingState={resetProfilingState}
                            />

                            {isAzureUser()===true && 
                            <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginTop: '10px' }}>Subscription Storages</Typography>
                            }
                              {isAzureUser()===true && 
                            <TreeViewStorageRest selectedSubscription={selectedSubscription} setSelectedItem={setSelectedItem}
                                setProfilingdataformatId={setProfilingdataformatId}
                                setProfilingBatchId={setProfilingBatchId}
                                setProfilingVisible={setProfilingVisible}
                                setRunId={setRunIdAndRestart}
                                setVisiblePreview={setVisiblePreviewCallback}
                                setFileOutputName={setFileOutputName}
                                numberSamples={numberSamples}
                                profilingSamples={profilingSamples}
                                flattenFlag={flattenFlag}
                                profilingRunId={setProfilingRunId}
                                setResourceGroups={setResourceGroupsAndSelect}
                                selectedResourceGroup={selectedResourceGroup}
                                alreadyDefinedDataformats={alreadyDefinedDataformats}
                                selectedEnvironment={selectedEnvironment}
                                selectedFormat={selectedFormat}
                                azureManager={props.azureManager}
                                resetProfilingState={resetProfilingState} />
                              }
                        </Grid>

                        <Grid item xs={8}>
                            {loadingPreview &&
                                <AqtivaLoading />
                            }
                            {!loadingPreview &&
                                <>
                                    <ComponentPreviewData
                                        selectedItem={selectedItem}
                                        visible={previewDialogVisible}
                                        setVisible={setPreviewDialogVisible}
                                        setVisibleFull={setPreviewDialogVisibleFull}
                                        parsingData={previewDialogParsingData}
                                        fileOutputName={fileOutputName}
                                        schema={schema}
                                        errorBackend={errorBackend}
                                        stats={stats}
                                        delimiter={delimiter}
                                        format={format}
                                        dataPath={dataPath}
                                        outputPath={outputPath}
                                        dataCount={dataCount}
                                        selectedEnvironmentStates={selectedEnvironmentStates}
                                        selectedEnvironment={selectedEnvironment} createAndContinue={createAndContinue} />
                                    <ComponentProfilingStatus profilingState={profilingState} visible={profilingVisible} dataformatWithProfiling={dataformatWithProfiling} createAndContinue={createAndContinue} />
                                    <ComponentProfilingBatchStatus profilingBatchState={profilingBatchState} visible={profilingVisible} setProfilingBatch={setProfilingBatch} profilingBatch={profilingBatch} createAndContinue={createAndContinue} />
                                </>
                            }
                        </Grid>

                    </Grid>
                </div>

            </AqtivaDialog>
        </>
    )
}
