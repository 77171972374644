import React from 'react'; 
import { Card, CardContent, Typography, Switch, FormControlLabel } from '@material-ui/core';
import * as commonStyles from 'style/CommonStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CardProps = {
    children: React.ReactNode; // 👈️ type children
    icon: any,
    title: string,
    textOption : string,
    setSecondaryOption : (value: any) => (void),
    secondaryFlag: boolean
  };

export default function ComponentCard(props: CardProps) {

    

    const toggleChecked = () => {
        
        props.setSecondaryOption(!props.secondaryFlag)
        
      };

     

    return (
        <Card elevation={12}
                    style={{ width: '100%', minHeight: '100%' }}>

                    <CardContent style={{ marginBottom: '-25px' }}>
                    <div style={commonStyles.adjacentSpaceBetweenGood}>
                        <div style={{...commonStyles.adjacentLeft, width:'50%'}}>
                            <FontAwesomeIcon icon={props.icon} style={{ color: commonStyles.mainColor, fontSize: 15, marginTop:'-10px' }} />
                            <Typography variant='subtitle2' style={{ marginLeft: '2%'}}> {props.title}</Typography>
                        </div>

                        <FormControlLabel
                            control={<Switch size="small" checked={props.secondaryFlag} onChange={toggleChecked} />}
                            label={<Typography variant='subtitle2' > {props.textOption}</Typography>}
                        />
                    </div>
                    </CardContent>
                    {props.children}
                    </Card>
    )
}
