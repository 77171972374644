import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as commonStyles from 'style/CommonStyles'

export default function RulesScoreChartSmall(props) {

   

    

    React.useLayoutEffect(() => {
        let root = am5.Root.new(props.chart);

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                layout: root.horizontalLayout,
                arrangeTooltips: false
            })
        );
        chart.zoomOutButton.set("forceHidden", true);
        // Data
        let data = [{
            category: "Search engines",
            negative1: -(100 - props.score),
            positive1: props.score,
        }];

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

        let renderer = am5xy.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
        });
        renderer.labels.template.setAll({
            visible: false
        })

        let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: renderer
            })
        );

        yAxis.data.setAll(data);

        let rerenderX = am5xy.AxisRendererX.new(root, {
            minGridDistance: 50
        })
        rerenderX.labels.template.setAll(({
            visible: false
        }))
        let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                calculateTotals: true,
                min: -100,
                max: 100,
                renderer: rerenderX
            })
        );

        let xRenderer = yAxis.get("renderer");
        xRenderer.axisFills.template.setAll({
            fill: am5.color(0x000000),
            fillOpacity: 0.05,
            visible: false
        });

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function createSeries(field, name, color, negative) {
            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: field,
                    categoryYField: "category",
                    sequencedInterpolation: true,
                    stacked: true,
                    fill: color,
                    stroke: color,
                    calculateAggregates: true
                })
            );

            series.columns.template.setAll({
                height: am5.p100
            });

            series.bullets.push(function (_root, _serie, item) {
                return am5.Bullet.new(root, {
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        centerY: am5.p50,
                        text: "[#000000R]{valueX}%",
                        fontFamily: commonStyles.fontFamily,
                        fontSize: commonStyles.tableCellFontSize,
                        locationY: 0.5,
                        populateText: true,
                        oversizedBehavior: negative && (item.dataContext.negative1 > -10 && item.dataContext.negative1 < 0)  ? 'hide' : 'show'
                    })
                });
            });

            series.data.setAll(data);
            series.appear();

            return series;
        }
        let positiveColor = commonStyles.colorOkTransp;
        let negativeColor = commonStyles.colorKoTransp;

        if (data[0].negative1 !== 0) createSeries("negative1", "Never", negativeColor, true);
        if (data[0].positive1 !== 0) createSeries("positive1", "Very often", positiveColor, false);

        return () => {
            chart.dispose()
        }

    }, [props.score]) // eslint-disable-line react-hooks/exhaustive-deps

    if(props.fullscreen === true){
        return (
          <div id={props.chart} style={{ width: props.width ? props.width : '100%', height:"100px", marginTop:'0px', 
          marginLeft: props.marginLeft ? props.marginLeft : '0px', marginBottom:  '0px' }}></div>
      )
      }
      else{
        return (
          <div id={props.chart} style={{ width: props.width ? props.width : '100%', height: props.height ? props.height :"100px", marginTop:'-15px', 
          marginLeft: props.marginLeft ? props.marginLeft : '0px', marginBottom: props.marginBottom ? props.marginBottom : '0px' }}></div>
      )
      }
}