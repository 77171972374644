import React, { useEffect, useState } from 'react';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { Grid, Button, Typography, InputAdornment, FormControlLabel, Switch, Tooltip,TextField } from '@material-ui/core'
import AqtivaDialog from 'components/common/AqtivaDialog'
import trackEvent from 'trackEvent';
import { useSelector, useDispatch } from 'react-redux';
import InputFile from 'components/common/azure-storage/components/InputFile'
import * as actions from 'actions'
import CustomInput from "components/common/CustomInput/CustomInput.js";
import TablePrevisualization from 'components/dialogs/DialogCreateDataFormat/TablePrevisualization'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogEditRecommendedRules from 'components/dialogs/DialogEditRecommendedRules'
import RuleRecommenderAdvanceConfiguration from './RuleRecommenderAdvanceConfiguration'
import QualityItemSelector from 'components/common/QualityItemSelector'
import * as configurationHelper from 'components/common/configurationHelper'
import { useTheme } from '@material-ui/core/styles';
import DialogSelectAzureSource from 'components/dialogs/DialogSelectAzureSource/index.js';
import DialogCreateDataFormat from 'components/dialogs/DialogCreateDataFormat'

import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'

import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent';

import QualityDatasetRepositoryPage from 'components/data/DataPageForm'
import { AqtivaDialogDataRepository } from 'components/data/DataRepositoryShare/EntityDataRepository/AqtivaDialogDataRepository'
import { dispatchError } from 'components/common/axios/axiosHelper';

import { isEmpty } from 'commonFunctions/commonFunctions'
import { getUserPreferenceProperty } from 'components/common/userHelper'

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'


export default function DialogRecommendedRules(props) {
    const languageContext = React.useContext(LanguageContext);

    const dispatch = useDispatch();
    const theme = useTheme()

    const env = useSelector(store => store.environment)
    const dataformats = useSelector(store => Object.values(store.dataformats))

    const { configuration, open, setClose, userId, recommenderShowNotification } = props;
    const [uploadFileName, setUploadFileName] = useState('')
    const [error, setError] = useState({})
    const [separator, setSeparator] = React.useState('automatic')
    const [colFormats, setColFormats] = React.useState(props.datasetFromParams ? props.datasetFromParams.columns : [])
    const [checkAutomaticSeparator, setCheckAutomaticSeparator] = React.useState(true)
    const [dataset, setDataset] = React.useState(props.datasetFromParams ? props.datasetFromParams : undefined)
    const [parsingData, setParsingData] = React.useState(false)
    const [openEditRecommendedRulesDialog, setOpenEditRecommendedRulesDialog] = useState(false)

    const [rowLimit, setRowLimit] = React.useState(100000)

    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState()
    const [runId, setRunId] = useState()
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [selectedEnvironment2, setSelectedEnvironment2] = React.useState('')

    const [jsonConfig, setJsonConfig] = React.useState(undefined)

    const [selectedDataFormat, setSelectedDataFormatState] = React.useState(undefined)


    const [showDialogCreateDataformatAzure, setShowDialogCreateDataformatAzure] = React.useState(false)
    const [showDialogCreateDataformat, setShowDialogCreateDataformat] = React.useState(false)

    const [openDialogDataRepository, setOpenDialogDataRepository] = React.useState(false)

    const user = useSelector(store => store.user)
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))

    const [selectedDataformatIdFromDialog, setSelectedDataFormatIdFromDialog] = React.useState(undefined) // si llamamos al dialogo de seleccionar dataformat, el id seleccionado

    function setSelectedDataFormat(value) {
        if (value && selectedDataFormat && value._id === selectedDataFormat._id) {
            return
        }
        if (value !== undefined) {
            setSelectedDataFormatState(value)
        }


    }


    function reset() {
        setParsingData(false)
        setOpenEditRecommendedRulesDialog(false)
        setRunId(undefined)
        setShowDialogCreateDataformat(false)
        setShowDialogCreateDataformat(false)
        setOpenDialogDataRepository(false)
    }

    function onChangeDFCallback(event) {
        setSelectedDataFormat(event.target.value)
        setUploadFileName('')
    }

    React.useEffect(() => {

        if (dataformats === undefined || dataformats.length === 0) {
            //fetch recent dataformats
            dispatch(actions.fetchDataFormats(user))
        }
    }, [dataformats])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchConfigurationByUserId(user.id))

        }

    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        validate()

    }, [uploadFileName, separator, colFormats, selectedQualityPoint, selectedEnvironment, selectedDataFormat]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchConfigurationByUserId(user.id))
            //dispatch(actions.fetchDataFormats(user))
        }

    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (qualityConfiguration && qualityConfiguration.environmentConfigurationList &&
            configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id).length > 0) {
            setSelectedEnvironment(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)[0].environmentName)
        }


    }, [qualityConfiguration])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        setColFormats(props.datasetFromParams ? props.datasetFromParams.columns : [])
        setDataset(props.datasetFromParams ? props.datasetFromParams : undefined)
    }, [uploadFileName]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        
        if (selectedDataFormat) {
            setColFormats(selectedDataFormat.columns)
            setDataset(selectedDataFormat)
        }

    }, [selectedDataFormat]);

    useEffect(() => {
        if (props.project !== undefined && props.project.qualityPoints !== undefined && props.project.qualityPoints.length > 0) {
            setSelectedQualityPoint(props.project.qualityPoints[0])
        }
    }, [props.project]);



    function setSelectedEnvironmentTest(event) {
        setSelectedEnvironment(event)
        setSelectedEnvironment2(event)

    }

    function onCloseDialogRepository(value) {
        dispatch(actions.fetchDataFormats(user))
        setOpenDialogDataRepository(value)
    }

    function handleSelectDatasetForExportationId(id) {
        setSelectedDataFormatIdFromDialog(id)
    }

    async function obtainAndSelectDataformatFromId() {
        //lo primero obtenemos el dataformat del id
        if (selectedDataformatIdFromDialog !== undefined) {
            dispatch(actions.fetchDataformat(selectedDataformatIdFromDialog)).then(response => {
                setSelectedDataFormat(response.data)


            })




        }
    }



    useEffect(() => {
        if (recommenderShowNotification === true) {
            setOpenEditRecommendedRulesDialog(recommenderShowNotification)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.datasetFromParams !== undefined) {
            setColFormats(props.datasetFromParams ? props.datasetFromParams.columns : [])
            setDataset(props.datasetFromParams ? props.datasetFromParams : undefined)

        }
    }, [props.datasetFromParams]);




    function validate() {
        var errors = {};
        if ((!uploadFileName || uploadFileName === null || uploadFileName === '') && selectedDataFormat === null) {
            errors.colFormats = 'You must upload a sample File';
        }

        if (props.qpId === undefined && (!selectedQualityPoint || selectedQualityPoint === null)) {
            errors.selectedQualityPoint = 'You must select a Quality Point';
        }

        if (!selectedEnvironment || selectedEnvironment === null) {
            errors.selectedEnvironment = 'You must selected an environment';
        }
        setError(errors)
        return errors;
    }


    async function onUpload(fileName) {
        setSelectedDataFormat(undefined)
        setUploadFileName(fileName)
        trackEvent('Rule Recommender', 'Upload Data File')

        var sep = separator
        if (sep === ";") {
            sep = "semicolon"
        }
        else if (sep === "|") {
            sep = "pipe"
        }
        setParsingData(true)
        var env2Send = env
        if (env === null || isEmpty(env)) {
            env2Send = getUserPreferenceProperty(user, 'selectedEnvironment')
        }
        const response = await dispatch(actions.parseDataFormatBlob(sep, fileName, user.id, "rulerecommender", env2Send))
        setParsingData(false)
        setColFormats(response.data.columns)
        setDataset(response.data)
    }

    async function sendFileInterface() {
        var qp = selectedQualityPoint !== undefined ? selectedQualityPoint._id : props.qpId


        //dependiendo del tipo de dataformat que se haya subido se utilizará una función u otra
        if (selectedDataFormat !== undefined) {
            //hemos elegido un dataformat
            if (selectedDataFormat.source === 'azure-storage' ||
                selectedDataFormat.source === 'azure-cosmos' ||
                selectedDataFormat.source === 'azure-sqldatabase' ||
                selectedDataFormat.source === 'azure-sqlsynapse' ||
                selectedDataFormat.source === 'sqlServer' ||
                selectedDataFormat.source === 'mySQL' ||
                selectedDataFormat.source === 'oracle' ||
                selectedDataFormat.source === 'bigQuery' ||
                selectedDataFormat.source === 'awsS3' ||
                selectedDataFormat.source === 'hive' ||
                selectedDataFormat.source === 'awsRedshift'
                ) {
                //llamamos al método para azure storage
                sendFileAzureFormat(selectedDataFormat._id, qp)
            }
            else {
                sendFileUploadFile(qp)
            }
        }
        else {
            if (uploadFileName !== '') {
                //hemos subido un fichero
                sendFileUploadFile(qp)
            }
        }
    }

    async function sendFileAzureFormat(dataformatId, qp) {
        if (props.qpId !== undefined || selectedQualityPoint !== undefined) {
            let environment = qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment2)[0]
            await dispatch(actions.getSecret(environment.storageKey))
            //let connStr = `DefaultEndpointsProtocol=https;AccountName=${environment ? environment.storageAccountName : 'sainputdemoquality'};AccountKey=${storageKey};EndpointSuffix=core.windows.net`
            let ruleRecommenderPostDTO = {
                //connection??
                dataformatId: dataformatId,
                jsonConfig: JSON.stringify(jsonConfig)
            }
            setOpenEditRecommendedRulesDialog(true)
            let recommJobDTO = await dispatch(actions.postDataForRecommendationAzureFormat(selectedEnvironment2, userId, ruleRecommenderPostDTO, props.projectId, qp, rowLimit))
            if (recommJobDTO !== undefined && recommJobDTO.data !== undefined) {
                const run_id = recommJobDTO.data.run_id
                setRunId(run_id)
            }
        }



    }

    async function sendFileUploadFile(qp) {
        if (props.qpId !== undefined || selectedQualityPoint !== undefined) {
            if (dataset === undefined || dataset.fileStorageProperties === undefined ||
                dataset === null || dataset.fileStorageProperties === null ||
                dataset.fileStorageProperties.container === undefined ||
                dataset.fileStorageProperties.path === undefined ||
                dataset.fileStorageProperties.account === undefined) {
                dispatchError(dispatch, "Dataformat without storage info. Please upload your local file again")
            }
            else {
                //let environment = qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]
                //let storageKey = await dispatch(actions.getSecret(environment.storageKey))
                let ruleRecommenderPostDTO = {
                    //connection??
                    account: dataset.fileStorageProperties.account,
                    containerName: dataset.fileStorageProperties.container,
                    blobPath: dataset.fileStorageProperties.path,
                    separator: dataset ? dataset.separator : ",",
                    jsonConfig: JSON.stringify(jsonConfig)
                }
                setOpenEditRecommendedRulesDialog(true)
                let recommJobDTO = await dispatch(actions.postDataForRecommendation(selectedEnvironment2, userId, ruleRecommenderPostDTO, props.projectId, qp))
                if (recommJobDTO && recommJobDTO.errorMessage) {
                    //error
                    reset()

                }
                if (recommJobDTO !== undefined && recommJobDTO.data !== undefined) {
                    const run_id = recommJobDTO.data.run_id
                    setRunId(run_id)
                }
            }
        }



    }

    function setColFormatsCallback(newFormat) {
        setColFormats(newFormat)
    }
    function onChangeSeparator(event) {
        setSeparator(event.target.value)
    }

    function setCloseAll() {
        setOpenEditRecommendedRulesDialog(false)
        props.setClose(false)
    }




    return (
        <div>
            {showDialogCreateDataformatAzure &&
                <DialogSelectAzureSource visible={showDialogCreateDataformatAzure} setVisible={setShowDialogCreateDataformatAzure} configuration={props.configuration}
                    setLocalFileDialogVisible={setShowDialogCreateDataformat} />
            }
            {showDialogCreateDataformat &&
                <DialogCreateDataFormat
                    configuration={props.configuration}
                    open={showDialogCreateDataformat}
                    setClose={setShowDialogCreateDataformat} />
            }
            {openDialogDataRepository && <AqtivaDialogDataRepository
                dialog={openDialogDataRepository}
                setDialog={onCloseDialogRepository}
                title="Data Repository"
                showConfirmButton={true}
                fullWidth={true}
                maxWidth={'xl'}
                confirmText={'Select DataFormat'}
                titleIcon={faDatabase}
                confirmCallback={(event) => { obtainAndSelectDataformatFromId(); setOpenDialogDataRepository(false) }}
            >
                <QualityDatasetRepositoryPage selectDatasetDialog={true} user={user} defaultValue={1}
                    handleSelectDatasetForExportationId={handleSelectDatasetForExportationId} />
            </AqtivaDialogDataRepository>}

            <DialogEditRecommendedRules open={openEditRecommendedRulesDialog} setClose={setCloseAll}
                configuration={configuration}
                runId={runId}
                dataset={dataset}
                setDataset={setDataset}
                projectId={props.projectId}
                qpId={props.qpId !== undefined ? props.qpId : selectedQualityPoint !== undefined ? selectedQualityPoint._id : undefined}
                recommenderShowNotification={recommenderShowNotification}
                //ruleDTOId = {ruleDTOId}
                userId={user.id}
                history={props.history}
                environment={(qualityConfiguration && qualityConfiguration.environmentConfigurationList) ?
                    qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment2)[0] : undefined}
            />

            <AqtivaDialog visible={open}
                maxWidth={'md'}
                editable={false}
                title={'Rule recommender'}
                titleIcon={faProjectDiagram}
                confirmText={'Send'}
                cancelText={'Close'}
                editText={'Edit'}
                confirmCallback={event => {
                    const results = validate()
                    if (isEmpty(results)) {
                        sendFileInterface()
                    }

                }}
                cancelCallback={event => {
                    setClose(false)
                }}
                editCallback={event => { return event; }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Grid container spacing={2} >
                            {props.qpId === undefined && props.project !== undefined &&
                                <Grid item xs={8}>
                                    <QualityItemSelector
                                        title={'Select a Quality Point'} type='QualityPoint'
                                        value={selectedQualityPoint}
                                        onChange={event => { setSelectedQualityPoint(event.target.value); }}
                                        itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints.filter(x => x.active === true) : [])}
                                        itemId={'_id'} itemValue={'name'}
                                    />
                                    {error && error.selectedQualityPoint &&
                                        <div className="ui error message" style={theme.errors.errorText}>
                                            <div className="header" >{error.selectedQualityPoint}</div>
                                        </div>}
                                </Grid>
                            }
                            <Grid item xs={7} style={{ marginTop: '-10px' }}>
                                <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironmentTest} />

                                {error && error.selectedEnvironment &&
                                    <div className="ui error message" style={theme.errors.errorText}>
                                        <div className="header" >{error.selectedEnvironment}</div>
                                    </div>}
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkAutomaticSeparator}
                                            onChange={event => {
                                                setCheckAutomaticSeparator(event.target.checked);
                                                setSeparator('automatic')
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant='body2'> Automatic Separator Estimation</Typography>}
                                />
                            </Grid>

                            {!checkAutomaticSeparator &&
                                <Grid item xs={12}>
                                    <CustomInput
                                        labelText="Separator"
                                        id="separator_id"
                                        formControlProps={{
                                            fullWidth: false,
                                            style: { width: '30%' }
                                        }}

                                        inputProps={{
                                            type: "text",
                                            onChange: onChangeSeparator,
                                            value: separator,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FontAwesomeIcon icon={faCode} style={{ fontSize: 20 }} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>Upload a Sample File </Typography>


                                <div style={{ marginTop: '5%' }}>
                                    <InputFile onUpload={onUpload} folder={`${userId}/stagging`}
                                        storageAccountName={selectedEnvironment && qualityConfiguration.environmentConfigurationList ? qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].storageAccountName : 'sainputdemoquality'}
                                        containerName={'rulerecommender'}
                                        sasToken={selectedEnvironment && qualityConfiguration.environmentConfigurationList ? qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].sasToken : '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'}
                                        buttonText={'Upload File'} />
                                </div>

                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='subtitle2'>or Select/Create a Dataformat  </Typography>
                                <div>
                                    <div style={theme.elementAdjacent}>
                                        <div style={{ width: '90%' }}>

                                            <QualityItemSelector
                                                title={languageContext.dictionary['selectDataformat']} type='DataFormat' value={selectedDataFormat} onChange={onChangeDFCallback}
                                                itemsArray={dataformats}
                                                itemId={'_id'} itemValue={'name'}
                                            />
                                        </div>
                                        <Tooltip title='Import DataFormat from Data Repository'>
                                            <div>
                                                <FontAwesomeIcon icon={faFileImport}
                                                    style={{ color: commonStyles.mainColor, fontSize: '20px', marginTop: '30px', marginLeft: '-60px', cursor: 'pointer' }}
                                                    onClick={event => {
                                                        trackEvent('DialogCreateDataSource', 'Data Repository')
                                                        setOpenDialogDataRepository(true)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={10}>


                                <Button variant='outlined' style={theme.buttons.buttonConfirm}
                                    onClick={event => {
                                        trackEvent('DialogCreateDataSource', 'Add Dataformat Azure')
                                        if (process.env.REACT_APP_AZURE_SOURCES && process.env.REACT_APP_AZURE_SOURCES === 'true') {
                                            setShowDialogCreateDataformatAzure(true)
                                        }
                                        else {
                                            setShowDialogCreateDataformat(true)
                                        }
                                    }}>
                                    <i className="icon plus"></i>
                                    {languageContext.dictionary["addDataformat"]}
                                </Button>


                            </Grid>
                            <Grid item xs={10}>
                                 <div >
                                    <Typography variant='subtitle2'>Select max number of rows to simulate</Typography>
                                    <TextField type='number'
                                                value={rowLimit}
                                                onChange={event => setRowLimit(event.target.value)}
                                                style={{ wdith: '100%' }}
                                                autoComplete='off'
                                                margin="dense"
                                     />
                                 </div>
                            </Grid>

                           




                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{ marginTop: '10px' }}>


                            <TablePrevisualization
                                columnFormats={colFormats !== undefined && colFormats.length > 0 ? colFormats :
                                    selectedDataFormat !== undefined ? selectedDataFormat.columns : []}
                                setColumnFormats={setColFormatsCallback}
                                parsingData={parsingData}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12}>
                        <RuleRecommenderAdvanceConfiguration
                            setJsonConfig={setJsonConfig}
                        />
                    </Grid>


                </Grid>

            </AqtivaDialog>
        </div>
    )
}



