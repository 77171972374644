
import React from 'react'
import { Button } from '@material-ui/core'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogSimulation from 'components/dialogs/DialogSimulation'

import trackEvent from 'trackEvent'
import { useTheme } from '@material-ui/core/styles';


export default function SimulateButton(props) {


    const theme = useTheme();
    const [showDialog, setShowDialog] = React.useState(false)

    function onClickSimulate() {

    }
    function onClickCloseSimulation() {
        setShowDialog(false)
    }

    return (
        <div>
            <DialogSimulation
                userId={props.user.id}
                configuration={props.qualityConfiguration}
                history={props.history}
                visible={showDialog}
                onClickSimulate={onClickSimulate}
                onClose={onClickCloseSimulation}
                onHide={onClickCloseSimulation}
                project={props.project}
                currentUser={props.user} />

            <Button onClick={event => { if(props.visible===true) {setShowDialog(true); trackEvent('DetailProject', 'Simulate Button')} }}
                style={props.visible===true ? theme.buttons.buttonRegular : theme.buttons.buttonRegularDisabled }
                startIcon={<FontAwesomeIcon icon={faPlayCircle}  style={{ fontSize:15}} />}
            >
                <div style={theme.common.text.buttonTextSmall}>
                Simulate
                </div>
                  
                        
                </Button>

          
       </div >
    )

}