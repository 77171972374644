import {FETCH_ENVIRONMENT,
        UPDATE_ENVIRONMENT
    } from '../actions/types'


export default (state={},action) => {
    
    switch(action.type){
        case FETCH_ENVIRONMENT:

            if(action.payload !== undefined){
                return action.payload;
            }
            else{
                return state;
            }
        case UPDATE_ENVIRONMENT:
            if(action.payload !== undefined){
                return action.payload;
            }
            else{
                return state;
            }
            
        default:
            return state;
        
    }
}