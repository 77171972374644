export const mainColor = '#2F2CA9'
export const darkColor = '#000000'
export const mainColorOppacity = '#2F2CA933'
export const mainColorSmallOppacity = '#2F2CA9AA'

export const secondaryColor = '#545454'
export const terciaryColor = '#deb41b'
export const folderColor = '#cc9c33'
export const lightGrey = '#8c8c8c'

export const redColor = "#F52400"


export const fontFamily= 'Roboto'

export const tableCellFontSize = '13px'
export const tableHeaderFontSize = '15px'

export const errorColor = '#ab261d'
export const warningColor = '#9e7523'

export const selectDatasourceIconSize="40px"

export const checkIconStyle = { color: "#3dad5b", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }

export const tableHeaderStyle = {
  fontSize: 'calc(10px + 0.2vw)',
  backgroundColor: mainColorOppacity,
  color: mainColor,
  fontWeight: 'bold',
  fontFamily: fontFamily
}
export const tableCellStyle = { fontSize: 'calc(8px + 0.2vw)', fontFamily: fontFamily }
export const tableCellStylePx = { fontSize: '15px', fontFamily: fontFamily }

export const tableFilterCellStyle = { fontSize: 'calc(8px + 0.2vw)', fontFamily: fontFamily }

export const appBarIconSelectedStyle = { height: '30px', width: '30px', marginLeft: '-5px' }
export const appBarIconStyle = { height: '22px', width: '22px' }

export const homeProjectTableHeaderStyle = {
  fontSize: 'calc(10px + 0.2vw)',
  fontFamily: fontFamily,
  fontWeight: 'bold',
  backgroundColor: '#FFF',
  color: '#000',
  borderBottomStyle: 'solid',
  borderBottomColor: mainColor,
  borderBottomWidth: '1px'
}

export const projectTableIconSize = 'calc(9px + 0.3vw)'
export const projectTableIconRoleSize = 'calc(12px + 0.3vw)'
export const projectTableIconHiveRoleSize = 'calc(14px + 0.3vw)'

export const projectTableQPTextSize = 'calc(10px + 0.3vw)'
export const projectTableDSTextSize = 'calc(8px + 0.3vw)'
export const projectTableRuleTextSize = 'calc(8px + 0.3vw)'

export const adjacentLeft = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left'
}

export const adjacentSpaceBetween = {display: 'flex',
alignItems: 'center',
justifyContent: 'space-betwewen' }

export const adjacentSpaceBetweenGood = {display: 'flex',
alignItems: 'center',
justifyContent: 'space-between' }

//Dashboard rafa 

export const colorOk = '#10a333'
export const colorKo = '#d41919'
export const colorOkTransp = '#10a333AA'
export const colorKoTransp = '#d41919AA'

export const colorRemaining = '#DEDEDE'


export const DashboardChartComponentTitle = {
  width: '100%', height: '30px', backgroundColor: mainColor, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
  borderTopLeftRadius: '6px', borderTopRightRadius: '6px'
}
export const DashboardChartComponentTitleDark = {
  width: '100%', height: '30px', backgroundColor: darkColor, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
  borderTopLeftRadius: '6px', borderTopRightRadius: '6px'
}
export const titleBox = {
  width: "100%", boxShadow: '-3px 4px 4px 0px rgba(153,153,153,0.67)', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px'
}

export const titleText = {
  fontWeight: 'bold', color: '#FFFFFF', fontFamily: fontFamily, flexGrow: 1, textAlign: "center", fontSize: 'calc(10px + 0.2vw)',
}

export const tabsDataSource = {
  width: '100%', paddingTop: '-20px', marginLeft: '1.5%', boxShadow: '-3px 4px 4px 4px rgba(153,153,153,0.67)', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px'
}

export const btnBack = {
  color: '#FFFFFF', fontFamily: fontFamily, flexGrow: 1, textAlign: "center", marginRight: '5%'
}

export const inputLabel = {fontSize:11}
export const inputLabelBig = {fontSize:13}