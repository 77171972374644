
import React from 'react';

import { Grid,Typography} from '@material-ui/core'

import ThresholdCard from './ThresholdCard'
import OutputControlCard from './OutputControlCard'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';

import { faCog } from '@fortawesome/free-solid-svg-icons'
import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'


export default function AdvanceConfigurationQuality(props) {

    const languageContext = React.useContext(LanguageContext); 

    
    const theme = useTheme()

    function isValid(){
        if(props.thWarning && props.thError && props.controlObj && props.controlObj.wrongRegisterAction && props.controlObj.outputMode){
            return true
        }
        return false
    }

    
   
    return(
                <Accordion
                style={{width:'100%'}}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                         <Grid item xs={1}>
                                        <FontAwesomeIcon icon={faCog} style={{color:commonStyles.mainColor, fontSize:20}} />
                                        </Grid>
                                        <Grid item xs={11}>
                                             <Typography variant = 'subtitle2'>{languageContext.dictionary['thBlockTitle']}</Typography>
                                             {!isValid() && 
                                           
                                                <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >{languageContext.dictionary['thBlockTitleError']}</div>
                                                </div>
                                            
                                                    }
                          
                                        </Grid>
                                        
                                </AccordionSummary>
                                <AccordionDetails>
                                <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                        <ThresholdCard title={languageContext.dictionary['warningTh']} setTh={props.setThWarning} obj={props.thWarning}/>
                                        </Grid>
                                    <Grid item xs={4}>
                                    <ThresholdCard title={languageContext.dictionary['errorTh']} setTh={props.setThError} obj={props.thError}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <OutputControlCard title={languageContext.dictionary['executionParameters']} setObj={props.setControlObj} obj = {props.controlObj}/>
                                    </Grid>
                            </Grid>

                                </AccordionDetails>
                        </Accordion>
    )
}