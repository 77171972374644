import React from 'react'
import { Button, LinearProgress, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';

import * as BlobStorageService from './../services/BlobStorageService'

import trackEvent from './../../../../trackEvent'

import * as actions from './../../../../actions'


export default function InputFile(props) {

  const theme = useTheme();

  const dispatch = useDispatch()

  var inputFileRef = React.useRef(null);

  const [uploading, setUploading] = React.useState(false)
  const [size, setSize] = React.useState(0)

  function uploadFiles(files, props) {

    files && files.length > 0 && uploadFile(files[0], props);
  }


  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click()

  }





  /*
     function buildConnectionString (options)  {
       
         return (
           `BlobEndpoint=${options.storageUri};` +
           `SharedAccessSignature=${options.storageAccessToken}`
         );
       };
 
      
      function buildClient(options) {
         return BlobServiceClient.fromConnectionString(
           buildConnectionString(options)
         );
       }
 */

  function uploadFile(file, props) {
    if (props.uploadInBack !== undefined && props.uploadInBack) {      
      dispatch(actions.uploadFileInBackend(file, props.folder.replaceAll("/", "AQTIVA_SLASH"), props.storageAccountName, props.containerName, props.storageKey, file.name, props.userId))
    } else {

      dispatch(actions.getSecret(props.sasToken)).then(result => {
        if (result && JSON.stringify(result) !== "{}") {
          const sasToken = result.startsWith("?") ? result : "?" + result
          setSize(file.size / 1024 / 1024)
          setUploading(true)


          BlobStorageService.uploadToBlobStorage(file, props.folder,
            props.storageAccountName, props.containerName, sasToken,
            {
              blobHTTPHeaders: {
                blobContentType: file.type
              }
            }).then(response => {
              setUploading(false)
              props.onUpload(response)
            })
        }
      })
    }
  }



  return (
    <div>
      <input
        style={{ display: 'none' }}
        accept=".csv, .json, .txt"
        ref={inputFileRef}
        type="file"
        multiple={false}
        onChange={e => uploadFiles(e.target.files, props)}
        onClick={(event) => {
          event.target.value = null
        }}
      />
      {uploading &&
        <div>
          <LinearProgress style={{ width: '65%' }} />
          {size < 20 && <Typography style={{ width: '65%' }} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) / 100} MB`} </Typography>}
          {size >= 20 && <Typography style={{ width: '65%' }} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) / 100} MB. It may take some time depending on your internet connection`} </Typography>}
        </div>
      }
      <Button variant='outlined' style={theme.buttons.buttonRegular} onClick={() => { trackEvent('InputFile', 'Upload File Button'); showFileDialog() }} >
        {props.buttonText}
      </Button>
    </div>
  )
}