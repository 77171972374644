import React from "react";
import { useSelector } from "react-redux";

import SlackFeedback from 'react-slack-feedback'
import { StyledMenu } from "../common/StyleMenu";
import {axiosHeader} from './../../components/common/axios/axiosHelper';



export default function FeedbackMenu(props) {
   const user = useSelector((store) => store.user);
  


  const feedbackType = [
    {value:'bug', label:'Bug'},
    {value:'feature', label:'Feature'}
    
  ]

  function sendToServer(payload, success, error) {
    return fetch(process.env.REACT_APP_BACK_HOST+'/notification/slack', {
      method: 'POST',
      headers: axiosHeader(),
      body: JSON.stringify(payload.attachments[0])
    })
    .then(success)
    .catch(error)
  }
  
  /*
  function uploadImage(image, success, error) {
    var form = new FormData()
    form.append('image', image)
  
    return fetch('http://localhost:18086/notification/upload', { method: 'POST', data: form })
      .then(({ url }) => success(url))
      .catch(err => error(err))
    
  }*/
  
    return (
      <div>
        <StyledMenu
          id="menu-appbar"
          anchorEl={props.anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={props.open}
          onClose={props.handleCloseNotifications}
          style={{ minWidth: "500px" }}
         
        >
           <SlackFeedback
            open
            channel="#feedback"
            disabled={props.open ===true ? false : true}
            errorTimeout={8 * 1000}
            onClose={props.handleCloseNotifications}
            onOpen={() => {}}
            sentTimeout={5 * 1000}
            showChannel={false}
            showIcon={true}
            feedbackTypes={feedbackType}
            user={user.email}
            
            onSubmit={(payload, success, error) => 
              sendToServer(payload)
                .then(success)
                .catch(error)
             }
          />

        </StyledMenu>
       
      </div>
    );
  }

  