import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'
import { Drawer, FormControl, Accordion, AccordionSummary, AccordionDetails, Checkbox, InputLabel, Select, Divider, Typography, Paper, Button, MenuItem } from "@material-ui/core";
import { DateTimePicker } from "react-rainbow-components";
import FilterListIcon from "@material-ui/icons/FilterList";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import FilterTagComponent from './FilterTagComponent'

import {onlyUnique} from 'commonFunctions/commonFunctions'


const withFilterVW = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: 'center',
  },
  BackdropProps: {
    background: "transparent",

  },
  drawerPaper: {
    height: "auto",
    margin: "20px",
    marginTop: "100px",
    borderRadius: "10px",
    border: "0px",
    top: "auto",
    backgroundColor: "transparent",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    overflow: "hidden"
  },
  content: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function Filter(props) {
  const theme = useTheme();

  const classes = useStyles();

  const [open, setOpen] = React.useState(false)
  const [startDate, setStartDate] = React.useState(moment(new Date()).subtract(1, 'months').toDate())
  const [endDate, setEndDate] = React.useState(moment(new Date()))

  const [currentDayCheck, setCurrentDayCheck] = React.useState(false)
  const [lastDayCheck, setLastDayCheck] = React.useState(false)
  const [currentMonthCheck, setCurrentMonthCheck] = React.useState(false)
  const [last30DaysCheck, setLast30DaysCheck] = React.useState(false)
  const [last3MonthsCheck, setLast3MonthsCheck] = React.useState(false)
  const [last6MonthsCheck, setLast6MonthsCheck] = React.useState(false)


  //tag filter variables
  const [projectTagObjects, setProjectTagObjects] = React.useState([])
  const [projectTagNames, setProjectTagNames] = React.useState([])
  const [associativeProjectTagFlags, setAssociativeProjectTagFlags] = React.useState({})

  const [qpTagObjects, setQpTagObjects] = React.useState([])
  const [qpTagNames, setQpTagNames] = React.useState([])
  const [associativeQpTagFlags, setAssociativeQpTagFlags] = React.useState({})

  const [ruleTagObjects, setRuleTagObjects] = React.useState([])
  const [ruleTagNames, setRuleTagNames] = React.useState([])
  const [associativeRuleTagFlags, setAssociativeRuleTagFlags] = React.useState({})

  const [selectedProjects, setSelectedProjects] =React.useState([])
  const [selectedQPs, setSelectedQPs] =React.useState([])
  const [selectedDSs, setSelectedDSs] =React.useState([])


  function createFilter(){
    const filterObj = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      projectTagFilter: associativeProjectTagFlags,
      qpTagFilter: associativeQpTagFlags,
      ruleTagFilter: associativeRuleTagFlags,
      selectedProjectQPIds: selectedQPs.length ===0 ? selectedProjects.filter(x=>x.qualityPoints!==undefined).flatMap(x=>x.qualityPoints).map(x=>x._id) :
                                                selectedQPs.map(x=>x._id),
      selectedDSIds: selectedDSs.length === 0 ? undefined : selectedDSs.map(x=>x._id)
    }
    if (props.setFilterObj) {
      props.setFilterObj(filterObj)
    }
  }
  React.useEffect(() => {
    createFilter()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  //startDate, endDate, associativeProjectTagFlags,associativeQpTagFlags, associativeRuleTagFlags, selectedProjects, selectedQPs, selectedDSs

  React.useEffect(() => {
    if (props.projects && props.projects.length > 0) {
      setProjectTagObjects(props.projects.filter(x=>x.project!==undefined).map(x=>x.project).filter(x => x.tagList && x.tagList.length > 0).flatMap(x => x.tagList).filter(onlyUnique))
    }
    if (props.projects && props.projects.length > 0) {
      setQpTagObjects(props.projects.filter(x=>x.project!==undefined).map(x=>x.project).filter(x=>x.qualityPoints && x.qualityPoints.length>0).flatMap(x=>x.qualityPoints).filter(x => x.tagList && x.tagList.length > 0).flatMap(x => x.tagList).filter(onlyUnique))
    }
    if (props.projects && props.projects.length > 0) {
      setRuleTagObjects(props.projects.filter(x=>x.project!==undefined).map(x=>x.project).filter(x=>x.qualityPoints && x.qualityPoints.length>0).flatMap(x=>x.qualityPoints)
      .filter(x=>x.datasources && x.datasources.length>0).flatMap(x=>x.datasources)
      .filter(x=>x.rules && x.rules.length>0).flatMap(x=>x.rules)
      .filter(x => x.tagList && x.tagList.length > 0).flatMap(x => x.tagList).filter(onlyUnique))
    }
  }, [props.projects])


 

  function updateProjectTagFilter(tagName, values) {
    var associative = JSON.parse(JSON.stringify(associativeProjectTagFlags))
    associative[tagName] = values
    setAssociativeProjectTagFlags(associative)
  }

  function updateQpTagFilter(tagName, values) {
    var associative = JSON.parse(JSON.stringify(associativeQpTagFlags))
    associative[tagName] = values
    setAssociativeQpTagFlags(associative)
  }

  function updateRuleTagFilter(tagName, values) {
    var associative = JSON.parse(JSON.stringify(associativeRuleTagFlags))
    associative[tagName] = values
    setAssociativeRuleTagFlags(associative)
  }



  React.useEffect(() => {

    if (projectTagObjects && projectTagObjects.length > 0) {
      let values = (projectTagObjects.map(x => x.tagName).filter(onlyUnique))
      setProjectTagNames(values)

      var associative = {}
      for (var i = 0; i < values.length; i++) {
        associative[values[i]] = []
      }
      setAssociativeProjectTagFlags(associative)

    }

  }, [projectTagObjects])

  React.useEffect(() => {

    if (qpTagObjects && qpTagObjects.length > 0) {
      let values = (qpTagObjects.map(x => x.tagName).filter(onlyUnique))
      setQpTagNames(values)

      var associative = {}
      for (var i = 0; i < values.length; i++) {
        associative[values[i]] = []
      }
      setAssociativeQpTagFlags(associative)

    }

  }, [qpTagObjects])

  React.useEffect(() => {

    if (ruleTagObjects && ruleTagObjects.length > 0) {
      let values = (ruleTagObjects.map(x => x.tagName).filter(onlyUnique))
      setRuleTagNames(values)

      var associative = {}
      for (var i = 0; i < values.length; i++) {
        associative[values[i]] = []
      }
      setAssociativeRuleTagFlags(associative)

    }

  }, [ruleTagObjects])




  function setLast30Days() {
    setLastDayCheck(false)
    setCurrentDayCheck(false)
    setLast30DaysCheck(true)
    setCurrentMonthCheck(false)
    setLast3MonthsCheck(false)
    setLast6MonthsCheck(false)
    setStartDate(moment(new Date()).subtract(1, 'months').toDate())
    setEndDate(moment(new Date()).subtract(1, 'months').add(1, 'months').toDate())
  }

  function setLast3Month() {
    setLastDayCheck(false)
    setCurrentDayCheck(false)
    setLast30DaysCheck(false)
    setCurrentMonthCheck(false)
    setLast3MonthsCheck(true)
    setLast6MonthsCheck(false)
    setStartDate(moment(new Date()).subtract(3, 'months').toDate())
    setEndDate(moment(new Date()).subtract(3, 'months').add(3, 'months').toDate())
  }


  function setLast6Month() {
    setLastDayCheck(false)
    setCurrentDayCheck(false)
    setLast30DaysCheck(false)
    setCurrentMonthCheck(false)
    setLast3MonthsCheck(false)
    setLast6MonthsCheck(true)
    setStartDate(moment(new Date()).subtract(6, 'months').toDate())
    setEndDate(moment(new Date()).subtract(6, 'months').add(6, 'months').toDate())
  }


  function setCurrentMonth() {
    setLastDayCheck(false)
    setCurrentDayCheck(false)
    setLast30DaysCheck(false)
    setCurrentMonthCheck(true)
    setLast3MonthsCheck(false)
    setLast6MonthsCheck(false)
    setStartDate(moment(new Date()).startOf('month').toDate())
    setEndDate(moment(new Date()).subtract(1, 'months').add(1, 'months').toDate())
  }

  function setCurrentDay(){
    setLastDayCheck(false)
    setCurrentDayCheck(true)
    setLast30DaysCheck(false)
    setCurrentMonthCheck(false)
    setLast3MonthsCheck(false)
    setLast6MonthsCheck(false)
    setStartDate(moment(new Date()).startOf('day').toDate())
    setEndDate(moment(new Date()).subtract(1, 'days').add(1, 'days').toDate())
  }

  function setLastDay(){

    setLastDayCheck(true)
    setCurrentDayCheck(false)
    setLast30DaysCheck(false)
    setCurrentMonthCheck(false)
    setLast3MonthsCheck(false)
    setLast6MonthsCheck(false)
    setStartDate(moment(new Date()).subtract(1, 'days').toDate())
    setEndDate(moment(new Date()).subtract(1, 'days').add(1, 'days').toDate())
  }

  function setCurrentDayFunction(checked) {
    if (checked === true) {
      setCurrentDay()
    }
    else {
      setCurrentDayCheck(false)
    }
  }
  function setLastDayFunction(checked) {

    if (checked === true) {
      setLastDay()
    }
    else {
      setLastDayCheck(false)
    }
  }
  function setLast30DaysFunction(checked) {
    if (checked === true) {
      setLast30Days()
    }
    else {
      setLast30DaysCheck(false)
    }
  }
  function setLast3MonthsFunction(checked) {
    if (checked === true) {
      setLast3Month()
    }
    else {
      setLast3MonthsCheck(false)
    }
  }

  function setLast6MonthsFunction(checked) {
    if (checked === true) {
      setLast6Month()
    }
    else {
      setLast6MonthsCheck(false)
    }
  }

  function setCurrentMonthFunction(checked) {
    if (checked === true) {
      setCurrentMonth()
    }
    else {
      setCurrentMonthCheck(false)
    }
  }

  function getQPsForSelect(){
    if(selectedProjects && selectedProjects.length>0){
      return selectedProjects.filter(x=>x.qualityPoints!==undefined).flatMap(x=>x.qualityPoints)
    }
    else{
      return props.projects.filter(x=>x.project!==undefined).map(x=>x.project).filter(x=>x.qualityPoints!==undefined).flatMap(x=>x.qualityPoints)
    }
    
  }


  return (
    <>
      <div style={{ textAlign: "end" }}>
        <Button
          aria-controls="simple-menu"
          style={{ marginTop: props.up && props.up === true ? '-60px' : '0px' }}
          startIcon={<FilterListIcon />}
          aria-haspopup="true"
          onClick={() => (open ? setOpen(false) : setOpen(true))}
        >
          Filter
        </Button>
      </div>
      <div className={classes.root}>
        <main
          className={classes.content}
          style={open ? { marginRight: `${withFilterVW + 2}px` } : { marginRight: "0" }}
        >
          {props.children}
        </main>
        <Drawer
          ModalProps={{
            BackdropProps: {
              classes: {
                root: classes.BackdropProps,
              },
            },
          }}
          variant="temporary"
          disableScrollLock={true}
          onClose={(event,reason) =>{if(reason === 'backdropClick'){setOpen(false)}
          if(reason === 'escapeKeyDown'){setOpen(false)}}}
          className={classes.drawer}
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Divider />
          <Paper style={{ width: `${withFilterVW}px`, height: `100%` }}>
            <div style={{ ...theme.dashboard.filter.mainTitleDiv, textAlign: "center" }}>
              <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                Query selection
              </Typography>
            </div>



            <div style={{
              width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap',
              justifyContent: 'flex-start', marginLeft: '5%', marginRight: '5%'
            }}>
              <DateTimePicker
                //disabled={}
                value={startDate}
                onChange={(date) => setStartDate(date)}
                formatStyle="small"
                style={{ maxWidth: "90%" }}
                label={<Typography variant="caption"> From </Typography>}
              />

              <DateTimePicker
                //disabled={}
                value={endDate}
                onChange={(date) => setEndDate(date)}
                formatStyle="small"
                style={{ maxWidth: "90%", }}
                label={<Typography variant="caption"> To </Typography>}
              />

              <FormControl style={{
                display: "flex", flexDirection: "row", alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Checkbox
                  checked={currentDayCheck}
                  onChange={event => setCurrentDayFunction(event.target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
                <Typography variant='caption'  >Current Day</Typography>
              </FormControl>

              <FormControl style={{
                display: "flex", flexDirection: "row", alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Checkbox
                  checked={lastDayCheck}
                  onChange={event => setLastDayFunction(event.target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
                <Typography variant='caption'  >Last Day</Typography>
              </FormControl>

              <FormControl style={{
                display: "flex", flexDirection: "row", alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Checkbox
                  checked={currentMonthCheck}
                  onChange={event => setCurrentMonthFunction(event.target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
                <Typography variant='caption'  >Current Month</Typography>
              </FormControl>

              <FormControl style={{
                display: "flex", flexDirection: "row", alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Checkbox
                  checked={last30DaysCheck}
                  onChange={event => setLast30DaysFunction(event.target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
                <Typography variant='caption'  >Last 30 days</Typography>
              </FormControl>

              <FormControl style={{
                display: "flex", flexDirection: "row", alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Checkbox
                  checked={last3MonthsCheck}
                  onChange={event => setLast3MonthsFunction(event.target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
                <Typography variant='caption'  >Last 3 months</Typography>
              </FormControl>

              <FormControl style={{
                display: "flex", flexDirection: "row", alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Checkbox
                  checked={last6MonthsCheck}
                  onChange={event => setLast6MonthsFunction(event.target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
                <Typography variant='caption'  >Last 6 months</Typography>
              </FormControl>

            </div>
            <Divider />

            <div style={{
              width: '90%', display: 'flex', flexWrap: 'wrap', marginTop:'5px',
              justifyContent: 'flex-start', marginLeft: '5%', marginRight: '5%', marginBottom: '5px'
            }}>

        <FormControl style={{ width: '100%', }}>
                          <InputLabel > <Typography variant='caption'> Select Projects</Typography> </InputLabel>
                                    <Select
                                       multiple
                                        value={selectedProjects}
                                        onChange={event => setSelectedProjects(event.target.value)}
                                        inputProps={{
                                            name: 'selectedRecurrence',
                                            id: 'selectedRecurrence',
                                        }}
                                        style={{ width: '100%', }}
                                        renderValue={(selected) => <Typography variant='caption'> {selected.map(x=>x.name).join(', ')}</Typography>}
                                    >

                                        {props.projects.filter(x=>x.project!==undefined).map(element => {
                                            return (
                                                <MenuItem key={element.projectId} value={element.project}>
                                                   <Checkbox checked={selectedProjects.indexOf(element.project) > -1} />
                                                    <Typography variant='caption'>{`${element.project.name} (${element.executions})`}</Typography>
                                                </MenuItem>

                                            )
                                        })}
                                    </Select>
                                </FormControl>


                                <FormControl style={{ width: '100%', }}>
                          <InputLabel >
                          <Typography variant='caption'> Select Quality Points</Typography>
                         </InputLabel>
                                    <Select
                                       multiple
                                        value={selectedQPs}
                                        onChange={event => setSelectedQPs(event.target.value)}
                                        inputProps={{
                                            name: 'selectedRecurrence',
                                            id: 'selectedRecurrence',
                                        }}
                                        style={{ width: '100%', }}
                                        renderValue={(selected) => <Typography variant='caption'> {selected.map(x=>x.name).join(', ')}</Typography> }
                                    >

                                        {getQPsForSelect().map(element => {
                                            return (
                                                <MenuItem key={element._id} value={element}>
                                                   <Checkbox checked={selectedQPs.indexOf(element) > -1} />
                                                    <Typography variant='caption'>{`${element.name}`}</Typography>
                                                </MenuItem>

                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl style={{ width: '100%', }}>
                          <InputLabel ><Typography variant='caption'> Select Datasource</Typography></InputLabel>
                                    <Select
                                       multiple
                                        value={selectedDSs}
                                        onChange={event => setSelectedDSs(event.target.value)}
                                        inputProps={{
                                            name: 'selectedRecurrence',
                                            id: 'selectedRecurrence',
                                        }}
                                        style={{ width: '100%', }}
                                        renderValue={(selected) => <Typography variant='caption'> {selected.map(x=>x.name).join(', ')}</Typography>}
                                    >

                                        {selectedQPs.filter(x=>x.datasources!==undefined).flatMap(x=>x.datasources).map(element => {
                                            return (
                                                <MenuItem key={element._id} value={element}>
                                                   <Checkbox checked={selectedDSs.indexOf(element) > -1} />
                                                    <Typography variant='caption'>{`${element.name} (${element.dataset.name})`}</Typography>
                                                </MenuItem>

                                            )
                                        })}
                                    </Select>
                                </FormControl>

              </div>

            <Divider />
            <div style={{
              width: '90%', display: 'flex', flexWrap: 'wrap', marginTop:'5px',
              justifyContent: 'flex-start', marginLeft: '5%', marginRight: '5%', marginBottom: '10px'
            }}>

              <Accordion style={{ width: '100%' }}>
                <AccordionSummary style={{ width: '100%' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="accordion-project-tags"
                >
                  <Typography variant='subtitle2'>Project Tags</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%' }}>
                  <div style={{ width: '100%', marginTop: '-30px' }}>


                    {projectTagNames && projectTagNames.length > 0 && projectTagNames.map(e => {
                      return (
                        <div style={{ width: '100%', marginTop: '0px' }}>
                          <FilterTagComponent
                            tagName={e}
                            tagObjects={projectTagObjects}
                            updateFilter={updateProjectTagFilter}
                          />
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{ width: '100%' }}>
                <AccordionSummary style={{ width: '100%' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="accordion-qp-tags"
                >
                  <Typography variant='subtitle2'>QualityPoint Tags</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%' }}>
                  <div style={{ width: '100%', marginTop: '-30px' }}>


                    {qpTagNames && qpTagNames.length > 0 && qpTagNames.map(e => {
                      return (
                        <div style={{ width: '100%', marginTop: '0px' }}>
                          <FilterTagComponent
                            tagName={e}
                            tagObjects={qpTagObjects}
                            updateFilter={updateQpTagFilter}
                          />
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{ width: '100%' }}>
                <AccordionSummary style={{ width: '100%' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="accordion-qp-tags"
                >
                  <Typography variant='subtitle2'>Rule Tags</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%' }}>
                  <div style={{ width: '100%', marginTop: '-30px' }}>


                    {ruleTagNames && ruleTagNames.length > 0 && ruleTagNames.map(e => {
                      return (
                        <div style={{ width: '100%', marginTop: '0px' }}>
                          <FilterTagComponent
                            tagName={e}
                            tagObjects={ruleTagObjects}
                            updateFilter={updateRuleTagFilter}
                          />
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>

            </div>

            <Divider/>
            <div style={{
              width: '90%', display: 'flex', flexWrap: 'wrap', 
              justifyContent: 'center', margin:'5px'
            }}>
            <Button variant='outlined' color='primary'
            onClick={createFilter}
            >Filter</Button>
            </div>

          </Paper>

        </Drawer>
      </div>
    </>
  );
}
