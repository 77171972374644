import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MaterialTable from "@material-table/core";
import DeleteDataCredentialsDialog from './DeleteDataCredentialsDialog'
import AddDataCredentialsDialog from './AddDataCredentialsDialog'
import EditDataCredentialsDialog from './EditDataCredentialsDialog'
import { Tooltip } from '@material-ui/core'


import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
    },
    fabWarning: {
        margin: theme.spacing(1),
        backgroundColor: "yellow"

    }

}));



export default function DataCredentialsTable(props) {
    const classes = useStyles();

    const [data, setData] = useState([])
    const [selectedDataCredentials, setSelectedDataCredentials] = useState(undefined)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [updateCounter, setUpdateCounter] = useState(1)
    //const [columns, setColumns] = useState()

    useEffect(() => {
        if (props.dataStorageCredentials && props.dataStorageCredentials.length > 0) {
            setData(props.dataStorageCredentials)
        }
    }, [props.dataStorageCredentials, props.dataStorageCredentials && props.dataStorageCredentials.length, showConfirmDialog, showAddDialog])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            props.setDataStorageCredentials(data)
        }
    }, [updateCounter])// eslint-disable-line react-hooks/exhaustive-deps

    function openDeleteDialog(dataCredentials) {
        setSelectedDataCredentials(dataCredentials)
        setShowConfirmDialog(true)
    }
    function openEditDialog(dataCredentials) {
        setSelectedDataCredentials(dataCredentials)
        setShowEditDialog(true)
    }


    return (
        <>
            <DeleteDataCredentialsDialog showConfirmDialog={showConfirmDialog} setShowConfirmDialog={setShowConfirmDialog} selectedDataCredentials={selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={setSelectedDataCredentials} data={data} setData={setData} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} configuration={props.configuration} />
            <EditDataCredentialsDialog data={data} setData={setData} showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} selectedDataCredentials={selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={setSelectedDataCredentials} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />
            <AddDataCredentialsDialog showAddDialog={showAddDialog} setShowAddDialog={setShowAddDialog} userId={props.userId} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} data={data} setData={setData} configuration={props.configuration} />
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>


                {data && data.length > 0 && < MaterialTable
                    style={{ width: "100%" }}
                    title="Data Credentials"
                    data={data}
                    columns={[
                        { title: 'Display name', field: 'displayName' },
                        {
                            title: 'Storage Type', field: 'storageType', filtering: false, render: (rowData) => {
                                if (rowData.storageType === "azure_storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>azure-storage</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../resources/icons/azure/Storage-Accounts.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure_sql_database") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>azure-sqldatabase</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../resources/icons/azure/sql-database-generic.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure_container") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>azure-container</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../resources/icons/azure/Storage-Container.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "sqlServer") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Oracle database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../resources/icons/sqlServer.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "mySQL") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Oracle database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../resources/icons/mySQL.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "oracle") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Oracle database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../resources/icons/oracle.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "bigQuery") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>BigQuery database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../resources/icons/bigQuery.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "aws") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Amazon Web Services</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../resources/icons/aws/aws-s3-logo.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "awsHive") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Hive</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../resources/icons/hive.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "awsRedshift") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Redshift</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../resources/icons/redshift.png')} />
                                        </Tooltip>
                                    )
                                }
                            }
                        },
                        {
                            title: 'Description', field: 'description', render: (rowData) => {
                                if (rowData.storageType === "azure_storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Storage Account Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageAccountCredentialsDTO.storageAccountName}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure_container") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Storage Account Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageAccountCredentialsDTO.storageAccountName}</strong></Typography>
                                                <Typography variant='subtitle2'>Container  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageAccountCredentialsDTO.folder}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "oracle") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.oracleStorageCredentialsDTO.server}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.oracleStorageCredentialsDTO.port}</strong></Typography>
                                                <Typography variant='subtitle2'>Service Name: <strong style={{ color: "#2fb7e0" }}>{rowData.oracleStorageCredentialsDTO.folder}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "mySQL") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.mySQLCredentialsDTO.server}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.mySQLCredentialsDTO.port}</strong></Typography>
                                                <Typography variant='subtitle2'>Schema Name: <strong style={{ color: "#2fb7e0" }}>{rowData.mySQLCredentialsDTO.schema}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "sqlServer") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.server}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.port}</strong></Typography>
                                                <Typography variant='subtitle2'>Database Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.database}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "bigQuery") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Project ID: <strong style={{ color: "#2fb7e0" }}>{rowData.bigQueryCredentialsDTO && rowData.bigQueryCredentialsDTO.projectId ? rowData.bigQueryCredentialsDTO.projectId : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "aws") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Region: <strong style={{ color: "#2fb7e0" }}>{rowData.awsCredentialsDTO && rowData.awsCredentialsDTO.region ? rowData.awsCredentialsDTO.region : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "awsHive") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Name: <strong style={{ color: "#2fb7e0" }}>{rowData.hiveCredentialsDTO && rowData.hiveCredentialsDTO.host ? rowData.hiveCredentialsDTO.host : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "awsRedshift") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Name: <strong style={{ color: "#2fb7e0" }}>{rowData.redshiftCredentialsDTO && rowData.redshiftCredentialsDTO.host ? rowData.redshiftCredentialsDTO.host : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }

                            }
                        },
                        {
                            title: 'Actions', field: '', filtering: false, render: (rowData) => {
                                return (
                                    <>
                                        <Tooltip title={"Delete Data Credentials"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openDeleteDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faTrash} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                        <Tooltip title={"Edit Data Credentials"}>
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openEditDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faEdit} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>
                                    </>
                                )
                            }
                        },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: false,
                        search: false,
                        pageSize: 5,
                        maxBodyHeight: '90%',
                        minBodyHeight: 250,
                        headerStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                            backgroundColor: commonStyles.mainColor,
                            color: '#FFF'
                        },
                        cellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                        filterCellStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                        },
                    }}

                    components={{
                        Toolbar: props => (
                            <div>

                            </div>
                        ),
                    }}
                />}
                <div className={classes.buttonContainer}>
                    <Button variant="contained" style={{ minWidth: '20%', marginLeft: "2%", marginTop: "1%" }}
                        color="primary"
                        onClick={event => {
                            setShowAddDialog(true)
                        }}>
                        <i className="icon plus"></i>
                        Add Data Credentials
                    </Button>

                </div>
            </div>
        </>

    )
}
