import React from 'react'
import { FormControl, Select, MenuItem, Typography, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faProjectDiagram, faFolderOpen } from '@fortawesome/free-solid-svg-icons'

import { ReactComponent as EventHubIcon } from './../../common/EventHubTextIcon/azure-event-hub.svg'

import * as commonStyles from 'style/CommonStyles'
import * as techHelper from 'commonFunctions/sourceTechHelper'

const useStyles = makeStyles((theme) => ({

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '80%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resize: {
    fontSize: 14
  }
}));

export default function QualityItemSelector(props) {

  const [values, setValues] = React.useState(props.value ? [...props.itemsArray, props.value] : props.itemsArray)


  const classes = useStyles();

  React.useEffect(() => {
    if (props.value) {
      const newArray = [...props.itemsArray, props.value]
      const arrayUnique = newArray.filter((v, i, a) => a.findLastIndex(v2 => (v2[props.itemId] === v[props.itemId])) === i)
      setValues(arrayUnique)

    }
    else {
      setValues(props.itemsArray)
    }
  }, [props.value, props.itemsArray])// eslint-disable-line react-hooks/exhaustive-deps

  function getDataformatName(value) {
    const name = value['nickname'] !== undefined && value['nickname'] !== null && value['nickname'] !== ''  ? value['nickname'] : value[props.itemValue];

    const version = value.version > 0 ? ` (version ${value.version})` : '';

    return name + version;
  }

  function getValue(value) {
    if (props.type === 'DataFormat') {
      return getDataformatName(value)
    }
    return value[props.itemValue]
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="typeWarnTh-selection-helper">{props.title}</InputLabel>
      <Select
        style={{ width: '100%' }}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: props.title,
          id: props.title + '-selection',

        }}
      >
        {values.map((value) =>
          <MenuItem key={value[props.itemId]} value={value}><div style={commonStyles.adjacentSpaceBetween}>
            {(props.type === 'StreamDataformat') && <EventHubIcon style={{ width: '15px', height: '15px', marginTop: '3px', marginRight: '3px' }} />}
            {(props.type === 'QualityPoint') && <FontAwesomeIcon icon={faProjectDiagram} style={{ color: commonStyles.mainColor, fontSize: 18 }} />}
            {(props.type === 'Datasource') && techHelper.getIconFromSource(value.dataformatSource)}
            {(props.type === 'DataFormat') && techHelper.getIconFromSource(value.source)}

            {(props.type === 'Folder') && <FontAwesomeIcon icon={faFolderOpen} style={{ color: commonStyles.mainColor, fontSize: 18 }} />}
            <Typography variant='subtitle2'>{getValue(value)}</Typography></div>
          </MenuItem>
        )}

      </Select>

    </FormControl>
  )
}