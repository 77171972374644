import React, { useState } from 'react'
import AqtivaDialog from 'components/common/AqtivaDialog'
import { Typography, CircularProgress } from '@material-ui/core'


import ExecutionsTable from './ExecutionsTable'


export default function ExecutionTableDialog(props) {
    const [loading, setLoading] = useState(true)


    return (
        <AqtivaDialog
            visible={props.showExecutionsDialog}
            dialog={props.showExecutionsDialog}
            fullWidth={true}
            maxWidth={'m'}
            title={"Executions"}
            editable={false}
            cancelText={'Close'}
            showConfirmButton={false}
            cancelCallback={() => {
                props.setShowExecutionsDialog(false)
                setLoading(true)
            }}

        >
            {loading && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                loading executions... <CircularProgress style={{ width: '15px', height: '15px' }} />
            </Typography>}
            <ExecutionsTable selectedSchedule={props.selectedSchedule} setLoading={setLoading} />
        </AqtivaDialog>
    )
}