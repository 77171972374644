import React from 'react'
import AqtivaDialog from 'components/common/AqtivaDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { Typography, Card } from '@material-ui/core'
import { useDispatch } from "react-redux";
import * as actions from 'actions';


export default function DialogConfirmProjectReversion(props) {

    const dispatch = useDispatch()


    return (
        <AqtivaDialog
            // Confirm Dialog
            visible={props.showConfirmDialog}
            dialog={props.showConfirmDialog}
            fullWidth={true}
            maxWidth={'sm'}
            title={"Do you want to Revert to this project's version?"}
            editable={false}
            titleIcon={faUndo}
            cancelText={'Cancel'}
            cancelCallback={() => { props.setShowConfirmDialog(false) }}
            confirmText={'Revert'}
            confirmCallback={event => {
                dispatch(actions.revertProject(props.project._id, props.selectedVersion && props.selectedVersion.projectHistory && props.selectedVersion.projectHistory.qualityProject, props.userId))
                props.setShowConfirmDialog(false)
                props.setShowDialog(false)
            }}
        >
            <Card style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={props.selectedVersionIcon} style={{ color: props.selectedVersionColor, fontSize: 'calc(15px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                    {props.selectedVersion && props.selectedVersion.updateElementType} {props.selectedVersion && props.selectedVersionMessage}
                </Typography>
                <Typography variant={'caption'} style={{ textAlign: "center" }}>Project Modification: {props.selectedVersion && props.selectedVersion.projectHistory && props.selectedVersion.projectHistory.updateInfo}</Typography>

                <Typography variant={'caption'} style={{ textAlign: "center" }}>UpdateDate: {props.selectedVersion && props.selectedVersion.projectHistory && props.selectedVersion.projectHistory.updateDate}</Typography>
            </Card>
            {props.project.hasScheduleFlag && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                {`This project has some scheduled jobs asociated to it, by reverting to a previous version, jobs asociated to a non-existent datasource will be terminated.`}
            </Typography>}

        </AqtivaDialog>
    )
}