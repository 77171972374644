import React from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as commonStyles from '../../../../../style/CommonStyles'

export default function DonutChartSmall(props) {

    const [data, setData] = React.useState([])

    const intRadiusMax = 92
    const intRadiusMin = 78
    const extRadiusMax= 100
    const extRadiusMin = 70

    React.useEffect(() => {
        if (props.data && props.data > 0) {
            setData(props.data)
        }

    }, [props.data])

    React.useLayoutEffect(() => {
        
        
        let root = am5.Root.new(props.chart);
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        // start and end angle must be set both for chart and series
        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout
            })
        );

        

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        // start and end angle must be set both for chart and series
        let series0 = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                alignLabels: false,
                radius: am5.percent(props.extRadiusMax ? props.extRadiusMax : extRadiusMax),
                innerRadius: am5.percent(props.extRadiusMin ? props.extRadiusMin : extRadiusMin)
            })
        );

        series0.states.create("hidden", {
            startAngle: 180,
            endAngle: 180
        });

        series0.slices.template.setAll({
            fillOpacity: 0.5,
            strokeOpacity: 0,
            templateField: "settings",
        });

        series0.slices.template.states.create("hover", { scale: 1 });
        series0.slices.template.states.create("active", { shiftRadius: 0 });

        series0.labels.template.setAll({
            templateField: "settings",
        });

        series0.ticks.template.setAll({
            templateField: "settings",
            visible: false
        });

        series0.labels.template.setAll({
            textType: "circular",
            radius: 30,
            visible: false
        });

        if (props.click) {
            series0.events.on('click', () => {
                props.openDimensionModal()
            })
        }


        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series0.data.setAll([
            {
                value: data >= 0 ? data : 0,
                settings: { fill: am5.Color.fromString(commonStyles.colorOk) }
            },
            {
                category: "Unused",
                value: data > 0 ? 100 - data : 100,
                settings: { forceHidden: true },

            }
        ]);

     

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        // start and end angle must be set both for chart and series
        let series1 = chart.series.push(
            am5percent.PieSeries.new(root, {
                radius: am5.percent(props.intRadiusMax ? props.intRadiusMax : intRadiusMax),
                innerRadius: am5.percent(props.intRadiusMin ? props.intRadiusMin : intRadiusMin),
                valueField: "value",
                categoryField: "category",
                alignLabels: false,
                
            })
        );

        series1.states.create("hidden", {
            startAngle: 180,
            endAngle: 180
        });

        series1.slices.template.setAll({
            templateField: "sliceSettings",
            strokeOpacity: 0,
        });


        series1.labels.template.setAll({
            textType: "circular",
            visible: false
        });

        series1.slices.template.states.create("hover", { scale: 1 });
        series1.slices.template.states.create("active", { shiftRadius: 0 });

        series1.ticks.template.setAll({
            forceHidden: true
        });


        if (props.showCenterLabel) {
            series0.children.push(am5.Label.new(root, {
                text: data > 0 ?  data + '%' : '0%',
                fontSize: 13,
                fontFamily: commonStyles.fontFamily,
                centerX: am5.percent(50),
                centerY: am5.percent(50)
            }));
        }

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series1.data.setAll([{
            category: 'Ok',
            value: data >= 0 ? data : 0,
            sliceSettings: { fill: am5.Color.fromString(data !== 0 ? commonStyles.colorOk : commonStyles.colorRemaining) }
        }, {
            category: 'Ko',
            value: data > 0 ? 100 - data : 100,
            sliceSettings: { fill: am5.Color.fromString(commonStyles.colorRemaining) }
        }]);
        
        return () => {
            chart.dispose()
        }

    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <div id={props.chart} style={{ width: "100%", height: props.height }}></div>
        </div>
    )
}