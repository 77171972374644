import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { QualityTheme } from 'style/CustomTheme';
import {Grid, CircularProgress,Typography,LinearProgress,
    TableContainer,Paper,Table,TableHead, TableRow,TableCell,
TableBody} from  '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'


export default function RunningSimulation(props){
    const [buffer, setBuffer] = React.useState(10);
    const [progress, setProgress] = React.useState(0);
     const iconSize = 15
 


    React.useEffect(() => {
        if(props.isSimulanionFinished){
            setProgress(0)
            setBuffer(10)
        }
        else{
            setProgress((props.simulationMessages.length+props.nReceivedRules)*10)
            setBuffer((props.simulationMessages.length+props.nReceivedRules)*10+10)
        }
        

    }, [props]);


    function getMessages(props){
        if(props.nReceivedRules<1){
            return (props.simulationMessages)
        }
        else{
            var returnArray = props.simulationMessages.slice()
            returnArray.push(`${props.nReceivedRules}/${props.nExpectedRules} executed`)
            return returnArray
        }
    }


    return(
        <MuiThemeProvider theme={QualityTheme}>
            <div style={{marginLeft:'5%',marginRight:'5%'}}>
            <Grid container spacing={2}
                style={{marginTop:'5%'}}>
                    <Typography variant='subtitle2'>
                        {getMessages(props)[getMessages(props).length-1]}    
                    </Typography>
                <Grid item xs={12}>

                <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                </Grid>

                <Grid item xs={12}>
                     <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="left">Step</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                        {getMessages(props).length>1 && getMessages(props).slice(0, -1).map((row,index) => 
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                <FontAwesomeIcon icon={faCheckCircle} style={{marginTop:'2px',color:'#228247', fontSize:iconSize}} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                        <Typography variant='caption'>
                                                                        {row}
                                                        </Typography>
                                                </TableCell>
                                            </TableRow>
                                            
                                        )}
                                        {getMessages(props).length>1 && getMessages(props).slice(getMessages(props).length-1, 
                                            getMessages(props).length).map((row,index) => 
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {props.isSimulanionFinished &&  <FontAwesomeIcon icon={faCheckCircle} style={{marginTop:'2px',color:'#228247', fontSize:iconSize}} />}
                                                    {!props.isSimulanionFinished && <CircularProgress size={iconSize} />}
                                                    
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                        <Typography variant='caption'>
                                                                        {row}
                                                        </Typography>
                                                </TableCell>
                                            </TableRow>
                                           )}

                                        {getMessages(props).length<2 && getMessages(props).map((row,index) => 
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                <FontAwesomeIcon icon={faCheckCircle} style={{marginTop:'2px',color:'#228247', fontSize:iconSize}} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                        <Typography variant='caption'>
                                                                        {row}
                                                        </Typography>
                                                </TableCell>
                                            </TableRow>
                                            
                                        )}
                                </TableBody>
                            </Table>
                    </TableContainer>

                <div>
                    
                </div>
                           
                </Grid>  
                

            </Grid>
               
            </div>
            
        </MuiThemeProvider>
    )

}