
import {  ACCESS_TOKEN_AZURE_USER } from './../Constants'
import customTokenCredential from './../components/login/CustomTokenCredential'
import { axiosHeaderAzure } from '../components/common/axios/axiosHelper';
import axios from 'axios';
import * as actions from 'actions'


import { SqlManagementClient } from "@azure/arm-sql";


import {
  StorageManagementClientContext,
  StorageAccounts,
  BlobContainers,
  BlobServices
} from "@azure/arm-storage";


import {
  SynapseManagementClient
} from "@azure/arm-synapse";




import {
  ResourceManagementClientContext,
  ResourceGroups
} from "@azure/arm-resources";



import {
  InteractiveBrowserCredential
} from "@azure/identity"



import { dispatchError, dispatchInfoLong } from "../components/common/axios/axiosHelper";

import {  PublicClientApplication } from "@azure/msal-browser";

const { BlobServiceClient } = require("@azure/storage-blob")

const expiredTokenMessage = "ExpiredAuthenticationToken"



const {
  DataLakeServiceClient,
} = require("@azure/storage-file-datalake");

async function obtainAzureToken(dispatch) {
  try{
    const appId = await dispatch(actions.getControlValue("AQTIVA_APP_ID"))
    const tenantId =await dispatch(actions.getControlValue("AQTIVA_TENANT_ID"))
    const msalConfig = {
      auth: {
        clientId: appId,
        redirectUri: (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
        prompt: 'select_account',
        authority:
          'https://login.microsoftonline.com/' + tenantId,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    }
  
  
    var token = undefined
    let request = {
      scopes: ['https://management.core.windows.net/user_impersonation'],
      prompt: 'select_account',
    };
    const msalInstance = new PublicClientApplication(
      msalConfig,
    );
    try {
      let cacheToken = localStorage.setItem('ACCESS_TOKEN_AZURE_IDENTITY', token);
      if (cacheToken) {
        token = cacheToken
      }
      else {
        let tokenResponse = await msalInstance.acquireTokenSilent(request);
        token = tokenResponse.accessToken
      }
  
    } catch (error) {
      console.error('Silent token acquisition failed. Using interactive mode');
     

      try{
        let tokenResponse = await msalInstance.acquireTokenPopup(request);
        token = tokenResponse.accessToken
        }
        catch(e){
          console.error("Error in getTokenFromAzureUser with popup")
          console.error(e)
        }
  
  
    }
  
    localStorage.setItem(ACCESS_TOKEN_AZURE_USER, token);
  }

  catch(e){
    console.error("Error in obtainAzureToken")
    console.error(e)
  }
  


}
async function getTokenFromAzureUser() {
  const appId = await actions.getControlValueAsync("AQTIVA_APP_ID")
  const tenantId =await actions.getControlValueAsync("AQTIVA_TENANT_ID")

 

  const msalConfig = {
      auth: {
          clientId: appId,
          redirectUri:  (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
          prompt: 'select_account',
          authority:
              'https://login.microsoftonline.com/'+tenantId,
      },
      cache: {
          cacheLocation: 'localStorage',
      },
  }

  console.log('msalConfig', msalConfig)

  var token = undefined
  let request = {
      scopes: ['https://management.core.windows.net/user_impersonation'],
      prompt: 'select_account',
  };
  const msalInstance = new PublicClientApplication(
      msalConfig,
  );
  try {
      let cacheToken = localStorage.setItem('ACCESS_TOKEN_AZURE_IDENTITY', token);
      if (cacheToken) {
          token = cacheToken
      }
      else {
          let tokenResponse = await msalInstance.acquireTokenSilent(request);
          token = tokenResponse.accessToken
          console.log('tok response', tokenResponse)
      }

  } catch (error) {
      console.error('Silent token acquisition failed. Using interactive mode');
      try{
      let tokenResponse = await msalInstance.acquireTokenPopup(request);
      token = tokenResponse.accessToken
      }
      catch(e){
          console.error("Error in acquireTokenPopup")
          console.error(3)
      }

  }

  localStorage.setItem(ACCESS_TOKEN_AZURE_USER, token);

  
}

async function getAccessTokenAzure(dispatch) {
  var token = localStorage.getItem(ACCESS_TOKEN_AZURE_USER)
  if (token === undefined || token === null || token === "") {
    await obtainAzureToken(dispatch)
    token = localStorage.getItem(ACCESS_TOKEN_AZURE_USER)
  }
  return token
}




function generateError(dispatch, error) {
  if(JSON.stringify(error).includes(expiredTokenMessage)){
    return ""
  }
  if (error.statusCode && error.statusCode === 403) {
    dispatchInfoLong(dispatch, "You do not have permission to access or list this resource. Please contact your administrator to request access: Storage Blob Data Reader")
  }
  else if (error.code && error.code === 'REQUEST_SEND_ERROR') {
    dispatchInfoLong(dispatch, "The Storage has not been configure to remote access. CORS needs to be enabled for GET operations ")
  }
  else {
    if(!JSON.stringify(error).includes(expiredTokenMessage)){
      dispatchError(dispatch, JSON.stringify(error.body ? error.body.message: error))
    }
    
  }
}

async function retryGetSubscriptions(dispatch){
  await getTokenFromAzureUser(dispatch)
          getSubscriptions(false)
}


export const getSubscriptions = (retry) => async (dispatch, getState) => {

  var returnValue = {}
  try{
    const axiosValue = await axios.create(
      {
        baseURL: "https://management.azure.com/"

      }
    )
    await axiosValue.get(`subscriptions?api-version=2020-01-01`, {
      headers: axiosHeaderAzure()
    }).then(function (response) {
      returnValue = response.data.value
      

    })
      .catch(function (error) {
      
       if(retry === undefined){
        retryGetSubscriptions(dispatch)
       
       }
       else{
        dispatchError(dispatch, error)
       }

          
        
      

        returnValue = { result: 'ko', error: error }
      });
    }
    catch(e){
      console.error("Error in getSubscriptions")
      console.error(e)
    }

  return returnValue

}


/** STORAGE ACCOUNT FUNCTIONS */
export async function listStorageAccountForRG(subscriptionId, resourceGroupName, dispatch, retry) {


  try {

    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);

    const azStorageCtx = new StorageManagementClientContext(browserCredential, subscriptionId
    );

    const accountsClient = new StorageAccounts(azStorageCtx);
    const blobServices = new BlobServices(azStorageCtx);

    const storage = await accountsClient.listByResourceGroup(resourceGroupName)



    const newStoragesPromises = storage.map(async s => {
      return ({
        resourceGroupName: resourceGroupName,
        name: s.name,
        region: s.location,
        kind: (s.primaryEndpoints !== undefined && s.primaryEndpoints.dfs !== undefined
          && s.primaryEndpoints.blob !== undefined
          && s.primaryEndpoints.file !== undefined) ? 'ADLV2' : s.kind,
        containers: [],
        cors: await blobServices.list(resourceGroupName, s.name)

      })
    })

    const newStorages = await Promise.all(newStoragesPromises)
    return newStorages.flat()


  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return listStorageAccountForRG(subscriptionId, resourceGroupName, dispatch, false)
    }
    else {
      
      generateError(dispatch, error)
      return []
    }

  }



}







/** STORAGE ACCOUNT FUNCTIONS */
export async function listStorageAccountsAll(subscriptionId, dispatch, retry) {
  if (subscriptionId === "") {
    return []
  }
  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);
    const azStorageCtx = new StorageManagementClientContext(
      browserCredential,
      subscriptionId
    );
    const accountsClient = new StorageAccounts(azStorageCtx);
    const blobServices = new BlobServices(azStorageCtx)
    const storage = await accountsClient.list()


    const newStoragesPromises = storage.map(async s => {
      return ({
        resourceGroupName: getResourceGroupFromId(s.id),
        name: s.name,
        region: s.location,
        kind: (s.primaryEndpoints !== undefined && s.primaryEndpoints.dfs !== undefined
          && s.primaryEndpoints.blob !== undefined
          && s.primaryEndpoints.file !== undefined) ? 'ADLV2' : s.kind,
        containers: [],
        cors: await blobServices.list(getResourceGroupFromId(s.id), s.name)
      })
    })
    const newStorages = await Promise.all(newStoragesPromises)
    return newStorages.flat()

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return listStorageAccountsAll(subscriptionId, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }

  }
}

/** STORAGE ACCOUNT FUNCTIONS */
export async function getNameListChildrenStorageObject(configuration, subscriptionId, object, dispatch, retry, decriptedSas) {
  try {
    if (decriptedSas) {
      const account = object.connectionInfoConnectionStringDTO.storageAccountName;
      //se añade el caracter "?" porque decripted sas no lo lleva.
      


      const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${decriptedSas}`);

      // Get a reference to a container
      const containerClient = blobServiceClient.getContainerClient(object.container);


      const blobs = []
      // List the blob(s) in the container.
      for await (const blob of
        containerClient.listBlobsFlat({ prefix: object.fullName })) {
        if (blob.name.startsWith(object.fullName)) {
          blobs.push(blob.name)
        }



      }
      object.listChildrenNames = blobs
      return object


    }
    else {
      const credential = new InteractiveBrowserCredential({
        tenantId: configuration.azureCredentials.domain,
        clientId: configuration.azureCredentials.clientId,
        redirectUri:  (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
        loginStyle: "popup"
      });


      const blobServiceClient = new BlobServiceClient(
        `https://${object.storageAccount}.blob.core.windows.net`,
        credential)

      // Get a reference to a container
      const containerClient = blobServiceClient.getContainerClient(object.container);


      const blobs = []
      // List the blob(s) in the container.
      for await (const blob of
        containerClient.listBlobsFlat({ prefix: object.fullName+"/" })) {
        if (blob.name.startsWith(object.fullName)) {
          blobs.push(blob.name)
        }



      }
      object.listChildrenNames = blobs
      return object
    }
  }
  catch (error) {
    if (decriptedSas) {
      return object
    }
    else {

      if (retry === undefined) {
        await getTokenFromAzureUser(dispatch)
        return getNameListChildrenStorageObject(configuration, subscriptionId, object, dispatch, false)
      }
      else {
        generateError(dispatch, error)
        return object
      }
    }
  }
}
/** STORAGE ACCOUNT FUNCTIONS */
export async function getNameListChildrenStorageObjectLocal(object, decriptedSas, dispatch, retry) {
  try {
    const account = object.connectionInfoConnectionStringDTO.storageAccountName;
    //se añade el caracter "?" porque decripted sas no lo lleva.


    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${decriptedSas}`);


    //get all containers inside a storage account


    let containers = blobServiceClient.listContainers();
    let allContainers = []
    for await (const container of containers) {
      allContainers.push(container)
    }


    object.containers = allContainers
    return object
  }
  catch (error) {
    const account = object.connectionInfoConnectionStringDTO && object.connectionInfoConnectionStringDTO.storageAccountName;
    dispatchError(dispatch, `Local storage ${account} does not have valid authorization.`)
    object.flagError = true
    object.containers = []
    return object
  }
}

export async function getBlobsFromContainer(blobServiceClient, container) {
  let blobsInContainer = []
  let containerClient = blobServiceClient.getContainerClient(container.name)
  let blobs = containerClient.listBlobsFlat()
  for await (const blob of blobs) {
    blobsInContainer.push(blob)
  }
  container.blobs = blobsInContainer
  return container
}



export async function onGetContainerList(subscriptionId, resourceGroupName, storageAccountName, region, dto, dispatch, retry) {
  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);
    const azStorageCtx = new StorageManagementClientContext(
      browserCredential,
      subscriptionId
    );

    const accountsClient = new BlobContainers(azStorageCtx);

    const containers = await accountsClient.list(resourceGroupName, storageAccountName)


    const containersMap = containers.map(container => {
      return {

        name: container.name,
        exploded: false,
        storageAccountItemDTOSRoot: []
      }
    })

    const clone = JSON.parse(JSON.stringify(dto));
    clone.containers = containersMap
    return clone
  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onGetContainerList(subscriptionId, resourceGroupName, storageAccountName, region, dto, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return dto
    }
  }
}


export async function onGetContainerListADLv2(configuration, subscriptionId, resourceGroupName, storageAccountName, region, dto, dispatch, retry, decriptedSas) {
  try {
    if (decriptedSas) {
      const account = dto.connectionInfoConnectionStringDTO.storageAccountName;


      const datalakeServiceClient = new DataLakeServiceClient(`https://${account}.dfs.core.windows.net?${decriptedSas}`);

      let fileSystems = datalakeServiceClient.listFileSystems();
      const containersMap = []
      for await (const fileSystem of fileSystems) {
        containersMap.push({
          name: fileSystem.name,
          exploded: false,
          storageAccountItemDTOSRoot: []
        })
      }





      const clone = JSON.parse(JSON.stringify(dto));
      clone.containers = containersMap
      return clone

    }
    else {
      const credential = new InteractiveBrowserCredential({
        tenantId: configuration.azureCredentials.domain,
        clientId: configuration.azureCredentials.clientId,
        redirectUri:  (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
        loginStyle: "popup"
      });

      const datalakeServiceClient = new DataLakeServiceClient(
        `https://${storageAccountName}.dfs.core.windows.net`, credential);
      let fileSystems = datalakeServiceClient.listFileSystems();
      const containersMap = []
      for await (const fileSystem of fileSystems) {
        containersMap.push({
          name: fileSystem.name,
          exploded: false,
          storageAccountItemDTOSRoot: []
        })
      }





      const clone = JSON.parse(JSON.stringify(dto));
      clone.containers = containersMap
      return clone

    }

  }
  catch (error) {
    if (decriptedSas) {
      return dto
    }
    else {

      if (retry === undefined) {
        await getTokenFromAzureUser(dispatch)
        return onGetContainerListADLv2(configuration, subscriptionId, resourceGroupName, storageAccountName, region, dto, dispatch, false)
      }
      else {
        generateError(dispatch, error)
        return dto
      }
    }
  }
}



export async function onListStorageAccountsClick(subscriptionId, dispatch, retry) {
  try {

    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);

    const azResourceCtx = new ResourceManagementClientContext(
      browserCredential,
      subscriptionId
    );

    const azStorageCtx = new StorageManagementClientContext(
      browserCredential,
      subscriptionId
    );
    const accountsClient = new StorageAccounts(azStorageCtx);


    const resourceGroupClient = new ResourceGroups(azResourceCtx);

    const rGroups = await resourceGroupClient.list()
    const promises = rGroups.map(async rGroup => {
      const storage = await accountsClient.listByResourceGroup(rGroup.name)
      const newStorages = storage.map(s => {
        return ({
          resourceGroupName: rGroup.name,
          name: s.name,
          region: s.location,
          kind: (s.primaryEndpoints !== undefined && s.primaryEndpoints.dfs !== undefined
            && s.primaryEndpoints.blob !== undefined
            && s.primaryEndpoints.file !== undefined) ? 'ADLV2' : s.kind,
          containers: []

        })
      })
      return newStorages




    })

    const a = await Promise.all(promises)
    return a.flat()

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListStorageAccountsClick(subscriptionId, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }
}




export async function onListResourceGroups(subscriptionId, dispatch, retry) {

  try {

    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);


    const azResourceCtx = new ResourceManagementClientContext(
      browserCredential,
      subscriptionId
    );

    const resourceGroupClient = new ResourceGroups(azResourceCtx);

    const rGroups = await resourceGroupClient.list()
    return rGroups.map(x => x.name)
  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListResourceGroups(subscriptionId, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }

}






export async function onListSqlDatabaseClick(subscriptionId, dispatch, retry) {
  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);
    const azResourceCtx = new ResourceManagementClientContext(
      browserCredential,
      subscriptionId
    );


    const accountsClient = new SqlManagementClient(browserCredential,
      subscriptionId);


    const resourceGroupClient = new ResourceGroups(azResourceCtx);



    const rGroups = await resourceGroupClient.list()
    const promises = rGroups.map(async rGroup => {
      const storage = await accountsClient.servers.list()
      const newStorages = storage.map(s => {




        return ({
          resourceGroupName: getResourceGroupFromId(s.id),
          name: s.name,
          region: s.location,
          sqlDatabaseDTOS: []

        })
      })
      return newStorages



    })

    const a = await Promise.all(promises)
    return a.flat()
  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListSqlDatabaseClick(subscriptionId, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }

}




export async function onListSqlDatabaseForRG(subscriptionId, resourceGroup, dispatch, retry) {

  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);
    const accountsClient = new SqlManagementClient(browserCredential,
      subscriptionId);

    const storage = await accountsClient.servers.listByResourceGroup(resourceGroup)
    const newStorages = storage.map(s => {
      return ({
        resourceGroupName: resourceGroup,
        name: s.name,
        region: s.location,
        sqlDatabaseDTOS: []

      })
    })




    return newStorages.flat()
  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListSqlDatabaseForRG(subscriptionId, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }

}





function getResourceGroupFromId(id) {
  return id.split("/")[4]
}


export async function onListSqlDatabaseAll(subscriptionId, dispatch, retry) {

  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);

    const accountsClient = new SqlManagementClient(browserCredential,
      subscriptionId);

    const storage = await accountsClient.servers.list()

    const newStorages = storage.map(s => {
      return ({
        resourceGroupName: getResourceGroupFromId(s.id),
        name: s.name,
        region: s.location,
        sqlDatabaseDTOS: []

      })
    })




    return newStorages.flat()

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListSqlDatabaseAll(subscriptionId, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }
}




export async function getItemsFromContainer(configuration, folder, storageAccountName, container, dispatch, retry, decriptedSas) {
  try {
    if (decriptedSas) {


      const blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net?${decriptedSas}`);

      // Get a reference to a container
      const containerClient = blobServiceClient.getContainerClient(container.name);


      const blobs = []
      // List the blob(s) in the container.
      for await (const blob of folder === "/" ? containerClient.listBlobsByHierarchy("/") :
        containerClient.listBlobsByHierarchy("/", { prefix: folder })) {

        blobs.push(blob)

      }
      return blobs

    }
    else {
      const credential = new InteractiveBrowserCredential({
        tenantId: configuration.azureCredentials.domain,
        clientId: configuration.azureCredentials.clientId,
        redirectUri:  (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
        loginStyle: 'popup',
      });

      const blobServiceClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net`,
        credential)

      // Get a reference to a container
      const containerClient = blobServiceClient.getContainerClient(container.name);


      const blobs = []
      // List the blob(s) in the container.
      for await (const blob of folder === "/" ? containerClient.listBlobsByHierarchy("/") :
        containerClient.listBlobsByHierarchy("/", { prefix: folder })) {

        blobs.push(blob)


      }
      return blobs
    }
  }
  catch (error) {
    if (decriptedSas) {
    }
    else {
      if (retry === undefined) {
        await getTokenFromAzureUser(dispatch)
        return getItemsFromContainer(configuration, folder, storageAccountName, container, dispatch, false)
      }
      else {
        generateError(dispatch, error)
        return []
      }
    }
  }
}


export async function getItemsFromContainerADLv2(configuration, folder, storageAccountName, containerName, dispatch, retry, decriptedSas) {
  

  try {
    if (decriptedSas) {
      const datalakeServiceClient = new DataLakeServiceClient(`https://${storageAccountName}.dfs.core.windows.net?${decriptedSas}`);
      // Get a reference to a container
      const fileSystemClient = datalakeServiceClient.getFileSystemClient(containerName.name);
      const blobs = []
      let iter = await fileSystemClient.listPaths({ path: folder, recursive: false });
      for await (const path of iter) {

        blobs.push(path)
      }
      return blobs

    }
    else {
      const credential = new InteractiveBrowserCredential({
        tenantId: configuration.azureCredentials.domain,
        clientId: configuration.azureCredentials.clientId,
        redirectUri:  (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
        loginStyle: 'popup',
      });

      const datalakeServiceClient = new DataLakeServiceClient(
        `https://${storageAccountName}.dfs.core.windows.net`, credential);
      const fileSystemClient = datalakeServiceClient.getFileSystemClient(containerName.name);
     const blobs = []
      let iter = await fileSystemClient.listPaths({ path: folder, recursive: false });
      for await (const path of iter) {

        blobs.push(path)
      }
      return blobs
    }
  }
  catch (error) {
    if (decriptedSas) {
      console.log('error getNameListChildrenStorageObject', error)
    }
    else {
      if (retry === undefined) {
        await getTokenFromAzureUser(dispatch)
        return getItemsFromContainerADLv2(configuration, folder, storageAccountName, containerName, dispatch, false)
      }
      else {

        return []
      }
    }
  }
}




export async function onlistContainersFromStorageAccount(subscriptionId, resourceGroupName, storageAccountName, dto, dispatch, retry) {

  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);

    const clone = JSON.parse(JSON.stringify(dto));

    const azStorageCtx = new StorageManagementClientContext(
      browserCredential,
      subscriptionId
    );

    const containersClient = new BlobContainers(azStorageCtx)

    clone.containers = (await containersClient.list(resourceGroupName, storageAccountName)).map(x => {
      return {
        name: x.name,
        storageAccountItemDTOSRoot: [
          {
            type: 'folder',
            name: 'test',
            fullName: 'test',
            resourceGroupName: resourceGroupName,
            storageAccount: storageAccountName,
            container: x.name,
            children: [],
            level: 0,
            parent: x.name
          }
        ]
      }
    })

    return clone

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onlistContainersFromStorageAccount(subscriptionId, resourceGroupName, storageAccountName, dto, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return dto
    }
  }
}



export async function onListDatabasesFromServer(subscriptionId, resourceGroupName, serverName, dto, dispatch, retry) {

  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);

    const clone = JSON.parse(JSON.stringify(dto));



    const accountsClient = new SqlManagementClient(browserCredential,
      subscriptionId);


    clone.sqlDatabaseDTOS = (await accountsClient.databases.listByServer(resourceGroupName, serverName)).map(x => {
      return ({
        resourceGroupName: resourceGroupName,
        name: x.name,
        region: '',
        schemas: []
      }
      )
    })

    return clone

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListDatabasesFromServer(subscriptionId, resourceGroupName, serverName, dto, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return dto
    }
  }

}



/****SYNAPSE */


export async function onListSynapseDatabaseForRG(subscriptionId, resourceGroup, dispatch, retry) {

  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);
    const accountsClient = new SynapseManagementClient(browserCredential,
      subscriptionId);

    const storage = await accountsClient.workspaces.listByResourceGroup(resourceGroup)
    const newStorages = storage.map(s => {
      return ({
        resourceGroupName: resourceGroup,
        name: s.name,
        region: s.location,
        sqlDatabaseDTOS: []

      })
    })




    return newStorages.flat()

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListSynapseDatabaseForRG(subscriptionId, resourceGroup, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }


}

export async function onListSynapseDatabaseAll(subscriptionId, dispatch, retry) {

  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);
    const accountsClient = new SynapseManagementClient(browserCredential,
      subscriptionId);

    const storage = await accountsClient.workspaces.list()
    const newStorages = storage.map(s => {
      return ({
        resourceGroupName: getResourceGroupFromId(s.id),
        name: s.name,
        region: s.location,
        sqlDatabaseDTOS: []

      })
    })




    return newStorages.flat()

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListSynapseDatabaseAll(subscriptionId, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }
}




export async function onListDatabasesFromWorkspace(subscriptionId, resourceGroupName, serverName, dto, dispatch, retry) {

  try {
    let token = await getAccessTokenAzure(dispatch)
    let browserCredential = new customTokenCredential(token);
    const clone = JSON.parse(JSON.stringify(dto));

    const accountsClient = new SynapseManagementClient(browserCredential,
      subscriptionId);

    console.log('accountsClient',accountsClient)
    const sqlPools = (await accountsClient.sqlPools.listByWorkspace(resourceGroupName, serverName)).map(x => {
      return ({
        resourceGroupName: resourceGroupName,
        name: x.name,
        region: '',
        schemas: []
      }
      )
    })

    const bigDataPools = (await accountsClient.bigDataPools.listByWorkspace(resourceGroupName, serverName)).map(x => {
      return ({
        resourceGroupName: resourceGroupName,
        name: x.name,
        region: '',
        schemas: []
      }
      )
    })

    const serverlessPools = [{
      resourceGroupName: resourceGroupName,
        name: "azuredataengcertsynapserg-ondemand.sql.azuresynapse.net",
        region: '',
        schemas: []
    }]

    clone.sqlDatabaseDTOS = sqlPools.concat(bigDataPools.concat(serverlessPools))



    return clone

  }
  catch (error) {
    if (retry === undefined) {
      await getTokenFromAzureUser(dispatch)
      return onListDatabasesFromWorkspace(subscriptionId, resourceGroupName, serverName, dto, dispatch, false)
    }
    else {
      generateError(dispatch, error)
      return []
    }
  }

}