import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Typography, FormControl, Checkbox,} from '@material-ui/core'


export default function LineChartZoomAndScroll(props) {

   
    const [processData, setProcessData] = React.useState([])
    //const [createdChar, setCreatedChar] = React.useState(undefined)
    const [separateSeries, setSeparateSeries] = React.useState(false)
    const [dataVolumeFlag, setDataVolumeFlag] = React.useState(false)

    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; props.hour === true ? dt.setHours(dt.getHours() + 1) : dt.setDate(dt.getDate()+1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };

    
   

    function prepareData(rawRuleData, momentumStart, momentumEnd) {


        var currentDateString = new Date(momentumEnd)
        var previousDateString = new Date(momentumStart)

        var daylist = getDaysArray(new Date(previousDateString), new Date(currentDateString)).map((v) => v.toISOString().slice(0, props.hour===true ?13 : 10))
        const data = rawRuleData.map(x => {
            return {
                createTime: x.year+"-"+String(x.month).padStart(2, '0')+"-"+String(x.day).padStart(2, '0')+ (props.hour === true ? "T"+String(x.hour).padStart(2, '0'):""),
                numIn: x.sumNumIn,
                numOk: x.sumNumOk,
                numKo: x.sumNumKo,
                executionId: x.executionId,
                qualityPointId: x.qualityPointId,

            }
        }).filter(x => x.numIn > 0)

        var result = [];

        data.reduce(function (res, value) {
            if (!res[value.createTime]) {
                res[value.createTime] = { createTime: value.createTime, numIn: 0, numOk: 0, numKo: 0 };
                result.push(res[value.createTime])
            }
            res[value.createTime].numIn += value.numIn;
            res[value.createTime].numOk += value.numOk;
            res[value.createTime].numKo += value.numKo;
            return res;
        }, {})


        var associativeResult = {};
        result.forEach(function (val, i) {
            associativeResult[val.createTime] = val;

        });


        var chartData = [];
        for (var i = 0; i < daylist.length; i++) {
            const dataObject = associativeResult[daylist[i]]
            if (dataObject !== undefined) {
                chartData.push({
                    date: daylist[i],
                    numIn: dataObject.numIn > 0 ? dataObject.numIn : 0,
                    numOk: dataObject.numOk > 0 ? dataObject.numOk : 0,
                    numKo: dataObject.numKo > 0 ? dataObject.numKo : 0,
                    dqi: dataObject.numOk / dataObject.numIn > 0 ? parseFloat((100 * dataObject.numOk / dataObject.numIn).toFixed(2)) : 0,
                    remaining: 100 - (dataObject.numOk / dataObject.numIn > 0 ? parseFloat((100 * dataObject.numOk / dataObject.numIn).toFixed(2)) : 0),

                });
            }
            else {
                chartData.push({
                    date: daylist[i],
                    numIn: 0,
                    numOk: 0,
                    dqi: 0,
                    remaining: 0

                });
            }

        }

        setProcessData(chartData)

    }

    function createDoubleDQI() {
        var chart = am4core.create(props.fullscreen === true ? "chartdivLineFullscreen" :"chartdivLine", am4charts.XYChart);
        // Add data
        chart.data = processData
        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd-HH";
        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.labels.template.fontSize = 10;
        /*
       var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
       valueAxis1.renderer.labels.template.fontSize = 10;
       valueAxis1.renderer.opposite = true;
    
       var series1 = chart.series.push(new am4charts.ColumnSeries());
       series1.dataFields.valueY = "numIn";
       series1.dataFields.dateX = "date";
       series1.yAxis = valueAxis1;
       series1.tooltipText = "{dateX}: [bold]{valueY}[/]";
       */
        var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        
        valueAxis2.renderer.labels.template.fontSize = 10;

        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        if(dataVolumeFlag === true){
            series.dataFields.valueY = "numOk";
        }
        else{
            series.dataFields.valueY = "dqi";
        }
        
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.fillOpacity = 0.3;

        series.minBulletDistance = 10;

        if(dataVolumeFlag === true){
            series.tooltipText = "{valueY}";
        }
        else{
            series.tooltipText = "{valueY} %";
        }

        
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.fillOpacity = 0.5;
        series.tooltip.label.padding(12, 12, 12, 12)
        series.yAxis = valueAxis2;
        series.tensionX = 0.77;
        series.segments.template.interactionsEnabled = true;
        series.stroke = am4core.color("#108521AA"); // red outline
        series.fill = am4core.color("#108521AA"); // green fill



        // Create series
        var seriesKo = chart.series.push(new am4charts.LineSeries());
        if(dataVolumeFlag === true){
            seriesKo.dataFields.valueY = "numKo";
        }
        else{
            seriesKo.dataFields.valueY = "remaining";
        }
        seriesKo.dataFields.dateX = "date";
        seriesKo.strokeWidth = 2;
        seriesKo.fillOpacity = 0.3;

        seriesKo.minBulletDistance = 10;
        if(dataVolumeFlag === true){
            seriesKo.tooltipText = "{valueY}";
        }
        else{
            seriesKo.tooltipText = "{valueY} %";
        }
        
        seriesKo.tooltip.pointerOrientation = "vertical";
        seriesKo.tooltip.background.cornerRadius = 20;
        seriesKo.tooltip.background.fillOpacity = 0.5;
        seriesKo.tooltip.label.padding(12, 12, 12, 12)
        seriesKo.yAxis = valueAxis2;
        seriesKo.tensionX = 0.77;
        seriesKo.segments.template.interactionsEnabled = true;
        seriesKo.stroke = am4core.color("#F3410DAA"); // red outline
        seriesKo.fill = am4core.color("#F3410DAA"); // green fill


        // Add scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.minHeight = 30;
        chart.scrollbarX.series.push(series);
        chart.scrollbarX.series.push(seriesKo);

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        if(props.fullscreen === true){
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [{
                "label": "...",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "json", "label": "JSON" },
                  { "label": "Print", "type": "print" }
                ]
              }];
        }

        if(processData && processData.length >0){
            chart.events.on("ready", function () {
                dateAxis.zoomToDates(
                  new Date(processData[0].date),
                  new Date(processData[processData.length-1].date)
                );
              });
        }

        //setCreatedChar(chart)
    }

    function createBaseDQI() {

        var chart = am4core.create(props.fullscreen === true ? "chartdivLineFullscreen" :"chartdivLine", am4charts.XYChart);
        // Add data
        chart.data = processData
        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd-HH";
        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.labels.template.fontSize = 10;
        /*
       var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
       valueAxis1.renderer.labels.template.fontSize = 10;
       valueAxis1.renderer.opposite = true;
    
       var series1 = chart.series.push(new am4charts.ColumnSeries());
       series1.dataFields.valueY = "numIn";
       series1.dataFields.dateX = "date";
       series1.yAxis = valueAxis1;
       series1.tooltipText = "{dateX}: [bold]{valueY}[/]";
       */
        var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        
        valueAxis2.renderer.labels.template.fontSize = 10;

        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        if(dataVolumeFlag === true){
            series.dataFields.valueY = "numIn";
        }
        else{
            series.dataFields.valueY = "dqi";
        }
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.fillOpacity = 0.3;

        series.minBulletDistance = 10;
        series.tooltipText = "{valueY} %";
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.fillOpacity = 0.5;
        series.tooltip.label.padding(12, 12, 12, 12)
        series.yAxis = valueAxis2;
        series.tensionX = 0.77;
        series.segments.template.interactionsEnabled = true;

        /*
        series.segments.template.events.on("hit", function (ev) {
            var item = ev.target.dataItem.component.tooltipDataItem.dataContext;
        });
        */
        // Add scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.minHeight = 30;
        chart.scrollbarX.series.push(series);

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        if(props.fullscreen === true){
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [{
                "label": "...",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "json", "label": "JSON" },
                  { "label": "Print", "type": "print" }
                ]
              }];
        }
        if(processData && processData.length >0){
            chart.events.on("ready", function () {
                dateAxis.zoomToDates(
                  new Date(processData[0].date),
                  new Date(processData[processData.length-1].date)
                );
              });
        }
       

        //setCreatedChar(chart)
    }

    React.useLayoutEffect(() => {
        
        if(separateSeries===true){
            createDoubleDQI()
        }
        else{
            createBaseDQI()
        }
        

    }, [processData,  separateSeries,dataVolumeFlag]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (props.data && props.data.length > 0 && props.startDate && props.endDate) {
            prepareData(props.data, props.startDate, props.endDate)
        }
        else{
            setProcessData([])
        }

    }, [props.data, props.startDate, props.endDate])// eslint-disable-line react-hooks/exhaustive-deps

   
    
   
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: 'flex-start' }}>
            <div style={{ marginLeft: '1%', marginTop: '1%', width: '10%', display: 'flex', minWidth: '100px', flexDirection: 'column' }}>


                <FormControl style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start', alignItems:'center' }}>
                    <Checkbox
                        checked={separateSeries}
                        onChange={event => setSeparateSeries(event.target.checked)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                        
                    />
                    <Typography variant='caption' >Separate Series Ok/Ko</Typography>
                </FormControl>


                <FormControl style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start', alignItems:'center' }}>
                    <Checkbox
                        checked={dataVolumeFlag}
                        onChange={event => {setDataVolumeFlag(event.target.checked)}}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                        
                    />
<Typography variant='caption' >{'Data Volume' }</Typography>
                </FormControl>

               
            </div>
            <div id={props.fullscreen === true ? "chartdivLineFullscreen" :"chartdivLine"} style={{ width: '90%', height: '90%' }} ></div></div>


    )



}
