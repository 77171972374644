
import React from 'react'
import { TextField, Grid } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'




export default function SearchButton(props) {

    const [searchText, setText] = React.useState()

    const onChange = (event) => {
        setText(event.target.value)
        props.updateText(event.target.value)
    }


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Grid container spacing={1} alignItems="flex-end" style={{ minWidth: '150px' }}>
                <Grid item>
                    <SearchIcon style={{ color: '#474b51' }} />
                </Grid>
                <Grid item>
                    <TextField id="search_button_id" label="Search..."
                        inputProps={{
                            style: { fontSize: 'calc(3px + 0.3vw)', minWidth: '130px', height: '20px' },
                        }}

                        onChange={onChange}
                        value={searchText}
                    />
                </Grid>
            </Grid>
        </div>



    )

}