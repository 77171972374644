import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as commonStyles from 'style/CommonStyles'



export default function SmallBar(props) {

  const [data, setData] = React.useState(0)

  React.useEffect(() => {
    if (props.data) {
        if(props.inverted === false){
            setData(props.data)
        }
        else{
            setData(100-props.data)
        }
      

    }
  }, [props.data])// eslint-disable-line react-hooks/exhaustive-deps

  React.useLayoutEffect(() => {
    am5.array.each(am5.registry.rootElements, function(root) {
      if (root && root.dom.id === props.id) {
        root.dispose();
      }
    });
    let root = am5.Root.new(props.id);


    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout
    }));


    let datas = [{
      "year": "2021",
      "value1": data >= 0 ? data : 0,
      "value2": data > 0 ? 100 - data : 100,
    }]



    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "year",
      renderer: am5xy.AxisRendererY.new(root, {}),
      visible: false
    }));

    yAxis.data.setAll(datas);

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: 100,
      renderer: am5xy.AxisRendererX.new(root, {}),
      visible: false
    }));

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(fieldName, color, bullet, noData) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        baseAxis: yAxis,
        valueXField: fieldName,
        categoryYField: "year",
        fill: color
      }));

      if (!noData) {
        series.columns.template.setAll({
          tooltipText: noData ? 'No Data' : props.prefix ? `[font-size:12px]${props.prefix} {valueX}%` : "{valueX}%",
          tooltipY: am5.percent(90)
        });
      }
      series.data.setAll(datas);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      if (bullet) {
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: noData ? 'No Data' :   "{valueX}%" ,
              fontFamily: commonStyles.fontFamily,
              fontSize: commonStyles.tableCellFontSize,
              fill: "#000000",
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });
      }
    }


    if (datas.value1 !== 0) {
      makeSeries("value1", commonStyles.mainColorSmallOppacity, true, false);
      
    } 

    return () => {
      chart.dispose()
    }

  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
      <div id={props.id} style={{ width: "100%", height: "100%" }}></div>
  )
}