import React from 'react';
//import AqtivaAppBar from './../components/template/AqtivaAppBar'
import * as pathHelper from './../components/template/PathHelper'
import QualityProjectForm from './../components/quality/project/QualityDetailProjectPage'
import AppBarTemplate from 'components/template/AppBarTemplate'
import { useSelector } from 'react-redux'

import ReactGA from 'react-ga';

import * as actions from 'actions'
import {  useDispatch } from 'react-redux'



ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

export default function QualityProjectPage(props) {

    const dispatch = useDispatch();


    //const [searchText, setSearchText] = React.useState(undefined)
    const [projectId, setProjectId] = React.useState(undefined)


    const [qpId, setQpId] = React.useState(undefined)
    const [notificationId, setNotificationId] = React.useState(undefined)
    const [recommenderShow, setRecommenderShow] = React.useState(undefined)

    const [newRuleFormFlag, setNewRuleFormFlag] = React.useState(false)

    const[projectName, setProjectName] = React.useState('')

    var projects = useSelector(store => Object.values(store.projects))

    async function getRuleFormFlag(){
        const newRuleFormFlagStr = await dispatch(actions.getControlValueNoSnackbar("NEW_RULE_FORM_FLAG"))
        if(newRuleFormFlagStr !== undefined && newRuleFormFlagStr !== null && newRuleFormFlagStr === "TRUE"){
            setNewRuleFormFlag(true)
        }
    }

    React.useEffect(()=>{
        getRuleFormFlag()
    }, [])

    React.useEffect(()=>{
        if(props && props.match && props.match.params && props.match.params.projectId){
            if(projects && projects.length>0){
                if(projects.filter(x=>x._id === projectId)[0]){
                    setProjectName( projects.filter(x=>x._id === projectId)[0].name)
                }
                

            }
        } 
    },[JSON.stringify(projects), props.match])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(()=>{
        if(props && props.match && props.match.params && props.match.params.projectId){
            setProjectId(props.match.params.projectId)
        }
        
        if(props && props.match && props.match.params && props.match.params.qpId){
            setQpId(props.match.params.qpId)
        }
        if(props && props.match && props.match.params && props.match.params.notificationId){
            setNotificationId(props.match.params.notificationId)
        }
        if(props && props.match && props.match.params && props.match.params.recommenderShow){
            setRecommenderShow(props.match.params.recommenderShow)
        }
        


        
    },[props])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div >
          {/*  <AqtivaAppBar history={props.history} title={'Home'} 
            arrayPath={pathHelper.getPathArray('ProjectDetail')}
            homeIcon = {true} 
            setSearchText={setSearchText}>
            
            {projectId!==undefined &&
            <QualityProjectForm projectId={projectId} 
            searchText={searchText}/>}
               
           
            </AqtivaAppBar>
             */}
             
             <AppBarTemplate
        history={props.history}
        title={projectName && projectName.length>30 ? projectName.substring(0,30)+"..." : projectName}
        arrayPath={pathHelper.getPathArray("ProjectDetail")}
      />
      {projectId!==undefined &&
            <QualityProjectForm 
            newRuleFormFlag = {newRuleFormFlag}
            projectId={projectId} 
            searchText={undefined} //CHANGE FOR searchText
            qpId={qpId}
            notificationId={notificationId}
            recommenderShow={recommenderShow}
            history={props.history}/>}
               
        </div>

    );
}