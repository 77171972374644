import React, { useState, useEffect } from 'react'
import AqtivaDialog from '../../../../common/AqtivaDialog'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import {  Card, Input, InputLabel, MenuItem, TextField, Select } from '@material-ui/core'
import {  useDispatch } from "react-redux";
import * as actions from '../../../../../actions';



export default function DialogConfirmUserEdition(props) {
    const dispatch = useDispatch()

    const [user, setUser] = useState(undefined)
    const [userProvider, setUserProvider] = useState('google')
    const [userName, setUserName] = useState()
    const [userRole, setUserRole] = useState()
    const [password, setPassword] = useState()
    //const [errors, setErrors] = useState()
    //TODO: CHECK
    const errors = undefined

    useEffect(() => {
        if (props.user) {
            console.log('user', props.user)
            setUser(props.user)
            setUserProvider(props.user.provider)
            setUserName(props.user.name)
            setUserRole(props.user.role)
            setPassword(props.user.password)
        }
    }, [props.user])

    function confirmEditUser(user, userProvider, userName, userRole) {

        let newUser = JSON.parse(JSON.stringify(user))
        newUser.name = userName
        newUser.provider = userProvider
        newUser.role = userRole
        newUser.password = password


        dispatch(actions.editUserLicense(newUser))
        props.handleCloseDialogUserEdition()
    }



    return (
        <AqtivaDialog
            // Confirm Dialog
            visible={props.visibleConfirmEdit}
            dialog={props.visibleConfirmEdit}
            fullWidth={true}
            maxWidth={'sm'}
            title={"Do you want to edit this user?"}
            editable={false}
            titleIcon={faEdit}
            cancelText={'Cancel'}
            cancelCallback={() => { props.handleCloseDialogUserEdition() }}
            confirmText={'Edit'}
            confirmCallback={event => {
                confirmEditUser(user, userProvider, userName, userRole)
            }}
        >
            <Card style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                {userName && <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="User Name"
                    type="email"
                    style={{ width: '300px' }}
                    value={userName}
                    onChange={event => setUserName(event.target.value)}
                />}
                {errors && errors.userName &&
                    props.renderError(errors.userName)}

                {userProvider === 'local' && 
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="password"
                                            label="Password"
                                            type="password"
                                            style={{ width: '300px' }}
                                            value={password}
                                            onChange={event => setPassword(event.target.value)}
                                        />
                                    }

                <InputLabel htmlFor="age-auto-width">Provider</InputLabel>
                {userProvider && <Select
                    style={{ width: '300px' }}
                    value={userProvider}
                    onChange={event => setUserProvider(event.target.value)}
                    input={<Input name="age" id="age-auto-width" />}
                    fullWidth
                >


                    <MenuItem key={'google'} value={'google'}>google</MenuItem>
                    <MenuItem key={'azure'} value={'azure'}>azure</MenuItem>
                    <MenuItem key={'local'} value={'local'}>local</MenuItem>
                </Select>}
                {errors && errors.provider &&
                    props.renderError(errors.provider)}

                <InputLabel htmlFor="age-auto-width">Role</InputLabel>
                {userRole && <Select
                    style={{ width: '300px' }}
                    value={userRole}
                    onChange={event => setUserRole(event.target.value)}
                    input={<Input name="age" id="age-auto-width" />}
                    fullWidth
                >
                    {props.license.role === 'SUPER_ADMIN' &&
                        <MenuItem key={'SUPER_ADMIN'} value={'SUPER_ADMIN'}>Super Admin</MenuItem>}
                    <MenuItem key={'ADMIN'} value={'ADMIN'}>Admin</MenuItem>
                    <MenuItem key={'USER'} value={'USER'}>User</MenuItem>
                </Select>}
            </Card>

        </AqtivaDialog>
    )
}