import React, { useState } from 'react'
import AqtivaDialog from '../../../AqtivaDialog'
import { Typography, CircularProgress } from '@material-ui/core'
import { useDispatch } from "react-redux";
import * as actions from '../../../../../actions/index'


export default function ConfirmDeleteDialog(props) {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    return (
        <AqtivaDialog
            visible={props.showConfirmDialog}
            dialog={props.showConfirmDialog}
            fullWidth={true}
            maxWidth={'xs'}
            title={"Delete Schedule"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setShowConfirmDialog(false)
                props.setSelectedSchedule(undefined)
            }}
            confirmText={'Confirm'}
            confirmCallback={async () => {
                setLoading(true)
                await dispatch(actions.deleteSchedule(props.selectedSchedule._id, props.userId))
                await dispatch(actions.fetchProjects(props.userId))
                props.setShowConfirmDialog(false)
                setLoading(false)
                props.setUpdateCounter(props.updateCounter + 1)
            }}
        >
            {!loading && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                Are you sure you want to delete this scheduled job?
            </Typography>}
            {loading && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                Deleting scheduled job... <CircularProgress style={{ width: '15px', height: '15px' }} />
            </Typography>}
        </AqtivaDialog>
    )
}