import React from 'react';

import SmallDonut from './SmallDonut'
import SmallBar from './SmallBar'
import { Typography, } from '@material-ui/core'

export default function DQIDimensionsChartSmall(props) {

    
    
    return(
      <div style={{
        width: '30%', height: '50%', display: 'flex',
        justifyContent: 'flex-start'
      }}>
        <SmallDonut data={props.data} id={props.dimension+"smallDonut"} />

        <div style={{ width: '60%', justifyContent: 'center', display:'flex', flexDirection:'column',
  alignItems: 'center' }}>
          <Typography style={{marginTop:'-5%'}} variant="subtitle2">{props.dimension}</Typography>
          <SmallBar  data={props.data} id={props.dimension+"smallBar"}  />
        </div>
      </div>
    )
}
