import React from 'react';
import { ICommonRule, IColumn } from '../../../interfaces/RuleInterfaces';

interface FormRuleFormatProps {
    commonRule: ICommonRule,
    setSpecific: (value: any)=>any,
    editableRule: any,
    specific: any,
    source: IColumn []

}

export default function FormRuleFormat(props: FormRuleFormatProps) {
    

    
    function createSpecific() {
        //creamos el objeto de regla especifica
        const specific = {
            subtype: 'statistics'
            
        }
        props.setSpecific(specific)

    }

    React.useEffect(() => {
        if (props.commonRule && props.commonRule.selectedColumns && props.commonRule.selectedColumns.length > 0) {
            createSpecific()
        }
    }, [props.source]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div></div>
    )
}

