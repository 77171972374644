import React, { useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "./../../components/common/Header/Header.js";
import Footer from "./../../components/common/Footer/Footer.js";
import GridContainer from "./../../components/common/Grid/GridContainer.js";
import GridItem from "./../../components/common/Grid/GridItem.js";
import Button from "./../../components/common/CustomButtons/Button.js";
import Card from "./../../components/common/Card/Card.js";
import CardBody from "./../../components/common/Card/CardBody.js";
import CardHeader from "./../../components/common/Card/CardHeader.js";
import CardFooter from "./../../components/common/Card/CardFooter.js";
import CustomInput from "./../../components/common/CustomInput/CustomInput.js";

import styles from "./../../assets/jss/material-kit-react/views/loginPage.js";

import image from "./../../assets/img/background1.jpeg";

import {AUTH0_AUTH_URL,GOOGLE_AUTH_URL,FACEBOOK_AUTH_URL,ACCESS_TOKEN,baseUrlLogin} from './../../Constants'


import {useDispatch, useSelector} from 'react-redux'
import * as actions  from './../../actions'
import Typography from "@material-ui/core/Typography";

import trackEvent from './../../trackEvent';
import { dispatchError } from "../common/axios/axiosHelper.js";
import { useHistory } from 'react-router-dom'

import { isEmail } from "validator";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "actions/UserAuthActions";
import * as commonStyles from 'style/CommonStyles'

import { useLocation } from "react-router-dom"


const useStyles = makeStyles(styles);




export default function LoginPage(props) {
  //ReactGA.pageview(window.location.pathname + window.location.search);
  const history = useHistory(); 

  const dispatch = useDispatch();

  const location = useLocation()

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [localLoadingError, setLocalLoadingError] = useState(undefined)
  //const { isLoggedIn } = useSelector(state => state.auth);

  //var token = localStorage.getItem(ACCESS_TOKEN);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  React.useEffect(() => {
    if(location && location.state && location.state.error){
      setLocalLoadingError(location.state.error)
      
    }
    else if(location && location.c && location.c.error){
      setLocalLoadingError(location.c.error)
      
    }
  }, []);

  React.useEffect(() => {

    if(props && props && props.location && props.location.state && props.location.state.error!==undefined ){
      if(props.location.state.error===""){
        setLocalLoadingError("User not found")
      }
      else{
        setLocalLoadingError(props.location.state.error)
      }
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  
  const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const email = value => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  
  const handleLogin = (e) => {
    try{
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then((response) => {
          if(response && response.result && response.result === 'ko'){
            setLoading(false);
            setLocalLoadingError("Error in Login. User or password not correct")
          }
          else if(response && response.data && response.data.accessToken){
            setLocalLoadingError(undefined)
            console.log('response.data', response.data.accessToken)
            localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
            console.log('setAuthenticated', props.setAuthenticated)
            if(props.setAuthenticated!==undefined){
              console.log('set Authenticated', true)
              props.setAuthenticated(true)
            }
            history.push("/");

          }
          
        })
        .catch((e) => {
          console.log('errorrr', e)
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }
  catch(e){
    dispatchError("Error in Login: "+e)
    console.error(e)
    setLoading(false);
  }
}

  
 
  return (
    <div>
    
      <div
        style={{
          backgroundImage: "url('/background_blue_black.jpg')",
          backgroundSize: "cover",
          backgroundPosition: " center"
        }}
      >
       
        
        <div className={classes.container}>
          <GridContainer justify="center" style={{marginTop:'0%'}}>
              <GridItem xs={12} sm={12} md={12}>
              <img alt=""
              src={`${process.env.PUBLIC_URL}/omma_logo_white_h.png`} 
              style={{width:'30%',marginLeft:'35%',marginBottom:'5%'}}
              />
              </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primaryNoTransparent" className={classes.cardHeader}>
                  
                      <img className="img-fluid" alt=""
                      src={`${process.env.PUBLIC_URL}/logo_omma_O_white.png`} 
                      style={{width:'15%', height:'15%', marginBottom:'-5%'}}
                      />
                    <h4>Login</h4>
                    
                    
                    <div className={classes.socialLine}>
                      
                      <Button
                        justIcon
                        target="_self"
                        href={FACEBOOK_AUTH_URL}
                        color="transparent"
                        onClick={ event=> trackEvent('Login','Login Azure')}
                       
                      >
                        <i className={"fab fa-microsoft"} />
                      </Button>

                      <Button
                        justIcon
                        target="_self"
                        href={AUTH0_AUTH_URL}
                        color="transparent"
                        onClick={ event=> trackEvent('Login','Login Auth0')}
                       
                      >
                        <i className={"fas fa-star"} />
                      </Button>
                     
                      <Button
                        justIcon
                        href={GOOGLE_AUTH_URL}
                        target="_self"
                        color="transparent"
                        onClick={ event=> trackEvent('Login','Login Azure')}
                        
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div>
                  </CardHeader>
                   
                
                  <div style={{margin:'15px'}}>
                  <Form onSubmit={handleLogin} ref={form}>
                  <div className="form-group">
                      <label htmlFor="username">Email</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="username"
                        value={username}
                        onChange={onChangeUsername}
                        validations={[required, email]}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[required]}
                    />

             {localLoadingError &&  <Typography variant= 'caption' style={{color:commonStyles.errorColor}}> {localLoadingError}</Typography>
}
                    
          </div>
            <div style={{marginTop:'15px'}}/>
              <div className="form-group" >
                <button className="btn btn-primary btn-block" style={{backgroundColor:commonStyles.mainColor}} disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span style={{color:'#FFFFFF'}} >Login</span>
                </button>
              </div>

             
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>

        <div>
            <Button
              href={process.env.REACT_APP_BACK_HOST+"/public/auth/redirect/check/localhost:3000"}
              target="_self"
               color="transparent"
               >
                Validate Backend URL
                </Button>
          </div>
                  
                  </div>
                </form>
               
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        
      </div>
    </div>
  );
}

