import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from "@material-table/core";

import * as commonStyles from 'style/CommonStyles'



class QualityRecordPowerTable extends Component {

  

   truncate( str, n, useWordBoundary ){
     if(str===null || str === undefined){
       return str
     }
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary 
      ? subString.substr(0, subString.lastIndexOf(" ")) 
      : subString) + " ...";
  };

 

  render(){
    const { classes } = this.props;
    return(
      <div className={classes.root}>
<MaterialTable
      title=""
      columns={[
        { title: 'Timestamp', field: 'createTime' },
        { title: 'Dimension', field: 'dimension' },
        { title: 'Level', field: 'level' },
        { title: 'Num.In', field: 'numIn', type: 'numeric' },
        { title: 'Num.Out', field: 'numOut', type: 'numeric' },
        { title: 'Num.OK', field: 'numOk', type: 'numeric' },
        { title: 'Num.KO', field: 'numKo', type: 'numeric' },
        { title: 'Name', field: 'ruleName' },
        { title: 'Message', field: 'msg',render: row =>  this.truncate(row.msg,250,true) },
        
      ]}
      data={this.props.data ? this.props.data.filter(e=>e.type==='QUALITY_RULE' || e.type==='rule'): []}        
      options={{
        exportButton: true,
        sorting: true,
        filtering: true,
      
          headerStyle: {
              fontSize: 'calc(8px + 0.2vw)',
              backgroundColor: commonStyles.mainColor,
              color: '#FFF'
          },
          cellStyle: {
              fontSize: 'calc(6px + 0.2vw)',
          },
          filterCellStyle: {
              fontSize: 'calc(6px + 0.2vw)',
          },

          
      }}
      
    />
        </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    
  },
});

QualityRecordPowerTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QualityRecordPowerTable);