
import React from 'react';

import { ILogicColumnCondition, ILogicColumnOperator, ILogicSqlExpression } from 'interfaces/LogicCondition';
import JoinOperator from './JoinOperator';
import PaperColumnCondition from './PaperColumnCondition';
import PaperSqlExpression from './PaperSqlExpression';
import AddOperatorCard from 'components/rule/RuleCreationComponent/ConditionGenerator/AddOperatorCard';

import { IColumn } from '../../../../interfaces/RuleInterfaces';



type IProps = {
    columnConditions: ILogicColumnCondition[] | undefined,
    columnOperations: ILogicColumnOperator[] | undefined,
    sqlExpressions: ILogicSqlExpression[] | undefined,
    baseHeight: number,
    baseWidth: number,
    columns: IColumn[]
    setColumnConditions: (value: ILogicColumnCondition[]) => (void),
    setColumnOperations: (value: ILogicColumnOperator[]) => (void),
    setSqlExpression: (value: any) => (any)

}



export default function ConditionOperator(props: IProps) {


    function addOperatorJoinFunction(operator: ILogicColumnOperator, op: ILogicColumnOperator, idxOps: number) {
        const newColumnoperations = JSON.parse(JSON.stringify(props.columnOperations))

        if (props.columnOperations) {
            var oldOps = props.columnOperations[idxOps]
            var operators = oldOps.columnOperations
            if (operators) {
                //add new colCondition
                operators.push(operator)
                oldOps.columnOperations = operators
                newColumnoperations[idxOps] = oldOps
                props.setColumnOperations(newColumnoperations)
            }
            else {

                operators = [operator]
                oldOps.columnOperations = operators
                newColumnoperations[idxOps] = oldOps
                props.setColumnOperations(newColumnoperations)
            }
        }

    }

    function addOperatorConditionFunction(condition: ILogicColumnCondition, op: ILogicColumnOperator, idxOps: number) {
        const newColumnoperations = JSON.parse(JSON.stringify(props.columnOperations))
        if (props.columnOperations) {
            var oldOps = props.columnOperations[idxOps]
            var conditions = oldOps.columnConditions
            if (conditions) {
                //add new colCondition
                conditions.push(condition)
                oldOps.columnConditions = conditions
                newColumnoperations[idxOps] = oldOps
                props.setColumnOperations(newColumnoperations)
            }
            else {

                conditions = [condition]
                oldOps.columnConditions = conditions
                newColumnoperations[idxOps] = oldOps
                props.setColumnOperations(newColumnoperations)
            }
        }

    }

    function addSqlExpressionFunction(expresion: ILogicSqlExpression, idxOps: number) {
        console.log('event', expresion)
        const newColumnoperations = JSON.parse(JSON.stringify(props.sqlExpressions))

        if (props.columnOperations) {
            var oldOps = props.columnOperations[idxOps]
            var expresions = oldOps.sqlExpressions
            if (expresions) {
                //add new colCondition
                expresions.push(expresion)
                oldOps.sqlExpressions = expresions
                newColumnoperations[idxOps] = oldOps
                props.setColumnOperations(newColumnoperations)
            }
            else {

                expresions = [expresion]
                oldOps.sqlExpressions = expresions
                newColumnoperations[idxOps] = oldOps
                props.setColumnOperations(newColumnoperations)
            }
        }
    }

    function setOperatorJoinFunctionVector(operator: ILogicColumnOperator[], idxOps: number) {
        const newColumnoperations = JSON.parse(JSON.stringify(props.columnOperations))

        if (props.columnOperations) {
            var oldOps = props.columnOperations[idxOps]
            var operators = oldOps.columnOperations
            //add new colCondition
            operators = operator
            oldOps.columnOperations = operators
            newColumnoperations[idxOps] = oldOps
            props.setColumnOperations(newColumnoperations)

        }


    }

    function deleteCondition(condition: ILogicColumnCondition) {
        const conditionStr = JSON.stringify(condition)
        const newColumnConditions = JSON.parse(JSON.stringify(props.columnConditions))
        for (var i = 0; i < newColumnConditions.length; i++) {
            if (JSON.stringify(newColumnConditions[i]) === conditionStr) {
                newColumnConditions.splice(i, 1)
                break;
            }
        }
        props.setColumnConditions(newColumnConditions)
    }

    function deleteExpression(expresion: ILogicSqlExpression) {
        const expresionStr = JSON.stringify(expresion)
        const newSqlExpressions = JSON.parse(JSON.stringify(props.sqlExpressions))
        for (var i = 0; i < newSqlExpressions.length; i++) {
            if (JSON.stringify(newSqlExpressions[i]) === expresionStr) {
                newSqlExpressions.splice(i, 1)
                break;
            }
        }
        props.setSqlExpression(newSqlExpressions)
    }

    function editCondition(condition: ILogicColumnCondition, ix: number) {
        //const conditionStr = JSON.stringify(condition)
        const newColumnConditions = JSON.parse(JSON.stringify(props.columnConditions))
        newColumnConditions[ix] = condition
        props.setColumnConditions(newColumnConditions)
    }

    function editSqlExpression(expresion: ILogicSqlExpression, ix: number) {
        //const conditionStr = JSON.stringify(condition)
        const newSqlExpressions = JSON.parse(JSON.stringify(props.sqlExpressions))
        newSqlExpressions[ix] = expresion
        props.setSqlExpression(newSqlExpressions)
    }

    function setOperatorConditionFunctionVector(condition: ILogicColumnCondition[], idxOps: number) {
        const newColumnoperations = JSON.parse(JSON.stringify(props.columnOperations))
        if (props.columnOperations) {
            var oldOps = props.columnOperations[idxOps]
            var conditions = oldOps.columnConditions

            conditions = condition
            oldOps.columnConditions = conditions
            newColumnoperations[idxOps] = oldOps
            props.setColumnOperations(newColumnoperations)

        }

    }

    function setSqlExpressionFunctionVector(expresion: ILogicSqlExpression[], idxOps: number) {
        const newColumnoperations = JSON.parse(JSON.stringify(props.columnOperations))
        if (props.columnOperations) {
            var oldOps = props.columnOperations[idxOps]
            var expresions = oldOps.sqlExpressions

            expresions = expresion
            oldOps.sqlExpressions = expresions
            newColumnoperations[idxOps] = oldOps
            props.setColumnOperations(newColumnoperations)

        }

    }
    /*
    function getNumberItemsHeight(columnOperations :ILogicColumnOperator[] | undefined){
        var count : number = 0
        if(columnOperations === undefined){
            return 1
        }
        else{
            for(var i = 0;i<columnOperations.length; i++){
                count+= getNumberItemsHeight(columnOperations[i].columnOperations)
            }
        }
        return count+1
    }*/

    function getHeightSteps(op: ILogicColumnOperator) {

        var steps = 1

        if (op && op.columnOperations && op.columnOperations.length > 0) {
            steps += op.columnOperations.length
        }
        return steps
    }

    function editOperator(value: string, opIndex: number) {
        if (props.columnOperations) {
            var operator: ILogicColumnOperator = props.columnOperations[opIndex]
            operator.operator = value
            var newOperators: ILogicColumnOperator[] = props.columnOperations
            newOperators[opIndex] = operator
            props.setColumnOperations(newOperators)
        }
    }

    function deleteOperator(opIndex: number){
        if (props.columnOperations) {
            var newOperators: ILogicColumnOperator[] = props.columnOperations
            newOperators = newOperators.slice(opIndex, 0)
            props.setColumnOperations(newOperators)
        }
    }

    function getWidth() {
        let i = 0
        if (props.columnConditions) i = props.columnConditions.length
        if (props.sqlExpressions) i += props.sqlExpressions.length
        if (i === 0) i = 1
        console.log('i', i)
        return props.baseWidth / i

    }

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <>
                <div style={{ display: 'flex', alignItems: 'top', width: '100%' }}>

                    {props.columnConditions && props.columnConditions.length > 0 &&
                        props.columnConditions.map((columnCondition, ixOp) =>
                            <PaperColumnCondition
                                height={props.baseHeight / (
                                    props.columnOperations ? props.columnOperations.length + 1 : 1
                                )}
                                width={getWidth()}
                                columnCondition={columnCondition}
                                ixOp={ixOp}
                                deleteCondition={deleteCondition}
                                columns={props.columns}
                                numOperations={props.columnConditions ? props.columnConditions.length : 0}
                                editCondition={editCondition} />
                        )

                    }

                    {props.sqlExpressions && props.sqlExpressions.length > 0 &&
                        props.sqlExpressions.map((sqlExpresion, ixOp) => {
                            return (
                                <PaperSqlExpression
                                    height={props.baseHeight / (
                                        props.sqlExpressions ? props.sqlExpressions.length + 1 : 1
                                    )}
                                    width={getWidth()}
                                    sqlExpression={sqlExpresion}
                                    ixOp={ixOp}
                                    deleteExpression={deleteExpression}
                                    columns={props.columns}
                                    numOperations={props.columnConditions ? props.columnConditions.length : 0}
                                    editExpression={editSqlExpression}
                                />
                            )
                        })

                    }


                </div>

            </>
            {props.columnOperations && props.columnOperations.length > 0 &&
                props.columnOperations.map((ops, idxOps) =>
                    <div style={{ display: 'flex', alignItems: 'top', height: `${100 * (getHeightSteps(ops))}px`, width: '100%' }}>
                        <div style={{ height: '100%', width: '40px' }}>
                            <JoinOperator operator={ops.operator} setOperator={editOperator} opIndex={idxOps} enableDelete={true} deleteOperator={deleteOperator}/>
                        </div>
                        {ops && (ops.columnConditions || ops.sqlExpressions) && <div style={{ height: '100%', width: `${props.baseWidth - 40}px` }}>
                            <ConditionOperator columnConditions={ops.columnConditions} columnOperations={ops.columnOperations} sqlExpressions={ops.sqlExpressions}
                                baseHeight={props.baseHeight / (props.columnOperations ? props.columnOperations.length + 1 : 1)}
                                baseWidth={props.baseWidth - 40}
                                columns={props.columns}
                                setColumnConditions={event => setOperatorConditionFunctionVector(event, idxOps)}
                                setSqlExpression={event => setSqlExpressionFunctionVector(event, idxOps)}
                                setColumnOperations={event => setOperatorJoinFunctionVector(event, idxOps)} />
                        </div>}
                        <AddOperatorCard columnConditions={ops.columnConditions} columnOperations={ops.columnOperations} sqlExpressions={ops.sqlExpressions}
                            baseHeight={props.baseHeight}
                            baseWidth={props.baseWidth - 250 - 40}
                            columns={props.columns}
                            addOperatorJoin={event => addOperatorJoinFunction(event, ops, idxOps)}
                            addOperatorCondition={event => addOperatorConditionFunction(event, ops, idxOps)}
                            addSqlExpression={event => addSqlExpressionFunction(event, idxOps)}
                            hasColumnConditions={ops && ops.columnConditions ? true : false}
                            hasSqlExpressions={ops && ops.sqlExpressions ? true : false} />
                    </div>

                )

            }

        </div>

    )

}