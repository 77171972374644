import React from 'react'
import AqtivaDialog from '../../../../../../common/AqtivaDialog'
import { Typography } from '@material-ui/core'




export default function ConfirmDeleteDialog(props) {
 
    function arrayRemove(arr, elementToRemove) {
        let newStorages = arr.filter(function (element) {
            return element.id !==elementToRemove.id;
        });
        props.setData(newStorages)
        props.setUpdateCounter(props.updateCounter + 1)
    }

    return (
        <AqtivaDialog
            visible={props.showConfirmDialog}
            dialog={props.showConfirmDialog}
            fullWidth={true}
            maxWidth={'xs'}
            title={"Delete Schedule"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setShowConfirmDialog(false)
            }}
            confirmText={'Confirm'}
            confirmCallback={async () => {
                arrayRemove(props.data, props.selectedStorage)
                props.setShowConfirmDialog(false)
            }}
        >
            <Typography variant={'subtitle2'} style={{ textAlign: "center" }}>
                Are you sure you want to delete this storage from the configuration?
            </Typography>
        </AqtivaDialog>
    )
}