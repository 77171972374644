import React from 'react';
import { Grid, Typography, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheckDouble, faCog } from '@fortawesome/free-solid-svg-icons'
import { faSitemap } from '@fortawesome/free-solid-svg-icons'


import * as commonStyles from 'style/CommonStyles'
import RuleNameSelector from './RuleNameSelector';
import RuleTypeSelector from './RuleTypeSelector';
import RuleDimensionSelector from './RuleDimensionSelector';
import ColumnsSelector from './ColumnsSelector';

import ComponentCard from './ComponentCard'
import ComponentCardTwoOptions from './ComponentCardTwoOptions'

import QualityItemSelector from 'components/common/QualityItemSelector'


import * as ruleHelper from './../utilsRuleCreation'
import { IRule, ICommonRule } from 'interfaces/RuleInterfaces';

import { useDispatch } from 'react-redux'
import * as actions from 'actions'

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IColumn } from 'interfaces/RuleInterfaces';

import ColumnsSelectorAggregated from './ColumnsSelectorAggregated';

import AdvanceConfigurationQuality from 'components/common/AdvanceConfigurationQuality'
import TagsConfigurationQuality from 'components/common/TagsConfigurationQuality'
import EnrichDatasourceComponent from 'components/common/EnrichDatasourceComponent'


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as enrichHelper from 'components/common/EnrichDataformatHelper'
import ParameterConfiguration from 'components/common/ParameterConfiguration';
import { IQualityParameter } from '../../../interfaces/Parameters';
import * as aggregationHelper from 'commonFunctions/aggregationHelper'
import * as styleHelper from "style/CommonStyles"
import RulePrioritySelector from "./RulePrioritySelector";
import CategoryLevelError from 'components/common/CategoryLevelError';
import { IDataformat } from '../../../interfaces/DataformatInterfaces';


type IProps = {
    commonRule: ICommonRule | undefined
    setCommonRule: (newValue: ICommonRule) => (any),
    setMandatoryTags: (newValue: any) => (any),
    setOptionalTags: (newValue: any) => (any),
    setMandatoryErrorTags: (newValue: any) => (any),
    mandatoryErrorTags: string,
    qualityPoint: any,
    project: any,
    datasource: any,
    rule: IRule,
    userId: string,
    joins: any[],
    setJoins: (newValue: any) => (any),
    onCloseFlag: boolean,
    setEnrichedDataset: (newValue: any) => (any),
    hasErrors: boolean
    arrayErrorTypes: string[]
    arrayErrorMsgs: string[]

};

type State = { a: string }; // your state type

type AppDispatch = ThunkDispatch<State, any, AnyAction>;


function getValueFromRules(rule: any, commonRule: any, element: string, defaultValue: any) {
    if (rule && rule[element]) {
        return rule[element]
    }
    else if (commonRule && commonRule[element]) {
        return commonRule[element]
    }
    else {
        return defaultValue
    }


}

export default function BasicRuleCreationComponent(props: IProps) {

    const dispatch: AppDispatch = useDispatch();

    const [ruleName, setRuleName] = React.useState<string>(getValueFromRules(props.rule, props.commonRule, "name", ""))
    const [ruleType, setRuleType] = React.useState<string>(getValueFromRules(props.rule, props.commonRule, "type", ruleHelper.ruleTypesBatch[0].value)) //default value schema
    const [ruleDimension, setRuleDimension] = React.useState<string>(getValueFromRules(props.rule, props.commonRule, "dimension", ruleHelper.getDimensionFromRuleType(ruleHelper.ruleTypesBatch[0].value))) //default value format
    const [rulePriority, setRulePriority] = React.useState<number>(getValueFromRules(props.rule, props.commonRule, "weight", 1));
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState(props.commonRule && props.commonRule ? props.commonRule.qualityPoint : props.qualityPoint)
    const [selectedDatasource, setSelectedDatasource] = React.useState(props.commonRule ? props.commonRule.datasource : props.datasource)
    const [baseDataFormat, setBaseDataformat] = React.useState<any>(undefined) // database dataformat to check profiling
    const [selectedColumns, setSelectedColumns] = React.useState<IColumn[]>(props.commonRule ? props.commonRule.selectedColumns : []) //set from rule in effect with
    const [aggregationObject, setAggregationObject] = React.useState<any>(getValueFromRules(props.rule, props.commonRule, "aggregationObject", {}))
    const [categoryError, setCategoryError] = React.useState(props.rule && props.rule.categoryLevelError ? props.rule.categoryLevelError : '')

    const [thError, setThError] = React.useState(getValueFromRules(props.rule, props.commonRule, "thError", props.datasource ? props.datasource.errorTh : {}))
    const [thWarning, setThWarning] = React.useState(getValueFromRules(props.rule, props.commonRule, "warningTh", props.datasource ? props.datasource.warningTh : {}))

    const [wrongRegisterAction, setWrongRegisterAction] = React.useState<string | undefined>(setWrongRegisterActionCallback(props.rule, props.commonRule, props.datasource))
    const [outputMode, setOutputMode] = React.useState(props.rule ? props.rule.controlModeType : (props.commonRule &&
        props.commonRule.output_control ? props.commonRule.output_control : (props.datasource ? props.datasource.controlModeType : '')))

    //const [deprecatedErrorTags, setDeprecatedErrorTags] = React.useState("")
    const deprecatedErrorTags = ""

    const [enrichedDataset, setEnrichedDataset] = React.useState<any>(undefined)

    const [aggregatePrior2Join, setAggregatePrior2Join] = React.useState<boolean>(getValueFromRules(props.rule, props.commonRule, "aggregatePrior2Join", false))


    const [aggregatedColumnsFlag, setAggregatedColumnsFlag] = React.useState<boolean>(getValueFromRules(props.rule, props.commonRule, "aggregatedColumnsFlag", false))
    const [aggregationColumnErrorFlag, setAggregationColumnErrorFlag] = React.useState<boolean>(false)
    const [aggregationColumnErrorFMsg, setAggregationColumnErrorMsg] = React.useState<string>("")
    const [parameters, setParameters] = React.useState<IQualityParameter[]>(props.rule && props.rule.parameters ? props.rule.parameters : props.commonRule && props.commonRule.parameters ? props.commonRule.parameters : [])
    const [parentParameters, setParentParameters] = React.useState<IQualityParameter[]>(props.datasource && props.datasource.parameters ? props.datasource.parameters : selectedDatasource && selectedDatasource.parameters ? selectedDatasource.parameters : [])

    const [executeByColumn, setExecuteByColumn] = React.useState<boolean> (getValueFromRules(props.rule, props.commonRule, "executeByColumn", false))
    

    function parseWrongRegisterAction(a: string | undefined) {
        var resultValue = a
        if (a === undefined) {
            resultValue = a
        }
        if (a === 'discard') {
            resultValue = 'discard_action'
        }
        else if (a === 'include') {
            resultValue = 'include_action'
        }
        else {
            resultValue = a
        }
        return resultValue
    }

    function setWrongRegisterActionCallback(rule: IRule, commonRule: ICommonRule | undefined, datasource: any) {
        if (rule) {
            return (parseWrongRegisterAction(rule.wrongRegisterActionType))
        }
        else {
            if (commonRule && commonRule.exclude_wrong_regs_next_rule) {
                return (parseWrongRegisterAction(commonRule.exclude_wrong_regs_next_rule))
            }
            else {
                if (datasource) {
                    return (parseWrongRegisterAction(datasource.wrongRegisterActionType))
                }
            }
        }
    }


    React.useEffect(() => {
        if (selectedDatasource) {
            //we need to get the datasource to check profiling
            dispatch(actions.fetchDataformat(selectedDatasource && selectedDatasource.dataset ? selectedDatasource.dataset._id : selectedDatasource.dataformatId)).then((result) => {
                //now base dataformat is not only the fetched dataformat. We need to check if datasource is aggregated and join
                if(selectedDatasource!=null && selectedDatasource.aggregationFlag === true){
                    enrichHelper.createEnrichDatasetFromDataformatAndJoin(result.data as IDataformat, selectedDatasource.aggregatePrior2Join, 
                        selectedDatasource.aggregationFlag,selectedDatasource.aggregationObject,selectedDatasource, 
                        selectedDatasource.joins, dispatch).then(
                            resultDataset => {
                              
                            setEnrichedDataset(resultDataset)
                        })
                }
                else{
                    setBaseDataformat(result.data)
                }
               
               

            })
        }
        setParentParameters(props.datasource && props.datasource.parameters ? props.datasource.parameters : selectedDatasource && selectedDatasource.parameters ? selectedDatasource.parameters : [])


    }, [selectedDatasource]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {

        setWrongRegisterAction(setWrongRegisterActionCallback(props.rule, props.commonRule, props.datasource))

    }, [props.rule, props.commonRule, props.datasource]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {

        if ((enrichedDataset !== undefined && enrichedDataset.columns !== undefined) || (baseDataFormat !== undefined && baseDataFormat.columns !== undefined))
            cleanSelectedColumns(enrichedDataset ? enrichedDataset.columns : baseDataFormat.columns)

    }, [baseDataFormat, enrichedDataset])


    React.useEffect(() => {
        //set in parent enriched Dataset
        props.setEnrichedDataset(enrichedDataset)
    }, [enrichedDataset])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        //if base dataformat is set, must set selected columns 
        if (props.rule && props.rule.columns && (baseDataFormat || enrichedDataset)) {
            const dfCols: Array<any> = enrichedDataset ? enrichedDataset.columns : baseDataFormat.columns
            setSelectedColumns(props.rule.columns.map(col => {
                const ix: number = dfCols.map(x => x.name).indexOf(col.cols[0])
                return dfCols[ix]
            }).filter(x => x !== undefined))
            
        }
        
    }, [baseDataFormat, enrichedDataset])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        const aggregationError = aggregationHelper.validateAggregationObj(aggregationObject, aggregatedColumnsFlag)
        if (aggregationError !== undefined) {
            setAggregationColumnErrorFlag(true)
            setAggregationColumnErrorMsg(aggregationError)
        }
        else {
            setAggregationColumnErrorFlag(false)
            setAggregationColumnErrorMsg("")
        }
    }, [aggregationObject, aggregatedColumnsFlag])


    //effect to create commonRule if any field changes
    React.useEffect(() => {
        createCommonRule()

    }, [ruleName, ruleType, ruleDimension, rulePriority, selectedColumns, aggregatedColumnsFlag, aggregationObject, aggregatePrior2Join, parameters, categoryError,executeByColumn])// eslint-disable-line react-hooks/exhaustive-deps


    //effect to update states based on commonRule
    /*React.useEffect(() => {
        if (props.commonRule) {
            setRuleName(props.commonRule.name)
            setRuleType(props.commonRule.type ? props.commonRule.type : ruleHelper.ruleTypesBatch[0].value)
            setRuleDimension(props.commonRule.dimension ? props.commonRule.dimension : ruleHelper.getDimensionFromRuleType(ruleHelper.ruleTypesBatch[0].value))
            setSelectedDatasource(props.commonRule.datasource)
            setSelectedQualityPoint(props.commonRule.qualityPoint)
            setSelectedColumns(props.commonRule.selectedColumns)
            setAggregatePrior2Join(props.commonRule.aggregatePrior2Join)
            setParameters(props.commonRule && props.commonRule.parameters ? props.commonRule.parameters : [])
        }
    }, [props.commonRule])// eslint-disable-line react-hooks/exhaustive-deps
*/


    React.useEffect(() => {
        enrichHelper.createEnrichDatasetFromDataformatAndJoin(baseDataFormat, aggregatePrior2Join, aggregatedColumnsFlag,
            aggregationObject, selectedDatasource, props.joins, dispatch).then(resultDataset => {
                setEnrichedDataset(resultDataset)
            })
    }, [props.joins, baseDataFormat, aggregatedColumnsFlag, aggregationObject, aggregatePrior2Join])// eslint-disable-line react-hooks/exhaustive-deps


    function isError(name: string) {
        if (!props.arrayErrorTypes) {
            return false
        }
        return props.arrayErrorTypes.includes(name)
    }

    function getErrorMessage(name: string) {
        var idx = props.arrayErrorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return props.arrayErrorMsgs ? props.arrayErrorMsgs[idx] : ''
        }
    }



    //BUSINESS LOGIC FUNCTIONS

    function createCommonRule() {
        var newCommonRule = {} as ICommonRule;
        newCommonRule.name = ruleName
        newCommonRule.type = ruleType
        newCommonRule.dimension = ruleDimension
        newCommonRule.weight = rulePriority
        newCommonRule.categoryLevelError = categoryError
        newCommonRule.datasource = selectedDatasource
        newCommonRule.qualityPoint = selectedQualityPoint
        newCommonRule.selectedColumns = selectedColumns
        newCommonRule.thError = thError
        newCommonRule.thWarning = thWarning
        newCommonRule.exclude_wrong_regs_next_rule = wrongRegisterAction
        newCommonRule.output_control = outputMode
        newCommonRule.aggregatedColumnsFlag = aggregatedColumnsFlag
        newCommonRule.aggregationObject = aggregationObject
        newCommonRule.aggregatePrior2Join = aggregatePrior2Join
        newCommonRule.parameters = parameters
        newCommonRule.executeByColumn =  executeByColumn
        if (JSON.stringify(newCommonRule) !== JSON.stringify(props.commonRule)) {
            props.setCommonRule(newCommonRule)
        }


    }

    function getControlObj() {
        return { wrongRegisterAction: wrongRegisterAction, outputMode: outputMode }


    }

    function setControlObj(data: any) {
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)
    }

    //CALLBACK FUNCTIONS

    function setRuleTypeAndDimension(ruleType: string) {
        setRuleType(ruleType)
        setRuleDimension(ruleHelper.getDimensionFromRuleType(ruleType))
    }

    function onChangeQPCallback(event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) {
        const qp = event.target.value
        setSelectedQualityPoint(qp)
        setSelectedDatasource(undefined)

    }
    function onChangeDSCallback(event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) {
        const ds = event.target.value
        setSelectedDatasource(ds)
    }

    const toggleAggregatePrior2Join = () => {
        setAggregatePrior2Join(!aggregatePrior2Join)
    };
    const toggleExecuteByColumn = () => {
        setExecuteByColumn(!executeByColumn)
    };


    function cleanSelectedColumns(newCols: IColumn[]) {

        let aux: IColumn[] = []
        if (selectedColumns && selectedColumns.length > 0) {
            selectedColumns.forEach(c => {
                newCols.filter(nc => {
                    if (nc.position === c.position && nc.name === c.name && nc.type === c.type) {
                        aux.push(c)
                    }
                })
            })
        }

        setSelectedColumns(aux)
    }

    return (<div style={{ height: '100%' }}>
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <ComponentCard icon={faCheckDouble} title={'Rule Selection'}>
                    <RuleNameSelector ruleName={ruleName} setRuleName={setRuleName} />
                    <div style={{ marginLeft: '10%' }}>
                        {props.hasErrors && isError('name') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('name')}</Typography>}
                    </div>
                    <RuleTypeSelector ruleType={ruleType} setRuleType={setRuleTypeAndDimension} />
                    <div style={{ marginLeft: '10%' }}>
                        {props.hasErrors && isError('ruleType') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('ruleType')}</Typography>}
                    </div>
                    <RuleDimensionSelector ruleDimension={ruleDimension} setRuleDimension={setRuleDimension} />
                    <div style={{ marginLeft: '10%' }}>
                        {props.hasErrors && isError('dimension') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('dimension')}</Typography>}
                    </div>
                    <RulePrioritySelector rulePriority={rulePriority} setRulePriority={setRulePriority} />
                    <div style={{ marginLeft: '10%' }}>
                        {props.hasErrors && isError('priority') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('priority')}</Typography>}
                    </div>
                    <div style={{ marginLeft: '10%' }}>
                    <FormControlLabel
                     control={<Switch size="small" checked={executeByColumn} onChange={toggleExecuteByColumn} />}
                                            label={<Typography variant='subtitle2' > {"Execute by column"}</Typography>}
                        />
                    </div>
                </ComponentCard>
            </Grid>

            <Grid item xs={2} >
                <ComponentCard icon={faSitemap} title={'Hierarchy Selection'}>
                    <div style={{ marginLeft: '10%' }}>
                        <QualityItemSelector
                            title={'Select a Quality Point'} type='QualityPoint' value={selectedQualityPoint} onChange={onChangeQPCallback}
                            itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints : [])}
                            itemId={'_id'} itemValue={'name'}
                        />
                        <div>
                            {props.hasErrors && isError('selectedQualityPoint') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedQualityPoint')}</Typography>}
                        </div>

                    </div>
                    <div style={{ marginLeft: '10%' }}>
                        <QualityItemSelector
                            title={'Select a Datasource'} type='Datasource' value={selectedDatasource} onChange={onChangeDSCallback}
                            itemsArray={((selectedQualityPoint && selectedQualityPoint.datasources) ? selectedQualityPoint.datasources : [])}
                            itemId={'_id'} itemValue={'name'}
                        />
                        <div >
                            {props.hasErrors && isError('selectedDatasource') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedDatasource')}</Typography>}
                        </div>

                    </div>
                </ComponentCard>
            </Grid>
            <Grid item xs={8} >

                <ComponentCardTwoOptions icon={faSitemap} title={'Column Selection'} textOption={"Aggregated Columns"} secondaryFlag={aggregatedColumnsFlag}
                    setSecondaryOption={setAggregatedColumnsFlag} >
                    <div >
                        {props.hasErrors && isError('selectedColumns') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedColumns')}</Typography>}
                    </div>
                    {aggregatedColumnsFlag !== false &&
                        <Accordion
                            style={{ width: '100%', marginTop: '10px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid item xs={1}>
                                    <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant='subtitle2'>Aggregation Operations</Typography>
                                </Grid>
                                {aggregationColumnErrorFlag === true &&
                                    <Grid item xs={11}>
                                        <Typography variant='caption' style={{ color: styleHelper.errorColor }}>{aggregationColumnErrorFMsg}</Typography>
                                    </Grid>
                                }
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'flex', flexDirection: "column", justifyContent: "space-around" }}>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px', marginTop: '5px' }}>
                                        <FormControlLabel
                                            control={<Switch size="small" checked={aggregatePrior2Join} onChange={toggleAggregatePrior2Join} />}
                                            label={<Typography variant='subtitle2' > {"Aggregate Before Join"}</Typography>}
                                        />
                                    </div>

                                    <ColumnsSelectorAggregated
                                        originalDF={baseDataFormat}
                                        baseDataFormat={enrichedDataset ? enrichedDataset : baseDataFormat}
                                        aggregationObject={aggregationObject}
                                        aggregatePrior2Join={aggregatePrior2Join}
                                        aggregateFlag={aggregatedColumnsFlag}
                                        setAggregationObject={setAggregationObject}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion >


                    }
                    <ColumnsSelector aggregationObject={aggregationObject}
                        aggregatedColumnsFlag={aggregatedColumnsFlag}
                        aggregatePrior2Join={aggregatePrior2Join}
                        baseDataFormat={enrichedDataset ? enrichedDataset : baseDataFormat}
                        selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} ruleType={ruleType} />


                </ComponentCardTwoOptions>
            </Grid>
            <Grid item xs={12} >
                <div style={{ marginTop: '1%', width: '95%' }}>
                    <ParameterConfiguration parameters={parameters} setParameters={setParameters} parentParameters={parentParameters} />
                </div>
                <div style={{ marginTop: '1%', width: '95%' }}>
                    <AdvanceConfigurationQuality
                        setThError={setThError} setThWarning={setThWarning} setControlObj={setControlObj}
                        thError={thError}
                        thWarning={thWarning}
                        controlObj={getControlObj()} />
                </div>
                <div style={{ marginTop: '1%', width: '95%' }}>
                    <CategoryLevelError rule={props.rule} setCategoryError={setCategoryError} selectedDatasource={props.datasource} />
                </div>
                <div style={{ marginTop: '1%', width: '95%' }}>
                    <TagsConfigurationQuality
                        parent={selectedDatasource}
                        qualityElement={props.rule}
                        elementType="rule"
                        setMandatoryTags={props.setMandatoryTags}
                        setOptionalTags={props.setOptionalTags}
                        mandatoryErrorTags={props.mandatoryErrorTags}
                        mainParent={props.project}
                        intermediateParent={selectedQualityPoint} />
                    <Typography variant={'subtitle2'} style={{ color: "red" }}>{props.mandatoryErrorTags}</Typography>
                    <Typography variant={'subtitle2'} style={{ color: "red" }}>{deprecatedErrorTags}</Typography>
                    <EnrichDatasourceComponent element={"rule"} joins={props.joins} setJoins={props.setJoins} datasource={selectedDatasource}
                        dataformat={enrichedDataset ? enrichedDataset : baseDataFormat}
                        userId={props.userId} qualityPoint={selectedQualityPoint} project={props.project} rule={props.rule} />
                </div>

            </Grid>

        </Grid>
    </div>)
}
