import React from 'react'
import {
    Grid, FormControl, InputLabel, NativeSelect, Typography, Select, Chip, InputAdornment, Icon, makeStyles, MenuItem,
    Card, CardContent, Button, Tooltip
} from '@material-ui/core'
import CustomInput from "components/common/CustomInput/CustomInput.js";
import QualityItemSelector from 'components/common/QualityItemSelector'
import AllOutIcon from '@material-ui/icons/AllOut';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AdvanceConfigurationQuality from 'components/common/AdvanceConfigurationQuality'
import TagsConfigurationQuality from 'components/common/TagsConfigurationQuality'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap } from '@fortawesome/free-solid-svg-icons'
import { faColumns } from '@fortawesome/free-solid-svg-icons'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'

import * as helper from './CreateRuleHelper'
import DialogCreateCombinedColumn from '../DialogCreateCombinedColumn/index.js';
import trackEvent from 'trackEvent'

import * as ruleUtils from 'components/utils/utilsRuleCreation'

import { isEmpty } from 'commonFunctions/commonFunctions'
import { useDispatch } from 'react-redux'
import * as EnrichUtils from 'components/common/EnrichDatasourceComponent/CreateEnrichedDataframeUtils'
import EnrichDatasourceComponent from 'components/common/EnrichDatasourceComponent'

import SmallBar from 'components/common/ProfilingComponent/SmallBar'

import * as commonStyles from 'style/CommonStyles'

import * as actions from 'actions'

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ParameterConfiguration from 'components/common/ParameterConfiguration';
import RulePrioritySelector from 'components/rule/RuleCreationComponent/RulePrioritySelector';
import CategoryLevelError from 'components/common/CategoryLevelError';



export function convertRuleTypes(ruleType) {

    if (ruleType === 'dvd') {
        return 'checkBetweenLoads'
    }
    if (ruleType === 'dup') {
        return 'duplicated'
    }
    if (ruleType === 'cell_based') {
        return 'ranges'
    }
    if (ruleType === 'cell_based') {
        return 'ranges'
    }

    return ruleType
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    },

    chipContainerRule: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function BasicRuleDataForm(props) {
    const classes = useStyles();

    const dispatch = useDispatch()



    /*
    * VARIABLES DE ESTADO
    */
    const [editable] = React.useState(props.rule ? true : false)
    const [name, setName] = React.useState(props.rule ? props.rule.name : (props.commonRule && props.commonRule.common ? props.commonRule.common.name : ''))
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState(props.commonRule && props.commonRule.common ? props.commonRule.common.qualityPoint : props.qualityPoint)

    const [selectedDatasource, setSelectedDatasource] = React.useState(props.commonRule && props.commonRule.common ? props.commonRule.common.datasource : props.datasource)


    const selectedFolderFromDS = (selectedDatasource && props.rule && props.rule.folder) ? selectedDatasource.folders.filter(x => x.url === props.rule.folder.url) : {}
    const [selectedFolder, setSelectedFolder] = React.useState((selectedFolderFromDS && selectedFolderFromDS.length > 0) ? selectedFolderFromDS[0] : (props.folder ? props.folder : undefined))

    const [categoryError, setCategoryError] = React.useState(props.rule && props.rule.categoryLevelError ? props.rule.categoryLevelError : '')
    const [ruleType, setRuleType] = React.useState(editable ? convertRuleTypes(props.rule.type) : (props.commonRule && props.commonRule.common ? convertRuleTypes(props.commonRule.common.type) : 'format'))
    const [selectedDimension, setSelectedDimension] = React.useState(editable ? props.rule.dimension : (props.commonRule && props.commonRule.common ? props.commonRule.common.dimension : helper.getDimensionFromRuleType('statistics')))

    const [thError, setThError] = React.useState(editable ? props.rule.errorTh : (props.commonRule && props.commonRule.common ? props.commonRule.common.thError : (props.datasource ? props.datasource.errorTh : {})))
    const [thWarning, setThWarning] = React.useState(editable ? props.rule.warningTh : (props.commonRule && props.commonRule.common ? props.commonRule.common.thWarning : (props.datasource ? props.datasource.warningTh : {})))

    const [wrongRegisterAction, setWrongRegisterAction] = React.useState(editable ? props.rule.wrongRegisterActionType : (props.commonRule && props.commonRule.common && props.commonRule.common.exclude_wrong_regs_next_rule
        ? (props.commonRule.common.exclude_wrong_regs_next_rule.includes('discard') ? props.commonRule.common.exclude_wrong_regs_next_rule.slice(0, -7) : props.commonRule.common.exclude_wrong_regs_next_rule) : (props.datasource ? props.datasource.wrongRegisterActionType : '')))
    const [outputMode, setOutputMode] = React.useState(editable ? props.rule.wrongRegisterActionType : (props.commonRule && props.commonRule.common &&
        props.commonRule.common.output_control ? props.commonRule.common.output_control : (props.datasource ? props.datasource.controlModeType : '')))
    const [rulePriority, setRulePriority] = React.useState(props.rule && props.rule.weight ? props.rule.weight : 1)




    const [selectedColumns, setSelectedColumns] = React.useState([])
    const [selectedCombinedColumns, setSelectedCombinedColumns] = React.useState(editable ? getCombinedColumns(props.rule.columns) : (props.commonRule && props.commonRule.common ? getCombinedColumnSelected(props.commonRule.common) : []))
    const [source, setSource] = React.useState()
    const [sourceAvailableColumns, setSourceAvailableColumns] = React.useState()
    //const [sourceEnriched, setSourceEnriched] = React.useState()
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors, setHasErrors] = React.useState();
    const [deprecatedErrorTags, setDeprecatedErrorTags] = React.useState("")

    const [enrichedDataset, setEnrichedDataset] = React.useState(undefined)

    const [baseDataformat, setBaseDataformat] = React.useState(undefined) // database dataformat to check profiling


    const [showDialogCreateCombinedColumn, setShowDialogCreateCombinedColumn] = React.useState(false)

    //const [openAvailableColumns, setOpenAvailableColumns] = React.useState(false)
    //const [aggregationExtraColumns, setAggregationExtraColumns] = React.useState([])

    const [isSelectAll, setIsSelectAll] = React.useState(false)
    const [typeColumnSelected, setTypeColumnSelected] = React.useState('')
    const [aggregationValid, setAggregationValid] = React.useState(false)
    const [parameters, setParameters] = React.useState(props.rule && props.rule.parameters ? props.rule.parameters : [])
    const [parentParameters, setParentParameters] = React.useState(props.datasource && props.datasource.parameters ? props.datasource.parameters : props.selectedDatasource && props.selectedDatasource.parameters ? props.selectedDatasource.parameters : [])


    function setSourceAndPropagate(sourceToSet) {
        setSource(sourceToSet)
        let extraColumnsFromAggregationOperations = createAggregationColumns(sourceToSet)
        if (extraColumnsFromAggregationOperations && extraColumnsFromAggregationOperations.length > 0) {
            sourceToSet = extraColumnsFromAggregationOperations
        }
        props.setSource(sourceToSet)
    }





    React.useEffect(() => {
        if (selectedDatasource) {
            setThError(selectedDatasource ? selectedDatasource.errorTh : {})
            setThWarning(selectedDatasource ? selectedDatasource.warningTh : {})
            setWrongRegisterAction(selectedDatasource ? selectedDatasource.wrongRegisterActionType : '')
            setOutputMode(selectedDatasource ? selectedDatasource.controlModeType : '')
            setParentParameters(props.datasource && props.datasource.parameters ? props.datasource.parameters : props.selectedDatasource && props.selectedDatasource.parameters ? props.selectedDatasource.parameters : [])

        }

        if (selectedDatasource) {
            //we need to get the datasource to check profiling
            dispatch(actions.fetchDataformat(selectedDatasource && selectedDatasource.dataset ? selectedDatasource.dataset._id : selectedDatasource.dataformatId)).then(result => {
                setBaseDataformat(result.data)
            })
        }


    }, [selectedDatasource]);// eslint-disable-line react-hooks/exhaustive-deps





    //Here we check if there are unavailable columns selected
    React.useEffect(() => {
        let isEditable = editable || (props.commonRule && props.commonRule.common && props.commonRule.common.selected_columns && props.commonRule.common.selected_columns.length > 0)
        if (isEditable && enrichedDataset && enrichedDataset.columns && enrichedDataset.columns !== null) {
            var sourceTmp = JSON.parse(JSON.stringify(enrichedDataset.columns.slice()))
            let extraColumnsFromAggregationOperations = createAggregationColumns(sourceTmp)
            if (extraColumnsFromAggregationOperations && extraColumnsFromAggregationOperations.length > 0) {
                sourceTmp = extraColumnsFromAggregationOperations
                setAggregationValid(true)
            } else {
                setAggregationValid(false)
            }

            let availableColumnNames = sourceTmp.map(column => column.name)


            let selectedColumnsFromRule = props.rule && props.rule.columns && props.rule.columns
            let selectedcolumnsFromCommonRule = props.commonRule && props.commonRule.common && props.commonRule.common.selected_columns
            let selectedColumnsToUse
            if (selectedcolumnsFromCommonRule && selectedcolumnsFromCommonRule.length > 0) {
                selectedColumnsToUse = selectedcolumnsFromCommonRule
            }
            else {
                selectedColumnsToUse = selectedColumnsFromRule
            }
            //we use selectedColumnsFromCommonRule in order to keep selected columns and going back from a next step when creating a rule for the first time

            let selectedColumnsAfterCheck = getSingleColumns(selectedColumnsToUse)


            for (let i = 0; i < selectedColumnsAfterCheck.length; i++) {
                if (availableColumnNames.includes(selectedColumnsAfterCheck[i].cols[0])) {
                    selectedColumnsAfterCheck[i].notAvailable = false
                }
                else {
                    selectedColumnsAfterCheck[i].notAvailable = true
                }
            }
            setSelectedColumns(selectedColumnsAfterCheck)

            if (props.rule && props.rule.columns) {

                let selectedCombinedColumnsAfterCheck = getCombinedColumns(props.rule.columns)

                for (let i = 0; i < selectedCombinedColumnsAfterCheck.length; i++) {
                    for (let j = 0; j < selectedCombinedColumnsAfterCheck[i].cols.length; j++) {

                        if (availableColumnNames.includes(selectedCombinedColumnsAfterCheck[i].cols[j])) {
                            selectedCombinedColumnsAfterCheck[i].notAvailable = false
                        }
                        else {
                            selectedCombinedColumnsAfterCheck[i].notAvailable = true
                        }
                    }
                }
                setSelectedCombinedColumns(selectedCombinedColumnsAfterCheck)


            }
        }



    }, [props.rule && props.rule.columns, enrichedDataset, props.commonRule && props.commonRule.common && props.commonRule.common && props.commonRule.common.selected_columns && props.commonRule.common.selected_columns.length, JSON.stringify(props.aggregationObject)]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (selectedQualityPoint) {
            setThError(selectedQualityPoint ? selectedQualityPoint.errorTh : {})
            setThWarning(selectedQualityPoint ? selectedQualityPoint.warningTh : {})
            setWrongRegisterAction(selectedQualityPoint ? selectedQualityPoint.wrongRegisterActionType : '')
            setOutputMode(selectedQualityPoint ? selectedQualityPoint.controlModeType : '')
            setParameters(selectedQualityPoint && selectedQualityPoint.parameters ? selectedQualityPoint.parameters : [])

        }


    }, [selectedQualityPoint]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setParentParameters(props.datasource && props.datasource.parameters ? props.datasource.parameters : props.selectedDatasource && props.selectedDatasource.parameters ? props.selectedDatasource.parameters : [])
    }, [props.datasource])

    React.useEffect(() => {

        createCommonRule()
    }, [name, selectedQualityPoint, selectedDatasource, selectedFolder, ruleType, rulePriority,// eslint-disable-line react-hooks/exhaustive-deps
        selectedDimension, thError, thWarning, wrongRegisterAction, outputMode, selectedColumns, selectedCombinedColumns, props.joins, parameters, categoryError]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (props.qualityPoint && props.qualityPoint.tagList && props.qualityPoint.tagList.length > 0 && props.configuration && props.configuration.tagConfiguration && props.configuration.tagConfiguration.length > 0) {
            checkIfTagsAreDeprecated(props.qualityPoint.tagList, props.configuration.tagConfiguration)
        }
    }, [props.configuration, props.qualityPoint])// eslint-disable-line react-hooks/exhaustive-deps


    async function createEnrichDataset() {
        if (selectedDatasource) {


            //first we take all columns from the original dataset
            var enrichedDataset = (selectedDatasource && selectedDatasource.dataset) ? selectedDatasource.dataset : (await dispatch(actions.fetchDataformat(selectedDatasource.dataformatId))).data




            let numberOfJoinsInDataset = 1
            //then we enrich with joins from the parent datasource
            if (enrichedDataset && selectedDatasource && selectedDatasource.enrichDataformatObject) {

                if (selectedDatasource.enrichDataformatObject.joins && selectedDatasource.enrichDataformatObject.joins.length > 0) {
                    let datasetsForEnrichment = await EnrichUtils.getDatasetsForEnrichment(selectedDatasource.enrichDataformatObject.joins, dispatch, actions.fetchDataformat)
                    enrichedDataset = EnrichUtils.createEnrichedDataset(enrichedDataset, selectedDatasource.enrichDataformatObject.joins, datasetsForEnrichment, numberOfJoinsInDataset)
                    numberOfJoinsInDataset = selectedDatasource.enrichDataformatObject.joins.length + 1
                }
            }
            //then we enrich with the rule own joins of enrichment
            if (props.joins && enrichedDataset) {
                if (props.joins && props.joins.length > 0) {
                    let datasetsForEnrichment = await EnrichUtils.getDatasetsForEnrichment(props.joins, dispatch, actions.fetchDataformat)
                    enrichedDataset = EnrichUtils.createEnrichedDataset(enrichedDataset, props.joins, datasetsForEnrichment, numberOfJoinsInDataset)
                }
            }

            setEnrichedDataset(enrichedDataset)
        }
    }

    function createAggregationColumns(enrichedDataset) {
        let newColumns = []
        let verifyGroupColumns = enrichedDataset && props.aggregationObject && props.aggregationObject.groupColumns && props.aggregationObject.groupColumns.length > 0
        let verifyOperationColumns = enrichedDataset && props.aggregationObject && props.aggregationObject.aggregationOperations && props.aggregationObject.aggregationOperations.length > 0
        if (verifyGroupColumns && verifyOperationColumns) {
            props.aggregationObject.groupColumns.map(c => {
                let col = enrichedDataset.find(ed => ed.name === c)
                if (col !== undefined) newColumns.push(col)
                return undefined
            })


            for (let i = 0; i < props.aggregationObject.aggregationOperations.length; i++) {
                if (props.aggregationObject.aggregationOperations[i].alias && props.aggregationObject.aggregationOperations[i].column && props.aggregationObject.aggregationOperations[i].operation) {
                    let newColumn = completeExtraColumns(enrichedDataset, props.aggregationObject.aggregationOperations[i], i)
                    newColumns.push(newColumn)
                }
            }
        }
        else {
            return []
        }

        //setAggregationExtraColumns(newColumns)
        return newColumns

    }
    function completeExtraColumns(enrichedDataset, operation, index) {
        let newColumn = {
            name: operation.alias,
            position: enrichedDataset.length + index,
            type: defineColumnOperationTypeByOperation(operation, enrichedDataset)

        }
        return newColumn

    }
    function defineColumnOperationTypeByOperation(operation, enrichedDataset) {
        let originalColumn = enrichedDataset.find(x => x.name === operation.column)
        switch (operation.operation) {
            case "COUNT":
                return "number"
            case "MAX":
                return originalColumn.type
            case "MIN":
                return originalColumn.type
            case "SUM":
                return "number"
            case "AVG":
                return "number"
            default:
                return originalColumn && originalColumn.type
        }
    }


    //Create Enriched Dataset
    React.useEffect(() => {
        createEnrichDataset()

    }, [selectedDatasource, props.rule, props.rule, props.joins && props.joins.map(join => join.alias).join()]);// eslint-disable-line react-hooks/exhaustive-deps


    // actualizamos el source de columnas cada vez que se cambie el dataset
    React.useEffect(() => {

        if (enrichedDataset && enrichedDataset.columns) {
            var sourceTmp = JSON.parse(JSON.stringify(enrichedDataset.columns.slice()))
            var sourceTmpAllColumns = JSON.parse(JSON.stringify(enrichedDataset.columns.slice()))
            let extraColumnsFromAggregationOperations = createAggregationColumns(sourceTmp)
            if (extraColumnsFromAggregationOperations && extraColumnsFromAggregationOperations.length > 0) {
                sourceTmp = extraColumnsFromAggregationOperations
                sourceTmpAllColumns = sourceTmpAllColumns.concat(extraColumnsFromAggregationOperations)
                setAggregationValid(true)
            } else {
                setAggregationValid(false)
            }

            //buscamos si hay alguna columna selecionada de antelación y lo quitamos de source
            if (props.rule && props.rule.columns && props.rule.columns.length > 0) {
                for (var i = 0; i < sourceTmp.length; i++) {
                    for (var j = 0; j < getSingleColumns(props.rule.columns).length; j++) {
                        if (sourceTmp[i] && getSingleColumns(props.rule.columns)[j].cols[0] === sourceTmp[i].name) {
                            sourceTmp.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
        }

        setSourceAndPropagate(sourceTmpAllColumns)
        setSourceAvailableColumns(sourceTmp)

    }, [enrichedDataset, props.rule, JSON.stringify(props.aggregationObject)]);// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (props.datasource) {
            setThError(editable ? props.rule.errorTh : (props.datasource ? props.datasource.errorTh : {}))
            setThWarning(editable ? props.rule.warningTh : (props.datasource ? props.datasource.warningTh : {}))
            setWrongRegisterAction(editable ? props.rule.wrongRegisterActionType : (props.datasource ? props.datasource.wrongRegisterActionType : ''))
            setOutputMode(editable ? props.rule.controlModeType : (props.datasource ? props.datasource.controlModeType : ''))
        }


    }, [props.datasource]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (selectedColumns.length === 0) setIsSelectAll(false)
    }, [selectedColumns])

    function checkIfTagsAreDeprecated(tagList, tagConfiguration) {
        let error = ""
        for (let i = 0; i < tagList.length; i++) {
            let isTagPresent = tagConfiguration.find(tag => tag.tagName === tagList[i].tagName)
            if (!isTagPresent) {
                error = "tag values are deprecated, please update them."
                setDeprecatedErrorTags(error)
            }
        }
    }



    function getSingleColumnSelected(common) {
        var returnColumns = []

        if (common && common.selected_columns) {
            returnColumns = getSingleColumns(common.selected_columns)
        }

        return returnColumns

    }
    function getCombinedColumnSelected(common) {

        var returnColumns = []

        if (common && common.selected_columns) {
            returnColumns = getCombinedColumns(common.selected_columns)
        }

        return returnColumns

    }




    function getSingleColumns(columns) {
        if (columns === null || columns === undefined) {
            return []
        }
        return columns.filter(x => x.cols.length === 1)
    }
    function getCombinedColumns(columns) {
        if (columns === null || columns === undefined) {
            return []
        }
        return columns.filter(x => x.cols.length > 1)
    }


    /*
    * ERROR HANDLE FUNCTIONS
    */
    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs[idx]
        }
    }

    function createCommonRule() {
        //TODO: poner prioridad
        //TODO: defiir bien type y subtype

        var selectedColumns_ = selectedColumns
        if (isEmpty(selectedColumns_) && props.commonRule && props.commonRule.common) {
            selectedColumns_ = getSingleColumnSelected(props.commonRule.common)
        }

        var selectedCombinedColumns_ = selectedCombinedColumns
        if (isEmpty(selectedCombinedColumns_) && props.commonRule && props.commonRule.common) {
            selectedCombinedColumns_ = getCombinedColumnSelected(props.commonRule.common)
        }


        const commonRule = helper.createCommonRule(name, rulePriority, ruleType, ruleType, outputMode, wrongRegisterAction,
            selectedDimension, thError, thWarning, selectedColumns_.concat(selectedCombinedColumns_), selectedQualityPoint, selectedDatasource, selectedFolder, props.joins, parameters, categoryError)

        props.setCommonRule(commonRule)
        const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = helper.validateCommonRule(commonRule)
        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)

    }
    /*
    * ON CHANGE CALLBACKS
    */


    function onChangeQPCallback(event) {

        const qp = event.target.value
        setSelectedQualityPoint(qp)
        setThError(qp.errorTh)
        setThWarning(qp.warningTh)
        setWrongRegisterAction(qp.wrongRegisterActionType)
        setOutputMode(qp.controlModeType)

    }
    function onChangeDSCallback(event) {

        const ds = event.target.value

        setSelectedDatasource(ds)
        setThError(ds.errorTh)
        setThWarning(ds.warningTh)
        setWrongRegisterAction(ds.wrongRegisterActionType)
        setOutputMode(ds.controlModeType)

    }
    function onChangeFolderCallback(event) {

        setSelectedFolder(event.target.value)
    }
    const onChangeTextField = (event) => {

        setName(event.target.value)
    }
    const onChangeDimensionCallback = (event) => {

        setSelectedDimension(event.target.value)
    }

    function onChangePriorityCallBack(value) {
        setRulePriority(value)
    }

    const onChangeRuleTypeCallback = (event) => {
        setRuleType(event.target.value)
        setSelectedDimension(helper.getDimensionFromRuleType(event.target.value))
        props.setRuleType(event.target.value)
    }
    //function to update control elmenets
    function setControlObj(data) {
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)
    }

    function getControlObj() {
        return { wrongRegisterAction: wrongRegisterAction, outputMode: outputMode }
    }


    function handleSelectColumn(e, reason) {
        if (reason === 'clear') {
            let extraColumnsFromAggregationOperations = createAggregationColumns(JSON.parse(JSON.stringify(enrichedDataset.columns.slice())))
            if (extraColumnsFromAggregationOperations.length === 0) {
                selectedColumns.map(c => {
                    let col = enrichedDataset.columns.find(x => x.name === c.cols[0])
                    if (col !== undefined) {
                        sourceAvailableColumns.splice(col.position, 0, col)
                        setSourceAvailableColumns(JSON.parse(JSON.stringify(sourceAvailableColumns)))
                    }
                    return undefined
                })

            } else {
                setSourceAvailableColumns(JSON.parse(JSON.stringify(extraColumnsFromAggregationOperations)))
            }

            setSelectedColumns([])

        } else {

            if (selectedColumns.length === 0 && ruleType === 'ranges' && e[0]) setTypeColumnSelected(e[0].type)

            const newValues = e && e.target ? e.target.value : e
            for (var i = 0; i < sourceAvailableColumns.length; i++) {
                for (var j = 0; j < newValues.length; j++) {
                    if (newValues[j].position === sourceAvailableColumns[i].position) {
                        sourceAvailableColumns.splice(i, 1);
                        i--;
                    }
                }
            }

            const tmpColumns = (newValues.map(x => {
                if (x.hasOwnProperty('position')) {
                    var x2 = {
                        positionTmp: x.position,
                        id: Math.random().toString(36).substr(2, 9),
                        cols: [x.name],
                        joinOperation: 'null',
                        datasetName: x.datasetName,
                        datasetId: x.datasetId,
                        type: x.type
                    }
                    return x2
                }
                else {
                    return x
                }

            }))

            setSelectedColumns(tmpColumns)
            //setSourceAndPropagate(source)
            setSourceAvailableColumns(sourceAvailableColumns)
        }
    }

    function handleSelectAllColumn(e) {
        let tmpColumns = (e.map(x => {
            if (x.hasOwnProperty('position')) {
                var x2 = {
                    positionTmp: x.position,
                    id: Math.random().toString(36).substr(2, 9),
                    cols: [x.name],
                    joinOperation: 'null',
                    datasetName: x.datasetName,
                    datasetId: x.datasetId,
                }
                return x2
            }
            else {
                return x
            }

        }))
        let extraColumnsFromAggregationOperations = createAggregationColumns(e)
        if (extraColumnsFromAggregationOperations && extraColumnsFromAggregationOperations.length > 0) {
            let newCols = []
            tmpColumns.map(x => {

                return extraColumnsFromAggregationOperations.map(y => {
                    if (y.name === x.cols[0]) newCols.push(x)
                    return undefined
                })
            })
            tmpColumns = newCols

        }
        setSelectedColumns(tmpColumns)
    }

    function compareByPosition(a, b) {
        if (a.position < b.position) {
            return -1;
        }
        if (a.position > b.position) {
            return 1;
        }
        return 0;
    }

    function arraysEqual(a, b) {
        if (a === b) return true;
        if (a === null || b === null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    function handleCloseChipCallback(value) {
        var selectedColTemp = selectedColumns.slice()

        var selectedColTempCombined = selectedCombinedColumns.slice()
        for (var i = 0; i < selectedColumns.length; i++) {
            if (value.cols.length === 1 && (value.cols[0] === selectedColumns[i].cols[0])) {
                selectedColTemp.splice(i, 1);
                break
            }

        }


        for (i = 0; i < selectedCombinedColumns.length; i++) {
            if (arraysEqual(value.cols, selectedCombinedColumns[i].cols)) {
                selectedColTempCombined.splice(i, 1);
                break
            }

        }
        var sourceTemp = sourceAvailableColumns;

        var originalFormatValue = undefined

        for (i = 0; i < source.length; i++) {
            if (value.cols.length === 1 && (value.cols[0] === source[i].name)) {
                originalFormatValue = source[i]
                break
            }

        }
        if (originalFormatValue) {
            sourceTemp.push(originalFormatValue)
            sourceTemp.sort(compareByPosition)
            setSourceAvailableColumns(sourceTemp)

        }
        setSelectedColumns(selectedColTemp)
        setSelectedCombinedColumns(selectedColTempCombined)
    }
    function getUniqueListBy(arr, key) {

        if (selectedColumns.length > 0 && ruleType === 'ranges' && typeColumnSelected !== '') return [...new Map(arr.filter(item => item.type === typeColumnSelected).map(item => [item[key], item])).values()]
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    function onAddCombinedColumn(column) {
        if (column) {
            var tmpColumns = Object.assign([], selectedCombinedColumns);
            tmpColumns.push(column)
            setSelectedCombinedColumns(tmpColumns)
        }
    }

    function handleAllColumns() {

        if (isSelectAll) setSelectedColumns([])
        else {
            handleSelectAllColumn(helper.getSourceForRule(ruleType, source))
        }

        setIsSelectAll(!isSelectAll)

    }

    /**
     * Remove the selected column from selectedColumns and insert in source list
     * @param {*} column column to elminate 
     * @param {*} index  pos in selected column array
     */

    /*const addRemovedColumn = (column, index) => {

        let col = {
            position: column.positionTmp,
            name: column.cols[0],
            type: column.type
        }

        setSelectedColumns(selectedColumns.splice(index, 0))
        sourceAvailableColumns.splice(column.positionTmp, 0, col)

    }*/


    return (
        <div style={{ marginBottom: '2%' }}>
            <DialogCreateCombinedColumn visible={showDialogCreateCombinedColumn}
                selectedDataformat={baseDataformat}
                source={sourceAvailableColumns}
                ruleType={ruleType}
                onClose={event => setShowDialogCreateCombinedColumn(false)}
                onAddCombinedColumn={onAddCombinedColumn} />
            <Grid container spacing={0}>
                <Grid item xs={3}>

                    <Card elevation={12}
                        style={{ width: '90%', minHeight: '100%' }}>

                        <CardContent style={{ marginBottom: '-10%' }}>
                            <div style={commonStyles.adjacentLeft}>
                                <FontAwesomeIcon icon={faCheckDouble} style={{ color: commonStyles.mainColor, fontSize: 15 }} />
                                <Typography variant='subtitle2' style={{ marginLeft: '2%', marginBottom: '2%' }}> Rule Selection</Typography>
                            </div>

                        </CardContent>
                        <div style={{ marginLeft: '10%' }}>
                            <CustomInput
                                labelText="Rule Name"
                                id="ProjectName"
                                formControlProps={{
                                    fullWidth: false,

                                    style: { width: '80%' }
                                }}

                                inputProps={{
                                    type: "text",
                                    onChange: onChangeTextField,
                                    value: name,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className="fas fa-signature" />
                                        </InputAdornment>
                                    )
                                }}
                                error={!props.validateInputs({ name }, "name").rule}
                                helperText={!props.validateInputs({ name }, "name").rule ? props.validateInputs({ name }, "name").description : ""}
                            />
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            {hasErrors && isError('name') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('name')}</Typography>}
                        </div>

                        <div style={{ marginLeft: '10%' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="typeWarnTh-selection-helper">Rule Type</InputLabel>
                                <NativeSelect
                                    style={{
                                        width: '80%',
                                        fontSize: 14,

                                    }}
                                    value={ruleType}
                                    onChange={event => onChangeRuleTypeCallback(event)}
                                >
                                    {selectedQualityPoint === undefined || (selectedQualityPoint.runningType === undefined || selectedQualityPoint.runningType !== 'streaming_lambda') ? ruleUtils.ruleTypesBatch.map(
                                        (event) => (<option key={event.value} value={event.value}>
                                            {event.label}
                                        </option>))
                                        :
                                        ruleUtils.ruleTypesLambda.map(
                                            (event) => (<option key={event.value} value={event.value}>
                                                {event.label}
                                            </option>))
                                    }
                                </NativeSelect>
                            </FormControl>
                            <div style={{ marginLeft: '10%' }}>
                                {hasErrors && isError('ruleType') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('ruleType')}</Typography>}
                            </div>
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            <FormControl className={classes.formControl}>

                                <Typography variant='caption'>Quality Dimension</Typography>
                                <Select
                                    style={{ width: '80%' }}
                                    value={selectedDimension}
                                    onChange={onChangeDimensionCallback}
                                    inputProps={{
                                        name: 'Rule Dimension',
                                        id: 'dimension-selection',

                                    }}

                                >
                                    <MenuItem key='integrity' value='integrity'><div style={commonStyles.adjacentLeft}>
                                        <CompareArrowsIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Integrity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='veracity' value='veracity'><div style={commonStyles.adjacentLeft}>
                                        <CheckCircleOutlineIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Veracity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='unicity' value='unicity'><div style={commonStyles.adjacentLeft}>
                                        <AllOutIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Unicity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='timeliness' value='timeliness'><div style={commonStyles.adjacentLeft}>
                                        <AvTimerIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Timeliness</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='validity' value='validity'><div style={commonStyles.adjacentLeft}>
                                        <CheckCircleIcon style={{ fontSize: '18px', color: commonStyles.mainColor }} />
                                        <Typography variant='body2'>Validity</Typography></div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginRight: '10%' }}>
                            <FormControl className={classes.formControl}>
                                <RulePrioritySelector rulePriority={rulePriority} setRulePriority={onChangePriorityCallBack} />
                                <div>
                                    {props.hasErrors && isError('priority') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('priority')}</Typography>}
                                </div>
                            </FormControl>
                        </div>

                        <div style={{ marginLeft: '10%' }}>
                            {hasErrors && isError('dimension') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('dimension')}</Typography>}
                        </div>
                    </Card>


                </Grid>
                <Grid item xs={3} >
                    <Card elevation={12}
                        style={{ width: '90%', minHeight: '100%' }}>

                        <CardContent style={{ marginBottom: '-10%' }}>
                            <div style={commonStyles.adjacentLeft}>
                                <FontAwesomeIcon icon={faSitemap} style={{ color: commonStyles.mainColor, fontSize: 15 }} />
                                <Typography variant='subtitle2' style={{ marginLeft: '2%', marginBottom: '2%' }}> Hierarchy Selection</Typography>
                            </div>

                        </CardContent>
                        <div style={{ marginLeft: '10%' }}>
                            <QualityItemSelector
                                title={'Select a Quality Point'} type='QualityPoint' value={selectedQualityPoint} onChange={onChangeQPCallback}
                                itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints : [])}
                                itemId={'_id'} itemValue={'name'}
                            />
                            <div>
                                {hasErrors && isError('selectedQualityPoint') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedQualityPoint')}</Typography>}
                            </div>
                        </div>

                        <div style={{ marginLeft: '10%' }}>
                            <QualityItemSelector
                                title={'Select a Datasource'} type='Datasource' value={selectedDatasource} onChange={onChangeDSCallback}
                                itemsArray={((selectedQualityPoint && selectedQualityPoint.datasources) ? selectedQualityPoint.datasources : [])}
                                itemId={'_id'} itemValue={'name'}
                            />
                            <div >
                                {hasErrors && isError('selectedDatasource') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedDatasource')}</Typography>}
                            </div>
                        </div>

                        <div style={{ marginLeft: '10%' }}>
                            <QualityItemSelector
                                title={'Select a Folder'} type='Folder' value={selectedFolder} onChange={onChangeFolderCallback}
                                itemsArray={((selectedDatasource && selectedDatasource.folders
                                ) ? helper.generateFolderArray(selectedDatasource.folders) : [])}
                                itemId={'uri'} itemValue={'name'}
                            />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={6} >
                    <Card elevation={12}
                        style={{ width: '90%', minHeight: '100%', overflowY: 'scroll', flexGrow: 1 }}>

                        <CardContent style={{ marginBottom: '-10%', }}>
                            <div >
                                <div style={commonStyles.adjacentLeft}>
                                    <FontAwesomeIcon icon={faColumns} style={{ color: commonStyles.mainColor, fontSize: 15 }} />
                                    <Typography variant='subtitle2' style={{ marginLeft: '2%' }}> Columns Selection</Typography>
                                </div>
                                {aggregationValid &&
                                    <div>
                                        <Typography variant='caption' > Aggregation columns detected, now the columns available are only the seleted in Aggregation configuration</Typography>
                                    </div>
                                }
                                <div>
                                    <Typography variant='body2' style={{ marginTop: '2%' }}> Available Columns</Typography>
                                    {((ruleType !== 'null' && ruleType !== 'duplicated') || baseDataformat === undefined || baseDataformat.profiling === null) &&

                                        <Autocomplete
                                            id="combo-box-demo"
                                            multiple
                                            //open={openAvailableColumns}
                                            //onClose={(event,reason)=>{ if(reason === 'select-option'){setOpenAvailableColumns(true);} else {setOpenAvailableColumns(false);} }}
                                            //onOpen = {event=>setOpenAvailableColumns(true)}
                                            limitTags={4}
                                            disabled={helper.isSelectedColumnDisabled(ruleType, selectedColumns)}
                                            value={selectedColumns}
                                            onChange={(event, newValue, reason) => {
                                                handleSelectColumn(newValue, reason);
                                            }}
                                            renderTags={(value, getTagProps) => {
                                                return value.map((option, index) => (
                                                    <div>
                                                        <Chip
                                                            label={option.cols[0]}
                                                            key={option.id}
                                                            style={{ backgroundColor: option.notAvailable ? 'red' : "" }}
                                                            onDelete={event => handleCloseChipCallback(option)}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            }
                                            options={(selectedDatasource && baseDataformat && sourceAvailableColumns) ? getUniqueListBy(helper.getSourceForRule(ruleType, sourceAvailableColumns), 'position').sort(helper.compareColumns) : []}
                                            getOptionLabel={(e) => `#  ${e.position} (${e.type}) ${e.name}`}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="Select columns" />}
                                        />

                                    }

                                    {ruleType === 'null' && baseDataformat && baseDataformat.profiling &&

                                        <Autocomplete
                                            id="combo-box-demo"
                                            multiple
                                            limitTags={4}
                                            disabled={helper.isSelectedColumnDisabled(ruleType, selectedColumns)}
                                            value={selectedColumns}
                                            onChange={(event, newValue, reason) => {
                                                handleSelectColumn(newValue, reason);
                                            }}
                                            renderTags={(value, getTagProps) => {

                                                return value.map((option, index) => (
                                                    <Chip label={option.cols[0]} {...getTagProps({ index })}
                                                        style={{ backgroundColor: option.notAvailable ? 'red' : "" }}
                                                        onDelete={event => handleCloseChipCallback(option)} />
                                                ))
                                            }
                                            }
                                            options={(selectedDatasource && baseDataformat && baseDataformat.profiling && sourceAvailableColumns) ? getUniqueListBy(helper.getNullPercentageForCol(baseDataformat.profiling.profilingColumns, sourceAvailableColumns), 'position').sort(helper.compareColumnsbyNullPercentage) : []}
                                            getOptionLabel={(e) => `#  ${e.position} (${e.type}) ${e.name}`}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="Select columns" />}
                                            renderOption={(e, state) =>
                                                
                                                <div style={{
                                                    height: '50px', display: 'flex', width: '350px',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: "spaceBetween"
                                                }}>
                                                    
                                                    <Typography variant='caption' style={{ width: '40%' }}>
                                                        {`#${e.position} (${e.type}) ${e.name} `}
                                                    </Typography>
                                                    { getUniqueListBy(helper.getNullPercentageForCol(baseDataformat.profiling.profilingColumns, sourceAvailableColumns), 'position').sort(helper.compareColumnsbyNullPercentage).length < 100 &&
                                                        <div style={{ width: '60%', height: '50px' }}>
                                                            <SmallBar data={e.nullPercentage} id={"smallBar-" + e.position} prefix={'NotNulls: '} />
                                                        </div>
                                                    }
                                                </div>}
                                        />

                                    }



                                    {ruleType === 'duplicated' && baseDataformat && baseDataformat.profiling &&

                                        <Autocomplete
                                            id="combo-box-demo"
                                            multiple
                                            limitTags={4}
                                            disabled={helper.isSelectedColumnDisabled(ruleType, selectedColumns)}
                                            value={selectedColumns}
                                            onChange={(event, newValue, reason) => {
                                                handleSelectColumn(newValue, reason);
                                            }}
                                            renderTags={(value, getTagProps) => {

                                                return value.map((option, index) => (
                                                    <Chip label={option.cols[0]} {...getTagProps({ index })}
                                                        style={{ backgroundColor: option.notAvailable ? 'red' : "" }}
                                                        onDelete={event => handleCloseChipCallback(option)} />
                                                ))
                                            }
                                            }
                                            options={(selectedDatasource && baseDataformat && baseDataformat.profiling && sourceAvailableColumns) ?
                                                getUniqueListBy(helper.getDuplicatedPercentageForCol(baseDataformat.profiling.profilingColumns, sourceAvailableColumns), 'position').sort(helper.compareColumnsbyDuplicatedPercentage) : []}
                                            getOptionLabel={(e) => `# ${e.position} (${e.type}) ${e.name}`}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="Select columns" />}
                                            renderOption={(e, state) =>
                                                <div style={{
                                                    height: '50px', display: 'flex', width: '350px',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: "spaceBetween"
                                                }}>
                                                    <Typography variant='caption' style={{ width: '50%' }}>
                                                        {`#${e.position} (${e.type}) ${e.name} `}
                                                    </Typography>
                                                    { getUniqueListBy(helper.getDuplicatedPercentageForCol(baseDataformat.profiling.profilingColumns, sourceAvailableColumns), 'position').sort(helper.compareColumnsbyDuplicatedPercentage).length < 100 &&
                                                        <div style={{ width: '50%', height: '50px' }}>
                                                            <SmallBar data={e.duplicatedPercentage} id={"smallBar-" + e.position} prefix={'Dups: '} invert={false} />
                                                        </div>
                                                    }
                                                </div>}
                                        />

                                    }

                                </div>
                                {!helper.isSelectedColumnCombinedDisabled(ruleType) &&
                                    <div style={{ marginTop: '5%' }}>
                                        <Button id='CancelButton' variant='outlined' color='primary'
                                            onClick={event => {
                                                trackEvent('CreateRuleDialog', 'Add Combined Column');
                                                setShowDialogCreateCombinedColumn(true)
                                            }}>
                                            Add Combined Column
                                        </Button>
                                    </div>
                                }

                                {(ruleType === 'statistics' || ruleType === 'format') &&
                                    <div style={{ marginTop: '5%' }}>
                                        <Button id='CancelButton' variant='outlined' color='primary'
                                            onClick={event => {
                                                trackEvent('CreateRuleDialog', 'Select all columns Column');
                                                handleAllColumns()
                                            }}>
                                            {isSelectAll ? 'Remove All Columns' : 'Select All Columns'}
                                        </Button>
                                    </div>
                                }

                                <div>
                                    <Typography variant='caption' > {helper.isSelectedColumnDisabledMessage(ruleType, sourceAvailableColumns)}</Typography>
                                </div>
                                <Typography variant='body2' style={{ marginTop: '5%' }}> Selected Columns</Typography>
                                <div className={classes.chipContainerRule}>

                                    {selectedColumns && selectedColumns.concat(selectedCombinedColumns).map(e => {
                                        if (!e.notAvailable) {
                                            return (
                                                <div>
                                                    <Tooltip title={e.cols.join(',')}>
                                                        <Chip
                                                            label={e.cols.join(',').substring(0, 20)}
                                                            key={e.id}
                                                            onDelete={event => handleCloseChipCallback(e)}

                                                        />
                                                    </Tooltip>
                                                </div>
                                            )

                                        }
                                        else {
                                            return (
                                                <div>
                                                    <Tooltip title={"this column is no longer available"}>
                                                        <Chip
                                                            label={e.cols.join(',')}
                                                            style={{ backgroundColor: "red" }}
                                                            key={e.id}
                                                            onDelete={event => handleCloseChipCallback(e)}

                                                        />
                                                    </Tooltip>
                                                </div>
                                            )

                                        }

                                    }

                                    )}
                                </div>
                                <div style={{ marginLeft: '10%' }}>
                                    {hasErrors && isError('selectedColumns') && <Typography variant='caption' style={{ color: commonStyles.errorColor }}> {getErrorMessage('selectedColumns')}</Typography>}
                                </div>

                            </div>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
            <div style={{ marginTop: '1%', width: '95%' }}>
                <ParameterConfiguration parameters={parameters} setParameters={setParameters} parentParameters={parentParameters} />
            </div>
            <div style={{ marginTop: '1%', width: '95%' }}>
                <AdvanceConfigurationQuality
                    setThError={setThError} setThWarning={setThWarning} setControlObj={setControlObj}
                    thError={thError}
                    thWarning={thWarning}
                    controlObj={getControlObj()} />
            </div>
            <div style={{ marginTop: '1%', width: '95%' }}>
                <CategoryLevelError rule={props.rule} setCategoryError={setCategoryError} selectedDatasource={props.datasource} />
            </div>
            <div style={{ marginTop: '1%', width: '95%' }}>
                <TagsConfigurationQuality
                    parent={selectedDatasource}
                    qualityElement={props.rule} elementType="rule"
                    setMandatoryTags={props.setMandatoryTags}
                    setOptionalTags={props.setOptionalTags}
                    mandatoryErrorTags={props.mandatoryErrorTags}
                    mainParent={props.project}
                    intermediateParent={selectedQualityPoint} />
                <Typography variant={'subtitle2'} style={{ color: "red" }}>{props.mandatoryErrorTags}</Typography>
                <Typography variant={'subtitle2'} style={{ color: "red" }}>{deprecatedErrorTags}</Typography>
                <EnrichDatasourceComponent element={"rule"} joins={props.joins} setJoins={props.setJoins} datasource={selectedDatasource}
                    dataformat={enrichedDataset ? enrichedDataset : baseDataformat}
                    userId={props.userId} qualityPoint={selectedQualityPoint} project={props.project} rule={props.rule} />
                { /*<AggregationConfigurationSection rule={props.rule} aggregationObject={props.aggregationObject} setAggregationObject={props.setAggregationObject}
                    joins={props.joins} setJoins={props.setJoins} datasource={selectedDatasource} dataformat={enrichedDataset ? enrichedDataset : baseDataformat} columns={source} />
                                */}
            </div>
        </div>
    )
}