import React from 'react';
//import AqtivaAppBar from 'components/template/AqtivaAppBar'
import AppBarTemplate from 'components/template/AppBarTemplate'

import * as pathHelper from 'components/template/PathHelper'
import { useHistory } from 'react-router-dom'


import ReactGA from 'react-ga';

import { useSelector, useDispatch } from 'react-redux'

import * as actions from 'actions'
import DataformatDetailForm from 'components/data/DataformatDetailForm';

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/simulation/repository '); 

export default function QualityDataformatDetailPage(props) {
    const dispatch = useDispatch();
    const history = useHistory(); 

    const  dataformats = useSelector(store => Object.values(store.dataformats))
    const user = useSelector(store => store.user)

    const[dataformat, setDataFormat] = React.useState(undefined)

    React.useEffect(()=>{
        if(user && JSON.stringify(user)!=="{}"){
            if(dataformats===undefined || dataformats.length===0){
                dispatch(actions.fetchDataFormats(user))
            }
        }
        else{
            dispatch(actions.fetchUser(history));
        }
        
    },[JSON.stringify(user)])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(()=>{
        if(dataformats !==undefined && dataformats.length>0 && 
            props && props.match && props.match.params && props.match.params.dataformatId){
                const df = dataformats.filter(x=>x._id === props.match.params.dataformatId)
                if(df!==undefined && df.length>0){
                    setDataFormat(df[0])
                }
            }
    },[dataformats])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div >
            {/*
            <AqtivaAppBar history={props.history} title={'Data Profiling'} 
            arrayPath={pathHelper.getPathArray('QualityDatasetRepositoryPage')}
            dataIcon = {true} >

           <DataForm props ={props} />
            </AqtivaAppBar>
            */}
             <AppBarTemplate
        history={props.history}
        title={dataformat ? dataformat.name : ''}
        arrayPath={pathHelper.getPathArray("Governance")}
      />
      <DataformatDetailForm dataformat={dataformat} />
      
        </div>

    );
}