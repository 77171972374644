import React, { useState, useEffect } from 'react';
import {
    TextField, Typography, Grid, FormControlLabel
} from '@material-ui/core';

import { encryptString } from 'components/common/encryptHelper'
import { useDispatch } from 'react-redux';
import * as commonStyles from 'style/CommonStyles'
import AqtivaDialog from 'components/common/AqtivaDialog'
import * as parserHelper from '../../AddDataCredentialsDialog/Helpers/ParserHelper'
import * as errorHelper from '../../AddDataCredentialsDialog/Helpers/ErrorHelper'
import SasNewCredentialsComponent from './SasNewCredentialsComponent'



export default function EditatAzureDialog(props) {


    const dispatch = useDispatch()


    const [displayName, setDisplayName] = React.useState(props.selectedDataCredentials && props.selectedDataCredentials.displayName ? props.selectedDataCredentials.displayName : "")
    const [sasConnectionString, setSasConnectionString] = React.useState('')
    const [sasToken, setSasToken] = React.useState('')
    const [errors, setErrors] = React.useState(undefined)
    const [sasConnectionStringIsValid, setSasConnectionStringIsValid] = React.useState(false)
    const [sasMethod, setSasMethod] = useState('')


    useEffect(() => {
        if (props.selectedDataCredentials && props.selectedDataCredentials.displayName) {
            setDisplayName(props.selectedDataCredentials.displayName)
        }
    }, [props.selectedDataCredentials])

    useEffect(() => {
        if (sasMethod && sasMethod === "connectionString" && sasConnectionString && props.selectedDataCredentials && props.selectedDataCredentials.storageAccountCredentialsDTO && props.selectedDataCredentials.storageAccountCredentialsDTO.storageAccountName) {
            let object
            if (props.selectedDataCredentials && props.selectedDataCredentials.storageType && props.selectedDataCredentials.storageType === "azure_storage") {
                object = parserHelper.parseConnectionString(sasConnectionString)
            }
            if (props.selectedDataCredentials && props.selectedDataCredentials.storageType && props.selectedDataCredentials.storageType === "azure_container") {
                object = parserHelper.parseContainerSasUrl(sasConnectionString)
            }
            if (object && object.storageAccountName && object.storageAccountName === props.selectedDataCredentials.storageAccountCredentialsDTO.storageAccountName) {
                let errorsInSas = errorHelper.checkForErrorsConnectionString(sasConnectionString)
                if (!errorsInSas.hasErrors) {
                    setSasToken(object.sharedAccessSignature)
                    setSasConnectionStringIsValid(true)
                    setErrors(errorsInSas)
                }
                else {
                    setErrors(errorsInSas)
                    setSasConnectionStringIsValid(false)
                }
            }
            else {
                let errorsInSas = {}
                errorsInSas.hasErrors = true
                errorsInSas.storageName = "Please insert a correct connection string, this connection string does not fit storage account name."
                setErrors(errorsInSas)
                setSasConnectionStringIsValid(false)

            }
        }
        else if (sasMethod && sasMethod === "sasToken" && !sasToken) {
            let errorsInSas = {}
            errorsInSas.hasErrors = true
            errorsInSas.storageName = "Please insert a correct sasToken."
            setErrors(errorsInSas)
            setSasConnectionStringIsValid(false)
        }
        else if (sasMethod && sasMethod === "sasToken" && sasToken && sasToken.length > 0) {
            let errorsInSas = {}
            errorsInSas.hasErrors = true
            errorsInSas.storageName = "Please insert a correct sasToken."
            setErrors(errorsInSas)
            setSasConnectionStringIsValid(true)
        }
        else {
            let errorsInSas = {}
            errorsInSas.hasErrors = true
            errorsInSas.storageName = "Please insert a correct connection string, this connection string does not fit storage account name."
            setErrors(errorsInSas)
            setSasConnectionStringIsValid(false)
        }

    }, [sasConnectionString, props.selectedDataCredentials, sasMethod, sasToken])

    async function editArrayElement(datacredentials, newToken) {
        let updatedDataCredentials = JSON.parse(JSON.stringify(props.selectedDataCredentials))
        // quitamos el ? del principio del token porque se le añade en la llamada.
        let encryptedSasConnectionString = await encryptString(props.selectedDataCredentials.id + "localsttkk", dispatch, newToken[0] === "?" ? newToken.substring(1) : newToken)
        updatedDataCredentials.storageAccountCredentialsDTO.sharedAccessSignature = encryptedSasConnectionString
        if (displayName && displayName !== "") {
            updatedDataCredentials.displayName = displayName
        }


        let index = datacredentials.findIndex(x => x.id === updatedDataCredentials.id)
        datacredentials[index] = updatedDataCredentials
        props.setData(datacredentials)
        props.setUpdateCounter(props.updateCounter + 1)
        props.setShowEditDialog(false)

        setSasConnectionString('')
        setSasConnectionStringIsValid(false)
        resetState()
    }


    function resetState() {
        setSasConnectionString('')
        setSasToken('')
        setErrors(undefined)
        setSasConnectionStringIsValid(false)
        setSasMethod('')
        setDisplayName('')

    }

    return (
        <>
            <AqtivaDialog visible={props.showEditDialog}
                maxWidth={'lg'}
                title={'update SAS Credentials'}
                //iconSource={require('icons/data_proc1_white.png')}
                cancelText={'Close'}
                confirmText={'Store and Use'}
                showConfirmButton={(sasToken !== '' && sasConnectionStringIsValid) ? true : false}
                cancelCallback={() => { props.setShowEditDialog(false) }}
                confirmCallback={async () => { editArrayElement(props.data, sasToken); props.setShowEditDialog(false) }}
            >
                <div style={{ width: '100%' }}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <FormControlLabel style={{ marginLeft: '5%', width: '65%' }}
                                control={
                                    <TextField style={{ width: '100%' }}
                                        readonly
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        autoComplete="new-password"
                                        id="DisplayName"
                                        label="Display name"
                                        margin="dense"
                                        value={displayName}
                                        onChange={(event) => { setDisplayName(event.target.value) }}
                                    />} autoComplete="new-password" />
                        </Grid>



                        <SasNewCredentialsComponent storage={props.selectedDataCredentials} sasConnectionString={sasConnectionString} setSasConnectionString={setSasConnectionString} sasToken={sasToken} setSasToken={setSasToken} sasMethod={sasMethod} setSasMethod={setSasMethod} />
                        <div style={{ maxWidth: "80%" }}>

                            {errors && errors.hasErrors && errors.connectionString && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{errors.connectionString}</Typography>}
                            {errors && errors.hasErrors && errors.storageName && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{errors.storageName}</Typography>}
                        </div>
                    </Grid>
                </div>
            </AqtivaDialog>
        </>
    )
}