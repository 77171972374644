import React, { useEffect, useState } from 'react';

import AqtivaDialog from '../../../../../../common/AqtivaDialog';
import TagCard from '../TagCard'
import {  faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import * as helper from '../ErrorUtils'


export default function CreateNewTagDialog(props) {
    const [tag, setTag] = useState('')
    const [errors, setErrors] = useState('')
    const [counter, setCounter] = useState(1)


    useEffect(() => {
        if (counter > 1) {
            let errorsToCheck = helper.checkAllFields(tag, props.existentTagNames)
            setErrors(errorsToCheck)
        }
    }, [counter, counter > 1 && tag])// eslint-disable-line react-hooks/exhaustive-deps


    function addTagToList(newTags, elementToAdd) {
        var errorsToCheck = {}
        errorsToCheck = helper.checkAllFields(elementToAdd, props.data)
        setErrors(errorsToCheck)
        if (!errorsToCheck.hasErrors) {
            newTags.push(elementToAdd)
            props.setData(newTags)
            props.setUpdateCounter(props.updateCounter + 1)
            props.setShowCreateDialog(false)
        }
        else {
            setCounter(2)
        }
    }

    return (
        <AqtivaDialog
            visible={props.showCreateDialog}
            dialog={props.showCreateDialog}
            titleIcon={faPlusCircle}
            fullWidth={true}
            maxWidth={'sm'}
            title={"Create New Tag"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                setCounter(1)
                setErrors(undefined)
                props.setShowCreateDialog(false)
            }}
            confirmText={'Save Tag'}
            confirmCallback={event => {
                setCounter(1)
                addTagToList(props.data, tag)
            }}
        >
            <TagCard master={true} configuration={props.configuration}
                setTag={setTag}
                errors={errors}
                newTag={true} />
        </AqtivaDialog>
    )
}