import React, {  useState } from 'react';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { Grid,  } from '@material-ui/core'
import AqtivaDialog from 'components/common/AqtivaDialog'
import TableRecommendations from 'components/recommender/TableRecommendations'
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'actions'
import trackEvent from 'trackEvent';
import { useHistory } from 'react-router-dom'



export default function DialogEditRecommendedRules(props) {

    const history = useHistory();

    const [rules, setRules] = useState([])
    const user = useSelector(store => store.user)
    const dispatch = useDispatch()

    function importRules(){
        if(rules && rules.length>0 && props.dataset){
            var dataformat = props.dataset
            if(dataformat.source === undefined || dataformat.source ===null){
                dataformat.userId = user.id
                dataformat.name = "dataset_recommender_"+Math.random().toString(36).substr(2, 9)
            }
            
        let dto = {
            creationId: Math.random().toString(36).substr(2, 32),
            dataformat: dataformat,
            dtos: rules
        }
        let projectId = props.projectId
        let qpId=props.qpId
        dispatch(actions.createDatasourceRuleRecommender(projectId, qpId, dto, user.id) )
        //we need to ensure we go to project main page
        let location = history.location.pathname.split('/')
        props.setClose(false)
        let newLocation = `/${process.env.REACT_APP_PREFIX}/project/`+ location[3]
        history.push(newLocation)
        }
    }


    function onClose() {
        trackEvent('RuleRecommender','Close RuleRecommender Dialog')
        
        if(rules && rules.length<1){
            setFlagNotificateSimulationFinish()
        } 
        //we need to ensure we go to project main page
        let location = history.location.pathname.split('/')
        props.setClose(false)
        let newLocation = `/${process.env.REACT_APP_PREFIX}/project/`+ location[3]
        history.push(newLocation)
    }

    function setFlagNotificateSimulationFinish(){
        const jobParams = {
            runId: props.runId,
            instance: props.environment.databricksInstance,
            token: props.environment.databricksToken,
            userId: props.userId,
            dataformat: props.dataset
        }
        dispatch(actions.setRuleRecommenderNotificable(props.projectId,props.qpId,jobParams))
    }
    

    return (
        <div>
            <AqtivaDialog visible={props.open}
                maxWidth={'lg'}
                fullWidth= {rules && rules.length>0 ? true : false}
                editable={false}
                title={'Rule recommender'}
                titleIcon={faProjectDiagram}
                showConfirmButton = {rules && rules.length>0 && props.dataset ? true : false}
                confirmText={'Import rules'} 
                cancelText={'Close'}
                confirmCallback={importRules}
                cancelCallback={event => {
                    onClose()
                }}
                editCallback={event => { return event; }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableRecommendations configuration={props.configuration} 
                        runId={props.runId} 
                        recommenderShowNotification={props.recommenderShowNotification} 
                        setDataSet={props.setDataSet}
                        //ruleDTOId ={props.ruleDTOId}
                        environment={props.environment}
                        qpId={props.qpId}
                        setRules ={setRules} />
                    </Grid>
                </Grid>
            </AqtivaDialog>
        </div>
    )
}



