export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
export function isEmpty (obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function MoneyFormat(labelValue) 
    {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9
  
         ? Math.round(100*Math.abs(Number(labelValue)) / 1.0e+9)/100 + "B"
         // Six Zeroes for Millions 
         : Math.round(Math.abs(Number(labelValue)))/100 >= 1.0e+6
  
         ? Math.round(Math.abs(Number(labelValue)) / 1.0e+6)/100 + "M"
         // Three Zeroes for Thousands
         : Math.round(Math.abs(Number(labelValue))>= 1.0e+3)/100 
  
         ? Math.round(Math.abs(Number(labelValue)) / 1.0e+3)/100 + "K"
  
         : Math.abs(Number(labelValue));
  
     }

export  function capitalizeFirstLetterAndTruncate(string, lengthString) {
    if(string!==null && string !==undefined){
        if(string.length> lengthString){
            return (string.charAt(0).toUpperCase() + string.slice(1)).slice(0,lengthString-3)+'...'
        }
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}