import React, { useEffect } from 'react';
import {
    Typography
} from '@material-ui/core'
import LinearProgressBar from './LinearProgressBar';



export default function ComponentProfilingBatchStatus(props) {
   
    const [progressComplete, setProgressComplete] = React.useState(false)
    //TODO: AVOID WARNINGS
    if(progressComplete){
        
    }
    const [profilingBatchList, setProfilingBatchList] = React.useState(undefined)
    const [profilingBatchListErrors, setProfilingBatchListErrors] = React.useState(undefined)

    useEffect(() => {
        if (props.profilingBatch && props.profilingBatch.profilingStatus === "ok" && props.profilingBatch.dataformatsWithProfiling && props.profilingBatch.dataformatsWithProfiling.length > 0) {
            let list = []
            for (let i = 0; i < props.profilingBatch.dataformatsWithProfiling.length; i++) {
                if (props.profilingBatch.dataformatsWithProfiling[i].name) {
                    list.push(props.profilingBatch.dataformatsWithProfiling[i].name)
                }
            }
            setProfilingBatchList(list)
        }
        if (props.profilingBatch && props.profilingBatch.invalidDataformatsNames && props.profilingBatch.invalidDataformatsNames.length > 0) {
            let listErrors = []
            for (let i = 0; i < props.profilingBatch.invalidDataformatsNames.length; i++) {
                if (props.profilingBatch.invalidDataformatsNames[i]) {
                    listErrors.push(props.profilingBatch.invalidDataformatsNames[i])
                }
            }
            setProfilingBatchListErrors(listErrors)
        }
        if (!props.profilingBatch) {
            setProfilingBatchList(undefined)
            setProfilingBatchListErrors(undefined)
        }

    }, [props.profilingBatch])

    return (
        <>
            <div style={{ width: '100%' }}>
                {/* convertSchema2DatasourceBase  */}
                {props.visible === true &&
                    <div>
                        {props.profilingBatchState && props.profilingBatchState !== "completed" && <LinearProgressBar timeToComplete={90} style={{ width: '100%' }} setProgressComplete={setProgressComplete} />}
                        {props.profilingBatchState && <Typography style={{ width: '100%' }} variant={'subtitle2'}> <strong>Profiling job Status:</strong> {props.profilingBatchState}</Typography>}
                        {profilingBatchList && profilingBatchList.length > 0 && <>
                            <br></br>
                            {profilingBatchList.length > 1 && <Typography>The following profilings have been created: </Typography>}
                            {profilingBatchList.length === 1 && <Typography>The following profiling has been created: </Typography>}
                            <ul styles={{ listStyleType: "none" }}>
                                {profilingBatchList.map(profiling => {
                                    return (
                                        <>
                                            <li>{profiling}</li>
                                        </>
                                    )
                                })}
                            </ul>
                        </>}
                        {profilingBatchListErrors && profilingBatchListErrors.length > 0 && <>
                            <br></br>
                            {profilingBatchListErrors.length > 1 && <Typography>The following profilings have encountered errors and couldn't be created: </Typography>}
                            {profilingBatchListErrors.length === 1 && <Typography>The following profiling has encountered errors and couldn't be created: </Typography>}
                            <ul styles={{ listStyleType: "none" }}>
                                {profilingBatchListErrors.map(profilingError => {
                                    return (
                                        <>
                                            <li>{profilingError}</li>
                                        </>
                                    )
                                })}
                            </ul>
                        </>}
                    </div>}
            </div>
        </>
    )
}