
import quality from '../apis/quality';
//import qualityLocalHost3 from '../apis/qualityLocalHost3';
//import qualityLocalHost4 from 'apis/qualityLocalHost4';
//import qualityLocalHost from 'apis/qualityLocalHost';

import {
    UPDATE_SIMULATION,
    FETCH_SIMULATIONS,
    DELETE_SIMULATIONS
    } from './types'
    import {axiosHeader, dispatchError} from '../components/common/axios/axiosHelper';


    
export const getSimulationFromId = (idSimulation) => async (dispatch,getState) => {
    
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/simulation/id/${idSimulation}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
           // dispatchError(dispatch,error)
            
          });
    
    
   return returnValue
};


export const getSimulationFromProjectId = (idProject) => async (dispatch,getState) => {
    
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/simulation/project/${idProject}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};



export const getMonitoringDAOForQualityPointEnvironment = (idQualityPoint,environment) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/dashboard/qualitypoint/${idQualityPoint}/1000/${environment}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};

export const getMonitoringDAOForProjectEnvironment = (idProject, environment) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/dashboard/project/${idProject}/1000/${environment}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};

export const getMonitoringDAOForDatasourcesEnvironment = (idDatasource,environment) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/dashboard/datasource/${idDatasource}/1000/${environment}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};

export const getMonitoringDAOForQualityPointBetweenDatesEnvironment = (idQualityPoint,initDate,endDate,environment) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
         
    await quality.get(`/dashboard/qualitypoint/${idQualityPoint}/${initDate}/${endDate}/1000/${environment}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};

export const getMonitoringDAOForDatasourcesBetweenDatesEnvironment = (idDatasource,initDate,endDate,environment) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
         
    await quality.get(`/dashboard/datasource/${idDatasource}/${initDate}/${endDate}/1000/${environment}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};

export const getMonitoringDAOForProjectBetweenDatesEnvironment = (idProject,initDate,endDate,environment) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
         
    await quality.get(`/dashboard/project/${idProject}/${initDate}/${endDate}/1000/${environment}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};


export const getSimulationFromQualityPointId = (idQualityPoint) => async (dispatch,getState) => {
    
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/simulation/qualitypoint/${idQualityPoint}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};


 export const runSimulation = (userId,separator, projectId,qualityPointId, datasourceId_, fileName,simulationName,environment,simId, nSamples) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
    var datasourceId = datasourceId_
            if(!datasourceId_){
                datasourceId = "null"
            }
        await quality.post(`/simulation/run/azure/${userId}/${separator}/${projectId}/${qualityPointId}/${datasourceId}/${simulationName}/${environment}/${simId}/${nSamples}`, fileName,{
            headers: axiosHeader()
          }).then(function (response) {
            /*dispatch( {
                type : UPDATE_SIMULATION,
                payload: response.data
            });*/
            returnValue= {result:response.data}
        })
        .catch(function (error) {
            const errorString = JSON.stringify(error)
            if(errorString.includes("Network Error")){
                //errores temporales, no son errores realmente
            }
            else{
                dispatchError(dispatch,error)
            }
            
            returnValue= {result:error}
          });
        
          return returnValue
        
    };

    export const getLastSimulationsForQpOrderByDate = (userId,qpId, nSimulations) => async (dispatch,getState) => {
        var returnValue = {result:'undefined'}
        
        
            await quality.get(`/simulation/getlast/user/${userId}/qp/${qpId._id}/limit/${nSimulations}`,{
                headers: axiosHeader()
              }).then(function (response) {
                /*dispatch( {
                    type : UPDATE_SIMULATION,
                    payload: response.data
                });*/
                returnValue= {result:response.data}
            })
            .catch(function (error) {
                //dispatchError(dispatch,error)
                returnValue= {result:error}
              });
            
              return returnValue
            
        };

   
    export function createSimulation (userId,separator, projectId,qualityPointId, datasourceId_, fileName_,simulationName,environment)  {
        var datasourceId = datasourceId_
        if(!datasourceId_){
            datasourceId = "null"
        }
        var fileName = fileName_
        if(fileName_ === undefined){
            fileName = ''
        }
       
      return quality.post(`/simulation/create/azure/${userId}/${separator}/${projectId}/${qualityPointId}/${datasourceId}/${simulationName}/${environment}`, fileName,{
        headers: axiosHeader()
      })
      
        
    };

    export async function addParameter2Simulation (simulationId, parameters)  {
        
       
        return await quality.post(`/simulation/set/parameters/${simulationId}`, parameters,{
          headers: axiosHeader()
        })
        
          
      };
      
export const addSimulation = (simulation,datasource,environment) =>async dispatch =>  {
    var returnValue = {result:'undefined'}
    const datasourceName = datasource !== null ? datasource : null
    var simulation_ = simulation
    simulation_.datasourceName = datasourceName
    
    await quality.put(`/simulation/${environment}`, simulation_,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : UPDATE_SIMULATION,
            payload: response.data
        });
        returnValue={result:response.data}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue={result:error}
      });
    
      return returnValue;
    
};

export const deleteSimulations = (simulationIds)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.delete(`/simulation/${simulationIds}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : DELETE_SIMULATIONS,
            payload: simulationIds
        });
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
       
        returnValue = {result:'ko',error:error}
      });
      return returnValue
}

export const fetchSimulationsByProject = (idProject) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    await quality.get(`/simulation/${idProject}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : FETCH_SIMULATIONS,
            payload: response.data
        });
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};
export const fetchSimulationsByProjectByDates = (idProject,initDate,endDate, simulationState) => async (dispatch,getState) => {
    if(initDate && endDate){
        var returnValue = {result:'undefined'}
    await quality.get(`/simulation/project/byDates/${idProject}/${initDate}/${endDate}/${simulationState}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : FETCH_SIMULATIONS,
            payload: response.data
        });
        returnValue = {result:'ok'}
        
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
    }
    
};

export const fetchSimulationsByUserIdByDates = (userId,initDate,endDate, simulationState) => async (dispatch,getState) => {

    if(initDate && endDate){
        var returnValue = {result:'undefined'}
        await quality.get(`/simulation/user/byDates/${userId}/${initDate}/${endDate}/${simulationState}`,{
            headers: axiosHeader()
        }).then(function (response) {
            dispatch( {
                type : FETCH_SIMULATIONS,
                payload: response.data
            });
            returnValue = {result:'ok'}
            
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
        });
        return returnValue
    }
    
};

export const fetchSimulationsByProjectAndQP = (idProject,idQualitypoint) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
   await quality.get(`/simulation/${idProject}/${idQualitypoint}`,{
    headers: axiosHeader()
  }).then(function (response) {
        dispatch( {
            type : FETCH_SIMULATIONS,
            payload: response.data
        });
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};
export const fetchSimulationsByProjectAndQPByDates = (idProject,idQualitypoint,initDate,endDate,simulationState) => async (dispatch,getState) => {
    if(initDate && endDate){
    var returnValue = {result:'undefined'}
    await quality.get(`/simulation/qualitypoint/byDates/${idProject}/${idQualitypoint}/${initDate}/${endDate}/${simulationState}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : FETCH_SIMULATIONS,
            payload: response.data
        });
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
    }
};




export const getSimulation = (idSimulation) => async (dispatch,getState) => {
    
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/simulation/id/${idSimulation}`,{
        headers: axiosHeader()
      }).then(function (response) {
            dispatch( {
                type : 'TEST',
                payload: response.data
            });
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
};




export const createStreamSimulation = (dto) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.post('/stream/simulation/azurefunction/',dto,{
    headers: axiosHeader()
  }).then(function (response) {
        
        returnValue = response.data
    })
    .catch(function (error) {
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};

export const setSimulationNotificable = (simulationId) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.post(`/simulation/update/notificable/${simulationId}`,undefined,{
    headers: axiosHeader()
  }).then(function (response) {
        
        returnValue = response.data
        
    })
    .catch(function (error) {
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};


export const getDetailsStreamSimulation = (userId) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.get(`/stream/simulation/azurefunction/details/${userId}`,{
    headers: axiosHeader()
  }).then(function (response) {
        returnValue = response.data
    })
    .catch(function (error) {
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};


export const deployStreamQualityPoint = (dto,name, apiKey,projectId, qpId, dsId,env) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.post(`/stream/azurefunction/deploy/key/${apiKey}/${name}/project/${projectId}/qp/${qpId}/ds/${dsId}/env/${env}`,dto,{
    headers: axiosHeader()
  }).then(function (response) {
        
        returnValue = response.data
    })
    .catch(function (error) {
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};

export const getDeploymentStatusForDatasourceId = (apiKey,dsId) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.get(`/stream/azurefunction/deploy/status/${apiKey}/datasource/${dsId}`,{
    headers: axiosHeader()
  }).then(function (response) {
        
        returnValue = response.data
    })
    .catch(function (error) {
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};

export const checkIfDeployedForDatasourceId = (apiKey,dsId) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.get(`/stream/azurefunction/deploy/isdeployed/${apiKey}/datasource/${dsId}`,{
    headers: axiosHeader()
  }).then(function (response) {
        
        returnValue = response.data
    })
    .catch(function (error) {
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};


export const getDeploymentStatusForProjectId = (apiKey,projectId) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.get(`/stream/azurefunction/deploy/status/${apiKey}/project/${projectId}`,{
    headers: axiosHeader()
  }).then(function (response) {
        
        returnValue = response.data
    })
    .catch(function (error) {
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};

export const checkIfDeployedForProjectId = (apiKey,projectId) => async (dispatch,getState) => {
    var returnValue = {result:'undefined'}
    
   await quality.get(`/stream/azurefunction/deploy/isdeployed/${apiKey}/project/${projectId}`,{
    headers: axiosHeader()
  }).then(function (response) {
        
        returnValue = response.data
    })
    .catch(function (error) {
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue
};