import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'

import { Tabs, Tab, Typography, LinearProgress } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MaterialTable from "@material-table/core";
import { useInterval } from '../../../common/useInterval'

import { BlobServiceClient } from "@azure/storage-blob"

import * as commonStyles from 'style/CommonStyles'
import {onlyUnique} from 'commonFunctions/commonFunctions'


export default function QualitySimulationDataViewer(props) {

    const dispatch = useDispatch();

    const [tabValue, setTabValue] = React.useState(0)
    const [dataCorrect, setDataCorrect] = React.useState([])
    const [dataError, setDataError] = React.useState([])
    const [columnsCorrect, setColumnsCorrect] = React.useState([])
    const [columnsError, setColumnsError] = React.useState([])
    const [waitForResultCorrect, setWaitForResultCorrect] = React.useState(false)
    const [waitForResultError, setWaitForResultError] = React.useState(false)
    //const [runIdCorrect, setRunIdCorrect] = React.useState(-1)
    //const [runIdError, setRunIdError] = React.useState(-1)
    //TODO: CHECK
    const runIdCorrect = -1
    const runIdError = -1
    
    const [noDataCorrect, setNoDataCorrect] = React.useState(false) // variable a setear si no se encuentran datos
    const [noDataError, setNoDataError] = React.useState(false)  // variable a setear si no se encuentran datos
    var configuration = useSelector(store => (store.qualityConfiguration))

    React.useEffect(() => {
        if (configuration && configuration.environmentConfigurationList && configuration.environmentConfigurationList.length > 0) {
            getDataFromStorage(true)
            getDataFromStorage(false)
        }

    }, [configuration, props.simulationId])// eslint-disable-line react-hooks/exhaustive-deps

    async function getDataFromStorage(correct) {
        console.log()
        const simulation = await dispatch(actions.getSimulationFromId(props.simulationId))
        const account = configuration.storageAccountName;
        const sasTmp = await dispatch(actions.getSecret(configuration.sasToken))
        let sas = sasTmp.startsWith("?") ? sasTmp : "?"+sasTmp
                
        const containerName = configuration.containerName;;
        const folderBlob = correct === true ?
            `simulationOutput/${props.simulationId}/${simulation.data.idProject}/${simulation.data.idQualitypoint}/${simulation.data.datasourceName}/correct/`
            :
            `simulationOutput/${props.simulationId}/${simulation.data.idProject}/${simulation.data.idQualitypoint}/${simulation.data.datasourceName}/errors/`

        const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);

        const containerClient = blobServiceClient.getContainerClient(containerName);





        const blobs = []
        // List the blob(s) in the container.
        for await (const blob of containerClient.listBlobsByHierarchy("/", { prefix: folderBlob })) {
            if (blob.name.startsWith(folderBlob + "part")) {
                blobs.push(blob)
            }


        }
        if (blobs.length > 0) {
            if(correct === true){
                setNoDataCorrect(false)
            }
            else{
                setNoDataError(false)
            }

            const blob = blobs[0]

            const blobClient = containerClient.getBlobClient(blob.name);
            // Get blob content from position 0 to the end
            // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
            const downloadBlockBlobResponse = await blobClient.download();
            const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

            const data = downloaded.split("\n")

            const newData = []
            for (var i = 0; i < data.length - 1; i++) {
                newData.push(JSON.parse(data[i]))
            }

            if (correct === true) {
                parseResultsCorrect(newData)
            }
            else {
                parseResultsError(newData)
            }



            // [Browsers only] A helper method used to convert a browser Blob into string.
            async function blobToString(blob) {
                const fileReader = new FileReader();
                return new Promise((resolve, reject) => {
                    fileReader.onloadend = (ev) => {
                        resolve(ev.target.result);
                    };
                    fileReader.onerror = reject;
                    fileReader.readAsText(blob);
                });
            }
        }
        else {
            if (correct === true) {
                setNoDataCorrect(true)
            }
            else {
                setNoDataError(true)
            }
        }
    }

    async function parseResultsCorrect(value) {
        var data = value
        if (data !== undefined && data !== null) {
            generateColumnsCorrect(data[0])
            setDataCorrect(data)
            setWaitForResultCorrect(false)
        }

    }

    async function parseResultsError(value) {
        var data = value
        if (data !== undefined) {
            generateColumnsError(data[0], data)
            setDataError(data)
            setWaitForResultError(false)
        }

    }

    function getDistinct(data, column) {
        const d1 = data.map(x => x[column])
        const d2 = d1.filter(onlyUnique)
        var unique = {}
        for (var i = 0; i < d2.length; i++) {
            unique[d2[i]] = d2[i]
        }
        return unique
    }
 
    function generateColumnsCorrect(data) {

        var cols = Object.keys(data).map(e => { return { title: e, field: e } });

        setColumnsCorrect(cols)


    }
    function generateColumnsError(data, allData) {
        var cols = Object.keys(data).map(e => {
            if (e === 'rule_name') {
                return { title: e, field: e, lookup: getDistinct(allData, "rule_name") }
            }
            else {
                return { title: e, field: e }
            }
        });

        setColumnsError(cols)

    }



    useInterval(() => {
        if (waitForResultCorrect === true) {
            // de momento busco un objeto
            var environment = configuration.environmentConfigurationList[0]
            var instance = environment.databricksInstance
            var token = environment.databricksToken
            var result = dispatch(actions.getJobRunOutput(runIdCorrect, instance, token))
            parseResultsCorrect(result)
        }

    }
        , 3000)

    useInterval(() => {
        if (waitForResultError === true) {
            // de momento busco un objeto
            var environment = configuration.environmentConfigurationList[0]
            var instance = environment.databricksInstance
            var token = environment.databricksToken
            var result = dispatch(actions.getJobRunOutput(runIdError, instance, token))
            parseResultsError(result)
        }

    }
        , 3000)

    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }

    return (
        <div>
            <Tabs value={tabValue} onChange={handleTabChange}
                variant="scrollable" scrollButtons="off"
                style={{ width: '100%', marginTop: '0px' }}>
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><CheckCircleOutlineIcon /> <Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Correct Data`}</Typography> </div>}
                />
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><ErrorOutlineIcon /> <Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Wrong Data`}</Typography> </div>}
                />

            </Tabs>
            {tabValue === 0 && waitForResultCorrect === true &&
                <div style={{ marginTop: '20px' }}>
                    <LinearProgress style={{ width: '100%' }} />
                    <Typography style={{ width: '100%' }} variant={'subtitle2'}> Reading and parsing data from backend. Please wait... </Typography>
                </div>
            }
            {tabValue === 0 && waitForResultCorrect === false && noDataCorrect===true && 
                <div>
                             <Typography style={{ width: '100%' }} variant={'subtitle2'}> No data found </Typography>
           
                    </div>
            }
            {  noDataCorrect===false && 
               <div style={tabValue ===0 ? {} : {display:'none'}}>
                    <MaterialTable
                    title=""
                    isLoading={waitForResultCorrect}
                    columns={columnsCorrect}
                    data={dataCorrect && dataCorrect.length >100 ? dataCorrect.slice(0, 99) : dataCorrect }
                    options={{
                        exportButton: true,
                        sorting: true,
                        filtering: true,
                        headerStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                            backgroundColor: commonStyles.mainColor,
                            color: '#FFF'
                        },
                        cellStyle: {
                            fontSize: 'calc(6px + 0.2vw)',
                        },
                        filterCellStyle: {
                            fontSize: 'calc(6px + 0.2vw)',
                        },

                    }}
                    
                />
                </div>
               
            }

            {tabValue === 1 && waitForResultError === true &&
                <div style={{ marginTop: '20px' }}>
                    <LinearProgress style={{ width: '100%' }} />
                    <Typography style={{ width: '100%' }} variant={'subtitle2'}> Reading and parsing data from backend. Please wait... </Typography>
                </div>
            }

            {tabValue === 1 && waitForResultError === false && noDataError===true && 
                <div>
                             <Typography style={{ width: '100%' }} variant={'subtitle2'}> No data found </Typography>
           
                    </div>
            }

            {   noDataError===false && 
            <div style={tabValue ===1 ? {} : {display:'none'}}>
                <MaterialTable
                    title=""
                    isLoading={waitForResultError}
                    columns={columnsError}
                    data={dataError && dataError.length >100 ? dataError.slice(0, 99) : dataError }
                    options={{
                        exportButton: true,
                        sorting: true,
                        filtering: true,
                        headerStyle: {
                            fontSize: 'calc(8px + 0.2vw)',
                            backgroundColor: commonStyles.mainColor,
                            color: '#FFF'
                        },
                        cellStyle: {
                            fontSize: 'calc(6px + 0.2vw)',
                        },
                        filterCellStyle: {
                            fontSize: 'calc(6px + 0.2vw)',
                        },

                    }}
                    
                />
                </div>
            }
        </div>
    )
}