
import React from 'react';
import ConfirmDialog from '../../../common/ConfirmDialog'

import * as projectHelper from './QualityDetailProject/QualityProjectHelper'

export function getDSAndQPFromSearchTextAndSelectdItem(searchText, selectedItem,projects, projectId) {

    const project = getProject(searchText,projects, projectId)
    const ds = projectHelper.findDatasourceFromRuleId(project, selectedItem._id)
    const qp = projectHelper.findQualityPointFromRuleId(project, selectedItem._id)
    if (ds === null || qp === null) {
        //TODO ERROR
        console.error('error test')
        return { ds: undefined, qp: undefined, project : undefined }
    }
    return { ds: ds, qp: qp, project : project}
}

export function getProject(searchText, projects, projectId) {
    var projectTmp = projects.filter(data => data._id === projectId)
    const originalProject = (projectTmp === undefined) ? undefined : projectTmp[0]
    var project = Object.assign({}, originalProject);
    if (project === undefined) {
        return undefined
    }
    if (searchText === "" || searchText === undefined) {
        return project
    }
    else {
        return project
    }
}

export function generateDialog(id, confirmAction,cancelAction, title, open){
    return(
        <ConfirmDialog
                    id={id}
                    confirmAction={confirmAction}
                    cancelAction={cancelAction}
                    title={title}
                    open={open}
                />
    )
}