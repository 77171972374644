import React from 'react';

import { Grid,  Typography,  Button } from '@material-ui/core';
import SqlOnPremiseConnectionInfo from './SqlOnPremiseConnectionInfo';
import SasConnectionInfo from './SasConnectionInfo';
import SqlServerConnectionInfo from './SqlServerConnectionInfo';
import MySqlConnectionInfo from './MySqlConnectionInfo';
import * as commonStyles from 'style/CommonStyles'
import BigQueryConnectionInfo from './BigQueryConnectionInfo';
import AWSConnectionInfo from './AWSConnectionInfo';
import HiveConnectionInfo from './HiveConnectionInfo';
import RedshiftConnectionInfo from './RedshiftConnectionInfo';
import SynapseConnectionInfo from './SynapseConnectionInfo';



export default function SelectResource(props) {


  function handleClickBack() {

    if (props.setConnectionPhase && props.connectionPhase) {
      if (props.resourceType && (props.resourceType === "azure_storage" || props.resourceType === "azure_container")) {
        props.setConnectionPhase(props.connectionPhase - 1)
      }
      if (props.resourceType && (props.resourceType === "azure-sqlsynapse" || props.resourceType === "oracle" || props.resourceType === "mySQL" || props.resourceType === "sqlServer" || props.resourceType === "bigQuery" || props.resourceType === "aws" || props.resourceType === "awsHive" || props.resourceType === "awsRedshift")) {
        props.setConnectionPhase(props.connectionPhase - 2)
      }
    }
  }
  function handleClickNext() {
    if (props.setConnectionPhase && props.connectionPhase) {
      props.setConnectionPhase(props.connectionPhase + 1)
    }
  }


  return (
    <>
      <div style={{ width: '100%', display: "flex", flexDirection: "column" }}>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            {props.connectionMethod && props.connectionMethod === "connectionString" ? <SasConnectionInfo connectionMethod={props.connectionMethod} setConnectionObjectSas={props.setConnectionObjectSas} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectSas} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} /> : <></>}
          </Grid>

          {props.resourceType && props.resourceType === "oracle" ?  <Grid item xs={12}>
            <SqlOnPremiseConnectionInfo setConnectionObjectOnPremise={props.setConnectionObjectOnPremise} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectSas} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} /> 
          </Grid> : <></>}

          {props.resourceType && props.resourceType === "azure-sqlsynapse" ? <Grid item xs={12}>
            <SynapseConnectionInfo setConnectionObjectSqlServer={props.setConnectionObjectSqlServer} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectSas} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} /> 
          </Grid>
          : <></>}


          {props.resourceType && props.resourceType === "sqlServer" ? <Grid item xs={12}>
            <SqlServerConnectionInfo setConnectionObjectSqlServer={props.setConnectionObjectSqlServer} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectSas} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} /> 
          </Grid>
          : <></>}
          {props.resourceType && props.resourceType === "mySQL" ? <Grid item xs={12}>
            <MySqlConnectionInfo setConnectionObjectMySQL={props.setConnectionObjectMySQL} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectMySQL} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} />
          </Grid> : <></>}
          {props.resourceType && props.resourceType === "bigQuery" ? <Grid item xs={12}>
            <BigQueryConnectionInfo setConnectionObjectBigQuery={props.setConnectionObjectBigQuery} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectMySQL} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} />
          </Grid> : <></>}
          {props.resourceType && props.resourceType === "aws" ? <Grid item xs={12}>
            <AWSConnectionInfo setConnectionObjectAWS={props.setConnectionObjectAWS} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectMySQL} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} />
          </Grid> : <></>}
          {props.resourceType && props.resourceType === "awsHive" ? <Grid item xs={12}>
            <HiveConnectionInfo setConnectionObjectHive={props.setConnectionObjectHive} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectMySQL} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} />
          </Grid> : <></>}
          {props.resourceType && props.resourceType === "awsRedshift" ? <Grid item xs={12}>
            <RedshiftConnectionInfo setConnectionObjectRedshift={props.setConnectionObjectRedshift} setDisplayName={props.setDisplayName} id={props.id} errors={props.errors} displayName={props.displayName} connectionObjectSas={props.connectionObjectMySQL} sasMethod={props.sasMethod} setSasMethod={props.setSasMethod} resourceType={props.resourceType} />
          </Grid> : <></>}

          <Grid item xs={12}>
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.connectionString}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.displayName}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.mySQLCredentials}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.sqlServerCredentials}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.projectId}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.name}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.accessKey}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.secretKey}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.username}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.host}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.password}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.engine}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.port}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.dbInstanceIdentifier}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.bigQueryCredentials}</Typography>}
            {props.errors && props.errors.hasErrors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{props.errors.oracleCredentials}</Typography>}

          </Grid>


          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginBottom: "5px" }}>
              <Button onClick={event => { handleClickBack() }} style={{ color: "white", backgroundColor: commonStyles.secondaryColor, marginRight: "30px", marginBottom: "-20px" }}>Back</Button>
              <Button disabled={props.errors && props.errors.hasErrors} onClick={event => { handleClickNext() }} style={{ color: "white", backgroundColor: commonStyles.mainColor, marginBottom: "-20px" }}>Next</Button>
            </div>
          </Grid>
        </Grid>
      </div>

    </>
  )
}