import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Typography, Tooltip } from '@material-ui/core';
import MaterialTable from "@material-table/core";

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import ProjectTypography from '../../common/ProjectTypography';

import * as tableFunctions from './../../../commonFunctions/tableFunctions'
import * as commonStyles from './../../../style/CommonStyles'

import ConfirmDeleteDialog from './../../common/SchedulingConfigurationQuality/SchedulesTable/ConfirmDeleteDialog';
import EditScheduleDialog from './../../common/SchedulingConfigurationQuality/SchedulesTable/EditScheduleDialog';
import ConfirmUpdateVersionDialog from './../../common/SchedulingConfigurationQuality/SchedulesTable/ConfirmUpdateVersionDialog';
import ExecutionTableDialog from './ExecutionTableDialog'

import { useSelector,useDispatch } from 'react-redux'
import * as actions from 'actions'



export default function SceduleTable(props) {

    const dispatch = useDispatch();
    const license = useSelector((store) => store.license);
    const user = useSelector(store => store.user)


    const [data, setData] = useState([])
    const [columnsObject, setColumnsObject] = useState(undefined)
    const [selectedSchedule, setSelectedSchedule] = useState(undefined)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showConfirmUpdateDialog, setShowConfirmUpdateDialog] = useState(false)
    const [showExecutionsDialog, setShowExecutionsDialog] = useState(false)

    //lookup data
    const [lookupProjectName, setLookupProjectName] = React.useState({})
    const [lookupQPName, setLookupQPName] = React.useState({})
    const [lookupDSName, setlookupDSName] = React.useState({})
    const [lookupEnvironment, setLookupEnvironment] = React.useState({})

    React.useEffect(()=>{
        if(user!==undefined && user.id!==undefined){
            dispatch(actions.fetchLicense((user).id))
        }
    }, [license, user])

    //state to update lookupTables
    React.useEffect(() => {
        if (data && data.length > 0) {
            setLookupProjectName(tableFunctions.getLookup(data, 'projectName'))
            setLookupQPName(tableFunctions.getLookup(data, 'qualityPointName'))
            setlookupDSName(tableFunctions.getLookup(data, 'datasourceName'))
            setLookupEnvironment(tableFunctions.getLookup(data, 'environment'))
        }

    }, [data])

    function findEmail(userId){
        try{
            if(license && license.users){
                return license.users.filter(e => e.id !== userId)[0].email
            }
            return userId
        }
        catch(error){
            return userId
        }
       
        
    }

    function mapUserId2Email(data){
        if (data && data.length > 0) {
            return data.map(x=>{
                x.userEmail = findEmail(x.userId)
                return x
            })
        }
        return data
    }


    useEffect(() => {
        if (props.existingSchedules) {
            setData(props.existingSchedules)
        }
    }, [props.existingSchedules, props.existingSchedules.length])

    useEffect(() => {
        let columnsObject = {
            object:
                [
                    {
                        title: 'User', field: 'userEmail', render: (data, t) => {
                           
                                return <Typography variant='caption'>{data.userEmail}</Typography>

                        }
                    },
                    {
                        title: 'Project', field: 'projectName', lookup: lookupProjectName, render: (data, t) => {
                            if (t === 'group') {
                                return <Typography variant='caption'>{data}</Typography>

                            }
                            else {
                                return <ProjectTypography variant={'caption'} value={data.projectName} projectId={data.projectId}></ProjectTypography>
                            }
                        }
                    },
                    {
                        title: 'Quality Point', field: 'qualityPointName', lookup: lookupQPName, render: (data, t) => {
                            if (t === 'group') {
                                return <Typography variant='caption'>{data}</Typography>
                            }
                            else {
                                return <Typography variant='caption'>{data.qualityPointName}</Typography>
                            }
                        }
                    },
                    {
                        title: 'Datasource', field: 'datasourceName', lookup: lookupDSName, render: (data, t) => {
                            if (t === 'group') {
                                return <Typography variant='caption'>{data}</Typography>
                            }
                            else {
                                return <Typography variant='caption'>{data.datasourceName}</Typography>
                            }
                        }
                    },
                    { title: 'Environment', field: 'environment', lookup: lookupEnvironment },
                    { title: 'Cron', field: 'cron', filtering: false, render: (rowData) => { return rowData.cron && rowData.cron.cron } },
                    { title: 'Pause status', field: 'cron', filtering: false, render: (rowData) => { return rowData.cron && rowData.cron.pauseStatus === "PAUSED" ? "Paused" : "Active" } },
                    { title: 'State', field: 'state',filtering: false },
                    {
                        title: 'Updated', field: 'updatedFlag', filtering: false, render: (rowData) => {
                            return (
                                rowData.updatedFlag ? <Tooltip title={"Schedule is up to date with last datasource version"}>
                                    <span>
                                        <FontAwesomeIcon icon={faCheck} style={commonStyles.checkIconStyle} />
                                    </span>
                                </Tooltip> :
                                    <div></div>
                            )
                        }
                    },
                    {
                        title: 'Executions', filtering: false, render: (rowData) => {
                            return (
                                rowData.updatedFlag ? <Tooltip title={"Display job executions"}>
                                    <span>
                                        <FontAwesomeIcon icon={faSearch} style={commonStyles.checkIconStyle} onClick={event => {
                                            openExecutionsDialog(rowData)
                                        }
                                        } />
                                    </span>
                                </Tooltip> :
                                    <div></div>
                            )
                        }
                    },

                ]
        }
        setColumnsObject(columnsObject)
    }, [lookupProjectName, lookupDSName, lookupEnvironment, lookupQPName])

    function openDeleteDialog(schedule) {
        setSelectedSchedule(schedule)
        setShowConfirmDialog(true)
    }
    function openEditDialog(schedule) {
        setSelectedSchedule(schedule)
        setShowEditDialog(true)
    }
    function openUpdateVersionDialog(schedule) {
        setSelectedSchedule(schedule)
        setShowConfirmUpdateDialog(true)
    }

    function openExecutionsDialog(schedule) {
        setSelectedSchedule(schedule)
        setShowExecutionsDialog(true)
    }








    return (
        <>
            <ConfirmDeleteDialog showConfirmDialog={showConfirmDialog} setShowConfirmDialog={setShowConfirmDialog} selectedSchedule={selectedSchedule} userId={props.userId} setSelectedSchedule={setSelectedSchedule} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} />
            <EditScheduleDialog showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} scheduleInfo={selectedSchedule} userId={props.userId} setSelectedSchedule={setSelectedSchedule} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} />
            <ConfirmUpdateVersionDialog showConfirmUpdateDialog={showConfirmUpdateDialog} setShowConfirmUpdateDialog={setShowConfirmUpdateDialog} selectedSchedule={selectedSchedule} userId={props.userId} setSelectedSchedule={setSelectedSchedule} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} />
            <ExecutionTableDialog showExecutionsDialog={showExecutionsDialog} setShowExecutionsDialog={setShowExecutionsDialog} selectedSchedule={selectedSchedule} userId={props.userId} setSelectedSchedule={setSelectedSchedule} />

            < MaterialTable
                style={{ width: "100%" }}
                title="Schedules"
                data={mapUserId2Email(data)}
                columns={columnsObject && columnsObject.object}
                options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    grouping: true,
                    search: false,
                    pageSize: 5,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: commonStyles.tableHeaderStyle,
                    cellStyle: commonStyles.tableCellStyle,
                    filterCellStyle: commonStyles.tableFilterCellStyle
                }}

                actions={[
                    rowData => ({
                        icon: () => rowData.updatedFlag === false ?
                            <WarningIcon style={{ color: "#FFCC00" }} />
                            : <div style={{ width: '25px' }}></div>
                        ,
                        tooltip: 'Update Deployed Scheduled',
                        onClick: (event, rowData) => { if (rowData.updatedFlag === false) openUpdateVersionDialog(rowData) },

                    }),
                    {
                        icon: () =>
                            <EditIcon />
                        ,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => openEditDialog(rowData)
                    },
                    {
                        icon: () =>
                            <DeleteIcon />
                        ,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => openDeleteDialog(rowData)
                    }

                ]}

                components={{
                    Toolbar: props => (
                        <div>

                        </div>
                    ),
                }}
            />
        </>

    )
}
