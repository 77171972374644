
 // Here given a datasource we check  if some rules can be consolidated 
    //by comparing the datasource before and after being consolidated, if no changes are found, we conclude there are no rules to be consolidated
    export function checkIfDatasourceHasConsolidableRules(datasourceId, project) {
        let newProject = JSON.parse(JSON.stringify(project))
        let datasource
        for (let i = 0; i < newProject.qualityPoints.length; i++) {
            for (let j = 0; newProject.qualityPoints[i].datasources && j < newProject.qualityPoints[i].datasources.length; j++) {
                if (newProject.qualityPoints[i].datasources[j]._id === datasourceId) {
                    datasource = JSON.parse(JSON.stringify(newProject.qualityPoints[i].datasources[j]))
                }
            }
        }
        if (datasource && datasource.rules && datasource.rules.length > 0) {
            let oldDatasource = JSON.parse(JSON.stringify(datasource))
            let consolidationResult = consolidateDatasource(datasource)


            return isEqual(oldDatasource, consolidationResult.datasource)
        }
    }

    export function consolidateDatasource(datasource) {
        let consolidationResult = {}
        let consolidatedRules = []
        let oldRules = []
        let rulesToCheck = JSON.parse(JSON.stringify(datasource.rules))
        while (rulesToCheck && 0 < rulesToCheck.length) {
            let firstRule = rulesToCheck[0]
            if (firstRule) {
                rulesToCheck.shift()
                let newColumns = []
                if (firstRule.columns) {
                    newColumns = JSON.parse(JSON.stringify(firstRule.columns))
                }
                let counter = 0
                let oldRule = {}
                oldRule.names = []
                oldRule.into = firstRule.name
                while (counter < rulesToCheck.length) {
                    let copy_rule1 = JSON.parse(JSON.stringify(firstRule))
                    let copy_rule2 = JSON.parse(JSON.stringify(rulesToCheck[counter]))
                    if (verifyRulesAreConsolidable(copy_rule1, copy_rule2)) {
                        newColumns.push(rulesToCheck[counter].columns)
                        oldRule.names.push(rulesToCheck[counter].name)
                        rulesToCheck.splice(counter, 1);
                    }
                    else {
                        counter += 1
                    }
                }
                oldRules.push(oldRule)
                // after all columns are collected in an array, we flaten them and we filter repeated columns and asign these new columns to the rule we've been comparing to
                let merged = [].concat.apply([], newColumns);
                let filtered = Array.from(new Set(merged.map(JSON.stringify))).map(JSON.parse);
                if (firstRule.columns && firstRule.columns.length > 0) {
                    firstRule.columns = filtered
                }
                // we then proceed to push this new consolidated rule to our new array of rules
                consolidatedRules.push(firstRule)
            }
        }
        datasource.rules = consolidatedRules
        consolidationResult.datasource = datasource
        consolidationResult.oldRules = oldRules

        return consolidationResult
    }

    // Here we compare two rules to check if they can be consolidated in one
    // values known to be different are set to "" before the comparison
    function verifyRulesAreConsolidable(rule1, rule2) {
        rule1.name = ""
        rule1.creationDate = ""
        rule1.updateDate = ""
        rule1.columns = ""
        rule1.priority = ""
        rule1._id = ""
        rule1.folder = ""
        rule1.columnNumber = ""
        rule1.columnNames = ""

        rule2.name = ""
        rule2.creationDate = ""
        rule2.updateDate = ""
        rule2.columns = ""
        rule2.priority = ""
        rule2._id = ""
        rule2.folder = ""
        rule2.columnNumber = ""
        rule2.columnNames = ""

        return isEqual(rule1, rule2)
    }

    // we use this function to do a deepcomparison
    const isEqual = (a, b) => {
        if (a === b) return true;
        if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
        if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
        if (a === null || a === undefined || b === null || b === undefined) return false;
        if (a.prototype !== b.prototype) return false;
        let keys = Object.keys(a);
        if (keys.length !== Object.keys(b).length) return false;
        return keys.every(k => isEqual(a[k], b[k]));
    };