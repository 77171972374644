import React from 'react';


import { useSelector, useDispatch } from 'react-redux'

import * as actions from 'actions'

import AppBarTemplate from 'components/template/AppBarTemplate'
import * as pathHelper from 'components/template/PathHelper'


import { Typography, Tabs, Tab } from '@material-ui/core'


import Filter from './components/Filter'

import * as helper from './helper'
import GlobalDQICoverage from './GlobalDQICoverage'
import DQIDataformatComplete from './DQIDataformatComplete'
import DQIProjectComplete from './DQIProjectComplete'

import { useHistory } from 'react-router-dom'

import { onlyUnique } from 'commonFunctions/commonFunctions'


export default function AqtivaDashboard(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const projects = useSelector(store => Object.values(store.projects))

    const [filterObj, setFilterObj] = React.useState({})

    const [tabValue, setTabValue] = React.useState(2)


    const [projectsLicense, setProjectsLicense] = React.useState([])





    //new states
    const [DQIByDimension, setDQIByDimension] = React.useState([])
    const [globalDQI, setGlobalDQI] = React.useState(undefined)
    const [globalExecutions, setGlobalExecutions] = React.useState(0)
    const [globalDefinitions, setGlobalDefinitions] = React.useState(0)

    const [executionsByDimension, setExecutionsByDimension] = React.useState([])
    const [executionsByRuleType, setExecutionsByRuleType] = React.useState([])

    const [definitionsByDimension, setDefinitionsByDimension] = React.useState([])
    const [definitionsByRuleType, setDefinitionsByRuleType] = React.useState([])

    const [DQIAndExecutions4RuleTypeAndDimension, setDQIAndExecutions4RuleTypeAndDimension] = React.useState([])
    const [DQIByDay, setDQIByDay] = React.useState([])

    const [DQI4Dataformat, setDQI4Dataformat] = React.useState([])
    const [DQI4DataformatAndDimension, setDQI4DataformatAndDimension] = React.useState([])

    const [projectIdExecutions, setProjectIdExecutions] = React.useState([])
    const [projectIdExecutionsEnrich, setProjectIdExecutionsEnrich] = React.useState([])

    const [DQI4Project, setDQI4Project] = React.useState([])
    const [DQI4QP, setDQI4QP] = React.useState([])

    //flags of data loading
    const [dQI4DimensionsFlag, setDQI4DimensionsFlag] = React.useState(true)
    const [dQI4DataformatsFlag, setDQI4DataformatsFlag] = React.useState(true)
    const [dQI4DataformatsAndDimensionsFlag, setDQI4DataformatsAndDimensionsFlag] = React.useState(true)
    const [executions4DimensionsFlag, setExecutions4DimensionsFlag] = React.useState(true)
    const [definitions4DimensionsFlag, setDefinitions4DimensionsFlag] = React.useState(true)
    const [executions4RuleTypeFlag, setExecutions4RuleTypeFlag] = React.useState(true)
    const [definitions4RuleTypeFlag, setDefinitions4RuleTypeFlag] = React.useState(true)
    const [executionsAndDQI4RuleTypeAndDimensionFlag, setExecutionsAndDQI4RuleTypeAndDimensionFlag] = React.useState(true)
    const [dQIByDayFlag, setDQIByDayFlag] = React.useState(true)


    React.useEffect(() => {
        if (projectIdExecutions !== undefined) {
            var projectsEnriched = []
            const allProjects = projects.concat(projectsLicense)
            for (var i = 0; i < projectIdExecutions.length; i++) {
                var p = JSON.parse(JSON.stringify(projectIdExecutions[i]))
                p.project = getProjectFromId(p.projectId, allProjects)
                projectsEnriched.push(p)
            }
            setProjectIdExecutionsEnrich(projectsEnriched)
        }

    }, [projectIdExecutions, projectsLicense])// eslint-disable-line react-hooks/exhaustive-deps

    function getProjectFromId(projectId, projects) {
        const project = projects.filter(x => x._id === projectId)
        if (project && project.length > 0) {
            return project[0]
        }
        return undefined
    }


    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchProjects(user.id))
            dispatch(actions.fetchAllDataFormats(user))
            if (license && license._id) {
                getLicenseProjects().then(response => {
                    setProjectsLicense(response)
                }
                )

            }
            else {
                if(user!==undefined && user.id!==undefined){
                    dispatch(actions.fetchLicense((user).id))
                }
                
            }

        }

    }, [user.id, license._id])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getUser()
        fetchData()
    }, [JSON.stringify(filterObj)])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchProjectData()
    }, [filterObj.startDate, filterObj.endDate])// eslint-disable-line react-hooks/exhaustive-deps



    //para los filtros de tags de momento no pedimos de nuevo a bbdd

    function tagInList(tagList, tagName, tagValues) {
        const filteredTags = tagList.filter(x => x.tagName === tagName && tagValues.includes(x.tagValue))
        if (filteredTags && filteredTags.length > 0) {
            return true
        }
        return false
    }



    function checkQPTagsIncluded(p) {
        var filteredQP = []
        var filterFlag = false
        if (p.qualityPoints && p.qualityPoints.length > 0) {

            for (var prop in filterObj.qpTagFilter) {
                if (Object.prototype.hasOwnProperty.call(filterObj.qpTagFilter, prop)) {
                    const tagName = prop
                    const tagValues = filterObj.qpTagFilter[prop]
                    if (tagValues && tagValues.length > 0) {
                        //filtramos aquellos qualitypoints que contengan el tag especificaco
                        const newQP = p.qualityPoints.filter(x => x.tagList && x.tagList.length > 0 && tagInList(x.tagList, tagName, tagValues))
                        filteredQP.push(...newQP)

                        filterFlag = true

                    }



                }
            }


        }
        if (filterFlag) {
            filteredQP = filteredQP.filter(onlyUnique)
        }
        else {
            filteredQP = p.qualityPoints
        }

        //filtro qp por rule tags
        if (filteredQP && filteredQP.length > 0) {

            var tmpFilteredQP = []
            for (var qpIndex = 0; qpIndex < filteredQP.length; qpIndex++) {
                const result = filterQPByRuleTags(filteredQP[qpIndex])
                tmpFilteredQP.push(result.qp)
                if (result.filterFlag) {
                    filterFlag = true
                }
            }
            filteredQP = tmpFilteredQP
        }



        return { qp: filteredQP, filterFlag: filterFlag }
    }


    function filterRulesByTag(rules, tags) {
        var rulesFiltered = []
        var filterFlag = false
        for (var prop in tags) {
            if (Object.prototype.hasOwnProperty.call(tags, prop)) {
                const tagName = prop
                const tagValues = tags[prop]
                if (tagValues && tagValues.length > 0) {
                    //filtramos aquellos qualitypoints que contengan el tag especificaco
                    const rulesTmp = rules.filter(x => x.tagList && x.tagList.length > 0 && tagInList(x.tagList, tagName, tagValues))
                    rulesFiltered.push(...rulesTmp)

                    filterFlag = true

                }



            }
        }

        if (filterFlag) {
            return rulesFiltered.filter(onlyUnique)
        }
        else {
            return rules
        }
    }

    function filterQPByRuleTags(qp) {
        var filterFlag = false
        var newQP = JSON.parse(JSON.stringify(qp))
        if (qp.datasources && qp.datasources.length > 0) {
            for (var dsIndex = 0; dsIndex < qp.datasources.length; dsIndex++) {
                var ds = JSON.parse(JSON.stringify(qp.datasources[dsIndex]))
                if (ds.rules && ds.rules.length > 0) {
                    const rules = filterRulesByTag(ds.rules, filterObj.ruleTagFilter)
                    if (rules.length !== ds.rules.length) {
                        filterFlag = true
                        ds.rules = rules
                        newQP.datasources[dsIndex] = ds
                    }
                }

            }





        }
        return { qp: newQP, filterFlag: filterFlag }
    }

    React.useEffect(() => {


        var projects = projectsLicense
        var fitleredProjects = []
        for (var prop in filterObj.projectTagFilter) {
            if (Object.prototype.hasOwnProperty.call(filterObj.projectTagFilter, prop)) {
                const tagName = prop
                const tagValues = filterObj.projectTagFilter[prop]
                if (tagValues && tagValues.length > 0) {
                    //someFilter = true
                    fitleredProjects.push(...projects.filter(x => x.tagList && x.tagList.length > 0 &&
                        x.tagList.filter(y => y.tagName === tagName && tagValues.includes(y.tagValue)).length > 0))
                }
            }
        }
        var filteredByQPProjects = []
        var someFilterQP = false
        const projects4QP = (fitleredProjects && fitleredProjects.length > 0) ? fitleredProjects : projects
        for (var j = 0; j < projects4QP.length; j++) {
            var newP = JSON.parse(JSON.stringify(projects4QP[j]))
            const returnValue = checkQPTagsIncluded(newP)
            const tempQP = returnValue.qp
            if (returnValue.filterFlag === true) {
                someFilterQP = true
            }
            if (tempQP && tempQP.length > 0) {
                newP.qualityPoints = tempQP
                filteredByQPProjects.push(newP)
            }

        }
        if (someFilterQP) {
            fitleredProjects = filteredByQPProjects
        }
        /*
        const project4Rules = (someFilter===true || someFilterQP===true) ? fitleredProjects : projects
        const ruleIds = project4Rules.filter(x=>x.qualityPoints && x.qualityPoints.length>0).flatMap(x=>x.qualityPoints)
        .filter(x=>x.datasources && x.datasources.length>0).flatMap(x=>x.datasources)
        .filter(x=>x.rules && x.rules.length>0).flatMap(x=>x.rules).map(x=>x._id)
        */






    }, [filterObj.projectTagFilter, filterObj.qpTagFilter, filterObj.ruleTagFilter])// eslint-disable-line react-hooks/exhaustive-deps




    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }



    function getRuleTypesData(rawRuleData, field) {
        if (rawRuleData !== undefined && rawRuleData.length > 0) {
            const ruleTypes = rawRuleData.filter(x => x.ruleType).map(x => x.ruleType).filter(onlyUnique)
            const sum = rawRuleData.map(x => x[field]).reduce((partialSum, a) => partialSum + a, 0);
            const fullMarkValue = sum

            const dataArray = []
            for (var i = 0; i < ruleTypes.length; i++) {
                const ruleType = ruleTypes[i]
                const data = {
                    subject: ruleType,
                    value: rawRuleData.filter(x => x.ruleType === ruleType)[0][field],
                    fullMark: fullMarkValue,
                }
                dataArray.push(data)
            }
            return dataArray
        }

        return []

    }

    async function fetchProjectData() {
        if (filterObj.startDate && filterObj.endDate) {
            dispatch(actions.getProjectIdExecutions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setProjectIdExecutions(response.data)

            })
        }
    }

    async function fetchData() {
        if (filterObj.startDate && filterObj.endDate) {

            setDQI4DimensionsFlag(true)
            setDQI4DataformatsFlag(true)
            setDQI4DataformatsAndDimensionsFlag(true)
            setExecutions4DimensionsFlag(true)
            setDefinitions4DimensionsFlag(true)
            setExecutions4RuleTypeFlag(true)
            setDefinitions4RuleTypeFlag(true)
            setExecutionsAndDQI4RuleTypeAndDimensionFlag(true)
            setDQIByDayFlag(true)


            dispatch(actions.getDQI4Dimensions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                const value = helper.getGlobalDQI(response.data)
                setGlobalDQI(value)
                setDQIByDimension(response.data)
                setDQI4DimensionsFlag(false)

            })

            dispatch(actions.getDQI4Dataformats(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setDQI4Dataformat(response.data)
                setDQI4DataformatsFlag(false)

            })

            dispatch(actions.getDQI4DataformatsAndDimensions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setDQI4DataformatAndDimension(response.data)
                setDQI4DataformatsAndDimensionsFlag(false)

            })

            dispatch(actions.getExecutions4Dimensions(filterObj)).then(response => {
                //get global DQI
                const value = helper.getGlobalExecutions(response.data)
                setGlobalExecutions(value)
                setExecutionsByDimension(response.data)
                setExecutions4DimensionsFlag(false)

            })

            dispatch(actions.getDefinitions4Dimensions(filterObj)).then(response => {
                const value = helper.getGlobalDefinitions(response.data)
                setGlobalDefinitions(value)
                setDefinitionsByDimension(response.data)
                setDefinitions4DimensionsFlag(false)
            })

            dispatch(actions.getExecutions4RuleType(filterObj)).then(response => {
                //get global DQI
                setExecutionsByRuleType(getRuleTypesData(response.data, 'executions'))
                setExecutions4RuleTypeFlag(false)
            })

            dispatch(actions.getDefinitions4RuleType(filterObj)).then(response => {
                //get global DQI
                setDefinitionsByRuleType(getRuleTypesData(response.data, 'definitions'))
                setDefinitions4RuleTypeFlag(false)
            })

            dispatch(actions.getExecutionsAndDQI4RuleTypeAndDimension(filterObj)).then(response => {
                //get global DQI
                setDQIAndExecutions4RuleTypeAndDimension(response.data)
                setExecutionsAndDQI4RuleTypeAndDimensionFlag(false)

            })

            dispatch(actions.getDQIByDay(filterObj)).then(response => {
                //get global DQI
                setDQIByDay(response.data)
                setDQIByDayFlag(false)

            })

            dispatch(actions.getDQI4Projects(filterObj)).then(response => {
                setDQI4Project(response.data)

            })

            dispatch(actions.getDQI4QualityPoints(filterObj)).then(response => {
                setDQI4QP(response.data)

            })





            /* dispatch(actions.getRuleRawRecords(filterObj.startDate, filterObj.endDate)).then(
                  resultsRule =>{
                     
                      setBaseRawRuleData(resultsRule.data)
                      //setRawRuleData(resultsRule.data)
                      
                  }
              )
      
              dispatch(actions.getDSRawRecords(filterObj.startDate, filterObj.endDate)).then(
                  resultsDS =>{
                      
                          setBaseRawDSData(resultsDS.data)
                          //setRawDSData(resultsDS.data)
      
                      
                  }
              )
      
      
              dispatch(actions.getQualityPointRawRecords(filterObj.startDate, filterObj.endDate)).then(
                  resultsQP =>{
                      setBaseRawQPData(resultsQP.data)
                      //setRawQPData(resultsQP.data)
      
                      //setRawQPData(resultsQP.data)
      
                     
                  }
              )*/


        }
    }


    async function getLicenseProjects() {
        var projects = []
        if (license && license._id && license.users && license.users.length > 0) {
            for (var i = 0; i < license.users.length; i++) {
                let licenseUser = license.users[i]
                if (user.id !== licenseUser.id) { //only other users in license
                    var userProjects = await dispatch(actions.fetchProjectsAndReturn(licenseUser.id))
                    projects = projects.concat(userProjects)
                }
            }
        }

        return projects
    }

    function getUser() {
        dispatch(actions.fetchUser(history))
    }




    return (

        <div >
            <AppBarTemplate history={props.history} title={'Dashboard'} arrayPath={pathHelper.getPathArray('Dashboard')}
                fecthNotifications={false} />



            <Filter setFilterObj={setFilterObj}
                projects={projectIdExecutionsEnrich}>
                <Tabs value={tabValue} onChange={handleTabChange}
                    variant="scrollable" scrollButtons="off"
                    style={{ width: '100%', paddingTop: '-20px' }}>
                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}> <Typography variant='caption' style={{ marginTop: '5px' }}>
                        {`Global DQI and Coverage`}</Typography> </div>}
                    />
                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                        {`Dataformat DQI`}</Typography> </div>}
                    />
                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                        {`Projects DQI`}</Typography> </div>}
                    />

                </Tabs>

                {
                    tabValue === 0 &&
                    <div style={{ width: '100%' }}>
                        <GlobalDQICoverage
                            globalDQI={globalDQI}
                            DQIByDimension={DQIByDimension}
                            executionsByDimension={executionsByDimension}
                            definitionsByDimension={definitionsByDimension}
                            globalExecutions={globalExecutions}
                            globalDefinitions={globalDefinitions}
                            executionsByRuleType={executionsByRuleType}
                            definitionsByRuleType={definitionsByRuleType}
                            DQIAndExecutions4RuleTypeAndDimension={DQIAndExecutions4RuleTypeAndDimension}
                            DQIByDay={DQIByDay}
                            startDate={filterObj.startDate}
                            endDate={filterObj.endDate}

                            dQI4DimensionsFlag={dQI4DimensionsFlag} 
                            executions4DimensionsFlag={executions4DimensionsFlag}
                            definitions4DimensionsFlag={definitions4DimensionsFlag}
                            executions4RuleTypeFlag={executions4RuleTypeFlag}
                            definitions4RuleTypeFlag={definitions4RuleTypeFlag}
                            executionsAndDQI4RuleTypeAndDimensionFlag={executionsAndDQI4RuleTypeAndDimensionFlag}
                            dQIByDayFlag={dQIByDayFlag}
                        />
                    </div>
                }

                {
                    tabValue === 1 &&
                    <div style={{ width: '100%' }}>
                        <DQIDataformatComplete DQI4Dataformat={DQI4Dataformat}
                            DQI4DataformatAndDimension={DQI4DataformatAndDimension}
                            projects={projects.concat(projectsLicense)}

                            dQI4DataformatsFlag={dQI4DataformatsFlag}
                            dQI4DataformatsAndDimensionsFlag={dQI4DataformatsAndDimensionsFlag}
                        />
                    </div>
                }

                {
                    tabValue === 2 &&
                    <div style={{ width: '100%' }}>
                        <DQIProjectComplete DQI4Project={DQI4Project} DQI4QP={DQI4QP}
                            filterObj={filterObj}
                        />
                    </div>
                }







            </Filter>

        </div>

    );
}