import React from "react";
import MaterialTable from "@material-table/core";
import * as commonStyles from 'style/CommonStyles'




export default function TableTotalUCs(props) {
  
    return(
        <MaterialTable
        style={{ width: "100%" }}
        columns={[
            { title: "Date", field: "date" ,},
            { title: "Year", field:"year"},
            { title: "Month", field:"month", render: row => row.month+1},
            { title: "Day", field:"day"},
            { title: "Environment", field:"environment"},
            { title: "ProcessingTime", field:"processingTime"},
            { title: "UCs", field:"ucs", render:row => Math.round(row.ucs*1000)/1000},
            
            
            
        ]}
        data={props.data ? props.data.filter(x=>x.environment !== "") : []}

       
        options={{
            toolbar: true,     
            exportButton: {
                csv: true,
                pdf: false
              },
            exportAllData: true,  // optional
            filtering: false,
            search:false,
            headerStyle: {
                fontSize: 'calc(8px + 0.2vw)',
                backgroundColor: commonStyles.mainColor,
                color: '#FFF'
            },
            cellStyle: {
                fontSize: 'calc(8px + 0.2vw)',
            },
            filterCellStyle: {
                fontSize: 'calc(8px + 0.2vw)',
            },
          }}
         
        title=""/>
    )
}