import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import * as colorHelper from './../colorHelper'



export default function DQIChartAmChartSimple(props) {


    React.useLayoutEffect(() => {
        if(props.data ){
        var chart = am4core.create(props.id, am4charts.PieChart);



        // Let's cut a hole in our Pie chart the size of 40% the radius
        chart.innerRadius = am4core.percent(20);

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.innerRadius = 10;
        pieSeries.slices.template.fillOpacity = 0.5;

        pieSeries.slices.template.propertyFields.disabled = "slidesDisabled";
        pieSeries.labels.template.propertyFields.disabled = "labelDisabled";
        pieSeries.ticks.template.propertyFields.disabled = "ticksDisabled";
        pieSeries.slices.template.propertyFields.fill = "fill";

        // Add data
        pieSeries.data = [{
            "category": "",
            "value": props.data>=0 ? props.data : 0 ,
            "labelDisabled": true,
            "ticksDisabled": true,
            "fill": colorHelper.getColorForPercentage(props.data)
        }, {
            "category": "Unused",
            "value": props.data>0 ? 100 - props.data : 100,
            "slidesDisabled": true,
            "labelDisabled": true,
            "ticksDisabled": true
        }];

        pieSeries.tooltip.disabled = true;
        // Disable sliding out of slices
        pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries.slices.template.states.getKey("hover").properties.scale = 1;

        // Add second series
        var pieSeries2 = chart.series.push(new am4charts.PieSeries());
        pieSeries2.dataFields.value = "value";
        pieSeries2.dataFields.category = "category";
        pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;
        pieSeries2.slices.template.propertyFields.fill = "fill";

        pieSeries2.slices.template.propertyFields.disabled = "slidesDisabled";
        pieSeries2.labels.template.propertyFields.disabled = "labelDisabled";
        pieSeries2.ticks.template.propertyFields.disabled = "ticksDisabled";

        // Add data
        pieSeries2.data = [{
            "category": "DQI",
            "value": props.data>=0 ? props.data : 0 ,
            "labelDisabled": true,
            "ticksDisabled": true,
            "fill": colorHelper.getColorForPercentage(props.data)
        }, {
            "category": "Remaining",
            "value": props.data>0 ? 100 - props.data : 100,
            "fill": "#dedede",
            "labelDisabled": true,
            "ticksDisabled": true,
            
            
        }];

        



        pieSeries.adapter.add("innerRadius", function (innerRadius, target) {
            return am4core.percent(40);
        })

        pieSeries2.adapter.add("innerRadius", function (innerRadius, target) {
            return am4core.percent(60);
        })

        pieSeries.adapter.add("radius", function (innerRadius, target) {
            return am4core.percent(100);
        })

        pieSeries2.adapter.add("radius", function (innerRadius, target) {
            return am4core.percent(80);
        })

        var label = chart.seriesContainer.createChild(am4core.Label);
       const data = props.data === -1? 0 :  props.data.toFixed(2)
        label.fontSize = 10;
        label.textAlign = "middle";
        label.horizontalCenter = "middle";
        label.adapter.add("text", function(text, target){
            return "[bold font-size:12px]" +data + "%";
          })


        return () => {
            chart.dispose();
        };
    }
    }, [props.data]);// eslint-disable-line react-hooks/exhaustive-deps


   
   
        return (
                <div id={props.id} style={{ width: "100%", maxHeight: "100%" }}></div>
               
           
        )
        
       
   
}
