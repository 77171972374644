
import * as actions from '../../actions'

export function getUserPreferenceProperty(user,preferenceName){
    if(user!==null && user.preferences!==null && user!==undefined && user.preferences!==undefined 
        && user.preferences[preferenceName]!==undefined && user.preferences[preferenceName]!==null){
        return user.preferences[preferenceName]
    }
    return undefined
}

export function updateUserPreference(dispatch,user,preferenceName, preferenceValue){
    var newUser = JSON.parse(JSON.stringify(user))
    if(newUser.preferences ===undefined || newUser.preferences ===null){
        newUser.preferences = {}
    }
    newUser.preferences[preferenceName] = preferenceValue
    dispatch(actions.updateUserLicense(newUser))
}