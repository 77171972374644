import React from 'react'; 
import { Card, CardContent, Typography } from '@material-ui/core';
import * as commonStyles from 'style/CommonStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CardProps = {
    children: React.ReactNode; // 👈️ type children
    icon: any,
    title: string
  };

export default function ComponentCard(props: CardProps) {
    return (
        <Card elevation={12}
                    style={{ width: '100%', minHeight: '100%' }}>

                    <CardContent style={{ marginBottom: '-25px' }}>
                        <div style={commonStyles.adjacentLeft}>
                            <FontAwesomeIcon icon={props.icon} style={{ color: commonStyles.mainColor, fontSize: 15, marginTop:'-10px' }} />
                            <Typography variant='subtitle2' style={{ marginLeft: '2%'}}> {props.title}</Typography>
                        </div>

                    </CardContent>
                    {props.children}
                    </Card>
    )
}
