import React from 'react';
import { faSearchPlus, faDatabase } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { Tooltip, Typography, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledMenu, StyledMenuItem } from "components/common/CustomizedMenu";
import DialogPreviewDataformat from './../common/DialogPreviewDataformat'

import { dispatchError, dispatchInfo } from 'components/common/axios/axiosHelper';
import { ReactComponent as LocalFileIcon } from 'resources/icons/azure/File.svg';

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialState = {
    mouseX: null,
    mouseY: null,
};



export default function TreeViewStorageRestLocal(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))
    const theme = useTheme();



    const [storageAccounts, setStorageAccounts] = React.useState([])
    const [selected, setSelected] = React.useState([])
    const [expanded, setExpanded] = React.useState([]);
    const [state, setState] = React.useState(initialState);
    const [selectedItemForMenu, setSelectedItemForMenu] = React.useState({})
    const [storageType, setStorageType] = React.useState("blob")


    let allowedFormats = ['.csv', '.json', '.parquet', '.txt']

    const [allowUpdate, setAllowUpdate] = React.useState(true)

    const [azureStorageDataformat, setAzureStorageDataformat] = React.useState([])

    const [showAlreadyDatasource, setShowAlreadyDatasource] = React.useState(false)


    React.useEffect(() => { getAzureStorageDataFormats(user) }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    async function getAzureStorageDataFormats(user) {
        const dataformats = await dispatch(actions.getAzureStorageDataformats(user.id))
        if (dataformats) {
            setAzureStorageDataformat(dataformats)
        }
    }
    //use effect to get local storages
    React.useEffect(() => {
        if (props.connectToLocalStorages && props.selectedEnvironment) {
            setStorageAccounts([])
            let credentials = getCredentialsFromEnvironmentConfig(props.selectedEnvironment, configuration)
            if (credentials && credentials.length > 0) {
                listLocalStorageAccounts(credentials).then(result => {
                    setStorageAccounts(result)
                })
            }
            else {
                setStorageAccounts([])
            }
        }
    }, [user, props.connectToLocalStorages, props.selectedEnvironment && props.selectedEnvironment.environmentName, configuration]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => { setAllowUpdate(true) }, [storageAccounts])

    function getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration) {
        let masterConfig = JSON.parse(JSON.stringify(configuration))
        console.log('masterConfig', masterConfig)
        if (masterConfig.environmentConfigurationList && masterConfig.environmentConfigurationList.length > 0) {
            let selectedEnvironmentConfiguration = masterConfig.environmentConfigurationList.filter(envConf => envConf.environmentName === selectedEnvironment)[0]
            let credentials = []
            if (selectedEnvironmentConfiguration && selectedEnvironmentConfiguration.enabledDataStorageCredentials
                && selectedEnvironmentConfiguration.enabledDataStorageCredentials.length > 0) {
                credentials = selectedEnvironmentConfiguration.enabledDataStorageCredentials
            }
            credentials = checkIfUserCanAccessCredentials(user, credentials)
            credentials = getStoragesAndContainersCredentials(credentials)
            return credentials
        }

    }

    //here we check if credentials are public or protected and if the user is allowed to access them
    function checkIfUserCanAccessCredentials(user, credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].accessType && credentials[i].accessType === "public_access") {
                usableCredentials.push(credentials[i])
            }
            if (credentials[i].accessType && credentials[i].accessType === "protected_access" && credentials[i].allowedUsers) {
                let userIsAllowed = credentials[i].allowedUsers.filter(allowedUser => allowedUser.id === user.id).length > 0
                if (userIsAllowed) {
                    usableCredentials.push(credentials[i])
                }
            }
        }
        return usableCredentials
    }
    function getStoragesAndContainersCredentials(credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].storageType && (credentials[i].storageType === "azure_storage" || credentials[i].storageType === "azure_container")) {
                usableCredentials.push(credentials[i])
            }
        }
        return usableCredentials
    }

    async function listLocalStorageAccounts(localStorageCredentials) {
        if (localStorageCredentials !== undefined && localStorageCredentials.length > 0) {


            let localStoragesWithContainers = []
            for (let i = 0; i < localStorageCredentials.length; i++) {
                const encryptedsas = localStorageCredentials[i].storageAccountCredentialsDTO.sharedAccessSignature
                let decriptedSas = await dispatch(actions.getSecret(encryptedsas))
                console.log('decriptedSas',decriptedSas)
                if(decriptedSas === undefined || decriptedSas.result === 'ko'){
                    dispatchError(dispatch, 'Error getting secret for storage '+localStorageCredentials[i].storageAccountCredentialsDTO.storageAccountName)
                }
                else{
                    let fullStorage = await actions.onGetContainerListADLv2SaS(localStorageCredentials[i], dispatch, decriptedSas)
                    localStoragesWithContainers.push(fullStorage)
                }
               
            }
            return localStoragesWithContainers
        }
        return

    }

    /*
    async function getAzureStorageAccount(user) {
        const dataformats = await dispatch(actions.getAzureStorageDataformats(user.id))
        setAzureStorageDataformat(dataformats)
    }*/

    function alreadyAzureDataformatOwn(storageAccount, container, item) {
        var already = false
        if (azureStorageDataformat === null || azureStorageDataformat === undefined || azureStorageDataformat.length || azureStorageDataformat.length === 0) {
            return already
        }
        try {
            azureStorageDataformat.forEach(dataformat => {

                //we dont use resourcegroup because in sas we do not have it
                if (
                    dataformat.fileStorageProperties.account === storageAccount.storageAccountCredentialsDTO.storageAccountName &&
                    dataformat.fileStorageProperties.container === container.name &&
                    dataformat.fileStorageProperties.path.endsWith(item.name)) {
                    if (
                        (dataformat.userId === user.id ||
                            (dataformat.allowedUsers && dataformat.allowedUsers.length > 0 && dataformat.allowedUsers.includes(user.id)))) {
                        already = true
                    }
                }
            })
        }
        catch (error) {
            console.error(error)
            //TODO: gestionar el error
        }
        return already
    }

    function alreadyAzureDataformatOther(storageAccount, container, item) {
        var already = false
        if (azureStorageDataformat === null || azureStorageDataformat === undefined) {
            return already
        }
        try {
            azureStorageDataformat.forEach(dataformat => {


                if (
                    dataformat.fileStorageProperties.account === storageAccount.storageAccountCredentialsDTO.storageAccountName &&
                    dataformat.fileStorageProperties.container === container.name &&
                    dataformat.fileStorageProperties.path.endsWith(item.name)) {
                    if (
                        alreadyAzureDataformatOwn(storageAccount, container, item) === false) {
                        already = true
                    }
                }
            })
        }
        catch (e) {
            //SENTRY ERROR Event ID c826a52eaa6a4d5da1895bb55a557258
            console.error("Error un azureStoreDataformat")
            console.error(e)
        }

        return already
    }

    function getDataformat(resourceGroup, storageAccount, container, name) {
        var selectedDF = undefined
        if (azureStorageDataformat === null || azureStorageDataformat === undefined) {
            return selectedDF
        }
        try {
            azureStorageDataformat.forEach(dataformat => {

                if (
                    dataformat.fileStorageProperties.account === storageAccount &&
                    dataformat.fileStorageProperties.container === container &&
                    dataformat.fileStorageProperties.path.endsWith(name)) {
                    selectedDF = dataformat
                }
            })
        }
        catch (e) {
            console.error("Error in getDataformat")
            console.error(e)
        }
        return selectedDF
    }

    /** Funciones de gestión del menu contextual **/

    const handleClick = (event, rootItem, finalName) => {
        const finalRootName = rootItem.storageAccount + "###%%###" + rootItem.container + "###%%###" + rootItem.name
        let selected_ = JSON.parse(JSON.stringify(selected)).split("###%%###")[1] + "###%%###" + JSON.parse(JSON.stringify(selected)).split("###%%###")[2] + "###%%###" + JSON.parse(JSON.stringify(selected)).split("###%%###")[3]
        if (finalRootName === selected_) {
            const expandedSplit = selected_.split("###%%###")
            if (expandedSplit.length === 3) {

                const blobName = expandedSplit[2]
                if (rootItem.name === blobName) {
                    event.preventDefault();
                    props.setFileOutputName(finalName)
                    setSelectedItemForMenu(rootItem)
                    setState({
                        mouseX: event.clientX - 2,
                        mouseY: event.clientY - 4,
                    });
                }
            }
            else {
                event.preventDefault();
                props.setFileOutputName(finalName)
                setSelectedItemForMenu(rootItem)
                setState({
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                });
            }

        }


    };

    const handleClose = () => {
        setState(initialState);
    };




    function getDetailStorageTransaction(storageAccount) {
        if (allowUpdate === true) {
            getDetailStorage(storageAccount)
        }
    }



    function getDetailStorage(storageAccount) {
        setAllowUpdate(false)
        getDetail(storageAccount).then(object => {

            var tempResult = JSON.parse(JSON.stringify(storageAccounts))
            if (object.containers === undefined || object.containers.length === 0) {
                //creamos container que nos marque que está vacío
                object.containers = [
                    {
                        resourceGroupName: 'no-container-found',
                        name: 'no-container-found',
                        region: 'no-container-found'
                    }
                ]
            }
            let newList = [];
            /*let index = -1
               for (let i; i < tempResult.length; i++) {
                if (tempResult[i].displayName === object.displayName && object.storageAccountCredentialsDTO.storageAccountName === tempResult[i].storageAccountCredentialsDTO.storageAccountName) {
                    index = i
                }
            } */
            try {
                tempResult.forEach(function (item) {
                    if (isEqualsSaSStorageAccount(item, object) === true) {
                        newList.push(object);
                    } else {
                        newList.push(item);
                    }
                });
            }
            catch (e) {
                console.error("Error in getDetailStorage")
                console.error(e)
            }
            tempResult = newList
            setStorageAccounts(newList);
            //const newTransactions = transactions.slice(1)
            //setTransactions(newTransactions)



        })
    }


    function isEqualsSaSStorageAccount(item, object) {
        if (item.displayName === object.displayName &&
            item.storageAccountCredentialsDTO.storageAccountName ===
            object.storageAccountCredentialsDTO.storageAccountName && item.storageType === object.storageType && item.storageAccountCredentialsDTO.folder === object.storageAccountCredentialsDTO.folder && item.id === object.id) {
            return true
        }
        return false
    }







    async function getDetail(dto) {
        //var result = await props.azureManager.finalizeLogin()
        // if (result.isLoggedIn) {
        //let valuesADLv2 =  actions.onGetContainerListADLv2(configuration,result.creds, props.selectedSubscription ? props.selectedSubscription : result.availableSubscriptions[0].id, dto.resourceGroupName, dto.name, dto.region, dto, dispatch)
        //if(valuesADLv2 === null || valuesADLv2.length ===0){
        //var blobResults = actions.onGetContainerList(result.creds, props.selectedSubscription ? props.selectedSubscription : result.availableSubscriptions[0].id, dto.resourceGroupName, dto.name, dto.region, dto, dispatch)

        const encryptedsas = dto.storageAccountCredentialsDTO.sharedAccessSignature
        let decriptedSas = await dispatch(actions.getSecret(encryptedsas))
        var ADLv2Results = actions.onGetContainerListADLv2SaS(dto, dispatch, decriptedSas)

        setStorageType("blob")

        return ADLv2Results
        //}
        // else{
        //     return valuesADLv2
        // }
        /*    }
            else {
                props.azureManager.login()
            }*/


    }


    /**FUNCIONES DE GESTIÓN DE EVENTOS UI */

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };


    async function handlePreview(event, isFolder, decriptedSasPromise) {
        const decriptedSas = await decriptedSasPromise

        if (isFolder === true) {
            event = await actions.getNameListChildrenStorageObjectSaS(event, decriptedSas)

        }
        
        if (props.selectedFormat) selectedItemForMenu.selectedFormat = props.selectedFormat.toLowerCase()

        props.setSelectedItem(selectedItemForMenu)

        handleClose(event)
        dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")

        await dispatch(actions.createPreviewDataJob(props.selectedEnvironment, user.id, selectedItemForMenu, props.numberSamples, props.flattenFlag)).then(result => {
            if (result !== undefined && result.error === undefined) {
                props.setVisiblePreview(true)
            }
            if (result && result.run_id) {
                props.setRunId(result.run_id)
            }

        })
    }

    async function handleProfilingNew(event, isFolder) {
        props.resetProfilingState()
        if (isFolder === true) {
            //obtenemos un listado del nombre de todos los hijos del elemento, para poder inferir el formato de los ficheros
            //  const result = await props.azureManager.finalizeLogin()
            //  if (result.isLoggedIn) {
            if (props.selectedFormat) selectedItemForMenu.selectedFormat = props.selectedFormat.toLowerCase()

            await dispatch(actions.addProfilingToDataformat(props.selectedEnvironment, user.id, selectedItemForMenu, props.profilingSamples, props.flattenFlag)).then(result => {
                /* if (result && result.dto && result.dto.run_id) {
                    props.setRunId(result.dto.run_id)
                } */
                if (result && result.profilingBatchId) {
                    props.setProfilingBatchId(result.profilingBatchId)
                }

            })
            /*   }
               else {
                   props.azureManager.login()
               }*/

        }
        if (isFolder === false) {
            props.setSelectedItem(selectedItemForMenu)

            handleClose(event)
            dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")

            await dispatch(actions.addProfilingToDataformat(props.selectedEnvironment, user.id, selectedItemForMenu, props.profilingSamples, props.flattenFlag)).then(result => {
                /* if (result && result.dto && result.dto.run_id) {
                    props.setRunId(result.dto.run_id)
                } */
                if (result && result.dataformatId) {
                    props.setProfilingdataformatId(result.dataformatId)
                }

            })
        }
    }

    async function handleImport(event, isFolder) {

        var dataformat = getDataformat(event.resourceGroupName, event.storageAccount, event.container, event.fullName)
        dataformat.userId = user.id
        if (dataformat !== undefined) {
            dispatch(actions.addDataFormatObject(dataformat))
        }


    }







    async function getRootItemsForContainer(storageAccount, container, containerIndex, storageAccountIndex) {
        //obtenemos la key del storageAccount
        //var key = undefined
        //  var result = await props.azureManager.finalizeLogin()

        //  if (result.isLoggedIn) {
        /* const dtoRest = {
             key: undefined,
             accountName: storageAccount.storageAccountCredentialsDTO.storageAccountName,
             folder: undefined,
             container: container
         }*/
        //probamos la funcion en browser

        const encryptedsas = storageAccount.storageAccountCredentialsDTO.sharedAccessSignature
        let decriptedSas = await dispatch(actions.getSecret(encryptedsas))

        //if its a container sas, we need to access with blob sas
        var getItems = storageAccount.storageAccountCredentialsDTO.folder ?
            actions.getItemsFromContainerBlobSaS("/", storageAccount.storageAccountCredentialsDTO.storageAccountName, container, decriptedSas) :
            actions.getItemsFromContainerADLv2SaS("/", storageAccount.storageAccountCredentialsDTO.storageAccountName, container, decriptedSas)

        getItems.then(childrens => {
            if (childrens === undefined || childrens.length === 0) {
                //try with blobStorage
                setStorageType("blob")
                actions.getItemsFromContainerBlobSaS(storageAccount.storageAccountCredentialsDTO.folder ? storageAccount.storageAccountCredentialsDTO.folder : "/", storageAccount.storageAccountCredentialsDTO.storageAccountName, container, decriptedSas).then(childrens => {
                    const childrensMap = (childrens === undefined || childrens.length === 0) ? [] : childrens.map(x => {
                        return {
                            type: (x.isDirectory === true) ? 'Folder ' : "file",
                            name: x.name,
                            fullName: x.name,
                            resourceGroupName: storageAccount.resourceGroupName,
                            storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
                            container: container.name,
                            children: (x.properties === null || x.properties === undefined) ?
                                [{
                                    type: 'no data',
                                    name: 'no data',
                                    fullName: 'no data',
                                    resourceGroupName: storageAccount.resourceGroupName,
                                    storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
                                    container: container.name,
                                    children: [],
                                    level: 1,
                                    parent: x.name
                                }] : [],
                            level: 0,
                            parent: ""
                        }
                    })
                    var tempResult = JSON.parse(JSON.stringify(storageAccount))
                    var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                    tempResult.containers[containerIndex].storageAccountItemDTOSRoot = childrensMap

                    newStorages[storageAccountIndex] = tempResult
                    setStorageAccounts(newStorages)
                })
            }
            else {
                const childrensMap = (childrens === undefined || childrens.length === 0) ? [] : childrens.map(x => {
                    return {
                        type: (x.isDirectory === true) ? 'Folder ' : 'file',
                        name: x.name,
                        fullName: x.name,
                        resourceGroupName: storageAccount.resourceGroupName,
                        storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
                        container: container.name,
                        children: (x.properties === null || x.properties === undefined) ?
                            [{
                                type: 'no data',
                                name: 'no data',
                                fullName: 'no data',
                                resourceGroupName: storageAccount.resourceGroupName,
                                storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
                                container: container.name,
                                children: [],
                                level: 1,
                                parent: x.name
                            }] : [],
                        level: 0,
                        parent: ""
                    }
                })
                var tempResult = JSON.parse(JSON.stringify(storageAccount))
                var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                tempResult.containers[containerIndex].storageAccountItemDTOSRoot = childrensMap

                newStorages[storageAccountIndex] = tempResult
                setStorageAccounts(newStorages)
            }
        })







    }

    function checkAlreadyCreatedDataformat(rootItem, elements) {
        if (elements === undefined || elements.length === 0) {
            return false
        }
        else {
            let rootItemPath = `wasbs://${rootItem.container}@${rootItem.storageAccount}.blob.core.windows.net/${rootItem.name}`

            let values = elements.filter(x => x.fileStorageProperties !== undefined).map(x => x.fileStorageProperties).filter(x => x.resourceGroup === rootItem.resourceGroupName
                && x.account === rootItem.storageAccount && x.container === rootItem.container && x.path === rootItemPath)
            if (values.length > 0) {

                return true
            }
        }
        return false
    }

    function getNameAlreadyCreatedDataformat(rootItem, elements) {
        if (elements === undefined || elements.length === 0) {
            return ''
        }
        else {
            let rootItemPath = `wasbs://${rootItem.container}@${rootItem.storageAccount}.blob.core.windows.net/${rootItem.name}`

            let values = elements.filter(x => x.fileStorageProperties !== undefined).filter(x => x.fileStorageProperties.account === rootItem.storageAccount && x.fileStorageProperties.container === rootItem.container
                && x.fileStorageProperties.path === rootItemPath)
            if (values.length > 0) {
                return values[0].name
            }
        }
        return ''
    }



    async function getDetailBlobItemList(storageAccount, container, parent, indexList,
        allowUpdate, azureManager, actions, dispatch, storageAccounts, setStorageAccounts) {
        if (parent && parent.children &&
            parent.children.length > 0 &&
            (parent.children.length > 1 ||
                parent.children[0].type !== 'no data')

        ) {

            return
        }

        if (allowUpdate === false) {
            return
        }


        // var key = undefined
        // var result = await azureManager.finalizeLogin()
        // if (result.isLoggedIn) {
        /*   const dtoRest = {
               key: undefined,
               accountName: storageAccount.storageAccountCredentialsDTO.storageAccountName,
               folder: parent.name,
               container: container
           }*/
        const encryptedsas = storageAccount.storageAccountCredentialsDTO.sharedAccessSignature
        let decriptedSas = await dispatch(actions.getSecret(encryptedsas))

        if (storageType === "adlv2") {
            actions.getItemsFromContainerADLv2SaS(parent.name, storageAccount.storageAccountCredentialsDTO.storageAccountName, container, decriptedSas).then(childrens => {

                // ahora hay que actualizar recursivamente
                //trabajamos con una copia ya que el original es constante
                var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                var firstDTO = storageAccount.containers[indexList[1]].storageAccountItemDTOSRoot[indexList[2]]
                const values = childrens.map(x => parseContainerDataADLv2(x, storageAccount, container))

                if (indexList.length <= 3) {
                    firstDTO.children = values
                }
                else {
                    firstDTO = updateStorageAccountDTORecursively(firstDTO, indexList, 3, values)
                }
                newStorages[indexList[0]] = storageAccount
                setStorageAccounts(newStorages)

            })
        }
        else {
            actions.getItemsFromContainerSaS(parent.name, storageAccount.storageAccountCredentialsDTO.storageAccountName, container, decriptedSas).then(childrens => {

                // ahora hay que actualizar recursivamente
                //trabajamos con una copia ya que el original es constante
                var newStorages = JSON.parse(JSON.stringify(storageAccounts))
                var firstDTO = storageAccount.containers[indexList[1]].storageAccountItemDTOSRoot[indexList[2]]
                const values = childrens.map(x => parseContainerData(x, storageAccount, container))

                if (indexList.length <= 3) {
                    firstDTO.children = values
                }
                else {
                    firstDTO = updateStorageAccountDTORecursively(firstDTO, indexList, 3, values)
                }
                newStorages[indexList[0]] = storageAccount
                setStorageAccounts(newStorages)

            })
        }

        //  }


    }

    function parseContainerData(x, storageAccount, container) {
        return {
            type: (x.isDirectory === true) ? 'Folder ' : 'file',
            name: x.name,
            fullName: x.name,
            resourceGroupName: storageAccount.resourceGroupName,
            storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
            container: container.name,
            children: (x.properties === null || x.properties === undefined) ?
                [{
                    type: 'no data',
                    name: 'no data',
                    fullName: 'no data',
                    resourceGroupName: storageAccount.resourceGroupName,
                    storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
                    container: container.name,
                    children: [],
                    level: 1,
                    parent: x.name
                }] : [],
            level: 0,
            parent: ""
        }
    }

    function parseContainerDataADLv2(x, storageAccount, container) {
        return {
            type: (x.isDirectory === true) ? 'Folder ' : 'file',
            name: x.name,
            fullName: x.name,
            resourceGroupName: storageAccount.resourceGroupName,
            storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
            container: container.name,
            children: (x.properties === null || x.properties === undefined) ?
                [{
                    type: 'no data',
                    name: 'no data',
                    fullName: 'no data',
                    resourceGroupName: storageAccount.resourceGroupName,
                    storageAccount: storageAccount.storageAccountCredentialsDTO.storageAccountName,
                    container: container.name,
                    children: [],
                    level: 1,
                    parent: x.name
                }] : [],
            level: 0,
            parent: ""
        }
    }


    function createStorageAccTreeItemFolder(content, storageAccount, container, rootItem,
        imagePath, treeItemName, indexVector) {
        return (
            <div>
                <TreeItem key={storageAccount.displayName + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.fullName} nodeId={storageAccount.displayName + "###%%###" + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.fullName}
                    onClick={event => {
                        getDetailBlobItemList(storageAccount, container, rootItem, indexVector,
                            allowUpdate, props.azureManager, actions, dispatch, storageAccounts, setStorageAccounts)
                    }}
                    onContextMenu={event => {
                        handleClick(event, rootItem, rootItem.name)
                    }
                    }
                    onDoubleClick={event => {
                        handleClick(event, rootItem, rootItem.name)
                    }
                    }

                    label={
                        <div>
                            {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === false &&
                                alreadyAzureDataformatOther(storageAccount, container, rootItem) === false &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    {imagePath === 'Folder-Blank' &&
                                        <img alt="" style={{ height: '12px', width: '12px' }}
                                            src={require('resources/icons/azure/Folder.png')} />}

                                    <Typography variant='caption'>{treeItemName}</Typography>


                                </div>
                            }



                            {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === true &&
                                <Tooltip title={`Source already registered in Aqtiva`}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <img alt="" style={{ height: '15px', width: '15px' }}
                                            src={require('resources/icons/favicon-32x32.jpg')} />
                                        <Typography variant='caption' style={({ color: '#4697c4' })}>{treeItemName}</Typography>

                                    </div>
                                </Tooltip>
                            }

                            {alreadyAzureDataformatOther(storageAccount, container, rootItem) === true &&
                                <Tooltip title={`Source already registered in Aqtiva by other user`}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <img alt="" style={{ height: '15px', width: '15px' }}
                                            src={require('resources/icons/favicon-32x32.jpg')} />
                                        <Typography variant='caption' style={({ color: '#a1a1a1' })}>{treeItemName}</Typography>

                                    </div>
                                </Tooltip>
                            }
                        </div>



                    } >
                    {content}
                </TreeItem>
                {createPreviewContextMenu(storageAccount, container, rootItem, true)}
            </div>
        )
    }



    function updateStorageAccountDTORecursively(dto, indexList, secondIndex, values) {
        // se empieza siempre por el hijo
        if (secondIndex < indexList.length) {
            dto.children[indexList[secondIndex]] = updateStorageAccountDTORecursively(dto.children[indexList[secondIndex]], indexList, secondIndex + 1, values)
            return dto
        }
        else {
            dto.children = values
            return dto
        }

    }



    function createRootItemChildren(storageAccount, container, rootItem, indexVector) {

        return (
            <div>
                {rootItem.children !== undefined && rootItem.children.length === 1 && rootItem.children[0].name === 'no data' &&
                    <div>
                        <TreeItem key={storageAccount.displayName + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.parent + "_empty"} nodeId={storageAccount.displayName + "###%%###" + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.parent + "_empty"} label={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>

                                <CircularProgress style={{ width: '15px', height: '15px' }} />
                                {allowUpdate === true && <Typography variant='caption'>Loading storage data, it may take some seconds...</Typography>}
                                {allowUpdate === false && <Typography variant='caption'>Wait for previous data to be loading and try again</Typography>}
                            </div>
                        } />
                    </div>


                }


                {(rootItem.children !== undefined && rootItem.children !== null && rootItem.children.length > 0 && rootItem.children[0].name !== 'no data') &&
                    rootItem.children.sort((a, b) => (a.name > b.name) ? 1 : -1).map((children, childrenIndex) => {
                        return createMenuItemBlobItem(storageAccount, container, children, [...indexVector, childrenIndex])
                    })
                }
            </div>
        )
    }


    function createPreviewContextMenu(storageAccount, container, rootItem, isFolder) {




        if (JSON.stringify(rootItem) === JSON.stringify(selectedItemForMenu)) {

            if (alreadyAzureDataformatOwn(storageAccount, container, rootItem) === true) {
                return (
                    <div>
                        <StyledMenu
                            keepMounted
                            open={state.mouseY !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                state.mouseY !== null && state.mouseX !== null
                                    ? { top: state.mouseY, left: state.mouseX }
                                    : undefined
                            }
                        >
                            <StyledMenuItem onClick={() => setShowAlreadyDatasource(true)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="View Dataformat" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => {
                                const encryptedsas = storageAccount.storageAccountCredentialsDTO.sharedAccessSignature
                                let decriptedSas = dispatch(actions.getSecret(encryptedsas))
                                handlePreview(rootItem, isFolder, decriptedSas)
                            }}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Preview" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleProfilingNew(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Update Profiling" />
                            </StyledMenuItem>


                        </StyledMenu>
                    </div>
                )
            }
            else if (alreadyAzureDataformatOther(storageAccount, container, rootItem) === true) {
                return (
                    <div>
                        <StyledMenu
                            keepMounted
                            open={state.mouseY !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                state.mouseY !== null && state.mouseX !== null
                                    ? { top: state.mouseY, left: state.mouseX }
                                    : undefined
                            }
                        >
                            <StyledMenuItem onClick={() => setShowAlreadyDatasource(true)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="View Dataformat" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => {
                                const encryptedsas = storageAccount.storageAccountCredentialsDTO.sharedAccessSignature
                                let decriptedSas = dispatch(actions.getSecret(encryptedsas))
                                handlePreview(rootItem, isFolder, decriptedSas)
                            }}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Preview" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleImport(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Import" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleProfilingNew(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Update Profiling" />
                            </StyledMenuItem>



                        </StyledMenu>
                    </div>
                )
            }
            else {

                return (
                    <div>
                        <StyledMenu
                            keepMounted
                            open={state.mouseY !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                state.mouseY !== null && state.mouseX !== null
                                    ? { top: state.mouseY, left: state.mouseX }
                                    : undefined
                            }
                        >
                            <StyledMenuItem onClick={() => {
                                const encryptedsas = storageAccount.storageAccountCredentialsDTO.sharedAccessSignature
                                let decriptedSas = dispatch(actions.getSecret(encryptedsas))
                                handlePreview(rootItem, isFolder, decriptedSas)
                            }}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Preview" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => handleProfilingNew(rootItem, isFolder)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                                </ListItemIcon>
                                <ListItemText primary="Profiling" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </div>
                )
            }

        }
        else {
            return <div></div>
        }




    }

    /* function isStorageAvailable(storage) {
         if (storage.cors === undefined || storage.cors.length === 0 ||
             storage.cors[0].cors.corsRules === undefined || storage.cors[0].cors.corsRules.length === 0) {
             return false
         }
         return true
     }
 
     function getStorageCorsErrorMessage(storage) {
         if (storage.cors === undefined || storage.cors.length === 0 ||
             storage.cors[0].cors.corsRules === undefined || storage.cors[0].cors.corsRules.length === 0) {
             return "No CORS has been configured. Please contact your administrator to allow CORS for this account in order to be accesible by Aqtiva"
 
         }
         return ""
     }*/



    function createMenuItemBlobItem(storageAccount, container, rootItem, indexVector) {

        let splitName = rootItem.name.split('/')
        let finalName = splitName.length > 1 ? splitName[splitName.length - 1] !== "" ? splitName[splitName.length - 1] : splitName[splitName.length - 2] : rootItem.name
        if (rootItem.type === 'Folder ') {
            if (rootItem.children !== undefined && rootItem.children !== null && rootItem.children.length > 0) {
                return <div>
                    {createStorageAccTreeItemFolder([createRootItemChildren(storageAccount, container, rootItem, indexVector)],
                        storageAccount, container, rootItem,
                        'Folder-Blank', finalName, indexVector)}


                </div>
            }
            else {
                return <div>
                    <TreeItem key={storageAccount.displayName + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.fullName} nodeId={storageAccount.displayName + "###%%###" + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.fullName}
                        onContextMenu={event => handleClick(event, rootItem, finalName)}
                        onDoubleClick={event => {
                            handleClick(event, rootItem, finalName)
                        }}
                        label={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>
                                <div style={{ width: '25px' }} />
                                <img alt="" style={{ height: '12px', width: '12px' }}
                                    src={require('resources/icons/azure/Folder.png')} />
                                <Typography variant='caption'>{finalName}</Typography>

                            </div>
                        } />

                </div>
            }

        }
        else {
            return <div>
                <TreeItem key={storageAccount.displayName + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.fullName} nodeId={storageAccount.displayName + "###%%###" + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "###%%###" + rootItem.fullName}
                    onContextMenu={event => {
                        if (
                            (allowedFormats.some(substring => rootItem.name.includes(substring)) === true)) {
                            handleClick(event, rootItem, finalName)
                        }
                    }}
                    onDoubleClick={event => {
                        if (
                            (allowedFormats.some(substring => rootItem.name.includes(substring)) === true)) {
                            handleClick(event, rootItem, finalName)
                        }
                    }}
                    label={checkAlreadyCreatedDataformat(rootItem, props.alreadyDefinedDataformats) === true ?

                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>
                            <div />
                            <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '12px', width: '12px' }} />
                            <Tooltip title={`Source already created: ${getNameAlreadyCreatedDataformat(rootItem, props.alreadyDefinedDataformats)}`}>
                                <Typography variant='caption' style={{ fontWeight: 'bold', color: '#4697c4' }}>{finalName}</Typography>
                            </Tooltip>
                        </div>
                        : (allowedFormats.some(substring => rootItem.name.includes(substring)) === true) ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>
                                <div />
                                {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === false &&
                                    alreadyAzureDataformatOther(storageAccount, container, rootItem) === false &&
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>

                                        <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '12px', width: '12px' }} />
                                        <Typography variant='caption' >{finalName}</Typography>

                                    </div>}
                                {alreadyAzureDataformatOwn(storageAccount, container, rootItem) === true &&
                                    <Tooltip title={`Source already registered in Aqtiva`}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <img alt="" style={{ height: '15px', width: '15px' }}
                                                src={require('resources/icons/favicon-32x32.jpg')} />
                                            <Typography variant='caption' style={({ color: '#4697c4' })}>{finalName}</Typography>

                                        </div>
                                    </Tooltip>
                                }


                                {alreadyAzureDataformatOther(storageAccount, container, rootItem) === true &&
                                    <Tooltip title={`Source already registered in Aqtiva by other user`}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <img alt="" style={{ height: '15px', width: '15px' }}
                                                src={require('resources/icons/favicon-32x32.jpg')} />
                                            <Typography variant='caption' style={({ color: '#a1a1a1' })}>{finalName}</Typography>

                                        </div>
                                    </Tooltip>
                                }


                            </div>
                            :
                            <div style={{
                                display: 'flex',
                                justifyContent: 'left',
                            }}>
                                <div />
                                <LocalFileIcon fill={commonStyles.mainColor} style={{ height: '12px', width: '12px' }} />
                                <Tooltip title={`Format not allowed`}>
                                    <Typography variant='caption' style={{ color: '#bfbfbf' }}>{finalName}</Typography>
                                </Tooltip>

                            </div>
                    } />


                {createPreviewContextMenu(storageAccount, container, rootItem, (rootItem.type === 'Folder ') ? true : false)}
            </div>

        }

    }

    function getDataFormatFromItemSelected(itemSelected) {
        var dataformat2Return = {}
        if (azureStorageDataformat === null || azureStorageDataformat === undefined) {
            return dataformat2Return
        }
        try {
            if (azureStorageDataformat && azureStorageDataformat.length > 0) {
                azureStorageDataformat.forEach(dataformat => {
                    if (
                        dataformat.fileStorageProperties.account === itemSelected.storageAccount &&
                        dataformat.fileStorageProperties.container === itemSelected.container &&
                        dataformat.fileStorageProperties.path.endsWith(itemSelected.name)) {
                        dataformat2Return = dataformat
                    }
                })
            }

        }
        catch (e) {
            console.error("Error in getDataFormatFromItemSelected")
            console.error(e)
        }
        return dataformat2Return
    }

    function sortStorageAccountsByName(a, b) {
        return (a.displayName + a.id > b.displayName + b.id) ? 1 : -1
    }


    function getTreeItemNoData(storageAccount, allowUpdate) {
        return (
            <TreeItem key={storageAccount.displayName + storageAccount.storageAccountCredentialsDTO.storageAccountName + "_empty"} nodeId={storageAccount.displayName + "###%%###" + storageAccount.storageAccountCredentialsDTO.storageAccountName + "_empty"} label={
                <div style={{
                    display: 'flex',
                    justifyContent: 'left',
                }}>
                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                    {!storageAccount.flagError && allowUpdate === true && <Typography variant='caption'>Loading storage data, it may take some seconds...</Typography>}
                    {!storageAccount.flagError && allowUpdate === false && <Typography variant='caption'>Wait for previous data to be loading and try again</Typography>}
                    {storageAccount.flagError && <Typography variant='caption'>This Local storage does not have valid authorization.</Typography>}
                </div>
            } />
        )
    }

    function getTreeItemStorageAccount1Container(storageAccount) {
        return (<TreeItem key={storageAccount.displayName + storageAccount.storageAccountCredentialsDTO.storageAccountName + "_empty"} nodeId={storageAccount.displayName + "###%%###" + storageAccount.storageAccountCredentialsDTO.storageAccountName + "_empty"} label={
            <div style={{
                display: 'flex',
                justifyContent: 'left',
            }}>

                {!storageAccount.flagError && <Typography variant='subtitle2'>No containers found</Typography>}
                {storageAccount.flagError && <Typography variant='caption'>This Local storage does not have valid authorization.</Typography>}
            </div>
        } />
        )
    }

    function getStorageContainerLabel(storageAccount, container) {
        return (
            <div style={{ display: 'flex', justifyContent: 'left', width: "100%" }}>

                {storageAccount.kind !== 'ADLV2' &&
                    <img alt="" style={{ height: '15px', width: '15px' }}
                        src={require('resources/icons/azure/Storage-Container.png')} />
                }
                {storageAccount.kind === 'ADLV2' &&
                    <img alt="" style={{ height: '25px', width: '25px' }}
                        src={require('resources/icons/azure/adlv2.png')} />
                }

                <Typography variant='subtitle2'>{container.name}</Typography>
            </div>
        )
    }
    return (

        <div className={classes.root}>



            <DialogPreviewDataformat
                setVisible={setShowAlreadyDatasource}
                visible={showAlreadyDatasource}
                selectedDataFormat={getDataFormatFromItemSelected(selectedItemForMenu)}
            />
            {(storageAccounts === undefined || storageAccounts === null || storageAccounts.length === 0) &&
                <Button onClick={event => { props.setConnectToLocalStorages(true) }}
                    style={theme.buttons.buttonRegular}
                    startIcon={<FontAwesomeIcon icon={faLink} style={{ fontSize: 15 }} />} >
                    <div style={theme.common.text.buttonTextSmall}>View local Storages</div>
                </Button>


            }
            {storageAccounts !== undefined && storageAccounts !== null && storageAccounts.length > 0 &&
                <TreeView
                    className={classes.rootView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {storageAccounts.sort((a, b) => (sortStorageAccountsByName(a, b))).map((storageAccount, storageAccountIndex) => {
                        const storageAccountName = storageAccount.storageAccountCredentialsDTO.storageAccountName
                        const displayName = storageAccount.displayName
                        return <TreeItem key={storageAccount.displayName + storageAccountName} nodeId={storageAccount.displayName + "###%%###" + storageAccountName} label={
                            <div style={{ marginRight: '5px', display: 'flex', justifyContent: 'left' }}>
                                <img alt="" style={{ height: '20px', width: '20px' }}
                                    src={require('resources/icons/azure/Storage-Accounts.png')} />
                                {storageAccount.storageType && storageAccount.storageType === "azure_container" && <img alt="" style={{ height: '20px', width: '20px' }}
                                    src={require('resources/icons/azure/Storage-Container.png')} />}
                                <Typography variant='subtitle2'>{displayName} ({storageAccountName})</Typography>
                            </div>}
                            onClick={event => getDetailStorageTransaction(storageAccount)}>

                            {(storageAccount === undefined || storageAccount.containers.length === 0) ?
                                getTreeItemNoData(storageAccount, allowUpdate)
                                :
                                (storageAccount.containers !== undefined && storageAccount.containers.length === 1 && storageAccount.containers[0].name === 'no-container-found') ?

                                    getTreeItemStorageAccount1Container(storageAccount)
                                    :
                                    storageAccount.containers && storageAccount.containers
                                        .sort((a, b) => (a.name > b.name) ? 1 : -1).map((container, containerIndex) => {

                                            return <TreeItem key={storageAccount.displayName + storageAccountName + "###%%###" + container.name} nodeId={storageAccount.displayName + "###%%###" + storageAccountName + "###%%###" + container.name}
                                                onClick={event => getRootItemsForContainer(storageAccount, container, containerIndex, storageAccountIndex)}
                                                label={
                                                    getStorageContainerLabel(storageAccount, container)
                                                } >

                                                {
                                                    container.storageAccountItemDTOSRoot === undefined || container.storageAccountItemDTOSRoot === null ? [] :
                                                        container.storageAccountItemDTOSRoot
                                                            .sort((a, b) => (a.name > b.name) ? 1 : -1).map((rootItem, rootItemIndex) => {

                                                                return createMenuItemBlobItem(storageAccount, container, rootItem, [storageAccountIndex, containerIndex, rootItemIndex])
                                                            })
                                                }
                                                {(container.storageAccountItemDTOSRoot === undefined || container.storageAccountItemDTOSRoot.length === 0) &&
                                                    <TreeItem key={storageAccount.displayName + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "_empty"} nodeId={storageAccount.displayName + "###%%###" + storageAccount.storageAccountCredentialsDTO.storageAccountName + "###%%###" + container.name + "_empty"} label={
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'left',
                                                        }}>
                                                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                            <Typography variant='subtitle2'>Loading storage data, it may take some seconds...</Typography>
                                                        </div>
                                                    } />
                                                }
                                            </TreeItem>


                                        })
                            }
                        </TreeItem>
                    })}

                </TreeView>
            }
        </div>

    )
}