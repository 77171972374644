
import React from 'react'

import FormRuleFormat from './FormRuleFormat'
import FormRuleNull from './FormRuleNull'
import FormRegularExp from './FormRegularExp'
import FormRuleDuplicated from './FormRuleDuplicated'
import FormRuleValueRange from './FormRuleValueRange'
import FormRuleCheckBetweenLoads from './FormRuleCheckBetweenLoads'
import FormTimeliness from './FormTimeliness'
import FormRowAnomalyDetection from './FormRowAnomalyDetection'
import FormColumnAnomalyDetection from './FormColumnAnomalyDetection'
import FormMasterDataNew from './FormMasterDataNew'

import FormAdHoc from './FormAdHoc'
import FormColumnComparison from './FormColumnComparison'


/*
import FormRuleCheckBetweenLoads from './FormRuleCheckBetweenLoads'
import FormRuleDuplicated from './FormRuleDuplicated'
import FormRuleValueRange from './FormRuleValueRange'
import FormTimeliness from './FormTimeliness'
*/


export function selectSpecificForm(type, commonRule, datasource, setSpecific, editableRule, specific, errorsMasterData, errorsComparison, source) {


    switch (type) {
        case 'statistics':
            return (
                <FormRuleFormat commonRule={commonRule}  setSpecific={setSpecific} editableRule={editableRule}
                    specific={specific} source={source} />
            )



        case 'null':
            return (
                <FormRuleNull commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule} specific={specific} />
            )

        case 'exp':
            return (
                <FormRegularExp commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule} specific={specific} />
            )
        case 'duplicated':
            return (
                <FormRuleDuplicated commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule} specific={specific} />
            )

        case 'ranges':
            return (
                <FormRuleValueRange commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule}
                     specific={specific} />
            )
        case 'checkBetweenLoads':
            return (
                <FormRuleCheckBetweenLoads commonRule={commonRule} source={source}
                    setSpecific={setSpecific} editableRule={editableRule} specific={specific} />
            )

        case 'dvd':
            return (
                <FormRuleCheckBetweenLoads commonRule={commonRule}  source={source}
                    setSpecific={setSpecific} editableRule={editableRule} specific={specific} />
            )

        case 'time':
            return (
                <FormTimeliness commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule} specific={specific} />
            )
        case 'masterData':
            return (
                <FormMasterDataNew commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule} specific={specific} datasource={datasource} source={source} errorsMasterData={errorsMasterData} />
            )
        case 'comparison':
            return (
                <FormColumnComparison commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule} specific={specific} datasource={datasource} source={source} errorsComparison={errorsComparison} />
            )
        case 'adHoc':
            return (
                <FormAdHoc commonRule={commonRule} setSpecific={setSpecific} editableRule={editableRule} specific={specific} />
            )

        case 'cell_anomaly_columns':
            return (
                <FormColumnAnomalyDetection commonRule={commonRule}  setSpecific={setSpecific} editableRule={editableRule}
                    specific={specific} />
            )
            case 'cell_anomaly_rows':
                return (
                    <FormRowAnomalyDetection commonRule={commonRule}  setSpecific={setSpecific} editableRule={editableRule}
                        specific={specific} />
                )
        default:
            return (
                <FormRuleFormat commonRule={commonRule} 
                    setSpecific={setSpecific} editableRule={editableRule} specific={specific}  source={source} />
            )


    }
}