import React from 'react';
import EditAzureDialog from './EditatAzureDialog'
import EditSqlServerDialog from './EditSqlServerDialog'
import EditMySqlServerDialog from './EditMySqlServerDialog'
import EditBigQueryServerDialog from './EditBigQueryDialog';
import EditAWSServerDialog from './EditAWSDialog';
import EditHiveServerDialog from './EditHiveDialog';
import EditRedshiftServerDialog from './EditRedshiftDialog';
import SqlOnPremiseConnectionInfo from '../AddDataCredentialsDialog/ConnectionInfo/SqlOnPremiseConnectionInfo';
import EditOracleServerDialog from './EditOracleServerDialog';



export default function EditDataCredentialsDialog(props) {

    return (
        <>
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType && (props.selectedDataCredentials.storageType === "azure_container" || props.selectedDataCredentials.storageType === "azure_storage") && <EditAzureDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType &&  props.selectedDataCredentials.storageType === "sqlServer" && <EditSqlServerDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType &&  props.selectedDataCredentials.storageType === "mySQL" && <EditMySqlServerDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType &&  props.selectedDataCredentials.storageType === "bigQuery" && <EditBigQueryServerDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType &&  props.selectedDataCredentials.storageType === "aws" && <EditAWSServerDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType &&  props.selectedDataCredentials.storageType === "awsHive" && <EditHiveServerDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType &&  props.selectedDataCredentials.storageType === "awsRedshift" && <EditRedshiftServerDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
            {props.selectedDataCredentials && props.selectedDataCredentials.storageType &&  props.selectedDataCredentials.storageType === "oracle" && <EditOracleServerDialog data={props.data} setData={props.setData} showEditDialog={props.showEditDialog} setShowEditDialog={props.setShowEditDialog} selectedDataCredentials={props.selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={props.setSelectedDataCredentials} updateCounter={props.updateCounter} setUpdateCounter={props.setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />}
        </>
    )
}