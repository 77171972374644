import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


import {  Paper, Typography } from '@material-ui/core'

import * as commonStyles from 'style/CommonStyles'
import { ReactComponent as LocalFileIcon } from 'resources/icons/azure/File.svg';


const iconSize = '40px'

const width = '18%'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        width: width, minHeight: '100%' ,
        cursor: 'pointer',
        color: theme.palette.text.secondary,
    },
    selectedPaper: {
        width: width, minHeight: '100%' ,
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        borderColor: commonStyles.mainColor,
        borderWidth: '10px',
    },
    elementAdjacent: {
        display: 'flex',
        justifyContent: 'left'
    },
}));

export default function DataformatPaperResume(props) {

    const classes = useStyles();

    const [totalDF, setTotalDF] = React.useState(0)
    const [totalDFLocal, setTotalDFLocal] = React.useState(0)
    const [totalDFStorage, setTotalDFStorage] = React.useState(0)
    const [totalDFSqlDatabase, setTotalDFSqlDatabase] = React.useState(0)
    const [totalDFSynapse, setTotalDFSynapse] = React.useState(0)


/*
    function getDataformatFromId(id) {
        for (var i = 0; i < dataformats.length; i++) {
            if (dataformats[i]._id === id) {
                return dataformats[i]
            }
        }
        return undefined
    }
*/
    React.useEffect(() => {


        if (props.data ) {
            if (props.type === 'complete') {
                const d = props.data.map(x => {

                    return {
                        subject: x.dataformatName,
                        DQI: parseFloat(x.sumNumOk)/(parseFloat(x.sumNumIn))*100,
                        numIn: x.sumNumIn,
                        numOk: x.sumNumOk,
                       dataformatId : x.dataformatId,
                       dataformatType: x.dataformatType
                    }
                })
                setTotalDF(d.length)
                setTotalDFStorage(d.filter(x =>  x.dataformatType === 'azure-storage').length)
                setTotalDFSqlDatabase(d.filter(x =>  x.dataformatType === 'azure-sqldatabase').length)
                setTotalDFSynapse(d.filter(x => x.dataformatType === 'azure-sqlsynapse').length)
                setTotalDFLocal(d.filter(x => x.dataformatType!=='azure-storage' && x.dataformatType!=='azure-sqldatabase' && x.dataformatType!=='azure-sqlsynapse'
                ).length)


            }
        }
    }, [props.data])// eslint-disable-line react-hooks/exhaustive-deps



    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            width:'100%'
        }}>
            

            <Paper className={props.filterStorage === true ? classes.selectedPaper : classes.paper}
            elevation={props.filterStorage === true ? 12 : 1}
             onClick = {event=>{
                props.filterStorage === true ? props.setFilterStorage(false) : props.setFilterStorage(true)
            }
            }>
                <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <img alt="" style={{ height: iconSize, width: iconSize, margin: '15px' }}
                        src={require('resources/icons/azure/Storage-Accounts.png')} />
                    <div>
                        <Typography variant='h6' style={{color:'#000000'}}>Storage DataFormats</Typography>
                        <Typography variant='subtitle2' style={{color:'#000000'}}>{`${totalDFStorage}/${totalDF} dataformats governed`} </Typography>
                    </div>

                </div>
            </Paper>

            <Paper className={props.filterSqlDatabase === true ? classes.selectedPaper : classes.paper}
            elevation={props.filterSqlDatabase === true ? 12 : 1}
             onClick = {event=>{
                props.filterSqlDatabase === true ? props.setFilterSqlDatabase(false) : props.setFilterSqlDatabase(true)
            }
            }>
                <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <img alt="" style={{ height: iconSize, width: iconSize, margin: '15px' }}
                        src={require('resources/icons/azure/sql-database-generic.png')} />
                    <div>
                        <Typography variant='h6' style={{color:'#000000'}}>Sql Database DataFormats</Typography>
                        <Typography variant='subtitle2' style={{color:'#000000'}}>{`${totalDFSqlDatabase}/${totalDF} dataformats governed`} </Typography>
                    </div>

                </div>
            </Paper>

            <Paper className={props.filterSynapse === true ? classes.selectedPaper : classes.paper}
            elevation={props.filterSynapse === true ? 12 : 1}
             onClick = {event=>{
                props.filterSynapse === true ? props.setFilterSynapse(false) : props.setFilterSynapse(true)
            }
            }>
                <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <img alt="" style={{ height: iconSize, width: iconSize, margin: '15px' }}
                        src={require('resources/icons/azure/synapse.png')} />
                    <div>
                        <Typography variant='h6' style={{color:'#000000'}}>Synapse DataFormats</Typography>
                        <Typography variant='subtitle2' style={{color:'#000000'}}>{`${totalDFSynapse}/${totalDF} dataformats governed`} </Typography>
                    </div>

                </div>
            </Paper>

            <Paper className={props.filterLocal === true ? classes.selectedPaper : classes.paper}
            elevation={props.filterLocal === true ? 12 : 1}
             onClick = {event=>{
                props.filterLocal === true ? props.setFilterLocal(false) : props.setFilterLocal(true)
            }
            }>
                <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                            <LocalFileIcon fill={commonStyles.mainColor} style={{ height: iconSize, width: iconSize, margin: '15px' }}/>
                    
                    <div>
                        <Typography variant='h6' style={{color:'#000000'}}>Local DataFormats</Typography>
                        <Typography variant='subtitle2' style={{color:'#000000'}}>{`${totalDFLocal}/${totalDF} dataformats governed`} </Typography>
                    </div>

                </div>
            </Paper>
        </div>

    )


}
