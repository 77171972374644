import React from 'react';

import { makeStyles, TextField, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Grid, Typography, MenuItem, InputLabel, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { styles, QualityTheme } from '../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
import AqtivaDialogEncryptString from '../../../common/AqtivaDialogEncryptString'

import * as commonStyles from 'style/CommonStyles'


export default function DefaultSqlConnector(props) {


        const classes = makeStyles(styles(QualityTheme));

      

        const [sqlConnector, setSqlConnector] = React.useState(props.configuration && props.configuration.defaultSqlConnector ?
                props.configuration.defaultSqlConnector : {})

        const [visibleAzureSecretEncrypt, setVisibleAzureSecretEncrypt] = React.useState(false)



        React.useEffect(() => {


             
                setSqlConnector(props.configuration && props.configuration.defaultSqlConnector ?
                        props.configuration.defaultSqlConnector : {})


        }, [props.configuration, props.parentConfiguration]);

        React.useEffect(() => {
                props.setConfig(sqlConnector)
        }, [sqlConnector]);// eslint-disable-line react-hooks/exhaustive-deps

        function setValue(param, value) {
                var oldDatabaseConn =   Object.assign({}, sqlConnector);
                oldDatabaseConn[param] = value
                setSqlConnector(oldDatabaseConn)
        }


        return (
                <MuiThemeProvider theme={QualityTheme}>
                        <AqtivaDialogEncryptString visible={visibleAzureSecretEncrypt}
                                setVisible={setVisibleAzureSecretEncrypt}
                                confirm={(event) => { 
                                        console.log('event',event)
                                        setValue('pwd', event)
                                        setVisibleAzureSecretEncrypt(false)
                                 }}
                                sName={"defaultValueSqlConnectorPwd"}
                        />

                        <Accordion
                                style={{ width: '100%' }}>
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Default SQL Connector Properties</Typography>
                                        </Grid>

                                </AccordionSummary>
                                <AccordionDetails>
                                        <div>
                                        <div style={{ width: '100%', minWidth: '800px' }}>
                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                        control={
                                                        <Select
                                                                value={sqlConnector? sqlConnector.databaseType: ''}
                                                                onChange={(event) => setValue('databaseType', event.target.value)}
                                                                inputProps={{
                                                                name: 'Database Type',
                                                                id: 'databaseType',
                                                                }}
                                                                style={{ width: '200px', marginRight: '20px' }}
                                                        >
                                                                
                                                        <MenuItem value={'postgresql'}>PostgreSQL</MenuItem>
                                                        

                                                        </Select>
                                                        } />
                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                         control={
                                                        <TextField
                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                id="sqlServer"
                                                                label="Server"
                                                                className={classNames(classes.textField, classes.dense)}
                                                                margin="dense"
                                                                value={sqlConnector? sqlConnector.server: ''}
                                                                onChange={(event) => setValue('server', event.target.value)}
                                                        />} />
                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                        control={
                                                        <TextField
                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                id="sqlConnectorDatabase"
                                                                label="Database"
                                                                className={classNames(classes.textField, classes.dense)}
                                                                margin="dense"
                                                                value={sqlConnector? sqlConnector.database: ''}
                                                                onChange={(event) => setValue('database',  event.target.value)}
                                                        />} />

                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                        <TextField
                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                id="sqlConnectorPort"
                                                                label="Port"
                                                                className={classNames(classes.textField, classes.dense)}
                                                                margin="dense"
                                                                value={sqlConnector ? sqlConnector.port: ''}
                                                                onChange={(event) => setValue('port',  event.target.value)}
                                                        />} />
                                                 <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                        control={
                                                        <TextField
                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                id="sqlConnectorUser"
                                                                label="User"
                                                                className={classNames(classes.textField, classes.dense)}
                                                                margin="dense"
                                                                value={sqlConnector? sqlConnector.user: ''}
                                                                onChange={(event) => setValue('user',  event.target.value)}
                                                        />} />

                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                         control={
                                                                <TextField style={{ width: '100%' }}
                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                id="sqlConnectorPwd"
                                                                label="Password"
                                                                className={classNames(classes.textField, classes.dense)}
                                                                margin="dense"
                                                                type="password"
                                                                value={sqlConnector? sqlConnector.pwd: ''}
                                                                onClick={(event) => { setVisibleAzureSecretEncrypt(true) }}
                                                                />} />


                                                </div>
                                        </div>



                                </AccordionDetails>
                        </Accordion>
                </MuiThemeProvider>
        )
}