import React from 'react';
import { Grid, Typography, AccordionSummary, AccordionDetails, Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import TagComponent from '../TagsComponent';

import * as commonStyles from 'style/CommonStyles'
import { LanguageContext } from 'language/LanguageContext'

export default function TagsConfigurationQuality(props) {
    const languageContext = React.useContext(LanguageContext); 


    return (
        <>
            <Accordion
                style={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid item xs={1}>
                        <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='subtitle2'>{languageContext.dictionary['tagsConfiguration']}</Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: "column", justifyContent: "space-around" }}>
                    <TagComponent qualityElement={props.qualityElement}
                        elementType={props.elementType}
                        setMandatoryTags={props.setMandatoryTags}
                        setOptionalTags={props.setOptionalTags}
                        mandatoryErrorTags={props.mandatoryErrorTags}
                        parent={props.parent}
                        intermediateParent={props.intermediateParent}
                        mainParent={props.mainParent} />
                </AccordionDetails>
            </Accordion >
        </>
    )
}