import React from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { Typography ,InputAdornment} from '@material-ui/core'

import CustomInput from "../../../common/CustomInput/CustomInput.js";

import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';
import { useTheme } from '@material-ui/core/styles';



export default function ProjectNewNameDialog(props) {

    const theme = useTheme();

    const [ name, setName ] = React.useState()


    React.useEffect(() => {
        loadCSS(
          'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
      }, []);




    const onChangeTextField = (name) => (event) => {

        if(name === 'name') setName(event.target.value)
       
    }

    const handleClose = () => {
        props.cancelAction()
    }
    const handleOk = () => {
        props.confirmAction(name)
    }
   
    return (
        <div>
            <Dialog open={props.open} >
                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <IconButton disabled={true} size='small' style={theme.dialogs.dialogTitleAwesomeIcon}>
                                <AddIcon style={{color: '#474b51'}}/>
                            </IconButton>
                        </Grid>
                        <Grid item style={{marginTop: '1%'}}>
    <Typography style={theme.dialogs.dialogTitleText}>
       Set New Name</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container style={{marginTop: '2%'}}>
                        <Grid item xs={7}>
                            <CustomInput
                            labelText="Project Name"
                            id="ProjectName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            
                            inputProps={{
                                defaultValue: name,
                                
                                type: "text",
                                onChange: onChangeTextField('name'),
                                endAdornment: (
                                <InputAdornment position="end">
                                   <Icon className="fas fa-signature" />
                                </InputAdornment>
                                )
                            }}
                            />
                        </Grid>

                       
                    </Grid>
                  
                </DialogContent>
                <DialogActions>
                    <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}  onClick={handleOk}>Confirm</Button>
                    <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel}  onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
