import React from 'react';
import AppBarTemplate from 'components/template/AppBarTemplate';
import FilterComponent from './FilterComponent'
import { LanguageContext } from 'language/LanguageContext';
import { useSelector, useDispatch } from 'react-redux'

//import * as actions from 'actions'
import * as pathHelper from 'components/template/PathHelper'
import DashboardTabComponent from './DashboardTabComponent'
import { useHistory } from 'react-router-dom'
import * as actions from 'actions'
import * as helper from './helper'

import { onlyUnique } from 'commonFunctions/commonFunctions'

export default function AqtivaDashboardDrillDownPage(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
   // const projects = useSelector(store => Object.values(store.projects))

    const languageContext = React.useContext(LanguageContext)
    const [filterObj, setFilterObj] = React.useState(undefined)
    //const [projectIdExecutionsEnrich, setProjectIdExecutionsEnrich] = React.useState([])
    const projectIdExecutionsEnrich = []
    //const [projectsLicense, setProjectsLicense] = React.useState([])
    //const [projectIdExecutions, setProjectIdExecutions] = React.useState([])
    //const [tabValue, setTabValue] = React.useState(2)






    //new states
    const [DQIByDimension, setDQIByDimension] = React.useState([])
    const [globalDQI, setGlobalDQI] = React.useState(undefined)
    //const [globalExecutions, setGlobalExecutions] = React.useState(0)
    //const [globalDefinitions, setGlobalDefinitions] = React.useState(0)

    const [executionsByDimension, setExecutionsByDimension] = React.useState([])
    const [executionsByRuleType, setExecutionsByRuleType] = React.useState([])

    const [definitionsByDimension, setDefinitionsByDimension] = React.useState([])
    const [definitionsByRuleType, setDefinitionsByRuleType] = React.useState([])

    const [DQIAndExecutions4RuleTypeAndDimension, setDQIAndExecutions4RuleTypeAndDimension] = React.useState([])
    //const [DQIByDay, setDQIByDay] = React.useState([])

    //const [DQI4Dataformat, setDQI4Dataformat] = React.useState([])
    //const [DQI4DataformatAndDimension, setDQI4DataformatAndDimension] = React.useState([])



    const [DQI4Project, setDQI4Project] = React.useState([])
    const [DQI4Project5First, setDQI4Project5First] = React.useState([])
    const [DQI4QP, setDQI4QP] = React.useState([])


    const [DQI4ProjectFlag, setDQI4ProjectFlag] = React.useState(true)
    const [DQI4Project5FirstFlag, setDQI4Project5FirstFlag] = React.useState(true)
    const [DQI4QPFlag, setDQI4QPFlag] = React.useState(true)

    //flags of data loading
    const [dQI4DimensionsFlag, setDQI4DimensionsFlag] = React.useState(true)
    //const [dQI4DataformatsFlag, setDQI4DataformatsFlag] = React.useState(true)
    //const [dQI4DataformatsAndDimensionsFlag, setDQI4DataformatsAndDimensionsFlag] = React.useState(true)
    const [executions4DimensionsFlag, setExecutions4DimensionsFlag] = React.useState(true)
    const [definitions4DimensionsFlag, setDefinitions4DimensionsFlag] = React.useState(true)
    const [executions4RuleTypeFlag, setExecutions4RuleTypeFlag] = React.useState(true)
    const [definitions4RuleTypeFlag, setDefinitions4RuleTypeFlag] = React.useState(true)
    const [executionsAndDQI4RuleTypeAndDimensionFlag, setExecutionsAndDQI4RuleTypeAndDimensionFlag] = React.useState(true)
    //const [dQIByDayFlag, setDQIByDayFlag] = React.useState(true)




    React.useEffect(() => {
        getUser()
        fetchData()
    }, [JSON.stringify(filterObj)])// eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchProjects(user.id))
            dispatch(actions.fetchAllDataFormats(user))
            if (license && license._id) {
                getLicenseProjects()
                /*.then(response => {
                    setProjectsLicense(response)
                }
                )*/

            }
            else {
                dispatch(actions.fetchLicense((user).id))
            }

        }

    }, [user.id, license._id])// eslint-disable-line react-hooks/exhaustive-deps


   /* React.useEffect(() => {
        if (projectIdExecutions !== undefined) {
            var projectsEnriched = []
            const allProjects = projects.concat(projectsLicense)
            for (var i = 0; i < projectIdExecutions.length; i++) {
                var p = JSON.parse(JSON.stringify(projectIdExecutions[i]))
                p.project = getProjectFromId(p.projectId, allProjects)
                projectsEnriched.push(p)
            }
            setProjectIdExecutionsEnrich(projectsEnriched)
        }

    }, [projectIdExecutions, projectsLicense])// eslint-disable-line react-hooks/exhaustive-deps
    */


    async function getLicenseProjects() {
        var projects = []
        if (license && license._id && license.users && license.users.length > 0) {
            for (var i = 0; i < license.users.length; i++) {
                let licenseUser = license.users[i]
                if (user.id !== licenseUser.id) { //only other users in license
                    var userProjects = await dispatch(actions.fetchProjectsAndReturn(licenseUser.id))
                    projects = projects.concat(userProjects)
                }
            }
        }

        return projects
    }


/*
    function getProjectFromId(projectId, projects) {
        const project = projects.filter(x => x._id === projectId)
        if (project && project.length > 0) {
            return project[0]
        }
        return undefined
    }*/

    function getUser() {
        dispatch(actions.fetchUser(history))
    }



    /*function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }*/

    function getRuleTypesData(rawRuleData, field) {
        if (rawRuleData !== undefined && rawRuleData.length > 0) {
            const ruleTypes = rawRuleData.filter(x => x.ruleType).map(x => x.ruleType).filter(onlyUnique)
            const sum = rawRuleData.map(x => x[field]).reduce((partialSum, a) => partialSum + a, 0);
            const fullMarkValue = sum

            const dataArray = []
            for (var i = 0; i < ruleTypes.length; i++) {
                const ruleType = ruleTypes[i]
                const data = {
                    subject: ruleType,
                    value: rawRuleData.filter(x => x.ruleType === ruleType)[0][field],
                    fullMark: fullMarkValue,
                }
                dataArray.push(data)
            }
            return dataArray
        }

        return []

    }




    async function fetchData() {
        if (filterObj && filterObj.startDate && filterObj.endDate) {

            setDQI4DimensionsFlag(true)
            //setDQI4DataformatsFlag(true)
            //setDQI4DataformatsAndDimensionsFlag(true)
            setExecutions4DimensionsFlag(true)
            setDefinitions4DimensionsFlag(true)
            setExecutions4RuleTypeFlag(true)
            setDefinitions4RuleTypeFlag(true)
            setExecutionsAndDQI4RuleTypeAndDimensionFlag(true)
            //setDQIByDayFlag(true)
            setDQI4ProjectFlag(true)
            setDQI4Project5FirstFlag(true)
            dispatch(actions.getDQI4Dimensions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                const value = helper.getGlobalDQI(response.data)
                setGlobalDQI(value)
                setDQIByDimension(response.data)
                setDQI4DimensionsFlag(false)

            })

           /* dispatch(actions.getDQI4Dataformats(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setDQI4Dataformat(response.data)
                setDQI4DataformatsFlag(false)

            })*/

            /*dispatch(actions.getDQI4DataformatsAndDimensions(filterObj)).then(response => {
                //get global DQI and 4 dimensions
                setDQI4DataformatAndDimension(response.data)
                setDQI4DataformatsAndDimensionsFlag(false)

            })*/

            dispatch(actions.getExecutions4Dimensions(filterObj)).then(response => {
                //get global DQI
                //const value = helper.getGlobalExecutions(response.data)
                //setGlobalExecutions(value)
                setExecutionsByDimension(response.data)
                setExecutions4DimensionsFlag(false)

            })

            dispatch(actions.getDefinitions4Dimensions(filterObj)).then(response => {
                //const value = helper.getGlobalDefinitions(response.data)
                //setGlobalDefinitions(value)
                setDefinitionsByDimension(response.data)
                setDefinitions4DimensionsFlag(false)
            })

            dispatch(actions.getExecutions4RuleType(filterObj)).then(response => {
                //get global DQI
                setExecutionsByRuleType(getRuleTypesData(response.data, 'executions'))
                setExecutions4RuleTypeFlag(false)
            })

            dispatch(actions.getDefinitions4RuleType(filterObj)).then(response => {
                //get global DQI
                setDefinitionsByRuleType(getRuleTypesData(response.data, 'definitions'))
                setDefinitions4RuleTypeFlag(false)
            })

            dispatch(actions.getExecutionsAndDQI4RuleTypeAndDimension(filterObj)).then(response => {
                //get global DQI
                setDQIAndExecutions4RuleTypeAndDimension(response.data)
                setExecutionsAndDQI4RuleTypeAndDimensionFlag(false)

            })

            /*dispatch(actions.getDQIByDay(filterObj)).then(response => {
                //get global DQI
                setDQIByDay(response.data)
                setDQIByDayFlag(false)

            })*/

            dispatch(actions.getDQI4Projects(filterObj)).then(response => {
                setDQI4Project(response.data)
                

                setDQI4ProjectFlag(false)
            })

            dispatch(actions.getDQI4ProjectsMostRecent(filterObj)).then(response => {
                setDQI4Project5First(response.data)
                setDQI4Project5FirstFlag(false)
            })
            

            dispatch(actions.getDQI4QualityPoints(filterObj)).then(response => {
                setDQI4QP(response.data)
                setDQI4QPFlag(false)
            })





            /* dispatch(actions.getRuleRawRecords(filterObj.startDate, filterObj.endDate)).then(
                  resultsRule =>{
                     
                      setBaseRawRuleData(resultsRule.data)
                      //setRawRuleData(resultsRule.data)
                      
                  }
              )
      
              dispatch(actions.getDSRawRecords(filterObj.startDate, filterObj.endDate)).then(
                  resultsDS =>{
                      
                          setBaseRawDSData(resultsDS.data)
                          //setRawDSData(resultsDS.data)
      
                      
                  }
              )
      
      
              dispatch(actions.getQualityPointRawRecords(filterObj.startDate, filterObj.endDate)).then(
                  resultsQP =>{
                      setBaseRawQPData(resultsQP.data)
                      //setRawQPData(resultsQP.data)
      
                      //setRawQPData(resultsQP.data)
      
                     
                  }
              )*/


        }
    }


    return (
        <div >
            <AppBarTemplate
                history={props.history}
                title={languageContext.dictionary['home']}
                intervalNotifications={false}
                arrayPath={pathHelper.getPathArray(languageContext.dictionary['home'])}
            />
            <FilterComponent setFilterObj={setFilterObj}
                projects={projectIdExecutionsEnrich}>
                <DashboardTabComponent
                    filterObj={filterObj}
                    DQI4Project={DQI4Project}
                    DQI4Project5First={DQI4Project5First}
                    DQIByDimension={DQIByDimension}
                    executionsByDimension={executionsByDimension}
                    executionsByRuleType={executionsByRuleType}
                    definitionsByDimension={definitionsByDimension}
                    definitionsByRuleType={definitionsByRuleType}
                    DQIAndExecutions4RuleTypeAndDimension={DQIAndExecutions4RuleTypeAndDimension}
                    globalDQI={globalDQI}
                    DQI4QP={DQI4QP}

                    DQI4ProjectFlag={DQI4ProjectFlag}
                    DQI4Project5FirstFlag={DQI4Project5FirstFlag}
                    DQIByDimensionFlag={dQI4DimensionsFlag}
                    executionsByDimensionFlag={executions4DimensionsFlag}
                    executionsByRuleTypeFlag={executions4RuleTypeFlag}
                    definitionsByDimensionFlag={definitions4DimensionsFlag}
                    definitionsByRuleTypeFlag={definitions4RuleTypeFlag}
                    DQIAndExecutions4RuleTypeAndDimensionFlag={executionsAndDQI4RuleTypeAndDimensionFlag}
                    DQI4QPFlag={DQI4QPFlag}


                    

                />
            </FilterComponent>

        </div>

    );
}





