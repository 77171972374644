import React from 'react';
import { faSearchPlus, faIdBadge } from '@fortawesome/free-solid-svg-icons'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


import AssignmentIcon from '@material-ui/icons/Assignment';
import { CircularProgress,TextField } from '@material-ui/core';
import { StyledMenu, StyledMenuItem } from "components/common/CustomizedMenu";
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dispatchInfo } from 'components/common/axios/axiosHelper';
import * as commonStyles from 'style/CommonStyles'
import * as treeViewJdbcHelper from '../treeViews/helpers/TreeViewJdbcHelper'
import AqtivaDialog from 'components/common/AqtivaDialog'

import { faKey } from '@fortawesome/free-solid-svg-icons'


const useStyles = makeStyles((theme) => ({
    root: {
        height: 240,
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        height: 380,
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialState = {
    mouseX: null,
    mouseY: null,
};

 

export default function TreeViewJdbcBasedDatabase(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))

    const [credentials, setCredentials] = React.useState([])


    const [selected, setSelected] = React.useState([])
    const [expanded, setExpanded] = React.useState([]);

    const [state, setState] = React.useState(initialState);


    const [selectedDatabase, setSelectedDatabase] = React.useState('')
    const [selectedTable, setSelectedTable] = React.useState('')
    const [selectedCredentials, setSelectedCredentials] = React.useState('')
    const [existantDataformats, setExistantDataformats] = React.useState([])

    const [notEnvironmentFlag, setNotEnvironmentFlag] = React.useState(false)

    /** variables for preview with query */
    const [previewWithQueryFlag, setPreviewWithQueryFlag] = React.useState(false)
    const [previewWithQueryCredId, setPreviewWithQueryCredId] = React.useState(undefined)
    const [previewWithQueryDatabase, setPreviewWithQueryDatabase] = React.useState(undefined)
    const [previewWithQueryTable, setPreviewWithQueryTable] = React.useState(undefined)
    



    React.useEffect(() => {
        if (props.selectedEnvironment) {
            let credentials = getCredentialsFromEnvironmentConfig(props.selectedEnvironment, configuration)
            console.log('credentials', credentials)
            if (credentials) {
                setCredentials(credentials)
            }

        }
    }, [user, props.connectToLocalStorages, props.selectedEnvironment && props.selectedEnvironment.environmentName, configuration]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getDatabases()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getHiveDataformats(user)
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps


    async function getHiveDataformats(user) {
        const dataformats = await dispatch(actions.getHiveOnpremiseDataformats(user.id, props.type))

        setExistantDataformats(dataformats)
    }

    function getCredentialsFromEnvironmentConfig(selectedEnvironment, configuration) {
        console.log('getCredentialsFromEnvironmentConfig', selectedEnvironment, configuration)
        let masterConfig = JSON.parse(JSON.stringify(configuration))

        if (masterConfig.environmentConfigurationList && masterConfig.environmentConfigurationList.length > 0) {
            let selectedEnvironmentConfiguration = masterConfig.environmentConfigurationList.filter(envConf => envConf.environmentName === selectedEnvironment)[0]
            let credentials = []
            if (selectedEnvironmentConfiguration && selectedEnvironmentConfiguration.enabledDataStorageCredentials
                && selectedEnvironmentConfiguration.enabledDataStorageCredentials.length > 0) {
                //console.log('selectedEnvironmentConfiguration.enabledDataStorageCredentials', selectedEnvironmentConfiguration.enabledDataStorageCredentials)
                credentials = selectedEnvironmentConfiguration.enabledDataStorageCredentials
            }
            credentials = checkIfUserCanAccessCredentials(user, credentials)
            credentials = getCredentialsByType(credentials)
            return credentials
        }
    }


    //here we check if credentials are public or protected and if the user is allowed to access them
    function checkIfUserCanAccessCredentials(user, credentials) {
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].accessType && credentials[i].accessType === "public_access") {
                usableCredentials.push(credentials[i])
            }
            if (credentials[i].accessType && credentials[i].accessType === "protected_access" && credentials[i].allowedUsers) {
                let userIsAllowed = credentials[i].allowedUsers.filter(allowedUser => allowedUser.id === user.id).length > 0
                if (userIsAllowed) {
                    usableCredentials.push(credentials[i])
                }
            }
        }
        if (usableCredentials.length === 0) setNotEnvironmentFlag(true)
        else setNotEnvironmentFlag(false)
        return usableCredentials
    }
    function getCredentialsByType(credentials) {
        console.log('getCredentialsByType',credentials,props.storageType)
        let usableCredentials = []
        for (let i = 0; i < credentials.length; i++) {
            if (credentials[i].storageType && (credentials[i].storageType === props.storageType)) {
                usableCredentials.push(credentials[i])
            }
        }
        if (usableCredentials.length === 0) setNotEnvironmentFlag(true)
        else setNotEnvironmentFlag(false)

        return usableCredentials
    }


    const handleClick = (event, credential, database, table) => {

        setSelectedDatabase(database)
        setSelectedTable(table)
        setSelectedCredentials(credentials.filter(cr => cr.id === credential.id)[0].id)

        event.preventDefault();
        props.setFileOutputName(database.name + '_' + table.name)
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    async function getDatabases(credential) {
        if (credential) {

            if (props.type === 'oracle') {

                setCredentials(
                    credentials.map(c => {
                        if (c.id === credential.id){
                            c = {
                                ...c,
                                'databases': [{ name: (credential.oracleStorageCredentialsDTO && credential.oracleStorageCredentialsDTO.database ) ?  credential.oracleStorageCredentialsDTO.database : "default" }]
                            }
                        }
                        
                        
                        return c
                    })
                )

            } else {

                let result = []

                let aqtivaConnector = treeViewJdbcHelper.getAqtivaConector(credential, props.storageType)
                console.log('conector', aqtivaConnector)

                result = await dispatch(actions.listHiveDatabases2(aqtivaConnector, props.type))

                if (result) {
                    //cred.databases = result
                    setCredentials(
                        credentials.map(c => {
                            if (c.id === credential.id) c = {
                                ...c,
                                'databases': result.map(r => { return { name: r } })
                            }
                            return c
                        })
                    )
                }
            }
        }
    }

    async function getTables(credential, database, schema) {

        if (credential) {
            let result = []

            let aqtivaConnector = treeViewJdbcHelper.getAqtivaConector(credential, props.storageType)

            result = await dispatch(actions.listHiveTables2(aqtivaConnector, props.type, database.name, schema.name))

            if (result) {
                setCredentials(
                    credentials.map(c => {
                        if (c.id === credential.id) {
                            c.databases = c.databases.map(db => {
                                if (db.name === database.name) {
                                    db.schemas = db.schemas.map(sch => {
                                        if (sch.name === schema.name) {
                                            sch = {
                                                ...sch,
                                                'tables': result.tables.map(r => { return { name: r } })
                                            }
                                        }
                                        return sch
                                    })
                                }
                                return db
                            })
                        }
                        return c
                    })
                )
            }
        }
    }

    async function getSchemas(credential, database) {

        if (credential) {

            if (props.type === 'mysql' || props.type === 'big_query') {

                setCredentials(
                    credentials.map(c => {
                        if (c.id === credential.id) {
                            c.databases = c.databases.map(db => {
                                if (db === database) {
                                    db = {
                                        ...db,
                                        'schemas': [{ name: database.name }]
                                    }
                                }
                                return db
                            })
                        }
                        return c
                    })
                )

            } else {

                let result = []

                let aqtivaConnector = treeViewJdbcHelper.getAqtivaConector(credential, props.storageType)

                result = await dispatch(actions.listHiveSchemas(aqtivaConnector, props.type, database.name))

                if (result) {
                    setCredentials(
                        credentials.map(c => {
                            if (c.id === credential.id) {
                                c.databases = c.databases.map(db => {
                                    if (db === database) {
                                        db = {
                                            ...db,
                                            'schemas': result.map(r => { return { name: r.name } })
                                        }
                                    }
                                    return db
                                })
                            }
                            return c
                        })
                    )
                }
            }
        }
    }


    async function handlePreview(credentialId, database, table) {

        const cred = credentials.filter(cr => cr.id === credentialId)[0]
        const dto = treeViewJdbcHelper.getPreviewDTO(cred, database, table, props.storageType)

        props.setSelectedItemDTO(dto)
        handleClose()
        props.setVisiblePreview(true)

        createPreviewJob(dto)
    }

     function handlePreviewWithQuery(credentialId, database, table) {
        setPreviewWithQueryFlag(true)
        cleanPreviewWithQuery()
        
        setPreviewWithQueryCredId(credentialId)
        setPreviewWithQueryDatabase(database)
        setPreviewWithQueryTable(table)
        
    }

    async function executePreviewWithQuery() {
        
        if(validatePreviewWithQuery()=== false){
            return
        }

        const cred = credentials.filter(cr => cr.id === previewWithQueryCredId)[0]
        const dto = treeViewJdbcHelper.getPreviewDTO(cred, previewWithQueryDatabase, previewWithQueryTable, props.storageType, 
            props.query2Preview, props.query2PreviewName)

        props.setSelectedItemDTO(dto)
        handleClose()
        props.setVisiblePreview(true)

        createPreviewJob(dto)
        setPreviewWithQueryFlag(false)
    }

     function cleanPreviewWithQuery(){
        setPreviewWithQueryCredId(undefined)
        setPreviewWithQueryDatabase(undefined)
        setPreviewWithQueryTable(undefined)
       
        props.setQuery2Preview('')
        props.setQuery2PreviewName('')
    }

    function validatePreviewWithQuery(){
        console.log('validate', props.query2Preview, props.query2PreviewName)
        if(props.query2Preview!== undefined && props.query2Preview.length > 0 &&
            props.query2PreviewName!== undefined && props.query2PreviewName.length > 0){
            return true
        }
        return false
    }


    async function createPreviewJob(dto) {

        switch (props.storageType) {
            case 'awsHive':
                dispatch(actions.createPreviewDataJobHive(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
                    if (result && result.run_id) {

                        props.setRunId(result.run_id)
                    }

                })
                break;
            case 'bigQuery':
                dispatch(actions.createPreviewDataJobBigQuery(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
                    if (result && result.run_id) {

                        props.setRunId(result.run_id)
                    }

                })
                break;

            case 'sqlServer':
                dispatch(actions.createPreviewDataJobSQLOnpremDatabase(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
                    if (result && result.run_id) {

                        props.setRunId(result.run_id)
                    }

                })
                break;

            case 'azure-sqlsynapse':
                dispatch(actions.createPreviewDataJobSQLOnpremDatabase(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
                    if (result && result.run_id) {

                        props.setRunId(result.run_id)
                    }

                })
                break;


            case 'mySQL':
                dispatch(actions.createPreviewDataJobSQLOnpremDatabase(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
                    if (result && result.run_id) {

                        props.setRunId(result.run_id)
                    }

                })
                break;
            case 'oracle':
                dispatch(actions.createPreviewDataJobSQLOnpremDatabase(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
                    if (result && result.run_id) {

                        props.setRunId(result.run_id)
                    }

                })
                break;
            case 'awsRedshift':
                dispatch(actions.createPreviewDataJobRedshift(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {
                    if (result && result.run_id) {

                        props.setRunId(result.run_id)
                    }

                })
                break;
        }
    }

    async function handleProfiling(credentialId, database, table) {

        props.setPreviewVisible(false)
        props.resetProfilingState()

        const cred = credentials.filter(cr => cr.id === credentialId)[0]

        const dto = treeViewJdbcHelper.getPreviewDTO(cred, database, table, props.storageType, props.query2Preview)

        handleClose()
        dispatchInfo(dispatch, "Checking Storage Credential access by Aqtiva")
        createProfilingJob(dto)
    }

    async function createProfilingJob(dto) {

        switch (props.storageType) {
            case 'awsHive':
                await dispatch(actions.createProfilingDataJobHive(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

                    if (result && result.dataformatId) {
                        props.setProfilingdataformatId(result.dataformatId)
                    }
                    if (result && result.dto?.run_id){
                        props.setRunId(result.dto?.run_idƒ)
                    }
                })
                break;
            case 'bigQuery':
                await dispatch(actions.createProfilingDataJobBigQuery(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

                    if (result && result.dataformatId) {
                        props.setProfilingdataformatId(result.dataformatId)
                    }
                    if (result && result.dto?.run_id){
                        props.setRunId(result.dto?.run_idƒ)
                    }
                })
                break;

            case 'sqlServer':
                await dispatch(actions.addProfilingToOnpremSQLDataformat(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

                    if (result && result.dataformatId) {
                        props.setProfilingdataformatId(result.dataformatId)
                    }
                    if (result && result.dto?.run_id){
                        props.setRunId(result.dto?.run_idƒ)
                    }
                })
                break;

            case 'mySQL':
                await dispatch(actions.addProfilingToOnpremSQLDataformat(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

                    if (result && result.dataformatId) {
                        props.setProfilingdataformatId(result.dataformatId)
                    }
                    if (result && result.dto?.run_id){
                        props.setRunId(result.dto?.run_idƒ)
                    }
                })
                break;

            case 'awsRedshift':
                await dispatch(actions.createProfilingDataJobRedshift(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

                    if (result && result.dataformatId) {
                        props.setProfilingdataformatId(result.dataformatId)
                    }

                })
                break;

            case 'oracle':
                await dispatch(actions.addProfilingToOnpremSQLDataformat(props.selectedEnvironment, user.id, dto, props.numberSamples, props.flattenFlag)).then(result => {

                    if (result && result.dataformatId) {
                        props.setProfilingdataformatId(result.dataformatId)
                    }
                    if (result && result.dto?.run_id){
                        props.setRunId(result.dto?.run_idƒ)
                    }
                })
                break;
        }
    }


    function createMenuSchemas(credential, database, schema) {
        return (
            <TreeItem nodeId={schema.name + '_schema'} key={schema.name + '_schema'} label={
                <div style={{
                    marginRight: '5px', display: 'flex',
                    justifyContent: 'left',
                }}>
                    {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                    {treeViewJdbcHelper.getIconsSchema(props.storageType)}
                    {props.type === 'mysql' ?
                        <Typography variant='subtitle2'>Default</Typography>
                        :
                        <Typography variant='subtitle2'>{`${schema.name}`}</Typography>
                    }
                </div>}
                onClick={event => getTables(credential, database, schema)}>


                {schema === undefined || schema === null || schema.tables === undefined ?
                    <TreeItem nodeId={`empty`} key={`empty`} label={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>
                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                            <Typography variant='subtitle2'>Loading tables, it may take some seconds...</Typography>
                        </div>
                    } /> :
                    schema.tables && schema.tables.length > 0 && schema.tables.map(table => {
                        return (
                            createMenuItemDataset(credential, schema, table, database)
                        )
                    })
                }
            </TreeItem>
        )
    }

    function createMenuItemDataset(credential, schema, tableSelected, database) {
        const tableName = tableSelected.name
        let table = schema.tables.filter(tb => tb.name === tableName)[0]

        return (
            <div>
            

                <TreeItem nodeId={tableName} key={tableName}
                    onContextMenu={event => handleClick(event, credential, schema, table)}
                    label={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                        }}>
                            {props.alreadyExistantDataformat(credential, schema, table, database) === false &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    {treeViewJdbcHelper.getIconsTables(props.storageType)}
                                    <Typography variant='caption' >{tableName}</Typography>
                                </div>
                            }
                            {props.alreadyExistantDataformat(credential, schema, table, database) === true &&
                                <Tooltip title={`Source already registered in Aqtiva`}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}>
                                        <img alt="" style={{ height: '15px', width: '15px' }}
                                            src={require('resources/icons/favicon-32x32.jpg')} />
                                        <Typography variant='caption' style={({ color: commonStyles.mainColor })}>{tableName}</Typography>

                                    </div>
                                </Tooltip>
                            }


                        </div>
                    }>

                </TreeItem>
                <StyledMenu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    <StyledMenuItem onClick={() => handlePreview(selectedCredentials, selectedDatabase, selectedTable)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Preview" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handlePreviewWithQuery(selectedCredentials, selectedDatabase, selectedTable)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Preview with query" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handleProfiling(selectedCredentials, selectedDatabase, selectedTable)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 14, marginLeft: '15%' }} />
                        </ListItemIcon>
                        <ListItemText primary="Create Profiling" />
                    </StyledMenuItem>

                </StyledMenu>
            </div>
        )

    }



    return (

        <div className={classes.root}>
            {(credentials === undefined || credentials === null || credentials.length === 0) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>

                    {/*!notEnvironmentFlag ?
                        <>
                            <CircularProgress style={{ width: '25px', height: '25px' }} />
                            <Typography variant='subtitle1'>Loading ...</Typography>
                        </>
                        : <Typography variant='subtitle2'>Not enabled buckets in this environment</Typography>
            */}
                </div>
            }
              {previewWithQueryFlag === true && 
                <AqtivaDialog visible={previewWithQueryFlag}
                title={`Enter query to preview`}
                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={true}
                confirmCallback={executePreviewWithQuery}
                cancelCallback={event=>setPreviewWithQueryFlag(false)}
            >
                <div style={{width:'220px'}}>
                <TextField
                        style={{fontSize:'2px', width:'200px'}}
                        id="date"
                        label="Dataformat Name"
                        defaultValue={props.query2PreviewName}
                        onChange={e => props.setQuery2PreviewName(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                </div>
                <div style={{width:'550px'}}>
                <TextField
                         style={{fontSize:'2px', width:'540px'}}
                        id="date"
                        label="Query to Preview"
                        defaultValue={props.query2Preview}
                        onChange={e => props.setQuery2Preview(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                </div>
                
                
               
              </AqtivaDialog>

                }
            {credentials !== undefined && credentials !== null && credentials.length > 0 &&
                <TreeView
                    className={classes.rootView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {credentials.map(credential => {
                        return credential !== undefined || credential !== null ?
                            <TreeItem nodeId={credential.id} key={credential.id} label={
                                <div style={{
                                    marginRight: '5px', display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                                    {treeViewJdbcHelper.getIconsCredentials(props.storageType)}
                                    <Typography variant='subtitle2'>{`${credential.displayName}`}</Typography>
                                </div>}
                                onClick={event => getDatabases(credential)}>


                                {credential === undefined || credential === null || credential.databases === undefined ?
                                    <TreeItem nodeId={`empty`} key={`empty`} label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                        }}>
                                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                                            <Typography variant='subtitle2'>Loading databases, it may take some seconds...</Typography>
                                        </div>
                                    } /> :
                                    credential.databases && credential.databases.length > 0 && credential.databases.map(database => {
                                        return database !== undefined || database !== null ?
                                            <TreeItem nodeId={database.name} key={database.name} label={
                                                <div style={{
                                                    marginRight: '5px', display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    {/*  <FontAwesomeIcon icon={faServer} style={{ fontSize: 18, marginLeft: '2%',marginRight: '2%' }} /> */}
                                                    {treeViewJdbcHelper.getIconsDatabases(props.storageType)}
                                                    <Typography variant='subtitle2'>{`${database.name}`}</Typography>
                                                </div>}
                                                onClick={event => getSchemas(credential, database)}>

                                                {database === undefined || database === null || database.schemas === undefined ?
                                                    <TreeItem nodeId={`empty`} key={`empty`} label={
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'left',
                                                        }}>
                                                            <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                            <Typography variant='subtitle2'>Loading schemas, it may take some seconds...</Typography>
                                                        </div>
                                                    } /> :
                                                    database.schemas && database.schemas.length > 0 && database.schemas.map(schema => {
                                                        return (
                                                            createMenuSchemas(credential, database, schema)
                                                        )
                                                    })
                                                }
                                            </TreeItem>
                                            :
                                            <TreeItem nodeId={`empty`} key={`empty`} label={
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}>
                                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                                    <Typography variant='subtitle2'>Loading, it may take some seconds...</Typography>
                                                </div>
                                            } />
                                    })
                                }
                            </TreeItem>
                            : <TreeItem nodeId={`empty`} key={`empty`} label={
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                    <Typography variant='subtitle2'>Loading, it may take some seconds...</Typography>
                                </div>
                            } />
                    })}

                </TreeView>
            }
        </div >

    )
}