import React from 'react';
//import AqtivaAppBar from './../components/template/AqtivaAppBar'
import * as pathHelper from './../components/template/PathHelper'
import AppBarTemplate from 'components/template/AppBarTemplate'


import ReactGA from 'react-ga';

import DataProfilingForm from './../components/profiling/DataProfilingForm'


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/simulation/repository '); 

export default function QualityDatasetProfilingPage(props) {

    

    return (
        <div >
        {/*    <AqtivaAppBar history={props.history} title={'Data Profiling'} 
            arrayPath={pathHelper.getPathArray('QualityDatasetRepositoryPage')}
            profilingIcon = {true} >

           <DataProfilingForm props ={props} />
            </AqtivaAppBar>
    */}
    <AppBarTemplate
        history={props.history}
        title={"Profiling"}
        arrayPath={pathHelper.getPathArray("Governance")}
      />
       <DataProfilingForm props ={props} />
        </div>

    );
}