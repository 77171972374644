import {FETCH_TOTAL_RECORDS
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_TOTAL_RECORDS:
            return action.payload
        default:
            return state;
        
    }
}