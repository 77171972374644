import React, { useState } from 'react'

import MaterialTable from "@material-table/core";

import { useSelector, useDispatch } from 'react-redux'

import * as actions from 'actions'

import { LanguageContext } from 'language/LanguageContext'
import { useHistory } from 'react-router-dom'

import { isEmpty } from 'commonFunctions/commonFunctions'

import * as renderHelper from './utils'
import * as tableFunctions from 'commonFunctions/tableFunctions'

import * as commonStyles from 'style/CommonStyles'

import { Badge } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';


import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import BarChartIcon from '@material-ui/icons/BarChart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import DataformatResumeCard from './DataformatResumeCard'
import AqtivaDialog from 'components/common/AqtivaDialog';

import { Typography, Card, CardHeader, CardContent } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenSquare } from '@fortawesome/free-solid-svg-icons'

import { Toolbar, Button, Divider } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

import { buttonDefinition } from './DataformatResumeCardButtonDefinition'

import * as windowHelper from 'commonFunctions/windowHelper'

import { StyledMenu, StyledMenuItem } from 'components/common/CustomizedMenu'
import ListItemText from '@material-ui/core/ListItemText';


const initialState = {
    mouseX: null,
    mouseY: null,
};


export default function DatasetManagementTable(props) {

    const dispatch = useDispatch()
    const history = useHistory();
    const theme = useTheme();

    const languageContext = React.useContext(LanguageContext);

    const dataformats = useSelector(store => props.viewAsUser === true ? Object.values(store.dataformats) : Object.values(store.dataformatsAll))

    const user = useSelector(store => store.user)
    const license = useSelector((store) => store.license);

    const [data, setData] = useState([])
    const [sourceTypeLP, setSourceTypeLp] = React.useState([])
    const [ownerLP, setOwnerLP] = React.useState([])
    const [usedDatasourcesByProjects, setUsedDatasourcesByProjects] = React.useState([]);

    const [fullscreenDataformatResumeCard, setFullscreenDataformatResumeCard] = React.useState(false)
    const [selectedDataformatResumeCard, setSelectedDataformatResumeCard] = React.useState(false)
    const [selectedDataset, setSelectedDataset] = React.useState(undefined)

    const [flagChangeDisplayName, setFlagChangeDisplayName] = React.useState(false)
    const [flagChangeFormat, setFlagChangeFormat] = React.useState(false)
    const [flagChangeQuery, setFlagChangeQuery] = React.useState(false)
    const [displayName, setDisplayName] = React.useState(undefined)
    const [query, setQuery] = React.useState(undefined)
    const { height, width } = windowHelper.useWindowDimensions();
    const [filterObj, setFilterObj] = React.useState(undefined)
    const [state, setState] = React.useState(initialState);
    const [newFormat, setNewFormat] = React.useState(undefined)
    const [updateDataformat, setUpdateDataformat] = React.useState(false)
    function executefetchUsedDatasourcesByProject(licenseId, userId) {
        dispatch(actions.fetchUsedDatasourcesByProject(licenseId, userId)).then(response => {
            setUsedDatasourcesByProjects(response.data)
        })
    }

    //for license and user get data
    React.useEffect(() => {
        if (license._id && user.id) {
            executefetchUsedDatasourcesByProject(license._id, user.id)
        }
    }, [JSON.stringify(license), JSON.stringify(user)]);// eslint-disable-line react-hooks/exhaustive-deps


    /* Get Dataformats*/
    React.useEffect(() => {
        if (!isEmpty(user)) {
            //if ((user.role === 'USER' || viewAsUser === true)) {
            dispatch(actions.fetchDataFormats(user))
            dispatch(actions.fetchAllDataFormats(user))
        }
        if (props.updateList) {
            dispatch(actions.fetchDataFormats(user))
            dispatch(actions.fetchAllDataFormats(user))
            props.setUpdateList(false)
        }
    }, [JSON.stringify(user),
         JSON.stringify(props.notificationDatasetId),
          props.updateList])// eslint-disable-line react-hooks/exhaustive-deps

    /*Get user for later use in get Dataformats*/
    React.useEffect(() => {
        dispatch(actions.fetchUser(history))
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    /* Set data object from dataformats*/
    React.useEffect(() => {
        setData(dataformats)
        if (selectedDataformatResumeCard !== undefined && selectedDataformatResumeCard._id !== undefined) {
            const dfNewArray = dataformats.filter(x => x._id === selectedDataformatResumeCard._id)
            if (dfNewArray !== undefined && dfNewArray.length > 0) {
                setSelectedDataformatResumeCard(dfNewArray[0])
            }
        }
    }, [JSON.stringify(dataformats)])// eslint-disable-line react-hooks/exhaustive-deps


    /* Create LookUp objects*/
    React.useEffect(() => {
        if (data !== undefined && data.length > 0) {
            setSourceTypeLp(tableFunctions.getLookup(data, 'source'))
            setOwnerLP(tableFunctions.getLookUpOwner(data, props.licenseUsers))

            props.setSourceList(tableFunctions.getLookupObject(data, 'source'))
            props.setOwnersList(tableFunctions.getLookUpOwnerList(data, props.licenseUsers))

            //TODO: VARIABLE NOT USED
            //setProfilingLp(tableFunctions.getLookUpProfiling(data, 'profiling'))
        }


    }, [JSON.stringify(data)])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (selectedDataformatResumeCard) {
            setDisplayName(selectedDataformatResumeCard.nickname)
        }

    }, [selectedDataformatResumeCard])


    React.useEffect(() => {
        setFilterObj(props.filterObj)
    }, [props.filterObj])



    function translateUserIdToName(userId, licenseUsers) {
        if (licenseUsers !== undefined && licenseUsers.length > 0) {
            for (let i = 0; i < licenseUsers.length; i++) {
                if (licenseUsers[i].id === userId) {
                    return licenseUsers[i].name
                }
            }
            return userId
        }
        return undefined


    }



    function getColumns() {
        var baseColumns =
            [
                { title: languageContext.dictionary['name'], width: props.selectDatasetDialog === true ? '30%' : '20%', render: rowData => { return rowData.nickname ? rowData.nickname : rowData.name } },
                { title: languageContext.dictionary['creationDate'], width: '7%', field: 'creationDate', filtering: false, render: rowData => { return rowData.creationDate && renderHelper.parseDateToCorrectFormat(rowData.creationDate) } },
                { title: languageContext.dictionary['updateDate'], width: '7%', field: 'updateDate', filtering: false, render: rowData => { return rowData.updateDate && renderHelper.parseDateToCorrectFormat(rowData.updateDate) } },
                { title: languageContext.dictionary['numberOfColumns'], filtering: false, width: '5%', field: 'columns', render: rowData => { return rowData.columns ? rowData.columns.length : 0 } },
                { title: languageContext.dictionary['sourceType'], width: '7%',  field: 'source', render: rowData => { return renderHelper.renderAzureLogo(rowData.source) } },
                { title: languageContext.dictionary['owner'], width: '10%',  field: 'userId', render: (rowData) => { return rowData.ownerUserId ? translateUserIdToName(rowData.ownerUserId, license.users) : rowData.userId && translateUserIdToName(rowData.userId, license.users) } },
                { title: languageContext.dictionary['allowedUsers'], width: '10%', field: 'allowedUsers', render: (rowData) => { return renderHelper.renderAllowedUsers(rowData, license.users) } },

                {
                    title: languageContext.dictionary['usedByProjects'], width: '5%', field: 'usedByProjects', filtering: false, render: (rowData) => {
                        return usedDatasourcesByProjects ? renderHelper.countProjectsUsingDataformat(usedDatasourcesByProjects, rowData) : '0'
                    }
                },
                {
                    title: languageContext.dictionary['version'], width: '5%', field: 'version', filtering: false, render: rowData => { return rowData.version }
                }
            ]
        /* if(props.selectDatasetDialog !== true){
             baseColumns.push({
                 title: languageContext.dictionary['actions'], width: '15%', field: '', filtering: false, render: rowData => { return getActionsModel(rowData) }
             }
             )

         }*/
        return baseColumns



    }

    function onClickFullscreenDF(df) {
        setFullscreenDataformatResumeCard(true)
        setSelectedDataformatResumeCard(df)
    }

    function displayChangeDisplayName(event) {
        event.preventDefault();
        setFlagChangeDisplayName(true)
    }
    function displayChangeFormat(event) {
        event.preventDefault();
        setFlagChangeFormat(true)
    }

    function shareDataformat(event) {
        event.preventDefault();
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    }

    function displayChangeQuery(event) {
        event.preventDefault();
        setFlagChangeQuery(true)
    }

    function updateDataformatWithNickName() {
        dispatch(actions.getDataformatDetailById(selectedDataformatResumeCard._id)).then(response => {
            var newDF = JSON.parse(JSON.stringify(response.data))
            newDF.nickname = displayName
            dispatch(actions.editDataformat(newDF))
            setFlagChangeDisplayName(false)
            setUpdateDataformat(true)
        })

    }

    function updateDataformatWithFormat() {
        dispatch(actions.getDataformatDetailById(selectedDataformatResumeCard._id)).then(response => {
            var newDF = JSON.parse(JSON.stringify(response.data))
            newDF.format = newFormat
            dispatch(actions.editDataformat(newDF))
                setFlagChangeFormat(false)
                setUpdateDataformat(true)    
                setNewFormat(undefined)
        })

    }
    function updateDataformatWithQuery() {
        dispatch(actions.getDataformatDetailById(selectedDataformatResumeCard._id)).then(response => {
            var newDF = JSON.parse(JSON.stringify(response.data))
            newDF.sqlQuery = query
            dispatch(actions.editDataformat(newDF))
            setFlagChangeQuery(false)
            setUpdateDataformat(true)    
            setQuery(undefined)
        })

    }

    function createActionDictionary() {
        return [
            {
                id: 'changeDisplayName',
                clickEvent: displayChangeDisplayName

            },
            {
                id: 'changeQuery',
                clickEvent: displayChangeQuery

            },
            {
                id: 'shareDataformat',
                clickEvent: shareDataformat

            },
            {
                id: 'addProfiling',
                clickEvent: (event => props.viewAddProfiling ? props.viewAddProfiling(selectedDataformatResumeCard._id) : console.log('tooltipSeeVersion Event'))

            },
            {
                id: 'createNewVersion',
                clickEvent: (event => props.viewDisplayDataFormatVersions ? props.viewDisplayDataFormatVersions(selectedDataformatResumeCard._id) : console.log('tooltipAddVersion Event'))

            },
            {
                id: 'changeFormat',
                clickEvent: displayChangeFormat

            }
        ]
    }

    function getFilteredData(data) {


        if (props.viewOnlyWithProfiling === true) {

            if (filterObj) {
                return filterData().filter(rowData => (rowData.profiling !== undefined && rowData.profiling !== null) || (rowData.profilingStatus !== null && rowData.profilingStatus !== undefined && rowData.profilingStatus === 'ok'))
            } else {
                return data.filter(rowData => (rowData.profiling !== undefined && rowData.profiling !== null) || (rowData.profilingStatus !== null && rowData.profilingStatus !== undefined && rowData.profilingStatus === 'ok'))
            }
        }

        if (filterObj) {
            return addId2Data(filterData())
        }

        return addId2Data(data)
    }

    function filterData() {

        let filterData = data
        if (filterObj) {

            if (filterObj.name !== '') filterData = filterData.filter(x => (x.name && x.name.includes(filterObj.name)) || (x.nickname && x.nickname.includes(filterObj.name)))

            if (filterObj.source !== '') filterData = filterData.filter(x => x.source === filterObj.source)

            if (filterObj.owner !== '') filterData = filterData.filter(x => x.userId === filterObj.owner.id || x.ownerUserId === filterObj.owner.id)

            if (filterObj.creationDate !== '') filterData = filterData.filter(x => x.creationDate && x.creationDate.substring(0, 10) === filterObj.creationDate.substring(0, 10))

            if (filterObj.updateDate !== '') filterData = filterData.filter(x => x.updateDate && x.updateDate.substring(0, 10) === filterObj.updateDate.substring(0, 10))


        }

        return filterData
    }

    const onCloseShareMenu = () => {
        setState(initialState)
    }

    function getContextShare() {
        return (
            <div>
                <StyledMenu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={onCloseShareMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    {user && license && license.users && license.users.filter(e => e.email !== user.email).sort((a, b) => (a.name > b.name) ? 1 : -1).map((user, index) =>
                        <StyledMenuItem key={index}>

                            <ListItemText primary={<Typography variant="caption">{`${user.name} (${user.email})`}</Typography>} key={index}
                                onClick={event => shareProject(user.id)} />
                        </StyledMenuItem>
                    )}

                </StyledMenu>
            </div>
        )
    }

    function shareProject(userId) {
        console.log('aqui')
        dispatch(actions.shareDataformat(selectedDataformatResumeCard._id, selectedDataformatResumeCard.nickname ? selectedDataformatResumeCard.nickname : selectedDataformatResumeCard.name, userId))
        setState(initialState)
    }


    function addId2Data(array){
        if(array === undefined){
            return array
        }
        else{
            return array.map(x=>{
                x.id=x._id
                return x
            })
        }
    }
    return (
        <div>

            <AqtivaDialog
                title={`Change Display Name`}
                visible={flagChangeDisplayName}
                maxWidth={'lg'}
                cancelText={'Close'}
                cancelCallback={event => { setFlagChangeDisplayName(false) }}
                confirmCallback={event => updateDataformatWithNickName()}
            >
                <TextField
                    style={{ fontSize: '2px' }}
                    id="displayNameTextField"
                    defaultValue={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </AqtivaDialog>

            <AqtivaDialog
                title={`Change Dataset Format`}
                visible={flagChangeFormat}
                maxWidth={'lg'}
                cancelText={'Close'}
                cancelCallback={event => { setFlagChangeFormat(false) }}
                confirmCallback={event => updateDataformatWithFormat()}
            >
                <TextField
                    style={{ fontSize: '2px' }}
                    id="newFormatTextField"
                    defaultValue={newFormat}
                    onChange={e => setNewFormat(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </AqtivaDialog>

            <AqtivaDialog
                title={`Change Query`}
                visible={flagChangeQuery}
                maxWidth={'lg'}
                fullWidth={true}
                cancelText={'Close'}
                cancelCallback={event => { setFlagChangeQuery(false) }}
                confirmCallback={event => updateDataformatWithQuery()}
            >
                <TextField
                    style={{ fontSize: '2px', width: '100%' }}
                    id="displayChangeQueryTextField"
                    defaultValue={query}
                    onChange={e => setQuery(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </AqtivaDialog>

            <AqtivaDialog
                title={`Dataformat ${selectedDataformatResumeCard ? selectedDataformatResumeCard.nickname ? selectedDataformatResumeCard.nickname : selectedDataformatResumeCard.name : ''} Detail`}
                visible={fullscreenDataformatResumeCard}
                maxWidth={'lg'}
                cancelText={'Close'}
                cancelCallback={event => { setFullscreenDataformatResumeCard(false); setSelectedDataformatResumeCard(undefined) }}
                showConfirmButton={false}

            >
                <div style={{ width: width * 0.8, height: height * 0.8, marginTop: '-10px' }}>
                    <Toolbar>
                        { selectedDataformatResumeCard !== undefined && selectedDataformatResumeCard.source !== undefined && selectedDataformatResumeCard.source === 'oracle' && buttonDefinition.filter(v => v.labelId === 'changeQueryLabel').map(value =>

                            <Button key={value.labelId} startIcon={value.icon}
                                style={{ ...theme.buttons.buttonRegular, marginRight: '15px' }}
                                onClick={createActionDictionary().filter(x => x.id === value.id)[0].clickEvent}>
                                <div style={theme.common.text.buttonTextSmall}>

                                    {languageContext.dictionary[value.labelId]}
                                </div>
                            </Button>

                        )}
                        {buttonDefinition.filter(v => v.labelId !== 'changeQueryLabel').map(value =>

                            <Button key={value.labelId} startIcon={value.icon}
                                style={{ ...theme.buttons.buttonRegular, marginRight: '15px' }}
                                onClick={createActionDictionary().filter(x => x.id === value.id)[0].clickEvent}>
                                <div style={theme.common.text.buttonTextSmall}>

                                    {languageContext.dictionary[value.labelId]}
                                </div>
                            </Button>

                        )}

                    </Toolbar>
                    <Divider style={{ width: '100%', marginTop: '-15px' }} />
                    <div style={{ width: '100%', height: '80%', marginTop: '15px' }}>
                        <DataformatResumeCard dataformat={selectedDataformatResumeCard ? selectedDataformatResumeCard : {}}
                            onClickFullScreen={onClickFullscreenDF} setSqlQuery={setQuery} />
                    </div>

                </div>

            </AqtivaDialog>
            {getContextShare()}
            <MaterialTable
                style={{ minWidth: props.selectDatasetDialog === true ? '1500px' : '800px', width: "100%" }}
                title="Datasets"
                data={getFilteredData(data)}
                columns={getColumns()}

                options={{
                    rowStyle: rowData => ({
                        backgroundColor:
                            props.selectedDatasetForExportationId &&
                                rowData._id === props.selectedDatasetForExportationId
                                ? commonStyles.mainColor
                                : "#fff"
                    }),
                    actionsColumnIndex: -1,
                    selection: props.selectDatasetDialog ? true : false,
                    filtering: false,
                    tableLayout: "fixed",
                    search: false,
                    pageSize: 10,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: commonStyles.tableHeaderStyle,
                    cellStyle: commonStyles.tableCellStyle,
                    filterCellStyle: commonStyles.tableFilterCellStyle
                }}

                actions={[

                    rowData => ({
                        icon: () => <ViewWeekIcon style={{ margin: '-3px' }} />,
                        tooltip: languageContext.dictionary['tooltipDetail'],
                        onClick: (event, rowData) => props.viewDatasetDetails ? props.viewDatasetDetails(rowData._id) : console.log('tooltipDetail Event')

                    }),
                    rowData => ({
                        icon: () => <VpnKeyIcon style={{ margin: '-3px' }} />,
                        tooltip: languageContext.dictionary['updateUserAndPwd'],
                        onClick: (event, rowData) => { props.viewUpdateUserAndPwd ? props.viewUpdateUserAndPwd(rowData._id) : console.log('tooltipDetail Event'); event.preventDefault() },
                        disabled: rowData.source !== 'azure-sqldatabase'
                    }),
                    rowData => ({
                        icon: () => <AccountTreeIcon style={{ margin: '-3px' }} />,
                        tooltip: languageContext.dictionary['tooltipSeeTrace'],
                        onClick: (event, rowData) => props.viewDatasetUseInProjects ? props.viewDatasetUseInProjects(rowData._id) : console.log('tooltipDetail Event')

                    }),
                    rowData => ({
                        icon: () => <BarChartIcon style={{ marginLeft: '-3px' }} />,
                        tooltip: languageContext.dictionary['tooltipProfiling'],
                        onClick: (event, rowData) => props.viewDatasetProfiling ? props.viewDatasetProfiling(rowData._id) : console.log(rowData),
                        disabled: (rowData.profiling === undefined || rowData.profiling === null) && (rowData.profilingStatus === null || rowData.profilingStatus === undefined)
                    }),
                    rowData => ({
                        icon: () => <Badge style={{ margin: '-3px' }} badgeContent={'+'} color="primary" overlap="rectangular"> <BarChartIcon /> </Badge>,
                        tooltip: languageContext.dictionary['tooltipAddProfiling'],
                        onClick: (event, rowData) => props.viewAddProfiling ? props.viewAddProfiling(rowData._id) : console.log('tooltipSeeVersion Event')

                    }),
                    rowData => ({
                        icon: () => <FormatListNumberedIcon style={{ margin: '-3px' }} />,
                        tooltip: languageContext.dictionary['tooltipSeeVersion'],
                        onClick: (event, rowData) => props.viewDisplayDataFormatVersions ? props.viewDisplayDataFormatVersions(rowData._id) : console.log('tooltipSeeVersion Event'),
                        disabled: rowData.version === 0
                    }),

                    rowData => ({
                        icon: () => <Badge style={{ margin: '-3px' }} badgeContent={'+'} color="primary" overlap="rectangular" > <FormatListNumberedIcon /> </Badge>,
                        tooltip: languageContext.dictionary['tooltipAddVersion'],
                        onClick: (event, rowData) => props.viewDisplayDataFormatVersions ? props.viewDisplayDataFormatVersions(rowData._id) : console.log('tooltipAddVersion Event'),
                    }),

                ]}
                detailPanel={rowData => {
                    console.log('rowData', rowData)
                    return (
                        <div style={{ marginLeft: '5%', width: '90%' }}>
                            <Card elevation={3} styles={{
                                width: '80%',
                                borderRadius: 20,
                            }}>

                                <CardHeader style={{ backgroundColor: commonStyles.mainColor, height: '30px', width: '100%' }}
                                    title={
                                        <div style={{ ...commonStyles.adjacentSpaceBetweenGood, width: '100%' }}>
                                            <FontAwesomeIcon icon={faPenSquare} style={{ cursor: "pointer", color: 'white', width: '20px' }}
                                                onClick={event => onClickFullscreenDF(rowData.rowData)}
                                            />
                                            <Typography variant='subtitle2' style={{ color: '#FFFFFF' }}>Dataformat Details</Typography>
                                            <div>

                                            </div>
                                        </div>

                                    } />
                                <CardContent>



                                    <div style={{ marginTop: '-10px' }}>


                                        <div style={{ width: '100%', height: '80%', marginTop: '15px' }}>
                                            <DataformatResumeCard dataformat={rowData.rowData}
                                                onClickFullScreen={onClickFullscreenDF} updateDataformat={updateDataformat} setSqlQuery={setQuery} setUpdateDataformat={setUpdateDataformat}/>

                                        </div>

                                    </div>
                                </CardContent>

                            </Card>
                        </div>

                    )
                }}

                components={{
                    Toolbar: props => (
                        <div>
                        </div>
                    ),
                }}
            />
        </div>

    )
}
