
import quality from '../apis/quality';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
//import qualityLocalHost3 from '../apis/qualityLocalHost3';
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';




export const fetchProjectVersions = (projectId) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }

  await quality.get(`/project/versions/${projectId}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = { result: 'ok', data: response.data }
  })
    .catch(function (error) {
      // dispatchError(dispatch,error)
      //no enviamos el error ya que si no se ha hecho commit nos devolverá un not found que no queremos que salga por pantalla 
      returnValue = { result: 'ko', error: error }
    });


  return returnValue


};

export const addCommitVersion = (idProject, commitInfo, userId) => async dispatch => {
  var returnValue = { result: 'undefined' }
  await quality.post(`/project/version/${idProject}/userId/${userId}`, commitInfo, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatchInfo(dispatch, `Project version stored`)

    returnValue = { result: 'ok' }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }

    });
  return returnValue
}
