import React, { useState } from 'react';
import AqtivaDialog from '../../AqtivaDialog'
import { Typography, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import SchedulingConfigurationCard from '../SchedulingConfigurationCard';
import * as scheduleHelper from 'commonFunctions/scheduleFunctions'
import { dispatchInfo, dispatchError, } from '../../axios/axiosHelper';



import * as actions from '../../../../actions/index'

export default function CreateScheduleDialog(props) {
    const dispatch = useDispatch();

    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))



    const [loading, setLoading] = useState(false)
    const [schedule, setSchedule] = useState(undefined)
    const [noEnvironmentError, setNoEnvironmentError] = useState(undefined)
    const [noOutputPathError, setNoOutputPathError] = useState(undefined)

    function validateSchedule(){
        if(schedule.name === undefined || schedule.name === null || schedule.name ===""){
            return false
        }
        return true
    }

    async function createConfigurationForDS() {
        if(validateSchedule() === false){
            return
        }
        await scheduleHelper.deployQP2Cloud(qualityConfiguration, schedule.environment, props.project._id, props.selectedQualityPoint._id, props.datasource, props.userId, dispatchError, dispatchInfo, dispatch)

        //-----------------------------------------------------------------------------//

        if (schedule && schedule.environment ) {
            // && schedule.storageDTO removed
            setLoading(true)

            await dispatch(actions.createSchedule(props.datasource._id, schedule.environment, schedule, props.userId))
            props.setShowCreateDialog(false)
            props.setUpdateCounter(props.updateCounter + 1)
            setLoading(false)
            await dispatch(actions.fetchProjects(props.userId))
        }
        else {
            if (!schedule.environment) {
                setNoEnvironmentError("Please select an enviroment")
            }
            /*if (!schedule.storageDTO) {
                setNoOutputPathError("Please select an output path")
            }*/
        }




    }



    async function createSchedule() {

        //primer paso: hay que crear la configuración para el datasource
        await createConfigurationForDS()



    }
    return (
        <>
            <AqtivaDialog
                visible={props.showCreateDialog}
                dialog={props.showCreateDialog}
                titleIcon={faPlusCircle}
                fullWidth={true}
                maxWidth={'lg'}
                title={"Create Schedule"}
                editable={false}
                cancelText={'Cancel'}
                cancelCallback={() => {
                    props.setShowCreateDialog(false)
                    setNoEnvironmentError(undefined)
                }}
                confirmText={'Create Schedule'}
                confirmCallback={async () => {
                    await createSchedule()
                    props.setUpdateCounter(props.updateCounter + 1)
                }}
            >
                <SchedulingConfigurationCard userId={props.userId} environmentOptions={props.environmentOptions}
                    datasource={props.datasource} project={props.project} selectedQualityPoint={props.selectedQualityPoint}
                    setSchedule={setSchedule} noEnvironmentError={noEnvironmentError}
                    setNoEnvironmentError={setNoEnvironmentError}
                    noOutputPathError={noOutputPathError}
                    setNoOutputPathError={setNoOutputPathError} />
                {loading && <Typography variant={'subtitle1'} style={{ textAlign: "center", marginTop: "20px" }}>
                    Creating schedule... <CircularProgress style={{ width: '25px', height: '25px' }} />
                </Typography>}
            </AqtivaDialog>
        </>
    )
}