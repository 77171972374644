import React from 'react'
import * as textHelper from 'components/common/Texts'

import { Grid, Paper, Box,  Modal } from '@material-ui/core'
import DQIChartAmChart from './components/DQIChartAmChart';
import DiagramTitle from './components/DiagramTitle';
import DQIDimensionsChartDetail from './components/DQIDimensionsChartDetail'
import * as helper from './helper'
import TreeViewDimensionsAndRuleTypes from './components/TreeViewDimensionsAndRuleTypes'

import * as commonStyles from 'style/CommonStyles'
import LineChartZoomAndScroll from './components/LineChartZoomAndScroll'
import AqtivaLoading from 'components/common/AqtivaLoading';
import RadarDimensionsAmChart from './components/RadarDimensionAmChart';


export default function GlobalDQICoverage(props) {


    const radarStyle = {
        fontSize: '12px',
        fontFamily: 'Times New Roman',
    }

    const fillColor = commonStyles.mainColor
    const strokeColor = commonStyles.mainColor

    const [ openModal, setOpenModal] = React.useState(false)
    const [modalComponent, setModalComponent] =React.useState('')
    function handleCloseModal(){
        setOpenModal(false)
    }

    function callbackOpenModal(value){
        setOpenModal(true)
        setModalComponent(value)
    }

    function getExecutionsForDimension(data, dimension) {
        if (data && data.length > 0 && data.filter(x => x.dimension === dimension).length > 0) {
            const dat = data.filter(x => x.dimension === dimension)[0]

            return dat.executions
        }
        return 0
    }

    function getDefinitionsForDimension(data, dimension) {
        if (data && data.length > 0 && data.filter(x => x.dimension === dimension).length > 0) {
            const dat = data.filter(x => x.dimension === dimension)[0]

            return dat.definitions
        }
        return 0
    }

    function getModalContent(){
        if(modalComponent ==='LineChartZoomAndScroll'){
            return (
            <div style={{ width: '100%', height: '450px' }}>
            <DiagramTitle title='DQI Timeline Evolution' fullscreenAllowed = {false} />
            <LineChartZoomAndScroll data={props.DQIByDay} startDate={props.startDate} endDate={props.endDate} fullscreen = {true} />
            </div>
            )
        }
        else if(modalComponent === 'TreeViewDimensionsAndRuleTypes'){
           return ( <Paper style={{ width: '100%', height: '800px' }} >

                            <DiagramTitle title='DQI Per Dimension and Rule Type' fullscreenAllowed = {false}/>
                            <TreeViewDimensionsAndRuleTypes data={props.DQIAndExecutions4RuleTypeAndDimension} fullscreen = {true}/>
                        </Paper>
           )
        }
        else if(modalComponent === 'RadarDimensionsDimensionCoverage'){
            return(<Paper style={{ width: '100%', height: '800px' }} >
            <DiagramTitle title='Rule Execution by Dimension Coverage' fullscreenAllowed = {false}/>

            <RadarDimensionsAmChart
            id='executionsWith'
                tooltipSuffix='executions'
                tootlipTitlePrefix='Executions with'
                style={radarStyle}
                fillColor={fillColor}
                strokeColor={strokeColor}
                fullscreen = {true}
                data={helper.createRadarDimensionData(props.globalExecutions,
                    getExecutionsForDimension(props.executionsByDimension, 'format'),
                    getExecutionsForDimension(props.executionsByDimension, 'integrity'),
                    getExecutionsForDimension(props.executionsByDimension, 'unicity'),
                    getExecutionsForDimension(props.executionsByDimension, 'validity'),
                    getExecutionsForDimension(props.executionsByDimension, 'veracity')
                )}
            />
        </Paper>)
        }
        else if(modalComponent === 'RadarDimensionsRuleTypeCoverage'){
            return(
                <Paper style={{ width: '100%', height: '800px' }} >

                <DiagramTitle title='Rule Execution by Rule Type Coverage' fullscreenAllowed = {false}/>
                <RadarDimensionsAmChart
                id='definitionsOf'
                    tooltipSuffix='definitions'
                    tootlipTitlePrefix='Definition of'
                    style={radarStyle}
                    fillColor={fillColor}
                    strokeColor={strokeColor}
                    fullscreen = {true}
                    data={props.executionsByRuleType} />
            </Paper>
            )
        }
        else{
           return ( <div>
                Component Not Found
            </div>)
        }
    }

    return (
        <div>
        <Grid container spacing={1} style={{ width: '100%' }}>


            <Grid item xs={4} >
                <Paper style={{ width: '100%', height: '200px' }} >
                    <DiagramTitle title='Global DQI' tooltip={textHelper.globalDQIText} />
                    {props.dQI4DimensionsFlag === true ? <AqtivaLoading />
                    :
                    <DQIChartAmChart data={props.globalDQI !== undefined ? props.globalDQI.DQIok : -1}/>}
                </Paper>
            </Grid>
            <Grid item xs={8} >
                <Paper style={{ width: '100%', height: '200px' }} >
                    <DiagramTitle title='Global DQI' tooltip={textHelper.globalDQIText} />
                    {props.dQI4DimensionsFlag === true ? <AqtivaLoading />
                    :
                    <DQIDimensionsChartDetail data={props.DQIByDimension}  />}

                    
                </Paper>
            </Grid>
            <Grid item xs={6} >
                <Grid container spacing={1} >
                    <Grid item xs={6} >
                        <Paper style={{ width: '100%', height: '200px' }} >
                            <DiagramTitle title='Rule Execution by Dimension Coverage' onClickModal={event=>callbackOpenModal('RadarDimensionsDimensionCoverage')} 
                    fullscreenAllowed = {true}/>
                    {props.executions4DimensionFlag === true ? <AqtivaLoading />
                    :
                  
                            <RadarDimensionsAmChart
                            id='executionsWith2'
                                tooltipSuffix='executions'
                                tootlipTitlePrefix='Executions with'
                                style={radarStyle}
                                fillColor={fillColor}
                                strokeColor={strokeColor}
                                data={helper.createRadarDimensionData(props.globalExecutions,
                                    getExecutionsForDimension(props.executionsByDimension, 'format'),
                                    getExecutionsForDimension(props.executionsByDimension, 'integrity'),
                                    getExecutionsForDimension(props.executionsByDimension, 'unicity'),
                                    getExecutionsForDimension(props.executionsByDimension, 'validity'),
                                    getExecutionsForDimension(props.executionsByDimension, 'veracity')
                                )}
                                
                            />
                                }
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper style={{ width: '100%', height: '200px' }} >

                            <DiagramTitle title='Rule Execution by Rule Type Coverage' onClickModal={event=>callbackOpenModal('RadarDimensionsRuleTypeCoverage')} 
                    fullscreenAllowed = {true}/>
                    {props.executions4RuleTypeFlag === true ? <AqtivaLoading />
                    :
                            <RadarDimensionsAmChart
                            id='definitionsOf2'
                                tooltipSuffix='definitions'
                                tootlipTitlePrefix='Definition of'
                                style={radarStyle}
                                fillColor={fillColor}
                                strokeColor={strokeColor}
                                data={props.executionsByRuleType} 
                                />
                    }
                                
                        </Paper>
                    </Grid>

                    <Grid item xs={6} >
                        <Paper style={{ width: '100%', height: '200px' }} >
                            <DiagramTitle title='Rule Definitions by Dimension Coverage' onClickModal={event=>callbackOpenModal('RadarDimensionsDimensionCoverage')} 
                    fullscreenAllowed = {true}/>
                    {props.definitions4DimensionsFlag === true ? <AqtivaLoading />
                    :
                            <RadarDimensionsAmChart
                            id='executionsWith3'
                                tooltipSuffix='executions'
                                tootlipTitlePrefix='Executions with'
                                style={radarStyle}
                                fillColor={fillColor}
                                strokeColor={strokeColor} 
                                data={helper.createRadarDimensionData(props.globalDefinitions,
                                    getDefinitionsForDimension(props.definitionsByDimension, 'format'),
                                    getDefinitionsForDimension(props.definitionsByDimension, 'integrity'),
                                    getDefinitionsForDimension(props.definitionsByDimension, 'unicity'),
                                    getDefinitionsForDimension(props.definitionsByDimension, 'validity'),
                                    getDefinitionsForDimension(props.definitionsByDimension, 'veracity')
                                )}
                                
                            />
                                }
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper style={{ width: '100%', height: '200px' }} >

                            <DiagramTitle title='Rule Definitions by Rule Type Coverage' onClickModal={event=>callbackOpenModal('RadarDimensionsRuleTypeCoverage')} 
                    fullscreenAllowed = {true}/>
                     {props.definitions4RuleTypeFlag === true ? <AqtivaLoading />
                    :
                            <RadarDimensionsAmChart
                            id='definitionsOf3'
                                tooltipSuffix='definitions'
                                tootlipTitlePrefix='Definition of'
                                style={radarStyle}
                                fillColor={fillColor}
                                strokeColor={strokeColor}
                                data={props.definitionsByRuleType} 
                               />
                     }
                        </Paper>
                    </Grid>
                 


                </Grid>
            </Grid>

            <Grid item xs={6} >
                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <Paper style={{ width: '100%', height: '400px' }} >

                            <DiagramTitle title='DQI Per Dimension and Rule Type' onClickModal={event=>callbackOpenModal('TreeViewDimensionsAndRuleTypes')} 
                    fullscreenAllowed = {true}/>
                     {props.executionsAndDQI4RuleTypeAndDimensionFlag === true ? <AqtivaLoading />
                    :
                            <TreeViewDimensionsAndRuleTypes data={props.DQIAndExecutions4RuleTypeAndDimension} 
                            />
                     }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper style={{ width: '100%', height: '250px' }} >

                    <DiagramTitle title='DQI Timeline Evolution' onClickModal={event=>callbackOpenModal('LineChartZoomAndScroll')} 
                    fullscreenAllowed = {true}/>
                    {props.dQIByDayFlag === true ? <AqtivaLoading />
                    :
                    <LineChartZoomAndScroll data={props.DQIByDay} startDate={props.startDate} endDate={props.endDate}
                     />
                    }
                </Paper>
            </Grid>

           
            
        </Grid>

<Modal
open={openModal}
onClose={handleCloseModal}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Box sx={{ position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  height: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,}}>
      
          {getModalContent()}
    </Box>
    </Modal>
    </div>
    )
        
}