
import React from 'react';
import { Card, Typography, CardContent, CardHeader, Avatar, IconButton } from '@material-ui/core';
import { ILogicColumnCondition } from 'interfaces/LogicCondition';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as commonStyles from 'style/CommonStyles'

import DeleteIcon from '@material-ui/icons/Delete';
import DialogEditConditionOperator from './DialogEditConditionOperator';
import { IColumn } from 'interfaces/RuleInterfaces';

type IProps = {
  columnCondition: ILogicColumnCondition,
  height: number,
  width: number
  deleteCondition: (value: any) => any,
  editCondition: (value: ILogicColumnCondition, ix: number) => any,
  ixOp: number,
  columns: IColumn[],
  numOperations: number
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: commonStyles.mainColor
    },
  }),
);

function getColumnConditionString(condition: ILogicColumnCondition) {
  if (condition.params?.nullCheck === true) {
    return `${condition.columnName} is null`
  }
  else if (condition.params?.notNullCheck === true) {
    return `${condition.columnName} is not null`
  }
  else if (condition.params?.valueRangeBool && condition.params?.valueRangeBool.length > 0) {
    return `${condition.columnName} in ${condition.params?.valueRangeBool ? condition.params?.valueRangeBool.join(',') : 'None'}`
  }
  else if (condition.params?.operator === 'in') {
    return `${condition.columnName} in ${condition.params?.listValues ? condition.params?.listValues.join(',') : 'None'}`
  }
  else if (condition.params?.operator === 'between') {
    return `${condition.columnName} between ${condition.params?.lowerValue} and ${condition.params?.greaterValue}`
  }
  else {
    return `${condition.columnName} ${condition.params?.operator} than ${condition.params?.lowerValue ? condition.params?.lowerValue : condition.params?.greaterValue}`
  }
}

export default function PaperColumnCondition(props: IProps) {

  const [openEdit, setOpenEdit] = React.useState<boolean>(false)

  function closeDialogEdit() {
    setOpenEdit(false)
  }
  function openDialogEdit() {
    setOpenEdit(true)
  }

  const classes = useStyles();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <DialogEditConditionOperator edit={openEdit} closeEdit={closeDialogEdit} condition={props.columnCondition}
        ixOp={props.ixOp}
        columns={props.columns}
        editCondition={props.editCondition} />

      <Card elevation={3} style={{ width: `${props.width}px`, minHeight: '100px', height: `${props.height}px` }} >
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              C
            </Avatar>
          }
          action={
            <div style={{ display: 'flex', alignItems: 'right', flexDirection: props.numOperations >= 5 ? 'column' : 'row' }}>
              <IconButton aria-label="settings" onClick={openDialogEdit}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="settings">
                <DeleteIcon onClick={event => props.deleteCondition(props.columnCondition)} />
              </IconButton>
            </div>

          }
          title={`Column: ${props.columnCondition.columnName}`}
          subheader={`Type: ${props.columnCondition.columnType ? props.columnCondition.columnType : undefined}`}
        />

        <CardContent style={{ marginTop: '-30px' }}>
          <Typography variant='subtitle1'> {getColumnConditionString(props.columnCondition)}</Typography>
        </CardContent>

      </Card>
    </div>
  )

}