import * as actions from 'actions'
import { BlobServiceClient } from '@azure/storage-blob';


export function deployQP2Cloud(configuration, envName, projectId, qpId, datasource, userId, dispatchError, dispatchInfo, dispatch) {
  //get environment configuration
  let envConfig = configuration.environmentConfigurationList.filter(x => x.environmentName === envName)[0]
  if (envConfig.backendType === 'Azure') {
    deployQP2AzureSchedule(envConfig, envName, projectId, qpId, datasource, userId, dispatchError, dispatchInfo, dispatch)
  }
  else if (envConfig.backendType === 'AWS') {
    deployQP2AWSSchedule(envConfig, envName, projectId, qpId, datasource, userId, dispatchError, dispatchInfo, dispatch)
  }
  else {
    dispatchError(dispatch, "No Environment configuration found")
    return
  }
}

async function deployQP2AWSSchedule(envConfiguration, envName, projectId, qpId, datasource, userId, dispatchError, dispatchInfo, dispatch) {
  const response = await dispatch(actions.getQualityConfigurationDeployment(projectId, envName))
  //get need the elements
  var selectedRxQP = undefined
  for (var i = 0; i < response.configuration.length; i++) {
    const configQP = JSON.parse(response.configuration[i])
    if (configQP.quality_point.id === qpId) {
      selectedRxQP = response.configuration[i]
      break;
    }
  }

  if (selectedRxQP === undefined) {
    dispatchError(dispatch, "Error obtaining configuration")
  }

  asyncDeployAWS(selectedRxQP, envConfiguration, envName, userId, qpId, datasource, dispatchInfo, dispatch)
}

const asyncDeployAWS = async (data, envConfiguration, environment, userId, qpId, datasource, dispatchInfo, dispatch) => {

  let container = envConfiguration.amazonConfiguration.bucketName
  let account = envConfiguration.amazonConfiguration.accessKey
  let key = envConfiguration.amazonConfiguration.privateKey
  let region = envConfiguration.amazonConfiguration.awsServiceRegion

  let folder = "schedule/" + environment + "/" + userId + "/" + qpId + "/" + datasource._id + "/"
  var filename = "config.json";
  filename = filename.split(' ').join('_')
  // dispatch to path /upload/file/s3
  //creamos metodo para poder pasar un string, no solamente un byte[]

  //let byteArray = Buffer.from(data + "\0")
  const object = {
    userId: userId,
    container: container,
    account: account,
    key: key,
    region: region,
    folder: folder,
    fileName: filename,
    fileAsString: JSON.stringify(JSON.parse(data)),
    //byteArray: byteArray
  }
  dispatch(actions.uploadObjectStringToS3(object))
}

async function deployQP2AzureSchedule(envConfiguration, envName, projectId, qpId, datasource, userId, dispatchError, dispatchInfo, dispatch) {

  let sasElements = getSasElements(envConfiguration)
  let sasTokenTmp = await dispatch(actions.getSecret(sasElements.sasToken))
  let containerName = sasElements.containerName
  let storageAccountName = sasElements.storageAccountName
  let sasToken = sasTokenTmp.startsWith("?") ? sasTokenTmp : "?" + sasTokenTmp

  const response = await dispatch(actions.getQualityConfigurationDeployment(projectId, envName))
  const STORAGE_ACCOUNT_NAME = storageAccountName
  const CONTAINER_NAME = containerName

  // for browser, SAS_TOKEN is get from API?
  const SAS_TOKEN = sasToken
  const sasURL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${SAS_TOKEN}`
  const blobServiceClient = new BlobServiceClient(sasURL)
  const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)


  var selectedRxQP = undefined
  for (var i = 0; i < response.configuration.length; i++) {
    const configQP = JSON.parse(response.configuration[i])
    if (configQP.quality_point.id === qpId) {
      selectedRxQP = response.configuration[i]
      break;
    }
  }

  if (selectedRxQP === undefined) {
    dispatchError(dispatch, "Error obtaining configuration")
  }

  asyncDeployScheduleAzure(selectedRxQP, containerClient, envName, userId, projectId, qpId, datasource, dispatchInfo, dispatch)
}

function getSasElements(envConfig) {

  return {
    'sasToken': envConfig.sasToken,
    'storageAccountName': envConfig.storageAccountName,
    "containerName": envConfig.containerName
  };
}

const asyncDeployScheduleAzure = async (selectedRxQP, containerClient, envName, userId, projectId, qpId, datasource, dispatchInfo, dispatch) => {
  var fileName = "schedule/" + envName + "/" + userId + "/" + qpId + "/" + datasource._id + "/config.json";
  fileName = fileName.split(' ').join('_')

  const blockBlobClient = containerClient.getBlockBlobClient(fileName)
  await blockBlobClient.uploadBrowserData(selectedRxQP)
  dispatchInfo(dispatch, `Datasource ${datasource.name} updated to BlobStorage`)
}


//------------------------------------------------------------------------------------------------------------//


function getFileNameAndFolderForDeploy(environment, projectId, qualitypointName) {
  let object = {}
  let folder = "deploy/" + environment + '/' + projectId + '/'
  let filename = qualitypointName + ".json"
  filename = filename.split(' ').join('_')
  object.folder = folder
  object.filename = filename
  return object
}

function getFileNameAndFolderForSchedule(environment, userId, projectId, qpId, datasourceId) {
  let object = {}
  let folder = "schedule/" + environment + "/" + userId + "/" + qpId + "/" + datasourceId + "/"
  var fileName = "config.json";
  fileName = fileName.split(' ').join('_')

  object.folder = folder
  object.fileName = fileName
  return object
}



//------------------------------------------------------------------------------------------------------------//

