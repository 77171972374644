import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5radar from '@amcharts/amcharts5/radar'
import * as commonStyles from '../../../../../style/CommonStyles'

export default function SpiderChart(props) {

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (props.data && props.data.length > 0) {
            setData(props.data)
        }
    }, [props.data])

    React.useLayoutEffect(() => {
        let root = am5.Root.new(props.chart);
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/radar-chart/
        let chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                innerRadius: am5.percent(0.1),
                radius: am5.percent(70),
                arrangeTooltips: true,
            })
        );

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
        let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {

        }));

        cursor.lineY.set("visible", false);

        // Create axes and their renderers
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
        let xRenderer = am5radar.AxisRendererCircular.new(root, {
            minGridDistance: 30
        });
        xRenderer.labels.template.setAll({
            textType: "adjusted",
            radius: 10,
            paddingTop: 0,
            paddingBottom: 0,
            centerY: am5.p50,
            fontSize: "0.8em",
            minScale: 0
        });

        xRenderer.grid.template.setAll({
            location: 0.5,
            strokeDasharray: [4, 4]
        });

        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "dimension",
                renderer: xRenderer
            })
        );

        let yRenderer = am5radar.AxisRendererRadial.new(root, {
            minGridDistance: 30
        });

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer
            })
        );

        yRenderer.grid.template.setAll({
            strokeDasharray: [4, 4]
        });

        // Create series
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
        let series1 = chart.series.push(
            am5radar.RadarLineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "executions",
                connectEnds: true,
                categoryXField: "dimension",
                tooltipText: "{valueY}",
                showTooltipOn: "always",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "up",
                    paddingTop: 3,
                    paddingBottom: 2,
                    paddingLeft: 4,
                    paddingRight: 4,
                }),
            })
        );

        series1.strokes.template.setAll({
            strokeOpacity: 0
        });

        series1.fills.template.setAll({
            visible: true,
            fillOpacity: 0.5
        });


        series1.data.setAll(data);
        xAxis.data.setAll(data);

        series1.set("fill", am5.Color.fromString(commonStyles.colorOk));

        return () => {
            chart.dispose()
        }

    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <div id={props.chart} style={{ width: "100%", height: "250px" }}></div>
        </div>
    )
}