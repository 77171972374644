import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


export default function LollipopChart(props) {

    const [datos, setDatos] = React.useState([])
    

    React.useEffect(() => {
        if(props.data && props.data.length > 0){
            const dataNoReduce= (props.data.map(dt => {
                if (dt.createTime) {
                    var date = new Date(dt.createTime)
                    date.setHours(0, 0, 0, 0);
                    return {
                        ...dt,
                        "chartDate": date,
                        'score': Number(((dt.numOk / (dt.numOk+dt.numKo)) * 100).toFixed(0)),
                    }
                }
                else {
                    return {
                        ...dt,
                        "chartDate": new Date(),
                        'score': Number(((dt.numOk / (dt.numOk+dt.numKo)) * 100).toFixed(0)),
                    }
                }
            }))
            var dataReduce = []
            dataNoReduce.reduce(function (res, value) {
                if (!res[value.chartDate]) {
                    res[value.chartDate] = { chartDate: value.chartDate, score: 0, numIn:0, count: 0 };
                    dataReduce.push(res[value.chartDate])
                }
                res[value.chartDate].score += value.score;
                res[value.chartDate].numIn += value.numIn;
                
                res[value.chartDate].count += 1;
                
                return res;
            }, {})

            const dataFinal = dataReduce.map(x=>{return {fecha: x.chartDate.getTime(), numIn: x.numIn>0 ? x.numIn/x.count: 0, score: x.count>0 ? x.score/x.count: 0 }})
            setDatos(dataFinal)
        }
    }, [props.data]);

    React.useLayoutEffect(() => {
       
         let maxValue = Math.max(datos.map(x=>x.score))*1.2
         let root = am5.Root.new(props.chart);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.horizontalLayout,
                arrangeTooltips: false,
                maxZoomLevel: 1
            })
        );

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.5,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
            }),
            visible: false
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 1,
            min: 0,
            max: maxValue,
            strictMinMax: true,
            renderer: am5xy.AxisRendererY.new(root, {
            }),
            visible: false
        }));


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: props.value,
            valueXField: props.category
        }));

        chart.zoomOutButton.set("forceHidden", true);

        series.fills.template.setAll({
            visible: true,
            fillOpacity: 0.2
        });

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0,
                sprite: am5.Circle.new(root, {
                    radius: props.fullscreen === true ? 4 : 2,
                    stroke: root.interfaceColors.get("background"),
                    strokeWidth: props.fullscreen === true ? 2 : 1,
                    fill: series.get("fill")
                })
            });
        });

        series.data.setAll(datos);

        return () => {
            chart.dispose()
        }


    }, [datos]) // eslint-disable-line react-hooks/exhaustive-deps


    if(props.fullscreen === true){
        return (
          <div id={props.chart} style={{ width: props.width ? props.width : '100%', height:"100px", marginTop:'0px', 
          marginLeft: props.marginLeft ? props.marginLeft : '0px', marginBottom:  '0px' }}></div>
      )
      }
      else{
        return (
          <div id={props.chart} style={{ width: props.width ? props.width : '100%', height: props.height ? props.height :"100px", marginTop:'-15px', 
          marginLeft: props.marginLeft ? props.marginLeft : '0px', marginBottom: props.marginBottom ? props.marginBottom : '0px' }}></div>
      )
      }
}