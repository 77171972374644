
import React from 'react'
import { Button, } from '@material-ui/core'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import trackEvent from 'trackEvent'
import { useTheme } from '@material-ui/core/styles';

import DialogDisplayProjectVersions from 'components/dialogs/DialogProjectVersions/DialogDisplayProjectVersions'
import DialogConfirmProjectReversion from 'components/dialogs/DialogProjectVersions/DialogConfirmProjectReversion'





export default function VersionsButton(props) {
    
    const theme = useTheme();
    const [showDialog, setShowDialog] = React.useState(false)
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
    const [selectedVersion, setSelectedVersion] = React.useState(undefined)
    const [selectedVersionColor, setSelectedVersionColor] = React.useState(undefined)
    const [selectedVersionIcon, setSelectedVersionIcon] = React.useState(undefined)
    const [selectedVersionMessage, setSelectedVersionMessage] = React.useState(undefined)




    return (
        <div>
            <DialogDisplayProjectVersions
                userId={props.user.id}
                license={props.license}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                setShowConfirmDialog={setShowConfirmDialog}
                setSelectedVersion={setSelectedVersion}
                setSelectedVersionColor={setSelectedVersionColor}
                setSelectedVersionIcon={setSelectedVersionIcon}
                setSelectedVersionMessage={setSelectedVersionMessage}
                project={props.project}

            />
            <DialogConfirmProjectReversion
                userId={props.user.id}
                showConfirmDialog={showConfirmDialog}
                setShowDialog={setShowDialog}
                setShowConfirmDialog={setShowConfirmDialog}
                setSelectedVersion={setSelectedVersion}
                setSelectedVersionColor={setSelectedVersionColor}
                setSelectedVersionIcon={setSelectedVersionIcon}
                selectedVersion={selectedVersion}
                selectedVersionColor={selectedVersionColor}
                selectedVersionIcon={selectedVersionIcon}
                selectedVersionMessage={selectedVersionMessage}
                project={props.project}
            />


            <Button onClick={event => { setShowDialog(true); trackEvent('go back version', 'go back version Button') }}
                style={theme.buttons.buttonRegular}
                startIcon={<FontAwesomeIcon icon={faCodeBranch} style={{ fontSize: 15 }} />}
            >
                <div style={theme.common.text.buttonTextSmall}>
                    Project Versions
                </div>
            </Button>
        </div >
    )

}