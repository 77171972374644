

import React from 'react';
import TreeViewFileStorageDatabase from '../treeViews/TreeViewFileStorageDatabase';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import streamConfigurationReducer from 'reducers/streamConfigurationReducer';
import TreeViewStorageRestLocal from '../treeViews/TreeViewStorageRestLocal';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    rootView: {
        flexGrow: 1,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',

    },
    menu: {
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function SelectAzureItemDialogLocal(props) {

    const [azureStorageDataformat, setAzureStorageDataformat] = React.useState([])
    const user = useSelector(store => store.user)
    const dispatch = useDispatch();
    const [showTreeView, setShowTreeView] = React.useState(false)
    const theme = useTheme();
    const classes = useStyles();


    React.useEffect(() => { getAzureStorageDataFormats(user) }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    async function getAzureStorageDataFormats(user) {
        const dataformats = await dispatch(actions.getAzureStorageDataformats(user.id))
        if (dataformats) {
            setAzureStorageDataformat(dataformats)
        }
    }

    function alreadyExistantDataformat(storageAccount, container, item, type) {
        var already = false
        let containerName = container && container.name ? container.name.split('/').lenght > 1 ? container.name.split('/').at(-1) : container.name.split('/').at(0): ''
        let fileName
        if(type === 'folder'){
            fileName = item && item.name ? item.name.split('/').lenght > 1 ? item.name.split('/').at(-1) : item.name.split('/').at(0): ''

        }else{
            fileName = item && item.fileName ? item.fileName.split('/').at(-1) : ''
        }
        try {

            azureStorageDataformat.forEach(dataformat => {
                if (
                    dataformat.fileStorageProperties.account === storageAccount.storageAccountCredentialsDTO.storageAccountName &&
                    dataformat.fileStorageProperties.container === containerName &&
                    dataformat.fileStorageProperties.path.endsWith(fileName)) {


                    already = true

                }
            })
        }
        catch (e) {
            //SENTRY ERROR Event ID c826a52eaa6a4d5da1895bb55a557258
            console.error("Error un azureStoreDataformat")
            console.error(e)
        }

        return already
    }

    return (
        <>
            <div className={classes.root} style={{ width: '100%', marginBottom: '5%' }}>


                {!showTreeView &&
                    <Button onClick={event => { setShowTreeView(true) }}
                        style={theme.buttons.buttonRegular}
                        startIcon={<FontAwesomeIcon icon={faLink} style={{ fontSize: 15 }} />} >
                        <div style={theme.common.text.buttonTextSmall}>View local Storages</div>
                    </Button>


                }
                {showTreeView &&
                   /* <TreeViewFileStorageDatabase
                        setConnectToLocalStorages={props.setConnectToLocalStorages}
                        connectToLocalStorages={props.connectToLocalStorages}
                        selectedSubscription={props.selectedSubscription}
                        setSelectedItem={props.setSelectedItem}
                        alreadyExistantDataformat={alreadyExistantDataformat}
                        setRunId={props.setRunId}
                        setProfilingdataformatId={props.setProfilingdataformatId}
                        setProfilingBatchId={props.setProfilingBatchId}
                        setVisiblePreview={props.setVisiblePreview}
                        type={'azure_storage'}
                        storageType={'azure_storage'}
                        setFileOutputName={props.setFileOutputName}
                        numberSamples={props.numberSamples}
                        profilingSamples={props.profilingSamples}
                        flattenFlag={props.flattenFlag}
                        alreadyDefinedDataformats={props.alreadyDefinedDataformats}
                        selectedEnvironment={props.selectedEnvironment}
                        resetProfilingState={props.resetProfilingState}
                        azureManager={props.azureManager} />
                        */
                    <TreeViewStorageRestLocal
                        setConnectToLocalStorages={props.setConnectToLocalStorages}
                        connectToLocalStorages={props.connectToLocalStorages}
                        selectedSubscription={props.selectedSubscription}
                        setSelectedItem={props.setSelectedItem}
                        setRunId={props.setRunId}
                        setProfilingdataformatId={props.setProfilingdataformatId}
                        setProfilingBatchId={props.setProfilingBatchId}
                        setVisiblePreview={props.setVisiblePreview}
                        setFileOutputName={props.setFileOutputName}
                        numberSamples={props.numberSamples}
                        profilingSamples={props.profilingSamples}
                        flattenFlag={props.flattenFlag}
                        alreadyDefinedDataformats={props.alreadyDefinedDataformats}
                        selectedEnvironment={props.selectedEnvironment}
                        resetProfilingState={props.resetProfilingState}
                        azureManager={props.azureManager} 
                        selectedFormat={props.selectedFormat}
                    
                        />


                }
            </div>
        </>
    )
}