import React from 'react';
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, FormControlLabel, TextField, Divider, Switch, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useInterval } from 'components/common/useInterval'
import * as actions from 'actions'
import { dispatchError } from 'components/common/axios/axiosHelper';
import { useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import SelectEnvironmentComponent from 'components/common/SelectEnvironmentComponent'
import * as helper from './SelectAzureItemDialogHelper'
import ComponentPreviewData from '../ComponentPreviewData'
import ComponentProfilingStatus from '../ComponentProfilingStatus'
import ComponentProfilingBatchStatus from '../ComponentProfilingBatchStatus'
import TreeViewFileStorageDatabase from '../treeViews/TreeViewFileStorageDatabase';
import AqtivaLoading from 'components/common/AqtivaLoading';
export default function SelectAwsS3DatabaseDialog(props) {

    const user = useSelector(store => store.user)
    const dispatch = useDispatch();
    const theme = useTheme();

    const [runId, setRunId] = React.useState(undefined)

    const [previewDialogVisible, setPreviewDialogVisible] = React.useState(false)
    const [previewDialogParsingData, setPreviewDialogParsingData] = React.useState(false)

    const [schema, setSchema] = React.useState([])
    const [separator, setSeparator] = React.useState('')

    const [fileOutputName, setFileOutputName] = React.useState('')

    const [stats, setStats] = React.useState([])

    const [delimiter, setDelimiter] = React.useState(undefined)
    const [format, setFormat] = React.useState(undefined)
    const [dataPath, setDataPath] = React.useState(undefined)
    const [dataCount, setDataCount] = React.useState(1)
    const [outputPath, setOutputPath] = React.useState(undefined)

    const [errorBackend, setErrorBackend] = React.useState(undefined)
    const [numberSamples, setNumberSamples] = React.useState(100)
    const [flattenFlag, setFlattenFlag] = React.useState(true)

    const [profilingSamples, setProfilingSamples] = React.useState(200000)
    const [profilingdataformatId, setProfilingdataformatId] = React.useState(undefined)
    const [profilingBatchId, setProfilingBatchId] = React.useState(undefined)
    const [dataformatWithProfiling, setDataformatWithProfiling] = React.useState(undefined)
    const [profilingBatch, setProfilingBatch] = React.useState(undefined)
    const [profilingState, setProfilingState] = React.useState('')
    const [profilingBatchState, setProfilingBatchState] = React.useState('')

    const [sqlError, setSqlError] = React.useState('')
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [selectedAwsDTO, setSelectedAwsDTO] = React.useState({})


    const [selectedEnvironmentStates, setSelectedEnvironmentStates] = React.useState('')
    const [existantDataformats, setExistantDataformats] = React.useState([])
    const [loadingPreview, setLoadingPreview] = React.useState(false)
    const datasetFormats = ['AUTOMATIC', 'CSV', 'TXT', 'PARQUET', 'DELTA', 'HOODIE']
    const [selectedFormat, setSelectedFormat] = React.useState('AUTOMATIC')


    useInterval(() => {
        if (runId !== undefined) {

            dispatch(actions.getJobRunOutputForUser(runId, user.id, selectedEnvironment, numberSamples, flattenFlag)).then(output => {
                if (output && output.data && output.data.error && output.data.error !== null) {
                    //execution error
                    if (output.data.error === 'No output is available until the task begins.') {
                        setErrorBackend('Starting Aws server. Depends on the infrastructure it make take some time...')
                    }
                    else {
                        setErrorBackend(output.data.error)
                    }



                }
                if (output && output.data && output.data.metadata && output.data.metadata.state && output.data.metadata.state.result_state === 'FAILED') {
                    //preview failed
                    dispatchError(dispatch, output.data.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (output && output.data && output.data.state && output.data.state === 'FAILED') {
                    //preview failed
                    dispatchError(dispatch, output.data.errorMessage)
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setPreviewDialogVisible(false)
                    setPreviewDialogParsingData(false)
                }
                if (output && output.data && output.data.result && output.data.result !== null) {
                    setErrorBackend(undefined)
                    setRunId(undefined)
                    setSeparator(JSON.parse(output.data.result).separator)
                    setSchema(JSON.parse(JSON.parse(output.data.result).schema))
                    setStats(JSON.parse(JSON.parse(output.data.result).statistics))

                    setPreviewDialogVisible(true)
                    setPreviewDialogParsingData(false)


                    setDelimiter(JSON.parse(output.data.result).separator)
                    setFormat(JSON.parse(output.data.result).format)
                    setOutputPath(JSON.parse(output.data.result).outputPath)
                    setDataPath(JSON.parse(output.data.result).dataPath)
                    setDataCount(JSON.parse(output.data.result).count)
                }

            }
            )
        }

    }
        , 3000)

    useInterval(() => {
        if (profilingdataformatId !== undefined) {
            checkStatusProfilingRun()
            dispatch(actions.fetchDataformat(profilingdataformatId)).then(result => {
                switch (result.data.profilingStatus) {
                    case "starting": {
                        setProfilingState("starting")
                        break
                    }
                    case "in_progress": {
                        setProfilingState("in progress")
                        break
                    }
                    case "ok": {
                        setProfilingState("completed")
                        setDataformatWithProfiling(result.data)
                        break
                    }
                    case "ko": {
                        setProfilingState("some errors were encountered,enable to execute profiling job")
                        break
                    }
                    default:
                        break
                }
            }
            )
        }
        if (profilingBatchId !== undefined) {
            dispatch(actions.fetchProfilingBatch(profilingBatchId)).then(result => {
                switch (result.data.profilingStatus) {
                    case "starting": {
                        setProfilingBatchState("starting")
                        break
                    }
                    case "in_progress": {
                        if (result.data.numberOfProfilings && result.data.dataformatsWithProfiling && result.data.dataformatsWithProfiling.length > 0) {
                            let numberOfFinishedProfilingJobs = checkProfilingsState(result.data)
                            setProfilingBatchState(`in progress: ${numberOfFinishedProfilingJobs} out of ${result.data.numberOfProfilings} profilings finished`)
                        }
                        else {
                            setProfilingBatchState("in progress")
                        }
                        break
                    }
                    case "ok": {
                        setProfilingBatchState("completed")
                        setProfilingBatch(result.data)
                        break
                    }
                    case "ko": {
                        setProfilingBatchState("some errors were encountered,enable to execute profiling job")
                        break
                    }
                    default:
                        break
                }
            }
            )
        }

    }, 3000)

    function checkStatusProfilingRun() {
        if (runId != undefined) {

            dispatch(actions.getJobRunOutputForUser(runId, user.id, selectedEnvironment)).then(output => {
                if (output && output.data && output.data.state === 'FAILED') {
                    dispatchError(dispatch, output.data.errorMessage)
                    setProfilingState(output.data.errorMessage)
                    setProfilingdataformatId(undefined)
                    setProfilingBatchId(undefined)
                }
            })
        }
    }

    React.useEffect(() => {
        getAwsDataformats(user)
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps


    function checkProfilingsState(profilingBatch) {
        let numberOfFinishedProfilingJobs = 0
        for (let i = 0; i < profilingBatch.dataformatsWithProfiling.length; i++) {
            if (profilingBatch.dataformatsWithProfiling[i].profilingStatus && profilingBatch.dataformatsWithProfiling[i].profilingStatus === "ok") {
                numberOfFinishedProfilingJobs += 1
            }
        }
        return numberOfFinishedProfilingJobs
    }



    function setVisiblePreviewCallback(value) {
        setPreviewDialogParsingData(true)
        setPreviewDialogVisible(true)

    }

    function setPreviewDialogVisibleFull(value) {
        setPreviewDialogVisible(value)
        props.setVisible(false)
    }

    //función que convierte un esquema de previsualización en un objecto dataformat, función final del sistema
    async function convertSchema2Datasource() {
        if (schema !== undefined) {
            const dataset = helper.convertSchema2DatasourceAwsDatabase(schema, user, selectedAwsDTO, fileOutputName, separator)
            if (props.createNewVersion && props.createNewVersion === true && props.selectedDataset) {
                setLoadingPreview(true)
                await dispatch(actions.createNewDataformatVersion(dataset, props.selectedDataset._id))
                props.closeDialogDisplay(false)
                setLoadingPreview(false)
            }
            else {
                dispatch(actions.addDataFormatObject(dataset))
            }
            props.setVisible(false)
            props.setVisibleParent(false)
        }


    }

    function resetProfilingState() {
        setProfilingBatch(undefined)
        setProfilingBatchId(undefined)
        setProfilingBatchState('')
        setProfilingState('')
        setProfilingdataformatId(undefined)
        setDataformatWithProfiling(undefined)
    }

    async function getAwsDataformats(user) {
        const dataformats = await dispatch(actions.getAwsOnpremiseDataformats(user.id, "awsS3"))
        setExistantDataformats(dataformats)
    }

    function alreadyExistantDataformat(credential, bucketComplete, object, type) {

        let name = type === 'folder' ? object.name : object.key
        var already = false

        if (existantDataformats && existantDataformats.length > 0) {

            existantDataformats.forEach(dataformat => {

                if (dataformat.awsDatabaseProperties !== null && dataformat.awsDatabaseProperties !== undefined &&
                    dataformat.awsDatabaseProperties.bucket === bucketComplete.name &&
                    dataformat.awsDatabaseProperties.key === name &&
                    dataformat.awsDatabaseProperties.credentialsId === credential.id) {
                    already = true
                }
            })
        }
        return already
    }

    return (
        <AqtivaDialog visible={props.visible}
            completeScreen={true}
            fullWidth={true}
            title={'Inspect and Select AWS Database'}
            titleIcon={faWindows}
            confirmText={props.createNewVersion && props.createNewVersion === true && props.selectedDataset ? 'Create new version' : 'Create'}
            cancelText={'Close'}
            showConfirmButton={(schema !== undefined && schema.fields !== undefined) ? true : false}
            confirmCallback={() => { convertSchema2Datasource() }}
            cancelCallback={() => { props.setVisible(false) }}
        >
            <div style={{ width: '100%', height: '500px' }}>



                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div style={{ ...theme.elementAdjacent, marginBottom: '-20px', marginLeft: '10px' }}>
                            <div style={{ width: '150px', marginRight: '15px' }}>
                                <SelectEnvironmentComponent setSelectedEnvironment={setSelectedEnvironment}
                                    setSelectedEnvironmentStates={setSelectedEnvironmentStates} />
                            </div>
                            {undefined &&<FormControlLabel style={theme.config.formControl}
                                control={
                                    <TextField
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        id="nSamples"
                                        label={<Typography variant="caption">Samples to Preview</Typography>}
                                        style={theme.config.textField}
                                        margin="dense"
                                        value={numberSamples}
                                        onChange={(event) => setNumberSamples(event.target.value)}
                                    />} />}

                            <FormControlLabel style={theme.config.formControl}
                                control={
                                    <TextField
                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                        id="nSamples"
                                        label={<Typography variant="caption">Profiling Samples</Typography>}
                                        style={theme.config.textField}
                                        margin="dense"
                                        value={profilingSamples}
                                        onChange={(event) => setProfilingSamples(event.target.value)}
                                    />} />
                             {undefined && <FormControlLabel style={theme.config.formControl}
                                control={
                                    <Switch
                                        checked={flattenFlag}
                                        onChange={event => {
                                            setFlattenFlag(event.target.checked)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={<Typography variant="caption">Flatten Nested Structures</Typography>}
                            />}

                            <FormControl style={{ ...theme.config.formControl, minWidth: '300px', marginRight: '20px', marginTop: '5px' }}>
                                <InputLabel id="selectSubscription">Select Format</InputLabel>
                                <Select
                                    labelId="dselectSubscription"
                                    id="selectSubscription"
                                    value={selectedFormat}
                                    onChange={event => setSelectedFormat(event.target.value)}
                                >
                                    {datasetFormats.map(x => {
                                        return <MenuItem value={x} key={x}>
                                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                <Typography variant='subtitle2'>{x}</Typography>
                                            </div>
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>


                    {sqlError !== undefined && sqlError !== '' &&
                        <Grid item xs={12}>
                            <Alert severity="error">{sqlError}</Alert>


                        </Grid>

                    }
                    <Grid item xs={4} style={{ marginTop: '-20px' }}>
                        {<TreeViewFileStorageDatabase
                            setProfilingdataformatId={setProfilingdataformatId}
                            setProfilingBatchId={setProfilingBatchId}
                            setRunId={setRunId}
                            setVisiblePreview={setVisiblePreviewCallback}
                            setFileOutputName={setFileOutputName}
                            numberSamples={numberSamples}
                            profilingSamples={profilingSamples}
                            type={'aws_s3'}
                            storageType={'aws'}
                            alreadyExistantDataformat={alreadyExistantDataformat}
                            flattenFlag={flattenFlag}
                            setSqlError={setSqlError}
                            sqlError={sqlError}
                            selectedEnvironment={selectedEnvironment}
                            setSelectedItem={setSelectedAwsDTO}
                            resetProfilingState={resetProfilingState}
                            setPreviewVisible={setPreviewDialogVisible}
                            selectedFormat={selectedFormat}
                        />
                        }
                        {/*  <TreeViewAwsS3Database
                            setProfilingdataformatId={setProfilingdataformatId}
                            setProfilingBatchId={setProfilingBatchId}
                            setRunId={setRunId}
                            setVisiblePreview={setVisiblePreviewCallback}
                            setFileOutputName={setFileOutputName}
                            numberSamples={numberSamples}
                            profilingSamples={profilingSamples}
                            flattenFlag={flattenFlag}
                            setSqlError={setSqlError}
                            sqlError={sqlError}
                            selectedEnvironment={selectedEnvironment}
                            setSelectedAwsDTO={setSelectedAwsDTO}
                            resetProfilingState={resetProfilingState}
                            setPreviewVisible={setPreviewDialogVisible}
                        /> */}
                    </Grid>
                    <Grid item xs={8}>
                        {loadingPreview &&
                            <AqtivaLoading />
                        }
                        {!loadingPreview &&
                            <>
                                <ComponentPreviewData
                                    selectedEnvironment={selectedEnvironment}
                                    visible={previewDialogVisible}
                                    setVisible={setPreviewDialogVisible}
                                    setVisibleFull={setPreviewDialogVisibleFull}
                                    parsingData={previewDialogParsingData}
                                    fileOutputName={fileOutputName}
                                    schema={schema}
                                    errorBackend={errorBackend}
                                    stats={stats}
                                    delimiter={delimiter}
                                    format={format}
                                    dataPath={dataPath}
                                    outputPath={outputPath}
                                    dataCount={dataCount}
                                    selectedEnvironmentStates={selectedEnvironmentStates}
                                />
                                <ComponentProfilingStatus profilingState={profilingState} visible={true} dataformatWithProfiling={dataformatWithProfiling} />
                                <ComponentProfilingBatchStatus profilingBatchState={profilingBatchState} visible={true} setProfilingBatch={setProfilingBatch} profilingBatch={profilingBatch} />
                            </>

                        }
                    </Grid>

                </Grid>

            </div>

        </AqtivaDialog>
    )
}