
import React from 'react';
import { FormControlLabel, Grid, TextField, Typography, IconButton, Select, Input, Chip, Avatar, MenuItem, Switch, FormControl, InputLabel } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import * as theme from 'style/CommonStyles'
import { ILogicColumnCondition, ILogicConditionParameters } from 'interfaces/LogicCondition';


type IProps = {
    type?: string | undefined,
    setConditionParams: (value: ILogicConditionParameters) => (any),
    editObject?: ILogicColumnCondition

}

export default function ColumnConditionCreation(props: IProps) {

    const [null_check, set_null_check] = React.useState((props.editObject && props.editObject.params && props.editObject.params.nullCheck) ? props.editObject.params.nullCheck : false)
    const [not_null_check, set_not_null_check] = React.useState((props.editObject && props.editObject.params && props.editObject.params.notNullCheck) ? props.editObject.params.notNullCheck : false)
    const [textValueRange, setTextValueRange] = React.useState('');
    const [rule_range_array, setRule_range_array] = React.useState<string[]>((props.editObject && props.editObject.params && props.editObject.params.listValues) ? props.editObject.params.listValues : []);
    const [value_range_check, setValue_range_check] = React.useState((props.editObject && props.editObject.params) ? props.editObject.params.valueCheck : false);
    const [type_range, setType_range] = React.useState('between');
    const [rule_max_range, setRule_max_range] = React.useState<number | undefined>((props.editObject && props.editObject.params) ? props.editObject.params.greaterValue : undefined);
    const [rule_min_range, setRule_min_range] = React.useState<number | undefined>((props.editObject && props.editObject.params) ? props.editObject.params.lowerValue : undefined);
    const [value_range_bool, setValue_range_bool] = React.useState<boolean[]>((props.editObject && props.editObject.params && props.editObject.params.valueRangeBool) ? props.editObject.params.valueRangeBool : []);

    React.useEffect(() => {
        createCondition()
    }, [null_check, not_null_check, textValueRange, rule_range_array, value_range_check,// eslint-disable-line react-hooks/exhaustive-deps
        type_range, rule_max_range, rule_min_range, value_range_bool])// eslint-disable-line react-hooks/exhaustive-deps


    function createCondition() {

        const condition: ILogicConditionParameters = {
            lowerValue: rule_min_range,
            greaterValue: rule_max_range,
            listValues: rule_range_array,
            valueCheck: value_range_check,
            valueRangeBool: value_range_bool,
            nullCheck: null_check,
            notNullCheck: not_null_check,
            operator: (rule_range_array && rule_range_array.length > 0) || (value_range_bool && value_range_bool.length > 0) ? "in" : type_range

        }
        props.setConditionParams(condition)
        //cleanConditionValues()



    }

    function handleNullCheck(event: { target: { checked: React.SetStateAction<boolean>; }; }) {
        set_null_check(event.target.checked)
        set_not_null_check(false)
    }

    function handleNotNullCheck(event: { target: { checked: React.SetStateAction<boolean>; }; }) {
        set_not_null_check(event.target.checked)
        set_null_check(false)
    }

    function onClickAddString2List() {
        var array: string[] = rule_range_array ? rule_range_array : []
        array.push(textValueRange)
        setRule_range_array(array)
        setTextValueRange('')


    }

    function handleChangeValueRangeCheck(event: { target: { checked: React.SetStateAction<boolean | undefined>; }; }) {
        setValue_range_check(event.target.checked)
    }

    function onChangeTextValueRange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setTextValueRange(e.target.value)
    }
    function onChangeMinRange(e: { target: { value: any; }; }) {
        setRule_min_range(e.target.value)
    }
    function onChangeMaxRange(e: { target: { value: any; }; }) {
        setRule_max_range(e.target.value)
    }

    function onChangeRangeType(event: React.ChangeEvent<{ value: unknown }>) {
        setType_range(event.target.value as string)
    }

    function onDeleteSelectedItem(value: string) {
        var selectedColTemp: string[] = rule_range_array
        for (var i = 0; i < rule_range_array.length; i++) {
            if (value === rule_range_array[i]) {
                selectedColTemp.splice(i, 1);
                i--;
            }

        }
        setRule_range_array(selectedColTemp)
    }

    const handleChangeBoolean = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue_range_bool(event.target.value as boolean[]);
    };


    function createBooleanCondition() {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={null_check}
                                onChange={handleNullCheck}
                                value={null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is null</Typography>}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={not_null_check}
                                onChange={handleNotNullCheck}
                                value={not_null_check}
                                color="primary"
                            />
                        }
                        label={<Typography style={theme.inputLabelBig}>Execute if value is not null</Typography>}
                    />
                    {null_check === false && not_null_check === false &&
                        <Grid container spacing={2} alignItems='flex-end' >
                            <Grid item xs={4} >
                                <FormControl >
                                    <InputLabel id="demo-mutiple-checkbox-label">Select Values</InputLabel>
                                    <Select
                                        multiple
                                        style={{ width: '300px', height: '35px' }}
                                        value={value_range_bool}
                                        input={<Input id="select-multiple-checkbox" />}
                                        onChange={handleChangeBoolean}
                                    >

                                        <MenuItem
                                            key={'true'}
                                            value={'true'}
                                        >
                                            {'true'}
                                        </MenuItem>
                                        <MenuItem
                                            key={'false'}
                                            value={'false'}
                                        >
                                            {'false'}
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </div>
            </div>
        )

    }


    function createNullCheckCondition() {

        return (<div>
            <FormControlLabel
                control={
                    <Switch
                        checked={null_check}
                        onChange={handleNullCheck}
                        value={null_check}
                        color="primary"
                    />
                }
                label={<Typography style={{ fontSize: 13 }}>Execute if value is null</Typography>}
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={not_null_check}
                        onChange={handleNotNullCheck}
                        value={not_null_check}
                        color="primary"
                    />
                }
                label={<Typography style={{ fontSize: 13 }}>Execute if value is not null</Typography>}
            />


        </div>)

    }

    function createNumericCondition() {
        return (
            <div style={{ width: '100%' }}>

                <FormControlLabel
                    control={
                        <Switch
                            checked={null_check}
                            onChange={handleNullCheck}
                            value={null_check}
                            color="primary"
                        />
                    }
                    label={<Typography style={{ fontSize: 13 }}>Execute if value is null</Typography>}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={not_null_check}
                            onChange={handleNotNullCheck}
                            value={not_null_check}
                            color="primary"
                        />
                    }
                    label={<Typography style={{ fontSize: 13 }}>Execute if value is not null</Typography>}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={value_range_check}
                            onChange={handleChangeValueRangeCheck}
                            value={value_range_check}
                            color="primary"
                        />
                    }
                    label={<Typography style={{ fontSize: 13 }}>Value Range Selection</Typography>}
                />

                {null_check === false && not_null_check === false && !value_range_check &&
                    <div style={{ width: '100%' }}>
                        <Grid container spacing={2} alignItems='flex-end' >

                            <Grid item xs={4} >
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left'
                                }}>
                                    <TextField type='number'
                                        value={textValueRange}
                                        style={{ width: '100%' }}
                                        autoComplete='off'
                                        onChange={onChangeTextValueRange}
                                        label={<Typography style={{ fontSize: 11 }}>Add Value to List</Typography>}
                                        margin="dense"
                                    />
                                    <IconButton color="primary"
                                        onClick={onClickAddString2List}>
                                        <AddIcon />
                                    </IconButton>
                                </div>

                            </Grid>

                            <Grid item xs={5}>
                                <Select
                                    value={rule_range_array}
                                    style={{ width: '100%' }}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={selected => {
                                        return <div>

                                            {(selected as string[]) && (selected as string[]).map(value => (
                                                <Chip
                                                    avatar={<Avatar>
                                                        <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                            onClick={(e) => { onDeleteSelectedItem(value); }} /></Avatar>}
                                                    key={value}
                                                    label={value} />
                                            ))}
                                        </div>
                                    }}
                                >

                                    {rule_range_array &&
                                        rule_range_array.map(e => (
                                            <MenuItem key={e} value={e} >
                                                <Chip
                                                    avatar={<Avatar>
                                                        <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                            onClick={(event) => { onDeleteSelectedItem(e); }} /></Avatar>}
                                                    key={e}
                                                    label={e} />
                                            </MenuItem>
                                        ))}

                                </Select>
                            </Grid>
                        </Grid>
                    </div>
                }
                {null_check === false && not_null_check === false && value_range_check &&
                    <div>
                        <Grid container spacing={2} alignItems='flex-end'>
                            <Grid xs={2}>
                                <FormControl >
                                    <Select
                                        inputProps={{
                                            name: 'range type',
                                            id: 'range type'

                                        }}
                                        style={{ width: '100%', marginBottom: '12%' }}
                                        value={type_range}
                                        onChange={onChangeRangeType}

                                    >
                                        <MenuItem key={'lower'} value={'lower'}>
                                            <Typography>lower</Typography>
                                        </MenuItem>
                                        <MenuItem key={'greater'} value={'greater'}>
                                            <Typography>greater</Typography>
                                        </MenuItem>
                                        <MenuItem key={'between'} value={'between'}>
                                            <Typography>between</Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {type_range !== 'lower' && <Grid item xs={3}>
                                <TextField type='number'
                                    value={rule_min_range}
                                    autoComplete='off'
                                    onChange={onChangeMinRange}
                                    label={<Typography style={theme.inputLabel}>Min Value</Typography>}
                                    style={{ width: '100%' }}

                                    margin="dense"
                                />
                            </Grid>
                            }
                            {type_range !== 'greater' &&
                                <Grid item xs={3}>
                                    <TextField type='number'
                                        value={rule_max_range}
                                        autoComplete='off'
                                        onChange={onChangeMaxRange}
                                        label={<Typography style={theme.inputLabel}>Max Value</Typography>}
                                        style={{ width: '100%' }}
                                        margin="dense"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </div>
        )
    }

    function createStringCondition() {
        return (
            <div>
                {createNullCheckCondition()}
                {null_check === false && not_null_check === false &&
                    <Grid container spacing={2} alignItems='flex-end' >

                        <Grid item xs={4} >
                            <div style={theme.adjacentSpaceBetween}>
                                <TextField type='string'
                                    value={textValueRange}
                                    style={{ width: '100%' }}
                                    autoComplete='off'
                                    onChange={event => setTextValueRange(event.target.value)}
                                    label={<Typography style={{ fontSize: 13 }}>Add Value to List</Typography>}
                                    margin="dense"
                                />
                                <IconButton color="primary"
                                    onClick={onClickAddString2List}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </Grid>

                        <Grid item xs={5}>
                            <Select
                                multiple
                                value={rule_range_array}
                                style={{ width: '100%' }}
                                input={<Input id="select-multiple-checkbox" />}
                                renderValue={selected => {
                                    return <div>
                                        {(selected as string[]) && (selected as string[]).map(value => (
                                            <Chip
                                                avatar={<Avatar>
                                                    <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                        onClick={(e) => { onDeleteSelectedItem(value); }} /></Avatar>}
                                                key={value}
                                                label={value} />
                                        ))}
                                    </div>
                                }}
                            >

                                {rule_range_array &&
                                    rule_range_array.map(e => (
                                        <MenuItem key={e} value={e} >
                                            <Chip
                                                avatar={<Avatar>
                                                    <CloseIcon style={{ fontSize: '8px', cursor: 'pointer' }}
                                                        onClick={(event) => { onDeleteSelectedItem(e); }} /></Avatar>}
                                                key={e}
                                                label={e} />
                                        </MenuItem>
                                    ))}

                            </Select>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }


    switch (props.type ? props.type.toLowerCase() : props.type) {
        case 'boolean':
            return createBooleanCondition()
        case 'integer':
            return createNumericCondition()
        case 'float':
            return createNumericCondition()
        case 'double':
            return createNumericCondition()
        case 'number':
            return createNumericCondition()
        default:
            return createStringCondition()
    }
}

