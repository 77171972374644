
import React from 'react';
import { Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Stepper, Step, StepLabel } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import BasicRuleDataForm from './BasicRuleDataForm'
import * as selectionHelper from './SpecificRuleDataForm/RuleSelectionSpecificFormHelper'
import * as ruleHelper from './CreateRuleHelper'
import * as actions from 'actions'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from 'trackEvent'
import ExecutionConditionRule from './ExecutionConditionRule';
import * as helper from 'components/common/EnrichDatasourceComponent/EnrichDatasourceTable/ErrorUtils'




export default function DialogCreateRule(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(store => store.user)

  //variables de estado
  const [editable, setEditable] = React.useState(props.rule ? true : false)
  const [activeStep, setActiveStep] = React.useState(0);
  const [ruleType, setRuleType] = React.useState(props.rule ? ruleHelper.getRuleClassType(props.rule.type ? props.rule.type : props.rule.classType) : '')
  const steps = getSteps();
  const [commonRule, setCommonRule] = React.useState()
  const [specificRule, setSpecificRule] = React.useState()
  const [executionConditions, setExecutionConditions] = React.useState([])

  const [mandatoryTags, setMandatoryTags] = React.useState([])
  const [optionalTags, setOptionalTags] = React.useState([])

  const [mandatoryErrorTags, setMandatoryErrorTags] = React.useState("")
  const [source, setSource] = React.useState()

  const [joins, setJoins] = React.useState([]);
  const [errorsMasterData, setErrorsMasterData] = React.useState();
  const [errorsComparison, setErrorsComparison] = React.useState();
  const [aggregationObject, setAggregationObject] = React.useState()

  React.useEffect(() => {
    if (user && user.id) {
      dispatch(actions.fetchConfigurationByUserId(user.id))
      //dispatch(actions.fetchDataFormats(user))
    }

  }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setRuleType(props.rule ? ruleHelper.getRuleClassType(props.rule.type ? props.rule.type : props.rule.classType) : '')
    setEditable(props.rule ? true : false)
    setExecutionConditions(props.rule ? props.rule.conditions : [])

  }, [props.rule]);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    dispatch(actions.fetchDataFormats(user))

    if (props.rule && props.rule.enrichDataformatObject && props.rule.enrichDataformatObject.joins) {
      setJoins(props.rule.enrichDataformatObject.joins)
    }
    if (props.rule && props.rule.aggregationObject) {
      setAggregationObject(props.rule.aggregationObject)
    }
    else {
      setAggregationObject(undefined)
    }

  }, [props.rule]);// eslint-disable-line react-hooks/exhaustive-deps


  function cleanAggregation() {
    let aux = aggregationObject.aggregationOperations.filter(op =>
      op.alias !== '' && op.column !== '' && op.operation !== ''
    )

    props.rule.aggregationObject.aggregationOperations = aux

    setAggregationObject(JSON.parse(JSON.stringify(props.rule.aggregationObject)))
  }

  function clearRule() {
    setRuleType('')
    setCommonRule(undefined)
    setSpecificRule({})
    setActiveStep(0)
    setJoins([])
  }

  function setRuleTypeCallback(type) {

    setRuleType(ruleHelper.getRuleClassType(type))
  }

  //wrapper to save rule
  const saveItem = () => {
    handleFinish()
    props.setClose(false)
  }

  //wrapper to edit rule
  const editItem = () => {
    handleFinish()
    props.setClose(false)
  }

  function setSpecificRuleCallback(value) {
    setSpecificRule(value)
  }

  function setCommonRuleCallback(rule) {
    if (!props.open) {
      setCommonRule(undefined)
    }else{
      setCommonRule(rule)
    }
  }

  function setExecutionConditionsCallback(conditions) {
    setExecutionConditions(conditions)
  }

  function validateInputs(states, type) {
    const validations = {
      name: {
        rule: /^[\w_]*$/.test(states.name),
        description: "Incorrect name. The name must only contain letters, numbers and _"
      }
    }
    if (type) {
      return validations[type]
    }
    return Object.values(validations).every((value) => value.rule === true)
  }

  /*
  **** FUNCIONES RELACIONADAS CON STEPPER
  */
  function getSteps() {
    return ['Basic Rule Data', 'Specific Rule Data', 'Execution Conditions'];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <BasicRuleDataForm project={props.project} setRuleType={setRuleTypeCallback}
          setCommonRule={setCommonRuleCallback}
          commonRule={commonRule}
          rule={props.rule}
          aggregationObject={aggregationObject}
          setAggregationObject={setAggregationObject}
          datasource={props.datasource}
          qualityPoint={props.qualityPoint}
          folder={props.folder}
          validateInputs={validateInputs}
          closeDialog={props.open}
          setMandatoryTags={setMandatoryTags}
          setOptionalTags={setOptionalTags}
          mandatoryErrorTags={mandatoryErrorTags} setJoins={setJoins} joins={joins} userId={user.id} setSource={setSource} />
      case 1:
        //get dataformat associated to datasource
        return selectionHelper.selectSpecificForm(ruleType, commonRule, commonRule.common.datasource, setSpecificRuleCallback, props.rule, specificRule, errorsMasterData, errorsComparison, source)
      case 2:

        return <ExecutionConditionRule project={props.project} commonRule={commonRule} setConditions={setExecutionConditionsCallback} conditions={executionConditions} columnsEnriched={source}
        />
      case 3:

        return '';
      default:
        return 'Unknown step';
    }
  }

  function handleFinish() {
    let tagsMandatory = mandatoryTags
    let tagsOptional = optionalTags
      ? optionalTags.filter(
        (tag) => tag.tagValue !== undefined || tag.tagValue !== ""
      )
      : [];
    let allTags = tagsMandatory.concat(tagsOptional);

    const ruleId = props.rule ? props.rule._id : undefined
    if (aggregationObject && aggregationObject.aggregationObject && aggregationObject.aggregationObject.length > 0) cleanAggregation()
    const rule = ruleHelper.createRuleForBackend(commonRule, specificRule, ruleId, executionConditions, allTags, joins, aggregationObject)

    if (props.compound) {
      props.setChildCompoundRule(rule)
    }
    else {
      if (ruleId) {
        dispatch(actions.editRule(props.project._id, commonRule.common.qualityPoint._id, commonRule.common.datasource._id, rule, user.id))

      }
      else {

        dispatch(actions.addRule(props.project._id, commonRule.common.qualityPoint._id, commonRule.common.datasource._id, rule, user.id))



      }
    }
    setActiveStep(0)
    clearRule()

  }


  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleFinish()
    }
    if (activeStep === 0) {
      //Here we check for rule errors
      const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = ruleHelper.validateCommonRule(commonRule)
      //TODO: avoid warnings
      if (arrayErrorTypes && arrayErrorMsgs) { }
      let hasTagErrors = false
      let emptyMandatoryTags = mandatoryTags.filter(tag => tag.tagValue === undefined || tag.tagValue === '')
      //here we check for tag errors
      if (emptyMandatoryTags.length > 0) {
        hasTagErrors = true
        setMandatoryErrorTags("Please complete all Mandatory tags.")
      }
      if (hasTagErrors) {
        return
      }
      if (hasErrors) {
        return
      }
    }
    if (activeStep === 1) {
      let hasErrors = validateSpecificRule()
      if (hasErrors) {
        return
      }
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };



  const handleReset = () => {
    setActiveStep(0);
  };

  function validateSpecificRule() {

    if (ruleType === "masterData") {
      let errorsToCheck = validateSingleSourceOfTruthRule()
      if (errorsToCheck.hasErrors) {
        setErrorsMasterData(errorsToCheck)

        return true
      }
    }
    if (ruleType === "comparison") {
      let errorsToCheck = validateComparisonRule()
      if (errorsToCheck.hasErrors) {
        setErrorsComparison(errorsToCheck)

        return true
      }
    }

  }

  function validateSingleSourceOfTruthRule() {
    let join = specificRule && specificRule.masterDataJoin
    let allAliases = []
    let errorsToCheck = helper.checkAllFields(join, allAliases)
    return errorsToCheck
  }
  function validateComparisonRule() {
    let columnOperations = specificRule && specificRule.columnOperations
    let errorsToCheck = helper.checkAllComparisons(columnOperations)
    return errorsToCheck
  }

  /*
  */

  return (
    <Dialog open={props.open}
      fullWidth={true}
      maxWidth={'lg'}>
      <DialogTitle style={theme.dialogs.dialogTitle}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <img alt="" style={{ height: '25px', width: '28px', marginLeft: '5px', marginRight: '5px', marginTop: '5px' }}
              src={require('resources/icons/rule_proc1_white.png')} />

          </Grid>
          <Grid item >
            <Typography style={theme.dialogs.dialogTitleText}>
              {editable ? 'Edit Rule' : 'Create Rule'}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>

        <Stepper activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};


            return (
              <Step key={label} {...stepProps} style={{ color: '#FFFFFF' }}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Grid>
                All steps completed - you&apos;re finished
              </Grid>
              <Button onClick={event => {
                trackEvent('CreateRule', 'Reset Button')
                handleReset(event)
              }} style={theme.buttons.buttonConfirm}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
            </div>
          )}
        </div>

      </DialogContent>

      <DialogActions>

        <div>
          {activeStep !== 0 &&
            <Button disabled={activeStep === 0} onClick={event => {
              trackEvent('CreateRule', 'Back Button')
              handleBack(event)
            }} variant='outlined' style={theme.buttons.buttonConfirm}>
              Back
            </Button>}


          {activeStep !== (steps.length - 1) && <Button
            disabled={commonRule && commonRule.common ? !validateInputs({ name: commonRule.common.name }) : false}
            variant='outlined'
            onClick={event => {
              trackEvent('CreateRule', 'Next Button')
              handleNext(event)
            }}
            style={theme.buttons.buttonConfirm}
          >
            Next
          </Button>}
        </div>


        {!editable && activeStep === (steps.length - 1) && <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}
          onClick={event => {
            trackEvent('CreateRule', 'Save Button')
            saveItem(event)
          }}>Save</Button>}
        {editable && activeStep === (steps.length - 1) && <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}
          onClick={event => {
            trackEvent('CreateRule', 'Edit Button')
            editItem(event)
          }}>Edit</Button>}
        <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel} onClick={event => {
          trackEvent('CreateRule', 'Cancel Button')
          clearRule()
          props.setClose(false)
        }}>Cancel
        </Button>
      </DialogActions>

    </Dialog>
  )
}