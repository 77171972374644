
import React from 'react';
import { createStyles, FormControl, InputLabel, makeStyles, NativeSelect, Theme } from '@material-ui/core';
import { ILogicColumnCondition } from 'interfaces/LogicCondition';
import { IColumn } from '../../../../interfaces/RuleInterfaces';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import AqtivaDialog from 'components/common/AqtivaDialog';
import ColumnConditionCreation from './ColumnConditionCreation';
import { ILogicConditionParameters } from '../../../../interfaces/LogicCondition';


type IProps = {
  edit: boolean,
  closeEdit: () => (void),
  condition: ILogicColumnCondition,
  editCondition: (value: ILogicColumnCondition, ix: number) => any,
  ixOp: number,
  columns: IColumn[]
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);


export default function DialogEditConditionOperator(props: IProps) {
  const classes = useStyles();

  const [tmpCondition, setTmpCondition] = React.useState<ILogicColumnCondition>(props.condition)


  React.useEffect(() => {
    setTmpCondition(props.condition)
  }, [props.condition])

  function setColumnConditionColumn(col: string) {
    var newCondition: ILogicColumnCondition = JSON.parse(JSON.stringify(tmpCondition))
    newCondition.columnName = col
    setTmpCondition(newCondition)
  }

  function editConditionParams(conditionParams: ILogicConditionParameters) {
    var newCondition: ILogicColumnCondition = JSON.parse(JSON.stringify(tmpCondition))
    newCondition.params = conditionParams
    setTmpCondition(newCondition)
  }

  function editCallback() {
    props.editCondition(tmpCondition, props.ixOp)
    props.closeEdit()
  }


  return (
    <AqtivaDialog visible={props.edit}
      title={`Add Operator`}
      maxWidth='lg'
      fullWidth={true}
      titleIcon={faKey}
      cancelText={'Close'}
      showConfirmButton={true}
      confirmCallback={editCallback}
      cancelCallback={props.closeEdit}
    >
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-helper">Select a Column</InputLabel>
        <NativeSelect
          value={tmpCondition && tmpCondition.columnName}
          onChange={event => setColumnConditionColumn(event.target.value)}
          inputProps={{
            name: 'age',
            id: 'age-native-helper',
          }}
        >
          {props.columns &&
            props.columns.map(col => {
              return <option value={col.name}>{`${col.name} (${col.type})`}</option>
            })}

        </NativeSelect>
      </FormControl>
      <ColumnConditionCreation type={tmpCondition.columnType} setConditionParams={editConditionParams} editObject={tmpCondition} />

    </AqtivaDialog>

  )

}