export function renderDescription(storage) {
    let description
    if (storage.storageType === "azure-storage") {
        description = `${storage.storageProperties.containerName}@${storage.storageProperties.storageAccountName}/${storage.storageProperties.path}`
    }
    if (storage.storageType === "azure-sqldatabase") {
        description = `${storage.sqlProperties.database}@${storage.sqlProperties.schema}/${storage.sqlProperties.table}`
    }
    if (storage.storageType === "AWS") {
        description = `${storage.awsProperties.s3Keyid} ${storage.awsProperties.s3Region } ${storage.awsProperties.s3BucketName}`
    }
    if (storage.storageType === "bigQuery") {
        description = `${storage.bigQueryProperties.projectId} ${storage.bigQueryProperties.region } ${storage.bigQueryProperties.bucket}`
    }
    return description
}