import React from 'react'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import ProjectDetail from './ProjectDetail/index'
import ExecutionRulesTable from './ExecutionRulesTable'
import { useTheme } from '@material-ui/core/styles';
import * as commonStyles from 'style/CommonStyles'
import ProfilingTable from 'components/common/ProfilingComponent/ProfilingTable';
import {MoneyFormat} from 'commonFunctions/commonFunctions'

export default function ExecutionDetail(props) {

    const theme = useTheme()
    const [tabValue, setTabValue] = React.useState(0)
    const [project, setProject] = React.useState([])
    const [hideNodes, setHideNodes] = React.useState([])
    const [rules, setRules] = React.useState([])
    const [qualityPoint, setQualityPoint] = React.useState([])
    //const [profilingData, setProfilingData] = React.useState([])
    const profilingData = []
    const [dataSource, setDataSource] = React.useState([])
    const [rows, setRows] = React.useState([])
    const [executionDate, setExecutionDate] = React.useState('')
    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }

    const [executionDQI, setExecutionDQI] = React.useState(0)

    // setHideNodes(response.data.qualityPointDQI === undefined ? [] : 
    // response.data.qualityPointDQI.flatMap(qpDQI=>qpDQI.datasourceDQI).map(ds=>ds.datasourceId))

    React.useEffect(() => {
        if (props.project) setProject(props.project)
        if (props.qualityPoint) setQualityPoint(props.qualityPoint)
        if (props.project && props.project.qualityPointDQI) setHideNodes(props.project.qualityPointDQI.flatMap(qpDQI => qpDQI.datasourceDQI).map(ds => ds.datasourceId))
        if (props.execution && props.execution.length > 0) {
            setRules(props.execution.filter(ex => ex.type === 'QUALITY_RULE'))
            setRows(props.execution.filter(ex => ex.type === 'QUALITY_POINT').reduce((a, v) => a = a + v.numIn, 0))
            try{
                setExecutionDQI(Number(props.execution.filter(ex => ex.type === 'QUALITY_POINT')[0].dqiWeight*100).toFixed(2)  )
              
            }
            catch (e) {
                //TODO: no quality point row
            }
            
            let date = props.execution.filter(ex => ex.type === 'QUALITY_POINT')[0].createTime.split('T')

            setExecutionDate(date[0] + ' - ' + date[1].substring(0,8))
        }
        if(props.dataSource) setDataSource(props.dataSource)


    }, [props.detail, props.project, props.execution, props.qualityPoint])// eslint-disable-line react-hooks/exhaustive-deps

   
    console.log('rows', rows)
    return (

        <div style={{ width: '100%', marginLeft: '4%', height: props.fullscreen === true ? '500px' : '' }}>
            <Grid container spacing={3} >
                <Grid item xs={3} >
                <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Execution DQI:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}>{`${executionDQI}%`} </Typography>
                    </div>
                    <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Quality Point:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}>{qualityPoint ? qualityPoint.name: ''} </Typography>
                    </div>
                    <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Project:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}>{props.project ? props.project.name: ''}</Typography>
                    </div>
                    <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Enviroment:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}>{(props.execution && props.execution[0]) ? props.execution[0].environment : ''}</Typography>
                    </div>


                </Grid >
                <Grid item xs={3} >
                    {/* <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Columns:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}></Typography>
                    </div>]*/}
                    <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Datasource:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}> {dataSource.name}</Typography>
                    </div>
                    <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Rows:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}> {MoneyFormat(rows)}</Typography>
                    </div>

                </Grid >
                <Grid item xs={3} >
                    <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Rules:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}>{rules.length}</Typography>
                    </div>
                    <div style={theme.elementAdjacent}>
                        <Typography style={{ ...commonStyles.tableCellStylePx, fontWeight: 'bold' }}>Execution´s Date:</Typography>
                        <Typography style={commonStyles.tableCellStylePx}>{executionDate}</Typography>
                    </div>

                </Grid >
            </Grid>


            <Tabs value={tabValue} onChange={handleTabChange}
                variant="scrollable" scrollButtons="off"
            >
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Rules`}</Typography> </div>}
                />
                {
                // set flag to control if Project Tree DQI is shown. Used mainly for AqtivaQualityIntegration component
                }
                {props.projectTreeViewFlag!==false && 
                <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}> <Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Project Tree DQI`}</Typography> </div>}
                />  
                }
                { /* <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Profile`}</Typography> </div>} 
                />*/}
                { /*  <Tab disabled label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`DataDistribution`}</Typography> </div>}
                />*/}
                
                { /* <Tab disabled label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}><Typography variant='caption' style={{ marginTop: '5px' }}>
                    {`Data Preview`}</Typography> </div>}
                />*/}
            </Tabs>
            <div style={{ width: '100%', height: '100%' }}>
                {
                    tabValue === 1 &&
                    <div style={{ width: '100%', marginBottom: "2%", height: '98%' }}>
                        <ProjectDetail detailedDQIProject={project} hideNodes={hideNodes} fullscreen={props.fullscreen} />
                    </div>
                }

                {
                    tabValue === 5 &&
                    <div style={{ width: '100%', marginBottom: "2%", height: '98%' }}>
                        <ProfilingTable data={profilingData} />
                    </div>
                }

                {
                    tabValue === 2 &&
                    <div style={{ width: '100%', marginBottom: "2%", height: '98%' }}>

                    </div>
                }
                {
                    tabValue === 0 &&
                    <div style={{ width: '100%', marginBottom: "2%", height: '98%' }}>
                        <ExecutionRulesTable rules={rules} />
                    </div>
                }
                {
                    tabValue === 4 &&
                    <div style={{ width: '100%', marginBottom: "2%" }}>

                    </div>
                }
            </div>

        </div>
    )
}