import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AqtivaDialogDataRepository } from "../../AqtivaDialogDataRepository";
import {getFileShared} from "../../Helpers/getterDTOs"
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {findAuthorNameFromId} from "../../Helpers/getterDTOs"
import Graph from "react-graph-vis";
import PublicIcon from "./graph/public.png";
import PrivateIcon from "./graph/private.png";

import * as commonStyles from 'style/CommonStyles'


export function OptionGraph({ showDialog, setShowDialog, entityName, ...props }) {


const entityDataRepoShared = useSelector((store) =>
Object.values(store[entityName + 'Shared'])
);


  const [graph, setGraph] = useState(null);
  const license = useSelector((store) => store.license);

  useEffect(() => {
    if (props.selectedItemShared.itemId) {
      setGraph(
          parseQualityEntityRepoDTOToGraph(
            getFileShared(
            entityDataRepoShared,
            props.selectedItemShared.itemId
          )
        ))

    }
  }, [props.selectedItemShared.itemId]);// eslint-disable-line react-hooks/exhaustive-deps

/*   function findAuthorNameFromId(license, userId) {
    if (license && license.users && license.users.length > 0 && userId) {
      let userFound = license.users.filter((x) => x.id === userId);

      if (userFound && userFound.length > 0) {
        return userFound[0].name;
      }
    }
    return "";
  }
 */
  function printNodeData (entity, user, version) {
    return [
      "<b>Project:</b> <i>" + entity + "</i>",
      "<b>User:</b> <i>" + findAuthorNameFromId(license, user) + "</i>",
      "<b>Version:</b> <i>" + version + "</i>",
    ].join("\n")
  }
  
  function parseQualityEntityRepoDTOToGraph(qualityEntityRepoDTO) {
    if (qualityEntityRepoDTO===undefined || qualityEntityRepoDTO.audit === null) {
      setShowDialog(false)
    } else {
      const dataGraphs = qualityEntityRepoDTO.audit.originEntities.map((originEntity) => {
        return {
          version: originEntity.version,
          user: qualityEntityRepoDTO.ownerId,
          name: qualityEntityRepoDTO.name,
          id: qualityEntityRepoDTO._id, // -v${originProject.version}}
          destinationEntities: qualityEntityRepoDTO.audit.destinationEntities ? qualityEntityRepoDTO.audit.destinationEntities
            .filter(
              (oneDestinationEntity) =>
                oneDestinationEntity.version === originEntity.version
            )
            .map((oneDestinationEntity) => {
              return {
                name: oneDestinationEntity.name,
                user: oneDestinationEntity.userId,
                version: oneDestinationEntity.version,
                id: oneDestinationEntity.id
              };
            }) : {},
          originEntities: {
            name: originEntity.name,
            user: originEntity.userId,
            version: originEntity.version,
            id: originEntity.id
          },
        };
      });
      
      const edges = dataGraphs.flatMap((dataGraph) => {
        const mainEdge = { 
          from: dataGraph.id,
          to: dataGraph.originEntities.id,
          length: 200,
          color:"#EF8354",
          width:1, 
          label: "<b>exported to</b>", 
          font: { align: "horizontal",  multi: "html", color:'#474b51', size:"12" } 
        }
        const destinationEdges = (dataGraph.destinationEntities && dataGraph.destinationEntities.length>0 )? dataGraph.destinationEntities
          .map((oneDestinationEntity) => {
            return { from: dataGraph.originEntities.id, to: oneDestinationEntity.id, length: 200, /* color: "#684551", */color: "#586BA4", width:2, 
            font: { align: "horizontal",  multi: "html", color:'474b51', size:"12" },
             label:"<b>imported to</b>" }
          }) : []
        return [mainEdge, ...destinationEdges]
      })

        const mainNode = [dataGraphs[0]].flatMap((dataGraph) => { 
        return {
          id: dataGraph.id,
          font: { multi: "html", size: "12" },
          shape: "image",
          image: PrivateIcon,
          margin: 500,
          size: 25,
          label: printNodeData( dataGraph.originEntities.name, dataGraph.originEntities.user, dataGraph.originEntities.version ),
          color: commonStyles.mainColor,
          level: 0,
          fixed: true,
        }
      }); 

   //Otra solución para evitar usar [dataGraphs[0]] podría ser un filter, pero nos devuelve un error...  "already exists" 
/* 
      const mainNode = dataGraphs.filter((dataGraph)=>{
        return {
          id: dataGraph.id,
          font: { multi: "html", size: "10", lineHeight:"25" },
          shape: "image",
          image: PrivateIcon,
          margin: 500,
          size: 25,
          label: printNodeData( dataGraph.originProjects.name, dataGraph.originProjects.user, dataGraph.originProjects.version ),
          color: commonStyles.mainColor,
          level: 0,
          fixed: true,
        }
      }
      ) */
      const nodes =  dataGraphs.flatMap((dataGraph) => {
        const centerNodes = [{
          id: dataGraph.originEntities.id,
          font: { multi: "html", size: "12" },  // color: commonStyles.mainColor, 
          shape: "image",
          image: PublicIcon,
          margin: 500,
          size: 25,
          label: printNodeData(dataGraph.name, dataGraph.user, dataGraph.version ),
          color: commonStyles.mainColor,
          level: 2,
          fixed: true,
        }
      ]
    
        const destinationNodes = (dataGraph.destinationEntities && dataGraph.destinationEntities.length>0 ) ? 
        dataGraph.destinationEntities.map((oneDestinationEntity) => {
            return {
              id: oneDestinationEntity.id,
              font: { multi: "html", size: "12" },
              shape: "image",
              image: PrivateIcon,
              margin: 500,
              size: 25,
              label: printNodeData(oneDestinationEntity.name, oneDestinationEntity.user, oneDestinationEntity.version ),
              color: commonStyles.mainColor,
              level: 4,
              fixed: true,
            }
          }) : []
        return [...centerNodes, ...destinationNodes]
        
      })
      return {edges, nodes: [...nodes, ...mainNode]}
    }
  }

  const options = {
    layout: {
      hierarchical: {
        direction: "LR",
      },
    },
    autoResize: true,
    edges: {
      smooth: {
        type: "cubicBezier",
        roundness: 0.8,
      },
    },
    nodes: {
      shape: "image",
      scaling: {
        label: {
          min: 8,
          max: 20,
        },
      },
    },
    physics: {
      enabled: false,
    },
  };

  return (
    <>
      <AqtivaDialogDataRepository
        dialog={!!showDialog}
        setDialog={setShowDialog}
        title={"Audit"}
        titleIcon={faSearch}
        confirmText={"Close"}
        showConfirmButton={false}
      > { 
        graph ?
          <Graph
            graph={graph}
            options={options}
            style={{ height: "500px", width: "1000px" }}
          /> : null
        }
      </AqtivaDialogDataRepository>
    </>
  );
}
