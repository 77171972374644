
import quality from '../apis/quality';


import { axiosHeader, dispatchError, axiosHeaderSendFile } from '../components/common/axios/axiosHelper';
import qualityLocalHost from '../apis/qualityLocalHost';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';






export const listStorageAccountListSimple = (userId, environment) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/filestorage/azure/list/storageacc/user/${userId}/env/${environment}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const listCosmoDbSimple = (userId, environment) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/filestorage/azure/list/cosmodb/user/${userId}/env/${environment}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const listSqlDatabaseSimple = (userId, environment) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.get(`/filestorage/azure/list/sqldatabase/user/${userId}/env/${environment}`, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const getStorageAccountDetail = (userId, environment, dto) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.post(`/filestorage/azure/detail/storageacc/user/${userId}/env/${environment}`, dto, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const getCosmoAccountDetail = (userId, environment, dto) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  await quality.post(`/filestorage/azure/detail/cosmodb/user/${userId}/env/${environment}`, dto, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const getStorageItemChildren = (userId, environment, dto, storageDTO) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  var data = {
    "storageAccountItemDTO": dto,
    "storageAccountDTO": storageDTO
  }

  await quality.post(`/filestorage/azure/children/storageacc/user/${userId}/env/${environment}`, data, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}



export const getDataContainer = (data) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }


  await quality.post(`/filestorage/azure/children/storageacc/container`, data, {
    headers: axiosHeader()
  }).then(function (response) {
    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}




export const getSQLDatabaseDetail = (user, pwd, server, dto) => async (dispatch, getState) => {
  dto.error = undefined
  var returnValue = { result: 'undefined' }
  await quality.post(`/filestorage/azure/sqldatabase/detail/user/${user}/pwd/${pwd}/server/${server}`, dto, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    if (response.data.error !== undefined && response.data.error !== "" && response.data.error !== "null" && response.data.error !== null) {
      dispatchError(dispatch, response.data.error)
    }

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const getSQLSynapseDetail = (user, pwd, server, dto) => async (dispatch, getState) => {
  dto.error = undefined
  var returnValue = { result: 'undefined' }
  await quality.post(`/filestorage/azure/sqlsynapse/detail/user/${user}/pwd/${pwd}/server/${server}`, dto, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    if (response.data.error !== undefined && response.data.error !== "" && response.data.error !== "null" && response.data.error !== null) {
      dispatchError(dispatch, response.data.error)
    }

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const getSQLTables = (dbType, credentials, retry) => async (dispatch, getState) => {
  var returnValue = { result: 'undefined' }
  await quality.post(`/filestorage/tables/${dbType}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    if (response.data.error !== undefined && response.data.error !== "" && response.data.error !== "null" && response.data.error !== null) {
      dispatchError(dispatch, response.data.error)
    }

  })
    .catch(async function (error) {
      if (retry === undefined) {
        return getSQLTables(dbType, credentials, retry)
      }
      else {
        dispatchError(dispatch, error)
        returnValue = { result: 'ko', error: error }
      }



    });


  return returnValue
}


export const uploadFileInBackend = (file, folder, storageAccountName, containerName, sasToken, filename, userId) => async (dispatch, getState) => {

  var returnValue = { result: 'undefined' }
  const formData = new FormData();
  formData.append('file', file)
  console.log('lanzando post', file)
  await quality.post(`/filestorage/upload/file/${folder}/${storageAccountName}/${containerName}/${sasToken}/${filename}/${userId}`, formData, {
    headers: axiosHeaderSendFile()
  }).then(function (response) {

    returnValue = response.data
    if (response.data.error !== undefined && response.data.error !== "" && response.data.error !== "null" && response.data.error !== null) {
      dispatchError(dispatch, response.data.error)
    }

  })
    .catch(async function (error) {
      dispatchError(dispatch, error)
      returnValue = { result: 'ko', error: error }
    });

  return returnValue
}