

import React from 'react';

import AqtivaDialog from 'components/common/AqtivaDialog/index.js';
import PreviewDataformat from 'components/dialogs/DialogCreateDataSource/PreviewDataformat';

export default function DialogCreateDataSource(props) {


return (
    <AqtivaDialog visible={props.visible}
    showConfirmButton={false}
        fullWidth={true}
        maxWidth={'md'}
        title={'Preview Dataformat'}
        iconSourceFlag={true}
        iconSource = {require('resources/icons/data_proc1_white.png')}
        cancelText={'Close'}
        cancelCallback={event => {
            props.setVisible(false)
        }}    >
        <PreviewDataformat dataformat={props.selectedDataFormat} />
        </AqtivaDialog>
        )

    }

