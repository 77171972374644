import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faUpload,
  faFolderPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import trackEvent from '../../../../trackEvent'


export function DataRepositoryHeaderButtons({
  setShowImportDialog,
  setShowExportDialog,
  setShowNewDialogParent,
}) {
  const theme = useTheme();

  return (
    <div style={{ ...theme.elementAdjacent, marginLeft: "1vw" }}>
      <Button
        style={theme.buttons.buttonRegularWithRightMargin}
        onClick={() => {setShowImportDialog("button"); trackEvent('DataRepository','Show Import Dialog')}
      }
        startIcon={
          <FontAwesomeIcon
            icon={faDownload}
            style={{ fontSize: "calc(8px + 0.4vw)" }}
            fontSize="small"
          />
        }
      >
        <div style={theme.common.text.buttonTextSmall}>Import</div>
      </Button>

      <Button
        style={theme.buttons.buttonRegularWithRightMargin}
        onClick={() => {setShowExportDialog("button");  trackEvent('DataRepository','Show Export Dialog')}}
        startIcon={
          <FontAwesomeIcon
            icon={faUpload}
            style={{ fontSize: "calc(8px + 0.4vw)" }}
            fontSize="small"
          />
        }
      >
        <div style={theme.common.text.buttonTextSmall}>Export</div>
      </Button>

      <Button
        style={theme.buttons.buttonRegularWithRightMargin}
        onClick={() => {setShowNewDialogParent(true);  trackEvent('DataRepository','Show Import Dialog')}}
        startIcon={
          <FontAwesomeIcon
            icon={faFolderPlus}
            style={{ fontSize: "calc(8px + 0.4vw)" }}
            fontSize="small"
          />
        }
      >
        <div style={theme.common.text.buttonTextSmall}>New root folder</div>
      </Button>
    </div>
  );
}
