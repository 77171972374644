import { IAggregationObject, IAggregationOperationObject } from '../../interfaces/RuleInterfaces';
import { IDataformat } from '../../interfaces/DataformatInterfaces';
import * as enrichHelper from 'components/common/EnrichDataformatHelper'
import * as EnrichUtils from 'components/common/EnrichDatasourceComponent/CreateEnrichedDataframeUtils'
import { AppDispatch } from 'reducers/store';
import * as actions from 'actions'


export function getColumnsFromAggregationObject(aggObject: IAggregationObject | undefined) {
  //we must return all group by columns plus aggregation columns, type obtained from its base column
  if (aggObject === undefined) {
    return []
  }
  const groupByColumns = aggObject.groupColumns
  const operationCols = (aggObject && aggObject.aggregationOperations) ? JSON.parse(JSON.stringify(aggObject.aggregationOperations))
    .map((op: IAggregationOperationObject) => {
      var opCol = op.column
      opCol.name = op.alias
      return opCol
    }) : []

  var columns = groupByColumns ? groupByColumns.concat(operationCols) : []
  for (var i = 0; i < columns.length; i++) {
    columns[i].position = i
  }

  return columns

}


export async function createEnrichDatasetFromDataformatAndJoin(baseDataFormat: IDataformat, aggregatePrior2Join: boolean, aggregatedColumnsFlag: boolean,
  aggregationObject: IAggregationObject,
  selectedDatasource: any,
  joins: any[],
  dispatch: AppDispatch,) {
  if (baseDataFormat) {
    //first we check if we need to aggregate original dataset
    let enrichedDataset = Object.assign({}, baseDataFormat);
    if (aggregatePrior2Join === true && aggregatedColumnsFlag === true) {
      const aggCols = enrichHelper.getColumnsFromAggregationObject(aggregationObject)
      enrichedDataset.columns = aggCols
    }
    else if (aggregatedColumnsFlag === true && joins.length === 0) {
      const aggCols = enrichHelper.getColumnsFromAggregationObject(aggregationObject)
      enrichedDataset.columns = aggCols
    }

    //first we take all columns from the original dataset

    let numberOfJoinsInDataset = 1
    //then we enrich with joins from the parent datasource
    if (enrichedDataset && selectedDatasource && selectedDatasource.enrichDataformatObject) {

      if (selectedDatasource.enrichDataformatObject.joins && selectedDatasource.enrichDataformatObject.joins.length > 0) {
        let datasetsForEnrichment = await EnrichUtils.getDatasetsForEnrichment(selectedDatasource.enrichDataformatObject.joins, dispatch, actions.fetchDataformat)
        enrichedDataset = EnrichUtils.createEnrichedDataset(enrichedDataset, selectedDatasource.enrichDataformatObject.joins, datasetsForEnrichment, numberOfJoinsInDataset)
        numberOfJoinsInDataset = selectedDatasource.enrichDataformatObject.joins.length + 1
      }
    }
    //then we enrich with the rule own joins of enrichment

    if (joins && enrichedDataset) {
      if (joins && joins.length > 0) {
        let datasetsForEnrichment = await EnrichUtils.getDatasetsForEnrichment(joins, dispatch, actions.fetchDataformat)
        enrichedDataset = EnrichUtils.createEnrichedDataset(enrichedDataset, joins, datasetsForEnrichment, numberOfJoinsInDataset)
      }
    }
    return enrichedDataset
  }
}