import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { AqtivaDialogDataRepository } from "../../AqtivaDialogDataRepository";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getEntityIdFromFileInRepositoryShared } from "../../Helpers/getterDTOs";
import { getEntityIdFromFileInRepository } from "../../Helpers/getterDTOs";
import { getDTOIdFromFolder } from "../../Helpers/getterDTOs";
import { useTheme } from "@material-ui/core/styles";

import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";


export function OptionDelete({showDialog, setShowDialog, actions, type, dtoData, defaultFolderId, selectedItem, user, treeData}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [deleteValue, setDeleteValue] = React.useState("copy");
  const [haveChildren, setHaveChildren] = React.useState(false);
  const [isDefault, setIsDefault] = React.useState(false);

  const handleChangeDelete = (event) => {
    setDeleteValue(event.target.value);
  };


  //Función para saber si una carpeta tiene o no children
  const elementHaveChildren = (selectedItem, node) => {
    if(node) {
      for (let i = 0; i < node.length; i++) {
        if (node[i].children && node[i].key === selectedItem.itemId) {
          if(node[i].children.length > 0) {
            setHaveChildren(true);
          } else {
            setHaveChildren(false);
          }
          if(node[i].data.name === "default") {
            setIsDefault(true);
          } else {
            setIsDefault(false);
          }
          return;
        }
        else if (node[i].children && node[i].children.length > 0) {
          elementHaveChildren(selectedItem, node[i].children)
        }
      }
    }
  };

  useEffect(() => {
    elementHaveChildren(selectedItem, treeData.node)
  }, [showDialog])// eslint-disable-line react-hooks/exhaustive-deps
  

  //Borra carpetas y guarda en 'default'
  function deleteFolderFromRepositoryAndSave (){
    dispatch(
      actions.deleteFolderFromRepositoryAndSave(
        type, 
        getDTOIdFromFolder(dtoData, selectedItem.itemId),
        selectedItem.itemId,
        defaultFolderId
      )
    )
  }
  //Borra carpetas y contenido
  function deleteParentFolderFromRepository() {
    dispatch(
      actions.deleteParentFolderFromRepository(
        type,
        getDTOIdFromFolder(dtoData, selectedItem.itemId),
        selectedItem.itemId
      )
    );
  }

  //Borra archivos
  function deleteFileFromRepository() {
    dispatch(
      actions.deleteFileFromRepository(
        type, //type
        type === "private"
          ? getEntityIdFromFileInRepository(
              dtoData,
              selectedItem.itemId
            )
          : getEntityIdFromFileInRepositoryShared(
              dtoData,
              selectedItem
            ), //projectId o entityId
        user.id //userId
      )
    );
  }


  const folderOrFile =()=>{
    if (selectedItem.type === "Files"){
      return "file?"
    } else {
      return "folder?"
    }
  }

  //Dialog para borrar root folder con radio buttons
   const deleteFolderDialog = ()=>{
     return <>
      <Typography style={{ marginTop: "30px", marginBottom:"30px" }}>
                Are you sure that you want to delete this folder and its contents?
              </Typography>
              <RadioGroup
                aria-label="Delete"
                name="Delete"
                value={deleteValue}
                onChange={handleChangeDelete}
                //onChange={(event) => setChosenType(event.target.value)}
                style={{
                  ...theme.common.text.regularText,
                }}
              >
                <FormControlLabel
                  value="copy"
                  control={
                    <Radio
                      color="primary"
                      style={{
                        ...theme.common.text.regularText,
                      }}
                    />
                  }
                  label="Save a copy of the folder contents in 'default' folder"
                  style={{
                    ...theme.common.text.regularText,
                  }}
                />
                <FormControlLabel
                  value="delete"
                  control={
                    <Radio
                      color="primary"
                      style={{
                        ...theme.common.text.regularText,
                      }}
                    />
                  }
                  label="Delete this folder and its contents"
                  style={{
                    ...theme.common.text.regularText,
                  }}
                />
              </RadioGroup>
     </> 
  }

  //Si queremos que solo se puedan borrar archivos y carpetas en private descomentamos lo de abajo
/* if (type==="private"){ */
  return ( <>
    {(isDefault && selectedItem.type === "Folder" &&   //Si el item seleccionado se llama "default" y es una carpeta mostramos este dialog"
      <AqtivaDialogDataRepository
      dialog={showDialog}
      setDialog={setShowDialog}
      title={"Delete 'default' folder"}
      titleIcon={faTrashAlt}
      showConfirmButton={false}
      style={{width:"10px"}}
      confirmCallback={() => { 
      }}
    > You can't delete 'default' folder because it may have important contents</AqtivaDialogDataRepository>)
      ||    // Si no se llama "default se muestra este otro"
      (<AqtivaDialogDataRepository
        dialog={showDialog}
        setDialog={setShowDialog}
        title={selectedItem.type === "Folder" ? "Delete folder" : "Delete file"}
        titleIcon={faTrashAlt}
        confirmCallback={() => {                  //Si es carpeta ejecuta un action, si no el otro
          if (selectedItem.type!=="Folder") {
            deleteFileFromRepository()
          } else if (!haveChildren) {
            deleteParentFolderFromRepository()
          } else if (deleteValue === 'copy') {
            deleteFolderFromRepositoryAndSave()
          } else if (deleteValue === 'delete') {
            deleteParentFolderFromRepository()
          }
          setShowDialog(false)

        }}
      > Do you want to delete this {folderOrFile(selectedItem)} {haveChildren && deleteFolderDialog()}</AqtivaDialogDataRepository>)
    }
    </>
  );
/* } else {
  return <AqtivaDialogDataRepository
    dialog={showDialog}
    setDialog={setShowDialog}
    title={"You can't delete shared folders"}
    showConfirmButton={false}
    titleIcon={faTrashAlt}
    confirmCallback={() => {
    }}
  > You can't delete shared folders or files because they belong to a shared workspace </AqtivaDialogDataRepository>
  } */
}
