const {
    BlobServiceClient,
  } = require("@azure/storage-blob");
  
  

  export async function listBlobsFilter(storageAccountName, sasToken, containerName,folder,list) {

    var result = []
    await listBlobs(storageAccountName, sasToken, containerName,folder,5, false).then(array=>{
        if(array && array.length>0){
          
            result =  array.filter(x=> list.includes(getOnlyNameFromBlob(x)))
            
        }
       
    })
    return result
  }
  export async function listBlobs(storageAccountName, sasTokenPromise, containerName,folder, nItems = 5, filter = true) {
    var sasToken = await sasTokenPromise
    if(storageAccountName===undefined || sasToken === undefined || containerName===undefined){
      return []
    }
      // Enter your storage account name and shared key

  
  // Use StorageSharedKeyCredential with storage account and account key
  // StorageSharedKeyCredential is only available in Node.js runtime, not in browsers
  
    const STORAGE_ACCOUNT_NAME = storageAccountName
    // for browser, SAS_TOKEN is get from API?
    const SAS_TOKEN = sasToken
    const sasURL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${SAS_TOKEN}`
  
    const blobServiceClient = new BlobServiceClient(sasURL)



    const containerClient = await blobServiceClient.getContainerClient(
      containerName,
    );
    var iter = containerClient.listBlobsFlat({ prefix: folder})
    var array = []
    for await (const item of iter) {
      array.push(item)
    }

    if(array && array.length >0){
        array.sort(function(a, b) {
            return (new Date(a.properties.lastModified).toISOString() > new Date(b.properties.lastModified).toISOString()) ? -1 :
             ((new Date(a.properties.lastModified).toISOString() < new Date(b.properties.lastModified).toISOString()) ? 1 : 0);
        })
    }
    if(filter ===true){
        array=array.slice(0, nItems)
    }
    
    return array
    
  }

  export function getOnlyNameFromBlob(x){

        let splits = x.name.split("/")
        let name = splits[splits.length-1]
        return name

  }

  export function getOnlyNameFromSimulation(x){

    let splits = x.dataFileName.split("/")
    let name = splits[splits.length-1]
    return name

}
  
  
  listBlobs().catch((err) => {
    console.error("Error running sample:", err.message);
  });