export function createStreamSimulationDTO(license,project,qualityPoint, messages, configuration, userId, simulationId,environment){
    //only admit qualitypoints with one datasource

    //TODO: configuration tiene que ir por environment
    if(!userId || !configuration || !qualityPoint || !qualityPoint.datasources || qualityPoint.datasources.length >1 || !messages || !simulationId){
        return undefined
    }
    let datasource = qualityPoint.datasources[0]

    let dto ={
        simulationId : simulationId,
        originEventHub: {
            connectionString: datasource.inputConnector.conn_str,
            resourceGroup: datasource.inputConnector.resourcegroup,
            namespace: datasource.inputConnector.namespace,
            name: datasource.inputConnector.name,
        },
        number_messages: messages,
        credentialDTO: configuration.azureCredentials,
        userId: userId,
        deploymentDTO : getDeploymentDTOSimulation(datasource,configuration,userId,environment),
        projectId: project._id,
        qpId: qualityPoint._id,
        apiKey: license.apiKey
    }

    return dto
}




function getDeploymentDTOSimulation(datasource,configuration,userId,environment){
    return {
        credentialDTO: configuration.azureCredentials,
        eventHubConnection:datasource.inputConnector.conn_str,
        resourceGroupName:"aqtiva",
        regionName:"westeurope",
        blobConnectionString:"DefaultEndpointsProtocol=https;AccountName=staggingsasaqtivanew;AccountKey=7xuEjDaQgWPWSbYUI0eP1tufsXHn6L+31jgsbKtXu3y4tUznauf0xvCEZ+qTY+a+xIyBjZhaMd/Z1v9jhLJ81A==;EndpointSuffix=core.windows.net",
        containerName:"stagging",
        blobName:"deploy/stream/simulation/"+userId+"/simulation.json",
        simulation:true,
        userId:userId,
        simulationEventHub:{
            connectionString:null,
            resourceGroup:"aqtiva",
            namespace:"simulationehubs",
            name:userId+"simulationeh"

        }
    }
}



export function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}