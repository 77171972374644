import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'

import { Grid } from '@material-ui/core';
import * as actions from 'actions'

import DashboardChartComponent from '../../Common/Components/DashboardChartComponent';

import DonutChart from '../../Common/Charts/DonutChart';
import DQIDimensionComponent from '../../Common/Components/DQIDimensionComponent';

import ChartTripleTemporal from '../../Common/Charts/ChartTripleTemporal';
import ExecutionDetail from '../../Common/Components/ExecutionDetail';
import AqtivaDialog from 'components/common/AqtivaDialog';
import TableLlolipopCharts from '../../Common/Components/TableLollipopCharts'

import { faKey } from '@fortawesome/free-solid-svg-icons'

import { LanguageContext } from 'language/LanguageContext';
import ExecutionSelectorDropdown from '../../Common/Components/ExecutionSelectorDropdown';

import * as windowHelper from 'commonFunctions/windowHelper'

export default function QualityPointGrid(props) {

    const languageContext = React.useContext(LanguageContext)
    //const [dataSourceList, setDataSourceList] = useState([])
    const [datasourcesExecution, setDatasourcesExecution] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const { height, width } = windowHelper.useWindowDimensions();
    const [dimension, setDimension] = useState([])
    const [donutChart, setDonutChart] = React.useState(<div></div>)
    const [donutChartFullScreen, setDonutChartFullScreen] = React.useState(<div></div>)
    const [chartTriple, setChartTriple] = useState(<div></div>)
    const [chartTripleFullScreen, setChartTripleFullScreen] = useState(<div></div>)
    const [executionDetailsChart, setExecutionDetailsChart] = useState(<div></div>)
    const [executionDetailsChartFullScreen, setExecutionDetailsChartFullScreen] = useState(<div></div>)
    const [tablesLollipop, setTablesLollipop] = useState(<div></div>)
    const [tablesLollipopFullScreen, setTablesLollipopFullScreen] = useState(<div></div>)
    const [executionSelector, setExecutionSelector] = useState(<div></div>)



    const dispatch = useDispatch()

    useEffect(() => {

        if (props.qualityPoint) {
            setDimension(props.qualityPoint.dimensionDQIList)
        }

        if (props.datasourcesExecution && props.datasourcesExecution.length > 0) {

            let order = props.datasourcesExecution.sort((a, b) => {
                return props.qualityPoint.datasourceDQI.indexOf(a.datasourceId) - props.qualityPoint.datasourceDQI.indexOf(b.datasourceId)
            })


            setDatasourcesExecution(order)
        }

        if (props.qualityPoint && props.qualityPoint.datasourceDQI && props.qualityPoint.datasourceDQI.length > 0 && props.datasourcesExecution && props.datasourcesExecution.length > 0) {

            //Here we map the datasources list of qualityPoint to obtain the data for the page compoents, if datasource doesnt have creation time we set one by default
            let dataSourceList = props.qualityPoint.datasourceDQI.map(dt => {
                if (dt.dayDsDQIList && dt.dayDsDQIList.length > 0) {
                    return {
                        ...dt,
                        'id': dt.datasourceId,
                        'historyList': props.datasourcesExecution.filter(ex => ex.datasourceId === dt.datasourceId)[0].executionHistory,
                        'score': Number(((dt.sumNumOk /  (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                        'scoreKo': -Number(((dt.sumNumKo / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                        "fecha": new Date(dt.dayDsDQIList[0].year, dt.dayDsDQIList[0].month, dt.dayDsDQIList[0].day).getTime(),
                        "timestamp": dt.dayDsDQIList[0].year + "/" + dt.dayDsDQIList[0].month + "/" + dt.dayDsDQIList[0].day

                    }
                } else {
                    return {
                        ...dt,
                        'score': Number(((dt.sumNumOk / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                        'scoreKo': -Number(((dt.sumNumKo / (dt.sumNumOk + dt.sumNumKo)) * 100).toFixed(0)),
                        "fecha": new Date().getTime(),
                        "timestamp": 2022 + "/" + 10 + "/" + 15

                    }
                }
            })
            setDatasourcesExecution(dataSourceList)
            setTablesLollipop(<TableLlolipopCharts data={dataSourceList} click={handleDrillDownElement} showIcons={true} />)
            setTablesLollipopFullScreen(<TableLlolipopCharts fullscreen={true} data={dataSourceList} click={handleDrillDownElement} showIcons={true} />)
            setDonutChart(<DonutChart data={Number((props.qualityPoint.sumNumOk / props.qualityPoint.sumNumIn) * 100).toFixed(0)} showCenterLabel={true} chart={'chartDonutDataSource'} openDimensionModal={openModal} click={true} />)
            setDonutChartFullScreen(<DonutChart fullscreen={true} data={Number((props.qualityPoint.sumNumOk / props.qualityPoint.sumNumIn) * 100).toFixed(0)} showCenterLabel={true} chart={'chartDonutDataSource'} openDimensionModal={openModal} click={true} />)

            if (props.qpExecutions && props.qpExecutions) {

                let listOfQpsExecutions = props.qpExecutions.map(qp => {
                    return {
                        ...qp,
                        'score': Number(((qp.sumNumOk / (qp.sumNumOk + qp.sumNumKo)) * 100).toFixed(0)),
                        'scoreKo': Number(((qp.sumNumKo / (qp.sumNumOk + qp.sumNumKo)) * 100).toFixed(0)),
                        "fecha": new Date(qp.createTime).getTime(),
                    }
                })
                setChartTriple(<ChartTripleTemporal data={listOfQpsExecutions.filter(qp => qp.qpId === props.qualityPoint.qualityPointId)[0].executionHistory} showBasicInformation={showBasicInformation} />)
                setChartTripleFullScreen(<ChartTripleTemporal fullscreen={true} data={listOfQpsExecutions.filter(qp => qp.qpId === props.qualityPoint.qualityPointId)[0].executionHistory} showBasicInformation={showBasicInformation} />)
                setExecutionSelector(<ExecutionSelectorDropdown executionList={listOfQpsExecutions.filter(qp => qp.qpId === props.qualityPoint.qualityPointId)[0].executionHistory} showBasicInformation={showBasicInformation}/>)

            }


        }

    }, [props.qualityPoint && props.qualityPoint.datasourceDQI, props.datasourcesExecution,   // eslint-disable-line react-hooks/exhaustive-deps
         props.project, props.qpExecutions, props.qualityPoint])// eslint-disable-line react-hooks/exhaustive-deps

   

    async function showBasicInformation(e) {
        let executionDetail = await dispatch(actions.getDetailExecutions(e.executionId))

        if (executionDetail) {
            let dataSourceId = executionDetail.data.filter(ex => ex.type === 'DATA_SOURCE')[0].datasourceId

            let dataSource = props.qualityPoint.datasourceDQI.filter(dt => dt.datasourceId === dataSourceId)[0]
            setExecutionDetailsChart(<ExecutionDetail project={props.project} execution={executionDetail.data} qualityPoint={props.qualityPoint} dataSource={dataSource}/>)
            setExecutionDetailsChartFullScreen(<ExecutionDetail fullscreen={true} project={props.project} execution={executionDetail.data} qualityPoint={props.qualityPoint} dataSource={dataSource}/>)
            setShowDetails(true)
        }


    }

    const [openDimensionModal, setOpenDimensionModal] = React.useState(false)
    const handleClose = () => setOpenDimensionModal(false);

    function openModal(data) {
        setOpenDimensionModal(true)
    }

    function handleDrillDownElement(e) {
        let dataSourceInfo = {
            ...e,
            'execution': datasourcesExecution.filter(dt => dt.datasourceId === e.datasourceId)[0]
        }
        props.handleDrillDownElement(dataSourceInfo)
    }


    return (
        <>
            <Grid item xs={4}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={donutChartFullScreen} content={donutChart} title={'QualityPoint DQI'} description={languageContext.dictionary['qualityPointDQI']} />
            </Grid >

            <Grid item xs={8}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={tablesLollipopFullScreen} content={tablesLollipop} title={'Trends'} description={languageContext.dictionary['datasourcesDQILollipop']} />
            </Grid >

            <Grid item xs={10}>
                <DashboardChartComponent loading={props.loading} fullscreenContent={chartTripleFullScreen} content={chartTriple} showTooltip={true} title={'QualityPoint DQI Time Evolution'} description={languageContext.dictionary['qualityPointChartTriple']} />

            </Grid >

            <Grid item xs={2}>
                <DashboardChartComponent loading={false} content={executionSelector} showTooltip={false} hideFullScreen={true} title={'Execution Selector'} description={languageContext.dictionary['exectuionSelector']} />

            </Grid >

            <Grid item xs={12}>
                {showDetails && <DashboardChartComponent loading={props.loading} fullscreenContent={executionDetailsChartFullScreen} content={executionDetailsChart} title={'Details'} description={languageContext.dictionary['detaillExecution']} />}

            </Grid >


            <AqtivaDialog visible={openDimensionModal}
                title={`DQI Dimension`}

                titleIcon={faKey}
                cancelText={'Close'}
                showConfirmButton={false}
                cancelCallback={handleClose}
                maxWidth={width}
            >
                <div style={{ width: width * 0.9, height: height * 0.7 }}>
                    <DQIDimensionComponent data={dimension} DQIByDimension={dimension} />

                </div>

            </AqtivaDialog>

        </>

    );
}


