import React from 'react'
import { Tooltip, Select, MenuItem, Typography } from '@material-ui/core';
import * as techHelper from 'commonFunctions/sourceTechHelper'

export function getDataformatOrigin(data){
    var origin = ''
    if(data.fileStorageProperties && data.fileStorageProperties.path){
        origin =  data.fileStorageProperties.path
    }
    if(origin.length > 35){
        return(
        <Tooltip title={origin}>
            <div>{origin.substring(0,35)+'...'}</div>
            
        </Tooltip>
        )
    }
    return origin
}
export function getAzureRG(data){
    if(data.fileStorageProperties && data.fileStorageProperties.resourceGroup){
        return data.fileStorageProperties.resourceGroup
    }
    else{
        return ''
    }
}

export function getAzureStorageAccount(data){
    if(data.fileStorageProperties && data.fileStorageProperties.account){
        return data.fileStorageProperties.account
    }
    else{
        return ''
    }
}

export function applyFilters(dataformats, selectedDataformatSource, selectedDataformatUser) {
    
    let filteredDataformats = JSON.parse(JSON.stringify(dataformats))
    for (let i = 0; i < filteredDataformats.length; i++) {
        if (filteredDataformats[i].source === null || filteredDataformats[i].source === undefined || filteredDataformats[i].source === '') {
            filteredDataformats[i].source = "local file"
        }
    }
    if (selectedDataformatSource && selectedDataformatSource !== "") {
        filteredDataformats = filteredDataformats.filter(dataformat => dataformat.source === selectedDataformatSource)
    }
    if (selectedDataformatUser && selectedDataformatUser !== "") {
        filteredDataformats = filteredDataformats.filter(dataformat => (selectedDataformatUser === dataformat.userId))
    }
    return filteredDataformats
}

export function countProjectsUsingDataformat(usedDatasourcesByProjects, dataformat) {
    let count = 0;
    for (let i = 0; i < usedDatasourcesByProjects.length; i++) {
        for (let j = 0; j < usedDatasourcesByProjects[i].dataformatsUsed.length; j++) {
            if (usedDatasourcesByProjects[i].dataformatsUsed[j] === dataformat._id) {
                count += 1
            }
        }
    }
    return count
}

export function parseDateToCorrectFormat(date) {
    let newDate = date.split('.')[0]
    newDate = newDate.replace('T', ' ')
    return newDate

}

export function renderSelectedIcon(dataformat, selectedDataformat) {
    if (dataformat._id === selectedDataformat._id) {
        return true
    }
    else {
        return false
    }

}


  
export function renderAllowedUsers(rowData, licenseUsers) {
    if (rowData.allowedUsers) {
        return (
            <Select
                labelId="select-license-type"
                id="select-license-type"
                value={""}
                style={{ width: "100%" }}
            >
                {rowData.allowedUsers.filter((value, index, array) => array.indexOf(value) === index).map(userId =>
                    <MenuItem value={userId}>
                        <Typography variant='caption'> {translateUserIdToName(userId, licenseUsers)}</Typography>
                    </MenuItem>
                )}

            </Select>
        )
    }
}

const translateUserIdToName = (userId, licenseUsers) => {
    if (licenseUsers !== undefined && licenseUsers.length > 0) {
        for (let i = 0; i < licenseUsers.length; i++) {
            if (licenseUsers[i].id === userId) {
                return licenseUsers[i].name
            }
        }
        return userId
    }
    return undefined


}
export function renderAzureLogo(source) {
    const size = '25px'
    return(
        <Tooltip title={techHelper.getLabelFromSource(source)}>
                    <span>
                        {techHelper.getIconFromSource(source, size)}
                        
                    </span>
                </Tooltip>
    )
    
    
}