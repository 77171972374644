import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import * as commonStyles from 'style/CommonStyles'

export default function DataformatBasicInformationJDBCDatabase(props) {


    return (
        <div style={{ maxWidth: '500px' }}>

            {props.properties &&
                <Grid container spacing={0} style={{ margin: '10px' }}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>JDBC Database Properties </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle2'>Server: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body2'>{props.properties.server}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='subtitle2'>Schema: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body2'>{props.properties.schema}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle2'>Table: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body2'>{props.properties.table}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle2'>User: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body2'>{props.properties.user}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle2'>Database: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body2'>{props.properties.database}</Typography>
                    </Grid>
                    {props.query &&
                        <>
                            <Grid item xs={4}>
                                <Typography variant='subtitle2'>Query: </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body2'>{props.query}</Typography>
                            </Grid>
                        </>

                    }
                </Grid>
            }
        </div>
    )
}