import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Typography,  TextField, FormControl, Grid,  Input, Button } from '@material-ui/core'
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import { faInbox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MyChip = (props) => {

    return (
        <Chip
            style={{ maxWidth: '120px', backgroundColor: '#b9e4eb44' }}
            {...props}
        />
    );
};

export default function EmailConfigCard(props) {
    var license = useSelector(store => store.license)

    const theme = useTheme()

    const [receivers, setReceivers] = useState([])
    const [selectedReceivers, setSelectedReceivers] = useState(props.mailsToNotify ? props.mailsToNotify : [])


    const [inputText1, setInputText1] = useState("")
    const [errorInputText1, setErrorInputText1] = useState("")


    useEffect(() => {
       /* const users = license.users
        let mails = []
        if(users && users.length >0){
            for (let i = 0; i < users.length; i++) {
                mails.push(users[i].email)
            }
            setReceivers(mails.sort())
        }
        
        if (props.mailsToNotify) {
            setSelectedReceivers(props.mailsToNotify)
        }*/
        setReceivers(license.users.sort((a, b) => (a.name > b.name) ? 1 : -1))
        if (props.mailsToNotify) {
            setSelectedReceivers(props.mailsToNotify)
        }
    }, [props.mailsToNotify])// eslint-disable-line react-hooks/exhaustive-deps






    useEffect(() => {
        if (props.selectedReceivers) {
            updateNotificationConfig()
        }
    }, [selectedReceivers, props.mailsToNotify]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateNotificationConfig = () => {

        props.setMailsToNotify(selectedReceivers)
    }




    const handleInsertNewMail = (event, value) => {
        let newSelectedReceivers = selectedReceivers
        let newMail = inputText1
        let mailCorrecto = false
        mailCorrecto = validateInputs(newMail)
        let present = false

        for (let i = 0; i < newSelectedReceivers.length; i++) {
            if (newSelectedReceivers[i] === newMail) {
                present = true
            }
        }
        if (!mailCorrecto) {
            setErrorInputText1("You must enter a valid email address")
        }
        if (!present && mailCorrecto) {
            newSelectedReceivers.push(newMail)
            setSelectedReceivers(newSelectedReceivers)
            props.setMailsToNotify(newSelectedReceivers)
            setInputText1("")
        }
        if (present) {
            setErrorInputText1("This email address is already on the list")
        }
    }


    function validateInputs(mail) {
        const validation = {
            name: {
                rule: /^[^\s@]+@[^\s@]+$/.test(mail),
            }
        }
        return validation.name.rule
    }



    const handleChangeInputText = (event, value) => {
        setInputText1(event.target.value)
        setErrorInputText1("")
    }


    return (
        <>
            {<Grid item container style={{ maxWidth: "100%" }} >
                <Grid item xs={10}>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={receivers}
                        getOptionLabel={(option) => option.email}
                        filterSelectedOptions={true}
                        value={selectedReceivers}
                        onChange={(event, value) => {
                            setSelectedReceivers(value.map(x=>x.email))
                            props.setMailsToNotify(value.map(x=>x.email))
                        }}
                        renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                                <MyChip {...getTagProps({ index })} label={<Typography variant='caption'>{option}</Typography>} />
                            ));
                        }}
                        renderOption={(option) => (
                            <Typography variant='caption'>{`${option.name} (${option.email})`}</Typography>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ fontSize: '4px', maxWidth: "100%" }}

                                variant="standard"
                                label={<Typography variant='caption'>Contacts to notify</Typography>}

                            />
                        )}
                    />
                    <FormControl style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start', marginTop: '20px' }} >
                        <Input value={inputText1} onChange={handleChangeInputText} placeholder="External Mail"
                            style={{ fontSize: "0.8rem", width: '65%', marginRight: '5%' }} />
                        <Button onClick={handleInsertNewMail} style={{ ...theme.buttons.buttonDark, width: '30%' }}
                            startIcon={
                                <FontAwesomeIcon icon={faInbox} style={{ color: '#474b51', fontSize: 15 }} />
                            }
                            variant='outlined'>
                            <Typography variant='standard' >Add mail</Typography>
                        </Button>
                    </FormControl>
                    {errorInputText1 && errorInputText1 !=="" && <Typography variant='standard' style={{ fontSize: "12px", marginTop: "0.8rem", marginLeft: "3rem", color: "#ab2e27" }} >{errorInputText1}</Typography>}
                </Grid>
            </Grid>}

        </>
    )
}

