import React from "react";
import { useSelector/* , useDispatch */ } from "react-redux";
import AppBarTemplate from "../../template/AppBarTemplate";
import * as pathHelper from "../../template/PathHelper";
import FilterMenuKPIS from "./FilterMenuKPIS";
import KPICard from "./KPICard";
/* import * as actions from "../../../actions"; */
import {Button} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from 'trackEvent'
import {faUndo} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as userHelper from '../../common/userHelper'

import { useDispatch } from "react-redux";

import * as actions from "actions";

import * as commonStyles from 'style/CommonStyles'



export default function QualityKpisDetail(props) {
  const dispatch = useDispatch();

  const theme = useTheme()

  const user = useSelector((store) => store.user);
  const license = useSelector((store) => store.license);


/*   const user = useSelector((store) => store.user);
  const license = useSelector((store) => store.license);  */
  const projects = useSelector((store) => Object.values(store.projects));
  const dataKPI = useSelector((store) => store.KPIS);
  const [projectsLicense, setProjectsLicense] = React.useState([]);
  
  const [showEmpty, setShowEmpty] = React.useState(props.match && props.match.params && props.match.params.aggregationLevel && props.match.params.entityIds ? true : false)
  
  const [showOwnProjects, setShowOwnProjects] = React.useState((userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")===undefined ||
  userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")===false)? false: true)
  const [showSelectedProjects, setShowSelectedProjects] = React.useState((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")===undefined ||
  userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? false: true)

  const [selectedProjects, setSelectedProjects] = React.useState((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")===undefined ||
  userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? []: userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects"));

  
  
  function setSelectedProjectsCallback(value){
    userHelper.updateUserPreference(dispatch,user,"governanceKPISelectedProjects", value)
    setSelectedProjects(value)
  }

  async function getLicenseProjects() {
    var projects = [];
    if (license && license._id && license.users && license.users.length > 0) {
      for (var i = 0; i < license.users.length; i++) {
        let licenseUser = license.users[i];
        if (user.id !== licenseUser.id) {
          var userProjects = await dispatch(
            actions.fetchProjectsAndReturn(licenseUser.id)
          );
          projects = projects.concat(userProjects);
        }
      }
    }
    return projects;
  }

  

  function toggleShowEmptyCallback(){
    userHelper.updateUserPreference(dispatch,user,"governanceShowNoDataProjects", !showEmpty)
  }

  function toggleShowOwnProjectsCallback(){
    userHelper.updateUserPreference(dispatch,user,"governanceShowOnlyOwnProjects", !showOwnProjects)
  }

  React.useEffect(() => {
    setSelectedProjects((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")===undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? []: userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects"))

    setShowSelectedProjects((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")===undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? false: true)

    setShowEmpty((userHelper.getUserPreferenceProperty(user,"governanceShowNoDataProjects")===undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceShowNoDataProjects")===false)? false: true)

    setShowOwnProjects((userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")===undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")===false)? false: true)
  }, [user]);

  React.useEffect(() => {
    if (user && user.id) {
      dispatch(actions.fetchProjects(user.id));
      if (license && license._id) {
        getLicenseProjects().then((response) => setProjectsLicense(response));
      } else {
        if(user!==undefined && user.id!==undefined){
          dispatch(actions.fetchLicense((user).id))
      }
      }
    }
  }, [user, license._id]);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <AppBarTemplate
        history={props.history}
        title={props.match.params.aggregationLevel}
        arrayPath={pathHelper.getPathArray("GovernanceDetail")}
      />
      <Button  onClick = {event => { props.history.goBack(); trackEvent('DetailProject', 'New Item Button')}}
                 style={theme.buttons.buttonRegular}
                startIcon={<FontAwesomeIcon icon={faUndo} style={{color:commonStyles.mainColor, fontSize:14}} />} 
                 >
                   <div style={theme.common.text.buttonText}>
                   Back
                   </div>
                   
                        
      </Button>
      <div style={{marginTop:'-30px'}}>

    
                <FilterMenuKPIS projects={projects} projectsLicense={projectsLicense} 
      toggleShowEmpty ={toggleShowEmptyCallback}  showEmpty = {showEmpty}
      toggleShowOwnProjects ={toggleShowOwnProjectsCallback} 
      showOwnProjects = {showOwnProjects}
      setSelectedProjects = {setSelectedProjectsCallback} selectedProjects = {selectedProjects}
      setShowSelectedProjects = {setShowSelectedProjects} showSelectedProjects = {showSelectedProjects}
      urlParams = {props.match} history={props.history}>
        <KPICard dataKPI={dataKPI} showEmpty = {showEmpty} showOwnProjects = {showOwnProjects}  history={props.history} showSelectedProjects = {showSelectedProjects}
        selectedProjects = {selectedProjects}/>
        
      </FilterMenuKPIS >
      </div>
    </>
  );
}
