import React, { useEffect, useState } from 'react';
import { Typography, FormControl, InputLabel,  TextField,  FormControlLabel,  Select, MenuItem} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from 'language/LanguageContext'


const useStyles = makeStyles((theme) => ({


}));
export default function TagConfigurator(props) {
    const languageContext = React.useContext(LanguageContext); 

   
    const classes = useStyles();
    

    const [tag, setTag] = useState(undefined)
    const [tagValue, setTagValue] = useState(props.presetValue && props.presetValue.tagValue ? props.presetValue.tagValue : "")

    useEffect(() => {
        if (props.tag) {
            setTag(props.tag)
        }
        if (props.presetValue && props.tag) {
            setTagValue(props.presetValue.tagValue)
        }
    }, [props.presetValue, props.tag])




    return (
        <>
            {tag && <div className={classes.tagContainer}>
                <div style={{ display: "flex", justifyContent: "left", alignItems: "baseline", flexDirection: "row" }}>
                    <Typography style={{ width: '50%' }} variant="subtitle2">{`Tag ${tag.tagName}`} </Typography>
                    {tag.tagType !== "free" &&
                        <FormControl style={{ width: '50%', marginLeft: '-12px' }}>
                            <InputLabel  id="tag">{languageContext.dictionary['selectTagValue']}</InputLabel>
                            <Select
                                defaultValue={tagValue}
                                renderValue={(val) => val}
                                inputProps={{
                                    style: { fontSize: 10 }
                                }}
                                style={{ minWidth: "20%" }}
                                autoWidth={true}
                                labelId="tag-list"
                                id="tag-list"
                                value={tagValue}
                                label="Tag Value"
                                onChange={(event) => {
                                    setTagValue(event.target.value)
                                    props.handleChangeValue(event.target.value, tag.tagName)
                                }}
                            >

                                {tag.allowedValues && tag.allowedValues.map(value => {
                                    return (
                                        <MenuItem key={value} value={value}>
                                            <Typography variant='body2'>{value}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    }
                    {tag.tagType === "free" &&
                        <FormControlLabel style={{ width: '50%' }}
                            control={
                                <TextField style={{ width: '100%', fontSize: '10px' }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: '10px' } }}
                                    id="tagValue"
                                    label={languageContext.dictionary['tagValue']}
                                    margin="dense"
                                    value={tagValue}
                                    onChange={(event) => {
                                        setTagValue(event.target.value)
                                        props.handleChangeValue(event.target.value, tag.tagName)
                                    }}
                                />} />
                    }
                </div>
            </div >}
        </>
    )
}