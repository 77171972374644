
export function getSourceValuesForFilter(dataformats) {
    const uniqueSourceValues = [...new Set(dataformats.map(item => {
        if (item.source) {
            return item.source
        }
        else {
            return 'local file'
        }
    }))]
    uniqueSourceValues.sort()
    return uniqueSourceValues
}

export function getUserIdValuesForFilter(dataformats, licenseUsers) {
    const uniqueOwnerValues = [...new Set(dataformats.map(item => item.userId))]
    const unique = uniqueOwnerValues.map(item => getUsernameFromId(item, licenseUsers))
    unique.sort()
    return unique
}

export function getUsernameFromId(userId, licenseUsers) {
    for (let i = 0; i < licenseUsers.length; i++) {
        if (licenseUsers[i].id === userId) {
            let user = {
                userId: licenseUsers[i].id,
                userName: licenseUsers[i].name
            }
            return user
        }
    }
    return {
        userId: userId,
        userName: ''
    }
}