

export const generateSidebarMenu = (user) =>{

   /* var items = [
        { name: 'home', label: 'Home',route: '/home' }
    ]*/
    var items = []
    //generateAdminMenu(user,items)
    generateQualityMenu(user,items)
    return items

}

/*const generateAdminMenu = (user, items) =>{
    if(user && user.role === 'SUPER_ADMIN'){
        items.push(
            { name: 'Admin', label: 'Admin',route:'/admin/license',
            items: [
                { name: 'adminLicense', label: 'Licenses' , route: '/admin/license'},
                { name: 'adminInfra', label: 'Infrastructure', route: '/admin/infrastructure' },
              ], }
        )
    }

    return items


}*/

const generateQualityMenu = (user, items) =>{
    if(user){
    if(user.role==='ADMIN'  ){
        items.push(
            
                { name: 'home', label: 'Home' , route: '/home'},
                { name: 'governance', label: 'Governance' , route: `/${process.env.REACT_APP_PREFIX}/governance`} ,
                { name: 'dashboard', label: 'Dashboard' , route: `/${process.env.REACT_APP_PREFIX}/dashboard`} ,
                { name: 'configuration', label: 'Configuration' , route: `/${process.env.REACT_APP_PREFIX}/configuration`} ,
                //{ name: 'simulations', label: 'Simulations' , route: `/${process.env.REACT_APP_PREFIX}/simulations`},
                { name: 'datasets', label: 'Datasets' , route: `/${process.env.REACT_APP_PREFIX}/datasets`}, 
                          
        )
    }
    else if( user.role === 'SUPER_ADMIN'){
        items.push(
            
            { name: 'home', label: 'Home' , route: '/home'},
            { name: 'rootManagement', label: 'Root Management' , route: '/management'},
            { name: 'governance', label: 'Governance' , route: `${process.env.REACT_APP_PREFIX}/governance`} ,
            { name: 'dashboard', label: 'Dashboard' , route: `/${process.env.REACT_APP_PREFIX}/dashboard`} ,
            { name: 'configuration', label: 'Configuration' , route: `/${process.env.REACT_APP_PREFIX}/configuration`} ,
           // { name: 'simulations', label: 'Simulations' , route: `/${process.env.REACT_APP_PREFIX}/simulations`},
            { name: 'datasets', label: 'Datasets' , route: `/${process.env.REACT_APP_PREFIX}/datasets`}, 
                      
    )
    }
    else{
        items.push(
           
                { name: 'home', label: 'Home' , route: '/home'},
                { name: 'governance', label: 'Governance' , route: `/${process.env.REACT_APP_PREFIX}/governance`} ,
                { name: 'dashboard', label: 'Dashboard' , route: `/${process.env.REACT_APP_PREFIX}/dashboard`} ,
               // { name: 'simulations', label: 'Simulations' , route: `/${process.env.REACT_APP_PREFIX}/simulations`},
                { name: 'datasets', label: 'Datasets' , route: `/${process.env.REACT_APP_PREFIX}/datasets`}, 
                          
        )
    }

    return items
}
return []


}
