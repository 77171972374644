
import React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';


import JoinOperator from './JoinOperator';
import ConditionOperator from './ConditionOperator';
import AddOperatorCard from './AddOperatorCard'
import { ILogicColumnCondition, ILogicColumnOperator, ILogicSqlExpression } from 'interfaces/LogicCondition';
import { IColumn } from '../../../../interfaces/RuleInterfaces';



type IProps = {
    columns: IColumn[],
    conditionObj: any,
    setParentConditions: (value: any) => any
}


export default function ConditionGenerator(props: IProps) {

    const ref = useRef<HTMLInputElement>(null);
    const [conditionObj, setConditionObj] = React.useState<ILogicColumnOperator>(createBaseConditionObj())
    const [baseWidth, setBaseWidth] = useState(0);
    const [baseHeight, setBaseHeight] = useState(0);


    React.useEffect(() => {
        setConditionObj(props.conditionObj)
    }, [props.conditionObj])

    useLayoutEffect(() => {
        setBaseWidth(ref && ref.current ? ref.current.offsetWidth : 0);
        setBaseHeight(ref && ref.current ? ref.current.offsetHeight : 0);
    }, []);




    function createBaseConditionObj() {
        return {
            type: 'columnOperator',
            operator: 'and',
            columnConditions: [
            ],
            columnOperations: [],
            sqlExpressions: []
        }
    }

    function addRootOperatorJoinFunction(operator: ILogicColumnOperator) {
        console.log('addRootOperatorJoinFunction', operator, conditionObj)
        //if object is empty we create it
        var cond = JSON.parse(JSON.stringify(conditionObj) !== "{}" ? JSON.stringify(conditionObj) : JSON.stringify(createBaseConditionObj()))
        var columnOperations = cond.columnOperations
        columnOperations.push(operator)
        cond.columnOperations = columnOperations
        setConditionObj(cond)
        props.setParentConditions(cond)

    }

    function addRootOperatorConditionFunction(condition: ILogicColumnCondition) {
        console.log('addRootOperatorConditionFunction', condition, conditionObj)
        //if object is empty we create it
        var cond = JSON.parse(JSON.stringify(conditionObj) !== "{}" ? JSON.stringify(conditionObj) : JSON.stringify(createBaseConditionObj()))
        var columnConditions = cond.columnConditions
        columnConditions.push(condition)
        cond.columnConditions = columnConditions
        setConditionObj(cond)
        props.setParentConditions(cond)


    }

    function addSqlExpressionFunction(expression: ILogicSqlExpression) {
        //if object is empty we create it
        var cond = JSON.parse(JSON.stringify(conditionObj) !== "{}" ? JSON.stringify(conditionObj) : JSON.stringify(createBaseConditionObj()))
        var sqlExpressions = cond.sqlExpressions
        sqlExpressions.push(expression)
        cond.sqlExpressions = sqlExpressions
        setConditionObj(cond)
        props.setParentConditions(cond)
    }

    function setColumnConditions(newConditions: Array<ILogicColumnCondition>) {
        var newCondObj = JSON.parse(JSON.stringify(conditionObj))
        newCondObj.columnConditions = newConditions
        setConditionObj(newCondObj)
        props.setParentConditions(newCondObj)
    }

    function setColumnOperations(newOperations: Array<ILogicColumnOperator>) {
        var newCondObj = JSON.parse(JSON.stringify(conditionObj))
        newCondObj.columnOperations = newOperations
        setConditionObj(newCondObj)
        props.setParentConditions(newCondObj)
    }

    function setSqlExpression(newOperations: Array<ILogicSqlExpression>) {
        var newCondObj = JSON.parse(JSON.stringify(conditionObj))
        newCondObj.sqlExpressions = newOperations
        setConditionObj(newCondObj)
        props.setParentConditions(newCondObj)
    }

    function getHeightSteps(op: ILogicColumnOperator) {

        var steps = 1

        if (op && op.columnOperations && op.columnOperations.length > 0) {
            for (var i = 0; i < op.columnOperations.length; i++) {
                steps += getHeightSteps(op.columnOperations[i])
            }

        }
        return steps
    }

    function editOperator(value: string) {
        var conditionObjectNew = JSON.parse(JSON.stringify(conditionObj))
        conditionObjectNew.operator = value
        setConditionObj(conditionObjectNew)
        props.setParentConditions(conditionObjectNew)
    }

    function deleteOperator(index: number){
        if (conditionObj && conditionObj.columnOperations) {
            var newOperators: ILogicColumnOperator[] = conditionObj.columnOperations
            newOperators = newOperators.slice(index, 0)
            props.setParentConditions(newOperators)
        }
    }
    return (
        <div ref={ref} style={{ height: '100%', width: '100%' }}>

            <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'left', height: '100%', width: `${baseWidth}px` }}>
                <div style={{ height: `${100 * getHeightSteps(conditionObj)}px`, width: '40px' }}>
                    <JoinOperator operator={conditionObj.operator} setOperator={editOperator} enableDelete={conditionObj.columnOperations && conditionObj.columnOperations.length > 1 ? true : false } deleteOperator={deleteOperator}/>
                </div>
                {( (conditionObj.columnConditions && conditionObj.columnConditions.length > 0) || (conditionObj.sqlExpressions && conditionObj.sqlExpressions.length > 0)) &&

                    <div style={{ height: '100%', width: `${baseWidth - 250 - 40}px` }}>
                        <ConditionOperator columnConditions={conditionObj.columnConditions} columnOperations={conditionObj.columnOperations} sqlExpressions={conditionObj.sqlExpressions}
                            baseHeight={baseHeight}
                            baseWidth={baseWidth - 250 - 40}
                            columns={props.columns}
                            setColumnConditions={event => setColumnConditions(event)}
                            setColumnOperations={event => setColumnOperations(event)}
                            setSqlExpression={event => setSqlExpression(event)} />
                    </div>
                }
                <AddOperatorCard columnConditions={conditionObj.columnConditions} columnOperations={conditionObj.columnOperations} sqlExpressions={conditionObj.sqlExpressions}
                    baseHeight={baseHeight}
                    baseWidth={baseWidth - 250 - 40}
                    columns={props.columns}
                    addOperatorJoin={addRootOperatorJoinFunction}
                    addOperatorCondition={addRootOperatorConditionFunction}
                    addSqlExpression={addSqlExpressionFunction}
                    hasColumnConditions={conditionObj.columnConditions && conditionObj.columnConditions.length > 0 ? true : false}
                    hasSqlExpressions={conditionObj.sqlExpressions && conditionObj.sqlExpressions.length > 0 ? true : false} />


            </div>


        </div>
    )

}