
import React from 'react';
import BasicRuleCreationComponent from './BasicRuleCreationComponent';
import * as selectionHelper from './../SpecificRuleDataForm/RuleSelectionSpecificFormHelper'
import * as helper from 'components/common/EnrichDatasourceComponent/EnrichDatasourceTable/ErrorUtils'

import { IRule, ICommonRule } from 'interfaces/RuleInterfaces';
import ExecutionConditionGenerator from './ExecutionConditionGenerator';
import { IDataformat, IErrorsMasterData } from '../../../interfaces/DataformatInterfaces';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { ILogicColumnOperator } from '../../../interfaces/LogicCondition';

interface RuleCreationComponentProps {
  step: number,
  setStep: (value: number) => any,
  rule: IRule,
  qualityPoint: any,
  datasource: any,
  project: any,
  userId: string,
  commonRule?: ICommonRule,
  joins: any[],
  specificRule: any,
  conditions: ILogicColumnOperator,
  mandatoryErrorTags: string,
  setMandatoryErrorTags: (value: any) => any,
  setConditions: (value: any) => any,
  setJoins: (value: any) => any,
  setOptionalTags: (value: any) => any,
  setMandatoryTags: (value: any) => any,
  setCommonRule: (value: any) => any,
  onCloseFlag: boolean,
  setSpecificRule: (value: any) => any,
  hasErrors: boolean
  arrayErrorTypes: string[],
  arrayErrorMsgs: string[],
  setHasErrors: (value: boolean) => any
}



export default function RuleCreationComponent(props: RuleCreationComponentProps) {



  const [errorsMasterData, setErrorsMasterData] = React.useState<IErrorsMasterData>();
  const [errorsComparison, setErrorsComparison] = React.useState<any>();
  //const errorsMasterData = undefined
  //const errorsComparison  = undefined
  const [enrichedDataset, setEnrichedDataset] = React.useState<IDataformat>()
  const [ruleType, setRuleType] = React.useState(props.specificRule && props.specificRule.subtype ? props.specificRule.subtype : '')

  const [specificRule, setSpecificRule] = React.useState<any>()

  React.useEffect(() => {
    validateSpecificRule()
  }, [specificRule])

  function setSpecificRuleCallback(value: any) {
    props.setSpecificRule(value)
    setRuleType(value.subtype)
    setSpecificRule(value)
  }
  React.useEffect(() => {
    if (props.onCloseFlag) setEnrichedDataset(undefined)
  }, [props.onCloseFlag])

  function validateSpecificRule() {

    if (ruleType === "masterData") {
      let errorsToCheck = validateSingleSourceOfTruthRule()
      setErrorsMasterData(errorsToCheck)
      props.setHasErrors(errorsToCheck.hasErrors)
      if (errorsToCheck.hasErrors) {
        return true
      }
    }
    if (ruleType === "cell_column_comparison") {
      let errorsToCheck = validateComparisonRule()
      setErrorsComparison(errorsToCheck)
      props.setHasErrors(errorsToCheck.hasErrors)
      if (errorsToCheck.hasErrors) {
        
        return true
      }
    }
    return false
  }

  function validateSingleSourceOfTruthRule() {
    let join = specificRule && specificRule.masterDataJoin
    let allAliases: any[] = []
    let errorsToCheck = helper.checkAllFields(join, allAliases)
    return errorsToCheck
  }
  function validateComparisonRule() {
    let columnOperations = specificRule && specificRule.columnOperations
    let errorsToCheck = helper.checkAllComparisons(columnOperations)
    
    return errorsToCheck
  }


  function getStepContent(step: number) {
    
    switch (step) {
      case 0:
        return <BasicRuleCreationComponent commonRule={props.commonRule} qualityPoint={props.qualityPoint} project={props.project}
          datasource={props.datasource}
          onCloseFlag={props.onCloseFlag}
          setCommonRule={props.setCommonRule}
          setMandatoryTags={props.setMandatoryTags}
          setOptionalTags={props.setOptionalTags}
          mandatoryErrorTags={props.mandatoryErrorTags}
          setMandatoryErrorTags={props.setMandatoryErrorTags}
          rule={props.rule}
          userId={props.userId}
          joins={props.joins}
          setJoins={props.setJoins}
          setEnrichedDataset={setEnrichedDataset}
          hasErrors={props.hasErrors}
          arrayErrorTypes={props.arrayErrorTypes}
          arrayErrorMsgs={props.arrayErrorMsgs} />

      case 1:
        return selectionHelper.selectSpecificForm(props.commonRule?.type, props.commonRule, props.commonRule?.datasource, setSpecificRuleCallback,
          props.rule, props.specificRule,
          errorsMasterData, errorsComparison, enrichedDataset ? enrichedDataset.columns : [])
      case 2:

        return (
          <div style={{ minHeight: '100%' }}>
            <ExecutionConditionGenerator project={props.project} commonRule={props.commonRule}
              setConditions={props.setConditions} conditions={props.conditions} columnsEnriched={enrichedDataset ? enrichedDataset.columns : []}
              aggregatedColumnsFlag={props.commonRule?.aggregatedColumnsFlag} aggregationPrior2JoinFlag={props.commonRule?.aggregatePrior2Join}
              aggregationObject={props.commonRule?.aggregationObject}
            />
          </div>

        )
      default:
        return <div>{step}</div>;
    }
  }

  function getSteps() {
    return ['Basic Rule Data', 'Specific Rule Data', 'Execution Conditions'];
  }

  /*  function handleReset () {
      props.setStep(0);
    }*/
  return (
    <div>
      <Stepper activeStep={props.step}
      >
        {getSteps().map((label, index) => {
          const stepProps = {};
          const labelProps = {};


          return (
            <Step key={label} {...stepProps} style={{ color: '#FFFFFF' }}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {getStepContent(props.step)}
      </div>
    </div>

  )
}