
import quality from '../apis/quality';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
import {
    FETCH_CONFIGURATION,
    UPDATE_CONFIGURATION,
} from './types'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';
import qualityLocalHost from 'apis/qualityLocalHost';

export const validateConfiguration =()=>async(dispatch)=>{
    var returnValue = { }
    await quality.get(`/configuration/validate`, {
        headers: axiosHeader()
    }).then(function (response) {
        returnValue= response.data
    })
        .catch(function (error) {
            dispatchError(dispatch, error)
        });
    return returnValue
}

export const addConfiguration = (configuration, user) => async (dispatch) => {
    var returnValue = { result: 'undefined' }
    var userName = user.providerId
    if (user.role === 'SUPER_ADMIN' || user.role === 'ADMIN') {
        //guardamos la configuración como configuracion de dominio (licencia)
        userName = user.licenseId
    }
    configuration.userName = userName;
    await quality.post(`/configuration/${userName}`, configuration, {
        headers: axiosHeader()
    }).then(function (response) {
        dispatch({
            type: UPDATE_CONFIGURATION,
            payload: response.data
        });
        dispatchInfo(dispatch, `Configuration updated`)
        returnValue = { result: 'ok' }
    })
        .catch(function (error) {
            dispatchError(dispatch, error)

            returnValue = { result: 'ko', error: error }
        });
    return returnValue;
};

export const fetchConfiguration = (user) => async (dispatch, getState) => {
    var userName = user.providerId
    if (user.role === 'SUPER_ADMIN' || user.role === 'ADMIN') {
        //obtenemos la configuración como configuracion de dominio (licencia)
        userName = user.licenseId
    }
    await quality.get(`/configuration/${userName}`, {
        headers: axiosHeader()
    }).then(function (response) {

        dispatch({
            type: FETCH_CONFIGURATION,
            payload: response.data
        });
    })
        .catch(function (error) {
            dispatchError(dispatch, error)

        });


};

export const fetchConfigurationByUserId = (userId) => async (dispatch, getState) => {

    await quality.get(`/configuration/${userId}`, {
        headers: axiosHeader()
    }).then(function (response) {

        dispatch({
            type: FETCH_CONFIGURATION,
            payload: response.data
        });
    })
        .catch(function (error) {
            dispatchError(dispatch, error)
        });


};