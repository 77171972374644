import React, { useEffect } from "react"

import * as actions from 'actions'

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import { AuthManager } from "@azure/ms-rest-browserauth";

import { useDispatch } from 'react-redux';
import ls from 'local-storage'
import { makeStyles } from '@material-ui/core/styles';

import {dispatchInfo, dispatchError} from '../../components/common/axios/axiosHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '10%',
    marginRight: '10%',
    width: '80%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function AzurePage(props) {

  const classes = useStyles();
  //const [aManager, setAManager] = useState(null)

  const dispatch = useDispatch();


  const [isLogged, setIsLogged] = React.useState(undefined)


  async function azureManagerLogin(){
    const appId = await dispatch(actions.getControlValue("AQTIVA_APP_ID"))
    const tenantId =await dispatch(actions.getControlValue("AQTIVA_TENANT_ID"))

    const authManager = new AuthManager({
      tenantId: ls.get('tenantId') ? ls.get('tenantId') : tenantId,
      clientId: ls.get('clientId') ? ls.get('clientId') : appId,
      redirectUri:  (process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_FRONT_HOST : process.env.REACT_APP_FRONT_HOST)+"azure",
      popUp: true,

    });
    
    authManager.finalizeLogin().then((result) => {

      if (result && result.isLoggedIn && result.isLoggedIn === true) {
        //estamos logeados, redirigir a la última página (a traves de browserhistory)
        setIsLogged(true)
        
        //set a timer of 3 seconds before goBack
        setTimeout(function () {
           props.history.goBack()
           
        }, 3000);
        dispatchInfo(dispatch,"Successfully logged in Azure for Data Exploration")
      }
      else {
        
        setIsLogged(false)
        //no estamos logueados, mensaje de error, y a ser posible con algo de info
        //set a timer of 3 seconds before goBack
        setTimeout(function () {
          props.history.goBack()
          
       }, 3000);
       dispatchError(dispatch,"Error Login in Azure")

      }
    });



  }

  useEffect(() => {
    azureManagerLogin()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

if(isLogged ===undefined){
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <div style={{ height: '10%' }}>

        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin:'2%'
        }}>
          <Typography variant='subtitle2'>Waiting for Azure Login Response</Typography>
        </div>
        <div className={classes.root}>
          <LinearProgress />

        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin:'2%'
        }}>
          <Typography variant='subtitle2'>Please Wait..</Typography>
        </div>
      </Container>
    </React.Fragment>
  )
}

else if(isLogged === true){
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <div style={{ height: '10%' }}>

        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin:'2%'
        }}>
          <Typography variant='subtitle2'>Successfully Azure Login</Typography>
        </div>
        <div className={classes.root}>
          <LinearProgress />

        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin:'2%'
        }}>
          <Typography variant='subtitle2'>Please Wait to be redirected to your previous page</Typography>
        </div>
      </Container>
    </React.Fragment>
  )
}
else if(isLogged === false){
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <div style={{ height: '10%' }}>

        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin:'2%'
        }}>
          <Typography variant='subtitle2'>Error in Azure Login</Typography>
        </div>
        <div className={classes.root}>
          <LinearProgress />

        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin:'2%'
        }}>
          <Typography variant='subtitle2'>Please Wait to be redirected to your previous page</Typography>
          <Typography variant='subtitle2'>Contact your Aqtiva administrator</Typography>
        </div>
      </Container>
    </React.Fragment>
  )
}



}