import React, { useEffect, useState } from 'react';
import AqtivaDialog from 'components/common/AqtivaDialog'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import SelectResource from './SelectResource'
import ConnectionMethod from './ConnectionMethod'
import ConnectionInfo from './ConnectionInfo'
import Summary from './Summary'
import * as errorHelper from './Helpers/ErrorHelper'


export default function AddDataCredentialsDialog(props) {


    const [connectionPhase, setConnectionPhase] = React.useState(1)

    const [resourceType, setResourceType] = useState('')
    const [connectionMethod, setConnectionMethod] = useState('')
    const [sasMethod, setSasMethod] = useState('')
    const [displayName, setDisplayName] = useState('')

    const [connectionObjectAccessKey, setConnectionObjectAccessKey] = useState(undefined)

    const [connectionObjectSas, setConnectionObjectSas] = useState(undefined)
    const [connectionObjectSqlServer, setConnectionObjectSqlServer] = useState(undefined)
    const [connectionObjectOnPremise, setConnectionObjectOnPremise] = useState(undefined)
    const [connectionObjectMySQL, setConnectionObjectMySQL] = useState(undefined)
    const [connectionObjectBigQuery, setConnectionObjectBigQuery] = useState(undefined)
    const [connectionObjectAWS, setConnectionObjectAWS] = useState(undefined)
    const [connectionObjectHive, setConnectionObjectHive] = useState(undefined)
    const [connectionObjectRedshift, setConnectionObjectRedshift] = useState(undefined)
    const [id, setId] = useState(undefined)
    const [errors, setErrors] = useState(undefined)
    const [autoCompleteEmptyDisplayName, setAutoCompleteEmptyDisplayName] = useState(true)




    useEffect(() => {
        let newId = Date.now().toString(36) + Math.random().toString(36).substr(2)
        setId(newId)
        setConnectionPhase(1)
    }, [props.showAddDialog])

    useEffect(() => {
        let result = createDTOAndCheckForErrors()
        let onlyConnectionString = true
        if (sasMethod === "connectionString") {
            let resultOnlyConnectionString = createDTOAndCheckForErrors(onlyConnectionString)
            if (displayName === "" && !resultOnlyConnectionString.errorsToCheck.hasErrors && connectionObjectSas && autoCompleteEmptyDisplayName) {
                setDisplayName(result.dataStorageCredentialsDTO.storageAccountCredentialsDTO.storageAccountName)
                setAutoCompleteEmptyDisplayName(false)
            }
        }
        setErrors(result.errorsToCheck)

    }, [connectionObjectSas, displayName, sasMethod, connectionObjectMySQL, connectionObjectOnPremise, connectionObjectSqlServer, connectionObjectBigQuery, connectionObjectAWS, connectionObjectHive, connectionObjectRedshift])// eslint-disable-line react-hooks/exhaustive-deps




    function addStorageCredentials(newDataCredentials) {
        console.log('addStorageCredentials', newDataCredentials)
        let result = createDTOAndCheckForErrors()
        if (result && result.errorsToCheck.hasErrors) {
            setErrors(result.errorsToCheck.errorsToCheck)
            return
        }
        else {
            newDataCredentials.push(result.dataStorageCredentialsDTO)
            props.setData(newDataCredentials)
            props.setUpdateCounter(props.updateCounter + 1)

            props.setShowAddDialog(false)
            resetState()
        }
    }

    function resetState() {
        setResourceType('')
        setConnectionMethod('')
        setSasMethod('')
        setDisplayName('')
        setConnectionObjectAccessKey(undefined)
        setConnectionObjectSas(undefined)
        setErrors(undefined)
        setAutoCompleteEmptyDisplayName(true)
    }

    //damos la opcion de solo checkear connection string para que pueda autocompletar el displayName en caso de que este vacio.
    function createDTOAndCheckForErrors(onlyConnectionString) {
        let dataStorageCredentialsDTO = buildDataStorageCredentialsDTO()

        let errorsToCheck = checkQualityCredentials(dataStorageCredentialsDTO, onlyConnectionString)

        let result = {}

        result.dataStorageCredentialsDTO = dataStorageCredentialsDTO
        result.errorsToCheck = errorsToCheck
        return result
    }


    function buildDataStorageCredentialsDTO() {
        let dataStorageCredentialsDTO
        switch (resourceType) {
            case "azure_storage":
                dataStorageCredentialsDTO = buildAzureStorageCredentialsDTO()
                return dataStorageCredentialsDTO
            case "azure_container":
                dataStorageCredentialsDTO = buildAzureStorageCredentialsDTO()
                return dataStorageCredentialsDTO
            case "sqlServer":
                dataStorageCredentialsDTO = buildSQLServerCredentialsDTO()
                return dataStorageCredentialsDTO
            case "azure-sqlsynapse":
                    dataStorageCredentialsDTO = buildSQLServerCredentialsDTO()
                    return dataStorageCredentialsDTO
            case "mySQL":
                dataStorageCredentialsDTO = buildMySQLCredentialsDTO()
                return dataStorageCredentialsDTO
            case "oracle":
                dataStorageCredentialsDTO = buildOracleCredentialsDTO()
                return dataStorageCredentialsDTO
            case "bigQuery":
                dataStorageCredentialsDTO = buildBigQueryCredentialsDTO()
                return dataStorageCredentialsDTO
            case "aws":
                dataStorageCredentialsDTO = buildAWSCredentialsDTO()
                return dataStorageCredentialsDTO
            case "awsHive":
                dataStorageCredentialsDTO = buildHiveCredentialsDTO()
                return dataStorageCredentialsDTO
            case "awsRedshift":
                dataStorageCredentialsDTO = buildRedshiftCredentialsDTO()
                return dataStorageCredentialsDTO
            default:
                dataStorageCredentialsDTO = buildAzureStorageCredentialsDTO()
                return dataStorageCredentialsDTO
        }

    }

    function buildAzureStorageCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.storageAccountCredentialsDTO = connectionObjectSas
        dataStorageCredentialsDTO.connectionMethod = connectionMethod
        dataStorageCredentialsDTO.sasMethod = sasMethod
        return dataStorageCredentialsDTO
    }
    function buildOracleCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.oracleStorageCredentialsDTO = connectionObjectOnPremise
        //Falta añadir el metodo para crear el jdbc
        dataStorageCredentialsDTO.oracleStorageCredentialsDTO.url = buildUrlConnectionOracle()

        return dataStorageCredentialsDTO
    }
    function buildSQLServerCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.sqlServerCredentialsDTO = connectionObjectSqlServer
        dataStorageCredentialsDTO.sqlServerCredentialsDTO.url = buildUrlConnectionSqlServer()
        return dataStorageCredentialsDTO
    }
    function buildMySQLCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.mySQLCredentialsDTO = connectionObjectMySQL
        dataStorageCredentialsDTO.mySQLCredentialsDTO.url = buildUrlConnectionMySQL()
        return dataStorageCredentialsDTO
    }
    function buildBigQueryCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.bigQueryCredentialsDTO = connectionObjectBigQuery
        dataStorageCredentialsDTO.bigQueryCredentialsDTO.url = buildUrlConnectionBigQueryServer()
        return dataStorageCredentialsDTO
    }

    function buildAWSCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.awsCredentialsDTO = connectionObjectAWS
        return dataStorageCredentialsDTO
    }

    function buildHiveCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.hiveCredentialsDTO = connectionObjectHive
        dataStorageCredentialsDTO.hiveCredentialsDTO.jdbcUrl = buildUrlConnectionHive()
        return dataStorageCredentialsDTO
    }

    function buildRedshiftCredentialsDTO() {
        let dataStorageCredentialsDTO = {}
        dataStorageCredentialsDTO.id = id
        dataStorageCredentialsDTO.displayName = displayName
        dataStorageCredentialsDTO.storageType = resourceType
        dataStorageCredentialsDTO.redshiftCredentialsDTO = connectionObjectRedshift
        dataStorageCredentialsDTO.redshiftCredentialsDTO.jdbcUrl = buildUrlConnectionRedshift()
        return dataStorageCredentialsDTO
    }

    function buildUrlConnectionMySQL() {
        let url = "jdbc:mysql://"
        if (connectionObjectMySQL && connectionObjectMySQL.server && connectionObjectMySQL.port && connectionObjectMySQL.schema) {
            url += connectionObjectMySQL.server
            if (connectionObjectMySQL.port) {
                url += ":" + connectionObjectMySQL.port
            }
            url += "/" + connectionObjectMySQL.schema
        }
        return url

    }
    function buildUrlConnectionSqlServer() {
        let url = "jdbc:sqlserver://"
        if (connectionObjectSqlServer && connectionObjectSqlServer.server) {
            url += connectionObjectSqlServer.server
            if (connectionObjectSqlServer.port) {
                url += ":" + connectionObjectSqlServer.port
            }
        }
        return url
    }

    function buildUrlConnectionOracle() {
        let url = "jdbc:oracle:thin:@//"
        if (connectionObjectOnPremise && connectionObjectOnPremise.server) {
            url += connectionObjectOnPremise.server
            if (connectionObjectOnPremise.port) {
                url += ":" + connectionObjectOnPremise.port
            }
            if (connectionObjectOnPremise.database) {
                url += "/" + connectionObjectOnPremise.database
            }
        }
        return url
    }

    function buildUrlConnectionHive() {
        //jdbc:hive2://ec2-54-170-96-123.eu-west-1.compute.amazonaws.com:10000/default
        let url = "jdbc:hive2://"
        if (connectionObjectHive && connectionObjectHive.host) {
            url += connectionObjectHive.host
            if (connectionObjectHive.port) {
                url += ":" + connectionObjectHive.port + "/default"
            }
        }

        return url
    }

    function buildUrlConnectionRedshift() {
       
        let url = "jdbc:redshift://"
        if (connectionObjectRedshift && connectionObjectRedshift.host) {
            url += connectionObjectRedshift.host
            if (connectionObjectRedshift.port) {
                url += ":" + connectionObjectRedshift.port + "/" + connectionObjectRedshift.database
            }
        }

        return url
    }

    function buildUrlConnectionBigQueryServer() {
        let url = "jdbc:datadirect:googlebigquery:AuthenticationMethod=serviceaccount;Project="
        if (connectionObjectBigQuery && connectionObjectBigQuery.projectId) url += connectionObjectBigQuery.projectId + ";Dataset="
        if (connectionObjectBigQuery && connectionObjectBigQuery.dataset) url += connectionObjectBigQuery.dataset + ";ServiceAccountPrivateKey="
        if (connectionObjectBigQuery && connectionObjectBigQuery.serviceAccountEmail) url += connectionObjectBigQuery.serviceAccountEmail + ";ServiceAccountPrivateKey="
        if (connectionObjectBigQuery && connectionObjectBigQuery.serviceAccountPrivateKey) url += connectionObjectBigQuery.serviceAccountPrivateKey
        return url
    }

    function checkQualityCredentials(dataStorageCredentialsDTO, onlyConnectionString) {
        let errorsToCheck = {}
        if (resourceType && (resourceType === "azure_storage" || resourceType === "azure_container") && onlyConnectionString) {
            errorsToCheck = errorHelper.checkForErrorsConnectionString(dataStorageCredentialsDTO)
        }
        else {
            if (resourceType && resourceType === "azure_storage") {
                errorsToCheck = errorHelper.checkForErrorsStorageAccount(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "azure_container") {
                errorsToCheck = errorHelper.checkForErrorsContainer(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "oracle") {
                errorsToCheck = errorHelper.checkForErrorsOracle(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "sqlServer") {
                errorsToCheck = errorHelper.checkForErrorsSqlServer(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "synapse") {
                errorsToCheck = errorHelper.checkForErrorsSynapse(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "mySQL") {
                errorsToCheck = errorHelper.checkForErrorsMySQLServer(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "bigQuery") {
                errorsToCheck = errorHelper.checkForErrorsBigQuery(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "aws") {
                errorsToCheck = errorHelper.checkForErrorsAWS(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "awsHive") {
                errorsToCheck = errorHelper.checkForErrorsHive(dataStorageCredentialsDTO)
            }
            if (resourceType && resourceType === "awsRedshift") {
                errorsToCheck = errorHelper.checkForErrorsRedshift(dataStorageCredentialsDTO)
            }
        }
        return errorsToCheck
    }


    return (
        <AqtivaDialog visible={props.showAddDialog}
            completeScreen={true}
            title={'Store Data Storage Credentials'}
            titleIcon={faWindows}
            confirmText={"Connect"}
            cancelText={'Close'}
            showConfirmButton={connectionPhase === 4 ? true : false}
            confirmCallback={() => { addStorageCredentials(props.data) }}
            cancelCallback={() => {
                resetState()
                props.setShowAddDialog(false)
            }}
        >
            <div style={{ width: '450px', height: '600px' }}>
                {connectionPhase === 1 ? <SelectResource configuration={props.configuration} connectionPhase={connectionPhase} setConnectionPhase={setConnectionPhase} setResourceType={setResourceType} /> : <></>}
                {connectionPhase === 2 ? <ConnectionMethod connectionPhase={connectionPhase} setConnectionPhase={setConnectionPhase} setConnectionMethod={setConnectionMethod} connectionMethod={connectionMethod} resourceType={resourceType} /> : <></>}
                {connectionPhase === 3 ? <ConnectionInfo connectionPhase={connectionPhase} setConnectionPhase={setConnectionPhase} connectionMethod={connectionMethod} setConnectionObjectOnPremise={setConnectionObjectOnPremise} setConnectionObjectSas={setConnectionObjectSas} setConnectionObjectAccessKey={setConnectionObjectAccessKey} setDisplayName={setDisplayName} id={id} errors={errors} displayName={displayName} connectionObjectSas={connectionObjectSas} sasMethod={sasMethod} setSasMethod={setSasMethod} resourceType={resourceType} setConnectionObjectMySQL={setConnectionObjectMySQL} connectionObjectSqlServer={connectionObjectSqlServer} setConnectionObjectSqlServer={setConnectionObjectSqlServer} setConnectionObjectBigQuery={setConnectionObjectBigQuery} connectionObjectBigQuery={connectionObjectBigQuery} connectionObjectAWS={connectionObjectAWS} setConnectionObjectAWS={setConnectionObjectAWS} connectionObjectHive={connectionObjectHive} setConnectionObjectHive={setConnectionObjectHive} setConnectionObjectRedshift={setConnectionObjectRedshift} /> : <></>}
                {connectionPhase === 4 ? <Summary connectionPhase={connectionPhase} setConnectionPhase={setConnectionPhase} displayName={displayName} connectionMethod={connectionMethod} connectionObjectAccessKey={connectionObjectAccessKey} connectionObjectSas={connectionObjectSas} resourceType={resourceType} connectionObjectMySQL={connectionObjectMySQL} connectionObjectSqlServer={connectionObjectSqlServer} setConnectionObjectSqlServer={setConnectionObjectSqlServer} connectionObjectBigQuery={connectionObjectBigQuery} setConnectionObjectBigQuery={setConnectionObjectBigQuery} connectionObjectAWS={connectionObjectAWS} setConnectionObjectAWS={setConnectionObjectAWS} /> : <></>}

            </div>

        </AqtivaDialog>
    )
}