import qualityLocalHost from 'apis/qualityLocalHost';
import quality from '../apis/quality';
//import qualityLocalHost2 from '../apis/qualityLocalHost2';
//import qualityLocalHost from '../apis/qualityLocalHost';

import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';


export const listBuckets = (credentials) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post('/databricks/list/aws/buckets', credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const listObjects = (credentials) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/list/aws/objects`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const listPrefixObjects = (credentials, prefix) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/list/aws/prefix/${prefix}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const createPreviewDataJobAwsS3 = (environment, userId, credentials, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/aws/s3/preview/${userId}/${environment}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}


export const createProfilingDataJobAwsS3 = (environment, userId, credentials, nSamples, flatten) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/aws/s3/profiling/${userId}/${environment}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
    dispatchInfo(dispatch, `Data preview job running`)

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const getS3Object = (credentials, filename) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/databricks/aws/objects/${filename}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const uploadObjectStringToS3 = (updateFileStorageDTO) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/upload/file/s3/string`, updateFileStorageDTO, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const uploadObjectStringToAzure = (updateFileStorageDTO) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/upload/file/azure/string/deploy`, updateFileStorageDTO, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const listStorages = (credentials, type) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/filestorage/containers/${type}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const listContainers = (credentials, type) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/filestorage/objects/${type}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const listObjectsFileStorage = (credentials, type) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/filestorage/objects/${type}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const listFolderObjectsFileStorage = (credentials, type, folder) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.post(`/filestorage/prefixes/${type}/${folder}`, credentials, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data

  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}
