import React from "react";
import AqtivaDialog from "components/common/AqtivaDialog";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";

import TopicTable from './TopicTable'


export default function DialogViewDetailTopic(props) {
   

   
    return (
        <AqtivaDialog
            visible={props.visible}
            maxWidth={"lg"}
            title={"Stream Topic Inspection"}
            titleIcon={faHubspot}
            confirmText={"Simulate"}
            cancelText={"Close"}
            showConfirmButton={false}
            confirmCallback={(event) => console.log(event)}
            cancelCallback={(event) => props.close(false)}
        >
            <TopicTable
            endPoint = {props.endPoint}
            entityPath = {props.entityPath}
            sak = {props.sak}
            sakName = {props.sakName}
            />
        </AqtivaDialog>
    );
}
