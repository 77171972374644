import React, { useEffect, useState } from "react";
import { Grid } from '@material-ui/core';
import * as colorHelper from './../../colorHelper'
import * as am5 from "@amcharts/amcharts5";


import DonutChart from '../Common/Charts/DonutChart'
import SpiderChart from '../Common/Charts/SpiderChart'
import DashboardChartComponent from '../Common/Components/DashboardChartComponent';
import DQIDimensionComponent from "../Common/Components/DQIDimensionComponent";
import TreeViewDimensionsAndRuleTypes from "../Common/Charts/TreeViewDimensionsAndRuleTypes";


export default function GlobalDQIComponent(props) {


    const [projects, setProjects] = useState([])
    const [donutData, setDonutData] = useState([])
    const [dimension, setDimension] = useState([]);
    const [executionsByDimension, setExecutionsByDimension] = useState([])
    const [executionsByRuleType, setExecutionsByRuleType] = useState([])
    const [definitionsByDimension, setDefinitionsByDimension] = useState([])
    const [definitionsByRuleType, setDefinitionsByRuleType] = useState([])
    const [DQIAndExecutions4RuleTypeAndDimension, setDQIAndExecutions4RuleTypeAndDimension] = useState()

    useEffect(() => {

        if (props.DQI4Project && props.DQI4Project.length > 0) {
            let listOfProjects = props.DQI4Project.map(val => {
                return {
                    ...val,
                    'score': Number(((val.sumNumOk / (val.sumNumOk + val.sumNumKo)) * 100).toFixed(0)),
                    'scoreKo': -Number(((val.sumNumKo / (val.sumNumOk + val.sumNumKo)) * 100).toFixed(0))
                }
            })
            setProjects(listOfProjects)

            setDonutData(listOfProjects.reduce((a, b) => {

                return {
                    'sumOk': a.sumNumOk + b.sumNumOk,
                    'sumKo': a.sumNumKo + b.sumNumKo
                }
            }))
        }

        if(props.globalDQI){
            setDonutData(props.globalDQI)
        }
        if (props.DQIByDimension && props.DQIByDimension.length > 0) {
            setDimension(props.DQIByDimension);
        }

        if (props.executionsByDimension && props.executionsByDimension.length > 0) {
            setExecutionsByDimension(props.executionsByDimension)
        }

        if (props.executionsByRuleType && props.executionsByRuleType.length > 0) {

            setExecutionsByRuleType(props.executionsByRuleType.map(val => {
                return {
                    'dimension': val.subject,
                    'executions': val.value
                }
            }))
        }

        if (props.definitionsByDimension && props.definitionsByDimension.length > 0) {

            setDefinitionsByDimension(props.definitionsByDimension.map(val => {
                return {
                    'dimension': val.dimension,
                    'executions': val.definitions
                }
            }))

        }

        if (props.definitionsByRuleType && props.definitionsByRuleType.length > 0) {

            setDefinitionsByRuleType(props.definitionsByRuleType.map(val => {
                return {
                    'dimension': parseDataLabels(val),
                    'executions': val.value
                }
            }))



        }

        if (props.DQIAndExecutions4RuleTypeAndDimension && props.DQIAndExecutions4RuleTypeAndDimension.length > 0) {

            setDQIAndExecutions4RuleTypeAndDimension(
                {
                    name: "First",
                    children: 
                        props.DQIAndExecutions4RuleTypeAndDimension.map(val => {
                            let score = Number(((val.sumNumOk / val.sumNumIn) * 100).toFixed(0))
                            return {
                                ...val,
                                'dimension': capitalizeFirstLetter(val.dimension),
                                'score': score,
                                'settings': { fill: am5.Color.fromString(colorHelper.getColorForPercentage(score)) }
                            }
                        }),
                    
                })
        }

    }, [props.DQI4Project, props.globalDQI, props.DQIByDimension, // eslint-disable-line react-hooks/exhaustive-deps
        props.executionsByDimension, props.executionsByRuleType, props.definitionsByDimension,// eslint-disable-line react-hooks/exhaustive-deps
        props.definitionsByRuleType, props.DQIAndExecutions4RuleTypeAndDimension])// eslint-disable-line react-hooks/exhaustive-deps



    function parseDataLabels(data) {
        if (data.subject === 'CELL_NULL') return 'Null'
        if (data.subject === 'cell_range') return 'Value Range'
        if (data.subject === 'DUPLICATED') return 'Duplicated'
        if (data.subject === 'cell_column_comparison') return 'Column Comparison'
        if (data.subject === 'statistics') return 'Format'
        if (data.subject === 'CELL_EXP') return 'Regex'
        if (data.subject === 'time_diff') return 'Time Check'
        if (data.subject === 'cell_anomaly_rows') return 'Anomaly Rows'
        if (data.subject === 'cell_anomaly_columns') return 'Anomaly Columns'
        return capitalizeFirstLetter(data.subject)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const donutChart = <DonutChart data={donutData.DQIok} showCenterLabel={true} chart={'chartDonutGlobalDQI'} click={false} />
    const dimensionComponent = <DQIDimensionComponent data={projects} DQIByDimension={dimension} />

    const spiderChart1 = <SpiderChart chart={"spiderGlobalDQI1"} data={executionsByDimension} />
    const spiderChart2 = <SpiderChart chart={"spiderGlobalDQI2"} data={executionsByRuleType} />
    const spiderChart3 = <SpiderChart chart={"spiderGlobalDQI3"} data={definitionsByDimension} />
    const spiderChart4 = <SpiderChart chart={"spiderGlobalDQI4"} data={definitionsByRuleType} />

    const treeView = <TreeViewDimensionsAndRuleTypes chart={"TreeViewDimensionsAndRuleTypes"} data={[DQIAndExecutions4RuleTypeAndDimension]} />

    return (

        <>
            <Grid container spacing={1} style={{ width: '100%' }}>


                <Grid item xs={4} >
                    <DashboardChartComponent loading = {false} content={donutChart} title={'Global DQI'} description={'Global DQI'} />
                </Grid>

                <Grid item xs={8} >
                    <DashboardChartComponent loading = {false} content={dimensionComponent} title={'Global DQI'} description={'Global DQI'} />
                </Grid>

                <Grid item xs={6} >
                    <Grid container spacing={1} >

                        <Grid item xs={6} >
                            <DashboardChartComponent loading={false}  content={spiderChart1} title={'Rule Execution by Dimension Coverage'} description={'Rule Execution by Dimension Coverage'} />
                        </Grid>

                        <Grid item xs={6} >
                            <DashboardChartComponent loading ={false} content={spiderChart2} title={'Rule Execution by Dimension Coverage'} description={'Rule Execution by Dimension Coverage'} />
                        </Grid>


                        <Grid item xs={6} >
                            <DashboardChartComponent loading = {false} content={spiderChart3} title={'Rule Execution by Dimension Coverage'} description={'Rule Execution by Dimension Coverage'} />
                        </Grid>

                        <Grid item xs={6} >
                            <DashboardChartComponent loading = {false} content={spiderChart4} title={'Rule Execution by Dimension Coverage'} description={'Rule Execution by Dimension Coverage'} />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <DashboardChartComponent loading = {false} content={treeView} title={'DQI Per Dimension and Rule Type'} description={'DQI Per Dimension and Rule Type'} />
                </Grid>

            </Grid>

        </>
    )
}