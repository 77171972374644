import React, { useEffect, useState } from 'react';

import AqtivaDialog from '../../../AqtivaDialog';
import EnrichCard from '../EnrichCard'
import {  faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import * as helper from '../ErrorUtils'


export default function CreateNewJoinDialog(props) {

    const [join, setJoin] = useState('')
    const [errors, seterrors] = useState('')
    const [counter, setCounter] = useState(1)


    useEffect(() => {
        if (counter > 1) {
            let allAliases = getAllAliasesInDatasourceAndRules(join, props.datasource)
            let errorsToCheck = helper.checkAllFields(join, allAliases)
            seterrors(errorsToCheck)
        }
    }, [counter, counter > 1 && join])// eslint-disable-line react-hooks/exhaustive-deps



    function addJoinToList(newJoins, elementToAdd) {
        let allAliases = getAllAliasesInDatasourceAndRules(join, props.datasource)
        elementToAdd = setAggregationColumnAlias(elementToAdd)
        console.log('elementToAdd mod', elementToAdd)
        let errorsToCheck = helper.checkAllFields(elementToAdd, allAliases)
        seterrors(errorsToCheck)
        if (!errorsToCheck.hasErrors) {
            elementToAdd.id = Date.now().toString(36) + Math.random().toString(36).substr(2)
            newJoins.push(elementToAdd)
            props.setData(newJoins)
            props.setShowCreateDialog(false)
        }
        else {
            setCounter(2)
        }
    }

    function setAggregationColumnAlias(enrich){
        if(enrich && enrich.alias && enrich.alias.length > 0 
            && enrich.aggregationObject != undefined && enrich.aggregationObject.aggregationOperations 
            && enrich.aggregationObject.aggregationOperations.length > 0){

                enrich.aggregationObject.aggregationOperations.forEach(el => {
                    el.column.name = enrich.alias + '_' + el.column.name
                })
                return enrich
        }else{
            return enrich
        }
    }

    function getAllAliasesInDatasourceAndRules(join, datasource) {
        let allAliases = []
        if (datasource && datasource.enrichDataformatObject && datasource.enrichDataformatObject.joins && datasource.enrichDataformatObject.joins.length > 0) {
            for (let i = 0; i < datasource.enrichDataformatObject.joins.length; i++) {
                if (datasource.enrichDataformatObject.joins[i].id !== join.id) {
                    allAliases.push(datasource.enrichDataformatObject.joins[i].alias ? datasource.enrichDataformatObject.joins[i].alias : "no alias")
                }
            }
        }
        if (datasource && datasource.rules && datasource.rules.length > 0) {
            for (let i = 0; i < datasource.rules.length; i++) {
                if (datasource.rules[i].enrichDataformatObject && datasource.rules[i].enrichDataformatObject.joins && datasource.rules[i].enrichDataformatObject.joins.length > 0) {
                    for (let j = 0; j < datasource.rules[i].enrichDataformatObject.joins.length; j++) {
                        if (datasource.rules[i].enrichDataformatObject.joins[j].id !==join.id) {
                            allAliases.push(datasource.rules[i].enrichDataformatObject.joins[j].alias ? datasource.rules[i].enrichDataformatObject.joins[j].alias : "no alias")
                        }
                    }
                }
            }
        }
        return allAliases
    }

    return (
        <AqtivaDialog
            visible={props.showCreateDialog}
            dialog={props.showCreateDialog}
            titleIcon={faPlusCircle}
            fullWidth={true}
            maxWidth={'md'}
            title={"Create New Join"}
            editable={false}
            cancelText={'Cancel'}
            cancelCallback={() => {
                props.setShowCreateDialog(false)
            }}
            confirmText={'Save Join'}
            confirmCallback={async () => {
                addJoinToList(props.data, join)
            }}
        >
            <EnrichCard master={true} configuration={props.configuration} setJoin={setJoin} enrichColumns={props.enrichColumns} join={join} errors={errors} newJoin={true} datasource={props.datasource} dataformat = {props.dataformat} />
        </AqtivaDialog>
    )
}