import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEdit,  faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
     FormControlLabel, Switch, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "@material-table/core";
import { Tooltip } from '@material-ui/core'
import Fab from '@material-ui/core/Fab';
import EditCredentialsPermissionsDialog from './EditCredentialsPermissionsDialog'

import * as commonStyles from 'style/CommonStyles'


const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
    },
    fabWarning: {
        margin: theme.spacing(1),
        backgroundColor: "yellow"

    }

}));



export default function EnvironmentDataStorageCredentialsTable(props) {
    const classes = useStyles();

    const [data, setData] = useState([])
    const [selectedDataCredentialsIds, setSelectedDataCredentialsIds] = useState([])
    const [selectedDataCredentials, setSelectedDataCredentials] = useState(undefined)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [updateCounter, setUpdateCounter] = useState(1)

    // here we initialize all possible storages from the main configuration
    useEffect(() => {

        if (props.dataStorageCredentials && props.dataStorageCredentials.length > 0) {
            setData(props.dataStorageCredentials)
        }

    }, [props.dataStorageCredentials, props.dataStorageCredentials && props.dataStorageCredentials.length, props.environmentConfiguration.name])// eslint-disable-line react-hooks/exhaustive-deps


    //here we pass on which storages are already enabled in the selected environment
    useEffect(() => {
        if (props.enabledDataStorageCredentials) {
            let enabledDataStorageCredentialsIds = []
            for (let i = 0; i < props.enabledDataStorageCredentials.length; i++) {
                enabledDataStorageCredentialsIds.push(props.enabledDataStorageCredentials[i].id)
            }
            setSelectedDataCredentialsIds(enabledDataStorageCredentialsIds)
        }
    }, [props.enabledDataStorageCredentials, props.environmentConfiguration && props.environmentConfiguration.name])// eslint-disable-line react-hooks/exhaustive-deps


    //here we update environment configuration with the enabledDataStorageCredentials
    //we complete the storage information based on the selected enabledDataStorageCredentials
    useEffect(() => {
        let storageCredentialsComplete = []
        for (let i = 0; i < selectedDataCredentialsIds.length; i++) {
            let dataStorageCredentials = data.find(element => element.id === selectedDataCredentialsIds[i])
            dataStorageCredentials = inheritAccessPolicy(dataStorageCredentials)
            storageCredentialsComplete.push(dataStorageCredentials)
        }
        props.setDataStorageCredentialsConfig(storageCredentialsComplete)

    }, [selectedDataCredentialsIds, showEditDialog])// eslint-disable-line react-hooks/exhaustive-deps

    function inheritAccessPolicy(dataStorageCredentials) {
        if (!dataStorageCredentials.accessType && props.environmentConfiguration && props.environmentConfiguration.accessType) {
            dataStorageCredentials.accessType = props.environmentConfiguration.accessType
        }
        if (!dataStorageCredentials.allowedUsers && props.environmentConfiguration && props.environmentConfiguration.allowedUsers) {
            dataStorageCredentials.allowedUsers = props.environmentConfiguration.allowedUsers
        }
        if (!dataStorageCredentials.allowedUsers && props.environmentConfiguration && !props.environmentConfiguration.allowedUsers) {
            dataStorageCredentials.allowedUsers = []
        }
        return dataStorageCredentials
    }

    const handleToggle = (value) => () => {
        const currentIndex = selectedDataCredentialsIds.indexOf(value);
        const newChecked = [...selectedDataCredentialsIds];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedDataCredentialsIds(newChecked);
    };

    function openEditDialog(dataCredentials) {
        setSelectedDataCredentials(dataCredentials)
        setShowEditDialog(true)
    }




    return (
        <>
            <EditCredentialsPermissionsDialog data={data} setData={setData} showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} selectedDataCredentials={selectedDataCredentials} userId={props.userId} setSelectedDataCredentials={setSelectedDataCredentials} updateCounter={updateCounter} setUpdateCounter={setUpdateCounter} environmentOptions={props.environmentOptions} configuration={props.configuration} />


            {data && data.length > 0 && < MaterialTable
                style={{ width: "100%" }}
                title="data storage credentials"
                data={data}
                columns={
                    [
                        { title: 'display name', field: 'displayName' },
                        {
                            title: 'Storage Type', field: 'storageType', render: (rowData) => {
                                if (rowData.storageType === "azure_storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>azure_storage</Typography>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/azure/Storage-Accounts.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure_container") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>azure_storage</Typography>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/azure/Storage-Container.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure_sql_database") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>azure-sqldatabase</Typography>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '25px', width: '25px' }}
                                                src={require('./../../../../../../resources/icons/azure/sql-database-generic.png')} />
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "sqlServer") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Oracle database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/sqlServer.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure-synapse-jdbc") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Synapse Jdbc database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/azure/synapse.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "oracle") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Oracle database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/oracle.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "mySQL") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Oracle database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/mySQL.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "aws") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>BigQuery database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/aws/aws-s3-logo.png')} />
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "bigQuery") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>BigQuery database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/bigQuery.png')} />
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "awsHive") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Hive database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/hive.png')} />
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "awsRedshift") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <h3>Redshift database</h3>
                                            </React.Fragment>}>
                                            <img alt="" style={{ height: '35px', width: '35px' }}
                                                src={require('./../../../../../../resources/icons/redshift.png')} />
                                        </Tooltip>
                                    )
                                }
                            }
                        },
                        {
                            title: 'Description', field: 'description', render: (rowData) => {
                                if (rowData.storageType === "azure_storage") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Storage Account Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageAccountCredentialsDTO.storageAccountName}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure_container") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Storage Account Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageAccountCredentialsDTO.storageAccountName}</strong></Typography>
                                                <Typography variant='subtitle2'>Container  Name: <strong style={{ color: "#2fb7e0" }}>{rowData.storageAccountCredentialsDTO.folder}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "oracle") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.oracleStorageCredentialsDTO.server}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.oracleStorageCredentialsDTO.port}</strong></Typography>
                                                <Typography variant='subtitle2'>Service Name: <strong style={{ color: "#2fb7e0" }}>{rowData.oracleStorageCredentialsDTO.folder}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "mySQL") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.mySQLCredentialsDTO.server}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.mySQLCredentialsDTO.port}</strong></Typography>
                                                <Typography variant='subtitle2'>Schema Name: <strong style={{ color: "#2fb7e0" }}>{rowData.mySQLCredentialsDTO.schema}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "sqlServer") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.server}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.port}</strong></Typography>
                                                <Typography variant='subtitle2'>Database Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.database}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "azure-synapse-jdbc") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Server Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.server}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.port}</strong></Typography>
                                                <Typography variant='subtitle2'>Database Name: <strong style={{ color: "#2fb7e0" }}>{rowData.sqlServerCredentialsDTO.database}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "bigQuery") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Project ID: <strong style={{ color: "#2fb7e0" }}>{rowData.bigQueryCredentialsDTO && rowData.bigQueryCredentialsDTO.projectId ? rowData.bigQueryCredentialsDTO.projectId : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "aws") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Region: <strong style={{ color: "#2fb7e0" }}>{rowData.awsCredentialsDTO && rowData.awsCredentialsDTO.region ? rowData.awsCredentialsDTO.region : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }

                                if (rowData.storageType === "awsHive") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Host: <strong style={{ color: "#2fb7e0" }}>{rowData.hiveCredentialsDTO && rowData.hiveCredentialsDTO.host ? rowData.hiveCredentialsDTO.host : ''}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.hiveCredentialsDTO && rowData.hiveCredentialsDTO.port ? rowData.hiveCredentialsDTO.port : ''}</strong></Typography>
                                                <Typography variant='subtitle2'>Username: <strong style={{ color: "#2fb7e0" }}>{rowData.hiveCredentialsDTO && rowData.hiveCredentialsDTO.username ? rowData.hiveCredentialsDTO.username : ''}</strong></Typography>
                                                <Typography variant='subtitle2'>JDBC: <strong style={{ color: "#2fb7e0" }}>{rowData.hiveCredentialsDTO && rowData.hiveCredentialsDTO.jdbcUrl ? rowData.hiveCredentialsDTO.jdbcUrl : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                if (rowData.storageType === "awsRedshift") {
                                    return (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography variant='subtitle2'>Host: <strong style={{ color: "#2fb7e0" }}>{rowData.redshiftCredentialsDTO && rowData.redshiftCredentialsDTO.host ? rowData.redshiftCredentialsDTO.host : ''}</strong></Typography>
                                                <Typography variant='subtitle2'>Port: <strong style={{ color: "#2fb7e0" }}>{rowData.redshiftCredentialsDTO && rowData.redshiftCredentialsDTO.port ? rowData.redshiftCredentialsDTO.port : ''}</strong></Typography>
                                                <Typography variant='subtitle2'>Username: <strong style={{ color: "#2fb7e0" }}>{rowData.redshiftCredentialsDTO && rowData.redshiftCredentialsDTO.username ? rowData.redshiftCredentialsDTO.username : ''}</strong></Typography>
                                                <Typography variant='subtitle2'>JDBC: <strong style={{ color: "#2fb7e0" }}>{rowData.redshiftCredentialsDTO && rowData.redshiftCredentialsDTO.jdbcUrl ? rowData.redshiftCredentialsDTO.jdbcUrl : ''}</strong></Typography>
                                            </React.Fragment>}>
                                            <span>
                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: commonStyles.mainColor, fontSize: 'calc(12px + 0.5vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </span>
                                        </Tooltip>
                                    )
                                }

                            }
                        },
                        { title: 'access type', field: 'accessType' },
                        {
                            title: 'Actions', field: 'enabled', filtering: false, render: (rowData) => {
                                return (
                                    <>
                                        <FormControlLabel className={classes.notificationCheck}
                                            control={
                                                <Tooltip title="Enable Data Storage Credentials for selected Environment">
                                                    <Switch
                                                        edge="end"
                                                        color="primary"
                                                        onChange={handleToggle(rowData.id)}

                                                        checked={!!selectedDataCredentialsIds.find(element => element === rowData.id)}
                                                    />
                                                </Tooltip>
                                            }
                                        />
                                        {!!selectedDataCredentialsIds.find(element => element === rowData.id) && < Tooltip title={"Edit Data Credentials permissions"} >
                                            <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                                onClick={() => { openEditDialog(rowData) }}>
                                                <FontAwesomeIcon icon={faEdit} style={{ color: "white", fontSize: 'calc(7px + 0.3vw)', marginTop: '2%', marginRight: '2%' }} />
                                            </Fab>
                                        </Tooltip>}

                                    </>
                                )
                            }
                        },
                    ]}
                options={{
                    actionsColumnIndex: -1,
                    filtering: false,
                    search: false,
                    pageSize: 5,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250,
                    headerStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                        backgroundColor: commonStyles.mainColor,
                        color: '#FFF'
                    },
                    cellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                    filterCellStyle: {
                        fontSize: 'calc(8px + 0.2vw)',
                    },
                }}

                components={{
                    Toolbar: props => (
                        <div>

                        </div>
                    ),
                }}
            />}
        </>

    )
}
