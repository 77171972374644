export function parseConnectionString(connectionString) {
    let blobEndpoint
    let storageAccountName
    let queueEndpoint
    let fileEndpoint
    let tableEndpoint
    let sharedAccessSignature
    let expirationDate
    let connectionStringElements = connectionString.split(";")
    if (connectionStringElements.length === 5) {
        if (connectionStringElements[0].split("BlobEndpoint=").length > 1) {
            blobEndpoint = connectionStringElements[0].split("BlobEndpoint=")[1]
        }
        if (blobEndpoint.split("://").length > 1) {
            storageAccountName = blobEndpoint.split("://")[1] && blobEndpoint.split("://")[1].split(".blob")[0]
        }
        if (connectionStringElements[1].split("QueueEndpoint=").length > 1 && connectionStringElements[1].split("QueueEndpoint=").length > 1) {
            queueEndpoint = connectionStringElements[1].split("QueueEndpoint=") && connectionStringElements[1].split("QueueEndpoint=")[1]
        }
        if (connectionStringElements[2].split("FileEndpoint=").length > 1) {
            fileEndpoint = connectionStringElements[2].split("FileEndpoint=")[1]
        }
        if (connectionStringElements[3].split("TableEndpoint=").length > 1) {
            tableEndpoint = connectionStringElements[3].split("TableEndpoint=")[1]
        }
        if (connectionStringElements[4].split("SharedAccessSignature=").length > 1) {
            sharedAccessSignature = connectionStringElements[4].split("SharedAccessSignature=")[1]
        }
        if (sharedAccessSignature && sharedAccessSignature.split("&st=").length > 1) {

            expirationDate = sharedAccessSignature && sharedAccessSignature.split("&st=")[1].split("&")[0]
        }
    }

    let connectionStringInfo = {}
    connectionStringInfo.blobEndpoint = blobEndpoint
    connectionStringInfo.storageAccountName = storageAccountName
    connectionStringInfo.queueEndpoint = queueEndpoint
    connectionStringInfo.fileEndpoint = fileEndpoint
    connectionStringInfo.tableEndpoint = tableEndpoint
    connectionStringInfo.sharedAccessSignature = sharedAccessSignature
    connectionStringInfo.expirationDate = expirationDate
    return connectionStringInfo

}

export function parseContainerSasUrl(connectionString) {
    let storageAccountName
    let folder
    let sharedAccessSignature
    


    if (connectionString.split("https://") && connectionString.split("https://").length > 0
        && connectionString.split("https://")[1] && connectionString.split("https://")[1].length > 0) {
        let firstParse = connectionString.split("https://")[1]

        if (firstParse.split(".blob.core.windows.net/").length > 0) {
            storageAccountName = firstParse.split(".blob.core.windows.net/")[0]
        }
        if (firstParse.split(".blob.core.windows.net/")[1] && firstParse.split(".blob.core.windows.net/")[1].split("?").length === 2) {
            folder = firstParse.split(".blob.core.windows.net/")[1].split("?")[0]
            sharedAccessSignature = firstParse.split(".blob.core.windows.net/")[1].split("?")[1]
        }
    }

    let connectionStringInfo = {}
    connectionStringInfo.storageAccountName = storageAccountName
    connectionStringInfo.sharedAccessSignature = sharedAccessSignature
    connectionStringInfo.folder = folder
    return connectionStringInfo

}

