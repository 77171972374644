import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DashboardChartComponent from "../../Common/Components/DashboardChartComponent";
import * as commonStyles from 'style/CommonStyles'


import DQIPerObjectChart from '../../Common/Charts/DQIPerObjectChart'
import LollipopChartComponent from "../../Common/Components/LollipopChartsComponent";

export default function QualityPointGrid(props) {


    const [DQI4QP, setDQI4QP] = useState([])
    //const [dimension, setDimension] = useState([])
    //const [openDimensionModal, setOpenDimensionModal] = React.useState(false)
    //const handleClose = () => setOpenDimensionModal(false);


    useEffect(() =>{
        if(props.DQI4QP && props.DQI4QP.length > 0 ){           

            setDQI4QP(props.DQI4QP.map((val, index) => {

                return {
                    ...val,
                    'score': Number(((val.sumNumOk / (val.sumNumOk + val.sumNumKo)) * 100).toFixed(0)),
                    'scoreKo': Number(-((val.sumNumKo / (val.sumNumOk + val.sumNumKo)) * 100).toFixed(0)),
                    'globalDQI': ((val.sumNumOk / (val.sumNumOk + val.sumNumKo)) * 100).toFixed(0),
                }
            }))

           // getListExecutions()
        }


    }, [props.DQI4QP])


    /*async function getListExecutions(){

        DQI4QP.map(val => {
            let execution =  dispatch(actions.getDQIProjectDetail(props.filterObj, val.projectId))
        })
        
    }*/

    function handleDrillDownElement(e) {
        props.handleDrillDownElement(e)
    }

   /* function openModal(data) {
        setOpenDimensionModal(true)
    }*/

   //const donutChart = <DonutChart data={[]} showCenterLabel={true} chart={'chartDonutDataSource'} openDimensionModal={openModal} click={true} />
   const drillDown = <DQIPerObjectChart chart={'drillDownChartQualityPointSec'} data={DQI4QP.slice(0, 5)} click={handleDrillDownElement} categoryField={'qualityPointName'} valueOk={'score'} valueKo={'scoreKo'} />
   const tablesLollipop = <LollipopChartComponent  data={[]} valueRow1={'score'} valueRow2={'sumNumIn'} categoryRow1={'fecha'} categoryRow2={'fecha'} disableCharts={false}/>


    return (
        <Grid container spacing={3} style={commonStyles.titleBox}>
            <Grid item xs={12} style={commonStyles.DashboardChartComponentTitle}>
                <Typography variant='h6' style={commonStyles.titleText}>Quality Points</Typography>

            </Grid >


            <Grid item xs={6}>

                <DashboardChartComponent loading = {false}  content={drillDown} title={'Quality Points'} description={'Puntuación de todas las ejecuciones por proyecto'} />


            </Grid >
            <Grid item xs={6}>
                <DashboardChartComponent loading = {false} content={tablesLollipop} title={'Trends'} description={'Global DQI'} />

            </Grid >

        </Grid>
    )
}