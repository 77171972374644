
import React from 'react';
import {
    Grid,  Typography,  InputAdornment, Icon,
    Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "components/common/CustomInput/CustomInput.js";
import AqtivaDialog from "components/common/AqtivaDialog";

import { useDispatch } from 'react-redux'
import * as actions from 'actions'
import ExecutionConditionRule from 'components/dialogs/DialogCreateRule/ExecutionConditionRule'

import * as commonStyles from 'style/CommonStyles'


export default function DialogCreateFolder(props) {

   
    const dispatch = useDispatch();
    const [editable, setEditable] = React.useState(props.folder ? true : false)
    const [name, setName] = React.useState(editable && props.folder ? props.folder.name : '')
    //const [active, setActive] = React.useState()
    //TODO: CHECK
    const active = undefined
    const datasourceId = (getDatasourceId(props.parent, props.parentType))
    const [conditions, setConditions] = React.useState([])
    const [errors, setErrors] = React.useState("")

    function getDatasourceId(parent, parentType) {
        if (!parent) {
            return -1
        }
        if (parentType === 'Datasource') {
            return parent._id
        }
        else {
            return parent.datasourceId
        }
    }
    //set editable elements if qualityPoint is found in props
    React.useEffect(() => {

        setEditable(props.editable)
        setName(props.folder ? props.folder.name : '')
        setConditions(props.folder ? props.folder.conditions : [])


    }, [props.folder, props.editable]);
    //set editable elements if qualityPoint is found in props
    /*     React.useEffect(() => {
    
            setEditable(props.qualityPoint ? true : false)
            setName(props.qualityPoint ? props.qualityPoint.name : undefined)
            setActive(props.qualityPoint ? props.qualityPoint.active : undefined)
    
        }, [props.qualityPoint]); */

    function getParentName() {

        /*
        LOGICA
        1) si el objeto es editable, cogeremos el parent del objeto o "" si no tiene parent (no debería darse
        2) si no es editable
            2.1) si el parent es data source, parentName = ""
            2.2) si el parent es un folder:
                parentName = parent.name
                parentUri = parent.uri
        */
        if (editable && props.folder && props.folder.parent) {
            return props.folder.parentName
        }
        else if (editable && props.folder) {
            return ""
        }
        else if (!editable) {
            if (props.parentType === "Datasource") {
                return ""

            }
            else if (props.parentType === "Folder") {
                return props.parent.name
            }
        }
        else {
            return ""
        }
    }

    function getParentUri() {

        /*
        LOGICA
        1) si el objeto es editable, cogeremos el parent del objeto o "" si no tiene parent (no debería darse
        2) si no es editable
            2.1) si el parent es data source, parentName = ""
            2.2) si el parent es un folder:
                parentName = parent.name
                parentUri = parent.uri
        */
        if (editable && props.folder && props.folder.parent) {
            return props.folder.parentUri
        }
        else if (editable && props.folder) {
            return ""
        }
        else if (!editable) {
            if (props.parentType === "Datasource") {
                return ""

            }
            else if (props.parentType === "Folder") {
                return props.parent.url
            }
        }
        else {
            return ""
        }
    }

    function checkFolderNameExistsAtSameLevel(folder, datasource) {
        let exists = false
        if (folder && datasource && datasource.folders && datasource.folders.length > 0) {
            for (let i = 0; i < datasource.folders.length; i++) {
                if (datasource.folders[i].level === folder.level && datasource.folders[i].parentUri === folder.parentUri && datasource.folders[i].name === folder.name) {
                    exists = true
                }
            }
        }
        return exists
    }







    const onChangeTextField = (event) => {

        setName(event.target.value)
    }


    const createFolder = () => {
        const fileItem = {
            url: `${getParentUri()}/${name}`,
            name: name,
            active: active,
            parentName: getParentName(),
            parentUri: getParentUri(),
            datasourceId: datasourceId,
            level: (props.parentType === 'Folder') ? props.parent.level + 1 : 1,
            conditions: conditions ? conditions : []


        }
        let nameExists = checkFolderNameExistsAtSameLevel(fileItem, props.datasource)
        if (!nameExists) {
            dispatch(actions.addFolder(props.project._id,
                (props.parentType === 'Folder') ? props.parent.datasourceId : props.parent._id,
                fileItem, props.userId))
            props.setClose(false)
        } else {
            setErrors("This folder name already exists.")
        }

    }

    const editFolder = () => {
        var fileItem = Object.assign({}, props.folder);
        fileItem.name = name
        fileItem.conditions = conditions

        let nameExists = checkFolderNameExistsAtSameLevel(fileItem, props.datasource)
        if (!nameExists) {
            dispatch(actions.editFolder(props.project._id,
                (props.parentType === 'Folder') ? props.parent.datasourceId : props.parent._id,
                fileItem, props.userId))
            props.setEditable(false)
            props.setClose(false)
        } else {
            setErrors("This folder name already exists.")
        }




    }

    function setExecutionConditionsCallback(cond) {

        setConditions(cond)
    }



    return (
        <AqtivaDialog visible={props.open}
            maxWidth={'lg'}
            editable={editable}
            title={editable ? 'Edit Folder' : 'Create Folder'}
            titleIcon={faFolderOpen}
            confirmText={'Create'}
            cancelText={'Close'}
            editText={'Edit'}
            confirmCallback={createFolder}
            cancelCallback={event => {
                props.setEditable(false)
                props.setClose(false)
            }}
            editCallback={editFolder}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CustomInput
                        labelText="Folder Name"
                        id="folder_name_id"
                        formControlProps={{
                            fullWidth: false,
                            style: { width: '200%' }
                        }}

                        inputProps={{
                            type: "text",
                            onChange: onChangeTextField,
                            value: name,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {errors && <Typography variant="subtitle2" gutterBottom style={{ color: commonStyles.errorColor }}>{errors}</Typography>}
                </Grid>

                {undefined && <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid item xs={1}>
                                <FontAwesomeIcon icon={faCog} style={{ color: commonStyles.mainColor, fontSize: 20 }} />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant='subtitle2'>Execution Conditions</Typography>
                            </Grid>

                        </AccordionSummary>
                        <AccordionDetails>
                            <ExecutionConditionRule setConditions={setExecutionConditionsCallback}
                                dataset={props.datasource ? props.datasource.dataset : []} conditions={conditions} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>}
            </Grid>



        </AqtivaDialog>
    )
}