import React from 'react';
import { makeStyles, Theme, createStyles, alpha } from '@material-ui/core/styles';

import { InputBase, Card, CardHeader, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText, Grid, Button } from '@material-ui/core';
import { IDataformat } from 'interfaces/DataformatInterfaces';
import { IAggregationObject, IColumn } from 'interfaces/RuleInterfaces';
import SearchIcon from '@material-ui/icons/Search';

import ColumnSelectorItem from './ColumnSelectorItem'

import * as enrichHelper from 'components/common/EnrichDataformatHelper'


type IProps = {
  baseDataFormat: IDataformat,
  selectedColumns: Array<IColumn> | undefined,
  setSelectedColumns: (newValue: Array<IColumn>) => (any)
  ruleType: string,
  aggregationObject?: IAggregationObject,
  aggregatePrior2Join: boolean,
  aggregatedColumnsFlag: boolean,

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: '100%',
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',

    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }),
);

function not(a: IColumn[], b: IColumn[]) {
  if(a === undefined || b===undefined){
    return []
  }
  return a.filter((value) => b.map(val => val.name).indexOf(value.name) === -1);
}

function intersection(a: IColumn[], b: IColumn[]) {
  if(a === undefined || b===undefined){
    return []
  }
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: IColumn[], b: IColumn[]) {
  
  return [...a, ...not(b, a)];
}

export default function ColumnsSelector(props: IProps) {



  const classes = useStyles();
  const [checked, setChecked] = React.useState<IColumn[]>([]);
  const [left, setLeft] = React.useState<IColumn[]>([]);
  const [right, setRight] = React.useState<IColumn[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [searchValue, setSearchValue] = React.useState('')
  const [searchValueSelected, setSearchValueSelected] = React.useState('')



  function getLeftAndRigthColumns(dataformat: IDataformat, selectedColumns: Array<IColumn> | undefined, aggFlag: boolean, aggPriorFlag: boolean,
    aggObject: IAggregationObject | undefined) {
    const selectedCols: Array<IColumn> = selectedColumns ? selectedColumns : []
    if (dataformat === undefined) {
      return { 'left': [], 'right': [] }
    }
    else if (dataformat && aggFlag === true) {
      if (aggPriorFlag === true) {
        return { 'right': selectedCols, 'left': not(dataformat.columns, selectedCols ? selectedCols : []) }
      }
      else {
        const aggCols = enrichHelper.getColumnsFromAggregationObject(aggObject)
        const cols = removeSelectedElementsFromAllColumns(aggCols, selectedCols)
        return { 'left': cols.allCols, 'right': cols.selectedCols }
      }
    }
    else if (dataformat && aggFlag === false) {
      return { 'right': selectedCols, 'left': not(dataformat.columns, selectedCols ? selectedCols : []) }
    }
    return { 'left': [], 'right': [] }
  }


  function removeSelectedElementsFromAllColumns(allCols: Array<IColumn>, selectedCols: Array<IColumn>) {
    const selectedColsFilter = selectedCols.filter(item => allCols.map(x => x.name).includes(item.name));
    const allColsFilter = not(allCols, selectedColsFilter)
    return { 'allCols': allColsFilter, 'selectedCols': selectedColsFilter }
  }






  React.useEffect(() => {

    const result = getLeftAndRigthColumns(props.baseDataFormat, props.selectedColumns, props.aggregatedColumnsFlag, props.aggregatePrior2Join,
      props.aggregationObject)

    setLeft(result.left)
    setRight(result.right)

  }, [props.baseDataFormat, searchValue, props.selectedColumns, props.aggregatePrior2Join, props.aggregatedColumnsFlag])// eslint-disable-line react-hooks/exhaustive-deps




  const handleToggle = (value: IColumn) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const numberOfChecked = (items: IColumn[]) => intersection(checked, items).length;

  const handleToggleAll = (items: IColumn[]) => () => {
    console.log('toogleando', items)
    console.log('numberOfChecked(items)', numberOfChecked(items))
    if (numberOfChecked(items) === items.length) {
      console.log('entra 1', not(checked, items))

      setChecked(not(checked, items));
    } else {
      console.log('entra 2', union(checked, items))
      let aux = union(checked, items)
      setChecked([])

      setChecked(items);
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    props.setSelectedColumns(right.concat(leftChecked))
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    props.setSelectedColumns(not(right, rightChecked))
  };

  function compareIColumns(a: IColumn, b: IColumn) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }

  function filterItems(items: IColumn[], leftOrRight: string) {

    let aux: IColumn[] = items;
    if (searchValue != '' || searchValueSelected != '') {
      if (leftOrRight === 'left') {
        aux = items.filter(x => x.name.includes(searchValue))
      } else {
        aux = items.filter(x => x.name.includes(searchValueSelected))
      }
    }

    return aux;

  }
  function customList(title: React.ReactNode, items_: IColumn[], leftOrRight: string) {
    var items = filterItems(items_, leftOrRight)
    return (
      <Card style={{ width: '100%' }}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={`${title} ${numberOfChecked(items)}/${items.length} selected`}
          subheader={
            leftOrRight === 'left' ?
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  value={searchValue}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchValue(event.target.value)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              :
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  value={searchValueSelected}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchValueSelected(event.target.value)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
          }
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.sort(compareIColumns).map((value: IColumn) => {
            const labelId = `transfer-list-all-item-${value}-label`;
            if (leftOrRight === 'right') { //selected items always shown as text
              return (
                <ListItem key={value.position} role="listitem" button onClick={handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`#${value.position} (${value.type}) ${value.name}`} />
                </ListItem>
              );
            }
            else {
              return (<ColumnSelectorItem key={value.position} column={value} profiling={props.baseDataFormat ? props.baseDataFormat.profiling : undefined} ruleType={props.ruleType} checked={checked}
                handleToggle={handleToggle} />)
            }

          })}
          <ListItem />
        </List>
      </Card>
    )
  };



  return (
    <div style={{ height: '100%', width: '100%' }}>

      <Grid style={{ height: '100%', width: '100%' }}
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item style={{ height: '100%', width: '45%' }}>{customList('Choices', left, "left")}</Grid>
        <Grid item style={{ height: '100%', width: '10%' }}>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ height: '100%', width: '45%' }}>{customList('Chosen', right, "right")}</Grid>
      </Grid>

    </div>
  )
}
